export class DataArea {
  selected: boolean;
  constructor(private id: string, private name: string) {
    this.selected = false;
  }
  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getValue() {
    return this.id;
  }

  setValues(newData: DataArea) {
    this.id = newData.getId();
    this.name = newData.getName();
  }
}
