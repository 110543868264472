// Native - Plugins
import { Component } from '@angular/core';
import {
  AlertController,
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { NewRestrictionComponent } from '../new-restriction/new-restriction.component';

// Pages
// import { HomePage } from './../../pages/home/home';

// BusinessCore
import { RegisterData } from './../../businessCore/RegisterData';
import { RestrictionData } from './../../businessCore/RestrictionData';

// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-register-additional-info',
  templateUrl: './register-additional-info.component.html',
  styleUrls: ['./register-additional-info.component.scss'],
})
export class RegisterAdditionalInfoComponent {
  register_data: RegisterData;
  available_days: any;

  /**
   * Class constructor with these injections:
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param popoverCtrl dialog that appears on top of the current page.
   * @param _apiProvider web service provider.
   * @param toast subtle notification commonly used in modern applications.
   * @param alert dialog that presents users with information
   * or collects information from the user using inputs.
   * @param navCtrl base class for navigation controller components like Nav and Tab.
   */
  constructor(
    public navParams: NavParams,
    private popoverCtrl: PopoverController,
    private _apiProvider: webServiceProvider,
    private toast: ToastController,
    private alert: AlertController,
    public navCtrl: NavController,
    private navService: NavigationService
  ) {
    this.register_data = this.navParams.get('registerData');

    this.available_days = [
      { id: 0, value: 'Lunes' },
      { id: 1, value: 'Martes' },
      { id: 2, value: 'Miércoles' },
      { id: 3, value: 'Jueves' },
      { id: 4, value: 'Viernes' },
      { id: 5, value: 'Sábado' },
    ];
  }

  /**
   * Metodo utilizado para obtener el nombre del día según el identificador
   * enviado por parametro
   * @param day identificador del día que está siendo consultado
   */
  public get_day_value(daysArray) {
    return this.register_data.get_day_value(daysArray);
  }

  /**
   * Metodo utilizado para llevar a cabo el paso final de registro, enviando la informacion por medio
   * del post para el registro del usuario
   */
  public registerFinalStep() {
    this.register_data.delivery_restric =
      this.register_data.restrictionToString();

    this._apiProvider
      .sendXML(this.register_data.finishAsExecuted())
      .subscribe(() => {
        try {
          this._apiProvider.registerNewCustomer().subscribe(() => {});
        } catch (error) {
          console.log(error);
        }
      });

    this._apiProvider
      .registerPassword(this.register_data.id_card, this.register_data.password)
      .subscribe(() => {});

    this.presentAlert(
      '¡Registro finalizado!',
      `<p>Se ha creado su usuario.</p>`
    );
  }

  /**
   * Metodo utilizado para presentar una alerta con un título y mensaje
   * enviados por parametro
   * @param title string con el titulo que va tener la alerta
   * @param msg string con el mensaje que va ser desplegado en la alerta
   */
  async presentAlert(title: string, msg: string) {
    let alert = await this.alert.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // this.navCtrl.setRoot(HomePage);
            /*this.navCtrl.setRoot(FamiliesComponent);
            this.navCtrl.popToRoot();*/
            this.goHome();
          },
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  /**
   * Metodo utilizado para agregar una restriccion de entrega al registro que va realizar
   * el usuario
   * @param restriction objeto con los datos de la restriccion que se desea registrar
   */
  async addRestriction(restriction: RestrictionData) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: NewRestrictionComponent,
      componentProps: { restriction: restriction },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (
        data != null &&
        this.register_data.restrictions.indexOf(restriction) == -1
      ) {
        this.register_data.restrictions.unshift(data as RestrictionData);
      }
    });
  }

  /**
   * Metodo utilizado para registrar una restriccion en el arreglo de restricciones
   * seteadas por el usuario
   */
  addNewRestriction() {
    const restriction = new RestrictionData();
    restriction.edit = false;
    this.addRestriction(restriction);
  }

  /**
   * Metodo utilizado para eliminar una restriccion del conjunto ingresado por el usuario
   * @param res objeto con los datos de la restriccion que se desea eliminar
   */
  async deleteRestriction(res: RestrictionData) {
    let delete_index = this.register_data.restrictions.indexOf(res);
    this.register_data.restrictions.splice(delete_index, 1);

    let toast = await this.toast.create({
      message: 'Restricción eliminada exitosamente.',
      duration: 2000,
    });
    toast.present();
  }

  /**
   * Metodo utilizado para realizar una especie de toString() de la restriccion, mostrando
   * el rango de fechas y el tipo de resticcion
   * @param restriction objeto con los datos de la
   */
  getRestrictionInfo(restriction: RestrictionData) {
    return (
      this.get_day_value(restriction.day) +
      ' - ' +
      restriction.get_type_value(restriction.type)
    );
  }

  /**
   * Metodo utilizado para generar un toString de las horas asociadas a una restriccion
   * @param restriction objeto con los datos de la restriccion con la que se va ejecutar
   * el toString.
   */
  getRestrictionHours(restriction: RestrictionData) {
    let hours_date1 = restriction.getHours(restriction.date1);
    let hours_date2 = restriction.getHours(restriction.date2);

    if (restriction.type == 0 || restriction.type == 1) {
      return '(' + hours_date1[0] + ':' + hours_date1[1] + ')';
    }
    return (
      '(' +
      hours_date1[0] +
      ':' +
      hours_date1[1] +
      ' - ' +
      hours_date2[0] +
      ':' +
      hours_date2[1] +
      ')'
    );
  }

  /**
   * Metodo utilizado para retornar al componente principal de la aplicacion
   * que es la ventana de despliegue de productos
   */
  goHome() {
    // this.navCtrl.setRoot(HomePage);
    /*this.navCtrl.setRoot(FamiliesComponent);
    this.navCtrl.popToRoot();*/
    this.navService.navigateTo(
      NavigationRoutes.FamiliesComponent,
      {},
      { replaceUrl: true }
    );
  }
}
