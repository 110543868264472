<div
  style="border-bottom-style: solid; border-bottom-width: thin; margin: 20px"
>
  <p style="font-weight: bold; margin-bottom: 2px">
    {{ title }}
    <ion-icon
      (click)="changeObjectListVisibility()"
      [ios]="iosIcon"
      [md]="mdIcon"
    ></ion-icon>
  </p>
  <ion-list *ngIf="showObjectList">
    <div
      class="edition"
      *ngFor="let object of objectList; let i = index"
      color="white"
      text-wrap
    >
      <p class="firstOption">Código de barras: {{ object.barcode }}</p>
      <p style="color: #000">{{ object.assetId }}: {{ object.assetName }}</p>
      <p>{{ object.assetGroup }}</p>
      <p>Valor de adquisición: {{ object.acquValue }}</p>
      <p>Depreciación acumulada: {{ object.accuDepreciation }}</p>
      <p class="lastOption">Valor en libros: {{ object.netBookValue }}</p>
    </div>
  </ion-list>
</div>
