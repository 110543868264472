import { AlmacenModel } from './AlmacenModel';
import { CatalogModel } from './CatalogModel';
import { DataArea } from './DataArea';
import { FileOCInfo } from './FileOCInfo';
import { PurchLine } from './PurchLine';
import { PurchPoolModel } from './PurchPoolModel';
import { SitioModel } from './SitioModel';

export class PurchOrder {
    private idOC: string;
    private localIdOC: string; // Cuando es borrador de OC
    private fechaCrea: Date;
    private postingProfile = 'COMERCIALE';
    private lineaList: PurchLine[];
    private documentState =  'BORRADOR';
    private documentStateI = 0;
    private purchStatusS = '';
    private purchStatus = 0;
    private createdBy = '';
    private notaRechazo = '';
    total: number;
    cantidadLineas: number;
    subTotal: number;
    totalDiscount: number;
    taxes: number;
    private lastEditingAppDate: Date;
    private accountNameVend: string;
    private toSend = false;
    private sending = 0;
    private A_Departamento: CatalogModel;
    private B_CentroCosto: CatalogModel;
    private C_Proposito: CatalogModel;
    private tipoProyecto: CatalogModel;
    private purpose: CatalogModel;
    //Axel Vargas
    private purposeCategory: string;
    private noPhysicalInvoice: string;
    //Axel Vargas
    private sale: CatalogModel;
    private nameCompany: string;
    private emailCompany: string;
    private faxCompany: string;
    private telfCompany: string;
    private paymet: string;
    private taxInclude: boolean;
    private listFileToSend: FileOCInfo[] = [];
    private typePurchaseOrder: CatalogModel;
    constructor(
    private accountNum: string,
    private purchPoolId: PurchPoolModel,
    private language: string,
    private currencyCode: string,
    private fechaInicio: Date,
    private fechaFin: Date,
    private site: SitioModel,
    private location: AlmacenModel,
    private fechaEntrega: Date,
    private containerNum: number,
    private TEUS: number,
    private typeContainer: CatalogModel,
    private freeDays: number,
    private salidaSolicitada: Date,
    private salidaArribo: Date,
    private soliLlegaBod: Date,
    private port: CatalogModel,
    private transport: CatalogModel,
    private incoterm: CatalogModel,
    private vendorRef: string,
    private status: CatalogModel,
    private purchaseNotes: string,
    private dataArea: DataArea,
    private vatNum: string) {
        this.lineaList = [];
        this.total = 0;
        this.cantidadLineas = 0;
        this.subTotal = 0;
        this.totalDiscount = 0;
        this.taxes = 0;
        this.idOC = '';
        this.localIdOC = '';
        this.taxInclude = false;
    }

    setTypePurchaseOrder(newTypePurchaseOrder: CatalogModel) {
        this.typePurchaseOrder = newTypePurchaseOrder;
    }

    getTypePurchaseOrder() {
        return this.typePurchaseOrder;
    }

    setListInfoFile(newListFileToSend: FileOCInfo[]) {
        this.listFileToSend = newListFileToSend;
    }

    getListInfoFile() {
        return this.listFileToSend;
    }

    setTaxInclude(newTaxInclude: boolean) {
        this.taxInclude = newTaxInclude;
    }

    getTaxInclude() {
        return this.taxInclude;
    }

    setNotaRechazo(newNotaR: string) {
        this.notaRechazo = newNotaR;
    }

    getNotaRechazo() {
        return this.notaRechazo;
    }

    getVatNum() {
        return this.vatNum;
    }

    setVatNum(newVatNum: string) {
        this.vatNum = newVatNum;
    }

    getDataArea() {
        return this.dataArea;
    }

    setDataArea(newDataArea: DataArea) {
        this.dataArea = newDataArea;
    }

    getPurchPool() {
        return this.purchPoolId;
    }

    setPurchPool(newPurchPool: PurchPoolModel) {
        this.purchPoolId = newPurchPool;
    }

    getFechaCreacion() {
        return this.fechaCrea;
    }

    setFechaCreacion(newFechaC: Date) {
        this.fechaCrea = newFechaC;
    }

    getIdOC() {
        return this.idOC;
    }

    setIdOC(newIdOC: string) {
        this.idOC = newIdOC;
    }

    getAccountNum() {
        return this.accountNum;
    }

    getVendName() {
        return this.accountNameVend;
    }

    setVendName(nameVend: string) {
        this.accountNameVend = nameVend;
    }

    getCurrency() {
        return this.currencyCode;
    }

    getLanguage() {
        return this.language;
    }

    getFechaIni() {
        return this.fechaInicio;
    }

    getFechaFin() {
        return this.fechaFin;
    }

    getLocalId() {
        return this.localIdOC;
    }

    setLocalId(newLocalId: string) {
        this.localIdOC = newLocalId;
    }

    getNote() {
        return this.purchaseNotes;
    }

    getSite() {
        return this.site;
    }

    setSite(newSite: SitioModel) {
        this.site = newSite;
    }

    setLocation(newLocation: AlmacenModel) {
        this.location = newLocation;
    }

    getLocation() {
        return this.location;
    }

    getFechaEntrega() {
        return this.fechaEntrega;
    }

    getContainerNum() {
        return this.containerNum;
    }

    getTEUS() {
        return this.TEUS;
    }

    getContainerType() {
        return this.typeContainer;
    }

    getFreeDays() {
        return this.freeDays;
    }

    getSalidaSolicD() {
        return this.salidaSolicitada;
    }
    getSalidadArriboD() {
        return this.salidaArribo;
    }

    getSoliLlegaBod() {
        return this.soliLlegaBod;
    }

    getPuerto() {
        return this.port;
    }

    setPuerto(puerto: CatalogModel) {
        this.port = puerto;
    }

    getNaviera() {
        return this.transport;
    }

    setNaviera(naviera: CatalogModel) {
        this.transport = naviera;
    }

    getIncoterm() {
        return this.incoterm;
    }

    setIncoterm(incoterm: CatalogModel) {
        this.incoterm = incoterm;
    }

    getStatus() {
        return this.status;
    }

    setStatus(newStatus: CatalogModel) {
        this.status = newStatus;
    }

    setLineList(newLineList: PurchLine[]) {
        this.lineaList = newLineList;
    }

    setContainerType(newContainerType: CatalogModel) {
        this.typeContainer = newContainerType;
    }

    setDocumentStateI(newDocumentStateI: number) {
        this.documentStateI = newDocumentStateI;
        this.documentState = this.getStringValueDocumentStateI(this.documentStateI);
    }

    getDocumentStateI() {
        return this.documentStateI;
    }

    getStringValueDocumentStateI(estado: number) {
        let state = '';
        switch (estado) {
            case 0:
                state = 'BORRADOR';
                break;
            case 10:
                state = 'EN REVISIÓN';
                break;
            case 20:
                state = 'RECHAZADO';
                break;
            case 30:
                state = 'APROBADO';
                break;
            case 35:
                state = 'EN REVISIÓN EXTERNA';
                break;
            case 40:
                state = 'CONFIRMADO';
                break;
            case 50:
                state = 'FINALIZADA';
                break;
            default:
                break;
        }
        return state;
    }

    setPurchStatus(newPurchStatus: number) {
        this.purchStatus = newPurchStatus;
        this.purchStatusS = this.getStringValuePurchStatus(this.purchStatus);
    }

    getPurchStatus() {
        return this.purchStatus;
    }

    getPurchStatusS() {
        return this.purchStatusS;
    }

    getStringValuePurchStatus(status: number) {
        let state = '';
        switch (status) {
            case 0:
                break;
            case 1:
                state = 'ORDEN ABIERTA';
                break;
            case 2:
                state = 'RECIBIDO';
                break;
            case 3:
                state = 'FACTURADO';
                break;
            case 4:
                state = 'CANCELADO';
                break;
            default:
                break;
        }
        return state;
    }
  
    getDocumentStateS() {
    return this.documentState;
    }

    getCreatedBy() {
    return this.createdBy;
    }

    setCreatedBy(create: string) {
        this.createdBy = create;
    }

    setToSend(toSend: boolean) {
        this.toSend = toSend;
    }

    getToSend() {
        return this.toSend;
    }

    setSending(newSending: number) {
        this.sending = newSending;
    }

    getSending() {
        return this.sending;
    }

    setLastEditingAppDate(newLastEditingAppDate: Date) { 
        this.lastEditingAppDate = newLastEditingAppDate;
        if(newLastEditingAppDate !== null && newLastEditingAppDate !== undefined) {
            this.lastEditingAppDate.setHours(this.lastEditingAppDate.getHours() + 6);
        }
    }

    getLastEditingAppDate() {
        return this.lastEditingAppDate;
    }

    setA_Departamento(newA_Dep: CatalogModel) {
        this.A_Departamento = newA_Dep;
    }

    getA_Departamento() {
        return this.A_Departamento;
    }
    
    setB_CentroCosto(newB_CentroCosto: CatalogModel) {
        this.B_CentroCosto = newB_CentroCosto;
    }

    getB_CentroCosto() {
        return this.B_CentroCosto;
    }
    
    setC_Proposito(newC_Proposito: CatalogModel) {
        this.C_Proposito = newC_Proposito;
    }
    
    getC_Proposito() {
        return this.C_Proposito;
    }

    getTypeProject() {
        return this.tipoProyecto;
    }

    setTypeProject(newTipoProyecto: CatalogModel) {
        this.tipoProyecto = newTipoProyecto;
    }

    getPurpose() {
        return this.purpose;
    }

    setPurpose(newTypePurpose: CatalogModel) {
        this.purpose = newTypePurpose;
    }

    //Axel Vargas
    getPurposeCategory() {
        return this.purposeCategory;
    }

    setPurposeCategory(newTypePurposeCategory: string) {
        this.purposeCategory = newTypePurposeCategory;
    }

    getNoPhysicalInvoice() {
        return this.noPhysicalInvoice;
    }

    setNoPhysicalInvoice(newTypeNoPhysicalInvoice: string) {
        this.noPhysicalInvoice = newTypeNoPhysicalInvoice;
    }
    //Axel Vargas

    getSale() {
        return this.sale;
    }

    setSale(newTypeSale: CatalogModel) {
        this.sale = newTypeSale;
    }


    setNameCompany(newNameCompany: string) {
        this.nameCompany = newNameCompany;
    }

    getNameCompany() {
        return this.nameCompany;
    }

    setEmailCompany(newEmailCompany: string) {
        this.emailCompany = newEmailCompany;
    }
    
    getEmailCompany() {
        return this.emailCompany;
    }

    setFaxCompany(newFaxCompany: string) {
        this.faxCompany = newFaxCompany;
    }

    getFaxCompany() {
        return this.faxCompany;
    }

    setTelfCompany(newTelfCompany: string) {
        this.telfCompany= newTelfCompany;
    }
    
    getTelfCompany() {
        return this.telfCompany;
    }

    setPaymet(newPayment: string) {
        this.paymet = newPayment;
    }

    getPaymet() {
        return this.paymet;
    }

    /**
     * Funcion que inserta una nueva linea a las lineas de la orden
     */
    insertLine(newLine: PurchLine) {
       /*const element =
       this.lineaList.find( itemAll =>
        {return newLine.getCodigoProducto() === itemAll.getCodigoProducto()
        && newLine.getUnidad() === itemAll.getUnidad()}
        );
       if(element !== undefined) {
         element.cantidad += newLine.cantidad;
         element.calculateInfo();
       } else {*/
       this.lineaList.push(newLine);
       // }
       this.calcularDatos();
    }
    /**
     * Calcula los datos de las lineas
     */
    public calcularDatosLines() {
        this.lineaList.forEach( data => data.calculateInfo(this.taxInclude));
    }

    /**
     * Calcula los datos a la hora de ingresar o eliminar una linea
     */
    public calcularDatos() {
        this.calculateTaxes();
        this.calculateDiscount();
        this.calculateSubTotal();
        this.calculateTotal();
        this.calculateCantLineas();// calcula la cantidad de lineas
    }

    /**
     * Calcula el total de las lineas y setea la cantidad de lineas
     */
    private calculateTotal() {
        this.cantidadLineas = this.lineaList.length;
        const sumaTotal = this.lineaList.reduce(
            (ant , actual) => {
                const result = actual.getToDelete() ? 0 : actual.getTotal();
                return ant + result;
            }
            , 0);
        this.total = sumaTotal;
    }

    private calculateSubTotal() {
        this.subTotal = this.lineaList.reduce(
            (ant, actual) => {
                const result = actual.getToDelete() ? 0 : actual.getSubTotal();
                return ant + result;
            }
            , 0
        );
    }

    private calculateTaxes() {
        this.taxes = this.lineaList.reduce(
            (ant, actual) => {
                const result = actual.getToDelete() ? 0 : actual.getTaxesLine();
                return ant + result;
            }
            , 0
        );
    }

    private calculateDiscount() {
        this.totalDiscount = this.lineaList.reduce(
            (ant, actual) => {
                const result = actual.getToDelete() ? 0 : actual.getTotalDiscount();
                return ant + result;
            }
            , 0
        );
    }

    private calculateCantLineas() {
        this.cantidadLineas = this.lineaList.reduce(
            (ant, actual) => {
                const result = actual.getToDelete() ? 0 : 1;
                return ant + result;
            }
            , 0
        );
    }

    getLineList() {
        return this.lineaList;
    }

    deleteLine(itemToDelete: PurchLine) {
        const index = this.lineaList.indexOf(itemToDelete);
        if (index > -1) {
            this.lineaList.splice(index, 1);
            this.calcularDatos();
        }
    }

    toDeleteLine(itemToDelete: PurchLine) {
        const index = this.lineaList.indexOf(itemToDelete);
        if (index > -1) {
            this.lineaList[index].setToDelete(true);
            this.calcularDatos();
        }
    }

    concatNotes() {
        let stringNotes = '';
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.lineaList.length; index++) {
            const tmpNote = this.lineaList[index].note;
            if ( tmpNote !== '') {
                stringNotes += 'Linea' + ' ' + (index + 1) + ' :' + tmpNote + '\n';
            }
        }
        if ( stringNotes.length > 0) {
            stringNotes = '\n' + stringNotes;
        }
        return this.purchaseNotes + stringNotes;
    }

    /*headerToJSONState() {
        const header = {
            DataArea: this.dataArea.getId(),
            PurchId: this.getIdOC()
            };
        return header;
    }*/

    headerToJSON() {
        const entrega = this.dateToString(this.fechaEntrega);
        const initDate = this.dateToString(this.fechaInicio);
        const finDate = this.dateToString(this.fechaFin);
        const salidaArri = this.dateToString(this.salidaArribo);
        const saliSoli = this.dateToString(this.salidaSolicitada);
        const llegadaWH = this.dateToString(this.soliLlegaBod);

        const notes = this.concatNotes();
        const header = {
        AccountNum: this.accountNum, 
        PostingProfile: this.postingProfile,
        PurchaseNotes: notes, 
        PurchPoolId: 
        (  this.purchPoolId !== null 
        && this.purchPoolId !== undefined) 
        ? this.purchPoolId.getId() : '',
        Language: this.language,
        DeliveryDate: entrega,
        InitDateTime: initDate, 
        EndDateTime: finDate,
        Port: (this.port !== null 
            && this.port !== undefined
            && this.port.getId() as number !== -1) ? this.port.getId() : null,
        Transport: ( this.transport !== null &&  this.transport !== undefined
            && this.transport.getId() as number !== -1) ? this.transport.getId() : null,
        CurrencyCode: this.currencyCode,
        VendorRef: '',
        SiteId: ( this.site !== null &&  this.site !== undefined) ? this.site.getId(): null,
        LocationId: ( this.location !== null &&  this.location !== undefined) ? this.location.getId(): null,
        ContainerNum: this.containerNum, 
        SIT_ContainerType: ( this.typeContainer !== null &&  this.typeContainer !== undefined) ? this.typeContainer.getName(): '',
        TEUS: this.TEUS , 
        ShipStatus:  ( this.status !== null &&  this.status !== undefined) ? this.status.getId() : 6,
        FreeDays: this.freeDays,
        RequestDeparture: saliSoli, AjustDeparture: saliSoli,
        ArrivalReq: salidaArri, ArrivalDateAjusted: salidaArri,
        SIT_RequestedArrivalWh: llegadaWH, SIT_AdjustedWh: llegadaWH,
        DataArea: this.dataArea.getId(),
        // DocumentState: this.documentState,
        DocumentStateI: this.documentStateI,
        PurchStatus: this.purchStatus,
        NotaRechazo: this.notaRechazo,
        PurchId: this.getIdOC(),
        A_Departamento:  (this.A_Departamento !== null &&  this.A_Departamento !== undefined) ? this.A_Departamento.getId() : '',
        B_CentroCosto: (this.B_CentroCosto !== null &&  this.B_CentroCosto !== undefined) ? this.B_CentroCosto.getId() : '',
        C_Proposito: (this.C_Proposito !== null &&  this.C_Proposito !== undefined) ? this.C_Proposito.getId() : '',
        VatNum: this.vatNum,
        InclTax: this.taxInclude ? 1 : 0,
        ProjId:  (this.tipoProyecto !== null &&  this.tipoProyecto !== undefined) ? this.tipoProyecto.getId() : '',
        TypePurchaseOrder: (this.typePurchaseOrder !== null && this.typePurchaseOrder !== undefined) ? this.typePurchaseOrder.getId() : '',
        PurposeName:  (this.purpose !== null &&  this.purpose !== undefined) ? this.purpose.getId() : '',
        //Axel Vargas
        PurposeCategoryName: (this.purposeCategory !== null &&  this.purposeCategory !== undefined) ? this.purposeCategory : '',
        NoPhysicalInvoice: (this.noPhysicalInvoice !== null &&  this.noPhysicalInvoice !== undefined) ? this.noPhysicalInvoice : '',
        //Axel Vargas
        SalesRelationId:  (this.sale !== null &&  this.sale !== undefined) ? this.sale.getId() : ''
        };

        return JSON.stringify(header);
    }

    linesToJSON() {
        const lista = [];
        for (const linea of this.lineaList) {
            lista.push(linea.lineToJSON());
        }
        return JSON.stringify(lista);
    }

    private dateToString(data: Date) {
        let resp = '';
        if(data !== null && data !== undefined) {
            resp = (data.getMonth() + 1) + '/' + data.getDate() + '/' + data.getFullYear();
        }
        return  resp;
    }

    setDateDataFromString() {
        if ( typeof this.fechaEntrega === 'string') {
            this.fechaEntrega = new Date(this.fechaEntrega);
        }
        if (typeof this.fechaInicio === 'string') {
            this.fechaInicio = new Date( this.fechaInicio);
        }
        if (typeof this.fechaFin === 'string') {
            this.fechaFin = new Date( this.fechaFin);
        }
        if (typeof this.salidaArribo === 'string') {
            this.salidaArribo = new Date( this.salidaArribo);
        }
        if (typeof this.salidaSolicitada === 'string') {
            this.salidaSolicitada = new Date( this.salidaSolicitada);
        }
        if (typeof this.soliLlegaBod === 'string') {
            this.soliLlegaBod = new Date( this.soliLlegaBod);
        }
        /* if (typeof this.fechaCrea === 'object') { */
            this.fechaCrea = new Date( this.fechaCrea);
        /* } */
    }

    // Return a copy of actual Lines by save the data in editing mode.
    getCopyLines() {
        return this.lineaList.map(data => Object.assign(new PurchLine(), data));
    }

    /*setNewDataType() {
        return 
    }*/

    copyDataFromOtherObjet(newObjetData: PurchOrder) {
        // Datos simples
        this.language = newObjetData.language;
        this.currencyCode = newObjetData.currencyCode;
        this.containerNum = newObjetData.containerNum;
        this.TEUS = newObjetData.TEUS;
        this.freeDays = newObjetData.freeDays;
        this.purchaseNotes = newObjetData.purchaseNotes;
        this.dataArea.setValues(newObjetData.dataArea);
        // this.documentState = newObjetData.documentState;
        this.setDocumentStateI(newObjetData.documentStateI);
        this.setPurchStatus(newObjetData.purchStatus); 
        this.idOC = newObjetData.idOC;
        this.accountNameVend = newObjetData.accountNameVend;
        this.createdBy = newObjetData.createdBy;
        this.cantidadLineas = newObjetData.cantidadLineas;
        this.total = newObjetData.total;
        
        // Fechas
        this.fechaInicio = newObjetData.fechaInicio;
        this.fechaFin = newObjetData.fechaFin;        
        this.fechaEntrega = newObjetData.fechaEntrega;
        this.salidaSolicitada = newObjetData.salidaSolicitada;
        this.salidaArribo = newObjetData.salidaArribo;
        this.soliLlegaBod = newObjetData.soliLlegaBod;
        this.lastEditingAppDate = newObjetData.lastEditingAppDate;
        

        (newObjetData.purchPoolId) ? this.purchPoolId.setDataValue(newObjetData.purchPoolId.getId(),newObjetData.purchPoolId.getName())
        : this.purchPoolId = null;

        (newObjetData.site) ? this.site.setValues(newObjetData.site.getId(), newObjetData.site.getName())
        : this.site = null;

        (newObjetData.location) ? this.location.setValues(newObjetData.location.getId(), newObjetData.location.getName()) 
        : this.location = null;

        (newObjetData.typeContainer) ? this.typeContainer.setValues(newObjetData.typeContainer.getId(), newObjetData.typeContainer.getName()) 
        : this.typeContainer = null ; 

        (newObjetData.port) ? this.port.setValues(newObjetData.port.getId(), newObjetData.port.getName())
        : this.port = null;

        (newObjetData.transport) ? this.transport.setValues(newObjetData.transport.getId(), newObjetData.transport.getName()) 
        : this.transport = null;

        (newObjetData.incoterm) ? this.incoterm.setValues(newObjetData.incoterm.getId(), newObjetData.incoterm.getName())
        : this.incoterm = null;

        (newObjetData.status) ? this.status.setValues(newObjetData.status.getId(), newObjetData.status.getName()) 
        : this.status = null;

        (newObjetData.tipoProyecto) ? this.tipoProyecto.setValues(newObjetData.tipoProyecto.getId(), newObjetData.tipoProyecto.getName())
        : this.tipoProyecto = null;

        (newObjetData.purpose) ? this.purpose.setValues(newObjetData.purpose.getId(), newObjetData.purpose.getName(), newObjetData.purpose.getSalesOrderRelation())
        : this.purpose = null;

        //Axel Vargas
        (newObjetData.purposeCategory) ? this.purposeCategory = newObjetData.purposeCategory
        : this.purposeCategory = '';

        (newObjetData.noPhysicalInvoice) ? this.noPhysicalInvoice = newObjetData.noPhysicalInvoice
        : this.noPhysicalInvoice = '';
        //Axel Vargas

        (newObjetData.sale) ? this.sale.setValues(newObjetData.sale.getId(), newObjetData.sale.getName(), newObjetData.sale.getSalesOrderRelation(), newObjetData.sale.getPSSalesId())
        : this.sale = null;

        this.vatNum = newObjetData.getVatNum();
        this.taxInclude = newObjetData.getTaxInclude();

        (newObjetData.typePurchaseOrder) ? this.typePurchaseOrder.setValues(newObjetData.typePurchaseOrder.getId(), newObjetData.typePurchaseOrder.getName())
        : this.typePurchaseOrder = null; 
        // Lineas 
        // Para evitar comflictos, se copia los objetos
        this.lineaList = newObjetData.getCopyLines();
        // Calcula datos de la línea
        this.calcularDatosLines();
        // Calcula los datos de la OC
        this.calcularDatos();        
    }


    /**
     * 
     * @param listToFilter 
     * @param listFilters 
     * @param type 1 Compania | 2 Proveedor | 3 DocumentState | 4 PurchStatus | 5 CreadoPor
     * @returns 
     */
    static filterState(listToFilter: PurchOrder[], listFilters: CatalogModel[], type: number ): PurchOrder[] {
            //Filtro por medio de lo seleccionado.
        let result: PurchOrder[] = [];
        result = listToFilter.filter( // Estados
        ocInfo => {
            let exits = false;
            switch (type) {
                case 1:
                    exits = listFilters.find(
                    (estado) => { return estado.getId() as any === ocInfo.getDataArea().getId() as any && estado.selected}) !== undefined;
                    break;
                case 2:
                    exits = listFilters.find(
                    (estado) => { return estado.getId() as any === ocInfo.getAccountNum() as any && estado.selected}) !== undefined; 
                    break;
                case 3:
                    exits = listFilters.find(
                    (estado) => { return estado.getId() === ocInfo.getDocumentStateI() && estado.selected}) !== undefined;    
                    break;
                case 4:
                    exits = listFilters.find(
                    (estado) => { return estado.getId() === ocInfo.getPurchStatus() && estado.selected}) !== undefined;    
                    break;
                case 5:
                    exits = listFilters.find(
                    (estado) => { return estado.getId() as any === ocInfo.getCreatedBy() as any && estado.selected}) !== undefined;    
                    break;           
                default:
                    break;
            }
            return exits;
        });

        if(result.length === 0) {
            result = listToFilter; //Retorno todas la lista de OC.
        }

        return result;
    }

      // Retorna la lista con las OC filtradas por la barra.
    static filterObjectByWrite(nameOrId: string, listPurchTmp: PurchOrder[]) {
        const key = nameOrId.toLowerCase();
        let tmpList: PurchOrder[] = [];
        if (key.length > 0) {
        // Filtra las OC.
        tmpList = listPurchTmp.filter(
            ocInfo => {
            let idInclude = false;
            idInclude = ocInfo.getIdOC().toLowerCase().includes(key);
            idInclude = ocInfo.getAccountNum().toLowerCase().includes(key) || idInclude;
            idInclude = ocInfo.getVendName().toLowerCase().includes(key) || idInclude;
            return idInclude;
            }
        );
        } else { //Devuele la lista
        tmpList = listPurchTmp;
        }

        return tmpList;
    }

    static filterByDateCreation(listToFilter: PurchOrder[], initD: Date, endD: Date) {
        let result: PurchOrder[] = [];
        result = listToFilter.filter( // Estados
        ocInfo => {
            let exits = false; 
            if(ocInfo.getFechaCreacion() !== null && ocInfo.getFechaCreacion() !== undefined) {
                let fechCreacion = new Date(ocInfo.getFechaCreacion());
                fechCreacion.setHours(0);
                fechCreacion.setMinutes(0);
                fechCreacion.setSeconds(0);
                fechCreacion.setMilliseconds(0);
                if(initD !== null && initD !== undefined && endD !== null && endD !== undefined) {
                    exits = ( initD <= fechCreacion  && fechCreacion <= endD );
                } else if(initD !== null && initD !== undefined) {
                    exits =  initD <= fechCreacion;
                } else if( endD !== null && endD !== undefined) {
                    exits =   fechCreacion <= endD;
                }
            } 
            return exits;
        });

        if(result.length === 0) {
            result = listToFilter; //Retorno todas la lista de OC.
        }

        return result;
    }

    static getPropertyOrder(typeOrder: string) {
        let result = 1;
        switch(typeOrder) {
            case 'proveedorZA':
            case 'fechaMenor-mayor':
            case 'estadoZ-A':
            case 'estadoApZ-A':
            case 'companyZ-A':
            case 'createByZ-A':
                result = -1;
                break;
            default:
                break;
        }
        return result;
    }

    static orderListBy(listToOrder: PurchOrder[], typeOrder: string) {
        if(typeOrder.length > 0) {
            const property: number = this.getPropertyOrder(typeOrder);
            return listToOrder.sort(
                (ocA, ocB) => {
                    let number = 0;
                    switch(typeOrder) {
                        case 'proveedorAZ':
                        case 'proveedorZA':
                            number = ocA.getVendName().localeCompare(
                                ocB.getVendName(), ['es-ES','en-US'],  { sensitivity: 'base' }
                            );
                          break;
                        case 'fechaMenor-mayor':
                        case 'fechaMayor-menor':
                            number = (ocA.getFechaCreacion().getTime() <= ocB.getFechaCreacion().getTime()) ? 1 : -1 ;
                            break;
                        case 'estadoA-Z':
                        case 'estadoZ-A':
                            number = ocA.getPurchStatusS().localeCompare(
                                ocB.getPurchStatusS(), ['es-ES','en-US'],  { sensitivity: 'base' }
                            );
                            break;
                        case 'estadoApA-Z':
                        case 'estadoApZ-A':
                            number = ocA.getDocumentStateS().localeCompare(
                                ocB.getDocumentStateS(),  ['es-ES','en-US'],  { sensitivity: 'base' }
                            );
                          break;
                        case 'companyA-Z':
                        case 'companyZ-A':
                            number = ocA.getDataArea().getId().localeCompare(
                                ocB.getDataArea().getId(),  ['es-ES','en-US'],  { sensitivity: 'base' }
                            );
                          break;
                        case 'createByA-Z':
                        case 'createByZ-A':
                            number = ocA.getCreatedBy().localeCompare(
                                ocB.getCreatedBy(),  ['es-ES','en-US'],  { sensitivity: 'base' }
                            );
                          break;
                        default:
                          break;
                    }
                    
                    // 0 ===
                    // -1 === A > B
                    // 1 === a < b
                    return number * property;
                }
            );
        } else {
            return listToOrder;
        }
    }

}
