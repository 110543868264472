import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { Audit } from '../../businessCore/Audit';
import { Worker } from '../../businessCore/Worker';
import { AssetAuditFilterPipe } from '../../pipes/asset-audit-filter/asset-audit-filter';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AssetAuditAddComponent } from '../asset-audit-add/asset-audit-add.component';

@Component({
  selector: 'app-asset-audit-record',
  templateUrl: './asset-audit-record.component.html',
  styleUrls: ['./asset-audit-record.component.scss'],
})
export class AssetAuditRecordComponent implements OnInit {
  @Input() auditor: Worker;
  @Input() petitioner: Worker;
  @Input() auth: number;
  @Input() componentId: any;

  auditList: Audit[];
  auditListToSearch: Audit[];

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private apiProvider: webServiceProvider,
    private assetAuditFilterPipe: AssetAuditFilterPipe,
    private navService: NavigationService
  ) {
    this.auditList = [];
    this.auditListToSearch = [];
  }

  async ngOnInit() {
    try {
      const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
      await loading.present(); //Muestra pantalla de cargado.
      this.auditList = await this.getAudit(Number(this.auditor.getRecId()), 0);
      this.auditList = this.auditList.concat(
        await this.getAudit(Number(this.auditor.getRecId()), 1)
      );
      this.auditListToSearch = this.auditList;
      //DEBUG
      /*this.auditListToSearch = [
        new Audit(0, 0, 0, 0, 'a', 'a', 'a', 0, 'a', [
          new AssetAudit(
            0,
            'a',
            [new AuditFinding(1, 'a', 'a', false, false)],
            [0],
            0
          ),
        ]),
      ];*/
      //DEBUG
      await loading.dismiss();
    } catch (error) {
      this.createAlert(
        'Error de conexión',
        'Se ha presentado un error, favor intentar más tarde'
      );
    }
  }

  async refreshPage() {
    this.navService.navigateTo(
      NavigationRoutes.AssetAuditPage,
      {
        auth: this.auth,
        code: this.componentId,
      },
      { replaceUrl: true }
    );
  }

  /**
   *
   * @param personnelNumber
   * @returns
   */
  async getAudit(pAuditor: number, pStatus: number) {
    const resp = await this.apiProvider.getAudit(pAuditor, pStatus); //Obtiene todos los hallazgos de auditoría.
    const list: Audit[] = resp as Audit[]; //Identifica el response como una lista de objetos AuditFinding.
    return list;
  }

  searchAudit(event) {
    this.auditListToSearch = this.assetAuditFilterPipe.transform(
      this.auditList,
      event.detail.value
    );
  }

  async loadAudit(auditRecId: number) {
    let audit: Audit;
    if (auditRecId) {
      audit = this.auditList.find(
        (element) => element.getAuditRecId() === auditRecId
      );
    } else {
      audit = new Audit(0, 0, 0, 0, '', '', '', 0, '', []);
    }
    let popover = await this.modalCtrl.create({
      component: AssetAuditAddComponent,
      componentProps: {
        audit: audit,
        petitioner: this.petitioner,
        auditor: this.auditor,
      },
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null && data.reload) {
        this.refreshPage();
      }
    });
  }

  parseAuditDate(pStrDate: string) {
    let result: string;
    let date = new Date(pStrDate);
    result =
      date.getDate().toString() +
      ' / ' +
      (date.getMonth() + 1).toString() +
      ' / ' +
      date.getFullYear().toString();
    return result;
  }

  /**
   *
   * @param title
   * @param subTitle
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }
}
