<ion-content class="ion-padding">
  <h4 class="title2" style="width: 100%">Registrar Contraseña</h4>

  <div class="password-request">
    Usted ya se encuentra registrado no obstante, debe registrar una clave de
    acceso para utilizar el sistema Mercasa VIP.
  </div>

  <form (ngSubmit)="addPassword()" #registerForm="ngForm">
    <ion-item class="register_item">
      <a class="required"></a>
      <ion-input
        label="Contraseña"
        label-placement="floating"
        class="floating"
        type="password"
        minlength="8"
        maxlength="15"
        name="password"
        [(ngModel)]="password"
        required
      ></ion-input>
    </ion-item>

    <ion-item class="register_item">
      <a class="required"></a>
      <ion-input
        label="Confirmar Contraseña"
        label-placement="floating"
        class="floating"
        type="password"
        minlength="8"
        maxlength="15"
        name="passConfirm"
        [(ngModel)]="passConfirm"
        required
      ></ion-input>
    </ion-item>

    <span class="span_alert black"
      >* Deben tener un largo entre 8 y 15 caracteres.</span
    >
    <br />
    <span *ngIf="password != passConfirm" class="span_alert red"
      >"Las contraseñas no coinciden."</span
    >

    <ion-button
      expand="block"
      class="submit-btn"
      type="submit"
      [disabled]="!validForm()"
      (click)="addPassword()"
    >
      <ion-icon name="md-checkmark-circle-outline" class="mr-5"></ion-icon>
      Agregar
    </ion-button>
  </form>
</ion-content>
