import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { RegisterAddress } from '../register-address/register-address.component';

@Component({
  selector: 'app-vendor-register-language',
  templateUrl: './vendor-register-language.component.html',
  styleUrls: ['./vendor-register-language.component.scss'],
})
export class VendorRegisterLanguageComponent implements OnInit {
  @Input() componentCalled: number;
  @Output() translate = new EventEmitter<any>();

  language: string = '';

  vendorRegisterES = {
    errorAlertTitle: 'Ha ocurrido un error',
    errorAlertSubtitle:
      'No ha sido posible registrarlo, favor intentar más tarde ',
    errorAlertBtn: 'Volver',
    successAlertTitle: 'Se ha guardado su solicitud',
    successAlertSubtitle: 'Su solicitud será evaluada pronto',
    successAlertBtn: 'Continuar',
  };

  vendorRegisterEN = {
    errorAlertTitle: 'A problem has been ocurred',
    errorAlertSubtitle:
      'Was not possible register your request, please try it later',
    errorAlertBtn: 'Dismiss',
    successAlertTitle: 'Request successfully send',
    successAlertSubtitle: 'Your request would be evaluated soon',
    successAlertBtn: 'Continue',
  };

  generalES = {
    vendorOf: 'Proveedor de:',
    vatnumType: 'Tipo de cédula:',
    vatnum: 'Cédula',
    name: 'Nombre',
    firstLastName: 'Primer apellido',
    secondLastName: 'Segundo apellido',
    repVatnumType: 'Tipo de cédula representante',
    representativeID: 'Cédula del representante',
    representativeName: 'Nombre del representante',
    placeholder: 'Ingrese el nombre requerido',
  };

  generalEN = {
    vendorOf: 'Vendor for:',
    vatnumType: 'Type of vendor ID',
    vatnum: 'Vendor ID',
    name: 'Name',
    firstLastName: 'Last name',
    secondLastName: 'Second Last Name',
    repVatnumType: 'Type of representative ID',
    representativeID: 'Representative ID',
    representativeName: "Representative's name",
    placeholder: 'Enter required name',
  };

  paymentES = {
    title: 'Pago',
    paymentTerms: 'Condiciones de pago',
    bankGroups: 'Nombre del banco',
    currency: 'Moneda',
    accountNumber: 'Cuenta Bancaria IBAN',
    ABANumber: 'Número ABA',
    SWIFTCode: 'Código SWIFT',
    placeholder: 'Ingrese la información solicitada',
  };

  paymentEN = {
    title: 'Payment',
    paymentTerms: 'Terms of payment',
    bankGroups: 'Bank groups',
    currency: 'Currency',
    accountNumber: 'Bank account number',
    ABANumber: 'ABA number',
    SWIFTCode: 'SWIFT Code',
    placeholder: 'Enter required data',
  };

  contactES = {
    title: 'Agregar contacto',
    phoneBtn: 'Teléfono',
    emailBtn: 'Correo',
  };

  contactEN = {
    title: 'Add Contact',
    phoneBtn: 'Phone',
    emailBtn: 'Email',
  };

  addressES: RegisterAddress = {
    title: 'Dirección',
    addBtn: 'Agregar',
    headerTitle: 'Obtener posición',
    pinUp: 'Fijar punto', //Se reutiliza
    bodyMsg:
      'Seleccione el pinto en el mapa donde se ubica su empresa y presione el botón',
    secondTitle: 'Nueva dirección',
    country: 'País',
    state: 'Provincia o estado',
    county: 'Cantón, colonia o municipio',
    city: 'Distrito o ciudad',
    streetAddress: 'Otras señas',
    name: 'Nombre dirección',
    egNote: 'E.j: Oficina central',
  };

  addressEN: RegisterAddress = {
    title: 'Address',
    addBtn: 'Add',
    headerTitle: 'Get Position',
    pinUp: 'Pin Up', //Se reutiliza
    bodyMsg:
      'Select the point on the map where your bussiness is located and press',
    secondTitle: 'Address',
    country: 'Country',
    state: 'State',
    county: 'County',
    city: 'City',
    streetAddress: 'Street Address',
    name: 'Name',
    egNote: 'E.g: Headquartes',
  };

  documentES = {
    title: 'Documentos',
    infoText:
      'Para que el acuerdo tenga validez, se deben adjuntar los siguientes documentos',
    b1: 'Fotocopia de cédula jurídica o física del proveedor.',
    b2: 'Fotocopia de cédula del representante legal.',
    b3: 'Fotocopia de cédula del responsable de tramitar factura.',
    b4: 'Fotocopia de cédula del responsable de realizar servicio.',
    b5: 'Acuerdo de proveedores.',
    b6: 'Autorización de transferencia bancaria.',
    b7: 'Políticas de garantía del bien o servicio.',
    attach: 'Adjuntar',
    terms: 'Aceptar acuerdo de proveedores',
    uploadImage: 'Subir archivo...',
    errorMsg:
      'Uno o más documentos de los anteriores no han sido completado o no se han aceptado los términos y condiciones, favor verificar',
    register: 'Registrar',
    alertTitle: 'Completar y subir',
    alertSubTitle: 'El siguiente documento debe ser completado y subido',
    alertBtnText: 'Continuar',
    alertRejectBtnText: 'Atrás',
    confirmTitle: 'Confirmar registro',
    confirmSubTitle: '¿Desea registrarse como proveedor?',
    confirmBtnText: 'Sí',
    rejectBtnText: 'No',
  };

  documentEN = {
    title: 'Documents',
    infoText:
      'For the agreement to be valid, the following documents must be attached',
    b1: "Photo of the supplier's legal ID.",
    b2: "Photo of the representative's legal ID",
    b3: 'Photo of the ID of the responsible for processing the invoice.',
    b4: 'Photo of the ID of the responsible for performing the invoice.',
    b5: 'Vendor Agreement.',
    b6: 'Bank transfer authorization.',
    b7: 'Guarantee policies.',
    attach: 'Add',
    terms: 'Accept Vendor Agreement',
    uploadImage: 'Upload file...',
    errorMsg:
      'One or more files are missing or the vendor agreement has not been accepted, please verify',
    register: 'Register',
    alertTitle: 'Fulfill and upload',
    alertSubTitle: 'The next document must be fulfilled and then upload it',
    alertBtnText: 'Continue',
    alertrejectBtnText: 'Back',
    confirmTitle: 'Register confirmation',
    confirmSubTitle: 'Do you want to register as a vendor?',
    confirmBtnText: 'Yes',
    rejectBtnText: 'No',
  };

  constructor(public storage: StorageService) {}

  /**
   *
   */
  async ngOnInit() {}

  /**
   *
   */
  async changeLanguage() {
    await this.storage.get('vendor_language').then((data) => {
      const language = data as string;
      if (language == 'EN') {
        this.storage.set('vendor_language', 'ES');
      } else {
        this.storage.set('vendor_language', 'EN');
      }
    });
  }

  /**
   *
   * @param pLenguaje
   * @param pScreen
   */
  getData(pLenguaje: string, pScreen: number) {
    switch (pScreen) {
      case 1:
        if (pLenguaje == 'ES') {
          return this.generalEN;
        } else {
          return this.generalES;
        }
      case 2:
        if (pLenguaje == 'ES') {
          return this.paymentEN;
        } else {
          return this.paymentES;
        }
      case 3:
        if (pLenguaje == 'ES') {
          return this.contactEN;
        } else {
          return this.contactES;
        }
      case 4:
        if (pLenguaje == 'ES') {
          return this.addressEN;
        } else {
          return this.addressES;
        }
      case 5:
        if (pLenguaje == 'ES') {
          return this.documentEN;
        } else {
          return this.documentES;
        }
      default:
        return this.documentES;
    }
  }

  /**
   *
   * @param pLenguaje
   */
  getVendorRegisterData(pLenguaje: string) {
    if (pLenguaje == 'ES') {
      return this.vendorRegisterEN;
    } else {
      return this.vendorRegisterES;
    }
  }
}
