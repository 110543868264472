<ion-content>
  <div style="text-align: center; margin-left: 10px">
    <ion-item class="filter-btn" lines="none">
      <!-- <ion-input type="number" [(ngModel)]="CardNumber" (keyup)="VerifyCardType()"> </ion-input> -->
      <ion-input
        label="Lista"
        label-placement="floating"
        class="floating"
        type="text"
        [(ngModel)]="listName"
        style="border-radius: 8px"
      >
      </ion-input>
      <ion-button
        item-right
        class="btnRight"
        (click)="CreateNewList()"
        [disabled]="listName.length == 0"
      >
        <i class="fas fa-plus"></i>
      </ion-button>
    </ion-item>
  </div>
  <ion-list>
    <ion-item *ngFor="let item of listas" lines="none">
      <div
        style="
          margin-left: 5px;
          border: 1px solid rgb(108, 105, 250);
          display: flex;
          align-items: center;
          padding: 10px;
          width: 100%;
        "
      >
        <ion-avatar
          style="width: 10%; color: blue"
          *ngIf="itemOnList(item)"
          (click)="removefromList(item)"
        >
          <i class="fas fa-check-circle" style="font-size: 30px"></i>
        </ion-avatar>
        <ion-avatar
          style="width: 10%; color: blue"
          *ngIf="!itemOnList(item)"
          (click)="addToList(item)"
        >
          <i class="far fa-circle" style="font-size: 30px"></i>
        </ion-avatar>
        <h6 style="margin-left: 10px; width: 75%" text-wrap>{{ item.name }}</h6>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
