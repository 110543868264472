import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { MessageBoxParams } from '../../OOP/messageBoxParms.interface';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent {
  MessageBoxParams: MessageBoxParams;
  InputValue: string = '';

  constructor(public navParams: NavParams, public viewCtrl: PopoverController) {
    this.MessageBoxParams = this.navParams.get('MessageBoxParams');
  }

  async dismiss(ok?: boolean) {
    if (this.MessageBoxParams.input && ok) {
      this.viewCtrl.dismiss({
        result:
          this.InputValue && this.InputValue.trim() !== ''
            ? this.InputValue
            : 'void',
      });
    } else {
      this.viewCtrl.dismiss({ result: ok });
    }
  }
}
