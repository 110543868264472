export class ProveedorModel {
  constructor(
    private accountNum: string,
    private delName: string,
    private currency: string,
    private languageId: string,
    private regimenId: string
  ) {}

  getAccountNum() {
    return this.accountNum;
  }

  getName() {
    return this.delName;
  }

  getId() {
    return this.getAccountNum();
  }

  getCurrency() {
    return this.currency;
  }

  getLanguageId() {
    return this.languageId;
  }

  getRegimenId() {
    return this.regimenId;
  }
}
