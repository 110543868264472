import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { RemissionService } from '../../services/remission.service';
import { SelectObjectsComponent } from '../select-objects/select-objects.component';

@Component({
  selector: 'app-config-line-purch-remission',
  templateUrl: './config-line-purch-remission.component.html',
  styleUrls: ['./config-line-purch-remission.component.scss'],
})
export class ConfigLinePurchRemissionComponent {
  data: any;

  constructor(
    private navParams: NavParams,
    private RemissionService: RemissionService,
    private modalCtrl: ModalController
  ) {
    this.data = this.navParams.get('data');
    //DEBUG
    //this.data = { roleSpecificIdsSelected: [] };
    // DEBUG
  }

  roleSpecificSelected() {
    let selected = '';
    this.data.roleSpecificIdsSelected
      .filter((x) => x.isChecked)
      .forEach((x, index, arr) => {
        selected += x.label + (arr.length - 1 !== index ? ',' : '');
      });
    return selected;
  }

  roleSpecificAssistantSelected() {
    let selected = '';
    this.data.roleSpecificIdsAssistantsSelected
      .filter((x) => x.isChecked)
      .forEach((x, index, arr) => {
        selected += x.label + (arr.length - 1 !== index ? ',' : '');
      });
    return selected;
  }

  containerIdsSelected() {
    let selected = '';
    this.data.containerIdsSelected
      .filter((x) => x.isChecked)
      .forEach((x, index, arr) => {
        selected += x.label + (arr.length - 1 !== index ? ',' : '');
      });
    return selected;
  }

  async selectRoleSpecificId() {
    const modal = await this.modalCtrl.create({
      component: SelectObjectsComponent,
      componentProps: {
        options: JSON.parse(JSON.stringify(this.data.roleSpecificIds)),
        optionsSelected: this.data.roleSpecificIdsSelected,
        multiple: false,
      },
    });

    await modal.present();

    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data) {
        this.data.roleSpecificIdsSelected = data;
      }
    });
  }

  async selectRoleSpecificIdAssitants() {
    const modal = await this.modalCtrl.create({
      component: SelectObjectsComponent,
      componentProps: {
        options: JSON.parse(JSON.stringify(this.data.roleSpecificIds)),
        optionsSelected: this.data.roleSpecificIdsAssistantsSelected,
        multiple: true,
      },
    });

    await modal.present();

    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data) {
        this.data.roleSpecificIdsAssistantsSelected = data;
      }
    });
  }

  async selectContainerId() {
    const modal = await this.modalCtrl.create({
      component: SelectObjectsComponent,
      componentProps: {
        options: JSON.parse(JSON.stringify(this.data.containerIds)),
        optionsSelected: this.data.containerIdsSelected,
        multiple: false,
      },
    });

    await modal.present();

    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data) {
        this.data.containerIdsSelected = data;
      }
    });
  }

  saveInfo() {
    this.RemissionService.configLinePRModal.dismiss(this.data);
  }

  close() {
    this.RemissionService.configLinePRModal.dismiss();
  }
}
