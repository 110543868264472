<ion-list>
  <ion-list-header style="color: #223e99"> Filtros </ion-list-header>
  <div>
    <ion-item>
      <ion-select
        label="Empresa"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeCompany($event)"
      >
        <!--TODOM: selected=" {{ company.selected }}"-->
        <ion-select-option
          [value]="company"
          *ngFor="let company of filterData.companies"
        >
          {{ company.dataAreaId }} -
          {{ company.companyName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-select
        label="Categoría"
        label-placement="floating"
        class="floating"
        [(ngModel)]="categories"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeCategory($event)"
      >
        <!--TODOM [selected]="category.selected"-->
        <ion-select-option
          [value]="category"
          *ngFor="let category of filterData.categories"
        >
          {{ category.assetGroup }} -
          {{ category.assetGroupName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label></ion-label>
      <ion-select
        label="Responsable actual"
        label-placement="floating"
        class="floating"
        [(ngModel)]="responsibles"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeResponsible($event)"
      >
        <!--TODOM [selected]="responsible.selected"-->
        <ion-select-option
          [value]="responsible"
          *ngFor="let responsible of filterData.responsibles"
          >{{ responsible.responsibleName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-select
        label="Nuevo responsable"
        label-placement="floating"
        class="floating"
        [(ngModel)]="newResponsibles"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeNewResponsible($event)"
      >
        <!--TODOM [selected]="newResponsible.selected"-->
        <ion-select-option
          [value]="newResponsible"
          *ngFor="let newResponsible of filterData.newResponsibles"
          >{{ newResponsible.responsibleName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </div>
</ion-list>

<ion-list-header style="color: #223e99" lines="none">
  Rango de fechas
</ion-list-header>
<ion-item>
  <ion-label>Fecha inicial</ion-label>
  <ion-datetime-button datetime="datetime"></ion-datetime-button>
  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        #datetime
        id="datetime"
        presentation="date"
        value="startDate"
        display-timezone="utc"
        displayFormat="DD/MM/YYYY"
        [(ngModel)]="startDate"
        [showDefaultButtons]="true"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
</ion-item>
<ion-item>
  <ion-label>Fecha final</ion-label>
  <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>
  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        id="datetimeEnd"
        presentation="date"
        value="finalDate"
        display-timezone="utc"
        displayFormat="DD/MM/YYYY"
        [(ngModel)]="finalDate"
        [showDefaultButtons]="true"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
</ion-item>
<ion-label *ngIf="wrongDateFlag" style="color: red; padding-left: 13px">
  El formato de las fechas es incorrecto.
</ion-label>

<ion-grid style="display: flex">
  <ion-button text-color="#0000ff" (click)="dismissNull()" style="width: 60%">
    <ion-icon name="trash"></ion-icon> Borrar Filtros
  </ion-button>

  <ion-button text-color="#0000ff" (click)="dismiss()" style="width: 40%">
    <ion-icon name="checkmark-done-outline"></ion-icon>Filtrar
  </ion-button>
</ion-grid>
