<ion-content>
  <div *ngFor="let producto of itemInfo">
    <ion-item>
      <ion-grid>
        <ion-row>
          <ion-col size="4">
            <img
              class="image-container"
              src="https://serviceplan-api.mercasacr.com/images/Products/{{
                producto.itemId
              }}_s_.PNG"
              alt=""
              onerror=""
            />
          </ion-col>
          <ion-col size="8">
            <h4>{{ producto.itemId }}</h4>
            <ion-item text-wrap no-lines>
              <span style="font-size: 0.8rem">{{ producto.itemName }}</span>
            </ion-item>
            <div style="display: flex">
              <div style="display: block" style="width: 100%">
                <h5 color="primary" style="font-weight: bold">
                  {{ setCurrency(producto.salePrice) }}
                </h5>
                <h5 color="primary" style="font-weight: bold; font-size: 15px">
                  {{ producto.unitId }}
                </h5>
              </div>
              <div style="display: flex">
                <ion-button
                  (click)="addToCart(producto)"
                  class="button-blue"
                  style="margin: auto"
                >
                  <i class="fas fa-cart-plus"></i>
                </ion-button>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </div>
</ion-content>
