<app-nav-bar [title]="'Devoluciones'" [start]="'GoBack'"></app-nav-bar>

<ion-content class="ion-padding" text-center>
  <!-- <ion-searchbar type="text" class="searchBar" [(ngModel)]="searchInput" placeholder='Buscar' (ionInput)="getItems($event)"></ion-searchbar> -->
  <ion-searchbar
    type="text"
    class="searchBar"
    [(ngModel)]="searchInput"
    placeholder="Buscar"
  ></ion-searchbar>

  <div style="text-align: center; margin-top: 12px" *ngIf="loadingRtnHistory">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando historial</h6>
  </div>

  <div
    style="text-align: center; margin-top: 12px"
    *ngIf="orderHistory.length < 1 && !loadingRtnHistory"
  >
    <h6 ion-text text-center style="font-size: 1rem">
      No existen solicitudes creadas.
    </h6>
  </div>

  <div
    style="
      text-align: center;
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    *ngIf="orderHistory.length > 0 && !loadingRtnHistory"
  >
    <ion-icon
      color="secondary"
      name="arrow-back"
      style="font-size: 1rem; font-weight: bold"
    ></ion-icon>
    <h6 ion-text text-center style="font-size: 1rem; margin-left: 1rem">
      Desliza la devolución para visualizar los productos.
    </h6>
  </div>

  <ion-list>
    <ion-card
      *ngFor="let order of orderHistory"
      style="
        background: transparent;
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      "
      (click)="displayItems(order)"
    >
      <ion-item-sliding
        style="border-radius: 5px; overflow: hidden"
        (swipe)="swipeEvent($event, order)"
      >
        <ion-item lines="none" class="borderStyle">
          <div style="display: flex; align-items: center">
            <div style="margin-right: 10px; font-size: 1rem">
              <i class="fas fa-sync blueColor"></i>
            </div>
            <div class="flexColumn">
              <div style="font-size: 1rem; white-space: normal" color="primary">
                <a>Estado: </a> {{ order.STATUSLABEL }}
              </div>
              <div style="font-size: 1rem; white-space: normal">
                <a>Fecha: </a> {{ order.PSCREATEDDATETIME }}
              </div>
              <!-- <h6 style="font-weight: bold;">Nombre: {{promo.Name}}</h6> -->
              <div style="font-size: 1rem; white-space: normal">
                <a style="text-transform: capitalize">Orden: </a>
                {{ order.SALESID }}
              </div>
            </div>
          </div>
        </ion-item>

        <ion-item-options side="end">
          <ion-item-option color="secondary">
            <i class="fas fa-tasks"></i>
            <span style="max-width: 70px" class="wrapword">Productos</span>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-card>
  </ion-list>
</ion-content>

<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button
    color="primary"
    class="submit-btn"
    type="submit"
    size="small"
    (click)="newReturnOrder()"
  >
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>
