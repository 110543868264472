<ion-content class="ion-padding">
  <app-timeline>
    <app-timeline-item *ngFor="let item of info">
      <app-timeline-time [time]="item.time"></app-timeline-time>
      <i class=" image {{ item.icon }}"></i>
      <ion-card>
        <ion-card-header>
          {{ item.orderState }}
        </ion-card-header>
        <ion-card-content>
          {{ item.orderDetail }}
        </ion-card-content>
      </ion-card>
    </app-timeline-item>
  </app-timeline>
</ion-content>
