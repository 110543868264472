import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Phone } from '../../businessCore/Phone';

@Component({
  selector: 'app-register-contact-number',
  templateUrl: './register-contact-number.component.html',
  styleUrls: ['./register-contact-number.component.scss'],
})
export class RegisterContactNumberComponent implements OnInit {
  public newPhone: Phone = new Phone('', '', '', false);

  contactNumber = {
    title: 'Nuevo contacto',
    phoneNumber: 'Teléfono',
    description: 'Descripción',
    main: 'Principal',
    add: 'Agregar',
  };

  contactNumberES = {
    title: 'Nuevo contacto',
    phoneNumber: 'Teléfono',
    description: 'Descripción',
    main: 'Principal',
    add: 'Agregar',
  };

  contactNumberEN = {
    title: 'New contact',
    phoneNumber: 'Phone',
    description: 'Description',
    main: 'Main number',
    add: 'Add',
  };

  constructor(
    public viewCtrl: PopoverController,
    public storage: StorageService,
    private navParams: NavParams
  ) {
    console.log(this.navParams);
  }

  /**
   *
   */
  async ngOnInit() {
    await this.storage.get('vendor_language').then((data) => {
      const language = data as string;
      if (language.toString() == 'ES') {
        this.contactNumber = this.contactNumberEN;
      } else {
        this.contactNumber = this.contactNumberES;
      }
    });
  }

  /**
   *
   */
  addPhoneNumber() {
    this.viewCtrl.dismiss(this.newPhone);
  }
}
