export class ItemByResult {
  ItemId: string = '';
  ItemName: string = '';
  UnitId: string = '';
  Amount: number = 0;
  Taxes: number = 0;
  Hierarchy5: string = '';
  Hierarchy4: string = '';
  Hierarchy3: string = '';
  Hierarchy2: string = '';
  Hierarchy1: string = '';
  SalesQuantity: number = 0;
  TotalSale: number = 0;
  ItemGroupId: string = '';
  Amount_Taxes: number = 0;
  lineDiscount: number = 0;
  linePercent: number = 0;
  TotalWithDisc: number = 0;

  /*
    0- Conditions
    1- ShoppingCar
    2- New Product(InventoryItem)
    3- 100% Item (SubType 108)
    */
  Origin: number = 0;

  constructor(
    ItemId: string,
    SalesQuantity: number,
    TotalSale: number,
    ItemGroupId: string,
    Hierarchy5: string,
    Hierarchy4: string,
    Hierarchy3: string,
    Hierarchy2: string,
    Hierarchy1: string,
    Amount: number,
    Taxes: number,
    ItemName: string,
    UnitId: string,
    Origin: number
  ) {
    this.ItemId = ItemId;
    this.SalesQuantity = SalesQuantity;
    this.TotalSale = Number.parseFloat(TotalSale.toFixed(2));
    this.ItemGroupId = ItemGroupId;
    this.Hierarchy5 = Hierarchy5;
    this.Hierarchy4 = Hierarchy4;
    this.Hierarchy3 = Hierarchy3;
    this.Hierarchy2 = Hierarchy2;
    this.Hierarchy1 = Hierarchy1;
    this.Amount = Number.parseFloat(
      Number.parseFloat(Amount.toString().replace(',', '.')).toFixed(2)
    );
    this.Taxes = Number.parseFloat(
      Number.parseFloat(Taxes.toString().replace(',', '.')).toFixed(2)
    );
    this.ItemName = ItemName;
    this.UnitId = UnitId;
    this.Origin = Origin;

    this.Amount_Taxes = Number.parseFloat(
      (this.Amount + this.Taxes).toFixed(2)
    );
  }
}
