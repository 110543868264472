import { Injectable, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { SelectFilterOption } from '../OOP/select-filter.interface';
import { SelectFilterComponent } from '../components/select-filter/select-filter.component';

@Injectable()
export class SelectFilterService implements OnInit {
  private currentModal: any;
  public loader: HTMLIonLoadingElement;
  public win: any;
  constructor(
    public modalController: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {}

  async presentModal(
    options: SelectFilterOption[],
    optionsSelected: SelectFilterOption[],
    multiple: boolean
  ) {
    let clonedOptions = JSON.parse(JSON.stringify(options));
    let clonedOptionsSelected = JSON.parse(JSON.stringify(optionsSelected));

    const modal = await this.modalController.create({
      component: SelectFilterComponent,
      componentProps: {
        optionsParam: clonedOptions,
        optionsSelectedParam: clonedOptionsSelected,
        multipleParam: multiple,
        modal: this.currentModal,
      },

      cssClass: 'setting-modal',
    });
    this.currentModal = modal;
    await modal.present();
  }

  async onDidDismiss(): Promise<any> {
    let modalPromise = await this.currentModal.onDidDismiss();
    return await modalPromise.data;
  }

  dismissModal() {
    if (this.currentModal) {
      this.currentModal.dismiss().then(() => {
        this.currentModal = null;
      });
    }
  }

  async showLoading(message: string = '') {
    if (message) {
      this.loader = await this.loadingCtrl.create({
        message: message,
        spinner: 'bubbles',
      });
      await this.loader.present();
    } else {
      await this.loader.dismiss();
    }
  }
}
