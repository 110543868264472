export const environment = {
  // ********** DEV-LAMBDA "172.26.1.150" **********
  SP_API: 'https://serviceplan-api-test.mercasacr.com', // Service Plans
  GIPlus_API: 'http://grupointecaapitest.mercasacr.com/Service1.svc', // GI_API / GI_PLUS https://grupointecaapitest.mercasacr.com/
  GWMainService_API: 'https://gwgeneralmovementstest.mercasacr.com/GW_MainService.svc', // GWMainService_API

  production: false,
  SMS_KEY: 'cm9yb3NlZ25pOkM0bG1lNTc3',
  SMS_USER: 'rorosegni',
  MAPS_KEY: 'AIzaSyClWvQSYuQUp1eYfvkSBVTI7UN02KAmhu0',
  PAYMENT_MODES: {
    CREDIT_CARD: 'TARJ_CRED',
    CASH: 'EFECTIVO',
    TRANSFER: 'TRANSFER',
  },
  FMCM_SITES: [
    {
      InventSiteId: 'ALA',
      InventLocationId: '011',
      SiteName: 'Site Alajuela',
    },
    {
      InventSiteId: 'CCA',
      InventLocationId: '003',
      SiteName: 'Site Cartago',
    },
    {
      InventSiteId: 'CPR',
      InventLocationId: '008',
      SiteName: 'Site Plaza Radio - Cartago',
    },
    {
      InventSiteId: 'CPT',
      InventLocationId: '006',
      SiteName: 'Site Cartago Pitahaya',
    },
    {
      InventSiteId: 'SFN',
      InventLocationId: '002',
      SiteName: 'Site San Francisco (Antiguas Oficinas )',
    },
    {
      InventSiteId: 'SJO',
      InventLocationId: '004',
      SiteName: 'San José',
    },
    {
      InventSiteId: 'SSH',
      InventLocationId: '012',
      SiteName: 'Sitio San Sebastián',
    },
  ],
  FMCM_SITES_NAMES: [
    {
      InventSiteId: 'ALA',
      SiteName: 'Alajuela',
    },
    {
      InventSiteId: 'CCA',
      SiteName: 'Cartago',
    },
    {
      InventSiteId: 'CPR',
      SiteName: 'Plaza Radio',
    },
    {
      InventSiteId: 'CPT',
      SiteName: 'Pitahaya',
    },
    {
      InventSiteId: 'SFN',
      SiteName: 'San Francisco',
    },
    {
      InventSiteId: 'SJO',
      SiteName: 'San José',
    },
    {
      InventSiteId: 'SSH',
      SiteName: 'San Sebastián',
    },
  ],
};
