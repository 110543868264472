import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
  NavParams,
} from '@ionic/angular';
import { CheckListInstance } from '../../../businessCore/check-models/CheckInstance';
import { CheckList } from '../../../businessCore/check-models/CheckList';
import { ExternalServiceChecklistProvider } from '../../../provider/Services/external-service-checklist';
import { ServicesLocalCheckProvider } from '../../../provider/Services/services-local-check';

@Component({
  selector: 'app-checklist-task',
  templateUrl: './checklist-task.component.html',
  styleUrls: ['./checklist-task.component.scss'],
})
export class ChecklistTaskPage {
  private checkListID: string;
  private checkListInstance: CheckListInstance;
  checkList: CheckList;
  private presentingMess: boolean;
  loader: HTMLIonLoadingElement;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private webService: ExternalServiceChecklistProvider,
    public alertController: AlertController,
    private loadingCtrl: LoadingController,
    private localService: ServicesLocalCheckProvider
  ) {
    this.checkListInstance = navParams.get('checkListInst');
    if (this.checkListInstance) {
      this.checkListID = this.checkListInstance.getCheckList();
    }
    this.presentingMess = false;
  }

  async ngOnInit() {
    if (this.checkListID) {
      await this.showSpinnerLoading('Cargando datos de checklist.');
      let result;
      if (!this.checkListInstance.getInLocal()) {
        result = await this.webService.getMyCheckListInfo(this.checkListID);
      } else {
        result = await this.localService.getInfoCheckList(this.checkListID);
      }
      //DEBUG
      /*
      result = [new CheckList('123', '111', '11')];
      let x = new TaskCheck('asda', 'aslkdka', 'asdasd');
      //x.setAttachecd(true);
      x.setOmissionTaskList([new OmissionTask('123', '1123')]);
      x.setAnswerTaskList([new AnswerTask('123', '1123')]);
      result[0].setTasksList([x]);
      */
      //DEBUG
      this.dissmissLoading();
      if (result) {
        const checksList = result as CheckList[];
        if (checksList.length > 0) {
          this.checkList = checksList[0];
        } else {
          // No hay datos de respuestas
          this.messageInfo(
            'Error',
            'No se pudo traer la información del checklist.'
          );
        }
      } else {
        const mensaje = this.checkListInstance.getInLocal()
          ? 'No se pudo traer la información local del checklist.'
          : 'No se pudo comunicar con el servidor.';
        this.messageInfo('Error', mensaje);
      }
    }
  }

  ionViewDidLoad() {
    // console.log('ionViewDidLoad ChecklistAnswerPage');
  }

  executeAction(type: number) {
    switch (type) {
      case 1:
        this.aux_sendAnswerCheckList();
        break;
      case 2:
        this.aux_saveLocalData();
        break;
      default:
        break;
    }
  }

  async aux_sendAnswerCheckList() {
    //console.log(
    //'VatNum: ',
      //this.checkListInstance.getVatNum(),
      //' instanceRecId ',
      //this.checkListInstance.getInstanceRecId(),
      //', CheckList: ',
      //this.checkList,
      //' , checkAnser: ',
      //this.checkList.toJsonTaskAnswer()
    //);
    // cedula, recId de instancia y el id del checkList
    //Agregar la instancia al encabezado
    let resp: any;
    let sendCont: number = 0;
    await this.showSpinnerLoading('Enviando datos de respuestas.');
    //Instance
    //vatNum
    //answer---
    //info64
    //json de info image
    // for (const iterator of this.checkList.getTasksList()) {
    for (let i = 0; i < this.checkList.getTasksList().length; i++) {
      /* if(iterator.getAttached() &&
      (iterator.getImageData() !== null && iterator.getImageData() !== undefined)) {
        console.log(
          " , VatNum: ", this.checkListInstance.getVatNum(),
          " , instanceRecId ", this.checkListInstance.getInstanceRecId(),
          " , CheckList: ", this.checkList,
          " , checkAnswer: " ,JSON.stringify(iterator.toJSON()),
          " , infoTask ",JSON.stringify(iterator.getImageData().toJSON()),
          " , info64 ", iterator.getImageData().dataImage);
      } else {
        console.log(
          ", VatNum: ", this.checkListInstance.getVatNum(),
          ", instanceRecId ", this.checkListInstance.getInstanceRecId(),
          ", CheckList: ", this.checkList,
          ", checkAnswer: " ,,
          ", infoTask ","---",
          ", info64 ", "---");
      }*/
      const iterator = this.checkList.getTasksList()[i];
      try {
        if (
          iterator.getAttached() &&
          iterator.getImageData() !== null &&
          iterator.getImageData() !== undefined
        ) {
          const listInfo: any[] = [];
          listInfo.push(iterator.toJSON());
          iterator.getImageData().vatnum = this.checkListInstance.getVatNum();
          resp = await this.webService.sendAnswerOnlyOne(
            this.checkListInstance.getInstanceRecId().toString(),
            this.checkListInstance.getVatNum(),
            JSON.stringify(listInfo),
            iterator.getImageData().dataImage,
            JSON.stringify(iterator.getImageData().toJSON())
          );
        } else {
          const listInfo: any[] = [];
          listInfo.push(iterator.toJSON());
          resp = await this.webService.sendAnswerOnlyOne(
            this.checkListInstance.getInstanceRecId().toString(),
            this.checkListInstance.getVatNum(),
            JSON.stringify(listInfo),
            '',
            ''
          );
        }
      } catch (error) {
        resp = error;
      }
      if (typeof resp === 'number') {
        // this.messageInfo("Error", "No se puedo comunicar con el servidor, intentelo de nuevo.");
      } else if (typeof resp === 'string' && resp === 'timeOut') {
        // this.messageInfo("Error", "No se puedo comunicar con el servidor, intentelo de nuevo.");
      } else if (typeof resp === 'string' && resp === 'FAILED') {
        // this.messageInfo("Error", "No se puedo almacenar las respuestas, intentelo de nuevo.");
      } else if (typeof resp === 'string' && resp === 'SUCCESS') {
        // Se almacenaron las respuestas.
        // Se pone como ejecutado el checklist y se crea el siguiente.
        // this.checkListInstance.showInstance = false;
        // this.messageInfo("Atención", "Las respuestas fueron almacenadas de manera correcta.", -1);
        sendCont++;
      } else if (typeof resp === 'string' && resp === 'SUCCESS-NOAD') {
        // traer el recId para guardar
        sendCont++;
      }
    }
    //Si el contador de sussccess es == a las respuestas
    /* try {
      resp = await this.webService.sendAnswer(
        this.checkListInstance.getInstanceRecId().toString(),
        this.checkListInstance.getVatNum(),
        this.checkList.toJsonTaskAnswer()
      );
    } catch (error) {
      resp = error;
    }*/
    //Si el contador de sussccess es == a las respuestas

    this.dissmissLoading();

    /* if(typeof(resp) === "number") {
      this.messageInfo("Error", "No se puedo comunicar con el servidor, intentelo de nuevo.");
    } else if (typeof(resp) === "string" && resp === "timeOut"){
      this.messageInfo("Error", "No se puedo comunicar con el servidor, intentelo de nuevo.");
    } else if (typeof(resp) === "string" && resp === "FAILED") {
      this.messageInfo("Error", "No se puedo almacenar las respuestas, intentelo de nuevo.");
    } else if (typeof(resp) === "string" && resp === "SUCCESS") {
      // Se almacenaron las respuestas.
      // Se pone como ejecutado el checklist y se crea el siguiente.
      this.checkListInstance.showInstance = false;
      this.messageInfo("Atención", "Las respuestas fueron almacenadas de manera correcta.", -1);
    }*/

    if (sendCont === this.checkList.getTasksList().length) {
      let resultChan: any = 0;
      try {
        resultChan = await this.webService.changeStateInstance(
          this.checkListInstance.getInstanceRecId(),
          'EJECUTADO',
          ''
        );
      } catch (error) {
        resultChan = error;
      }

      if (typeof resultChan === 'number') {
        this.messageInfo(
          'Error',
          'No se puedo comunicar con el servidor, intentelo de nuevo.'
        );
      } else if (typeof resultChan === 'string' && resultChan === 'timeOut') {
        this.messageInfo(
          'Error',
          'No se puedo comunicar con el servidor, intentelo de nuevo.'
        );
      } else if (typeof resultChan === 'string' && resultChan === 'FAILED') {
        this.messageInfo(
          'Error',
          'No se puedo generar la siguiente instancia, intentelo de nuevo.'
        );
      } else if (typeof resultChan === 'string' && resultChan === 'SUCCESS') {
        // Se almacenaron las respuestas.
        // Se pone como ejecutado el checklist y se crea el siguiente.
        this.checkListInstance.showInstance = false;
        this.checkListInstance.setEstado(2);
        this.messageInfo(
          'Atención',
          'Las respuestas fueron almacenadas de manera correcta.',
          -1
        );
      }
    } else {
      this.messageInfo(
        'Atención',
        'Algunas respuestas no fueron enviadas de forma correcta.'
      );
      //Se tiene que volver a intentar guardar
    }
  }

  sendAnswerCheckList() {
    const cantNoEvluadas = this.checkList
      .getTasksList()
      .filter((data) => data.state === 0);

    if (cantNoEvluadas.length === 0) {
      // Todas las tareas fueron evaluadas
      // Se genera la informacion a enviar.
      let resultAd = this.evaluarAdguntos();
      if (resultAd[0] === true) {
        this.messageInfo(
          'Atención',
          '¿Desea enviar las respuestas del checklist?',
          1
        );
      } else {
        this.messageInfo('Atención', resultAd[1] as string);
      }
    } else {
      this.messageInfo('Atención', 'Algunas tareas no han sido marcadas aún.');
    }
  }

  evaluarAdguntos() {
    let result = true;
    let mensaje = '';
    const cantAdjuntos = this.checkList
      .getTasksList()
      .filter((data) => data.getAttached());
    if (cantAdjuntos.length > 0) {
      const cantSaved = this.checkList
        .getTasksList()
        .filter((data) => !data.savedImage());
      if (cantSaved.length <= cantAdjuntos.length && cantSaved.length > 0) {
        mensaje = 'Faltan adjuntar la imagen en las tareas: \n';
        for (const iterator of cantSaved) {
          mensaje = mensaje + iterator.getOrderTask().toString() + ',';
        }
        if (mensaje.slice(mensaje.length - 1, mensaje.length) === ',') {
          mensaje = mensaje.substring(0, mensaje.length - 1);
        }
        result = false;
      }
    }

    return [result, mensaje];
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action > 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.executeAction(action);
          },
        },
        { text: 'NO', handler: () => {} },
      ];
    } else if (action && action === -1) {
      botones = [
        {
          text: 'ACEPTAR',
          handler: () => {
            // Se borra la info local de este checklist
            this.deleteLocalData(
              this.checkListInstance.inLocal,
              this.checkListInstance.getInstanceRecId(),
              this.checkListID
            );
            this.navCtrl.pop();
          },
        },
      ];
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async saveData() {
    // Guardar instancia.
    this.messageInfo('Atención', '¿Desea guardar los datos de avance?', 2);
  }

  async aux_saveLocalData() {
    await this.showSpinnerLoading('Guardando datos de checklist actual...');
    if (!this.checkListInstance.getInLocal()) {
      const result = await this.localService.saveInstanceCheckList(
        this.checkListInstance
      );
      if (result) {
        await this.localService.saveInfoCheckList(this.checkList);
      }
    } else {
      const result = await this.localService.updateInstanceCheckList(
        this.checkListInstance
      );
      if (result) {
        await this.localService.updateInfoCheckList(this.checkList);
      }
    }
    this.dissmissLoading();
  }

  async deleteLocalData(
    local: boolean,
    checkInstanceRecID: number,
    checkListID: string
  ) {
    if (local) {
      const result = await this.localService.deleteInstanceCheckList(
        checkInstanceRecID
      );
      if (result) {
        await this.localService.deleteInfoCheckList(checkListID);
      }
    }
  }
}
