import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  ModalController,
  NavController,
  NavParams,
  Platform,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Address } from '../../businessCore/Address';
import { Bank } from '../../businessCore/Bank';
import { Currency } from '../../businessCore/Currency';
import { EntityName } from '../../businessCore/EntityName';
import { IdentifierType } from '../../businessCore/IdentifierType';
import { PaymentTerm } from '../../businessCore/PaymentTerm';
import { RegisterData } from '../../businessCore/RegisterData';
import { VendorRegisterData } from '../../businessCore/VendorRegisterData';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { Vendor } from '../../businessCore/vendor';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';
import { VendorAlertComponent } from '../vendor-alert/vendor-alert.component';
import { VendorRegisterLanguageComponent } from '../vendor-register-language/vendor-register-language.component';

@Component({
  selector: 'app-vendor-register',
  templateUrl: './vendor-register.component.html',
  styleUrls: ['./vendor-register.component.scss'],
})
export class VendorRegisterComponent implements OnInit {
  actualScreen: number;

  changeLanguageTo: string;

  languageData = {};

  languageDataVR = {
    errorAlertTitle: '',
    errorAlertSubtitle: '',
    errorAlertBtn: '',
    successAlertTitle: '',
    successAlertSubtitle: '',
    successAlertBtn: '',
  };

  vendorRegisterData: VendorRegisterData = new VendorRegisterData();

  generalData: any = {
    company: new DataArea('CMER', 'MERCADEO DE ARTÍCULOS DE CONSUMO'),
    vendorIdType: new IdentifierType('', '', '', '', 0),
    vendorVatnum: '',
    vendorEntityName: new EntityName(),
    representIdType: new IdentifierType('', '', '', '', 0),
    representEntityName: new EntityName(),
    representVatnum: '',
  };

  paymentData: any = {
    paymentTerm: new PaymentTerm('', ''),
    bank: new Bank('', ''),
    currency: new Currency('', ''),
    bankAccount: '',
    ABANumber: '',
    SWIFTCode: '',
  };

  contactData: any = {
    phones: [],
    emails: [],
    contacts: [],
  };

  addressData: Address[] = [];

  documentData: any = {
    vatNumVendor: [],
    vatNumRepres: [],
    vatNumReprInvoice: [],
    vatNumReprService: [],
    vendorAgreement: [],
    transferAuthorization: [],
    guaranteePolicies: [],
    acceptVendorAgreement: false,
  };

  loggerVatnum: string; //Cédula del logger.

  auth: number;
  componentId: any;

  constructor(
    public vrLang: VendorRegisterLanguageComponent,
    public storage: StorageService,
    private modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    public platform: Platform,
    public authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService,
    public navParams: NavParams,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.auth = Number(this.navParams.get('auth'));
    this.componentId = this.navParams.get('code');

    if (this.auth === 0 && this.authService.hasCookie()) {
      this.navService.setRoot(
        this.activatedRoute.component.name as NavigationRoutes,
        {
          auth: 1,
          code: this.componentId,
        }
      );
    } else {
      if (this.auth === 1) {
        let checkedToken = await this.authGuard.checkToken();
        if (
          checkedToken &&
          this.authGuard.componentAllow(
            this.authService.getComponentsInfo(),
            this.componentId
          )
        ) {
          this.loggerVatnum =
            this.authService.getTokenData('refresh')['vat-num'];
          console.log(this.loggerVatnum);
        } else {
          window.location.href = 'http://giiis01:8025/';
        }
      } else {
        this.storage.get('register_data').then((data) => {
          const register_data = JSON.parse(data) as RegisterData;
          this.loggerVatnum = register_data.id_card;
        });
      }

      this.generalData.creatorVatnum = this.loggerVatnum;
      this.actualScreen = 1;
      this.changeLanguageTo = 'EN';
      // if (await this.storage.get('vendorRegisterData')) {
      //   console.log("No tengo nada");
      // }else {
      //   await this.storage.set('vendorRegisterData', this.vendorRegisterData);
      // }
      await this.storage.set('vendor_language', this.changeLanguageTo);
      this.languageData = this.vrLang.getData(
        this.changeLanguageTo,
        this.actualScreen
      );
    }
  }

  /**
   *
   * @param pNewLanguage
   */
  async translate(pNewLanguage) {
    this.changeLanguageTo = pNewLanguage;
    await this.storage.set('vendor_language', pNewLanguage);
    this.getLanguageData();
    this.getVRLanguageData();
  }

  /**
   *
   */
  async getLanguageData() {
    this.languageData = this.vrLang.getData(
      this.changeLanguageTo,
      this.actualScreen
    );
  }

  async getVRLanguageData() {
    this.languageDataVR = this.vrLang.getVendorRegisterData(
      this.changeLanguageTo
    );
  }

  async setVendorRegisterData(pCondition) {
    switch (pCondition) {
      case 1:
        this.vendorRegisterData.getGeneralData();
        this.vendorRegisterData.setGeneralData(this.generalData);
        break;
      case 2:
        this.vendorRegisterData.setPaymentData(this.paymentData);
        break;
      case 3:
        this.vendorRegisterData.setContactData(this.contactData);
        break;
      case 4:
        this.vendorRegisterData.setAddressData(this.addressData);
        break;
      case 5:
        this.vendorRegisterData.setDocumentData(this.documentData);
        break;
      default:
        break;
    }
    this.storage.set('vendorRegisterData', this.vendorRegisterData);
  }

  /**
   *
   * @param pScreen
   */
  async changeScreen(pScreen: number) {
    this.setVendorRegisterData(this.actualScreen);
    this.actualScreen = pScreen;
    this.getLanguageData();
    this.getVRLanguageData();
  }

  /**
   *
   * @param yes
   */
  async registerVendor() {
    this.setVendorRegisterData(5);
    let vendor: Vendor = new Vendor();
    vendor = vendor.parseRegisterData(this.vendorRegisterData);
    this.vendorLoading(vendor, this.vendorRegisterData, this.changeLanguageTo);
  }

  /**
   *
   * Muestra un popover donde se elige.
   *
   */
  async vendorLoading(pVendor, pRegisterData, pLanguage) {
    const modal = await this.modalCtrl.create({
      component: VendorAlertComponent,
      componentProps: {
        vendor: pVendor,
        registerData: pRegisterData,
        actualLanguage: pLanguage,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data == '1') {
          this.showAlert(
            this.languageDataVR.successAlertTitle,
            this.languageDataVR.successAlertSubtitle,
            this.languageDataVR.successAlertBtn,
            1
          );
        } else {
          this.showAlert(
            this.languageDataVR.errorAlertTitle,
            this.languageDataVR.errorAlertSubtitle,
            this.languageDataVR.errorAlertBtn,
            2
          );
        }
      }
    });
  }

  async showAlert(pTitle, pSubTitle, pText, pCase) {
    let alert = await this.alertCtrl.create({
      header: pTitle,
      subHeader: pSubTitle,
      buttons: [
        {
          text: pText,
          handler: () => {
            if (pCase == 1) {
              this.navService.navigateTo(
                NavigationRoutes.VendorRegisterComponent
              );
            }
          },
        },
      ],
    });
    await alert.present();
  }
}
