<app-nav-bar [title]="'Carrito - Pago'"></app-nav-bar>

<ion-content class="ion-padding">
  <ion-item>
    <ion-badge
      style="text-align: center; background: blue; margin: auto; padding: 3px"
    >
      <h6 style="color: aliceblue; font-weight: bold; margin: 0px">3/3</h6>
    </ion-badge>
  </ion-item>
  <ion-item>
    <div style="width: 100%; display: flex; justify-content: center">
      <h5 ion-text text-center text-wrap>
        Total de la orden: {{ setCurrency(totalOrder) }}
      </h5>
    </div>
  </ion-item>

  <div style="display: flex; justify-content: center">
    <div style="text-align: center">
      <span
        style="font-size: 1rem !important; color: brown; text-align: justify"
      >
        Seleccione el <a>Tipo de Pago</a> y Acepte términos y condiciones para
        poder finalizar su pedido .</span
      >
    </div>
  </div>

  <ion-item lines="none" style="padding-left: 13px; padding-right: 13px">
    <ion-select
      label="Tipo de Pago"
      label-placement="floating"
      class="floating"
      [(ngModel)]="paymentType"
      style="border: 0px; width: 100%"
    >
      <ion-select-option value="DefaultPaymentType">Elegir</ion-select-option>
      <ion-select-option value="CreditCard">Tarjeta</ion-select-option>
      <ion-select-option value="Transfer">Transferencia</ion-select-option>
    </ion-select>
  </ion-item>

  <div style="display: flex; justify-content: center">
    <div *ngIf="paymentType == 'Transfer'" class="transferContainer">
      <span
        style="font-size: 1rem !important; color: brown; text-align: justify"
      >
        Antes de que su pedido sea procesado el pago ya debe haberse realizado!
        Recuerde guardar un comprobante de la transferencia.</span
      >
      <h6 ion-text style="color: #223e99">Nuestra Cuenta</h6>
      <span style="font-size: 1rem !important; text-decoration: underline"
        >Cuenta IBAN:</span
      >
      <span style="font-size: 1rem !important; margin-left: 5px"
        >CR74015201001016569294</span
      >
      <span
        style="
          font-size: 1rem !important;
          text-decoration: underline;
          margin-top: 5px;
        "
        >A nombre de:</span
      >
      <span style="font-size: 1rem !important; margin-left: 5px"
        >Mercadeo de Articulos de Consumo S.A.</span
      >
      <span style="font-size: 1rem !important; margin-left: 5px"
        >3-101-137584</span
      >
    </div>
  </div>

  <!-- Terms and Conditions -->
  <div style="padding: 6px; margin-left: 8px; display: flex">
    <label class="container">
      <input type="checkbox" [(ngModel)]="terms_conditions" />
      <span class="checkmark"></span>
    </label>
    <span style="text-decoration: underline" (click)="showTerms()"
      >Aceptar términos y condiciones de uso</span
    >
  </div>

  <!-- Terms and Conditions Credentials -->
  <div style="padding: 6px; margin-left: 8px; display: flex">
    <label class="container">
      <input type="checkbox" [(ngModel)]="credential_terms" />
      <span class="checkmark"></span>
    </label>
    <span style="text-decoration: underline" (click)="showCredentialTerms()"
      >Aceptar acuerdo de responsabilidad de credenciales</span
    >
  </div>

  <!-- Slide to unlock -->
  <div
    style="text-align: center; margin-top: 5px"
    *ngIf="
      terms_conditions &&
      credential_terms &&
      minimunLoaded &&
      paymentType != 'DefaultPaymentType'
    "
  >
    <input
      #unlock
      type="range"
      class="slideToUnlock"
      value="0"
      max="100"
      (touchend)="checkUnlock($event)"
      (mouseout)="checkUnlock($event)"
    />
  </div>
</ion-content>

<!-- Back button -->
<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button (click)="dismiss()" mini>
    <ion-icon name="chevron-back"></ion-icon>
  </ion-fab-button>
</ion-fab>
