import { Component } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';

import { StorageService } from 'src/app/GeneralUtilis/Storage';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';
import { InventoryItem } from '../../businessCore/InventoryItem';
import { ItemByResult } from '../../businessCore/ItemByResult';
import { OrderLine } from '../../businessCore/OrderLine';
import { PromoConditionGroup } from '../../businessCore/PromoConditionGroup';
import { PromoConditionsListView } from '../../businessCore/PromoConditionsListView';
import { PromoResultsViews } from '../../businessCore/PromoResultsViews';
import { PromoTableView } from '../../businessCore/PromoTableView';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { PrevShoppingCarComponent } from '../prev-shopping-car/prev-shopping-car.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';

@Component({
  selector: 'app-promo-result',
  templateUrl: './promo-result.component.html',
  styleUrls: ['./promo-result.component.scss'],
})
export class PromoResultComponent {
  promo: PromoTableView;

  // PromoConditions by Group
  PromoConditionGroup: PromoConditionGroup[];

  // Inventory Items (showed in the home page)
  itemInfo: InventoryItem[] = [];

  // Optional Results
  optionalResult: PromoResultsViews[] = [];
  validOptionalResult: boolean = false;
  // Optional Exclusive Results
  optionalExclResult: PromoResultsViews[] = [];
  validOptionalExclResult: boolean = false;
  // Mandatories Results
  mandatoryResult: PromoResultsViews[] = [];
  validMandatoryResult: boolean = false;

  // Shopping Car Array
  shoppingCar: OrderLine[] = [];

  // Temporal Shopping car
  tmpShoppingCar: OrderLine[] = [];

  // Shopping Car Total Amount
  shoppingCarTotalAmount: number = 0;

  // Control variable
  itemDetailExecuting: boolean = false;

  constructor(
    private params: NavParams,
    public viewCtrl: ModalController,
    private toastCtrl: ToastController,
    private storage: StorageService,
    private webProvider: webServiceProvider,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private globalEventsService: GlobalEventsService
  ) {
    // Get Params
    this.promo = this.params.get('promoHeader');
    this.PromoConditionGroup = this.params.get('promoConditionGroup');
    this.itemInfo = this.params.get('itemInfo');

    console.log(this.params);

    this.getShoppingCar();

    // this.initPromo();
  }

  initPromo() {
    /*
    Define Selected attribute based on ConditionOr >>
    0- Obligatorio
    1- Opcional
    2- Opcional Exclusivo
    */
    this.promo.PromoResults.forEach((pr) => {
      pr.Selected = pr.ConditionOr == 0 ? true : false;
      pr.Showed = false;
    });

    this.generateResultDescription();

    this.mandatoryResult = this.promo.PromoResults.filter(
      (pr) => pr.ConditionOr == 0
    );
    this.optionalResult = this.promo.PromoResults.filter(
      (pr) => pr.ConditionOr == 1
    );
    this.optionalExclResult = this.promo.PromoResults.filter(
      (pr) => pr.ConditionOr == 2
    );

    this.validMandatoryResult = this.mandatoryResult.length > 0;
    this.validOptionalResult = this.optionalResult.length > 0;
    this.validOptionalExclResult = this.optionalExclResult.length > 0;

    /*
    Sort results according with the Operator value (desc)
    */
    this.mandatoryResult.sort(function (r1, r2) {
      return r1.Operator - r2.Operator;
    });
    this.optionalResult.sort(function (r1, r2) {
      return r1.Operator - r2.Operator;
    });
    this.optionalExclResult.sort(function (r1, r2) {
      return r1.Operator - r2.Operator;
    });

    this.associateItemsByResult();
  }

  associateItemsByResult() {
    this.mandatoryResult.forEach((res) => {
      res.ItemByResult = [];
      this.getItemsBySubType(res).forEach((ir) => {
        /*
        0- Sin defnir
        2- % Descuento
        3- NO Aplica
        4- Monto sobre la unidad
        */
        switch (res.QtyType) {
          case 2:
            ir.linePercent = res.Operator == 1 ? res.QtyValue : res.QtyValueTo;
            ir.lineDiscount = Number.parseFloat(
              ((ir.TotalSale * ir.linePercent) / 100).toFixed(2)
            );
            ir.TotalWithDisc = Number.parseFloat(
              (ir.TotalSale - ir.lineDiscount).toFixed(2)
            );
            break;

          case 4:
            ir.linePercent =
              res.Operator == 1
                ? Number.parseFloat(
                    ((res.QtyValue * 100) / ir.TotalSale).toFixed(2)
                  )
                : Number.parseFloat(
                    ((res.QtyValueTo * 100) / ir.TotalSale).toFixed(2)
                  );
            ir.lineDiscount = Number.parseFloat(
              ((ir.TotalSale * ir.linePercent) / 100).toFixed(2)
            );
            ir.TotalWithDisc = Number.parseFloat(
              (ir.TotalSale - ir.lineDiscount).toFixed(2)
            );
            break;
        }
        res.ItemByResult.push(ir);
      });
    });

    this.optionalResult.forEach((res) => {
      res.ItemByResult = [];
      this.getItemsBySubType(res).forEach((ir) => {
        /*
        0- Sin defnir
        2- % Descuento
        3- NO Aplica
        4- Monto sobre la unidad
        */
        switch (res.QtyType) {
          case 2:
            ir.linePercent = res.Operator == 1 ? res.QtyValue : res.QtyValueTo;
            ir.lineDiscount = Number.parseFloat(
              ((ir.TotalSale * ir.linePercent) / 100).toFixed(2)
            );
            ir.TotalWithDisc = Number.parseFloat(
              (ir.TotalSale - ir.lineDiscount).toFixed(2)
            );
            break;

          case 4:
            ir.linePercent =
              res.Operator == 1
                ? Number.parseFloat(
                    ((res.QtyValue * 100) / ir.TotalSale).toFixed(2)
                  )
                : Number.parseFloat(
                    ((res.QtyValueTo * 100) / ir.TotalSale).toFixed(2)
                  );
            ir.lineDiscount = Number.parseFloat(
              ((ir.TotalSale * ir.linePercent) / 100).toFixed(2)
            );
            ir.TotalWithDisc = Number.parseFloat(
              (ir.TotalSale - ir.lineDiscount).toFixed(2)
            );
            break;
        }
        res.ItemByResult.push(ir);
      });
    });

    this.optionalExclResult.forEach((res) => {
      res.ItemByResult = [];
      this.getItemsBySubType(res).forEach((ir) => {
        /*
        0- Sin defnir
        2- % Descuento
        3- NO Aplica
        4- Monto sobre la unidad
        */
        switch (res.QtyType) {
          case 2:
            ir.linePercent = res.Operator == 1 ? res.QtyValue : res.QtyValueTo;
            ir.lineDiscount = Number.parseFloat(
              ((ir.TotalSale * ir.linePercent) / 100).toFixed(2)
            );
            ir.TotalWithDisc = Number.parseFloat(
              (ir.TotalSale - ir.lineDiscount).toFixed(2)
            );
            break;

          case 4:
            ir.linePercent =
              res.Operator == 1
                ? Number.parseFloat(
                    ((res.QtyValue * 100) / ir.TotalSale).toFixed(2)
                  )
                : Number.parseFloat(
                    ((res.QtyValueTo * 100) / ir.TotalSale).toFixed(2)
                  );
            ir.lineDiscount = Number.parseFloat(
              ((ir.TotalSale * ir.linePercent) / 100).toFixed(2)
            );
            ir.TotalWithDisc = Number.parseFloat(
              (ir.TotalSale - ir.lineDiscount).toFixed(2)
            );
            break;
        }
        res.ItemByResult.push(ir);
      });
    });

    console.log(this.mandatoryResult);
    console.log(this.optionalResult);
    console.log(this.optionalExclResult);
  }

  getItemsBySubType(res: PromoResultsViews): ItemByResult[] {
    let result: ItemByResult[] = [];
    let itemInConditions: ItemByResult[] = this.getPromoItemSelected();
    /*
    0- Sin definir
    10- Sobre articulos del grupo y lista
    11- Sobre articulos del grupo
    100- sobre cod de articulo
    101- sobre lista de condiciones
    102- sobre total
    103- sobre fam
    104- sobre sub fam
    105- sobre cat
    106- sobre sub cat
    107- sobre sub sub cat
    108- 100% sobre cod de articulo
    */
    switch (res.SubType) {
      // 100- sobre cod de articulo
      case 100:
        // Get items in the shopping car
        this.getShopLine_WithoutPromoID().forEach((ol) => {
          if (ol.itemId == res.SubTypeValue) {
            let item = this.itemInfo.filter((i) => i.ItemId == ol.itemId);
            if (item.length > 0) {
              let i = item[0];
              // utilizado solamente para saber si el itemid se encuentra en condiciones
              let itemConditionAux = itemInConditions.filter(
                (ic) => ic.ItemId == ol.itemId
              );

              // Toma el item exacto que cumple con la unidad y id de la condicion
              let itemCondition = itemInConditions.filter(
                (ic) => ic.ItemId == ol.itemId && ic.UnitId == ol.unitId
              );

              // Si esta en las condiciones coloca cantidades con que cumplio la condicion
              // Debe cumplir que solamente exista la cantidad de veces que esta en las condiciones, no mas
              if (
                itemCondition.length > 0 &&
                result.filter(
                  (r) =>
                    r.ItemId == itemCondition[0].ItemId &&
                    r.UnitId == itemCondition[0].UnitId
                ).length < itemCondition.length
              ) {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    itemCondition[0].SalesQuantity,
                    itemCondition[0].TotalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    itemCondition[0].Amount,
                    itemCondition[0].Taxes,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              }
              // Si existe alguno que cumple con la condicion de unidad e itemid entonces para este que no esta en las condiciones no lo despliega como resultado
              else if (itemConditionAux.length <= 0) {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    ol.salesQuantity,
                    ol.totalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    ol.salePrice,
                    ol.tax,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              }
            }
          }
        });
        // Get items in the conditions
        if (result.length <= 0) {
          this.getPromoItemSelected().forEach((ibr) => {
            if (ibr.ItemId == res.SubTypeValue) {
              result.push(ibr);
            }
          });
        }
        // Get new items from InventoryItems
        if (result.length <= 0) {
          let item = this.itemInfo.filter((i) => i.ItemId == res.SubTypeValue);
          if (item.length > 0) {
            let i = item[0];
            result.push(
              new ItemByResult(
                i.ItemId,
                0,
                0,
                i.ItemGroupId,
                i.Hierarchy5,
                i.Hierarchy4,
                i.Hierarchy3,
                i.Hierarchy2,
                i.Hierarchy1,
                i.Amount,
                i.taxes,
                i.ItemName,
                i.UnitId,
                2
              )
            );
          }
        }

        break;

      // 101- sobre lista de condiciones
      case 101:
        // ListCondition Name
        this.getItemsByConditionList(res.SubTypeValue).forEach((r) => {
          let inserted: boolean = false;

          // Get items in the shopping car
          this.getShopLine_WithoutPromoID().forEach((ol) => {
            if (ol.itemId == r.Value) {
              let item = this.itemInfo.filter((i) => i.ItemId == ol.itemId);
              if (item.length > 0) {
                let i = item[0];
                let itemCondition = itemInConditions.filter(
                  (ic) => ic.ItemId == i.ItemId && ic.UnitId == i.UnitId
                );
                // Si esta en las condiciones coloca cantidades con que cumplio la condicion
                if (itemCondition.length > 0) {
                  result.push(
                    new ItemByResult(
                      ol.itemId,
                      itemCondition[0].SalesQuantity,
                      itemCondition[0].TotalSale,
                      i.ItemGroupId,
                      i.Hierarchy5,
                      i.Hierarchy4,
                      i.Hierarchy3,
                      i.Hierarchy2,
                      i.Hierarchy1,
                      itemCondition[0].Amount,
                      itemCondition[0].Taxes,
                      ol.itemName,
                      ol.unitId,
                      1
                    )
                  );
                } else {
                  result.push(
                    new ItemByResult(
                      ol.itemId,
                      ol.salesQuantity,
                      ol.totalSale,
                      i.ItemGroupId,
                      i.Hierarchy5,
                      i.Hierarchy4,
                      i.Hierarchy3,
                      i.Hierarchy2,
                      i.Hierarchy1,
                      ol.salePrice,
                      ol.tax,
                      ol.itemName,
                      ol.unitId,
                      1
                    )
                  );
                }

                inserted = true;
              }
            }
          });

          if (!inserted) {
            result.push(
              new ItemByResult(
                r.Value,
                r.SaleQty,
                r.TotalSale,
                r.ItemGroupId,
                '',
                '',
                '',
                '',
                '',
                r.Amount,
                r.Taxes,
                r.ItemName,
                r.UnitId,
                0
              )
            );
          }
        });
        break;
      // 103- sobre fam
      case 103:
        // Get items in the shopping car
        this.getShopLine_WithoutPromoID().forEach((ol) => {
          let item = this.itemInfo.filter((i) => i.ItemId == ol.itemId);
          if (item.length > 0) {
            let i = item[0];
            if (i.Hierarchy1.toUpperCase() == res.SubTypeValue.toUpperCase()) {
              let itemCondition = itemInConditions.filter(
                (ic) => ic.ItemId == i.ItemId && ic.UnitId == i.UnitId
              );
              // Si esta en las condiciones coloca cantidades con que cumplio la condicion
              if (itemCondition.length > 0) {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    itemCondition[0].SalesQuantity,
                    itemCondition[0].TotalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    itemCondition[0].Amount,
                    itemCondition[0].Taxes,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              } else {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    ol.salesQuantity,
                    ol.totalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    ol.salePrice,
                    ol.tax,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              }
            }
          }
        });
        // Get items in the conditions
        if (result.length <= 0) {
          this.getPromoItemSelected().forEach((ibr) => {
            if (
              ibr.Hierarchy1.toUpperCase() == res.SubTypeValue.toUpperCase()
            ) {
              result.push(ibr);
            }
          });
        }
        // Get new items from InventoryItems
        if (result.length <= 0) {
          this.itemInfo
            .filter(
              (i) =>
                i.Hierarchy1.toUpperCase() == res.SubTypeValue.toUpperCase()
            )
            .forEach((i) => {
              result.push(
                new ItemByResult(
                  i.ItemId,
                  0,
                  0,
                  i.ItemGroupId,
                  i.Hierarchy5,
                  i.Hierarchy4,
                  i.Hierarchy3,
                  i.Hierarchy2,
                  i.Hierarchy1,
                  i.Amount,
                  i.taxes,
                  i.ItemName,
                  i.UnitId,
                  2
                )
              );
            });
        }
        break;
      // 104- sobre sub fam
      case 104:
        // Get items in the shopping car
        this.getShopLine_WithoutPromoID().forEach((ol) => {
          let item = this.itemInfo.filter((i) => i.ItemId == ol.itemId);
          if (item.length > 0) {
            let i = item[0];
            if (i.Hierarchy2.toUpperCase() == res.SubTypeValue.toUpperCase()) {
              let itemCondition = itemInConditions.filter(
                (ic) => ic.ItemId == i.ItemId && ic.UnitId == i.UnitId
              );
              // Si esta en las condiciones coloca cantidades con que cumplio la condicion
              if (itemCondition.length > 0) {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    itemCondition[0].SalesQuantity,
                    itemCondition[0].TotalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    itemCondition[0].Amount,
                    itemCondition[0].Taxes,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              } else {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    ol.salesQuantity,
                    ol.totalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    ol.salePrice,
                    ol.tax,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              }
            }
          }
        });
        // Get items in the conditions
        if (result.length <= 0) {
          this.getPromoItemSelected().forEach((ibr) => {
            if (
              ibr.Hierarchy2.toUpperCase() == res.SubTypeValue.toUpperCase()
            ) {
              result.push(ibr);
            }
          });
        }
        // Get new items from InventoryItems
        if (result.length <= 0) {
          this.itemInfo
            .filter(
              (i) =>
                i.Hierarchy2.toUpperCase() == res.SubTypeValue.toUpperCase()
            )
            .forEach((i) => {
              result.push(
                new ItemByResult(
                  i.ItemId,
                  0,
                  0,
                  i.ItemGroupId,
                  i.Hierarchy5,
                  i.Hierarchy4,
                  i.Hierarchy3,
                  i.Hierarchy2,
                  i.Hierarchy1,
                  i.Amount,
                  i.taxes,
                  i.ItemName,
                  i.UnitId,
                  2
                )
              );
            });
        }
        break;
      // 105- sobre cat
      case 105:
        // Get items in the shopping car
        this.getShopLine_WithoutPromoID().forEach((ol) => {
          let item = this.itemInfo.filter((i) => i.ItemId == ol.itemId);
          if (item.length > 0) {
            let i = item[0];
            if (i.Hierarchy3.toUpperCase() == res.SubTypeValue.toUpperCase()) {
              let itemCondition = itemInConditions.filter(
                (ic) => ic.ItemId == i.ItemId && ic.UnitId == i.UnitId
              );
              // Si esta en las condiciones coloca cantidades con que cumplio la condicion
              if (itemCondition.length > 0) {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    itemCondition[0].SalesQuantity,
                    itemCondition[0].TotalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    itemCondition[0].Amount,
                    itemCondition[0].Taxes,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              } else {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    ol.salesQuantity,
                    ol.totalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    ol.salePrice,
                    ol.tax,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              }
            }
          }
        });
        // Get items in the conditions
        if (result.length <= 0) {
          this.getPromoItemSelected().forEach((ibr) => {
            if (
              ibr.Hierarchy3.toUpperCase() == res.SubTypeValue.toUpperCase()
            ) {
              result.push(ibr);
            }
          });
        }
        // Get new items from InventoryItems
        if (result.length <= 0) {
          this.itemInfo
            .filter(
              (i) =>
                i.Hierarchy3.toUpperCase() == res.SubTypeValue.toUpperCase()
            )
            .forEach((i) => {
              result.push(
                new ItemByResult(
                  i.ItemId,
                  0,
                  0,
                  i.ItemGroupId,
                  i.Hierarchy5,
                  i.Hierarchy4,
                  i.Hierarchy3,
                  i.Hierarchy2,
                  i.Hierarchy1,
                  i.Amount,
                  i.taxes,
                  i.ItemName,
                  i.UnitId,
                  2
                )
              );
            });
        }
        break;
      // 106- sobre sub cat
      case 106:
        // Get items in the shopping car
        this.getShopLine_WithoutPromoID().forEach((ol) => {
          let item = this.itemInfo.filter((i) => i.ItemId == ol.itemId);
          if (item.length > 0) {
            let i = item[0];
            if (i.Hierarchy4.toUpperCase() == res.SubTypeValue.toUpperCase()) {
              let itemCondition = itemInConditions.filter(
                (ic) => ic.ItemId == i.ItemId && ic.UnitId == i.UnitId
              );
              // Si esta en las condiciones coloca cantidades con que cumplio la condicion
              if (itemCondition.length > 0) {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    itemCondition[0].SalesQuantity,
                    itemCondition[0].TotalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    itemCondition[0].Amount,
                    itemCondition[0].Taxes,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              } else {
                result.push(
                  new ItemByResult(
                    ol.itemId,
                    ol.salesQuantity,
                    ol.totalSale,
                    i.ItemGroupId,
                    i.Hierarchy5,
                    i.Hierarchy4,
                    i.Hierarchy3,
                    i.Hierarchy2,
                    i.Hierarchy1,
                    ol.salePrice,
                    ol.tax,
                    ol.itemName,
                    ol.unitId,
                    1
                  )
                );
              }
            }
          }
        });
        // Get items in the conditions
        if (result.length <= 0) {
          this.getPromoItemSelected().forEach((ibr) => {
            if (
              ibr.Hierarchy4.toUpperCase() == res.SubTypeValue.toUpperCase()
            ) {
              result.push(ibr);
            }
          });
        }
        // Get new items from InventoryItems
        if (result.length <= 0) {
          this.itemInfo
            .filter(
              (i) =>
                i.Hierarchy4.toUpperCase() == res.SubTypeValue.toUpperCase()
            )
            .forEach((i) => {
              result.push(
                new ItemByResult(
                  i.ItemId,
                  0,
                  0,
                  i.ItemGroupId,
                  i.Hierarchy5,
                  i.Hierarchy4,
                  i.Hierarchy3,
                  i.Hierarchy2,
                  i.Hierarchy1,
                  i.Amount,
                  i.taxes,
                  i.ItemName,
                  i.UnitId,
                  2
                )
              );
            });
        }
        break;
      //   108- 100% sobre cod de articulo
      case 108:
        let item = this.itemInfo.filter((i) => i.ItemId == res.SubTypeValue);
        if (item.length > 0) {
          let i = item[0];
          let t = Number.parseFloat(
            Number.parseFloat(i.taxes.toString().replace(',', '.')).toFixed(2)
          );
          let a = Number.parseFloat(
            Number.parseFloat(i.Amount.toString().replace(',', '.')).toFixed(2)
          );
          result.push(
            new ItemByResult(
              i.ItemId,
              res.QtyProduct,
              Number.parseFloat(((t + a) * res.QtyProduct).toFixed(2)),
              i.ItemGroupId,
              i.Hierarchy5,
              i.Hierarchy4,
              i.Hierarchy3,
              i.Hierarchy2,
              i.Hierarchy1,
              i.Amount,
              i.taxes,
              i.ItemName,
              i.UnitId,
              3
            )
          );
        }
        break;
    }

    return result;
  }

  getPromoItemSelected(): ItemByResult[] {
    let result: ItemByResult[] = [];

    this.PromoConditionGroup.forEach((cg) => {
      // Obtener condiciones tipo articulo individual con totalsale mayor a 0
      let itemConditions = cg.PromoCondition.filter(
        (pc) =>
          pc.TypeCondition == 1 && pc.OperatorValue == 2 && pc.TotalSale > 0
      );
      let itemConditionList = cg.PromoCondition.filter(
        (pc) => pc.TypeCondition == 1 && pc.OperatorValue == 1
      );
      itemConditions.forEach((ic) => {
        let item = this.itemInfo.filter((i) => i.ItemId == ic.Value);
        if (item.length > 0) {
          let i = item[0];
          result.push(
            new ItemByResult(
              ic.Value,
              ic.SaleQty,
              ic.TotalSale,
              i.ItemGroupId,
              i.Hierarchy5,
              i.Hierarchy4,
              i.Hierarchy3,
              i.Hierarchy2,
              i.Hierarchy1,
              ic.Amount,
              ic.Taxes,
              i.ItemName,
              ic.UnitIdValue,
              0
            )
          );
        }
      });

      itemConditionList.forEach((icl) => {
        // filtrar items de la lista con venta mayor a 0
        icl.PromoConditionsList.filter((lc) => lc.TotalSale > 0).forEach(
          (listitem) => {
            let item = this.itemInfo.filter((i) => i.ItemId == listitem.Value);
            if (item.length > 0) {
              let i = item[0];
              result.push(
                new ItemByResult(
                  listitem.Value,
                  listitem.SaleQty,
                  listitem.TotalSale,
                  listitem.ItemGroupId,
                  i.Hierarchy5,
                  i.Hierarchy4,
                  i.Hierarchy3,
                  i.Hierarchy2,
                  i.Hierarchy1,
                  listitem.Amount,
                  listitem.Taxes,
                  listitem.ItemName,
                  listitem.UnitId,
                  0
                )
              );
            } else {
              result.push(
                new ItemByResult(
                  listitem.Value,
                  listitem.SaleQty,
                  listitem.TotalSale,
                  listitem.ItemGroupId,
                  'Otros',
                  'Otros',
                  'Otros',
                  'Otros',
                  'Otros',
                  listitem.Amount,
                  listitem.Taxes,
                  listitem.ItemName,
                  listitem.UnitId,
                  0
                )
              );
            }
          }
        );
      });
    });

    return result;
  }

  getShopLine_WithoutPromoID(): OrderLine[] {
    console.log('this.shoppingCar.filter(ol => ol.promoId == "")');
    console.log(this.shoppingCar);
    console.log(this.shoppingCar.filter((ol) => ol.promoId == ''));

    return Object.assign(
      [],
      this.shoppingCar.filter((ol) => ol.promoId == '')
    );
    // return this.shoppingCar.filter(ol => ol.promoId == '');
  }

  /**
   * Get selected items in the specific list of conditions
   * @param listName List Name, field Value on PromoConditionView
   */
  getItemsByConditionList(listName: string): PromoConditionsListView[] {
    // console.log(listName);
    // console.log(this.PromoConditionGroup);
    let result: PromoConditionsListView[] = [];
    this.PromoConditionGroup.forEach((pcg) => {
      // console.log(pcg);
      let conditions = pcg.PromoCondition.filter(
        (pc) =>
          pc.Value == listName && pc.TypeCondition == 1 && pc.OperatorValue == 1
      );
      // console.log(conditions);
      if (conditions.length > 0) {
        // console.log(conditions[0].PromoConditionsList.filter(pcl => pcl.SaleQty > 0));
        result = conditions[0].PromoConditionsList.filter(
          (pcl) => pcl.SaleQty > 0
        );
      }
    });
    return result;
  }

  asocItemGroupToShoppingCar() {
    this.shoppingCar.forEach((ordLine) => {
      let item = this.itemInfo.filter((i) => i.ItemId == ordLine.itemId);
      if (item.length > 0) {
        ordLine.ItemGroupId = item[0].ItemGroupId;
      }
    });
  }

  async getShoppingCar() {
    await this.storage
      .get('carrito')
      .then((data) => {
        // console.log(data);
        this.shoppingCar =
          data && data != null ? (JSON.parse(data) as OrderLine[]) : [];
        console.log(this.shoppingCar);
        this.getShoppingCarTotalAmount();
      })
      .catch((e) => {
        this.initPromo();
      });
  }

  getShoppingCarTotalAmount() {
    this.shoppingCarTotalAmount = 0;

    this.shoppingCar.forEach((item) => {
      this.shoppingCarTotalAmount += item.totalSale;
    });

    this.shoppingCarTotalAmount = Number.parseFloat(
      this.shoppingCarTotalAmount.toFixed(2)
    );

    this.initPromo();
  }

  generateResultDescription() {
    this.promo.PromoResults.forEach((pr) => {
      let promoClass = new PromoResultsViews();
      pr.SubDesc =
        'Aplica' +
        promoClass.getSubTypeENUM().filter((ste) => ste.ID == pr.SubType)[0]
          .Name +
        ' >> ' +
        pr.SubTypeValue;
      /*
      0- sin defnir
      1- Igual a
      4- Entre(rango)
      */
      switch (pr.Operator) {
        // 1- Igual a
        case 1:
          pr.Desc =
            promoClass.getQtyTypeENUM().filter((qte) => qte.ID == pr.QtyType)[0]
              .Name +
            (pr.QtyType != 2
              ? '₡' + pr.QtyValue.toString()
              : pr.QtyValue.toString() + '%');
          break;
        // 4- Entre(rango)
        case 4:
          // Toma el valor mas alto del rango
          pr.Desc =
            promoClass.getQtyTypeENUM().filter((qte) => qte.ID == pr.QtyType)[0]
              .Name +
            (pr.QtyType != 2
              ? '₡' + pr.QtyValueTo.toString()
              : pr.QtyValueTo.toString() + '%');
          break;
        default:
          pr.Desc = '';
          pr.SubDesc = '';
          break;
      }
    });
  }

  selectOptExclResult(promoResult: PromoResultsViews) {
    this.optionalExclResult
      .filter((oer) => oer.Selected == true && oer.RecId != promoResult.RecId)
      .forEach((res) => {
        res.Selected = false;
      });
  }

  async displayItemDetails(ItemID: string) {
    if (!this.itemDetailExecuting) {
      this.displayItemDetails_Aux(ItemID);
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Consulta de información en ejecución!',
        duration: 2000,
      });
      toast.present();
    }
  }

  displayItemDetails_Aux(ItemID: string) {
    this.itemDetailExecuting = true;

    this.webProvider.getProductsByItem(ItemID, 'AD').subscribe(
      async (res) => {
        let data = res as InventoryItem[];
        if (data && data[0] != null && data[0]) {
          let popover = await this.popoverCtrl.create({
            cssClass: 'custom-popover',
            component: ProductDetailComponent,
            componentProps: { inventoryItem: data[0] },
          });
          popover.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: 'Producto sin información detallada!',
            duration: 3000,
          });
          toast.present();
        }
        this.itemDetailExecuting = false;
      },
      () => {
        this.itemDetailExecuting = false;
      }
    );
  }

  applyPromo() {
    console.log('Aplicando Promoción con results: ');
    console.log(this.promo.PromoResults);

    console.log('this.shoppingCar');
    console.log(this.shoppingCar);
    this.tmpShoppingCar = Object.assign([], this.shoppingCar); // OrdLine[]
    console.log('this.tmpShoppingCar');
    console.log(this.tmpShoppingCar);

    // Get The PromoSequence
    let tmpSC = this.tmpShoppingCar.filter(
      (sc) => sc.promoId == this.promo.PromoId
    );
    let pSequence = 0;
    if (
      tmpSC.sort(function (n1, n2) {
        return n1.PromoSequence - n2.PromoSequence;
      }).length > 0
    ) {
      pSequence = tmpSC.pop().PromoSequence + 1;
    }

    // console.log('this.getPromoItemSelected()');
    // console.log(this.getPromoItemSelected());
    console.log('ADD Conditions');
    // ADD CONDITIONS

    this.getPromoItemSelected().forEach((item) => {
      // Si no está en el carro entonces solamente agrega el item
      let i = this.tmpShoppingCar.filter(
        (i) =>
          i.itemId == item.ItemId && i.unitId == item.UnitId && i.promoId == ''
      );
      if (i.length <= 0) {
        let newOrdLine = new OrderLine();
        newOrdLine.lineId = this.tmpShoppingCar.length;
        newOrdLine.itemId = item.ItemId;
        newOrdLine.itemName = item.ItemName;
        newOrdLine.unitId = item.UnitId;
        newOrdLine.salesQuantity = item.SalesQuantity;
        newOrdLine.salePrice = Number.parseFloat(item.Amount.toFixed(2));
        newOrdLine.totalSale = Number.parseFloat(item.TotalSale.toFixed(2));
        newOrdLine.taxAmount = Number.parseFloat(item.Taxes.toFixed(2));
        newOrdLine.tax = Number.parseFloat(item.Taxes.toFixed(2));
        newOrdLine.lineDiscount = 0;
        newOrdLine.linePercent = 0;
        newOrdLine.lnAmnt = item.TotalSale;
        newOrdLine.promoSalesType = 1; // 1- Condition
        newOrdLine.promoId = this.promo.PromoId;
        newOrdLine.PromoSequence = pSequence;

        this.tmpShoppingCar.push(newOrdLine);
      } else {
        i.forEach((line) => {
          line.lineId = this.tmpShoppingCar.length;
          line.itemId = item.ItemId;
          line.itemName = item.ItemName;
          line.unitId = item.UnitId;
          line.salesQuantity = item.SalesQuantity;
          line.salePrice = Number.parseFloat(item.Amount.toFixed(2));
          line.totalSale = Number.parseFloat(item.TotalSale.toFixed(2));
          line.taxAmount = Number.parseFloat(item.Taxes.toFixed(2));
          line.tax = Number.parseFloat(item.Taxes.toFixed(2));
          line.lineDiscount = 0;
          line.linePercent = 0;
          line.lnAmnt = item.TotalSale;
          line.promoSalesType = 1; // 1- Condition
          line.promoId = this.promo.PromoId;
          line.PromoSequence = pSequence;
        });
      }
    });

    this.applyResults(this.mandatoryResult, pSequence);
    this.applyResults(this.optionalResult, pSequence);
    this.applyResults(this.optionalExclResult, pSequence);

    console.log('final this.tmpShoppingCar');
    console.log(this.tmpShoppingCar);

    this.showTempShoppingCar(pSequence);
  }

  applyResults(promoResult: PromoResultsViews[], pSequence: number) {
    console.log('promoResult');
    console.log(promoResult);

    let newOrdLine = new OrderLine();

    // ADD SELECTED RESULTS
    promoResult
      .filter((pr) => pr.Selected == true)
      .forEach((promo) => {
        promo.ItemByResult.filter((l) => l.SalesQuantity > 0).forEach(
          (item) => {
            newOrdLine = new OrderLine();
            /*
        0- Conditions
        1- ShoppingCar
        2- New Product(InventoryItem)
        3- 100% Item (SubType 108)
        */
            switch (item.Origin) {
              // Conditions - NEW LINE IN THE SHOPPING CAR
              case 0:
                let l = this.tmpShoppingCar.filter(
                  (ol) =>
                    ol.itemId == item.ItemId &&
                    ol.unitId == item.UnitId &&
                    ol.promoId == promo.PromoId &&
                    ol.PromoSequence == pSequence
                );
                if (l.length > 0) {
                  l.forEach((lAux) => {
                    lAux.promoId = promo.PromoId;
                    lAux.lineDiscount = item.lineDiscount;
                    lAux.linePercent = item.linePercent;
                    lAux.lnAmnt = item.TotalWithDisc;
                    lAux.promoSalesType = 5;
                    lAux.PromoSequence = pSequence;
                    lAux.salesQuantity = item.SalesQuantity;
                    lAux.salePrice = Number.parseFloat(item.Amount.toFixed(2));
                    lAux.totalSale = Number.parseFloat(
                      item.TotalSale.toFixed(2)
                    );
                    lAux.taxAmount = Number.parseFloat(item.Taxes.toFixed(2));
                    lAux.tax = Number.parseFloat(item.Taxes.toFixed(2));
                  });
                }

                break;
              // Shopping Car - UPDATE LINE IN THE SHOPPING CAR
              case 1:
                let lines = this.tmpShoppingCar.filter(
                  (ol) =>
                    ol.itemId == item.ItemId &&
                    ol.unitId == item.UnitId &&
                    ol.promoId == promo.PromoId &&
                    ol.PromoSequence == pSequence
                );
                // let lines = this.tmpShoppingCar.filter(ol => ol.itemId == item.ItemId && ol.unitId == item.UnitId && ol.promoId == "");
                if (lines.length > 0) {
                  lines.forEach((l) => {
                    l.promoId = promo.PromoId;
                    l.lineDiscount = item.lineDiscount;
                    l.linePercent = item.linePercent;
                    l.lnAmnt = item.TotalWithDisc;
                    l.promoSalesType = 3;
                    l.PromoSequence = pSequence;
                  });
                }
                break;
              // Inventory Item - NEW LINE IN THE SHOPPING CAR
              case 2:
                newOrdLine.lineId = this.tmpShoppingCar.length;
                newOrdLine.itemId = item.ItemId;
                newOrdLine.itemName = item.ItemName;
                newOrdLine.unitId = item.UnitId;
                newOrdLine.salesQuantity = item.SalesQuantity;
                newOrdLine.salePrice = Number.parseFloat(
                  item.Amount.toFixed(2)
                );
                newOrdLine.totalSale = Number.parseFloat(
                  item.TotalSale.toFixed(2)
                );
                newOrdLine.taxAmount = Number.parseFloat(item.Taxes.toFixed(2));
                newOrdLine.tax = Number.parseFloat(item.Taxes.toFixed(2));
                newOrdLine.lineDiscount = item.lineDiscount;
                newOrdLine.linePercent = item.linePercent;
                newOrdLine.lnAmnt = item.TotalWithDisc;
                newOrdLine.promoSalesType = 3; // 3- Result
                newOrdLine.promoId = promo.PromoId;
                newOrdLine.PromoSequence = pSequence;

                this.tmpShoppingCar.push(newOrdLine);
                break;
              // Bonificacion - NEW LINE IN THE SHOPPING CAR
              case 3:
                // let linesInShoppingCar = this.tmpShoppingCar.filter(ol => ol.itemId == item.ItemId && ol.unitId == item.UnitId && ol.promoId == "");
                // if (linesInShoppingCar.length > 0) {
                //   linesInShoppingCar[0].lineId = this.tmpShoppingCar.length;
                //   linesInShoppingCar[0].itemId = item.ItemId;
                //   linesInShoppingCar[0].itemName = item.ItemName;
                //   linesInShoppingCar[0].unitId = item.UnitId;
                //   linesInShoppingCar[0].salesQuantity = item.SalesQuantity;
                //   linesInShoppingCar[0].salePrice = Number.parseFloat(item.Amount.toFixed(2));
                //   linesInShoppingCar[0].totalSale = Number.parseFloat(item.TotalSale.toFixed(2));
                //   linesInShoppingCar[0].taxAmount = Number.parseFloat(item.Taxes.toFixed(2));
                //   linesInShoppingCar[0].tax = Number.parseFloat(item.Taxes.toFixed(2));
                //   linesInShoppingCar[0].lineDiscount = item.lineDiscount;
                //   linesInShoppingCar[0].linePercent = item.linePercent;
                //   linesInShoppingCar[0].lnAmnt = item.TotalWithDisc;
                //   linesInShoppingCar[0].promoSalesType = 3; // 3- Result
                //   linesInShoppingCar[0].promoId = promo.PromoId;
                //   linesInShoppingCar[0].PromoSequence = pSequence;
                // }
                // else {
                newOrdLine.lineId = this.tmpShoppingCar.length;
                newOrdLine.itemId = item.ItemId;
                newOrdLine.itemName = item.ItemName;
                newOrdLine.unitId = item.UnitId;
                newOrdLine.salesQuantity = item.SalesQuantity;
                newOrdLine.salePrice = Number.parseFloat(
                  item.Amount.toFixed(2)
                );
                newOrdLine.totalSale = Number.parseFloat(
                  item.TotalSale.toFixed(2)
                );
                newOrdLine.taxAmount = Number.parseFloat(item.Taxes.toFixed(2));
                newOrdLine.tax = Number.parseFloat(item.Taxes.toFixed(2));
                newOrdLine.lineDiscount = item.lineDiscount;
                newOrdLine.linePercent = item.linePercent;
                newOrdLine.lnAmnt = item.TotalWithDisc;
                newOrdLine.promoSalesType = 3; // 3- Result
                newOrdLine.promoId = promo.PromoId;
                newOrdLine.PromoSequence = pSequence;

                this.tmpShoppingCar.push(newOrdLine);
                // }
                break;
            }
          }
        );
      });
  }

  sortShoppingCarItems() {
    this.tmpShoppingCar.sort(function (a, b) {
      if (a.promoId == b.promoId) {
        return a.PromoSequence - b.PromoSequence;
      } else {
        return a.promoId < b.promoId ? -1 : 1;
      }
    });
  }

  async showTempShoppingCar(pSequence: number) {
    if (this.tmpShoppingCar.length > 0) {
      // sort items by promoId and Psequence
      this.sortShoppingCarItems();

      let popup = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: PrevShoppingCarComponent,
        componentProps: {
          tmpShoppingCar: this.tmpShoppingCar,
          showBtn: true,
          promoId: this.promo.PromoId,
          pSequence: pSequence,
        },
      });
      popup.present();

      popup.onDidDismiss().then(async (res) => {
        let data = res.data;
        if (data != null && data == 'OK') {
          this.storage
            .set('carrito', JSON.stringify(this.tmpShoppingCar))
            .then(() => {
              this.globalEventsService.publish(GlobalEvents.UPDATE_CART);
            });
          let alert = await this.alertCtrl.create({
            header: 'Estado',
            message: 'Promoción agregada con éxito!',
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  this.viewCtrl.dismiss('OK');
                },
              },
            ],
          });
          alert.present();
        } else {
          this.storage.get('carrito').then((data) => {
            this.shoppingCar =
              data && data != null ? (JSON.parse(data) as OrderLine[]) : [];

            this.shoppingCarTotalAmount = 0;
            this.shoppingCar.forEach((item) => {
              this.shoppingCarTotalAmount += item.totalSale;
            });
            this.shoppingCarTotalAmount = Number.parseFloat(
              this.shoppingCarTotalAmount.toFixed(2)
            );
          });

          this.tmpShoppingCar = [];

          let toast = await this.toastCtrl.create({
            message: 'Promoción no agregada!',
            duration: 2000,
          });
          toast.present();
        }
      });
    }
  }

  undo() {
    this.viewCtrl.dismiss();
  }

  async displayInfoDialog() {
    let toast = await this.toastCtrl.create({
      message:
        'Sección para seleccionar los resultados deseados según la promoción',
      duration: 3000,
    });
    toast.present();
  }

  reduceQuantityItemResult(res: ItemByResult, id: number) {
    switch (id) {
      case 0:
        this.mandatoryResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity =
              item[0].SalesQuantity <= 1 ? 0 : item[0].SalesQuantity - 1;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
      case 1:
        this.optionalResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity =
              item[0].SalesQuantity <= 1 ? 0 : item[0].SalesQuantity - 1;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
      case 2:
        this.optionalExclResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity =
              item[0].SalesQuantity <= 1 ? 0 : item[0].SalesQuantity - 1;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
    }
  }

  increaseQuantityItemResult(res: ItemByResult, id: number) {
    switch (id) {
      case 0:
        this.mandatoryResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity++;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
      case 1:
        this.optionalResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity++;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
      case 2:
        this.optionalExclResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity++;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
    }
  }

  changeValueKeyUpItemResult(_event, res: ItemByResult, id: number) {
    switch (id) {
      case 0:
        this.mandatoryResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity =
              item[0].SalesQuantity &&
              this.validQuantity(item[0].SalesQuantity.toString())
                ? item[0].SalesQuantity
                : 0;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
      case 1:
        this.optionalResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity =
              item[0].SalesQuantity &&
              this.validQuantity(item[0].SalesQuantity.toString())
                ? item[0].SalesQuantity
                : 0;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
      case 2:
        this.optionalExclResult.forEach((result) => {
          let item = result.ItemByResult.filter((r) => r.ItemId == res.ItemId);
          if (item.length > 0) {
            item[0].SalesQuantity =
              item[0].SalesQuantity &&
              this.validQuantity(item[0].SalesQuantity.toString())
                ? item[0].SalesQuantity
                : 0;
            item[0].TotalSale = Number.parseFloat(
              (
                item[0].SalesQuantity *
                (item[0].Amount + item[0].Taxes)
              ).toFixed(2)
            );

            item[0].lineDiscount = Number.parseFloat(
              ((item[0].TotalSale * item[0].linePercent) / 100).toFixed(2)
            );
            item[0].TotalWithDisc = Number.parseFloat(
              (item[0].TotalSale - item[0].lineDiscount).toFixed(2)
            );
          }
        });
        break;
    }
  }

  validQuantity(quantity: string) {
    return /^\d+$/.test(quantity);
  }

  async previewShoppingCar() {
    if (this.shoppingCar.length > 0) {
      let p = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: PrevShoppingCarComponent,
        componentProps: {
          tmpShoppingCar: this.shoppingCar,
          showBtn: false,
          promoId: '',
          pSequence: -1,
        },
      });
      p.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Carrito de compras sin productos!',
        duration: 3000,
      });
      toast.present();
    }
  }
}
