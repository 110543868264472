<app-nav-bar [title]="'Nueva Solicitud'"></app-nav-bar>

<ion-content>
  <div class="flexBlockContainer">
    <div class="flexContainer">
      <div
        class="familyContainerAux fImg fImgAlimentos"
        (click)="
          nextStep(
            'Editar Dirección',
            './assets/img/casemanagement/latlng.jpg',
            0
          )
        "
      >
        <i
          class="fas fa-map-marked-alt"
          style="color: rgba(0, 0, 0, 0.8); font-size: 40px; margin-bottom: 5px"
        ></i>
        <div class="h7">Editar dirección</div>
      </div>
      <div
        class="familyContainerAux fImg fImgCuidadoHogar"
        (click)="
          nextStep(
            'Crear Nueva Dirección',
            './assets/img/casemanagement/direction.jpg',
            3
          )
        "
      >
        <i
          class="fas fa-map-pin"
          style="color: rgba(0, 0, 0, 0.8); font-size: 40px; margin-bottom: 5px"
        ></i>
        <div class="h7">Crear nueva dirección</div>
      </div>
    </div>

    <!-- <div class="flexContainer">
      <div class="familyContainerAux fImg fImgCuidadoPersonal" (click)="nextStep('Editar Correo Electrónico', './assets/img/casemanagement/email.jpg', 2)">
        <i class="fas fa-at" style="color: rgba(0, 0, 0, 0.8); font-size: 40px; margin-bottom: 5px;"></i>
        <div class="h7">Editar correo electrónico</div>
      </div>
      <div class="familyContainerAux fImg fImgBebidas" (click)="nextStep('Editar Número Telefónico', './assets/img/casemanagement/phone.jpg', 1)">
        <i class="fas fa-phone" style="color: rgba(0, 0, 0, 0.8); font-size: 40px; margin-bottom: 5px;"></i>
        <div class="h7">Editar número telefónico</div>
      </div>
    </div> -->

    <div class="flexContainer">
      <div
        class="familyContainerAux fImg fImgLibreria"
        (click)="
          nextStep(
            'Agregar Restricción',
            '../../../assets/img/casemanagement/restriction.jpg',
            4
          )
        "
      >
        <i
          class="fas fa-minus-circle"
          style="color: rgba(0, 0, 0, 0.8); font-size: 40px; margin-bottom: 5px"
        ></i>
        <div class="h7">Crear nueva restricción</div>
      </div>
      <div
        class="familyContainerAux fImg fImgMascotas"
        (click)="
          nextStep(
            'Próximamente',
            './assets/img/casemanagement/comingsoon.jpg',
            5
          )
        "
      >
        <i
          class="far fa-eye"
          style="color: rgba(0, 0, 0, 0.8); font-size: 40px; margin-bottom: 5px"
        ></i>
        <div class="h7">Próximamente</div>
      </div>
    </div>
  </div>
</ion-content>
