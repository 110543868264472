import { Component, Input, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { DateTimeOperation } from '../../../businessCore/oc-models/DateTimeOperations';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';
import { ServicesOcParameterProvider } from '../../../provider/Services/services-oc-parameter';

@Component({
  selector: 'app-historial-oc',
  templateUrl: './historial-oc.component.html',
  styleUrls: ['./historial-oc.component.scss'],
})
export class HistorialOcComponent implements OnInit {
  @Input() infoPurch: PurchOrder;
  presentingMess = false;
  fecha: string;
  public pCanEdit: boolean = false;
  constructor(
    public navCtrl: NavController,
    public alertController: AlertController,
    private ocParameter: ServicesOcParameterProvider,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    if (this.infoPurch) {
      this.pCanEdit = this.canEdit();
      if (
        this.infoPurch.getFechaCreacion() !== undefined &&
        this.infoPurch.getFechaCreacion() !== null
      ) {
        this.fecha = DateTimeOperation.dateToStringFormart(
          this.infoPurch.getFechaCreacion()
        );
      }
    }

    //DEBUG
    /*let PO = new PurchOrder(
      'jlbs001',
      new PurchPoolModel('sdfas', 'asdfsad'),
      'sdf',
      'asdf',
      new Date(),
      new Date(),
      new SitioModel('cca', 'asdf'),
      new AlmacenModel('f20', 'asdf'),
      new Date(),
      1,
      1,
      new CatalogModel(1, 'asdf'),
      1,
      new Date(),
      new Date(),
      new Date(),
      new CatalogModel(1, 'asdf'),
      new CatalogModel(1, 'asdf'),
      new CatalogModel(1, 'asdf'),
      'asdf',
      new CatalogModel(1, 'asdf'),
      'asdf',
      new DataArea('cmer', 'asdf'),
      'asdf'
    );
    PO.setDocumentStateI(10);
    this.infoPurch = PO;*/
    //DEBUG
  }

  swipeEvent(event) {
    if (event.direction === 2) {
      // Confirmar OC
      this.goToConfirmOrder();
    } else if (event.direction === 4) {
      // Editar
      if (this.canEdit()) {
        this.openEdit();
      }
    }
  }

  openEdit() {
    this.ocParameter.setPurchOrderParameter(this.infoPurch);
    this.ocParameter.setToEdit(false); //es para crear
    this.ocParameter.setFrom('RECORD');
    //this.navCtrl.push(PurchOrderPage);
    this.navService.navigateTo(NavigationRoutes.PurchOrderPage);
  }

  goToConfirmOrder() {
    // Setea el parametro a pasar
    if (this.infoPurch.getLineList().length > 0) {
      this.ocParameter.setPurchOrderParameter(this.infoPurch);
      this.ocParameter.setListFile(this.infoPurch.getListInfoFile());
      this.navService.navigateTo(NavigationRoutes.PurchOrderConfirmPage);
    } else {
      this.messageInfo('Error lineas', 'No hay lineas agregadas a la OC.');
    }
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [
          {
            text: 'Aceptar',
            handler: () => {
              // Guarda la hora de finalizada la actividad
              // this.updateActivityMerchandising();
              // this.navCtrl.pop();
            },
          },
        ],
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  canEdit() {
    return (
      this.infoPurch.getDocumentStateI() == 0 || // 'BORRADOR'
      this.infoPurch.getDocumentStateI() == 20 || // 'RECHAZADO'
      this.infoPurch.getDocumentStateI() == 10 // 'EN REVISIÓN';
    );
  }
}
