<div class="popoverHeader"></div>
<div>
  <p style="text-align: center">{{ mainText }}</p>
</div>

<div *ngIf="secondaryText">
  <ion-item>
    <ion-select
      label="{{ secondaryText }}"
      label-placement="floating"
      class="floating"
      [(ngModel)]="period"
      okText="Aceptar"
      cancelText="Cancelar"
    >
      <ion-select-option *ngFor="let p of periods; let i = index" [value]="p">{{
        p.strPeriod
      }}</ion-select-option>
    </ion-select>
  </ion-item>
</div>

<div class="popoverFooter">
  <ion-button (click)="dismiss(true)">{{ buttonText }}</ion-button>
</div>
