import { Component } from '@angular/core';

@Component({
  selector: 'app-vendor-record',
  templateUrl: './vendor-record.component.html',
  styleUrls: ['./vendor-record.component.scss'],
})
export class VendorRecordComponent {
  text: string;

  constructor() {
    console.log('Hello VendorRecordComponent Component');
    this.text = 'Hello World';
  }
}
