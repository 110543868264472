export class ClientInfo {
  private latitud: number;
  private longitud: number;
  private customerID: string;
  private startTime: Date;

  /**
   * <CustomerBusinessId>ROCM014</CustomerBusinessId>
   * <CustomerId>ROCM0145647007691</CustomerId>
   */
  /**
   *
   * @param customerBusinessID
   * @param customerName
   */
  constructor(
    private customerBusinessID: string,
    private customerName: string
  ) {
    this.latitud = 0;
    this.longitud = 0;
  }

  getAddressId() {
    let recIdAddress = 0;
    if (this.customerBusinessID !== '' && this.customerID !== '') {
      const idTmp = this.customerID.substring(
        this.customerBusinessID.length,
        this.customerID.length
      );
      recIdAddress = Number(idTmp);
    }
    return recIdAddress;
  }

  getCustomerID() {
    return this.customerID;
  }

  setCustomerID(newCustomerID: string) {
    this.customerID = newCustomerID;
  }

  setStartTime(newStartTime: Date) {
    this.startTime = newStartTime;
  }

  getStartTime() {
    return this.startTime;
  }

  setLatitud(newLatitud: number) {
    if (newLatitud !== null && newLatitud !== undefined) {
      this.latitud = newLatitud;
    }
  }

  getLatitud() {
    return this.latitud;
  }

  setLongitud(newLongitud: number) {
    if (newLongitud !== null && newLongitud !== undefined) {
      this.longitud = newLongitud;
    }
  }

  getLongitud() {
    return this.longitud;
  }

  getId() {
    return this.customerBusinessID;
  }

  getName() {
    return this.customerName;
  }

  getValue() {
    return this.customerBusinessID;
  }

  setValues(newData: ClientInfo) {
    this.customerBusinessID = newData.getId();
    this.customerName = newData.getName();
  }
}
