import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { AssociatedInstance } from '../../businessCore/AssociatedInstance';
import { RegisterData } from '../../businessCore/RegisterData';
import { AlmacenModel } from '../../businessCore/oc-models/AlmacenModel';
import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { PurchLine } from '../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { PurchPoolModel } from '../../businessCore/oc-models/PurchPoolModel';
import { SitioModel } from '../../businessCore/oc-models/SitioModel';
import { FileOCInfo } from './../../businessCore/oc-models/FileOCInfo';

const ITEM_KEY = 'OC-DB';

@Injectable()
export class LocalServiceOCService {
  constructor(private storage: StorageService) {}

  // Save PurchOrder data in local Storage
  async saveOC(newOC: PurchOrder) {
    let result = false;
    const listInfoOC = await this.storage.get(ITEM_KEY);

    if (newOC.getLocalId() === '') {
      // Es una OC que no ha sido envidada o creada
      // Se le asigna un Id local, que será sustituido por el generado en AX
      const dateCreate = new Date();
      const localId = this.generateLocalID(dateCreate);
      if (
        !(
          newOC.getFechaCreacion() !== null &&
          newOC.getFechaCreacion() !== undefined &&
          newOC.getFechaCreacion() > new Date('1/1/1900 00:00:00')
        )
      ) {
        newOC.setFechaCreacion(dateCreate);
      }

      newOC.setLocalId(localId);

      if (listInfoOC) {
        // Si hay datos ya guardados
        listInfoOC.push(JSON.stringify(newOC));
        this.storage.set(ITEM_KEY, listInfoOC);
      } else {
        // Se guarda una lista con la nueva Orden
        this.storage.set(ITEM_KEY, [JSON.stringify(newOC)]);
      }

      result = true;
    } else {
      // Se actualiza la orden de compra
      await this.upDate(newOC);
      result = true;
    }

    return result;
  }

  /**
   * Setea los datos de una OC guardada en la base de datos local.
   */
  setDataPurchOrder(objeto: any) {
    let purchPool = new PurchPoolModel('', '');
    let sitio = new SitioModel('', '');
    let almacen = new AlmacenModel('', '');
    let port = new CatalogModel(0, '');
    let transport = new CatalogModel(0, '');
    let incoterm = new CatalogModel(0, '');
    let status = new CatalogModel(0, '');
    let contType = new CatalogModel(0, '');
    let A_Departamento = new CatalogModel(0, '');
    let B_CentroCosto = new CatalogModel(0, '');
    let C_Proposito = new CatalogModel(0, '');
    let dataArea = new DataArea('', '');
    let purpose = new CatalogModel(0, '');
    let sale = new CatalogModel(0, '');
    let tipoProyecto = new CatalogModel('' as any, '');
    let tipoOC = new CatalogModel('' as any, '');
    const tmpPurchOrder = new PurchOrder(
      '',
      null,
      '',
      '',
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      null,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      '',
      null,
      '',
      null,
      ''
    );
    const jsonInfo = JSON.parse(objeto);
    const orderHeader: PurchOrder = Object.assign(tmpPurchOrder, jsonInfo);
    purchPool = Object.assign(purchPool, jsonInfo.purchPoolId);
    orderHeader.setPurchPool(purchPool);
    purpose = Object.assign(purpose, jsonInfo.purpose);
    orderHeader.setPurpose(purpose);
    sale = Object.assign(sale, jsonInfo.sale);
    orderHeader.setSale(sale);
    sitio = Object.assign(sitio, jsonInfo.site);
    orderHeader.setSite(sitio);
    almacen = Object.assign(almacen, jsonInfo.location);
    orderHeader.setLocation(almacen);
    port = Object.assign(port, jsonInfo.port);
    orderHeader.setPuerto(port);
    transport = Object.assign(transport, jsonInfo.transport);
    orderHeader.setNaviera(transport);
    incoterm = Object.assign(incoterm, jsonInfo.incoterm);
    orderHeader.setIncoterm(incoterm);
    status = Object.assign(status, jsonInfo.status);
    orderHeader.setStatus(status);
    contType = Object.assign(contType, jsonInfo.typeContainer);
    orderHeader.setContainerType(contType);
    dataArea = Object.assign(contType, jsonInfo.dataArea);
    orderHeader.setDataArea(dataArea);
    A_Departamento = Object.assign(A_Departamento, jsonInfo.A_Departamento);
    orderHeader.setA_Departamento(A_Departamento);
    B_CentroCosto = Object.assign(B_CentroCosto, jsonInfo.B_CentroCosto);
    orderHeader.setB_CentroCosto(B_CentroCosto);
    C_Proposito = Object.assign(C_Proposito, jsonInfo.C_Proposito);
    orderHeader.setC_Proposito(C_Proposito);
    tipoProyecto = Object.assign(tipoProyecto, jsonInfo.tipoProyecto);
    orderHeader.setTypeProject(tipoProyecto);
    tipoOC = Object.assign(tipoOC, jsonInfo.typePurchaseOrder);
    orderHeader.setTypePurchaseOrder(tipoOC);
    orderHeader.setDocumentStateI(Number(jsonInfo.documentStateI));
    orderHeader.setPurchStatus(Number(jsonInfo.purchStatus));
    orderHeader.setNotaRechazo(jsonInfo.notaRechazo as string);
    // Setea la info de las lineas
    orderHeader.setLineList(this.getDataFromList(jsonInfo.lineaList));
    orderHeader.setListInfoFile(
      this.getDataFileFromList(jsonInfo.listFileToSend)
    );
    orderHeader.setDateDataFromString();
    return orderHeader;
  }

  /**
   * Parsea los datos de las lineas de la OC
   */
  getDataFromList(dataList: any) {
    const lineaList: PurchLine[] = [];
    for (const item of dataList) {
      const actualLine = new PurchLine();
      let A_Departamento = new CatalogModel(0, '');
      let B_CentroCosto = new CatalogModel(0, '');
      let C_Proposito = new CatalogModel(0, '');
      actualLine.setTotal(item.total);
      actualLine.cantidad = item.cantidad;
      actualLine.setSubTotal(item.subTotal);
      actualLine.setTotalDis(item.totalDiscount);
      actualLine.setNote(item.note);
      actualLine.itemId = item.itemId;
      actualLine.taxes = item.taxes;
      actualLine.porcentDescuent = item.porcentDescuent;
      actualLine.precio = item.precio;
      actualLine.unitId = item.unitId;
      actualLine.setTaxesLine(item.taxesLine);
      actualLine.name = item.name;
      A_Departamento = Object.assign(A_Departamento, item.A_Departamento);
      B_CentroCosto = Object.assign(B_CentroCosto, item.B_CentroCosto);
      C_Proposito = Object.assign(C_Proposito, item.C_Proposito);
      actualLine.setA_Departamento(A_Departamento);
      actualLine.setB_CentroCosto(B_CentroCosto);
      actualLine.setC_Proposito(C_Proposito);
      actualLine.setTaxItemGroup(item.taxItemGroup);
      actualLine.setRecId(Number(item.recId));
      lineaList.push(actualLine);
    }
    return lineaList;
  }

  /**
   * Parsea los datos de archivos a mandar en una OC
   *
   */
  getDataFileFromList(dataList: any) {
    const fileList: FileOCInfo[] = [];
    for (const fileInfo of dataList) {
      const actualLine = new FileOCInfo(
        fileInfo.fileName,
        fileInfo.fileInf,
        fileInfo.fileExtension,
        fileInfo.fileData,
        fileInfo.send
      );
      fileList.push(actualLine);
    }
    return fileList;
  }
  // Get cedula
  async getCedulaActual() {
    const data = await this.storage.get('register_data');
    const register_data = JSON.parse(data) as RegisterData;
    let cedulaAct = register_data.id_card as string;

    const cedulaDist = await this.storage.get('associatedPerson');
    const associatedPerson = JSON.parse(cedulaDist) as AssociatedInstance;
    if (associatedPerson) {
      cedulaAct = associatedPerson.vatNum as string;
    }
    if (cedulaAct === undefined || cedulaAct === null) {
      cedulaAct = '';
    }
    //console.log('Cedula actual es: ', cedulaAct);
    return cedulaAct;
  }

  // Id del usuario actual
  async getIdActualUser() {
    const data = await this.storage.get('register_data');
    const register_data = JSON.parse(data) as RegisterData;
    let codigoAct = register_data.customerId as string;

    const cedulaDist = await this.storage.get('associatedPerson');
    const associatedPerson = JSON.parse(cedulaDist) as AssociatedInstance;
    if (associatedPerson) {
      codigoAct = associatedPerson.codPerson as string;
    }
    //console.log('Cedula actual es: ', codigoAct);
    return codigoAct;
  }
  /**
   *
   */
  // Get the date saved in local Storage
  async getOC(): Promise<PurchOrder[]> {
    const listTmp: PurchOrder[] = [];
    const listInfoOC = await this.storage.get(ITEM_KEY);
    if (listInfoOC) {
      for (const iterator of listInfoOC) {
        listTmp.push(this.setDataPurchOrder(iterator));
      }
    }
    return listTmp;
  }

  async getID_ax_OC(): Promise<string[]> {
    const listTmp: string[] = [];
    const listInfoOC = await this.storage.get(ITEM_KEY);
    if (listInfoOC) {
      for (const iterator of listInfoOC) {
        const jsonInfo = JSON.parse(iterator);
        const idOC = jsonInfo.idOC as string;
        if (idOC !== null && idOC !== undefined && idOC !== '') {
          listTmp.push(idOC);
        }
      }
    }
    return listTmp;
  }

  async upDate(item: PurchOrder) {
    const listOrder = await this.getOC();
    const newList = [];
    for (const order of listOrder) {
      if (order.getLocalId() !== item.getLocalId()) {
        newList.push(JSON.stringify(order));
      } else {
        newList.push(JSON.stringify(item));
      }
    }
    this.storage.set(ITEM_KEY, newList);
  }

  async deleteOC(item: PurchOrder) {
    let result = false;
    const tmpList = await this.getOC();
    const toKeep = [];

    for (const ite of tmpList) {
      if (ite.getLocalId() !== item.getLocalId()) {
        toKeep.push(JSON.stringify(ite));
      }
    }
    this.storage.set(ITEM_KEY, toKeep);
    result = true;
    return result;
  }
  /**
   * Funcion que genera un ID para la orden a guardar,
   * se genera a partir de la fecha:hora:segundos
   */
  generateLocalID(date: Date) {
    return (
      'oc-' +
      date.getDate() +
      date.getMonth() +
      date.getFullYear() +
      date.getHours() +
      date.getMinutes() +
      date.getSeconds() +
      date.getMilliseconds()
    );
  }
}
