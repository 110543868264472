<app-nav-bar [title]="'Productos'" [end]="'Cart'"></app-nav-bar>

<ion-content
  #content
  [scrollEvents]="true"
  (ionScrollStart)="logScrollStart()"
  (ionScrollEnd)="logScrollEnd()"
>
  <div>
    <swiper-container
      class="slider"
      style="height: 50%; padding: 2%"
      [autoplay]="true"
      [loop]="true"
    >
      <swiper-slide
        *ngFor="let slide of slide_items"
        style="padding: 0px !important; margin: 0px !important"
      >
        <div>
          <!-- <img style="width: 90%; height: auto;" src="https://serviceplan-api.mercasacr.com/images/Products/Slider/{{slide}}.png"> -->
          <img
            style="height: auto"
            src="https://serviceplan-api.mercasacr.com/images/Products/SliderMercasa/{{
              slide
            }}.png"
            alt=""
            onerror=""
          />
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="search" [hidden]="loadingProducts">
      <ion-searchbar
        type="text"
        [(ngModel)]="filter.productName"
        placeholder="Buscar"
        (keyup)="applySearchFilter()"
      ></ion-searchbar>
    </div>

    <div class="flex-container nowrap">
      <ion-button
        class="filter-btn"
        style="width: 100%"
        (click)="presentPopoverFilter()"
        [disabled]="loadingProducts"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        style="width: 100%"
        (click)="presentPopoverSort()"
        [disabled]="loadingProducts"
      >
        Ordenar
        <ion-icon name="reorder-four-outline"></ion-icon>
      </ion-button>
    </div>

    <div class="searchBar">
      <span class="alertMessage" *ngIf="loadingProducts"
        >Filtros deshabilitados!</span
      >
      <span class="alertMessage" *ngIf="loadingProducts"
        >Cargando todos los productos...</span
      >
    </div>
  </div>
  <cdk-virtual-scroll-viewport itemSize="56" style="height: 100%">
    <ion-list class="flexContainer">
      <ion-item
        class="productContainer"
        *cdkVirtualFor="let item of itemInfo"
        (click)="addToCart(item)"
        style="width: 100%"
      >
        <ion-grid>
          <ion-row>
            <ion-col
              size="4"
              style="
                align-items: center;
                justify-content: center;
                display: flex;
              "
            >
              <!-- <ion-img class="image-container" alt="Imagen No Disponible" src="https://serviceplan-api.mercasacr.com/images/Products/{{item.ItemId}}_l_.PNG"
            onerror="this.onerror=null;this.src='assets/img/error.png'">
          </ion-img> -->

              <img
                class="image-container"
                alt="Imagen No Disponible"
                src="https://serviceplan-api.mercasacr.com/images/Products/{{
                  item.ItemId
                }}_l_.PNG"
                onerror="this.onerror=null;this.src='assets/img/error.png'"
              />
            </ion-col>
            <ion-col size="8">
              <h4 style="font-size: 4vw">{{ item.ItemId }}</h4>
              <span style="font-size: 3vw">{{ item.ItemName }}</span>
              <div style="display: flex; align-items: center">
                <div style="display: flex; align-items: baseline">
                  <!-- <h5 style="font-weight: bold; margin-right: 2px;">{{setTotalSale(item.Amount, item.taxes)}}</h5> -->
                  <h5 style="font-weight: bold; margin-right: 2px">
                    {{ item.FinalPrice }}
                  </h5>
                  <!-- <h6 class="taxStyle" *ngIf="hasTaxes(item)">{{' +' + setCurrency(item.taxes) + ' IVA'}}</h6> -->
                </div>
                <ion-button
                  style="margin-left: auto; font-size: 20px"
                  class="button-blue"
                >
                  <i class="fas fa-cart-plus"></i>
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>
  </cdk-virtual-scroll-viewport>
  <ion-fab
    vertical="top"
    horizontal="center"
    slot="fixed"
    *ngIf="showScrollButton"
  >
    <ion-fab-button
      color="primary"
      (click)="scrollToTop()"
      style="width: 40px; height: 40px; margin: 0px"
    >
      <ion-icon name="arrow-up"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
