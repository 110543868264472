import { Component } from '@angular/core';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import {
  RegisterAddress,
  RegisterAddressComponent,
} from 'src/app/components/register-address/register-address.component';
import { Address, AddressFeature } from '../../businessCore/Address';

@Component({
  selector: 'app-get-position-vendor',
  templateUrl: './get-position-vendor.component.html',
  styleUrls: ['./get-position-vendor.component.scss'],
})
export class GetPositionVendorPage {
  lat: number;
  lng: number;
  geocoder: any;

  addressData: Address;
  countryData: AddressFeature;
  stateData: AddressFeature;
  countyData: AddressFeature;
  cityData: AddressFeature;

  mymap: any;
  marker: any;
  showActualPosBtn: boolean = false;

  callback: any;

  addressES: RegisterAddress = {
    title: 'Dirección',
    addBtn: 'Agregar',
    headerTitle: 'Obtener posición',
    pinUp: 'Fijar punto', //Se reutiliza
    bodyMsg:
      'Seleccione el punto en el mapa donde se ubica su empresa y presione el botón ',
    secondTitle: 'Nueva dirección',
    country: 'País',
    state: 'Provincia o estado',
    county: 'Cantón, colonia o municipio',
    city: 'Distrito o ciudad',
    streetAddress: 'Otras señas',
    name: 'Nombre dirección',
    egNote: 'E.j: Oficina central',
  };

  addressEN: RegisterAddress = {
    title: 'Address',
    addBtn: 'Add',
    headerTitle: 'Get Position',
    pinUp: 'Pin Up', //Se reutiliza
    bodyMsg:
      'Select the point on the map where your bussiness is located and press ',
    secondTitle: 'Address',
    country: 'Country',
    state: 'State',
    county: 'County',
    city: 'City',
    streetAddress: 'Street Address',
    name: 'Name',
    egNote: 'E.g: Headquartes',
  };

  addressLanguage: { headerTitle: string; pinUp: string; bodyMsg: string } = {
    headerTitle: '',
    pinUp: '',
    bodyMsg: '',
  };

  constructor(
    public storage: StorageService,
    public nav: NavController,
    public navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {}

  async ngOnInit() {
    this.addressData = new Address();
    // this.addressData.longitude = 0;
    // this.addressData.latitude = 0;
    this.callback = this.navParams.get('callback');
    this.setLanguage();
    // await this.getAddress();
  }

  /**
   *
   */
  async setLanguage() {
    await this.storage.get('vendor_language').then((data) => {
      const language = data as string;
      if (language.toString() == 'ES') {
        this.addressLanguage = this.addressEN;
      } else {
        this.addressLanguage = this.addressES;
      }
    });
  }

  /**
   *
   */
  async getAddress() {
    navigator.geolocation.getCurrentPosition((location) => {
      let latLng = new Leaflet.LatLng(
        location.coords.latitude,
        location.coords.longitude
      );
      this.lat = location.coords.latitude;
      this.lng = location.coords.longitude;
      this.mymap = Leaflet.map('mapid').setView(latLng, 15);
      Leaflet.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGVjbm9pbnRlY2EiLCJhIjoiY2s1aDlkZnJzMDFleTNrbnh3enZnNGo4aCJ9.q7uTFEKcKNL1_cBC_Uiulg',
        {
          maxZoom: 25,
          attribution:
            'Datos del mapa de &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imágenes © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: 'mapbox/streets-v11',
        }
      ).addTo(this.mymap);

      this.marker = Leaflet.marker(latLng)
        .setIcon(
          new Leaflet.Icon({
            iconUrl: './assets/leafletImg/marker-icon-2x.png',
          })
        )
        .addTo(this.mymap);

      this.mymap.on('move', (e) => {
        this.moveEvent();
      });
    });
  }

  /**
   *
   */
  moveEvent() {
    let latLng = this.mymap.getCenter();
    this.lat = latLng.lat;
    this.lng = latLng.lng;
    this.marker.setLatLng(latLng);
  }

  /**
   *
   */
  async getPosition() {
    /*this.http.get("https://api.mapbox.com/geocoding/v5/mapbox.places/" + this.lng + "," + this.lat + ".json?access_token=pk.eyJ1IjoidGVjbm9pbnRlY2EiLCJhIjoiY2s1aDlkZnJzMDFleTNrbnh3enZnNGo4aCJ9.q7uTFEKcKNL1_cBC_Uiulg").subscribe(
      data => {
        // console.log(data);
        let allAddressData = Object.assign(new AddressFeature(), data);
        this.setAddressData(allAddressData);
      }
    );*/
    this.presentPopover(this.addressData);
  }

  /**
   *
   * @param allAddressData Json Object conteneniendo toda la info necesaria
   */
  setAddressData(allAddressData: any) {
    this.validatePosition();
    this.addressData.longitude = this.lng;
    this.addressData.latitude = this.lat;
    allAddressData.features.forEach((feature) => {
      let id = feature.id.toString().split('.')[0];
      this.addressData.addressType = 0;
      switch (id) {
        case 'country':
          this.countryData = feature;
          this.addressData.countryCode = this.countryData.text;
          break;
        case 'region':
          this.stateData = feature;
          this.addressData.region = this.stateData.text;
          break;
        case 'district':
          this.countyData = feature;
          this.addressData.subregion = this.countyData.text;
          break;
        case 'place':
          this.cityData = feature;
          this.addressData.city = this.cityData.text;
          break;
        default:
          break;
      }
    });
  }

  /**
   *
   * @param address
   */
  async presentPopover(address: Address) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RegisterAddressComponent,
      componentProps: {
        addressData: address,
        thirdAddressLang: this.addressLanguage,
      },
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null) {
        await this.callback(data);
        this.nav.pop();
      }
    });
  }

  /**
   *
   */
  setActualPosition() {
    navigator.geolocation.getCurrentPosition((location) => {
      let latlng = new Leaflet.LatLng(
        location.coords.latitude,
        location.coords.longitude
      );
      this.marker.setLatLng(latlng);
      this.mymap.setView(latlng, 18);
    });
  }

  /**
   *
   */
  async validatePosition() {
    navigator.geolocation.getCurrentPosition((location) => {
      if (
        this.lat != location.coords.latitude ||
        this.lng != location.coords.longitude
      ) {
        this.addressData = new Address();
      }
    });
  }
}
