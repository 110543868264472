import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { CatalogModel } from './../../../businessCore/oc-models/CatalogModel';
import { PurchOrder } from './../../../businessCore/oc-models/PurchOrder';

@Component({
  selector: 'app-info-filter-oc-pop',
  templateUrl: './info-filter-oc-pop.component.html',
  styleUrls: ['./info-filter-oc-pop.component.scss'],
})
export class InfoFilterOcPopComponent {
  filterData: PurchOrder[] = [];
  companyList: CatalogModel[] = [];
  proveedorList: CatalogModel[] = [];
  estadosApList: CatalogModel[] = [];
  estadosList: CatalogModel[] = [];
  creadoPorList: CatalogModel[] = [];
  wrongDateFlag: boolean;
  startDate: string = new Date().toISOString();
  finalDate: string = new Date().toISOString();
  typeFilter: number = 0;

  constructor(
    public viewCtrl: PopoverController,
    private navParams: NavParams
  ) {
    this.filterData = this.navParams.get('purchList');
    this.typeFilter = this.navParams.get('typeFilter');
    this.companyList = this.navParams.get('companyList');
    this.proveedorList = this.navParams.get('proveedorList');
    this.estadosApList = this.navParams.get('estadosApList');
    this.estadosList = this.navParams.get('estadosList');
    this.creadoPorList = this.navParams.get('creadoPorList');
    const initD = this.navParams.get('startDate');
    const endD = this.navParams.get('finalDate');
    if (initD !== null && initD !== undefined) {
      this.startDate = initD.toISOString();
    }

    if (endD !== null && endD !== undefined) {
      this.finalDate = endD.toISOString();
    }

    this.wrongDateFlag = false;
    if (this.typeFilter === 1 || this.typeFilter === 3) {
      this.getDataArea();
    }

    if (this.typeFilter === 1 || this.typeFilter === 2) {
      this.getProveedor();
    }

    if (this.typeFilter === 2 || this.typeFilter === 3) {
      this.getCreadoPor();
    }

    if (
      (this.estadosList === null ||
        this.estadosList === undefined ||
        this.estadosList.length === 0) &&
      this.typeFilter === 1
    ) {
      this.getEstados();
    }

    if (
      (this.estadosApList === null ||
        this.estadosApList === undefined ||
        this.estadosApList.length === 0) &&
      this.typeFilter === 1
    ) {
      this.getEstadosAp();
    }
  }

  getDataArea() {
    this.filterData.forEach((data) => {
      if (
        this.companyList.find(
          (data2) =>
            (data2.getId() as any) === (data.getDataArea().getId() as any)
        ) === undefined
      ) {
        this.companyList.push(
          new CatalogModel(
            data.getDataArea().getId() as any,
            data.getDataArea().getName()
          )
        );
      }
    });
  }

  getProveedor() {
    this.filterData.forEach((data) => {
      if (
        this.proveedorList.find(
          (data2) => (data2.getId() as any) === (data.getAccountNum() as any)
        ) === undefined
      ) {
        if (data.getVendName().length === 0) {
          this.proveedorList.push(
            new CatalogModel(data.getAccountNum() as any, data.getAccountNum())
          );
        } else {
          this.proveedorList.push(
            new CatalogModel(data.getAccountNum() as any, data.getVendName())
          );
        }
      }
    });
  }

  getCreadoPor() {
    this.filterData.forEach((data) => {
      if (
        this.creadoPorList.find(
          (data2) => (data2.getId() as any) === (data.getCreatedBy() as any)
        ) === undefined
      ) {
        this.creadoPorList.push(
          new CatalogModel(data.getCreatedBy() as any, data.getCreatedBy())
        );
      }
    });
  }

  getEstados() {
    this.estadosList.push(
      new CatalogModel(1, 'ORDEN ABIERTA'),
      new CatalogModel(2, 'RECIBIDO'),
      new CatalogModel(3, 'FACTURADO'),
      new CatalogModel(4, 'CANCELADO')
    );
  }

  getEstadosAp() {
    this.estadosApList.push(
      new CatalogModel(0, 'BORRADOR'),
      new CatalogModel(10, 'EN REVISIÓN'),
      new CatalogModel(20, 'RECHAZADO'),
      new CatalogModel(30, 'APROBADO'),
      new CatalogModel(35, 'EN REVISIÓN EXTERNA'),
      new CatalogModel(40, 'CONFIRMADO'),
      new CatalogModel(50, 'FINALIZADA')
    );
  }

  onChangeCompany(event) {
    this.companyList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    for (let i of event.detail.value as CatalogModel[]) {
      this.companyList.find(
        (element) => element.getId() === i.getId()
      ).selected = true;
    }
  }

  onChangeProveedor(event) {
    this.proveedorList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    for (let i of event.detail.value as CatalogModel[]) {
      this.proveedorList.find(
        (element) => element.getId() === i.getId()
      ).selected = true;
    }
  }

  onChangeEstadoAp(event) {
    this.estadosApList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    for (let i of event.detail.value as CatalogModel[]) {
      this.estadosApList.find(
        (element) => element.getId() === i.getId()
      ).selected = true;
    }
  }

  onChangeEstado(event) {
    this.estadosList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    for (let i of event.detail.value as CatalogModel[]) {
      this.estadosList.find(
        (element) => element.getId() === i.getId()
      ).selected = true;
    }
  }

  onChangeCreado(event) {
    this.creadoPorList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    for (let i of event.detail.value as CatalogModel[]) {
      this.creadoPorList.find(
        (element) => element.getId() === i.getId()
      ).selected = true;
    }
  }

  dismissNull() {
    if (this.typeFilter === 1) {
      this.estadosApList.forEach((data) => (data.selected = false)); //Pone en falso todas...
      this.estadosList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    }

    if (this.typeFilter === 1 || this.typeFilter === 3) {
      this.companyList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    }

    if (this.typeFilter === 2 || this.typeFilter === 3) {
      this.creadoPorList.forEach((data) => (data.selected = false));
    }

    if (this.typeFilter === 1 || this.typeFilter === 2) {
      this.proveedorList.forEach((data) => (data.selected = false)); //Pone en falso todas...
    }

    this.startDate = ''; // new Date().toISOString();
    this.finalDate = ''; // new Date().toISOString();
    this.dismiss();
  }

  dismiss() {
    this.wrongDateFlag = false;
    //Devuelve los filtros seleccionados.
    const initD = this.startDate ? new Date(this.startDate) : null;
    const endD = this.finalDate ? new Date(this.finalDate) : null;
    if (initD !== null) {
      initD.setHours(0);
      initD.setMinutes(0);
      initD.setSeconds(0);
      initD.setMilliseconds(0);
    }

    if (endD !== null) {
      endD.setHours(0);
      endD.setMinutes(0);
      endD.setSeconds(0);
      endD.setMilliseconds(0);
    }

    if (initD !== null && endD !== null && endD <= initD) {
      this.wrongDateFlag = true;
    } else {
      this.viewCtrl.dismiss({
        companyList: this.companyList,
        proveedorList: this.proveedorList,
        estadosApList: this.estadosApList,
        estadosList: this.estadosList,
        startDate: initD,
        finalDate: endD,
        creadoPorList: this.creadoPorList,
      });
    }
  }
}
