// Native-Plugins
import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

// BusinessCore
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { OrderLine } from './../../businessCore/OrderLine';
import { productList } from './../../businessCore/productList';

@Component({
  selector: 'app-add-to-list',
  templateUrl: './add-to-list.component.html',
  styleUrls: ['./add-to-list.component.scss'],
})
export class AddToListComponent {
  // Arreglo con el conjunto de listas que tiene creadas un cliente
  listas: productList[] = [];

  // NGModel para el nombre de la lista a crear
  listName: string = '';

  // Objeto con los datos del producto que se asociaran a una determinada lista
  itemToAdd: OrderLine;

  /**
   * Class constructor with these injections:
   * @param storage Storage is an easy way to store key/value pairs and JSON objects.
   * @param params object that exists on a page and can contain data for that particular view.
   * @param viewCtrl Access various features and information about the current view.
   */
  constructor(
    private storage: StorageService,
    public viewCtrl: ModalController,
    private navParams: NavParams
  ) {
    this.storage.get('listas_productos').then((value) => {
      if (value != null) {
        this.listas = JSON.parse(value) as productList[];
      }
    });
    this.itemToAdd = this.navParams.get('item') as OrderLine;
    if (this.itemToAdd == null) {
      this.viewCtrl.dismiss();
    }
  }

  /**
   * Metodo utilizado para crear una nueva lista.
   * Se debe proporcionar el nombre, que es capturado por medio del
   * binding que existe con el atributo 'listName'
   */
  CreateNewList() {
    let newList: productList = new productList();
    newList.name = this.listName;
    newList.items = [];
    this.listas.push(newList);
    this.storage.set('listas_productos', JSON.stringify(this.listas));
    this.listName = '';
  }

  /**
   * Metodo utilizado para validar si un producto ya se encuentra
   * registrado en el arreglo de items que tiene una determinada lista
   * @param item objeto de tipo productList sobre el que se valida la existencia del producto
   */
  itemOnList(item: productList) {
    let response: boolean = false;
    item.items.forEach((value) => {
      if (
        value.itemId == this.itemToAdd.itemId &&
        value.unitId == this.itemToAdd.unitId
      ) {
        response = true;
      }
    });
    return response;
  }

  /**
   * Metodo utilizado para remover/eliminar una lista del arreglo
   * @param item objeto de tipo productList que se eliminara del arreglo de listas
   */
  removefromList(item: productList) {
    item.items = item.items.filter(
      (value) =>
        !(
          value.itemId == this.itemToAdd.itemId &&
          value.unitId == this.itemToAdd.unitId
        )
    );
    this.storage.set('listas_productos', JSON.stringify(this.listas));
  }

  /**
   * Metodo utilizado para agregar un producto a una lista
   * @param item objeto de tipo productList que representa la lista
   * sobre la que se agregara el nuevo producto
   * itemToAdd contiene la informacion del articulo que se va agregar a la lista,
   * este se obtiene por medio del binding que existe con el atributo definido en la clase
   */
  addToList(item: productList) {
    this.itemToAdd.salesQuantity = 1;
    this.itemToAdd.totalSale =
      this.itemToAdd.salesQuantity * this.itemToAdd.salePrice;
    item.items.push(this.itemToAdd);
    this.storage.set('listas_productos', JSON.stringify(this.listas));
  }
}
