import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  LoadingController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { AssetCertificationPopoverComponent } from 'src/app/components/asset-certification-popover/asset-certification-popover.component';
import { FixedAsset } from '../../businessCore/FixedAsset';
import { RegisterData } from '../../businessCore/RegisterData';
import { Worker } from '../../businessCore/Worker';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';

@Component({
  selector: 'app-asset-certification',
  templateUrl: './asset-certification.component.html',
  styleUrls: ['./asset-certification.component.scss'],
})
export class AssetCertificationPage implements OnInit {
  assetCertificationList: any[];
  assetCertificationListTemp: any[];
  assetCertificationMap: Map<string, any[]>;
  isLoaded: boolean;

  auth: number;
  componentId: any;

  worker: Worker;
  workerVatnum: string;

  strPeriod: string;
  strPeriodDate: string;

  periodicity: number;
  certificationId: string = '01';

  months: Map<number, string>;

  constructor(
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    public navParams: NavParams,
    public authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService,
    private _apiProvider: webServiceProvider,
    public storage: StorageService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isLoaded = false;
  }

  async refreshPage() {
    this.navService.navigateTo(
      NavigationRoutes.AssetCertificationPage,
      {
        auth: this.auth,
        code: this.componentId,
      },
      { replaceUrl: true }
    );
  }

  async ngOnInit() {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    await loading.present(); //Muestra pantalla de cargado.
    this.months = new Map([
      [0, 'Enero'],
      [1, 'Febrero'],
      [2, 'Marzo'],
      [3, 'Abril'],
      [4, 'Mayo'],
      [5, 'Junio'],
      [6, 'Julio'],
      [7, 'Agosto'],
      [8, 'Septiembre'],
      [9, 'Octubre'],
      [10, 'Noviembre'],
      [11, 'Diciembre'],
    ]);
    this.auth = Number(this.navParams.get('auth'));
    this.componentId = this.navParams.get('code');

    if (this.auth === 0 && this.authService.hasCookie()) {
      this.navService.setRoot(
        this.activatedRoute.component.name as NavigationRoutes,
        {
          auth: 1,
          code: this.componentId,
        }
      );
    } else {
      if (this.auth === 1) {
        let checkedToken = await this.authGuard.checkToken();
        if (
          checkedToken &&
          this.authGuard.componentAllow(
            this.authService.getComponentsInfo(),
            this.componentId
          )
        ) {
          this.workerVatnum =
            this.authService.getTokenData('refresh')['vat-num'];
        } else {
          window.location.href = 'http://giiis01:8025/';
        }
      } else {
        await this.storage.get('register_data').then((data) => {
          const register_data = JSON.parse(data) as RegisterData;
          this.workerVatnum = register_data.id_card;
          // DEBUG
          //this.workerVatnum = '1-1236-0499';
          // DEBUG
        });
      }
      const workerList = await this.getWorkerList('', this.workerVatnum);
      try {
        this.worker = workerList[0];
        this.assetCertificationList = await this.getAssetsList(
          this.worker.getPersonnelNumber()
        );
        this.assetCertificationMap = await this.listToDictionary(
          this.assetCertificationList
        );
        // console.log(this.assetCertificationList);
        this.periodicity = await this.getCertificationPeriodicity(
          this.worker.getRecId(),
          this.certificationId
        );
      } catch (error) {
        this.createAlert(
          'Error de conexión',
          'Se ha presentado un error, favor intentar más tarde'
        );
      }
      await loading.dismiss();
    }
  }

  /**
   *
   * @param assetList
   * @returns
   */
  async listToDictionary(assetList: any) {
    const myClonedArray = [];
    for (let asset of assetList) {
      asset.accuDepreciation = Math.abs(asset.accuDepreciation);
      myClonedArray.push(Object.assign({}, asset));
    }
    let map = new Map<string, any[]>();
    for (let asset of myClonedArray) {
      if (!map.has(asset.dataAreaId)) {
        map.set(asset.dataAreaId, []);
      }
      asset.acquValue = asset.acquValue
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      asset.accuDepreciation = asset.accuDepreciation
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      asset.netBookValue = asset.netBookValue
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      map.get(asset.dataAreaId).push(asset);
    }
    this.isLoaded = true;
    return map;
  }

  /**
   *
   * @param map
   * @returns
   */
  getKeys(map) {
    return Array.from(map.keys());
  }

  /**
   *
   */
  async isInAvailableCertPeriod() {
    if (this.periodicity == -1) {
      this.createAlert(
        'Certificación inválida',
        'No se puede certificar porque no se ha configurado la periodicidad su usuario'
      );
    } else {
      const isAvailable = await this.isCertPeriodValid(
        this.worker.getRecId(),
        this.certificationId
      );
      // console.log(isAvailable);
      if (isAvailable == 1) {
        this.presentCertificatePopover();
      } else if (isAvailable == 0) {
        this.createAlert(
          'Periodo incorrecto',
          'Se encuentra fuera de su periodo de certificación'
        );
      } else {
        this.createAlert(
          'Error',
          'No se ha logrado verificar si se halla en un periodo válido'
        );
      }
    }
  }

  /**
   *
   * Muestra el componente popover para elegir los filtros necesarios.
   *
   */
  async presentCertificatePopover() {
    const certOverPeriod = await this.canCertificateOutsidePeriod(
      this.worker.getRecId(),
      this.certificationId
    );
    let resp: any;
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AssetCertificationPopoverComponent,
      componentProps: {
        mainText:
          'Por este medio certifico que tengo bajo mi cuidado los activos de la lista y además doy fe de que todo se encuentra en buen estado.',
        secondaryText: 'Periodo a certificar:',
        buttonText: 'Enviar',
        certPeriodicity: this.periodicity,
        workerRecId: this.worker.getRecId(),
        certOverPeriod: certOverPeriod,
      },
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null) {
        // console.log(data);
        this.strPeriod = data.strPeriod;
        this.strPeriodDate = data.strPeriodDate;
        resp = await this.CreateAssetCertificationPdf(
          this.worker.getName(),
          this.workerVatnum,
          this.strPeriod,
          this.assetCertificationList
        );
        if (resp == 1) {
          resp = await this.certificate();
          // console.log("Certificate", resp);
          if (resp == '1') {
            this.sendEmail();
            this.presentSendMsgPopover();
          } else {
            this.createAlert(
              'Error',
              'Ha sucedido un error. Intente más tarde.'
            );
          }
        } else {
          this.createAlert(
            'Error',
            'Ha sucedido un error al crear el archivo. Intente más tarde.'
          );
        }
      }
    });
  }

  /**
   *
   */
  async presentSendMsgPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AssetCertificationPopoverComponent,
      componentProps: {
        mainText: 'La certificación se ha enviado correctamente',
        secondaryText: '',
        buttonText: 'Aceptar',
      },
    });
    popover.present();
  }

  /**
   *
   * @returns
   */
  private createEmailBody(): string {
    let date = new Date();
    let msgBody: string;
    let count: number = 0;
    msgBody = 'Certificación de activos fijos&#60;br&#62;';
    msgBody +=
      this.worker.getName() +
      ', realizó la certificación de control y cuido de activos, el día ' +
      date.getDate() +
      ' de ' +
      this.months.get(date.getMonth()) +
      ' a las ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ' con la siguiente lista de activos fijos: &#60;br&#62;';
    for (let i of this.assetCertificationList) {
      count += 1;
      msgBody += '&#60;br&#62;' + count.toString() + ') ' + i.getAssetId();
      if (i.getBarcode()) {
        msgBody += ' / ' + i.getBarcode();
      }
      msgBody += ' / ' + i.getAssetName();
    }
    msgBody +=
      '&#60;br&#62;&#60;br&#62; La certificación se encuentra a la espera de ser aprobada.';
    // console.log(msgBody);
    return msgBody;
  }

  /**
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }

  //**********WebServiceProvider**********//

  /**
   *
   * Consume el servicio necesario para retornar la lista de activos por usuario.
   *
   * @param personnelNumber Es el personnelNumber del usuario.
   *
   * @returns Una lista de activos por usuario.
   */
  async getAssetsList(personnelNumber: string) {
    const resp = await this._apiProvider.getAssetByWorker(personnelNumber); //Obtiene los activos por usuario.
    let list: FixedAsset[] = resp as FixedAsset[]; //Identifica el response como una lista de objetos FixedAsset.
    return list;
  }

  /**
   *
   * @param personnelNumber
   * @param vatnum
   * @returns
   */
  async getWorkerList(personnelNumber: string = '', vatnum: string = '') {
    const resp = await this._apiProvider.getWorker(personnelNumber, vatnum);
    const list: Worker[] = resp as Worker[];
    return list;
  }

  /**
   *
   * @param pWorkerFullName
   * @param pWorkerVatnum
   * @param pPeriod
   * @param pAssetList
   * @returns
   */
  // async CreateAssetCertificationPdf(pWorkerFullName: string, pWorkerVatnum: string, pPeriod: string, pAssetList: any[]) {
  //   const resp = await this._apiProvider.createAssetCertificationPdf(pWorkerFullName, pWorkerVatnum, pPeriod, pAssetList);
  //   return resp;
  // }

  async CreateAssetCertificationPdf(
    pWorkerFullName: string,
    pWorkerVatnum: string,
    pPeriod: string,
    pAssetList: any[]
  ) {
    const resp = await this._apiProvider.createAssetCertificationPdf(
      pWorkerFullName,
      pWorkerVatnum,
      pPeriod,
      pAssetList
    );
    return resp;
  }

  /**
   *
   * @param pWorkerRecId
   * @param pCertificationId
   * @returns
   */
  async getCertificationPeriodicity(
    pWorkerRecId: string,
    pCertificationId: string
  ) {
    const resp = await this._apiProvider.getCertificationPeriodicity(
      pWorkerRecId,
      pCertificationId
    );
    const numResp: number = resp as number;
    return numResp;
  }

  async certificate() {
    const resp = await this._apiProvider.certificate(
      this.worker,
      this.worker,
      this.strPeriod,
      this.certificationId,
      this.strPeriodDate,
      this.assetCertificationList.length
    );
    return resp;
  }

  async sendEmail() {
    if (this.worker.getEmail()) {
      await this._apiProvider.sendEmail(
        this.createEmailBody(),
        this.worker.getEmail(),
        'Certificación de activos fijos'
      );
    } else {
      this.createAlert(
        'Correo eletrónico no enviado',
        'No se tiene una dirección de correo electrónico de la empresa configurada a su usuario.'
      );
    }
  }

  async isCertPeriodValid(pWorkerRecId: string, pStrDate: string) {
    const resp = await this._apiProvider.isCertPeriodValid(
      pWorkerRecId,
      '01',
      pStrDate
    );
    const numResp: number = resp as number;
    return numResp;
  }

  async canCertificateOutsidePeriod(
    pWorkerRecId: string,
    pCertificationId: string
  ) {
    const resp = await this._apiProvider.canCertificateOutsidePeriod(
      pWorkerRecId,
      pCertificationId
    );
    const numResp: number = resp as number;
    return numResp;
  }

  getAssetCertification(assetCertificationMap, key) {
    return assetCertificationMap.get(key);
  }
}
