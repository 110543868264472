//Native
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

//Provider
import { webServiceProvider } from '../../provider/webServiceProvider';

//Components
import { SearchProviderComponent } from '../oc-components/search-provider/search-provider.component';

//Classes
import { ModalController, NavController } from '@ionic/angular';
import { EntityName } from '../../businessCore/EntityName';
import { IdentifierType } from '../../businessCore/IdentifierType';
import { DataArea } from '../../businessCore/oc-models/DataArea';

//import { DirectivesModule } from './../../directives/directives.module';

@Component({
  selector: 'app-vendor-register-general',
  templateUrl: './vendor-register-general.component.html',
  styleUrls: ['./vendor-register-general.component.scss'],
})
export class VendorRegisterGeneralComponent implements OnInit {
  @Input() generalData: any = {};
  @Output() generalDataChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() generalLenguage: { [key: string]: any } = {};
  @Input() changeLanguageTo = '';
  @Output() languageChanger = new EventEmitter<string>();
  @Output() screenChanger = new EventEmitter<number>();

  dataAreaList: DataArea[];
  dataAreaSelected: DataArea = new DataArea(
    'CMER',
    'MERCADEO DE ARTÍCULOS DE CONSUMO'
  );

  identifierTypeList: IdentifierType[] = [
    new IdentifierType('0', 'PerFisica', 'Persona física', '*-****-****', 11),
    new IdentifierType(
      '1',
      'Extranjero',
      'Extranjeros',
      '********************',
      20
    ),
    new IdentifierType('2', 'PerJurid', 'Jurídica', '*-***-******', 12),
    new IdentifierType('3', 'DIMEX', 'Dimex', '************', 12),
    new IdentifierType('4', 'Nite', 'Nite', '*************', 13),
  ];

  vendorIdType: IdentifierType = new IdentifierType('', '', '', '', 0);
  representIdType: IdentifierType = new IdentifierType('', '', '', '', 0);

  vendorVatnum: string = '';
  representVatnum: string = '';

  vendorName: EntityName = new EntityName();
  representName: EntityName = new EntityName();

  nextButton: Boolean;

  constructor(
    private _apiProvider: webServiceProvider,
    public modalCtrl: ModalController,
    public navCtrl: NavController
  ) {}

  async ngOnInit() {
    this.setGeneralVariables();
  }

  /**
   *
   * @param option
   */
  async selectMenuOption(menuOption: number) {
    switch (menuOption) {
      case 0: //Select DataArea
        this.dataAreaList = await this.getDataAreaList();
        this.selectSpecificData(this.dataAreaList, menuOption, false);
        break;
      case 1: //Select VatnumType Vendor
        this.selectSpecificData(this.identifierTypeList, menuOption, true);
        break;
      case 2: //Select VatnumType Representative
        let modifiedVatnumArray: IdentifierType[] =
          this.identifierTypeList.slice(0, 2);
        modifiedVatnumArray.push(...this.identifierTypeList.slice(3, 5));
        modifiedVatnumArray.unshift(
          new IdentifierType('', '', 'Ninguna', '', 0)
        );
        this.selectSpecificData(modifiedVatnumArray, menuOption, true);
        break;
      default:
        break;
    }
  }

  /**
   *
   * Muestra un popover donde se elige.
   *
   */
  async selectSpecificData(
    listInfo: any[],
    menuOption: number,
    pShow: boolean
  ) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
        show: pShow,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.setDataSelected(data.objSelected, menuOption);
        }
      }
    });
  }

  /**
   *
   */
  async getDataAreaList() {
    const resp = await this._apiProvider.getDataAreaByComponent(28);
    const list: DataArea[] = resp as DataArea[];
    return list;
  }

  /**
   *
   * @param dataSelected
   * @param menuOption
   */
  async setDataSelected(dataSelected: any, menuOption: number) {
    switch (menuOption) {
      case 0: //DataArea
        this.dataAreaSelected = dataSelected;
        break;
      case 1:
        this.vendorVatnum = '';
        this.vendorName = new EntityName();
        this.vendorIdType = dataSelected;
        break;
      case 2:
        this.representVatnum = '';
        this.representName = new EntityName();
        this.representIdType = dataSelected;
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param value
   * @param idType
   * @param whichInput
   */
  async setEntityName(event: any, idType: number, whichInput: number) {
    let value = (event.target as HTMLInputElement).value;
    if (whichInput == 0) {
      this.vendorVatnum = value.toString();
      this.vendorName = await this.getEntityName(value, idType);
    } else {
      this.representVatnum = value.toString();
      this.representName = await this.getEntityName(value, idType);
    }
  }

  /**
   *
   * @param pVatnum
   * @param idType
   */
  async getEntityName(pVatnum: string, idType: number) {
    const resp = await this._apiProvider.getEntityName(pVatnum, idType);
    const list: EntityName[] = resp as EntityName[];
    return list[0];
  }

  /**
   *
   */
  disableNext() {
    if (this.dataAreaSelected.getId() && this.vendorName.getName()) {
      if (
        (!this.representIdType.getId() && this.vendorIdType.getId() != '2') ||
        this.representName.getName()
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   */
  changeLanguage() {
    switch (this.changeLanguageTo) {
      case 'ES':
        this.languageChanger.emit('EN');
        break;
      case 'EN':
        this.languageChanger.emit('ES');
        break;
      default:
        break;
    }
  }

  setGeneralVariables() {
    this.dataAreaSelected = this.generalData.company;

    this.vendorIdType = this.generalData.vendorIdType;
    this.vendorName = this.generalData.vendorEntityName;
    this.vendorVatnum = this.generalData.vendorVatnum;

    this.representIdType = this.generalData.representIdType;
    this.representName = this.generalData.representEntityName;
    this.representVatnum = this.generalData.representVatnum;
  }

  setGlobalVariables() {
    this.generalData.company = this.dataAreaSelected;

    this.generalData.vendorIdType = this.vendorIdType;
    this.generalData.vendorEntityName = this.vendorName;
    this.generalData.vendorVatnum = this.vendorVatnum;

    this.generalData.representIdType = this.representIdType;
    this.generalData.representEntityName = this.representName;
    this.generalData.representVatnum = this.representVatnum;
  }

  /**
   *
   */
  changeScreen() {
    this.setGlobalVariables();
    // console.log(this.generalData);
    this.generalDataChange.emit(this.generalData);
    this.screenChanger.emit(2);
  }

  validateVendorName() {
    let resp;
    if (this.vendorIdType.getId() == '0' || this.vendorIdType.getId() == '2') {
      resp = true;
    } else {
      resp = false;
    }
    return resp;
  }

  validateVendorNameDisabled() {
    let resp;
    if (
      !this.vendorVatnum ||
      this.vendorIdType.getId() == '0' ||
      this.vendorIdType.getId() == '2'
    ) {
      resp = true;
    } else {
      resp = false;
    }
    return resp;
  }

  validateRepresentName() {
    let resp;
    if (this.representIdType.getId() == '0') {
      resp = true;
    } else {
      resp = false;
    }
    return resp;
  }

  validateRepresentNameDisabled() {
    let resp;
    if (!this.representVatnum || this.representIdType.getId() == '0') {
      resp = true;
    } else {
      resp = false;
    }
    return resp;
  }
}
