export class RoleSpecifidInfo {
  constructor(
    private a_departamento: string,
    private b_centroCosto: string,
    private c_proposito: string,
    private roleID: string,
    private roleSpecificId: string
  ) {}
  getRoleID() {
    return this.roleID;
  }

  getRoleSpecificId() {
    return this.roleSpecificId;
  }

  getA_Departamento() {
    return this.a_departamento;
  }

  getB_CentroCosto() {
    return this.b_centroCosto;
  }

  getC_Proposito() {
    return this.c_proposito;
  }
}
