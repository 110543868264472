//Native
import { Component, OnInit } from '@angular/core';

//Models-Classes
import { AssetLocation } from '../../businessCore/AssetLocation';
import { FilterData, FixedAsset } from '../../businessCore/FixedAsset';
import { RegisterData } from '../../businessCore/RegisterData';
import { Worker } from '../../businessCore/Worker';

//Pipes
import { FilterOneKeyListPipe } from '../../pipes/filter-one-key-list/filter-one-key-list';
import { FixedAssetFilterPipe } from '../../pipes/fixed-asset-filter/fixed-asset-filter';

//Components

//Provider
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  AlertOptions,
  IonItemSliding,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { AssetRequest } from '../../businessCore/AssetRequest';
import { AssociatedInstance } from '../../businessCore/AssociatedInstance';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';
import { AssetFilterComponent } from '../asset-filter/asset-filter.component';
import { SearchableSelectComponent } from '../searchable-select/searchable-select.component';

@Component({
  selector: 'app-assets-transfer',
  templateUrl: './assets-transfer.component.html',
  styleUrls: ['./assets-transfer.component.scss'],
})
export class AssetsTransferComponent implements OnInit {
  ///////////////////////////////
  //VARIABLES
  ///////////////////////////////

  logger: Worker; //Persona que inicia sesión.
  loggerVatnum: string; //Cédula del logger.
  // subalterns: Worker[]; //Lista de subalternos
  assetResponsible: Worker; //Responsable actual de los activos.
  assetRespVat: string; //Cédula del Responsable actual de los activos.

  assetsListByWorker: FixedAsset[]; //Lista de todos los activos por empleado, esta lista no se altera.
  assetsListToFilter: FixedAsset[]; //Contiene todos los activos sin alteración, necesarios por las modificaciones sobre la lista previa.
  assetsListToSearch: FixedAsset[]; //Contiene todos los activos sin alteración, necesarios por las modificaciones sobre la lista previa.
  assetsListChecked: FixedAsset[]; //Todos los activos que serán pasados.
  assetsListApproval: FixedAsset[]; //Tiene una lista de todos los activos a transferir que requieren aprobación.

  sendAssetRequestList: AssetRequest[];
  newResponsible: string;

  companies: any[]; //Contiene todas las compañías que tienen relación con los activos actuales, para filtrar por ellos.
  categories: any[]; //Contiene todas las categorias que tienen relación con los activos actuales, para filtrar por ellos.
  statusList: any[]; //Contiene un indicador de si los activos están pendientes o no, para filtrar por ellos.

  filterData: FilterData; //Una estructura que contiene companies y categories, así se pasan los valores al popover.

  checkNewLocation: boolean; //Indica si se pretende elegir un nuevo Aposento.
  sortByAssetNumberAsc: boolean; //Indica que el Radio Button de número de activo ascendente está seleccionado o no.
  sortByAssetNumberDesc: boolean; //Indica que el Radio Button de número de activo descendente está seleccionado o no.
  sortByBarcodeAsc: boolean; //Indica que el Radio Button de barcode ascendente está seleccionado o no.
  sortByBarcodeDesc: boolean; //Indica que el Radio Button de barcode descendente está seleccionado o no.
  sortByAssetNameAsc: boolean; //Indica que el Radio Button del nombre del activo ascendente está seleccionado o no.
  sortByAssetNameDesc: boolean; //Indica que el Radio Button del nombre del activo descendente está seleccionado o no.
  sortByDataAreaIdAsc: boolean; //Indica que el Radio Button del código de la empresa es ascendente está seleccionado o no.
  sortByDataAreaIdDesc: boolean; //Indica que el Radio Button del código de la empresa es descendente está seleccionado o no.
  sortByAssetGroupNameAsc: boolean; //Indica que el Radio Button del nombre de la categoría es ascendente está seleccionado o no.
  sortByAssetGroupNameDesc: boolean; //Indica que el Radio Button del nombre de la categoría es descendente está seleccionado o no.

  // firstScreen: boolean;
  secondScreen: boolean;
  thirdScreen: boolean;

  assetResponsibles: Worker[];
  newAssetResponsible: Worker;

  assetLocations: AssetLocation[];
  newAssetLocation: AssetLocation;

  auth: number;
  componentId: any;

  constructor(
    private _apiProvider: webServiceProvider,
    private fixedAssetFilterPipe: FixedAssetFilterPipe,
    private OneKeyListPipe: FilterOneKeyListPipe,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public storage: StorageService,
    public authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService,
    public navCtrl: NavController,
    private navParams: NavParams,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.auth = Number(this.navParams.get('auth'));
    this.componentId = this.navParams.get('code');
  }

  async ngOnInit() {
    // this.logger = new Worker();
    // this.logger.recId = '5637602842';
    // this.logger.vatnum = '1-1690-0626';
    // this.logger.workerName = 'José Evelio Castro Quesada';
    // this.logger.personnelNumber = 'JECQ009';
    //console.log(this.subalterns);
    //Danilo: 1-0877-0940
    //Evelio: 1-1690-0626
    //Fabián: 1-0601-0848
    //Jonathan: 5-0399-0169
    //Kattia: '1-1236-0499'
    //Steven = '3-0512-0804';
    //Eduard = '1-1675-0364';
    // "155808475709"

    if (this.auth === 0 && this.authService.hasCookie()) {
      this.navService.setRoot(
        this.activatedRoute.component.name as NavigationRoutes,
        { auth: 1, code: this.componentId }
      );
    } else {
      if (this.auth === 1) {
        let checkedToken = await this.authGuard.checkToken();
        if (
          checkedToken &&
          this.authGuard.componentAllow(
            this.authService.getComponentsInfo(),
            this.componentId
          )
        ) {
          this.loggerVatnum =
            this.authService.getTokenData('refresh')['vat-num'];
          //console.log(this.loggerVatnum);
        } else {
          window.location.href = 'http://giiis01:8025/';
        }
      } else {
        await this.storage.get('register_data').then((data) => {
          const register_data = JSON.parse(data) as RegisterData;
          this.loggerVatnum = register_data.id_card;
          // DEBUG
          //this.loggerVatnum = '1-1236-0499';
          // DEBUG
        });
      }
      //Si el usuario que inició sesión utiliza una instancia de otro usuario
      //obtiene la cédula del usuario instancia.
      await this.storage.get('associatedPerson').then((data) => {
        const associatedPerson = JSON.parse(data) as AssociatedInstance;
        if (associatedPerson) {
          this.assetRespVat = associatedPerson.vatNum;
        } else {
          this.assetRespVat = this.loggerVatnum;
        }
      });

      const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
      await loading.present(); //Muestra pantalla de cargado.
      try {
        this.assetResponsibles = await this.getWorkerList(
          '',
          this.loggerVatnum
        );
        this.logger = this.assetResponsibles[0]; //Obtiene los datos de la persona que inició sesión.
        if (this.logger.getVatnum() === this.assetRespVat) {
          this.assetResponsible = this.logger;
        } else {
          this.assetResponsibles = await this.getWorkerList(
            '',
            this.assetRespVat
          );
          this.assetResponsible = this.assetResponsibles[0];
        }
        //console.log(this.assetResponsible.getRecId());
        this.sendAssetRequestList = await this.getSendAssetRequestList(
          0,
          this.assetResponsible.getRecId()
        );
        this.assetResponsibles = await this.getWorkerList();
        this.assetLocations = [];
        await this.displayAssetList();
        this.secondScreen = true;
      } catch (error) {
        this.createAlert(
          'Error de conexión',
          'Se ha presentado un error, favor intentar más tarde'
        );
      }
      await loading.dismiss();
    }
  }

  /**
   * Esta función crea un alert, se le debe pasar el encabezado y el cuerpo
   * @title es el encabezado
   * @subtitle es el cuerpo.
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }

  /**
   *
   * @param asset
   */
  async cancelTransfer(index, slidingItem: IonItemSliding) {
    let alert = await this.alertCtrl.create({
      header: '¿Desea cancelar el traslado?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            alert.dismiss(false);
            return false;
          },
        },
        {
          text: 'Sí',
          handler: () => {
            alert.dismiss(true);
            return false;
          },
        },
      ],
    });
    alert.present();
    alert.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data) {
        slidingItem.close();
        let emptyWorker: Worker = new Worker();
        let canceledRequest: AssetRequest = this.sendAssetRequestList.find(
          (request) =>
            request.getAssetId() ==
              this.assetsListToSearch[index].getAssetId() &&
            request.getDataAreaId() ==
              this.assetsListToSearch[index].getDataAreaId()
        );
        let resp = await this._apiProvider.alterAssetRequest(
          emptyWorker,
          [canceledRequest],
          '',
          5
        );
        if (resp == 1) {
          this.assetsListToSearch[index].pending = false;
        }
      }
    });
  }

  async addNewResponsibles() {
    for (let asset of this.assetsListByWorker) {
      for (let assetRequest of this.sendAssetRequestList) {
        if (
          asset.getAssetId() == assetRequest.getAssetId() &&
          asset.getDataAreaId() == assetRequest.getDataAreaId()
        ) {
          asset.newResponsible = assetRequest.getWorkerNameNew();
        }
      }
    }
  }

  /**
   *
   * Función que inicializa las variables
   *
   */
  initialize() {
    this.sortByAssetNumberAsc = true;
    this.sortByAssetNumberDesc = false;
    this.sortByBarcodeAsc = false;
    this.sortByBarcodeDesc = false;
    this.sortByAssetNameAsc = false;
    this.sortByAssetNameDesc = false;
    this.sortByDataAreaIdAsc = false;
    this.sortByDataAreaIdDesc = false;
    this.sortByAssetGroupNameAsc = false;
    this.sortByAssetGroupNameDesc = false;
    this.assetsListChecked = [];
    this.newAssetResponsible = new Worker();
    this.newAssetLocation = new AssetLocation();
    this.newResponsible = 'Jose Evelio Castro Quesada';
  }

  /**
   *
   * Muestra los datos de manera ordena y obtiene los elementos de compañía y categoría.
   * Y duplica los elementos en diferentes listas para realizar pipes sobre los mismos
   * sin perder datos.
   *
   */
  async displayAssetList() {
    this.initialize();
    this.assetsListByWorker = await this.getAssetsList(
      this.assetResponsible.getPersonnelNumber()
    );
    this.assetsListByWorker = this.sortProducts(
      'sortByAssetNumberAsc',
      this.assetsListByWorker
    );
    await this.addNewResponsibles();
    this.assetsListToFilter = this.assetsListByWorker;
    this.assetsListToSearch = this.assetsListToFilter;
    this.companies = this.assetsListByWorker
      .filter(
        (assetData, i, arr) =>
          arr.findIndex(
            (t) => t.getDataAreaId() === assetData.getDataAreaId()
          ) === i
      )
      .map((item) => {
        return {
          dataAreaId: item.getDataAreaId(),
          companyName: item.getCompanyName(),
          selected: false,
        };
      });
    this.categories = this.assetsListByWorker
      .filter(
        (assetData, i, arr) =>
          arr.findIndex(
            (t) => t.getAssetGroup() === assetData.getAssetGroup()
          ) === i
      )
      .map((item) => {
        return {
          assetGroup: item.getAssetGroup(),
          assetGroupName: item.getAssetGroupName(),
          selected: false,
        };
      });
    this.statusList = [
      { statusName: 'Pendiente', pending: true, selected: false },
      { statusName: 'Activo', pending: false, selected: false },
    ];
    this.filterData = {
      companies: this.companies,
      categories: this.categories,
      statusList: this.statusList,
    };
  }

  /**
   * Permite refrescar el componente.
   * @param e Es el evento en cuestión proporcionado desde el html
   */
  async refreshAssets() {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    await loading.present(); //Muestra pantalla de cargado.

    this.navService.navigateTo(NavigationRoutes.AssetsTransferComponent, {
      auth: this.auth,
      code: 22,
    });
    await this.displayAssetList();
    loading.dismiss();
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de activos por usuario.
   *
   * @param personnelNumber Es el personnelNumber del usuario.
   *
   * @returns Una lista de activos por usuario.
   */
  async getAssetsList(personnelNumber: string) {
    const resp = await this._apiProvider.getAssetByWorker(personnelNumber); //Obtiene los activos por usuario.
    const list: FixedAsset[] = resp as FixedAsset[]; //Identifica el response como una lista de objetos FixedAsset.
    return list;
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de trabajadores.
   *
   * @returns Una lista con los trabajadores activos a la fecha.
   */
  async getWorkerList(personnelNumber: string = '', vatnum: string = '') {
    const resp = await this._apiProvider.getWorker(personnelNumber, vatnum);
    const list: Worker[] = resp as Worker[];
    return list;
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de localizaciones de activos.
   *
   * @returns Una lista con todas las localizaciones de activos.
   */
  async getAssetsLocationList(
    workerResponsible: number = 0,
    location: string = ''
  ) {
    const resp = await this._apiProvider.getAssetLocation(
      workerResponsible,
      location
    );
    const list: AssetLocation[] = resp as AssetLocation[];
    return list;
  }

  /**
   *
   * @param pStatus
   * @param pRecId
   * @returns
   */
  async getSendAssetRequestList(pStatus: number, pRecId: string = '') {
    const resp = await this._apiProvider.getSendAssetRequest(pStatus, pRecId);
    const list: AssetRequest[] = resp as AssetRequest[];
    //console.log(list);
    return list;
  }

  /**
   *
   * Modifica la lista sobre la que se busca según los valores ingresado en la barra de búsqueda.
   *
   */
  searchAsset(event) {
    this.assetsListToSearch = this.fixedAssetFilterPipe.transform(
      this.assetsListToFilter,
      event.detail.value
    );
  }

  /**
   *
   * Muestra el componente popover para elegir los filtros necesarios.
   *
   */
  async presentAssetFilterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AssetFilterComponent,
      componentProps: {
        filterData: this.filterData,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        this.filterData = data;
        this.filterAsset();
      }
    });
  }

  /**
   *
   * Modifica la lista sobre la que se busca según los valores ingresado en la barra de búsqueda.
   *
   */
  filterAsset() {
    this.assetsListToFilter = this.assetsListByWorker;
    const newCompanies = this.filterAssetList('Empresa');
    const newCategories = this.filterAssetList('Categoria');
    const newStatusList = this.filterAssetList('StatusList');
    this.assetsListToFilter = this.OneKeyListPipe.transform(
      this.assetsListToFilter,
      newCompanies,
      1
    );
    this.assetsListToFilter = this.OneKeyListPipe.transform(
      this.assetsListToFilter,
      newCategories,
      1
    );
    this.assetsListToFilter = this.OneKeyListPipe.transform(
      this.assetsListToFilter,
      newStatusList,
      1
    );
    this.assetsListToSearch = this.assetsListToFilter;
  }

  /**
   *
   * Obtiene una lista y la filtra de acuerdo al elemento de filtrado elegido.
   *
   * @param type Indica el tipo de elemento por el que se debe filtrar.
   *
   * @returns Una lista de activos filtrados.
   */
  filterAssetList(type: string) {
    let result: string[] = [];
    switch (type) {
      case 'Empresa':
        for (let i of this.filterData.companies.filter(
          (element) => element.selected === true
        )) {
          result.push(i.dataAreaId);
        }
        break;
      case 'Categoria':
        for (let i of this.filterData.categories.filter(
          (element) => element.selected === true
        )) {
          result.push(i.assetGroup);
        }
        break;
      case 'StatusList':
        for (let i of this.filterData.statusList.filter(
          (element) => element.selected === true
        )) {
          result.push(i.pending);
        }
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Muestra un alert con valores para ordenar las listas.
   */
  async presentAssetSortAlert() {
    let alertOptions: AlertOptions = {
      header: '¿Cómo ordenar los activos?',
      inputs: [
        {
          type: 'radio',
          label: 'Número de activo (Menor a Mayor)',
          value: 'sortByAssetNumberAsc',
          checked: this.sortByAssetNumberAsc,
        },
        {
          type: 'radio',
          label: 'Número de activo (Mayor a Menor)',
          value: 'sortByAssetNumberDesc',
          checked: this.sortByAssetNumberDesc,
        },
        {
          type: 'radio',
          label: 'Código de barras (Menor a Mayor)',
          value: 'sortByBarcodeAsc',
          checked: this.sortByBarcodeAsc,
        },
        {
          type: 'radio',
          label: 'Código de barras (Mayor a Menor)',
          value: 'sortByBarcodeDesc',
          checked: this.sortByBarcodeDesc,
        },
        {
          type: 'radio',
          label: 'Nombre del activo (A -> Z)',
          value: 'sortByAssetNameAsc',
          checked: this.sortByAssetNameAsc,
        },
        {
          type: 'radio',
          label: 'Nombre del activo (Z -> A)',
          value: 'sortByAssetNameDesc',
          checked: this.sortByAssetNameDesc,
        },
        {
          type: 'radio',
          label: 'Código de la empresa (A -> Z)',
          value: 'sortByDataAreaIdAsc',
          checked: this.sortByDataAreaIdAsc,
        },
        {
          type: 'radio',
          label: 'Código de la empresa (Z -> A)',
          value: 'sortByDataAreaIdDesc',
          checked: this.sortByDataAreaIdDesc,
        },
        {
          type: 'radio',
          label: 'Categoría (A -> Z)',
          value: 'sortByAssetGroupNameAsc',
          checked: this.sortByAssetGroupNameAsc,
        },
        {
          type: 'radio',
          label: 'Categoría (Z -> A)',
          value: 'sortByAssetGroupNameDesc',
          checked: this.sortByAssetGroupNameDesc,
        },
      ],

      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            this.sortProducts(data, this.assetsListToSearch);
          },
        },
      ],
    };

    let alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  /**
   *
   * Ordena una lista por el elemento que se le indique.
   *
   * @param data El valor por el que se debe ordenar.
   * @param list La lista que se debe ordenar.
   *
   * @returns Una lista ordenada.
   */
  sortProducts(data: string, list: FixedAsset[]) {
    this.sortByAssetNumberAsc = false;
    this.sortByAssetNumberDesc = false;
    this.sortByBarcodeAsc = false;
    this.sortByBarcodeDesc = false;
    this.sortByAssetNameAsc = false;
    this.sortByAssetNameDesc = false;
    this.sortByDataAreaIdAsc = false;
    this.sortByDataAreaIdDesc = false;
    this.sortByAssetGroupNameAsc = false;
    this.sortByAssetGroupNameDesc = false;
    switch (data) {
      case 'sortByAssetNumberAsc':
        list.sort((a, b) => (a.assetId <= b.assetId ? -1 : 1));
        this.sortByAssetNumberAsc = true;
        break;
      case 'sortByAssetNumberDesc':
        list.sort((a, b) => (a.assetId > b.assetId ? -1 : 1));
        this.sortByAssetNumberDesc = true;
        break;
      case 'sortByBarcodeAsc':
        list.sort((a, b) => (+a.barcode <= +b.barcode ? -1 : 1));
        this.sortByBarcodeAsc = true;
        break;
      case 'sortByBarcodeDesc':
        list.sort((a, b) => (+a.barcode > +b.barcode ? -1 : 1));
        this.sortByBarcodeDesc = true;
        break;
      case 'sortByAssetNameAsc':
        list.sort((a, b) => (a.assetName < b.assetName ? -1 : 1));
        this.sortByAssetNameAsc = true;
        break;
      case 'sortByAssetNameDesc':
        list.sort((a, b) => (a.assetName > b.assetName ? -1 : 1));
        this.sortByAssetNameDesc = true;
        break;
      case 'sortByDataAreaIdAsc':
        list.sort((a, b) => (a.dataAreaId < b.dataAreaId ? -1 : 1));
        this.sortByDataAreaIdAsc = true;
        break;
      case 'sortByDataAreaIdDesc':
        list.sort((a, b) => (a.dataAreaId > b.dataAreaId ? -1 : 1));
        this.sortByDataAreaIdDesc = true;
        break;
      case 'sortByAssetGroupNameAsc':
        list.sort((a, b) => (a.assetGroupName < b.assetGroupName ? -1 : 1));
        this.sortByAssetGroupNameAsc = true;
        break;
      case 'sortByAssetGroupNameDesc':
        list.sort((a, b) => (a.assetGroupName > b.assetGroupName ? -1 : 1));
        this.sortByAssetGroupNameDesc = true;
        break;
      default:
        break;
    }
    return list;
  }

  /**
   *
   * Obtiene la lista de los elementos seleccionados que se muestran en pantalla.
   *
   */
  selectAllAssets(event) {
    this.assetsListToSearch.forEach((asset) => {
      if (event.detail.checked && !asset.pending) {
        asset.selected = true;
      } else {
        asset.selected = false;
      }
    });
  }

  /**
   *
   * Obtiene la lista de los elementos seleccionados que se muestran en pantalla.
   *
   */
  getCheckedvalue() {
    this.assetsListChecked = this.assetsListToSearch.filter((value) => {
      return value.selected;
    });
  }

  /**
   *
   * Expande una lista de subassets, para los assets que poseen varios.
   *
   * @param asset Indica el asset específico que pide expandirse
   *
   */
  toggleAccordion(asset: FixedAsset) {
    asset.expanded = !asset.getExpanded();
  }

  /**
   *
   * Muestra un popover donde se elige al nuevo responsable de los activos.
   *
   */
  async selectNewResponsible() {
    let popover = await this.modalCtrl.create({
      component: SearchableSelectComponent,
      componentProps: {
        objectsList: this.assetResponsibles,
      },
      cssClass: 'hide-overflow-popover',
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null) {
        this.newAssetResponsible = data;
        this.switchLocations();
      }
    });
  }

  /**
   * 
   * Muestra un popover donde se elige el nuevo aposento de los activos.
   *
   */
  async selectNewLocation() {
    let popover = await this.modalCtrl.create({
      component: SearchableSelectComponent,
      componentProps: {
        objectsList: this.assetLocations,
        typeAction: 2,
      },
      cssClass: 'hide-overflow-popover',
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        this.newAssetLocation = data;
      }
    });
  }

  /**
   *
   * Función que envía la solicitud de los activos para su traslado.
   *
   */
  async transferAssets() {
    try {
      const resp = await this._apiProvider.transferAsset(
        this.logger,
        this.assetResponsible,
        this.newAssetResponsible,
        this.newAssetLocation,
        this.assetsListChecked
      );
      if (resp == '1') {
        this.createAlert(
          'Solicitud completada',
          'Se ha realizado la solicitud de traslado de activos con éxito'
        );
        try {
          let msgBody: string = this.createEmailBody(
            this.logger,
            this.assetResponsible,
            this.newAssetResponsible,
            this.newAssetLocation,
            this.assetsListChecked
          );
          let recipients: string[] = [this.logger.getEmail()];
          if (this.logger != this.assetResponsible) {
            recipients.push(this.assetResponsible.getEmail());
          }
          if (
            this.assetResponsible.getVatnum() !=
            this.newAssetResponsible.getVatnum()
          ) {
            recipients.push(this.newAssetResponsible.getEmail());
          }
          for (let element of recipients) {
            await this._apiProvider.sendEmail(
              msgBody,
              element,
              'Solicitud traslado de activos'
            );
          }
        } catch (error) {
          this.createAlert(
            'Error',
            'No se ha logrado enviar el correo. Favor informar al encargado.'
          );
        }
        this.navService.navigateTo(NavigationRoutes.AssetsTransferComponent, {
          auth: this.auth,
          code: 22,
        });
        //this.displayAssetList();
        //this.changeScreen(2);
      } else {
        this.createAlert(
          'Solicitud rechazada',
          'No se ha podido realizar la solicitud, favor intentar más tarde'
        );
      }
    } catch (error) {
      this.createAlert(
        'Error de conexión',
        'Se ha presentado un error, favor intentar más tarde'
      );
    }
  }

  /**
   *
   * Función para cambiar los datos que trae en pantalla.
   *
   */
  async switchLocations() {
    this.newAssetLocation = new AssetLocation();
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    await loading.present(); //Muestra pantalla de cargado.
    if (this.checkNewLocation) {
      this.assetLocations = await this.getAssetsLocationList();
    } else {
      this.assetLocations = await this.getAssetsLocationList(
        parseInt(this.newAssetResponsible.getRecId())
      );
    }
    loading.dismiss();
  }

  /**
   *
   * Función para cambiar datos entre pantallas.
   *
   * @param screen Indica a cual pantalla se desea acceder.
   *
   */
  changeScreen(screen: number) {
    switch (screen) {
      // case 1:
      // this.secondScreen = false;
      // this.firstScreen = true;
      // break;
      case 2:
        this.assetsListToSearch = this.assetsListToFilter;
        // this.firstScreen = false;
        this.thirdScreen = false;
        this.secondScreen = true;
        break;
      case 3:
        this.secondScreen = false;
        this.thirdScreen = true;
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param logger
   * @param responsible
   * @param newResponsible
   * @param newAssetLocation
   * @param assetsList
   */
  private createEmailBody(
    logger: Worker,
    responsible: Worker,
    newResponsible: Worker,
    newAssetLocation: AssetLocation,
    assetsList: FixedAsset[]
  ) {
    this.assetsListApproval = [];
    let newAssetsList: FixedAsset[] = [];
    let asset: FixedAsset = new FixedAsset();
    for (let i = 0; i < assetsList.length; i++) {
      if (assetsList[i].getApproval()) {
        this.assetsListApproval.push(assetsList[i]);
        assetsList[i].assetId = assetsList[i].getAssetId() + '*';
      }
      asset = new FixedAsset();
      asset.assetId = assetsList[i].getAssetId();
      asset.barcode = assetsList[i].getBarcode();
      asset.assetName = assetsList[i].getAssetName();
      asset.location = assetsList[i].getLocation();
      asset.mainAssetId = assetsList[i].getMainAssetId();
      asset.dataAreaId = assetsList[i].getDataAreaId();
      newAssetsList.push(asset);
      if (assetsList[i].getSubAssets().length) {
        for (let j = 0; j < assetsList[i].getSubAssets().length; j++) {
          asset = new FixedAsset();
          asset.assetId = assetsList[i].getSubAssets()[j].getAssetId();
          asset.barcode = assetsList[i].getSubAssets()[j].getBarcode();
          asset.assetName = assetsList[i].getSubAssets()[j].getAssetName();
          asset.location = assetsList[i].getSubAssets()[j].getLocation();
          asset.dataAreaId = assetsList[i].getSubAssets()[j].getDataAreaId();
          asset.mainAssetId = assetsList[i].getMainAssetId();
          newAssetsList.push(asset);
        }
      }
    }

    let resp =
      logger.getName() + ' solicita trasladar los activos: &#60;br&#62;';
    for (let i of newAssetsList) {
      resp += '&#60;br&#62;─Número de activo: ' + i.getAssetId();
      if (i.getBarcode()) {
        resp += ', Código de barras: ' + i.getBarcode();
      }
      resp +=
        ', Nombre: ' +
        i.getAssetName() +
        ', Aposento: ' +
        i.getLocation() +
        ', Compañía: ' +
        i.getDataAreaId();
    }
    resp +=
      '&#60;br&#62;&#60;br&#62;Pertenecientes a ' +
      responsible.getName() +
      ' a su persona, ' +
      newResponsible.getName() +
      ', al aposento ' +
      newAssetLocation.getLocation() +
      '.';
    resp +=
      '&#60;br&#62;&#60;br&#62;Favor aceptar o rechazar las solicitudes en la sección Activos Fijos > Recepción de activos; de la aplicación MercasaVIP.';
    if (this.assetsListApproval.length) {
      resp +=
        '&#60;br&#62;&#60;br&#62;*Estos activos requieren aprobación, favor comunicarse con el aprobador pertinente.';
    }
    resp += '&#60;br&#62;&#60;br&#62;Saludos,';
    return resp;
  }
}
