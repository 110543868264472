import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { DateTimeOperation } from '../../../businessCore/oc-models/DateTimeOperations';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../../provider/Services/local-service-oc.service';
import { RejectedNoteOCComponent } from '../rejected-note-oc/rejected-note-oc.component';
import { ResumenOcVendorComponent } from '../resumen-oc-vendor/resumen-oc-vendor.component';

@Component({
  selector: 'app-to-be-confirmed-oc',
  templateUrl: './to-be-confirmed-oc.component.html',
  styleUrls: ['./to-be-confirmed-oc.component.scss'],
})
export class ToBeConfirmedOcComponent implements OnInit {
  @Input() infoPurch: PurchOrder;
  @Input() recordConfirmed: boolean;
  @Output() selectedAction = new EventEmitter<boolean>();
  presentingMess = false;
  selected = false;
  loader: HTMLIonLoadingElement;

  constructor(
    public navCtrl: NavController,
    public alertController: AlertController,
    private externalService: ExternalServiceOCService,
    private loadingCtrl: LoadingController,
    public popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    public plt: Platform,
    private localOcService: LocalServiceOCService
  ) {
    if (this.recordConfirmed === null || this.recordConfirmed === undefined) {
      this.recordConfirmed = false;
    }
  }

  ngOnInit() {
    this.infoPurch.setToSend(this.selected); //Inicia en false
  }

  swipeEvent(event) {
    if (!this.recordConfirmed) {
      if (event.direction === 2) {
        // Rechazar
        this.rejectPurchOrder();
      } else if (event.direction === 4) {
        // Confirmar
        this.confirmPurchOrder();
      }
    }
  }

  confirmPurchOrder() {
    this.messageInfo('Atención', '¿Desea CONFIRMAR la orden de compra?', 1);
  }

  rejectPurchOrder() {
    this.messageInfo('Atención', '¿Desea RECHAZAR la orden de compra?', 2);
  }

  async sendNewStatePurchOrder(estado: number, type: number, nota?: string) {
    const typeSend = type === 1 ? 'confirmación' : 'rechazo';
    await this.showSpinnerLoading(
      'Enviando ' + typeSend + ' de orden de compra.'
    );
    try {
      // this.loader.setContent('Enviando ' + typeSend + ' de orden de compra.');
      const stateNum: number = 40; // 'CONFIRMADO' === 40
      const vatNum = await this.localOcService.getCedulaActual();
      const result = (await this.externalService.updateDocumentState(
        this.infoPurch.getIdOC(),
        estado,
        this.infoPurch.getDataArea().getId(),
        vatNum,
        nota
      )) as number;
      // if (result.toUpperCase() === this.infoPurch.getDocumentState().toUpperCase()
      if (result !== estado) {
        // No se pudo actualizar
        this.messageInfo(
          'Error',
          'No se pudo actualizar el estado de la orden',
          0
        );
      } else {
        this.infoPurch.setDocumentStateI(result);
        if (estado === stateNum) {
          this.messageInfo(
            'Atención',
            'Se ha actualizado el estado de la orden ' +
              this.infoPurch.getIdOC() +
              ' \n a ' +
              this.infoPurch.getDocumentStateS() +
              ' \n ¿Desea descargar el reporte de OC?',
            3
          );
        } else {
          this.messageInfo(
            'Atención',
            'Se ha actualizado el estado de la orden ' +
              this.infoPurch.getIdOC() +
              ' \n a ' +
              this.infoPurch.getDocumentStateS(),
            0
          );
        }
      }
    } catch (error) {
      this.messageInfo(
        'Error',
        'No se pudo actualizar el estado de la orden',
        0
      );
    }
    this.dissmissLoading();
  }

  async validateSendAprobedPurchOrder() {
    // Se muestra el spinner
    await this.showSpinnerLoading('Verficando datos de la OC.');
    try {
      const result = await this.externalService.getLastEditingDateApp(
        this.infoPurch.getIdOC(),
        this.infoPurch.getDataArea().getId()
      );
      // result = null => la orden no ha sido editada nunca
      if (result === null) {
        // Se debe de validar que no esta siendo editada
        console.log('NO ha sido editada: ', result);
        this.isEditing();
      } else {
        const comparation = DateTimeOperation.dateAComparatedateBandTime(
          this.infoPurch.getLastEditingAppDate(),
          result as Date
        );
        console.log(
          'dateA: ',
          this.infoPurch.getLastEditingAppDate(),
          ' dateB: ',
          result as Date,
          ' comparación: ',
          comparation
        );
        if (comparation === -1) {
          //La fecha que tenemos es menor a la actual
          // Se traen los datos nuevos.
          this.loader.message = 'Actualizando datos de la orden';
          const newInfo = await this.externalService.getUpdateDataPO(
            this.infoPurch.getDataArea().getId(),
            -1,
            this.infoPurch.getIdOC(),
            -1,
            '',
            ''
          );
          if (newInfo !== null) {
            this.infoPurch.copyDataFromOtherObjet(newInfo[0] as PurchOrder);
            this.dissmissLoading();
            this.messageInfo(
              'Atención',
              'Los datos de la orden estaban desactualizados y fueron actualizados.',
              0
            );
          } else {
            this.dissmissLoading();
            this.messageInfo(
              'Error',
              'No se pudo traer la información más actual de la OC.',
              0
            );
          }
        } else {
          // la fecha que tenemos es igual a la actual
          // Se debe de validar si está siendo editada
          this.isEditing();
        }
      }
    } catch (error) {
      console.log(error);
      this.dissmissLoading();
      this.messageInfo('Error', 'No se pudo aprobar de la orden.', 0);
    }
  }

  async isEditing() {
    try {
      this.loader.message = 'Verificando si la orden no está siendo editada.';
      let result = await this.externalService.isEditing(
        this.infoPurch.getIdOC(),
        this.infoPurch.getDataArea().getId()
      );
      if (result === 'NOTEDITING') {
        // this.sendAprobedPurchOrder(); // como no está siendo editada
      } else {
        this.dissmissLoading();
        this.messageInfo(
          'Error',
          'La orden está siendo editada por un usuario.',
          0
        );
      }
    } catch (error) {
      this.dissmissLoading();
      this.messageInfo('Error', 'No se pudo aprobar de la orden.', 0);
    }
  }

  // Verificar si el valor de actualización es 0
  executeAction(type: number) {
    switch (type) {
      case 1:
        this.sendNewStatePurchOrder(40, 1); // 40 === 'CONFIRMADO'
        break;
      case 2:
        // Muestra el mensaje de rechazo y pide la razón.
        this.messageInfoReject();
        break;
      case 3:
        break;
      default:
        break;
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action !== 0) {
      if (action === 3) {
        botones = [
          {
            text: 'SI',
            handler: () => {
              // Se llama la funcion que realiza el pdf.
              this.createReport();
            },
          },
          { text: 'NO', handler: () => {} },
        ];
      } else {
        botones = [
          {
            text: 'SI',
            handler: () => {
              this.executeAction(action);
            },
          },
          { text: 'NO', handler: () => {} },
        ];
      }
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async messageInfoReject() {
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.popoverCtrl.create({
        component: RejectedNoteOCComponent,
        cssClass: ['custom-popover', 'rejected-note-oc'],
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((res) => {
        let data = res.data as string;
        console.log('modal se cerró, mensaje: ', data);
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          this.sendNewStatePurchOrder(20, 2, data); // 20 === 'RECHAZADO'
        }
      });
    }
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  /*goToConfirm() {
    // Mando el parametro de la orde de compra
    this.ocParameter.setPurchOrderParameter(this.infoPurch);
    this.navCtrl.push( PurchOrderToApprovedPage );
  }*/

  doPress() {
    this.selected = true; // Selecciona o desselecciona el item
    this.infoPurch.setToSend(this.selected);
    this.selectedAction.emit(this.selected);
  }

  doClick() {
    if (this.selected) {
      this.selected = false;
      this.infoPurch.setToSend(this.selected);
      this.selectedAction.emit(this.selected);
    }
  }

  getStyle() {
    return this.selected ? 'selectedItem' : 'normalItem';
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async createReport() {
    await this.showSpinnerLoading('Creando reporte de OC.');
    try {
      // const result = await
      if (this.infoPurch !== null && this.infoPurch !== undefined) {
        const result = await this.externalService.createReport(
          this.infoPurch.getIdOC(),
          this.infoPurch.getDataArea().getId(),
          ''
        );
        if (result !== 'error') {
          // Se empieza a descargar el documento
          this.openReport(result as string);
        } else {
          this.messageInfo('Error', 'No se pudo generar el documento. ', 0);
        }
      }
    } catch (error) {
      this.messageInfo('Error', 'No se pudo generar el documento. ', 0);
    }
    this.dissmissLoading();
  }

  async openResumen() {
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.modalCtrl.create({
        component: ResumenOcVendorComponent,
        componentProps: {
          purchOrd: this.infoPurch,
          recordConfirmed: this.recordConfirmed,
        },
        cssClass: 'resumen-oc-vendor',
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then(() => {
        this.presentingMess = false;
      });
    }
  }

  async openReport(nameFile: string) {
    const src = `${environment.SP_API}/pdfReports/` + nameFile;
    // Dependiendo de la plataforma actuara distinto la app
    if (this.plt.is('mobileweb') || this.plt.is('desktop')) {
      // WEB
      // console.log('Open web', this.plt.is('mobileweb'), this.plt.platforms());
      window.open(
        src,
        '_blank',
        'Content-Type: application/pdf' +
          ', width=' +
          screen.availWidth +
          ',height=' +
          screen.availHeight
      );
    } else {
      /// MOBILE
      // console.log('Open mobile', this.plt.is('mobileweb'), this.plt.platforms());
      const options: InAppBrowserOptions = {
        location: 'yes', //Or 'no'
        hidden: 'no', //Or  'yes'
        clearcache: 'yes',
        clearsessioncache: 'yes',
        zoom: 'yes', //Android only ,shows browser zoom controls
        hardwareback: 'yes',
        mediaPlaybackRequiresUserAction: 'no',
        shouldPauseOnSuspend: 'no', //Android only
        closebuttoncaption: 'Close', //iOS only
        disallowoverscroll: 'no', //iOS only
        toolbar: 'yes', //iOS only
        enableViewportScale: 'no', //iOS only
        allowInlineMediaPlayback: 'no', //iOS only
        presentationstyle: 'pagesheet', //iOS only
        fullscreen: 'yes', //Windows only
      };
      let browserInst = new InAppBrowser();
      let browser = browserInst.create(src, '_system', options);
      browser.show();
    }
  }
}
