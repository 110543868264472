import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum GlobalEvents {
  UPDATE_PAGES = 'update:pages',
  SHOW_PAGES = 'show:pages',
  SET_LOGIN_DATA = 'setLoginData',
  SET_COMPONENTS_BY_USER = 'setComponentsByUser',
  SET_ASSOCIATED_PEOPLE = 'setAssociatedPeople',
  getLatLng = "'get:LatLng'",
  setActualPos = 'setActualPos',
  UPDATE_CART = 'update:cart',
  UPDATE_PRODUCTS = 'update:products',
}

export interface GlobalEvent {
  event: Subject<any>;
  key: GlobalEvents;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalEventsService {
  private events: GlobalEvent[] = [];

  publish(event: GlobalEvents, data: any = {}) {
    this.getGlobalEvent(event).next(data);
  }

  getGlobalEvent(event: GlobalEvents): Subject<any> {
    let globalEvent: GlobalEvent;

    if (this.exists(event)) {
      globalEvent = this.events.filter((currentEvent) => {
        return currentEvent.key == event;
      })[0];
    } else {
      globalEvent = { key: event, event: new Subject<any>() };
      this.events.push(globalEvent);
    }

    return globalEvent.event;
  }

  exists(event: GlobalEvents) {
    let hasGlobalEvent = this.events.some((currentEvent) => {
      return currentEvent.key == event;
    });

    return !!hasGlobalEvent;
  }
}
