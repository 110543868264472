// Native - Plugins
import { Component } from '@angular/core';
import { Money } from '../../GeneralUtilis/money';

// Components

// BusinessCore
import { OrderTraceItem } from '../../businessCore/OrderTraceItem';
import { RegisterData } from './../../businessCore/RegisterData';

// Provider
import {
  AlertController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { OrderLine } from '../../businessCore/OrderLine';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { OrderTraceLinesComponent } from '../order-trace-lines/order-trace-lines.component';
import { OrderTraceProgressComponent } from '../order-trace-progress/order-trace-progress.component';

@Component({
  selector: 'app-order-trace',
  templateUrl: './order-trace.component.html',
  styleUrls: ['./order-trace.component.scss', './order-trace.component2.scss'],
})
export class OrderTraceComponent {
  groups: { name: string; item: OrderTraceItem[]; showed: boolean }[] = [];
  orders: OrderTraceItem[] = [];
  executed: boolean = false;

  shouldAnimate: boolean = true;
  register_data: RegisterData = new RegisterData();
  shoppingCar: OrderLine[] = [];

  /**
   * Class constructor with these injections:
   * @param _apiProvider web service provider
   * @param storage to store key/value pairs and JSON objects.
   * @param popoverCtrl dialog that appears on top of the current page.
   */
  constructor(
    public _apiProvider: webServiceProvider,
    private storage: StorageService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    public popoverCtrl: PopoverController,
    private shoopingCartUtils: ShoopingCartUtils
  ) {
    this.storage.get('register_data').then((data) => {
      this.register_data = JSON.parse(data) as RegisterData;
      this.getTrace((JSON.parse(data) as RegisterData).customerId);
    });
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  displayShoppingCar() {
    this.shoopingCartUtils.displayShoppingCart();
  }

  async presentAlert(title: string, msg: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  filterOrderByStatus() {
    // o.salesStatus != 4 // Cancelada
    this.orders = this.orders.filter((o) => o.salesStatus != 4);
  }

  getTrace(customerId: string) {
    let dateEnd = new Date();
    let dateIni = new Date();
    dateIni.setMonth(new Date().getMonth() - 3);

    let actualStrDate =
      dateEnd.getFullYear().toString() +
      ('00' + (dateEnd.getMonth() + 1).toString()).slice(-2) +
      ('00' + dateEnd.getDate().toString()).slice(-2) +
      '0000'; // 0000 = HHmm
    let fromStrDate =
      dateIni.getFullYear().toString() +
      ('00' + (dateIni.getMonth() + 1).toString()).slice(-2) +
      ('00' + dateIni.getDate().toString()).slice(-2) +
      '0000'; // 0000 = HHmm

    this._apiProvider
      .getOrderTraceList(
        0,
        customerId,
        -1,
        '',
        0,
        15,
        fromStrDate,
        actualStrDate
      )
      .subscribe((data) => {
        this.orders = data as OrderTraceItem[];
        // DEBUG
        /*let order = new OrderTraceItem();
        order.salesId = 'OV_01634602';
        order.salesAxId = 'OV_01634602';
        order.accountNum = 'JLBS001';
        order.customerId = 'JLBS001';
        order.addressName = 'lak';
        order.createdDatetime = new Date();
        order.createdDatetimeString = '01/01/1900';
        order.employeeId = '123';
        order.deliveryDate = new Date();
        order.deliveryDateString = '01/01/1800';
        order.salesStatus = 0;
        order.salesStatusString = 'Indefinido';
        order.totalSales = 123456;
        order.states = [
          { icon: 'fas fa-question', state: 'Indefinido', class: '' },
        ];
        order.paymentReceiptId = '464564';
        this.orders = [order];*/
        // DEBUG
        this.executed = true;
        if (this.orders != null && this.orders.length > 0) {
          let nombre: string = this.orders[0].addressName;
          this.groups.push({ name: nombre, item: [], showed: false });
          this.orders.forEach((item) => {
            let aux: OrderTraceItem = new OrderTraceItem();
            aux.salesStatus = item.salesStatus;
            aux.createdDatetime = item.createdDatetime;
            aux.deliveryDate = item.deliveryDate;
            aux.createdDatetimeString =
              (aux.createdDatetime + '').substring(6, 8) +
              '-' +
              (aux.createdDatetime + '').substring(4, 6) +
              '-' +
              (aux.createdDatetime + '').substring(2, 4);
            aux.deliveryDateString =
              (aux.deliveryDate + '').substring(6, 8) +
              '-' +
              (aux.deliveryDate + '').substring(4, 6) +
              '-' +
              (aux.deliveryDate + '').substring(2, 4);
            item.salesStatusString = aux.statusToText();
            item.createdDatetimeString = aux.createdDatetimeString;
            item.deliveryDateString = aux.deliveryDateString;

            console.log('item.salesId');
            console.log(item.salesId);
            if (item.salesId && item.salesId != '') {
              this._apiProvider
                .getOrderTraceInfo(item.employeeId, item.salesId)
                .subscribe((data) => {
                  item.states = [];
                  let param: {
                    groudType: string;
                    orderDate: string;
                    orderDetail: string;
                    orderState: string;
                    time: { subtitle: string; title: string };
                  }[] = data as {
                    groudType: string;
                    orderDate: string;
                    orderDetail: string;
                    orderState: string;
                    time: { subtitle: string; title: string };
                  }[];

                  param.forEach((value) => {
                    item.states.push({
                      icon: this.IconOnStatus(value.orderState),
                      state: value.orderState,
                      class: 'progressbarChangeBefore',
                    });
                  });

                  if (item.salesStatus == 1) {
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                  } else if (item.salesStatus == 2) {
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                  } else if (item.salesStatus == 3 || item.salesStatus == 4) {
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                  } else if (item.salesStatus == 5) {
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                  } else if (item.salesStatus == 6) {
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                  } else if (item.salesStatus == 7) {
                    item.states.push({
                      icon: this.IconOnStatus('UnDefined'),
                      state: 'UnDefined',
                      class: 'progressbarChangeBefore',
                    });
                  }

                  item.states.forEach((value, index) => {
                    param.forEach((value2) => {
                      if (value.state == value2.orderState) {
                        value.class = 'active';
                      }
                    });
                  });
                });
            } // FIN if(item.salesId != '')
            let inserted: boolean = false;
            this.groups.forEach((data) => {
              if (!inserted && data.name == item.addressName) {
                data.item.push(item);
                inserted = true;
              }
            });
            if (!inserted) {
              let group = { name: item.addressName, item: [], showed: false };
              group.item.push(item);
              this.groups.push(group);
            }
          });

          this.groups.forEach((item) => {
            if (item.name == null) {
              item.name = 'Sin nombre definido';
            }
          });
        }
      });

    this.filterOrderByStatus();
  }

  toggleGroup(group: {
    name: string;
    item: OrderTraceItem[];
    showed: boolean;
  }) {
    group.showed = !group.showed;
  }

  setCurrency(Amount: number) {
    return Amount == 0 ? 'Procesando Orden' : new Money(Amount).getCurrency();
  }

  async CheckTaskProgress(item: OrderTraceItem) {
    if (item.salesId && item.salesId != '') {
      const popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: OrderTraceProgressComponent,
        componentProps: { item: item },
      });
      popover.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: 'No se pudo consultar la trazabilidad de la orden!',
        duration: 3000,
      });
      toast.present();
    }
  }

  async ShowLines(item) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: OrderTraceLinesComponent,
      componentProps: { item: item },
    });
    popover.present();
  }

  swipeEvent(event, order) {
    if (event.direction === 2) {
      this.ShowLines(order);
    } else if (event.direction === 4) {
      this.CheckTaskProgress(order);
    }
  }

  IconOnStatus(salesStatus: string) {
    if (salesStatus == 'Indefinido') {
      return 'fas fa-question';
    } else if (salesStatus == 'Creada') {
      return 'fas fa-save';
    } else if (salesStatus == 'Recibida') {
      return 'fas fa-receipt';
    } else if (salesStatus == 'PosPuesta') {
      return 'fas fa-question';
    } else if (salesStatus == 'Cancelada') {
      return 'fas fa-ban';
    } else if (salesStatus == 'Aprobada') {
      return 'far fa-handshake';
    } else if (salesStatus == 'Facturada') {
      return 'fas fa-file-invoice';
    } else if (salesStatus == 'Recibida por Entregador') {
      return 'fas fa-truck';
    } else if (salesStatus == 'Entregada Totalmente') {
      return 'fas fa-star';
    } else if (salesStatus == 'Entregada Parcialmente') {
      return 'fas fa-star-half-alt';
    } else if (salesStatus == 'Devuelta Totalmente') {
      return 'fas fa-times-circle';
    } else if (salesStatus == 'Entrega postergada') {
      return 'far fa-clock';
    } else {
      return 'fas fa-question-circle';
    }
  }
}
