import { Component, ViewEncapsulation } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { SelectionType } from '@swimlane/ngx-datatable';
import { PurchInfoResult } from '../../OOP/PurchInfoResult.interface';
import { PurchLinesInfoParam } from '../../OOP/PurchLinesInfoParam.interface';
import { ReceiveItem } from '../../OOP/ReceiveItem.interface';
import { SelectFilterOption } from '../../OOP/SelectFilterOption.interface';
import { StatusReceipt } from '../../OOP/StatusReceipt.enum';
import { ConfigLinePurchRemissionComponent } from '../../components/config-line-purch-remission/config-line-purch-remission.component';
import { ShowConversionItemComponent } from '../../components/show-conversion-item/show-conversion-item.component';
import { PurchRemissionInfoService } from '../../services/purchRemissionInfo.service';
import { RemissionService } from '../../services/remission.service';

@Component({
  selector: 'app-remission-lines',
  templateUrl: './remission-lines.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './remission-lines.component.scss',
    './remission-lines.component2.scss',
  ],
})
export class RemissionLinesPage {
  public loader: HTMLIonLoadingElement;
  public PurchId: string;
  public StatusReceipt: number;
  public PurchOrder: PurchLinesInfoParam;
  public PurchInfoResult: PurchInfoResult;
  public tableStyle: string = 'bootstrap';
  public selected: ReceiveItem[] = [];
  public roleSpecificIds: SelectFilterOption[];
  public containerIds: SelectFilterOption[];
  public currentRow: any = [];
  public totalQtyLabel: string;
  public registeredQtyLabel: string;
  public orderedQtyLabel: string;
  public remissionQtyLabel: string;
  public PurchLinesInfo: ReceiveItem[] = [];
  private empresaSelected: string = '';
  public selection: SelectionType;

  constructor(
    private navParams: NavParams,
    private PurchRemissionInfoService: PurchRemissionInfoService,
    private RemissionService: RemissionService,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController
  ) {
    this.PurchOrder = this.navParams.get('data');
    this.empresaSelected = this.navParams.get('company');
    this.PurchId = this.PurchOrder.purchId;
    this.StatusReceipt = StatusReceipt[this.PurchOrder.remissionMode];
    this.selection = SelectionType.checkbox;
    this.setQtyLabels();
  }

  async ionViewWillEnter() {
    await this.showLoading('Obteniendo datos');
    // Reset Variables
    this.resetInfo();
    // Get PurchInfo
    await this.getPurchInfo();
    // Get PurchLines
    await this.getPurchLines();

    await this.showLoading();
  }

  async getPurchInfo() {
    // Get PurchInfo
    let response = await this.RemissionService.getPurchInfo(
      this.PurchId,
      this.empresaSelected
    );
    this.roleSpecificIds = response.roleSpecificIds;
    this.containerIds = response.containerIds;
  }

  async getPurchLines() {
    // Get PurchLines
    let data = await this.RemissionService.getPurchLinesInfo(
      this.PurchId,
      this.StatusReceipt,
      this.empresaSelected
    );
    this.PurchLinesInfo = data.purchLines;
    this.setDefaultPurchInfoToLines();
    this.selected = [...this.PurchLinesInfo];
  }

  setQtyLabels() {
    if (this.StatusReceipt == StatusReceipt.Registered) {
      this.totalQtyLabel = 'Cant. Pediente de Remisión';
      this.registeredQtyLabel = '';
      this.orderedQtyLabel = 'Cant. Pendiente de Registrar';
      this.remissionQtyLabel = 'Cant. Registrada sin Remisión';
    } else if (this.StatusReceipt == StatusReceipt.Ordered) {
      this.totalQtyLabel = 'Cant. Saldo Pendiente';
      this.registeredQtyLabel = '';
      this.orderedQtyLabel = '';
      this.remissionQtyLabel = 'Cant por Remitir';
    }
  }

  resetInfo() {
    this.PurchLinesInfo = [];
    this.roleSpecificIds = [];
    this.containerIds = [];
  }

  async remissionLines() {
    if (this.validateRemissionLines(this.selected)) {
      await this.showLoading('Procesando remisión');
      console.log(this.selected);
      let response = await this.RemissionService.execRemissionLines(
        this.PurchId,
        this.StatusReceipt,
        this.selected,
        this.empresaSelected
      );
      await this.showLoading();

      if (response.status) {
        this.msgAlert('Estado', 'Remisión realizada correctamente');
        localStorage.setItem('StatusOC', 'true');
        // Update info
        await this.showLoading('Actualizando información');
        try {
          await this.getPurchInfo();
          await this.getPurchLines();
        } catch {}
        await this.showLoading();
      } else {
        this.msgAlert('Estado', response.message);
      }
    }
  }

  validateRemissionLines(remissionLines: ReceiveItem[]) {
    for (let remissionLine of remissionLines) {
      let totalQty = remissionLine.totalQty;
      let remissionQty = remissionLine.remissionQty;

      if (remissionQty <= 0) {
        this.msgAlert(
          remissionLine.itemId,
          'La cantidad de la remisión debe ser mayor a 0.'
        );
        return false;
      }

      if (remissionQty > totalQty) {
        this.msgAlert(
          remissionLine.itemId,
          `La cantidad de la remisión no puede ser mayor a: '${this.totalQtyLabel}'`
        );
        return false;
      }
    }
    return true;
  }

  setDefaultPurchInfoToLines() {
    for (let i = 0; i < this.PurchLinesInfo.length; i++) {
      this.PurchLinesInfo[i].roleSpecificIds = [];
      this.PurchLinesInfo[i].roleSpecificIdsAssistants = [];
      this.PurchLinesInfo[i].containerIds = [];
    }
  }

  async onSelect(rows) {
    this.selected = [...rows.selected];
  }

  askPurchInfo(event) {
    // TODOM Check if working (press)="askPurchInfo($event)"
    this.changePurchInfo(event.row);
  }

  async askPurchInfoMain(PurchOrder: PurchLinesInfoParam) {
    await this.PurchRemissionInfoService.presentModal(PurchOrder);

    let response = await this.PurchRemissionInfoService.onDidDismiss();

    if (response.status) {
      this.PurchInfoResult = response;
    }
  }

  async showItemInfo(item) {
    let dataConv: {
      description: string;
      conversions: { fromUnit: string; toUnit: string }[];
    } = {
      description: '',
      conversions: [],
    };

    dataConv.description = item.description;

    for (let conversion of item.conversions) {
      dataConv.conversions.push({
        fromUnit: `1 - ${conversion.fromUnit}`,
        toUnit: `${conversion.qty} - ${conversion.toUnit}`,
      });
    }

    let pop = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: ShowConversionItemComponent,
      componentProps: {
        data: dataConv,
      },
    });
    pop.present();
  }

  async changePurchInfo(rowData) {
    let data: PurchLinesInfoParam = {
      purchId: this.PurchId,
      objectId: rowData.itemId,
      objectName: rowData.description,
      roleSpecificIds: JSON.parse(JSON.stringify(this.roleSpecificIds)),
      containerIds: JSON.parse(JSON.stringify(this.containerIds)),
      id: rowData.recId,
      containerIdsSelected: rowData.containerIds,
      roleSpecificIdsAssistantsSelected: rowData.roleSpecificIdsAssistants,
      roleSpecificIdsSelected: rowData.roleSpecificIds,
    };

    const modal = await this.modalCtrl.create({
      component: ConfigLinePurchRemissionComponent,
      componentProps: {
        data: data,
      },
    });

    this.RemissionService.configLinePRModal = modal;

    await modal.present();

    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data) {
        this.updatePurchLineInfo(
          data.id,
          data.roleSpecificIdsSelected,
          data.roleSpecificIdsAssistantsSelected,
          data.containerIds
        );
      }
    });
  }

  updatePurchLineInfo(
    RecId: string,
    roleSpecificId: SelectFilterOption[],
    roleSpecificIdAssistants: SelectFilterOption[],
    containerId: SelectFilterOption[]
  ) {
    for (let i = 0; i < this.PurchLinesInfo.length; i++) {
      if (this.PurchLinesInfo[i].recId == RecId) {
        this.PurchLinesInfo[i].roleSpecificIds = roleSpecificId;
        this.PurchLinesInfo[i].roleSpecificIdsAssistants =
          roleSpecificIdAssistants;
        this.PurchLinesInfo[i].containerIds = containerId;
        break;
      }
    }
  }

  updateCantRemission(item, event) {
    this.updatePurchLinesInfo(this.PurchLinesInfo, item, event);
  }

  updatePurchLinesInfo(list, item, event) {
    for (let element of list) {
      if (element.recId == item.recId) {
        let newValue = parseInt(event.target.value);

        if (!isNaN(newValue)) {
          element.remissionQty = Math.max(0, newValue);
          event.target.value = Math.max(0, newValue);
        } else {
          element.remissionQty = 0;
          event.target.value = 0;
          event.innerHTML = 0;
        }
        break;
      }
    }
  }

  showForm() {
    console.log(this.PurchLinesInfo);
    console.log(this.selected);
  }

  async showLoading(message: string = '') {
    if (message) {
      this.loader = await this.loadingCtrl.create({
        message: message,
        spinner: 'bubbles',
      });
      await this.loader.present();
    } else {
      await this.loader.dismiss();
    }
  }

  async msgAlert(title: string, msg: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      backdropDismiss: true,
      buttons: [{ text: 'Aceptar ' }],
    });

    alert.present();
  }
}
