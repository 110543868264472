<app-nav-bar [title]="'Checklists pendientes'" [start]="'GoBack'"></app-nav-bar>

<ion-content style="background-color: white; color: black">
  <ion-refresher (ionRefresh)="refresChecklist($event)" slot="fixed">
    <ion-refresher-content
      pullingIcon="arrow-dropdown"
      pullingText="Deslice para actualizar los checklist por realizar"
      refreshingSpinner=""
      refreshingText=""
      slot="fixed"
    >
    </ion-refresher-content>
  </ion-refresher>

  <div>
    <ion-list lines="none">
      <app-check-list-instance-component
        *ngFor="let checkLits of checkLitsPending"
        [checkListIns]="checkLits"
        [vatNum]="vatNum"
      >
      </app-check-list-instance-component>
    </ion-list>
  </div>
</ion-content>
