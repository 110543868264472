<app-nav-bar
  [title]="'Acuerdo de responsabilidad'"
  [start]="'GoBack'"
  *ngIf="showHeader"
></app-nav-bar>

<ion-content class="ion-padding">
  <div
    id="page1-div"
    style="position: relative; text-align: justify; font-family: arial narrow"
  >
    ACUERDO DE RESPONSABILIDAD DE USO DE CÓDIGO DE USUARIO Y CLAVE DE ACCESO A
    LOS SISTEMAS MERCASA VIP
    <br /><br /><br />

    Yo, XXXXXXXXXXXXXXXXXXX portador de la Cédula ######, y en mi condición de
    Cliente de MERCASA (MERCADEO DE ARTICULOS DE CONSUMO S.A.), en este acto
    hago constar que recibo a mi entera satisfacción el identificador de usuario
    y la clave de usuario de MERCASA VIP (Plataforma tecnológica para comprar a
    Mercasa), que me identifica como usuario autorizado para realizar
    transacciones comerciales, a través del acceso al sistema, por lo que
    suscribo el presente ACUERDO DE RESPONSABILIDAD DE USO DE CÓDIGO DE USUARIO
    Y CLAVE DE ACCESO A LOS SISTEMAS MERCASA VIP, y declaro conocer y aceptar
    que:
    <br /><br /><br />

    1. La utilización de la clave de acceso o contraseña de ingreso es de
    carácter personal, confidencial e intransferible; por lo que, seré el
    responsable de proteger y mantener la confidencialidad de mi clave, de forma
    que nadie más pueda tener acceso a la información que resguardo y a los
    trámites que deseo realizar en Mercasa.
    <br />
    <br />
    2. Me hago responsable de no alterar mi clave personal, no divulgar, ni
    revelar la información confidencial, procedimientos, formatos, y demás
    aspectos técnicos y administrativos que se generen dentro del sistema,
    derivados de la entrega del usuario y clave, para proteger la información
    contra uso desautorizado o incorrecto, aún después que haya terminado mi
    relación comercial con la empresa Mercasa.
    <br />
    <br />
    3. La clave es un mecanismo importante para la protección de los sistemas y
    aplicaciones, por lo cual entiendo que su manejo es personal e
    intransferible y acuerdo no divulgar la(s) clave(s) de acceso a mi asignadas
    a ninguna persona.
    <br /><br />
    4. Entiendo que el usuario que me asignen y clave, son exclusivamente para
    mi uso y para propósitos generados por mi relación comercial con Mercasa y
    estoy consciente que cualquier actividad en los sistemas, haciendo mal uso
    de mis claves es de mi entera responsabilidad.
    <br /><br />
    5. He revisado y aceptado los Términos y Condiciones de uso de la plataforma
    tecnología Mercasa VIP y estoy de acuerdo seguir las reglas en él descritas.
    <br /><br />
    6. Es mi responsabilidad informarme, entender, apoyar y cumplir con las
    normas de seguridad que Mercasa utilice para protección de la información.
    <br /><br />
    7. En caso de pérdida, olvido o sustracción del Identificador de Usuario y
    clave de acceso, me obligo a comunicar de manera inmediata a los
    Administradores informáticos de Mercasa.
    <br /><br />
    8. Seré responsable de las consecuencias administrativas, civiles y penales
    establecidas en la Ley, por la pérdida, olvido o sustracción del
    Identificador de Usuario y clave de acceso, así como por las que se deriven
    del uso indebido del identificador de usuario y clave de acceso.
    <br /><br />
    9. Por lo anterior, entiendo que Mercasa no es responsable por el mal uso
    que yo haga de mi usuario o clave que me ha sido asignada para realizar
    transacciones comerciales a través de su plataforma Mercasa VIP.
    <br /><br /><br />
    Se firma el presente ACUERDO DE RESPONSABILIDAD DE USO DE CÓDIGO DE USUARIO
    Y CLAVE DE ACCESO A LOS SISTEMAS MERCASA VIP, el día XXX del mes XX del
    2019.
    <br /><br />
    Nombre del cliente
    <br />
    Firma del cliente
    <br />
    Cedula #
  </div>
</ion-content>
