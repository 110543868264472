<div>
  <h3 style="text-align: center">{{ contactNumber.title }}</h3>

  <ion-item class="madeUp-select">
    <div item-content class="forInputs" style="width: 100%">
      <ion-label style="width: 100%">
        <h2 class="title-info required">
          {{ contactNumber.phoneNumber }}
        </h2>
      </ion-label>
      <ion-row>
        <ion-col col-4>
          <ion-item style="padding-left: 0px">
            <ion-input
              type="tel"
              placeholder="506"
              [(ngModel)]="newPhone.extension"
              minlength="2"
              maxlength="6"
              required="true"
            >
            </ion-input>
          </ion-item>
        </ion-col>
        <ion-col col-8>
          <ion-item style="padding-left: 0px">
            <ion-input
              type="tel"
              placeholder="84848484"
              [(ngModel)]="newPhone.phoneNumber"
              required
            >
            </ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
  </ion-item>

  <ion-item class="madeUp-select">
    <div item-content class="forInputs" style="width: 100%">
      <ion-label style="width: 100%">
        <h2 class="title-info required">
          {{ contactNumber.description }}
        </h2>
      </ion-label>
      <ion-input
        [placeholder]=""
        [(ngModel)]="newPhone.description"
      ></ion-input>
    </div>
  </ion-item>

  <ion-item class="madeUp-select">
    <ion-label>
      <h2 class="title-info">{{ contactNumber.main }}</h2>
    </ion-label>
    <ion-toggle [(ngModel)]="newPhone.main" color="primary"></ion-toggle>
  </ion-item>
  <br />
  <div style="text-align: center">
    <ion-button
      style="width: 97%; border-radius: 8px"
      [disabled]="!newPhone.getPhoneNumber() || !newPhone.getDescription()"
      (click)="addPhoneNumber()"
    >
      {{ contactNumber.add }}
    </ion-button>
  </div>
</div>
