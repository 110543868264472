<app-nav-bar [title]="'Auditoría Activos Fijos'">
  <ion-buttons slot="end">
    <ion-button (click)="refreshPage()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content class="ion-paddding">
  <app-asset-audit-record
    *ngIf="auditor && petitioner"
    [auditor]="auditor"
    [petitioner]="petitioner"
    [auth]="auth"
    [componentId]="componentId"
  ></app-asset-audit-record>
</ion-content>
