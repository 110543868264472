<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button *ngIf="isMenu()"></ion-menu-button>
      <ion-back-button defaultHref="/" *ngIf="isGoBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <img height="28" width="28" src="assets/img/shopping_car.png" alt="" />
      {{ title }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="isCart()">
      <ion-button (click)="displayShoppingCar()" style="background: none">
        <img
          height="28"
          width="28"
          src="assets/img/shopping_car.png"
          alt="shopping cart"
        />
        <ion-badge
          *ngIf="shoopingCart().length > 0"
          id="cart-badge"
          style="width: 25px; margin: 0px 5px"
          >{{ shoopingCart().length }}</ion-badge
        >
      </ion-button>
    </ion-buttons>
    <ng-content></ng-content>
  </ion-toolbar>
</ion-header>
