import { PSRtnBoughtItem } from './PSRtnBoughtItem';

export class PSRtnLine {
  baseObject: PSRtnBoughtItem;
  itmId: string = 'MTEZLI12';
  untId: string = 'BOLSA';
  slQntty: number = 8;
  lnAmnt: number = 0;
  dlvryDte: string = '2019-11-08T00:00:00Z';
  InvBtchId: string = ''; //BatchId
  InvBtchExpryDt: string = '2019-10-31';
  RsnCd: string = '19A'; // ReasonCode
  dbtNbr: string = ''; // InvoiceID
  orgnSte: string = '';

  rtnId: string = '120037510';
  lnId: number = 1;
  RqstTyp: number = 1; // requestType
  DspstnCdId: string = '202'; // disposal

  // Defaults
  cstmrRma: string = ''; //reqCreditNote
  Nts: string = 'Devolucion App Express'; // Notes
  dtrId: string = 'cmer';

  // No importan
  delvryMthd: string = null; // No Importa
  slPrce: number = 0; // No Importa
  ttlSle: number = 0; // No Importa
  txAmnt: number = 0; // No Importa
  lneDscnt: number = 0; // No Importa
  lnPrcnt: number = 0; // No Importa
  fAmnt: number = 0; // No Importa
  dlvryRte: string = null; // No Importa

  constructor() {}

  toXML() {
    let xml: string = '';
    xml =
      '<PSRtnLine class="entity">' +
      '<lnId>' +
      this.lnId +
      '</lnId>' +
      '<rtnId>' +
      this.rtnId +
      '</rtnId>' +
      '<dtrId>' +
      this.dtrId +
      '</dtrId>' +
      '<itmId>' +
      this.itmId +
      '</itmId>' +
      '<untId>' +
      this.untId +
      '</untId>' +
      '<slQntty>' +
      this.slQntty +
      '</slQntty>' +
      '<slPrce>' +
      this.slPrce +
      '</slPrce>' +
      '<ttlSle>' +
      this.ttlSle +
      '</ttlSle>' +
      '<txAmnt>' +
      this.txAmnt +
      '</txAmnt>' +
      '<lneDscnt>' +
      this.lneDscnt +
      '</lneDscnt>' +
      '<lnPrcnt>' +
      this.lnPrcnt +
      '</lnPrcnt>' +
      '<fAmnt>' +
      this.fAmnt +
      '</fAmnt>' +
      '<lnAmnt>' +
      this.lnAmnt +
      '</lnAmnt>' +
      '<dlvryDte>' +
      this.dlvryDte +
      '</dlvryDte>' +
      '<delvryMthd>' +
      this.delvryMthd +
      '</delvryMthd>' +
      '<orgnSte>' +
      this.orgnSte +
      '</orgnSte>' +
      '<dlvryRte>' +
      this.dlvryRte +
      '</dlvryRte>' +
      '<dbtNbr>' +
      this.dbtNbr +
      '</dbtNbr>' +
      '<cstmrRma>' +
      this.cstmrRma +
      '</cstmrRma>' +
      '<InvBtchId>' +
      this.InvBtchId +
      '</InvBtchId>' +
      '<InvBtchExpryDt>' +
      this.InvBtchExpryDt +
      '</InvBtchExpryDt>' +
      '<RsnCd>' +
      this.RsnCd +
      '</RsnCd>' +
      '<Nts>' +
      this.Nts +
      '</Nts>' +
      '<DspstnCdId>' +
      this.DspstnCdId +
      '</DspstnCdId>' +
      '<RqstTyp>' +
      this.RqstTyp +
      '</RqstTyp>' +
      '</PSRtnLine>';

    return xml;
  }
}

// -----------------------------        PS TOXML METHOD     -----------------------------
// public String toXml() {
//     StringBuilder stringBuilder = new StringBuilder(500);
//     stringBuilder.append("<PSRtnLine class=\"entity\">");
//     Utils.storeValueToXml(stringBuilder, "lnId", id);
//     Utils.storeValueToXml(stringBuilder, "rtnId", returnId);
//     Utils.storeValueToXml(stringBuilder, "dtrId", dataAreaId);
//     Utils.storeValueToXml(stringBuilder, "itmId", itemId);
//     Utils.storeValueToXml(stringBuilder, "untId", unitId);
//     Utils.storeValueToXml(stringBuilder, "slQntty", returnQuantity);
//     Utils.storeValueToXml(stringBuilder, "slPrce", "0");
//     Utils.storeValueToXml(stringBuilder, "ttlSle", "0");
//     Utils.storeValueToXml(stringBuilder, "txAmnt", "0");
//     Utils.storeValueToXml(stringBuilder, "lneDscnt", "0");
//     Utils.storeValueToXml(stringBuilder, "lnPrcnt", "0");
//     Utils.storeValueToXml(stringBuilder, "fAmnt", "0");
//     Utils.storeValueToXml(stringBuilder, "lnAmnt", lineFinancialAmount);
//     Utils.storeValueToXml(stringBuilder, "dlvryDte", collectedDate);
//     Utils.storeValueToXml(stringBuilder, "delvryMthd", "null");
//     Utils.storeValueToXml(stringBuilder, "orgnSte", String.valueOf(originSite));
//     Utils.storeValueToXml(stringBuilder, "dlvryRte", "null");
//     Utils.storeValueToXml(stringBuilder, "dbtNbr", invoice);
//     Utils.storeValueToXml(stringBuilder, "cstmrRma", reqCreditNote);
//     Utils.storeValueToXml(stringBuilder, "InvBtchId", batch);
//     Utils.storeValueToXml(stringBuilder, "InvBtchExpryDt", sdf.format(expirationDate));
//     Utils.storeValueToXml(stringBuilder, "RsnCd", reason);
//     Utils.storeValueToXml(stringBuilder, "Nts", notes);
//     Utils.storeValueToXml(stringBuilder, "DspstnCdId", disposal);
//     Utils.storeValueToXml(stringBuilder, "RqstTyp", requestType);
//     //Utils.storeValueToXml(stringBuilder, "reqReplace", requireReplace);

//     /**
//     Utils.storeValueToXml(stringBuilder, "lnId", id);
//     Utils.storeValueToXml(stringBuilder, "rtnId", returnId);
//     Utils.storeValueToXml(stringBuilder, "dtrId", dataAreaId);
//     Utils.storeValueToXml(stringBuilder, "itmId", itemId);
//     Utils.storeValueToXml(stringBuilder, "untId", unitId);
//     Utils.storeValueToXml(stringBuilder, "clctdDte", collectedDate);
//     Utils.storeValueToXml(stringBuilder, "rtnQntty", returnQuantity);
//     */
//     stringBuilder.append("</PSRtnLine>");
//     return stringBuilder.toString();
// }

// // ************************** BASE BASE BASE ***********************************
// // ************************** BASE BASE BASE ***********************************
// // ************************** BASE BASE BASE ***********************************

// <?xml version="1.0" encoding="UTF-8"?>
// <PSRtn class="entity">
//    <DocPurpose>Original</DocPurpose>
//    <SenderId>vcom</SenderId>
//    <PSRtnHeader class="entity">
//       <rtnId>120039542</rtnId>
//       <cstmrAccnt>WEWU010</cstmrAccnt>
//       <crtdDttme>2020-02-06T07:31:34Z</crtdDttme>
//       <OrigOrdNbr>null</OrigOrdNbr>
//       <emplyId>VDP16</emplyId>
//       <dlvryDte>2020-02-14T00:00:00Z</dlvryDte>
//       <plnDlvryDte>2020-02-14T00:00:00Z</plnDlvryDte>
//       <rtnStts>1</rtnStts>
//       <shpTId>5647039192</shpTId>
//       <ttlSls>0</ttlSls>
//       <txAmnt>0</txAmnt>
//       <ttlDscnt>0</ttlDscnt>
//       <ttlFs>0</ttlFs>
//       <ttlOrdr>0</ttlOrdr>
//       <addtnlInstrctns />
//       <prntd>0</prntd>
//       <delvryMthd>Inteca</delvryMthd>
//       <orgnSte>CCA</orgnSte>
//       <dlvryRte>RE131</dlvryRte>
//       <ordrType>0</ordrType>
//       <isPrpsl>0</isPrpsl>
//       <ordrModel>2</ordrModel>
//       <paymMethod>null</paymMethod>
//       <streId>null</streId>
//       <steId>null</steId>
//       <athorztnNbr>null</athorztnNbr>
//       <cstmrRma />
//       <dbtNbr>06022020</dbtNbr>
//       <RtrnRsnCdId>null</RtrnRsnCdId>
//       <authId>0</authId>
//       <NomAuth>null</NomAuth>
//       <apprvlTyp>1</apprvlTyp>
//       <rtnType>0</rtnType>
//       <PSRtnHeaderCpny class="entity">
//          <rtnId>120039542</rtnId>
//          <dtArId>cmer</dtArId>
//          <ttlSls>0</ttlSls>
//          <txAmnt>0</txAmnt>
//          <ttlDscnt>0</ttlDscnt>
//          <ttlFs>0</ttlFs>
//          <ttlOrdr>0</ttlOrdr>
//          <prntd>0</prntd>
//          <shpTId>5647039192</shpTId>
//          <dlvryDte>2020-02-14T00:00:00Z</dlvryDte>
//          <plnDlvryDte>2020-02-14T00:00:00Z</plnDlvryDte>
//          <delvryMthd>null</delvryMthd>
//          <orgnSte>CCA</orgnSte>
//          <dlvryRte>null</dlvryRte>
//          <addtnlInstrctns>null</addtnlInstrctns>
//          <OrigOrdNbr>null</OrigOrdNbr>
//          <paymMethod>null</paymMethod>
//          <streId>null</streId>
//          <steId>null</steId>
//          <athorztnNbr>null</athorztnNbr>
//          <cstmrRma />
//          <dbtNbr>null</dbtNbr>
//          <apprvlTyp>1</apprvlTyp>
//          <PSRtnLine class="entity">
//             <lnId>203</lnId>
//             <rtnId>120039542</rtnId>
//             <dtrId>cmer</dtrId>
//             <itmId>MTCLLI18</itmId>
//             <untId>PAQUET</untId>
//             <slQntty>48</slQntty>
//             <slPrce>0</slPrce>
//             <ttlSle>0</ttlSle>
//             <txAmnt>0</txAmnt>
//             <lneDscnt>0</lneDscnt>
//             <lnPrcnt>0</lnPrcnt>
//             <fAmnt>0</fAmnt>
//             <lnAmnt>0</lnAmnt>
//             <dlvryDte>2020-02-14T00:00:00Z</dlvryDte>
//             <delvryMthd>null</delvryMthd>
//             <orgnSte>0</orgnSte>
//             <dlvryRte>null</dlvryRte>
//             <dbtNbr />
//             <cstmrRma />
//             <InvBtchId />
//             <InvBtchExpryDt>2020-02-06</InvBtchExpryDt>
//             <RsnCd>19A</RsnCd>
//             <Nts />
//             <DspstnCdId>203</DspstnCdId>
//             <RqstTyp>1</RqstTyp>
//          </PSRtnLine>
//       </PSRtnHeaderCpny>
//    </PSRtnHeader>
// </PSRtn>
