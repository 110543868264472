export class AssetLocation {
  location: string;
  locationName: string;
  locationCatalogueCode: string;
  locationCatalogueDescription: string;

  getLocation() {
    return this.location;
  }

  getName() {
    return this.locationName;
  }

  getLocationCatalogueCode() {
    return this.locationCatalogueCode;
  }

  getLocationCatalogueDesription() {
    return this.locationCatalogueDescription;
  }
}
