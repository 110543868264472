<app-nav-bar [title]="'Información de Transporte'">
  <ion-buttons slot="end">
    <ion-button (click)="closePopOver()">
      <i class="fas fa-times" style="width: 30px"></i>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <ion-item>
    <ion-select
      label="Propiedad del vehiculo"
      label-placement="floating"
      class="floating"
      [(ngModel)]="selectedProp"
      (ionChange)="changeSelectedPropiedad()"
      okText="Aceptar"
      cancelText="Cancelar"
      placeholder="Seleccione la propiedad"
      disabled="{{ !cantEdit }}"
    >
      <ion-select-option
        *ngFor="let prop of listProperty"
        [value]="prop.getValue()"
        >{{ prop.getDescription() }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item (click)="emitSelectedBar()" disabled="{{ !cantEdit }}">
    <ion-label>
      <h2 class="h2Select">N° Vehículo</h2>
      <h3 *ngIf="numeroVehi !== ''">{{ numeroVehi }} - {{ descripVehi }}</h3>
    </ion-label>
    <ion-icon name="caret-down-outline" *ngIf="canSelectNumber"></ion-icon>
  </ion-item>

  <ion-item disabled="{{ !cantEdit }}">
    <ion-label fixed><h2 class="h2Select">N° Placa</h2></ion-label>
    <ion-input
      placeholder="Número de placa"
      type="text"
      [readonly]="!insertNumberPla"
      [(ngModel)]="numeroPla"
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-select
      label="Tipo"
      label-placement="floating"
      class="floating"
      [(ngModel)]="selectedType"
      okText="Aceptar"
      cancelText="Cancelar"
      placeholder="Seleccione el tipo"
      disabled="{{ !cantEdit && canSelectType }}"
    >
      <ion-select-option
        *ngFor="let type of listTypeVehicle"
        [value]="type.getValue()"
        >{{ type.getDescription() }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <div style="margin: 2%">
    <ion-button
      color="primary"
      class="btnPosition"
      (click)="saveTransportInfo()"
    >
      Guardar
    </ion-button>
  </div>
</ion-content>
