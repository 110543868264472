// BusinessCore
import { productList } from './../../businessCore/productList';

// Native - Plugins
import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';

@Component({
  selector: 'app-new-list',
  templateUrl: './new-list.component.html',
  styleUrls: ['./new-list.component.scss'],
})
export class NewListComponent {
  listName: string = '';
  listas: productList[] = [];

  /**
   * Class constructor with these injections:
   * @param storage to store value/keys pairs and JSON objects.
   * @param viewCtrl features and information about the current view.
   */
  constructor(
    public storage: StorageService,
    public viewCtrl: PopoverController
  ) {
    this.storage.get('listas_productos').then((value) => {
      if (value != null) {
        this.listas = JSON.parse(value) as productList[];
      }
    });
  }

  /**
   * Metodo utilizado para crear una nueva lista de productos
   * insertando en storage con la variable 'listas_productos'
   */
  createNewList() {
    let nueva: productList = new productList();
    nueva.name = this.listName;
    nueva.items = [];
    this.listas.push(nueva);
    this.storage.set('listas_productos', JSON.stringify(this.listas));
    this.viewCtrl.dismiss(true);
  }
}
