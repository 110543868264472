<ion-header>
  <ion-toolbar>
    <ion-searchbar
      placeholder="Buscar"
      (ionInput)="onInputChange()"
      [(ngModel)]="inputKey"
    >
    </ion-searchbar>
    <ion-buttons slot="end">
      <ion-button (click)="closePopOver()">
        <!-- <ion-icon name="close"></ion-icon>-->
        <i class="fas fa-times" style="width: 30px"></i>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content overflow-scroll="true">
  <cdk-virtual-scroll-viewport itemSize="10" style="height: 100%">
    <ion-list style="height: 100%">
      <ion-item *cdkVirtualFor="let obj of filteredList; let i = index">
        <ion-label *ngIf="!typeAction" style="text-transform: capitalize">{{
          obj.getName()
        }}</ion-label>
        <ion-radio
          *ngIf="!typeAction"
          [value]="obj.getName()"
          (click)="onItemClick(obj, i)"
        ></ion-radio>

        <ion-label *ngIf="typeAction == 1" class="capitalize"
          >{{ obj.name }} {{ obj.vatNum }}</ion-label
        >
        <ion-radio
          *ngIf="typeAction == 1"
          [value]="obj.name"
          (click)="onItemClick(obj, i)"
        ></ion-radio>

        <ion-label
          *ngIf="typeAction == 2"
          class=""
          style="text-transform: capitalize; width: 60%"
          >{{ obj.getName() }}
          <br />
          <p style="size: 12px" *ngIf="obj.getLocationCatalogueDesription()">
            {{ obj.getLocationCatalogueDesription() }}
          </p>
        </ion-label>
        <ion-radio
          *ngIf="typeAction == 2"
          [value]="obj.getName()"
          (click)="onItemClick(obj, i)"
        ></ion-radio>

        <ion-label
          *ngIf="typeAction == 3 && obj.getName() != ''"
          style="text-transform: capitalize"
          >{{ obj.getName() }}
        </ion-label>
        <ion-radio
          *ngIf="typeAction == 3 && obj.getName()"
          [value]="obj.getName()"
          (click)="onItemClick(obj, i)"
        >
        </ion-radio>
      </ion-item> </ion-list
  ></cdk-virtual-scroll-viewport>
</ion-content>
