import { Address } from './Address';
import { Bank } from './Bank';
import { Currency } from './Currency';
import { EntityName } from './EntityName';
import { IdentifierType } from './IdentifierType';
import { DataArea } from './oc-models/DataArea';
import { PaymentTerm } from './PaymentTerm';

export class VendorRegisterData {
  generalData: any = {
    company: new DataArea('', ''),
    vendorIdType: new IdentifierType('', '', '', '', 0),
    vendorVatnum: '',
    vendorEntityName: new EntityName(),
    representIdType: new IdentifierType('', '', '', '', 0),
    representVatnum: '',
    representEntityName: new EntityName(),
    creatorVatnum: '',
  };

  paymentData: any = {
    paymentTerm: new PaymentTerm('', ''),
    bank: new Bank('', ''),
    currency: new Currency('', ''),
    bankAccount: '',
    ABANumber: '',
    SWIFTCode: '',
  };

  contactData: any = {
    phones: [],
    emails: [],
    contacts: [],
  };

  addressData: Address[] = [];

  documentData: any = {
    vatNumVendor: [],
    vatNumRepres: [],
    vatNumReprInvoice: [],
    vatNumReprService: [],
    vendorAgreement: [],
    transferAuthorization: [],
    guaranteePolicies: [],
    acceptVendorAgreement: false,
  };

  public getGeneralData() {
    return this.generalData;
  }

  public getPaymentData() {
    return this.paymentData;
  }

  public getContactData() {
    return this.contactData;
  }

  public getAddressData() {
    return this.addressData;
  }

  public getDocumentData() {
    return this.documentData;
  }

  public setGeneralData(newGeneral: any) {
    this.generalData = newGeneral;
  }

  public setPaymentData(newPayment: any) {
    this.paymentData = newPayment;
  }

  public setContactData(newContact: any) {
    this.contactData = newContact;
  }

  public setAddressData(newAddress: any) {
    this.addressData = newAddress;
  }

  public setDocumentData(newDocument: any) {
    this.documentData = newDocument;
  }
}
