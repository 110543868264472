// Native - Plugins
import { Component } from '@angular/core';

// BusinessCore
import { RegisterData } from './../../businessCore/RegisterData';

// Provider
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-my-profile-updatepass',
  templateUrl: './my-profile-updatepass.component.html',
  styleUrls: ['./my-profile-updatepass.component.scss'],
})
export class MyProfileUpdatepassComponent {
  // Atributos con los que se hace binding en el formulario
  actualPass: string = '';
  newPass: string = '';
  confirmPass: string = '';

  // Atributo de control para saber cuando mostrar una
  // alerta o mensaje de estado al usuario
  setWarning: boolean = false;

  // Mensaje que se le muestra al usuario segun el estado en que finaliza
  // la actualizacion de la contrasenha
  warningMsg: string = '';

  // Atributo con toda la informacion del usuario con que se ha registrado en el sistema
  register_data: RegisterData;

  /**
   * Class constructor with these injections:
   * @param _apiProvider web service provider
   * @param storage to store key/value pairs and JSON objects.
   */
  constructor(
    public _apiProvider: webServiceProvider,
    public storage: StorageService
  ) {
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
      }
    });
  }

  /**
   * Metodo utilizado para llevar a cabo la actualizacion de la contrasenha
   * verificando el cumplimiento de restricciones y el estado de finalizacion
   * de cada actualizacion que se procese
   */
  updatePassword() {
    let user_id = this.register_data.id_card;
    if (this.validPassword(this.newPass)) {
      if (this.newPass == this.confirmPass) {
        this._apiProvider
          .changePassword(user_id, this.newPass, this.actualPass)
          .subscribe((data) => {
            if (data != null) {
              let resp = data as string;
              if (resp == 'SUCCESS') {
                this.warningMsg = '¡ Contraseña cambiada con éxito !';
                this.setWarning = false;
              } else if (resp == 'INVALID_USER') {
                this.warningMsg =
                  'La cuenta de usuario: ' +
                  user_id +
                  ' es invalida o no existe.';
                this.setWarning = true;
              } else if (resp == 'INVALID_PASSWORD') {
                this.warningMsg = 'La contraseña actual es inválida';
                this.setWarning = true;
              } else if (resp == 'FAILURE') {
                this.warningMsg = 'Fallo inesperado, inténtelo más tarde.';
                this.setWarning = true;
              }
            } else {
              this.setWarning = true;
              this.warningMsg =
                '¡ No se pudo llevar a cabo el cambio de contraseña !';
            }

            this.newPass = '';
            this.confirmPass = '';
            this.actualPass = '';
          });
      } else {
        this.setWarning = true;
        this.warningMsg = 'Las contraseñas no coincide';
        this.confirmPass = '';
      }
    } else {
      this.setWarning = true;
      this.warningMsg =
        'La nueva clave no cumple con los requerimientos establecidos';
    }
  }

  /**
   * Metodo utilizado para validar las restricciones que tiene la contrasenha
   * @param password string con la contrasenha que se desea actualizar para verificar
   * que cumpla con las restricciones de tamanho establecidas
   */
  validPassword(password: string) {
    if (password.length > 7 && password.length < 20) {
      return true;
    }
    return false;
  }
}
