<app-nav-bar [title]="'Mis Pagos'"></app-nav-bar>

<ion-fab vertical="top" horizontal="end" (click)="presentPopupInfo()">
  <ion-fab-button
    color="gray"
    class="submit-btn"
    size="small"
    style="margin: 0px"
  >
    <i class="fas fa-question" style="color: gray"></i>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding">
  <!-- <ion-searchbar type="text" class="searchBar" [(ngModel)]="searchInput" placeholder='Buscar' (ionInput)="getItems($event)"></ion-searchbar> -->

  <div style="text-align: center; margin-top: 12px" *ngIf="!hasPayments">
    <h6 style="font-size: 1rem !important">
      No existen pagos con tarjeta realizados hasta el momento.
    </h6>
  </div>

  <ion-list>
    <ion-card
      *ngFor="let payment of paymentList"
      style="
        background: transparent;
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      "
    >
      <ion-item lines="none" class="borderStyle">
        <div style="display: flex; align-items: center; flex-wrap: nowrap">
          <div style="margin-right: 10px; font-size: 18px">
            <i class="fas fa-align-left blueColor"></i>
          </div>
          <div>
            <h6 style="font-weight: bold; color: brown">
              Transacción ({{ payment.TransactionID }})
            </h6>
            <h6>Cliente: {{ payment.CustId }}</h6>
            <h6>Fecha: {{ getDateFormatt(payment.DataPayment) }}</h6>
            <h6>Monto: {{ setCurrency(payment.Amount) }}</h6>
          </div>
        </div>
      </ion-item>
    </ion-card>
  </ion-list>
</ion-content>
