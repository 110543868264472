import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-general-divider',
  templateUrl: './general-divider.component.html',
  styleUrls: ['./general-divider.component.scss'],
})
export class GeneralDividerComponent {
  @Input() title: string;
  @Input() objectList: any[];

  iosIcon: string;
  mdIcon: string;
  showObjectList: boolean;

  constructor() {
    this.showObjectList = false;
    this.iosIcon = 'chevron-down-outline';
    this.mdIcon = 'chevron-down-outline';
  }

  changeObjectListVisibility(): void {
    this.showObjectList = !this.showObjectList;
    if (this.showObjectList) {
      this.iosIcon = 'chevron-up-outline';
      this.mdIcon = 'chevron-up-outline';
    } else {
      this.iosIcon = 'chevron-down-outline';
      this.mdIcon = 'chevron-down-outline';
    }
  }
}
