<ion-content>
  <div class="box">
    <div class="content">
      <div style="margin-top: 2rem; margin-bottom: 1rem; text-align: center">
        <h3 style="margin-top: 0; margin-bottom: 0; display: inline">
          {{ addressLanguage["title"] }}
        </h3>
        <div class="languageTag" style="display: inline">
          <p
            style="
              margin-top: 0px;
              margin-bottom: 0px;
              vertical-align: top;
              font-size: 13px;
            "
            (click)="changeLanguage()"
          >
            {{ changeLanguageTo }}
          </p>
        </div>
      </div>

      <div style="margin-top: 40px"></div>

      <div class="centerDiv">
        <ion-button
          (click)="presentGetPosition()"
          style="border-radius: 8px; min-width: 113px"
          ion-button
          class="contactBtn"
        >
          <ion-icon name="pin"></ion-icon>
          &nbsp;&nbsp;{{ addressLanguage["addBtn"] }}
        </ion-button>
      </div>
      <div style="margin-top: 25px"></div>

      <ion-list *ngIf="addressList.length">
        <ion-card
          *ngFor="let addressItem of addressList; let i = index"
          style="border-radius: 8px"
        >
          <ion-card-content>
            <!-- <ion-card-content [ngClass]="{'mainContact': contactItem.main}"> -->
            <div>
              <span style="vertical-align: middle">{{
                addressItem.addressName
              }}</span>
              <ion-icon
                (click)="deleteElement(i)"
                name="trash"
                style="
                  font-size: 1.8em;
                  vertical-align: middle;
                  float: right;
                  cursor: pointer;
                "
              >
              </ion-icon>
            </div>
          </ion-card-content>
        </ion-card>
        <br />
      </ion-list>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <div class="whiteRectangle">
    <ion-fab horizontal="start" vertical="bottom">
      <ion-fab-button size="small" (click)="changeScreen(3)">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>

<div class="infContent">
  <div class="whiteRectangle">
    <ion-fab horizontal="end" vertical="bottom">
      <ion-fab-button
        size="small"
        (click)="changeScreen(5)"
        [disabled]="disableNext()"
      >
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>
