import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { InfoFilterOcPopComponent } from 'src/app/components/oc-components/info-filter-oc-pop/info-filter-oc-pop.component';
import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../provider/Services/local-service-oc.service';
import { ServicesOcParameterProvider } from '../../provider/Services/services-oc-parameter';

@Component({
  selector: 'app-purch-order-record',
  templateUrl: './purch-order-record.component.html',
  styleUrls: ['./purch-order-record.component.scss'],
})
export class PurchOrderRecordPage {
  private listPurch: PurchOrder[];
  listPurchShowing: PurchOrder[];
  auth: number;
  vatnumAct: string;
  loader: HTMLIonLoadingElement;
  presentingMess: boolean = false;
  filterKey: string = '';
  companyList: CatalogModel[] = [];
  proveedorList: CatalogModel[] = [];
  estadosApList: CatalogModel[] = [];
  estadosList: CatalogModel[] = [];
  startDate: Date;
  finalDate: Date;

  //Filtro de orden
  fechaMenorMayor: boolean = false;
  fechaMayorMenor: boolean = false;
  estadoAZ: boolean = false;
  estadoZA: boolean = false;
  estadoApAZ: boolean = false;
  estadoApZA: boolean = false;
  orderBy: string = '';

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private localServOC: LocalServiceOCService,
    private exterServOC: ExternalServiceOCService,
    private ocParameter: ServicesOcParameterProvider,
    private loadingCtrl: LoadingController,
    public alertController: AlertController,
    private popoverCtrl: PopoverController,
    private navService: NavigationService
  ) {}

  async ngOnInit() {
    //Axel Vargas
    this.exterServOC.triggerStopLoadingOC = false;
    //Axel Vargas
    this.vatnumAct = await this.localServOC.getCedulaActual();
    this.listPurch = await this.localServOC.getOC();
    this.startDate = null; // new Date();
    this.finalDate = null; // new Date();

    // Traer la info de las OC que se han mandado.
    if (this.listPurch.length === 0) {
      // Solo realiza esto si no hay datos
      if (
        this.vatnumAct !== null &&
        this.vatnumAct !== undefined &&
        this.vatnumAct.length > 0
      ) {
        await this.showSpinnerLoading(
          'Cargando datos de ordenes creadas desde la aplicación.'
        );
        // Traer la info de las OC creadas desde la app
        const listtmp = (await this.exterServOC.getUpdateDataPO(
          '',
          -1,
          '',
          -1,
          this.vatnumAct,
          ''
        )) as PurchOrder[];
        this.dissmissLoading();
        //Trae todas las OC creadas desde la app
        if (listtmp !== null && listtmp !== undefined) {
          if (listtmp.length > 0) {
            for (const ocInfo of listtmp) {
              //Se guardan los datos
              await this.localServOC.saveOC(ocInfo);
            }
          } else {
            this.messageInfo(
              'Atención',
              'No hay ordenes creadas por el usuario.'
            );
          }
          this.listPurch = await this.localServOC.getOC();
          this.setListToShow();
        } else {
          this.messageInfo('Atención', 'No se pudo realizar la consulta.');
        }
      }
    }
  }

  //Axel Vargas
  async ngOnDestroy() {
    this.exterServOC.triggerStopLoadingOC = true;
  }
  //Axel Vargas
  async ionViewDidEnter() {
    this.listPurch = await this.localServOC.getOC();
    this.setListToShow();
    // Traer la info de las OC que se han mandado.
    if (this.listPurch.length > 0) {
      for (const oc of this.listPurch) {
        if (this.exterServOC.triggerStopLoadingOC) {
          break;
        }

        if (oc.getIdOC() !== '') {
          // && oc.getDocumentState() !== 'BORRADOR') {
          await this.exterServOC
            .getUpdateDataPO(
              oc.getDataArea().getId(),
              -1,
              oc.getIdOC(),
              -1,
              '',
              ''
            )
            .then(async (data) => {
              if (data !== null && data !== undefined) {
                const pos = this.listPurch.indexOf(oc);
                const actualOCInfo = data[0] as PurchOrder;
                if (actualOCInfo !== undefined && actualOCInfo !== null) {
                  actualOCInfo.setLocalId(oc.getLocalId());
                  // actualOCInfo.setFechaCreacion(oc.getFechaCreacion());
                  await this.localServOC.upDate(actualOCInfo);
                  this.listPurch.splice(pos, 1, actualOCInfo);

                  this.setListToShow();
                }
              }
            });
        }
      }
      // Buscamos otras OC si no hay otras OC ya guardadas locamente
      // Pero hay otras en AX
      const listTmpIDOC = await this.localServOC.getID_ax_OC();
      this.exterServOC
        .getUpdateDataPO('', -1, '', -1, this.vatnumAct, '')
        .then(async (infoOC) => {
          if (infoOC !== null && infoOC !== undefined) {
            const listInfoOC = infoOC as PurchOrder[];
            if (listInfoOC.length > 0) {
              for (const ocInfo of listInfoOC) {
                if (listTmpIDOC.indexOf(ocInfo.getIdOC()) === -1) {
                  //Se guardan los datos
                  await this.localServOC.saveOC(ocInfo);
                }
              }
              this.listPurch = await this.localServOC.getOC();
              this.setListToShow();
            }
          }
        });
    }
  }

  async goToOC() {
    this.exterServOC.triggerStopLoadingOC = true;
    this.ocParameter.setPurchOrderParameter(null);
    this.ocParameter.setToEdit(false); // No es para editar, es para crear

    this.navService.navigateTo(NavigationRoutes.PurchOrderPage);
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [{ text: 'Aceptar', handler: () => {} }],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  // Retorna una lista a partir de lo local y
  // filtros...
  setListToShow() {
    //A mi lista, realice los siguientes filtros
    const result = PurchOrder.filterState(this.listPurch, this.companyList, 1);
    const result2 = PurchOrder.filterState(result, this.proveedorList, 2);
    const result3 = PurchOrder.filterState(result2, this.estadosApList, 3);
    const result4 = PurchOrder.filterState(result3, this.estadosList, 4);
    const result5 = PurchOrder.filterByDateCreation(
      result4,
      this.startDate,
      this.finalDate
    );
    const result6 = PurchOrder.orderListBy(result5, this.orderBy);
    this.listPurchShowing = PurchOrder.filterObjectByWrite(
      this.filterKey,
      result6
    );
  }

  searchOC(event) {
    this.filterKey = event.detail.value as string;
    if (this.listPurch !== null && this.listPurch !== undefined) {
      this.setListToShow();
    }
  }

  // Retorna los filtros seleccionados por el usuario
  async presentFilterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: InfoFilterOcPopComponent,
      componentProps: {
        purchList: this.listPurch,
        typeFilter: 1,
        companyList: this.companyList,
        proveedorList: this.proveedorList,
        estadosApList: this.estadosApList,
        estadosList: this.estadosList,
        startDate: this.startDate,
        finalDate: this.finalDate,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        this.companyList = data.companyList as CatalogModel[];
        this.proveedorList = data.proveedorList as CatalogModel[];
        this.estadosApList = data.estadosApList as CatalogModel[];
        this.estadosList = data.estadosList as CatalogModel[];
        this.startDate = data.startDate as Date;
        this.finalDate = data.finalDate as Date;
        this.setListToShow();
      }
    });
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  setSelectedOrder(orderType: string) {
    this.fechaMenorMayor = 'fechaMenor-mayor' === orderType;
    this.fechaMayorMenor = 'fechaMayor-menor' === orderType;
    this.estadoAZ = 'estadoA-Z' === orderType;
    this.estadoZA = 'estadoZ-A' === orderType;
    this.estadoApAZ = 'estadoApA-Z' === orderType;
    this.estadoApZA = 'estadoApZ-A' === orderType;
  }
  /**
   * Muestra un alert con valores para ordenar las listas.
   */
  async presentSortAlert() {
    let alert = await this.alertController.create({
      header: '¿Cómo ordenar los activos?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Fecha (menor - mayor)',
          'fechaMenor-mayor',
          this.fechaMenorMayor
        ),
        this.getInputAlert(
          'radio',
          'Fecha (mayor - menor)',
          'fechaMayor-menor',
          this.fechaMayorMenor
        ),
        this.getInputAlert(
          'radio',
          'Estado (A -> Z)',
          'estadoA-Z',
          this.estadoAZ
        ),
        this.getInputAlert(
          'radio',
          'Estado (Z -> A)',
          'estadoZ-A',
          this.estadoZA
        ),
        this.getInputAlert(
          'radio',
          'Estado de Aprobación (A -> Z)',
          'estadoApA-Z',
          this.estadoApAZ
        ),
        this.getInputAlert(
          'radio',
          'Estado de Aprobación (Z -> A)',
          'estadoApZ-A',
          this.estadoApZA
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow();
            }
          },
        },
      ],
    });
    alert.present();
  }
}
