import { Component } from '@angular/core';
import {
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { InventoryItem } from 'src/app/businessCore/InventoryItem';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { RegisterContactNumberComponent } from '../register-contact-number/register-contact-number.component';

@Component({
  selector: 'app-families-select-hierarchy',
  templateUrl: './families-select-hierarchy.component.html',
  styleUrls: ['./families-select-hierarchy.component.scss'],
})
export class FamiliesSelectHierarchyComponent {
  titulo: string = '';
  itemInfo: InventoryItem[] = [];
  mainGroup: { name: string; show: boolean; iconUrl: string }[] = [];
  subGroup: string[] = [];
  imgUrl: string = '';
  shoppingCar: OrderLine[] = [];
  showLastClickedGroup: boolean = false;
  //breadcrumbs: any[];
  subFamilies_info: string[] = [];

  constructor(
    private nav: NavController,
    private storage: StorageService,
    private toast: ToastController,
    public popoverCtrl: PopoverController,
    private ShoopingCartUtils: ShoopingCartUtils,
    private navService: NavigationService,
    private navParams: NavParams
  ) {
    this.itemInfo = this.navParams.get('inventoryItem') as InventoryItem[];

    this.imgUrl = this.navParams.get('imgUrl');

    //this.breadcrumbs = this.navParams.get('breadcrumbs');

    // Verifica si existen productos, sino retorna a la ventana anterior
    if (this.itemInfo == null || this.itemInfo.length == 0) {
      this.nav.pop();
    } else {
      this.titulo = this.itemInfo[0].Hierarchy1;

      let response: string[] = this.getSubFamilyStrings();

      this.getSubFamiliesNameString();

      if (response.length == 0) {
        this.navService.navigateTo(NavigationRoutes.FamiliesHomeComponent, {
          inventoryItem: this.itemInfo,
        });
      } else {
        // Construccion del grupo de nombres de las diferentes sub familias
        //console.log(response);
        response.forEach((value) => {
          this.mainGroup.push({
            name: value,
            show: false,
            iconUrl: 'assets/img/icons/' + value + '_icon.png',
          });
          let arrayAux = this.mainGroup;
          this.mainGroup = arrayAux.sort((n1, n2) => {
            if (n1.name > n2.name) {
              return 1;
            }
            if (n1.name < n2.name) {
              return -1;
            }
            return 0;
          });
        });
      }
    }
    // DEBUG //
    //this.addPhoneNumber();
    //DEBUG
  }

  /**
   *
   */
  async addPhoneNumber() {
    const modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RegisterContactNumberComponent,
      componentProps: {
        test_data_1: 1,
        test_data_2: 2,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      //console.log('RES', res);
      //console.log('DATA', data);
    });
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  displayShoppingCar() {
    this.ShoopingCartUtils.displayShoppingCart();
  }

  getSubFamilyStrings() {
    let response: string[] = [];

    this.itemInfo.forEach((value) => {
      value.Icon = 'arrow-down';
      if (
        response.findIndex(
          (data) => data.toLowerCase() === value.Hierarchy2.toLowerCase()
        ) == -1
      ) {
        response.push(value.Hierarchy2);
      }
    });
    return response;
  }

  getSubFamiliesNameString() {
    this.itemInfo.forEach((item) => {
      item.Icon = 'arrow-down';
      if (
        this.subFamilies_info.indexOf(
          item.Hierarchy2.toUpperCase().replace(/ /g, '_')
        ) === -1
      ) {
        this.subFamilies_info.push(
          item.Hierarchy2.toUpperCase().replace(/ /g, '_')
        );
      }
    });
  }

  getCategoryStrings(subFamily: string) {
    let response: string[] = [];

    let subfamilies = this.itemInfo.filter(
      (value: InventoryItem) =>
        value.Hierarchy2.toUpperCase() === subFamily.toUpperCase()
    );
    if (subfamilies != null && subfamilies.length > 0) {
      subfamilies.forEach((value) => {
        if (response.findIndex((data) => data === value.Hierarchy3) == -1) {
          response.push(value.Hierarchy3);
        }
      });
    }
    return response;
  }

  toggleGroup(group) {
    let groupState: boolean = group.show;

    this.mainGroup.forEach((value) => {
      value.show = false;
    });

    group.show = !groupState;

    this.showLastClickedGroup = group.show;

    this.subGroup = this.getCategoryStrings(group.name);
    let arrayAux = this.subGroup;
    this.subGroup = arrayAux.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    });
  }

  showProducts(groupName: string, item: string = '') {
    // Si no selecciono la categoria, entonces solo filtra por familia y sub familia
    if (item == '') {
      this.navService.navigateTo(NavigationRoutes.FamiliesHomeComponent, {
        inventoryItem: this.itemInfo.filter(
          (value) =>
            value.Hierarchy2 === groupName && value.Hierarchy1 === this.titulo
        ),
      });
    }
    // de otra manera, Si selecciono la categoria, entonces filtra por familia, sub familia y categoria
    else {
      this.navService.navigateTo(NavigationRoutes.FamiliesHomeComponent, {
        inventoryItem: this.itemInfo.filter(
          (value) =>
            value.Hierarchy3 === item &&
            value.Hierarchy2 === groupName &&
            value.Hierarchy1 === this.titulo
        ),
      });
    }
  }

  nextStep(subfamily: string) {
    let imgUrl =
      'https://serviceplan-api.mercasacr.com/images/Products/Families/' +
      subfamily +
      '_BG.JPG';
    subfamily = subfamily.replace(/_/g, ' ');

    this.storage.get('productos').then(async (data) => {
      this.itemInfo = JSON.parse(data);
      if (this.itemInfo == null) {
        this.itemInfo = [];
      }

      // Se filtran los items que cumplen con la Hierarchy1 = family y Hierarchy2 = subfamilia
      this.itemInfo = this.itemInfo.filter(
        (value: InventoryItem) =>
          value.Hierarchy2.toUpperCase() === subfamily.toUpperCase() &&
          value.Hierarchy1.toUpperCase() === this.titulo.toUpperCase()
      );
      if (this.itemInfo != null && this.itemInfo.length > 0) {
        //this.redirectTo(SubFamiliesSelectHierarchyComponent, { 'inventoryItem': this.itemInfo, 'imgUrl': imgUrl, 'breadcrumbs': this.breadcrumbs});
        this.navService.navigateTo(
          NavigationRoutes.SubFamiliesSelectHierarchyComponent,
          {
            inventoryItem: this.itemInfo,
            imgUrl: imgUrl,
          }
        );
      } else {
        let toast = await this.toast.create({
          message:
            'Actualmente no hay productos en la sub-familia seleccionada',
          duration: 2000,
          buttons: ['Ok'],
        });
        toast.present();
      }
    });
  }

  replaceUnderScore(name: string) {
    return name.replace(/_/g, ' ');
  }
}
