<app-nav-bar [title]="'Resumen de OC'" [start]="'None'">
  <ion-buttons slot="end">
    <ion-button
      style="background: none"
      (click)="openSendFile()"
      expand="block"
      shape="round"
    >
      <i class="fas fa-file-upload" *ngIf="!recordConfirmed"></i>
      <ion-icon name="document" *ngIf="recordConfirmed"></ion-icon>
    </ion-button>

    <ion-button (click)="closePopOver(null)">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<!-- MERCASA VIP STYLE-->
<div class="box">
  <ion-item lines="none" *ngIf="infoPurch.getIdOC() !== ''">
    <ion-label style="font-size: 1rem; text-align: center">
      <b>{{ infoPurch.getIdOC() }}</b>
    </ion-label>
  </ion-item>

  <div class="content" style="color: black">
    <div class="borderInfo">
      <ion-grid>
        <ion-row class="titleRow"
          ><label><b>COMPRADOR</b></label></ion-row
        >
        <ion-row>
          <ion-col>Nombre:</ion-col>
          <ion-col>{{ infoPurch.getDataArea().getName() }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Telefono:</ion-col>
          <ion-col>{{ infoPurch.getTelfCompany() }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Fax:</ion-col>
          <ion-col>{{ infoPurch.getFaxCompany() }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>E-mail:</ion-col>
          <ion-col>{{ infoPurch.getEmailCompany() }}</ion-col>
        </ion-row>
        <!--<ion-row>
          <ion-col>Dirección:</ion-col>
          <ion-col></ion-col>
        </ion-row>-->
      </ion-grid>
    </div>
    <div class="borderInfo">
      <ion-grid>
        <ion-row class="titleRow"
          ><label><b>IDENTIFICACIÓN</b></label></ion-row
        >
        <ion-row>
          <ion-col>Fecha Inicio:</ion-col>
          <ion-col>{{ fechaIni }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col> Fecha Fin:</ion-col>
          <ion-col>{{ fechaFin }}</ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="borderInfo">
      <ion-grid>
        <ion-row class="titleRow"
          ><label><b>TRANSPORTE</b></label></ion-row
        >
        <!--<ion-row>
          <ion-col>Fecha Despacho:</ion-col>
          <ion-col></ion-col>
        </ion-row>-->
        <ion-row>
          <ion-col>Puerto:</ion-col>
          <ion-col>{{ puerto }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Transporte:</ion-col>
          <ion-col>{{ naviera }}</ion-col>
        </ion-row>
        <!--<ion-row>
          <ion-col>Enviado vía:</ion-col>
          <ion-col></ion-col>
        </ion-row>-->
        <ion-row>
          <ion-col>Contenedor:</ion-col>
          <ion-col>{{ containerType }}</ion-col>
        </ion-row>
        <!--<ion-row>
          <ion-col>Profundidad actual:</ion-col>
          <ion-col></ion-col>
        </ion-row>-->
        <ion-row>
          <ion-col>Sitio:</ion-col>
          <ion-col>{{ sitio }}</ion-col>
          <ion-col>Almacén:</ion-col>
          <ion-col>{{ almacen }}</ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="borderInfo">
      <ion-grid>
        <ion-row class="titleRow"
          ><label><b>NOTA</b></label></ion-row
        >
        <ion-row>
          <ion-col>{{ infoPurch.getNote() }}</ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="borderInfo">
      <ion-grid>
        <ion-row class="titleRow"
          ><label><b>CONDICION DE PAGO </b></label></ion-row
        >
        <ion-row>
          <ion-col>Terminos:</ion-col>
          <ion-col>{{ infoPurch.getPaymet() }}</ion-col>
        </ion-row>
        <!--<ion-row>
          <ion-col>Forma de Pago:</ion-col>
          <ion-col></ion-col>
        </ion-row>-->
      </ion-grid>
    </div>
    <ion-content class="borderInfo">
      <ion-grid>
        <ion-row class="titleRow"
          ><label><b>LÍNEAS</b></label></ion-row
        >
      </ion-grid>
      <ion-list>
        <app-linea-info-oc
          *ngFor="let purchLineInf of infoPurch.getLineList()"
          [purchLineInf]="purchLineInf"
          [cantEdit]="false"
          [showDimension]="false"
          [taxInfo]="infoPurch.getTaxInclude()"
        >
        </app-linea-info-oc>
      </ion-list>
    </ion-content>
  </div>

  <div class="infContent">
    <ion-item lines="none" color="primary">
      <div style="display: flex; width: 100%">
        <h5 style="width: 33%; font-size: 1rem; text-align: center">
          N° Líneas: {{ cantidadLineas }}
        </h5>
        <h5 style="width: 33%; font-size: 1rem; text-align: center">
          Imp: {{ getCurrency(impuestos) }}
        </h5>
        <h5 style="width: 33%; font-size: 1rem; text-align: center">
          Total: {{ getCurrency(total) }}
        </h5>
      </div>
    </ion-item>
    <div
      style="display: flex; margin-top: 2%; margin-bottom: 2%"
      *ngIf="!recordConfirmed"
    >
      <ion-button
        color="danger"
        class="btnPosition"
        (click)="rejectPurchOrder()"
      >
        Rechazar
      </ion-button>
      <ion-button
        color="secondary"
        class="btnPosition"
        (click)="confirmPurchOrder()"
      >
        Confirmar
      </ion-button>
    </div>
  </div>
</div>
