import { CatalogModel } from './CatalogModel';

export class PurchLine {
  private total: number;
  private subTotal: number;
  private taxesLine: number;
  private totalDiscount: number;
  public itemId: string;
  public precio: number;
  public unitId: string;
  public taxes: number;
  public porcentDescuent: number;
  public cantidad: number;
  public note: string;
  public name: string;
  private recId: number;
  private taxItemGroup: string;
  private toDelete: boolean;
  private A_Departamento: CatalogModel;
  private B_CentroCosto: CatalogModel;
  private C_Proposito: CatalogModel;
  constructor() {
    this.total = 0;
    this.cantidad = 0;
    this.subTotal = 0;
    this.totalDiscount = 0;
    this.note = '';
    this.toDelete = false;
    this.recId = -1;
  }

  setTaxItemGroup(newTaxItemGroup: string) {
    this.taxItemGroup = newTaxItemGroup;
  }

  getTaxItemGroup() {
    return this.taxItemGroup;
  }

  getName() {
    return this.name;
  }

  getCodigoProducto() {
    return this.itemId;
  }

  getNombreProducto() {
    return '';
  }

  getCantidad() {
    return this.cantidad;
  }

  getUnidad() {
    return this.unitId;
  }

  getPrecio() {
    return this.precio;
  }

  getPorcentajeDesc() {
    return this.porcentDescuent;
  }

  getTotal() {
    return this.total;
  }

  getSubTotal() {
    return this.subTotal;
  }

  getTaxesLine() {
    return this.taxesLine;
  }

  getTotalDiscount() {
    return this.totalDiscount;
  }

  setName(newName: string) {
    this.name = newName;
  }

  setNote(newNote: string) {
    this.note = newNote;
  }

  setTotal(newTotal: number) {
    this.total = newTotal;
  }

  setSubTotal(newSubT: number) {
    this.subTotal = newSubT;
  }

  setTaxesLine(newTaxesL: number) {
    this.taxesLine = newTaxesL;
  }

  setTotalDis(newTotalDis: number) {
    this.totalDiscount = newTotalDis;
  }

  setRecId(newRecId: number) {
    this.recId = newRecId;
  }

  getRecId() {
    return this.recId;
  }

  setToDelete(newToDelete: boolean) {
    this.toDelete = newToDelete;
  }

  getToDelete() {
    return this.toDelete;
  }

  setA_Departamento(newA_Dep: CatalogModel) {
    this.A_Departamento = newA_Dep;
  }

  getA_Departamento() {
    return this.A_Departamento;
  }

  setB_CentroCosto(newB_CentroCosto: CatalogModel) {
    this.B_CentroCosto = newB_CentroCosto;
  }

  getB_CentroCosto() {
    return this.B_CentroCosto;
  }

  setC_Proposito(newC_Proposito: CatalogModel) {
    this.C_Proposito = newC_Proposito;
  }

  getC_Proposito() {
    return this.C_Proposito;
  }

  calculateTotal() {
    this.total = this.subTotal - this.totalDiscount + this.taxesLine;
    return this.total;
  }

  calculateSubtotal() {
    this.subTotal = this.cantidad * this.precio;
  }

  calculateDiscount() {
    this.totalDiscount = this.subTotal * (this.porcentDescuent / 100);
  }

  calculateTaxesLine() {
    this.taxesLine = (this.subTotal - this.totalDiscount) * this.taxes;
  }

  calculateTaxesLine2() {
    this.taxesLine = this.cantidad * this.precio * this.taxes;
  }

  calculateSubtotal2() {
    const total = this.cantidad * this.precio;
    const porDes = this.porcentDescuent / 100;
    // const totalD = (total)
    // const ff = Number((1 - porDes).toFixed(2));
    // const ss = Number(( ff *  (1 + this.taxes )).toFixed(2));
    // numero = 1 / ( total * ( ( (-1 * porDes) + ( 1 - porDes) * this.taxes  )  + 1) );
    // const tmpS = Number( ((total / ss)).toFixed(2));
    // const tmpS = Number( ((total * ss)).toFixed(2));

    this.subTotal = total - total * this.taxes; // total - (tmpS - total);
    this.subTotal =
      porDes > 0 ? this.subTotal - this.subTotal * porDes : this.subTotal;
    /* console.log('Total: ', total, ' SubTotal: ', this.subTotal,
        ' Taxes: ',  ((this.subTotal + this.totalDiscount) * this.taxes),
        'Txes2: ' , (tmpS - total));*/
  }

  /**
   *
   * @param taxInclude : false calcula el impuesto sumando al total
   * : true calcula el impuesto restando al total
   */
  calculateInfo(taxInclude: boolean) {
    if (taxInclude) {
      // this.calculateTaxesLine2();
      this.calculateSubtotal2();
      this.calculateTaxesLine2();
      this.calculateDiscount();
      this.calculateTotal();
    } else {
      this.calculateSubtotal();
      this.calculateTaxesLine();
      this.calculateDiscount();
      this.calculateTotal();
    }
  }

  updateCant(newCant: number, taxInclude: boolean) {
    this.cantidad = newCant;
    this.calculateInfo(taxInclude);
  }

  lineToJSON() {
    const lineProducto = {
      PurchID: '',
      ItemID: this.itemId,
      Entrada: this.cantidad,
      purchLinePurchPrice: this.precio,
      purchLineNote: this.note,
      purchLinePercent: this.porcentDescuent,
      purchUnit: this.unitId,
      Name: this.name,
      recId: this.recId,
      ToDelete: this.toDelete ? 1 : 0,
      A_Departamento:
        this.A_Departamento !== null && this.A_Departamento !== undefined
          ? this.A_Departamento.getId()
          : '',
      B_CentroCosto:
        this.B_CentroCosto !== null && this.B_CentroCosto !== undefined
          ? this.B_CentroCosto.getId()
          : '',
      C_Proposito:
        this.C_Proposito !== null && this.C_Proposito !== undefined
          ? this.C_Proposito.getId()
          : '',
      TaxItemGroup: this.taxItemGroup,
    };
    // purchLineAmount : es el precio total de la linea
    // return JSON.stringify(lineProducto);
    return lineProducto;
  }
}
