export class Contact {
  contact: number;
  countryCode: string = '506';
  extension: number = 0;
  description: string = '';
  is_principal: boolean = false;
  contactName: string = '';
  firstName: string = '';
  lastName: string = '';
  type: boolean = true;
  edit: boolean = true;

  toXML(identify: string, prospectId: string) {
    let xml: string = '';
    xml =
      '<PSCustBusRelTableContact class="entity">' +
      '<prospectId>' +
      prospectId +
      '</prospectId>' +
      '<contactType>99</contactType>' +
      '<contact>' +
      this.contact +
      '</contact>' +
      '<extension>' +
      this.extension +
      '</extension>' +
      '<description>' +
      this.description +
      '</description>' +
      '<isPrincipal>' +
      (this.is_principal ? 1 : 0) +
      '</isPrincipal>' +
      '<contactName>' +
      this.contactName +
      '</contactName>' +
      '<firstName>' +
      this.firstName +
      '</firstName>' +
      '<lastName>' +
      this.lastName +
      '</lastName>' +
      '<contactIdType>1</contactIdType>' +
      '<contactId>null</contactId>' +
      '<typeRegister>0</typeRegister>' +
      '<type></type>' +
      '<identify>' +
      identify +
      '</identify>' +
      '<isNew>1</isNew>' +
      '</PSCustBusRelTableContact>';

    return xml;
  }
}
