export class Money {
  constructor(private amount: number) {}

  /*getCurrency() {
    //let price:number = Number.parseInt((this.amount + "").split(",",2)[0]);
    let price = Number(this.amount).toFixed(2);
    let priceStr:string = price.toString();
    let response:string="";
    if(priceStr.length<4){
      response=priceStr;
    }else if(priceStr.length>=4 && priceStr.length<7){
      response= priceStr.substr(0,priceStr.length-3)+"."+priceStr[priceStr.length-3]+priceStr[priceStr.length-2]+priceStr[priceStr.length-1];
    }else if(priceStr.length>=7 && priceStr.length<10){
      response= priceStr.substr(0,priceStr.length-6)+"."+priceStr[priceStr.length-6]+priceStr[priceStr.length-5]+priceStr[priceStr.length-4]+"."+priceStr[priceStr.length-3]+priceStr[priceStr.length-2]+priceStr[priceStr.length-1];
    }
    
    return '₡'+response;
    
  }*/

  getCurrency() {
    if (this.amount) {
      let decimal: number = Number.parseInt(
        (this.amount + '').split('.', 2)[1]
      );

      if (decimal && decimal > 0) {
        //return '₡' + Number(this.amount).toFixed(2);
        return '₡' + Number(this.amount).toFixed(0);
      } else {
        //return '₡' + Number(this.amount).toFixed(3).split(".", 2)[0];
        return '₡' + Number.parseInt((this.amount + '').split('.', 2)[0]);
      }
    } else {
      return '₡' + 0;
    }
  }
}
