import { Component } from '@angular/core';
import {
  AlertController,
  AlertOptions,
  ModalController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { ItemConvertion } from '../../../businessCore/OrderRet/ItemConvertion';
import { PSRtnBoughtItem } from '../../../businessCore/OrderRet/PSRtnBoughtItem';
import { PSRtnHeader } from '../../../businessCore/OrderRet/PSRtnHeader';
import { PSRtnLine } from '../../../businessCore/OrderRet/PSRtnLine';
import { RegisterData } from '../../../businessCore/RegisterData';
import { filteringOptions } from '../../../businessCore/filteringOptions';
import { webServiceProvider } from '../../../provider/webServiceProvider';
import { FilterComponent } from '../../filter/filter.component';
import { RetAddItemComponent } from '../ret-add-item/ret-add-item.component';

@Component({
  selector: 'app-ret-bought-item',
  templateUrl: './ret-bought-item.component.html',
  styleUrls: ['./ret-bought-item.component.scss'],
})
export class RetBoughtItemComponent {
  AccountNum: string = '';
  PriceList: string = '';

  BoughtItems: PSRtnBoughtItem[] = [];

  BoughtItemsByGroup: PSRtnBoughtItem[] = [];
  BoughtItemsByGroupAux: PSRtnBoughtItem[] = [];

  loadingBoughtItems: boolean = true;

  psRtnHeader: PSRtnHeader;

  rtnLines: PSRtnLine[] = [];

  register_data: RegisterData = new RegisterData();

  filter: filteringOptions = new filteringOptions();

  sortByNameAsc: boolean = false;
  sortByNameDesc: boolean = false;

  /**
   * Class constructor with these injections:
   * @param params object that exists on a page and can contain data for that particular view.
   */
  constructor(
    private params: NavParams,
    public viewCtrl: ModalController,
    private provider: webServiceProvider,
    private toast: ToastController,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private navService: NavigationService
  ) {
    this.AccountNum = this.params.get('accountNum');
    this.PriceList = this.params.get('priceList');
    this.register_data = this.params.get('registerData');

    this.getRtnBoughtItem();
  }

  getRtnBoughtItem() {
    // INVENTBATCHID: string = '';
    // EXPDATE: string = '';
    // INVENTDIMID: string = '';
    // INVOICEID: string = '';
    // INVOICEDATE: string = '';
    // SALESID: string = '';
    // ITEMID: string = '';
    // NAME: string = '';
    // TAXGROUP: string = '';
    // SALESUNIT: string = '';
    // INVOICEACCOUNT: string = '';
    // SALESPRICE: number = 0;
    // QTY: number = 0;
    this.provider.GetPSRtnBoughtItem(this.AccountNum).subscribe((data) => {
      if (data != null) {
        //DEBUG
        //data = [new PSRtnBoughtItem('PAPEL', 'MTIDHI01')];
        //data[0].QTY = 1;
        //DEBUG
        this.BoughtItems = data as PSRtnBoughtItem[];
        console.log(this.BoughtItems);
        if (this.BoughtItems.length < 1) {
          this.toast.create({
            message:
              'Usuario sin productos comprados en las últimas 6 semanas!',
            duration: 3000,
          });
        } else {
          this.groupByItemID();
        }
      } else {
        this.toast.create({
          message:
            'No se pudo consultar la información de productos comprados en las últimas 6 semanas!',
          duration: 3000,
        });
      }
      this.loadingBoughtItems = false;
    });
  }

  groupByItemID() {
    this.BoughtItems.forEach((bi) => {
      if (
        this.BoughtItemsByGroup.filter((bg) => bg.ITEMID == bi.ITEMID).length <=
        0
      ) {
        this.BoughtItemsByGroup.push(bi);
      }
    });

    this.BoughtItemsByGroupAux = Object.assign(
      [],
      this.BoughtItemsByGroup as PSRtnBoughtItem[]
    );

    console.log('this.BoughtItemsByGroup');
    console.log(this.BoughtItemsByGroup);
  }

  async addToCart(item: PSRtnBoughtItem) {
    this.provider.GetPSRtnInProcess(this.AccountNum).subscribe(async (res) => {
      let inProcess = res as boolean;
      if (!inProcess) {
        this.provider
          .GetItemConvertion(item.ITEMID, this.PriceList)
          .subscribe(async (data) => {
            let modal = await this.viewCtrl.create({
              component: RetAddItemComponent,
              componentProps: {
                item: item,
                boughtItems: this.BoughtItems,
                itemConvertion: data as ItemConvertion[],
                accountNum: this.AccountNum,
                psRtnLines: this.rtnLines,
                originSite: this.register_data.OriginSite,
              },
            });
            modal.present();
          });
      } else {
        let alert = await this.alertCtrl.create({
          header: 'Orden en proceso!',
          message:
            'Actualmente se cuenta con una orden de devolución en proceso para su cuenta. Favor esperar a que se libera para poder generar una nueva orden!',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
            },
          ],
        });
        alert.present();
      }
    });
  }

  closeModal() {
    this.viewCtrl.dismiss();
  }

  async cancelRtn() {
    let alert = await this.alertCtrl.create({
      header: 'Atención!',
      subHeader: 'Está seguro que desea cancelar la devolución?',
      message: `<p>Esto eliminará los productos agregados hasta el momento, para finalizar la devolución de click en el botón <a>Ver Devolución.</a></p>`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Si',
          handler: () => {
            this.closeModal();
          },
        },
        {
          text: 'Cancelar',
          role: 'cancel',
        },
      ],
    });
    alert.present();
  }

  async applyRtn() {
    if (this.rtnLines.length > 0) {
      this.navService.navigateTo(NavigationRoutes.RetCarComponent, {
        rtnLines: this.rtnLines,
        registerData: this.register_data,
      });
    } else {
      let toast = await this.toast.create({
        message: 'Agregue artículos a la devolución para poder visualizarla!',
        duration: 3000,
      });
      toast.present();
    }
  }

  applySearchFilter() {
    let filterAux = this.filter;
    filterAux.minPriceSelected = this.filter.minPrice;
    filterAux.maxPriceSelected = this.filter.maxPrice;

    this.applyFilters(filterAux);
  }

  async presentPopoverFilter() {
    // this.filter.productName
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: FilterComponent,
      componentProps: { filter: this.filter },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      this.applyFilters(data);
    });
  }

  applyFilters(data) {
    if (this.BoughtItemsByGroupAux.length > 0) {
      this.BoughtItemsByGroup = this.BoughtItemsByGroupAux;
    }
    if (data != null) {
      this.filter = data as filteringOptions;

      this.BoughtItemsByGroupAux = this.BoughtItemsByGroup;

      // Si una familia fue seleccionada se toma en cuenta este criterio para el filtrado de productos
      if (this.filter.families.filter((f) => f.selected).length > 0) {
        this.BoughtItemsByGroup = [];

        // Check if some family is selected
        this.filter.families.forEach((familyInfo) => {
          this.BoughtItemsByGroupAux.forEach((item) => {
            if (
              familyInfo.selected &&
              item.HIERARCHY1.toUpperCase() ==
                familyInfo.title.toUpperCase().replace(/_/g, ' ')
            ) {
              this.BoughtItemsByGroup.push(item);
            }
          });
        });
      }

      // Filtro de productos por nombre e identificacion
      if (this.filter.productName && this.filter.productName.length > 0) {
        let aux: PSRtnBoughtItem[] = this.BoughtItemsByGroup;
        this.BoughtItemsByGroup = [];
        aux.forEach((item) => {
          if (
            item.NAME.toLowerCase().includes(
              this.filter.productName.toLowerCase()
            ) ||
            item.ITEMID.toLocaleLowerCase().includes(
              this.filter.productName.toLocaleLowerCase()
            )
          ) {
            this.BoughtItemsByGroup.push(item);
          }
        });
      }
    } else {
      if (this.filter != null) {
        this.filter.minPriceSelected = 0;
        this.filter.maxPriceSelected = 0;
        this.filter.families = [];
        this.filter.productName = '';
      }
    }
  }

  async presentPopoverSort() {
    let alertOptions: AlertOptions = {
      header: '¿Cómo ordenar los productos?',
      inputs: [
        {
          type: 'radio',
          label: 'Nombre (A -> Z)',
          value: 'sortByNameAsc',
          checked: this.sortByNameAsc,
        },
        {
          type: 'radio',
          label: 'Nombre (Z -> A)',
          value: 'sortByNameDesc',
          checked: this.sortByNameDesc,
        },
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            this.sortProducts(data);
          },
        },
      ],
    };

    let alert = await this.alertCtrl.create(alertOptions);

    alert.present();
  }

  sortProducts(data: string) {
    this.sortByNameAsc = false;
    this.sortByNameDesc = false;
    if (data == 'sortByNameAsc') {
      this.sortNameAsc();
      this.sortByNameAsc = true;
    } else if (data == 'sortByNameDesc') {
      this.sortNameDesc();
      this.sortByNameDesc = true;
    }
  }

  sortNameAsc() {
    if (this.BoughtItemsByGroup.length > 1) {
      this.BoughtItemsByGroup.sort((a, b) => {
        if (a.NAME < b.NAME) {
          return -1;
        } else if (a.NAME > b.NAME) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  sortNameDesc() {
    if (this.BoughtItemsByGroup.length > 1) {
      this.BoughtItemsByGroup.sort((a, b) => {
        if (a.NAME > b.NAME) {
          return -1;
        } else if (a.NAME < b.NAME) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }
}
