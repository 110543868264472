import { Attribute, Directive } from '@angular/core';
import { NgModel } from '@angular/forms';
@Directive({
  selector: '[mask]',
  host: {
    '(ionInput)': 'onInputChange($event)',
  },
  providers: [NgModel],
})
export class MaskDirective {
  pattern: string;

  constructor(@Attribute('mask') pattern: string) {
    this.pattern = pattern;
  }

  onInputChange(event) {
    try {
      let value: string = event.target.value;
      let inputType: string = event.detail.event.inputType;
      let currentValue: string = value;

      if (inputType === 'deleteContentBackward') {
        currentValue = this.deleteText(value);
      } else if (inputType === 'insertText') {
        currentValue = this.insertText(value);
      }

      // Set the value
      event.target.value = currentValue;
    } catch (ex) {
      console.error(ex.message);
    }
  }

  deleteText(value: string) {
    let currentValue: string = '';

    //remove all trailing formatting
    for (let i = value.length - 1; i > 0; i--) {
      //match mask
      if (this.pattern[i] === '*') {
        currentValue = value.substring(0, i + 1);
        break;
      }
    }
    return currentValue;
  }

  insertText(value: string) {
    let currentValue: string = '';

    //apply mask characters
    for (let i = 0; i < value.length; i++) {
      //enforce pattern limit
      if (i < this.pattern.length) {
        //match mask
        if (this.pattern[i] !== '*' && this.pattern[i] !== value[i]) {
          currentValue += this.pattern[i];
        }
        currentValue += value[i];
      }
    }
    return currentValue;
  }
}
