import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Money } from 'src/app/GeneralUtilis/money';
import { ItemStatus } from 'src/app/businessCore/ItemStatus';
import { OrderHeader } from 'src/app/businessCore/OrderHeader';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { RegisterData } from 'src/app/businessCore/RegisterData';
import { webServiceProvider } from 'src/app/provider/webServiceProvider';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';
import { environment } from 'src/environments/environment';
import { UpdateQuantityComponent } from '../update-quantity/update-quantity.component';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent {
  public ordHerder: OrderHeader = new OrderHeader();
  public totalOrder: number = 0;
  public totalUnits: number = 0;
  public itemStatus: ItemStatus[] = [];

  public products_status: boolean = true;
  public avail_status: boolean = true;
  public sale_price_status: boolean = true;

  public shouldAnimate: boolean = true;

  public priceList: string = 'HX-VIP';
  public OrigOrdNbrInd: boolean = false;

  constructor(
    public popoverCtrl: PopoverController,
    private storage: StorageService,
    public alert: AlertController,
    public _loadingCtrl: LoadingController,
    public _apiProvider: webServiceProvider,
    private navParams: NavParams,
    private navService: NavigationService,
    private globalEventsService: GlobalEventsService
  ) {
    this.ordHerder.ordLine = this.navParams.get('shoppingCar') as OrderLine[];

    this.storage.get('register_data').then((data) => {
      if (data != null) {
        let registerData: RegisterData = JSON.parse(data) as RegisterData;
        this.ordHerder.customerId = registerData.customerId;
        this.ordHerder.deliveryRoute = registerData.DeliveryRoute;
        this.ordHerder.originSite = registerData.OriginSite;

        // save pricegroupid of the client
        this.priceList = registerData.priceList;
      }

      // try to set pricegroupid, located into the local storage
      this.storage.get('price_group_id').then((priceGroup) => {
        if (priceGroup && priceGroup != '') {
          this.priceList = JSON.parse(priceGroup) as string;
        }

        this.reIndexCar();
        this.validateProducts();
      });
    });
  }

  unionLines() {
    let tmpLines: OrderLine[] = Object.assign(
      [],
      this.ordHerder.ordLine.filter((ol) => ol.promoId == '')
    );
    let tmpLinesAux = Object.assign(
      [],
      this.ordHerder.ordLine.filter((ol) => ol.promoId != '')
    );

    this.ordHerder.ordLine = tmpLinesAux;

    // Lineas sin promocion
    tmpLines.forEach((line) => {
      let ordLine = this.ordHerder.ordLine.filter(
        (ol) =>
          ol.itemId == line.itemId &&
          ol.unitId == line.unitId &&
          ol.promoId == ''
      );
      if (ordLine.length <= 0) {
        this.ordHerder.ordLine.push(line);
      }
      // Ya existe un registro para ese itemid y unidad, entonces unificara las cantidades
      else {
        ordLine[0].salesQuantity += line.salesQuantity;
        ordLine[0].totalSale =
          (ordLine[0].salePrice + ordLine[0].tax) * ordLine[0].salesQuantity;
        ordLine[0].lnAmnt =
          ordLine[0].totalSale -
          Number.parseFloat(
            ((ordLine[0].totalSale * ordLine[0].linePercent) / 100).toFixed(2)
          );
      }
    });
  }

  reIndexCar() {
    let count: number = 1;
    this.totalOrder = 0;
    this.ordHerder.totalSale = 0;
    this.totalUnits = 0;
    this.ordHerder.ordLine.forEach((item) => {
      item.lineId = count;
      // this.totalOrder = this.totalOrder + item.totalSale;
      // this.ordHerder.totalSale = this.ordHerder.totalSale + item.totalSale;
      if (item.promoId != '') {
        this.totalOrder += item.lnAmnt;
        this.ordHerder.totalSale += item.lnAmnt;
      } else {
        this.totalOrder = this.totalOrder + item.totalSale;
        this.ordHerder.totalSale = this.ordHerder.totalSale + item.totalSale;
      }
      this.totalUnits += item.salesQuantity;
      count++;
    });

    // Une las lineas sin promocion
    this.unionLines();
  }

  async validateProducts() {
    let loading = await this._loadingCtrl.create({
      message: `Validando precios e inventario disponible.
      Por favor espere unos segundos.`,
    });
    //loading.present();

    const order_lines = this.ordHerder.ordLine as OrderLine[];

    let itemIds = '';
    let unitId = '';
    let availphysical = '';
    let character = '';

    order_lines.forEach((element) => {
      itemIds = itemIds.concat(character, element.itemId);
      unitId = unitId.concat(character, element.unitId);
      availphysical = availphysical.concat(
        character,
        element.salesQuantity.toString()
      );
      character = '@';
    });

    this._apiProvider
      .getRealProductsAvailable(this.priceList, itemIds, unitId, availphysical)
      .subscribe((data) => {
        if (data != null) {
          this.itemStatus = data as ItemStatus[];
        }
        loading.dismiss();

        this.updateItemsAmount();
      });
  }

  updateItemsAmount() {
    this.itemStatus.forEach((item) => {
      this.ordHerder.ordLine.forEach((ordLine) => {
        if (ordLine.itemId == item.itemId && ordLine.unitId == item.unitId) {
          ordLine.itemStatus = item.status;
          ordLine.avail = item.avail;

          if (
            ordLine.salePrice != item.amount &&
            (item.status == 0 || item.status == 1)
          ) {
            let salePrice = Math.trunc(item.amount);
            if (salePrice != ordLine.salePrice) {
              ordLine.salePriceUpdated = true;
            }
            ordLine.salePrice = salePrice;
            ordLine.totalSale = ordLine.salePrice * ordLine.salesQuantity;
            ordLine.lnAmnt =
              ordLine.totalSale -
              Number.parseFloat(
                ((ordLine.totalSale * ordLine.linePercent) / 100).toFixed(2)
              );
          }
        }
      });
    });

    this.reIndexCar();
  }

  validateStatusOK() {
    // 0 -> Producto con existencias suficientes (OK)
    // 1 -> Inventario insuficiente
    // -2 -> Producto inexistente
    // -1 -> Error en la consulta
    let resp = true;
    this.ordHerder.ordLine.forEach((element) => {
      if (element.itemStatus != 0) {
        resp = false;
      }
    });

    return resp;
  }

  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para setear el precio unitario de un producto + el impuesto correspondiente
   * @param item Línea de la orden a la que se le calcula el precio con impuesto por cada unidad
   */
  setSalePrice(item: OrderLine) {
    return this.setCurrency(item.salePrice + item.tax);
  }

  async presentMsg(title: string, subtitle: string) {
    let alert = await this.alert.create({
      header: title,
      subHeader: subtitle,
      buttons: ['Ok'],
      backdropDismiss: false,
    });
    alert.present();
  }

  displayUpdatePopup(item: OrderLine) {
    let itemStatus = -1;

    this.itemStatus.forEach((element, index) => {
      if (element.itemId == item.itemId) {
        // Inventario insuficiente
        itemStatus = index;
      }
    });

    const index = this.ordHerder.ordLine.indexOf(item);

    this.ordHerder.ordLine[index].salesQuantity =
      this.itemStatus[itemStatus].avail;

    this.ordHerder.ordLine[index].totalSale =
      this.ordHerder.ordLine[index].salePrice *
      this.ordHerder.ordLine[index].salesQuantity;

    let i = this.ordHerder.ordLine.indexOf(item);
    this.ordHerder.ordLine[i].itemStatus = 0;

    this.presentMsg(
      item.itemName,
      'Cantidad actualizada según el inventario disponible.'
    );
  }

  displayDeletePopup(item: OrderLine) {
    const index = this.ordHerder.ordLine.indexOf(item);
    this.ordHerder.ordLine.splice(index, 1);
    let itemIndex = -1;

    this.itemStatus.forEach((element, index) => {
      if (element.itemId == item.itemId) {
        // Inventario insuficiente
        itemIndex = index;
      }
    });

    this.itemStatus.splice(itemIndex, 1);

    this.presentMsg(
      item.itemName,
      'Se ha eliminado del carrito de compras por falta de existencias.'
    );

    this.reIndexCar();
  }

  /**
   * Elimina las promociones en un conjunto de lineas especificas
   */
  deletePromoInLines(PromoId, Sequence) {
    this.ordHerder.ordLine
      .filter((ol) => ol.promoId == PromoId && ol.PromoSequence == Sequence)
      .forEach((line) => {
        line.linePercent = 0;
        line.PromoSequence = 0;
        line.promoId = '';
        line.promoSalesType = 99; // Sin Promo
      });

    this.reIndexCar();
  }

  async presentItemAlert(updateItem: boolean, item: OrderLine) {
    // ITEM WITH PROMO
    if (item.promoId != '') {
      // let subT = 'Esta línea de la orden no puede ser modificada!';
      // let msg = `<p>Al pertenecer a una promoción no se podrán actualizar cantidades ni eliminarla de esta forma.</p>
      //   <p>Usted podrá:</p>
      //   <ul>
      //   <li>Eliminar todas las lineas relacionadas a esta promoción.</li>
      //   <li>Eliminar la promoción de las líneas.</li>
      //   </ul>
      //   `;
      let btns = [];

      // Es eliminar
      //if (!updateItem) {
      this.delete(item);
      let subT = 'Esta línea de la orden a sido eliminada!';
      let msg = `<p>Al pertenecer a una promoción no se pueden mantener los descuentos ni rebajar la cantidad de producto.</p>
        <p>Usted podrá:</p>
        <ul>
        <li>Eliminar todas las lineas relacionadas a esta promoción.</li>
        <li>Eliminar la promoción de las líneas.</li>
        </ul>
        `;

      /*  btns.push({
          text: 'Cancelar',
          role: 'cancel'
        });
        */
      //}

      btns.push(
        {
          text: 'Eliminar líneas',
          handler: () => {
            this.ordHerder.ordLine
              .filter(
                (ol) =>
                  ol.promoId == item.promoId &&
                  ol.PromoSequence == item.PromoSequence
              )
              .forEach((line) => {
                this.delete(line);
              });
          },
        },
        {
          text: 'Eliminar promoción',
          handler: () => {
            this.deletePromoInLines(item.promoId, item.PromoSequence);
          },
        }
      );

      let alert = await this.alert.create({
        header: 'Atención!',
        subHeader: subT,
        message: msg,
        buttons: btns,
        backdropDismiss: updateItem,
      });

      alert.present();
    }
    // ITEM without PROMO ID
    else {
      if (updateItem) {
        // Popover para actualizar las cantidades de un item
        this.displayUpdatePopup(item);
      } else {
        // Popover para eliminar un producto del carrito por falta de existencias
        this.displayDeletePopup(item);
      }

      // Actualizamos el total de la orden
      let total_aux = 0;
      this.ordHerder.ordLine.forEach((item) => {
        if (item.promoId != '') {
          total_aux += item.lnAmnt;
          // this.ordHerder.totalSale += item.lnAmnt;
        } else {
          total_aux += item.totalSale;
          // this.ordHerder.totalSale = this.ordHerder.totalSale + item.totalSale;
        }
        // total_aux += item.totalSale;
      });

      this.totalOrder = total_aux;
      this.ordHerder.totalSale = total_aux;
    }
  }

  async presentAlert(title: string, msg: string, orderline: OrderLine) {
    let alert = await this.alert.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Eliminar',
          handler: () => {
            this.delete(orderline);
          },
        },
        {
          text: 'Cancelar',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  async presentAlertAux(title: string, msg: string, orderline: OrderLine) {
    if (orderline.promoId != '') {
      let alert = await this.alert.create({
        header: 'Atención!',
        subHeader: '¿Está seguro que desea eliminar esta línea de la orden?',
        message: `<p>Al pertenecer a una promoción no se podrá eliminar de manera directa.</p>
        <p>Usted podrá:</p>
        <ul>
        <li>Eliminar todas las lineas relacionadas a esta promoción.</li>
        <li>Eliminar la promoción de las líneas.</li>
        </ul>
        `,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Eliminar líneas',
            handler: () => {
              this.ordHerder.ordLine
                .filter(
                  (ol) =>
                    ol.promoId == orderline.promoId &&
                    ol.PromoSequence == orderline.PromoSequence
                )
                .forEach((line) => {
                  this.delete(line);
                });
            },
          },
          {
            text: 'Eliminar promoción',
            handler: () => {
              this.deletePromoInLines(
                orderline.promoId,
                orderline.PromoSequence
              );
            },
          },
        ],
        backdropDismiss: true,
      });

      alert.present();
    } else {
      this.presentAlert(title, msg, orderline);
    }
  }

  delete(orderline: OrderLine) {
    // let result: OrderLine[] = [];
    this.ordHerder.ordLine.forEach((item, index) => {
      if (index + 1 == orderline.lineId) {
        // result.push(item);
        this.ordHerder.ordLine.splice(index, 1);
      }
      // else{
      // }
    });
    // this.ordHerder.ordLine = result;
    this.reIndexCar();
  }

  async updateAux(orderline: OrderLine) {
    if (orderline.promoId != '') {
      /* 
      1- Condition
      3- Result
      5- Condition-Result
      99- Sin Promo
      */
      if (orderline.promoSalesType == 1 || orderline.promoSalesType == 5) {
        this.update(orderline, true);
      } else {
        let alert = await this.alert.create({
          header: 'Alerta!',
          subHeader:
            'La cantidad de este producto no puede ser modificada puesto que pertenece a una promoción y se encuentra como resultado.',
          message:
            'En caso de requerir modificarla, elimine la línea y vuelva a aplicar la promoción.',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
            },
          ],
          backdropDismiss: false,
        });

        alert.present();
      }
    } else {
      this.update(orderline, false);
    }
  }

  async update(orderline: OrderLine, InPromo: boolean) {
    const popover = await this.popoverCtrl.create({
      component: UpdateQuantityComponent,
      componentProps: { OrderLine: orderline, InPromo: InPromo },
      cssClass: ['custom-popover', 'update-quantity'],
    });
    popover.present();
    popover.onDidDismiss().then(() => {
      this.verifyList();
    });
  }

  verifyList() {
    this.ordHerder.ordLine.forEach((data, index) => {
      if (data.salesQuantity == 0) {
        this.ordHerder.ordLine.splice(index, 1);
      }
    });
    this.reIndexCar();
  }

  resetSalePriceStatus() {
    this.ordHerder.ordLine.forEach((element) => {
      element.salePriceUpdated = false;
    });
  }

  // to get the event when the user clicked the back button
  ionViewWillLeave() {
    this.storage.get('carrito').then((data) => {
      // If == NULL, el carrito ya se finalizo
      if (JSON.parse(data) != null) {
        this.resetSalePriceStatus();
        this.storage
          .set('carrito', JSON.stringify(this.ordHerder.ordLine))
          .then(() => {
            this.globalEventsService.publish(GlobalEvents.UPDATE_CART);
          });
      }
    });
  }

  dismiss() {
    this.resetSalePriceStatus();
    this.popoverCtrl.dismiss(this.ordHerder.ordLine);
    // Save shoppingCar
    this.storage
      .set('carrito', JSON.stringify(this.ordHerder.ordLine))
      .then(() => {
        this.globalEventsService.publish(GlobalEvents.UPDATE_CART);
      });
  }

  NextStep() {
    this.navService.navigateTo(NavigationRoutes.ShoppingCarDeliveryComponent, {
      ordHeader: this.ordHerder,
    });
  }

  async swipeEvent(event, order: OrderLine) {
    // DELETE LINE
    if (event.direction === 2) {
      if (order.promoId != '') {
        let alert = await this.alert.create({
          header: 'Atención!',
          subHeader: '¿Está seguro que desea eliminar esta línea de la orden?',
          message: `<p>Al pertenecer a una promoción no se podrá eliminar de manera directa.</p>
          <p>Usted podrá:</p>
          <ul>
          <li>Eliminar todas las lineas relacionadas a esta promoción.</li>
            <li>Eliminar la promoción de las líneas.</li>
          </ul>
          `,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Eliminar líneas',
              handler: () => {
                this.ordHerder.ordLine
                  .filter(
                    (ol) =>
                      ol.promoId == order.promoId &&
                      ol.PromoSequence == order.PromoSequence
                  )
                  .forEach((line) => {
                    this.delete(line);
                  });
              },
            },
            {
              text: 'Eliminar promoción',
              handler: () => {
                this.deletePromoInLines(order.promoId, order.PromoSequence);
              },
            },
          ],
          backdropDismiss: true,
        });
        alert.present();
      } else {
        this.presentAlert(
          'Atención',
          '¿Desea eliminar una línea de la orden?',
          order
        );
      }
    }
    // UPDATE LINE QUANTITY
    else if (event.direction === 4) {
      if (order.promoId != '') {
        /* 
        1- Condition
        3- Result
        5- Condition-Result
        99- Sin Promo
        */
        if (order.promoSalesType == 1 || order.promoSalesType == 5) {
          this.update(order, true);
        } else {
          let alert = await this.alert.create({
            header: 'Alerta!',
            subHeader:
              'La cantidad de este producto no puede ser modificada puesto que pertenece a una promoción y se encuentra como resultado.',
            message:
              'En caso de requerir modificarla, elimine la línea y vuelva a aplicar la promoción.',
            buttons: [
              {
                text: 'OK',
                role: 'cancel',
              },
            ],
            backdropDismiss: false,
          });
          alert.present();
        }
      } else {
        this.update(order, false);
      }
    }
  }

  validOrigOrdNbr() {
    return this.ordHerder.OrigOrdNbr && this.ordHerder.OrigOrdNbr.length > 0
      ? true
      : false;
  }

  validForm() {
    return this.ordHerder.ordLine.length > 0 && this.validateStatusOK()
      ? true
      : false;
  }

  getSiteName(InventSiteId: string) {
    return environment.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }

  getOriginalSiteName(InventSiteId: string) {
    return environment.FMCM_SITES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }
}
