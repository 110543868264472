<ion-content center text-center>
  <h4 style="text-align: center">Productos del Pedido</h4>

  <form (ngSubmit)="addItems()" #registerForm="ngForm">
    <div class="selectAll">
      <ion-checkbox
        name="chkAll"
        id="chkAll"
        class="chkBox"
        (click)="selectAll()"
      ></ion-checkbox>
      <label for="chkAll">Seleccionar todos</label>
    </div>

    <div style="text-align: center" *ngIf="loadingProducts">
      <ion-spinner name="dots" duration="1000"></ion-spinner>
      <h6 style="text-align: center">Cargando Productos</h6>
    </div>

    <div class="flexContainer" lines="none" *ngFor="let line of orderLines">
      <ion-checkbox
        class="chkBox"
        [(ngModel)]="line.checked"
        name="lineCheck"
        (click)="changeValue()"
      ></ion-checkbox>
      <div class="infoContainer">
        <div class="flexContainer">
          <div style="margin-right: 4px">
            <img
              src="https://serviceplan-api.mercasacr.com/images/Products/{{
                line.itemId
              }}_s_.PNG"
              alt=""
              onerror=""
            />
          </div>

          <div class="flexBlockContainer" style="margin-left: 4px">
            <div ion-text text-center text-wrap class="itemName">
              {{ line.itemName }}
            </div>
            <div class="flexContainer">
              <div class="flexBlockContainer" style="margin-right: 2px">
                <h6 ion-text color="primary" style="font-weight: bold">
                  {{ setCurrency(line.salePrice) }}
                </h6>
                <h6 ion-text>{{ line.unitId }}</h6>
              </div>
              <div
                class="flexBlockContainer"
                style="margin-right: 2px; margin-left: 2px"
              >
                <h6 ion-text>{{ line.salesQuantity }} {{ line.unitId }}</h6>
                <h6 ion-text *ngIf="line.linePercent > 0" color="danger">
                  -{{ line.linePercent }}%
                </h6>
              </div>
              <div class="flexBlockContainer" style="margin-left: 2px">
                <h6 ion-text style="font-weight: bold" color="primary">
                  {{ setCurrency(line.totalSale) }}
                </h6>
                <h6 ion-text>TOTAL</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <ion-button
        class="submit-btn"
        type="submit"
        [disabled]="!validForm()"
        shape="round"
      >
        <i class="fas fa-cart-plus" style="margin-right: 10px"></i>Agregar al
        Carrito
      </ion-button>
    </div>
  </form>
</ion-content>
