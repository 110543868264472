











// Native-Plugins
import { Component, Input, ViewChild } from '@angular/core';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { google } from 'google-maps';
declare let google;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent {
  // atributo donde se va cargar el componente del mapa
  @ViewChild('map') mapElement;
  map: any;

  // latitud de la ubicacion que es pasada por parametro desde la definicion del html
  @Input()
  lat: number;

  // longitud de la ubicacion que es pasada por parametro desde la definicio del html
  @Input()
  lng: number;

  // marcador que es posicionado sobre el mapa para indicar una ubicacion (lat-lng)
  marker: google.maps.Marker;

  /**
   * Class constructor with these injections:
   * @param events a publish-subscribe style event system for sending
   * and responding to application-level events across your app.
   * @param geolocation provides information about the device's location,
   * such as latitude and longitude.
   */
  constructor(
    public events: GlobalEventsService,
    public geolocation: Geolocation
  ) {
    /**
     * Funcion utilizada para publicar el metodo 'get:LatLng' y que el mismo
     * pueda ser accedido desde el componente padre
     */
    events.getGlobalEvent(GlobalEvents.getLatLng).subscribe(() => {
      return this.getPosition();
    });

    /**
     * Funcion utilizada para publicar el metodo 'setActualPos' y que el mismo
     * pueda ser accedido desde el componente padre
     */
    events.getGlobalEvent(GlobalEvents.setActualPos).subscribe(() => {
      return this.setActualPosition();
    });
  }

  /**
   * Metodo encargado de inicializar el mapa con todas las configuraciones necesarias
   * de coordenadas, marcadores, opciones y demas cosas
   */
  ngOnInit() {
    this.initMap();
  }

  /**
   * Metodo utilizado para inicializar el mapa y todas las opciones de configuracion
   * que tiene asociadas
   */
  initMap() {
    // coordenadas en las que se despliega el marcador y se centra el mapa
    let coords = new google.maps.LatLng(this.lat, this.lng);

    // objeto utilizado para controlar los eventos y acciones que se pueden llevar
    // a cabo por medio del mapa
    let geocoder = new google.maps.Geocoder();

    // opciones con las que se configura el mapa en terminos de
    // coordenadas, zoom, y controles que se despliegan
    let mapOptions: google.maps.MapOptions = {
      center: coords,
      zoom: 16,

      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.HYBRID,
    };

    // Se incia el mapa con las opciones establecidas anteriormente
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    // Creacion del marcador que se despliega en el mapa de manera centrada
    // en la posicion lat-lng definida en coords
    this.marker = new google.maps.Marker({
      map: this.map,
      position: coords,
      draggable: true,
    });

    //This line is what makes the Marker stick to the center of the map
    this.marker.bindTo('position', this.map, 'center');

    geocoder.geocode({ latLng: coords }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.lat = this.marker.getPosition().lat();
          this.lng = this.marker.getPosition().lng();
        }
      }
    });

    google.maps.event.addListener(this.map, 'dragend', () => {
      geocoder.geocode(
        { latLng: this.marker.getPosition() },
        (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.lat = this.marker.getPosition().lat();
              this.lng = this.marker.getPosition().lng();
            }
          }
        }
      );
    });
  }

  /**
   * Metodo utilizado para actualizar la posicion actual del marcador y del mapa
   */
  setActualPosition() {
    this.geolocation.getCurrentPosition().then((position) => {
      // Se establecen las nuevas coordenadas segun la posicion actual
      let coords = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );

      // Se asignan dichas coordenas al marcador
      this.marker = new google.maps.Marker({
        map: this.map,
        position: coords,
        draggable: true,
      });

      // Actualizar latitud y longitud
      this.lat = this.marker.getPosition().lat();
      this.lng = this.marker.getPosition().lng();

      // Centrar el mapa en las nuevas coordenadas
      this.map.setCenter(coords);

      // Centrar el marcador en las nuevas coordenadas
      this.marker.bindTo('position', this.map, 'center');
    });
  }

  /**
   * Metodo utilizado para obtener la posicion actual del marcador en el mapa
   * desde el componente principal que lo abarca
   */
  getPosition() {
    return [this.lat, this.lng];
  }
}
