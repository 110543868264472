import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SelectedStandar } from '../../../businessCore/trip-models/selectedStandar';

@Component({
  selector: 'app-search-provider',
  templateUrl: './search-provider.component.html',
  styleUrls: ['./search-provider.component.scss'],
})
export class SearchProviderComponent implements OnInit {
  objectsList: any[];
  objectFilterdList: any[];
  claveBusqueda: string;
  objectSelected: any;
  show: boolean = false;
  cantEdit: boolean = false;
  private backButtonSub: Subscription;

  constructor(
    private viewCtrl: ModalController,
    private platform: Platform,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.objectsList = this.navParams.get('objectsList');
    const tmpCanEdit = this.navParams.get('canEdit');
    if (tmpCanEdit !== null && tmpCanEdit !== undefined) {
      this.cantEdit = tmpCanEdit;
    }
    this.objectFilterdList = this.objectsList;
    this.objectSelected = null;
    this.show = this.navParams.get('show');
    console.log(this.objectFilterdList);
  }

  // Coloca la lista nueva de filtros
  setFilteredObjects() {
    this.objectFilterdList = this.filterObjectBy(this.claveBusqueda);
  }

  // Retorna la lista de los clientes filtrados
  filterObjectBy(nameOrCode: string) {
    const key = nameOrCode.toLowerCase();
    let tmpList: any[] = [];
    if (key.length > 0) {
      // Filtra los clientes
      tmpList = this.objectsList.filter((objectPlan) => {
        let idInclude = false;
        if (typeof objectPlan.getId() === 'string') {
          idInclude = objectPlan.getId().toLowerCase().includes(key);
        } else {
          idInclude = objectPlan.getId() === key;
        }
        return objectPlan.getName().toLowerCase().includes(key) || idInclude;
      });
    }
    if (tmpList.length === 0) {
      if (this.cantEdit) {
        tmpList = [new SelectedStandar(nameOrCode, nameOrCode)];
      } else {
        tmpList = this.objectsList; // Si no encuentra
      }
    }

    return tmpList;
  }

  ionViewDidEnter() {
    // this.platform.registerBackButtonAction.
    this.backButtonSub = this.platform.backButton.subscribe(
      () => {
        setTimeout(() => this.closePopOver(null), 10000);
      } // Al solo devolverse con el boton es null
    );
  }

  ionViewWillLeave() {
    // this.backButtonSub.unsubscribe();
  }

  async closePopOver(objetSelected: any) {
    this.viewCtrl.dismiss({
      objSelected: objetSelected,
    });
  }

  showId(obj: any) {
    let result = true;
    if (obj.getId() === obj.getName() || typeof obj.getId() === 'number') {
      result = false;
    }
    return result;
  }
}
