<!-- NavBar with go back option -->
<app-nav-bar [title]="titulo" [start]="'GoBack'" [end]="'Cart'"></app-nav-bar>

<!-- Component content -->
<ion-content>
  <div class="flexContainer">
    <div
      class="familyContainer fImg"
      [ngStyle]="{
        'background-image':
          'url(https://serviceplan-api.mercasacr.com/images/Products/Subfamilies/' +
          subfamilyTitle +
          '_BG.JPG' +
          ')'
      }"
      (click)="nextStep(subfamilyTitle)"
      *ngFor="let subfamilyTitle of subFamilies_info"
    >
      <img
        src="{{
          'https://serviceplan-api.mercasacr.com/images/Products/Subfamilies/' +
            subfamilyTitle +
            '_ico.png'
        }}"
        alt=""
      />
      <div class="h7">{{ replaceUnderScore(subfamilyTitle) }}</div>
    </div>
  </div>
</ion-content>
