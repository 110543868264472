<ion-card *ngIf="!purchLineInf.getToDelete()">
  <ion-card-content>
    <ion-item-sliding (swipe)="swipeEvent($event)">
      <ion-item style="--inner-padding-end: 0px; --padding-start: 0px">
        <ion-grid [ngClass]="getStyle()" (click)="updateDimensionFinancial()">
          <ion-row>
            <h6 ion-text text-wrap class="h72">
              {{ purchLineInf.getCodigoProducto() }}
            </h6>
          </ion-row>
          <ion-row
            ><h6 ion-text text-wrap class="h72">
              {{ purchLineInf.getName() }}
            </h6></ion-row
          >

          <ion-row>
            <ion-col col-13>
              <h6 ion-text text-center class="h7">Cant</h6>
              <h6
                ion-text
                text-center
                color="primary"
                class="h7"
                style="font-weight: bold"
              >
                {{ purchLineInf.getCantidad() }}
              </h6>
            </ion-col>
            <ion-col col-13>
              <h6 ion-text text-center class="h7">Und</h6>
              <h6
                ion-text
                color="primary"
                text-center
                class="h7"
                style="font-weight: bold"
              >
                {{ purchLineInf.getUnidad() }}
              </h6>
            </ion-col>
            <ion-col col-13>
              <h6 ion-text text-center class="h7">P.U</h6>
              <h6
                ion-text
                color="primary"
                text-center
                class="h7"
                style="font-weight: bold"
              >
                {{ getCurrency(purchLineInf.getPrecio()) }}
              </h6>
            </ion-col>
            <ion-col col-13>
              <h6 ion-text text-center class="h7">Desc</h6>
              <h6
                ion-text
                color="primary"
                text-center
                class="h7"
                style="font-weight: bold"
              >
                {{ purchLineInf.getPorcentajeDesc() }}
              </h6>
            </ion-col>
            <ion-col col-13>
              <h6 ion-text text-center class="h7">SubTotal</h6>
              <h6
                ion-text
                color="primary"
                text-center
                class="h7"
                style="font-weight: bold"
              >
                {{ getCurrency(purchLineInf.getSubTotal()) }}
              </h6>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item-options side="start" *ngIf="permitEdit">
        <ion-item-option (click)="updateCantItem()">
          <i class="fas fa-pencil-alt"></i>
          <span style="font-size: 8px">EDITAR</span>
        </ion-item-option>
      </ion-item-options>
      <ion-item-options side="end" *ngIf="permitEdit">
        <ion-item-option color="danger" (click)="clickDelete()">
          <ion-icon name="trash"> </ion-icon>
          <span style="font-size: 8px">ELIMINAR</span>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-card-content>
</ion-card>
