<app-nav-bar [title]="'Obtener Posición'"></app-nav-bar>

<ion-fab
  vertical="top"
  horizontal="end"
  (click)="setActualPosition()"
  [hidden]="!showActualPosBtn"
>
  <ion-fab-button
    color="gray"
    class="submit-btn"
    size="small"
    style="margin: 0px"
  >
    <i class="fas fa-crosshairs" style="color: gray"></i>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding" class="map-content">
  <ion-button
    style="width: 90%; margin: 10px"
    shape="round"
    (click)="getPosition()"
  >
    Fijar Punto de Entrega
  </ion-button>
  <div
    style="
      text-align: center;
      font-size: 0.9rem !important;
      color: brown;
      margin: 10px;
    "
  >
    Seleccione el punto en el mapa donde desea que se realicen las entregas y
    presione el botón <a style="font-weight: bold">Fijar Punto de Entrega.</a>
  </div>
  <div id="mapid"></div>
  <!-- <google-map [lat]="lat" [lng]="lng"></google-map> -->
</ion-content>
