<ion-content center text-center>
  <h4 class="title2" style="text-align: center" *ngIf="restriction_data.edit">
    Editar Restricción
  </h4>
  <h4 class="title2" style="text-align: center" *ngIf="!restriction_data.edit">
    Nueva Restricción
  </h4>

  <form (ngSubmit)="addRestriction()" #registerForm="ngForm">
    <ion-item>
      <ion-select
        label="Día(s)"
        label-placement="floating"
        class="floating"
        name="day"
        [(ngModel)]="restrictionDays"
        multiple="true"
        [ngModelOptions]="{ standalone: true }"
      >
        <ion-select-option
          *ngFor="let day of available_days"
          value="{{ day.id }}"
          >{{ day.value }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-select
        label="Tipo"
        label-placement="floating"
        class="floating"
        name="type"
        [(ngModel)]="restrictionType"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option
          *ngFor="let type of type_list"
          value="{{ type.id }}"
          >{{ type.value }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label
        *ngIf="restrictionType == 0 || restrictionType == 1"
        color="primary"
        ><a style="color: brown">*</a> Hora</ion-label
      >
      <ion-label
        *ngIf="restrictionType == 2 || restrictionType == 3"
        color="primary"
        ><a style="color: brown">*</a> Hora Inicio</ion-label
      >
      <ion-datetime
        displayFormat="h:mm A"
        pickerFormat="h:mm A"
        hourCycle="h12"
        presentation="time"
        [(ngModel)]="restrictionDate1"
        [ngModelOptions]="{ standalone: true }"
      ></ion-datetime>
    </ion-item>

    <ion-item *ngIf="restrictionType == 2 || restrictionType == 3">
      <ion-label color="primary"
        ><a style="color: brown">*</a> Hora Final</ion-label
      >
      <ion-datetime
        displayFormat="h:mm A"
        hourCycle="h12"
        presentation="time"
        [preferWheel]="false"
        min="{{ restrictionDate1 }}"
        [(ngModel)]="restrictionDate2"
        [ngModelOptions]="{ standalone: true }"
      ></ion-datetime>
    </ion-item>
    <div class="center-element">
      <ion-button
        class="submit-btn"
        type="submit"
        margin-top
        [disabled]="!validForm()"
        *ngIf="restriction_data.edit"
        style="width: 80%"
        shape="round"
      >
        <ion-icon name="md-checkmark-circle-outline" class="mr-5"></ion-icon>
        Editar
      </ion-button>
    </div>
    <div class="center-element">
      <ion-button
        class="submit-btn"
        type="submit"
        margin-top
        [disabled]="!validForm()"
        *ngIf="!restriction_data.edit"
        style="width: 80%"
        shape="round"
      >
        <ion-icon name="md-checkmark-circle-outline" class="mr-5"></ion-icon>
        Agregar
      </ion-button>
    </div>
  </form>
</ion-content>
