import { OrderLine } from './OrderLine';

export class OrderHeader {
  public ordLine: OrderLine[] = [];
  public salesId: string = '';
  public deliveryMethod: string = 'Inteca';
  public addressId: number = 0;
  public deliveryDate: string = '';
  public aditionalInstruction: string =
    'Orden de Mercasa VIP, Verificar pago por tarjeta o transferencia antes de convertir a contrato';
  public customerId: string = '';
  public employId: string = 'HOEX00';
  public originSite: string = '';
  public deliveryRoute: string = '';
  public isProposal: number = 1;
  public totalSale: number = 0;
  public StorageId: string = '317';
  public Sitio: string = 'CTD';
  public OrigOrdNbr: string = '0';

  setAditionalInstruction(metodo: string) {
    this.aditionalInstruction =
      'Orden de Mercasa VIP, Verificar pago por ' +
      metodo +
      ' antes de convertir a contrato';
  }

  toXML() {
    let dateAux = new Date(this.deliveryDate);
    let date: string = new Date(
      dateAux.getTime() - dateAux.getTimezoneOffset() * 60000
    ).toISOString();
    let xml: string = '';
    xml += '<PSOrd class="entity">';
    xml +=
      '<PSOrdHeader class="entity">' +
      '<slsId>' +
      this.salesId +
      '</slsId>' +
      '<cstmrAccnt>' +
      this.customerId +
      '</cstmrAccnt>' +
      '<SlsAddrId>' +
      this.addressId +
      '</SlsAddrId>' +
      '<crtdDttme>' +
      new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      ).toISOString() +
      '</crtdDttme>' +
      '<OrigOrdNbr>' +
      this.OrigOrdNbr +
      '</OrigOrdNbr>' +
      '<emplyId>' +
      this.employId +
      '</emplyId>' +
      '<dlvryDte>' +
      date +
      '</dlvryDte>' +
      '<slsStts>1</slsStts>' + // SALES STATUS , RECOMENDADO SIEMPRE EN 1 (NO ES UN ENUM EN AX)
      '<shpTId>' +
      this.addressId +
      '</shpTId>' +
      '<ttlSls>' +
      this.totalSale +
      '</ttlSls>' + // no importa si es 0, AX lo calcula
      '<txAmnt>0</txAmnt>' + // no importa si es 0, AX lo calcula
      '<ttlDscnt>0</ttlDscnt>' + // no importa si es 0, AX lo calcula
      '<ttlFs>0</ttlFs>' + //siempre es 0
      '<ttlOrdr>' +
      this.totalSale +
      '</ttlOrdr>' + // no importa si es 0, AX lo calcula
      '<addtnlInstrctns>' +
      this.aditionalInstruction +
      '</addtnlInstrctns>' +
      '<prntd>0</prntd>' + // PRINTED
      '<delvryMthd>' +
      this.deliveryMethod +
      '</delvryMthd>' + //Metodo de entrega Inteca / RInteca
      '<orgnSte>' +
      this.originSite +
      '</orgnSte>' + //originSite del cliente o la dirección del cliente
      '<dlvryRte>' +
      this.deliveryRoute +
      '</dlvryRte>' + // Ruta de entrega
      '<ordrType>0</ordrType>' + // 0 PS , 1  EDI, 2 HOMEX , 3 MANUAL , 4 DEVOLUCIONES
      '<isPrpsl>' +
      this.isProposal +
      '</isPrpsl>' + // propuesta
      '<ordrModel>0</ordrModel>' + // 0 PRE VENTA , 1
      '<paymMethod>1</paymMethod>' + // 0 CREDITO 1 CONTADO
      '<streId>' +
      this.StorageId +
      '</streId>' + // Almacen del vendedor, se necesita un vendedor virtual que tenga su almacen propio
      '<steId>' +
      this.Sitio +
      '</steId>' + // sitio perteneciente al almacen de arriba
      '<plnDlvryDte>' +
      date +
      '</plnDlvryDte>' +
      '<InvoiceId>null</InvoiceId>';
    xml +=
      '<PSOrdHeaderCpny class="entity">' +
      '<SlsAddrId>' +
      this.addressId +
      '</SlsAddrId>' + //Dirección de toma del pedido
      '<slsId>' +
      this.salesId +
      '</slsId>' + //ID orden mobile
      '<dtArId>cmer</dtArId>' + //compañia
      '<ttlSls>' +
      this.totalSale +
      '</ttlSls>' + // no importa si es 0, AX lo calcula
      '<txAmnt>0</txAmnt>' + // no importa si es 0, AX lo calcula
      '<ttlDscnt>0</ttlDscnt>' + // no importa si es 0, AX lo calcula
      '<ttlFs>0</ttlFs>' + // siempre en 0
      '<ttlOrdr>' +
      this.totalSale +
      '</ttlOrdr>' + // no importa si es 0, AX lo calcula
      '<prntd>0</prntd>' + //Printed 0 !Printed 1
      '<shpTId>' +
      this.addressId +
      '</shpTId>' + //Dirección de entrega
      '<dlvryDte>' +
      date +
      '</dlvryDte>' + // Fecha de entrega
      '<plnDlvryDte>' +
      date +
      '</plnDlvryDte>' + //Dirección Planeada de entrega
      '<delvryMthd>' +
      this.deliveryMethod +
      '</delvryMthd>' + //Metodo de entrega Inteca / RInteca  , null en este nivel
      '<orgnSte>' +
      this.originSite +
      '</orgnSte>' + //originSite del cliente o la dirección del cliente
      '<dlvryRte>' +
      this.deliveryRoute +
      '</dlvryRte>' + // Ruta de entrega, vacio en este nivel
      '<addtnlInstrctns>null</addtnlInstrctns>' + // notas de la orden
      '<OrigOrdNbr>' +
      this.OrigOrdNbr +
      '</OrigOrdNbr>' + //numero de orden de compra(del cliente)
      '<paymMethod>1</paymMethod>' + // 0 CREDITO 1 CONTADO
      '<streId>' +
      this.StorageId +
      '</streId>' + // Almacen del vendedor, se necesita un vendedor virtual que tenga su almacen propio
      '<steId>' +
      this.Sitio +
      '</steId>' + // sitio perteneciente al almacen de arriba
      '<InvoiceId>null</InvoiceId>'; // solo para facturas de venta directa
    this.ordLine.forEach((line) => {
      let linea: OrderLine = new OrderLine();
      linea.itemId = line.itemId;
      linea.itemName = line.itemName;
      linea.lineId = line.lineId;
      linea.salesQuantity = line.salesQuantity;
      linea.salePrice = line.salePrice;
      linea.unitId = line.unitId;
      linea.promoSalesType = line.promoSalesType;
      linea.promoId = line.promoId;
      linea.linePercent = line.linePercent;
      linea.DeliveryDate = date;
      linea.taxAmount = line.taxAmount;
      let texto: string = linea.toXml(this.salesId);
      xml += texto;
    });
    xml += '</PSOrdHeaderCpny></PSOrdHeader></PSOrd>';

    return encodeURI(xml);
  }
}
