export class OmissionTask {
  selected: boolean;
  observation: string;
  position: number;
  constructor(public ID: string, private description: string) {
    this.selected = false;
  }

  getID() {
    return this.ID;
  }

  getDescription() {
    return this.description;
  }

  setID(newID: string) {
    this.ID = newID;
  }

  setDescription(newDescription: string) {
    this.description = newDescription;
  }

  toJSONLocal() {
    return {
      Selected: this.selected ? 1 : 0,
      Observation: this.observation,
      Position: this.position,
      ID: this.ID,
      Description: this.description,
    };
  }
}
