<app-nav-bar [title]="'Condiciones'"></app-nav-bar>

<ion-fab horizontal="end" vertical="top" (click)="displayInfoDialog()">
  <ion-fab-button
    color="gray"
    class="submit-btn"
    size="small"
    style="margin: 0px"
  >
    <i class="fas fa-question"></i>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-paddding" style="text-align: center">
  <ion-item lines="none">
    <ion-badge style="text-align: center; background: blue; margin: auto">
      <h6 style="color: aliceblue; font-weight: bold">1/2</h6>
    </ion-badge>
  </ion-item>

  <div class="flexColumn">
    <div
      style="font-size: 1rem; text-transform: lowercase; text-align: center"
      (click)="previewShoppingCar()"
    >
      <ion-icon color="primary" name="eye" style="font-size: 1rem"></ion-icon>
      <a style="text-transform: capitalize; text-decoration: underline"
        >VER CARRITO DE COMPRAS (SIN PROMOCIÓN)</a
      >
      <!-- ₡{{shoppingCarTotalAmount}} (Sin promoción actual) -->
      <!-- <ion-spinner *ngIf="loadingShoppingCar" style="margin-left: 0.2rem;" color="primary"></ion-spinner> -->
    </div>
    <div
      style="font-size: 1rem; text-transform: lowercase; text-align: justify"
    >
      <a style="text-transform: capitalize">PROMOCIÓN: </a> {{ promo.PromoId }}
    </div>
    <div
      style="font-size: 1rem; text-transform: lowercase; text-align: justify"
    >
      <a style="text-transform: capitalize">DESCRIPCIÓN: </a>
      {{ promo.Description }}
    </div>
  </div>

  <fieldset
    class="fieldsetContainer"
    *ngFor="let promoGroup of PromoConditionGroup"
  >
    <legend style="font-size: 1rem">
      <a>Grupo {{ promoGroup.GroupID }}</a>
    </legend>

    <div *ngFor="let pc of promoGroup.PromoCondition">
      <!-- ARTICULOS -->
      <fieldset *ngIf="pc.TypeCondition == 1 && pc.OperatorValue == 2">
        <legend style="font-size: 1rem">
          <a>Artículo</a>
        </legend>

        <!-- HEADER -->
        <div
          style="display: flex; padding-top: 5px"
          *ngIf="pc.LineRequired != -1"
        >
          <ion-icon color="danger" name="arrow-down"></ion-icon>
          <h6 color="danger" padding-left>Requiere Siguiente Línea</h6>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid black;
            padding-top: 5px;
          "
        >
          <div style="width: 10%" (click)="pc.showed = !pc.showed">
            <h6 text-wrap color="secondary">
              <ion-icon *ngIf="!pc.showed" ios="ios-add" md="md-add"></ion-icon>
              <ion-icon
                *ngIf="pc.showed"
                ios="ios-remove"
                md="md-remove"
              ></ion-icon>
              Ver
            </h6>
          </div>
          <div style="display: block; width: 25%">
            <h6 color="primary">Línea</h6>
            <h6>{{ pc.LineNum }}</h6>
          </div>
          <div style="display: block; width: 25%">
            <h6 color="primary">Producto</h6>
            <h6>{{ pc.Value }}</h6>
          </div>
          <div style="display: block; width: 25%">
            <h6 color="primary" *ngIf="pc.TypeValue == 1">Cantidad</h6>
            <h6 color="primary" *ngIf="pc.TypeValue == 2">Monto</h6>
            <h6 *ngIf="pc.Operator == 1">Igual A</h6>
            <h6 *ngIf="pc.Operator == 2">Mayor o igual que</h6>
            <h6 *ngIf="pc.Operator == 3">Menor o igual que</h6>
            <h6 *ngIf="pc.Operator == 4">Entre</h6>
            <div style="display: flex; justify-content: center">
              <h6>{{ pc.FromQty }}</h6>
              <h6 *ngIf="pc.Operator == 4">y {{ pc.ToQty }}</h6>
            </div>
          </div>
        </div>

        <!-- BODY Define Quantity -->
        <div *ngIf="pc.showed">
          <div
            style="
              display: block;
              align-items: center;
              justify-content: center;
              border-bottom: 1px dashed black;
              padding-top: 5px;
            "
          >
            <!-- Top Section -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0.5rem;
                font-size: 1rem;
              "
            >
              {{ pc.ItemName }}
            </div>

            <!-- Bottom Section -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              [ngClass]="{ itemDisabled: pc.Amount <= 0 }"
            >
              <div (click)="displayItemDetails(pc.Value)">
                <ion-icon
                  color="primary"
                  name="eye"
                  style="font-size: 20px"
                ></ion-icon>
              </div>
              <div
                style="text-align: center; width: 25%"
                (click)="displayItemDetails(pc.Value)"
              >
                <img
                  class="image-container"
                  alt="Imagen No Disponible"
                  src="https://serviceplan-api.mercasacr.com/images/Products/{{
                    pc.Value
                  }}_l_.PNG"
                  onerror="this.onerror=null;this.src='assets/img/error.png'"
                />
              </div>

              <div style="display: block; width: 25%">
                <h6 color="primary">Producto</h6>
                <h6>{{ pc.Value }}</h6>
              </div>

              <!-- Amount/Quantity section -->
              <div
                style="
                  display: block;
                  justify-content: center;
                  align-items: center;
                  width: 35%;
                "
              >
                <!-- UNIT Description Section and UNIT price -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <h6 *ngIf="pc.Amount > 0">
                    <a style="margin-right: 0.5rem">{{ pc.UnitIdValue }}</a
                    >₡{{ pc.Amount_Taxes }}
                  </h6>
                  <h6 *ngIf="pc.Amount <= 0">
                    <a style="margin-right: 0.5rem; color: brown"
                      >No Disponible</a
                    >
                  </h6>
                </div>
                <!-- Quantity Section -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <ion-button
                    ion-button
                    icon-only
                    clear
                    style="z-index: 1"
                    style="width: 30%"
                    (click)="reduceQuantity(pc)"
                  >
                    <i class="fas fa-minus-circle"></i>
                  </ion-button>
                  <div style="width: 40%; text-align: center">
                    <input
                      class="quantityClass"
                      type="number"
                      name="SaleQty"
                      id="SaleQty"
                      (keyup)="changeValueKeyUp($event, pc)"
                      [(ngModel)]="pc.SaleQty"
                      min="0"
                      step="1"
                    />
                  </div>
                  <ion-button
                    ion-button
                    icon-only
                    clear
                    style="width: 30%"
                    (click)="increaseQuantity(pc)"
                  >
                    <i class="fas fa-plus-circle"></i>
                  </ion-button>
                </div>
                <!-- Total Amount Section -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <h6 *ngIf="pc.Amount > 0">
                    <a style="margin-right: 1rem; text-transform: uppercase"
                      >Total:</a
                    >
                    ₡{{ pc.TotalSale }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <!-- ******************************************************************************************* -->
      <!-- LISTA DE ARTICULOS -->
      <fieldset *ngIf="pc.TypeCondition == 1 && pc.OperatorValue == 1">
        <legend style="font-size: 1rem">
          <a>Lista</a>
        </legend>

        <!-- Header -->
        <div
          style="display: flex; padding-top: 5px"
          *ngIf="pc.LineRequired != -1"
        >
          <ion-icon color="danger" name="arrow-down"></ion-icon>
          <h6 color="danger" padding-left>Requiere Siguiente Línea</h6>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid black;
            padding-top: 5px;
          "
        >
          <div style="width: 10%" (click)="pc.showed = !pc.showed">
            <h6 text-wrap color="secondary">
              <ion-icon *ngIf="!pc.showed" ios="ios-add" md="md-add"></ion-icon>
              <ion-icon
                *ngIf="pc.showed"
                ios="ios-remove"
                md="md-remove"
              ></ion-icon>
              Ver
            </h6>
          </div>
          <div style="display: block; width: 25%">
            <h6 color="primary">Línea</h6>
            <h6>{{ pc.LineNum }}</h6>
          </div>
          <div style="display: block; width: 25%">
            <h6 color="primary">Producto</h6>
            <h6>{{ pc.Value }}</h6>
          </div>
          <div style="display: none; width: 25%">
            <h6 color="primary">Lista</h6>
            <!-- Sin Definir -->
            <h6 *ngIf="pc.TypeList == 0">Artículos</h6>
            <div *ngIf="pc.TypeList == 1">
              <h6>Familia</h6>
              <h6>{{ pc.TypeListValue }}</h6>
            </div>
            <div *ngIf="pc.TypeList == 2">
              <h6>SubFamilia</h6>
              <h6>{{ pc.TypeListValue }}</h6>
            </div>
            <div *ngIf="pc.TypeList == 3">
              <h6>Categoría</h6>
              <h6>{{ pc.TypeListValue }}</h6>
            </div>
            <div *ngIf="pc.TypeList == 4">
              <h6>SubCategoría</h6>
              <h6>{{ pc.TypeListValue }}</h6>
            </div>
            <div *ngIf="pc.TypeList == 5">
              <h6>SubSubCategoría</h6>
              <h6>{{ pc.TypeListValue }}</h6>
            </div>
          </div>

          <div style="display: block; width: 25%">
            <h6 color="primary" *ngIf="pc.TypeValue == 1">Cantidad</h6>
            <h6 color="primary" *ngIf="pc.TypeValue == 2">Monto</h6>
            <h6 *ngIf="pc.Operator == 1">Igual A</h6>
            <h6 *ngIf="pc.Operator == 2">Mayor o igual que</h6>
            <h6 *ngIf="pc.Operator == 3">Menor o igual que</h6>
            <h6 *ngIf="pc.Operator == 4">Entre</h6>
            <div style="display: flex; justify-content: center">
              <h6>{{ pc.FromQty }}</h6>
              <h6 *ngIf="pc.Operator == 4">y {{ pc.ToQty }}</h6>
            </div>
          </div>

          <div style="display: block; width: 25%">
            <h6 color="primary">Al menos</h6>
            <h6>{{ pc.AtLeastQtyConditions }}</h6>
          </div>
        </div>

        <!-- BODY Define Quantity -->
        <div *ngIf="pc.showed">
          <div
            *ngFor="let condList of pc.PromoConditionsList"
            style="
              display: block;
              align-items: center;
              justify-content: center;
              border-bottom: 1px dashed black;
              padding-top: 5px;
            "
          >
            <!-- Top Section -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0.5rem;
                font-size: 1rem;
              "
            >
              {{ condList.ItemName }}
            </div>

            <!-- Bottom Section -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              [ngClass]="{ itemDisabled: condList.Amount <= 0 }"
            >
              <div (click)="displayItemDetails(condList.Value)">
                <ion-icon
                  color="primary"
                  name="eye"
                  style="font-size: 20px"
                ></ion-icon>
              </div>
              <div
                style="text-align: center; width: 25%"
                (click)="displayItemDetails(condList.Value)"
              >
                <img
                  class="image-container"
                  alt="Imagen No Disponible"
                  src="https://serviceplan-api.mercasacr.com/images/Products/{{
                    condList.Value
                  }}_l_.PNG"
                  onerror="this.src='assets/img/error.png'"
                />
              </div>

              <div style="display: block; width: 25%">
                <h6 color="primary">Producto</h6>
                <h6>{{ condList.Value }}</h6>
              </div>

              <!-- Amount/Quantity section -->
              <div
                style="
                  display: block;
                  justify-content: center;
                  align-items: center;
                  width: 35%;
                "
              >
                <!-- UNIT Description Section and UNIT price -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <h6 *ngIf="condList.Amount > 0">
                    <a style="margin-right: 0.5rem">{{ condList.UnitId }}</a
                    >₡{{ condList.Amount_Taxes }}
                  </h6>
                  <h6 *ngIf="condList.Amount <= 0">
                    <a style="margin-right: 0.5rem; color: brown"
                      >No Disponible</a
                    >
                  </h6>
                </div>
                <!-- Quantity Section -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <ion-button
                    style="z-index: 1"
                    style="width: 30%"
                    (click)="reduceQuantityItemList(pc, condList)"
                  >
                    <i class="fas fa-minus-circle"></i>
                  </ion-button>
                  <div style="width: 40%; text-align: center">
                    <input
                      class="quantityClass"
                      type="number"
                      name="SaleQtyItemList"
                      id="SaleQtyItemList"
                      (keyup)="changeValueKeyUpItemList($event, pc, condList)"
                      [(ngModel)]="condList.SaleQty"
                      min="0"
                      step="1"
                    />
                  </div>
                  <ion-button
                    style="width: 30%"
                    (click)="increaseQuantityItemList(pc, condList)"
                  >
                    <i class="fas fa-plus-circle"></i>
                  </ion-button>
                </div>
                <!-- Total Amount Section -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <h6 *ngIf="condList.Amount > 0">
                    <a style="margin-right: 1rem; text-transform: uppercase"
                      >Total:</a
                    >
                    ₡{{ condList.TotalSale }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </fieldset>
</ion-content>

<!-- Footer without a border -->
<ion-footer class="ion-no-border">
  <div class="flex-container">
    <ion-button
      class="borderRadius"
      style="margin-right: 3px; background-color: brown; color: white"
      (click)="cancelPromo()"
    >
      <ion-icon name="close-circle"></ion-icon>Cancelar
    </ion-button>

    <ion-button
      class="borderRadius"
      style="margin-left: 3px"
      (click)="applyPromo()"
    >
      Resultados
      <ion-icon name="eye"></ion-icon>
    </ion-button>
  </div>
</ion-footer>
