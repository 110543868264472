import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-screen',
  templateUrl: './logo-screen.component.html',
  styleUrls: ['./logo-screen.component.scss'],
})
export class LogoScreenComponent {
  text: string;

  constructor() {}
}
