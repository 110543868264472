export interface Parameter {
  tag: string;
  value: number | string | boolean | Parameter[];
}

export class ParametersBuilder {
  private parameters: Parameter[] = [];

  addParameter(tag: string, value: number | string | boolean) {
    this.parameters.push({ tag: tag, value: value });
    return this;
  }

  addParameters(tag: string, ...parameters: Parameter[]) {
    this.parameters.push({ tag: tag, value: parameters });
    return this;
  }

  buildParameters() {
    return this._buildParameters(this.parameters);
  }

  private _buildParameters(parameters: Parameter[]) {
    return parameters
      .map((param) => {
        let value: string | number | boolean;
        if (
          typeof param.value == 'number' ||
          typeof param.value == 'string' ||
          typeof param.value == 'boolean'
        ) {
          value = param.value;
        } else if (Array.isArray(param.value)) {
          value = this._buildParameters(param.value);
        }
        return `<${param.tag}>${value}</${param.tag}>`;
      })
      .join('');
  }
}
