export class PurposeAllowedByRole {
  Purpose: string = '';
  WeekNumber: number = 0;
  WeeklyAmountAllowed: number = 0;
  WeeklyAmountRemaining: number = 0;

  constructor(newPurposeAllowedByRole?: PurposeAllowedByRole) {
    this.Purpose = newPurposeAllowedByRole.Purpose;
    this.WeekNumber = newPurposeAllowedByRole.WeekNumber;
    this.WeeklyAmountAllowed = newPurposeAllowedByRole.WeeklyAmountAllowed;
    this.WeeklyAmountRemaining = newPurposeAllowedByRole.WeeklyAmountRemaining;
  }
}
