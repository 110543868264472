import { Component } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { DataArea } from '../../../businessCore/oc-models/DataArea';
import { ClientInfo } from '../../../businessCore/trip-models/ClientInfo';
import { FormTrip } from '../../../businessCore/trip-models/FormTrip';
import { InfoImage } from '../../../businessCore/trip-models/InfoImage';
import { Vehicle } from '../../../businessCore/trip-models/Vehicle';
import { ParadaModel } from '../../../businessCore/trip-models/parada';
import { TripModel } from '../../../businessCore/trip-models/trip';
import { LocalTripStorageProvider } from '../../../provider/Services/local-trip-storage';
import { TripParameterProvider } from '../../../provider/Services/trip-parameter';
import { AuthJWTService } from '../../../provider/auth-jwt/auth.jwt.service';
import { AuthenticationAuthGuardProvider } from '../../../providers/authentication-auth-guard/authentication-auth-guard';
import { SearchProviderComponent } from '../../oc-components/search-provider/search-provider.component';
import { InfoTripsPopComponent } from '../info-trips-pop/info-trips-pop.component';
import { InfoVehiclePopComponent } from '../info-vehicle-pop/info-vehicle-pop.component';
import { NotaTripComponent } from '../nota-trip/nota-trip.component';
import { ExternalServiceTripProvider } from './../../../provider/Services/external-service-trip';

@Component({
  selector: 'app-register-trip',
  templateUrl: './register-trip.component.html',
  styleUrls: ['./register-trip.component.scss'],
})
export class RegisterTripComponent {
  dataAreaList: DataArea[] = [];
  vehicleList: Vehicle[] = [];
  clientList: ClientInfo[] = [];
  maxDate: string = null;
  todayS: string = null;
  todayS2: string = null;
  todayEndS: string = null;
  todayEndS2: string = null;
  cantEdit: boolean;
  initRegisterShow: boolean = false;
  endRegisterShow: boolean = false;
  posSelected: string;
  posSelectedEnd: string;
  kiloInit: number = null;
  kiloEnd: number = null;
  canOriginB: boolean = false;
  canDestB: boolean = false;
  initButtonTrip: boolean = false;
  endButtonTrip: boolean = false;
  initTrip: boolean = false;
  endTrip: boolean = false;
  actualTrip: TripModel = null;
  imageDataInit: InfoImage = null;
  imageDataEnd: InfoImage = null;
  presentingMess: boolean = false;
  showOptionAdj: boolean = true;
  paraDataArea: string = '';
  loader: HTMLIonLoadingElement;
  cedulaUser: string;
  rolSpecific: string;
  instanceID: string;
  editing: boolean; //Para saber que el viaje esta editando la informacion y no la está creando
  setInitKilo: boolean;
  setEndKilo: boolean;
  showOptionClient: boolean = true;
  dateFromCheckInOut: boolean = false;
  optionSelectedK: number = 0;
  auth: number;
  private onPauseSubscription: Subscription;
  private onResumenSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private tripParameter: TripParameterProvider,
    private modalCtrl: ModalController,
    public alertController: AlertController,
    private loadingCtrl: LoadingController,
    private webService: ExternalServiceTripProvider,
    private popoverCtrl: PopoverController,
    private camera: Camera,
    private toastCtrl: ToastController,
    private localSto: LocalTripStorageProvider,
    private platform: Platform,
    public navParams: NavParams,
    public authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService,
    private navService: NavigationService
  ) {
    this.auth = 0;

    this.onPauseSubscription = this.platform.pause.subscribe(() => {
      //En el momento que cambie de app
      //Se ejecuta esta opcion
      this.pruSave();
    });

    this.onResumenSubscription = this.platform.resume.subscribe(() => {
      this.pruGetData();
    });
  }

  initData() {
    this.cantEdit = false;
    this.maxDate = '2100-01-01';
    const dateTmp = this.getDateToShow(new Date());
    this.todayS = dateTmp.toISOString();
    this.todayS2 = dateTmp.toISOString();
    this.posSelected = 'planeado';
    this.posSelectedEnd = 'planeado';
    this.setInitKilo = true;
  }

  async getParameters() {
    const info = window.location.search;
    const urlParams = new URLSearchParams(info);
    this.cedulaUser = this.navParams.get('userID'); // urlParams.get('userID');
    this.rolSpecific = this.navParams.get('userRole'); // urlParams.get('userRole');
    this.instanceID = this.navParams.get('instanceID'); // urlParams.get('instanceID');
    this.paraDataArea = this.navParams.get('dataArea'); // urlParams.get('dataArea');
    if (
      this.cedulaUser === null ||
      this.cedulaUser === undefined ||
      this.cedulaUser === ''
    ) {
      this.cedulaUser = await this.localSto.getCedulaActual(true); // Para MVIP
      //DEBUG
      //this.cedulaUser = '3-0478-0749'; // Para pruebas con apk sola
      //this.cedulaUser = '155819600701';
      //DEBUG
    }
    if (this.rolSpecific === null || this.rolSpecific === undefined) {
      this.rolSpecific = '';
    }
    if (this.instanceID === null || this.instanceID === undefined) {
      this.instanceID = '';
    }
    if (this.paraDataArea === null || this.paraDataArea === undefined) {
      this.paraDataArea = '';
    }
    this.showOptionAdj = !(Number(urlParams.get('showOp')) === 1);
    this.showOptionClient = !this.showOptionAdj;
    this.tripParameter.setParadaOP(this.showOptionAdj);
  }

  async ngOnInit() {
    this.auth = Number(this.navParams.get('auth'));
    let componentId = this.navParams.get('code');

    if (this.auth === 1) {
      //viene de giPlus?
      if (
        (await this.authGuard.checkToken()) &&
        this.authGuard.componentAllow(
          this.authService.getComponentsInfo(),
          componentId
        )
      ) {
        this.cedulaUser = this.authService.getTokenData('refresh');
      } else {
        window.location.href = 'http://giiis01:8025/';
      }
    } else {
      await this.getParameters();
    }

    this.initData();
    await this.getDataArea();
    if (this.rolSpecific !== '') {
      await this.getVehicules();
    }
    if (this.rolSpecific !== '') {
      await this.getClient();
    }
    this.canOrigin();
    this.actualTrip = new TripModel();
    // ---------------------------------------------
    if (
      this.cedulaUser === null ||
      this.cedulaUser === undefined ||
      this.cedulaUser === ''
    ) {
      this.messageInfo('Atención', 'Sin información de cédula de usuario.');
    } else {
      //Se verifica si hay un viaje pendiente
      this.setDataFromService();
    }
  }

  ionViewWillLeave() {}

  ngOnDestroy() {
    // always unsubscribe your subscriptions to prevent leaks
    this.onPauseSubscription.unsubscribe();
    this.onResumenSubscription.unsubscribe();
  }

  pruSave() {
    /*this.setStateForm();
    this.localSto.saveLastForm();*/
  }

  async pruGetData() {
    /*let info = await this.localSto.getLastForm();
     if(info) {
      console.log("Hay algo");
      this.presentToast("Hay algo");
    }*/
  }

  validateFirstStep(evalColor?: boolean) {
    //Abre o cierra el escoger cliente
    this.initRegisterShow = false;
    //Escoger cliente inicial
    this.canOriginB =
      this.actualTrip.getPuntoInit() === null ||
      this.actualTrip.getPuntoInit() === undefined ||
      this.actualTrip.getPuntoInit().getRecId() === null ||
      this.actualTrip.getPuntoInit().getRecId() === undefined;
    //Boton de empezar o actualizar cliente
    this.initButtonTrip =
      this.actualTrip.getPuntoInit() !== null &&
      this.actualTrip.getPuntoInit() !== undefined &&
      this.actualTrip.getPuntoInit().getKilometraje() === null;

    if (this.actualTrip.getTypeTrip() === 'INESPERADO') {
      this.posSelected = 'noPlaneado';
    } else {
      this.posSelected = 'planeado';
    }

    //Coloca el boton verde
    if (evalColor) {
      this.initTrip =
        this.actualTrip.getPuntoInit() !== null &&
        this.actualTrip.getPuntoInit() !== undefined &&
        this.actualTrip.getPuntoInit().getRecId() !== null &&
        this.actualTrip.getPuntoInit().getRecId() !== undefined &&
        this.actualTrip.getPuntoInit().getKilometraje() !== null;
      this.setInitKilo = !this.initTrip;
      //Aqui se evalula el segundo paso, ya que se evalula si se ejecuto el envio del primer punto
      this.validateSecondStep();
    }
  }

  validateSecondStep() {
    //Abre o cierra el escoger cliente
    this.endRegisterShow = false;

    //Escoger cliente final, si no hay cliente final
    this.canDestB =
      (this.initTrip &&
        (this.actualTrip.getPuntoFinal() === null ||
          this.actualTrip.getPuntoFinal() === undefined)) ||
      this.actualTrip.getPuntoFinal().getRecId() === null ||
      (this.actualTrip.getPuntoFinal().getRecId() === undefined &&
        (this.actualTrip.getPuntoFinal().getInitDate() === null ||
          this.actualTrip.getPuntoFinal().getInitDate() === undefined ||
          this.actualTrip.getPuntoFinal().getKilometraje() === null ||
          this.actualTrip.getPuntoFinal().getKilometraje() === undefined));

    //Boton de empezar o actualizar cliente final
    this.endButtonTrip =
      this.actualTrip.getPuntoFinal() !== null &&
      this.actualTrip.getPuntoFinal() !== undefined &&
      (this.actualTrip.getPuntoFinal().getInitDate() === null ||
        this.actualTrip.getPuntoFinal().getInitDate() === undefined ||
        this.actualTrip.getPuntoFinal().getKilometraje() === null ||
        this.actualTrip.getPuntoFinal().getKilometraje() === undefined);

    /**
     * Para viajes Planeados se debe poder activar este boton
     * */
    if (this.actualTrip.getTypeTrip() === 'PLANEADO') {
      //Si no hay punto final, habilita el boton
      //Si hay punto final, se deja el valor anterior
      const tmpEnd =
        this.actualTrip.getPuntoFinal() === null ||
        this.actualTrip.getPuntoFinal() === undefined;
      this.endButtonTrip = tmpEnd || this.endButtonTrip;
    }

    if (this.actualTrip.getTypeTrip() === 'INESPERADO') {
      this.posSelectedEnd = 'noPlaneado';
    } else {
      this.posSelectedEnd = 'planeado';
    }

    //Colocar el boton verde.
    //Además permite visualizar la parte final
    this.endTrip =
      this.actualTrip.getPuntoFinal() !== null &&
      this.actualTrip.getPuntoFinal() !== undefined &&
      this.actualTrip.getPuntoFinal().getInitDate() !== null &&
      this.actualTrip.getPuntoFinal().getInitDate() !== undefined &&
      this.actualTrip.getPuntoFinal().getKilometraje() !== null &&
      this.actualTrip.getPuntoFinal().getKilometraje() !== undefined;

    this.setEndKilo = !this.endTrip;
  }

  setDateInfo(initPoint: boolean) {
    if (this.actualTrip.getPuntoInit() && initPoint) {
      let fecha = this.getDateToShow(
        this.actualTrip.getPuntoInit().getInitDate(),
        false
      );
      let fecha2 = this.getDateToShow(
        this.actualTrip.getPuntoInit().getEndDate(),
        false
      );
      this.todayS = fecha ? fecha.toISOString() : '';
      this.todayS2 = fecha2 ? fecha2.toISOString() : '';
      this.kiloInit = this.actualTrip.resolveSetMile(
        this.actualTrip.getKilometrajeInicial()
      ); //Setea el valor de kilometro o milla
    }
    if (this.actualTrip.getPuntoFinal() && !initPoint) {
      let fecha = this.getDateToShow(
        this.actualTrip.getPuntoFinal().getEndDate(),
        false
      );
      let fechaIni = this.getDateToShow(
        this.actualTrip.getPuntoFinal().getInitDate(),
        false
      );
      console.log(fechaIni);
      if (this.dateFromCheckInOut) {
        let fecha2 = this.getDateToShow(
          this.actualTrip.getPuntoFinal().getEndDate(),
          undefined
        );
        let fecha3 = this.getDateToShow(
          this.actualTrip.getPuntoFinal().getInitDate(),
          undefined
        );
        this.todayEndS = fecha2 ? fecha2.toISOString() : '';
        this.todayEndS2 = fecha3 ? fecha3.toISOString() : '';
        this.dateFromCheckInOut = false;
      } else {
        this.todayEndS = fecha ? fecha.toISOString() : '';
        this.todayEndS2 = fechaIni ? fechaIni.toISOString() : '';
      }
      this.kiloEnd = this.actualTrip.resolveSetMile(
        this.actualTrip.getKilometrajeFinal()
      );
    }
  }

  async getInfoTripFromService(
    codigoViaje: string,
    dataAreiD: string,
    estado: string,
    cedula: string,
    instancia: string
  ) {
    let resp = null;
    await this.showSpinnerLoading('Trayendo datos del viaje...');
    try {
      // resp = await this.webService.getTripInfo('', this.paraDataArea, 'ABIERTO', this.cedulaUser, this.instanceID);
      resp = await this.webService.getTripInfo(
        codigoViaje,
        dataAreiD,
        estado,
        cedula,
        instancia
      );
      let tripListResp: TripModel[] = [];
      tripListResp = resp as TripModel[];
      if (tripListResp.length > 0) {
        this.actualTrip = tripListResp[0];
        this.actualTrip.setDataArea(this.actualTrip.getRealDataArea());
        this.optionSelectedK = this.actualTrip.getMile();
        // Se traen las paradas
        try {
          const respParadas = await this.webService.getTripPointInfo(
            this.actualTrip.getFMTrip(),
            this.actualTrip.getDataArea(),
            '',
            ''
          );
          this.actualTrip.setParadasInfo(respParadas as ParadaModel[]);
          this.setDateInfo(true);
          this.setDateInfo(false);
          this.validateFirstStep(true); //valida si el primer paso esta completo
        } catch (error) {}
        this.editing = true; //Para puntos inicio/fin y datos del viaje.
      } else {
        this.actualTrip = new TripModel();
        this.editing = false; //Para puntos inicio/fin y datos del viaje.
      }
      this.tripParameter.setTripParameter(this.actualTrip);
    } catch (error) {
      resp = error;
    }

    this.dissmissLoading();

    if (
      typeof resp === 'number' ||
      (typeof resp === 'string' && resp === 'timeOut')
    ) {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor para traer información del viaje, intentelo de nuevo.'
      );
    }
  }

  async setDataFromService() {
    // Hay una instancia y cedula cargada?
    // Es un viaje nuevo no se realiza nada
    // if (this.instanceID !== "") {
    await this.showSpinnerLoading(
      'Verificando viajes sin concluir para instancia.'
    );
    let resp = null;
    let resp2 = null;
    let tripAbiertoList: TripModel[] = [];
    let tripDetenidoList: TripModel[] = [];
    try {
      resp = await this.webService.getTripInfo(
        '',
        this.paraDataArea,
        'ABIERTO',
        this.cedulaUser,
        ''
      );
      resp2 = await this.webService.getTripInfo(
        '',
        this.paraDataArea,
        'DETENIDO',
        this.cedulaUser,
        ''
      );
      tripAbiertoList = resp as TripModel[];
      tripDetenidoList = resp2 as TripModel[];
    } catch (error) {
      resp = error;
    }

    this.dissmissLoading();

    if (
      typeof resp === 'number' ||
      (typeof resp === 'string' && resp === 'timeOut')
    ) {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor para traer información del viaje, intentelo de nuevo.'
      );
    } else {
      // No hubo error, abre un model para escoger el viaje.
      if (
        tripAbiertoList !== undefined &&
        tripAbiertoList !== null &&
        tripAbiertoList.length > 0
      ) {
        await this.openSelectTrip(
          tripAbiertoList,
          'Seleccione el viaje abierto: '
        );
      } else if (
        tripDetenidoList !== undefined &&
        tripDetenidoList !== null &&
        tripDetenidoList.length > 0
      ) {
        await this.openSelectTrip(
          tripDetenidoList,
          'Seleccione el viaje detenido: '
        );
      } else {
        this.actualTrip = new TripModel();
        this.editing = false; //Para puntos inicio/fin y datos del viaje
      }
    }
    // }
  }

  async openSelectTrip(listTrip: TripModel[], tituloS: string) {
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.popoverCtrl.create({
        component: InfoTripsPopComponent,
        componentProps: { objectsList: listTrip, titulo: tituloS },
        cssClass: 'info-trips-pop',
        backdropDismiss: false,
      });
      await modal.present();
      // Se espera las opciones a actualizar
      modal.onDidDismiss().then(async (res) => {
        let data = res.data;
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          // no es un dato null, no se cerro por error
          const { tripNum, instance } = data;
          await this.getInfoTripFromService(
            tripNum,
            this.paraDataArea,
            '',
            this.cedulaUser,
            instance
          );
        }
      });
    }
  }

  async getDataArea() {
    await this.showSpinnerLoading('Cargando datos de empresas...');
    let resp = null;
    try {
      resp = await this.webService.getDataArea();
    } catch (error) {
      resp = error;
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor para traer información de empresas, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor para traer información de empresas, intentelo de nuevo.'
      );
    } else {
      this.dataAreaList = resp as DataArea[];
      if (this.dataAreaList.length === 0) {
        this.messageInfo('Error', 'No hay datos de empresas.');
      }
    }
  }

  async getClient() {
    await this.showSpinnerLoading('Cargando datos de clientes del plan...');
    try {
      this.clientList = await this.webService.getClientTrip(
        this.rolSpecific,
        this.instanceID
      );
    } catch {
      this.messageInfo(
        'Error',
        'Sucedió un error al tratar de traer los clientes.'
      );
    }
    this.dissmissLoading();
  }

  async getVehicules() {
    await this.showSpinnerLoading('Cargando datos de vehiculos...');
    let resp = null;
    try {
      resp = await this.webService.getVehicule(
        this.rolSpecific,
        new Date('3/28/2019')
      );
    } catch (error) {
      resp = error;
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor para traer información de empresas, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor para traer información de empresas, intentelo de nuevo.'
      );
    } else {
      this.vehicleList = resp as Vehicle[];
      if (this.dataAreaList.length === 0) {
        this.messageInfo('Error', 'No hay datos de vehiculos.');
      }
    }
  }

  emitSelectedBar(type: number) {
    switch (type) {
      case 1:
        this.actualTrip.getFMTrip() === ''
          ? this.openSearchBarAux(this.dataAreaList, 'search-company', true)
          : this.messageInfo('Error', 'No se puede cambiar la compañia.');
        break;
      case 3:
        this.openSearchBarAux(this.clientList, 'search-client-init', false);
        break;
      case 4:
        this.openSearchBarAux(this.clientList, 'search-client-end', true);
        break;
      default:
        break;
    }
  }

  emitDays() {}

  openCloseInitRegister() {
    this.initRegisterShow = !this.initRegisterShow;
  }

  openCloseDest() {
    this.endRegisterShow = !this.endRegisterShow;
  }

  openGPSMap(op: number) {
    let titulo = '';
    if (op === 0) {
      titulo = 'Inicio';
    } else if (op === 2) {
      titulo = 'Destino';
    }

    this.navService.navigateTo(NavigationRoutes.MapTripPage, {
      tipo: op,
      titulo: titulo,
      move: true,
      callback: this.getPointCallback,
    });
  }

  getNumberCantidad(event) {
    this.kiloInit = event as number;
    this.initRegisterShow = false;
    this.canOrigin();
    this.setDataService();
    /* if (this.editing) {
      this.validateFirstStep();
    }*/
  }

  getNumberCantidad2(event) {
    this.kiloEnd = event as number;
    this.setDataService();
  }

  valitKiloAux() {
    let result = true;
    if (this.actualTrip !== null && this.actualTrip !== undefined) {
      result =
        this.actualTrip.getPuntoInit() === null ||
        this.actualTrip.getPuntoInit() === undefined ||
        this.actualTrip.getPuntoInit().getRecId() === null ||
        this.actualTrip.getPuntoInit().getRecId() === undefined;
    }
    return result;
  }

  canOrigin() {
    this.canOriginB =
      this.kiloInit !== undefined &&
      this.kiloInit !== null &&
      this.valitKiloAux()
        ? true
        : false;
  }

  async editPoint(
    paradaInfo: ParadaModel,
    mensajeSpinner: string,
    mensajeEnd: string,
    endPoint?: boolean
  ) {
    let resultado = false;
    await this.showSpinnerLoading(mensajeSpinner);
    let resp = null;
    try {
      const listInfo: any[] = [];
      listInfo.push(paradaInfo.ToJSON());
      resp = await this.webService.editPointTrip(
        JSON.stringify(listInfo),
        paradaInfo.getImageData64(),
        paradaInfo.getImageJSON()
      );
    } catch (error) {
      resp = error;
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puede guardar el punto de ' +
          mensajeEnd +
          ' , intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp.includes('SUCCESS')) {
      this.messageInfo(
        'Atención',
        'Punto de ' + mensajeEnd + ' actualizado correctamente.'
      );
      if (endPoint !== null && endPoint !== undefined && endPoint) {
        this.endButtonTrip = true;
      }
      if (paradaInfo.getTypeString() === 'INICIO') {
        // Se habilita el siguiete paso
        this.validateFirstStep(true);
      } else {
        this.validateSecondStep();
      }
      this.tripParameter.setTripParameter(this.actualTrip);
      resultado = true;
    }
    return resultado;
  }

  async auxUpdateTripInfo() {
    if (this.validateAdjInit()) {
      //Se llama al servicio para crear el viaje en AX
      let resp = null;
      await this.showSpinnerLoading('Actualizando viaje...');
      try {
        //Se setean los datos
        this.setDataService();
        const listInfo: any[] = [];
        listInfo.push(this.actualTrip.toJSON());
        resp = await this.webService.ediTrip(JSON.stringify(listInfo));
      } catch (error) {
        resp = error;
      }
      this.dissmissLoading();
      if (typeof resp === 'number') {
        this.messageInfo(
          'Error',
          'No se puede comunicar con el servidor, intentelo de nuevo.'
        );
      } else if (typeof resp === 'string' && resp === 'timeOut') {
        this.messageInfo(
          'Error',
          'No se puede comunicar con el servidor, intentelo de nuevo.'
        );
      } else if (typeof resp === 'string' && resp === 'FAILED') {
        this.messageInfo(
          'Error',
          'No se puede actualizar el viaje, intentelo de nuevo.'
        );
      } else if (typeof resp === 'string' && resp === 'SUCCESS') {
        // Si esta editando pero el punto inicial no ha sido creado
        if (this.actualTrip.getPuntoInit().getRecId() === null) {
          this.addTripPointInit();
        } else {
          this.editPoint(
            this.actualTrip.getPuntoInit(),
            'Actualizando punto de inicio',
            'inicio'
          );
        }
      }
    } else {
      this.messageInfo(
        'Error',
        'Se necesita la foto del kilometraje, para crear el viaje.'
      );
    }
  }

  updateTripInfo() {
    if (
      this.actualTrip.getKilometrajeInicial() !== null &&
      this.actualTrip.getKilometrajeInicial() !== undefined
    ) {
      if (this.actualTrip.getVehicule().getTypeProperty() !== 0) {
        this.actualTrip.setFecha(this.getDateString(this.todayS));
        this.actualTrip.setFecha2(this.getDateString(this.todayS2));
        if (
          this.actualTrip.getPuntoInit() !== null &&
          this.actualTrip.getPuntoInit() !== undefined
        ) {
          this.auxUpdateTripInfo();
        } else {
          this.messageInfo(
            'Error',
            'Seleccione el punto de inicio para el viaje.'
          );
        }
      } else {
        this.messageInfo(
          'Error',
          'Ingrese la información del transporte del viaje.'
        );
      }
    } else {
      this.messageInfo('Error', 'Ingrese el kilometraje inicial del viaje.');
    }
  }

  async saveTrip() {
    if (this.actualTrip.getFMTrip() === '') {
      //Se setea el tipo del viaje
      this.posSelected === 'noPlaneado'
        ? this.actualTrip.setTypeTrip('INESPERADO')
        : this.actualTrip.setTypeTrip('PLANEADO');
      if (this.actualTrip.getDataArea() !== '') {
        if (this.actualTrip.getVehicule().getTypeProperty() !== 0) {
          if (
            this.actualTrip.getKilometrajeInicial() !== null &&
            this.actualTrip.getKilometrajeInicial() !== undefined
          ) {
            if (this.actualTrip.getTypeTrip() === 'INESPERADO') {
              //Se setea la descripción del viaje...
              this.messageInfoObservation();
            } else {
              this.actualTrip.setInstance(this.instanceID);
              this.actualTrip.setDescription(
                'Viaje Asociado a plan de servicio (' +
                  this.rolSpecific +
                  ') instancia: ' +
                  this.instanceID
              );
              //Al ser uno planeado se seta otra nota
              this.saveTrip_Aux();
            }
          } else {
            this.messageInfo(
              'Error',
              'Ingrese el kilometraje inicial del viaje.'
            );
          }
        } else {
          this.messageInfo(
            'Error',
            'Ingrese la información del transporte del viaje.'
          );
        }
      } else {
        this.messageInfo(
          'Error',
          'Seleccione una compañía para crear el viaje.'
        );
      }
    } else {
      this.messageInfo(
        'Error',
        'El viaje ya ha sido creado, no se puede crear de nuevo.'
      );
    }
  }

  validateAdjInit() {
    let resultado = true;
    if (
      (this.imageDataInit === null || this.imageDataInit === undefined) &&
      this.showOptionAdj
    ) {
      //Puede que el punto ya sea uno guardado.
      if (
        (this.actualTrip.getPuntoInit().getImageData() === null ||
          this.actualTrip.getPuntoInit().getImageData() === undefined) &&
        this.actualTrip.getPuntoInit().getRecId() === null
      ) {
        resultado = false;
      }
    }
    //DEBUG
    //return true;
    //DEBUG
    return resultado;
  }

  async saveTrip_Aux() {
    if (this.actualTrip.getFMTrip() === '') {
      if (this.validateAdjInit()) {
        //Se llama al servicio para crear el viaje en AX
        let resp = null;
        await this.showSpinnerLoading('Creando viaje...');
        try {
          //Se setean los datos
          this.setDataService();
          const listInfo: any[] = [];
          listInfo.push(this.actualTrip.toJSON());
          resp = await this.webService.createTrip(JSON.stringify(listInfo));
        } catch (error) {
          resp = error;
        }
        //Si el contador de sussccess es == a las respuestas
        this.dissmissLoading();
        if (typeof resp === 'number') {
          this.messageInfo(
            'Error',
            'No se puede comunicar con el servidor, intentelo de nuevo.'
          );
        } else if (typeof resp === 'string' && resp === 'timeOut') {
          this.messageInfo(
            'Error',
            'No se puede comunicar con el servidor, intentelo de nuevo.'
          );
        } else if (typeof resp === 'string' && resp === 'FAILED') {
          this.messageInfo(
            'Error',
            'No se puede crear el viaje, intentelo de nuevo.'
          );
        } else if (
          typeof resp === 'string' &&
          resp.toUpperCase().includes('TRP_')
        ) {
          //Se crea el punto inicial, en AX (Ya que se ocupa que el viaje esté creado)
          this.actualTrip.setFMTrip(resp as string);
          this.actualTrip.setStateTrip('ABIERTO');
          this.addTripPointInit();
          //Se habilita el siguiete paso
          this.initTrip = true;
          this.canDestB = true;
          this.endRegisterShow = false;
        }
      } else {
        this.messageInfo(
          'Error',
          'Se necesita la foto del kilometraje, para crear el viaje.'
        );
      }
    } else {
      this.messageInfo(
        'Error',
        'El viaje ya ha sido creado, no se puede crear de nuevo.'
      );
    }
  }

  addTripPointInit() {
    if (
      this.actualTrip.getPuntoInit() !== null &&
      this.actualTrip.getPuntoInit() !== undefined
    ) {
      this.actualTrip.getPuntoInit().setFMTrips(this.actualTrip.getFMTrip());
      this.actualTrip.getPuntoInit().setDataArea(this.actualTrip.getDataArea());
      this.actualTrip
        .getPuntoInit()
        .setKilometraje(this.actualTrip.getKilometrajeInicial());
      this.actualTrip.getPuntoInit().setInitDate(this.actualTrip.getFecha());

      this.sendTripPoint(
        this.actualTrip.getPuntoInit(),
        'Guardando punto de inicio',
        'inicio'
      );
    } else {
      this.messageInfo(
        'Error',
        'No hay datos de punto de inicio, intentelo de nuevo.'
      );
    }
  }

  addTripPointEnd() {
    if (
      this.actualTrip.getPuntoFinal() !== null &&
      this.actualTrip.getPuntoFinal() !== undefined
    ) {
      this.actualTrip.getPuntoFinal().setFMTrips(this.actualTrip.getFMTrip());
      this.actualTrip
        .getPuntoFinal()
        .setDataArea(this.actualTrip.getDataArea());
      this.actualTrip
        .getPuntoFinal()
        .setKilometraje(this.actualTrip.getKilometrajeFinal());
      // this.actualTrip.getPuntoFinal().setEndDate(this.actualTrip.getHoraFin());
      //Se insertar
      this.sendTripPoint(
        this.actualTrip.getPuntoFinal(),
        'Guardando punto de fin',
        'fin',
        true
      );
    } else {
      this.messageInfo(
        'Error',
        'No hay datos de punto de fin, intentelo de nuevo.'
      );
    }
  }

  async sendTripPoint(
    paradaInfo: ParadaModel,
    mensajeSpinner: string,
    mensajeEnd: string,
    endPoint?: boolean
  ) {
    await this.showSpinnerLoading(mensajeSpinner);
    let resp = null;
    try {
      const listInfo: any[] = [];
      listInfo.push(paradaInfo.ToJSON());
      resp = await this.webService.createTripPoint(
        JSON.stringify(listInfo),
        paradaInfo.getImageData64(),
        paradaInfo.getImageJSON()
      );
    } catch (error) {
      resp = error;
      console.log(error);
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puede guardar el punto de ' +
          mensajeEnd +
          ' , intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp.includes('SUCCESS')) {
      this.messageInfo(
        'Atención',
        'Punto de ' + mensajeEnd + ' almacenado correctamente.'
      );
      const arraySplit = resp.split('-');
      if (arraySplit[1] !== '') {
        paradaInfo.setRecId(Number(arraySplit[1]));

        /*if (endPoint === null || endPoint === undefined || !endPoint) {
          try {
            const listInfo: any[] = [];
            listInfo.push(paradaInfo.ToJSON());
            this.webService.editPointTrip(JSON.stringify(listInfo), '', '');
          } catch (error) {
            console.log(error);
          }
        }*/
      }

      if (endPoint !== null && endPoint !== undefined && endPoint) {
        this.endButtonTrip = true;
      }
      if (paradaInfo.getTypeString() === 'INICIO') {
        this.canOriginB = false;
      }

      if (paradaInfo.getTypeString() === 'INICIO') {
        // Se habilita el siguiete paso
        this.validateFirstStep(true);
      } else {
        this.setDateInfo(false);
        this.validateSecondStep();
      }

      this.tripParameter.setTripParameter(this.actualTrip);
    }
  }

  openCreateEditTrip() {
    if (this.editing) {
      this.openMessage(3);
    } else {
      this.openMessage(1);
    }
  }

  openMessage(opcion: number, setNull?: boolean) {
    switch (opcion) {
      case 1:
        this.messageInfo('Atención', '¿ Desea crear el viaje ?', opcion);
        break;
      case 2:
        this.messageInfo(
          'Atención',
          '¿ Desea guardar los datos de punto de fin ?',
          opcion,
          setNull
        );
        break;
      case 3:
        this.messageInfo(
          'Atención',
          '¿ Desea actualizar los datos del viaje ?',
          opcion
        );
        break;
      default:
        break;
    }
  }

  setDataService() {
    this.actualTrip.setMile(this.optionSelectedK);
    this.actualTrip.setKilometrajeInicial(this.kiloInit);
    let dayIni = this.getDateString(this.todayS);
    let dayIni2 = this.getDateString(this.todayS2);
    this.actualTrip.setFecha(dayIni);
    this.actualTrip.setFecha2(dayIni2);
    this.actualTrip.setKilometrajeFinal(this.kiloEnd);
    let dayFin = this.getDateString(this.todayEndS);
    let dayFin2 = this.getDateString(this.todayEndS2);
    this.actualTrip.setHoraFin(dayFin);
    this.actualTrip.setHoraFin2(dayFin2);
    this.actualTrip.setExecutorId(this.cedulaUser);

    this.tripParameter.setTripParameter(this.actualTrip);
  }

  async tripWhitStopEnd() {
    const tmpPuntoFinal = this.actualTrip.getListParada().pop();
    tmpPuntoFinal.setType(2); //FINAL
    //Seta la ultima parada realizada como final...
    this.actualTrip.setPuntoFinal(tmpPuntoFinal);
    //Se cambia como punto final en AX...
    const resp = await this.editPoint(
      this.actualTrip.getPuntoFinal(),
      'Actualizando punto de fin',
      'fin'
    );
    if (!resp) {
      // Se vuelve el estado anterior
      tmpPuntoFinal.setType(1); // Vuelve a ser parada
      this.actualTrip.getListParada().push(tmpPuntoFinal);
      this.actualTrip.setPuntoFinal(null); //Setea el punto final como null
    }
    //Se setea la infode las fechas y kilometraje
    this.setDateInfo(false);
    //Se valida el segundo pasa
    this.validateSecondStep();
  }

  async tripWhitOutStopEnd() {
    //Basicamente es una copia del punto final
    const tmpPuntoFinal = this.actualTrip.getPuntoInit().copyParadaModel();
    tmpPuntoFinal.setRecId(null); //Es una nueva parada
    const customerId =
      tmpPuntoFinal.getPartyNumber() + tmpPuntoFinal.getAddressID();
    await this.showSpinnerLoading('Consultando datos de checkIn y checkOut');
    const resp = await this.webService.getCheckInOut(
      this.rolSpecific,
      this.instanceID,
      customerId
    );
    this.dissmissLoading();

    if (resp.length > 0) {
      if (resp[1] === null || resp[0] === null) {
        this.messageInfo(
          'Atención',
          `No hay datos de checkIn/checkOut, no se puede realizar esta acción.`
        );
      } else {
        tmpPuntoFinal.setType(2); //FINAL
        // const fechaI = resp[0]; // this.getDateToShow(resp[0]);
        // const sFechaI = (fechaI) ? fechaI.toISOString() : '';
        tmpPuntoFinal.setInitDate(resp[0]); // this.getDateString(sFechaI)); // checkIn
        // const fechaF = this.getDateToShow(resp[1]);
        // const sFechaF = (fechaF) ? fechaF.toISOString() : '';
        tmpPuntoFinal.setEndDate(resp[1]); // this.getDateString(sFechaF)); // checkOut
        this.actualTrip.setPuntoFinal(tmpPuntoFinal);
        this.dateFromCheckInOut = true;
        // Se guarda el destino
        this.openMessage(2, true);
      }
    } else {
      // Pasó algo y no hay datos
      this.messageInfo(
        'Atención',
        `No se pudo consultar los datos de checkIn/checkOut de la parada inicial.`
      );
    }
  }

  async saveTripWithoutLastStop() {
    if (this.cantDoIt()) {
      if (
        this.actualTrip.getPuntoFinal() === null ||
        this.actualTrip.getPuntoFinal() === undefined
      ) {
        // Se revisa si tiene paradas realizadas (Caso facil)
        if (this.actualTrip.getListParada().length > 0) {
          await this.tripWhitStopEnd();
        } else {
          //Se debe buscar la informacion del checkOut de la parada inicial
          await this.tripWhitOutStopEnd();
        }
      }
    } else {
      this.messageInfo(
        'Atención',
        `El viaje ha sido ` +
          this.actualTrip.getStateTrip().toUpperCase() +
          `, no se puede realizar esta opción.`
      );
    }
  }

  setNullEndPoint() {
    if (this.actualTrip !== null && this.actualTrip !== undefined) {
      this.actualTrip.setPuntoFinal(null); //Setea el punto final como null
      this.setDateInfo(false); //Se setea la infode las fechas y kilometraje
      //Se valida el segundo pasa
      this.validateSecondStep();
    }
  }

  async endTripSave() {
    // Hora
    let dateTmp = this.getDateToShow(new Date());
    if (this.todayEndS === null || this.todayEndS === '') {
      // Se setea l fecha final, solo si es inesperado y todavia no tiene fecha final.
      if (this.actualTrip.getTypeTrip() === 'INESPERADO') {
        this.todayEndS = dateTmp.toISOString();
        this.todayEndS2 = dateTmp.toISOString();
      } else {
        // Para un viaje planeado se setea a partir de la informacion del ultimo checkOut,
        // si no se ha escogido aun un destino...
        await this.saveTripWithoutLastStop();
      }
    }
    this.validateSecondStep();
    this.setDataService();
    this.endTrip = true;
  }

  getDateToShow(date?: Date, res?: boolean) {
    let dateTmp = null; // new Date();
    if (date !== null && date !== undefined) {
      dateTmp = new Date(date);
      // dateTmp.setDate(dateTmp.getDate() - 1);
      if (res === undefined) {
        dateTmp.setHours(dateTmp.getHours() - 6);
      }
    }
    return dateTmp;
  }

  getDateString(dateInfo: string) {
    let dayInfoReal = null;
    if (dateInfo !== null && dateInfo !== undefined && dateInfo !== '') {
      dayInfoReal = new Date(dateInfo);
      // dayInfoReal.setDate(dayInfoReal.getDate() + 1);
      dayInfoReal.setHours(dayInfoReal.getHours() + 6);
    } else {
      dayInfoReal = new Date(null); //Retorna una fecha por defecto.
    }

    return dayInfoReal;
  }

  getStyle() {
    return this.initTrip ? 'executed' : 'noExecuted';
  }

  getStyle2() {
    return this.endTrip ? 'executed' : 'noExecuted';
  }

  setInitDataPoint(data: ParadaModel) {
    if (this.cantDoIt()) {
      this.posSelected === 'noPlaneado'
        ? data.setTypeCreation('INESPERADO')
        : data.setTypeCreation('PLANEADO');
      //Se coloca la informacion de la image
      if (this.imageDataInit !== null && this.imageDataInit !== undefined) {
        data.setImageData(this.imageDataInit);
        this.presentToast('Imagen de kilometraje inicial almacenada.');
      }
      //Se seta la informacion del punto al viaje
      this.actualTrip.setPuntoInit(data);

      this.actualTrip.setKilometrajeInicial(this.kiloInit);
      // this.canOriginB = false;
      this.initRegisterShow = false;
      this.initButtonTrip = true;
    } else {
      this.messageInfo(
        'Atención',
        `El viaje ha sido ` +
          this.actualTrip.getStateTrip().toUpperCase() +
          `, no se puede realizar esta opción.`
      );
    }
  }

  cantDoIt() {
    return (
      this.actualTrip.getStateTrip().toUpperCase() !== 'CONCLUIDO' &&
      this.actualTrip.getStateTrip().toUpperCase() !== 'CANCELADO' &&
      this.actualTrip.getStateTrip().toUpperCase() !== 'INTERRUMPIDO'
    );
  }

  setEndDataPoint(data: ParadaModel) {
    if (this.cantDoIt()) {
      this.posSelectedEnd === 'noPlaneado'
        ? data.setTypeCreation('INESPERADO')
        : data.setTypeCreation('PLANEADO');
      this.actualTrip.setPuntoFinal(data);
      //Se guarda el destino
      this.openMessage(2, true);
    } else {
      this.messageInfo(
        'Atención',
        `El viaje ha sido ` +
          this.actualTrip.getStateTrip().toUpperCase() +
          `, no se puede realizar esta opción.`
      );
    }
  }

  // callback...
  getPointCallback = (data: ParadaModel) => {
    if (data !== null && data !== undefined) {
      switch (data.getType()) {
        case 0:
          this.setInitDataPoint(data);
          break;
        case 2:
          this.setEndDataPoint(data);
          break;
        default:
          break;
      }
    }
  };

  async openSearchBarAux(
    listInfo: any[],
    typeSearch: string,
    cantEdit: boolean
  ) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
        canEdit: cantEdit,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.setDataSelected(data.objSelected, typeSearch);
        }
      }
    });
  }

  setDataAreaSelect(dataAreaID: string) {
    if (this.actualTrip.getFMTrip() !== '') {
      this.messageInfo(
        'Error',
        'El viaje ya ha sido creado, no se puede editar la compañía.'
      );
    } else {
      console.log('Comapañía: ', dataAreaID);
      this.actualTrip.setDataArea(dataAreaID);
    }
  }

  setDataSelected(objSelected: any, typeSearch: string) {
    switch (typeSearch) {
      case 'search-company':
        this.setDataAreaSelect(objSelected.getId());
        break;
      case 'search-client-init':
        this.setDataPointPlaneado(objSelected as ClientInfo, 0);
        break;
      case 'search-client-end':
        this.setDataPointPlaneado(objSelected as ClientInfo, 2);
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param dataInfo
   * @param type 0 Inicio 2 Fin
   */
  setDataPointPlaneado(dataInfo: ClientInfo, type: number) {
    const tmpData = new ParadaModel();
    tmpData.setType(type);
    tmpData.setLatitud(dataInfo.getLatitud());
    tmpData.setLongitud(dataInfo.getLongitud());
    tmpData.setPartyNumber(dataInfo.getId());
    tmpData.setPartyName(dataInfo.getName());
    tmpData.setAddressID(dataInfo.getAddressId());
    if (type === 0) {
      this.setInitDataPoint(tmpData);
    } else if (type === 2) {
      this.setEndDataPoint(tmpData);
    }
  }

  executeAction(type: number) {
    switch (type) {
      case 1:
        this.saveTrip();
        break;
      case 2:
        this.addTripPointEnd();
        break;
      case 3:
        this.updateTripInfo();
        break;
      default:
        break;
    }
  }

  async messageInfo(
    titulo: string,
    mensaje: string,
    action?: number,
    setNull?: boolean
  ) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action > 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.executeAction(action);
          },
        },
        {
          text: 'NO',
          handler: () => {
            if (action === 2 && setNull) {
              this.setNullEndPoint();
            }
          },
        },
      ];
    } else if (action && action === -1) {
      botones = [
        {
          text: 'ACEPTAR',
          handler: () => {
            this.navCtrl.pop();
          },
        },
      ];
    }
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  //Llama la función de pedir observacion
  async messageInfoObservation() {
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.popoverCtrl.create({
        component: NotaTripComponent,
        componentProps: { descrip: this.actualTrip.getDescription() },
        cssClass: 'nota-trip',
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((res) => {
        let data = res.data;
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          this.actualTrip.setDescription(data as string);
          this.saveTrip_Aux();
        } else {
          this.saveTrip_Aux();
        }
      });
    }
  }

  openCamera(type: number) {
    //Kilometraje inicial o final

    const options: CameraOptions = {
      quality: 10, // 100,
      destinationType: this.camera.DestinationType.DATA_URL, // FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA, // PHOTOLIBRARY // CAMERA // PHOTOLIBRARY
    };

    this.camera.getPicture(options).then(
      (imageData) => {
        // imageData is either a base64 encoded string or a file URI
        // If it's base64 (DATA_URL):
        let base64Image = 'data:image/jpeg;base64,' + imageData;
        const imgData = new InfoImage();
        imgData.dataImage = base64Image.substring(
          base64Image.lastIndexOf(',') + 1
        );
        imgData.extension = 'jpeg';
        imgData.action = 5;
        imgData.type = 4;
        imgData.company = this.actualTrip.getDataArea();
        switch (type) {
          case 1:
            this.imageDataInit = imgData;
            if (
              this.actualTrip.getPuntoInit() !== null &&
              this.actualTrip.getPuntoInit() !== undefined
            ) {
              this.actualTrip.getPuntoInit().setImageData(this.imageDataInit);
            }
            this.presentToast('Imagen de kilometraje inicial almacenada.');
            break;
          case 2:
            this.imageDataEnd = imgData;
            this.actualTrip.getPuntoFinal().setImageData(this.imageDataEnd);
            this.presentToast('Imagen de kilometraje final almacenada.');
            break;
          default:
            break;
        }
      },
      (err) => {
        // Handle error
        console.log(err);
      }
    );
  }

  showNameOrigen() {
    let result = false;
    if (this.posSelected === 'planeado') {
      if (
        this.actualTrip !== null &&
        this.actualTrip !== undefined &&
        this.actualTrip.getPuntoInit() !== null &&
        this.actualTrip.getPuntoInit() !== undefined &&
        this.actualTrip.getPuntoInit().getTypeCreation().toUpperCase() ===
          'PLANEADO'
      ) {
        result = true;
      }
    }
    return result;
  }

  showDescripOrig() {
    let result = false;
    if (this.posSelected === 'noPlaneado') {
      if (
        this.actualTrip !== null &&
        this.actualTrip !== undefined &&
        this.actualTrip.getPuntoInit() !== null &&
        this.actualTrip.getPuntoInit() !== undefined &&
        this.actualTrip.getPuntoInit().getTypeCreation().toUpperCase() ===
          'INESPERADO'
      ) {
        result = true;
      }
    }
    return result;
  }

  showNameFin() {
    let result = false;
    if (this.posSelectedEnd === 'planeado') {
      if (
        this.actualTrip !== null &&
        this.actualTrip !== undefined &&
        this.actualTrip.getPuntoFinal() !== null &&
        this.actualTrip.getPuntoFinal() !== undefined &&
        this.actualTrip.getPuntoFinal().getTypeCreation().toUpperCase() ===
          'PLANEADO'
      ) {
        result = true;
      }
    }
    return result;
  }

  showDescripFin() {
    let result = false;
    if (this.posSelectedEnd === 'noPlaneado') {
      if (
        this.actualTrip !== null &&
        this.actualTrip !== undefined &&
        this.actualTrip.getPuntoFinal() !== null &&
        this.actualTrip.getPuntoFinal() !== undefined &&
        this.actualTrip.getPuntoFinal().getTypeCreation().toUpperCase() ===
          'INESPERADO'
      ) {
        result = true;
      }
    }
    return result;
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 4000,
    });
    toast.present();
  }

  getClickImage(type: number) {
    let resultado: string = 'fas fa-paperclip noImageClick';
    if (this.actualTrip !== null && this.actualTrip !== undefined) {
      let change = false;
      if (type === 1) {
        // Inicio
        if (
          this.actualTrip.getPuntoInit() !== null &&
          this.actualTrip.getPuntoInit() !== undefined
        ) {
          change = this.actualTrip.getPuntoInit().getHaveImageSaved();
        }
      } else {
        //Fin
        if (
          this.actualTrip.getPuntoFinal() !== null &&
          this.actualTrip.getPuntoFinal() !== undefined
        ) {
          change = this.actualTrip.getPuntoFinal().getHaveImageSaved();
        }
      }
      if (change) {
        resultado = 'fas fa-check imageClick'; // 'fas fa-paperclip imageClick';
      }
    }
    return resultado;
  }

  async getInfoVehicule() {
    const modal = await this.modalCtrl.create({
      component: InfoVehiclePopComponent,
      componentProps: {
        objInf: this.actualTrip.getVehicule(),
        canEdit: true,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        const { objInf } = data;
        this.actualTrip.setVehicule(objInf as Vehicle);
      }
    });
  }

  /**
   * Setea el estado de los objetos del viaje que se esta haciendo de manera actual
   */
  setStateForm() {
    // Datos actuales
    let actualForm: FormTrip = {
      MaxDate: this.maxDate,
      TodayS: this.todayS,
      TodayEndS: this.todayEndS,
      CantEdit: this.cantEdit,
      InitRegisterShow: this.initRegisterShow,
      EndRegisterShow: this.endRegisterShow,
      PosSelected: this.posSelected,
      PosSelectedEnd: this.posSelectedEnd,
      KiloInit: this.kiloInit,
      KiloEnd: this.kiloEnd,
      CanOriginB: this.canOriginB,
      CanDestB: this.canDestB,
      InitButtonTrip: this.initButtonTrip,
      EndButtonTrip: this.endButtonTrip,
      InitTrip: this.initTrip,
      EndTrip: this.endTrip,
      PresentingMess: this.presentingMess,
      ShowOptionAdj: this.showOptionAdj,
      ParaDataArea: this.paraDataArea,
      CedulaUser: this.cedulaUser,
      RolSpecific: this.rolSpecific,
      InstanceID: this.instanceID,
      Editing: this.editing,
      SetInitKilo: this.setInitKilo,
      SetEndKilo: this.setEndKilo,
      ShowOptionClient: this.showOptionClient,
      DateFromCheckInOut: this.dateFromCheckInOut,
      FechaLocal: null,
      DataAreaList: this.dataAreaList,
      VehicleList: this.vehicleList,
      ClientList: this.clientList,
      ActualTrip: this.actualTrip
        ? this.actualTrip.toJSONLocal()
        : this.actualTrip,
      ImageDataInit: this.imageDataInit,
      ImageDataEnd: this.imageDataEnd,
    };

    this.localSto.setFormData(actualForm);
  }
}
