<app-nav-bar [title]="'Facturación'"></app-nav-bar>

<ion-content lines="none">
  <div class="box">
    <ion-item lines="none" class="barTitle" (click)="openSearchBarDataAreaId()">
      <ion-label>
        <h2 class="h2Select">Empresa</h2>
        <h3>
          {{ empresaSelected ? empresaSelected.getId() : "Sin Seleccionar" }}
        </h3>
        <p>{{ empresaSelected ? empresaSelected.getName() : "Sin Datos" }}</p>
      </ion-label>
      <ion-icon name="caret-down-outline" style="color: white"></ion-icon>
    </ion-item>

    <!--Filtros de OC-->
    <ion-searchbar
      type="text"
      placeholder="Buscar"
      (ionChange)="searchOC($event)"
    ></ion-searchbar>

    <div class="flex-container nowrap" style="display: flex">
      <ion-button
        class="filter-btn"
        (click)="filterPopover()"
        style="width: 100%"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        (click)="presentSortAlert()"
        style="width: 100%"
      >
        Ordenar
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>

    <ion-content class="content" style="background-color: white">
      <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list *ngIf="listPurchOrdersToSearch && !refresher" lines="none">
        <ion-card *ngFor="let element of listPurchOrdersToSearch">
          <ion-card-content style="padding: 0px">
            <ion-item-sliding>
              <ion-item>
                <div class="cardContent">
                  <div class="subContent title">
                    <h1 style="font-size: medium">
                      <b>{{ element.purchId }}</b>
                    </h1>
                  </div>
                  <div class="subContent vendor">
                    <span class="vendor">{{ element.objectName }}</span>
                    <span class="status">{{ element.status }}</span>
                  </div>
                  <hr class="divider" />
                  <div class="subContent details">
                    <span class="qtyLines"
                      ><b>Cant. Líneas:</b> {{ element.qtyLines }}</span
                    >
                    <span class="totalAmount"
                      ><b>Total:</b>
                      {{
                        element.totalAmount | currency : "CRC" : "symbol-narrow"
                      }}</span
                    >
                  </div>
                  <hr class="divider" />
                  <div class="subContent RemissionMode">
                    <span><b>Modo de Facturación: </b></span>
                  </div>
                  <div class="subContent select">
                    <ion-item style="width: 100%">
                      <ion-select
                        label=""
                        label-placement="floating"
                        class="floating"
                        [(ngModel)]="element.invoiceMode"
                      >
                        <ion-select-option [value]="'All'" selected
                          >Cant. Pedida</ion-select-option
                        >
                        <ion-select-option [value]="'Recorded'"
                          >Cant. Registrada</ion-select-option
                        >
                        <ion-select-option [value]="'PackingSlip'"
                          >Cant. Recepción de Producto</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </div>
                </div>
              </ion-item>
              <ion-item-options side="end">
                <ion-item-option (click)="goToDetails(element)">
                  <ion-icon name="add"> </ion-icon>
                  <span style="font-size: 8px">Ver Más</span>
                </ion-item-option>
              </ion-item-options>
              <ion-item-options side="start">
                <ion-item-option (click)="alertMakeInvoice(element)">
                  <ion-icon name="checkmark-outline"> </ion-icon>
                  <span style="font-size: 8px">Facturar</span>
                </ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </ion-content>
  </div>
</ion-content>
