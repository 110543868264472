<ion-list>
  <ion-list-header style="color: #223e99"> Filtros </ion-list-header>
  <div>
    <ion-item>
      <ion-select
        label="Departamento"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeDepartment($event)"
      >
        <!--[selected]="department.selected" TODOM-->
        <ion-select-option
          [value]="department"
          *ngFor="let department of filterData.departments"
          >{{ department.workerDepartment }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-select
        label="Período"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangePeriod($event)"
      >
        <!--[selected]="period.selected" TODOM-->
        <ion-select-option
          [value]="period"
          *ngFor="let period of filterData.periods"
          >{{ period.certificatedPeriod }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </div>
</ion-list>

<ion-list-header style="color: #223e99" lines="none">
  Rango de fechas
</ion-list-header>
<ion-item>
  <ion-label>Fecha inicial</ion-label>
  <ion-datetime-button datetime="datetime"></ion-datetime-button>
  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        id="datetime"
        presentation="date"
        [showDefaultButtons]="true"
        value="startDate"
        display-timezone="utc"
        displayFormat="DD/MM/YYYY"
        [(ngModel)]="startDate"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
</ion-item>
<ion-item>
  <ion-label>Fecha final</ion-label>
  <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>
  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        id="datetimeEnd"
        presentation="date"
        [showDefaultButtons]="true"
        value="finalDate"
        display-timezone="utc"
        displayFormat="DD/MM/YYYY"
        [(ngModel)]="finalDate"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
</ion-item>
<ion-label *ngIf="wrongDateFlag" style="color: red; padding-left: 13px">
  El formato de las fechas es incorrecto.
</ion-label>

<ion-grid style="display: flex">
  <ion-button text-color="#0000ff" (click)="dismissNull()" style="width: 60%">
    <ion-icon name="trash"></ion-icon> Borrar Filtros
  </ion-button>

  <ion-button text-color="#0000ff" (click)="dismiss()" style="width: 40%">
    <ion-icon name="checkmark-done-outline"></ion-icon>Filtrar
  </ion-button>
</ion-grid>
