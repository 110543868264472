import { Injectable } from '@angular/core';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';

@Injectable()
export class AuthenticationAuthGuardProvider {
  constructor(private authService: AuthJWTService) {}

  public checkToken() {
    return new Promise<boolean>((resolve, _) => {
      if (this.authService.hasToken() && this.authService.hasCookie()) {
        resolve(true);
      } else {
        this.authService.refreshToken().subscribe(
          (res) => {
            // TODO
            resolve(res);
          },
          (err) => {
            // TODO
            resolve(false);
          }
        );
      }
    });
  }

  public componentAllow(componentsByUser: any, componentId: number) {
    let answer: boolean = false;
    componentsByUser.forEach((component: any) => {
      if (component.type == 0 && component.componentId == componentId) {
        answer = true;
      } else if (component.type == 3 && component.ListAppsComponent.length) {
        component.ListAppsComponent.forEach((subComponent: any) => {
          if (subComponent.componentId == componentId) {
            answer = true;
          }
        });
      }
    });
    return answer;
  }
}
