<app-nav-bar [title]="'Proveedor'"></app-nav-bar>

<ion-content>
  <div class="box">
    <div lines="none" class="barTitle" style="width: 100%">
      <div style="display: flex">
        <h5 style="width: 100%; font-size: 1rem; text-align: center">
          <a></a>HISTORIAL
        </h5>
      </div>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <div class="whiteRectangle"></div>
  <ion-fab horizontal="end" vertical="bottom">
    <ion-fab-button size="small">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</div>
