import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Param } from '../../WMS/OOP/Param.interface';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { PurchLinesInfoParamv2 } from '../OOP/PurchLinesInfoParamv2.interface';
import { PurchLinesSummary } from '../OOP/PurchLinesSummary.interface';
import { Einvoice } from '../OOP/einvoices.interface';

@Injectable()
export class InvoiceService {
  listDataArea: DataArea[];

  constructor(public _http: HttpClient) {}

  async getDataAreaInfo() {
    const dataAreaInfList: DataArea[] = [];
    const dataAreaInfo = await this.getDataAreaRequest();
    if (dataAreaInfo !== null) {
      for (const areaInf of dataAreaInfo) {
        const tmpDataArea = areaInf as string;
        const information = tmpDataArea.split('_,,,_');
        const nameP = information[1];
        const idP = information[0];
        dataAreaInfList.push(new DataArea(idP, nameP));
      }
    }
    return dataAreaInfList;
  }

  async getDataAreaRequest() {
    let dataAreaList = null;
    let basePath = environment.SP_API + '/';
    let businessPath = 'business/';
    const consulta = basePath + businessPath + 'getDataAreaInfo';
    await this._http
      .get(consulta)
      .toPromise()
      .then((data) => {
        dataAreaList = data;
      })
      .catch((error) => {
        console.log('Error al tratar de traer lista de empresas...', error);
      });
    return dataAreaList;
  }

  async getAllPurchOrders(dataAreaId: string) {
    let response: any[];
    let method: string = 'GetAllPurchOrdersInvoice';
    let user: string = JSON.parse(localStorage.getItem('WMS')).user;

    if (!user) {
      return [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_user', val: user, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let collectData: PurchLinesInfoParamv2[] = [];

        let mainData = res.getElementsByTagName(
          'a:AIF_Aux_ResponseGetPurchInfo_v2'
        );

        for (const PurchOrder of mainData) {
          let data: PurchLinesInfoParamv2 = {
            purchId:
              PurchOrder.getElementsByTagName('a:PurchOrder')[0].textContent,
            objectId: '',
            objectName:
              PurchOrder.getElementsByTagName('a:provider')[0].textContent,
            roleSpecificIds: [],
            containerIds: [],
            id: '',
            containerIdsSelected: [],
            roleSpecificIdsAssistantsSelected: [],
            roleSpecificIdsSelected: [],
            status:
              PurchOrder.getElementsByTagName('a:StatusPO')[0].textContent,
            createdDate:
              PurchOrder.getElementsByTagName(
                'a:CreatedDate'
              )[0].textContent.split('T')[0],
            qtyLines:
              PurchOrder.getElementsByTagName(
                'a:QtyLines'
              )[0].textContent.split('T')[0],
            totalAmount:
              PurchOrder.getElementsByTagName(
                'a:TotalAmount'
              )[0].textContent.split('T')[0],
            invoiceMode: 'PackingSlip',
          };

          collectData.push(data);
        }

        return collectData;
      });
    } catch {
      response = [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }
    return response;
  }

  async getAllPurchLines(dataAreaId: string, purchId: string) {
    let response: any[];
    let method: string = 'GetAllPurchLines';

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_purchId', val: purchId, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let collectData: PurchLinesSummary[] = [];
        let mainData = res.getElementsByTagName('a:AIF_Aux_PurchLinesSummary');
        for (const PurchLine of mainData) {
          let data: PurchLinesSummary = {
            PurchId: PurchLine.getElementsByTagName('a:PurchId')[0].textContent,
            ItemId: PurchLine.getElementsByTagName('a:ItemId')[0].textContent,
            ProductName:
              PurchLine.getElementsByTagName('a:ProductName')[0].textContent,
            UnitId: PurchLine.getElementsByTagName('a:UnitId')[0].textContent,
            LineNumber:
              PurchLine.getElementsByTagName('a:LineNumber')[0].textContent,
            Qty: PurchLine.getElementsByTagName('a:Qty')[0].textContent,
            Price: PurchLine.getElementsByTagName('a:Price')[0].textContent,
            Discount:
              PurchLine.getElementsByTagName('a:Discount')[0].textContent,
            LineAmount:
              PurchLine.getElementsByTagName('a:LineAmount')[0].textContent,
          };

          collectData.push(data);
        }
        return collectData;
      });
    } catch {
      response = undefined;
    }

    return response;
  }

  async InvoiceSpecificPurchOrder(
    dataAreaId: string,
    purchId: string,
    specQty: number
  ) {
    let response: any;
    let method: string = 'InvoiceSpecificPurchOrder';

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_purchId', val: purchId, type: 1 },
      { tag: '_number', val: '', type: 1 },
      { tag: '_specQty', val: specQty, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let mainData = JSON.parse(
          res.getElementsByTagName('InvoiceSpecificPurchOrderResult')[0]
            .textContent
        );

        return mainData;
      });
    } catch {
      response = undefined;
    }

    return response;
  }

  async getAllEInvoicesByVendor(dataAreaId: string) {
    let wmsUser = JSON.parse(localStorage.getItem('WMS')).user as string;

    while (wmsUser.includes('-')) {
      wmsUser = wmsUser.replace('-', '');
    }

    let response: any[];
    let method: string = 'GetAllEInvoicesByVendor';
    let user: string = wmsUser;

    if (!user) {
      return [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_user', val: user, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let collectData: Einvoice[] = [];

        let mainData = res.getElementsByTagName('a:AIF_Aux_EInvoice');

        for (const Einvoice of mainData) {
          let data: Einvoice = {
            KeyEInvoice:
              Einvoice.getElementsByTagName('a:KeyEInvoice')[0].textContent,
            Consecutive:
              Einvoice.getElementsByTagName('a:Consecutive')[0].textContent,
            EmittedDate:
              Einvoice.getElementsByTagName('a:EmittedDate')[0].textContent,
            PurchId: Einvoice.getElementsByTagName('a:PurchId')[0].textContent,
            ReceiverId:
              Einvoice.getElementsByTagName('a:ReceiverId')[0].textContent,
            ReceiverName:
              Einvoice.getElementsByTagName('a:ReceiverName')[0].textContent,
            Total: Number(
              Einvoice.getElementsByTagName('a:Total')[0].textContent
            ),
            CurrencyCode:
              Einvoice.getElementsByTagName('a:CurrencyCode')[0].textContent,
          };

          collectData.push(data);
        }

        return collectData;
      });
    } catch {
      response = [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }
    return response;
  }

  async getAllPurchOrdersToAssignEInvoice(dataAreaId: string) {
    let response: any[];
    let method: string = 'GetAllPurchOrdersToAssignEInvoice';
    let user: string = JSON.parse(localStorage.getItem('WMS')).user; //'3-102-742992'

    if (!user) {
      return [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_user', val: user, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let collectData: PurchLinesInfoParamv2[] = [];

        let mainData = res.getElementsByTagName(
          'a:AIF_Aux_ResponseGetPurchInfo_v2'
        );

        for (const PurchOrder of mainData) {
          let data: PurchLinesInfoParamv2 = {
            purchId:
              PurchOrder.getElementsByTagName('a:PurchOrder')[0].textContent,
            objectId: '',
            objectName:
              PurchOrder.getElementsByTagName('a:provider')[0].textContent,
            roleSpecificIds: [],
            containerIds: [],
            id: '',
            containerIdsSelected: [],
            roleSpecificIdsAssistantsSelected: [],
            roleSpecificIdsSelected: [],
            status:
              PurchOrder.getElementsByTagName('a:StatusPO')[0].textContent,
            createdDate:
              PurchOrder.getElementsByTagName(
                'a:CreatedDate'
              )[0].textContent.split('T')[0],
            qtyLines:
              PurchOrder.getElementsByTagName(
                'a:QtyLines'
              )[0].textContent.split('T')[0],
            totalAmount:
              PurchOrder.getElementsByTagName(
                'a:TotalAmount'
              )[0].textContent.split('T')[0],
            invoiceMode: 'PackingSlip',
          };

          collectData.push(data);
        }

        return collectData;
      });
    } catch {
      response = [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }
    return response;
  }

  async ProcessEInvoiceToAssignPO(
    dataAreaId: string,
    key: string,
    consecutive: string,
    purchId: string
  ) {
    let response: any;
    let method: string = 'ProcessEInvoiceToAssignPO';

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_key', val: key, type: 1 },
      { tag: '_consecutive', val: consecutive, type: 1 },
      { tag: '_purchId', val: purchId, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let mainData = JSON.parse(
          res.getElementsByTagName('ProcessEInvoiceToAssignPOResult')[0]
            .textContent
        );

        return mainData;
      });
    } catch {
      response = undefined;
    }

    return response;
  }

  // Recibe una lista de parámetros y el nombre de un método.
  // Construye el xml del request
  constructXMLRequest(paramList: Param[], method: string): string {
    return `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
            <s:Body><${method} xmlns="http://tempuri.org/">
            ${this.constructXMLParams(paramList)}</${method}>
            </s:Body></s:Envelope>`;
  }

  // Recibe una lista de parámetros y los convierte en tags de XML
  // Type = 1 : Hoja // Type = 2 : Lista
  constructXMLParams(params: Param[]): string {
    let paramsData = '';
    for (let param of params) {
      let extra = param.extra ? param.extra : '';
      if (param.type == 1) {
        paramsData += `<${param.tag}${extra}>${param.val}</${param.tag}>`;
      } else {
        paramsData += `<${param.tag}${extra}>${this.constructXMLParams(
          param.val
        )}</${param.tag}>`;
      }
    }
    return paramsData;
  }

  // Ejecuta el request hacia el backend
  execRequest(xmlRequest: string, method: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.open('POST', environment.GWMainService_API, true);
      xhr.setRequestHeader('Content-Type', 'text/xml');
      xhr.setRequestHeader(
        'SOAPAction',
        `http://tempuri.org/IGW_MainService/${method}`
      );
      xhr.send(xmlRequest);

      xhr.onreadystatechange = function (aEvt) {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(xhr.responseXML);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
    });
  }
}
