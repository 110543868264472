import { Injectable } from '@angular/core';
import { FileOCInfo } from '../../businessCore/oc-models/FileOCInfo';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';

@Injectable()
export class ServicesOcParameterProvider {
  private purchaseOrder: PurchOrder;
  private editated: boolean;
  private toEdit: boolean;
  private from: string;
  private listToSendFileOC: FileOCInfo[];

  constructor() {
    this.purchaseOrder = null;
    this.editated = false;
    this.toEdit = false;
    this.from = '';
    this.listToSendFileOC = [];
  }

  setListFile(newListFile: FileOCInfo[]) {
    this.listToSendFileOC = newListFile;
  }

  getListFile() {
    return this.listToSendFileOC;
  }

  getFrom() {
    // de donde viene
    return this.from;
  }

  setFrom(newFrom: string) {
    this.from = newFrom;
  }

  getPurchOrderParameter() {
    return this.purchaseOrder;
  }

  setPurchOrderParameter(newPurchOrder: PurchOrder) {
    this.purchaseOrder = newPurchOrder;
  }

  setEditated(newEditated: boolean) {
    this.editated = newEditated;
  }

  getEditated() {
    return this.editated;
  }

  setToEdit(newToEdit: boolean) {
    this.toEdit = newToEdit;
  }

  getToEdit() {
    return this.toEdit;
  }
}
