import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ModalController,
  NavController,
  PopoverController,
} from '@ionic/angular';

import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Phone } from '../../businessCore/Phone';
import { RegisterContactEmailComponent } from '../register-contact-email/register-contact-email.component';
import { RegisterContactNumberComponent } from '../register-contact-number/register-contact-number.component';

@Component({
  selector: 'app-vendor-register-contact',
  templateUrl: './vendor-register-contact.component.html',
  styleUrls: ['./vendor-register-contact.component.scss'],
})
export class VendorRegisterContactComponent implements OnInit {
  @Input() contactData: any = {};
  @Output() contactDataChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() contactLanguage = {};
  @Input() changeLanguageTo = '';
  @Output() languageChanger = new EventEmitter<string>();
  @Output() screenChanger = new EventEmitter<number>();

  contactList: any[] = [];

  phoneList: Phone[] = [];
  emailList: any[] = [];

  constructor(
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public navCtrl: NavController,
    public storage: StorageService
  ) {}

  async ngOnInit() {
    this.setContactVariables();
  }

  setContactVariables() {
    this.phoneList = this.contactData.phones;
    this.emailList = this.contactData.emails;
    this.contactList = this.contactData.contacts;
  }

  setGlobalVariables() {
    this.contactData.phones = this.phoneList;
    this.contactData.emails = this.emailList;
    this.contactData.contacts = this.contactList;
  }

  /**
   *
   */
  changeLanguage() {
    switch (this.changeLanguageTo) {
      case 'ES':
        this.languageChanger.emit('EN');
        break;
      case 'EN':
        this.languageChanger.emit('ES');
        break;
      default:
        break;
    }
  }

  /**
   *
   */
  async addPhoneNumber() {
    const modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RegisterContactNumberComponent,
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null && data.getPhoneNumber() && data.getDescription()) {
        if (!data.main && this.mainOne(this.phoneList) == -1) {
          data.main = true;
        } else {
          if (data.main && this.mainOne(this.phoneList) != -1) {
            this.changeMain(this.phoneList);
          }
        }
        this.phoneList.push(data);
        this.contactList.push(data);
      }
    });
  }

  /**
   *
   */
  async addEmail() {
    const modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RegisterContactEmailComponent,
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null && data.email) {
        if (!data.main && this.mainOne(this.emailList) == -1) {
          data.main = true;
        } else {
          if (data.main && this.mainOne(this.emailList) != -1) {
            this.changeMain(this.emailList);
          }
        }
        this.emailList.push(data);
        this.contactList.push(data);
      }
    });
  }

  /**
   *
   * @param pList
   */
  changeMain(pList: any) {
    pList[this.mainOne(pList)].main = false;
  }

  /**
   *
   * @param pList
   */
  mainOne(pList: any) {
    let index = pList.findIndex((obj: any) => obj.main === true);
    return index;
  }

  /**
   *
   */
  deleteElement(pIndex: number) {
    let newMain: any;
    let phoneIndex = this.phoneList.findIndex(
      (phone: any) =>
        JSON.stringify(phone) === JSON.stringify(this.contactList[pIndex])
    );
    let mailIndex = this.emailList.findIndex(
      (mail: any) =>
        JSON.stringify(mail) === JSON.stringify(this.contactList[pIndex])
    );
    if (phoneIndex > -1) {
      this.phoneList.splice(phoneIndex, 1);
      if (this.phoneList.length) {
        newMain = this.phoneList[0];
        this.phoneList[0].setMainNumber(true);
      }
    }
    if (mailIndex > -1) {
      this.emailList.splice(mailIndex, 1);
      if (this.emailList.length) {
        newMain = this.emailList[0];
        this.emailList[0].main = true;
      }
    }
    this.contactList.splice(pIndex, 1);
    if (newMain) {
      let contactIndex = this.contactList.findIndex(
        (contact: any) => JSON.stringify(contact) === JSON.stringify(newMain)
      );
      this.contactList[contactIndex].main = true;
    }
  }

  /**
   *
   */
  disableNext() {
    if (this.phoneList.length && this.emailList.length) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @param pSecreen
   */
  changeScreen(pSecreen: number) {
    this.setGlobalVariables();
    this.contactDataChange.emit(this.contactData);
    this.screenChanger.emit(pSecreen);
  }
}
