<ion-searchbar
  type="text"
  placeholder="Buscar"
  (ionChange)="searchAssetRequest($event)"
></ion-searchbar>

<div class="flex-container nowrap" style="display: flex">
  <ion-button
    class="filter-btn"
    style="width: 100%; margin: 10px"
    (click)="presentFilterPopover()"
  >
    Filtros
    <ion-icon name="options"></ion-icon>
  </ion-button>

  <ion-button
    class="sort-btn"
    style="width: 100%; margin: 10px"
    (click)="presentSortAlert()"
  >
    Ordenar
    <ion-icon name="reorder-three-outline"></ion-icon>
  </ion-button>
</div>



<ion-item>
  <!-- Checkbox para seleccionar todo -->
  <ion-checkbox

    (ionChange)="selectAllRequests($event)"
    labelPlacement="end"
  >
    Seleccionar todo
  </ion-checkbox>
  <ion-label>
  </ion-label>
</ion-item>

<ion-item
    *ngFor="let request of requestListToSearch; let i = index"
    color="white"
    lines="none"
  >
    <!-- Checkbox para cada item en la lista -->
    <ion-checkbox
      slot="start"
      [(ngModel)]="request.selected"
      (ionChange)="getCheckedvalue()"
    ></ion-checkbox>

    <!-- Información del elemento de la lista -->
    <ion-label>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <p *ngIf="request.barcode" style="font-size: 13px;">
          Código de barras: {{ request.getBarcode() }}
        </p>
        <p *ngIf="request.transferDate" style="font-size: 13px;">
          {{ request.getTransferDate().getDate() }}/
          {{ request.getTransferDate().getMonth() + 1 }}/
          {{ request.getTransferDate().getFullYear() }}
        </p>
      </div>
      <h3>
        {{ request.getAssetId() }}: {{ request.getAssetName() }}
        <div
          *ngIf="request.getSubRequest().length"
          class="markedNumber"
          (click)="toggleAccordion(request)"
        >
          &nbsp;+{{ request.getSubRequest().length }}&nbsp;
        </div>
      </h3>
      <p style="font-size: 14px; color: #888;">
        {{ request.getDataAreaId() }}: {{ request.getCompanyName() }}
      </p>
      <p style="color: #000000;">
        {{ request.getWorkerName() }}
        <ion-icon name="ios-arrow-round-forward-outline"></ion-icon>
        {{ request.getWorkerNameNew() }}
      </p>
      <div
        *ngIf="requestType == 2 && request.assetType == '0'"
        style="margin-top: 5px;"
      >
        <label class="btn-attach">
          Subir imagen...
          <input
            type="file"
            class="upload-photo"
            accept="image/*"
            (change)="onFileSelected($event, i)"
          />
        </label>
        <p style="display: inline;">&nbsp;{{ request.getImgName() }}</p>
      </div>
      <div *ngIf="request.affected" class="ui-select-toggle">
        Nota: Este subactivo posee un dueño diferente al del activo principal
      </div>
      <div *ngIf="request.expanded">
        <ion-item *ngFor="let subRequest of request.getSubRequest(); let j = index">
          <h3>{{ j + 1 }}) {{ subRequest.getAssetName() }}</h3>
        </ion-item>
      </div>
    </ion-label>
  </ion-item>

