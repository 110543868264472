import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {
  constructor() {}
}

@Component({
  selector: 'app-timeline-item',
  template: '<ng-content></ng-content>',
})
export class TimelineItemComponent {
  constructor() {}
}

@Component({
  selector: 'app-timeline-time',
  template: '<span>{{time.subtitle}}</span> <span>{{time.title}}</span>',
})
export class TimelineTimeComponent {
  @Input('time') time: { subtitle: string; title: string } = {
    subtitle: '',
    title: '',
  };
  constructor() {}
}
