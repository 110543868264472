<app-nav-bar [title]="'Encabezado'">
  <ion-buttons slot="end">
    <ion-button
      style="background: none"
      (click)="openMenuOC($event)"
      *ngIf="!toEdit"
    >
      <img
        src="assets/img/menuPoint2.svg"
        style="width: 25px"
        onerror=""
        alt=""
      />
    </ion-button>
    <ion-button style="background: none" *ngIf="toEdit" (click)="changeEdit()">
      <i class="fas fa-pencil-alt" style="width: 45px"></i>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-toolbar class="oc-parameter">
  <ion-segment color="black" value="infoGeneral" [(ngModel)]="segmentModel">
    <ion-segment-button value="infoGeneral" layout="icon-start">
      <!--<ion-icon name="person"></ion-icon>-->
      <span class="labelCenter">Gen</span>
    </ion-segment-button>
    <ion-segment-button value="infoEntrega" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <span class="labelCenter">Entregas</span>
    </ion-segment-button>
    <ion-segment-button value="infoPlus" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <span class="labelCenter">Info+</span>
    </ion-segment-button>
    <ion-segment-button value="infoNota" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <span class="labelCenter">Notas</span>
    </ion-segment-button>
  </ion-segment>
</ion-toolbar>

<ion-content>
  <div class="box">
    <div class="content">
      <app-general-parameter-oc
        *ngIf="segmentModel === 'infoGeneral' && presentData"
        [fechaIni]="fechaInicio"
        [fechaFin]="fechaFin"
        [cantEdit]="isEditing"
        [creating]="createEditing"
        [saleRequired]="saleRequired"
        (getDatesIniFin)="getDatesIniFin($event)"
        (openSearchBar)="openSearchBar($event)"
        [dataAreaSelected]="empresaSelected"
        [proveedorSelected]="proveedorSelected"
        [purchPoolSelected]="purchPoolSelected"
        [purposeSelected]="purposeSelected"
        [saleSelected]="saleSelected"
        [currencySelected]="currencySelected"
        [languageSelected]="languageSelected"
        [taxInclude]="taxInclude"
        [tipoOCSelected]="tipoOCSelected"
        [PurposeCategorySelected]="PurposeCategorySelected"
        [needToSetCategory]="needToSetCategory"
        [requireNoPhysicalInvoice]="requireNoPhysicalInvoice"
        [noPhysicalInvoice]="noPhysicalInvoice"
        (noPhysicalInvoiceEmit)="noPhysicalInvoiceEmit($event)"
        (getTaxesInfo)="getTaxesInfo($event)"
      >
      </app-general-parameter-oc>

      <app-entrega-oc
        *ngIf="segmentModel === 'infoEntrega' && presentData"
        [cantEdit]="isEditing"
        [fechaEntrega]="fechaEntrega"
        (openSearchBar)="openSearchBar($event)"
        (getDatesEntrega)="getDatesEntrega($event)"
        [sitioSelected]="sitioSelected"
        [almacenSelected]="almacenSelected"
      >
      </app-entrega-oc>

      <app-info-plus-oc
        [cantidadContenedor]="cantContenedor"
        [cantidadTEUS]="cantTEUS"
        [cantidadLibres]="cantDLibres"
        [salidaSolicD]="salidaSolicD"
        [salidaArriboD]="salidaArriboD"
        [soliLlegaBD]="soliLlegaBD"
        [cantEdit]="isEditing"
        (getValueNumber)="getInfoPlusNumber($event)"
        (getDates)="getDatesPlus($event)"
        [puertoSelected]="puertoSelected"
        [navieraSelected]="navieraSelected"
        [incoterm]="incotermSelected"
        [typeContainer]="containerTypeSelected"
        [estadoPedido]="estadoPedidoSelected"
        [aDepartamentoSelected]="aDepartamentoSelected"
        [bCentroCostoSelected]="bCentroCostoSelected"
        [cPropositoSelected]="cPropositoSelected"
        [proyectoSelected]="proyectoSelected"
        (openSearchBar)="openSearchBar($event)"
        *ngIf="segmentModel === 'infoPlus' && presentData"
      >
      </app-info-plus-oc>

      <app-nota-oc
        *ngIf="segmentModel === 'infoNota'"
        [note]="noteOC"
        (getNote)="getNote($event)"
        [cantEdit]="isEditing"
      >
      </app-nota-oc>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <div class="whiteRectangle">
    <ion-fab horizontal="end" vertical="bottom" *ngIf="!toEdit">
      <ion-fab-button (click)="goToLines()" size="small">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-fab horizontal="start" vertical="bottom" *ngIf="toEdit">
      <ion-fab-button (click)="goToLBackEditApproved()" size="small"
        ><ion-icon name="chevron-back-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>
