<ion-label class="title">
  <h2 class="titletext"><b>Dimensiones Financieras</b></h2>
</ion-label>
<ion-item (click)="openSearchBar(1)">
  <ion-label>
    <h2>A_Departamento</h2>
    <p>{{ purchLine.getA_Departamento().getName() }}</p>
  </ion-label>
  <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
</ion-item>
<ion-item (click)="openSearchBar(2)">
  <ion-label>
    <h2>B_CentroCosto</h2>
    <p>{{ purchLine.getB_CentroCosto().getName() }}</p>
  </ion-label>
  <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
</ion-item>
<ion-item (click)="openSearchBar(3)">
  <ion-label>
    <h2>C_Proposito</h2>
    <p>{{ purchLine.getC_Proposito().getName() }}</p>
  </ion-label>
  <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
</ion-item>
<!--</ion-grid>-->
