export class Worker {
  recId: string;
  workerName: string;
  vatnum: string;
  personnelNumber: string;
  email: string;

  getRecId() {
    return this.recId;
  }

  getName() {
    return this.workerName;
  }

  getVatnum() {
    return this.vatnum;
  }

  getPersonnelNumber() {
    return this.personnelNumber;
  }

  getEmail() {
    return this.email;
  }
}
