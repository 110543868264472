<!-- 
  ***************************************************************
  NUEVA INTERFAZ
  ***************************************************************
 -->

<app-nav-bar [title]="'Recuperar contraseña'" [start]="'None'">
  <ion-buttons slot="start">
    <ion-button (click)="cancelReset()">
      <ion-icon style="font-size: 25px" name="arrow-back-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content class="ion-padding" class="login-content">
  <ion-grid style="align-items: center">
    <div class="img-container">
      <img
        src="assets/img/logo_homex.png"
        style="height: 30px; width: 125px"
        alt=""
        onerror=""
      />
    </div>

    <ion-card class="login-card" *ngIf="!login_status">
      <ion-card-header text-center>
        <div class="login-title">Cambiar Contraseña</div>
      </ion-card-header>
      <ion-card-content>
        <!-- FORM STEP # 01 -->

        <form (ngSubmit)="resetPassword()" #registerForm="ngForm" *ngIf="step1">
          <!-- Tipo de cédula del cliente -->
          <ion-item>
            <ion-select
              label="Tipo de Cédula"
              label-placement="floating"
              class="floating"
              [(ngModel)]="c_type"
              [ngModelOptions]="{ standalone: true }"
              interface="popover"
            >
              <ion-select-option
                *ngFor="let ct of card_types"
                (ionSelect)="updateCardType(ct.value)"
                value="{{ ct.value }}"
                >{{ ct.name }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <!-- Identificación del usuario condicionada por el tipo de cedula seleccionado -->
          <ion-item>
            <ion-label>
              <a
                [ngClass]="{
                  success: validateID(id_card),
                  error: !validateID(id_card)
                }"
                >*</a
              >
            </ion-label>
            <ion-input
              type="text"
              placeholder="{{ card_types[c_type].format }}"
              #username
              mask="*-****-****"
              minlength="11"
              maxlength="11"
              *ngIf="card_types[c_type].value == 0"
              name="id_card"
              [(ngModel)]="id_card"
              required
            ></ion-input>
            <ion-input
              type="text"
              placeholder="{{ card_types[c_type].format }}"
              #username
              mask="********************"
              minlength="1"
              maxlength="20"
              *ngIf="card_types[c_type].value == 1"
              name="id_card"
              [(ngModel)]="id_card"
              required
            ></ion-input>
            <ion-input
              type="text"
              placeholder="{{ card_types[c_type].format }}"
              #username
              mask="*-***-******"
              minlength="12"
              maxlength="12"
              *ngIf="card_types[c_type].value == 2"
              name="id_card"
              [(ngModel)]="id_card"
              required
            ></ion-input>
            <ion-button
              type="button"
              (click)="deleteIdCard()"
              style="height: 40px; text-align: center; margin-right: 0px"
              class="button-blue"
            >
              <ion-icon
                name="close"
                style="font-size: 20px"
                slot="icon-only"
              ></ion-icon>
            </ion-button>
          </ion-item>

          <!-- Email -->
          <ion-item>
            <a
              [ngClass]="{
                success: validateEmail(email),
                error: !validateEmail(email)
              }"
              style="margin-right: 2%"
              >*</a
            >

            <ion-input
              label=" Correo Electrónico"
              label-placement="floating"
              class="floating"
              type="email"
              name="email"
              [(ngModel)]="email"
              [ngModelOptions]="{ standalone: true }"
              required
            ></ion-input>
          </ion-item>
          <!-- Botón para realizar el cambio de contraseña -->
          <ion-grid style="padding: 0px !important">
            <ion-button
              style="margin: 0px !important"
              type="submit"
              [disabled]="!verifyEnableBtn"
              expand="block"
            >
              <ion-icon name="search" class="mr-5"></ion-icon> Verificar
            </ion-button>
          </ion-grid>
        </form>

        <!-- FORM STEP # 02 -->

        <form (ngSubmit)="setNewPassword()" *ngIf="step2">
          <!-- Código de Verificación -->
          <ion-item class="register_item">
            <a class="error" style="margin-right: 2%">*</a>
            <ion-input
              label="Código de Verificación"
              label-placement="floating"
              class="floating"
              mask="********"
              type="text"
              minlength="8"
              maxlength="8"
              name="verification_code"
              [(ngModel)]="verification_code"
              required
            ></ion-input>
          </ion-item>

          <!-- Nueva contraseña -->
          <ion-item class="register_item">
            <a
              [ngClass]="{
                success: validatePassword(password),
                error: !validatePassword(password)
              }"
              style="margin-right: 2%"
              >*</a
            >

            <ion-input
              label="Nueva Contraseña"
              label-placement="floating"
              class="floating"
              type="password"
              minlength="8"
              maxlength="15"
              name="password"
              [(ngModel)]="password"
              required
            ></ion-input>
          </ion-item>

          <!-- Confirmación de contraseña -->
          <ion-item class="register_item">
            <a
              [ngClass]="{
                success: password == passConfirm,
                error: password != passConfirm
              }"
              style="margin-right: 2%"
              >*</a
            >
            <ion-input
              label="Confirmar Contraseña"
              label-placement="floating"
              class="floating"
              type="password"
              minlength="8"
              maxlength="15"
              name="passConfirm"
              [(ngModel)]="passConfirm"
              required
            ></ion-input>
          </ion-item>

          <ion-grid style="padding: 0px !important">
            <!-- Restricciones de contraseña -->
            <span class="span_alert black"
              >* Deben tener un largo entre 8 y 15 caracteres.</span
            >
            <br />
            <span *ngIf="password != passConfirm" class="span_alert red"
              >Las contraseñas no coinciden.</span
            >

            <!-- Botón para realizar el cambio de contraseña -->
            <div style="display: flex; justify-content: center">
              <ion-button
                style="margin: 0px !important; width: 100%"
                type="submit"
              >
                <ion-icon name="build" class="mr-5"></ion-icon> Cambiar
              </ion-button>
            </div>
          </ion-grid>
        </form>

        <!-- Reintentar password -->
        <div style="width: 100%; text-align: center; margin-top: 5px">
          <div
            [hidden]="!showCountDown"
            style="text-align: center; font-size: 1rem !important; color: brown"
          >
            ¿No has recibido el correo electrónico? Inténtalo en
            <a style="font-weight: bold">{{ countDownSeconds }} Segundos.</a>
          </div>
          <ion-button
            [hidden]="!showRenew"
            (click)="renewPassword()"
            style="margin: 0px !important; width: 100%"
            type="submit"
          >
            <ion-icon name="refresh" class="mr-5"></ion-icon> Reintentar
          </ion-button>
        </div>

        <div class="forgotPassword" (click)="cancelReset()">Cancelar</div>
      </ion-card-content>
    </ion-card>
  </ion-grid>
</ion-content>
