<!-- NavBar with go back option -->
<app-nav-bar [title]="titulo" [start]="'GoBack'" [end]="'Cart'"></app-nav-bar>

<ion-content>
  <div class="flexContainer">
    <div
      class="familyContainer fImg"
      [ngStyle]="{
        'background-image':
          'url(https://serviceplan-api.mercasacr.com/images/Products/Categories/' +
          categoryTitle +
          '_BG.JPG' +
          ')'
      }"
      (click)="showProducts(categoryTitle, titulo)"
      *ngFor="let categoryTitle of categories_info"
    >
      <img
        src="{{
          'https://serviceplan-api.mercasacr.com/images/Products/Categories/' +
            categoryTitle +
            '_ico.png'
        }}"
        alt=""
      />
      <div class="h7">{{ replaceUnderScore(categoryTitle) }}</div>
    </div>
  </div>
</ion-content>
