import { Component, Input } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { CheckListInstance } from '../../../businessCore/check-models/CheckInstance';
import { ServicesLocalCheckProvider } from '../../../provider/Services/services-local-check';
import { NoteTaskComponent } from '../note-task/note-task.component';
import { ExternalServiceChecklistProvider } from './../../../provider/Services/external-service-checklist';

@Component({
  selector: 'app-check-list-instance-component',
  templateUrl: './check-list-instance-component.html',
  styleUrls: ['./check-list-instance-component.scss'],
})
export class CheckListInstanceComponent {
  @Input() checkListIns: CheckListInstance;
  @Input() vatNum: string;
  dateExec: string = '';
  presentingMess: boolean = false;
  loader: HTMLIonLoadingElement;
  lastDateExec: string = '';
  constructor(
    public navCtrl: NavController,
    public alertController: AlertController,
    private localService: ServicesLocalCheckProvider,
    private loadingCtrl: LoadingController,
    private webService: ExternalServiceChecklistProvider,
    private popoverCtrl: PopoverController,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    if (this.checkListIns !== null && this.checkListIns !== undefined) {
      if (this.checkListIns.getExecutionDate()) {
        this.dateExec = this.checkListIns.getExecutionDate().toISOString();
      }
      if (this.checkListIns.getLastExecution()) {
        this.lastDateExec = this.checkListIns.getLastExecution().toISOString();
      }
    }
  }

  beforeLastRange(firstDate: Date, secondDate: Date) {
    let result = true;
    if (firstDate) {
      result = secondDate <= firstDate;
    }
    return result;
  }

  openInfoCheckList() {
    const actualTimeDate = new Date();
    if (
      //true || //DEBUG
      this.checkListIns.getExecutionDate() <= actualTimeDate &&
      (this.checkListIns.getLastExecution() !== null &&
      this.checkListIns.getLastExecution() !== undefined
        ? this.beforeLastRange(
            this.checkListIns.getLastExecution(),
            actualTimeDate
          )
        : this.sameDay(this.checkListIns.getExecutionDate(), actualTimeDate)) &&
      this.checkListIns.getEstado() === 0
    ) {
      this.checkListIns.setVatNum(this.vatNum);

      this.navService.navigateTo(NavigationRoutes.ChecklistTaskPage, {
        checkListInst: this.checkListIns,
      });
    } else {
      this.messageInfo(
        'Atención',
        'No se puede realizar el checklist en este momento.'
      );
    }
  }

  executeAction(action) {
    switch (action) {
      case -1:
        this.deleteLocalData(
          this.checkListIns.inLocal,
          this.checkListIns.getInstanceRecId(),
          this.checkListIns.getCheckList()
        );
        break;
      case 1:
        this.omitInstaciaSelected();
        break;
      default:
        break;
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];

    if (action) {
      if (action === -1) {
        botones = [
          {
            text: 'ACEPTAR',
            handler: () => {
              this.executeAction(action);
            },
          },
        ];
      } else {
        botones = [
          {
            text: 'SI',
            handler: () => {
              this.executeAction(action);
            },
          },
          { text: 'NO', handler: () => {} },
        ];
      }
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  sameDay(dateA: Date, dateB: Date) {
    let result = false;
    if (
      dateA.getDate() === dateB.getDate() && // dateA.getDay() === dateB.getDay()
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear()
    ) {
      result = true;
    }
    return result;
  }

  async goToOmit() {
    if (this.canOmit()) {
      this.messageInfo(
        'Atención',
        '¿Desea omitir la información del checklist seleccionado?',
        1
      );
    }
  }

  canOmit() {
    // DEBUG
    //return true;
    // DEBUG
    const actualTimeDate = new Date();
    return (
      this.checkListIns.getExecutionDate() <= actualTimeDate &&
      // && this.sameDay(this.checkListIns.getExecutionDate(), actualTimeDate)
      (this.checkListIns.getLastExecution() !== null &&
      this.checkListIns.getLastExecution() !== undefined
        ? this.beforeLastRange(
            this.checkListIns.getLastExecution(),
            actualTimeDate
          )
        : this.sameDay(this.checkListIns.getExecutionDate(), actualTimeDate)) &&
      this.checkListIns.getEstado() === 0
    );
  }

  getStyle() {
    return this.canOmit() ? 'borderStyle2' : 'borderStyle1';
  }

  swipeEvent(event) {
    if (event.direction === 2) {
      // Omitir checklist
      this.goToOmit();
    } else if (event.direction === 4) {
    }
  }

  async omitInstaciaSelected() {
    //Llama la función de pedir observacion
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.popoverCtrl.create({
        component: NoteTaskComponent,
        componentProps: { noteInfo: this.checkListIns.getReason() },
        cssClass: ['custom-popover', 'note-task'],
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((res) => {
        let data = res.data;
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          if (typeof data === 'string' && data.length > 0) {
            this.aux_omitInstanciaSelected(data);
          } else {
            this.messageInfo(
              'Error',
              'La razón de omisión no puede ser vacía.'
            );
          }
        }
      });
    }
  }

  async aux_omitInstanciaSelected(reason: string) {
    let resultChan: any = 0;
    await this.showSpinnerLoading('Omitendo checklist');
    try {
      resultChan = await this.webService.changeStateInstance(
        this.checkListIns.getInstanceRecId(),
        'OMITIDA',
        reason
      );
    } catch (error) {
      resultChan = error;
    }
    this.dissmissLoading();

    if (typeof resultChan === 'number') {
      this.messageInfo(
        'Error',
        'No se puedo comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resultChan === 'string' && resultChan === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puedo comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resultChan === 'string' && resultChan === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puedo generar la siguiente instancia, intentelo de nuevo.'
      );
    } else if (typeof resultChan === 'string' && resultChan === 'SUCCESS') {
      // Se hizo el cambio de estado
      this.checkListIns.showInstance = false;
      this.checkListIns.state = 4;
      this.messageInfo(
        'Atención',
        'El checklist fue omitido de forma correcta.',
        -1
      );
    }
  }

  async deleteLocalData(
    local: boolean,
    checkInstanceRecID: number,
    checkListID: string
  ) {
    if (local) {
      const result = await this.localService.deleteInstanceCheckList(
        checkInstanceRecID
      );
      if (result) {
        await this.localService.deleteInfoCheckList(checkListID);
      }
    }
    this.localService.setNextSubjetReload(true);
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }
}
