import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { ParadaModel } from '../../../businessCore/trip-models/parada';

@Component({
  selector: 'app-parada-trip',
  templateUrl: './parada-trip.component.html',
  styleUrls: ['./parada-trip.component.scss'],
})
export class ParadaTripComponent {
  horaIni: string;
  horaFin: string;
  DateIni: Date;
  DateFin: Date;
  motivo: string;
  descrip: string;
  puntoInicio: [number, number] = null;
  puntoFin: [number, number] = null;
  constructor(
    private viewCtrl: ModalController,
    public navCtrl: NavController,
    private navService: NavigationService
  ) {}

  saveCheckPoint() {
    const parada: ParadaModel = new ParadaModel();
    parada.setType(1);
    parada.setTypeCreation('INESPERADO');
    parada.setMotivo(this.motivo);
    parada.setEndDate(this.DateFin);
    parada.setInitDate(this.DateIni);
    if (
      (this.puntoInicio !== null && this.puntoInicio !== undefined) ||
      (this.puntoFin !== null && this.puntoFin !== undefined)
    ) {
      if (this.puntoInicio !== null && this.puntoInicio !== undefined) {
        parada.setLatitud(this.puntoInicio[0]);
        parada.setLongitud(this.puntoInicio[1]);
      }
      if (this.puntoFin !== null && this.puntoFin !== undefined) {
        parada.setLatitudEnd(this.puntoFin[0]);
        parada.setLongitudEnd(this.puntoFin[1]);
      }
      parada.setDescripcion(this.descrip);
      this.closePopOver(parada);
    } else {
      this.closeCancel();
    }
  }

  closeCancel() {
    this.closePopOver(null);
  }

  async closePopOver(parada: ParadaModel) {
    this.viewCtrl.dismiss({
      paradaCreated: parada,
    });
  }

  openMap(tipo: number) {
    // Abre el mapa y recibe el punto gps de inicio o fin de la parada
    console.log('OpeningMap');
    let titulo = '';
    if (tipo === 3) {
      titulo = 'Inicio Parada';
    } else if (tipo === 4) {
      titulo = 'Fin Parada';
    }
    this.navService.navigateTo(NavigationRoutes.MapTripPage, {
      tipo: tipo,
      titulo: titulo,
      move: false,
      callback: this.getPointCallback,
    });
  }

  getTimeString(actualTime: Date) {
    // const actualTime = new Date();
    const hora =
      actualTime.getHours() < 10
        ? '0' + actualTime.getHours().toString()
        : actualTime.getHours().toString();
    const min =
      actualTime.getUTCMinutes() < 10
        ? '0' + actualTime.getUTCMinutes().toString()
        : actualTime.getUTCMinutes().toString();
    return hora + ':' + min;
  }
  // callback...
  getPointCallback = (data: ParadaModel) => {
    if (data !== null && data !== undefined) {
      this.descrip = data.getDescripcion();
      switch (data.getType()) {
        case 3:
          this.puntoInicio = [0, 0];
          this.puntoInicio[0] = data.getLatitud();
          this.puntoInicio[1] = data.getLongitud();
          this.DateIni = new Date();
          this.horaIni = this.getTimeString(this.DateIni);
          break;
        case 4:
          this.puntoFin = [0, 0];
          this.puntoFin[0] = data.getLatitud();
          this.puntoFin[1] = data.getLongitud();
          this.DateFin = new Date();
          this.horaFin = this.getTimeString(this.DateFin);
          break;
        default:
          break;
      }
    }
  };
}
