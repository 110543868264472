<ion-list lines="inset" color="secondary">
  <ion-list-header>
    <ion-label style="text-align: center; font-size: 20px">{{
      titulo
    }}</ion-label>
  </ion-list-header>

  <ion-item
    lines="inset"
    style="margin-top: 1%"
    *ngFor="let obj of objectList"
    (click)="closePopOver(obj)"
  >
    <ion-label>
      <div style="display: flex">
        <h2 class="h2Select">{{ obj.getFMTrip() }}</h2>
        <h2
          class="h2Select"
          style="margin-left: 5% !important"
          *ngIf="obj.getFecha()"
        >
          {{ obj.getFecha().toISOString() | date : "dd/MM/yyyy" }}
        </h2>
      </div>
      <p text-wrap>{{ obj.getDescription() }}</p>
    </ion-label>
  </ion-item>
</ion-list>
