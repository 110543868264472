import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { CatalogModel } from '../../../businessCore/oc-models/CatalogModel';
import { PurchLine } from '../../../businessCore/oc-models/PurchLine';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { SearchProviderComponent } from '../search-provider/search-provider.component';

@Component({
  selector: 'app-update-financial-dimension',
  templateUrl: './update-financial-dimension.component.html',
  styleUrls: ['./update-financial-dimension.component.scss'],
})
export class UpdateFinancialDimensionComponent implements OnInit {
  @Input() purchLine: PurchLine;
  @Input() cantEdit: boolean;
  operation: any;

  aDepartamento: CatalogModel[];
  bCentroCosto: CatalogModel[];
  cProposito: CatalogModel[];
  constructor(
    private params: NavParams,
    public modalCtrl: PopoverController,
    public extServOC: ExternalServiceOCService
  ) {
    try {
      this.purchLine = this.params.get('purchLine');
      this.cantEdit = this.params.get('cantEdit');
    } catch (e) {}
    this.operation = null;
  }

  async ngOnInit() {
    // Dimensiones Financieras
    this.extServOC
      .getFinancialDimensionByName('A_Departamento')
      .then((data) => {
        this.aDepartamento = data;
        if (this.purchLine.getA_Departamento().getName() === '') {
          const result = this.setDataByPO(
            this.purchLine.getA_Departamento(),
            this.aDepartamento
          ) as CatalogModel;
          if (result.getName() !== '') {
            this.purchLine.getA_Departamento().setName(result.getName());
          }
        }
      });
    this.extServOC.getFinancialDimensionByName('B_CentroCosto').then((data) => {
      this.bCentroCosto = data;
      if (this.purchLine.getB_CentroCosto().getName() === '') {
        const result = this.setDataByPO(
          this.purchLine.getB_CentroCosto(),
          this.bCentroCosto
        ) as CatalogModel;
        if (result.getName() !== '') {
          this.purchLine.getB_CentroCosto().setName(result.getName());
        }
      }
    });
    this.extServOC.getFinancialDimensionByName('C_Proposito').then((data) => {
      this.cProposito = data;
      if (this.purchLine.getC_Proposito().getName() === '') {
        const result = this.setDataByPO(
          this.purchLine.getC_Proposito(),
          this.cProposito
        ) as CatalogModel;
        if (result.getName() !== '') {
          this.purchLine.getC_Proposito().setName(result.getName());
        }
      }
    });
  }

  setDataByPO(dataSearch: any, list: any[]) {
    let result: any;
    if (dataSearch !== null && dataSearch !== undefined) {
      result = list.find(
        (data) =>
          data.getId().toString().toLowerCase() ===
          dataSearch.getId().toString().toLowerCase()
      );
      if (result === undefined) {
        result = list[0];
      }
    } else {
      result = list[0];
    }
    return result;
  }

  /**
   * Funcion que abre la pantalla de busqueda con una lista en especifico
   * retorna el dato seleccionado, que luego sera procesado.
   */
  async openSearchBar(typeSearch: number) {
    if (this.cantEdit) {
      switch (typeSearch) {
        case 1:
          this.openSearchBarAux(this.aDepartamento, 1);
          break;
        case 2:
          this.openSearchBarAux(this.bCentroCosto, 2);
          break;
        case 3:
          this.openSearchBarAux(this.cProposito, 3);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Funcion para abrir una pantalla y retornar el elemento de busqueda
   * recibe la lista a filtrar
   */
  async openSearchBarAux(listInfo: any[], typeSearch: number) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          if (((data.objSelected as CatalogModel).getId() as any) !== '') {
            this.setDataSelected(data.objSelected, typeSearch);
          }
        }
      }
    });
  }

  // Asigna el dato seleccionado a la variable
  setDataSelected(objectSelected: any, typeSearch: number) {
    // Si es el proveedor se cambia otras cosas tambien
    switch (typeSearch) {
      case 1:
        this.purchLine.setA_Departamento(objectSelected as CatalogModel);
        break;
      case 2:
        this.purchLine.setB_CentroCosto(objectSelected as CatalogModel);
        break;
      case 3:
        this.purchLine.setC_Proposito(objectSelected as CatalogModel);
        break;
      default:
        break;
    }
  }
}
