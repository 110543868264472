<ion-content center text-center>
  <h4 margin-bottom>{{ title }}</h4>

  <div style="text-align: center" *ngIf="loadingInfo">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando información</h6>
  </div>

  <div style="margin-left: 10px; margin-right: 10x">
    <!-- New Data -->
    <div style="margin-top: 10px">
      <ion-item style="padding-left: 0px !important">
        <ion-input
          label="Ingresar Dato"
          label-placement="floating"
          class="floating"
          class="inputField"
          [(ngModel)]="newData"
          [ngModelOptions]="{ standalone: true }"
          required
        ></ion-input>
      </ion-item>
    </div>
  </div>

  <ion-button
    class="submit-btn btnCss"
    type="submit"
    margin-top
    (click)="addData()"
  >
    <i class="fas fa-pencil-alt" style="margin-right: 10px"></i>Agregar
  </ion-button>
</ion-content>
