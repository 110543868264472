import { Component } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';

import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { InventoryItem } from '../../businessCore/InventoryItem';
import { OrderLine } from '../../businessCore/OrderLine';
import { PromoConditionGroup } from '../../businessCore/PromoConditionGroup';
import { PromoConditionsListView } from '../../businessCore/PromoConditionsListView';
import { PromoConditionsView } from '../../businessCore/PromoConditionsView';
import { PromoItemGroup } from '../../businessCore/PromoItemGroup';
import { PromoResultsViews } from '../../businessCore/PromoResultsViews';
import { PromoTableView } from '../../businessCore/PromoTableView';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { PrevShoppingCarComponent } from '../prev-shopping-car/prev-shopping-car.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { PromoResultComponent } from '../promo-result/promo-result.component';

@Component({
  selector: 'app-promo-detail',
  templateUrl: './promo-detail.component.html',
  styleUrls: ['./promo-detail.component.scss'],
})
export class PromoDetailComponent {
  // Click Events Controllers
  itemDetailExecuting: boolean = false;

  // Promo Header
  promo: PromoTableView = new PromoTableView();
  promoBase: PromoTableView = new PromoTableView();

  // PromoConditions by Group
  PromoConditionGroup: PromoConditionGroup[] = [];

  // Total Amount (selected items in conditions)
  ActualPromoAmount: number = 0;

  // Total Amount in the shopping car
  shoppingCarTotalAmount: number = 0;

  // Shopping Car Array
  shoppingCar: OrderLine[] = [];

  // Inventory Items (showed in the home page)
  itemInfo: InventoryItem[] = [];

  // Selected items in the promo conditions
  PromoItemGroup: PromoItemGroup[] = [];

  // Control variable
  loadingShoppingCar: boolean = true;

  // Error conditions
  conditionInError: PromoConditionsView[] = [];

  // Si la promo tiene resultados de regalia
  promoHas108Res: boolean = false;

  // timesApply
  timesApply: number[] = [];

  constructor(
    public viewCtrl: ModalController,
    private params: NavParams,
    private toastCtrl: ToastController,
    private popoverCtrl: PopoverController,
    private alert: AlertController,
    private webProvider: webServiceProvider,
    private storage: StorageService,
    private modalCtrl: ModalController
  ) {
    let tmpPromo: PromoTableView = Object.assign(
      {},
      this.params.get('promoTableView')
    );

    // ----- Copiar datos de la promocion
    this.promo = Object.assign(
      new PromoTableView(),
      tmpPromo as PromoTableView
    );
    this.promoBase = Object.assign(
      new PromoTableView(),
      tmpPromo as PromoTableView
    );

    this.promo.PromoResults = [];
    this.promoBase.PromoResults = [];
    this.promo.PromoConditions = [];
    this.promoBase.PromoConditions = [];

    tmpPromo.PromoResults.forEach((p) => {
      this.promo.PromoResults.push(Object.assign(new PromoResultsViews(), p));
      this.promoBase.PromoResults.push(
        Object.assign(new PromoResultsViews(), p)
      );
    });

    tmpPromo.PromoConditions.forEach((c) => {
      this.promo.PromoConditions.push(
        Object.assign(new PromoResultsViews(), c)
      );
      this.promoBase.PromoConditions.push(
        Object.assign(new PromoResultsViews(), c)
      );
    });
    // ----- FIN Copiar datos de la promocion

    this.decomposePromo(this.promo);

    this.getInventoryItems();

    this.validatePromo108Res();
  }

  /**
   * Setea cantidades por defecto de los articulos que estan en condiciones
   * y que se encuentran libres (sin promo) en el carro de compras
   */
  setDefaultQtys() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar =
        data && data != null ? (JSON.parse(data) as OrderLine[]) : [];

      // Actualiza la cantidad en el carro de compras
      this.getShoppingCarTotalAmount();

      console.log('---------------setDefaultQtys() {---------------');
      console.log('this.shoppingCar');
      console.log(this.shoppingCar);
      this.PromoConditionGroup.forEach((promoGroup) => {
        promoGroup.PromoCondition.forEach((pc) => {
          // Lista de Articulos
          if (pc.TypeCondition == 1 && pc.OperatorValue == 1) {
            pc.PromoConditionsList.forEach((listCond) => {
              this.shoppingCar
                .filter(
                  (l) =>
                    l.promoId == '' &&
                    l.itemId == listCond.Value &&
                    l.unitId == listCond.UnitId
                )
                .forEach((line) => {
                  let promoCond = this.PromoConditionGroup.filter(
                    (pcg) => pcg.GroupID == pc.Group
                  )[0]
                    .PromoCondition.filter((data) => data.RecId == pc.RecId)[0]
                    .PromoConditionsList.filter(
                      (cl) => cl.Value == listCond.Value
                    )[0];

                  promoCond.SaleQty = line.salesQuantity;

                  promoCond.TotalSale = Number.parseFloat(
                    (
                      promoCond.SaleQty *
                      (promoCond.Amount + promoCond.Taxes)
                    ).toFixed(2)
                  );
                });
            });
          }
          // Articulos individuales
          else if (pc.TypeCondition == 1 && pc.OperatorValue == 2) {
            this.shoppingCar
              .filter(
                (l) =>
                  l.promoId == '' &&
                  l.itemId == pc.Value &&
                  l.unitId == pc.UnitIdValue
              )
              .forEach((line) => {
                let promoCond = this.PromoConditionGroup.filter(
                  (pcg) => pcg.GroupID == pc.Group
                )[0].PromoCondition.filter((data) => data.RecId == pc.RecId)[0];

                promoCond.SaleQty = line.salesQuantity;

                promoCond.TotalSale = Number.parseFloat(
                  (
                    promoCond.SaleQty *
                    (promoCond.Amount + promoCond.Taxes)
                  ).toFixed(2)
                );
              });
          }
        });
      });
    });

    console.log('---------------FIN FIN setDefaultQtys() {---------------');
  }

  validatePromo108Res() {
    if (this.promo.PromoResults.filter((pr) => pr.SubType == 108).length > 0) {
      this.promoHas108Res = true;
    }
  }

  decomposePromo(Promo: PromoTableView) {
    // Promo.PromoConditions.sort((n1, n2) => { return n1.LineNum - n2.LineNum; });
    Promo.PromoConditions.sort(function (a, b) {
      return a.Group - b.Group || a.LineNum - b.LineNum;
    });

    // Conditions
    Promo.PromoConditions.forEach((cond, index) => {
      cond.showed = false;
      cond.TotalSale = 0;
      cond.SaleQty = 0;

      cond.Amount_Taxes = Number.parseFloat(
        (cond.Amount + cond.Taxes).toFixed(2)
      );

      cond.PromoConditionsList.forEach((pcl) => {
        pcl.Amount_Taxes = Number.parseFloat(
          (pcl.Amount + pcl.Taxes).toFixed(2)
        );
        pcl.TotalSale = 0;
        pcl.SaleQty = 0;
      });

      /*
      Sort ItemConditionList
      1 - Amount (Desc)
      2 - SalesQuantity
      3 - ItemId
      */
      cond.PromoConditionsList.sort(function (a, b) {
        return b.Amount_Taxes - a.Amount_Taxes || a.SaleQty - b.SaleQty;
      });

      // AND==0 -- OR==1 and not lastIndex
      if (cond.ConditionOr == 0 && Promo.PromoConditions.length - 1 != index) {
        cond.LineRequired = cond.LineNum + 1;
      } else {
        cond.LineRequired = -1;
      }

      // SI existe
      if (
        this.PromoConditionGroup.filter((cg) => cg.GroupID == cond.Group)
          .length >= 1
      ) {
        this.PromoConditionGroup.filter(
          (cg) => cg.GroupID == cond.Group
        )[0].PromoCondition.push(cond);
      }
      // NO existe
      else {
        this.PromoConditionGroup.push(
          new PromoConditionGroup(cond.Group, cond)
        );
      }
    });

    this.PromoConditionGroup.forEach((group, index) => {
      group.GroupID = index + 1;
      group.PromoCondition.forEach((pc) => {
        pc.Group = index + 1;
      });

      // AND==0 -- OR==1 and != lastIndex
      if (
        group.PromoCondition[group.PromoCondition.length - 1].ConditionOr ==
          0 &&
        this.PromoConditionGroup.length - 1 != index
      ) {
        this.PromoConditionGroup[index].GroupRequired =
          group.PromoCondition[group.PromoCondition.length - 1].Group + 1;
      } else {
        this.PromoConditionGroup[index].GroupRequired = -1;
      }
    });

    console.log(this.PromoConditionGroup);

    // Setea cantidades por defecto de los articulos que estan en condiciones
    // y que se encuentran libres (sin promo) en el carro de compras
    this.setDefaultQtys();
  }

  getInventoryItems() {
    this.storage.get('productos').then((data) => {
      if (data && data != null) {
        this.itemInfo = JSON.parse(data);
      }
    });

    this.getShoppingCar();
  }

  joinShoppingCar_PromoItem() {
    this.PromoItemGroup = [];

    this.asocItemGroupToShoppingCar();

    this.shoppingCar.forEach((ol) => {
      this.PromoItemGroup.push(
        new PromoItemGroup(
          ol.itemId,
          ol.salesQuantity,
          ol.totalSale,
          ol.ItemGroupId
        )
      );
    });

    this.PromoConditionGroup.forEach((cg) => {
      // Obtener condiciones tipo articulo individual con totalsale mayor a 0
      let itemConditions = cg.PromoCondition.filter(
        (pc) =>
          pc.TypeCondition == 1 && pc.OperatorValue == 2 && pc.TotalSale > 0
      );
      let itemConditionList = cg.PromoCondition.filter(
        (pc) => pc.TypeCondition == 1 && pc.OperatorValue == 1
      );

      itemConditions.forEach((ic) => {
        if (
          this.PromoItemGroup.filter((pig) => pig.itemId == ic.Value).length > 0
        ) {
          let itemGroup = this.PromoItemGroup.filter(
            (pig) => pig.itemId == ic.Value
          )[0];
          itemGroup.salesQuantity += ic.SaleQty;
          itemGroup.totalSale += ic.TotalSale;
        } else {
          this.PromoItemGroup.push(
            new PromoItemGroup(
              ic.Value,
              ic.SaleQty,
              ic.TotalSale,
              ic.ItemGroupId
            )
          );
        }
      });

      itemConditionList.forEach((icl) => {
        // filtrar items de la lista con venta mayor a 0
        icl.PromoConditionsList.filter((lc) => lc.TotalSale > 0).forEach(
          (listitem) => {
            if (
              this.PromoItemGroup.filter((pig) => pig.itemId == listitem.Value)
                .length > 0
            ) {
              let itemGroup = this.PromoItemGroup.filter(
                (pig) => pig.itemId == listitem.Value
              )[0];
              itemGroup.salesQuantity += listitem.SaleQty;
              itemGroup.totalSale += listitem.TotalSale;
            } else {
              this.PromoItemGroup.push(
                new PromoItemGroup(
                  listitem.Value,
                  listitem.SaleQty,
                  listitem.TotalSale,
                  listitem.ItemGroupId
                )
              );
            }
          }
        );
      });
    });

    console.log(this.PromoItemGroup);
  }

  asocItemGroupToShoppingCar() {
    this.shoppingCar.forEach((ordLine) => {
      let item = this.itemInfo.filter((i) => i.ItemId == ordLine.itemId);
      if (item.length > 0) {
        ordLine.ItemGroupId = item[0].ItemGroupId;
      }
    });
  }

  async getShoppingCar() {
    this.loadingShoppingCar = true;
    await this.storage
      .get('carrito')
      .then((data) => {
        // console.log(data);
        this.shoppingCar =
          data && data != null ? (JSON.parse(data) as OrderLine[]) : [];
        console.log(this.shoppingCar);
        this.getShoppingCarTotalAmount();
        this.loadingShoppingCar = false;
      })
      .catch(() => {
        this.loadingShoppingCar = false;
      });
  }

  getShoppingCarTotalAmount() {
    this.shoppingCarTotalAmount = 0;

    this.shoppingCar.forEach((item) => {
      if (item.promoId != '') {
        this.shoppingCarTotalAmount += item.lnAmnt;
      } else {
        this.shoppingCarTotalAmount =
          this.shoppingCarTotalAmount + item.totalSale;
      }
    });

    // this.shoppingCar.forEach(item => {
    //   this.shoppingCarTotalAmount += item.totalSale;
    // });

    this.shoppingCarTotalAmount = Number.parseFloat(
      this.shoppingCarTotalAmount.toFixed(2)
    );
  }

  /**
   * Get total amount of the items included in the actual promo
   */
  getPromoItemAmount() {
    this.ActualPromoAmount = 0;
    this.PromoConditionGroup.forEach((promoGroup) => {
      promoGroup.PromoCondition.filter((pc) => pc.TypeCondition == 1).forEach(
        (prodCond) => {
          // Lista
          if (prodCond.OperatorValue == 1) {
            prodCond.PromoConditionsList.filter(
              (pcl) => pcl.TotalSale > 0
            ).forEach((pclItem) => {
              this.ActualPromoAmount += pclItem.TotalSale;
            });
          }
          // Articulo individual
          else if (prodCond.OperatorValue == 2) {
            this.ActualPromoAmount += prodCond.TotalSale;
          }
        }
      );
    });
  }

  cancelPromo() {
    this.viewCtrl.dismiss('CANCEL');
  }

  multiplyResults() {
    let minFactor = 1;
    this.timesApply.forEach((v, i) => {
      minFactor = i == 0 || (0 < v && v < minFactor) ? v : minFactor;
    });

    console.log('minFactor');
    console.log(minFactor);

    // multiplica por el factor todos aquellos resultados que sean regalias
    this.promo.PromoResults.filter((pr) => pr.SubType == 108).forEach((res) => {
      if (
        this.promoBase.PromoResults.filter((pb) => pb.RecId == res.RecId)
          .length > 0
      ) {
        // Esto para tomar el QtyProduct original y no el actual
        res.QtyProduct =
          this.promoBase.PromoResults.filter((pb) => pb.RecId == res.RecId)[0]
            .QtyProduct * minFactor;
        console.log('res modified');
        console.log(res);
      }
    });

    console.log('promo result');
    console.log(this.promo);
  }

  async applyPromo() {
    await this.getShoppingCar();
    this.getPromoItemAmount();

    // console.log('Applying promo');
    // console.log('this.ActualPromoAmount');
    // console.log(this.ActualPromoAmount);

    if (this.validPromo()) {
      console.log('PROMO VÁLIDA');
      if (this.promoHas108Res && this.timesApply.length > 0) {
        console.log('********* PROMO MULTIPLICADA *********');
        this.multiplyResults();
      }

      this.nextStep();
    } else {
      let liBody: string = '';
      let resp = '';
      this.conditionInError.forEach((pc) => {
        resp = this.getErrorDesc(pc);
        liBody +=
          '<li>Grupo #' +
          pc.Group +
          ' - Línea #' +
          pc.LineNum +
          ':</li>' +
          resp;
      });

      let alert = await this.alert.create({
        header: 'Condiciones Incompletas!',
        subHeader: 'No se ha cumplido con todas las condiciones solicitadas',
        message:
          `<p>Favor verificar las condiciones que se listan a continuación.</p>
        <p>Condiciones</p>
        <ul>` +
          liBody +
          `</ul>`,
        cssClass: 'ol',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          },
        ],
        backdropDismiss: true,
      });
      alert.present();
    }
  }

  getErrorDesc(pc: PromoConditionsView) {
    let resp = '<ol class="ol">Debe agregar ';

    // es una lista o un igual a
    if (
      pc.TypeCondition == 1 &&
      (pc.OperatorValue == 2 || pc.OperatorValue == 1)
    ) {
      resp += pc.TypeValue == 1 ? '<a>una cantidad ' : '<a>un monto ';

      switch (pc.Operator) {
        // igual a
        case 1:
          resp += 'igual a ' + pc.FromQty + '</a>';
          break;
        // mayor o igual q
        case 2:
          resp += 'mayor o igual que ' + pc.FromQty + '</a>';
          break;
        // menor o igual q
        case 3:
          resp += 'menor o igual que ' + pc.FromQty + '</a>';
          break;
        // entre
        case 4:
          resp += 'entre ' + pc.FromQty + ' y ' + pc.ToQty + '</a>';
          break;
        // clear resp
        default:
          resp = '<ol>';
          break;
      }
    }

    // Es una lista de articulos ... tiene atleastqty
    if (pc.TypeCondition == 1 && pc.OperatorValue == 1) {
      resp +=
        ' de almenos <a>' +
        pc.AtLeastQtyConditions +
        ' artículo(s) distinto(s).</a>';
    }

    return resp + '</ol>';
  }

  async nextStep() {
    let profileModal = await this.modalCtrl.create({
      component: PromoResultComponent,
      componentProps: {
        promoHeader: this.promo,
        promoConditionGroup: this.PromoConditionGroup,
        itemInfo: this.itemInfo,
      },
      backdropDismiss: false,
    });
    profileModal.present();
    profileModal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null && data == 'OK') {
        this.viewCtrl.dismiss('OK');
      }
    });
  }

  async displayInfoDialog() {
    let toast = await this.toastCtrl.create({
      message:
        'Sección para configurar las promociones aplicables a su dirección',
      duration: 3000,
    });
    toast.present();
  }

  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que al presionar el boton de - se
   * decremente una vez la cantidad.
   * @param cond objeto de tipo OrderLine, utilizado para identificar el item que se va decrementar
   */
  reduceQuantity(cond: PromoConditionsView) {
    let promoCond = this.PromoConditionGroup.filter(
      (pcg) => pcg.GroupID == cond.Group
    )[0].PromoCondition.filter((data) => data.RecId == cond.RecId)[0];

    promoCond.SaleQty = promoCond.SaleQty <= 1 ? 0 : promoCond.SaleQty - 1;

    promoCond.TotalSale = Number.parseFloat(
      (promoCond.SaleQty * (promoCond.Amount + promoCond.Taxes)).toFixed(2)
    );
  }

  /**
   * Metodo utilizado para incrementar la cantidad de producto de manera tal que al presionar el boton de + se
   * incremente una vez la cantidad.
   * @param cond objeto de tipo OrderLine, utilizado para identificar el item que se va incrementar
   */
  increaseQuantity(cond: PromoConditionsView) {
    let promoCond = this.PromoConditionGroup.filter(
      (pcg) => pcg.GroupID == cond.Group
    )[0].PromoCondition.filter((data) => data.RecId == cond.RecId)[0];

    promoCond.SaleQty++;

    promoCond.TotalSale = Number.parseFloat(
      (promoCond.SaleQty * (promoCond.Amount + promoCond.Taxes)).toFixed(2)
    );
  }

  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que al presionar el boton de - se
   * decremente una vez la cantidad.
   * @param condList objeto de tipo OrderLine, utilizado para identificar el item que se va decrementar
   */
  reduceQuantityItemList(
    itemListCond: PromoConditionsView,
    condList: PromoConditionsListView
  ) {
    let promoCond = this.PromoConditionGroup.filter(
      (pcg) => pcg.GroupID == itemListCond.Group
    )[0]
      .PromoCondition.filter((data) => data.RecId == itemListCond.RecId)[0]
      .PromoConditionsList.filter((cl) => cl.Value == condList.Value)[0];

    promoCond.SaleQty = promoCond.SaleQty <= 1 ? 0 : promoCond.SaleQty - 1;

    promoCond.TotalSale = Number.parseFloat(
      (promoCond.SaleQty * (promoCond.Amount + promoCond.Taxes)).toFixed(2)
    );
  }

  /**
   * Metodo utilizado para incrementar la cantidad de producto de manera tal que al presionar el boton de + se
   * incremente una vez la cantidad.
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va incrementar
   */
  increaseQuantityItemList(
    itemListCond: PromoConditionsView,
    condList: PromoConditionsListView
  ) {
    let promoCond = this.PromoConditionGroup.filter(
      (pcg) => pcg.GroupID == itemListCond.Group
    )[0]
      .PromoCondition.filter((data) => data.RecId == itemListCond.RecId)[0]
      .PromoConditionsList.filter((cl) => cl.Value == condList.Value)[0];

    promoCond.SaleQty++;

    promoCond.TotalSale = Number.parseFloat(
      (promoCond.SaleQty * (promoCond.Amount + promoCond.Taxes)).toFixed(2)
    );
  }

  changeValueKeyUp(_event, cond: PromoConditionsView) {
    let promoCond = this.PromoConditionGroup.filter(
      (pcg) => pcg.GroupID == cond.Group
    )[0].PromoCondition.filter((data) => data.RecId == cond.RecId)[0];

    promoCond.SaleQty =
      promoCond.SaleQty && this.validQuantity(promoCond.SaleQty.toString())
        ? promoCond.SaleQty
        : 0;

    promoCond.TotalSale = Number.parseFloat(
      (promoCond.SaleQty * (promoCond.Amount + promoCond.Taxes)).toFixed(2)
    );
  }

  changeValueKeyUpItemList(
    _event,
    itemListCond: PromoConditionsView,
    condList: PromoConditionsListView
  ) {
    let promoCond = this.PromoConditionGroup.filter(
      (pcg) => pcg.GroupID == itemListCond.Group
    )[0]
      .PromoCondition.filter((data) => data.RecId == itemListCond.RecId)[0]
      .PromoConditionsList.filter((cl) => cl.Value == condList.Value)[0];

    promoCond.SaleQty =
      promoCond.SaleQty && this.validQuantity(promoCond.SaleQty.toString())
        ? promoCond.SaleQty
        : 0;

    promoCond.TotalSale = Number.parseFloat(
      (promoCond.SaleQty * (promoCond.Amount + promoCond.Taxes)).toFixed(2)
    );
  }

  validQuantity(quantity: string) {
    return /^\d+$/.test(quantity);
  }

  async displayItemDetails(ItemID: string) {
    if (!this.itemDetailExecuting) {
      this.displayItemDetails_Aux(ItemID);
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Consulta de información en ejecución!',
        duration: 2000,
      });
      toast.present();
    }
  }

  displayItemDetails_Aux(ItemID: string) {
    this.itemDetailExecuting = true;

    this.webProvider.getProductsByItem(ItemID, 'AD').subscribe(
      async (res) => {
        let data = res as InventoryItem[];
        if (data && data[0] != null) {
          let popOver = await this.popoverCtrl.create({
            cssClass: 'custom-popover',
            component: ProductDetailComponent,
            componentProps: { inventoryItem: data[0] },
          });
          popOver.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: 'Producto sin información detallada!',
            duration: 3000,
          });
          toast.present();
        }
        this.itemDetailExecuting = false;
      },
      () => {
        this.itemDetailExecuting = false;
      }
    );
  }

  async previewShoppingCar() {
    if (this.shoppingCar.length > 0) {
      let popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: PrevShoppingCarComponent,
        componentProps: {
          tmpShoppingCar: this.shoppingCar,
          showBtn: false,
          promoId: '',
          pSequence: -1,
        },
      });
      popover.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Carrito de compras sin productos!',
        duration: 3000,
      });
      toast.present();
    }
  }

  generateExpression() {
    // Reset conditions with status 'Error'
    this.conditionInError = [];

    this.PromoConditionGroup.sort(function (a, b) {
      return a.GroupID - b.GroupID;
    });

    let condition: boolean = false;
    let expression: string = '';
    let createExpression: boolean = false;
    let times: number = 0;
    this.timesApply = [];
    let qtyList: number = 0;

    this.PromoConditionGroup.forEach((condGroup, groupIndex) => {
      condGroup.PromoCondition.forEach((pc, i) => {
        /*
        0- Sin Definir
        1- Articulo
        2- GrupoArticulo
        3- Monto Total
        */
        // ********************* Articulo *********************
        switch (pc.TypeCondition) {
          // Articulo
          case 1:
            /*
            0- Sin Definir
            1- lista
            2- Igual(Articulo)
            */
            switch (pc.OperatorValue) {
              // ########################## Lista ##########################
              case 1:
                /*
                0- sin definir
                1- cantidad
                2- monto
                */
                switch (pc.TypeValue) {
                  // Cantidad
                  case 1:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.SaleQty;
                        });

                        condition =
                          qtyList == pc.FromQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.SaleQty > 0
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        // condition = pc.PromoConditionsList.filter(pcl => pcl.SaleQty == pc.FromQty).length >= pc.AtLeastQtyConditions;
                        // createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.SaleQty;
                        });

                        condition =
                          qtyList >= pc.FromQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.SaleQty > 0
                          ).length >= pc.AtLeastQtyConditions;
                        // condition = pc.PromoConditionsList.filter(pcl => pcl.SaleQty >= pc.FromQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(pc.SaleQty / pc.FromQty);

                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }

                        break;
                      // Menor o igual q
                      case 3:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.SaleQty;
                        });

                        condition =
                          qtyList <= pc.FromQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.SaleQty > 0
                          ).length >= pc.AtLeastQtyConditions;

                        // condition = pc.PromoConditionsList.filter(pcl => pcl.SaleQty <= pc.FromQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.SaleQty;
                        });

                        condition =
                          pc.FromQty <= qtyList &&
                          qtyList <= pc.ToQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.SaleQty > 0
                          ).length >= pc.AtLeastQtyConditions;

                        // condition = pc.PromoConditionsList.filter(pcl => pc.FromQty <= pcl.SaleQty && pcl.SaleQty <= pc.ToQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;

                  // Monto
                  case 2:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.TotalSale;
                        });

                        condition =
                          qtyList == pc.FromQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.TotalSale > 0
                          ).length >= pc.AtLeastQtyConditions;

                        // condition = pc.PromoConditionsList.filter(pcl => pcl.TotalSale == pc.FromQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.TotalSale;
                        });

                        condition =
                          qtyList >= pc.FromQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.TotalSale > 0
                          ).length >= pc.AtLeastQtyConditions;
                        // condition = pc.PromoConditionsList.filter(pcl => pcl.TotalSale >= pc.FromQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(pc.TotalSale / pc.FromQty);

                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }
                        break;
                      // Menor o igual q
                      case 3:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.TotalSale;
                        });

                        condition =
                          qtyList <= pc.FromQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.TotalSale > 0
                          ).length >= pc.AtLeastQtyConditions;
                        // condition = pc.PromoConditionsList.filter(pcl => pcl.TotalSale <= pc.FromQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        qtyList = 0;
                        pc.PromoConditionsList.forEach((e) => {
                          qtyList += e.TotalSale;
                        });

                        condition =
                          pc.FromQty <= qtyList &&
                          qtyList <= pc.ToQty &&
                          pc.PromoConditionsList.filter(
                            (pcl) => pcl.TotalSale > 0
                          ).length >= pc.AtLeastQtyConditions;
                        // condition = pc.PromoConditionsList.filter(pcl => pc.FromQty <= pcl.TotalSale && pcl.TotalSale <= pc.ToQty).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;
                  default:
                    console.log('TypeValue sin definir');
                    break;
                }
                break;
              // ########################## Igual(Articulo) ##########################
              case 2:
                /*
                0- sin definir
                1- cantidad
                2- monto
                */
                switch (pc.TypeValue) {
                  // Cantidad
                  case 1:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        condition = pc.SaleQty == pc.FromQty;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        condition = pc.SaleQty >= pc.FromQty;
                        createExpression = true;

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(pc.SaleQty / pc.FromQty);
                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }
                        break;
                      // Menor o igual q
                      case 3:
                        condition = pc.SaleQty <= pc.FromQty;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        condition =
                          pc.FromQty <= pc.SaleQty && pc.SaleQty <= pc.ToQty;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;
                  // Monto
                  case 2:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        condition = pc.TotalSale == pc.FromQty;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        condition = pc.TotalSale >= pc.FromQty;
                        createExpression = true;

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(pc.TotalSale / pc.FromQty);
                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }
                        break;
                      // Menor o igual q
                      case 3:
                        condition = pc.TotalSale <= pc.FromQty;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        condition =
                          pc.FromQty <= pc.TotalSale &&
                          pc.TotalSale <= pc.ToQty;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;
                  default:
                    console.log('TypeValue sin definir');
                    break;
                }
                break;
              default:
                console.log('OperatorValue sin Definir');
                break;
            }
            break;

          // ********************* Grupo Articulo *********************
          case 2:
            this.joinShoppingCar_PromoItem();
            /*
              0- Sin Definir
              1- lista
              2- Igual(Articulo)
              */
            switch (pc.OperatorValue) {
              // ########################## Lista ##########################
              case 1:
                let countItems = 0;
                /*
                0- sin definir
                1- cantidad
                2- monto
                */
                switch (pc.TypeValue) {
                  // Cantidad
                  case 1:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.salesQuantity == pc.FromQty
                          ).length;
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        let qty = 0;

                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.salesQuantity >= pc.FromQty
                          ).length;
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.salesQuantity >= pc.FromQty
                          ).forEach((element) => {
                            qty = qty + element.salesQuantity;
                          });
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(qty / pc.FromQty);
                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }
                        break;
                      // Menor o igual q
                      case 3:
                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.salesQuantity <= pc.FromQty
                          ).length;
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pc.FromQty <= pig.salesQuantity &&
                              pig.salesQuantity <= pc.ToQty
                          ).length;
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;

                  // Monto
                  case 2:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.totalSale == pc.FromQty
                          ).length;
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        let ts = 0;

                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.totalSale >= pc.FromQty
                          ).length;
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.totalSale >= pc.FromQty
                          ).forEach((element) => {
                            ts = ts + element.salesQuantity;
                          });
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(ts / pc.FromQty);
                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }

                        break;
                      // Menor o igual q
                      case 3:
                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pig.totalSale <= pc.FromQty
                          ).length;
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        pc.PromoConditionsList.forEach((e) => {
                          countItems += this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == e.ItemGroupId &&
                              pc.FromQty <= pig.totalSale &&
                              pig.totalSale == pc.ToQty
                          ).length;
                        });
                        condition = countItems >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;
                  default:
                    console.log('TypeValue sin definir');
                    break;
                }
                break;
              // ########################## Igual(Articulo) ##########################
              case 2:
                /*
                0- sin definir
                1- cantidad
                2- monto
                */
                switch (pc.TypeValue) {
                  // Cantidad
                  case 1:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pc.FromQty == pig.salesQuantity
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        let qty = 0;

                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pig.salesQuantity >= pc.FromQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        this.PromoItemGroup.filter(
                          (pig) =>
                            pig.ItemGroupId == pc.ItemGroupId &&
                            pig.salesQuantity >= pc.FromQty
                        ).forEach((i) => {
                          qty = qty + i.salesQuantity;
                        });

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(qty / pc.FromQty);
                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }

                        break;
                      // Menor o igual q
                      case 3:
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pig.salesQuantity <= pc.FromQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pc.FromQty <= pig.salesQuantity &&
                              pig.salesQuantity <= pc.ToQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;
                  // Monto
                  case 2:
                    /*
                    0- sin definir
                    1- Igual A
                    2- Mayor o igual q
                    3- Menor o igual q
                    4- Entre
                    */
                    switch (pc.Operator) {
                      // Igual A
                      case 1:
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pig.totalSale == pc.FromQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Mayor o igual q
                      case 2:
                        let ts = 0;
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pig.totalSale >= pc.FromQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;

                        this.PromoItemGroup.filter(
                          (pig) =>
                            pig.ItemGroupId == pc.ItemGroupId &&
                            pig.totalSale >= pc.FromQty
                        ).forEach((i) => {
                          ts = ts + i.totalSale;
                        });

                        if (this.promoHas108Res && condition) {
                          times = Math.trunc(ts / pc.FromQty);
                          this.timesApply.push(
                            times <= pc.howManyTimesApply ||
                              pc.howManyTimesApply == 0
                              ? times
                              : pc.howManyTimesApply
                          );
                        }

                        break;
                      // Menor o igual q
                      case 3:
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pig.totalSale <= pc.FromQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      // Entre
                      case 4:
                        condition =
                          this.PromoItemGroup.filter(
                            (pig) =>
                              pig.ItemGroupId == pc.ItemGroupId &&
                              pc.FromQty <= pig.totalSale &&
                              pig.totalSale <= pc.ToQty
                          ).length >= pc.AtLeastQtyConditions;
                        createExpression = true;
                        break;
                      default:
                        console.log('Operator sin definir');
                        break;
                    }
                    break;
                  default:
                    console.log('TypeValue sin definir');
                    break;
                }
                break;
              default:
                console.log('OperatorValue sin Definir');
                break;
            }
            break;

          // ********************* Monto Total *********************
          case 3:
            /*
            0- sin definir
            1- Igual A
            2- Mayor o igual q
            3- Menor o igual q
            4- Entre
            */
            switch (pc.Operator) {
              // Igual A
              case 1:
                condition =
                  this.shoppingCarTotalAmount + this.ActualPromoAmount ==
                  pc.FromQty;
                createExpression = true;
                break;
              // Mayor o igual q
              case 2:
                console.log('this.shoppingCarTotalAmount');
                console.log(this.shoppingCarTotalAmount);
                console.log('this.ActualPromoAmount');
                console.log(this.ActualPromoAmount);
                console.log('pc.FromQty');
                console.log(pc.FromQty);
                console.log(
                  '(this.shoppingCarTotalAmount + this.ActualPromoAmount)'
                );
                console.log(
                  this.shoppingCarTotalAmount + this.ActualPromoAmount
                );

                condition =
                  this.shoppingCarTotalAmount + this.ActualPromoAmount >=
                  pc.FromQty;
                createExpression = true;

                if (this.promoHas108Res && condition) {
                  times = Math.trunc(
                    (this.shoppingCarTotalAmount + this.ActualPromoAmount) /
                      pc.FromQty
                  );

                  this.timesApply.push(
                    times <= pc.howManyTimesApply || pc.howManyTimesApply == 0
                      ? times
                      : pc.howManyTimesApply
                  );
                }

                break;
              // Menor o igual q
              case 3:
                condition =
                  this.shoppingCarTotalAmount + this.ActualPromoAmount <=
                  pc.FromQty;
                createExpression = true;
                break;
              // Entre
              case 4:
                condition =
                  pc.FromQty <=
                    this.shoppingCarTotalAmount + this.ActualPromoAmount &&
                  this.shoppingCarTotalAmount + this.ActualPromoAmount <=
                    pc.ToQty;
                createExpression = true;
                break;
              default:
                console.log('Operator sin definir');
                break;
            }
            break;
          default:
            console.log('Type Condition sin Definir');
            break;
        }

        if (createExpression) {
          // Save Promo conditions with status 'False'
          if (!condition) {
            this.conditionInError.push(pc);
          }

          expression = new PromoTableView().buildExpression(
            expression,
            i == 0 ? pc.Group - 1 : condGroup.PromoCondition[i - 1].Group,
            pc.Group,
            i == condGroup.PromoCondition.length - 1
              ? pc.Group + 1
              : condGroup.PromoCondition[i + 1].Group,
            pc.ConditionOr == 0 ? ' & ' : ' | ',
            condition ? ' 1 ' : ' 0 ',
            // i == condGroup.PromoCondition.length - 1);
            groupIndex == this.PromoConditionGroup.length - 1 &&
              i == condGroup.PromoCondition.length - 1
          );
        }

        createExpression = false;

        // console.log('Actual Condition');
        // console.log(pc);
        // console.log('Actual expression');
        // console.log(expression);
      });
    });

    console.log(' -------- TIMES APPLY -------- ');
    console.log(this.timesApply);
    console.log(' -------- APPLY 108 Results -------- ');
    console.log(this.promoHas108Res);

    return expression;
  }

  validPromo(): boolean {
    let expression = this.generateExpression().replace(/ /g, '');

    if (expression == '') {
      return true;
    } else {
      console.log(expression);
      console.log(new PromoTableView().eval(expression));
      return new PromoTableView().eval(expression);
    }
  }
}
