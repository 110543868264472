import { Component } from '@angular/core';
import {
  AlertController,
  IonRefresher,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { DataArea } from '../../../businessCore/oc-models/DataArea';
import { SearchProviderComponent } from '../../../components/oc-components/search-provider/search-provider.component';
import { PurchInfoResult } from '../../OOP/PurchInfoResult.interface';
import { PurchLinesInfoParam } from '../../OOP/PurchLinesInfoParam.interface';
import { PurchOrderRemissionFilterComponent } from '../../components/purch-order-remission-filter/purch-order-remission-filter.component';
import { PurchOrderRemissionFilterPipe } from '../../pipes/PurchOrderRemission.pipe';
import { RemissionService } from '../../services/remission.service';

@Component({
  selector: 'app-remission-list',
  templateUrl: './remission-list.component.html',
  styleUrls: ['./remission-list.component.scss'],
})
export class RemissionListPage {
  loader: HTMLIonLoadingElement;

  empresaSelected: DataArea;
  listPurchOrders: PurchLinesInfoParam[] = [];
  listPurchOrdersPivot: PurchLinesInfoParam[] = [];
  listPurchOrdersToSearch: PurchLinesInfoParam[] = [];
  listVendorsSelected: string[] = [];
  rangeDatesSelected: Date[] = [];

  refresher: boolean = false;

  sortEvents = {
    PurchIdAsc: false,
    PurchIdDesc: true,
    VendorIdAsc: false,
    VendorIdDesc: false,
    VendorNameAsc: false,
    VendorNameDesc: false,
  };

  orderBy: string = '';

  PurchInfoResult: PurchInfoResult;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private reServ: RemissionService,
    private loadingCtrl: LoadingController,
    private PORemissionFilter: PurchOrderRemissionFilterPipe,
    private popoverCtrl: PopoverController,
    private alertController: AlertController,
    private navService: NavigationService
  ) {
    this.loadCompanies();
    this.loadData();
  }

  loadData() {
    let StatusOC = localStorage.getItem('StatusOC');

    if (StatusOC) {
      localStorage.removeItem('StatusOC');
      this.loadPurchOrders(this.empresaSelected);
    }
  }

  searchOC(event) {
    this.listPurchOrdersToSearch = this.PORemissionFilter.transform(
      this.listPurchOrdersPivot,
      event.detail.value
    );
  }

  goToOC() {}

  async loadCompanies() {
    await this.showSpinnerLoading('Cargando Compañías');
    this.reServ.listDataArea = await this.reServ.getDataAreaInfo();
    this.loader.dismiss();
  }

  async openSearchBarDataAreaId() {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: this.reServ.listDataArea,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.loadPurchOrders(data.objSelected);
        }
      }
    });
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async loadPurchOrders(company: any, refresher: boolean = false) {
    if (!refresher) {
      await this.showSpinnerLoading('Cargando Órdenes de Compra.');
    }

    this.empresaSelected = company;
    this.listPurchOrders = await this.reServ.getAllPurchOrders(
      this.empresaSelected.getId()
    );
    //DEBUG
    /*this.listPurchOrders = [
      {
        purchId: 'OC-00140916',
        objectId: 'Objectid',
        objectName: 'ObjectName',
        roleSpecificIds: [
          {
            label: 'Label',
            description: 'Description',
            id: '123',
            isChecked: false,
          },
        ],
        containerIds: [
          {
            label: 'Label',
            description: 'Description',
            id: '123',
            isChecked: false,
          },
        ],
        id: 'Idddd',
        roleSpecificIdsSelected: [
          {
            label: 'Label',
            description: 'Description',
            id: '123',
            isChecked: false,
          },
        ],
        roleSpecificIdsAssistantsSelected: [
          {
            label: 'Label',
            description: 'Description',
            id: '123',
            isChecked: false,
          },
        ],
        containerIdsSelected: [
          {
            label: 'Label',
            description: 'Description',
            id: '123',
            isChecked: false,
          },
        ],
        remissionMode: 'MODE',
        status: 'STATUS',
        createdDate: new Date(),
      },
    ];
    */
    //DEBUG

    this.listPurchOrdersToSearch = this.listPurchOrders;
    this.listPurchOrdersPivot = this.listPurchOrders;

    if (!refresher) {
      this.loader.dismiss();
    }
  }

  async filterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: PurchOrderRemissionFilterComponent,
      componentProps: {
        data: this.listPurchOrders,
        listVendors: this.listVendorsSelected,
        rangeDates: this.rangeDatesSelected,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null && data !== undefined) {
        let listPurchOrdersTmp: PurchLinesInfoParam[] = [];

        if (data.listVendors && data.listVendors.length > 0) {
          this.listVendorsSelected = data.listVendors as string[];
        }

        if (data.rangeDates && data.rangeDates.length > 0) {
          this.rangeDatesSelected = data.rangeDates;
        }

        listPurchOrdersTmp = this.listPurchOrders.filter((x) => {
          if (
            this.listVendorsSelected.length > 0 &&
            !this.listVendorsSelected.some((y) => y.includes(x.objectName))
          ) {
            return false;
          }

          let createdDate = new Date(x.createdDate);
          createdDate.setHours(createdDate.getHours() + 6);
          if (
            this.rangeDatesSelected.length > 0 &&
            !(
              createdDate >= new Date(this.rangeDatesSelected[0]) &&
              createdDate <= new Date(this.rangeDatesSelected[1])
            )
          ) {
            return false;
          }

          return true;
        });

        this.listPurchOrdersPivot =
          listPurchOrdersTmp.length > 0
            ? listPurchOrdersTmp
            : this.listPurchOrders;
        this.listPurchOrdersToSearch = this.listPurchOrdersPivot;
      } else {
        this.listVendorsSelected = [];
        this.rangeDatesSelected = [];
        this.listPurchOrdersPivot = this.listPurchOrders;
        this.listPurchOrdersToSearch = this.listPurchOrders;
      }
    });

    this.setListToShow();
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  private setSelectedOrder(orderType: string) {
    this.sortEvents.PurchIdAsc = 'PurchIdAsc' === orderType;
    this.sortEvents.PurchIdDesc = 'PurchIdDesc' === orderType;
    this.sortEvents.VendorIdAsc = 'VendorIdAsc' === orderType;
    this.sortEvents.VendorIdDesc = 'VendorIdDesc' === orderType;
    this.sortEvents.VendorNameAsc = 'VendorNameAsc' === orderType;
    this.sortEvents.VendorNameDesc = 'VendorNameDesc' === orderType;
  }

  async presentSortAlert() {
    let alert = await this.alertController.create({
      header: '¿Cómo ordenar las OCs?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Orden de Compra (A -> Z)',
          'PurchIdAsc',
          this.sortEvents.PurchIdAsc
        ),
        this.getInputAlert(
          'radio',
          'Orden de Compra (Z -> A)',
          'PurchIdDesc',
          this.sortEvents.PurchIdDesc
        ),
        this.getInputAlert(
          'radio',
          'Código de Proveedor (A -> Z)',
          'VendorIdAsc',
          this.sortEvents.VendorIdAsc
        ),
        this.getInputAlert(
          'radio',
          'Código de Proveedor (Z -> A)',
          'VendorIdDesc',
          this.sortEvents.VendorIdDesc
        ),
        this.getInputAlert(
          'radio',
          'Nombre de Proveedor (A -> Z)',
          'VendorNameAsc',
          this.sortEvents.VendorNameAsc
        ),
        this.getInputAlert(
          'radio',
          'Nombre de Proveedor (Z -> A)',
          'VendorNameDesc',
          this.sortEvents.VendorNameDesc
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow();
            }
          },
        },
      ],
    });
    alert.present();
  }

  private setListToShow() {
    Object.keys(this.sortEvents).forEach((key) => {
      if (this.sortEvents[key]) {
        switch (key) {
          case 'PurchIdAsc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) => a.purchId.localeCompare(b.purchId)
            );
            break;

          case 'PurchIdDesc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) => b.purchId.localeCompare(a.purchId)
            );
            break;

          case 'VendorIdAsc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                a.objectName
                  .trim()
                  .split('|')[0]
                  .localeCompare(b.objectName.trim().split('|')[0])
            );
            break;

          case 'VendorIdDesc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                b.objectName
                  .trim()
                  .split('|')[0]
                  .localeCompare(a.objectName.trim().split('|')[0])
            );
            break;

          case 'VendorNameAsc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                a.objectName
                  .trim()
                  .split('|')[1]
                  .localeCompare(b.objectName.trim().split('|')[1])
            );
            break;

          case 'VendorNameDesc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                b.objectName
                  .trim()
                  .split('|')[1]
                  .localeCompare(a.objectName.trim().split('|')[1])
            );
            break;

          default:
            break;
        }
      }
    });
  }

  async goToDetails(PurchOrder: PurchLinesInfoParam) {
    if (!PurchOrder.remissionMode) {
      PurchOrder.remissionMode = 'Ordered';
    }

    this.navService.navigateTo(NavigationRoutes.RemissionLinesPage, {
      data: PurchOrder,
      company: this.empresaSelected.getId(),
    });
  }

  swipeEvent(event, PurchOrder: PurchLinesInfoParam) {
    if (event.direction === 2) {
      this.goToDetails(PurchOrder);
    }
  }

  async doRefresh(event) {
    let refresher: IonRefresher = event.detail;
    this.refresher = true;
    if (this.empresaSelected) {
      await this.loadPurchOrders(this.empresaSelected, this.refresher);
    }
    this.listVendorsSelected = [];
    this.rangeDatesSelected = [];

    this.setListToShow();

    this.refresher = false;
    refresher.complete();
  }
}
