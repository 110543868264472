import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-tutorial-video',
  templateUrl: './tutorial-video.component.html',
  styleUrls: ['./tutorial-video.component.scss'],
})
export class TutorialVideoComponent {
  /**
   * Attribute related with the video component that is displayed in the html section
   * with the template reference variable #videos
   */
  @ViewChild('tutorialVideo') tutorialVideo: ElementRef;

  showHeader: boolean = true;
  showPlayButton: boolean = true;

  constructor(public navParams: NavParams, public viewCtrl: PopoverController) {
    if (this.navParams.get('showHeader') != null) {
      this.showHeader = this.navParams.get('showHeader');
    }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  playVideo() {
    if (this.tutorialVideo.nativeElement.paused) {
      this.tutorialVideo.nativeElement.play();
    }
    this.showPlayButton = false;
  }

  nextStep() {
    this.viewCtrl.dismiss();
  }
}
