// Native - Plugin
import { Component } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
// Providers
import { webServiceProvider } from './../../provider/webServiceProvider';

// BusinessCore
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Platform } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { CustomerData } from './../../businessCore/CustomerData';
import { RegisterData } from './../../businessCore/RegisterData';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  register_data: RegisterData = new RegisterData();
  customer_info: CustomerData = new CustomerData();
  loadingDirections: boolean = true;
  directionsError: boolean = false;

  /**
   * Class Constructor with these injections:
   * @param _apiProvider Web service provider
   * @param storage an easy way to store key/value pairs and JSON objects
   * @param platform  used to get information about your current device.
   * @param geolocation provides information about the device's location,
   * such as latitude and longitude.
   */
  constructor(
    public _apiProvider: webServiceProvider,
    public storage: StorageService,
    public platform: Platform,
    public geolocation: Geolocation,
    private callNumber: CallNumber
  ) {
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
        this._apiProvider
          .getCustomer(this.register_data.id_card)
          .subscribe((res) => {
            let data = res as any[];
            if (data && data.length > 0 && data[0]) {
              this.customer_info = data[0] as CustomerData;
            } else {
              this.directionsError = true;
            }
            this.loadingDirections = false;
          });
      } else {
        this.loadingDirections = false;
        this.directionsError = true;
      }
    });
  }

  /**
   * Metodo utilizado para inicializar el mapa con una determinada direccion (lat - lng)
   * El mapa desplegado es aquel que seleccione el usuario y que tenga instalado en el
   * telefono.
   * Se utiliza desde la seccion de Mis Direcciones, donde el cliente puede presionar sobre
   * la opcion de 'ver direccion' y la aplicacion procede a mostrar el punto gps asociado
   * a esta, y tambien la posicion actual donde se encuentra el dispositivo
   * @param direction objeto tipo json con dos atributos (Latitude & Longitude) de la
   * ubicacion que se le asignara al mapa
   */
  startExternalMap(direction) {
    let name = 'Mi dirección';
    if (direction.Latitude != 0 && direction.Longitude != 0) {
      this.geolocation.getCurrentPosition().then((position) => {
        // ios
        if (this.platform.is('ios')) {
          window.open(
            'maps://?q=' +
              name +
              '&saddr=' +
              position.coords.latitude +
              ',' +
              position.coords.longitude +
              '&daddr=' +
              direction.Latitude +
              ',' +
              direction.Longitude,
            '_system'
          );
        }
        // android
        if (this.platform.is('android')) {
          window.open(
            'geo://' +
              position.coords.latitude +
              ',' +
              position.coords.longitude +
              '?q=' +
              direction.Latitude +
              ',' +
              direction.Longitude +
              '(' +
              name +
              ')',
            '_system'
          );
        }
      });
    }
  }

  /**
   * Metodo utilizado para realizar una llamada al agente que tiene asociado el usuario que se encuentra registrado
   * De no existir una sesion activa se llamara a un numero por defecto en la empresa.
   */
  callSeller(NumeroTelVendedor: string) {
    let phoneNumber: string = '+506' + NumeroTelVendedor.replace('-', '');

    this.storage.get('register_data').then((data) => {
      if (data && data != null) {
        this.callNumber.isCallSupported().then(() => {});

        this.callNumber
          .callNumber(phoneNumber, true)
          .then(() => {})
          .catch(() => {});
      }
    });
  }
}
