import { Injectable, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { PurchInfoResult } from '../OOP/PurchInfoResult.interface';
import { PurchRemissionInfoComponent } from '../components/purch-remission-info/purch-remission-info.component';

@Injectable()
export class PurchRemissionInfoService implements OnInit {
  private currentModal: any;
  public loader: HTMLIonLoadingElement;
  public win: any;
  constructor(
    public modalController: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {}

  async presentModal(data) {
    const modal = await this.modalController.create({
      component: PurchRemissionInfoComponent,
      componentProps: {
        data: data,
        modal: this.currentModal,
      },
    });
    this.currentModal = modal;
    await modal.present();
  }

  async onDidDismiss(): Promise<PurchInfoResult> {
    let modalPromise = await this.currentModal.onDidDismiss();

    return await modalPromise.data;
  }

  dismissModal() {
    if (this.currentModal) {
      this.currentModal.dismiss().then(() => {
        this.currentModal = null;
      });
    }
  }

  async showLoading(message: string = '') {
    if (message) {
      this.loader = await this.loadingCtrl.create({
        message: message,
        spinner: 'bubbles',
      });
      await this.loader.present();
    } else {
      await this.loader.dismiss();
    }
  }
}
