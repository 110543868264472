import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AndroidPermissions } from '@ionic-native/android-permissions';

@Injectable()
export class CameraService {
  // Check if application having GPS access permission
  async checkCameraPermission(): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      if (Capacitor.isNativePlatform()) {
        AndroidPermissions.checkPermission(
          AndroidPermissions.PERMISSION.CAMERA
        ).then(
          (result) => {
            if (result.hasPermission) {
              // If having permission show 'Turn On GPS' dialogue
              resolve(true);
            } else {
              // If not having permission ask for permission
              resolve(false);
            }
          },
          (err) => {
            alert(err);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  async requestCameraPermission(): Promise<string> {
    return await new Promise((resolve, reject) => {
      // Show 'Camera Permission Request' dialogue
      AndroidPermissions.requestPermission(
        AndroidPermissions.PERMISSION.CAMERA
      ).then(
        (result) => {
          if (result.hasPermission) {
            // call method to turn on GPS
            resolve('GOT_PERMISSION');
          } else {
            resolve('DENIED_PERMISSION');
          }
        },
        (error) => {
          // Show alert if user click on 'No Thanks'
          alert(
            'requestPermission Error requesting camera permissions ' + error
          );
        }
      );
    });
  }
}
