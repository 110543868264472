<!-- Generated template for the MessageBoxComponent component -->
<ion-content>
  <div class="content" style="color: black; height: 100%; width: 100%">
    <div class="header">
      <span>{{ MessageBoxParams.header }}</span>
    </div>
    <div class="message">
      <span>{{ MessageBoxParams.message }}</span>
    </div>
    <div class="input" *ngIf="MessageBoxParams.input">
      <ion-item>
        <ion-label>{{
          MessageBoxParams.labelInput ? MessageBoxParams.labelInput : "Valor"
        }}</ion-label>
        <ion-input [(ngModel)]="InputValue"></ion-input>
      </ion-item>
    </div>
    <div class="controls">
      <div text-center *ngIf="!MessageBoxParams.noYes">
        <ion-button
          icon-left
          ion-button
          text-color="#0000ff"
          clear
          (click)="dismiss()"
        >
          <ion-icon name="checkmark-outline"></ion-icon> Ok
        </ion-button>
      </div>
      <div text-center *ngIf="MessageBoxParams.noYes">
        <ion-button
          class="cancel"
          icon-left
          ion-button
          clear
          (click)="dismiss(false)"
        >
          <ion-icon name="close"></ion-icon> Cancelar
        </ion-button>
      </div>
      <div text-center *ngIf="MessageBoxParams.noYes">
        <ion-button
          class="accept"
          icon-left
          ion-button
          text-color="#0000ff"
          clear
          (click)="dismiss(true)"
        >
          <ion-icon name="checkmark-outline"></ion-icon> Aceptar
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
