<ion-content class="ion-padding">
  <div class="sectionContainer">
    <h4 class="title2" style="width: 100%; text-align: center;">Información del Pedido</h4>
    <div class="flexBlockContainer" *ngIf="orderInfo.SALESID == ''">
      <div class="flexContainer">
        <div class="textContent">
          No se pudo consultar correctamente la información de la orden. Vuelva
          a intentarlo más tarde!
        </div>
      </div>
    </div>
    <div class="flexBlockContainer" *ngIf="orderInfo.SALESID != ''">
      <div class="flexContainer">
        <h6 class="titleContent">Fecha Pedido:</h6>
        <div class="textContent">{{ orderInfo.CREATEDDATETIME }}</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Fecha Vence:</h6>
        <div class="textContent">{{ orderInfo.FechaVence }}</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Términos de pago:</h6>
        <div class="textContent">{{ orderInfo.TerminoPago }}</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Forma de pago:</h6>
        <div class="textContent">{{ orderInfo.PAYMMODE }}</div>
      </div>

      <hr />
      <div class="flexContainer">
        <h6 class="titleContent">Enviar a:</h6>
        <div class="textContent">{{ orderInfo.ADDRESSNAME }}</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Dirección:</h6>
        <div class="textContent">{{ orderInfo.ADDRESS }}</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Fecha de entrega:</h6>
        <div class="textContent">{{ orderInfo.PSCUSTSHIPDATE }}</div>
      </div>
    </div>
  </div>
  <ion-button
    class="submit-btn"
    type="submit"
    (click)="closeWin()"
    shape="round"
    style="width: 100%"
  >
    <i class="far fa-times-circle" style="margin-right: 5px"></i>Cerrar
  </ion-button>
</ion-content>
