<h1 class="title" style="width: 100%; padding: 10px">Filtros</h1>
<ion-list>
  <ion-list-header style="color: #223e99">
    <ion-label>Rango de Montos</ion-label>
    <ion-badge>{{ MinMax.lower }}</ion-badge>
    <ion-badge>{{ MinMax.upper }}</ion-badge>
  </ion-list-header>
  <div style="margin: 0 12px">
    <ion-range
      id="colonRange"
      dualKnobs="true"
      pin="true"
      [(ngModel)]="MinMax"
      [min]="Min"
      [max]="Max"
    ></ion-range>
  </div>
  <ion-item>
    <div class="amounts">
      <p>Min: {{ Min }}</p>
      <p>Max: {{ Max }}</p>
    </div>
  </ion-item>

  <ion-list-header style="color: #223e99" lines="none">
    Rango de fechas
  </ion-list-header>
  <ion-item>
    <ion-label>Fecha inicial</ion-label>

    <ion-datetime-button datetime="datetime"></ion-datetime-button>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime
          id="datetime"
          presentation="date"
          [showDefaultButtons]="true"
          value="startDate"
          display-timezone="utc"
          displayFormat="DD/MM/YYYY"
          [(ngModel)]="startDate"
        ></ion-datetime>
      </ng-template>
    </ion-modal>
  </ion-item>
  <ion-item>
    <ion-label>Fecha final</ion-label>

    <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime
          id="datetimeEnd"
          presentation="date"
          [showDefaultButtons]="true"
          value="finalDate"
          display-timezone="utc"
          displayFormat="DD/MM/YYYY"
          [(ngModel)]="finalDate"
        ></ion-datetime>
      </ng-template>
    </ion-modal>
  </ion-item>
  <ion-label *ngIf="!isValidFormat()" style="color: red; padding-left: 13px">
    El formato de las fechas es incorrecto.
  </ion-label>
</ion-list>

<ion-grid style="display: flex">
  <ion-button text-color="#0000ff" (click)="dismissNull()" style="width: 60%">
    <ion-icon name="trash"></ion-icon> Borrar Filtros
  </ion-button>

  <ion-button text-color="#0000ff" (click)="dismiss()" style="width: 40%">
    <ion-icon name="checkmark-done-outline"></ion-icon>Filtrar
  </ion-button>
</ion-grid>
