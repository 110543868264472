import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { RegisterData } from '../../businessCore/RegisterData';
import { Worker } from '../../businessCore/Worker';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';

@Component({
  selector: 'app-asset-audit',
  templateUrl: './asset-audit.component.html',
  styleUrls: ['./asset-audit.component.scss'],
})
export class AssetAuditPage implements OnInit {
  auth: number;
  componentId: any;

  auditor: Worker;
  auditorVatnum: string;

  petitioner: Worker;

  errorMsg: string;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public popoverCtrl: PopoverController,
    public navParams: NavParams,
    public loadingCtrl: LoadingController,
    public storage: StorageService,
    public authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService,
    private _apiProvider: webServiceProvider,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  async refreshPage() {
    this.navService.navigateTo(
      NavigationRoutes.AssetAuditPage,
      {
        auth: this.auth,
        code: this.componentId,
      },
      { replaceUrl: true }
    );
  }

  async ngOnInit() {
    this.auth = Number(this.navParams.get('auth'));
    this.componentId = this.navParams.get('code');

    if (this.auth === 0 && this.authService.hasCookie()) {
      this.navService.setRoot(
        this.activatedRoute.component.name as NavigationRoutes,
        {
          auth: this.auth,
          code: this.componentId,
        }
      );
    } else {
      if (this.auth === 1) {
        let checkedToken = await this.authGuard.checkToken();
        if (
          checkedToken &&
          this.authGuard.componentAllow(
            this.authService.getComponentsInfo(),
            this.componentId
          )
        ) {
          this.auditorVatnum =
            this.authService.getTokenData('refresh')['vat-num'];
        } else {
          window.location.href = 'http://giiis01:8025/';
        }
      } else {
        this.storage.get('register_data').then((data) => {
          const register_data = JSON.parse(data) as RegisterData;
          this.auditorVatnum = register_data.id_card;
          // DEBUG
          //this.auditorVatnum = '1-1236-0499';
          // DEBUG
        });
      }
      this.errorMsg = '';
      try {
        const loading = await this.loadingCtrl.create({
          message: 'Cargando...',
        }); //Genera pantalla de cargado.
        await loading.present(); //Muestra pantalla de cargado.
        this.auditor = await this.getWorker('', this.auditorVatnum);
        //DEBUG
        //this.auditor = new Worker();
        //DEBUG
        this.petitioner = this.auditor;
        await loading.dismiss();
      } catch (error) {
        this.createAlert(
          'Error de conexión',
          'Se ha presentado un error, favor intentar más tarde'
        );
      }
    }
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de trabajadores.
   *
   * @returns Una lista con los trabajadores activos a la fecha.
   */
  async getWorker(personnelNumber: string = '', vatnum: string = '') {
    const resp = await this._apiProvider.getWorker(personnelNumber, vatnum);
    const list: Worker[] = resp as Worker[];
    return list[0];
  }

  /**
   *
   * @param title
   * @param subTitle
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }
}
