<ion-content>
  <h1 class="title">Comentario</h1>
  <div class="box">
    <div class="content">
      <ion-item>
        <ion-textarea
          label="Comentario"
          label-placement="floating"
          class="floating"
          [(ngModel)]="comment"
          maxlength="800"
          rows="5"
          placeholder="..."
        ></ion-textarea>
      </ion-item>
    </div>
    <div style="text-align: center">
      <ion-button (click)="dismiss()" [disabled]="!comment">
        Aceptar
      </ion-button>
    </div>
  </div>
</ion-content>
