import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogModel } from '../../../businessCore/oc-models/CatalogModel';
import { CurrencyInfoModel } from '../../../businessCore/oc-models/CurrencyInfoModel';
import { DataArea } from '../../../businessCore/oc-models/DataArea';
import { LanguageModel } from '../../../businessCore/oc-models/LanguageModel';
import { ProveedorModel } from '../../../businessCore/oc-models/ProveedorModel';
import { PurchPoolModel } from '../../../businessCore/oc-models/PurchPoolModel';
import { DayIniFin } from '../../../businessCore/oc-models/dayIniFin';

@Component({
  selector: 'app-general-parameter-oc',
  templateUrl: './general-parameter-oc.component.html',
  styleUrls: ['./general-parameter-oc.component.scss'],
})
export class GeneralParameterOcComponent implements OnInit {
  @Input() public dataAreaSelected: DataArea;
  @Input() public proveedorSelected: ProveedorModel;
  @Input() public purchPoolSelected: PurchPoolModel;
  @Input() public purposeSelected: CatalogModel;
  @Input() public saleSelected: CatalogModel;
  @Input() public currencySelected: CurrencyInfoModel;
  @Input() public languageSelected: LanguageModel;
  @Input() public tipoOCSelected: CatalogModel;
  @Input() public fechaIni: Date;
  @Input() public fechaFin: Date;
  @Input() public cantEdit: boolean;
  @Input() public creating: boolean;
  @Input() public taxInclude: boolean;
  @Input() public saleRequired: boolean;
  @Output() public openSearchBar = new EventEmitter<string>(); // Evento la direccion
  @Output() public getDatesIniFin = new EventEmitter<DayIniFin>();
  @Output() public getTaxesInfo = new EventEmitter<boolean>();

  // Axel Vargas
  @Input() public PurposeCategorySelected: string;
  @Input() public needToSetCategory: boolean;
  @Input() public requireNoPhysicalInvoice: boolean;
  @Input() public noPhysicalInvoice: string;

  /**Emitter que enviará el número de factura física.*/
  @Output() noPhysicalInvoiceEmit = new EventEmitter<string>();

  // Axel Vargas

  lenguageSelected: any;
  inicioDayS = new Date().toISOString();
  finalDayS = new Date().toISOString();
  maxDate: string = null;
  constructor() {
    // this.setMaxDate();
    this.maxDate = '2100-01-01';
  }

  ngOnInit() {
    // Se carga el tipo de OC
    // Se cargan los dias
    this.inicioDayS =
      this.fechaIni !== null && this.fechaIni !== undefined
        ? this.fechaIni.toISOString()
        : new Date().toISOString();
    this.finalDayS =
      this.fechaFin !== null && this.fechaFin !== undefined
        ? this.fechaFin.toISOString()
        : new Date().toISOString();

    this.dataAreaSelected =
      this.dataAreaSelected !== null && this.dataAreaSelected !== undefined
        ? this.dataAreaSelected
        : new DataArea('', '');

    this.proveedorSelected =
      this.proveedorSelected !== null && this.proveedorSelected !== undefined
        ? this.proveedorSelected
        : new ProveedorModel('', '', '', '', '');

    this.purchPoolSelected =
      this.purchPoolSelected !== null && this.purchPoolSelected !== undefined
        ? this.purchPoolSelected
        : new PurchPoolModel('', '');

    this.purposeSelected =
      this.purposeSelected !== null && this.purposeSelected !== undefined
        ? this.purposeSelected
        : new CatalogModel('' as any, '');

    this.saleSelected =
      this.saleSelected !== null && this.saleSelected !== undefined
        ? this.saleSelected
        : new CatalogModel('' as any, '', false, '' as String);

    this.saleRequired =
      this.saleRequired !== null && this.saleRequired !== undefined
        ? this.saleRequired
        : false;

    this.currencySelected =
      this.currencySelected !== null && this.currencySelected !== undefined
        ? this.currencySelected
        : new CurrencyInfoModel('', '');

    this.languageSelected =
      this.languageSelected !== null && this.languageSelected !== undefined
        ? this.languageSelected
        : new LanguageModel('');

    this.taxInclude =
      this.taxInclude !== null && this.taxInclude !== undefined
        ? this.taxInclude
        : false;

    this.tipoOCSelected =
      this.tipoOCSelected !== null && this.tipoOCSelected !== undefined
        ? this.tipoOCSelected
        : new CatalogModel('' as any, '');
  }

  setMaxDate() {
    //Fecha maxima
    const dayToConvert = new Date();
    this.maxDate =
      (dayToConvert.getFullYear() + 1).toString() +
      '-' +
      (dayToConvert.getMonth() + 1).toString() +
      '-' +
      dayToConvert.getDate();
  }

  emitSelectedBar(type: number) {
    let typeS = '';
    switch (type) {
      case 1:
        typeS = 'search-pro';
        break;
      case 2:
        typeS = 'search-pool';
        break;
      case 3:
        typeS = 'language';
        break;
      case 4:
        typeS = 'currency';
        break;
      case 5:
        typeS = 'search-data-area';
        break;
      case 6:
        typeS = 'search-purpose';
        break;
      case 7:
        typeS = 'search-type-oc';
        break;
      case 8:
        typeS = 'search-saleOrder';
        break;
      case 9:
        typeS = 'categoriesPurpose';
        break;
      default:
        break;
    }
    if (this.cantEdit) {
      this.openSearchBar.emit(typeS);
    }
  }

  emitDays() {
    if (this.cantEdit) {
      this.getDatesIniFin.emit({
        dayIni: this.inicioDayS === '' ? null : new Date(this.inicioDayS),
        dayFin: this.finalDayS === '' ? null : new Date(this.finalDayS),
      });
    }
  }

  emitTaxes() {
    if (this.cantEdit) {
      this.getTaxesInfo.emit(this.taxInclude);
    }
  }

  emitNoPhysicalInvoice($event: any) {
    this.noPhysicalInvoiceEmit.emit($event.value);
  }
}
