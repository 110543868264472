<app-nav-bar [title]="'Agregar'" [start]="'None'">
  <ion-buttons slot="start">
    <ion-button (click)="viewCtrl.dismiss()">
      <ion-icon style="font-size: 25px" name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button
      [disabled]="!assetsResponsible.getRecId()"
      style="background: none; width: 100%"
      shape="round"
      (click)="saveData()"
    >
      <!--<i class="fas fa-save" style="width: 45px;"></i>-->
      <img
        src="assets/img/diskette.svg"
        style="width: 20px"
        alt=""
        onerror=""
      />
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content class="ion-paddding">
  <div class="box">
    <div>
      <ion-item (click)="selectDepartment()" [disabled]="savedFlag">
        <ion-label>Departamento</ion-label>
        <ion-label class="label-selected">{{
          department.getName() || audit.getAuditedWorkerDepartment()
        }}</ion-label>
        <ion-icon name="caret-down-outline" item-right></ion-icon>
      </ion-item>

      <ion-item
        [disabled]="!department.getName()"
        (click)="selectAuditedResponsible()"
      >
        <ion-label>Funcionario</ion-label>
        <ion-label class="label-selected">{{
          assetsResponsible.getName()
        }}</ion-label>
        <ion-icon name="caret-down-outline" item-right></ion-icon>
      </ion-item>
    </div>

    <div *ngIf="assetList.length" style="display: flex">
      <p
        style="
          color: red;
          padding-left: 17px;
          margin-bottom: 4px;
          display: inline;
        "
      >
        Total de activos: {{ assetList.length }}
      </p>
      <p style="color: red; margin-left: auto">
        Activos revisados: {{ countCheckedAssets() }}
      </p>
    </div>

    <div class="content" *ngIf="assetList.length != 0">
      <ion-list>
        <ion-item-sliding *ngFor="let asset of assetList; let i = index">
          <ion-item>
            <ion-label>
              <p *ngIf="asset.barcode" style="color: #6f6f6f; font-size: 12px">
                Código de barras:
                {{ asset.getBarcode() }}
              </p>
              <h3>
                <div>{{ asset.getAssetId() }}: {{ asset.getAssetName() }}</div>
                <!-- <div *ngIf="asset.getSubAssets().length" class="markedNumber" (click)="toggleAccordion(asset)">
              &nbsp;+{{asset.getSubAssets().length}}&nbsp;
            </div> -->
              </h3>
              <p style="color: #6f6f6f">
                {{ asset.getDataAreaId() }}: {{ asset.getCompanyName() }}
              </p>
              <p style="color: #6f6f6f">{{ asset.getAssetGroupName() }}</p>
              <div class="threeBtn">
                <ion-button
                  [ngClass]="{
                    addedComment: asset.getImgURL(),
                    disabled: !asset.getImgURL(),
                    'btn-left': true
                  }"
                  (click)="openFile(asset.getImgURL())"
                >
                  Imagen
                </ion-button>
                <ion-button
                  [ngClass]="{
                    addedComment: checkComment(asset.getAssetRecId())
                  }"
                  (click)="addComment(asset.getAssetRecId())"
                >
                  Comentarios
                </ion-button>
                <ion-button
                  [ngClass]="{
                    'btn-right': true,
                    'btn-right2': checkFinding(asset.getAssetRecId()),
                    required: !checkFinding(asset.getAssetRecId())
                  }"
                  (click)="addFinding(asset.getAssetRecId())"
                >
                  Hallazgos
                </ion-button>
              </div>
            </ion-label>
          </ion-item>
        </ion-item-sliding>
      </ion-list>
      <ion-list-header>
        <h2>Activos sin asignar</h2>
      </ion-list-header>
      <ul *ngIf="unassignedAssetList.length" style="padding-left: 31px">
        <li *ngFor="let item of unassignedAssetList; let i = index">
          <div>
            <span
              style="color: gray; font-size: 12px; display: inline-block"
              *ngIf="item.getBarcode()"
              >No. {{ item.getBarcode() }}</span
            >
            <span style="display: inline-block"
              >{{ item.getDataAreaId() }} - {{ item.getAssetId() }}:
              {{ capitalize(item.getAssetName()) }}</span
            >
          </div>
        </li>
      </ul>
      <div (click)="getAssetListByCodebar()">
        <span style="margin-left: 15px">
          <ion-icon name="add-circle"></ion-icon>
        </span>
        <p style="display: inline; vertical-align: middle; color: royalblue">
          Añadir activo
        </p>
      </div>
    </div>
    <div *ngIf="checkErrorMsg()" style="display: flex">
      <p
        style="
          color: red;
          margin-left: auto;
          margin-top: 0px;
          margin-bottom: 0px;
        "
      >
        {{ errorMsg }}
      </p>
    </div>
    <div
      [ngClass]="{ btnDiv: true, removeMarginTop: checkErrorMsg() }"
      class="flex-container nowrap infContent"
    >
      <ion-button class="btnPositionRight" (click)="finishAudit()">
        Finalizar
      </ion-button>
    </div>
  </div>
</ion-content>
