// Native - Plugins
import { Component } from '@angular/core';
import { Money } from '../../GeneralUtilis/money';

// BusinessCore
import { NavParams, ToastController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';
import { OrderLine } from '../../businessCore/OrderLine';

@Component({
  selector: 'app-my-custom-list-products',
  templateUrl: './my-custom-list-products.component.html',
  styleUrls: ['./my-custom-list-products.component.scss'],
})
export class MyCustomListProductsComponent {
  itemInfo: OrderLine[] = [];
  shoppingCar: OrderLine[] = [];

  /**
   * Class constructor with these injections:
   * @param params object that exists on a page and can contain data for that particular view.
   * @param storage an easy way to store key/value pairs and JSON objects.
   * @param toast a subtle notification commonly used in modern applications.
   */
  constructor(
    private params: NavParams,
    private storage: StorageService,
    private toast: ToastController,
    private globalEventsService: GlobalEventsService
  ) {
    this.itemInfo = this.params.get('products');
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   * @returns numero indicando la cantidad de dinero
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para agergar un producto seleccionado al carrito de compras
   * efectuando los debidos cambios en el total de la compra, cantidad de productos, etc.
   * @param item objeto con los datos del producto que se va agregar al carrito de compras
   */
  async addToCart(item: OrderLine) {
    let inserted: boolean = false;
    this.shoppingCar.forEach((data) => {
      if (item.itemId == data.itemId && item.unitId == data.unitId) {
        inserted = true;
        data.salesQuantity = data.salesQuantity + item.salesQuantity;
        data.totalSale = data.salesQuantity * data.salePrice;
      }
    });
    if (!inserted) {
      this.shoppingCar.push(item);
    }
    let toast = await this.toast.create({
      message: 'Artículo agregado ' + item.itemId,
      duration: 1000,
    });
    toast.present();
    this.storage.set('carrito', JSON.stringify(this.shoppingCar)).then(() => {
      this.globalEventsService.publish(GlobalEvents.UPDATE_CART);
    });
  }
}
