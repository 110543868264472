<div class="box">
  <div class="barTitle">
    <h5
      style="
        font-size: 1rem;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
      "
    >
      GENERALES
    </h5>
  </div>
  <div class="content">
    <ion-item (click)="emitSelectedBar(5)">
      <ion-label>
        <h2 class="h2Select">Empresa</h2>
        <h3>{{ dataAreaSelected.getId() }}</h3>
        <p>{{ dataAreaSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="creating" name="caret-down-outline" item-end></ion-icon>
    </ion-item>
    <ion-item (click)="emitSelectedBar(1)">
      <ion-label>
        <h2 class="h2Select">Proveedor</h2>
        <h3>{{ proveedorSelected.getAccountNum() }}</h3>
        <p>{{ proveedorSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="creating" name="caret-down-outline" item-end></ion-icon>
    </ion-item>
    <ion-item (click)="emitSelectedBar(2)">
      <ion-label>
        <h2 class="h2Select">Tipo de Compra</h2>
        <p>{{ purchPoolSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item (click)="emitSelectedBar(7)">
      <ion-label>
        <h2 class="h2Select">Tipo de Orden</h2>
        <p>{{ tipoOCSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <!--DG-->
    <ion-item (click)="emitSelectedBar(6)">
      <ion-label>
        <h2 class="h2Select">Propósito de OC</h2>
        <h3>{{ purposeSelected.getId() }}</h3>
        <p>{{ purposeSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <!-- Axel Vargas -->
    <ion-item *ngIf="needToSetCategory" (click)="emitSelectedBar(9)">
      <ion-label>
        <h2 class="h2Select">Categoría de Propósito de OC</h2>
        <h3>{{ PurposeCategorySelected }}</h3>
        <p>{{ PurposeCategorySelected }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item *ngIf="requireNoPhysicalInvoice">
      <ion-label>No. Factura Física</ion-label>
      <ion-input
        (ionBlur)="emitNoPhysicalInvoice($event)"
        [(ngModel)]="noPhysicalInvoice"
        [value]="noPhysicalInvoice"
      ></ion-input>
    </ion-item>
    <!-- Axel Vargas -->

    <ion-item (click)="emitSelectedBar(8)" *ngIf="saleRequired">
      <ion-label>
        <h2 class="h2Select">OV relacionada</h2>
        <h3>{{ saleSelected.getId() }} - {{ saleSelected.getPSSalesId() }}</h3>
        <p>{{ saleSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item (click)="emitSelectedBar(3)">
      <ion-label>
        <h2 class="h2Select">Lenguaje</h2>
        <p>{{ languageSelected.getId() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>
    <ion-item (click)="emitSelectedBar(4)">
      <ion-label>
        <h2 class="h2Select">Moneda</h2>
        <p>{{ currencySelected.getCurrencyCode() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>
    <ion-item class="timer" *ngIf="maxDate">
      <ion-label><h2 class="h2Select">Fecha Inicio</h2></ion-label>

      <ion-datetime-button datetime="datetime"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetime"
            presentation="date"
            [showDefaultButtons]="true"
            [disabled]="!cantEdit"
            displayFormat="DD/MM/YYYY"
            [(ngModel)]="inicioDayS"
            placeholder="Seleccione la fecha"
            doneText="Aceptar"
            cancelText="Cancelar"
            (ionChange)="emitDays()"
            [max]="maxDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
    <ion-item class="timer" *ngIf="maxDate">
      <ion-label><h2 class="h2Select">Fecha Fin</h2></ion-label>

      <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetimeEnd"
            presentation="date"
            [showDefaultButtons]="true"
            [disabled]="!cantEdit"
            displayFormat="DD/MM/YYYY"
            [(ngModel)]="finalDayS"
            placeholder="Seleccione la fecha"
            (ionChange)="emitDays()"
            doneText="Aceptar"
            cancelText="Cancelar"
            [max]="maxDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
    <ion-item>
      <ion-label
        ><h2 class="h2Select">Impuestos incluidos en precio</h2></ion-label
      >
      <ion-checkbox
        [disabled]="!cantEdit"
        slot="end"
        item-end
        [(ngModel)]="taxInclude"
        (ionChange)="emitTaxes()"
      ></ion-checkbox>
    </ion-item>
  </div>
</div>
