<ion-list>
  <ion-list-header style="color: #223e99"> Filtros </ion-list-header>
  <div>
    <ion-item *ngIf="this.typeFilter === 1 || this.typeFilter === 3">
      <ion-select
        label="Compañia"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeCompany($event)"
      >
        <!--[selected]="company.selected" TODOM-->
        <ion-select-option [value]="company" *ngFor="let company of companyList"
          >{{ company.getId() }} - {{ company.getName() }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item *ngIf="this.typeFilter === 1 || this.typeFilter === 2">
      <ion-select
        label="Proveedor"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeProveedor($event)"
      >
        <!--TODOM [selected]="proveedor.selected"-->
        <ion-select-option
          [value]="proveedor"
          *ngFor="let proveedor of proveedorList"
          >{{ proveedor.getName() }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item *ngIf="typeFilter === 1">
      <ion-select
        label="Estado de Aprobación"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeEstadoAp($event)"
      >
        <!--[(selected)]="estadoAp.selected"TODOM-->
        <ion-select-option
          [value]="estadoAp"
          *ngFor="let estadoAp of estadosApList"
          >{{ estadoAp.getName() }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item *ngIf="typeFilter === 1">
      <ion-select
        label="Estado"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeEstado($event)"
      >
        <!--[selected]="estado.selected" TODOM-->
        <ion-select-option
          [value]="estado"
          *ngFor="let estado of estadosList"
          >{{ estado.getName() }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item *ngIf="typeFilter === 2 || typeFilter === 3">
      <ion-select
        label="Creado por"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeCreado($event)"
      >
        <!-- [selected]="creado.selected"TODOM-->
        <ion-select-option
          [value]="creado"
          *ngFor="let creado of creadoPorList"
          >{{ creado.getName() }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-list-header style="color: #223e99" lines="none">
      Rango de fechas
    </ion-list-header>
    <ion-item>
      <ion-label>Fecha inicial</ion-label>
      <ion-datetime-button datetime="datetime"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetime"
            presentation="date"
            [showDefaultButtons]="true"
            value="startDate"
            display-timezone="utc"
            displayFormat="DD/MM/YYYY"
            [(ngModel)]="startDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
    <ion-item>
      <ion-label>Fecha final</ion-label>
      <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetimeEnd"
            presentation="date"
            [showDefaultButtons]="true"
            value="finalDate"
            display-timezone="utc"
            displayFormat="DD/MM/YYYY"
            [(ngModel)]="finalDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
    <ion-label *ngIf="wrongDateFlag" style="color: red; padding-left: 13px">
      El formato de las fechas es incorrecto.
    </ion-label>
  </div>
</ion-list>

<ion-grid style="display: flex">
  <ion-button text-color="#0000ff" (click)="dismissNull()" style="width: 60%">
    <ion-icon name="trash"></ion-icon> Borrar Filtros
  </ion-button>

  <ion-button text-color="#0000ff" (click)="dismiss()" style="width: 40%">
    <ion-icon name="checkmark-done-outline"></ion-icon>Filtrar
  </ion-button>
</ion-grid>
