<div class="card mb-3" style="background: white; color: black; padding: 15px">
  <h5 style="text-align: center">Resultado de la Transacción</h5>
  <div class="row no-gutters">
    <!-- <img src="../../assets/img/BACDS-4800_StickerTarjetasDigital520x90.png" class="card-img" alt="..."> -->
    <img
      src="assets/img/BACDS-4800_StickerTarjetasDigital520x90.png"
      class="card-img"
      alt="..."
    />

    <div class="card-body">
      <!-- Transaction Status -->
      <h5 class="txtUnderline">Estado de la transacción</h5>
      <p class="d-flex">
        <small class="tranStatus" *ngIf="transStatusOk">Muchas Gracias!</small>
        <small class="tranStatus" *ngIf="!transStatusOk">Lo Sentimos!</small>
        <small
          class="tranStatus"
          [ngStyle]="{ color: transStatusOk ? 'green' : 'red' }"
          >{{ transactionStatus }}</small
        >
      </p>

      <!-- Transaction Data -->
      <h5 class="txtUnderline">Datos de la transacción</h5>
      <p class="d-flex">
        <small>ID de la orden: {{ orderid }}</small>
        <small>Monto de la transacción: {{ setCurrency(amount) }}</small>
        <small>Cliente: {{ custid }}</small>
      </p>
      <div style="text-align: center; width: 100%">
        <ion-button (click)="autoDismissForm()">Aceptar</ion-button>
      </div>
    </div>
  </div>
</div>
