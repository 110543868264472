<app-nav-bar [title]="'Detalles del Producto'"></app-nav-bar>

<ion-content class="ion-paddding">
  <img
    class="center"
    src="https://serviceplan-api.mercasacr.com/images/Products/{{ item.ItemId }}_l_.PNG"
    onerror="this.src='assets/img/error.png'"
    alt=""
  />
  <p class="h7 center-text">{{ item.ItemName }}</p>

  <div *ngFor="let item of ordLine">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px dashed black;
        padding-top: 5px;
      "
    >
      <div style="display: block; width: 25%">
        <h6 class="center-text">{{ item.unitId }}</h6>
        <h6 class="center-text" color="primary">{{ setSalePrice(item) }}</h6>
      </div>
    </div>
  </div>

  <div class="flex-container nowrap">
    <ion-button
      class="borderRadius"
      style="background-color: brown"
      (click)="closePopup()"
    >
      Cerrar
    </ion-button>
  </div>
</ion-content>
