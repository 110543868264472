export class CaseCategoryType {
  CategoryId: string = '';
  CategoryType: number = 1;
  Description: string = '';
  Duracion: string = '';
  Empleado: string = '';
  OwnerWorker: number = 0;
  PrincipalCategory: string = '';
  RecId: number = 0;

  constructor() {}
}
