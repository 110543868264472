import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Market } from '@ionic-native/market/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NavigationService } from './GeneralUtilis/Navigation/NavigationElement';
import { NavigationParamsService } from './GeneralUtilis/Navigation/NavigationParamsService';
import { ShoopingCartUtils } from './GeneralUtilis/ShoopingCar.utils';
import { StorageService } from './GeneralUtilis/Storage';
import { InvoiceService } from './Invoice/services/invoice.service';
import { PurchRemissionInfoService } from './WMS/services/purchRemissionInfo.service';
import { RemissionService } from './WMS/services/remission.service';
import { SelectFilterService } from './WMS/services/select-filter.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/Components.module';
import { VendorRegisterLanguageComponent } from './components/vendor-register-language/vendor-register-language.component';
import { PipesModule } from './pipes/pipes.module';
import { CameraService } from './provider/Services/CameraService';
import { LocationService } from './provider/Services/LocationService';
import { ExternalServiceChecklistProvider } from './provider/Services/external-service-checklist';
import { ExternalServiceOCService } from './provider/Services/external-service-oc.service';
import { ExternalServiceTripProvider } from './provider/Services/external-service-trip';
import { LocalServiceOCService } from './provider/Services/local-service-oc.service';
import { LocalTripStorageProvider } from './provider/Services/local-trip-storage';
import { ServicesLocalCheckProvider } from './provider/Services/services-local-check';
import { ServicesOcParameterProvider } from './provider/Services/services-oc-parameter';
import { TripParameterProvider } from './provider/Services/trip-parameter';
import { AuthJWTService } from './provider/auth-jwt/auth.jwt.service';
import { webServiceProvider } from './provider/webServiceProvider';
import { AuthenticationAuthGuardProvider } from './providers/authentication-auth-guard/authentication-auth-guard';
import { ManageUserKeysProvider } from './providers/manage-user-keys/manage-user-keys';
import { PushNotificationsProvider } from './providers/push-notifications/push-notifications';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DirectivesModule } from './directives/directives.module';

const ionicConfig = {
  innerHTMLTemplatesEnabled: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(ionicConfig),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ComponentsModule,
    NgxDatatableModule,
    PipesModule,
    ScrollingModule,
    DirectivesModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Storage,
    webServiceProvider,
    NavParams,
    ShoopingCartUtils,
    SocialSharing,
    AppVersion,
    Market,
    Device,
    PushNotificationsProvider,
    OneSignal,
    ManageUserKeysProvider,
    CallNumber,
    Camera,
    NavigationService,
    NavigationParamsService,
    GoogleMaps,
    Screenshot,
    Geolocation,
    ExternalServiceChecklistProvider,
    ServicesLocalCheckProvider,
    AuthenticationAuthGuardProvider,
    AuthJWTService,
    ExternalServiceOCService,
    LocalServiceOCService,
    InvoiceService,
    VendorRegisterLanguageComponent,
    InAppBrowser,
    ServicesOcParameterProvider,
    RemissionService,
    PurchRemissionInfoService,
    SelectFilterService,
    ExternalServiceTripProvider,
    TripParameterProvider,
    LocalTripStorageProvider,
    LocationService,
    CameraService,
    StorageService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
