<app-nav-bar [title]="addressLanguage.headerTitle"></app-nav-bar>

<ion-content class="ion-padding" class="map-content">
  <ion-button expand="block" (click)="getPosition()">
    {{ addressLanguage.pinUp }}
  </ion-button>

  <div style="text-align: center; font-size: 1rem !important; color: brown">
    {{ addressLanguage.bodyMsg
    }}<a style="font-weight: bold">{{ addressLanguage.pinUp }}</a>
  </div>
  <!-- <div id="mapid"></div> -->
  <!-- <google-map [lat]="lat" [lng]="lng"></google-map> -->
</ion-content>
