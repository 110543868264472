import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { PurchLine } from '../../../businessCore/oc-models/PurchLine';
import { UpdateFinancialDimensionComponent } from '../update-financial-dimension/update-financial-dimension.component';
import { UpdateQtyOCComponent } from '../update-qty-oc/update-qty-oc.component';
// import { UpdateQuantityComponent } from '../update-quantity/update-quantity.component';

@Component({
  selector: 'app-linea-info-oc',
  templateUrl: './linea-info-oc.component.html',
  styleUrls: ['./linea-info-oc.component.scss'],
})
export class LineaInfoOcComponent implements OnInit {
  @Input() cantEdit: boolean;
  @Input() showDimension: boolean;
  @Input() purchLineInf: PurchLine;
  @Input() taxInfo: boolean;
  @Output() deleteItem = new EventEmitter<PurchLine>();
  @Output() updateInfPurch = new EventEmitter<boolean>();
  presentingMess = false;
  permitEdit = true;
  permitShowDimension = true;

  constructor(
    public popoverCtrl: PopoverController,
    public alertController: AlertController
  ) {}

  ngOnInit() {
    if (this.cantEdit !== null && this.cantEdit !== undefined) {
      this.permitEdit = this.cantEdit;
    }
    if (this.showDimension !== null && this.showDimension !== undefined) {
      this.permitShowDimension = this.showDimension;
    }
  }

  /**
   * Retorna la informacion
   */
  deleteItemFunc() {
    this.deleteItem.emit(this.purchLineInf);
  }

  swipeEvent(event) {
    if (this.permitEdit) {
      if (event.direction === 2) {
        // Eliminar
        this.messageInfo(
          'Atención',
          '¿Desea eliminar una línea de la orden?',
          1
        );
      } else if (event.direction === 4) {
        // Editar cantidad
        this.updateCantItem();
      }
    }
  }

  clickDelete() {
    this.messageInfo('Atención', '¿Desea eliminar una línea de la orden?', 1);
  }

  async updateCantItem() {
    // Emite a la orden de compra que actualice sus totales
    const modal = await this.popoverCtrl.create({
      component: UpdateQtyOCComponent,
      componentProps: { purchLine: this.purchLineInf, taxtInfo: this.taxInfo },
      cssClass: ['custom-popover', 'update-qty-oc'],
    });
    await modal.present();
    // Se espera las opciones a actualizar, seleccionada por el usuario
    modal.onDidDismiss().then(() => {
      this.updateInfPurch.emit(true);
      if (this.purchLineInf.cantidad === 0) {
        this.executeAction(1);
      }
    });
  }

  async updateDimensionFinancial() {
    // Emite a la orden de compra que actualice sus dimensiones
    if (this.permitShowDimension) {
      const modal = await this.popoverCtrl.create({
        component: UpdateFinancialDimensionComponent,
        componentProps: {
          purchLine: this.purchLineInf,
          cantEdit: this.permitEdit,
        },
        cssClass: ['custom-popover', 'update-financial-dimension'],
      });
      modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then(() => {
        // this.updateInfPurch.emit(true);
        // if( this.purchLineInf.cantidad === 0) {
        //  this.executeAction(1);
        // }
      });
    }
  }

  // Verificar si el valor de actualización es 0
  executeAction(type: number) {
    switch (type) {
      case 1:
        this.deleteItemFunc();
        break;
      case 2:
        this.updateCantItem();
        break;
      case 3:
        break;
      default:
        break;
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [
          {
            text: 'SI',
            handler: () => {
              this.executeAction(action);
            },
          },
          { text: 'NO' },
        ],
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  getStyle() {
    return this.cantEdit ? 'borderStyle' : 'borderStyle2';
  }
}
