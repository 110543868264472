import { Component } from '@angular/core';
import {
  AlertController,
  IonRefresher,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { InfoFilterOcPopComponent } from 'src/app/components/oc-components/info-filter-oc-pop/info-filter-oc-pop.component';

import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from './../../provider/Services/local-service-oc.service';

@Component({
  selector: 'app-purch-order-to-be-confirmed',
  templateUrl: './purch-order-to-be-confirmed.component.html',
  styleUrls: ['./purch-order-to-be-confirmed.component.scss'],
})
export class PurchOrderToBeConfirmedPage {
  listPurch: PurchOrder[] = [];
  listPurchShowing: PurchOrder[] = [];

  listPurchRecord: PurchOrder[] = [];
  listPurchRecordShowing: PurchOrder[] = [];

  loader: HTMLIonLoadingElement;
  vendorAccount: string = '';
  vendorVatNum: string = '';
  presentingMess = false;
  sendMultiple = false;
  segmentModel = 'toConfirm';

  // Filtros y ordenamiento
  filterKey: string = '';
  creadoPorList: CatalogModel[] = [];
  companyList: CatalogModel[] = [];
  creadoPorList2: CatalogModel[] = [];
  companyList2: CatalogModel[] = [];
  startDate: Date;
  finalDate: Date;
  companyAZ: boolean = false;
  companyZA: boolean = false;
  createByAZ: boolean = false;
  createByZA: boolean = false;
  fechaMenorMayor: boolean = false;
  fechaMayorMenor: boolean = false;
  orderBy: string = '';

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private extServOC: ExternalServiceOCService,
    private loadingCtrl: LoadingController,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public localServOC: LocalServiceOCService,
    private popoverCtrl: PopoverController
  ) {}

  async ngOnInit() {
    this.vendorAccount = await this.localServOC.getIdActualUser();
    this.vendorVatNum = await this.localServOC.getCedulaActual();
    this.updateData();
  }

  refresOC(event) {
    let refresher: IonRefresher = event.detail;
    if (
      this.vendorVatNum !== null &&
      this.vendorVatNum !== undefined &&
      this.vendorVatNum !== ''
    ) {
      this.sendMultiple = false;
      if (this.segmentModel === 'toConfirm') {
        this.changeVenderSelected(this.vendorAccount, this.vendorVatNum);
      } else {
        this.getConfirmed(this.vendorAccount, this.vendorVatNum);
      }
    } else {
      this.messageInfo('Alerta', 'Ingrese la información  del proveedor');
    }
    refresher.complete();
  }

  logScrolling(event) {
    console.log('evento: ', event);
  }

  async updateData() {
    if (
      this.vendorVatNum !== null &&
      this.vendorVatNum !== undefined &&
      this.vendorVatNum !== ''
    ) {
      this.sendMultiple = false;
      // if(this.segmentModel === "toConfirm") {
      await this.changeVenderSelected(this.vendorAccount, this.vendorVatNum);
      // } else {
      await this.getConfirmed(this.vendorAccount, this.vendorVatNum);
      // }
    } else {
      this.messageInfo('Alerta', 'Ingrese la información del proveedor');
    }
  }

  async changeVenderSelected(orderAccount: string, vatNumVendor: string) {
    await this.showSpinnerLoading('Cargando datos de ordenes por confirmar.');
    let resp = await this.extServOC.getToBeConfirmed(vatNumVendor, 30); // APROBADO === 30
    // DEBUG //
    /*resp = [
      new PurchOrder(
        '',
        null,
        '',
        '',
        null,
        null,
        null,
        null,
        null,
        0,
        0,
        null,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        '',
        null,
        '',
        new DataArea('cmer', 'Mercasa'),
        ''
      ),
    ];*/
    // DEBUG
    // Traer la info de las OC que se tienen por
    this.dissmissLoading();
    if (resp !== null) {
      this.listPurch = resp as PurchOrder[];
      if (this.listPurch.length === 0) {
        this.messageInfo(
          'Alerta',
          'No hay datos de ordenes pendientes para el proveedor ' +
            orderAccount +
            ' ' +
            vatNumVendor +
            '.'
        );
      }
      this.setListToShow('toConfirm');
    } else {
      this.listPurch = []; // Se setea una lista vacia
      this.messageInfo(
        'Alerta',
        'Se produjo un error al consultar la información de las ordenes, para el proveedor' +
          orderAccount +
          ' ' +
          vatNumVendor +
          '.'
      );
      this.setListToShow('toConfirm');
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [{ text: 'Aceptar', handler: () => {} }],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async getConfirmed(orderAccount: string, vatNumVendor: string) {
    await this.showSpinnerLoading('Cargando datos de ordenes confirmadas.');
    let listtmp = (await this.extServOC.getUpdateDataPO(
      '',
      -1,
      '',
      -1,
      '',
      vatNumVendor
    )) as PurchOrder[];
    // DEBUG //
    /*listtmp = [
      new PurchOrder(
        '',
        null,
        '',
        '',
        null,
        null,
        null,
        null,
        null,
        0,
        0,
        null,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        '',
        null,
        '',
        new DataArea('cmer', 'Mercasa'),
        ''
      ),
    ];*/
    // DEBUG
    this.dissmissLoading();

    if (listtmp !== null && listtmp !== undefined) {
      //console.log(this.segmentModel, listtmp);
      this.listPurchRecord = listtmp;
      if (this.listPurchRecord.length === 0) {
        this.messageInfo(
          'Alerta',
          'No hay datos de ordenes confirmadas para el proveedor ' +
            orderAccount +
            ' ' +
            vatNumVendor +
            '.'
        );
      }
      this.setListToShow('record');
    } else {
      this.listPurchRecord = []; // Se setea una lista vacia
      this.messageInfo(
        'Alerta',
        'Se produjo un error al consultar la información de las ordenes, para el proveedor' +
          orderAccount +
          ' ' +
          vatNumVendor +
          '.'
      );
      this.setListToShow('record');
    }
  }

  searchOC(event) {
    this.filterKey = event.detail.value as string;
    if (this.listPurch !== null && this.listPurch !== undefined) {
      this.setListToShow(this.segmentModel);
    }
  }

  // Retorna una lista a partir de lo local y
  // filtros...
  setListToShow(typeShow: String) {
    //A mi lista, realice los siguientes filtros
    let result: PurchOrder[] = [];
    let result2: PurchOrder[] = [];
    if (typeShow === 'toConfirm') {
      result = PurchOrder.filterState(this.listPurch, this.companyList, 1);
      result2 = PurchOrder.filterState(result, this.creadoPorList, 5);
    } else {
      result = PurchOrder.filterState(
        this.listPurchRecord,
        this.companyList2,
        1
      );
      result2 = PurchOrder.filterState(result, this.creadoPorList2, 5);
    }
    const result3 = PurchOrder.filterByDateCreation(
      result2,
      this.startDate,
      this.finalDate
    );
    const result4 = PurchOrder.orderListBy(result3, this.orderBy);

    if (typeShow === 'toConfirm') {
      this.listPurchShowing = PurchOrder.filterObjectByWrite(
        this.filterKey,
        result4
      );
    } else {
      this.listPurchRecordShowing = PurchOrder.filterObjectByWrite(
        this.filterKey,
        result4
      );
    }
  }

  async presentFilterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: InfoFilterOcPopComponent,
      componentProps: {
        purchList: this.listPurch,
        typeFilter: 3,
        companyList:
          this.segmentModel === 'toConfirm'
            ? this.companyList
            : this.companyList2,
        creadoPorList:
          this.segmentModel === 'toConfirm'
            ? this.creadoPorList
            : this.creadoPorList2,
        //startDate: this.startDate,
        //finalDate: this.finalDate,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (this.segmentModel === 'toConfirm') {
          this.companyList = data.companyList as CatalogModel[];
          this.creadoPorList = data.creadoPorList as CatalogModel[];
        } else {
          this.companyList2 = data.companyList as CatalogModel[];
          this.creadoPorList2 = data.creadoPorList as CatalogModel[];
        }
        this.startDate = data.startDate as Date;
        this.finalDate = data.finalDate as Date;
        this.setListToShow(this.segmentModel);
      }
    });
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  setSelectedOrder(orderType: string) {
    this.companyAZ = 'companyA-Z' === orderType;
    this.companyZA = 'companyZ-A' === orderType;
    this.createByAZ = 'createByA-Z' === orderType;
    this.createByZA = 'createByZ-A' === orderType;
    this.fechaMenorMayor = 'fechaMenor-mayor' === orderType;
    this.fechaMayorMenor = 'fechaMayor-menor' === orderType;
  }

  /**
   *
   * Muestra un alert con valores para ordenar las listas.
   *
   **/
  async presentSortAlert() {
    let alert = await this.alertController.create({
      header: '¿Cómo ordenar los activos?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Compañía (A -> Z)',
          'companyA-Z',
          this.companyAZ
        ),
        this.getInputAlert(
          'radio',
          'Compañía (Z -> A)',
          'companyZ-A',
          this.companyZA
        ),
        this.getInputAlert(
          'radio',
          'Creado por (A -> Z)',
          'createByA-Z',
          this.createByAZ
        ),
        this.getInputAlert(
          'radio',
          'Creado por (Z -> A)',
          'createByZ-A',
          this.createByZA
        ),
        this.getInputAlert(
          'radio',
          'Fecha (menor - mayor)',
          'fechaMenor-mayor',
          this.fechaMenorMayor
        ),
        this.getInputAlert(
          'radio',
          'Fecha (mayor - menor)',
          'fechaMayor-menor',
          this.fechaMayorMenor
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            //console.log(data);
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow(this.segmentModel);
            }
          },
        },
      ],
    });
    alert.present();
  }

  changedSegmentValue(event) {
    this.segmentModel = event.detail.value;
  }
}
