<app-nav-bar [title]="'Datos de Recolección'"></app-nav-bar>

<ion-content>
  <ion-item lines="none" style="margin-top: 20px">
    <ion-badge style="text-align: center; background: blue; margin: auto">
      <h6 style="color: aliceblue; font-weight: bold">2/2</h6>
    </ion-badge>
  </ion-item>

  <ion-item>
    <a style="color: brown; margin-right: 2%">*</a>
    <ion-select
      label="Dirección de Recolección"
      label-placement="floating"
      class="floating"
      [(ngModel)]="ordHerder.shpTId"
      (ionChange)="selectAddress(ordHerder.shpTId)"
      placeholder="Dirección"
    >
      <ion-select-option
        *ngFor="let item of register_data.directions"
        value="{{ item.recId }}"
        >{{ item.direction }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item>
    <a style="color: brown; margin-right: 2%">*</a>
    <ion-select
      label="Fecha de Recolección:"
      label-placement="floating"
      class="floating"
      [(ngModel)]="ordHerder.dlvryDte"
      placeholder="DD/MM/YY"
    >
      <ion-select-option
        *ngFor="let item of addressAvailableDates.Dates"
        value="{{ item.Date.toISOString() }}"
        >{{ item.DateFmt }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item lines="none">
    <h6 ion-text text-wrap>{{ addressNameSelected }}</h6>
  </ion-item>

  <!-- Terms and Conditions -->
  <div style="padding: 6px; margin-left: 8px; display: flex">
    <label class="container">
      <input type="checkbox" [(ngModel)]="terms_conditions" />
      <span class="checkmark"></span>
    </label>
    <span style="text-decoration: underline" (click)="showTerms()"
      >Aceptar términos y condiciones de uso</span
    >
  </div>
</ion-content>

<ion-fab horizontal="start" vertical="bottom">
  <ion-fab-button (click)="dismiss()" size="small">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
<ion-fab horizontal="end" vertical="bottom">
  <ion-fab-button
    ion-fab
    (click)="NextStep()"
    size="small"
    [disabled]="
      ordHerder.shpTId == 0 || ordHerder.dlvryDte == '' || !terms_conditions
    "
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
