import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import { ParadaModel } from '../../businessCore/trip-models/parada';

@Component({
  selector: 'app-map-trip',
  templateUrl: './map-trip.component.html',
  styleUrls: ['./map-trip.component.scss'],
})
export class MapTripPage {
  public text: string;
  public mymap: Leaflet.Map;
  public marker: Leaflet.Marker;
  public latitudCenter: number = null;
  public longitudCenter: number = null;
  public showActualPosBtn: boolean;
  public callback: any;
  public tipo: number;
  public titulo: string;
  public canMove: boolean = true;
  public selectedPoint: boolean = false;
  public showLoader: boolean = false;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public http: HttpClient
  ) {
    this.showActualPosBtn = true;
    this.tipo = this.navParams.get('tipo');
    this.titulo = this.navParams.get('titulo');
    this.canMove = this.navParams.get('move');
  }

  ngOnInit() {
    this.setMapa();
  }

  setMapa() {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        let latlng = new Leaflet.LatLng(
          location.coords.latitude,
          location.coords.longitude
        );
        this.latitudCenter = latlng.lat;
        this.longitudCenter = latlng.lng;
        this.mymap = Leaflet.map('mapTripId').setView(
          [this.latitudCenter, this.longitudCenter],
          15
        );
        Leaflet.tileLayer(
          'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
          {
            attribution: '',
            maxZoom: 18,
            id: 'mapbox.streets',
          }
        ).addTo(this.mymap);

        this.marker = Leaflet.marker([this.latitudCenter, this.longitudCenter])
          .setIcon(
            new Leaflet.Icon({
              iconUrl: './assets/leafletImg/marker-icon-2x.png',
            })
          )
          .addTo(this.mymap);

        this.mymap.on('move', (e) => {
          if (this.canMove) {
            this.moveEvent();
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setActualPosition() {
    navigator.geolocation.getCurrentPosition((location) => {
      let latlng = new Leaflet.LatLng(
        location.coords.latitude,
        location.coords.longitude
      );
      this.marker.setLatLng(latlng);
      this.mymap.setView(latlng, 18);
    });
  }

  moveEvent() {
    let latlng = this.mymap.getCenter();
    this.latitudCenter = latlng.lat;
    this.longitudCenter = latlng.lng;
    this.marker.setLatLng(latlng);
  }

  getPositionSelected() {
    this.selectedPoint = true;
    this.closeScreen();
  }

  async closeScreen() {
    if (
      this.latitudCenter !== null &&
      this.latitudCenter !== undefined &&
      this.longitudCenter !== null &&
      this.longitudCenter !== undefined &&
      this.selectedPoint
    ) {
      const newParada = new ParadaModel();
      newParada.setType(this.tipo);
      newParada.setLatitud(this.latitudCenter);
      newParada.setLongitud(this.longitudCenter);
      // if((this.tipo === 0 || this.tipo === 2)) {
      if (this.showLoader === false) {
        const descripGPS = await this.getInfoPosition(
          this.latitudCenter,
          this.longitudCenter
        );
        newParada.setDescripcion(descripGPS);
        this.goToPreviousPage(newParada);
      }
      // } else {
      // this.goToPreviousPage(newParada);
      // }
    } else {
      this.goToPreviousPage(null);
    }
  }

  goToPreviousPage(newParada: ParadaModel) {
    this.navCtrl.pop().then(() => {
      this.navParams.get('callback')(newParada);
    });
  }

  async getInfoPosition(latInf: number, lonInf: number) {
    let result = '';
    this.showLoader = true;
    try {
      const data = await this.http
        .get(
          'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
            lonInf +
            ',' +
            latInf +
            '.json?access_token=pk.eyJ1IjoidGVjbm9pbnRlY2EiLCJhIjoiY2s1aDlkZnJzMDFleTNrbnh3enZnNGo4aCJ9.q7uTFEKcKNL1_cBC_Uiulg'
        )
        .toPromise();

      const features = data;
      const { place_name } = features[0];
      result = place_name;
    } catch (error) {
      // Error
      result = '';
    }
    this.showLoader = false;

    return result;
  }
}
