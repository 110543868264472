import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnswerTask } from '../../businessCore/check-models/AnswerTask';
import { CheckListInstance } from '../../businessCore/check-models/CheckInstance';
import { CheckList } from '../../businessCore/check-models/CheckList';
import { InfoImage } from '../../businessCore/check-models/InfoImage';
import { OmissionTask } from '../../businessCore/check-models/OmissionTask';
import { TaskCheck } from '../../businessCore/check-models/TaskCheck';

@Injectable()
export class ExternalServiceChecklistProvider {
  constructor(public http: HttpClient) {}

  private getPendingCheckListToXML(vatNum: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getPendingChecklist xmlns="http://tempuri.org/">
        <vatNum>` +
      vatNum +
      `</vatNum>
        </getPendingChecklist>
      </s:Body>
    </s:Envelope>`
    );
  }

  private getCheckListInfoToXML(checkListID: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getCheckListInfo xmlns="http://tempuri.org/">
        <checkListID>` +
      checkListID +
      `</checkListID>
        </getCheckListInfo>
      </s:Body>
    </s:Envelope>`
    );
  }

  private getPendingChecklist(vatNum: string) {
    // Se envia un XML con los datos de la OC
    return new Promise((resolve, reject) => {
      const xmlCheck = this.getPendingCheckListToXML(vatNum);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getPendingChecklist'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(xmlRequest.responseXML);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlCheck);
    });
  }

  async getMyPendingCheckList(vatNum: string) {
    let resp: any;
    try {
      const XMLDocument = (await this.getPendingChecklist(vatNum)) as Document;
      resp = this.parsePendingCheckList(XMLDocument);
    } catch (error) {
      resp = null;
    }
    return resp;
  }

  private parsePendingCheckList(xml: XMLDocument) {
    const listCheckListInstance: CheckListInstance[] = [];
    const dataInfo = xml
      .getElementsByTagName('getPendingChecklistResult')[0]
      .getElementsByTagName('a:CheckListInstance');
    for (let position = 0; position < dataInfo.length; position++) {
      const assigmenID = dataInfo[position]
        .getElementsByTagName('a:AssigmentID')
        .item(0).textContent;
      const checkList = dataInfo[position]
        .getElementsByTagName('a:CheckList')
        .item(0).textContent;
      const description = dataInfo[position]
        .getElementsByTagName('a:Description')
        .item(0).textContent;
      const execution = dataInfo[position]
        .getElementsByTagName('a:ExecutionDate')
        .item(0).textContent;
      const recIdInstance = dataInfo[position]
        .getElementsByTagName('a:InstanceRecId')
        .item(0).textContent;
      const estado = dataInfo[position]
        .getElementsByTagName('a:Estado')
        .item(0).textContent;
      const lastExecution = dataInfo[position]
        .getElementsByTagName('a:LastExecutionDate')
        .item(0).textContent;
      const tmpInstance = new CheckListInstance(
        assigmenID,
        description,
        checkList,
        Number(recIdInstance),
        execution.includes('1900-01-01T') || execution.includes('0001-01-01T')
          ? null
          : new Date(execution),
        lastExecution.includes('1900-01-01T') ||
        lastExecution.includes('0001-01-01T')
          ? null
          : new Date(lastExecution)
      );
      tmpInstance.position = listCheckListInstance.length + 1;
      tmpInstance.setEstado(Number(estado));
      listCheckListInstance.push(tmpInstance);
    }

    return listCheckListInstance;
  }

  private getCheckListInfo(checkListID: string) {
    // Se envia un XML con los datos de la OC
    return new Promise((resolve, reject) => {
      const xmlCheck = this.getCheckListInfoToXML(checkListID);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getCheckListInfo'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(xmlRequest.responseXML);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlCheck);
    });
  }

  async getMyCheckListInfo(checkListID: string) {
    let resp: any;
    try {
      const XMLDocument = (await this.getCheckListInfo(
        checkListID
      )) as Document;
      resp = this.parseCheckListInfo(XMLDocument);
    } catch (error) {
      resp = null;
    }
    return resp;
  }

  private parseCheckListInfo(xml: XMLDocument) {
    let checksList: CheckList[] = [];
    const dataInfo = xml
      .getElementsByTagName('getCheckListInfoResult')[0]
      .getElementsByTagName('a:CheckList');

    for (let position = 0; position < dataInfo.length; position++) {
      const ID = dataInfo[position]
        .getElementsByTagName('a:IDCheck')
        .item(0).textContent;
      const name = dataInfo[position]
        .getElementsByTagName('a:NameCheck')
        .item(0).textContent;
      const description = dataInfo[position]
        .getElementsByTagName('a:DescriptionCheck')
        .item(0).textContent;
      const newTmpCheckList = new CheckList(ID, description, name);
      const infoTaskList = dataInfo[position]
        .getElementsByTagName('a:CheckTasksList')[0]
        .getElementsByTagName('a:TaskCheck');
      // Se saca la informacion de las tareas
      const tasksList: TaskCheck[] = [];
      for (let j = 0; j < infoTaskList.length; j++) {
        const IDTask = infoTaskList[j]
          .getElementsByTagName('a:IDTask')
          .item(0).textContent;
        const nameTask = infoTaskList[j]
          .getElementsByTagName('a:NameTask')
          .item(0).textContent;
        const descriptionTask = infoTaskList[j]
          .getElementsByTagName('a:DescriptionTask')
          .item(0).textContent;
        const tmpTask = new TaskCheck(IDTask, descriptionTask, nameTask);
        const attachedTask = infoTaskList[j]
          .getElementsByTagName('a:Attached')
          .item(0).textContent;
        const orderTask = infoTaskList[j]
          .getElementsByTagName('a:OrderTask')
          .item(0).textContent;
        if (attachedTask !== '') {
          tmpTask.setAttachecd(Number(attachedTask) === 1);
        }
        tmpTask.setOrderTask(Number(orderTask));

        // Traer la informacion de las respuestas
        const infoAns = infoTaskList[j]
          .getElementsByTagName('a:AnswerTaskList')[0]
          .getElementsByTagName('a:AnswerTask');
        const listAnsw: AnswerTask[] = [];
        for (let k = 0; k < infoAns.length; k++) {
          const idAns = infoAns[k]
            .getElementsByTagName('a:IDAns')
            .item(0).textContent;
          const descriptionAns = infoAns[k]
            .getElementsByTagName('a:DescriptionAns')
            .item(0).textContent;
          const tmpAns = new AnswerTask(idAns, descriptionAns);
          tmpAns.position = listAnsw.length + 1;
          listAnsw.push(tmpAns);
        }
        tmpTask.setAnswerTaskList(listAnsw);

        // Traer la informacion de las omisiones
        const infoOmi = infoTaskList[j]
          .getElementsByTagName('a:OmisionTaskList')[0]
          .getElementsByTagName('a:OmissionTask');
        const listOmis: OmissionTask[] = [];
        for (let k = 0; k < infoOmi.length; k++) {
          const idOmi = infoOmi[k]
            .getElementsByTagName('a:IDOmi')
            .item(0).textContent;
          const descriptionOmi = infoOmi[k]
            .getElementsByTagName('a:DescriptionOmi')
            .item(0).textContent;
          const tmpOmi = new OmissionTask(idOmi, descriptionOmi);
          tmpOmi.position = listOmis.length + 1;
          listOmis.push(tmpOmi);
        }
        tmpTask.setOmissionTaskList(listOmis);
        tasksList.push(tmpTask);
      }
      newTmpCheckList.setTasksList(tasksList);
      checksList.push(newTmpCheckList);
    }
    return checksList;
  }

  private sendAnswerToXML(
    instanceId: string,
    vatNum: string,
    listAnswer: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <insertAnswer xmlns="http://tempuri.org/">
        <_instanceId>` +
      instanceId +
      `</_instanceId>
        <_vatNum>` +
      vatNum +
      `</_vatNum>
        <_answerList>` +
      listAnswer +
      `</_answerList>
        </insertAnswer>
      </s:Body>
    </s:Envelope>`
    );
  }

  async sendAnswer(insatanceId: string, vatNum: string, listAnswer: string) {
    return new Promise((resolve, reject) => {
      const xmlAnswer = this.sendAnswerToXML(insatanceId, vatNum, listAnswer);

      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/insertAnswer'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName(
                'insertAnswerResult'
              )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlAnswer);
    });
  }

  uploadImageToXML(infoData: InfoImage) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <UploadImage xmlns="http://tempuri.org/">
        <base64>` +
      infoData.getDataImage() +
      `</base64>
        <extension>` +
      infoData.extension +
      `</extension>
        <action>` +
      infoData.action +
      `</action>
        <type>` +
      infoData.type +
      `</type>
        <personVatnum>` +
      infoData.vatnum +
      `</personVatnum>
        <elementId>` +
      infoData.recIdAnswer +
      `</elementId>
        <company>` +
      infoData.company +
      `</company>
        </UploadImage>
      </s:Body>
    </s:Envelope>`
    );
  }

  async uploadImge(infoData: InfoImage) {
    return new Promise((resolve, reject) => {
      const xmlImg = this.uploadImageToXML(infoData);

      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/UploadImage'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName(
                'UploadImageResult'
              )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlImg);
    });
  }

  private sendAnswerOnlyOneToXML(
    instanceId: string,
    vatNum: string,
    answer: string,
    info64: string,
    infoImg: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <insertAnswerOnlyOne xmlns="http://tempuri.org/">
        <_instanceId>` +
      instanceId +
      `</_instanceId>
        <_vatNum>` +
      vatNum +
      `</_vatNum>
        <_answerList>` +
      answer +
      `</_answerList>
        <data64>` +
      info64 +
      `</data64>
        <dataImage>` +
      infoImg +
      `</dataImage>
        </insertAnswerOnlyOne>
      </s:Body>
    </s:Envelope>`
    );
  }

  async sendAnswerOnlyOne(
    instanceId: string,
    vatNum: string,
    answer: string,
    info64: string,
    infoImg: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlAnswer = this.sendAnswerOnlyOneToXML(
        instanceId,
        vatNum,
        answer,
        info64,
        infoImg
      );

      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/insertAnswerOnlyOne'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'insertAnswerOnlyOneResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlAnswer);
    });
  }

  private changeStateInstanceToXML(
    instanceId: number,
    state: string,
    reason: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <changeStateInstanceCheckList xmlns="http://tempuri.org/">
        <_instanceId>` +
      instanceId +
      `</_instanceId>
        <state>` +
      state +
      `</state>
        <reason>` +
      reason +
      `</reason>
        </changeStateInstanceCheckList>
      </s:Body>
    </s:Envelope>`
    );
  }

  async changeStateInstance(instanceId: number, state: string, reason: string) {
    return new Promise((resolve, reject) => {
      const xmlInstance = this.changeStateInstanceToXML(
        instanceId,
        state,
        reason
      );

      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/changeStateInstanceCheckList'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'changeStateInstanceCheckListResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlInstance);
    });
  }
}
