import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogModel } from '../../../businessCore/oc-models/CatalogModel';

@Component({
  selector: 'app-info-plus-oc',
  templateUrl: './info-plus-oc.component.html',
  styleUrls: ['./info-plus-oc.component.scss'],
})
export class InfoPlusOcComponent implements OnInit {
  @Output() getValueNumber = new EventEmitter<{
    value: number;
    type: number;
  }>();
  @Output() getDates = new EventEmitter<{
    sSoli: Date;
    sArribo: Date;
    sLlegB: Date;
  }>();
  @Output() openSearchBar = new EventEmitter<string>();
  @Input() cantidadContenedor: number;
  @Input() cantidadTEUS: number;
  @Input() cantidadLibres: number;
  @Input() typeContainer: CatalogModel;
  @Input() salidaSolicD: Date;
  @Input() salidaArriboD: Date;
  @Input() soliLlegaBD: Date;
  @Input() puertoSelected: CatalogModel;
  @Input() navieraSelected: CatalogModel;
  @Input() incoterm: CatalogModel;
  @Input() estadoPedido: CatalogModel;
  @Input() cantEdit: boolean;
  @Input() aDepartamentoSelected: CatalogModel;
  @Input() bCentroCostoSelected: CatalogModel;
  @Input() cPropositoSelected: CatalogModel;
  @Input() proyectoSelected: CatalogModel;

  contenedorShow: boolean;
  fechasShow: boolean;
  internacionalesShow: boolean;
  dimensionesFinShow: boolean;
  proyectShow: boolean;

  salidaSolicDayS: string;
  salidaArriboDayS: string;
  soliLlegadaBodDayS: string;
  maxDate: string = null;
  constructor() {
    // this.setMaxDate();
    this.maxDate = '2100-01-01';
  }

  ngOnInit() {
    this.contenedorShow = false;
    this.fechasShow = false;
    this.internacionalesShow = false;
    this.dimensionesFinShow = false;
    this.proyectShow = false;
    this.salidaSolicDayS =
      this.salidaSolicD !== null && this.salidaSolicD !== undefined
        ? this.salidaSolicD.toISOString()
        : new Date().toISOString();

    this.salidaArriboDayS =
      this.salidaArriboD !== null && this.salidaArriboD !== undefined
        ? this.salidaArriboD.toISOString()
        : new Date().toISOString();

    this.soliLlegadaBodDayS =
      this.soliLlegaBD !== null && this.soliLlegaBD !== undefined
        ? this.soliLlegaBD.toISOString()
        : new Date().toISOString();

    this.puertoSelected =
      this.puertoSelected !== null && this.puertoSelected !== undefined
        ? this.puertoSelected
        : new CatalogModel('' as any, '');

    this.navieraSelected =
      this.navieraSelected !== null && this.navieraSelected !== undefined
        ? this.navieraSelected
        : new CatalogModel('' as any, '');

    this.incoterm =
      this.incoterm !== null && this.incoterm !== undefined
        ? this.incoterm
        : new CatalogModel('' as any, '');

    this.estadoPedido =
      this.estadoPedido !== null && this.estadoPedido !== undefined
        ? this.estadoPedido
        : new CatalogModel('' as any, '');

    this.typeContainer =
      this.typeContainer !== null && this.typeContainer !== undefined
        ? this.typeContainer
        : new CatalogModel('' as any, '');

    this.aDepartamentoSelected =
      this.aDepartamentoSelected !== null &&
      this.aDepartamentoSelected !== undefined
        ? this.aDepartamentoSelected
        : new CatalogModel('' as any, '');

    this.bCentroCostoSelected =
      this.bCentroCostoSelected !== null &&
      this.bCentroCostoSelected !== undefined
        ? this.bCentroCostoSelected
        : new CatalogModel('' as any, '');

    this.cPropositoSelected =
      this.cPropositoSelected !== null && this.cPropositoSelected !== undefined
        ? this.cPropositoSelected
        : new CatalogModel('' as any, '');

    this.proyectoSelected =
      this.proyectoSelected !== null && this.proyectoSelected !== undefined
        ? this.proyectoSelected
        : new CatalogModel('' as any, '');
  }

  // Contendor ----------------------------------------------------------------
  // Controla el ver u ocultar las opciones de contenedor
  openCloseCont() {
    this.contenedorShow = !this.contenedorShow;
  }

  getNumberContenedor(numberEn: number) {
    this.setValues(numberEn, 1);
  }

  getNumberTEUS(numberEn: number) {
    this.setValues(numberEn, 2);
  }

  getNumberDLibres(numberEn: number) {
    this.setValues(numberEn, 3);
  }

  setValues(value: number, typeS: number) {
    if (this.cantEdit) {
      switch (typeS) {
        case 1:
          this.cantidadContenedor = value;
          this.getValueNumber.emit({
            value: this.cantidadContenedor,
            type: typeS,
          });
          break;
        case 2:
          this.cantidadTEUS = value;
          this.getValueNumber.emit({ value: this.cantidadTEUS, type: typeS });
          break;
        case 3:
          this.cantidadLibres = value;
          this.getValueNumber.emit({ value: this.cantidadLibres, type: typeS });
          break;
        default:
          break;
      }
    }
  }
  // -----------------------------------------------------------------------------------

  // Fechas ------------------------------------------------------------------------------
  // Controla el ver u ocultar las opciones de fechas
  openCloseFechas() {
    this.fechasShow = !this.fechasShow;
  }

  emitDays() {
    if (this.cantEdit) {
      this.getDates.emit({
        sSoli:
          this.salidaSolicDayS === '' ? null : new Date(this.salidaSolicDayS),
        sArribo:
          this.salidaArriboDayS === '' ? null : new Date(this.salidaArriboDayS),
        sLlegB:
          this.soliLlegadaBodDayS === ''
            ? null
            : new Date(this.soliLlegadaBodDayS),
      });
    }
  }

  // Internacionales ---------------------------------------------------------------------
  //
  openCloseInternacionales() {
    this.internacionalesShow = !this.internacionalesShow;
  }

  // Dimensiones Financieras -------------------------------------------------------------
  //
  openCloseDimensionesFinancieras() {
    this.dimensionesFinShow = !this.dimensionesFinShow;
  }

  // Proyectos ---------------------------------------------------------------------------
  //
  openCloseProyectos() {
    this.proyectShow = !this.proyectShow;
  }

  emitSelectedBar(type: number) {
    let typeS = '';
    switch (type) {
      case 1:
        typeS = 'search-puerto';
        break;
      case 2:
        typeS = 'search-naviera';
        break;
      case 3:
        typeS = 'search-incoterm';
        break;
      case 4:
        typeS = 'search-pedido-state';
        break;
      case 5:
        typeS = 'search-type-container';
        break;
      case 6:
        typeS = 'search-A_Departamento';
        break;
      case 7:
        typeS = 'search-B_CentroCosto';
        break;
      case 8:
        typeS = 'search-C_Proposito';
        break;
      case 9:
        typeS = 'search-projectType';
        break;
      default:
        break;
    }
    if (this.cantEdit) {
      this.openSearchBar.emit(typeS);
    }
  }

  setMaxDate() {
    //Fecha maxima
    const dayToConvert = new Date();
    this.maxDate =
      (dayToConvert.getFullYear() + 1).toString() +
      '-' +
      (dayToConvert.getMonth() + 1).toString() +
      '-' +
      dayToConvert.getDate();
  }
}
