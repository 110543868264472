import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { NavigationData } from './Navigation-data';
import { NavigationRoutes } from './Navigation-routes';

@Injectable()
export class NavigationService {
  constructor(private router: Router, private navParams: NavParams) {}

  async navigateTo(
    componentRoute: string,
    data: NavigationData = {},
    navExtras: NavigationExtras = {}
  ) {
    // Set the params
    this.navParams.data = data;

    // Redirect
    this.router.navigateByUrl(`/${componentRoute}`, navExtras);
  }

  setRoot(route: NavigationRoutes, data: NavigationData = {}) {
    this.navigateTo(route, data, { replaceUrl: true });
  }

  popToRoot() {
    this.setRoot(NavigationRoutes.ROOT_PAGE);
  }
}
