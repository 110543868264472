export class CustomerAddress {
  AccountNum: string = '';
  Address: string = '';
  AddressName: string = '';
  DataAreaId: string = '';
  DeliveryDays: string = '';
  DeliveryMethod: string = '';
  DeliveryRoute: string = '';
  IsPrimary: number = -1;
  Latitude: number = 0;
  Longitude: number = 0;
  OriginSite: string = '';
  PartialReturn: number = -1;
  PriceGroupId: string = '';
  Promos: string = '';
  QuantityRound: number = 0;
  RecId: number = 0;
  RoundTrend: number = 0;
  SalesAgent: string = '';
  TotalReturn: number = 0;
  Type: number = 0;
  notes: string = '';
  Email: string = '';
  City: '';
  PrincipalDeliveryDay: 0;
  SecondDeliveryDay: 0;
  NumeroTelVendedor: string = '';
  NombreVendedor: string = '';

  constructor() {}
}
