import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { SelectFilterOption } from '../../OOP/SelectFilterOption.interface';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent {
  private modal: any;
  public options: SelectFilterOption[] = [];
  public options_loaded: SelectFilterOption[] = [];
  public options_selected: SelectFilterOption[] = [];
  public options_all: SelectFilterOption[] = [];
  public input_value: string;
  public multiple: boolean = false;
  public currentLoadedIndex: number = 20;
  public partitions: number = 20;
  public timer: any;

  constructor(public navParams: NavParams) {
    this.multiple = this.navParams.get('multipleParam');

    this.options_all = this.navParams.get('optionsParam');

    this.options_selected = this.navParams.get('optionsSelectedParam');

    this.modal = this.navParams.get('modal');

    this.options_loaded = [
      ...this.options_all.slice(0, this.currentLoadedIndex),
    ];

    this.options = [...this.options_loaded];

    this.setOptionsSelected();
  }

  ngOnInit() {}

  setOptionsSelected() {
    for (let optSelected of this.options_selected) {
      for (let option of this.options_all) {
        if (optSelected.id == option.id) {
          option.isChecked = optSelected.isChecked;
          break;
        }
      }
    }
  }

  evalMultiple(_option) {
    if (!this.multiple) {
      for (let option of this.options_all) {
        if (option.id != _option.id) {
          option.isChecked = false;
        }
      }
    }
  }

  close() {
    if (this.modal) {
      this.modal.dismiss(null);
    }
  }

  save() {
    let selected = this.options_all.filter((option) => option.isChecked);

    this.modal.dismiss(selected);
  }

  filter() {
    clearTimeout(this.timer);

    let ms = 200; // milliseconds

    this.timer = setTimeout(() => {
      this.doFilter();
    }, ms);
  }

  doFilter() {
    if (
      (this.input_value !== null || this.input_value !== undefined) &&
      this.input_value
    ) {
      let temp_input_value = this.input_value.toLowerCase();

      this.options = this.options_all.filter((option) => {
        let id = option.label.toLowerCase() + option.description.toLowerCase();
        return id.includes(temp_input_value);
      });
    } else {
      this.options = [...this.options_loaded];
    }
  }

  loadData(event) {
    if (this.options_loaded.length === this.options_all.length) {
      event.target.disabled = true;
    } else {
      let lastIndexLoaded = this.currentLoadedIndex;
      this.currentLoadedIndex += this.partitions;
      this.options_loaded = this.options_loaded.concat(
        this.options_all.slice(lastIndexLoaded, this.currentLoadedIndex)
      );
      this.options = [...this.options_loaded];
      event.target.complete();
    }
  }
}
