import { Pipe, PipeTransform } from '@angular/core';
import { FixedAsset } from '../../businessCore/FixedAsset';

@Pipe({
  name: 'fixedAssetFilter',
})
export class FixedAssetFilterPipe implements PipeTransform {
  transform(array: FixedAsset[], text: string): any[] {
    if (text === '') {
      return array;
    }

    text = text.toLowerCase();

    return array.filter((item) => {
      return (
        item.getBarcode().toLowerCase().includes(text) ||
        item.getAssetId().toLowerCase().includes(text) ||
        item.getAssetName().toLowerCase().includes(text)
      );
    });
  }
}
