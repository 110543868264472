import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../../provider/Services/local-service-oc.service';
import { ServicesOcParameterProvider } from '../../../provider/Services/services-oc-parameter';
import { DateTimeOperation } from './../../../businessCore/oc-models/DateTimeOperations';

@Component({
  selector: 'app-to-be-approved-oc',
  templateUrl: './to-be-approved-oc.component.html',
  styleUrls: ['./to-be-approved-oc.component.scss'],
})
export class ToBeApprovedOcComponent implements OnInit {
  @Input() infoPurch: PurchOrder;
  @Output() selectedAction = new EventEmitter<Boolean>();
  presentingMess = false;
  selected = false;
  loader: HTMLIonLoadingElement;

  constructor(
    public navCtrl: NavController,
    public alertController: AlertController,
    private externalService: ExternalServiceOCService,
    private ocParameter: ServicesOcParameterProvider,
    private loadingCtrl: LoadingController,
    private localOcService: LocalServiceOCService,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    this.infoPurch.setToSend(this.selected); //Inicia en false
  }

  swipeEvent(event) {
    if (event.direction === 2) {
      // Editar
      this.viewPurchOrder();
    } else if (event.direction === 4) {
      // Confirmar OC
      this.approvePurchOrder();
    }
  }

  approvePurchOrder() {
    this.messageInfo('Atención', '¿Desea APROBAR la orden de compra?', 1);
  }

  viewPurchOrder() {
    this.executeAction(2);
  }

  async sendAprobedPurchOrder() {
    try {
      this.loader.message = 'Enviando aprobación de orden de compra.';
      const estadoNum: number = 30; // 'APROBADO' === 30
      const vatNum = await this.localOcService.getCedulaActual();
      let result = (await this.externalService.updateDocumentState(
        this.infoPurch.getIdOC(),
        estadoNum,
        this.infoPurch.getDataArea().getId(),
        vatNum
      )) as number;
      // if (result.toUpperCase() === this.infoPurch.getDocumentState().toUpperCase()
      if (result !== estadoNum) {
        // No se pudo actualizar
        this.messageInfo(
          'Error',
          'No se pudo actualizar el estado de la orden',
          0
        );
      } else {
        this.infoPurch.setDocumentStateI(result as number);
        this.messageInfo(
          'Atención',
          'Se ha actualizado el estado de la orden' +
            this.infoPurch.getIdOC() +
            ' \n a ' +
            this.infoPurch.getDocumentStateS(),
          0
        );
      }
    } catch (error) {
      this.messageInfo(
        'Error',
        'No se pudo actualizar el estado de la orden',
        0
      );
    }
    this.dissmissLoading();
  }

  async validateSendAprobedPurchOrder() {
    // Se muestra el spinner
    await this.showSpinnerLoading('Verficando datos de la OC.');
    try {
      const result = await this.externalService.getLastEditingDateApp(
        this.infoPurch.getIdOC(),
        this.infoPurch.getDataArea().getId()
      );
      // result = null => la orden no ha sido editada nunca
      if (result === null) {
        // Se debe de validar que no esta siendo editada
        console.log('NO ha sido editada: ', result);
        this.isEditing();
      } else {
        const comparation = DateTimeOperation.dateAComparatedateBandTime(
          this.infoPurch.getLastEditingAppDate(),
          result as Date
        );
        console.log(
          'dateA: ',
          this.infoPurch.getLastEditingAppDate(),
          ' dateB: ',
          result as Date,
          ' comparación: ',
          comparation
        );
        if (comparation === -1) {
          //La fecha que tenemos es menor a la actual
          // Se traen los datos nuevos.
          this.loader.message = 'Actualizando datos de la orden';
          const newInfo = await this.externalService.getUpdateDataPO(
            this.infoPurch.getDataArea().getId(),
            -1,
            this.infoPurch.getIdOC(),
            -1,
            '',
            ''
          ); // -1 Ignora el estado
          if (newInfo !== null) {
            this.infoPurch.copyDataFromOtherObjet(newInfo[0] as PurchOrder);
            this.dissmissLoading();
            this.messageInfo(
              'Atención',
              'Los datos de la orden estaban desactualizados y fueron actualizados.',
              0
            );
          } else {
            this.dissmissLoading();
            this.messageInfo(
              'Error',
              'No se pudo traer la información más actual de la OC.',
              0
            );
          }
        } else {
          // la fecha que tenemos es igual a la actual
          // Se debe de validar si está siendo editada
          this.isEditing();
        }
      }
    } catch (error) {
      console.log(error);
      this.dissmissLoading();
      this.messageInfo('Error', 'No se pudo aprobar de la orden.', 0);
    }
  }

  async isEditing() {
    try {
      this.loader.message = 'Verificando si la orden no está siendo editada.';
      let result = await this.externalService.isEditing(
        this.infoPurch.getIdOC(),
        this.infoPurch.getDataArea().getId()
      );
      if (result === 'NOTEDITING') {
        this.sendAprobedPurchOrder(); // como no está siendo editada
      } else {
        this.dissmissLoading();
        this.messageInfo(
          'Error',
          'La orden está siendo editada por un usuario.',
          0
        );
      }
    } catch (error) {
      this.dissmissLoading();
      this.messageInfo('Error', 'No se pudo aprobar de la orden.', 0);
    }
  }
  // Verificar si el valor de actualización es 0
  executeAction(type: number) {
    switch (type) {
      case 1:
        this.validateSendAprobedPurchOrder();
        break;
      case 2:
        this.goToConfirm();
        break;
      case 3:
        break;
      default:
        break;
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action !== 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.executeAction(action);
          },
        },
        { text: 'NO', handler: () => {} },
      ];
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  goToConfirm() {
    // Mando el parametro de la orde de compra
    this.ocParameter.setPurchOrderParameter(this.infoPurch);
    this.navService.navigateTo(NavigationRoutes.PurchOrderToApprovedPage);
  }

  doPress() {
    this.selected = true; // Selecciona o desselecciona el item
    this.infoPurch.setToSend(this.selected);
    this.selectedAction.emit(this.selected);
  }

  doClick() {
    if (this.selected) {
      this.selected = false;
      this.infoPurch.setToSend(this.selected);
      this.selectedAction.emit(this.selected);
    }
  }

  getStyle() {
    return this.selected ? 'selectedItem' : 'normalItem';
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }
}
