<div>
  <div style="display: flex; align-items: center; justify-content: center">
    <div style="width: 80%">
      <ion-item>
        <ion-label
          ><h2>{{ titulo }}</h2></ion-label
        >
      </ion-item>
    </div>
    <div slot="end" style="display: contents">
      <ion-button
        *ngIf="cantEdit"
        style="z-index: 1; width: 10%"
        (pan)="reduceQuantityOnPressUp()"
        (pressup)="reduceQuantityOnPressUp()"
        (press)="reduceQuantityOnPress()"
        (click)="clickReduce()"
        fill="clear"
      >
        <i style="font-size: large" class="fas fa-minus-circle"></i>
      </ion-button>
      <div style="width: 50%; text-align: center">
        <input
          class="quantityClass"
          type="number"
          name="salesQuantity"
          id="salesQuantity"
          [(ngModel)]="cantidadObjeto"
          (ngModelChange)="emitNumberChange()"
          min="0"
          step="1"
          [disabled]="!cantEdit"
        />
      </div>
      <ion-button
        *ngIf="cantEdit"
        style="width: 10%"
        (pan)="increaseQuantityOnPressUp()"
        (pressup)="increaseQuantityOnPressUp()"
        (press)="increaseQuantityOnPress()"
        (click)="clickIncrease()"
        fill="clear"
      >
        <i style="font-size: large" class="fas fa-plus-circle"></i>
      </ion-button>
    </div>
  </div>
</div>
