import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent {
  public credomaticForm: SafeResourceUrl;
  private orderid: string;
  private custid: string;
  private amount: number;
  private app: number;
  public responseReceived: boolean = false;
  public paymentStatus: number = 0;

  constructor(
    private sanitizer: DomSanitizer,
    public navParams: NavParams,
    public viewCtrl: PopoverController
  ) {
    let amount = this.navParams.get('amount') as number;
    this.app = this.navParams.get('app')
      ? (this.navParams.get('app') as number)
      : 0; // 0=HomexExpress / 1=MercasaVIP
    let custid = this.navParams.get('custid');
    let orderid = this.navParams.get('orderid');

    if (amount != null) {
      this.amount = Number.parseFloat(amount.toFixed(2));
    }
    if (custid != null) {
      this.custid = custid;
    }
    if (orderid != null) {
      this.orderid = orderid;
    }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://mercasacr.com:8096/PaymentForm/?amount=' +
        this.amount +
        '&orderid=' +
        this.orderid +
        '&custid=' +
        this.custid +
        '&app=' +
        this.app
    );
    // this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/PaymentForm/?amount=' + this.amount + '&orderid=' + this.orderid + '&custid=' + this.custid + '&app=' + this.app);

    window.addEventListener(
      'message',
      (res) => {
        //DEBUG
        //this.viewCtrl.dismiss('1');
        this.viewCtrl.dismiss(res.data);
        //DEBUG
      },
      false
    );
  }
}
