export class CatalogInfo {
  constructor(private value: number, private description: string) {}
  getValue() {
    return this.value;
  }

  getDescription() {
    return this.description;
  }
}
