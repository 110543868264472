export class Vehicle {
  private placVehicle: string = '';
  private typeProperty: number = 0;
  private typePropertyStr: string;
  private typeTransport: number = -1;
  private typeTransportStr: string = '';

  constructor(private numVehicle: string, private description: string) {}

  getId() {
    return this.numVehicle;
  }

  getName() {
    return this.description;
  }

  getValue() {
    return this.numVehicle;
  }

  setName(newName: string) {
    this.description = newName;
  }

  setValues(newData: Vehicle) {
    this.numVehicle = newData.getId();
    this.description = newData.getName();
  }

  getNumVehicle() {
    return this.numVehicle;
  }

  setNumVehicle(newNumVehicle: string) {
    this.numVehicle = newNumVehicle;
  }

  getPlacVehicle() {
    return this.placVehicle;
  }

  setPlacVehicle(newPlacVehicle: string) {
    this.placVehicle = newPlacVehicle;
  }

  setTypeProperty(newTypeProperty: number) {
    this.typeProperty = newTypeProperty;
  }

  getTypeProperty() {
    return this.typeProperty;
  }

  setTypeTransport(newTypeTransport: number) {
    this.typeTransport = newTypeTransport;
  }

  getTypeTransport() {
    return this.typeTransport;
  }

  setTypePropertyStr(newTypePropertyStr: string) {
    this.typePropertyStr = newTypePropertyStr;
  }

  getTypePropertyStr() {
    return this.typePropertyStr;
  }

  setTypeTransportStr(newTypeTransportStr: string) {
    this.typePropertyStr = newTypeTransportStr;
  }

  getTypeTransportStr() {
    return this.typeTransportStr;
  }
}
