<app-nav-bar [title]="titulo">
  <ion-buttons slot="end">
    <ion-button (click)="closePopOver(null)">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <ion-radio-group [(ngModel)]="posSelected">
    <ion-list>
      <ion-item *ngFor="let answer of listInfo; let i = index">
        <ion-label>
          <b>{{ answer.position }}</b> - {{ answer.getDescription() }}
        </ion-label>
        <ion-radio slot="start" [value]="answer.ID"></ion-radio>
      </ion-item>
    </ion-list>
  </ion-radio-group>

  <ion-item lines="none">
    <ion-checkbox [(ngModel)]="addNote" >Agregar nota complementaria</ion-checkbox>
  </ion-item>
  <div *ngIf="addNote">
    <textarea
      rows="10"
      cols="50"
      placeholder="Escriba la nota"
      [(ngModel)]="note"
      name="note"
      autocomplete="on"
      autocorrect="on"
      class="textArea"
    >
    </textarea>
  </div>
  <!--(ngModelChange)="emitNote()">-->
</ion-content>
