import { Pipe, PipeTransform } from '@angular/core';
import { PurchLinesInfoParamv2 } from '../OOP/PurchLinesInfoParamv2.interface';

@Pipe({
  name: 'POInvoiceFilter',
})
export class PurchOrderInvoiceFilterPipe implements PipeTransform {
  transform(array: PurchLinesInfoParamv2[], text: string): any[] {
    if (text === '') {
      return array;
    }

    text = text.toLowerCase();

    return array.filter((item) => {
      return (
        item.purchId.toLowerCase().includes(text) ||
        item.objectName.toLowerCase().includes(text)
      );
    });
  }
}
