import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { PurchLinesInfoParamv2 } from '../../OOP/PurchLinesInfoParamv2.interface';

@Component({
  selector: 'app-purch-order-einvoice-filter',
  templateUrl: './purch-order-einvoice-filter.component.html',
  styleUrls: ['./purch-order-einvoice-filter.component.scss'],
})
export class PurchOrderEinvoiceFilterComponent {
  PurchOrders: PurchLinesInfoParamv2[] = [];

  wrongDateFlag: boolean = false;
  startDate: Date;
  finalDate: Date;
  preLoadDates: boolean = false;

  MinMax = { lower: 0, upper: 0 };
  Min = 0;
  Max = 0;

  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.PurchOrders = this.params.get('data');
    this.MinMax = this.params.get('MinMax');
    let rangeDates = this.params.get('rangeDates');

    if (rangeDates) {
      this.preLoadDates = !this.preLoadDates;
      this.startDate = rangeDates[0];
      this.finalDate = rangeDates[1];
    }

    this.extractData();
  }

  extractData() {
    this.PurchOrders.forEach((x) => {
      let total = Number(x.totalAmount);
      let emittedDate = x.createdDate;
      if (this.Min === 0 || Number(this.Min) > total) {
        this.Min = total;
      }

      if (this.Max === 0 || Number(this.Max) < total) {
        console.log(this.Max, total);
        this.Max = total;
      }

      if (
        !this.preLoadDates &&
        (!this.startDate ||
          new Date(this.startDate).getTime() > new Date(emittedDate).getTime())
      ) {
        this.startDate = emittedDate;
      }

      if (
        !this.preLoadDates &&
        (!this.finalDate ||
          new Date(this.finalDate).getTime() < new Date(emittedDate).getTime())
      ) {
        this.finalDate = emittedDate;
      }
    });

    if (this.MinMax.lower === 0 && this.MinMax.upper === 0) {
      this.MinMax.lower = this.Min;
      this.MinMax.upper = this.Max;
    }
  }

  dismiss() {
    this.viewCtrl.dismiss({
      rangeDates: this.isValidFormat()
        ? this.startDate && this.finalDate
          ? [this.startDate, this.finalDate]
          : null
        : null,
      MinMax: this.MinMax,
    });
  }

  dismissNull() {
    this.viewCtrl.dismiss();
  }

  isValidFormat(): boolean {
    if (
      (this.startDate && this.finalDate && this.startDate <= this.finalDate) ||
      (!this.startDate && !this.finalDate)
    ) {
      return true;
    }
    return false;
  }
}
