<div class="head">Hallazgos</div>
<div class="body">
  <ion-list>
    <ion-item *ngFor="let element of auditFindingsCopy; let i = index">
      <ion-label>{{ element.getDescription() }}</ion-label>
      <ion-checkbox [(ngModel)]="element.selected"></ion-checkbox>
    </ion-item>
  </ion-list>
</div>
<div class="foot">
  <ion-button (click)="dismiss(0)">Cancelar</ion-button>
  <ion-button (click)="dismiss(1)">Aceptar</ion-button>
</div>
