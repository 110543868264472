<ion-list>
  <ion-list-header style="color: #223e99"> Filtros </ion-list-header>
  <div>
    <ion-item>
      <ion-select
        label="Empresa"
        label-placement="floating"
        class="floating"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeCompany($event)"
      >
        <!--TODOM [selected]="company.selected"-->
        <ion-select-option
          [value]="company"
          *ngFor="let company of filterData.companies"
          >{{ company.dataAreaId }} -
          {{ company.companyName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-select
        label="Categoría"
        label-placement="floating"
        class="floating"
        [(ngModel)]="categories"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeCategory($event)"
      >
        <!--TODOM [selected]="category.selected"-->
        <ion-select-option
          [value]="category"
          *ngFor="let category of filterData.categories"
          >{{ category.assetGroup }} -
          {{ category.assetGroupName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-select
        label="Estado"
        label-placement="floating"
        class="floating"
        [(ngModel)]="statusList"
        multiple="true"
        cancelText="Cancelar"
        okText="Aceptar"
        (ionChange)="onChangeStatus($event)"
      >
        <!--TODOM [selected]="status.selected"-->
        <ion-select-option
          [value]="status"
          *ngFor="let status of filterData.statusList"
          >{{ status.statusName }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </div>
</ion-list>

<ion-grid style="display: flex">
  <ion-button text-color="#0000ff" (click)="dismissNull()" style="width: 60%">
    <ion-icon name="trash"></ion-icon> Borrar Filtros
  </ion-button>

  <ion-button text-color="#0000ff" (click)="dismiss()" style="width: 40%">
    <ion-icon name="checkmark-done-outline"></ion-icon>Filtrar
  </ion-button>
</ion-grid>
