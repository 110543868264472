import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent implements OnInit {
  @Input() titulo: string;
  @Input() cantidadObjeto: number;
  @Input() cantEdit: boolean;
  @Output() getNumber = new EventEmitter<number>(); // Evento de numero
  incrQtyOnPressUp: boolean = false;
  reducQtyOnPressUp: boolean = false;
  constructor() {}

  ngOnInit() {}

  clickIncrease() {
    this.increaseQuantity();
    this.emitNumberChange();
  }

  clickReduce() {
    this.reduceQuantity();
    this.emitNumberChange();
  }

  increaseQuantity() {
    if (this.cantidadObjeto >= 0) {
      this.cantidadObjeto++;
    } else {
      this.cantidadObjeto = 0;
    }
  }

  reduceQuantity() {
    if (this.cantidadObjeto > 0) {
      this.cantidadObjeto--;
    } else {
      this.cantidadObjeto = 0;
    }
  }

  reduceQuantityOnPress() {
    this.reducQtyOnPressUp = true;
    this.reduceQuantityOnPress_Aux();
  }

  increaseQuantityOnPress() {
    this.incrQtyOnPressUp = true;
    this.increaseQuantityOnPress_Aux();
  }
  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que
   * al presionar el boton de se disminuya multiples veces la cantidad.
   * Metodo encargado de hacer el ciclo de reducción de cantidad
   */
  reduceQuantityOnPress_Aux() {
    this.reduceQuantity();
    if (this.reducQtyOnPressUp) {
      setTimeout(() => {
        this.reduceQuantityOnPress_Aux();
      }, 200);
    } else {
      this.emitNumberChange();
    }
  }

  /**
   * Metodo utilizado para incrementar la cantidad de manera tal que
   * al presionar el boton de se disminuya multiples veces la cantidad.
   * Metodo encargado de hacer el ciclo de reducción de cantidad
   */
  increaseQuantityOnPress_Aux() {
    this.increaseQuantity();
    if (this.incrQtyOnPressUp) {
      setTimeout(() => {
        this.increaseQuantityOnPress_Aux();
      }, 200);
    } else {
      this.emitNumberChange();
    }
  }

  /**
   * Metodo utilizado para cancelar el metodo reduce del quantity
   */
  reduceQuantityOnPressUp() {
    this.reducQtyOnPressUp = false;
  }

  /**
   * Metodo utilizado para cancelar el metodo increase del quantity
   */
  increaseQuantityOnPressUp() {
    this.incrQtyOnPressUp = false;
  }

  emitNumberChange() {
    this.getNumber.emit(this.cantidadObjeto);
  }
}
