// Native - Plugins
import { Component } from '@angular/core';

// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

// BusinessCore
import { NavController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { CustomerData } from './../../businessCore/CustomerData';
import { RegisterData } from './../../businessCore/RegisterData';

@Component({
  selector: 'app-my-profile-general-info',
  templateUrl: './my-profile-general-info.component.html',
  styleUrls: ['./my-profile-general-info.component.scss'],
})
export class MyProfileGeneralInfoComponent {
  // atributo que contiene toda la informacion de registro de un cliente
  // contactos, direcciones e informacion general
  register_data: RegisterData = new RegisterData();

  // atributo con la informacion mas detallada del cliente
  // como la lista de pago asociada, credito max-min, etc.
  customer_info: CustomerData = new CustomerData();

  loadingInfo: boolean = true;
  infoError: boolean = false;

  /**
   * Class constructor with these injections:
   * @param _apiProvider web service provider.
   * @param storage to store key/value pairs and JSON objects.
   */
  constructor(
    public _apiProvider: webServiceProvider,
    public storage: StorageService,
    public nav: NavController,
    private navService: NavigationService
  ) {
    // Se consulta la informacion del usuario por medio de la variable 'register_data'
    // almacenada en el storage
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
        this._apiProvider
          .getCustomer(this.register_data.id_card)
          .subscribe((res) => {
            let data = res as Array<CustomerData>;
            if (data && data.length > 0 && data[0]) {
              this.customer_info = data[0] as CustomerData;
            } else {
              this.infoError = true;
            }
            this.loadingInfo = false;
          });
      } else {
        this.loadingInfo = false;
        this.infoError = true;
      }
    });
  }

  // ionViewWillEnter() {

  //   this._apiProvider.getCustomer(this.register_data.id_card).subscribe((data) => {
  //     if (data && data.length > 0 && data[0]) {
  //       this.customer_info = data[0] as CustomerData;
  //     }
  //   });
  // }

  /**
   *
   * @param component
   * @param data
   */
  private redirectTo(component, data) {
    //this.nav.push(component, data);
    this.navService.navigateTo(component, data);
  }

  editPhoneInfo() {
    console.log('Editing phone numbers');
    this.redirectTo(NavigationRoutes.MyProfileEditPhoneComponent, {
      title: 'Editar Número Telefónico',
      imgUrl: './assets/img/casemanagement/phone.jpg',
      custData: this.customer_info,
      registerData: this.register_data,
    });
  }

  editEmailInfo() {
    console.log('Editing Email info');
    this.redirectTo(NavigationRoutes.MyProfileEditEmailComponent, {
      title: 'Editar Correo Electrónico',
      imgUrl: './assets/img/casemanagement/email.jpg',
      custData: this.customer_info,
      registerData: this.register_data,
    });
  }
}
