import { ItemByResult } from './ItemByResult';

export class PromoResultsViews {
  RecId: number = 0;
  PromoId: string = '';
  LineNum: number = 0;
  DataAreaId: string = '';
  Type: number = 0;
  SubType: number = 0;
  SubTypeValue: string = '';
  SubTypeValue1: string = '';
  QtyType: number = 0;
  QtyValue: number = 0;
  QtyValueTo: number = 0;
  UntId: string = '';
  ConditionOr: number = 0;
  ConditionsList: number = 0;
  Group: number = 0;
  How: number = 0;
  Operator: number = 0;
  QtyProduct: number = 0;

  // Defined by the user
  Selected: boolean = false;
  Showed: boolean = false;

  // Promo Description Generated by the component Promo-result
  Desc: string = '';
  SubDesc: string = '';

  // Items included in the promoResult
  ItemByResult: ItemByResult[] = [];

  // ENUMS
  /*
    0- sin definir
    2- % descuento
    3- Monto Sobre total de la orden
    4- Monto sobre unidad
    */
  getQtyTypeENUM() {
    return [
      { ID: 0, Name: ' sin definir ' },
      { ID: 2, Name: 'Porcentaje de descuento igual a ' },
      { ID: 3, Name: 'Monto sobre el total de la orden igual a ' },
      { ID: 4, Name: 'Monto sobre la unidad igual a ' },
    ];
  }

  /*
    0- Sin definir
    10- Sobre articulos del grupo y lista
    11- Sobre articulos del grupo
    100- sobre cod de articulo
    101- sobre lista de condiciones
    102- sobre total
    103- sobre fam
    104- sobre sub fam
    105- sobre cat
    106- sobre sub cat
    107- sobre sub sub cat
    108- 100% sobre cod de articulo
    */
  getSubTypeENUM() {
    return [
      { ID: 0, Name: ' sin definir ' },
      { ID: 10, Name: ' sobre los artículos del grupo y lista ' },
      { ID: 11, Name: ' sobre los artículos del grupo ' },
      { ID: 100, Name: ' sobre el artículo ' },
      { ID: 101, Name: ' sobre la lista de condiciones ' },
      { ID: 102, Name: ' sobre total ' },
      { ID: 103, Name: ' sobre familia ' },
      { ID: 104, Name: ' sobre sub familia ' },
      { ID: 105, Name: ' sobre categoría ' },
      { ID: 106, Name: ' sobre sub categoría ' },
      { ID: 107, Name: ' sobre subsub categoría ' },
      { ID: 108, Name: ' como regalía para el artículo ' },
    ];
  }
}
