import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PurchInfoResult } from '../../OOP/PurchInfoResult.interface';
import { PurchLinesInfoParam } from '../../OOP/PurchLinesInfoParam.interface';
import { SelectFilterOption } from '../../OOP/SelectFilterOption.interface';
import { SelectFilterService } from '../../services/select-filter.service';

@Component({
  selector: 'app-purch-remission-info',
  templateUrl: './purch-remission-info.component.html',
  styleUrls: ['./purch-remission-info.component.scss'],
})
export class PurchRemissionInfoComponent {
  private data: PurchLinesInfoParam;
  private modal: any;
  public loader: HTMLIonLoadingElement;
  public provider: string;
  public objectId: string;
  public objectName: string;
  public purchId: string;
  public id: string;
  public roleSpecificIds: SelectFilterOption[] = [];
  public roleSpecificIdAssistants: SelectFilterOption[] = [];
  public containerIds: SelectFilterOption[] = [];

  public roleSpecificIdsSelected: SelectFilterOption[] = [];
  public roleSpecificIdAssistantsSelected: SelectFilterOption[] = [];
  public containerIdsSelected: SelectFilterOption[] = [];

  public roleSpecificIdsLabel: string;
  public roleSpecificIdsAssistantsLabel: string;
  public containerIdsLabel: string;

  public infoResult: PurchInfoResult = {
    status: false,
    roleSpecificIds: [],
    roleSpecificIdsAssistants: [],
    containerIds: [],
    id: '',
  };

  constructor(
    public navParams: NavParams,
    private SelectFilterService: SelectFilterService
  ) {
    this.data = this.navParams.get('data');
    this.modal = this.navParams.get('modal');

    //DEBUG
    /*this.data = {
      purchId: '',
      objectId: '',
      objectName: '',
      roleSpecificIds: [],
      containerIds: [],
      id: '',
      roleSpecificIdsSelected: [],
      roleSpecificIdsAssistantsSelected: [],
      containerIdsSelected: [],
      remissionMode: '',
      status: '',
      createdDate: new Date(),
    };*/
    // DEBUG
    // Get Info
    this.purchId = this.data.purchId;
    this.objectId = this.data.objectId;
    this.objectName = this.data.objectName;
    this.roleSpecificIds = this.data.roleSpecificIds;
    this.roleSpecificIdAssistants = this.data.roleSpecificIds;
    this.containerIds = this.data.containerIds;
    this.id = this.data.id;

    // Get Selected Info
    this.roleSpecificIdsSelected = this.data.roleSpecificIdsSelected;
    this.roleSpecificIdAssistantsSelected =
      this.data.roleSpecificIdsAssistantsSelected;
    this.containerIdsSelected = this.data.containerIdsSelected;

    // Set default info
    this.roleSpecificIdsLabel = this.create_SelectOptionsLabel(
      this.roleSpecificIdsSelected
    );
    this.roleSpecificIdsAssistantsLabel = this.create_SelectOptionsLabel(
      this.roleSpecificIdAssistantsSelected
    );
    this.containerIdsLabel = this.create_SelectOptionsLabel(
      this.containerIdsSelected
    );
  }

  ngOnInit() {}

  saveInfo() {
    this.infoResult = {
      status: true,
      roleSpecificIds: this.roleSpecificIdsSelected,
      roleSpecificIdsAssistants: this.roleSpecificIdAssistantsSelected
        ? this.roleSpecificIdAssistantsSelected
        : [],
      containerIds: this.containerIdsSelected,
      id: this.id,
    };

    this.close();
  }

  async selectRoleSpecificId() {
    let options = await this.selectOptions(
      this.roleSpecificIds,
      this.roleSpecificIdsSelected,
      false
    );

    if (options != null) {
      this.roleSpecificIdsSelected = options;
      this.roleSpecificIdsLabel = this.create_SelectOptionsLabel(options);
    }
  }

  async selectRoleSpecificIdAssitants() {
    let options = await this.selectOptions(
      this.roleSpecificIdAssistants,
      this.roleSpecificIdAssistantsSelected,
      true
    );

    if (options != null) {
      this.roleSpecificIdAssistantsSelected = options;
      this.roleSpecificIdsAssistantsLabel =
        this.create_SelectOptionsLabel(options);
    }
  }

  async selectContainerId() {
    let options = await this.selectOptions(
      this.containerIds,
      this.containerIdsSelected,
      false
    );

    if (options != null) {
      this.containerIdsSelected = options;
      this.containerIdsLabel = this.create_SelectOptionsLabel(options);
    }
  }

  async selectOptions(options, optionsSelected, multiple) {
    await this.SelectFilterService.presentModal(
      options,
      optionsSelected,
      multiple
    );
    let resp_options = await this.SelectFilterService.onDidDismiss();
    return options;
  }

  create_SelectOptionsLabel(options) {
    let label: string = '';

    if (options != null) {
      for (let option of options) {
        label += (label ? ',' : '') + option.label;

        if (label.length > 25) {
          break;
        }
      }
    }

    return label;
  }

  close() {
    if (this.modal) {
      this.modal.dismiss(this.infoResult);
    }
  }

  showForm() {}
}

export enum PurchRemissionInfoKeys {
  RoleSpecificId = 'roleSpecificId',
  RoleSpecificIdAssistants = 'roleSpecificIdAssistants',
  ContainerIds = 'containerIds',
}
