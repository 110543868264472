import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuditFinding } from '../../businessCore/AuditFinding';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent {
  public assetRecId: number;
  public comment: string;
  public auditFindings: AuditFinding[];
  public assigned: number;

  constructor(public viewCtrl: ModalController, private navParams: NavParams) {
    this.assetRecId = this.navParams.get('assetRecId');
    this.comment = this.navParams.get('comment');
    this.auditFindings = this.navParams.get('auditFindings');
    this.assigned = this.navParams.get('assigned');
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y efectuar
   * los cambios deseados para que el sistema muestre los productos
   * que coincidan con la selección
   */
  dismiss() {
    if (!this.comment) {
      this.viewCtrl.dismiss();
    } else {
      this.viewCtrl.dismiss({
        assetRecId: this.assetRecId,
        comment: this.comment,
        auditFindings: this.auditFindings,
        assigned: this.assigned,
      });
    }
  }
}
