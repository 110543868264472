import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Bank } from '../../businessCore/Bank';
import { Currency } from '../../businessCore/Currency';
import { PaymentTerm } from '../../businessCore/PaymentTerm';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { SearchProviderComponent } from '../oc-components/search-provider/search-provider.component';

@Component({
  selector: 'app-vendor-register-payment',
  templateUrl: './vendor-register-payment.component.html',
  styleUrls: ['./vendor-register-payment.component.scss'],
})
export class VendorRegisterPaymentComponent implements OnInit {
  @Input() paymentData: any = {};
  @Output() paymentDataChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() paymentLanguage: { [key: string]: any } = {};
  @Input() changeLanguageTo = '';
  @Output() languageChanger = new EventEmitter<string>();

  @Output() screenChanger = new EventEmitter<number>();

  paymentTermList: PaymentTerm[];
  paymentTermSelected: PaymentTerm = new PaymentTerm('', '');

  bankList: Bank[];
  bankSelected: Bank = new Bank('', '');

  currencyList: Currency[];
  currencySelected: Currency = new Currency('', '');

  bankAccount = '';
  ABANumber = '';
  SWIFTCode = '';

  constructor(
    private _apiProvider: webServiceProvider,
    public modalCtrl: ModalController,
    public navCtrl: NavController
  ) {}

  async ngOnInit() {
    this.setPaymentVariables();
  }

  setPaymentVariables() {
    this.paymentTermSelected = this.paymentData.paymentTerm;
    this.bankSelected = this.paymentData.bank;
    this.currencySelected = this.paymentData.currency;
    this.bankAccount = this.paymentData.bankAccount;
    this.ABANumber = this.paymentData.ABANumber;
    this.SWIFTCode = this.paymentData.SWIFTCode;
  }

  setGlobalVariables() {
    this.paymentData.paymentTerm = this.paymentTermSelected;
    this.paymentData.bank = this.bankSelected;
    this.paymentData.currency = this.currencySelected;
    this.paymentData.bankAccount = this.bankAccount;
    this.paymentData.ABANumber = this.ABANumber;
    this.paymentData.SWIFTCode = this.SWIFTCode;
  }

  /**
   *
   */
  changeLanguage() {
    switch (this.changeLanguageTo) {
      case 'ES':
        this.languageChanger.emit('EN');
        break;
      case 'EN':
        this.languageChanger.emit('ES');
        break;
      default:
        break;
    }
  }

  /**
   *
   */
  async getPaymentTermList() {
    const resp = await this._apiProvider.getPaymentTerm();
    const list: PaymentTerm[] = resp as PaymentTerm[];
    return list;
  }

  /**
   *
   */
  async getBankList() {
    const resp = await this._apiProvider.getBank();
    const list: Bank[] = resp as Bank[];
    return list;
  }

  /**
   *
   */
  async getCurrencyList() {
    const resp = await this._apiProvider.getCurrency();
    const list: Currency[] = resp as Currency[];
    return list;
  }

  /**
   *
   * @param option
   */
  async selectMenuOption(menuOption: number) {
    switch (menuOption) {
      case 0: //Select PaymentTerm
        this.paymentTermList = await this.getPaymentTermList();
        this.selectSpecificData(this.paymentTermList, menuOption);
        break;
      case 1: //Select Bank
        this.bankList = await this.getBankList();
        this.selectSpecificData(this.bankList, menuOption);
        break;
      case 2: //Select Currency
        this.currencyList = await this.getCurrencyList();
        this.selectSpecificData(this.currencyList, menuOption);
        break;
      default:
        break;
    }
  }

  /**
   *
   * Muestra un popover donde se elige al nuevo responsable de los activos.
   *
   * @param listInfo
   * @param menuOption
   */
  async selectSpecificData(listInfo: any[], menuOption: number) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.setDataSelected(data.objSelected, menuOption);
        }
      }
    });
  }

  /**
   *
   * @param dataSelected
   * @param menuOption
   */
  async setDataSelected(dataSelected: any, menuOption: number) {
    switch (menuOption) {
      case 0: //PaymentTerm
        this.paymentTermSelected = dataSelected;
        break;
      case 1: //Bank
        this.bankSelected = dataSelected;
        break;
      case 2: //Currency
        this.currencySelected = dataSelected;
        break;
      default:
        break;
    }
  }

  /**
   *
   */
  disableNext() {
    if (
      this.paymentTermSelected.getPaymTermId() &&
      this.bankSelected.getBankGroupID() &&
      this.currencySelected.getCurrencyCodeId() &&
      this.bankAccount
    ) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param pSecreen
   */
  changeScreen(pSecreen: number) {
    this.setGlobalVariables();
    this.paymentDataChange.emit(this.paymentData);
    this.screenChanger.emit(pSecreen);
  }
}
