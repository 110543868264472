import { Injectable } from '@angular/core';
import { TripModel } from '../../businessCore/trip-models/trip';

@Injectable()
export class TripParameterProvider {
  private actualTrip: TripModel;
  private showParadaOP: boolean;
  constructor() {
    this.actualTrip = null;
    this.showParadaOP = true;
  }

  getTripParameter() {
    return this.actualTrip;
  }

  setTripParameter(newTrip: TripModel) {
    this.actualTrip = newTrip;
  }

  setParadaOP(newShowParadaOP: boolean) {
    this.showParadaOP = newShowParadaOP;
  }

  getParadaOP() {
    return this.showParadaOP;
  }
}
