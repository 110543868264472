import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(public storage: Storage) {}

  async get(key: string) {
    const exist: boolean = await this.exist(key);
    if (!exist) {
      await this.storage.create();
    }

    return await this.storage.get(key);
  }

  async create() {
    this.storage.create();
  }

  async exist(key: string) {
    try {
      await this.storage.get(key);
    } catch {
      return false;
    }

    return true;
  }

  async set(key: string, data: any) {
    await this.storage.set(key, data);
  }

  async remove(key: string) {
    await this.storage.remove(key);
  }

  async clear() {
    await this.storage.clear();
  }
}
