import { Component } from '@angular/core';

@Component({
  selector: 'app-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss'],
})
export class ShowImageComponent {
  text: string;

  constructor() {
    console.log('Hello ShowImageComponent Component');
    this.text = 'Hello World';
  }
}
