<app-nav-bar [title]="'Confirmar Orden'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button style="background: none" (click)="openMenuOC($event)">
      <img
        src="assets/img/menuPoint2.svg"
        style="width: 25px"
        alt=""
        onerror=""
      />
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box">
    <ion-item lines="none" *ngIf="purchInfo.getIdOC() !== ''">
      <ion-label style="font-size: 1rem; text-align: center">
        <b>{{ purchInfo.getIdOC() }}</b>
      </ion-label>
    </ion-item>
    <div style="display: flex">
      <ion-item lines="none" style="width: 30%">
        <ion-label style="font-size: 1rem">
          {{ purchInfo.getAccountNum() }}
        </ion-label>
      </ion-item>
      <ion-item
        lines="none"
        style="width: 70%"
        *ngIf="purchInfo.getVendName() !== ''"
      >
        <ion-label text-wrap style="font-size: 1rem">
          {{ purchInfo.getVendName() }}
        </ion-label>
      </ion-item>
    </div>
    <div
      lines="none"
      style="width: 50%; padding-left: 15px"
      *ngIf="purchInfo.getVendName() !== ''"
    >
      <ion-label style="font-size: 1rem" class="currency">
        {{ purchInfo.getCurrency() }}
      </ion-label>
    </div>
    <ion-content class="content">
      <ion-list>
        <app-linea-info-oc
          *ngFor="let purchLineInf of purchInfo.getLineList()"
          [purchLineInf]="purchLineInf"
          (deleteItem)="deleteItem($event)"
          (updateInfPurch)="updateInfPurch($event)"
          [cantEdit]="cantEdit"
          [taxInfo]="purchInfo.getTaxInclude()"
        >
        </app-linea-info-oc>
      </ion-list>
    </ion-content>

    <!--<linea-info-total [total]="total" [cantidadLineas]="cantidadLineas"></linea-info-total>-->
    <div class="infContent">
      <ion-item
        lines="none"
        color="primary"
        style="margin-top: 2%; margin-bottom: 2%"
      >
        <div style="display: flex; width: 100%">
          <h5 style="width: 100%; font-size: 1rem; text-align: center">
            N° Líneas: {{ cantidadLineas }}
          </h5>
          <h5 style="width: 100%; font-size: 1rem; text-align: center">
            Imp: {{ getCurrency(impuestos) }}
          </h5>
          <h5 style="width: 100%; font-size: 1rem; text-align: center">
            Total: {{ getCurrency(total) }}
          </h5>
        </div>
      </ion-item>
      <div class="whiteRectangle"></div>
    </div>
  </div>
</ion-content>

<ion-fab horizontal="start" vertical="bottom">
  <ion-fab-button (click)="goToBack()" size="small"
    ><ion-icon name="chevron-back-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
