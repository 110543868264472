export class InfoImage {
  recIdAnswer: number;
  dataImage: string;
  extension: string;
  type: number;
  action: number;
  vatnum: string;
  company: string;

  constructor() {}

  setRecIdAnswer(newRecId: number) {
    this.recIdAnswer = newRecId;
  }

  getRecIdAnswer() {
    return this.recIdAnswer;
  }

  setDataImage(newDataImage: string) {
    this.dataImage = newDataImage;
  }

  getDataImage() {
    return this.dataImage;
  }

  toJSON() {
    return {
      base64: '',
      attachmentExtension: this.extension,
      attachmentType: this.type,
      attachmentAction: this.action,
      associatedVatnum: this.vatnum,
      associatedElementId: 0,
      associatedCompany: this.company,
    };
  }

  toJSONLocal() {
    return {
      base64: this.dataImage,
      attachmentExtension: this.extension,
      attachmentType: this.type,
      attachmentAction: this.action,
      associatedVatnum: this.vatnum,
      associatedElementId: 0,
      associatedCompany: this.company,
    };
  }
}
