<app-nav-bar [title]="'Activo sin asignar'" [start]="'GoBack'">
  <ion-buttons slot="start">
    <ion-button ion-button icon-only (click)="viewCtrl.dismiss()">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button (click)="getAsset()">
      <ion-icon name="search"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content overflow-scroll="true">
  <div style="display: flex; justify-content: center; margin-top: 15px">
    <input
      [(ngModel)]="barcode"
      type="text"
      placeholder="Código de barras"
      style="background: white; color: grey; width: 80%"
    />
  </div>

  <div class="vertical-center">
    <p *ngIf="!assetList.length">Ingrese parámetros de búsqueda</p>
    <ion-list *ngIf="assetList.length">
      <ion-item
        [disabled]="checkAsset(item.getAssetRecId())"
        (click)="selectAsset(i)"
        *ngFor="let item of assetList; let i = index"
      >
        <ion-label
          >{{ item.getDataAreaId() }} - {{ item.getAssetName() }}</ion-label
        >
      </ion-item>
    </ion-list>
  </div>
</ion-content>
