import { TaskCheck } from './TaskCheck';
export class CheckList {
  private TasksList: TaskCheck[];
  constructor(
    private ID: string,
    private description: string,
    private name: string
  ) {
    this.TasksList = [];
  }

  setTasksList(newTasksList: TaskCheck[]) {
    this.TasksList = newTasksList;
  }

  getTasksList() {
    return this.TasksList;
  }

  getID() {
    return this.ID;
  }

  setID(newID: string) {
    this.ID = newID;
  }

  getDescription() {
    return this.description;
  }

  setDescriptio(newDescription: string) {
    this.description = newDescription;
  }

  getName() {
    return this.name;
  }

  setName(newDescription: string) {
    this.description = newDescription;
  }

  toJsonTaskAnswer() {
    const listInfo: any[] = [];
    for (const task of this.TasksList) {
      listInfo.push(task.toJSON());
    }
    return JSON.stringify(listInfo);
  }

  private toJsonLocaltTaskAnswer() {
    const listInfo: any[] = [];
    for (const task of this.TasksList) {
      listInfo.push(task.toJSONLocalInfo());
    }
    return listInfo;
  }

  toJsonLocalCheckListInfo() {
    return {
      TasksList: this.toJsonLocaltTaskAnswer(),
      ID: this.ID,
      Description: this.description,
      Name: this.name,
    };
  }
}
