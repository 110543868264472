import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { ItemConvertion } from '../../../businessCore/OrderRet/ItemConvertion';
import { PSRtnBoughtItem } from '../../../businessCore/OrderRet/PSRtnBoughtItem';
import { PSRtnLine } from '../../../businessCore/OrderRet/PSRtnLine';
import { PSRtnReason } from '../../../businessCore/OrderRet/PSRtnReason';
import { webServiceProvider } from '../../../provider/webServiceProvider';

@Component({
  selector: 'app-ret-add-item',
  templateUrl: './ret-add-item.component.html',
  styleUrls: ['./ret-add-item.component.scss'],
})
export class RetAddItemComponent {
  item: PSRtnBoughtItem;
  boughtItems: PSRtnBoughtItem[] = [];
  itemConvertion: ItemConvertion[];
  AccountNum: string = '';
  psRtnLines: PSRtnLine[] = [];
  originSite: string = '';

  psRtnReason: PSRtnReason[] = [];
  batchIds: string[] = [];
  expDates: string[] = [];
  unitIds: string[] = [];

  // Selected Values
  reason: string = '';
  expDate: string = '';
  batchId: string = '';
  unitId: string = '';
  qty: string = '';

  loading: HTMLIonLoadingElement;

  constructor(
    public params: NavParams,
    private viewCtrl: ModalController,
    private provider: webServiceProvider,
    private toast: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {
    this.item = this.params.get('item');
    // this.boughtItems = pr.boughtItems;
    this.itemConvertion = this.params.get('itemConvertion');
    this.AccountNum = this.params.get('accountNum');
    this.psRtnLines = this.params.get('psRtnLines');
    this.originSite = this.params.get('originSite');

    console.log('this.item');
    console.log(this.item);

    // console.log('this.boughtItems');
    // console.log(this.boughtItems);

    console.log('this.itemConvertion');
    console.log(this.itemConvertion);

    this.getRtnReasons();
    this.getItemInfo();
  }

  async getItemInfo() {
    this.loading = await this.loadingCtrl.create({
      message: 'Cargando información del producto...',
    });

    this.loading.present();

    this.provider
      .GetPSRtnItemInfo(this.item.ITEMID, this.AccountNum)
      .subscribe((data) => {
        this.boughtItems = data as PSRtnBoughtItem[];
        console.log('this.boughtItems');
        console.log(this.boughtItems);
        this.initSelects();

        this.loading.dismiss();
      });
  }

  getFactorByUnit(UnitId: string): number {
    return this.itemConvertion.filter((ic) => ic.UNITID == UnitId).length > 0
      ? this.itemConvertion.filter((ic) => ic.UNITID == UnitId)[0].FACTOR
      : 1;
  }

  getTotalBoughtUnits(batchId: string) {
    let res = 0;
    if (batchId != '') {
      this.boughtItems
        .filter((bi) => bi.INVENTBATCHID == batchId)
        .forEach((item) => {
          res =
            res + Math.trunc(item.QTY * this.getFactorByUnit(item.SALESUNIT));
        });
    } else {
      this.boughtItems.forEach((item) => {
        res = res + Math.trunc(item.QTY * this.getFactorByUnit(item.SALESUNIT));
      });
    }

    return res;
  }

  /**
   * Obtiene las unidades agregadas en el carro de devoluciones para ese articulo
   */
  getUnitsInRtnCar(ItemId: string): number {
    let units = 0;
    this.psRtnLines
      .filter((l) => l.itmId == ItemId)
      .forEach((rtnLine) => {
        units =
          units +
          this.getFactorByUnit(rtnLine.untId) * Math.trunc(rtnLine.slQntty);
      });
    return units;
  }

  async validSelectedQty() {
    if (this.qty != '' && this.unitId != '') {
      let qtySelectedUnits =
        this.getFactorByUnit(this.unitId) * Number.parseInt(this.qty);
      let batchAux = this.batchIds.length > 0 ? this.batchId : '';
      let availableUnits = this.getTotalBoughtUnits(batchAux);
      let unitsInRtnCar = this.getUnitsInRtnCar(this.item.ITEMID);

      let invUnit =
        this.itemConvertion.length > 0 ? this.itemConvertion[0].INVUNITID : '';

      console.log('qtySelectedUnits');
      console.log(qtySelectedUnits);

      console.log('availableUnits');
      console.log(availableUnits);

      console.log('availableUnits - this.getUnitsInRtnCar(this.item.ITEMID)');
      console.log(availableUnits - unitsInRtnCar);

      if (qtySelectedUnits > 0) {
        // cantidad ingresada OK
        if (qtySelectedUnits <= availableUnits - unitsInRtnCar) {
          return true;
        }
        // Error de cantidad excedida
        else {
          if (batchAux != '') {
            let alert = await this.alertCtrl.create({
              header: 'Cantidad Excedida!',
              subHeader: 'N° de Lote: ' + batchAux,
              message:
                `<p>La cantidad agregada para este lote debe ser menor o igual a: <a>` +
                availableUnits +
                ' ' +
                invUnit.toLowerCase() +
                `(s).</a></p>
            <p>Cantidad agregada actualmente:</p>
            <ul><li>` +
                unitsInRtnCar +
                ' ' +
                invUnit.toLowerCase() +
                `(s)</li></ul>`,
              buttons: [
                {
                  text: 'OK',
                  role: 'cancel',
                },
              ],
            });
            alert.present();
          } else {
            let alert = await this.alertCtrl.create({
              header: 'Cantidad Excedida!',
              subHeader:
                'Cantidad facturada: ' +
                availableUnits +
                ' ' +
                invUnit.toLowerCase() +
                '(s).',
              message:
                `<p>La cantidad agregada debe ser menor o igual a: <a>` +
                availableUnits +
                ' ' +
                invUnit.toLowerCase() +
                `(s).</a></p>
            <p>Cantidad agregada actualmente:</p>
            <ul>` +
                unitsInRtnCar +
                ' ' +
                invUnit.toLowerCase() +
                `(s)</ul>`,
              buttons: [
                {
                  text: 'OK',
                  role: 'cancel',
                },
              ],
            });
            alert.present();
          }
        }
      } else {
        let toast = await this.toast.create({
          message: 'Debe ingresar una cantidad válida!',
          duration: 3000,
        });
        toast.present();
      }
    } else {
      let toast = await this.toast.create({
        message:
          'Debe ingresar todos los datos requeridos para poder continuar!',
        duration: 3000,
      });
      toast.present();
    }

    return false;
  }

  async dismiss(type: number) {
    // Cancel
    if (type == 0) {
      this.viewCtrl.dismiss();
    }
    // Add Item
    else {
      if (
        this.unitId != '' &&
        this.qty != '' &&
        this.reason != '' &&
        ((this.batchIds.length > 0 &&
          this.batchId != '' &&
          this.expDate != '') ||
          this.batchIds.length <= 0)
      ) {
        if (this.validSelectedQty()) {
          let res = new PSRtnLine();

          res.baseObject = this.item;
          res.itmId = this.item.ITEMID;
          res.untId = this.unitId;
          res.slQntty = Number.parseInt(this.qty);
          res.lnAmnt = 0;
          res.dlvryDte = '';
          res.InvBtchId = this.batchId != '' ? this.batchId : ''; //BatchId
          res.InvBtchExpryDt = this.expDate != '' ? this.expDate : '1900-01-01';
          res.RsnCd = this.reason; // ReasonCode
          res.dbtNbr = ''; // InvoiceID

          res.lnId = 145;
          res.RqstTyp = 1; // requestType
          res.DspstnCdId = '202'; // disposal

          res.orgnSte = this.originSite;

          this.psRtnLines.push(res);

          this.viewCtrl.dismiss();
          console.log('SUCCESS');
        }
      } else {
        let toast = await this.toast.create({
          message: 'Debe completar toda la información solicitada!',
          duration: 4000,
        });
        toast.present();
      }
    }
  }

  getRtnReasons() {
    this.provider.GetPSRtnReason().subscribe(async (data) => {
      if (data != null) {
        this.psRtnReason = data as PSRtnReason[];
        if (this.psRtnReason.length > 0) {
          this.reason = this.psRtnReason[0].REASONCODEID;
        }
        // console.log('this.psRtnReason');
        // console.log(this.psRtnReason);
      } else {
        let toast = await this.toast.create({
          message: 'No se pudieron consultar las razones de devolución',
          duration: 3000,
        });
        toast.present();
      }
    });
  }

  /**
   * batchIds: string[]
   * expDates: string[]
   * unitIds: string[]
   */
  initSelects() {
    // expDates
    this.boughtItems
      .filter((bi) => bi.ITEMID == this.item.ITEMID)
      .forEach((data) => {
        if (data.EXPDATE != '' && this.expDates.indexOf(data.EXPDATE) == -1) {
          this.expDates.push(data.EXPDATE);
        }
      });
    // batchIds
    this.boughtItems
      .filter((bi) => bi.ITEMID == this.item.ITEMID)
      .forEach((data) => {
        if (
          data.INVENTBATCHID != '' &&
          this.batchIds.indexOf(data.INVENTBATCHID) == -1
        ) {
          this.batchIds.push(data.INVENTBATCHID);
        }
      });
    // unitIds
    this.itemConvertion.forEach((ic) => {
      if (ic.UNITID != '' && this.unitIds.indexOf(ic.UNITID) == -1) {
        this.unitIds.push(ic.UNITID);
      }
    });
  }
}
