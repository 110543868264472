<app-nav-bar [title]="'Mis Direcciones'"></app-nav-bar>

<ion-content>
  <div style="text-align: center" *ngIf="loadingDirections">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando Información</h6>
  </div>
  <div *ngIf="!loadingDirections && directionsError">
    No se pudieron consultar las direcciones del cliente
  </div>
  <div
    class="sectionContainer"
    style="margin: 15px"
    *ngIf="!loadingDirections && !directionsError"
  >
    <h4 class="title2" style="text-align: center; font-weight: bold">
      Información de Direcciones
    </h4>
    <div
      class="flexBlockContainer"
      *ngFor="let item of customer_info.CustomerAddresses"
    >
      <hr />
      <div class="flexContainer">
        <h6 class="titleContent">Nombre:</h6>
        <div class="textContent">{{ item.AddressName }}</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Descripción:</h6>
        <div class="textContent">{{ item.Address }}</div>
      </div>
      <!-- <div class="flexContainer">
                <h6 class="titleContent">Ruta de entrega:</h6>
                <div class="textContent">{{item.DeliveryRoute}}</div>
            </div>
            <div class="flexContainer">
                <h6 class="titleContent">Método de entrega:</h6>
                <div class="textContent">{{item.DeliveryMethod}}</div>
            </div>
            <div class="flexContainer" *ngIf="item.SalesAgent && item.SalesAgent != ''">
                <h6 class="titleContent">Código del Agente:</h6>
                <div class="textContent">{{item.SalesAgent}}</div>
            </div>
            <div class="flexContainer" *ngIf="item.NombreVendedor && item.NombreVendedor != ''">
                <h6 class="titleContent">Nombre del Agente:</h6>
                <div class="textContent">{{item.NombreVendedor}}</div>
            </div>
            <div class="flexContainer" *ngIf="item.NumeroTelVendedor && item.NumeroTelVendedor != ''">
                <h6 class="titleContent">Número del Agente:</h6>
                <div class="textContent" style="color: #223e99; text-decoration: underline;" (click)="callSeller(item.NumeroTelVendedor)">{{'+506 ' + item.NumeroTelVendedor}}</div>
                <i style="color: #333333; font-size: 18px; margin-left: 3%;" class="fas fa-phone-square" (click)="callSeller(item.NumeroTelVendedor)"></i>
            </div>
            <div class="flexContainer">
                <h6 class="titleContent">Lista de precios:</h6>
                <div class="textContent" *ngIf="item.PriceGroupId != ''">{{item.PriceGroupId}}</div>
                <div class="textContent" *ngIf="item.PriceGroupId == ''">{{customer_info.PriceGroup}}</div>
            </div> 
            <div class="flexContainer">
                <h6 class="titleContent">¿Es principal?:</h6>
                <div class="textContent" *ngIf="item.IsPrimary">Si</div>
                <div class="textContent" *ngIf="!item.IsPrimary">No</div>
            </div> -->
      <div class="flexContainer">
        <h6 class="titleContent">Restricciones:</h6>
        <div class="textContent">{{ item.notes }}</div>
        <div class="textContent" *ngIf="item.notes == ''">---</div>
      </div>
      <div
        class="flexContainer"
        (click)="startExternalMap(item)"
        *ngIf="item.Latitude != 0 && item.Longitude != 0"
      >
        <h6 class="viewAddress">Ver dirección en el mapa</h6>
      </div>
      <div
        class="flexContainer"
        (click)="startExternalMap(item)"
        *ngIf="item.Latitude == 0 || item.Longitude == 0"
      >
        <h6
          style="
            font-size: 11px;
            text-align: center;
            min-width: 100%;
            max-width: 100%;
            color: #f53d3d;
          "
        >
          Esta dirección no posee ningún punto GPS registrado.
        </h6>
      </div>
    </div>
  </div>
</ion-content>
