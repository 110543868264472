import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GastoModel } from '../../../businessCore/trip-models/gasto';

@Component({
  selector: 'app-spend-trip',
  templateUrl: './spend-trip.component.html',
  styleUrls: ['./spend-trip.component.scss'],
})
export class SpendTripComponent {
  description: string;
  factura: string;
  monto: number;
  proveedorInfo: string;

  constructor(private viewCtrl: ModalController) {}

  getmonto(event) {
    this.monto = event as number;
  }

  closeCancel() {
    this.closePopOver(null);
  }

  saveSpend() {
    const newSpend = new GastoModel(
      this.factura,
      this.monto,
      this.description,
      this.proveedorInfo
    );
    this.closePopOver(newSpend);
  }

  async closePopOver(spend: GastoModel) {
    this.viewCtrl.dismiss({
      spendCreated: spend,
    });
  }
}
