<app-nav-bar [title]="'Aprobación de traslado'">
  <ion-buttons slot="end">
    <ion-button ion-button icon-only (click)="refreshAssetRequest()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box">
    <div class="content">
      <app-asset-request-list
        *ngIf="requestsList.length"
        [requestList]="requestsList"
        [requestType]="1"
        (requestListCheckedEmit)="setRequestListChecked($event)"
      ></app-asset-request-list>
    </div>
    <div style="display: flex" class="flex-container nowrap infContent">
      <ion-button
        [disabled]="!requestListChecked.length"
        color="danger"
        (click)="presentAssetReqActionPopover(4)"
        style="width: 100%"
      >
        Rechazar
      </ion-button>
      <ion-button
        [disabled]="!requestListChecked.length"
        (click)="presentAssetReqActionPopover(0)"
        style="width: 100%"
      >
        Aprobar
      </ion-button>
    </div>
  </div>
</ion-content>
