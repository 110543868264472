export class ConfirmOrderHeader {
  ADDRESS: string = '';
  ADDRESSNAME: string = '';
  CREATEDDATETIME: string = '';
  CUSTACCOUNT: string = '';
  DeliveryDir: string = '';
  FechaVence: string = '';
  IdV: string = '';
  NAME: string = '';
  PAYMMODE: string = '';
  PSCUSTSHIPDATE: string = '';
  SALESID: string = '';
  TerminoPago: string = '';
  VATNUM: string = '';
  nameV: string = '';
  telefono: string = '';

  constructor() {}
}
