import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CatalogModel } from '../../../businessCore/oc-models/CatalogModel';
import { ItemOCModel } from '../../../businessCore/oc-models/ItemOCModel';
import { ItemOCInfoPriceModel } from '../../../businessCore/oc-models/ItemOCPriceInfoModel';
import { PurchLine } from '../../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';

@Component({
  selector: 'app-line-oc',
  templateUrl: './line-oc.component.html',
  styleUrls: ['./line-oc.component.scss'],
})
export class LineOcComponent implements OnInit {
  @Input() purhInfoOC: PurchOrder;
  @Input() lineaActual: PurchLine;
  @Input() productActual: ItemOCModel;
  @Input() unidadSelected: ItemOCInfoPriceModel;
  @Input() cantidadLineas: number;
  @Input() total: number;
  @Input() impuestos: number;
  @Input() aDepartamentoSelected: CatalogModel;
  @Input() bCentroCostoSelected: CatalogModel;
  @Input() cPropositoSelected: CatalogModel;
  @Input() tipoImpuesto: CatalogModel;
  @Output() getPurchInfoOC = new EventEmitter<PurchOrder>();
  @Output() openSearchBar = new EventEmitter<string>(); // Evento la direccion
  // unidad: string;
  // cantidad: number;
  // precioAct: number;
  // porcenDescuent: number;
  presentingMess = false;
  dimensionesFinShow = false;
  constructor(public alertController: AlertController) {
    this.aDepartamentoSelected =
      this.aDepartamentoSelected !== null &&
      this.aDepartamentoSelected !== undefined
        ? this.aDepartamentoSelected
        : new CatalogModel('' as any, '');

    this.bCentroCostoSelected =
      this.bCentroCostoSelected !== null &&
      this.bCentroCostoSelected !== undefined
        ? this.bCentroCostoSelected
        : new CatalogModel('' as any, '');

    this.cPropositoSelected =
      this.cPropositoSelected !== null && this.cPropositoSelected !== undefined
        ? this.cPropositoSelected
        : new CatalogModel('' as any, '');

    this.tipoImpuesto =
      this.tipoImpuesto !== null && this.tipoImpuesto !== undefined
        ? this.tipoImpuesto
        : new CatalogModel('' as any, '');
  }

  ngOnInit() {
    // Item seleccionado por el usuario
    this.lineaActual = new PurchLine();
    // this.precioAct = this.unidadSelected.getAmount();
    // this.porcenDescuent = this.unidadSelected.getPercent();
    // this.unidad = this.unidadSelected.getUnitId();
    // this.cantidad = 0;
    // Carga un dato por default o el que pasa el usuario
    this.setData();
  }

  ionViewDidEnter() {
    this.setData();
  }

  setData() {
    if (this.purhInfoOC !== null && this.purhInfoOC !== undefined) {
      this.total = this.purhInfoOC.total;
      this.cantidadLineas = this.purhInfoOC.cantidadLineas;
      this.impuestos = this.purhInfoOC.taxes;
    }
  }

  getNumberCantidad(cantidadP: number) {
    this.unidadSelected.cantidad = cantidadP;
  }

  addProducto() {
    // Se setean los datos de la linea a anadir y se verifican las restricciones
    if (this.unidadSelected.cantidad > 0) {
      if (
        this.unidadSelected.amount > 0 &&
        this.unidadSelected.percent >= 0 &&
        this.unidadSelected.percent <= 100
      ) {
        if (this.productActual) {
          if (
            this.unidadSelected.unitId !== '' &&
            this.productActual.getId() !== ''
          ) {
            if (
              this.aDepartamentoSelected !== null &&
              this.aDepartamentoSelected !== undefined &&
              this.bCentroCostoSelected !== null &&
              this.bCentroCostoSelected !== undefined &&
              this.cPropositoSelected !== null &&
              this.cPropositoSelected !== undefined &&
              (this.aDepartamentoSelected.getId() as any) !== '' &&
              (this.bCentroCostoSelected.getId() as any) !== ''
              //&& this.cPropositoSelected.getId() as any !== ''
            ) {
              if (this.purhInfoOC) {
                this.unidadSelected.typeTaxes =
                  this.tipoImpuesto.getId() as any;
                this.unidadSelected.taxes = this.tipoImpuesto.getRecId();
                this.lineaActual.itemId = this.productActual.getId();
                this.lineaActual.name = this.productActual.getName();
                this.lineaActual.cantidad = this.unidadSelected.cantidad; // cantidad que el usuario digita
                this.lineaActual.taxes = this.unidadSelected.taxes;
                this.lineaActual.porcentDescuent = this.unidadSelected.percent; // descuento que el usuario digita
                this.lineaActual.precio = this.unidadSelected.amount;
                this.lineaActual.unitId = this.unidadSelected.unitId; // unidad que el usuario eligo
                this.lineaActual.setA_Departamento(this.aDepartamentoSelected);
                this.lineaActual.setB_CentroCosto(this.bCentroCostoSelected);
                this.lineaActual.setC_Proposito(this.cPropositoSelected);
                this.lineaActual.calculateInfo(this.purhInfoOC.getTaxInclude());
                this.lineaActual.setTaxItemGroup(this.unidadSelected.typeTaxes);
                this.purhInfoOC.insertLine(this.lineaActual);
                // Se actualizan las lineas y totales
                this.cantidadLineas = this.purhInfoOC.cantidadLineas;
                this.total = this.purhInfoOC.total;
                this.impuestos = this.purhInfoOC.taxes;
                // Se seta algun nuevo producto
                this.lineaActual = new PurchLine();
                this.unidadSelected = this.productActual
                  .getListUnits()[0]
                  .copyItem(this.productActual.getListUnits()[0]);
                this.tipoImpuesto = new CatalogModel(
                  this.unidadSelected.getTypeTaxes() as any,
                  this.unidadSelected.getTypeTaxes()
                );
                this.tipoImpuesto.setRecId(this.unidadSelected.getTaxes());
                this.emmitPurchInfo();
                this.messageInfo('Línea', 'Línea agregada con éxito a la OC.');
              } else {
                this.messageInfo(
                  'Línea',
                  'No hay información de los datos de cabecera de la OC'
                );
              }
            } else {
              this.messageInfo(
                'Error',
                'No hay datos de dimensiones financieras.'
              );
            }
          } else {
            this.messageInfo(
              'Error Unidad/Item',
              'Se ocupa la unidad y el nombre del producto para realizar la OC.'
            );
          }
        } else {
          this.messageInfo(
            'Error Producto',
            'No se cargó correctamente la inforamción del producto.'
          );
        }
      } else {
        this.messageInfo(
          'Error Precio y/o Porcentaje',
          'La cantidad debe de ser mayor a 0.'
        );
      }
    } else {
      this.messageInfo('Error cantidad', 'La cantidad debe de ser mayor a 0.');
    }
  }

  emitSelectedBar(type: number) {
    let typeS = '';
    switch (type) {
      case 1:
        typeS = 'search-product';
        break;
      case 2:
        typeS = 'search-unit';
        break;
      case 6:
        typeS = 'search-A_Departamento';
        break;
      case 7:
        typeS = 'search-B_CentroCosto';
        break;
      case 8:
        typeS = 'search-C_Proposito';
        break;
      case 9:
        typeS = 'search-typeTaxes';
        break;
      default:
        break;
    }
    this.openSearchBar.emit(typeS);
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [{ text: 'Aceptar', handler: () => {} }],
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  emmitPurchInfo() {
    this.getPurchInfoOC.emit(this.purhInfoOC);
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  // Dimensiones Financieras -------------------------------------------------------------
  //
  openCloseDimensionesFinancieras() {
    this.dimensionesFinShow = !this.dimensionesFinShow;
  }
}
