export class FixedAsset {
  assetRecId: number;
  assetGroup: string;
  assetGroupName: string;
  assetId: string;
  assetName: string;
  barcode: string;
  companyName: string;
  dataAreaId: string;
  location: string;
  workerResponsible: string;
  mainAssetId: string;
  pending: boolean;
  approval: boolean;
  acquValue: string;
  accuDepreciation: string;
  netBookValue: string;
  imgURL: string;

  //Las siguientes variables no son atributos de la clase Assets en AX son exclusivas del FrontEnd
  //Proveen información para poder realizar las funcionalidades.
  newResponsible: string;
  selected: boolean;
  expanded: boolean;
  affected: boolean;
  subAssets: FixedAsset[];

  //Setters y Getters
  getAssetRecId() {
    return this.assetRecId;
  }

  getAssetId() {
    return this.assetId;
  }

  getBarcode() {
    return this.barcode;
  }

  getAssetName() {
    return this.assetName;
  }

  getAssetGroup() {
    return this.assetGroup;
  }

  getAssetGroupName() {
    return this.assetGroupName;
  }

  getDataAreaId() {
    return this.dataAreaId;
  }

  getCompanyName() {
    return this.companyName;
  }

  getLocation() {
    return this.location;
  }

  getResponsibleId() {
    return this.workerResponsible;
  }

  getMainAssetId() {
    return this.mainAssetId;
  }

  getPending() {
    return this.pending;
  }

  getSelected() {
    return this.selected;
  }

  getExpanded() {
    return this.expanded;
  }

  getAffected() {
    return this.affected;
  }

  getSubAssets() {
    return this.subAssets;
  }

  getApproval() {
    return this.approval;
  }

  getNewResponsible() {
    return this.newResponsible;
  }

  getAcquValue() {
    return this.acquValue;
  }

  getAccuDepreciation() {
    return this.accuDepreciation;
  }

  getNetBookValue() {
    return this.netBookValue;
  }

  getImgURL() {
    return this.imgURL;
  }
}

//Clase complementaria para pasar los datos necesarios para realizar el filtro.
export class FilterData {
  companies: any[];
  categories: any[];
  statusList: any[];
}
