import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { VendorRegisterData } from '../../businessCore/VendorRegisterData';
import { Vendor } from '../../businessCore/vendor';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-vendor-alert',
  templateUrl: './vendor-alert.component.html',
  styleUrls: ['./vendor-alert.component.scss'],
})
export class VendorAlertComponent implements OnInit {
  actualLanguage = '';

  vendor: Vendor;
  registerData: VendorRegisterData;

  step: number = 0;
  stepTxt: string = '';

  alertVendLg = {
    title: '',
    save: '',
    generalAndPayment: '',
    contact: '',
    address: '',
    documentation: '',
  };

  alertVendES = {
    title: 'FAVOR ESPERAR',
    save: 'Guardando',
    generalAndPayment: 'datos generales e información de pago',
    contact: 'Información de contacto',
    address: 'Direcciones',
    documentation: 'documentación',
  };

  alertVendEN = {
    title: 'PlEASE WAIT',
    save: 'Saving',
    generalAndPayment: 'general data and Payment info',
    contact: 'Contact info',
    address: 'Addresses',
    documentation: 'documentation',
  };

  constructor(
    public storage: StorageService,
    private viewCtrl: ModalController,
    private navParams: NavParams,
    private _apiProvider: webServiceProvider
  ) {}

  async ngOnInit() {
    try {
      this.actualLanguage = this.navParams.get('actualLanguage');
      if (this.actualLanguage == 'EN') {
        this.alertVendLg = this.alertVendES;
      } else {
        this.alertVendLg = this.alertVendEN;
      }
      this.vendor = this.navParams.get('vendor');
      this.registerData = this.navParams.get('registerData');
      let vendNoDocuments: Vendor = new Vendor();
      vendNoDocuments = vendNoDocuments.parseRegisterNoDocument(
        this.registerData
      );

      this.stepTxt = this.alertVendLg.generalAndPayment;
      //let interrupt =
      let vendTableAuxRecId = String(
        await this._apiProvider.registerVendorAux(vendNoDocuments)
      );
      //await this.doInterrupt(interrupt);
      this.step++;

      this.stepTxt = this.alertVendLg.contact;
      // console.log(this.vendor);
      let interrupt = String(
        await this._apiProvider.registerVendContactAux(
          this.vendor,
          vendTableAuxRecId
        )
      );
      await this.doInterrupt(interrupt);
      this.step++;

      this.stepTxt = this.alertVendLg.address;
      interrupt = String(
        await this._apiProvider.registerVendAddressAux(
          this.vendor,
          vendTableAuxRecId
        )
      );
      await this.doInterrupt(interrupt);
      this.step++;

      this.stepTxt = this.alertVendLg.documentation;
      // let allDocuments = this.vendor.vatNumVendor;
      // allDocuments.push(...this.vendor.vatNumRepres);
      // allDocuments.push(...this.vendor.vatNumReprInvoice);
      // allDocuments.push(...this.vendor.vatNumReprService);
      // allDocuments.push(...this.vendor.vendorAgreement);
      // allDocuments.push(...this.vendor.transferAuthorization);
      // allDocuments.push(...this.vendor.guaranteePolicies);
      await this.iterateAttachment(this.vendor.vatNumVendor, this.vendor, 7);
      await this.iterateAttachment(this.vendor.vatNumRepres, this.vendor, 8);
      await this.iterateAttachment(
        this.vendor.vatNumReprInvoice,
        this.vendor,
        9
      );
      await this.iterateAttachment(
        this.vendor.vatNumReprService,
        this.vendor,
        10
      );
      await this.iterateAttachment(
        this.vendor.vendorAgreement,
        this.vendor,
        11
      );
      await this.iterateAttachment(
        this.vendor.transferAuthorization,
        this.vendor,
        12
      );
      await this.iterateAttachment(
        this.vendor.guaranteePolicies,
        this.vendor,
        13
      );
      this.step++;
      this.closePopOver(true);
    } catch {
      this.closePopOver(false);
    }
  }

  async iterateAttachment(
    attachment: any[],
    pVendor: any,
    pTypeOfAction: number
  ) {
    for (let index = 0; index < attachment.length; index++) {
      const element = attachment[index];
      let interrupt = String(
        await this._apiProvider.uploadImage(
          element.fileTo64,
          element.fileExtension,
          pTypeOfAction,
          8,
          pVendor.vendorVatnum,
          pVendor.vendorVatnum,
          'CMER'
        )
      );

      // let interrupt = String(await this._apiProvider.vendorAttach(element.fileTo64, element.fileExtension, 6, 8,
      //   pVendor.vendorVatnum, pVendor.vendorVatnum, "CMER", pTypeOfFile));
      this.doInterrupt(interrupt);
    }
  }

  async doInterrupt(interrupt: string) {
    if (interrupt != '1') {
      this.closePopOver(false);
    }
  }

  async closePopOver(data: boolean) {
    this.viewCtrl.dismiss(data);
  }
}
