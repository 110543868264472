import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { PurchLinesInfoParam } from '../../OOP/PurchLinesInfoParam.interface';

@Component({
  selector: 'app-purch-order-remission-filter',
  templateUrl: './purch-order-remission-filter.component.html',
  styleUrls: ['./purch-order-remission-filter.component.scss'],
})
export class PurchOrderRemissionFilterComponent {
  listPurchOrders: PurchLinesInfoParam[] = [];
  listVendors: string[] = [];
  listVendorsSelected: string[] = [];

  wrongDateFlag: boolean = false;
  startDate: Date;
  finalDate: Date;

  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.listPurchOrders = this.params.get('data') as PurchLinesInfoParam[];
    this.listVendorsSelected = this.params.get('listVendors') as string[];

    let rangeDates = this.params.get('rangeDates') as Date[];
    if (rangeDates) {
      this.startDate = rangeDates[0];
      this.finalDate = rangeDates[1];
    }

    // DEBUG
    /*
    this.listPurchOrders = [
      {
        purchId: '',
        objectId: '',
        objectName: '',
        roleSpecificIds: [],
        containerIds: [],
        id: '',
        roleSpecificIdsSelected: [],
        roleSpecificIdsAssistantsSelected: [],
        containerIdsSelected: [],
        remissionMode: '',
        status: '',
        createdDate: new Date(),
      },
    ];

    this.listVendorsSelected = ['123'];
    */
    // DEBUG
    this.extractData();
  }

  extractData() {
    this.listPurchOrders.forEach((x) => {
      let vendor = x.objectName;
      if (!this.listVendors.some((x) => x === vendor)) {
        this.listVendors.push(vendor);
      }
    });

    this.listVendors = this.listVendors.sort();
  }

  dismiss() {
    this.viewCtrl.dismiss({
      listVendors: this.listVendorsSelected,
      rangeDates: this.isValidFormat()
        ? this.startDate && this.finalDate
          ? [this.startDate, this.finalDate]
          : null
        : null,
    });
  }

  dismissNull() {
    this.viewCtrl.dismiss();
  }

  isValidFormat(): boolean {
    if (
      (this.startDate && this.finalDate && this.startDate <= this.finalDate) ||
      (!this.startDate && !this.finalDate)
    ) {
      return true;
    }
    return false;
  }
}
