import { PSRtnLine } from './PSRtnLine';

export class PSRtnHeader {
  rtnId: string = '120037510';
  cstmrAccnt: string = 'IQG000';
  crtdDttme: string = '2019 - 10 - 31T04: 51: 18Z';
  dlvryDte: string = '';
  plnDlvryDte: string = '2019 - 11 - 08T00: 00: 00Z';
  rtnStts: number = 1;
  shpTId: number = 0;
  ttlOrdr: number = 0;
  dlvryRte: string = 'RE131';
  orgnSte: string = 'CSF';
  PSRtnLine: PSRtnLine[] = [];

  ordrType: number = 0;
  athorztnNbr: string = null;
  dbtNbr: string = '311019';
  RtrnRsnCdId: string = null;
  authId: number = 0;
  NomAuth: string = null;
  apprvlTyp: number = 1;
  rtnType: number = 0;
  delvryMthd: string = 'Inteca';
  cstmrRma: string = '';

  // DEFAULTS
  addtnlInstrctns: string = null;
  dtArId: string = 'cmer';
  emplyId: string = 'HOEX00';
  DocPurpose: string = 'Original';
  SenderId: string = 'vcom';

  // No importan
  OrigOrdNbr: string = null;
  ttlSls: number = 0;
  txAmnt: number = 0;
  ttlDscnt: number = 0;
  ttlFs: number = 0;
  prntd: number = 0;
  isPrpsl: number = 0;
  ordrModel: number = 2;
  paymMethod: string = null;
  streId: string = null;
  steId: string = null;

  constructor() {}

  toXML() {
    let dateAux = new Date(this.dlvryDte);
    this.dlvryDte = new Date(
      dateAux.getTime() - dateAux.getTimezoneOffset() * 60000
    ).toISOString();
    this.plnDlvryDte = this.dlvryDte;
    this.crtdDttme = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    ).toISOString();

    let xml: string = '';

    xml =
      '<PSRtn class="entity">' +
      '<DocPurpose>' +
      this.DocPurpose +
      '</DocPurpose>' +
      '<SenderId>' +
      this.SenderId +
      '</SenderId>' +
      // ----------- PSRtnHeader
      '<PSRtnHeader class="entity">' +
      '<rtnId>' +
      this.rtnId +
      '</rtnId>' +
      '<cstmrAccnt>' +
      this.cstmrAccnt +
      '</cstmrAccnt>' +
      '<crtdDttme>' +
      this.crtdDttme +
      '</crtdDttme>' +
      '<OrigOrdNbr>' +
      this.OrigOrdNbr +
      '</OrigOrdNbr>' +
      '<emplyId>' +
      this.emplyId +
      '</emplyId>' +
      '<dlvryDte>' +
      this.dlvryDte +
      '</dlvryDte>' +
      '<plnDlvryDte>' +
      this.plnDlvryDte +
      '</plnDlvryDte>' +
      '<rtnStts>' +
      this.rtnStts +
      '</rtnStts>' +
      '<shpTId>' +
      this.shpTId +
      '</shpTId>' +
      '<ttlSls>' +
      this.ttlSls +
      '</ttlSls>' +
      '<txAmnt>' +
      this.txAmnt +
      '</txAmnt>' +
      '<ttlDscnt>' +
      this.ttlDscnt +
      '</ttlDscnt>' +
      '<ttlFs>' +
      this.ttlFs +
      '</ttlFs>' +
      '<ttlOrdr>' +
      this.ttlOrdr +
      '</ttlOrdr>' +
      '<addtnlInstrctns>' +
      this.addtnlInstrctns +
      '</addtnlInstrctns>' +
      '<prntd>' +
      this.prntd +
      '</prntd>' +
      '<delvryMthd>' +
      this.delvryMthd +
      '</delvryMthd>' +
      '<orgnSte>' +
      this.orgnSte +
      '</orgnSte>' +
      '<dlvryRte>' +
      this.dlvryRte +
      '</dlvryRte>' +
      '<ordrType>' +
      this.ordrType +
      '</ordrType>' +
      '<isPrpsl>' +
      this.isPrpsl +
      '</isPrpsl>' +
      '<ordrModel>' +
      this.ordrModel +
      '</ordrModel>' +
      '<paymMethod>' +
      this.paymMethod +
      '</paymMethod>' +
      '<steId>' +
      this.steId +
      '</steId>' +
      '<athorztnNbr>' +
      this.athorztnNbr +
      '</athorztnNbr>' +
      '<cstmrRma>' +
      this.cstmrRma +
      '</cstmrRma>' +
      '<dbtNbr>' +
      this.dbtNbr +
      '</dbtNbr>' +
      '<RtrnRsnCdId>' +
      this.RtrnRsnCdId +
      '</RtrnRsnCdId>' +
      '<authId>' +
      this.authId +
      '</authId>' +
      '<NomAuth>' +
      this.NomAuth +
      '</NomAuth>' +
      '<apprvlTyp>' +
      this.apprvlTyp +
      '</apprvlTyp>' +
      '<rtnType>' +
      this.rtnType +
      '</rtnType>' +
      // ----------- PSRtnHeaderCpny
      '<PSRtnHeaderCpny class="entity">' +
      '<rtnId>' +
      this.rtnId +
      '</rtnId>' +
      '<dtArId>' +
      this.dtArId +
      '</dtArId>' +
      '<ttlSls>' +
      this.ttlSls +
      '</ttlSls>' +
      '<txAmnt>' +
      this.txAmnt +
      '</txAmnt>' +
      '<ttlDscnt>' +
      this.ttlDscnt +
      '</ttlDscnt>' +
      '<ttlFs>' +
      this.ttlFs +
      '</ttlFs>' +
      '<ttlOrdr>' +
      this.ttlOrdr +
      '</ttlOrdr>' +
      '<prntd>' +
      this.prntd +
      '</prntd>' +
      '<shpTId>' +
      this.shpTId +
      '</shpTId>' +
      '<dlvryDte>' +
      this.dlvryDte +
      '</dlvryDte>' +
      '<plnDlvryDte>' +
      this.plnDlvryDte +
      '</plnDlvryDte>' +
      '<delvryMthd>' +
      this.delvryMthd +
      '</delvryMthd>' +
      '<orgnSte>' +
      this.orgnSte +
      '</orgnSte>' +
      '<dlvryRte>' +
      this.dlvryRte +
      '</dlvryRte>' +
      '<addtnlInstrctns>' +
      this.addtnlInstrctns +
      '</addtnlInstrctns>' +
      '<OrigOrdNbr>' +
      this.OrigOrdNbr +
      '</OrigOrdNbr>' +
      '<paymMethod>' +
      this.paymMethod +
      '</paymMethod>' +
      '<streId>' +
      this.streId +
      '</streId>' +
      '<steId>' +
      this.steId +
      '</steId>' +
      '<athorztnNbr>' +
      this.athorztnNbr +
      '</athorztnNbr>' +
      '<cstmrRma>' +
      this.cstmrRma +
      '</cstmrRma>' +
      '<dbtNbr>' +
      this.dbtNbr +
      '</dbtNbr>' +
      '<apprvlTyp>' +
      this.apprvlTyp +
      '</apprvlTyp>';
    // PSRtnLine
    this.PSRtnLine.forEach((line) => {
      let psrtLine = new PSRtnLine();
      psrtLine.lnId = line.lnId;
      psrtLine.rtnId = this.rtnId;
      psrtLine.dtrId = line.dtrId;
      psrtLine.itmId = line.itmId;
      psrtLine.untId = line.untId;
      psrtLine.slQntty = line.slQntty;
      psrtLine.slPrce = line.slPrce;
      psrtLine.ttlSle = line.ttlSle;
      psrtLine.txAmnt = line.txAmnt;
      psrtLine.lneDscnt = line.lneDscnt;
      psrtLine.lnPrcnt = line.lnPrcnt;
      psrtLine.fAmnt = line.fAmnt;
      psrtLine.lnAmnt = line.lnAmnt;
      psrtLine.dlvryDte = this.dlvryDte;
      psrtLine.delvryMthd = this.delvryMthd;
      psrtLine.orgnSte = this.orgnSte;
      psrtLine.dlvryRte = this.dlvryRte;
      psrtLine.InvBtchExpryDt = line.InvBtchExpryDt;
      psrtLine.RsnCd = line.RsnCd;
      psrtLine.DspstnCdId = line.DspstnCdId;
      psrtLine.RqstTyp = line.RqstTyp;
      // Revisar estos
      psrtLine.cstmrRma = line.cstmrRma;
      psrtLine.InvBtchId = line.InvBtchId;
      psrtLine.Nts = line.Nts;
      psrtLine.dbtNbr = this.dbtNbr;

      xml += psrtLine.toXML();
    });
    // FIN PSRtnLine

    xml += '</PSRtnHeaderCpny>' + '</PSRtnHeader>' + '</PSRtn>';
    // FIN PSRtnHeaderCpny, FIN PSRtnHeader

    return encodeURI(xml);
  }
}

// -----------------------------        PS TOXML METHOD     -----------------------------

// public String toXml(String deliveryMethod, String deliveryRoute) {
//     StringBuilder stringBuilder = new StringBuilder(500);
//     stringBuilder.append("<PSRtnHeader class=\"entity\">");
//     Utils.storeValueToXml(stringBuilder, "rtnId", returnId);
//     Utils.storeValueToXml(stringBuilder, "cstmrAccnt", customerAccount);
//     Utils.storeValueToXml(stringBuilder, "crtdDttme", createdDatetime);
//     Utils.storeValueToXml(stringBuilder, "OrigOrdNbr", "null");
//     Utils.storeValueToXml(stringBuilder, "emplyId", employeeId);
//     Utils.storeValueToXml(stringBuilder, "dlvryDte", collectedDate);
//     Utils.storeValueToXml(stringBuilder, "plnDlvryDte", planCollectedDate);
//     Utils.storeValueToXml(stringBuilder, "rtnStts", returnStatus.ordinal());
//     Utils.storeValueToXml(stringBuilder, "shpTId", (long)collectToId);
//     Utils.storeValueToXml(stringBuilder, "ttlSls", "0");
//     Utils.storeValueToXml(stringBuilder, "txAmnt", "0");
//     Utils.storeValueToXml(stringBuilder, "ttlDscnt", "0");
//     Utils.storeValueToXml(stringBuilder, "ttlFs", "0");
//     Utils.storeValueToXml(stringBuilder, "ttlOrdr", totalFinancialAmount);
//     Utils.storeValueToXml(stringBuilder, "addtnlInstrctns", String.valueOf(additionalInstructions));
//     Utils.storeValueToXml(stringBuilder, "prntd", String.valueOf(0));
//     Utils.storeValueToXml(stringBuilder, "delvryMthd", String.valueOf(deliveryMethod));
//     Utils.storeValueToXml(stringBuilder, "orgnSte", String.valueOf(originSite));
//     Utils.storeValueToXml(stringBuilder, "dlvryRte", String.valueOf(deliveryRoute));
//     Utils.storeValueToXml(stringBuilder, "ordrType", String.valueOf(returnType.ordinal()));
//     Utils.storeValueToXml(stringBuilder, "isPrpsl", isProposal ? 1 : 0);
//     Utils.storeValueToXml(stringBuilder, "ordrModel", "2");
//     Utils.storeValueToXml(stringBuilder, "paymMethod", "null");
//     Utils.storeValueToXml(stringBuilder, "streId", "null");
//     Utils.storeValueToXml(stringBuilder, "steId", "null");
//     Utils.storeValueToXml(stringBuilder, "athorztnNbr", authorizationNumber);
//     Utils.storeValueToXml(stringBuilder, "cstmrRma", customerRma);
//     Utils.storeValueToXml(stringBuilder, "dbtNbr", debitNumber);
//     Utils.storeValueToXml(stringBuilder, "RtrnRsnCdId", reasonReturnId);
//     Utils.storeValueToXml(stringBuilder, "authId", authId);
//     Utils.storeValueToXml(stringBuilder, "NomAuth", authorizedName);
//     Utils.storeValueToXml(stringBuilder, "apprvlTyp", approvalType);
//     Utils.storeValueToXml(stringBuilder, "rtnType", debitNoteType.ordinal());

//     /*
//     Utils.storeValueToXml(stringBuilder, "RtnId", returnId);
//     Utils.storeValueToXml(stringBuilder, "cstmrAccnt", customerAccount);
//     Utils.storeValueToXml(stringBuilder, "crtdDttme", createdDatetime);
//     Utils.storeValueToXml(stringBuilder, "emplyId", employeeId);
//     Utils.storeValueToXml(stringBuilder, "clcTId", collectToId);
//     Utils.storeValueToXml(stringBuilder, "clctdDte", collectedDate);
//     Utils.storeValueToXml(stringBuilder, "rtnStts", returnStatus.ordinal());
//     Utils.storeValueToXml(stringBuilder, "addtnlInstrctns", String.valueOf(additionalInstructions));
//     Utils.storeValueToXml(stringBuilder, "athorztnNbr", authorizationNumber);
//     Utils.storeValueToXml(stringBuilder, "dbtNbr", debitNumber);
//     Utils.storeValueToXml(stringBuilder, "orgnSte", originSite);
//     Utils.storeValueToXml(stringBuilder, "rtrnType", String.valueOf(returnType.ordinal()));
//     Utils.storeValueToXml(stringBuilder, "isPrpsl", isProposal ? 1 : 0);
//     */
//     stringBuilder.append("</PSRtnHeader>");
//     return stringBuilder.toString();
// }

// // ************************** BASE BASE BASE ***********************************
// // ************************** BASE BASE BASE ***********************************
// // ************************** BASE BASE BASE ***********************************

// <?xml version="1.0" encoding="UTF-8"?>
// <PSRtn class="entity">
//    <DocPurpose>Original</DocPurpose>
//    <SenderId>vcom</SenderId>
//    <PSRtnHeader class="entity">
//       <rtnId>120039542</rtnId>
//       <cstmrAccnt>WEWU010</cstmrAccnt>
//       <crtdDttme>2020-02-06T07:31:34Z</crtdDttme>
//       <OrigOrdNbr>null</OrigOrdNbr>
//       <emplyId>VDP16</emplyId>
//       <dlvryDte>2020-02-14T00:00:00Z</dlvryDte>
//       <plnDlvryDte>2020-02-14T00:00:00Z</plnDlvryDte>
//       <rtnStts>1</rtnStts>
//       <shpTId>5647039192</shpTId>
//       <ttlSls>0</ttlSls>
//       <txAmnt>0</txAmnt>
//       <ttlDscnt>0</ttlDscnt>
//       <ttlFs>0</ttlFs>
//       <ttlOrdr>0</ttlOrdr>
//       <addtnlInstrctns />
//       <prntd>0</prntd>
//       <delvryMthd>Inteca</delvryMthd>
//       <orgnSte>CCA</orgnSte>
//       <dlvryRte>RE131</dlvryRte>
//       <ordrType>0</ordrType>
//       <isPrpsl>0</isPrpsl>
//       <ordrModel>2</ordrModel>
//       <paymMethod>null</paymMethod>
//       <streId>null</streId>
//       <steId>null</steId>
//       <athorztnNbr>null</athorztnNbr>
//       <cstmrRma />
//       <dbtNbr>06022020</dbtNbr>
//       <RtrnRsnCdId>null</RtrnRsnCdId>
//       <authId>0</authId>
//       <NomAuth>null</NomAuth>
//       <apprvlTyp>1</apprvlTyp>
//       <rtnType>0</rtnType>
//       <PSRtnHeaderCpny class="entity">
//          <rtnId>120039542</rtnId>
//          <dtArId>cmer</dtArId>
//          <ttlSls>0</ttlSls>
//          <txAmnt>0</txAmnt>
//          <ttlDscnt>0</ttlDscnt>
//          <ttlFs>0</ttlFs>
//          <ttlOrdr>0</ttlOrdr>
//          <prntd>0</prntd>
//          <shpTId>5647039192</shpTId>
//          <dlvryDte>2020-02-14T00:00:00Z</dlvryDte>
//          <plnDlvryDte>2020-02-14T00:00:00Z</plnDlvryDte>
//          <delvryMthd>null</delvryMthd>
//          <orgnSte>CCA</orgnSte>
//          <dlvryRte>null</dlvryRte>
//          <addtnlInstrctns>null</addtnlInstrctns>
//          <OrigOrdNbr>null</OrigOrdNbr>
//          <paymMethod>null</paymMethod>
//          <streId>null</streId>
//          <steId>null</steId>
//          <athorztnNbr>null</athorztnNbr>
//          <cstmrRma />
//          <dbtNbr>null</dbtNbr>
//          <apprvlTyp>1</apprvlTyp>
//          <PSRtnLine class="entity">
//             <lnId>203</lnId>
//             <rtnId>120039542</rtnId>
//             <dtrId>cmer</dtrId>
//             <itmId>MTCLLI18</itmId>
//             <untId>PAQUET</untId>
//             <slQntty>48</slQntty>
//             <slPrce>0</slPrce>
//             <ttlSle>0</ttlSle>
//             <txAmnt>0</txAmnt>
//             <lneDscnt>0</lneDscnt>
//             <lnPrcnt>0</lnPrcnt>
//             <fAmnt>0</fAmnt>
//             <lnAmnt>0</lnAmnt>
//             <dlvryDte>2020-02-14T00:00:00Z</dlvryDte>
//             <delvryMthd>null</delvryMthd>
//             <orgnSte>0</orgnSte>
//             <dlvryRte>null</dlvryRte>
//             <dbtNbr />
//             <cstmrRma />
//             <InvBtchId />
//             <InvBtchExpryDt>2020-02-06</InvBtchExpryDt>
//             <RsnCd>19A</RsnCd>
//             <Nts />
//             <DspstnCdId>203</DspstnCdId>
//             <RqstTyp>1</RqstTyp>
//          </PSRtnLine>
//       </PSRtnHeaderCpny>
//    </PSRtnHeader>
// </PSRtn>
