<ion-content>
  <h1 *ngIf="typeAction == 4" class="title">
    ¿Desea rechazar el traslado de estos activos?
  </h1>
  <h1 *ngIf="typeAction == 0" class="title">
    ¿Desea aprobar el traslado de estos activos?
  </h1>
  <h1 *ngIf="typeAction == 1" class="title">
    ¿Desea aceptar el traslado de estos activos?
  </h1>
  <div class="box">
    <div class="content">
      <ion-card>
        <ion-card-content>
          <ion-list>
            <p *ngFor="let request of requestList" text-wrap>
              - {{ request.getAssetId() }}: {{ request.getAssetName() }}
            </p>
          </ion-list>
        </ion-card-content>
      </ion-card>
      <div>
        <ion-item *ngIf="typeAction == 4">
          <a class="required"></a>
          <ion-textarea
            label="Motivo de rechazo"
            label-placement="floating"
            class="floating"
            [(ngModel)]="rejectMotive"
            maxlength="118"
            rows="5"
            placeholder="..."
          ></ion-textarea>
        </ion-item>
      </div>
    </div>
    <div class="flex-container nowrap infContent atRight">
      <ion-button style="color: gray; --background: white" (click)="dismiss(1)">
        Atrás
      </ion-button>
      <ion-button
        *ngIf="typeAction == 4"
        color="danger"
        (click)="dismiss(0)"
        [disabled]="!rejectMotive.length"
      >
        Rechazar
      </ion-button>
      <ion-button *ngIf="typeAction == 0" (click)="dismiss(0)">
        Aprobar
      </ion-button>
      <ion-button *ngIf="typeAction == 1" (click)="dismiss(0)">
        Aceptar
      </ion-button>
    </div>
  </div>
</ion-content>
