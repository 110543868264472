// Native - Plugins
import { Component } from '@angular/core';

@Component({
  selector: 'app-my-profile-contacts',
  templateUrl: './my-profile-contacts.component.html',
  styleUrls: ['./my-profile-contacts.component.scss'],
})
export class MyProfileContactsComponent {
  constructor() {}
}
