<ion-content>
  <div style="text-align: center; margin-right: 10px; margin-left: 10px">
    <ion-item class="filter-btn" lines="none">
      <ion-input
        label="Nombre de la lista"
        label-placement="floating"
        class="floating"
        type="text"
        [(ngModel)]="listName"
        style="border-radius: 8px"
      >
      </ion-input>
    </ion-item>
    <ion-button
      shape="round"
      expand="full"
      (click)="createNewList()"
      [disabled]="listName.length == 0"
    >
      <i class="fas fa-plus" style="margin-right: 5px"></i> Crear
    </ion-button>
  </div>
</ion-content>
