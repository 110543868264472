import { Component } from '@angular/core';
import {
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { CaseCategoryType } from './../../businessCore/CaseCategoryType';
import { Direction } from './../../businessCore/Direction';
import { RegisterData } from './../../businessCore/RegisterData';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-my-profile-addcase',
  templateUrl: './my-profile-addcase.component.html',
  styleUrls: ['./my-profile-addcase.component.scss'],
})
export class MyProfileAddcaseComponent {
  direction: Direction = new Direction();

  caseCategoryType: CaseCategoryType = new CaseCategoryType();
  register_data: RegisterData = new RegisterData();

  constructor(
    public nav: NavController,
    public toastCtrl: ToastController,
    public apiProvider: webServiceProvider,
    public storage: StorageService,
    public popoverCtrl: PopoverController,
    private navService: NavigationService
  ) {
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
      }
    });

    let recId = 5637149088;
    this.apiProvider.GetCaseCategoryType(recId).subscribe((res) => {
      let data = res as Array<any>;
      if (data /*&& data.length > 0*/) {
        this.caseCategoryType = data.pop() as CaseCategoryType;
      }
    });
  }

  /**
   *
   * @param title
   * @param imgUrl
   * @param requestType
   */
  nextStep(title: string, imgUrl: string, requestType: number) {
    // 1 - Editar NUM TEL
    // 2 - Editar Email
    switch (requestType) {
      case 0:
        this.redirectTo(NavigationRoutes.MyProfileEditLatlngComponent, {
          title: title,
          imgUrl: imgUrl,
          caseCategoryType: this.caseCategoryType,
        });
        break;
      case 1:
        this.redirectTo(NavigationRoutes.MyProfileEditPhoneComponent, {
          title: title,
          imgUrl: imgUrl,
          caseCategoryType: this.caseCategoryType,
        });
        break;
      case 2:
        this.redirectTo(NavigationRoutes.MyProfileEditEmailComponent, {
          title: title,
          imgUrl: imgUrl,
          caseCategoryType: this.caseCategoryType,
        });
        break;
      case 3:
        this.createDirection();
        break;
      case 4:
        this.redirectTo(NavigationRoutes.MyProfileCreateRestrictionComponent, {
          title: title,
          imgUrl: imgUrl,
          caseCategoryType: this.caseCategoryType,
        });
        break;
      default:
        this.presentAlert();
        break;
    }
  }

  /**
   * Metodo utilizado para presentar el popup de creacion de una nueva direccion
   * donde se registra los datos solicitados en el formulario
   * @param direction objeto con los campos que deben ser llenados para llevar
   * a cabo el registro de una nueva direccion
   */
  createDirection() {
    let address = new Direction();
    address.edit = false;

    this.navService.navigateTo(NavigationRoutes.GetPositionPage, {
      data: address,
      callback: this.getData,
    });
  }

  /**
   *
   */
  getData = (data) => {
    return new Promise<void>((resolve, reject) => {
      this.direction = data as Direction;

      if (this.direction.directionName == '') {
        if (this.register_data.directions.length > 0) {
          this.direction.directionName =
            this.register_data.name +
            ' - ' +
            this.register_data.commercial_name +
            ' - #' +
            this.register_data.directions.length.toString();
        } else {
          this.direction.directionName =
            this.register_data.name +
            ' - ' +
            this.register_data.commercial_name;
        }
      }

      let notes =
        'GPS= ' +
        this.direction.latitude +
        ',' +
        this.direction.longitude +
        ' | Dir=' +
        this.direction.direction +
        ' | Dir Name=' +
        this.direction.directionName;

      let description = 'Agregar dirección HE';

      this.sendCase(notes, description);

      resolve();
    });
  };

  /**
   *
   * @param component
   * @param data
   */
  private redirectTo(component, data) {
    //this.nav.push(component, data);
    this.navService.navigateTo(component, data);
  }

  /**
   *
   * @param notes
   * @param description
   */
  sendCase(notes: string, description: string) {
    let caseId = new Date().getTime().toString();
    let categoryId = this.caseCategoryType.RecId;
    let customerId = this.register_data.customerId;
    let ownerWorker = this.caseCategoryType.OwnerWorker;

    this.apiProvider
      .InsertCaseDetail(
        caseId,
        description,
        categoryId,
        customerId,
        ownerWorker
      )
      .subscribe((res) => {
        let data = res as string;
        if (data && data.length > 0) {
          let response = (data as string).split(',');
          if (response.length == 2) {
            this.presentToast('Solicitud creada con éxito!');
            let caseRecId = +response[1]; // revisar si el campo llega en null

            this.apiProvider
              .InsertCaseLog(caseRecId, description, notes)
              .subscribe(() => {});

            // entityType = 3 => cliente
            // entityType = 5 => prospectos
            // entityType = 7 => OVs
            // entityType = 11 => productos
            let entityType = 3;

            this.apiProvider
              .InsertCaseAssociation(caseRecId, entityType, customerId)
              .subscribe(() => {});
          } else {
            this.presentToast('La solicitud no se pudo crear!');
          }
        } else {
          this.presentToast('La solicitud no se pudo crear!');
        }
      });
  }

  /**
   *
   */
  private async presentAlert() {
    let toast = await this.toastCtrl.create({
      message: 'Esta opción aún no se encuentra disponible!',
      buttons: ['Ok'],
      duration: 3000,
    });

    toast.present();
  }

  /**
   *
   * @param msg
   */
  async presentToast(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: ['Ok'],
    });
    toast.present();
  }
}
