// Native-Plugins
import { Component } from '@angular/core';
import { Money } from '../../GeneralUtilis/money';

// BusinessCore
import { NavParams } from '@ionic/angular';
import { OrderLine } from '../../businessCore/OrderLine';

@Component({
  selector: 'app-update-quantity',
  templateUrl: './update-quantity.component.html',
  styleUrls: ['./update-quantity.component.scss'],
})
export class UpdateQuantityComponent {
  public item: OrderLine;
  InPromo: boolean;

  /**
   * Class constructor with this injection:
   * @param params object that exists on a page and can contain
   * data for that particular view.
   */
  constructor(private params: NavParams) {
    try {
      this.item = this.params.get('OrderLine');
      this.InPromo = this.params.get('InPromo');
    } catch (e) {}

    // Valida la cantidad de existencias de un producto para que no exceda lo solicitado
    this.validateItem();
  }

  /**
   * Metodo utilizado para validar que la cantidad solicitada de un producto
   * no exceda las existencias reales. Si se cumple se procede con la actualizacion del item
   * seteando la cantidad, monto total y estado del item
   */
  validateItem() {
    this.item.lnAmnt = Number(this.item.lnAmnt.toString().replace(',', '.'));
    this.item.totalSale = Number(
      this.item.totalSale.toString().replace(',', '.')
    );
    this.item.taxAmount = Number(
      this.item.taxAmount.toString().replace(',', '.')
    );
    this.item.tax = Number(this.item.tax.toString().replace(',', '.'));

    if (this.item.salesQuantity > this.item.avail) {
      this.item.salesQuantity = this.item.avail;
      this.item.totalSale = this.item.lnAmnt + this.item.taxAmount;
      this.item.itemStatus = 0;
    }
  }

  /**
   * Metodo utilizado para reducir la cantidad solicitada de un producto
   * Restringe que la cantidad no sea negativa y de cumplirse reduce en una
   * unidad la cantidad a vender y tambien actualiza el precio total de venta
   */
  reduceQuantity() {
    /* 
    1- Condition
    3- Result
    5- Condition-Result
    99- Sin Promo
    */
    if (this.item.salesQuantity > 0 && this.item.promoSalesType == 99) {
      this.item.salesQuantity--;
      this.item.lnAmnt = Number(this.item.salesQuantity * this.item.salePrice);
      this.item.taxAmount = Number(this.item.tax * this.item.salesQuantity);
      this.item.totalSale = Number(this.item.lnAmnt + this.item.taxAmount);
    }
  }

  /**
   * Metodo utilizado para aumentar la cantidad solicitada de un producto
   * Restringe que la cantidad solicitada de un producto no exceda las existencias reales.
   * Si se cumple se procede con la actualizacion del item
   * seteando la cantidad y el monto total de la venta
   */
  increaseQuantity() {
    if (
      this.item.salesQuantity < this.item.avail &&
      this.item.promoSalesType != 3
    ) {
      this.item.salesQuantity++;
      this.item.lnAmnt = Number(this.item.salesQuantity * this.item.salePrice);
      this.item.taxAmount = Number(this.item.tax * this.item.salesQuantity);
      this.item.totalSale = Number(this.item.lnAmnt + this.item.taxAmount);
    }
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para setear el precio unitario de un producto + el impuesto correspondiente
   * @param item Línea de la orden a la que se le calcula el precio con impuesto por cada unidad
   */
  setSalePrice(item: OrderLine) {
    return this.setCurrency(item.salePrice + item.tax);
  }
}
