import { Component, Input } from '@angular/core';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  @Input() title: string;
  @Input() start: string = 'GoBack';
  @Input() end: string = 'None';

  public static shoppingCar: OrderLine[] = [];

  constructor(private ShoopingCartUtils: ShoopingCartUtils) {}

  shoopingCart() {
    return NavBarComponent.shoppingCar;
  }

  displayShoppingCar() {
    this.ShoopingCartUtils.displayShoppingCart();
  }

  // #region EVAL MENU OPTIONS

  // #region Left
  isMenu() {
    return this.start.toLowerCase() == 'menu';
  }

  isGoBack() {
    return this.start.toLowerCase() == 'goback';
  }
  // #endregion Left

  // #region Right
  isCart() {
    return this.end.toLowerCase() == 'cart';
  }
  // #region Right

  // #endregion EVAL MENU OPTIONS
}
