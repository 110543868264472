<app-nav-bar [title]="'Estado de la Orden'"></app-nav-bar>

<ion-content class="ion-padding">
  <div style="text-align: center" *ngIf="!executed">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando ordenes</h6>
  </div>
  <div style="text-align: center" *ngIf="executed && orders.length == 0">
    <h6 ion-text text-center text-wrap>
      Trazabilidad no disponible, realice una compra primero
    </h6>
  </div>
  <ion-list>
    <div *ngFor="let group of groups">
      <ion-item (click)="toggleGroup(group)">
        <h6 ion-text text-wrap>
          <ion-icon *ngIf="!group.showed" name="add-outline"></ion-icon>
          <ion-icon *ngIf="group.showed" name="remove-outline"></ion-icon>
          {{ group.name }}
        </h6>
      </ion-item>
      <div *ngIf="group.showed" style="padding: 2px">
        <ion-card *ngFor="let item of group.item">
          <ion-card-content>
            <ion-item-sliding (swipe)="swipeEvent($event, item)">
              <ion-item
                lines="none"
                [ngClass]="{ bgGray: item.salesStatus > 7 }"
                class="borderStyle"
              >
                <div style="width: 100%">
                  <div style="display: flex">
                    <h6 style="margin-top: 4px; text-align: center; width: 50%">
                      {{ item.salesAxId }}
                    </h6>
                    <h6 style="text-align: center; width: 50%">
                      {{ setCurrency(item.totalSales) }}
                    </h6>
                  </div>

                  <div class="container">
                    <ul class="progressbar">
                      <li
                        *ngFor="let state of item.states"
                        class="{{ state.class }}"
                        text-wrap
                      >
                        <i class="{{ state.icon }}"></i>
                      </li>
                    </ul>
                  </div>
                  <div style="display: flex">
                    <div style="display: block; width: 50%; text-align: center">
                      <i class="fas fa-cart-arrow-down"></i>
                      <h6>{{ item.createdDatetimeString }}</h6>
                    </div>
                    <div style="display: block; width: 50%; text-align: center">
                      <div
                        style="
                          display: flex;
                          flex-wrap: nowrap;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <i class="fas fa-truck"></i>
                        <i
                          *ngIf="item.salesStatus > 7"
                          style="margin-left: 2px"
                          class="fas fa-check primaryColor"
                        ></i>
                      </div>
                      <h6>{{ item.deliveryDateString }}</h6>
                    </div>
                  </div>
                </div>
              </ion-item>

              <ion-item-options side="start">
                <ion-item-option
                  style="
                    background-color: var(--ion-color-secondary);
                    padding: 2px;
                  "
                  (click)="CheckTaskProgress(item)"
                >
                  <i class="fas fa-tasks"></i>
                  <span style="padding-left: 5px">PROGRESO</span>
                </ion-item-option>
              </ion-item-options>

              <ion-item-options side="end">
                <ion-item-option
                  style="
                    background-color: var(--ion-color-primary);
                    padding: 2px;
                  "
                  (click)="ShowLines(item)"
                >
                  <i class="fas fa-list-ol"></i>
                  <span style="padding-left: 5px">PRODUCTOS</span>
                </ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </ion-list>
</ion-content>
