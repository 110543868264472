<ion-content class="ion-padding">
  <div class="blockContainer centerItems">
    <!-- Imagen y nombre del producto -->
    <img
      src="https://serviceplan-api.mercasacr.com/images/Products/{{ item.ItemId }}_l_.PNG"
      class="center"
      alt=""
      onerror=""
    />
    <p class="h7" style="display: flex; justify-content: center">
      {{ item.ItemName }}
    </p>

    <!-- Encabezado con la valoración -->
    <div class="flexContainer centerItems marginAll">
      <div style="padding: 5px 10px" *ngIf="product_rate.AVERAGE != 0">
        {{ product_rate.AVERAGE }}
      </div>
      <div class="flexContainer">
        <app-rating
          (ratingChange)="onModelChange($event)"
          [(rating)]="product_rate.AVERAGE"
          [readonly]="true"
        ></app-rating>
      </div>
    </div>

    <p
      class="h7"
      style="display: flex; justify-content: center"
      *ngIf="product_rate.AVERAGE == 0"
    >
      Producto sin valoraciones
    </p>

    <!-- Titulo de los comentarios -->
    <h6
      style="text-align: center; font-weight: bold"
      *ngIf="product_comments.length > 0"
    >
      Lista de Comentarios
    </h6>
    <h6
      style="text-align: center; font-weight: bold"
      *ngIf="product_comments.length == 0"
    >
      No existen comentarios para este producto
    </h6>

    <!-- Conjunto de comentarios con el contenido, likes y dislikes -->
    <div *ngFor="let comment of product_comments">
      <div
        class="flexContainer marginAll commentContainer"
        [ngClass]="{
          commentOwnerContainer: comment.COMMENTOWNER,
          notCommentOwnerContainer: !comment.COMMENTOWNER
        }"
      >
        <div class="blockContainer centerItems marginAll" style="width: 25%">
          <div style="text-align: center">
            <i class="far fa-user" style="font-size: 16px"></i>
          </div>
          <div
            style="margin-top: 5px; font-size: 12px; text-align: center"
            *ngIf="
              register_data == null ||
              comment.ACCOUNTNUM != register_data.customerId
            "
          >
            {{ comment.ACCOUNTNUM }}
          </div>
          <div
            style="margin-top: 5px; font-size: 12px; text-align: center"
            *ngIf="
              register_data != null &&
              comment.ACCOUNTNUM == register_data.customerId
            "
          >
            Yo
          </div>
        </div>
        <div class="vl"></div>
        <div class="blockContainer marginAll">
          <div style="text-align: justify; font-size: 12px">
            {{ comment.CONTENT }}
          </div>
          <div class="flexContainer marginAll" style="align-items: center">
            <div style="margin-right: 8px; font-size: 12px">
              {{ comment.LIKES }}
            </div>
            <div style="margin-right: 6px" (click)="setLike(comment)">
              <i
                class="far fa-thumbs-up"
                style="font-size: 16px"
                [ngClass]="{ likeColor: comment.USERLIKED }"
              ></i>
            </div>
            <div style="margin-left: 6px; margin-right: 8px; font-size: 12px">
              {{ comment.DISLIKES }}
            </div>
            <div (click)="setDislike(comment)">
              <i
                class="far fa-thumbs-down"
                style="font-size: 16px"
                [ngClass]="{ dislikeColor: comment.USERDISLIKED }"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
