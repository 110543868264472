import { Component } from '@angular/core';
import {
  NavController,
  NavParams,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { PurchLine } from '../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { PopInfoOcComponent } from '../../components/oc-components/pop-info-oc/pop-info-oc.component';
import { ServicesOcParameterProvider } from '../../provider/Services/services-oc-parameter';

@Component({
  selector: 'app-purch-order-confirm',
  templateUrl: './purch-order-confirm.component.html',
  styleUrls: ['./purch-order-confirm.component.scss'],
})
export class PurchOrderConfirmPage {
  purchInfo: PurchOrder;
  total: number;
  cantidadLineas: number;
  backToLine: boolean;
  cantEdit: boolean = true;
  impuestos: number;
  //@ViewChild(Navbar) navBar: Navbar;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private popoverCtrl: PopoverController,
    private ocParameter: ServicesOcParameterProvider,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.purchInfo = this.ocParameter.getPurchOrderParameter();
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
    this.impuestos = this.purchInfo.taxes;
    if (
      this.purchInfo.getDocumentStateI() !== 0 && // 'BORRADOR'
      this.purchInfo.getDocumentStateI() !== 20 && // 'RECHAZADO'
      this.purchInfo.getDocumentStateI() !== 10
    ) {
      // 'EN REVISIÓN'
      this.cantEdit = false;
    }
  }

  // Permite regresar al componente de encabezado.
  ionViewDidLoad() {
    this.platform.backButton.subscribe(() => {
      this.goToBack();
    });
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  /**
   * Permite regresar a la pantalla de lineas, si así lo quiso el usuario
   *  de lo contrario realiza un pop
   */
  goToBack() {
    // Mando la ordern actual como parametro
    this.ocParameter.setPurchOrderParameter(this.purchInfo);
    // Hago el pop de la pagina
    this.navCtrl.pop();
  }

  deleteItem(itemToDelete: PurchLine) {
    if (itemToDelete.getRecId() !== -1) {
      // es una linea que se quiere eliminar de AX
      this.purchInfo.toDeleteLine(itemToDelete); //solo se oculta a la vista del usuario
    } else {
      // es una linea agregada desde el componente de editar
      this.purchInfo.deleteLine(itemToDelete); // se debe de eliminar de la lista.
    }
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
    this.impuestos = this.purchInfo.taxes;
  }

  updateInfPurch(parameter: boolean) {
    this.purchInfo.calcularDatos();
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
    this.impuestos = this.purchInfo.taxes;
  }

  async openMenuOC(evento) {
    // Muestra la opcion de cambiar de estado en la pantalla
    const popover = await this.popoverCtrl.create({
      cssClass: ['custom-popover', 'pop-info-oc'],
      component: PopInfoOcComponent,
      componentProps: { infoOC: this.purchInfo },
      backdropDismiss: true,
      showBackdrop: true,
    });
    await popover.present(evento);
  }
}
