import { Component } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { RegisterData } from '../../businessCore/RegisterData';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  // atributo con la nueva contrasenha que esta registrando el cliente
  password: string = '';
  // confirmacion de la nueva contrasenha para evitar errores de registro
  passConfirm: string;

  register_data: RegisterData;
  card_types: any;
  id_card: string;
  c_type: number;
  email: string = '';
  verification_code: string = '';
  step1: boolean = true;
  step2: boolean = false;

  verifyEnableBtn: boolean = true;
  countDownSeconds: number = 60;
  showCountDown: boolean = false;
  showRenew: boolean = false;

  login_status: any;

  constructor(
    public viewCtrl: ModalController,
    public toastCtrl: ToastController,
    public apiProvider: webServiceProvider,
    private navService: NavigationService
  ) {
    this.register_data = new RegisterData();
    this.card_types = this.register_data.get_card_types();

    this.c_type = 0;
  }

  /**
   * Metodo utilizado para llevar acabo el reset de la contraseña (primer paso), donde se verifica el correo ingresado
   * contra la cedula ingresada por el usuario, de ser satisfcatoria la validacion se habilita el ingreso del codigo de verificacion
   * y contrasenha nueva.
   */
  resetPassword() {
    this.verifyEnableBtn = false;

    if (this.validateID(this.id_card) && this.validateEmail(this.email)) {
      this.apiProvider
        .ResetPassword(this.email, this.id_card)
        .subscribe((data) => {
          if (data) {
            switch (data) {
              case -1:
                this.presentPopover(
                  'El correo ingresado no coincide con el registrado.',
                  5000
                );
                this.verifyEnableBtn = true;
                this.step1 = true;
                this.step2 = false;
                break;

              case 1:
                this.presentPopover(
                  'El correo ha sido enviado con éxito. Introduzca el código de autorización enviado a su correo.',
                  5000
                );
                this.step1 = false;
                this.step2 = true;

                setTimeout(() => {
                  this.verifyEnableBtn = true;
                }, 60000);

                this.showCountDown = true;
                this.countDownSeconds = 60;
                this.countDownFunction();

                break;

              case -99:
                this.presentPopover(
                  'Error diríjase a la sección de registro para establecer una contraseña.',
                  5000
                );
                this.step1 = true;
                this.step2 = false;
                this.verifyEnableBtn = false;

                break;

              default: // 0
                this.presentPopover(
                  'No se pudo enviar el correo de autorización, Inténtelo más tarde.',
                  3000
                );
                this.verifyEnableBtn = true;
                this.step1 = true;
                this.step2 = false;
                break;
            }
          } else {
            this.presentPopover(
              'No se pudo verificar el correo de autorización, Inténtelo más tarde.',
              3000
            );
            this.verifyEnableBtn = true;
          }
        });
    } else {
      this.presentPopover(
        'Los datos suministrados no cumplen con las restricciones solicitadas',
        4000
      );

      this.verifyEnableBtn = true;
      this.step1 = true;
      this.step2 = false;
    }
  }

  renewPassword() {
    this.showRenew = false;
    this.step1 = true;
    this.step2 = false;
  }

  countDownFunction() {
    if (this.countDownSeconds == 0) {
      this.showCountDown = false;
      this.showRenew = true;
      this.countDownSeconds = 60;
    } else {
      this.countDownSeconds--;
      setTimeout(() => {
        return this.countDownFunction();
      }, 1000);
    }
  }

  /**
   *
   */
  setNewPassword() {
    if (
      this.password == this.passConfirm &&
      this.validatePassword(this.password) &&
      this.verification_code.length == 8
    ) {
      this.apiProvider
        .SetNewPassword(this.id_card, this.password, this.verification_code)
        .subscribe((data) => {
          switch (data) {
            case 0:
              this.presentPopover('El código ingresado es incorrecto!', 3000);
              break;
            case -1:
              this.presentPopover('El código ingresado es incorrecto!', 3000);
              break;
            case 1:
              // this.presentPopover('La contraseña ha sido cambiada con éxito!', 3000);
              this.viewCtrl.dismiss('SUCCESS');
              break;

            default:
              this.presentPopover(
                'No se pudo realizar el cambio de contraseña, Inténtelo más tarde.',
                4000
              );
              break;
          }
        });
    } else {
      this.presentPopover(
        'Los datos suministrados no cumplen con las restricciones solicitadas',
        4000
      );
    }
  }

  async presentPopover(message, duration) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      buttons: ['Ok'],
    });
    toast.present();
  }

  async cancelReset() {
    try {
      await this.viewCtrl.dismiss('CANCEL');
    } catch {
      this.navService.navigateTo(NavigationRoutes.ROOT_PAGE);
    }
  }

  /**
   *
   * @param ct
   */
  updateCardType(ct) {
    this.c_type = ct;
    this.id_card = '';
  }

  /**
   *
   */
  deleteIdCard() {
    this.id_card = '';
  }

  /**
   * Metodo utilizado para validar el formato con que se ingresa un correo electronico,
   * logrando el objetivo por medio de una expresion regular
   * @param email string con la ruta del correo que se desea validar
   */
  validateEmail(email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * Metodo utilizado para validar el formato con que se ingresa una identificacion,
   * logrando el objetivo por medio de una expresion regular
   * { "value": 0, "name": "Persona Física", "format": "#-####-####" },
   * { "value": 1, "name": "Extranjeros", "format": "############" },
   * { "value": 2, "name": "Jurídica", "format": "#-###-######" }
   * @param id string con la identificacion que se desea validar
   */
  validateID(id) {
    let re;
    switch (this.c_type) {
      case 1:
        // "format": "############"
        re = /[0-9]{12}$/;
        break;
      case 2:
        // "format": "#-###-######"
        re = /[1-9]+-[0-9]{3}-[0-9]{6}$/;
        break;
      default:
        // "format": "#-####-####"
        re = /[1-9]+-[0-9]{4}-[0-9]{4}$/;
        break;
    }
    return re.test(id);
  }

  /**
   * Metodo utilizado para validar el formato con que se ingresa una contraseña,
   * logrando el objetivo por medio de una expresion regular
   * @param password string con la contraseña que se desea validar
   */
  validatePassword(password) {
    let re = /[0-9a-zA-Z]{8,15}$/;
    return re.test(password);
  }

  /**
   * Metodo utilizado para validar el formulario de registro, para que el mismo cumpla con
   * todo lo necesario para llevar a cabo el registro del nuevo cliente y asi le permita
   * continuar con el ingreso de informacion adicional
   */
  validForm() {
    if (
      Object.keys(this.register_data.contacts).length &&
      this.validateEmail(this.register_data.email)
    )
      return true;
    return false;
  }
}
