<ion-content>
  <div
    style="font-size: 1rem; text-align: center; padding: 15px"
    *ngIf="showBtn"
  >
    <i class="fas fa-info" style="margin-right: 0.5rem; color: orange"></i>
    Aplique los Resultados dando click en el botón
    <a style="color: green">Aplicar Resultados</a>, ubicado en la sección
    inferior!
  </div>

  <ion-list *ngFor="let item of ordHerder.ordLine">
    <ion-card
      [ngClass]="{
        bsBlue: item.salePriceUpdated,
        bsYellow: item.itemStatus == 1,
        bsRed: item.itemStatus == -2
      }"
    >
      <ion-card-content>
        <ion-item class="borderStyle">
          <ion-grid>
            <ion-row>
              <ion-col size="3" class="imgCol">
                <img
                  src="https://serviceplan-api.mercasacr.com/images/Products/{{
                    item.itemId
                  }}_l_.PNG"
                  onerror="this.src='assets/img/error.png'"
                  alt=""
                />
              </ion-col>
              <ion-col size="9">
                <ion-item lines="none">
                  <h6 class="h7">
                    {{ item.itemName }}
                  </h6>
                  <ion-grid>
                    <ion-row>
                      <ion-col col-4>
                        <h6 class="h7">
                          {{ item.salesQuantity }}
                        </h6>
                        <h6 class="h7">
                          {{ item.unitId }}
                        </h6>
                      </ion-col>
                      <ion-col col-4>
                        <h6
                          color="primary"
                          class="h7"
                          style="font-weight: bold"
                        >
                          {{ setSalePrice(item) }}
                        </h6>
                        <h6 class="h7">
                          {{ item.unitId }}
                        </h6>
                      </ion-col>
                      <ion-col col-4>
                        <h6
                          ion-text
                          color="primary"
                          text-center
                          class="h7"
                          style="font-weight: bold"
                        >
                          {{ setCurrency(item.totalSale) }}
                        </h6>
                        <h6 class="h7">SUBTOTAL</h6>
                      </ion-col>
                    </ion-row>

                    <ion-row
                      class="primaryRow"
                      *ngIf="item.promoId != ''"
                      [ngStyle]="{
                        background:
                          pSequence == item.PromoSequence &&
                          promoId == item.promoId
                            ? 'rgba(0,128,0,0.3)'
                            : 'transparent'
                      }"
                    >
                      <ion-col>
                        <h6
                          color="primary"
                          class="h7"
                          style="font-weight: bold"
                        >
                          {{ item.promoId }}-{{ item.PromoSequence }}
                        </h6>
                        <h6 class="h7">PromoId</h6>
                      </ion-col>
                      <ion-col>
                        <h6
                          color="primary"
                          class="h7"
                          style="font-weight: bold"
                        >
                          {{ item.linePercent }}%
                        </h6>
                        <h6 class="h7">% Desc</h6>
                      </ion-col>
                      <ion-col col-4>
                        <h6
                          color="primary"
                          class="h7"
                          style="font-weight: bold"
                        >
                          {{ item.lnAmnt }}
                        </h6>
                        <h6 class="h7">TOTAL</h6>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-list>
  <ion-item lines="none">
    <div style="display: flex; width: 100%">
      <h5 style="width: 100%; font-size: 1rem; text-align: center">
        <a style="margin-right: 0.2rem">N° Líneas:</a
        >{{ ordHerder.ordLine.length }}
      </h5>
      <h5 style="width: 100%; font-size: 1rem; text-align: center">
        <a style="margin-right: 0.2rem">N° Artículos:</a>{{ totalUnits }}
      </h5>
      <h5 style="width: 100%; font-size: 1rem; text-align: center">
        <a style="margin-right: 0.2rem">Total:</a>{{ setCurrency(totalOrder) }}
      </h5>
    </div>
  </ion-item>
  <div class="flex-container" *ngIf="showBtn">
    <ion-button
      class="borderRadius"
      style="margin-right: 3px; background-color: green"
      (click)="dismiss(1)"
      expand="block"
    >
      Aplicar Resultados
    </ion-button>
  </div>
</ion-content>
