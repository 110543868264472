<ion-content>
  <ion-list *ngFor="let item of orderLines">
    <ion-card>
      <ion-card-content>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col size="3">
                <img
                  src="https://serviceplan-api.mercasacr.com/images/Products/{{
                    item.itemId
                  }}_s_.PNG"
                  alt=""
                  onerror=""
                />
              </ion-col>
              <ion-col size="9">
                <ion-item>
                  <div style="width: 100%">
                    <h6 style="width: 100%" class="h7">
                      {{ item.itemName }}
                    </h6>
                    <ion-grid>
                      <ion-row>
                        <ion-col size="4">
                          <h6
                            class="h7"
                            style="
                              font-weight: bold;
                              color: var(--ion-color-primary);
                            "
                          >
                            {{ setCurrency(item.salePrice) }}
                          </h6>
                          <h6 ion-text text-center class="h7">
                            {{ item.unitId }}
                          </h6>
                        </ion-col>
                        <ion-col size="4">
                          <h6 ion-text text-center class="h7">
                            {{ item.salesQuantity }} {{ item.unitId }}
                          </h6>
                          <h6
                            ion-text
                            *ngIf="item.linePercent > 0"
                            text-center
                            class="h7"
                            color="danger"
                          >
                            -{{ item.linePercent }}%
                          </h6>
                        </ion-col>
                        <ion-col size="4">
                          <h6
                            ion-text
                            color="primary"
                            text-center
                            class="h7"
                            style="
                              font-weight: bold;
                              color: var(--ion-color-primary);
                            "
                          >
                            {{ setCurrency(item.totalSale) }}
                          </h6>
                          <h6 ion-text text-center class="h7">TOTAL</h6>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-list>
</ion-content>
