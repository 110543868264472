// Native - Plugins
import { Component, Input } from '@angular/core';

// BusinessCore
import { InventoryItem } from './../../businessCore/InventoryItem';
import { PersonalProductRate } from './../../businessCore/PersonalProductRate';
import { ProductComment } from './../../businessCore/ProductComment';
import { RegisterData } from './../../businessCore/RegisterData';

// Provider
import {
  ModalController,
  NavController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-product-my-comments',
  templateUrl: './product-my-comments.component.html',
  styleUrls: ['./product-my-comments.component.scss'],
})
export class ProductMyCommentsComponent {
  // informacion del item sobre el que se esta consultando los comentarios y reacciones
  @Input() item: InventoryItem;

  public rate: number = 0;
  public ratingReadOnly: boolean = false;
  public newComment: string = '';
  public register_data: RegisterData;
  public personal_product_rate: PersonalProductRate = new PersonalProductRate();
  public product_comment: ProductComment = new ProductComment();

  /**
   * Class constructor with these injections:
   * @param navCtrl base class for navigation controller components like Nav and Tab.
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param _apiProvider web service provider.
   * @param storage to store key/value pairs and JSON objects.
   */
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public _apiProvider: webServiceProvider,
    public storage: StorageService,
    public toastCtrl: ToastController,
    public viewCtrl: ModalController
  ) {
    this.item = this.navParams.get('item');
    this.loadData();
  }

  /**
   * Metodo utilizado de manera automatica por el sistema
   * Se ejecuta cuando la vista detecta que esta siendo accedida desde cualquier punto
   * de la aplicacion, el proposito principal es llevar a cabo una actualizacion de los comentarios
   * y valoraciones que tiene un producto.
   */
  loadData() {
    let itemId = this.item.ItemId;

    if (this.register_data != null) {
      // Cargar evaluacion personal sobre el producto (Si existe)
      this.getPersonalProductEvaluation(this.register_data.customerId, itemId);
      this.getPersonalProductComment(this.register_data.customerId, itemId);
    } else {
      this.storage.get('register_data').then((data) => {
        if (data != null) {
          this.register_data = JSON.parse(data) as RegisterData;

          // Cargar evaluacion personal sobre el producto (Si existe)
          if (this.register_data != null) {
            this.getPersonalProductEvaluation(
              this.register_data.customerId,
              itemId
            );
            this.getPersonalProductComment(
              this.register_data.customerId,
              itemId
            );
          }
        } else {
          // No existen datos de un usuario, entonces se le restringe la posibilidad de poner un rate sobre el producto
          this.ratingReadOnly = true;
        }
      });
    }
  }

  /**
   * Metodo utilizado para consultar el comentario que tiene registrado el usuario para un
   * determinado producto
   * @param accountNum string con el identificador del usuario
   * @param itemId string con el identificador del producto
   */
  getPersonalProductComment(accountNum, itemId) {
    this._apiProvider.getCommentsInfo(itemId, accountNum).subscribe((data) => {
      if (data != null) {
        let comments = data as ProductComment[];
        comments.forEach((element) => {
          if (element.COMMENTOWNER) {
            this.product_comment = element;
          }
        });
      }
    });
  }

  /**
   * Metodo utilizado para consultar la valoracion que tiene registrada el usuario para un
   * determinado producto
   * @param accountNum string con el identificador del usuario
   * @param itemId string con el identificador del producto
   */
  getPersonalProductEvaluation(accountNum, itemId) {
    this._apiProvider
      .getPersonalProductRate(accountNum, itemId)
      .subscribe((data) => {
        if (data != null) {
          let productRate = data as PersonalProductRate;
          if (productRate.ITEMID == this.item.ItemId) {
            this.personal_product_rate = data as PersonalProductRate;
            this.rate = this.personal_product_rate.RATE;
            if (this.personal_product_rate.RATE != 0) {
              this.ratingReadOnly = true;
            }
          } else {
            this.ratingReadOnly = true;
          }
        }
      });
  }

  /**
   * Metodo utilizado para capturar cuando un usuario desea setar una valoracion
   * por medio del componente de estrellas (1, 2, ..., 5), donde al presionar una de
   * estas se activa el evento de cambio y se invoca este metodo
   * @param event datos del evento llevado a cabo por el usuario
   */
  onModelChange(event) {
    if (this.register_data != null) {
      let accountNum = this.register_data.customerId;
      let itemId = this.item.ItemId;

      this._apiProvider
        .setProductEvaluation(this.rate, accountNum, itemId)
        .subscribe((data) => {
          if (data != null) {
            if (data == 'SUCCESS') {
              this.ratingReadOnly = true;
            } else {
              if (data == 'NO_SALE') {
                this.ratingReadOnly = true;
                this.rate = 0;
                this.presentToast(
                  'Debe comprar el producto antes de calificarlo!',
                  3000,
                  'bottom'
                );
              }
            }
          }
        });
    }
  }

  /**
   * Metodo utilizado para crear un toast con un mensaje determinado y desplegarlo en un tiempo y
   * posicion especifica
   * @param msg Texto con el mensaje que se va desplegar en el toast
   * @param time tiempo en milisegundos que durara el toast en pantalla
   * @param pos posicion en que se ubicara el componente, top, bottom, medium
   */
  async presentToast(msg, time, pos) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: time,
      position: pos,
    });

    toast.present();
  }

  /**
   * Metodo utilizado para crear un nuevo comentario, el cual esta asociado a un cliente
   * y a un producto en concreto
   * @param accountNum identificador del usuario que esta creando el comentario
   * @param itemId identificador del producto al que se le asocia el comentario
   */
  createComment() {
    if (this.register_data != null) {
      let accountNum = this.register_data.customerId;
      let itemId = this.item.ItemId;

      this._apiProvider
        .setProductComment(this.newComment, accountNum, itemId)
        .subscribe((data) => {
          if (data != null) {
            if (data == 'SUCCESS') {
              this.getPersonalProductComment(accountNum, itemId);
            } else {
              if (data == 'NO_SALE') {
                this.presentToast(
                  'Debe comprar el prodcuto antes de comentar!',
                  3000,
                  'bottom'
                );
                this.newComment = '';
              }
            }
          }
        });
    }
  }
}
