import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import {
  AlertController,
  LoadingController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { CertFilterComponent } from 'src/app/components/cert-filter/cert-filter.component';
import { CertListSelectedComponent } from 'src/app/components/cert-list-selected/cert-list-selected.component';
import {
  CertFilterData,
  Certification,
} from '../../businessCore/Certification';
import { RegisterData } from '../../businessCore/RegisterData';
import { Worker } from '../../businessCore/Worker';
import { CertFilterPipe } from '../../pipes/cert-filter/cert-filter';
import { FilterOneKeyListPipe } from '../../pipes/filter-one-key-list/filter-one-key-list';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';

@Component({
  selector: 'app-asset-certification-approval',
  templateUrl: './asset-certification-approval.component.html',
  styleUrls: ['./asset-certification-approval.component.scss'],
})
export class AssetCertificationApprovalPage implements OnInit {
  auth: number;
  componentId: any;

  certification: Certification;

  certListOriginal: Certification[]; //Lista de todos las certificaciones pendientes por jefe esta lista no se altera.

  certListToFilter: Certification[];
  certListToSearch: Certification[];
  certListSelected: Certification[];

  departments: any[];
  periods: any[];
  dates: any;

  worker: Worker;
  workerVatnum: string;

  filterData: CertFilterData; //Una estructura que contiene companies y categories, así se pasan los valores al popover.

  sortByCertPeriodAsc: boolean;
  sortByCertPeriodDesc: boolean;
  sortByWorkerNameAsc: boolean;
  sortByWorkerNameDesc: boolean;
  sortByDepartNameAsc: boolean;
  sortByDepartNameDesc: boolean;
  sortByCertDateAsc: boolean;
  sortByCertDateDesc: boolean;

  months: Map<number, string>;

  flagOneUse: number; //

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private _apiProvider: webServiceProvider,
    private OneKeyListPipe: FilterOneKeyListPipe,
    private certFilterPipe: CertFilterPipe,
    public loadingCtrl: LoadingController,
    private authService: AuthJWTService,
    public authGuard: AuthenticationAuthGuardProvider,
    public storage: StorageService,
    private inAppBrowser: InAppBrowser,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   *
   */
  async ngOnInit() {
    this.certListSelected = [];
    //Muestra pantalla de cargado.
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    await loading.present();
    this.months = new Map([
      [0, 'Enero'],
      [1, 'Febrero'],
      [2, 'Marzo'],
      [3, 'Abril'],
      [4, 'Mayo'],
      [5, 'Junio'],
      [6, 'Julio'],
      [7, 'Agosto'],
      [8, 'Septiembre'],
      [9, 'Octubre'],
      [10, 'Noviembre'],
      [11, 'Diciembre'],
    ]);
    this.auth = Number(this.navParams.get('auth'));
    this.componentId = this.navParams.get('code');
    if (this.auth === 0 && this.authService.hasCookie()) {
      this.navService.setRoot(
        this.activatedRoute.component.name as NavigationRoutes,
        {
          auth: 1,
          code: this.componentId,
        }
      );
    } else {
      if (this.auth === 1) {
        let checkedToken = await this.authGuard.checkToken();
        if (
          checkedToken &&
          this.authGuard.componentAllow(
            this.authService.getComponentsInfo(),
            this.componentId
          )
        ) {
          this.workerVatnum =
            this.authService.getTokenData('refresh')['vat-num'];
        } else {
          window.location.href = 'http://giiis01:8025/';
        }
      } else {
        await this.storage.get('register_data').then((data) => {
          const register_data = JSON.parse(data) as RegisterData;
          this.workerVatnum = register_data.id_card;
          // DEBUG
          //this.workerVatnum = '1-1236-0499';
          // DEBUG
        });
      }
      const workerList = await this.getWorkerList('', this.workerVatnum);
      try {
        this.worker = workerList[0];
        await this.initialize(this.worker.getRecId()); //"5637274339"
      } catch (error) {
        this.createAlert(
          'Error de conexión',
          'Se ha presentado un error, favor intentar más tarde'
        );
      }
    }
    await loading.dismiss();
  }

  /**
   *
   */
  async initialize(workerRecId) {
    try {
      this.sortByCertPeriodAsc = true;
      this.certListOriginal = await this.getPendingCertification(
        '01',
        workerRecId
      );
      //console.log(this.certListOriginal);
      this.certListOriginal = this.sortCertificationList(
        'sortByCertDateAsc',
        this.certListOriginal
      );
      this.certListToFilter = this.certListOriginal;
      this.certListToSearch = this.certListToFilter;
      this.departments = this.certListOriginal
        .filter(
          (assetData, i, arr) =>
            arr.findIndex(
              (t) =>
                t.getWorkerDepartmentName() ===
                assetData.getWorkerDepartmentName()
            ) === i
        )
        .map((item) => {
          return {
            workerDepartment: item.getWorkerDepartmentName(),
            selected: false,
          };
        });
      this.periods = this.certListOriginal
        .filter(
          (assetData, i, arr) =>
            arr.findIndex(
              (t) =>
                t.getCertificatedPeriod() === assetData.getCertificatedPeriod()
            ) === i
        )
        .map((item) => {
          return {
            certificatedPeriod: item.getCertificatedPeriod(),
            selected: false,
          };
        });
      this.dates = {
        startDate: this.certListToSearch[0].getPetitionDate(),
        finalDate:
          this.certListToSearch[
            this.certListToSearch.length - 1
          ].getPetitionDate(),
        definitiveStartDate: this.certListToSearch[0].getPetitionDate(),
        definitiveFinalDate:
          this.certListToSearch[
            this.certListToSearch.length - 1
          ].getPetitionDate(),
      };
      // this.periods = this.certListOriginal.map(cert => cert.getCertificatedPeriod());
      this.filterData = {
        departments: this.departments,
        periods: this.periods,
        dates: this.dates,
      };
    } catch (error) {
      this.createAlert('No hay certificaciones pendientes de aprobación', '');
    }
  }

  /**
   *
   * @param personnelNumber
   * @param vatnum
   * @returns
   */
  async getWorkerList(personnelNumber: string = '', vatnum: string = '') {
    const resp = await this._apiProvider.getWorker(personnelNumber, vatnum);
    const list: Worker[] = resp as Worker[];
    return list;
  }

  /**
   *
   * @param pCertificationId
   * @param pApprovRecId
   * @returns
   */
  async getPendingCertification(
    pCertificationId: string,
    pApprovRecId: string
  ) {
    const resp = (await this._apiProvider.getPendingCertification(
      pCertificationId,
      pApprovRecId
    )) as Certification[];
    return resp;
  }

  /**
   *
   * @param pCertRecordList
   * @param pStatus
   * @returns
   */
  async alterCertRecord(
    worker: Worker,
    pCertRecordList: Certification[],
    pStatus: number
  ) {
    const resp = (await this._apiProvider.alterCertRecord(
      worker,
      pCertRecordList,
      pStatus
    )) as number;
    return resp;
  }

  /**
   *
   * Muestra el componente popover para elegir los filtros necesarios.
   *
   */
  async presentCertFilterPopover() {
    //DEBUG
    //this.filterData = { departments: [], periods: [], dates: '' };
    //DEBUG
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: CertFilterComponent,
      componentProps: { certFilterData: this.filterData },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        this.filterData = data;
        this.filterCert();
      }
    });
  }

  /**
   * Muestra un alert con valores para ordenar las listas.
   */
  async presentCertSortAlert() {
    let alert = await this.alertCtrl.create({
      header: '¿Cómo ordenar los activos?',
      inputs: [
        {
          type: 'radio',
          label: 'Fecha de certificación (Menor a Mayor)',
          value: 'sortByCertDateAsc',
          checked: this.sortByCertDateAsc,
        },
        {
          type: 'radio',
          label: 'Fecha de certificación (Mayor a Menor)',
          value: 'sortByCertDateDesc',
          checked: this.sortByCertDateDesc,
        },
        {
          type: 'radio',
          label: 'Período certificado (Menor a Mayor)',
          value: 'sortByCertPeriodAsc',
          checked: this.sortByCertPeriodAsc,
        },
        {
          type: 'radio',
          label: 'Perído certificado (Mayor a Menor)',
          value: 'sortByCertPeriodDesc',
          checked: this.sortByCertPeriodDesc,
        },
        {
          type: 'radio',
          label: 'Empleado (A->Z)',
          value: 'sortByWorkerNameAsc',
          checked: this.sortByWorkerNameAsc,
        },
        {
          type: 'radio',
          label: 'Empleado (Z->A)',
          value: 'sortByWorkerNameDesc',
          checked: this.sortByWorkerNameDesc,
        },
        {
          type: 'radio',
          label: 'Departamento (A->Z)',
          value: 'sortByDepartNameAsc',
          checked: this.sortByDepartNameAsc,
        },
        {
          type: 'radio',
          label: 'Departamento (Z->A)',
          value: 'sortByDepartNameDesc',
          checked: this.sortByDepartNameDesc,
        },
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            this.sortCertificationList(data, this.certListToSearch);
          },
        },
      ],
    });
    alert.present();
  }

  async refreshPage() {
    this.navService.navigateTo(
      NavigationRoutes.AssetCertificationApprovalPage,
      {
        auth: this.auth,
        code: this.componentId,
      },
      { replaceUrl: true }
    );
  }

  /**
   *
   * Modifica la lista sobre la que se busca según los valores ingresado en la barra de búsqueda.
   *
   */
  filterCert() {
    this.certListToFilter = this.certListOriginal;
    const newDepartments = this.filterCertList('Departamento');
    const newPeriods = this.filterCertList('Periodo');
    this.certListToFilter = this.OneKeyListPipe.transform(
      this.certListToFilter,
      newDepartments,
      3
    );
    this.certListToFilter = this.OneKeyListPipe.transform(
      this.certListToFilter,
      newPeriods,
      3
    );
    //console.log(this.filterData.dates);
    this.certListToFilter = this.certListToFilter.filter(
      (cert: Certification) => {
        return (
          cert.getPetitionDate() >= this.filterData.dates.startDate &&
          cert.getPetitionDate() <= this.filterData.dates.finalDate
        );
      }
    );

    this.certListToSearch = this.certListToFilter;
  }

  /**
   *
   * Modifica la lista sobre la que se busca según los valores ingresado en la barra de búsqueda.
   *
   */
  searchCert(event) {
    this.certListToSearch = this.certFilterPipe.transform(
      this.certListToFilter,
      event.detail.value
    );
  }

  /**
   *
   * @param type
   * @returns
   */
  filterCertList(type: string) {
    let result: string[] = [];
    switch (type) {
      case 'Departamento':
        for (let i of this.filterData.departments.filter(
          (element) => element.selected === true
        )) {
          result.push(i.workerDepartment);
        }
        break;
      case 'Periodo':
        for (let i of this.filterData.periods.filter(
          (element) => element.selected === true
        )) {
          result.push(i.certificatedPeriod);
        }
        break;
      default:
        break;
    }
    return result;
  }

  /**
   *
   * @param data
   * @param list
   * @returns
   */
  sortCertificationList(data: string, list: Certification[]) {
    this.sortByCertPeriodAsc = false;
    this.sortByCertPeriodDesc = false;
    this.sortByWorkerNameAsc = false;
    this.sortByWorkerNameDesc = false;
    this.sortByDepartNameAsc = false;
    this.sortByDepartNameDesc = false;
    this.sortByCertDateAsc = false;
    this.sortByCertDateDesc = false;
    switch (data) {
      case 'sortByCertPeriodAsc':
        list.sort((a, b) =>
          a.getPeriodStart() <= b.getPeriodStart() ? -1 : 1
        );
        this.sortByCertPeriodAsc = true;
        break;
      case 'sortByCertPeriodDesc':
        list.sort((a, b) => (a.getPeriodStart() > b.getPeriodStart() ? -1 : 1));
        this.sortByCertPeriodDesc = true;
        break;
      case 'sortByWorkerNameAsc':
        list.sort((a, b) => (a.getWorkerName() <= b.getWorkerName() ? -1 : 1));
        this.sortByWorkerNameAsc = true;
        break;
      case 'sortByWorkerNameDesc':
        list.sort((a, b) => (a.getWorkerName() > b.getWorkerName() ? -1 : 1));
        this.sortByWorkerNameDesc = true;
        break;
      case 'sortByDepartNameAsc':
        list.sort((a, b) =>
          a.getWorkerDepartmentName() <= b.getWorkerDepartmentName() ? -1 : 1
        );
        this.sortByDepartNameAsc = true;
        break;
      case 'sortByDepartNameDesc':
        list.sort((a, b) =>
          a.getWorkerDepartmentName() > b.getWorkerDepartmentName() ? -1 : 1
        );
        this.sortByDepartNameDesc = true;
        break;
      case 'sortByCertDateAsc':
        list.sort((a, b) =>
          a.getPetitionDate() <= b.getPetitionDate() ? -1 : 1
        );
        this.sortByCertDateAsc = true;
        break;
      case 'sortByCertDateDesc':
        list.sort((a, b) =>
          a.getPetitionDate() > b.getPetitionDate() ? -1 : 1
        );
        this.sortByCertDateDesc = true;
        break;
      default:
        break;
    }
    return list;
  }

  /**
   *
   * Obtiene la lista de los elementos seleccionados que se muestran en pantalla.
   *
   */
  selectAll(event) {
    for (let index = 0; index < this.certListToSearch.length; index++) {
      if (event.checked) {
        this.certListToSearch[index].selected = true;
      } else {
        this.certListToSearch[index].selected = false;
      }
    }
  }

  /**
   *
   * Obtiene la lista de los elementos seleccionados que se muestran en pantalla.
   *
   */
  getCheckedvalue() {
    this.certListSelected = this.certListToSearch.filter((value) => {
      return value.selected;
    });
  }

  ////PU_0000192 -> PU_0000347
  async showCertListPopOver(type: number) {
    let resp: number;
    let rejAppr: string;
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: CertListSelectedComponent,
      componentProps: {
        certList: this.certListSelected,
        typeAction: type,
      },
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data) {
        //console.log(data);
        if (type == 1) {
          //Aprobar
          rejAppr = 'APROBÓ';
          resp = await this.alterCertRecord(
            this.worker,
            this.certListSelected,
            1
          );
        } else {
          //Rechazar
          rejAppr = 'RECHAZÓ';
          resp = await this.alterCertRecord(
            this.worker,
            this.certListSelected,
            2
          );
        }
        if (resp == 1) {
          this.sendEmail(
            this.createApprEmailBody(rejAppr),
            this.worker.getEmail(),
            'Aprobación de Certificación de Activos fijos'
          );
          for (let index = 0; index < this.certListSelected.length; index++) {
            let certSelected = this.certListSelected[index];
            this.sendEmail(
              this.createCertEmailBody(rejAppr),
              certSelected.getWorkerEmail(),
              'Aprobación de Certificación de Activos fijos'
            );
          }
        } else {
          this.createAlert('Error', 'Ha sucedido un error. Intente más tarde.');
        }
        this.refreshPage();
      }
    });
    // popover.onDidDismiss(data => {
    //   if (data != null) {
    //     this.rejectMotive = data.motive;
    //     this.approveRequest(this.approver, this.requestListChecked, data.motive, data.typeAction);
    //   }
    // });
  }

  /**
   *
   * @param asset
   */
  async showAlert(msg: string, type: number) {
    let alert = await this.alertCtrl.create({
      header: msg,
      buttons: [
        {
          text: 'No',
          handler: () => {
            alert.dismiss(false);
            return false;
          },
        },
        {
          text: 'Sí',
          handler: () => {
            alert.dismiss(true);
            return false;
          },
        },
      ],
    });
    alert.present();
    alert.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data) {
        if (type == 1) {
          //Aprobar
          this.alterCertRecord(this.worker, this.certListSelected, 1);
        } else {
          //Rechazar
          this.alterCertRecord(this.worker, this.certListSelected, 2);
        }
        this.refreshPage();
      }
    });
  }

  /**
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }

  /**
   *
   * @param docLink
   */
  openLink(docLink: string) {
    const options: InAppBrowserOptions = {
      zoom: 'no',
    };
    const browser = this.inAppBrowser.create(docLink, '_system', options);
  }

  /**
   *
   */
  async sendEmail(msg_body: string, to: string, subject: string) {
    //console.log(to);
    if (to) {
      const resp = await this._apiProvider.sendEmail(msg_body, to, subject);
    //console.log(resp);
    } else {
      this.createAlert(
        'Correo eletrónico no enviado',
        'No se tiene una dirección de correo electrónico de la empresa configurada a su usuario.'
      );
    }
  }

  /**
   *
   * @returns
   */
  private createApprEmailBody(pRejAppr: string): string {
    let date = new Date();
    let msgBody: string;
    let count: number = 0;
    msgBody = 'Aprobación de Certificación de Activos fijos&#60;br&#62;';
    msgBody +=
      this.worker.getName() +
      ', ' +
      pRejAppr +
      ' la certificación de control y cuido de activos, el día ' +
      date.getDate() +
      ' de ' +
      this.months.get(date.getMonth()) +
      ' a las ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ' , de las siguientes personas: &#60;br&#62;';
    for (let i of this.certListSelected) {
      count += 1;
      msgBody += '&#60;br&#62;' + count.toString() + ') ' + i.getWorkerName();
      msgBody += ', departamento: ' + i.getWorkerDepartmentName();
      msgBody += ', periodo: ' + i.getCertificatedPeriod();
      msgBody += ', monto de premio ' + i.getRewardAmount();
    }
    //console.log(msgBody);
    return msgBody;
  }

  /**
   *
   * @returns
   */
  private createCertEmailBody(pRejAppr: string): string {
    let date = new Date();
    let msgBody: string;
    let count: number = 0;
    msgBody = 'Aprobación de Certificación de Activos fijos&#60;br&#62;';
    msgBody +=
      this.worker.getName() +
      ', ' +
      pRejAppr +
      ' la certificación de control y cuido de activos, el día ' +
      date.getDate() +
      ' de ' +
      this.months.get(date.getMonth()) +
      ' a las ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ', de: &#60;br&#62;';
    for (let i of this.certListSelected) {
      count += 1;
      msgBody += '&#60;br&#62;' + count.toString() + ') ' + i.getWorkerName();
      msgBody += ', departamento: ' + i.getWorkerDepartmentName();
      msgBody += ', periodo: ' + i.getCertificatedPeriod();
      msgBody += ', monto de premio ' + i.getRewardAmount();
    }
    return msgBody;
  }
}
