<app-nav-bar [title]="'Aprobador certificación AF'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button (click)="refreshPage()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box">
    <ion-searchbar
      type="text"
      placeholder="Buscar"
      (ionChange)="searchCert($event)"
    ></ion-searchbar>

    <div class="flex-container nowrap" style="margin: 5px; display: flex">
      <ion-button
        class="filter-btn"
        (click)="presentCertFilterPopover()"
        style="width: 100%"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        (click)="presentCertSortAlert()"
        style="width: 100%"
      >
        Ordenar
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>

    <ion-item>
      <ion-checkbox (ionChange)="selectAll($event)"></ion-checkbox>
      <ion-label>
        <h2>Seleccionar todo</h2>
      </ion-label>
    </ion-item>

    <div class="content">
      <ion-list>
        <ion-item
          *ngFor="let cert of certListToSearch; let i = index"
          color="white"
          text-wrap
        >
          <ion-checkbox
            (ionChange)="getCheckedvalue()"
            [(ngModel)]="cert.selected"
            style="z-index: 9"
          ></ion-checkbox>
          <ion-label>
            <ion-grid>
              <ion-row>
                <ion-col col-10>
                  <p style="display: inline; font-size: 13.5px">
                    Período: {{ cert.getCertificatedPeriod() }}
                  </p>
                  <p style="font-weight: bold">{{ cert.getWorkerName() }}</p>
                  <p style="color: #6f6f6f">
                    Departamento: {{ cert.getWorkerDepartmentName() }}
                  </p>
                  <p style="color: #6f6f6f">
                    Cant. activos: {{ cert.getAssetQty() }}
                  </p>
                  <p style="color: #6f6f6f">
                    Premio: {{ cert.getRewardAmount() }}
                    {{ cert.getCurrency() }}
                  </p>
                  <p style="color: #6f6f6f">
                    Fecha de certificación:
                    {{
                      cert
                        .getPetitionDate()
                        .toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-")
                    }}
                  </p>
                </ion-col>
                <ion-col
                  col-2
                  style="text-align: center; font-size: 31px; z-index: 10"
                >
                  <div
                    (click)="openLink(cert.getDocumentLink())"
                    style="margin-top: 48px"
                  >
                    <a>
                      <ion-icon name="ios-document-outline"></ion-icon>
                    </a>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div>
      <div
        style="
          display: flex;
          margin-top: 2%;
          margin-bottom: 2%;
          margin-right: 0;
          margin-left: 0%;
        "
        class="flex-container nowrap infContent"
      >
        <ion-button
          (click)="showCertListPopOver(2)"
          class="btnPositionLeft"
          style="width: 45%"
          color="danger"
          [disabled]="!certListSelected.length"
        >
          Rechazar
        </ion-button>
        <ion-button
          (click)="showCertListPopOver(1)"
          class="btnPositionRight"
          style="width: 45%"
          [disabled]="!certListSelected.length"
        >
          Aprobar
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
