import { Component, Input } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {
  AlertController,
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { InfoImage } from 'src/app/businessCore/check-models/InfoImage';
import { TaskCheck } from 'src/app/businessCore/check-models/TaskCheck';
import { CheckListAnswerOmiComponent } from '../check-list-answer-omi/check-list-answer-omi.component';
import { NoteTaskComponent } from '../note-task/note-task.component';

@Component({
  selector: 'app-check-list-task',
  templateUrl: './check-list-task.component.html',
  styleUrls: ['./check-list-task.component.scss'],
})
export class CheckListTaskComponent {
  @Input() Task: TaskCheck;
  @Input() position: number;
  presentingMess: boolean = false;
  constructor(
    public modalCtrl: ModalController,
    public alertController: AlertController,
    public popoverCtrl: PopoverController,
    private camera: Camera,
    private toastCtrl: ToastController
  ) {}

  ngOnInit() {
    this.position += 1;
    this.Task.setOrderTask(this.position);
  }

  swipeEvent(event) {
    if (event.direction === 2) {
      // Omitir tarea
      this.goToOmit();
    } else if (event.direction === 4) {
      this.getObservationLeft();
    }
  }

  goToOmit() {
    this.messageInfo('Atención', '¿Desea omitir la tarea seleccionada?', 1);
  }

  executeAction(type: number) {
    switch (type) {
      case 1:
        this.showOmissionInfo();
        break;
      case 2:
        this.showAnswerInfo();
        break;
      default:
        break;
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action > 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.executeAction(action);
          },
        },
        { text: 'NO', handler: () => {} },
      ];
    } // else if (action && action === -1) {
    // botones = [{ text: 'ACEPTAR', handler: () => {this.navCtrl.pop()}}];
    // }
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async showOmissionInfo() {
    if (
      this.Task.getOmissionTaskList() &&
      this.Task.getOmissionTaskList().length > 0
    ) {
      if (!this.presentingMess) {
        this.presentingMess = true;
        const modal = await this.modalCtrl.create({
          component: CheckListAnswerOmiComponent,
          componentProps: {
            listInfo: this.Task.getOmissionTaskList(),
            type: 1,
            titulo: 'Omisiones',
          },
          cssClass: 'check-list-answer-omi',
          backdropDismiss: false,
        });
        await modal.present();
        // Se espera las opciones a actualizar, seleccionada por el usuario
        modal.onDidDismiss().then((params) => {
          let data = params.data;
          if (data.successful) {
            // Fue ejecutada bien
            this.Task.state = 2; // la tarea se omitio
            this.Task.selected = false;
            this.changeSelected();
          }
          this.presentingMess = false;
        });
      }
    } else {
      // La tarea no tiene omisiones asignadas
      this.Task.state = 2; // Es omitida y pide la razon
      this.Task.selected = false; //Por si estaba seleccionada (En caso de no tener respuestas)
      this.changeSelected();
      this.messageInfoObservation();
    }
  }

  async showAnswerInfo() {
    if (
      this.Task.getAnswerTaskList() &&
      this.Task.getAnswerTaskList().length > 0
    ) {
      if (!this.presentingMess) {
        this.presentingMess = true;
        const modal = await this.modalCtrl.create({
          component: CheckListAnswerOmiComponent,
          componentProps: {
            listInfo: this.Task.getAnswerTaskList(),
            type: 0,
            titulo: 'Respuestas',
          },
          cssClass: 'check-list-answer-omi',
          backdropDismiss: false,
        });

        await modal.present();
        // Se espera las opciones a actualizar, seleccionada por el usuario
        modal.onDidDismiss().then((params) => {
          let data = params.data;
          if (data.successful) {
            // Fue ejecutada bien
            this.Task.state = 1;
          }
          this.presentingMess = false;
        });
      }
    } else {
      // La tarea no tiene respuestas asignadas
      //console.log('No tiene respuestas');
    }
  }

  // Si no tiene tareas
  changeSelected() {
    // Si se selecciona la tarea pasa a ser ejecutada 1. si se desmarca pasa al estado 0
    if (this.Task.selected) {
      this.Task.state = 1;
      this.Task.setObservation('');
    } else {
      if (this.Task.state !== 2) {
        this.Task.state = 0; // El estado ahora es 0
        this.Task.setObservation(''); //La observacion anterior es borrada.
      }
    }
  }

  observationLeft() {
    return this.Task.state === 1 && this.Task.getAnswerTaskList().length === 0;
  }

  getObservationLeft() {
    if (this.observationLeft()) {
      this.messageInfoObservation();
    }
  }

  //Llama la función de pedir observacion
  async messageInfoObservation() {
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.popoverCtrl.create({
        component: NoteTaskComponent,
        componentProps: { noteInfo: this.Task.getObservation() },
        cssClass: ['custom-popover', 'note-task'],
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((params) => {
        let data = params.data;
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          this.Task.setObservation(data as string);
        }
      });
    }
  }

  getStyle() {
    let result = 'noExecuted1';
    if (this.Task !== null && this.Task !== undefined) {
      switch (this.Task.state) {
        case 0:
          if (
            (this.Task.getAnswerTaskList().length === 0 &&
              this.Task.getAttached()) ||
            this.Task.getAttached()
          ) {
            result = 'noExecuted2';
          } else {
            result = 'noExecuted1';
          }
          break;
        case 1:
          result = 'executed2';
          break;
        case 2:
          if (
            (this.Task.getAnswerTaskList().length === 0 &&
              this.Task.getAttached()) ||
            this.Task.getAttached()
          ) {
            result = 'ommision2';
          } else {
            result = 'ommision1';
          }

          break;
        default:
          break;
      }
    }
    return result;
  }

  openCamera() {
    const options: CameraOptions = {
      quality: 10, // 100,
      destinationType: this.camera.DestinationType.DATA_URL, // FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY, // CAMERA // PHOTOLIBRARY
    };

    this.camera.getPicture(options).then(
      (imageData) => {
        // imageData is either a base64 encoded string or a file URI
        // If it's base64 (DATA_URL):
        let base64Image = 'data:image/jpeg;base64,' + imageData;
        const imgData = new InfoImage();
        imgData.dataImage = base64Image.substring(
          base64Image.lastIndexOf(',') + 1
        );
        imgData.recIdAnswer = new Date().getHours() + new Date().getMinutes();
        imgData.extension = 'jpeg';
        imgData.action = 3;
        imgData.type = 2;
        imgData.company = 'cmer';
        this.Task.setImageData(imgData);
        this.presentToast('Imagen de adjunto almacenada.');
        /* this.service.uploadImge(imgData).then(
       result => {
         console.log(result);
       }
     )
     .catch(
       error => {
         console.log(error);
       }
     );*/
      },
      (err) => {
        // Handle error
        //console.log(err);
      }
    );
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 4000,
    });
    toast.present();
  }
}
