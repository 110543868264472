export class OrderLine {
  lineId: number = 0;
  salesId: number = 0;
  dataAreaId: string = 'cmer';
  itemId: string = '';
  itemName: string = '';
  unitId: string = '';
  salesQuantity: number = 0;
  salePrice: number = 0;
  totalSale: number = 0;
  taxAmount: number = 0;
  tax: number = 0; // tax amount for only one item, its like a backup of the original taxamount
  lineDiscount: number = 0;
  linePercent: number = 0;
  fAmnt: number = 0;
  lnAmnt: number = 0;
  DeliveryDate: string = '';
  DeliveryMethod: string = '0';
  originSite: string = '0';
  deliveryRoute: string = 'null';
  /* 
    1- Condition
    3- Result
    5- Condition-Result
    99- Sin Promo
    */
  promoSalesType: number = 99;
  promoId: string = '';
  checked: boolean = false;
  salePriceUpdated: boolean = false;
  itemStatus: number = 0;
  avail: number = 0;
  taxProduct: number = 0;

  // Used to control when the shopping car has two times the same promoID
  PromoSequence: number = 0;

  //PromoDetail
  ItemGroupId: string = '';

  // Atributos Erroneos por WS, HAY QUE CORREGIRLOS pues ya estan con MINUSCULA
  // FIX Temporal convertOrderLine convierte del formato en mayúsculas al correcto
  //private LineQuantity: number = 0;
  //private SalePrice: number = 0;
  //private TotalSale: number = 0;
  // FIN DE ATRIBUTOS ERRONEOS

  fillAtt(
    lineId: number,
    salesId: number,
    dataAreaId: string,
    itemId: string,
    itemName: string,
    unitId: string,
    salesQuantity: number,
    salePrice: number,
    totalSale: number,
    taxAmount: number,
    lineDiscount: number,
    linePercent: number,
    fAmnt: number,
    lnAmnt: number,
    DeliveryDate: string,
    DeliveryMethod: string,
    originSite: string,
    deliveryRoute: string,
    promoSalesType: number,
    promoId: string,
    checked: boolean
  ) {
    this.lineId = lineId;
    this.salesId = salesId;
    this.dataAreaId = dataAreaId;
    this.itemId = itemId;
    this.itemName = itemName;
    this.unitId = unitId;
    this.salesQuantity = salesQuantity;
    this.salePrice = salePrice;
    this.totalSale = totalSale;
    this.taxAmount = taxAmount;
    this.lineDiscount = lineDiscount;
    this.linePercent = linePercent;
    this.fAmnt = fAmnt;
    this.lnAmnt = lnAmnt;
    this.DeliveryDate = DeliveryDate;
    this.DeliveryMethod = DeliveryMethod;
    this.originSite = originSite;
    this.deliveryRoute = deliveryRoute;
    this.promoSalesType = promoSalesType;
    this.promoId = promoId;
    this.checked = checked;
  }

  toXml(salesID: string) {
    let xml: string = '';
    xml += '<PSOrdLine class="entity">';
    xml += '<lnId>' + this.lineId + '</lnId>'; //contador para identificar la linea
    xml += '<slsId>' + salesID + '</slsId>'; // numero de orden
    xml += '<dtrId>' + this.dataAreaId + '</dtrId>'; //compañia
    xml += '<itmId>' + this.itemId + '</itmId>'; //articulo
    xml += '<untId>' + this.unitId + '</untId>';
    xml += '<slQntty>' + this.salesQuantity + '</slQntty>';
    xml += '<slPrce>' + this.salePrice + '</slPrce>';
    xml += '<ttlSle>' + this.totalSale + '</ttlSle>';
    xml += '<txAmnt>' + this.taxAmount + '</txAmnt>'; // no importa si es 0, AX lo calcula
    xml += '<lneDscnt>' + this.lineDiscount + '</lneDscnt>';
    xml += '<lnPrcnt>' + this.linePercent + '</lnPrcnt>';
    xml += '<fAmnt>' + this.fAmnt + '</fAmnt>'; //0
    xml += '<lnAmnt>' + this.lnAmnt + '</lnAmnt>'; //0
    xml += '<dlvryDte>' + this.DeliveryDate + '</dlvryDte>';
    xml += '<delvryMthd>' + this.DeliveryMethod + '</delvryMthd>';
    xml += '<orgnSte>' + this.originSite + '</orgnSte>';
    xml += '<dlvryRte>' + this.deliveryRoute + '</dlvryRte>';
    xml += '<PromoSalesType>' + this.promoSalesType + '</PromoSalesType>';
    xml += '<PromoId>' + this.promoId + '</PromoId>';
    xml += '</PSOrdLine>';
    return xml;
  }

  static convertOrderLine(orderLine: any): OrderLine {
    let newOrderLine: OrderLine = new OrderLine();

    newOrderLine.fillAtt(
      0, //lineId: number,
      0, //salesId: number,
      orderLine.DataAreaId, //dataAreaId: string,
      orderLine.ItemId, //itemId: string,
      orderLine.ItemName, //itemName: string,
      orderLine.UnitId, //unitId: string,
      orderLine.LineQuantity, //salesQuantity: number,
      orderLine.SalePrice, //salePrice: number,
      orderLine.TotalSale, //totalSale: number,
      orderLine.TaxAmount, //taxAmount: number,
      orderLine.LineDiscount, //lineDiscount: number,
      orderLine.LinePercent, //linePercent: number,
      orderLine.FeeAmount, //fAmnt: number,
      orderLine.LineAmount, //lnAmnt: number,
      orderLine.DeliveryDate, //DeliveryDate: string,
      '0', //DeliveryMethod: string,
      '0', //originSite: string,
      'null', //deliveryRoute: string,
      99, //promoSalesType: number,
      orderLine.PromoId, //promoId: string,
      true //checked: boolean
    );

    newOrderLine.taxProduct = orderLine.taxProduct;

    return newOrderLine;
  }
}
