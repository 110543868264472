export class CatalogModel {
  private recId: number;
  selected: boolean;
  constructor(
    private id: number,
    private name: string,
    private salesOrderRelation: boolean = false,
    private PSSalesId: String = ''
  ) {
    this.selected = false;
  }
  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  setName(newName: string) {
    this.name = newName;
  }

  getValue() {
    return this.id;
  }

  setValues(
    newId: number,
    newName: string,
    newSalesOrderRelation: boolean = false,
    newPSSalesId: String = ''
  ) {
    this.id = newId;
    this.name = newName;
    this.salesOrderRelation = newSalesOrderRelation;
    this.PSSalesId = newPSSalesId;
  }

  setRecId(recId: number) {
    this.recId = recId;
  }

  getRecId() {
    return this.recId;
  }

  getSalesOrderRelation() {
    return this.salesOrderRelation;
  }

  setSalesOrderRelation(newSalesOrderRelation: boolean) {
    this.salesOrderRelation = newSalesOrderRelation;
  }

  getPSSalesId() {
    return this.PSSalesId;
  }

  setPSSalesId(newPSSalesId: String) {
    this.PSSalesId = newPSSalesId;
  }

  static truncDigits(inputNumber: number, digits: number) {
    const fact = 10 ** digits;
    return Math.floor(inputNumber * fact) / fact;
  }
}
