<!-- NavBar with go back option -->
<app-nav-bar [title]="titulo" [start]="'GoBack'" [end]="'Cart'"></app-nav-bar>

<ion-content>
  <div
    *ngIf="!hasProducts"
    style="margin-top: 15px; display: flex; justify-content: center"
  >
    <span>No existen productos disponibles</span>
  </div>
  <div class="flexContainer">
    <div class="productContainer" *ngFor="let item of itemInfo">
      <ion-grid style="padding: 0px">
        <ion-row>
          <ion-col
            size="4"
            style="
              align-items: center;
              justify-content: center;
              display: flex;
              padding: 0px;
            "
            (click)="addToCart(item)"
          >
            <img
              class="image-container"
              src="https://serviceplan-api.mercasacr.com/images/Products/{{
                item.ItemId
              }}_l_.PNG"
              onerror="this.src='assets/img/error.png'"
              alt=""
            />
          </ion-col>
          <ion-col size="8">
            <label style="color: #4b4b4b; font-size: 10px">{{
              item.ItemId
            }}</label>
            <ion-item lines="none" style="--padding-start: 0px">
              <span class="h4">{{ item.ItemName }}</span>
            </ion-item>
            <div style="display: flex">
              <h5 color="primary" style="font-weight: bold; width: 75%">
                {{ setCurrency(item) }}
              </h5>
              <ion-button
                style="font-size: 20px"
                class="button-blue"
                (click)="addToCart(item)"
              >
                <i class="fas fa-cart-plus"></i>
              </ion-button>
            </div>
            <!-- <ion-item text-wrap lines='none' style="font-size: 6px;padding-right: 10px;">
              <span class="h7" style="text-transform: capitalize">Disponible: {{item.SiteName}}</span>
            </ion-item> -->
          </ion-col>
        </ion-row>
        <ion-list>
          <ion-item
            style="background-color: transparent; width: 100%"
            (click)="toggleDetails(item)"
          >
            <ion-grid style="padding: 0px">
              <ion-row style="padding: 0px">
                <ion-col style="padding: 0px"
                  ><div>
                    <span style="white-space: nowrap" class="small-text"
                      >Disponible en:</span
                    >

                    <span
                      style="margin-left: 5px"
                      class="small-text"
                      *ngIf="!item.IsExpanded"
                      >{{ getSlicedSites(item.SiteName) }}</span
                    >
                    <ion-icon
                      slot="end"
                      color="primary"
                      [name]="item.Icon"
                      style="font-size: 1.3rem; position: absolute; right: 0px"
                    ></ion-icon></div
                ></ion-col>
              </ion-row>
              <ion-row *ngIf="item.IsExpanded" style="padding: 0px"
                ><div
                  style="
                    text-transform: none;
                    padding: 5px 0px;
                    white-space: normal;
                    text-align: justify;
                  "
                  class="small-text"
                >
                  {{ item.SiteName }}
                </div></ion-row
              >
            </ion-grid>
          </ion-item>
        </ion-list>
      </ion-grid>
    </div>
  </div>
</ion-content>
