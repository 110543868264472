export class EntityName {
  name: string;
  firstLastName: string;
  secondLastName: string;

  //Setters y Getters
  getName() {
    return this.name;
  }

  getFirstLastName() {
    return this.firstLastName;
  }

  getSecondLastName() {
    return this.secondLastName;
  }

  setName(pName: string) {
    this.name = pName;
  }
}
