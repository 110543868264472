export class Currency {
  constructor(private currencyCodeId: string, private txt: string) {}

  getCurrencyCodeId() {
    return this.currencyCodeId;
  }

  getTXT() {
    return this.txt;
  }

  //Those functions are the same, but those names are because SearchProviderComponent works in that way

  getId() {
    return this.currencyCodeId;
  }

  getName() {
    return this.txt;
  }

  setValues(newData: Currency) {
    this.currencyCodeId = newData.getCurrencyCodeId();
    this.txt = newData.getTXT();
  }
}
