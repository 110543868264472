export class LanguageModel {
  constructor(private languageId: string) {}

  getName() {
    return this.languageId;
  }

  getId() {
    return this.languageId;
  }
}
