import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Certification } from '../../businessCore/Certification';

@Component({
  selector: 'app-cert-list-selected',
  templateUrl: './cert-list-selected.component.html',
  styleUrls: ['./cert-list-selected.component.scss'],
})
export class CertListSelectedComponent {
  certList: Certification[]; // Lista a filtrar
  typeAction: number;

  /**
   * Class constructor with these injections:
   * @param viewCtrl Access various features and information about the current view.
   * @param params Object that exists on a page and can contain data for that particular view.
   */
  constructor(
    public viewCtrl: PopoverController,
    private navParams: NavParams
  ) {
    this.certList = this.navParams.get('certList');
    this.typeAction = this.navParams.get('typeAction');
  }

  dismiss(param) {
    if (param) {
      this.viewCtrl.dismiss({
        typeAction: this.typeAction,
        certList: this.certList,
      });
    } else {
      this.viewCtrl.dismiss();
    }
  }
}
