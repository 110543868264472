<app-nav-bar [title]="'Tareas a ejecutar'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button style="background: none" (click)="saveData()">
      <img
        src="assets/img/diskette.svg"
        style="width: 20px"
        alt=""
        onerror=""
      />
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box">
    <div class="content">
      <ion-list lines="none" *ngIf="checkList">
        <app-check-list-task
          *ngFor="let taskCheck of checkList.getTasksList(); let pos = index"
          [Task]="taskCheck"
          [position]="pos"
        >
        </app-check-list-task>
      </ion-list>
    </div>
    <div class="infContent">
      <div
        style="
          text-align: end;
          margin-bottom: 2%;
          margin-left: 2%;
          margin-right: 2%;
        "
      >
        <ion-button ion-button color="primary" (click)="sendAnswerCheckList()">
          Enviar respuestas
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
