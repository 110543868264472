export enum NavigationRoutes {
  ROOT_PAGE = '',
  AppComponent = 'AppComponent',
  AddCommentComponent = 'AddCommentComponent',
  AddFindingComponent = 'AddFindingComponent',
  AddToCartComponent = 'AddToCartComponent',
  AddToListComponent = 'AddToListComponent',
  AssetAcceptanceComponent = 'AssetAcceptanceComponent',
  AssetAuditAddComponent = 'AssetAuditAddComponent',
  AssetAuditRecordComponent = 'AssetAuditRecordComponent',
  AssetAuditUnassignedAssetComponent = 'AssetAuditUnassignedAssetComponent',
  AssetCertificationPeriodicityComponent = 'AssetCertificationPeriodicityComponent',
  AssetCertificationPopoverComponent = 'AssetCertificationPopoverComponent',
  AssetFilterComponent = 'AssetFilterComponent',
  AssetRequestActionComponent = 'AssetRequestActionComponent',
  AssetRequestFilterComponent = 'AssetRequestFilterComponent',
  AssetRequestListComponent = 'AssetRequestListComponent',
  AssetsApprovalComponent = 'AssetsApprovalComponent',
  AssetsTransferComponent = 'AssetsTransferComponent',
  CertFilterComponent = 'CertFilterComponent',
  CertListSelectedComponent = 'CertListSelectedComponent',
  CheckListAnswerOmiComponent = 'CheckListAnswerOmiComponent',
  CheckListTaskComponent = 'CheckListTaskComponent',
  NoteTaskComponent = 'NoteTaskComponent',
  ContactComponent = 'ContactComponent',
  FamiliesComponent = 'FamiliesComponent',
  FamiliesHomeComponent = 'FamiliesHomeComponent',
  FamiliesSelectHierarchyComponent = 'FamiliesSelectHierarchyComponent',
  FilterComponent = 'FilterComponent',
  GeneralDividerComponent = 'GeneralDividerComponent',
  GetData2CreateComponent = 'GetData2CreateComponent',
  GetData2EditComponent = 'GetData2EditComponent',
  GetMapPositionComponent = 'GetMapPositionComponent',
  GoogleMapComponent = 'GoogleMapComponent',
  InsertPasswordComponent = 'InsertPasswordComponent',
  LogoScreenComponent = 'LogoScreenComponent',
  MyCustomListComponent = 'MyCustomListComponent',
  MyCustomListProductsComponent = 'MyCustomListProductsComponent',
  MyPaymentComponent = 'MyPaymentComponent',
  MyProfileAddcaseComponent = 'MyProfileAddcaseComponent',
  MyProfileCasemanagementComponent = 'MyProfileCasemanagementComponent',
  MyProfileContactsComponent = 'MyProfileContactsComponent',
  MyProfileCreateRestrictionComponent = 'MyProfileCreateRestrictionComponent',
  MyProfileDirectionsComponent = 'MyProfileDirectionsComponent',
  MyProfileEditEmailComponent = 'MyProfileEditEmailComponent',
  MyProfileEditLatlngComponent = 'MyProfileEditLatlngComponent',
  MyProfileEditPhoneComponent = 'MyProfileEditPhoneComponent',
  MyProfileGeneralInfoComponent = 'MyProfileGeneralInfoComponent',
  MyProfileRestrictionsComponent = 'MyProfileRestrictionsComponent',
  MyProfileUpdatepassComponent = 'MyProfileUpdatepassComponent',
  MyPromoListComponent = 'MyPromoListComponent',
  MenuNavBarComponent = 'MenuNavBarComponent',
  NewContactComponent = 'NewContactComponent',
  NewDirectionComponent = 'NewDirectionComponent',
  NewListComponent = 'NewListComponent',
  NewRestrictionComponent = 'NewRestrictionComponent',
  EntregaOcComponent = 'EntregaOcComponent',
  GeneralParameterOcComponent = 'GeneralParameterOcComponent',
  HistorialOcComponent = 'HistorialOcComponent',
  InfoFileOcPopComponent = 'InfoFileOcPopComponent',
  InfoFilterOcPopComponent = 'InfoFilterOcPopComponent',
  InfoPlusOcComponent = 'InfoPlusOcComponent',
  InputNumberComponent = 'InputNumberComponent',
  LineOcComponent = 'LineOcComponent',
  LineaInfoOcComponent = 'LineaInfoOcComponent',
  NotaOcComponent = 'NotaOcComponent',
  PopInfoOcComponent = 'PopInfoOcComponent',
  RejectedNoteOCComponent = 'RejectedNoteOCComponent',
  ResumenOcVendorComponent = 'ResumenOcVendorComponent',
  SearchProviderComponent = 'SearchProviderComponent',
  SendMultipleOCComponent = 'SendMultipleOCComponent',
  ToBeApprovedOcComponent = 'ToBeApprovedOcComponent',
  ToBeConfirmedOcComponent = 'ToBeConfirmedOcComponent',
  TotalesOcComponent = 'TotalesOcComponent',
  UpdateFinancialDimensionComponent = 'UpdateFinancialDimensionComponent',
  UpdateQtyOCComponent = 'UpdateQtyOCComponent',
  OrderConfirmationComponent = 'OrderConfirmationComponent',
  OrderConfirmationInfoComponent = 'OrderConfirmationInfoComponent',
  OrderConfirmationPassComponent = 'OrderConfirmationPassComponent',
  OrderHistoryComponent = 'OrderHistoryComponent',
  OrderTraceComponent = 'OrderTraceComponent',
  OrderTraceLinesComponent = 'OrderTraceLinesComponent',
  OrderTraceProgressComponent = 'OrderTraceProgressComponent',
  PaymentFormComponent = 'PaymentFormComponent',
  PaymentStatusComponent = 'PaymentStatusComponent',
  PrevShoppingCarComponent = 'PrevShoppingCarComponent',
  ProductCommentComponent = 'ProductCommentComponent',
  ProductDetailComponent = 'ProductDetailComponent',
  ProductGeneralCommentComponent = 'ProductGeneralCommentComponent',
  ProductMyCommentsComponent = 'ProductMyCommentsComponent',
  PromoDetailComponent = 'PromoDetailComponent',
  PromoResultComponent = 'PromoResultComponent',
  RegisterAdditionalInfoComponent = 'RegisterAdditionalInfoComponent',
  RegisterAddressComponent = 'RegisterAddressComponent',
  RegisterContactComponent = 'RegisterContactComponent',
  RegisterContactEmailComponent = 'RegisterContactEmailComponent',
  RegisterContactNumberComponent = 'RegisterContactNumberComponent',
  RegisterDirectionComponent = 'RegisterDirectionComponent',
  RegisterMethodComponent = 'RegisterMethodComponent',
  ResetPasswordComponent = 'ResetPasswordComponent',
  OrderRetHistoryComponent = 'OrderRetHistoryComponent',
  RetAddItemComponent = 'RetAddItemComponent',
  RetBoughtItemComponent = 'RetBoughtItemComponent',
  RetCarComponent = 'RetCarComponent',
  RetDeliveryCarComponent = 'RetDeliveryCarComponent',
  RetProductHistoryComponent = 'RetProductHistoryComponent',
  SearchableSelectComponent = 'SearchableSelectComponent',
  SelectDeliveryAddressComponent = 'SelectDeliveryAddressComponent',
  SelectDeliveryTimeComponent = 'SelectDeliveryTimeComponent',
  SelectProductsComponent = 'SelectProductsComponent',
  ShoppingCarDeliveryComponent = 'ShoppingCarDeliveryComponent',
  ShoppingCarPaymentComponent = 'ShoppingCarPaymentComponent',
  ShoppingCartComponent = 'ShoppingCartComponent',
  SubFamiliesSelectHierarchyComponent = 'SubFamiliesSelectHierarchyComponent',
  TermsComponent = 'TermsComponent',
  TimelineComponent = 'TimelineComponent',
  TimelineItemComponent = 'TimelineItemComponent',
  TimelineTimeComponent = 'TimelineTimeComponent',
  InfoTripsPopComponent = 'InfoTripsPopComponent',
  InfoVehiclePopComponent = 'InfoVehiclePopComponent',
  NotaTripComponent = 'NotaTripComponent',
  ParadaTripComponent = 'ParadaTripComponent',
  RegisterTripComponent = 'RegisterTripComponent',
  ShowImageComponent = 'ShowImageComponent',
  SpendTripComponent = 'SpendTripComponent',
  TutorialVideoComponent = 'TutorialVideoComponent',
  TutorialsComponent = 'TutorialsComponent',
  UpdateQuantityComponent = 'UpdateQuantityComponent',
  UseTermsComponent = 'UseTermsComponent',
  ValidateClientComponent = 'ValidateClientComponent',
  VendorAlertComponent = 'VendorAlertComponent',
  VendorRecordComponent = 'VendorRecordComponent',
  VendorRegisterComponent = 'VendorRegisterComponent',
  VendorRegisterAddressComponent = 'VendorRegisterAddressComponent',
  VendorRegisterContactComponent = 'VendorRegisterContactComponent',
  VendorRegisterDocumentComponent = 'VendorRegisterDocumentComponent',
  VendorRegisterGeneralComponent = 'VendorRegisterGeneralComponent',
  VendorRegisterLanguageComponent = 'VendorRegisterLanguageComponent',
  VendorRegisterPaymentComponent = 'VendorRegisterPaymentComponent',
  EinvoiceFilterComponent = 'EinvoiceFilterComponent',
  MessageBoxComponent = 'MessageBoxComponent',
  PurchOrderEinvoiceFilterComponent = 'PurchOrderEinvoiceFilterComponent',
  AssignEinvoicePage = 'AssignEinvoicePage',
  AssignPurchOrderPage = 'AssignPurchOrderPage',
  InvoiceLinesPage = 'InvoiceLinesPage',
  InvoiceListPage = 'InvoiceListPage',
  AssetAuditPage = 'AssetAuditPage',
  AssetCertificationPage = 'AssetCertificationPage',
  AssetCertificationApprovalPage = 'AssetCertificationApprovalPage',
  AuthPage = 'AuthPage',
  ChecklistInstancePage = 'ChecklistInstancePage',
  ChecklistTaskPage = 'ChecklistTaskPage',
  GetPositionPage = 'GetPositionPage',
  GetPositionVendorPage = 'GetPositionVendorPage',
  HomePage = 'HomePage',
  LoginPage = 'LoginPage',
  MainPagePage = 'MainPagePage',
  MapTripPage = 'MapTripPage',
  PurchOrderPage = 'PurchOrderPage',
  PurchOrderConfirmPage = 'PurchOrderConfirmPage',
  PurchOrderLinePage = 'PurchOrderLinePage',
  PurchOrderRecordPage = 'PurchOrderRecordPage',
  PurchOrderToApprovedPage = 'PurchOrderToApprovedPage',
  PurchOrderToBeApprovedPage = 'PurchOrderToBeApprovedPage',
  PurchOrderToBeConfirmedPage = 'PurchOrderToBeConfirmedPage',
  TripRegisterPage = 'TripRegisterPage',
  ConfigLinePurchRemissionComponent = 'ConfigLinePurchRemissionComponent',
  PurchOrderRemissionFilterComponent = 'PurchOrderRemissionFilterComponent',
  PurchRemissionInfoComponent = 'PurchRemissionInfoComponent',
  SelectFilterComponent = 'SelectFilterComponent',
  SelectObjectsComponent = 'SelectObjectsComponent',
  ShowConversionItemComponent = 'ShowConversionItemComponent',
  RemissionLinesPage = 'RemissionLinesPage',
  RemissionListPage = 'RemissionListPage',
  RatingComponent = 'RatingComponent',
}
