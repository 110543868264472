<app-nav-bar [title]="'Documentos'" [start]="'None'">
  <ion-buttons slot="end">
    <ion-button (click)="closePopOver(false)">
      <i class="fas fa-times" style="width: 30px"></i>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-toolbar class="oc-parameter">
  <ion-segment color="black" value="reci" [(ngModel)]="segmentModel">
    <ion-segment-button value="reci" layout="icon-start">
      <ion-label class="labelCenter">Recibidos</ion-label>
    </ion-segment-button>
    <ion-segment-button value="send" layout="icon-start" *ngIf="typeView !== 3">
      <ion-label class="labelCenter">Enviados</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-toolbar>

<ion-content>
  <div
    *ngIf="
      listOfFileSend.length > 0 && segmentModel === 'send' && typeView !== 3
    "
  >
    <ion-list>
      <ion-list-header> Documentos enviados: </ion-list-header>
      <ion-button
        expand="block"
        *ngFor="let fileEnv of listOfFileSend; let i = index"
        (click)="openReport(fileEnv.getURL())"
      >
        {{ i + 1 }} - {{ fileEnv.getFileName() }}
      </ion-button>
    </ion-list>
  </div>

  <div *ngIf="listOfFileReceived.length > 0 && segmentModel === 'reci'">
    <ion-list>
      <ion-list-header> Documentos recibidos: </ion-list-header>
      <ion-button
        expand="block"
        *ngFor="let fileRec of listOfFileReceived; let i = index"
        (click)="openReport(fileRec.getURL())"
      >
        {{ i + 1 }} - {{ fileRec.getFileName() }}
      </ion-button>
    </ion-list>
  </div>

  <div *ngIf="canSend && (typeView === 1 || typeView === 2)">
    <div *ngIf="listOfFileToSend.length > 0">
      <ion-list>
        <ion-list-header> Documentos por enviar: </ion-list-header>
        <ion-item-sliding
          *ngFor="let fileOp of listOfFileToSend; let i = index"
        >
          <ion-item> {{ i + 1 }} - {{ fileOp.getFileName() }} </ion-item>
          <ion-item-options side="end">
            <ion-item-option color="danger" (click)="deleteFile(i)">
              <ion-icon name="trash"> </ion-icon>
              <span style="font-size: 8px">Borrar</span>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
    </div>

    <div style="text-align: center">
      <ion-button color="primary" class="btnPosition">
        +Agregar nuevo documento
        <input
          type="file"
          accept="application/pdf, image/*"
          class="upload-photo"
          (change)="onFileSelected($event, 7)"
          multiple
        />
      </ion-button>
    </div>
    <div
      style="margin-top: 2%; margin-bottom: 2%; width: 100%; text-align: center"
      *ngIf="typeView === 1"
    >
      <ion-button color="primary" class="btnPosition" (click)="saveFile()">
        Aceptar
      </ion-button>
    </div>
    <div
      style="margin-top: 2%; margin-bottom: 2%; width: 100%; text-align: center"
      *ngIf="typeView === 2"
    >
      <ion-button
        color="primary"
        class="btnPosition"
        (click)="saveFile2(false)"
      >
        Aceptar
      </ion-button>
    </div>
  </div>
</ion-content>
