import { Component } from '@angular/core';
import {
  AlertController,
  IonRefresher,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { InfoFilterOcPopComponent } from 'src/app/components/oc-components/info-filter-oc-pop/info-filter-oc-pop.component';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { SearchProviderComponent } from '../../components/oc-components/search-provider/search-provider.component';
import { SendMultipleOCComponent } from '../../components/oc-components/send-multiple-oc/send-multiple-oc.component';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';
import { CatalogModel } from './../../businessCore/oc-models/CatalogModel';

@Component({
  selector: 'app-purch-order-to-be-approved',
  templateUrl: './purch-order-to-be-approved.component.html',
  styleUrls: ['./purch-order-to-be-approved.component.scss'],
})
export class PurchOrderToBeApprovedPage {
  listPurch: PurchOrder[] = [];
  listPurchShowing: PurchOrder[] = [];
  listDataArea: DataArea[] = [];
  loader: HTMLIonLoadingElement;
  dataAreaSelected: string = '';
  presentingMess = false;
  sendMultiple = false;

  // Filtros y ordenamiento
  filterKey: string = '';
  proveedorList: CatalogModel[] = [];
  creadoPorList: CatalogModel[] = [];
  startDate: Date;
  finalDate: Date;
  proveedorAZ: boolean = false;
  proveedorZA: boolean = false;
  fechaMenorMayor: boolean = false;
  fechaMayorMenor: boolean = false;
  orderBy: string = '';

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private extServOC: ExternalServiceOCService,
    private loadingCtrl: LoadingController,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    private authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService
  ) {}

  async ngOnInit() {
    this.listDataArea = await this.extServOC.getDataAreaInfo();

    if (this.listDataArea.length > 0) {
      this.dataAreaSelected = '';
      // this.listDataArea[0].getId();
      // this.changeCompanySelected(this.dataAreaSelected);
    } else {
      this.dataAreaSelected = '';
      this.messageInfo('Alerta', 'No se pudo cargar los datos de compañias.');
    }
    this.startDate = null;
    this.finalDate = null;
  }

  async openSearchBarAux() {
    if (this.listDataArea.length > 0) {
      const modal = await this.modalCtrl.create({
        component: SearchProviderComponent,
        componentProps: {
          objectsList: this.listDataArea,
        },
      });
      await modal.present();
      modal.onDidDismiss().then((res) => {
        let data = res.data;
        if (data.objSelected !== null) {
          const tmpData = data.objSelected as DataArea;
          this.dataAreaSelected = tmpData.getId();
          this.changeCompanySelected(this.dataAreaSelected);
        }
      });
    }
  }

  refresOC(event) {
    let refresher: IonRefresher = event.detail;
    if (
      this.dataAreaSelected !== null &&
      this.dataAreaSelected !== undefined &&
      this.dataAreaSelected !== ''
    ) {
      refresher.complete();
      this.sendMultiple = false;
      this.changeCompanySelected(this.dataAreaSelected);
    } else {
      refresher.complete();
      this.messageInfo('Alerta', 'Seleccione una compañía.');
    }

    //DEBUG
    //this.sendMultiple = true;
    // DEBUG
  }

  logScrolling(event) {
    //console.log('evento: ', event);
  }

  async changeCompanySelected(dataAreaId: string) {
    await this.showSpinnerLoading('Cargando datos de ordenes por aprobar.');
    const resp = await this.extServOC.getToBeApproved(dataAreaId, 10); // 'EN REVISIÓN' === 10
    // Traer la info de las OC que se tienen por
    this.dissmissLoading();
    if (resp !== null) {
      this.listPurch = resp as PurchOrder[];
      //DEBUG
      /*let PO = new PurchOrder(
        'jlbs001',
        new PurchPoolModel('sdfas', 'asdfsad'),
        'sdf',
        'asdf',
        new Date(),
        new Date(),
        new SitioModel('cca', 'asdf'),
        new AlmacenModel('f20', 'asdf'),
        new Date(),
        1,
        1,
        new CatalogModel(1, 'asdf'),
        1,
        new Date(),
        new Date(),
        new Date(),
        new CatalogModel(1, 'asdf'),
        new CatalogModel(1, 'asdf'),
        new CatalogModel(1, 'asdf'),
        'asdf',
        new CatalogModel(1, 'asdf'),
        'asdf',
        new DataArea('cmer', 'asdf'),
        'asdf'
      );
      PO.setDocumentStateI(10);
      PO.setIdOC('OC-*24545454');
      this.listPurch = [PO];*/
      // DEBUG
      if (this.listPurch.length === 0) {
        this.messageInfo(
          'Alerta',
          'No hay datos de ordenes pendientes para la compañía ' +
            dataAreaId +
            '.'
        );
      }

      //Setea la informacion a mostrar
      this.setListToShow();

      this.listPurchShowing = this.listPurch;
    } else {
      this.listPurch = []; // Se setea una lista vacia
      this.setListToShow();
      this.messageInfo(
        'Alerta',
        'Se produjo un error al consultar la información de las ordenes, para la compañía ' +
          dataAreaId +
          '.'
      );
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [{ text: 'Aceptar', handler: () => {} }],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async sendOCToAprobe() {
    // Abre el mensaje de envio, paso como parametro la lista de las ordenes a mandar.
    if (!this.presentingMess) {
      this.presentingMess = true;
      // const result = this.listPurch.filter(ocInfo => ocInfo.getToSend() && ocInfo.getDocumentStateI() === 10); // 'EN REVISIÓN' === 10
      const result = this.listPurchShowing.filter(
        (ocInfo) => ocInfo.getToSend() && ocInfo.getDocumentStateI() === 10
      ); // 'EN REVISIÓN' === 10

      const modal = await this.modalCtrl.create({
        component: SendMultipleOCComponent,
        componentProps: { objectsList: result },
        backdropDismiss: false,
      });
      await modal.present();
      modal.onDidDismiss().then(() => {
        this.showBtnMultipleSend();
        this.presentingMess = false;
      });
    }
  }

  showBtnMultipleSend() {
    // Se selecciona o desele
    // const result = this.listPurch.filter(ocInfo => ocInfo.getToSend() && ocInfo.getDocumentStateI() === 10); // 'EN REVISIÓN' === 10
    const result = this.listPurchShowing.filter(
      (ocInfo) => ocInfo.getToSend() && ocInfo.getDocumentStateI() === 10
    ); // 'EN REVISIÓN' === 10
    if (result.length > 0) {
      this.sendMultiple = true;
    } else {
      this.sendMultiple = false;
    }
  }

  // Retorna una lista a partir de lo local y
  // filtros...
  setListToShow() {
    //A mi lista, realice los siguientes filtros
    const result = PurchOrder.filterState(
      this.listPurch,
      this.proveedorList,
      2
    );
    const result2 = PurchOrder.filterState(result, this.creadoPorList, 5);
    const result3 = PurchOrder.filterByDateCreation(
      result2,
      this.startDate,
      this.finalDate
    );
    const result4 = PurchOrder.orderListBy(result3, this.orderBy);
    this.listPurchShowing = PurchOrder.filterObjectByWrite(
      this.filterKey,
      result4
    );
  }

  searchOC(event) {
    this.filterKey = event.detail.value as string;
    if (this.listPurch !== null && this.listPurch !== undefined) {
      this.setListToShow();
    }
  }

  async presentFilterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: InfoFilterOcPopComponent,
      componentProps: {
        purchList: this.listPurch,
        typeFilter: 2,
        proveedorList: this.proveedorList,
        creadoPorList: this.creadoPorList,
        startDate: this.startDate,
        finalDate: this.finalDate,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        this.proveedorList = data.proveedorList as CatalogModel[];
        this.creadoPorList = data.creadoPorList as CatalogModel[];
        this.startDate = data.startDate as Date;
        this.finalDate = data.finalDate as Date;
        this.setListToShow();
      }
    });
  }

  setSelectedOrder(orderType: string) {
    this.proveedorAZ = 'proveedorAZ' === orderType;
    this.proveedorZA = 'proveedorZA' === orderType;
    this.fechaMenorMayor = 'fechaMenor-mayor' === orderType;
    this.fechaMayorMenor = 'fechaMayor-menor' === orderType;
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  /**
   * Muestra un alert con valores para ordenar las listas.
   */
  async presentSortAlert() {
    let alert = await this.alertController.create({
      header: '¿Cómo ordenar los activos?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Proveedor (A -> Z)',
          'proveedorAZ',
          this.proveedorAZ
        ),
        this.getInputAlert(
          'radio',
          'Proveedor (Z -> A)',
          'proveedorZA',
          this.proveedorZA
        ),
        this.getInputAlert(
          'radio',
          'Fecha (menor - mayor)',
          'fechaMenor-mayor',
          this.fechaMenorMayor
        ),
        this.getInputAlert(
          'radio',
          'Fecha (mayor - menor)',
          'fechaMayor-menor',
          this.fechaMayorMenor
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            //console.log(data);
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow();
            }
          },
        },
      ],
    });
    alert.present();
  }
}
