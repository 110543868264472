import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlmacenModel } from '../../../businessCore/oc-models/AlmacenModel';
import { SitioModel } from '../../../businessCore/oc-models/SitioModel';

@Component({
  selector: 'app-entrega-oc',
  templateUrl: './entrega-oc.component.html',
  styleUrls: ['./entrega-oc.component.scss'],
})
export class EntregaOcComponent implements OnInit {
  @Input() sitioSelected: SitioModel;
  @Input() almacenSelected: AlmacenModel;
  @Input() fechaEntrega: Date;
  @Input() cantEdit: Boolean;
  @Output() openSearchBar = new EventEmitter<string>(); // Evento la direccion
  @Output() getDatesEntrega = new EventEmitter<Date>(); // Evento de la fecha
  entregaDayS: string;
  maxDate: string = null;
  constructor() {
    // this.setMaxDate();
    this.maxDate = '2100-01-01';
  }

  ngOnInit() {
    this.entregaDayS =
      this.fechaEntrega !== null && this.fechaEntrega !== undefined
        ? this.fechaEntrega.toISOString()
        : new Date().toISOString();

    this.sitioSelected =
      this.sitioSelected !== null && this.sitioSelected !== undefined
        ? this.sitioSelected
        : new SitioModel('', '');

    this.almacenSelected =
      this.almacenSelected !== null && this.almacenSelected !== undefined
        ? this.almacenSelected
        : new AlmacenModel('', '');
  }

  emitSelectedBar(type: number) {
    let typeS = '';
    switch (type) {
      case 1:
        typeS = 'search-sitio';
        break;
      case 2:
        typeS = 'search-almacen';
        break;
      default:
        break;
    }
    if (this.cantEdit) {
      this.openSearchBar.emit(typeS);
    }
  }

  emitDays() {
    if (this.cantEdit) {
      this.getDatesEntrega.emit(
        this.entregaDayS === '' ? null : new Date(this.entregaDayS)
      );
    }
  }

  setMaxDate() {
    //Fecha maxima
    const dayToConvert = new Date();
    this.maxDate =
      (dayToConvert.getFullYear() + 1).toString() +
      '-' +
      (dayToConvert.getMonth() + 1).toString() +
      '-' +
      dayToConvert.getDate();
  }
}
