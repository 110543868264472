import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
})
export class SearchableSelectComponent implements OnInit {
  @Input() objectsList: any[]; // Lista a filtrar
  filteredList: any[];
  inputKey: string;
  typeAction: number;

  /**
   * Class constructor with these injections:
   * @param viewCtrl Access various features and information about the current view.
   * @param params Object that exists on a page and can contain data for that particular view.
   * @param opts
   */

  constructor(public viewCtrl: ModalController, private params: NavParams) {}

  ngOnInit() {
    this.objectsList = this.params.get('objectsList');
    this.typeAction = this.params.get('typeAction');
    this.filteredList = this.objectsList.slice();
    this.inputKey = '';
  }

  onInputChange() {
    switch (this.typeAction) {
      case 1: {
        this.filteredList = this.objectsList.filter((item: any) => {
          return (
            item.name.toLowerCase().indexOf(this.inputKey.toLowerCase()) > -1 ||
            item.vatNum.toLowerCase().indexOf(this.inputKey.toLowerCase()) > -1
          );
        });
        break;
      }
      case 2: 
      case 3: {
        this.filteredList = this.objectsList.filter((item: any) => {
          return (
            item.getName().toLowerCase().indexOf(this.inputKey.toLowerCase()) >
            -1
          );
        });
        break;
      }
      default: {
        this.filteredList = this.objectsList.filter((item: any) => {
          return (
            item.getName().toLowerCase().indexOf(this.inputKey.toLowerCase()) >
              -1 ||
            item
              .getVatnum()
              .toLowerCase()
              .indexOf(this.inputKey.toLowerCase()) > -1
          );
        });
        break;
      }
    }
  }

  onItemClick(item, i) {
    switch (this.typeAction) {
      case 1:
        this.viewCtrl.dismiss(item);
        break;
      default:
        this.viewCtrl.dismiss(item);
        break;
    }
  }

  async closePopOver() {
    this.viewCtrl.dismiss();
  }
}
