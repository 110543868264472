// Native - Plugins
import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

// BusinessCore
import { InventoryItem } from './../../businessCore/InventoryItem';

@Component({
  selector: 'app-product-general-comment',
  templateUrl: './product-general-comment.component.html',
  styleUrls: ['./product-general-comment.component.scss'],
})
export class ProductGeneralCommentComponent {
  // Producto al que se le consulta la informacion de comentarios y reacciones
  public item: InventoryItem;

  public tab: string = 'ProductCommentComponent';

  /**
   * Class constructor with this injection:
   * @param navParams object that exists on a page and can contain data for that particular view.
   */
  constructor(public navParams: NavParams) {
    // Consulta la informacion sobre la que se trabajara en los tab de
    // 'product-comment' y 'product-my-comment'
    this.item = this.navParams.get('item');
  }

  setTab(newTab: string) {
    this.tab = newTab;
  }
}
