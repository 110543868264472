<div class="box">
  <div class="barTitle">
    <h5
      style="
        font-size: 1rem;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
      "
    >
      NOTAS
    </h5>
  </div>
  <ion-item lines="none">
    <ion-label><h2>Nota</h2></ion-label>
  </ion-item>
  <!-- <div class="content"> -->
  <div
    style="
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <textarea
      rows="10"
      cols="50"
      style="padding: 0px; margin: 0px; border: 0px solid; background: white"
      placeholder="Escriba la nota"
      [disabled]="!cantEdit"
      [(ngModel)]="note"
      name="note"
      autocomplete="on"
      autocorrect="on"
      (ngModelChange)="emitNote()"
    >
    </textarea>
  </div>
</div>
