<app-nav-bar [title]="'Obtener Posición'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button
      style="background: none"
      (click)="setActualPosition()"
      [hidden]="!showActualPosBtn"
    >
      <i class="fas fa-crosshairs" style="width: 30px"></i>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="start">
    <ion-button style="background: none" (click)="closeScreen()">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content class="ion-paddding">
  <ion-button (click)="getPositionSelected()" style="display: flex">
    Fijar Punto {{ titulo }}
  </ion-button>
  <div style="text-align: justify; font-size: 1rem !important; color: brown">
    Seleccione el punto en el mapa donde desea que se registre el punto de
    {{ titulo }} del viaje y presione el botón
    <a style="font-weight: bold">Fijar Punto {{ titulo }}</a>
  </div>
  <div
    class="loaderInf"
    *ngIf="showLoader"
    style="
      display: flex;
      background-color: hsla(230, 47%, 77%, 0.212);
      padding: 0 10px 0 10px;
      border-radius: 5px;
    "
  >
    <ion-label> Obteniendo información depunto GPS </ion-label>
    <div class="loader"></div>
  </div>

  <div style="position: relative; height: 100% !important" id="mapTripId"></div>
</ion-content>
