import { GastoModel } from './gasto';
import { ParadaModel } from './parada';
import { Vehicle } from './Vehicle';

export class TripModel {
  private dataArea: string;
  private realDataArea: string;
  private description: string;
  private executorId: string;
  private initClient: string;
  private endClient: string;
  private fechaInicio: Date;

  private puntoInicio: ParadaModel;
  private puntoFinal: ParadaModel;
  private typeTrip: string;
  private stateTrip: string;

  private horaFin: Date;
  private listParada: ParadaModel[];
  private listGasto: GastoModel[];

  private FMTrip: string;
  private instanceID: string;

  private vehicle: Vehicle;
  private mile: number; // 0 No, 1 Si

  constructor() {
    this.listParada = [];
    this.listGasto = [];
    this.dataArea = '';
    this.description = '';
    this.executorId = '';
    this.initClient = '';
    this.typeTrip = '';
    this.stateTrip = '';
    this.FMTrip = '';
    this.vehicle = new Vehicle('', '');
    this.mile = 0; // Por defecto es kilometro
  }

  setMile(newMile: number) {
    this.mile = newMile;
  }

  getMile() {
    return this.mile;
  }

  setHoraFin(newHoraFin: Date) {
    if (this.validateDate(newHoraFin)) {
      this.horaFin = new Date(newHoraFin);
      if (this.puntoFinal !== null && this.puntoFinal !== undefined) {
        // this.puntoFinal.setInitDate(this.horaFin);
        this.puntoFinal.setEndDate(this.horaFin);
      }
    }
  }

  setHoraFin2(newHoraFin: Date) {
    if (this.validateDate(newHoraFin)) {
      let horaFin2 = new Date(newHoraFin);
      if (this.puntoFinal !== null && this.puntoFinal !== undefined) {
        this.puntoFinal.setInitDate(horaFin2);
      }
    }
  }

  getHoraFin() {
    return this.horaFin;
  }

  setKilometrajeInicial(newKilometrajeInicial: number) {
    if (this.puntoInicio !== null && this.puntoInicio !== undefined) {
      this.puntoInicio.setKilometraje(
        this.resolveSendMile(newKilometrajeInicial)
      );
    }
  }

  getKilometrajeInicial() {
    return this.puntoInicio !== null && this.puntoInicio !== undefined
      ? this.puntoInicio.getKilometraje()
      : 0;
  }

  setKilometrajeFinal(newKilometrajeFinal: number) {
    if (this.puntoFinal !== null && this.puntoFinal !== undefined) {
      this.puntoFinal.setKilometraje(this.resolveSendMile(newKilometrajeFinal));
    }
  }

  getKilometrajeFinal() {
    return this.puntoFinal !== null && this.puntoFinal !== undefined
      ? this.puntoFinal.getKilometraje()
      : 0;
  }

  setFecha(newFecha: Date) {
    if (this.validateDate(newFecha)) {
      this.fechaInicio = new Date(newFecha);
      if (this.puntoInicio !== null && this.puntoInicio !== undefined) {
        this.puntoInicio.setInitDate(this.fechaInicio);
      }
    }
  }

  setFecha2(newFecha: Date) {
    if (this.validateDate(newFecha)) {
      let fecha = new Date(newFecha);
      if (this.puntoInicio !== null && this.puntoInicio !== undefined) {
        this.puntoInicio.setEndDate(fecha);
      }
    }
  }

  getFecha() {
    return this.fechaInicio;
  }

  setPuntoInit(newPuntoInit: ParadaModel) {
    this.puntoInicio = newPuntoInit;
  }

  getPuntoInit() {
    return this.puntoInicio;
  }

  setPuntoFinal(newPuntoFinal: ParadaModel) {
    this.puntoFinal = newPuntoFinal;
  }

  getPuntoFinal() {
    return this.puntoFinal;
  }

  setNewParada(newParada: ParadaModel) {
    this.listParada.push(newParada);
  }

  getListParada() {
    return this.listParada;
  }

  setNewGasto(newGasto: GastoModel) {
    this.listGasto.push(newGasto);
  }

  getListGasto() {
    return this.listGasto;
  }

  getDataArea() {
    return this.dataArea;
  }

  setDataArea(newDataArea: string) {
    this.dataArea = newDataArea;
  }

  setDescription(newDescription: string) {
    this.description = newDescription;
  }

  getDescription() {
    return this.description;
  }

  setExecutorId(newExecutorId: string) {
    this.executorId = newExecutorId;
  }

  getExecutorId() {
    return this.executorId;
  }

  setInitClient(newInitClient: string) {
    this.initClient = newInitClient;
  }

  getInitClient() {
    return this.initClient;
  }

  setEndClient(newEndClient: string) {
    this.endClient = newEndClient;
  }

  getDateToString(infoDate: Date) {
    let result = '';
    if (this.validateDate(infoDate)) {
      result =
        infoDate.getMonth() +
        1 +
        '/' +
        infoDate.getDate() +
        '/' +
        infoDate.getFullYear() +
        ' ' +
        infoDate.getHours() +
        ':' +
        infoDate.getMinutes();
    }
    return result;
  }

  getTypeTrip() {
    return this.typeTrip;
  }

  setTypeTrip(newTypeTrip: string) {
    this.typeTrip = newTypeTrip;
  }

  getStateTrip() {
    return this.stateTrip;
  }

  setStateTrip(newStateTrip: string) {
    this.stateTrip = newStateTrip;
  }

  getFMTrip() {
    return this.FMTrip;
  }

  setFMTrip(newFMTrip: string) {
    this.FMTrip = newFMTrip;
  }

  setInstance(newInstance: string) {
    this.instanceID = newInstance;
  }

  getInstance() {
    return this.instanceID;
  }

  setRealDataArea(newRealDataArea: string) {
    this.realDataArea = newRealDataArea;
  }

  getRealDataArea() {
    return this.realDataArea;
  }

  validateDate(fecha: Date) {
    return (
      fecha !== null &&
      fecha !== undefined &&
      fecha.getTime() !== new Date(null).getTime()
    );
  }

  setVehicule(newVehicle: Vehicle) {
    this.vehicle = newVehicle;
  }

  getVehicule() {
    return this.vehicle;
  }

  /**
   * Valor de Milla o Kilometro  --> kilometro
   * @param data
   *
   * @returns
   */
  resolveSendMile(data: number) {
    let result = data;
    if (this.mile === 1) {
      //Milla a Kilometro  km = milla * 1,609
      result = result > 0 ? result * 1.609 : result;
      result = Math.round(result);
    }
    return result;
  }

  /**
   * Valor kilometro --> Milla o Kilometro
   * @param data
   * @returns
   */
  resolveSetMile(data: number) {
    let result = data;
    if (this.mile === 1) {
      result = result > 0 ? result * 0.62137 : result; // milla = km * 0.62137
      result = Math.round(result);
    }
    return result;
  }

  toJSON() {
    const objeto = {
      TripNumber: this.FMTrip,
      DataArea: this.dataArea,
      Description: this.description,
      ExecutorId: this.executorId,
      InitClient: this.initClient,
      StartDate: this.getDateToString(this.fechaInicio),
      TypeTrip: this.getTypeTrip(),
      StartMileage:
        this.puntoInicio !== null && this.puntoInicio !== undefined
          ? this.puntoInicio.getKilometrajeJSON()
          : -1,
      EndMileage:
        this.puntoFinal !== null && this.puntoFinal !== undefined
          ? this.puntoFinal.getKilometrajeJSON()
          : -1,
      StateTrip: this.stateTrip,
      InstanceID: this.instanceID,
      NumVehicle: this.vehicle.getNumVehicle(),
      PlacVehicle: this.vehicle.getPlacVehicle(),
      PropertyVehicle: this.vehicle.getTypeProperty(),
      VehicleType: this.vehicle.getTypeTransport(),
      Mile: this.mile,
    };
    return objeto;
  }

  toJSONLocal() {
    const objeto = {
      TripNumber: this.FMTrip,
      DataArea: this.dataArea,
      Description: this.description,
      ExecutorId: this.executorId,
      InitClient: this.initClient,
      EndClient: this.endClient,
      StartDate: this.fechaInicio,
      RealDataArea: this.realDataArea,
      EndDate: this.horaFin,
      TypeTrip: this.getTypeTrip(),
      StartMileage:
        this.puntoInicio !== null && this.puntoInicio !== undefined
          ? this.puntoInicio.getKilometrajeJSON()
          : -1,
      EndMileage:
        this.puntoFinal !== null && this.puntoFinal !== undefined
          ? this.puntoFinal.getKilometrajeJSON()
          : -1,
      StateTrip: this.stateTrip,
      InstanceID: this.instanceID,
      NumVehicle: this.vehicle.getNumVehicle(),
      PlacVehicle: this.vehicle.getPlacVehicle(),
      PropertyVehicle: this.vehicle.getTypeProperty(),
      VehicleType: this.vehicle.getTypeTransport(),
      PuntoInicio:
        this.puntoInicio !== null && this.puntoInicio !== undefined
          ? this.puntoInicio.ToJSONLocal()
          : this.puntoInicio,
      PuntoFinal:
        this.puntoFinal !== null && this.puntoFinal !== undefined
          ? this.puntoFinal.ToJSONLocal()
          : this.puntoFinal,
      Mile: this.mile,
    };
    return objeto;
  }

  /**
   * Procesa las paradas de un viaje
   * @param infoParadas
   */
  setParadasInfo(infoParadas: ParadaModel[]) {
    for (let index = 0; index < infoParadas.length; index++) {
      const element = infoParadas[index];
      switch (element.getType()) {
        case 0: //inicio
          this.setPuntoInit(element);
          break;
        case 1:
          this.listParada.push(element);
          break;
        case 2:
          this.setPuntoFinal(element);
          break;
        default:
          break;
      }
    }
  }
}
