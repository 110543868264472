export class ProductComment {
  RECID: string = '';
  ACCOUNTNUM: string = '';
  CONTENT: string = '';
  ITEMID: string = '';
  COMMENTOWNER: number;
  LIKES: number;
  DISLIKES: number;
  USERLIKED: number;
  USERDISLIKED: number;

  constructor() {}
}
