import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavigationRoutes } from './GeneralUtilis/Navigation/Navigation-routes';
import { EinvoiceFilterComponent } from './Invoice/components/einvoice-filter/einvoice-filter.component';
import { MessageBoxComponent } from './Invoice/components/message-box/message-box.component';
import { PurchOrderEinvoiceFilterComponent } from './Invoice/components/purch-order-einvoice-filter/purch-order-einvoice-filter.component';
import { AssignEinvoicePage } from './Invoice/pages/assign-einvoice/assign-einvoice.component';
import { AssignPurchOrderPage } from './Invoice/pages/assign-purch-order/assign-purch-order.component';
import { InvoiceLinesPage } from './Invoice/pages/invoice-lines/invoice-lines.component';
import { InvoiceListPage } from './Invoice/pages/invoice-list/invoice-list.component';
import { ConfigLinePurchRemissionComponent } from './WMS/components/config-line-purch-remission/config-line-purch-remission.component';
import { PurchOrderRemissionFilterComponent } from './WMS/components/purch-order-remission-filter/purch-order-remission-filter.component';
import { PurchRemissionInfoComponent } from './WMS/components/purch-remission-info/purch-remission-info.component';
import { SelectFilterComponent } from './WMS/components/select-filter/select-filter.component';
import { SelectObjectsComponent } from './WMS/components/select-objects/select-objects.component';
import { ShowConversionItemComponent } from './WMS/components/show-conversion-item/show-conversion-item.component';
import { RemissionLinesPage } from './WMS/pages/remission-lines/remission-lines.component';
import { RemissionListPage } from './WMS/pages/remission-list/remission-list.component';
import { AppComponent } from './app.component';
import { OrderRetHistoryComponent } from './components/ReturnComponents/order-ret-history/order-ret-history.component';
import { RetAddItemComponent } from './components/ReturnComponents/ret-add-item/ret-add-item.component';
import { RetBoughtItemComponent } from './components/ReturnComponents/ret-bought-item/ret-bought-item.component';
import { RetCarComponent } from './components/ReturnComponents/ret-car/ret-car.component';
import { RetDeliveryCarComponent } from './components/ReturnComponents/ret-delivery-car/ret-delivery-car.component';
import { RetProductHistoryComponent } from './components/ReturnComponents/ret-product-history/ret-product-history.component';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { AddFindingComponent } from './components/add-finding/add-finding.component';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { AddToListComponent } from './components/add-to-list/add-to-list.component';
import { AssetAcceptanceComponent } from './components/asset-acceptance/asset-acceptance.component';
import { AssetAuditAddComponent } from './components/asset-audit-add/asset-audit-add.component';
import { AssetAuditRecordComponent } from './components/asset-audit-record/asset-audit-record.component';
import { AssetAuditUnassignedAssetComponent } from './components/asset-audit-unassigned-asset/asset-audit-unassigned-asset.component';
import { AssetCertificationPeriodicityComponent } from './components/asset-certification-periodicity/asset-certification-periodicity.component';
import { AssetCertificationPopoverComponent } from './components/asset-certification-popover/asset-certification-popover.component';
import { AssetFilterComponent } from './components/asset-filter/asset-filter.component';
import { AssetRequestActionComponent } from './components/asset-request-action/asset-request-action.component';
import { AssetRequestFilterComponent } from './components/asset-request-filter/asset-request-filter.component';
import { AssetRequestListComponent } from './components/asset-request-list/asset-request-list.component';
import { AssetsApprovalComponent } from './components/assets-approval/assets-approval.component';
import { AssetsTransferComponent } from './components/assets-transfer/assets-transfer.component';
import { CertFilterComponent } from './components/cert-filter/cert-filter.component';
import { CertListSelectedComponent } from './components/cert-list-selected/cert-list-selected.component';
import { CheckListAnswerOmiComponent } from './components/checklist-component/check-list-answer-omi/check-list-answer-omi.component';
import { CheckListTaskComponent } from './components/checklist-component/check-list-task/check-list-task.component';
import { ChecklistInstancePage } from './components/checklist-component/checklist-instance/checklist-instance.component';
import { ChecklistTaskPage } from './components/checklist-component/checklist-task/checklist-task.component';
import { NoteTaskComponent } from './components/checklist-component/note-task/note-task.component';
import { ContactComponent } from './components/contact/contact.component';
import { FamiliesHomeComponent } from './components/families-home/families-home.component';
import { FamiliesSelectHierarchyComponent } from './components/families-select-hierarchy/families-select-hierarchy.component';
import { FamiliesComponent } from './components/families/families.component';
import { FilterComponent } from './components/filter/filter.component';
import { GeneralDividerComponent } from './components/general-divider/general-divider.component';
import { GetData2CreateComponent } from './components/get-data2-create/get-data2-create.component';
import { GetData2EditComponent } from './components/get-data2-edit/get-data2-edit.component';
import { GetMapPositionComponent } from './components/get-map-position/get-map-position.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { InsertPasswordComponent } from './components/insert-password/insert-password.component';
import { LogoScreenComponent } from './components/logo-screen/logo-screen.component';
import { MyCustomListProductsComponent } from './components/my-custom-list-products/my-custom-list-products.component';
import { MyCustomListComponent } from './components/my-custom-list/my-custom-list.component';
import { MyPaymentComponent } from './components/my-payment/my-payment.component';
import { MyProfileAddcaseComponent } from './components/my-profile-addcase/my-profile-addcase.component';
import { MyProfileCasemanagementComponent } from './components/my-profile-casemanagement/my-profile-casemanagement.component';
import { MyProfileContactsComponent } from './components/my-profile-contacts/my-profile-contacts.component';
import { MyProfileCreateRestrictionComponent } from './components/my-profile-create-restriction/my-profile-create-restriction.component';
import { MyProfileDirectionsComponent } from './components/my-profile-directions/my-profile-directions.component';
import { MyProfileEditEmailComponent } from './components/my-profile-edit-email/my-profile-edit-email.component';
import { MyProfileEditLatlngComponent } from './components/my-profile-edit-latlng/my-profile-edit-latlng.component';
import { MyProfileEditPhoneComponent } from './components/my-profile-edit-phone/my-profile-edit-phone.component';
import { MyProfileGeneralInfoComponent } from './components/my-profile-general-info/my-profile-general-info.component';
import { MyProfileRestrictionsComponent } from './components/my-profile-restrictions/my-profile-restrictions.component';
import { MyProfileUpdatepassComponent } from './components/my-profile-updatepass/my-profile-updatepass.component';
import { MyPromoListComponent } from './components/my-promo-list/my-promo-list.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NewContactComponent } from './components/new-contact/new-contact.component';
import { NewDirectionComponent } from './components/new-direction/new-direction.component';
import { NewListComponent } from './components/new-list/new-list.component';
import { NewRestrictionComponent } from './components/new-restriction/new-restriction.component';
import { EntregaOcComponent } from './components/oc-components/entrega-oc/entrega-oc.component';
import { GeneralParameterOcComponent } from './components/oc-components/general-parameter-oc/general-parameter-oc.component';
import { HistorialOcComponent } from './components/oc-components/historial-oc/historial-oc.component';
import { InfoFileOcPopComponent } from './components/oc-components/info-file-oc-pop/info-file-oc-pop.component';
import { InfoFilterOcPopComponent } from './components/oc-components/info-filter-oc-pop/info-filter-oc-pop.component';
import { InfoPlusOcComponent } from './components/oc-components/info-plus-oc/info-plus-oc.component';
import { InputNumberComponent } from './components/oc-components/input-number/input-number.component';
import { LineOcComponent } from './components/oc-components/line-oc/line-oc.component';
import { LineaInfoOcComponent } from './components/oc-components/linea-info-oc/linea-info-oc.component';
import { NotaOcComponent } from './components/oc-components/nota-oc/nota-oc.component';
import { PopInfoOcComponent } from './components/oc-components/pop-info-oc/pop-info-oc.component';
import { RejectedNoteOCComponent } from './components/oc-components/rejected-note-oc/rejected-note-oc.component';
import { ResumenOcVendorComponent } from './components/oc-components/resumen-oc-vendor/resumen-oc-vendor.component';
import { SearchProviderComponent } from './components/oc-components/search-provider/search-provider.component';
import { SendMultipleOCComponent } from './components/oc-components/send-multiple-oc/send-multiple-oc.component';
import { ToBeApprovedOcComponent } from './components/oc-components/to-be-approved-oc/to-be-approved-oc.component';
import { ToBeConfirmedOcComponent } from './components/oc-components/to-be-confirmed-oc/to-be-confirmed-oc.component';
import { TotalesOcComponent } from './components/oc-components/totales-oc/totales-oc.component';
import { UpdateFinancialDimensionComponent } from './components/oc-components/update-financial-dimension/update-financial-dimension.component';
import { UpdateQtyOCComponent } from './components/oc-components/update-qty-oc/update-qty-oc.component';
import { OrderConfirmationInfoComponent } from './components/order-confirmation-info/order-confirmation-info.component';
import { OrderConfirmationPassComponent } from './components/order-confirmation-pass/order-confirmation-pass.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { OrderTraceLinesComponent } from './components/order-trace-lines/order-trace-lines.component';
import { OrderTraceProgressComponent } from './components/order-trace-progress/order-trace-progress.component';
import { OrderTraceComponent } from './components/order-trace/order-trace.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PrevShoppingCarComponent } from './components/prev-shopping-car/prev-shopping-car.component';
import { ProductCommentComponent } from './components/product-comment/product-comment.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ProductGeneralCommentComponent } from './components/product-general-comment/product-general-comment.component';
import { ProductMyCommentsComponent } from './components/product-my-comments/product-my-comments.component';
import { PromoDetailComponent } from './components/promo-detail/promo-detail.component';
import { PromoResultComponent } from './components/promo-result/promo-result.component';
import { RatingComponent } from './components/rating/rating.component';
import { RegisterAdditionalInfoComponent } from './components/register-additional-info/register-additional-info.component';
import { RegisterAddressComponent } from './components/register-address/register-address.component';
import { RegisterContactEmailComponent } from './components/register-contact-email/register-contact-email.component';
import { RegisterContactNumberComponent } from './components/register-contact-number/register-contact-number.component';
import { RegisterContactComponent } from './components/register-contact/register-contact.component';
import { RegisterDirectionComponent } from './components/register-direction/register-direction.component';
import { RegisterMethodComponent } from './components/register-method/register-method.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { SelectDeliveryAddressComponent } from './components/select-delivery-address/select-delivery-address.component';
import { SelectDeliveryTimeComponent } from './components/select-delivery-time/select-delivery-time.component';
import { SelectProductsComponent } from './components/select-products/select-products.component';
import { ShoppingCarDeliveryComponent } from './components/shopping-car-delivery/shopping-car-delivery.component';
import { ShoppingCarPaymentComponent } from './components/shopping-car-payment/shopping-car-payment.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { SubFamiliesSelectHierarchyComponent } from './components/sub-families-select-hierarchy/sub-families-select-hierarchy.component';
import { TermsComponent } from './components/terms/terms.component';
import {
  TimelineComponent,
  TimelineItemComponent,
  TimelineTimeComponent,
} from './components/timeline/timeline.component';
import { InfoTripsPopComponent } from './components/trip-components/info-trips-pop/info-trips-pop.component';
import { InfoVehiclePopComponent } from './components/trip-components/info-vehicle-pop/info-vehicle-pop.component';
import { NotaTripComponent } from './components/trip-components/nota-trip/nota-trip.component';
import { ParadaTripComponent } from './components/trip-components/parada-trip/parada-trip.component';
import { RegisterTripComponent } from './components/trip-components/register-trip/register-trip.component';
import { ShowImageComponent } from './components/trip-components/show-image/show-image.component';
import { SpendTripComponent } from './components/trip-components/spend-trip/spend-trip.component';
import { TutorialVideoComponent } from './components/tutorial-video/tutorial-video.component';
import { TutorialsComponent } from './components/tutorials/tutorials.component';
import { UpdateQuantityComponent } from './components/update-quantity/update-quantity.component';
import { UseTermsComponent } from './components/use-terms/use-terms.component';
import { ValidateClientComponent } from './components/validate-client/validate-client.component';
import { VendorAlertComponent } from './components/vendor-alert/vendor-alert.component';
import { VendorRecordComponent } from './components/vendor-record/vendor-record.component';
import { VendorRegisterAddressComponent } from './components/vendor-register-address/vendor-register-address.component';
import { VendorRegisterContactComponent } from './components/vendor-register-contact/vendor-register-contact.component';
import { VendorRegisterDocumentComponent } from './components/vendor-register-document/vendor-register-document.component';
import { VendorRegisterGeneralComponent } from './components/vendor-register-general/vendor-register-general.component';
import { VendorRegisterLanguageComponent } from './components/vendor-register-language/vendor-register-language.component';
import { VendorRegisterPaymentComponent } from './components/vendor-register-payment/vendor-register-payment.component';
import { VendorRegisterComponent } from './components/vendor-register/vendor-register.component';
import { AssetAuditPage } from './pages/asset-audit/asset-audit.component';
import { AssetCertificationApprovalPage } from './pages/asset-certification-approval/asset-certification-approval.component';
import { AssetCertificationPage } from './pages/asset-certification/asset-certification.component';
import { AuthPage } from './pages/auth/auth.component';
import { GetPositionVendorPage } from './pages/get-position-vendor/get-position-vendor.component';
import { GetPositionPage } from './pages/get-position/get-position.component';
import { HomePage } from './pages/home/home.component';
import { LoginPage } from './pages/login/login.page.component';
import { MainPagePage } from './pages/main-page/main-page.page.component';
import { MapTripPage } from './pages/map-trip/map-trip.component';
import { PurchOrderConfirmPage } from './pages/purch-order-confirm/purch-order-confirm.component';
import { PurchOrderLinePage } from './pages/purch-order-line/purch-order-line.component';
import { PurchOrderRecordPage } from './pages/purch-order-record/purch-order-record.component';
import { PurchOrderToApprovedPage } from './pages/purch-order-to-approved/purch-order-to-approved.component';
import { PurchOrderToBeApprovedPage } from './pages/purch-order-to-be-approved/purch-order-to-be-approved.component';
import { PurchOrderToBeConfirmedPage } from './pages/purch-order-to-be-confirmed/purch-order-to-be-confirmed.component';
import { PurchOrderPage } from './pages/purch-order/purch-order.component';
import { TripRegisterPage } from './pages/trip-register/trip-register.component';

const routes: Routes = [
  {
    path: NavigationRoutes.ROOT_PAGE,
    redirectTo: NavigationRoutes.FamiliesComponent,
    pathMatch: 'full',
  },
  {
    path: NavigationRoutes.FamiliesComponent,
    component: FamiliesComponent,
  },
  {
    path: NavigationRoutes.AppComponent,
    component: AppComponent,
  },
  {
    path: NavigationRoutes.AddCommentComponent,
    component: AddCommentComponent,
  },
  {
    path: NavigationRoutes.AddFindingComponent,
    component: AddFindingComponent,
  },
  {
    path: NavigationRoutes.AddToCartComponent,
    component: AddToCartComponent,
  },
  {
    path: NavigationRoutes.AddToListComponent,
    component: AddToListComponent,
  },
  {
    path: NavigationRoutes.AssetAcceptanceComponent,
    component: AssetAcceptanceComponent,
  },
  {
    path: NavigationRoutes.AssetAuditAddComponent,
    component: AssetAuditAddComponent,
  },
  {
    path: NavigationRoutes.AssetAuditRecordComponent,
    component: AssetAuditRecordComponent,
  },
  {
    path: NavigationRoutes.AssetAuditUnassignedAssetComponent,
    component: AssetAuditUnassignedAssetComponent,
  },
  {
    path: NavigationRoutes.AssetCertificationPeriodicityComponent,
    component: AssetCertificationPeriodicityComponent,
  },
  {
    path: NavigationRoutes.AssetCertificationPopoverComponent,
    component: AssetCertificationPopoverComponent,
  },
  {
    path: NavigationRoutes.AssetFilterComponent,
    component: AssetFilterComponent,
  },
  {
    path: NavigationRoutes.AssetRequestActionComponent,
    component: AssetRequestActionComponent,
  },
  {
    path: NavigationRoutes.AssetRequestFilterComponent,
    component: AssetRequestFilterComponent,
  },
  {
    path: NavigationRoutes.AssetRequestListComponent,
    component: AssetRequestListComponent,
  },
  {
    path: NavigationRoutes.AssetsApprovalComponent,
    component: AssetsApprovalComponent,
  },
  {
    path: NavigationRoutes.AssetsTransferComponent,
    component: AssetsTransferComponent,
  },
  {
    path: NavigationRoutes.CertFilterComponent,
    component: CertFilterComponent,
  },
  {
    path: NavigationRoutes.CertListSelectedComponent,
    component: CertListSelectedComponent,
  },
  {
    path: NavigationRoutes.CheckListAnswerOmiComponent,
    component: CheckListAnswerOmiComponent,
  },
  {
    path: NavigationRoutes.CheckListTaskComponent,
    component: CheckListTaskComponent,
  },
  {
    path: NavigationRoutes.NoteTaskComponent,
    component: NoteTaskComponent,
  },
  {
    path: NavigationRoutes.ContactComponent,
    component: ContactComponent,
  },
  {
    path: NavigationRoutes.FamiliesHomeComponent,
    component: FamiliesHomeComponent,
  },
  {
    path: NavigationRoutes.FamiliesSelectHierarchyComponent,
    component: FamiliesSelectHierarchyComponent,
  },
  {
    path: NavigationRoutes.FilterComponent,
    component: FilterComponent,
  },
  {
    path: NavigationRoutes.GeneralDividerComponent,
    component: GeneralDividerComponent,
  },
  {
    path: NavigationRoutes.GetData2CreateComponent,
    component: GetData2CreateComponent,
  },
  {
    path: NavigationRoutes.GetData2EditComponent,
    component: GetData2EditComponent,
  },
  {
    path: NavigationRoutes.GetMapPositionComponent,
    component: GetMapPositionComponent,
  },
  {
    path: NavigationRoutes.GoogleMapComponent,
    component: GoogleMapComponent,
  },
  {
    path: NavigationRoutes.InsertPasswordComponent,
    component: InsertPasswordComponent,
  },
  {
    path: NavigationRoutes.LogoScreenComponent,
    component: LogoScreenComponent,
  },
  {
    path: NavigationRoutes.MyCustomListComponent,
    component: MyCustomListComponent,
  },
  {
    path: NavigationRoutes.MyCustomListProductsComponent,
    component: MyCustomListProductsComponent,
  },
  {
    path: NavigationRoutes.MyPaymentComponent,
    component: MyPaymentComponent,
  },
  {
    path: NavigationRoutes.MyProfileAddcaseComponent,
    component: MyProfileAddcaseComponent,
  },
  {
    path: NavigationRoutes.MyProfileCasemanagementComponent,
    component: MyProfileCasemanagementComponent,
  },
  {
    path: NavigationRoutes.MyProfileContactsComponent,
    component: MyProfileContactsComponent,
  },
  {
    path: NavigationRoutes.MyProfileCreateRestrictionComponent,
    component: MyProfileCreateRestrictionComponent,
  },
  {
    path: NavigationRoutes.MyProfileDirectionsComponent,
    component: MyProfileDirectionsComponent,
  },
  {
    path: NavigationRoutes.MyProfileEditEmailComponent,
    component: MyProfileEditEmailComponent,
  },
  {
    path: NavigationRoutes.MyProfileEditLatlngComponent,
    component: MyProfileEditLatlngComponent,
  },
  {
    path: NavigationRoutes.MyProfileEditPhoneComponent,
    component: MyProfileEditPhoneComponent,
  },
  {
    path: NavigationRoutes.MyProfileGeneralInfoComponent,
    component: MyProfileGeneralInfoComponent,
  },
  {
    path: NavigationRoutes.MyProfileRestrictionsComponent,
    component: MyProfileRestrictionsComponent,
  },
  {
    path: NavigationRoutes.MyProfileUpdatepassComponent,
    component: MyProfileUpdatepassComponent,
  },
  {
    path: NavigationRoutes.MyPromoListComponent,
    component: MyPromoListComponent,
  },
  {
    path: NavigationRoutes.MenuNavBarComponent,
    component: NavBarComponent,
  },
  {
    path: NavigationRoutes.NewContactComponent,
    component: NewContactComponent,
  },
  {
    path: NavigationRoutes.NewDirectionComponent,
    component: NewDirectionComponent,
  },
  {
    path: NavigationRoutes.NewListComponent,
    component: NewListComponent,
  },
  {
    path: NavigationRoutes.NewRestrictionComponent,
    component: NewRestrictionComponent,
  },
  {
    path: NavigationRoutes.EntregaOcComponent,
    component: EntregaOcComponent,
  },
  {
    path: NavigationRoutes.GeneralParameterOcComponent,
    component: GeneralParameterOcComponent,
  },
  {
    path: NavigationRoutes.HistorialOcComponent,
    component: HistorialOcComponent,
  },
  {
    path: NavigationRoutes.InfoFileOcPopComponent,
    component: InfoFileOcPopComponent,
  },
  {
    path: NavigationRoutes.InfoFilterOcPopComponent,
    component: InfoFilterOcPopComponent,
  },
  {
    path: NavigationRoutes.InfoPlusOcComponent,
    component: InfoPlusOcComponent,
  },
  {
    path: NavigationRoutes.InputNumberComponent,
    component: InputNumberComponent,
  },
  {
    path: NavigationRoutes.LineOcComponent,
    component: LineOcComponent,
  },
  {
    path: NavigationRoutes.LineaInfoOcComponent,
    component: LineaInfoOcComponent,
  },
  {
    path: NavigationRoutes.NotaOcComponent,
    component: NotaOcComponent,
  },
  {
    path: NavigationRoutes.PopInfoOcComponent,
    component: PopInfoOcComponent,
  },
  {
    path: NavigationRoutes.RejectedNoteOCComponent,
    component: RejectedNoteOCComponent,
  },
  {
    path: NavigationRoutes.ResumenOcVendorComponent,
    component: ResumenOcVendorComponent,
  },
  {
    path: NavigationRoutes.SearchProviderComponent,
    component: SearchProviderComponent,
  },
  {
    path: NavigationRoutes.SendMultipleOCComponent,
    component: SendMultipleOCComponent,
  },
  {
    path: NavigationRoutes.ToBeApprovedOcComponent,
    component: ToBeApprovedOcComponent,
  },
  {
    path: NavigationRoutes.ToBeConfirmedOcComponent,
    component: ToBeConfirmedOcComponent,
  },
  {
    path: NavigationRoutes.TotalesOcComponent,
    component: TotalesOcComponent,
  },
  {
    path: NavigationRoutes.UpdateFinancialDimensionComponent,
    component: UpdateFinancialDimensionComponent,
  },
  {
    path: NavigationRoutes.UpdateQtyOCComponent,
    component: UpdateQtyOCComponent,
  },
  {
    path: NavigationRoutes.OrderConfirmationComponent,
    component: OrderConfirmationComponent,
  },
  {
    path: NavigationRoutes.OrderConfirmationInfoComponent,
    component: OrderConfirmationInfoComponent,
  },
  {
    path: NavigationRoutes.OrderConfirmationPassComponent,
    component: OrderConfirmationPassComponent,
  },
  {
    path: NavigationRoutes.OrderHistoryComponent,
    component: OrderHistoryComponent,
  },
  {
    path: NavigationRoutes.OrderTraceComponent,
    component: OrderTraceComponent,
  },
  {
    path: NavigationRoutes.OrderTraceLinesComponent,
    component: OrderTraceLinesComponent,
  },
  {
    path: NavigationRoutes.OrderTraceProgressComponent,
    component: OrderTraceProgressComponent,
  },
  {
    path: NavigationRoutes.PaymentFormComponent,
    component: PaymentFormComponent,
  },
  {
    path: NavigationRoutes.PaymentStatusComponent,
    component: PaymentStatusComponent,
  },
  {
    path: NavigationRoutes.PrevShoppingCarComponent,
    component: PrevShoppingCarComponent,
  },
  {
    path: NavigationRoutes.ProductCommentComponent,
    component: ProductCommentComponent,
  },
  {
    path: NavigationRoutes.ProductDetailComponent,
    component: ProductDetailComponent,
  },
  {
    path: NavigationRoutes.ProductGeneralCommentComponent,
    component: ProductGeneralCommentComponent,
  },
  {
    path: NavigationRoutes.ProductMyCommentsComponent,
    component: ProductMyCommentsComponent,
  },
  {
    path: NavigationRoutes.PromoDetailComponent,
    component: PromoDetailComponent,
  },
  {
    path: NavigationRoutes.PromoResultComponent,
    component: PromoResultComponent,
  },
  {
    path: NavigationRoutes.RegisterAdditionalInfoComponent,
    component: RegisterAdditionalInfoComponent,
  },
  {
    path: NavigationRoutes.RegisterAddressComponent,
    component: RegisterAddressComponent,
  },
  {
    path: NavigationRoutes.RegisterContactComponent,
    component: RegisterContactComponent,
  },
  {
    path: NavigationRoutes.RegisterContactEmailComponent,
    component: RegisterContactEmailComponent,
  },
  {
    path: NavigationRoutes.RegisterContactNumberComponent,
    component: RegisterContactNumberComponent,
  },
  {
    path: NavigationRoutes.RegisterDirectionComponent,
    component: RegisterDirectionComponent,
  },
  {
    path: NavigationRoutes.RegisterMethodComponent,
    component: RegisterMethodComponent,
  },
  {
    path: NavigationRoutes.ResetPasswordComponent,
    component: ResetPasswordComponent,
  },
  {
    path: NavigationRoutes.OrderRetHistoryComponent,
    component: OrderRetHistoryComponent,
  },
  {
    path: NavigationRoutes.RetAddItemComponent,
    component: RetAddItemComponent,
  },
  {
    path: NavigationRoutes.RetBoughtItemComponent,
    component: RetBoughtItemComponent,
  },
  {
    path: NavigationRoutes.RetCarComponent,
    component: RetCarComponent,
  },
  {
    path: NavigationRoutes.RetDeliveryCarComponent,
    component: RetDeliveryCarComponent,
  },
  {
    path: NavigationRoutes.RetProductHistoryComponent,
    component: RetProductHistoryComponent,
  },
  {
    path: NavigationRoutes.SearchableSelectComponent,
    component: SearchableSelectComponent,
  },
  {
    path: NavigationRoutes.SelectDeliveryAddressComponent,
    component: SelectDeliveryAddressComponent,
  },
  {
    path: NavigationRoutes.SelectDeliveryTimeComponent,
    component: SelectDeliveryTimeComponent,
  },
  {
    path: NavigationRoutes.SelectProductsComponent,
    component: SelectProductsComponent,
  },
  {
    path: NavigationRoutes.ShoppingCarDeliveryComponent,
    component: ShoppingCarDeliveryComponent,
  },
  {
    path: NavigationRoutes.ShoppingCarPaymentComponent,
    component: ShoppingCarPaymentComponent,
  },
  {
    path: NavigationRoutes.ShoppingCartComponent,
    component: ShoppingCartComponent,
  },
  {
    path: NavigationRoutes.SubFamiliesSelectHierarchyComponent,
    component: SubFamiliesSelectHierarchyComponent,
  },
  {
    path: NavigationRoutes.TermsComponent,
    component: TermsComponent,
  },
  {
    path: NavigationRoutes.TimelineComponent,
    component: TimelineComponent,
  },
  {
    path: NavigationRoutes.TimelineItemComponent,
    component: TimelineItemComponent,
  },
  {
    path: NavigationRoutes.TimelineTimeComponent,
    component: TimelineTimeComponent,
  },
  {
    path: NavigationRoutes.InfoTripsPopComponent,
    component: InfoTripsPopComponent,
  },
  {
    path: NavigationRoutes.InfoVehiclePopComponent,
    component: InfoVehiclePopComponent,
  },
  {
    path: NavigationRoutes.NotaTripComponent,
    component: NotaTripComponent,
  },
  {
    path: NavigationRoutes.ParadaTripComponent,
    component: ParadaTripComponent,
  },
  {
    path: NavigationRoutes.RegisterTripComponent,
    component: RegisterTripComponent,
  },
  {
    path: NavigationRoutes.ShowImageComponent,
    component: ShowImageComponent,
  },
  {
    path: NavigationRoutes.SpendTripComponent,
    component: SpendTripComponent,
  },
  {
    path: NavigationRoutes.TutorialVideoComponent,
    component: TutorialVideoComponent,
  },
  {
    path: NavigationRoutes.TutorialsComponent,
    component: TutorialsComponent,
  },
  {
    path: NavigationRoutes.UpdateQuantityComponent,
    component: UpdateQuantityComponent,
  },
  {
    path: NavigationRoutes.UseTermsComponent,
    component: UseTermsComponent,
  },
  {
    path: NavigationRoutes.ValidateClientComponent,
    component: ValidateClientComponent,
  },
  {
    path: NavigationRoutes.VendorAlertComponent,
    component: VendorAlertComponent,
  },
  {
    path: NavigationRoutes.VendorRecordComponent,
    component: VendorRecordComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterComponent,
    component: VendorRegisterComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterAddressComponent,
    component: VendorRegisterAddressComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterContactComponent,
    component: VendorRegisterContactComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterDocumentComponent,
    component: VendorRegisterDocumentComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterGeneralComponent,
    component: VendorRegisterGeneralComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterLanguageComponent,
    component: VendorRegisterLanguageComponent,
  },
  {
    path: NavigationRoutes.VendorRegisterPaymentComponent,
    component: VendorRegisterPaymentComponent,
  },
  {
    path: NavigationRoutes.EinvoiceFilterComponent,
    component: EinvoiceFilterComponent,
  },
  {
    path: NavigationRoutes.MessageBoxComponent,
    component: MessageBoxComponent,
  },
  {
    path: NavigationRoutes.PurchOrderEinvoiceFilterComponent,
    component: PurchOrderEinvoiceFilterComponent,
  },
  {
    path: NavigationRoutes.AssignEinvoicePage,
    component: AssignEinvoicePage,
  },
  {
    path: NavigationRoutes.AssignPurchOrderPage,
    component: AssignPurchOrderPage,
  },
  {
    path: NavigationRoutes.InvoiceLinesPage,
    component: InvoiceLinesPage,
  },
  {
    path: NavigationRoutes.InvoiceListPage,
    component: InvoiceListPage,
  },
  {
    path: NavigationRoutes.AssetAuditPage,
    component: AssetAuditPage,
  },
  {
    path: NavigationRoutes.AssetCertificationPage,
    component: AssetCertificationPage,
  },
  {
    path: NavigationRoutes.AssetCertificationApprovalPage,
    component: AssetCertificationApprovalPage,
  },
  {
    path: NavigationRoutes.AuthPage,
    component: AuthPage,
  },
  {
    path: NavigationRoutes.ChecklistInstancePage,
    component: ChecklistInstancePage,
  },
  {
    path: NavigationRoutes.ChecklistTaskPage,
    component: ChecklistTaskPage,
  },
  {
    path: NavigationRoutes.GetPositionPage,
    component: GetPositionPage,
  },
  {
    path: NavigationRoutes.GetPositionVendorPage,
    component: GetPositionVendorPage,
  },
  {
    path: NavigationRoutes.HomePage,
    component: HomePage,
  },
  {
    path: NavigationRoutes.LoginPage,
    component: LoginPage,
  },
  {
    path: NavigationRoutes.MainPagePage,
    component: MainPagePage,
  },
  {
    path: NavigationRoutes.MapTripPage,
    component: MapTripPage,
  },
  {
    path: NavigationRoutes.PurchOrderPage,
    component: PurchOrderPage,
  },
  {
    path: NavigationRoutes.PurchOrderConfirmPage,
    component: PurchOrderConfirmPage,
  },
  {
    path: NavigationRoutes.PurchOrderLinePage,
    component: PurchOrderLinePage,
  },
  {
    path: NavigationRoutes.PurchOrderRecordPage,
    component: PurchOrderRecordPage,
  },
  {
    path: NavigationRoutes.PurchOrderToApprovedPage,
    component: PurchOrderToApprovedPage,
  },
  {
    path: NavigationRoutes.PurchOrderToBeApprovedPage,
    component: PurchOrderToBeApprovedPage,
  },
  {
    path: NavigationRoutes.PurchOrderToBeConfirmedPage,
    component: PurchOrderToBeConfirmedPage,
  },
  {
    path: NavigationRoutes.TripRegisterPage,
    component: TripRegisterPage,
  },
  {
    path: NavigationRoutes.ConfigLinePurchRemissionComponent,
    component: ConfigLinePurchRemissionComponent,
  },
  {
    path: NavigationRoutes.PurchOrderRemissionFilterComponent,
    component: PurchOrderRemissionFilterComponent,
  },
  {
    path: NavigationRoutes.PurchRemissionInfoComponent,
    component: PurchRemissionInfoComponent,
  },
  {
    path: NavigationRoutes.SelectFilterComponent,
    component: SelectFilterComponent,
  },
  {
    path: NavigationRoutes.SelectObjectsComponent,
    component: SelectObjectsComponent,
  },
  {
    path: NavigationRoutes.ShowConversionItemComponent,
    component: ShowConversionItemComponent,
  },
  {
    path: NavigationRoutes.RemissionLinesPage,
    component: RemissionLinesPage,
  },
  {
    path: NavigationRoutes.RemissionListPage,
    component: RemissionListPage,
  },
  {
    path: NavigationRoutes.RatingComponent,
    component: RatingComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
