export class IdentifierType {
  constructor(
    private id: string,
    private codCategory: string,
    private name: string,
    private mask: string,
    private length: number
  ) {}

  getId() {
    return this.id;
  }

  getCodCategory() {
    return this.codCategory;
  }

  getName() {
    return this.name;
  }

  getMask() {
    return this.mask;
  }

  getLength() {
    return this.length;
  }

  setValues(newData: IdentifierType) {
    this.id = newData.getId();
    this.codCategory = newData.getCodCategory();
    this.name = newData.getName();
    this.mask = newData.getMask();
    this.length = newData.getLength();
  }
}
