<div
  style="
    text-align: center;
    margin-top: 130px;
    background-color: white;
    color: black;
  "
>
  <h1 style="font-weight: bold; margin-bottom: 50px">
    {{ alertVendLg.title }}
  </h1>
  <!-- <div style="margin-top: 20px"> </div> -->
  <ion-spinner name="dots"></ion-spinner>
  <!-- <div style="margin-bottom: 20px"> </div> -->
  <!-- <h2><span style="background-color: black; color: white;">Paso {{step}} de 4</span></h2> -->
  <h2 style="margin-top: 50px">{{ step }}/4</h2>
  <h2 style="margin-top: 50px">{{ alertVendLg.save }} {{ stepTxt }}...</h2>
</div>
