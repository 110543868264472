import { Component } from '@angular/core';
import {
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NewRestrictionComponent } from '../new-restriction/new-restriction.component';

import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { CaseCategoryType } from './../../businessCore/CaseCategoryType';
import { CustomerAddress } from './../../businessCore/CustomerAddress';
import { CustomerData } from './../../businessCore/CustomerData';
import { Direction } from './../../businessCore/Direction';
import { RegisterData } from './../../businessCore/RegisterData';
import { RestrictionData } from './../../businessCore/RestrictionData';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-my-profile-create-restriction',
  templateUrl: './my-profile-create-restriction.component.html',
  styleUrls: ['./my-profile-create-restriction.component.scss'],
})
export class MyProfileCreateRestrictionComponent {
  title: string = '';
  imgUrl: string = '';
  customerInfoError: boolean = true;
  userEmailChecked: boolean = true;
  userEmail2Checked: boolean = false;
  loadingCustomerInfo: boolean = true;
  register_data: RegisterData = new RegisterData();
  customer_info: CustomerData = new CustomerData();
  addressInfo: { address: CustomerAddress; checked: boolean }[] = [];
  caseCategoryType: CaseCategoryType = new CaseCategoryType();

  direction: Direction = new Direction();

  /**
   *
   * @param navParams
   * @param storage
   * @param _apiProvider
   */
  constructor(
    public navParams: NavParams,
    public storage: StorageService,
    public _apiProvider: webServiceProvider,
    public toastCtrl: ToastController,
    public nav: NavController,
    public popoverCtrl: PopoverController
  ) {
    this.title = this.navParams.get('title');
    this.imgUrl = this.navParams.get('imgUrl');
    this.caseCategoryType = this.navParams.get('caseCategoryType');

    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
        this._apiProvider
          .getCustomer(this.register_data.id_card)
          .subscribe((res) => {
            let data = res as Array<CustomerData>;
            if (data && data.length > 0 && data[0]) {
              this.customerInfoError = false;
              this.customer_info = data[0] as CustomerData;

              this.formatCustomerAddresses(
                this.customer_info.CustomerAddresses
              );
            } else {
              this.customerInfoError = true;
            }
            this.loadingCustomerInfo = false;
          });
      } else {
        this.customerInfoError = true;
        this.loadingCustomerInfo = false;
      }
    });
  }

  /**
   *
   */
  formatCustomerAddresses(customerAddresses: CustomerAddress[]) {
    let checked = true;
    customerAddresses.forEach((address) => {
      this.addressInfo.push({ address: address, checked: checked });
      checked = false;
    });
  }

  /**
   *
   * @param addressItem
   */
  checkItem(addressItem: { address: CustomerAddress; checked: boolean }) {
    let itemIndex = this.addressInfo.indexOf(addressItem);
    this.addressInfo.forEach((element, index) => {
      element.checked = false;
      if (index == itemIndex) {
        element.checked = true;
      }
    });
  }

  /**
   *
   */
  async addRestriction() {
    let addressSelected = this.addressInfo.filter(
      (data) => data.checked == true
    );
    if (addressSelected.length == 1) {
      // Hay que cambiar AccountNum por Email, cuando el campo se devuelva en el Web Service

      let restriction = new RestrictionData();
      restriction.edit = false;

      const popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: NewRestrictionComponent,
        componentProps: { restriction: restriction },
      });

      popover.present();

      popover.onDidDismiss().then((res) => {
        let data = res.data;
        if (data != null) {
          let notes =
            'Dir= ' +
            addressSelected[0].address.AddressName +
            ' | Restricción= ' +
            this.restrictionToString(data as RestrictionData);
          let description = 'Agregar restricción HE';

          this.sendCase(notes, description);
        }
      });
    } else {
      this.presentToast('Debe seleccionar solamente una dirección.');
    }
  }

  /**
   *
   * @param res
   */
  restrictionToString(res: RestrictionData) {
    return (
      this.get_day_value(res.day) +
      ' ' +
      res.get_type_value(res.type) +
      this.getRestrictionHours(res)
    );
  }

  /**
   *
   * @param daysArray
   */
  public get_day_value(daysArray) {
    const available_days = [
      { id: 0, value: 'Lunes' },
      { id: 1, value: 'Martes' },
      { id: 2, value: 'Miércoles' },
      { id: 3, value: 'Jueves' },
      { id: 4, value: 'Viernes' },
      { id: 5, value: 'Sábado' },
    ];

    let daysResult = '';

    // Si el usuario marco todos los dias
    if (daysArray.length == 6) {
      daysResult = 'Todos los días';
    } else {
      daysArray.forEach((day) => {
        available_days.forEach((element) => {
          if (element.id == parseInt(day)) {
            if (daysResult.length == 0) {
              daysResult = element.value;
            } else {
              daysResult = daysResult + ', ' + element.value;
            }
          }
        });
      });
    }
    return daysResult;
  }

  /**
   *
   * @param restriction
   */
  getRestrictionHours(restriction: RestrictionData) {
    let hours_date1 = restriction.getHours(restriction.date1);
    let hours_date2 = restriction.getHours(restriction.date2);

    if (restriction.type == 0 || restriction.type == 1) {
      return '(' + hours_date1[0] + ':' + hours_date1[1] + ')';
    }
    return (
      '(' +
      hours_date1[0] +
      ':' +
      hours_date1[1] +
      ' - ' +
      hours_date2[0] +
      ':' +
      hours_date2[1] +
      ')'
    );
  }

  /**
   *
   * @param notes
   * @param description
   */
  sendCase(notes: string, description: string) {
    let caseId = new Date().getTime().toString();
    let categoryId = this.caseCategoryType.RecId;
    let customerId = this.register_data.customerId;
    let ownerWorker = this.caseCategoryType.OwnerWorker;

    this._apiProvider
      .InsertCaseDetail(
        caseId,
        description,
        categoryId,
        customerId,
        ownerWorker
      )
      .subscribe((res) => {
        let data = res as string;
        if (data && data.length > 0) {
          let response = (data as string).split(',');
          if (response.length == 2) {
            this.presentToast('Solicitud creada con éxito!');
            let caseRecId = +response[1]; // revisar si el campo llega en null

            this._apiProvider
              .InsertCaseLog(caseRecId, description, notes)
              .subscribe(() => {});

            // entityType = 3 => cliente
            // entityType = 5 => prospectos
            // entityType = 7 => OVs
            // entityType = 11 => productos
            let entityType = 3;

            this._apiProvider
              .InsertCaseAssociation(caseRecId, entityType, customerId)
              .subscribe(() => {});
          } else {
            this.presentToast('La solicitud no se pudo crear!');
          }
        } else {
          this.presentToast('La solicitud no se pudo crear!');
        }
      });
  }

  /**
   *
   * @param msg
   */
  async presentToast(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: ['Ok'],
    });
    toast.present();
  }
}
