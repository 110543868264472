import { PromoConditionsView } from './PromoConditionsView';
import { PromoDateLineView } from './PromoDateLineView';
import { PromoFeaturesHistoryView } from './PromoFeaturesHistoryView';
import { PromoResultsViews } from './PromoResultsViews';

export class PromoTableView {
  PromoId: string = '';
  Description: string = '';
  Name: string = '';
  Times: number = 0;

  ValidProductConditions: boolean = true;

  PromoConditions: PromoConditionsView[] = [];
  PromoResults: PromoResultsViews[] = [];
  PromoDateLineViews: PromoDateLineView[] = [];
  PromoFeaturesHistoryViews: PromoFeaturesHistoryView[] = [];

  expression: string = '';
  lockedByIpPonderation: boolean = false;

  eval(expressionReduced: string): boolean {
    let caracteres: string[] = expressionReduced.split('');
    let grupo: string[] = [];
    let grupos: string[] = [];
    let groupClosed: boolean = true;

    caracteres.forEach((caractere) => {
      if (caractere == '(') {
        groupClosed = false;
      } else if (caractere == ')') {
        grupos.push(this.evalGroup(grupo));
        grupo = [];
        groupClosed = true;
      } else if (groupClosed) {
        grupos.push(caractere.toString());
      } else {
        grupo.push(caractere.toString());
      }
    });

    return this.evalGroup(grupos) == '1';
  }

  evalGroup(lista: string[]): string {
    if (lista.length == 1) {
      return lista[0];
    }

    while (lista.indexOf('&') > -1) {
      let index: number = lista.lastIndexOf('&');
      let result: boolean =
        lista[index - 1] == '1' && lista[index + 1] == '1' ? true : false;
      lista.splice(index + 1, 1);
      lista.splice(index, 1);
      lista.splice(index - 1, 1);

      // Add new Item into a specific index
      lista.splice(index - 1, 0, result ? '1' : '0');
    }
    while (lista.length > 1) {
      let result: boolean = lista[0] == '1' || lista[2] == '1' ? true : false;
      lista.splice(0, 3);
      lista.splice(0, 0, result ? '1' : '0');
    }

    return lista[0];
  }

  /*
    Actual: Como va la expresión formada
    LastGroup: # de Group del miembro n-1 del array (cuidado con OutOfRangeError)
    ActualGrupo: # de Group del miembro n del array
    nextGrupo: # de Group del miembro n+1 del array (cuidado con OutOfRangeError)
    ConditionOr:  AND o OR
    BooleanValue: TRUE o FALSE
    isLast: muestra si el elemento n es el ultimo del array
     */
  buildExpression(
    Actual: string,
    lastGroup: number,
    ActualGroup: number,
    nextGroup: number,
    ConditionOr: string,
    BooleanValue: string,
    isLast: boolean
  ): string {
    return (
      Actual +
      (lastGroup != ActualGroup ? ' ( ' : '') +
      BooleanValue +
      (nextGroup != ActualGroup && !isLast ? ' ) ' + ConditionOr : '') +
      (isLast ? ' ) ' : '') +
      (nextGroup == ActualGroup && !isLast ? ConditionOr : '')
    );
  }

  // buildExpression(expression,
  //     i==0?feature.getGroup()-1:features.get(i-1).getGroup(),
  //     feature.getGroup(),
  //     i==features.size()-1?feature.getGroup()+1:features.get(i+1).getGroup(),
  //     feature.getConditionOr()==0?" & ": " | ",
  // condition?" 1 ":" 0 ",
  // i==features.size()-1);

  // CASO DE IP MAYOR O IGUAL
  // case 3:
  //     condition=feature.getValueTransaction()>=this.encabezado.getIpPonderation();
  //     expression = buildExpression(expression,
  //             i==0?feature.getGroup()-1:features.get(i-1).getGroup(),
  //             feature.getGroup(),
  //             i==features.size()-1?feature.getGroup()+1:features.get(i+1).getGroup(),
  //             feature.getConditionOr()==0?" & ": " | ",
  //             condition?" 1 ":" 0 ",
  //             i==features.size()-1);
  //     break;
}
