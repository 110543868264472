<div style="margin-top: 1rem; margin-bottom: 1rem; text-align: center">
  <h3 style="margin-top: 0; margin-bottom: 0; display: inline">
    {{ thirdAddressLang.secondTitle }}
  </h3>
  <div class="languageTag" style="display: inline"></div>
</div>

<ion-item class="madeUp-select">
  <div item-content class="forInputs" style="width: 100%">
    <ion-label style="width: 100%">
      <h2 class="title-info required">{{ thirdAddressLang.country }}</h2>
    </ion-label>
    <ion-input
      [(ngModel)]="addressData.countryCode"
      required="true"
    ></ion-input>
  </div>
</ion-item>

<ion-item class="madeUp-select">
  <div item-content class="forInputs" style="width: 100%">
    <ion-label style="width: 100%">
      <h2 class="title-info required">{{ thirdAddressLang.state }}</h2>
    </ion-label>
    <ion-input [(ngModel)]="addressData.region" required="true"></ion-input>
  </div>
</ion-item>

<ion-item class="madeUp-select">
  <div item-content class="forInputs" style="width: 100%">
    <ion-label style="width: 100%">
      <h2 class="title-info required">{{ thirdAddressLang.county }}</h2>
    </ion-label>
    <ion-input [(ngModel)]="addressData.subregion" required="true"></ion-input>
  </div>
</ion-item>

<ion-item class="madeUp-select">
  <div item-content class="forInputs" style="width: 100%">
    <ion-label style="width: 100%">
      <h2 class="title-info required">{{ thirdAddressLang.city }}</h2>
    </ion-label>
    <ion-input [(ngModel)]="addressData.city" required="true"></ion-input>
  </div>
</ion-item>

<ion-item class="madeUp-select">
  <div item-content class="forInputs" style="width: 100%">
    <ion-label style="width: 100%">
      <h2 class="title-info required">{{ thirdAddressLang.streetAddress }}</h2>
    </ion-label>
    <ion-input [(ngModel)]="addressData.address" required="true"></ion-input>
  </div>
</ion-item>

<ion-item class="madeUp-select">
  <div item-content class="forInputs" style="width: 100%">
    <ion-label style="width: 100%">
      <h2 class="title-info required">{{ thirdAddressLang.name }}</h2>
      <p style="font-size: small; color: red">{{ thirdAddressLang.egNote }}</p>
    </ion-label>
    <ion-input
      [(ngModel)]="addressData.addressName"
      required="true"
    ></ion-input>
  </div>
</ion-item>

<br />

<div class="centerDiv">
  <ion-button
    ion-button
    class="addAddressBtn"
    (click)="sendAddress()"
    [disabled]="disableAdd()"
  >
    {{ thirdAddressLang.addBtn }}
  </ion-button>
</div>
