import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Money } from '../../GeneralUtilis/money';
import { CreditCardPayment } from '../../businessCore/CreditCardPayment';
import { RegisterData } from '../../businessCore/RegisterData';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-my-payment',
  templateUrl: './my-payment.component.html',
  styleUrls: ['./my-payment.component.scss'],
})
export class MyPaymentComponent {
  hasPayments: boolean = false;
  register_data: RegisterData = new RegisterData();
  paymentList: CreditCardPayment[] = [];

  constructor(
    public toastCtrl: ToastController,
    public apiProvider: webServiceProvider,
    public storage: StorageService
  ) {
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;

        this.getPayments();
      }
    });
  }

  getPayments() {
    if (this.register_data.id_card != null) {
      this.apiProvider
        .getCreditCardPayment(this.register_data.id_card)
        .subscribe((data) => {
          if (data != null) {
            this.paymentList = data as CreditCardPayment[];

            this.hasPayments = this.paymentList.length > 0 ? true : false;
          }
        });
    }
  }

  async presentPopupInfo() {
    let toast = await this.toastCtrl.create({
      message: 'Sección para consultar los pagos realizados con tarjeta',
      duration: 3000,
    });
    toast.present();
  }

  /**
   * Metodo utilizado para dar formato tipo moneda a un numero pasado por parametro
   * @param Amount numero con la cantidad a la que se le dara formato de moneda
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  getDateFormatt(DatePayment: string) {
    if (DatePayment != null) {
      return [
        DatePayment.slice(0, 4),
        DatePayment.slice(4, 6),
        DatePayment.slice(6),
      ].join('/');
    } else return 'No definida';
  }
}
