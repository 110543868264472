import { NgModule } from '@angular/core';
import { EInvoiceFilterPipe } from '../Invoice/pipes/EInvoice.pipe';
import { PurchOrderInvoiceFilterPipe } from '../Invoice/pipes/PurchOrderInvoice.pipe';
import { PurchOrderRemissionFilterPipe } from '../WMS/pipes/PurchOrderRemission.pipe';
import { AssetAuditFilterPipe } from './asset-audit-filter/asset-audit-filter';
import { AssetRequestFilterPipe } from './asset-request-filter/asset-request-filter';
import { CertFilterPipe } from './cert-filter/cert-filter';
import { FilterOneKeyListPipe } from './filter-one-key-list/filter-one-key-list';
import { FixedAssetFilterPipe } from './fixed-asset-filter/fixed-asset-filter';
import { NameFilterPipe } from './name-filter/name-filter';

@NgModule({
  declarations: [
    FixedAssetFilterPipe,
    NameFilterPipe,
    FilterOneKeyListPipe,
    AssetRequestFilterPipe,
    CertFilterPipe,
    AssetAuditFilterPipe,
    PurchOrderRemissionFilterPipe,
    PurchOrderInvoiceFilterPipe,
    EInvoiceFilterPipe,
  ],
  imports: [],
  exports: [
    FixedAssetFilterPipe,
    NameFilterPipe,
    FilterOneKeyListPipe,
    AssetRequestFilterPipe,
    CertFilterPipe,
    AssetAuditFilterPipe,
    PurchOrderRemissionFilterPipe,
    PurchOrderInvoiceFilterPipe,
    EInvoiceFilterPipe,
  ],
  providers: [
    FixedAssetFilterPipe,
    FilterOneKeyListPipe,
    AssetRequestFilterPipe,
    CertFilterPipe,
    AssetAuditFilterPipe,
    PurchOrderRemissionFilterPipe,
    PurchOrderInvoiceFilterPipe,
    EInvoiceFilterPipe,
  ],
})
export class PipesModule {}
