<app-nav-bar [title]="'Carrito - Productos'" [start]="'GoBack'"></app-nav-bar>

<ion-content>
  <ion-item>
    <ion-badge
      style="text-align: center; background: blue; margin: auto; padding: 3px"
    >
      <h6 style="color: aliceblue; font-weight: bold; margin: 0px">1/3</h6>
    </ion-badge>
  </ion-item>

  <!-- <div style="display: flex; justify-content: center;">
    <div style="text-align: center;">
      <span style="font-size: 1rem !important; color: brown; text-align: center;"> Aplique las promociones en la sección <a>Aplicar Promos</a> que se encuentra en el menú lateral de la aplicación.</span>
    </div>
  </div> -->

  <div *ngIf="OrigOrdNbrInd">
    <div
      padding-left
      padding-right
      style="font-size: 11px; padding-bottom: 5px; text-align: justify"
    >
      Según su configuración debe ingresar un identificador para la órden de
      compra de referencia.
    </div>
    <ion-item style="padding-left: 10px; padding-right: 10px">
      <ion-input
        [(ngModel)]="ordHerder.OrigOrdNbr"
        placeholder="ID Orden de compra"
        required
      ></ion-input>
    </ion-item>
  </div>

  <ion-card *ngIf="!validForm()">
    <ion-card-header
      style="
        padding-top: 10px;
        padding-left: 16px;
        padding-bottom: 10px;
        font-size: 14px !important;
      "
    >
      Tipos de Alerta
      <a class="span_alert"> (Solucionar para continuar)</a>
    </ion-card-header>
    <ion-card-content>
      <div style="display: flex; justify-content: center; align-items: center">
        <div
          *ngIf="sale_price_status"
          style="
            text-align: center;
            font-family: -webkit-body;
            padding-bottom: 5px;
          "
        >
          <i
            class="fas fa-coins"
            style="color: darkblue; margin-right: 5px"
          ></i>
          Precio Actualizado
        </div>
        <div
          *ngIf="avail_status"
          style="
            text-align: center;
            font-family: -webkit-body;
            padding-bottom: 5px;
          "
        >
          <i
            class="fas fa-sort-amount-down"
            style="color: darkgoldenrod; margin-right: 5px"
          ></i>
          Inventario Insuficiente
        </div>
        <div
          *ngIf="products_status"
          style="
            text-align: center;
            font-family: -webkit-body;
            padding-bottom: 5px;
          "
        >
          <i class="fas fa-ban" style="color: brown; margin-right: 5px"></i>
          Falta de Existencias
        </div>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-list *ngFor="let item of ordHerder.ordLine">
    <ion-card
      [ngClass]="{
        bsBlue: item.salePriceUpdated,
        bsYellow: item.itemStatus == 1,
        bsRed: item.itemStatus == -2
      }"
    >
      <ion-card-content>
        <ion-item-sliding (swipe)="swipeEvent($event, item)">
          <ion-item class="borderStyle">
            <ion-grid>
              <ion-row>
                <ion-col size="3">
                  <img
                    src="https://serviceplan-api.mercasacr.com/images/Products/{{
                      item.itemId
                    }}_l_.PNG"
                    onerror="this.src='assets/img/error.png'"
                    alt=""
                  />
                </ion-col>
                <ion-col size="9">
                  <ion-item lines="none">
                    <ion-grid>
                      <ion-row
                        ><h6 ion-text text-center text-wrap class="h7">
                          {{ item.itemName }}
                        </h6></ion-row
                      >
                      <ion-row>
                        <ion-col col-4>
                          <h6 ion-text text-center class="h7">
                            {{ item.salesQuantity }}
                          </h6>
                          <h6 ion-text text-center class="h7">
                            {{ item.unitId }}
                          </h6>
                          <div
                            style="
                              font-family: -webkit-body;
                              text-align: center;
                            "
                          >
                            <i
                              *ngIf="item.salePriceUpdated"
                              class="fas fa-coins"
                              style="color: darkblue; margin-right: 5px"
                            ></i>
                            <i
                              (click)="presentItemAlert(true, item)"
                              *ngIf="item.itemStatus == 1"
                              class="fas fa-sort-amount-down"
                              style="color: darkgoldenrod; margin-right: 5px"
                            ></i>
                            <i
                              (click)="presentItemAlert(false, item)"
                              *ngIf="item.itemStatus == -2"
                              class="fas fa-ban"
                              style="color: brown"
                            ></i>
                          </div>
                        </ion-col>
                        <ion-col col-4>
                          <h6
                            ion-text
                            text-center
                            color="primary"
                            class="h7"
                            style="font-weight: bold; color: darkblue"
                          >
                            {{ setSalePrice(item) }}
                          </h6>
                          <h6 ion-text text-center class="h7">
                            {{ item.unitId }}
                          </h6>
                        </ion-col>
                        <ion-col col-4>
                          <h6
                            ion-text
                            color="primary"
                            text-center
                            class="h7"
                            style="font-weight: bold; color: darkblue"
                          >
                            {{ setCurrency(item.totalSale) }}
                          </h6>
                          <!-- <h6 ion-text color="secondary" text-center class="h7" style="font-weight: bold;" *ngIf="item.taxAmount > 0">{{setCurrency(item.taxAmount) + ' IVA'}}</h6> -->
                          <h6 ion-text text-center class="h7">SubTotal</h6>
                        </ion-col>
                      </ion-row>

                      <ion-row class="primaryRow" *ngIf="item.promoId != ''">
                        <ion-col col-4>
                          <h6
                            ion-text
                            text-center
                            color="primary"
                            class="h7"
                            style="font-weight: bold"
                          >
                            {{ item.promoId }}-{{ item.PromoSequence }}
                          </h6>
                          <h6 ion-text text-center class="h7">PromoId</h6>
                        </ion-col>
                        <ion-col col-4>
                          <h6
                            ion-text
                            text-center
                            color="primary"
                            class="h7"
                            style="font-weight: bold"
                          >
                            {{ item.linePercent }}%
                          </h6>
                          <h6 ion-text text-center class="h7">% Desc</h6>
                        </ion-col>
                        <ion-col col-4>
                          <h6
                            ion-text
                            color="primary"
                            text-center
                            class="h7"
                            style="font-weight: bold"
                          >
                            {{ item.lnAmnt }}
                          </h6>
                          <h6 ion-text text-center class="h7">TOTAL</h6>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item-options side="end">
            <ion-item-option
              style="background: crimson"
              (click)="
                presentAlertAux(
                  'Atención',
                  '¿Desea eliminar una línea de la orden?',
                  item
                )
              "
            >
              <ion-icon name="trash"> </ion-icon>
              <span style="font-size: 12px">ELIMINAR</span>
            </ion-item-option>
          </ion-item-options>
          <ion-item-options side="start">
            <ion-item-option
              (click)="updateAux(item)"
              style="background: var(--ion-color-primary)"
            >
              <i class="fas fa-pencil-alt"></i>
              <span style="font-size: 12px">Actualizar</span>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-card-content>
    </ion-card>
  </ion-list>
  <!-- <ion-item lines='none'>
    <div style="display: flex;">
      <h5 ion-text text-center text-wrap style="width: 33%;">Cantidad de Líneas: {{ordHerder.ordLine.length}}</h5>
      <h5 ion-text text-center text-wrap style="width: 33%;">Cantidad de Artículos: {{totalUnits}}</h5>
      <h5 ion-text text-center text-wrap style="width: 33%;">Total de la orden: {{setCurrency(totalOrder)}}</h5>
    </div>
  </ion-item> -->
  <ion-item lines="none">
    <div style="display: flex; width: 100%">
      <h5 style="width: 33%; font-size: 1rem; text-align: center">
        <a style="margin-right: 0.2rem">N° Líneas:</a
        >{{ ordHerder.ordLine.length }}
      </h5>
      <h5 style="width: 33%; font-size: 1rem; text-align: center">
        <a style="margin-right: 0.2rem">N° Artículos:</a>{{ totalUnits }}
      </h5>
      <h5 style="width: 33%; font-size: 1rem; text-align: center">
        <a style="margin-right: 0.2rem">Total:</a>{{ setCurrency(totalOrder) }}
      </h5>
    </div>
  </ion-item>
  <BR />
  <BR />
  <BR />
  <BR />
</ion-content>

<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button
    size="small"
    (click)="NextStep()"
    [disabled]="!validForm() || !validOrigOrdNbr()"
  >
    <ion-icon name="chevron-forward"></ion-icon>
  </ion-fab-button>
</ion-fab>
