import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-asset-certification-popover',
  templateUrl: './asset-certification-popover.component.html',
  styleUrls: ['./asset-certification-popover.component.scss'],
})
export class AssetCertificationPopoverComponent {
  mainText: string;
  secondaryText: string;
  buttonText: string;
  workerRecId: string;
  certOverPeriod: number;

  periods: any[];

  //periods: string[];

  period: {
    strPeriod: string;
    strPeriodDate: string;
  };

  periodTemp: {
    strPeriod: string;
    strPeriodDate: string;
  };

  certPeriodicity: number;

  months: string[];

  constructor(
    private viewCtrl: PopoverController,
    private _apiProvider: webServiceProvider,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private navParams: NavParams
  ) {
    this.mainText = this.navParams.get('mainText');
    this.secondaryText = this.navParams.get('secondaryText');
    this.buttonText = this.navParams.get('buttonText');
    this.certPeriodicity = this.navParams.get('certPeriodicity');
    this.workerRecId = this.navParams.get('workerRecId');
    this.certOverPeriod = this.navParams.get('certOverPeriod');
    this.months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    this.periods = [];
    this.selectPeriodType(this.certPeriodicity);
  }

  /**
   *
   * @param periodType
   */
  selectPeriodType(periodType: number) {
    switch (Number(periodType)) {
      case 0:
        this.selectDayType();
        break;
      case 1:
        this.selectWeekType();
        break;
      case 2:
        this.selectMonthType();
        break;
      default:
        break;
    }
  }

  async selectDayType() {
    let current = new Date();
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    loading.present(); //Muestra pantalla de cargado.
    while (true) {
      this.period = {
        strPeriod:
          current.getDate().toString() +
          ' de ' +
          this.months[current.getMonth()].toString() +
          ' ' +
          current.getFullYear().toString(),
        strPeriodDate: this.dateToString(current),
      };
      let check = await this.checkPeriod(
        this.workerRecId,
        this.period.strPeriodDate,
        this.certPeriodicity
      );
      if (check == 0) {
        this.periods.push(this.period);
        break;
      } else if (check == 1 && this.certOverPeriod == 0) {
        this.createAlert(
          'Periodo certificado',
          'El periodo actual ya fue certificado y no se tienen permisos para certificar periodos posteriores'
        );
        this.dismiss(false);
        break;
      } else {
        current.setDate(current.getDate() + 1);
      }
    }
    await loading.dismiss();
  }

  async selectWeekType() {
    let currentDate = new Date();
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    await loading.present(); //Muestra pantalla de cargado.
    while (true) {
      this.period = {
        strPeriod: this.calcWeekOfYear(currentDate),
        strPeriodDate: this.dateToString(currentDate),
      };
      let check = await this.checkPeriod(
        this.workerRecId,
        this.period.strPeriodDate,
        this.certPeriodicity
      );
      if (check == 0) {
        this.periods.push(this.period);
        break;
      } else if (check == 1 && this.certOverPeriod == 0) {
        this.createAlert(
          'Periodo certificado',
          'El periodo actual ya fue certificado y no se tienen permisos para certificar periodos posteriores'
        );
        this.dismiss(false);
        break;
      } else {
        currentDate.setDate(currentDate.getDate() + 7);
      }
    }
    await loading.dismiss();
  }

  calcWeekOfYear(day: Date): string {
    let d = new Date(
      Date.UTC(day.getFullYear(), day.getMonth(), day.getDate())
    );
    let dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    let resp: string =
      'Semana ' +
      Math.ceil(
        ((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7
      ).toString() +
      ' del año ' +
      day.getFullYear().toString();
    return resp;
  }

  async selectMonthType() {
    let current = new Date();
    const loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Cargando...',
    }); //Genera pantalla de cargado.
    await loading.present(); //Muestra pantalla de cargado.
    current.setDate(1);
    while (true) {
      this.period = {
        strPeriod:
          this.months[current.getMonth()].toString() +
          ' ' +
          current.getFullYear().toString(),
        strPeriodDate: this.dateToString(current),
      };
      let check = await this.checkPeriod(
        this.workerRecId,
        this.period.strPeriodDate,
        this.certPeriodicity
      );
      if (check == 0) {
        this.periods.push(this.period);
        break;
      } else if (check == 1 && this.certOverPeriod == 0) {
        this.createAlert(
          'Periodo certificado',
          'El periodo actual ya fue certificado y no se tienen permisos para certificar periodos posteriores'
        );
        this.dismiss(false);
        break;
      } else {
        current.setMonth(current.getMonth() + 1);
      }
    }
    // if (this.periods.length > 0) {
    //   this.period = this.periods[0];
    // }
    await loading.dismiss();
  }

  /**
   *
   */
  dismiss(param: boolean) {
    if (param) {
      this.viewCtrl.dismiss(this.period);
    } else {
      this.viewCtrl.dismiss();
    }
  }

  /**
   *
   * @param date
   * @returns
   */
  dateToString(date: Date): string {
    let resp: string =
      String(date.getMonth() + 1) +
      '/' +
      String(date.getDate()) +
      '/' +
      String(date.getFullYear());
    return resp;
  }

  /**
   *
   * @returns
   */
  async checkPeriod(
    pWorkerRecId: string,
    pStrDate: string,
    pPeriodType: number
  ) {
    const resp = await this._apiProvider.checkPeriod(
      pWorkerRecId,
      '01',
      pStrDate,
      pPeriodType
    );
    const numResp: number = resp as number;
    return numResp;
  }

  /**
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }
}
