export class ProductEvaluation {
  ITEMID: string = '';
  AVERAGE: number = 0;
  RATE1: number = 0;
  RATE2: number = 0;
  RATE3: number = 0;
  RATE4: number = 0;
  RATE5: number = 0;

  constructor() {}
}
