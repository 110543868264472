import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { InventoryItem } from 'src/app/businessCore/InventoryItem';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';

@Component({
  selector: 'app-sub-families-select-hierarchy',
  templateUrl: './sub-families-select-hierarchy.component.html',
  styleUrls: ['./sub-families-select-hierarchy.component.scss'],
})
export class SubFamiliesSelectHierarchyComponent {
  titulo: string = '';
  family: string = '';
  itemInfo: InventoryItem[] = [];
  mainGroup: { name: string; show: boolean; iconUrl: string }[] = [];
  subGroup: string[] = [];
  imgUrl: string = '';
  shoppingCar: OrderLine[] = [];
  showLastClickedGroup: boolean = false;
  categories_info: string[] = [];

  constructor(
    public navParams: NavParams,
    private nav: NavController,
    private storage: StorageService,
    private ShoopingCartUtils: ShoopingCartUtils,
    private navService: NavigationService
  ) {
    this.itemInfo = this.navParams.get('inventoryItem');

    this.imgUrl = this.navParams.get('imgUrl');

    // Verifica si existen productos, sino retorna a la ventana anterior
    if (this.itemInfo == null || this.itemInfo.length == 0) {
      this.nav.pop();
    } else {
      this.family = this.itemInfo[0].Hierarchy1;
      this.titulo = this.itemInfo[0].Hierarchy2;

      let response: string[] = this.getCategoryStrings(this.titulo);

      this.getCategoriesNameString();

      if (response.length == 0) {
        this.navService.navigateTo(NavigationRoutes.FamiliesHomeComponent, {
          inventoryItem: this.itemInfo,
        });
      } else {
        // Construccion del grupo de nombres de las diferentes sub familias
        response.forEach((value) => {
          this.mainGroup.push({
            name: value,
            show: false,
            iconUrl: 'assets/img/icons/' + value + '_icon.png',
          });
          let arrayAux = this.mainGroup;
          this.mainGroup = arrayAux.sort((n1, n2) => {
            if (n1.name > n2.name) {
              return 1;
            }
            if (n1.name < n2.name) {
              return -1;
            }
            return 0;
          });
        });
      }
    }
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  getCategoryStrings(subFamily: string) {
    let response: string[] = [];

    let subfamilies = this.itemInfo.filter(
      (value: InventoryItem) =>
        value.Hierarchy2.toUpperCase() === subFamily.toUpperCase()
    );
    if (subfamilies != null && subfamilies.length > 0) {
      subfamilies.forEach((value) => {
        if (response.findIndex((data) => data === value.Hierarchy3) == -1) {
          response.push(value.Hierarchy3);
        }
      });
    }
    return response;
  }

  displayShoppingCar() {
    this.ShoopingCartUtils.displayShoppingCart();
  }

  showProducts(groupName: string, item: string = '') {
    groupName = this.replaceUnderScore(groupName);

    //console.log(groupName);
    //console.log(item);

    for (let group of this.mainGroup) {
      if (group.name.toLowerCase() == groupName.toLowerCase()) {
        groupName = group.name;
        break;
      }
    }
    // Si no selecciono la categoria, entonces solo filtra por familia y sub familia
    if (item == '') {
      this.navService.navigateTo(NavigationRoutes.FamiliesHomeComponent, {
        inventoryItem: this.itemInfo.filter(
          (value) =>
            value.Hierarchy2 === groupName && value.Hierarchy1 === this.titulo
        ),
      });
    }
    // de otra manera, Si selecciono la categoria, entonces filtra por familia, sub familia y categoria
    else {
      this.navService.navigateTo(NavigationRoutes.FamiliesHomeComponent, {
        inventoryItem: this.itemInfo.filter(
          (value) =>
            value.Hierarchy3 === groupName &&
            value.Hierarchy2 === item &&
            value.Hierarchy1 === this.family
        ),
      });
    }
  }

  getCategoriesNameString() {
    this.itemInfo.forEach((item) => {
      item.Icon = 'arrow-down';
      if (
        this.categories_info.indexOf(
          item.Hierarchy3.toUpperCase().replace(/ /g, '_')
        ) === -1
      ) {
        this.categories_info.push(
          item.Hierarchy3.toUpperCase().replace(/ /g, '_')
        );
      }
    });
  }

  replaceUnderScore(name: string) {
    return name.replace(/_/g, ' ');
  }
}
