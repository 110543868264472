import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Address } from '../../businessCore/Address';

export interface RegisterAddress {
  title: string;
  addBtn: string;
  headerTitle: string;
  pinUp: string;
  bodyMsg: string;
  secondTitle: string;
  country: string;
  state: string;
  county: string;
  city: string;
  streetAddress: string;
  name: string;
  egNote: string;
}

@Component({
  selector: 'app-register-address',
  templateUrl: './register-address.component.html',
  styleUrls: ['./register-address.component.scss'],
})
export class RegisterAddressComponent implements OnInit {
  addressData: Address;
  thirdAddressLang: RegisterAddress;

  constructor(
    public viewCtrl: PopoverController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.addressData = this.navParams.get('addressData');
    this.thirdAddressLang = this.navParams.get('thirdAddressLang');
  }

  /**
   *
   */
  disableAdd() {
    if (
      this.addressData.countryCode &&
      this.addressData.region &&
      this.addressData.subregion &&
      this.addressData.city &&
      this.addressData.address &&
      this.addressData.addressName
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   */
  sendAddress() {
    this.viewCtrl.dismiss(this.addressData);
  }
}
