<!-- MERCASA VIP STYLE-->
<ion-card
  *ngIf="infoPurch && infoPurch.getDocumentStateI() === 10"
  (press)="doPress()"
  (click)="doClick()"
>
  <ion-card-content>
    <ion-item-sliding (swipe)="swipeEvent($event)">
      <ion-item class="borderStyle">
        <ion-grid style="padding: 0px">
          <ion-row
            ><h6
              *ngIf="infoPurch.getIdOC() !== ''"
              class="h73"
              style="text-align: center; width: 100%"
            >
              <b>{{ infoPurch.getIdOC() }}</b>
            </h6></ion-row
          >
          <ion-row>
            <ion-col col-4>
              <h6 ion-text text-wrap class="h72">
                {{ infoPurch.getAccountNum() }}
              </h6>
            </ion-col>
            <ion-col col-42>
              <h6 ion-text text-wrap class="h73">
                {{ infoPurch.getVendName() }}
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col col-4>
              <h6 class="h7">Creado por:</h6>
              <h6 class="h7">Notas:</h6>
            </ion-col>
            <ion-col col-42>
              <h6 ion-text class="h7">
                {{ this.infoPurch.getCreatedBy() }}
              </h6>
              <h6 ion-text text-wrap class="h7">
                {{ this.infoPurch.getNote() }}
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col col-4>
              <h6 class="h7" style="text-align: center">LINEAS</h6>
              <h6
                color="primary"
                class="h7"
                style="
                  font-weight: bold;
                  text-align: center;
                  color: var(--ion-color-primary);
                "
              >
                {{ infoPurch.cantidadLineas }}
              </h6>
            </ion-col>
            <ion-col col-4>
              <h6 class="h7" style="text-align: center">TOTAL</h6>
              <h6
                color="primary"
                class="h7"
                style="
                  font-weight: bold;
                  text-align: center;
                  color: var(--ion-color-primary);
                "
              >
                {{ getCurrency(infoPurch.total) }}
              </h6>
            </ion-col>
            <ion-col col-4>
              <h6 class="h7">&#8205;</h6>
              <h6
                color="primary"
                class="h7"
                style="
                  font-weight: bold;
                  text-align: center;
                  color: var(--ion-color-primary);
                "
              >
                {{ infoPurch.getDocumentStateS() }}
              </h6>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item-options side="start">
        <ion-item-option (click)="approvePurchOrder()">
          <ion-icon name="checkmark-outline"></ion-icon>
          <span style="font-size: 8px">Aprobar</span>
        </ion-item-option>
      </ion-item-options>

      <ion-item-options side="end">
        <ion-item-option color="danger" (click)="viewPurchOrder()">
          <ion-icon name="add"></ion-icon>
          <span style="font-size: 8px">Ver más</span>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-card-content>
</ion-card>
