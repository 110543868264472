import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NavigationParamsService {
  getParam(service: Router | NavParams, paramKey: string) {
    if (service instanceof Router) {
      return this.resolveParamsRouter(service)[paramKey];
    }

    if (service instanceof NavParams) {
      return this.resolveParamsNavParams(service)[paramKey];
    }

    return null;
  }

  resolveParamsRouter(router: Router) {
    const paramData = router.getCurrentNavigation().extras.state['data'];
    return paramData;
  }

  resolveParamsNavParams(navParams: NavParams) {
    const paramData = navParams.data;
    return paramData;
  }
}
