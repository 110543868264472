<app-nav-bar [title]="'Aprobar O.C'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button style="background: none" (click)="closePopOver()">
      <i class="fas fa-times"></i>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box">
    <div class="content">
      <ion-list>
        <div *ngFor="let obj of objectsList" class="lineSpinner">
          <ion-spinner
            name="crescent"
            *ngIf="obj.getSending() === 2"
          ></ion-spinner>
          <i
            class="fas fa-check"
            style="color: #32db64"
            *ngIf="obj.getSending() === 1"
          ></i>
          <i class="fas fa-paper-plane" *ngIf="obj.getSending() === 0"></i>
          <i
            class="fas fa-exclamation-triangle"
            style="color: #f53d3d"
            *ngIf="obj.getSending() === -1"
          ></i>
          <ion-item [ngClass]="getStyle(obj.getSending())" style="width: 100%">
            <ion-label>
              <h2 style="width: 100%">{{ obj.getIdOC() }}</h2>
              <p *ngIf="obj.getSending() !== -1" style="width: 100%">
                {{ obj.getDocumentStateS() }}
              </p>
              <p *ngIf="obj.getSending() === -1" style="width: 100%">
                Error al tratar de aprobar.
              </p>
            </ion-label>
          </ion-item>
        </div>
      </ion-list>
    </div>

    <div class="infContent">
      <div
        style="
          display: flex;
          margin-top: 2%;
          margin-bottom: 2%;
          margin-bottom: 10%;
        "
      >
        <ion-button color="danger" class="btnPosition" (click)="closePopOver()">
          Cerrar
        </ion-button>
        <ion-button
          color="secondary"
          class="btnPosition"
          (click)="approvePurchOrder()"
        >
          Aprobar
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
