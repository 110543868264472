<app-nav-bar [title]="'Mis Pedidos'"></app-nav-bar>

<ion-content class="ion-padding">
  <h3 class="title2" style="text-align: center">Historial</h3>

  <ion-searchbar
    type="text"
    class="searchBar"
    [(ngModel)]="searchInput"
    placeholder="Buscar"
    (ionInput)="getItems($event)"
  ></ion-searchbar>

  <div style="text-align: center" *ngIf="loadingOrders">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando Pedidos</h6>
  </div>

  <ion-list>
    <ion-card
      *ngFor="let order of searchOrders"
      style="
        background: transparent;
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      "
    >
      <ion-item-sliding
        style="border-radius: 5px; overflow: hidden"
        (swipe)="swipeEvent($event, order)"
      >
        <ion-item lines="none" class="borderStyle" style="margin: 5px">
          <div class="flexBlockContainer" style="width: 100%">
            <div class="flexContainer">
              <h6>{{ order.salesAxId }}</h6>
              <h6 style="color: transparent">-</h6>
              <h6
                [ngStyle]="{
                  color:
                    order.paymentReceiptId && order.paymentReceiptId != '-1'
                      ? 'green'
                      : 'transparent'
                }"
              >
                Pagado
                <i
                  *ngIf="
                    order.paymentReceiptId && order.paymentReceiptId != '-1'
                  "
                  style="color: green"
                  class="fas fa-check-double"
                ></i>
              </h6>
            </div>

            <div class="flexContainer">
              <div>
                <i class="fas fa-cart-arrow-down"></i>
              </div>
              <div>
                <i class="fas fa-truck"></i>
              </div>
              <div>
                <i class="fas fa-money-bill-wave"></i>
              </div>
            </div>

            <div class="flexContainer">
              <h6>{{ order.createdDatetimeString }}</h6>
              <h6>{{ order.deliveryDateString }}</h6>
              <h6>{{ setCurrency(order.totalSales) }}</h6>
            </div>
          </div>
        </ion-item>

        <ion-item-options side="end">
          <ion-item-option color="primary" (click)="selectProducts(order)">
            <i class="far fa-eye"></i>
            <span style="font-size: 8px">PRODUCTOS</span>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-card>
  </ion-list>
</ion-content>
