<app-nav-bar [title]="'Aprobar O.C'"
  ><ion-buttons slot="end">
    <ion-button
      style="background: none"
      (click)="sendOCToAprobe()"
      *ngIf="sendMultiple"
    >
      <i class="fas fa-paper-plane" style="width: 30px"></i>
    </ion-button> </ion-buttons
></app-nav-bar>

<div class="barTitle" (click)="openSearchBarAux()">
  <div style="display: flex; color: white">
    <h5 style="width: 30%; font-size: 1rem; text-align: center">Empresa:</h5>
    <h5 style="width: 60%; font-size: 1rem">{{ dataAreaSelected }}</h5>
    <div style="display: flex; align-items: center">
      <ion-icon name="caret-down-outline" slot="end"></ion-icon>
    </div>
  </div>
</div>

<!--Filtros de OC-->
<ion-searchbar
  type="text"
  placeholder="Buscar"
  (ionChange)="searchOC($event)"
  style="background-color: white"
></ion-searchbar>

<div
  class="flex-container nowrap"
  style="width: 100%; display: flex; background: white"
>
  <ion-button
    class="filter-btn"
    (click)="presentFilterPopover()"
    style="width: 100%"
  >
    Filtros
    <ion-icon name="options"></ion-icon>
  </ion-button>

  <ion-button class="sort-btn" (click)="presentSortAlert()" style="width: 100%">
    Ordenar
    <ion-icon name="reorder-three-outline"></ion-icon>
  </ion-button>
</div>
<!--//////////////////////////////////////////////////////////////////////////////////////////////-->

<ion-content style="background-color: white">
  <ion-refresher (ionRefresh)="refresOC($event)" slot="fixed">
    <ion-refresher-content
      pullingIcon="arrow-dropdown"
      pullingText="Deslice para actualizar las ordenes por aprobar"
      refreshingSpinner=""
      refreshingText=""
      slot="fixed"
    >
    </ion-refresher-content>
  </ion-refresher>

  <div class="box">
    <ion-list lines="none">
      <app-to-be-approved-oc
        *ngFor="let purch of listPurchShowing"
        [infoPurch]="purch"
        (selectedAction)="showBtnMultipleSend()"
      >
      </app-to-be-approved-oc>
    </ion-list>
  </div>
</ion-content>
