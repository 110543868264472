import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { ItemOCModel } from '../../businessCore/oc-models/ItemOCModel';
import { ItemOCInfoPriceModel } from '../../businessCore/oc-models/ItemOCPriceInfoModel';
import { PurchLine } from '../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { PopInfoOcComponent } from '../../components/oc-components/pop-info-oc/pop-info-oc.component';
import { SearchProviderComponent } from '../../components/oc-components/search-provider/search-provider.component';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../provider/Services/local-service-oc.service';
import { ServicesOcParameterProvider } from '../../provider/Services/services-oc-parameter';

@Component({
  selector: 'app-purch-order-line',
  templateUrl: './purch-order-line.component.html',
  styleUrls: ['./purch-order-line.component.scss'],
})
export class PurchOrderLinePage {
  notaLineaAct: string;
  actualLine: PurchLine;
  purchInfo: PurchOrder;
  unidadSelected: ItemOCInfoPriceModel;
  cantidadLineas: number;
  total: number;
  impuestos: number;
  segmentModel: string;
  notItem: boolean;
  productoSelected: ItemOCModel;
  listItem: ItemOCModel[];
  presentingMess = false;
  loader: HTMLIonLoadingElement;
  toEdit = false;
  isEditing = false;
  sendingData = false;
  purchLineCopy: PurchLine[];
  //Dimensiones
  aDepartamento: CatalogModel[] = [];
  bCentroCosto: CatalogModel[] = [];
  cProposito: CatalogModel[] = [];
  aDepartamentoSelected: CatalogModel;
  bCentroCostoSelected: CatalogModel;
  cPropositoSelected: CatalogModel;
  tipoImpuesto: CatalogModel;
  impuestosList: CatalogModel[] = [];
  //@ViewChild(Navbar) navBar: Navbar;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private extServOC: ExternalServiceOCService,
    public alertController: AlertController,
    private popoverCtrl: PopoverController,
    private loadingCtrl: LoadingController,
    private ocParameter: ServicesOcParameterProvider,
    private localServOC: LocalServiceOCService,
    private navService: NavigationService,
    private platform: Platform
  ) {
    this.segmentModel = 'infoLineas';
    this.purchInfo = this.ocParameter.getPurchOrderParameter();
    this.toEdit = this.ocParameter.getToEdit();
    this.ocParameter.setEditated(false); // No se ha hecho ningu cambio para la orden.
    if (!this.toEdit) {
      // no es para editar, es para crear, entonces se deja seleccionar
      this.isEditing = true;
    }
  }

  async ngOnInit() {
    console.log('entro');
    if (!this.toEdit) {
      await this.setInfoToCreatedOCLine();
    } else {
      this.notaLineaAct = this.purchInfo.getNote();
      this.cantidadLineas = this.purchInfo.cantidadLineas;
      this.total = this.purchInfo.total;
    }
  }

  async setInfoToCreatedOCLine() {
    // Se recibe el objeto de encabezado
    this.notItem = false;
    // Se llama la lista de productos
    await this.showSpinnerLoading('Cargando datos de productos.');
    this.listItem = await this.extServOC.getDataItemByVendot(
      this.purchInfo.getAccountNum(),
      this.purchInfo.getDataArea().getId()
    );
    // Dimensiones Financieras
    this.aDepartamento = await this.extServOC.getFinancialDimensionByName(
      'A_Departamento'
    );
    this.bCentroCosto = await this.extServOC.getFinancialDimensionByName(
      'B_CentroCosto'
    );
    this.cProposito = await this.extServOC.getFinancialDimensionByName(
      'C_Proposito'
    );
    this.impuestosList = await this.extServOC.getTypeTaxes();
    this.dissmissLoading();
    // Objeto de linea actual, se cargan los datos del primer elemento
    if (this.listItem.length > 0) {
      this.productoSelected = this.listItem[0];
      const actUnitSelectedA = this.productoSelected.getListUnits()[0];
      this.unidadSelected = actUnitSelectedA.copyItem(actUnitSelectedA);
      const tmpImpuesto = this.impuestosList.find(
        (data) =>
          (data.getId() as any) === (this.unidadSelected.getTypeTaxes() as any)
      );
      this.tipoImpuesto =
        tmpImpuesto !== null && tmpImpuesto !== undefined
          ? tmpImpuesto
          : new CatalogModel('' as any, '');
      // this.cantidadLineas = this.purchInfo.cantidadLineas;
      // this.total = this.purchInfo.total;
    } else {
      this.notItem = true;
    }
    this.notaLineaAct = '';
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
    this.impuestos = this.purchInfo.taxes;
    this.aDepartamentoSelected = this.purchInfo.getA_Departamento();
    this.bCentroCostoSelected = this.purchInfo.getB_CentroCosto();
    this.cPropositoSelected = this.purchInfo.getC_Proposito();
  }

  ionViewDidEnter() {
    this.purchInfo = this.ocParameter.getPurchOrderParameter();
    if (this.purchInfo !== null && this.purchInfo !== undefined) {
      this.total = this.purchInfo.total;
      this.cantidadLineas = this.purchInfo.cantidadLineas;
      this.impuestos = this.purchInfo.taxes;
    }
  }

  ionViewDidLoad() {
    this.platform.backButton.subscribe(() => {
      if (this.toEdit) {
        this.goToBackEdit();
      } else {
        this.goToBack();
      }
    });
  }

  getPurchInfoOC(dataActual: PurchOrder) {
    this.purchInfo = dataActual;
    if (this.purchInfo.getLineList().length > 0) {
      this.purchInfo
        .getLineList()
        [this.purchInfo.getLineList().length - 1].setNote(this.notaLineaAct);
      this.aDepartamentoSelected = this.purchInfo.getA_Departamento();
      this.bCentroCostoSelected = this.purchInfo.getB_CentroCosto();
      this.cPropositoSelected = this.purchInfo.getC_Proposito();
    }
    this.notaLineaAct = '';
  }

  getNoteLine(notaLinea: string) {
    this.notaLineaAct = notaLinea;
    /*if (this.purchInfo.getLineList().length > 0) {
      console.log(this.purchInfo.getLineList());
      this.purchInfo.getLineList[this.purchInfo.getLineList.length - 1].setNote(
        this.notaLineaAct
      );
    }*/
  }

  goToConfirmOrder() {
    // Setea el parametro a pasar
    if (this.purchInfo.getLineList().length > 0) {
      this.ocParameter.setPurchOrderParameter(this.purchInfo);
      this.navService.navigateTo(NavigationRoutes.PurchOrderConfirmPage);
    } else {
      this.messageInfo('Error lineas', 'No hay lineas agregadas a la OC.');
    }
  }

  async changeEdit() {
    if (this.isEditing) {
      const tmpPurchase = this.purchInfo;
      // Se manda los cambios si la persona quiere
      this.sendDataOCToUpdate(
        'Atención',
        '¿Cambios realizados a la orden, desea enviar los cambios?',
        tmpPurchase
      );
    } else {
      // Realiza una copia de las lineas que estan en el objeto actual, asi evita errores a la hora de cancelar la edicion.
      this.purchLineCopy = this.purchInfo.getCopyLines();
      // Se trata de entrar a modo de edición
      await this.modoEditing('Entrando a modo edición', 0);
    }
    this.segmentModel = 'infoLineas';
  }

  async sendDataOCToUpdate(
    titulo: string,
    mensaje: string,
    purchInfo: PurchOrder
  ) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [
          {
            text: 'ACEPTAR',
            handler: () => {
              this.updatePurchaseOrder(purchInfo);
            },
          },
          {
            text: 'CANCELAR',
            handler: async () => {
              // Como no se quiere guardar los cambios no se setea el parametro, deja igual
              await this.modoEditing('Saliendo de modo edición', 10);
              // Se setea la orden anterior
              this.goToBackEdit();
            },
          },
        ],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  /**
   * 'BORRADOR' === 0
   *  'EN REVISIÓN' === 10
   */
  async modoEditing(mensaje: string, estado: number) {
    if (!this.sendingData) {
      this.sendingData = true;
      await this.showSpinnerLoading(mensaje);
      const cedula = await this.localServOC.getCedulaActual();
      const resp = await this.extServOC.editingMode(
        estado,
        this.purchInfo.getIdOC(),
        this.purchInfo.getDataArea().getId(),
        cedula
      );
      this.dissmissLoading();
      this.sendingData = false;
      this.resolveEditingMode(resp);
    }
  }

  async updatePurchaseOrder(toSendData: PurchOrder) {
    if (!this.sendingData) {
      this.sendingData = true;
      let titulo = '';
      let mensaje = '';
      let resp: any;
      await this.showSpinnerLoading('Enviando cambios de OC.');
      try {
        const lines = toSendData.linesToJSON();
        resp = await this.extServOC.updateLinePurchaseOrder(
          toSendData.getIdOC(),
          toSendData.getDataArea().getId(),
          lines
        );
        if (resp === 'SUCCESS') {
          // Si sale todo bien se cambia de modo edicion
          this.purchInfo = toSendData;
          titulo = 'Atención';
          mensaje =
            'La orden de compra ha sido actualizada ' + toSendData.getIdOC();
          this.ocParameter.setEditated(true);
        } else {
          titulo = 'Error';
          mensaje = 'No se pudo actualizar la orden de compra.';
        }
      } catch (error) {
        // Hubo un error, la orden sigue siendo la misma.
        titulo = 'Error';
        mensaje = 'No se comunicar con el servidor.';
      }
      this.dissmissLoading();
      this.messageInfo(titulo, mensaje);
      this.sendingData = false;
      if (resp === 'SUCCESS') {
        await this.modoEditing('Saliendo de modo edición', 10);
      }
    }
  }

  async resolveEditingMode(respuesta: any) {
    if (typeof respuesta === 'string') {
      switch (respuesta) {
        case 'CANNOTEDIT':
          this.messageInfo(
            'Error',
            'La orden está siendo editada por otro usuario.'
          );
          this.isEditing = false;
          break;
        case 'EDITING':
          this.messageInfo('Atención', 'Puede editar la orden.');
          this.isEditing = true;
          await this.setInfoToCreatedOCLine();
          break;
        case 'SUCCESS':
          this.isEditing = false;
          break;
        default:
          break;
      }
    } else {
      this.messageInfo(
        'Error',
        'No se puede editar la orden en este momento, no se pudo comunicar con el servidor.'
      );
    }
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [{ text: 'Aceptar', handler: () => {} }],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  /**
   * Funcion que abre la pantalla de busqueda con una lista en especifico
   * retorna el dato seleccionado, que luego sera procesado.
   */
  async openSearchBar(typeSearch: string) {
    let dataSelected: any = null;
    switch (typeSearch) {
      case 'search-product':
        dataSelected = await this.openSearchBarAux(this.listItem, typeSearch);
        break;
      case 'search-unit':
        dataSelected = await this.openSearchBarAux(
          this.productoSelected.getListUnits(),
          typeSearch
        );
        break;
      case 'search-A_Departamento':
        dataSelected = await this.openSearchBarAux(
          this.aDepartamento,
          typeSearch
        );
        break;
      case 'search-B_CentroCosto':
        dataSelected = await this.openSearchBarAux(
          this.bCentroCosto,
          typeSearch
        );
        break;
      case 'search-C_Proposito':
        dataSelected = await this.openSearchBarAux(this.cProposito, typeSearch);
        break;
      case 'search-typeTaxes':
        dataSelected = await this.openSearchBarAux(
          this.impuestosList,
          typeSearch
        );
        break;
      default:
        break;
    }
    return dataSelected;
  }

  /**
   * Funcion para abrir una pantalla y retornar el elemento de busqueda
   * recibe la lista a filtrar
   */
  async openSearchBarAux(listInfo: any[], typeSearch: string) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.setDataSelected(data.objSelected, typeSearch);
        }
      }
    });
  }

  // Asigna el dato seleccionado a la variable
  setDataSelected(objectSelected: any, type: string) {
    // Si es el proveedor se cambia otras cosas tambien
    switch (type) {
      case 'search-product':
        this.productoSelected = objectSelected as ItemOCModel;
        const actUnitSelectedA = this.productoSelected.getListUnits()[0];
        this.unidadSelected = actUnitSelectedA.copyItem(actUnitSelectedA);
        const tmpImpuesto = this.impuestosList.find(
          (data) =>
            (data.getId() as any) ===
            (this.unidadSelected.getTypeTaxes() as any)
        );
        this.tipoImpuesto =
          tmpImpuesto !== null && tmpImpuesto !== undefined
            ? tmpImpuesto
            : new CatalogModel('' as any, '');
        break;
      case 'search-unit':
        const actUnitSelected = objectSelected as ItemOCInfoPriceModel;
        this.unidadSelected = actUnitSelected.copyItem(actUnitSelected);
        const tmpImpuesto2 = this.impuestosList.find(
          (data) =>
            (data.getId() as any) ===
            (this.unidadSelected.getTypeTaxes() as any)
        );
        this.tipoImpuesto =
          tmpImpuesto2 !== null && tmpImpuesto2 !== undefined
            ? tmpImpuesto2
            : new CatalogModel('' as any, '');
        break;
      case 'search-A_Departamento':
        this.aDepartamentoSelected = objectSelected as CatalogModel;
        break;
      case 'search-B_CentroCosto':
        this.bCentroCostoSelected = objectSelected as CatalogModel;
        break;
      case 'search-C_Proposito':
        this.cPropositoSelected = objectSelected as CatalogModel;
        break;
      case 'search-typeTaxes':
        this.tipoImpuesto = objectSelected as CatalogModel;
        this.unidadSelected.typeTaxes = this.tipoImpuesto.getId() as any;
        this.unidadSelected.taxes = this.tipoImpuesto.getRecId();
        console.log('Taxes select: ', this.tipoImpuesto, this.unidadSelected);
        break;
      default:
        break;
    }
  }

  async openMenuOC(evento) {
    // Muestra la opcion de cambiar de estado en la pantalla
    const popover = await this.popoverCtrl.create({
      component: PopInfoOcComponent,
      componentProps: { infoOC: this.purchInfo },
      cssClass: ['custom-popover', 'pop-info-oc'],
      backdropDismiss: true,
      showBackdrop: true,
    });
    await popover.present(evento);
  }

  async goToBackEdit() {
    if (this.isEditing) {
      const tmpPurchase = this.purchInfo;
      // Se manda los cambios si la persona quiere
      await this.sendDataOCToUpdate(
        'Atención',
        '¿Cambios realizados a la orden, desea enviar los cambios?',
        tmpPurchase
      );
    } else {
      console.log(
        'fue editada: ',
        this.ocParameter.getEditated(),
        'En servicio: ',
        this.ocParameter.getPurchOrderParameter(),
        'En uso: ',
        this.purchInfo,
        'Copia: ',
        this.purchLineCopy
      );
      if (!this.ocParameter.getEditated()) {
        // Si no fue editado.
        this.purchInfo = this.ocParameter.getPurchOrderParameter();
        if (this.purchLineCopy !== null && this.purchLineCopy !== undefined) {
          // fue editado pero no se quieren guardar los cambios
          this.purchInfo.setLineList(this.purchLineCopy);
          this.purchInfo.calcularDatos();
        }
      }
      this.goToBack();
    }
  }

  goToBack() {
    this.ocParameter.setPurchOrderParameter(this.purchInfo);
    this.navCtrl.pop();
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  deleteItem(itemToDelete: PurchLine) {
    if (itemToDelete.getRecId() !== -1) {
      // es una linea que se quiere eliminar de AX
      this.purchInfo.toDeleteLine(itemToDelete); //solo se oculta a la vista del usuario
    } else {
      // es una linea agregada desde el componente de editar
      this.purchInfo.deleteLine(itemToDelete); // se debe de eliminar de la lista.
    }
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
  }

  updateInfPurch(parameter: boolean) {
    this.purchInfo.calcularDatos();
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  async ngOnDestroy() {
    if (this.isEditing) {
      if (this.purchInfo) {
        const cedula = await this.localServOC.getCedulaActual();
        // 20 === 'RECHAZADO'
        // 0 === 'BORRADOR'
        // 10 === 'EN REVISIÓN'
        const recha = 20;
        const revi = 10;
        if (this.purchInfo.getDocumentStateI() === recha) {
          //Si la orden tiene ciertos estado es necesario hacer
          this.extServOC.editingMode(
            recha,
            this.purchInfo.getIdOC(),
            this.purchInfo.getDataArea().getId(),
            cedula
          );
        } else if (this.purchInfo.getDocumentStateI() === revi) {
          if (this.ocParameter.getFrom() == 'RECORD') {
            this.extServOC.editingMode(
              revi,
              this.purchInfo.getIdOC(),
              this.purchInfo.getDataArea().getId(),
              cedula
            );
          }
        } else if (
          this.purchInfo.getDocumentStateI() === 0 &&
          this.purchInfo.getIdOC() !== ''
        ) {
          this.extServOC.editingMode(
            revi,
            this.purchInfo.getIdOC(),
            this.purchInfo.getDataArea().getId(),
            cedula
          );
        }
      }
    }
  }
}
