<app-nav-bar [title]="'Mis Promociones'"></app-nav-bar>

<ion-content class="ion-padding">
  <ion-searchbar
    type="text"
    class="searchBar"
    [(ngModel)]="searchInput"
    placeholder="Buscar"
    (ionInput)="getItems($event)"
  ></ion-searchbar>

  <div style="text-align: center; margin-top: 12px" *ngIf="!promoExist()">
    <h6 style="font-size: 1rem; text-align: center">
      No existen promociones asignadas.
    </h6>
  </div>

  <div
    style="
      text-align: center;
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    *ngIf="promoExist()"
  >
    <ion-icon
      color="secondary"
      name="arrow-back"
      style="font-size: 1rem; font-weight: bold"
    ></ion-icon>
    <h6 ion-text text-center style="font-size: 1rem; margin-left: 1rem">
      Desliza la promoción para visualizar.
    </h6>
  </div>

  <ion-list>
    <ion-card
      *ngFor="let promo of promoFiltered"
      style="
        background: transparent;
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      "
      (click)="displayPromo(promo)"
    >
      <ion-item-sliding
        (swipe)="swipeEvent($event, promo)"
        style="border-radius: 5px; overflow: hidden"
      >
        <ion-item lines="none" class="borderStyle">
          <div style="display: flex; align-items: center">
            <div style="margin-right: 10px; font-size: 18px">
              <i class="fas fa-gift blueColor"></i>
            </div>
            <div class="flexColumn">
              <div style="font-size: 1rem; white-space: normal">
                <a>ID: </a> {{ promo.PromoId }}
              </div>
              <!-- <h6 style="font-weight: bold;">Nombre: {{promo.Name}}</h6> -->
              <div
                style="
                  font-size: 1rem;
                  text-transform: lowercase;
                  white-space: normal;
                "
              >
                <a style="text-transform: capitalize">Descripción: </a>
                {{ promo.Description }}
              </div>
            </div>
          </div>
        </ion-item>

        <ion-item-options side="end">
          <ion-item-option color="secondary">
            <i class="fas fa-tasks"></i>
            <span style="padding-left: 5px" class="wrapword">Aplicar</span>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-card>
  </ion-list>
</ion-content>
