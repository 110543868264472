// Native - Plugins
import { Component } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
// BusinessCore
import { Direction } from './../../businessCore/Direction';
import { RegisterData } from './../../businessCore/RegisterData';

// Pages
import {
  AlertController,
  NavController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
// import { HomePage } from './../../pages/home/home';

// Components

@Component({
  selector: 'app-register-direction',
  templateUrl: './register-direction.component.html',
  styleUrls: ['./register-direction.component.scss'],
})
export class RegisterDirectionComponent {
  register_data: RegisterData;

  /**
   * Class constructor with these injections
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param nav base class for navigation controller components like Nav and Tab
   * @param toast subtle notification commonly used in modern applications.
   * @param geolocation provides information about the device's location,
   * such as latitude and longitude.
   */
  constructor(
    public navParams: NavParams,
    private toast: ToastController,
    public navCtrl: NavController,
    public geolocation: Geolocation,
    public alert: AlertController,
    private navService: NavigationService
  ) {
    this.register_data = this.navParams.get('registerData');
  }

  getData = (data) => {
    return new Promise<void>((resolve, reject) => {
      const direction = data as Direction;
      if (!direction.edit) {
        direction.edit = true;
        if (direction.directionName == '') {
          if (this.register_data.directions.length > 0) {
            direction.directionName =
              this.register_data.name +
              ' - ' +
              this.register_data.commercial_name +
              ' - #' +
              this.register_data.directions.length.toString();
          } else {
            direction.directionName =
              this.register_data.name +
              ' - ' +
              this.register_data.commercial_name;
          }
        }
        this.register_data.directions.push(direction);
      }
      resolve();
    });
  };

  /**
   * Metodo utilizado para redirigir la aplicacion al siguiente paso del registro de usuarios
   * en especifico se realiza al componente de registro de contactos
   * Personales u otros.
   */
  public registerNextStep() {
    this.navService.navigateTo(NavigationRoutes.RegisterContactComponent, {
      registerData: this.register_data,
      callback: this.getData,
    });
  }

  /**
   * Metodo utilizado para presentar el popup de creacion de una nueva direccion
   * donde se registra los datos solicitados en el formulario
   * @param direction objeto con los campos que deben ser llenados para llevar
   * a cabo el registro de una nueva direccion
   */
  presentPopover(direction: Direction) {
    this.navService.navigateTo(NavigationRoutes.GetPositionPage, {
      data: direction,
      callback: this.getData,
    });
  }

  /**
   * Metodo utilizado para presentar el popup de registro de direcciones pero de manera tal
   * que no sea para actualizar la direccion, sino para crearla desde cero = nueva.
   */
  presentNewPopover() {
    this.geolocation.getCurrentPosition().then(
      (position) => {
        const direction = new Direction();

        direction.latitude = position.coords.latitude;
        direction.longitude = position.coords.longitude;

        direction.edit = false;

        this.presentPopover(direction);
      },
      () => {
        this.presentRejectAlert('Error obteniendo posicion GPS!!');
      }
    );
  }

  async presentRejectAlert(title) {
    let alert = await this.alert.create({
      backdropDismiss: true,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
      header: title,
      message: `<p>Asegúrese de tener habilitado el permiso de localización para poder continuar con el registro.</p>
      <ul>
      <li>Ajustes -> Aplicaciones -> Mercasa VIP -> Permisos</li>
      </ul>
      <p>En caso de que el error persista, favor comunicarse a los números:</p>
      <ul>
      <li>7071-3089</li>
      <li>7071-3067</li>
      <li>7071-3153</li>
      <li>7071-3098</li>
      </ul>
      `,
    });
    alert.present();
  }

  /**
   * Metodo utilizado para eliminar una direccion de la lista que el usuario tiene ingresadas
   * esto se realiza por medio de un splice que elimina el objeto que se encuentra en la posicion
   * especificada
   * @param con objeto con la informacion de la direccion que se desea eliminar
   */
  async deleteDirection(dir: Direction) {
    let delete_index = this.register_data.directions.indexOf(dir);
    this.register_data.directions.splice(delete_index, 1);

    let toast = await this.toast.create({
      message: 'Dirección ' + dir.direction + ' eliminada.',
      duration: 2000,
    });
    toast.present();
  }

  /**
   * Metodo utilizado para validar el formulario de registro, para que el mismo cumpla con
   * todo lo necesario para llevar a cabo el registro del nuevo cliente y asi le permita
   * continuar con el ingreso de informacion adicional
   */
  validForm() {
    if (Object.keys(this.register_data.directions).length) return true;
    return false;
  }

  /**
   * Metodo utilizado para retornar al componente principal de la aplicacion
   * que es la ventana de despliegue de productos
   */
  goHome() {
    // this.navCtrl.setRoot(HomePage);
    //this.navCtrl.setRoot(FamiliesComponent);
    //this.navCtrl.popToRoot();
    this.navService.navigateTo(NavigationRoutes.FamiliesComponent);
  }
}
