import { Address } from './Address';
import { EntityName } from './EntityName';
import { Phone } from './Phone';
import { VendorRegisterData } from './VendorRegisterData';

export class Vendor {
  //Atributos para el registro
  //GENERAL
  vendorVatnum: string;
  vendorVatnumType: string;
  vendorName: string;
  vendorFirstLastname: string;
  vendorSecondLastname: string;
  vendorFullName: string;
  represVatnum: string;
  represVatnumType: string;
  represName: string;
  represFirstLastname: string;
  represSecondLastname: string;
  represFullName: string;
  providedCompany: string;
  creatorVatnum: string;

  //PAYMENT
  paymentTermId: string;
  accountId: string;
  currency: string;
  accountNum: string;
  ABANum: string;
  SWIFTCode: string;

  //CONTACT
  phoneList: Phone[];
  emailList: any[];

  //ADDRESS
  AddressList: Address[];

  //DOCUMENT
  vatNumVendor: any[];
  vatNumRepres: any[];
  vatNumReprInvoice: any[];
  vatNumReprService: any[];
  vendorAgreement: any[];
  transferAuthorization: any[];
  guaranteePolicies: any[];

  //Atributos para recepción de datos
  vendorOf: string;
  vatnumType: string;
  //vendorVatnum: string;
  vendorEntity: EntityName;
  //represVatnumType: string;
  //represVatnum: string;
  represEntity: EntityName;

  //Setters y Getters
  getVendorOf() {
    return this.vendorOf;
  }

  getVatnumType() {
    return this.vatnumType;
  }

  getVendorVatnum() {
    return this.vendorVatnum;
  }

  getVendorEntity() {
    return this.vendorEntity;
  }

  getRepresVatnumType() {
    return this.represVatnumType;
  }

  getRepresVatnum() {
    return this.represVatnum;
  }

  getRepresEntity() {
    return this.represEntity;
  }

  //Parse

  parseRegisterData(pRegisterData: VendorRegisterData) {
    let vendor: Vendor = new Vendor();
    //General
    vendor.vendorVatnum = pRegisterData.generalData.vendorVatnum;
    vendor.vendorVatnumType =
      pRegisterData.generalData.vendorIdType.getCodCategory();

    vendor.vendorName = pRegisterData.generalData.vendorEntityName.getName();
    vendor.vendorFirstLastname =
      pRegisterData.generalData.vendorEntityName.getFirstLastName();
    vendor.vendorSecondLastname =
      pRegisterData.generalData.vendorEntityName.getSecondLastName();
    vendor.vendorFullName = vendor.vendorName;
    if (vendor.vendorFirstLastname) {
      vendor.vendorFullName += ' ' + vendor.vendorFirstLastname;
    }

    if (vendor.vendorSecondLastname) {
      vendor.vendorFullName += ' ' + vendor.vendorSecondLastname;
    }

    vendor.represVatnum = pRegisterData.generalData.representVatnum;
    vendor.represVatnumType =
      pRegisterData.generalData.representIdType.getCodCategory();

    vendor.represName = pRegisterData.generalData.representEntityName.getName();
    vendor.represFirstLastname =
      pRegisterData.generalData.representEntityName.getFirstLastName();
    vendor.represSecondLastname =
      pRegisterData.generalData.representEntityName.getSecondLastName();
    vendor.represFullName = vendor.represName;
    if (vendor.represFirstLastname) {
      vendor.represFullName += ' ' + vendor.represFirstLastname;
    }

    if (vendor.represSecondLastname) {
      vendor.represFullName += ' ' + vendor.represSecondLastname;
    }

    vendor.providedCompany = pRegisterData.generalData.company.getId();

    //Payment
    vendor.paymentTermId =
      pRegisterData.paymentData.paymentTerm.getPaymTermId();
    vendor.accountId = pRegisterData.paymentData.bank.getBankGroupID();
    vendor.currency = pRegisterData.paymentData.currency.getCurrencyCodeId();
    vendor.accountNum = pRegisterData.paymentData.bankAccount;
    vendor.ABANum = pRegisterData.paymentData.ABANumber;
    vendor.SWIFTCode = pRegisterData.paymentData.SWIFTCode;

    //Contact
    vendor.phoneList = pRegisterData.contactData.phones;
    vendor.emailList = pRegisterData.contactData.emails;

    //Address
    vendor.AddressList = pRegisterData.addressData;

    //Document
    vendor.vatNumVendor = pRegisterData.documentData.vatNumVendor;
    vendor.vatNumRepres = pRegisterData.documentData.vatNumRepres;
    vendor.vatNumReprInvoice = pRegisterData.documentData.vatNumReprInvoice;
    vendor.vatNumReprService = pRegisterData.documentData.vatNumReprService;
    vendor.vendorAgreement = pRegisterData.documentData.vendorAgreement;
    vendor.transferAuthorization =
      pRegisterData.documentData.transferAuthorization;
    vendor.guaranteePolicies = pRegisterData.documentData.guaranteePolicies;

    return vendor;
  }

  parseRegisterNoDocument(pRegisterData: VendorRegisterData) {
    let vendor: Vendor = new Vendor();
    //General
    vendor.vendorVatnum = pRegisterData.generalData.vendorVatnum;
    vendor.vendorVatnumType =
      pRegisterData.generalData.vendorIdType.getCodCategory();

    vendor.vendorName = pRegisterData.generalData.vendorEntityName.getName();
    vendor.vendorFirstLastname =
      pRegisterData.generalData.vendorEntityName.getFirstLastName();
    vendor.vendorSecondLastname =
      pRegisterData.generalData.vendorEntityName.getSecondLastName();
    vendor.vendorFullName = vendor.vendorName;
    if (vendor.vendorFirstLastname) {
      vendor.vendorFullName += ' ' + vendor.vendorFirstLastname;
    }

    if (vendor.vendorSecondLastname) {
      vendor.vendorFullName += ' ' + vendor.vendorSecondLastname;
    }

    vendor.represVatnum = pRegisterData.generalData.representVatnum;
    vendor.represVatnumType =
      pRegisterData.generalData.representIdType.getCodCategory();

    vendor.represName = pRegisterData.generalData.representEntityName.getName();
    vendor.represFirstLastname =
      pRegisterData.generalData.representEntityName.getFirstLastName();
    vendor.represSecondLastname =
      pRegisterData.generalData.representEntityName.getSecondLastName();
    vendor.represFullName = vendor.represName;
    if (vendor.represFirstLastname) {
      vendor.represFullName += ' ' + vendor.represFirstLastname;
    }

    if (vendor.represSecondLastname) {
      vendor.represFullName += ' ' + vendor.represSecondLastname;
    }

    vendor.creatorVatnum = pRegisterData.generalData.creatorVatnum;
    vendor.providedCompany = pRegisterData.generalData.company.getId();

    //Payment
    vendor.paymentTermId =
      pRegisterData.paymentData.paymentTerm.getPaymTermId();
    vendor.accountId = pRegisterData.paymentData.bank.getBankGroupID();
    vendor.currency = pRegisterData.paymentData.currency.getCurrencyCodeId();
    vendor.accountNum = pRegisterData.paymentData.bankAccount;
    vendor.ABANum = pRegisterData.paymentData.ABANumber;
    vendor.SWIFTCode = pRegisterData.paymentData.SWIFTCode;

    //Contact
    vendor.phoneList = [];
    vendor.emailList = [];

    //Address
    vendor.AddressList = [];

    //Document
    // vendor.vatNumVendor = pRegisterData.documentData.vatNumVendor;
    // vendor.vatNumRepres = pRegisterData.documentData.vatNumRepres;
    // vendor.vatNumReprInvoice = pRegisterData.documentData.vatNumReprInvoice;
    // vendor.vatNumReprService = pRegisterData.documentData.vatNumReprService;
    // vendor.vendorAgreement = pRegisterData.documentData.vendorAgreement;
    // vendor.transferAuthorization = pRegisterData.documentData.transferAuthorization;
    // vendor.guaranteePolicies = pRegisterData.documentData.guaranteePolicies;

    return vendor;
  }
}
