import { Component, Input, OnInit } from '@angular/core';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';

@Component({
  selector: 'app-totales-oc',
  templateUrl: './totales-oc.component.html',
  styleUrls: ['./totales-oc.component.scss'],
})
export class TotalesOcComponent implements OnInit {
  @Input() purhInfoOC: PurchOrder;
  subTotalS: any;
  descuentoS: any;
  impuestoVenS: any;
  totalS: any;
  constructor() {}

  ngOnInit() {
    this.subTotalS = this.getCurrency(this.purhInfoOC.subTotal);
    this.descuentoS = this.getCurrency(this.purhInfoOC.totalDiscount);
    this.impuestoVenS = this.getCurrency(this.purhInfoOC.taxes);
    this.totalS = this.getCurrency(this.purhInfoOC.total);
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }
}
