import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
// import { ExternalServiceOCService } from 'src/app/services/ExternalServices/external-service-oc.service';

import { CatalogModel } from '../../../businessCore/oc-models/CatalogModel';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';
import { PurposeAllowedByRole } from '../../../businessCore/oc-models/PurposeAllowedByRole';
import { PurposeCategoriesAllowedByRole } from '../../../businessCore/oc-models/PurposeCategoriesAllowedByRole';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../../provider/Services/local-service-oc.service';
import { InfoFileOcPopComponent } from '../info-file-oc-pop/info-file-oc-pop.component';

import { InfoImage } from './../../../businessCore/check-models/InfoImage';
import { FileOCInfo } from './../../../businessCore/oc-models/FileOCInfo';
import { ServicesOcParameterProvider } from './../../../provider/Services/services-oc-parameter';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';

@Component({
  selector: 'app-pop-info-oc',
  templateUrl: './pop-info-oc.component.html',
  styleUrls: ['./pop-info-oc.component.scss'],
})
export class PopInfoOcComponent implements OnInit {
  listOption = [];
  private backButtonSub: Subscription;
  private purhInfoOC: PurchOrder;
  presentingMess = false;
  reenviar = false;
  loader: HTMLIonLoadingElement;
  constructor(
    private platform: Platform,
    private navParams: NavParams,
    private extServOC: ExternalServiceOCService,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private localServOC: LocalServiceOCService,
    public navCtrl: NavController,
    private viewCtrl: PopoverController,
    private modalCtrl: ModalController,
    private ocParameter: ServicesOcParameterProvider,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // Saca la OC que se manda como parametro
    this.purhInfoOC = this.navParams.get('infoOC');
    if (this.purhInfoOC && this.purhInfoOC !== undefined) {
      if (
        this.purhInfoOC.getDocumentStateI() === 20 || // 'RECHAZADO' === 20
        this.purhInfoOC.getDocumentStateI() === 10 || // 'EN REVISIÓN' // 'BORRADOR' === 0
        this.purhInfoOC.getIdOC() !== '' ||
        (this.purhInfoOC.getDocumentStateI() === 0 &&
          this.purhInfoOC.getIdOC() !== '')
      ) {
        this.reenviar = true;
      }
    }
  }

  ionViewDidEnter() {
    this.backButtonSub = this.platform.backButton.subscribe(() =>
      setTimeout(() => this.closePopOver(), 10000)
    );
  }

  async closePopOver() {
    /*this.popoverCtrl
    .dismiss (
      { click: clickUs, resultado: result}
    );*/
    this.viewCtrl.dismiss();
  }

  ionViewWillLeave() {
    this.backButtonSub.unsubscribe();
  }

  async sendOC() {
    let resp: any;
    if (this.purhInfoOC.getAccountNum() !== '') {
      const sitio =
        this.purhInfoOC.getSite() !== null &&
        this.purhInfoOC.getSite() !== undefined &&
        this.purhInfoOC.getSite().getId() !== '';
      const almacen =
        this.purhInfoOC.getLocation() !== null &&
        this.purhInfoOC.getLocation() !== undefined &&
        this.purhInfoOC.getLocation().getId() !== '';
      if (sitio && almacen) {
        if (this.dimensionFinancialValitation()) {
          //Axel Vargas
          let isValid = await this.validatedOCByPurpose();

          resp = isValid.resp;

          console.log('Factura Física', this.purhInfoOC.getNoPhysicalInvoice());

          if (isValid.isValid) {
            if (
              !this.extServOC.requireNoPhysicalInvoice ||
              (this.extServOC.requireNoPhysicalInvoice &&
                this.purhInfoOC.getNoPhysicalInvoice() !== '')
            ) {
              //Axel Vargas
              await this.showSpinnerLoading('Enviando datos de OC.');
              try {
                // Se setea el estado a EN revisión, para que quede con este estado al crearce.
                // 'EN REVISIÓN' === 10 | 'BORRADOR' === 0
                this.purhInfoOC.setDocumentStateI(0);
                const headerJSON = this.purhInfoOC.headerToJSON();
                const linesJSON = this.purhInfoOC.linesToJSON();
                resp = await this.extServOC.sendOC(headerJSON, linesJSON);
              } catch (error) {
                resp = error;
                // Si hay un error se setea el estado de nuevo en borrador.
                this.purhInfoOC.setDocumentStateI(0);
              }
              this.dissmissLoading();
            } else {
              resp =
                'No se puede realizar esta OC, debido a que requiere el número de factura física.';
            }
          }
        } else {
          resp = 'NO_DIMENSION';
        }
      } else {
        resp = 'NO_SIDE_LOCATION';
      }
    } else {
      resp = 'NO_VEND';
    }
    this.proccesResult(resp);
  }

  dimensionFinancialValitation() {
    let resultado = false;
    if (
      this.purhInfoOC.getA_Departamento() !== null &&
      this.purhInfoOC.getA_Departamento() !== undefined &&
      this.purhInfoOC.getB_CentroCosto() !== null &&
      this.purhInfoOC.getB_CentroCosto() !== undefined &&
      this.purhInfoOC.getC_Proposito() !== null &&
      this.purhInfoOC.getC_Proposito() !== undefined
    ) {
      if (
        (this.purhInfoOC.getA_Departamento().getId() as any) === '' &&
        (this.purhInfoOC.getB_CentroCosto().getId() as any) === '' &&
        (this.purhInfoOC.getC_Proposito().getId() as any) === ''
      ) {
        if (this.purhInfoOC.getLineList().length > 0) {
          resultado = true;
        }
      } else {
        resultado = true;
      }
    } else {
      if (this.purhInfoOC.getLineList().length > 0) {
        this.purhInfoOC.setA_Departamento(new CatalogModel('' as any, ''));
        this.purhInfoOC.setB_CentroCosto(new CatalogModel('' as any, ''));
        this.purhInfoOC.setC_Proposito(new CatalogModel('' as any, ''));
        resultado = true;
      }
    }
    return resultado;
  }

  /**
   * Funcion que guarda la informacion de una OC de manera L
   * @param newOC
   * @param showM
   */

  async saveOCLocalDB(newOC: PurchOrder, showM: boolean, saveFile: boolean) {
    // Le setea los datos de los archivos guardados localmente
    if (saveFile) {
      this.setFileToSend(newOC);
    }

    if (await this.localServOC.saveOC(newOC)) {
      if (showM) {
        this.messageInfo(
          'Guardada',
          'Los datos de la OC fueron guardados de forma correcta.',
          true
        );
      }
    } else {
      this.messageInfo(
        'Error',
        'No se pudo guardar la OC, intente de nuevo.',
        false
      );
    }
  }

  setFileToSend(ocInfo: PurchOrder) {
    if (ocInfo !== null && ocInfo !== undefined) {
      if (this.ocParameter.getListFile().length > 0) {
        const listFiles = this.ocParameter
          .getListFile()
          .filter((data) => !data.getSend());
        ocInfo.setListInfoFile(listFiles);
        this.ocParameter.setListFile([]); //Setea una lista vacia para evitar errores om-2127 om-2128
      }
    }
  }

  async deleteOCLocalDB(deletePurch: PurchOrder) {
    if (await this.localServOC.deleteOC(deletePurch)) {
      this.messageInfo(
        'Eliminada',
        'Los datos de la OC fueron elimandos de forma correcta.',
        true
      );
    } else {
      this.messageInfo(
        'Error',
        'No se pudo guardar la OC, intente de nuevo.',
        false
      );
    }
  }

  async proccesResult(resultado: any) {
    console.log('Este es el resultado: ', resultado, ' ', typeof resultado);
    if (typeof resultado === 'number' && resultado === 500) {
      this.messageInfo(
        'Error',
        'No se pudo realizar la orden de compra, intente de nuevo.',
        false
      );
    } else if (typeof resultado === 'string' && resultado === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se pudo comunicar con el servidor, intente de nuevo.',
        false
      );
    } else if (typeof resultado === 'string' && resultado === 'NO_VEND') {
      this.messageInfo(
        'Error',
        'No se puede finalizar la orden, no hay datos del proveedor.',
        false
      );
    } else if (
      typeof resultado === 'string' &&
      resultado === 'NO_SIDE_LOCATION'
    ) {
      this.messageInfo(
        'Error',
        'No se puede finalizar la orden, no hay datos del sitio o almacén.',
        false
      );
    } else if (typeof resultado === 'string' && resultado === 'NO_DIMENSION') {
      this.messageInfo(
        'Error',
        'No se puede finalizar la orden, no hay datos de dimensiones financieras.',
        false
      );
    } else if (
      typeof resultado === 'string' &&
      resultado.includes('No se puede realizar esta OC')
    ) {
      //Axel Vargas
      this.messageInfo('Error', resultado, false);
    } else if (typeof resultado === 'string') {
      this.purhInfoOC.setIdOC(resultado);
      this.sendFileAux(); // Envia datos de archivos y guarda los datos de forma local

      /*//Se envian los datos de los documentos, si hay datos...
      await this.showSpinnerLoading('Enviando datos de archivos');
      await this.setImageAux(this.purhInfoOC.getIdOC(), this.purhInfoOC.getDataArea().getId(), 15); 
      this.dissmissLoading();

      //Finaliza la creacion de la OC desde AX
      await this.showSpinnerLoading('Guardando datos de OC.');
      // Se guarda la OC en la base de datos local
      await this.saveOCLocalDB(this.purhInfoOC, false, false);
      this.dissmissLoading();
      this.messageInfo('OC Creada', 'Orden de compra creada con éxito', true, resultado);*/
    } else {
      this.messageInfo('Error', 'No se pudo ejecutar la acción', false);
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async messageInfo(
    titulo: string,
    mensaje: string,
    go: boolean,
    subH?: string
  ) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: subH,
        message: mensaje,
        buttons: [
          {
            text: 'Aceptar',
            handler: () => {
              if (go) {
                this.navCtrl.navigateRoot(
                  NavigationRoutes.PurchOrderRecordPage
                );
              }
            },
          },
        ],
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  sendDataOC() {
    if (this.purhInfoOC) {
      this.closePopOver();
      this.messageInfo2('Atención', '¿Desea finalizar la orden de compra?', 1);
    } else {
      this.messageInfo(
        'Error',
        'No hay datos de la OC para poder enviar.',
        false
      );
    }
  }

  saveDataOC() {
    if (this.purhInfoOC) {
      this.closePopOver();
      this.messageInfo2('Atención', '¿Desea guardar la orden de compra?', 2);
    } else {
      this.messageInfo(
        'Error',
        'No hay datos de la OC para poder guardar.',
        false
      );
    }
  }

  deleteDataOC() {
    if (this.purhInfoOC) {
      this.closePopOver();
      this.messageInfo2('Atención', '¿Desea borrar la orden de compra?', 3);
    } else {
      this.messageInfo(
        'Error',
        'No hay datos de la OC para poder eliminar.',
        false
      );
    }
  }

  /**
   *
   * @param type 1 SendOC | 2 save | 3 delete | 4 sendFile
   *
   */
  executedOperation(type: number) {
    switch (type) {
      case 1: // Enviar OC
        this.sendOC();
        break;
      case 2:
        this.saveOCLocalDB(this.purhInfoOC, true, true);
        break;
      case 3:
        this.deleteOCLocalDB(this.purhInfoOC);
        break;
      case 4:
        this.sendFileAux();
        break;
      default:
        break;
    }
  }

  async messageInfo2(
    titulo: string,
    mensaje: string,
    type: number,
    subH?: string
  ) {
    if (!this.presentingMess) {
      let optionsB = () => {};
      if (type === 4) {
        optionsB = () => {
          this.endStepSave();
        };
      }

      const alert = await this.alertController.create({
        header: titulo,
        subHeader: subH,
        message: mensaje,
        buttons: [
          {
            text: 'SI',
            handler: () => {
              this.executedOperation(type);
            },
          },
          { text: 'NO', handler: optionsB },
        ],
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  updateDataOC() {
    // Se manda los datos del encabezado y luego las lineas
    // por ultimo se desbloquea la orden
    this.closePopOver();
    this.sendDataOCToUpdate(
      'Atención',
      '¿Cambios realizados a la orden, desea actualizar los cambios?',
      this.purhInfoOC
    );
  }

  async canSendToUpdate() {
    const newState = 0; // 'BORRADOR'=== 0Para entrar en modo edicion
    const cedula = await this.localServOC.getCedulaActual();
    await this.showSpinnerLoading('Enviando datos de OC.');
    try {
      const resp = await this.extServOC.editingMode(
        newState,
        this.purhInfoOC.getIdOC(),
        this.purhInfoOC.getDataArea().getId(),
        cedula
      );
      if (typeof resp === 'string') {
        // Por cualquier cosa
        switch (resp) {
          case 'CANNOTEDIT':
            this.messageInfo(
              'Error',
              'La orden está siendo editada por otro usuario.',
              true
            );
            break;
          case 'EDITING':
            this.updatePurchaseOrder(); // Si pudo entrar o ya estaba en modo edicion
            break;
          default:
            this.messageInfo(
              'Error',
              'No se pudo entrar al modo edición.',
              false
            );
            break;
        }
      } else {
        this.messageInfo('Error', 'No se pudo entrar al modo edición.', false);
      }
    } catch (error) {
      this.messageInfo(
        'Error',
        'No se puede editar la orden en este momento, no se pudo comunicar con el servidor.',
        false
      );
    }
    this.dissmissLoading();
  }

  async sendDataOCToUpdate(
    titulo: string,
    mensaje: string,
    purchInfo: PurchOrder
  ) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [
          {
            text: 'ACEPTAR',
            handler: () => {
              this.canSendToUpdate();
            },
          },
          {
            text: 'CANCELAR',
            handler: async () => {
              this.cancelarEdicion();
            },
          },
        ],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async updatePurchaseOrder() {
    let resp: any;
    if (this.purhInfoOC.getAccountNum() !== '') {
      const sitio =
        this.purhInfoOC.getSite() !== null &&
        this.purhInfoOC.getSite() !== undefined &&
        this.purhInfoOC.getSite().getId() !== '';
      const almacen =
        this.purhInfoOC.getLocation() !== null &&
        this.purhInfoOC.getLocation() !== undefined &&
        this.purhInfoOC.getLocation().getId() !== '';
      if (sitio && almacen) {
        if (this.dimensionFinancialValitation()) {
          let titulo = '';
          let mensaje = '';
          let back = false;
          await this.showSpinnerLoading('Enviando datos de OC.');
          try {
            const cedula = await this.localServOC.getCedulaActual();
            const header = this.purhInfoOC.headerToJSON();
            resp = await this.extServOC.updateHeaderPurchase(header);
            if (resp === 'SUCCESS') {
              const lines = this.purhInfoOC.linesToJSON();
              resp = await this.extServOC.updateLinePurchaseOrder(
                this.purhInfoOC.getIdOC(),
                this.purhInfoOC.getDataArea().getId(),
                lines
              );
              if (resp === 'SUCCESS') {
                const newState = 10; // 'EN REVISIÓN' === 10;
                resp = await this.extServOC.editingMode(
                  newState,
                  this.purhInfoOC.getIdOC(),
                  this.purhInfoOC.getDataArea().getId(),
                  cedula
                );
                if (typeof resp === 'string') {
                  // Por cualquier cosa
                  switch (resp) {
                    case 'SUCCESS':
                      titulo = 'Atención';
                      mensaje =
                        'La orden de compra ha sido actualizada ' +
                        this.purhInfoOC.getIdOC();
                      this.purhInfoOC.setDocumentStateI(newState);
                      this.localServOC.upDate(this.purhInfoOC);
                      back = true;
                      break;
                    case 'CANNOTEDIT':
                      titulo = 'Error';
                      mensaje =
                        'La orden está siendo editada por otro usuario.';
                      back = true;
                      break;
                    case 'EDITING':
                      titulo = 'Atención';
                      mensaje = 'Puede editar la orden.';
                      break;
                    default:
                      titulo = 'Error';
                      mensaje = 'No se pudo salir o entrar al modo edición.';
                      break;
                  }
                } else {
                  titulo = 'Error';
                  mensaje = 'No se pudo salir o entrar al modo edición.';
                }
              } else {
                titulo = 'Error';
                mensaje =
                  'No se pudo actualizar las líneas de la orden de compra.';
              }
            } else {
              titulo = 'Error';
              mensaje =
                'No se pudo actualizar el encabezado de la orden de compra.';
            }
          } catch (error) {
            resp = error;
            titulo = 'Error';
            mensaje =
              'No se puede editar la orden en este momento, no se pudo comunicar con el servidor.';
          }
          // Verificar las respuesta
          this.dissmissLoading();
          this.messageInfo(titulo, mensaje, back);
        } else {
          this.messageInfo(
            'Error',
            'No se puede finalizar la orden, no hay datos de dimensiones financieras.',
            false
          );
        }
      } else {
        this.messageInfo(
          'Error',
          'No se puede finalizar la orden, no hay datos del sitio o almacén.',
          false
        );
      }
    } else {
      this.messageInfo(
        'Error',
        'No se puede finalizar la orden, no hay datos del proveedor.',
        false
      );
    }
  }

  cantFinalizar() {
    return (
      this.purhInfoOC.getDocumentStateI() === 0 || // 0 === 'BORRADOR'
      this.purhInfoOC.getDocumentStateI() === 20 || // 20 === 'RECHAZADO'
      this.purhInfoOC.getDocumentStateI() === 10
    ); // 10 === 'EN REVISIÓN';
  }

  async cancelarEdicion() {
    let resp: any;
    let titulo = '';
    let mensaje = '';
    await this.showSpinnerLoading('Saliendo del modo edición.');
    try {
      const cedula = await this.localServOC.getCedulaActual();
      const newState = 10; // 'EN REVISIÓN' === 10;
      resp = await this.extServOC.editingMode(
        newState,
        this.purhInfoOC.getIdOC(),
        this.purhInfoOC.getDataArea().getId(),
        cedula
      );
      if (typeof resp === 'string') {
        // Por cualquier cosa
        switch (resp) {
          case 'SUCCESS':
            titulo = 'Atención';
            mensaje = 'Se  ha desbloquedo la OC para ser editada.';
            break;
          case 'CANNOTEDIT':
          case 'EDITING':
          default:
            titulo = 'Error';
            mensaje = 'No se pudo salir o entrar al modo edición.';
            break;
        }
      } else {
        titulo = 'Error';
        mensaje = 'No se pudo salir o entrar al modo edición.';
      }
    } catch (error) {
      resp = error;
      titulo = 'Error';
      mensaje = 'No se pudo comunicar con el servidor.';
    }
    this.dissmissLoading();
    this.messageInfo(titulo, mensaje, true);
  }

  async saveFilesOC() {
    const cedula = await this.localServOC.getCedulaActual();
    const modal = await this.modalCtrl.create({
      component: InfoFileOcPopComponent,
      componentProps: {
        fileList: this.ocParameter.getListFile(),
        cantSendFile: !this.reenviar,
        ocId: this.purhInfoOC.getIdOC(),
        vatnum: cedula,
        company: this.purhInfoOC.getDataArea().getId(),
        type: 1,
      },
      backdropDismiss: false,
    });

    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.listFile && !this.reenviar) {
          this.ocParameter.setListFile(data.listFile);
        }
      }
    });
    //this.closePopOver();
  }

  /**
   *
   * @param ocId
   * @param company
   * @param typeAction Action tipo 15 Creador OC | 16 Confirmador OC
   */
  async setImageAux(ocId: string, company: string, typeAction: number) {
    const listFile = this.ocParameter.getListFile();
    const vatNum = await this.localServOC.getCedulaActual();
    if (listFile.length > 0) {
      for (let i = 0; i < listFile.length; i++) {
        // Archivos tipo 1 Orden de Compra
        // Action tipo 15 Creador OC | 16 Confirmador OC
        let infoFile = listFile[i];
        if (!infoFile.getSend()) {
          const result = await this.loadImage(
            infoFile,
            typeAction,
            company,
            vatNum,
            ocId
          );
          if (result === 1) {
            infoFile.setSend(true);
          }
        }
      }
    }
  }

  async loadImage(
    fileInfo: FileOCInfo,
    typeAction: number,
    company: string,
    vatnumUser: string,
    ocId: string
  ) {
    let result = 0;
    try {
      const infoData = new InfoImage();
      infoData.setDataImage(fileInfo.getFileData());
      infoData.extension = fileInfo.getFileExtension() as string;
      infoData.action = typeAction;
      infoData.type = 1; //OC
      infoData.vatnum = vatnumUser;
      infoData.recIdAnswer = ocId as any; //ElementeId
      infoData.company = company;
      result = (await this.extServOC.uploadImge(infoData)) as number;
    } catch (error) {}
    return result;
  }

  async sendFileAux() {
    //Se envian los datos de los documentos, si hay datos...
    await this.showSpinnerLoading('Enviando datos de archivos');
    await this.setImageAux(
      this.purhInfoOC.getIdOC(),
      this.purhInfoOC.getDataArea().getId(),
      15
    );
    this.dissmissLoading();

    const restantes = this.ocParameter
      .getListFile()
      .find((fileD) => !fileD.getSend());
    if (restantes) {
      // Aun quedan restantes
      this.messageInfo2(
        'Atención',
        '¿Desea enviar los documentos restantes no enviados?',
        4
      );
    } else {
      this.endStepSave();
    }
  }

  async endStepSave() {
    //Finaliza la creacion de la OC desde AX
    await this.showSpinnerLoading('Guardando datos de OC.');
    // Se guarda la OC en la base de datos local
    await this.saveOCLocalDB(this.purhInfoOC, false, false);
    this.dissmissLoading();
    this.messageInfo(
      'OC Creada',
      'Orden de compra creada con éxito',
      true,
      this.purhInfoOC.getIdOC()
    );
  }

  //Axel Vargas
  async validatedOCByPurpose(): Promise<{ isValid: boolean; resp: string }> {
    const listPurposes: PurposeAllowedByRole[] = this.extServOC.listPurposes;
    const listPurposesCategories: PurposeCategoriesAllowedByRole[] =
      this.extServOC.listPurposesCategories;

    if (
      listPurposes.some(
        (x) => x.Purpose === this.purhInfoOC.getPurpose().getId().toString()
      )
    ) {
      if (
        listPurposesCategories.some(
          (x) => x.PurposeCategory === this.purhInfoOC.getPurposeCategory()
        )
      ) {
        if (
          listPurposesCategories.some(
            (x) =>
              x.PurposeCategory === this.purhInfoOC.getPurposeCategory() &&
              new Date(x.RegisteredDate).toDateString() ===
                new Date().toDateString()
          )
        ) {
          let purposeCategory = listPurposesCategories.find(
            (x) =>
              x.PurposeCategory === this.purhInfoOC.getPurposeCategory() &&
              new Date(x.RegisteredDate).toDateString() ===
                new Date().toDateString()
          );

          let minDateTime = new Date(
            purposeCategory.RegisteredDate.replace(
              '00:00:00',
              purposeCategory.RangeStartTime.replace(' ', '0')
            )
          );
          let maxDateTime = new Date(
            purposeCategory.RegisteredDate.replace(
              '00:00:00',
              purposeCategory.RangeEndTime.replace(' ', '0')
            )
          );
          if (
            (new Date() >= minDateTime && new Date() <= maxDateTime) ||
            (minDateTime.getHours() === 0 && maxDateTime.getHours() === 0)
          ) {
            if (this.purhInfoOC.total <= purposeCategory.DiaryAmountAllowed) {
              let isValid: number =
                await this.extServOC.canGenerateNewPurchByPurposeCategory(
                  this.purhInfoOC.getDataArea().getId(),
                  this.purhInfoOC.getPurposeCategory(),
                  this.extServOC.roleSpecificId
                );
              switch (isValid) {
                case 0:
                  return {
                    isValid: false,
                    resp: `No se puede realizar esta OC, debido a que ingresó el número máximo de OCs, con la Categoría: ${this.purhInfoOC.getPurposeCategory()}`,
                  };

                case 1:
                  return { isValid: true, resp: '' };

                default:
                  return {
                    isValid: false,
                    resp: 'No se puede realizar esta OC, debido a un problema con el servidor.',
                  };
              }
            } else {
              return {
                isValid: false,
                resp: `No se puede realizar esta OC, el monto ingresado excede al monto máximo permitido.\n El monto permitido es: ${purposeCategory.DiaryAmountAllowed}`,
              };
            }
          } else {
            return {
              isValid: false,
              resp: `No se puede realizar esta OC, debido que está fuera del rango de tiempo permitido. \n Entre: \n ${minDateTime} \n ${maxDateTime}`,
            };
          }
        } else {
          return {
            isValid: false,
            resp: 'No se puede realizar esta OC, debido que está fuera del rango de fechas permitidas.',
          };
        }
      } else {
        return {
          isValid: false,
          resp: 'No se puede realizar esta OC, se debe seleccionar una categoría de próposito de OC.',
        };
      }
    }

    return { isValid: true, resp: '' };
  }
  //Axel Vargas
}
