import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Money } from '../../GeneralUtilis/money';
import { OrderLine } from '../../businessCore/OrderLine';
import { OrderTraceItem } from '../../businessCore/OrderTraceItem';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-order-trace-lines',
  templateUrl: './order-trace-lines.component.html',
  styleUrls: ['./order-trace-lines.component.scss'],
})
export class OrderTraceLinesComponent {
  parameter: OrderTraceItem;
  orderLines: OrderLine[] = [];

  /**
   * Class Constructor with these injections:
   * @param _apiProvider web service provider
   * @param params object that exists on a page and can contain data for that particular view.
   */
  constructor(_apiProvider: webServiceProvider, private params: NavParams) {
    this.parameter = this.params.get('item');

    //DEBUG
    //this.parameter = new OrderTraceItem();
    //DEBUG
    // Consulta de productos asociados a la orden de venta
    _apiProvider
      .getOrderTraceLines(this.parameter.salesAxId)
      .subscribe((data) => {
        this.orderLines = data as OrderLine[];

        this.orderLines.forEach((_, index) => {
          this.orderLines[index] = OrderLine.convertOrderLine(
            this.orderLines[index]
          );
          this.orderLines[index].salePrice += this.orderLines[index].taxProduct;
          this.orderLines[index].totalSale =
            this.orderLines[index].salePrice *
            this.orderLines[index].salesQuantity;
        });
      });
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   * @returns <number> con el formato de dinero asociado
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }
}
