import { AssetAudit } from '../businessCore/AssetAudit';
import { Certification } from '../businessCore/Certification';
import { FixedAssetTransfer } from '../businessCore/FixedAssetTransfer';
import { Vendor } from '../businessCore/vendor';

export class Request {
  /**
   * Crea el xml a mandar para obtener la lista de los assets
   *
   * @param barcode Indicador para indicar el trabajador en cuestión.
   * @returns Un XML con el encabezado necesario para realizar un post.
   */
  XMLGetAsset(barcode: string, assetRecId: number) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <GetAsset xmlns="http://tempuri.org/">
            <barcode>` +
      barcode +
      `</barcode>
            <assetRecId>` +
      assetRecId +
      `</assetRecId>
            </GetAsset>
        </s:Body>
        </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para obtener la lista de los assets
   *
   * @param personnelNumber Indicador para indicar el trabajador en cuestión.
   * @returns Un XML con el encabezado necesario para realizar un post.
   */
  XMLGetAssetsByWorker(personnelNumber: string) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <GetAssetsByWorker xmlns="http://tempuri.org/">
            <personnelNumber>` +
      personnelNumber +
      `</personnelNumber>
            </GetAssetsByWorker>
        </s:Body>
        </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para obtener la lista de los trabajadores activos.
   *
   * @param personnelNumber Identificador del trabajador específico que se busca.
   * @param vatnum Cédula del trabajador específico que se busca.
   *
   * @returns Un XML con el encabezado necesario para realizar un post
   */
  XMLGetWorker(personnelNumber: string, vatnum: string, recId: number) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <GetWorker xmlns="http://tempuri.org/">
        <personnelNumber>` +
      personnelNumber +
      `</personnelNumber>
        <vatnum>` +
      vatnum +
      `</vatnum> 
        <recId>` +
      recId +
      `</recId> 
        </GetWorker>
      </s:Body>
    </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para obtener la lista de los trabajadores activos.
   *
   * @param personnelNumber Identificador del trabajador específico que se busca.
   * @param vatnum Cédula del trabajador específico que se busca.
   *
   * @returns Un XML con el encabezado necesario para realizar un post
   */
  XMLGetWorkerByFinancialDimension(
    displayValue: string,
    dimensionName: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <GetWorkerByFinancialDimension xmlns="http://tempuri.org/">
        <displayValue>` +
      displayValue +
      `</displayValue>
        <dimensionName>` +
      dimensionName +
      `</dimensionName> 
        </GetWorkerByFinancialDimension>
      </s:Body>
    </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para obtener la lista de las localidades de los assets
   *
   * @returns Un XML con el encabezado necesario para realizar un post
   */
  XMLGetAssetLocation(workerResponsible, location) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
        <GetAssetLocation xmlns="http://tempuri.org/">
        <workerResponsible>` +
      workerResponsible +
      `</workerResponsible>
        <location>` +
      location +
      `</location>
        </GetAssetLocation>
    </s:Body>
    </s:Envelope>`
    );
  }

  XMLGetAssetRequest(pStatus: number, pRecId: string) {
    if (pRecId == '') {
      pRecId = '0';
    }
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetAssetRequest xmlns="http://tempuri.org/">
            <pStatus>` +
      pStatus +
      `</pStatus>
            <pRecId>` +
      pRecId +
      `</pRecId>
            </GetAssetRequest>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLGetSendAssetRequest(pStatus: number, pRecId: string) {
    if (pRecId == '') {
      pRecId = '0';
    }
    return (
      `
      <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
          <GetSendAssetRequest xmlns="http://tempuri.org/">
          <pStatus>` +
      pStatus +
      `</pStatus>
          <pRecId>` +
      pRecId +
      `</pRecId>
          </GetSendAssetRequest>
        </s:Body>
      </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para enviar la lista de los assets que será transferida.
   *
   * @param list Lista de FixedAssetTransfer que se convertira en string.
   * @returns Un XML con el encabezado necesario para realizar un post.
   */
  XMLTransferAsset(list: FixedAssetTransfer[]) {
    const assetList = JSON.stringify(list);
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <TransferAsset xmlns="http://tempuri.org/">
            <assetList>` +
      assetList +
      `</assetList>
            </TransferAsset>
        </s:Body>
        </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para enviar la lista de los assets que será transferida.
   *
   * @param list Lista de FixedAssetTransfer que se convertira en string.
   * @param typeAction valor que indicará si debe aprobar, aceptar o rechazar la solicitud.
   * @returns Un XML con el encabezado necesario para realizar un post.
   */
  XMLAlterAssetRequest(list: FixedAssetTransfer[], pStatus: number) {
    const assetRequestList = JSON.stringify(list);
    console.log(assetRequestList)
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <AlterAssetRequest xmlns="http://tempuri.org/">
                <pAssetRequestList>` +
      assetRequestList +
      `</pAssetRequestList>
                <pStatus>` +
      pStatus +
      `</pStatus>
            </AlterAssetRequest>
        </s:Body>
        </s:Envelope>`
    );
  }

  XMLGetComponentsByUserId(pVatnum: string) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <GetComponentsByUserId xmlns="http://tempuri.org/">
                <VatNum>` +
      pVatnum +
      `</VatNum>
            </GetComponentsByUserId>
        </s:Body>
        </s:Envelope>`
    );
  }

  XMLGetAssociatedPersonInfo(pVatnum: string) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <GetAssociatedPersonInfo xmlns="http://tempuri.org/">
                <vatNum>` +
      pVatnum +
      `</vatNum>
            </GetAssociatedPersonInfo>
        </s:Body>
        </s:Envelope>`
    );
  }

  toXMLSendEmail(msg_body: string, to: string, subject: string) {
    return (
      `
            <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
            <s:Body>
                <SendEmail xmlns="http://tempuri.org/">
                  <msg_body>` +
      msg_body +
      `</msg_body>
                  <to>` +
      to +
      `</to>
                  <subject>` +
      subject +
      `</subject>
                </SendEmail>
            </s:Body>
            </s:Envelope>`
    );
  }

  XMLGetDataAreaByComponent(pComponentId: number) {
    return (
      `
            <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
              <s:Body>
                <GetDataAreaByComponent xmlns="http://tempuri.org/">
                  <pComponentId>` +
      pComponentId +
      `</pComponentId>
                </GetDataAreaByComponent>
              </s:Body>
            </s:Envelope>`
    );
  }

  XMLGetEntityName(pVatnum: string, pIdentifierType: number) {
    return (
      `
            <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
              <s:Body>
                <GetEntityName xmlns="http://tempuri.org/">
                    <vatnum>` +
      pVatnum +
      `</vatnum>
                    <pIdentifierType>` +
      pIdentifierType +
      `</pIdentifierType>
                </GetEntityName>
              </s:Body>
            </s:Envelope>`
    );
  }

  XMLGetPaymentTerm() {
    return `
          <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
            <s:Body>
              <GetPaymentTerm xmlns="http://tempuri.org/">
              </GetPaymentTerm>
            </s:Body>
          </s:Envelope>`;
  }

  XMLGetBank() {
    return `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetBank xmlns="http://tempuri.org/">
            </GetBank>
          </s:Body>
        </s:Envelope>`;
  }

  XMLGetCurrency() {
    return `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetAllCurrencyData xmlns="http://tempuri.org/">
            </GetAllCurrencyData>
          </s:Body>
        </s:Envelope>`;
  }

  XMLGetValidationCode(pEmail: string) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetValidationCode xmlns="http://tempuri.org/">
              <email>` +
      pEmail +
      `</email>
            </GetValidationCode>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLRegisterVendor(pVendorInfo: Vendor) {
    let JSONvendorInfo = JSON.stringify(pVendorInfo);
    JSONvendorInfo = JSONvendorInfo.replace(new RegExp('&', 'g'), '&amp;');
    // console.log("Json", JSONvendorInfo);
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <RegisterVendorAux xmlns="http://tempuri.org/">
              <vendorInfo>` +
      JSONvendorInfo +
      `</vendorInfo>
            </RegisterVendorAux>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLRegisterVendContactAux(
    pPhoneList: any,
    pEmailList: any,
    pVendVatnum: string,
    pVendTableAuxRecId: string
  ) {
    const phoneListJSON = JSON.stringify(pPhoneList);
    const emailListJSON = JSON.stringify(pEmailList);
    // console.log(phoneListJSON);
    // console.log(emailListJSON);
    // console.log(pVendVatnum);
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <RegVendContact xmlns="http://tempuri.org/">
              <phoneList>` +
      phoneListJSON +
      `</phoneList>
              <emailList>` +
      emailListJSON +
      `</emailList>
              <vendorVatnum>` +
      pVendVatnum +
      `</vendorVatnum>
              <pVendTableAuxRecId>` +
      pVendTableAuxRecId +
      `</pVendTableAuxRecId>
            </RegVendContact>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLRegisterVendAddressAux(
    pAddressList: any,
    pVendVatnum: string,
    pVendTableAuxRecId: string
  ) {
    const addressListJSON = JSON.stringify(pAddressList);
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <RegVendAddress xmlns="http://tempuri.org/">
              <addressList>` +
      addressListJSON +
      `</addressList>
              <vendorVatnum>` +
      pVendVatnum +
      `</vendorVatnum>
              <pVendTableAuxRecId>` +
      pVendTableAuxRecId +
      `</pVendTableAuxRecId>
            </RegVendAddress>
          </s:Body>
        </s:Envelope>`
    );
  }

  /**
   *
   * @param base64
   * @param imgExtension
   * @param action
   * @param type
   * @param personVatnum
   * @param elementId
   * @param company
   * @returns
   */
  XMLUploadImage(
    base64: string,
    imgExtension: string,
    action: number,
    type: number,
    personVatnum: string,
    elementId: string,
    company: string
  ) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <UploadImage xmlns="http://tempuri.org/">
                <base64>` +
      base64 +
      `</base64>
                <extension>` +
      imgExtension +
      `</extension>
                <action>` +
      action +
      `</action>
                <type>` +
      type +
      `</type>
                <personVatnum>` +
      personVatnum +
      `</personVatnum>
                <elementId>` +
      elementId +
      `</elementId>
                <company>` +
      company +
      `</company>
            </UploadImage>
        </s:Body>
        </s:Envelope>`
    );
  }

  /**
   * Crea el xml a mandar para enviar la lista de los assets que será transferida.
   *
   * @param list Lista de FixedAssetTransfer que se convertira en string.
   * @returns Un XML con el encabezado necesario para realizar un post.
   */
  XMLCertificate(list: Certification[], workerVatnum: string) {
    const certificateList = JSON.stringify(list);
    return (
      `
      <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <Certificate xmlns="http://tempuri.org/">
          <certificationList>` +
      certificateList +
      `</certificationList>
          <personVatnum>` +
      workerVatnum +
      `</personVatnum>
          </Certificate>
      </s:Body>
      </s:Envelope>`
    );
  }

  /**
   *
   * @returns
   */
  XMLGetCertificationPeriodicity(
    pWorkerRecId: string,
    pCertificationId: string
  ) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetCertificationPeriodicity xmlns="http://tempuri.org/">
            <pUserRecId>` +
      pWorkerRecId +
      `</pUserRecId>
            <pCertificationId>` +
      pCertificationId +
      `</pCertificationId>
            </GetCertificationPeriodicity>
          </s:Body>
        </s:Envelope>`
    );
  }

  /**
   *
   * @returns
   */
  XMLCheckPeriod(
    pWorkerRecId: string,
    pCertificationId: string,
    pStrDate: string,
    pPeriodType: number
  ) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <CheckPeriod xmlns="http://tempuri.org/">
            <pUserRecId>` +
      pWorkerRecId +
      `</pUserRecId>
            <pCertificationId>` +
      pCertificationId +
      `</pCertificationId>
            <pStrDate>` +
      pStrDate +
      `</pStrDate>
            <pPeriodType>` +
      pPeriodType +
      `</pPeriodType>
            </CheckPeriod>
          </s:Body>
        </s:Envelope>`
    );
  }

  /**
   *
   * @returns
   */
  XMLIsCertPeriodValid(
    pWorkerRecId: string,
    pCertificationId: string,
    pStrDate: string
  ) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <IsCertPeriodValid xmlns="http://tempuri.org/">
            <pUserRecId>` +
      pWorkerRecId +
      `</pUserRecId>
            <pCertificationId>` +
      pCertificationId +
      `</pCertificationId>
            <pStrDate>` +
      pStrDate +
      `</pStrDate>
            </IsCertPeriodValid>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLCanCertificateOutsidePeriod(
    pWorkerRecId: string,
    pCertificationId: string
  ) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <CanCertificateOutsidePeriod xmlns="http://tempuri.org/">
            <pUserRecId>` +
      pWorkerRecId +
      `</pUserRecId>
            <pCertificationId>` +
      pCertificationId +
      `</pCertificationId>
            </CanCertificateOutsidePeriod>
          </s:Body>
        </s:Envelope>`
    );
  }

  /**
   *
   * @returns
   */
  XMLCreateAssetCertificationPdf(
    pWorkerFullName: string,
    pWorkerVatnum: string,
    pPeriod: string,
    pAssetList: any[]
  ) {
    let assetList = JSON.stringify(pAssetList);
    assetList = assetList.replace(new RegExp('&', 'g'), '&amp;');
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <CreateAssetCertificationPdf xmlns="http://tempuri.org/">
            <responsibleFullName>` +
      pWorkerFullName +
      `</responsibleFullName>
            <responsibleVatnum>` +
      pWorkerVatnum +
      `</responsibleVatnum>
            <period>` +
      pPeriod +
      `</period>
            <assetList>` +
      assetList +
      `</assetList>
            </CreateAssetCertificationPdf>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLAlterCertRecord(list: Certification[], pStatus: number) {
    const cerRecordList = JSON.stringify(list);
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
        <s:Body>
            <AlterCertRecord xmlns="http://tempuri.org/">
                <pCertRecordList>` +
      cerRecordList +
      `</pCertRecordList>
                <pStatus>` +
      pStatus +
      `</pStatus>
            </AlterCertRecord>
        </s:Body>
        </s:Envelope>`
    );
  }

  /**
   *
   * @param pStatus
   * @param pRecId
   * @returns
   */
  XMLGetPendingCertification(pCertificationId: string, pApproverRecId: string) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetPendingCertification xmlns="http://tempuri.org/">
            <pCertificationId>` +
      pCertificationId +
      `</pCertificationId>
            <pApproverRecId>` +
      pApproverRecId +
      `</pApproverRecId>
            </GetPendingCertification>
          </s:Body>
        </s:Envelope>`
    );
  }

  XMLGetAuditFinding() {
    return `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetAuditFinding xmlns="http://tempuri.org/">
            </GetAuditFinding>
          </s:Body>
        </s:Envelope>`;
  }

  /**
   * Crea el xml a mandar para enviar la lista de los audits que será transferida.
   *
   * @param list Lista de ..... que se convertira en string.
   * @returns Un XML con el encabezado necesario para realizar un post.
   */
  XMLAudit(
    auditRecId: number,
    petitioner: string,
    auditor: string,
    auditedWorker: string,
    auditList: AssetAudit[],
    auditStatus: number
  ) {
    let vAuditList = JSON.stringify(auditList);
    vAuditList = vAuditList.replace(new RegExp('&', 'g'), '&amp;');
    return (
      `
      <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <SaveAudit xmlns="http://tempuri.org/">
          <auditRecId>` +
      auditRecId +
      `</auditRecId>
          <petitioner>` +
      petitioner +
      `</petitioner>
          <auditor>` +
      auditor +
      `</auditor>
          <auditedWorker>` +
      auditedWorker +
      `</auditedWorker>
          <auditList>` +
      vAuditList +
      `</auditList>
          <auditStatus>` +
      auditStatus +
      `</auditStatus>
          </SaveAudit>
      </s:Body>
      </s:Envelope>`
    );
  }

  /**
   *
   * @param pStatus
   * @param pRecId
   * @returns
   */
  XMLGetAudit(pAuditor: number, pStatus: number) {
    return (
      `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetAudit xmlns="http://tempuri.org/">
            <pAuditor>` +
      pAuditor +
      `</pAuditor>
            <pStatus>` +
      pStatus +
      `</pStatus>
            </GetAudit>
          </s:Body>
        </s:Envelope>`
    );
  }
}
