import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { CatalogInfo } from '../../businessCore/trip-models/CatalogInfo';
import { ClientInfo } from '../../businessCore/trip-models/ClientInfo';
import { Vehicle } from '../../businessCore/trip-models/Vehicle';
import { ParadaModel } from '../../businessCore/trip-models/parada';
import { TripModel } from '../../businessCore/trip-models/trip';

@Injectable()
export class ExternalServiceTripProvider {
  constructor(public http: HttpClient) {}

  async getClientTrip(executorID: string, instanceID: string) {
    let clienteTrip: ClientInfo[] = [];
    const consulta =
      environment.SP_API +
      '/get_client_trip?serviceplaninstanceid=' +
      instanceID +
      '&executorID=' +
      executorID;
    try {
      const data = await this.http.get(consulta).toPromise();
      clienteTrip = this.parseClientTrip(data);
    } catch (e) {
      //console.log(e);
      clienteTrip = [];
    }
    return clienteTrip;
  }

  private parseClientTrip(listInfo: any) {
    const result: ClientInfo[] = [];
    //console.log(typeof listInfo, listInfo);
    for (const iterator of listInfo) {
      const newTmpClient = new ClientInfo(
        iterator.CustomerBusinessId,
        iterator.CustomerName
      );
      newTmpClient.setCustomerID(iterator.CustomerId);
      if (iterator.Latitude !== null && iterator.Longitude !== undefined) {
        newTmpClient.setLatitud(iterator.Latitude as number);
      }
      if (iterator.Longitude !== null && iterator.Longitude !== undefined) {
        newTmpClient.setLongitud(iterator.Longitude as number);
      }
      result.push(newTmpClient);
    }
    return result;
  }

  private createTripToXML(dataTrip: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <createTrip xmlns="http://tempuri.org/">
        <infoTrip>` +
      dataTrip +
      `</infoTrip>
        </createTrip>
      </s:Body>
    </s:Envelope>`
    );
  }

  createTrip(dataTrip: string) {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.createTripToXML(dataTrip);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/createTrip'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName('createTripResult')[0]
                .textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripInfo);
    });
  }

  private editTripToXML(dataTrip: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <editTrip xmlns="http://tempuri.org/">
        <infoTrip>` +
      dataTrip +
      `</infoTrip>
        </editTrip>
      </s:Body>
    </s:Envelope>`
    );
  }

  ediTrip(dataTripEdit: string) {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.editTripToXML(dataTripEdit);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/editTrip'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName('editTripResult')[0]
                .textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
      xmlRequest.send(xmlTripInfo);
    });
  }

  private createTripPointToXML(
    pointInfo: string,
    data64: string,
    dataImage: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <addPointTrip  xmlns="http://tempuri.org/">
          <pointTripInfo>` +
      pointInfo +
      `</pointTripInfo>
          <data64>` +
      data64 +
      `</data64>
          <dataImage>` +
      dataImage +
      `</dataImage>
        </addPointTrip>
      </s:Body>
    </s:Envelope>`
    );
  }

  createTripPoint(pointInfo: string, data64: string, dataImage: string) {
    return new Promise((resolve, reject) => {
      const xmlTripPoinInfo = this.createTripPointToXML(
        pointInfo,
        data64,
        dataImage
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/addPointTrip'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName(
                'addPointTripResult'
              )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripPoinInfo);
    });
  }

  private editTripPointToXML(
    pointInfo: string,
    data64: string,
    dataImage: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <editPointTrip  xmlns="http://tempuri.org/">
          <pointTripInfo>` +
      pointInfo +
      `</pointTripInfo>
          <data64>` +
      data64 +
      `</data64>
          <dataImage>` +
      dataImage +
      `</dataImage>
        </editPointTrip>
      </s:Body>
    </s:Envelope>`
    );
  }

  editPointTrip(pointInfo: string, data64: string, dataImage: string) {
    return new Promise((resolve, reject) => {
      const xmlTripPoinInfo = this.editTripPointToXML(
        pointInfo,
        data64,
        dataImage
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/editPointTrip'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'editPointTripResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripPoinInfo);
    });
  }

  private changeTripStateToXML(
    newState: string,
    tripNum: string,
    dataArea: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <changeTripState xmlns="http://tempuri.org/">
          <newState>` +
      newState +
      `</newState>
          <tripNum>` +
      tripNum +
      `</tripNum>
          <dataArea>` +
      dataArea +
      `</dataArea>
        </changeTripState>
      </s:Body>
    </s:Envelope>`
    );
  }

  changeTripState(newState: string, tripNum: string, dataArea: string) {
    //console.log(newState, tripNum, dataArea);
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.changeTripStateToXML(
        newState,
        tripNum,
        dataArea
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/changeTripState'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'changeTripStateResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripInfo);
    });
  }

  private XMLGetDataArea() {
    return `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <GetCompany xmlns="http://tempuri.org/">
            </GetCompany>
          </s:Body>
        </s:Envelope>`;
  }

  /**
   * Webservice que trae todos los activos pertenecientes a un trabajador
   *
   * @param pStatus
   */
  getDataArea() {
    return new Promise((resolve, reject) => {
      const xml = this.XMLGetDataArea();
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetCompany'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(this.parseDataArea(xmlHttpRequest.responseXML));
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo DataArea con todos las compañías por usuario.
   */
  private parseDataArea(xml: XMLDocument) {
    const tag: string = 'a:';
    let dataAreaList: DataArea[] = [];
    let dataAreaXML = xml.getElementsByTagName(tag + 'Company');
    for (let i = 0; i < dataAreaXML.length; i++) {
      let dataAreaTempXML = dataAreaXML[i];
      let dataAreaId = dataAreaTempXML
        .getElementsByTagName(tag + 'id')
        .item(0).textContent;
      let dataAreaName = dataAreaTempXML
        .getElementsByTagName(tag + 'name')
        .item(0)
        .textContent.toUpperCase();
      let dataAreaTemp: DataArea = new DataArea(dataAreaId, dataAreaName);
      dataAreaList.push(dataAreaTemp);
    }
    return dataAreaList;
  }

  private sendGastoToXML(gastoInfo: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <addExpenseTrip xmlns="http://tempuri.org/">
          <expenseTripInfo>` +
      gastoInfo +
      `</expenseTripInfo>
        </addExpenseTrip>
      </s:Body>
    </s:Envelope>`
    );
  }

  sendGastoTrip(gastoInfo: string) {
    return new Promise((resolve, reject) => {
      const xmlTripGastoInfo = this.sendGastoToXML(gastoInfo);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/addExpenseTrip'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'addExpenseTripResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripGastoInfo);
    });
  }

  private getVehiculeToXML(idConductor: string, dia: Date) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getActualVehicle xmlns="http://tempuri.org/">
          <driverID>` +
      idConductor +
      `</driverID>
          <dia>` +
      dia.toISOString() +
      `</dia>
        </getActualVehicle>
      </s:Body>
    </s:Envelope>`
    );
  }

  getVehicule(idConductor: string, dia: Date) {
    return new Promise((resolve, reject) => {
      const xmlTripGastoInfo = this.getVehiculeToXML(idConductor, dia);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getActualVehicle'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.parseVehicule(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripGastoInfo);
    });
  }

  private parseVehicule(xml: XMLDocument) {
    const tag: string = 'a:';
    let vehicleList: Vehicle[] = [];
    let vehicleXML = xml.getElementsByTagName(tag + 'TripVehicle');
    for (let i = 0; i < vehicleXML.length; i++) {
      const vehicleTempXML = vehicleXML[i];
      const vehicleNum = vehicleTempXML
        .getElementsByTagName(tag + 'VehicleNum')
        .item(0).textContent;
      const vehicleDesp = vehicleTempXML
        .getElementsByTagName(tag + 'Description')
        .item(0)
        .textContent.toUpperCase();
      let vehicleTemp: Vehicle = new Vehicle(vehicleNum, vehicleDesp);
      vehicleList.push(vehicleTemp);
    }
    return vehicleList;
  }

  private getTripInfoToXML(
    tripNum: string,
    dataAreaId: string,
    state: string,
    executor: string,
    instanceID: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
      <getTripInfo xmlns="http://tempuri.org/">
        <tripNum>` +
      tripNum +
      `</tripNum>
        <dataAreaId>` +
      dataAreaId +
      `</dataAreaId>
        <state>` +
      state +
      `</state>
        <executor>` +
      executor +
      `</executor>
        <instanceID>` +
      instanceID +
      `</instanceID>
      </getTripInfo>
    </s:Body>
    </s:Envelope>`
    );
  }

  getTripInfo(
    tripNum: string,
    dataAreaId: string,
    state: string,
    executor: string,
    instanceID: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.getTripInfoToXML(
        tripNum,
        dataAreaId,
        state,
        executor,
        instanceID
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getTripInfo'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.parseTripInfo(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripInfo);
    });
  }

  private parseTripInfo(xml: XMLDocument) {
    const tag: string = 'a:';
    let tripList: TripModel[] = [];
    let tripXML = xml.getElementsByTagName(tag + 'Trip');
    for (let i = 0; i < tripXML.length; i++) {
      const tripTempXML = tripXML[i];
      const tripNumber = tripTempXML
        .getElementsByTagName(tag + 'TripNumber')
        .item(0).textContent;
      const dataArea = tripTempXML
        .getElementsByTagName(tag + 'DataArea')
        .item(0)
        .textContent.toLowerCase();
      const description = tripTempXML
        .getElementsByTagName(tag + 'Description')
        .item(0).textContent;
      const executorId = tripTempXML
        .getElementsByTagName(tag + 'ExecutorId')
        .item(0).textContent;
      const startDate = tripTempXML
        .getElementsByTagName(tag + 'StartDate')
        .item(0).textContent;
      const endDate = tripTempXML
        .getElementsByTagName(tag + 'EndDate')
        .item(0).textContent;
      const initClient = tripTempXML
        .getElementsByTagName(tag + 'InitClient')
        .item(0).textContent;
      const endClient = tripTempXML
        .getElementsByTagName(tag + 'EndClient')
        .item(0).textContent;
      const startMileage = tripTempXML
        .getElementsByTagName(tag + 'StartMileage')
        .item(0).textContent;
      const endMileage = tripTempXML
        .getElementsByTagName(tag + 'EndMileage')
        .item(0).textContent;
      // const transport = tripTempXML.getElementsByTagName(tag + 'Transport').item(0).textContent;
      const typeTrip = tripTempXML
        .getElementsByTagName(tag + 'TypeTrip')
        .item(0).textContent;
      const stateTrip = tripTempXML
        .getElementsByTagName(tag + 'StateTrip')
        .item(0).textContent;
      const instanceID = tripTempXML
        .getElementsByTagName(tag + 'InstanceID')
        .item(0).textContent;
      const realDataArea = tripTempXML
        .getElementsByTagName(tag + 'RealDataArea')
        .item(0).textContent;
      // Crea el transporte del
      const numVehicle = tripTempXML
        .getElementsByTagName(tag + 'NumVehicle')
        .item(0).textContent;
      const placVehicle = tripTempXML
        .getElementsByTagName(tag + 'PlacVehicle')
        .item(0).textContent;
      const vehicleType = tripTempXML
        .getElementsByTagName(tag + 'VehicleType')
        .item(0).textContent;
      const propertyVehicle = tripTempXML
        .getElementsByTagName(tag + 'PropertyVehicle')
        .item(0).textContent;
      const vehicleTypeStr = tripTempXML
        .getElementsByTagName(tag + 'VehicleTypeStr')
        .item(0).textContent;
      const propertyVehicleStr = tripTempXML
        .getElementsByTagName(tag + 'PropertyVehicleStr')
        .item(0).textContent;
      //Milla
      const mile = tripTempXML
        .getElementsByTagName(tag + 'Mile')
        .item(0).textContent;

      const tripTemp: TripModel = new TripModel();
      tripTemp.setFMTrip(tripNumber);
      tripTemp.setDataArea(dataArea);
      tripTemp.setDescription(description);
      tripTemp.setExecutorId(executorId);
      if (startDate !== '') {
        const infoDate = new Date(startDate);
        tripTemp.setFecha(infoDate);
      }
      if (endDate !== '') {
        const infoDate = new Date(endDate);
        tripTemp.setHoraFin(infoDate);
      }
      tripTemp.setInitClient(initClient);
      tripTemp.setEndClient(endClient);
      const kiloInit = Number(startMileage);
      if (kiloInit !== -1) {
        tripTemp.setKilometrajeInicial(kiloInit);
      }
      const kiloEnd = Number(endMileage);
      if (kiloEnd) {
        tripTemp.setKilometrajeFinal(kiloEnd);
      }

      tripTemp.setTypeTrip(typeTrip.toUpperCase());
      tripTemp.setStateTrip(stateTrip.toUpperCase());
      tripTemp.setInstance(instanceID);
      tripTemp.setRealDataArea(realDataArea);

      //--------------Informacion de vehiculo
      const tmpVehicle = new Vehicle('', '');
      tmpVehicle.setNumVehicle(numVehicle);
      tmpVehicle.setPlacVehicle(placVehicle);
      tmpVehicle.setTypeTransport(Number(vehicleType));
      tmpVehicle.setTypeTransportStr(vehicleTypeStr);
      tmpVehicle.setTypeProperty(Number(propertyVehicle));
      tmpVehicle.setTypePropertyStr(propertyVehicleStr);
      //-------------------------------------------------------
      tripTemp.setVehicule(tmpVehicle);

      tripTemp.setMile(Number(mile));

      tripList.push(tripTemp);
    }
    return tripList;
  }

  private getTripPointInfoToXML(
    tripNum: string,
    dataAreaId: string,
    type: string,
    typeCreation: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getTripPointInfo xmlns="http://tempuri.org/">
          <tripNum>` +
      tripNum +
      `</tripNum>
          <dataAreaId>` +
      dataAreaId +
      `</dataAreaId>
          <type>` +
      type +
      `</type>
          <typeCreation>` +
      typeCreation +
      `</typeCreation>
        </getTripPointInfo>
      </s:Body>
    </s:Envelope>`
    );
  }

  getTripPointInfo(
    tripNum: string,
    dataAreaId: string,
    type: string,
    typeCreation: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.getTripPointInfoToXML(
        tripNum,
        dataAreaId,
        type,
        typeCreation
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getTripPointInfo'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.parseTripPointInfo(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlTripInfo);
    });
  }

  private parseTripPointInfo(xml: XMLDocument) {
    const tag: string = 'a:';
    let tripList: ParadaModel[] = [];
    let tripPoinXML = xml.getElementsByTagName(tag + 'TripPoint');
    for (let i = 0; i < tripPoinXML.length; i++) {
      const tripTempXML = tripPoinXML[i];
      const dataArea = tripTempXML
        .getElementsByTagName(tag + 'DataArea')
        .item(0).textContent;
      const description = tripTempXML
        .getElementsByTagName(tag + 'Description')
        .item(0).textContent;
      const motivo = tripTempXML
        .getElementsByTagName(tag + 'Motivo')
        .item(0).textContent;
      const endLatitude = tripTempXML
        .getElementsByTagName(tag + 'EndLatitude')
        .item(0).textContent;
      const endLongitude = tripTempXML
        .getElementsByTagName(tag + 'EndLongitude')
        .item(0).textContent;
      const endDateTime = tripTempXML
        .getElementsByTagName(tag + 'EndPointDateTime')
        .item(0).textContent;
      const fmTrip = tripTempXML
        .getElementsByTagName(tag + 'FMTrips')
        .item(0).textContent;
      const initLatitude = tripTempXML
        .getElementsByTagName(tag + 'InitLatitude')
        .item(0).textContent;
      const initLongitude = tripTempXML
        .getElementsByTagName(tag + 'InitLongitude')
        .item(0).textContent;
      const initDateTime = tripTempXML
        .getElementsByTagName(tag + 'InitPointDateTime')
        .item(0).textContent;
      const mileage = tripTempXML
        .getElementsByTagName(tag + 'Mileage')
        .item(0).textContent;
      const partyNumber = tripTempXML
        .getElementsByTagName(tag + 'PartyNumber')
        .item(0).textContent;
      const recId = tripTempXML
        .getElementsByTagName(tag + 'RecId')
        .item(0).textContent;
      const addressId = tripTempXML
        .getElementsByTagName(tag + 'AddressID')
        .item(0).textContent;
      const typeCreation = tripTempXML
        .getElementsByTagName(tag + 'TypeCreationPoint')
        .item(0).textContent;
      const typePoint = tripTempXML
        .getElementsByTagName(tag + 'TypePoint')
        .item(0).textContent;
      const customerName = tripTempXML
        .getElementsByTagName(tag + 'CustomerName')
        .item(0).textContent;
      const tripPTemp: ParadaModel = new ParadaModel();
      tripPTemp.setDataArea(dataArea);
      tripPTemp.setDescripcion(description);
      tripPTemp.setMotivo(motivo);
      tripPTemp.setLatitudEnd(Number(endLatitude));
      tripPTemp.setLongitudEnd(Number(endLongitude));
      if (endDateTime !== '1900-01-01T00:00:00Z') {
        tripPTemp.setEndDate(new Date(endDateTime));
      }
      tripPTemp.setFMTrips(fmTrip);
      tripPTemp.setLatitud(Number(initLatitude));
      tripPTemp.setLongitud(Number(initLongitude));
      if (initDateTime !== '1900-01-01T00:00:00Z') {
        tripPTemp.setInitDate(new Date(initDateTime));
      }
      const tmpKil = Number(mileage);
      if (tmpKil !== -1) {
        tripPTemp.setKilometraje(tmpKil);
      }
      tripPTemp.setPartyNumber(partyNumber);
      tripPTemp.setPartyName(customerName);
      tripPTemp.setRecId(Number(recId));
      tripPTemp.setAddressID(Number(addressId));
      tripPTemp.setTypeCreation(typeCreation.toUpperCase());
      tripPTemp.setTypeByString(typePoint.toUpperCase());
      tripList.push(tripPTemp);
    }
    return tripList;
  }

  /**
   *
   * @param executorID
   * @param instanceID
   * @param customerId codigo+recIdDireccion
   * @returns
   */
  async getCheckInOut(
    executorID: string,
    instanceID: string,
    customerId: string
  ) {
    let clienteCheckInOut: Date[] = [];
    const consulta =
      environment.SP_API +
      '/get_checkIn_Out?serviceplaninstanceid=' +
      instanceID +
      '&executorID=' +
      executorID +
      '&customerID=' +
      customerId;
    try {
      const data = await this.http.get(consulta).toPromise();
      clienteCheckInOut = this.parseCheckInOutClient(data);
    } catch (e) {
      clienteCheckInOut = [];
    }
    return clienteCheckInOut;
  }

  parseCheckInOutClient(listInfo: any) {
    const result: Date[] = [];
    for (const iterator of listInfo) {
      const fechaTmp = iterator;
      //Primera checkIn, segunda checkOut
      const fecha: Date =
        fechaTmp === '01/01/0001 00:00' ? null : new Date(fechaTmp);
      //console.log('Fecha from server: ', fecha);
      if (fecha && result.length === 0) {
        fecha.setHours(fecha.getHours()); // - 6);
      }
      result.push(fecha);
    }
    return result;
  }

  private getPropertyVehicleXML() {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getPropertyVehicle xmlns="http://tempuri.org/">
        </getPropertyVehicle>
      </s:Body>
    </s:Envelope>`;
  }

  async getPropertyVehicle() {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.getPropertyVehicleXML();
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getPropertyVehicle'
      );
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.parseCatalogInfo(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
      xmlRequest.send(xmlTripInfo);
    });
  }

  private getTypeVehicleXML() {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getTypeVehicle xmlns="http://tempuri.org/">
        </getTypeVehicle>
      </s:Body>
    </s:Envelope>`;
  }

  async getTypeVehicle() {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.getTypeVehicleXML();
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getTypeVehicle'
      );
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.parseCatalogInfo(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
      xmlRequest.send(xmlTripInfo);
    });
  }

  parseCatalogInfo(xml: XMLDocument) {
    const tag: string = 'a:';
    let infoList: CatalogInfo[] = [];
    let infoXML = xml.getElementsByTagName(tag + 'CatalogInfo');
    for (let i = 0; i < infoXML.length; i++) {
      const tripTempXML = infoXML[i];
      const description = tripTempXML
        .getElementsByTagName(tag + 'labelInfo')
        .item(0).textContent;
      const value = tripTempXML
        .getElementsByTagName(tag + 'valueInfo')
        .item(0).textContent;
      const tripPTemp: CatalogInfo = new CatalogInfo(
        Number(value),
        description
      );
      infoList.push(tripPTemp);
    }
    return infoList;
  }

  private getVehiclesXML(companyId: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getVehicles xmlns="http://tempuri.org/">
         <companyId>` +
      companyId +
      `</companyId>
        </getVehicles>
      </s:Body>
    </s:Envelope>`
    );
  }

  async getVehicles(companyId: string) {
    return new Promise((resolve, reject) => {
      const xmlTripInfo = this.getVehiclesXML(companyId);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getVehicles'
      );
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.parseVehicleInfo(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
      xmlRequest.send(xmlTripInfo);
    });
  }

  parseVehicleInfo(xml: XMLDocument) {
    const tag: string = 'a:';
    let infoList: Vehicle[] = [];
    let infoXML = xml.getElementsByTagName(tag + 'TripVehicle');
    for (let i = 0; i < infoXML.length; i++) {
      const tripTempXML = infoXML[i];
      const description = tripTempXML
        .getElementsByTagName(tag + 'Description')
        .item(0).textContent;
      const numPlaca = tripTempXML
        .getElementsByTagName(tag + 'NumPlaca')
        .item(0).textContent;
      const vehicleNum = tripTempXML
        .getElementsByTagName(tag + 'VehicleNum')
        .item(0).textContent;
      const vehicleType = tripTempXML
        .getElementsByTagName(tag + 'VehicleType')
        .item(0).textContent;
      const vehicleTemp: Vehicle = new Vehicle(vehicleNum, description);
      vehicleTemp.setPlacVehicle(numPlaca);
      vehicleTemp.setTypeTransport(Number(vehicleType));
      infoList.push(vehicleTemp);
    }
    return infoList;
  }
}
