import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { AssociatedInstance } from '../../businessCore/AssociatedInstance';
import { RegisterData } from '../../businessCore/RegisterData';
import { AnswerTask } from '../../businessCore/check-models/AnswerTask';
import { CheckListInstance } from '../../businessCore/check-models/CheckInstance';
import { CheckList } from '../../businessCore/check-models/CheckList';
import { InfoImage } from '../../businessCore/check-models/InfoImage';
import { OmissionTask } from '../../businessCore/check-models/OmissionTask';
import { TaskCheck } from '../../businessCore/check-models/TaskCheck';

const CHKIDB = 'DB-CHK'; //instance
const CHKINFDB = 'DB-CHKINF';

@Injectable()
export class ServicesLocalCheckProvider {
  private reloadInf: boolean;
  private subject: Subject<boolean>;

  constructor(private storage: StorageService) {
    this.reloadInf = false;
    this.subject = new Subject<boolean>();
  }

  getSubjectReload() {
    return this.subject;
  }

  setNextSubjetReload(infoReload: boolean) {
    this.subject.next(infoReload);
  }

  getReloadInfo() {
    return this.reloadInf;
  }

  setReloadInfo(newReloadInf: boolean) {
    this.reloadInf = newReloadInf;
  }

  // Get cedula
  async getCedulaActual() {
    const data = await this.storage.get('register_data');
    const register_data = JSON.parse(data) as RegisterData;
    let cedulaAct = register_data.id_card as string;

    const cedulaDist = await this.storage.get('associatedPerson');
    const associatedPerson = JSON.parse(cedulaDist) as AssociatedInstance;
    if (associatedPerson) {
      cedulaAct = associatedPerson.vatNum as string;
    }
    if (cedulaAct === undefined || cedulaAct === null) {
      cedulaAct = '';
    }
    return cedulaAct;
  }

  async updateInstanceCheckList(infoInstance: CheckListInstance) {
    let resultado = false;
    try {
      //await this.storage.ready();
      let infoSave = await this.storage.get(CHKIDB);
      if (infoSave !== null && infoSave !== undefined) {
        const data = infoSave as string[];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const jsonInfo = JSON.parse(element);
          if (
            jsonInfo &&
            jsonInfo.InstanceRecId === infoInstance.getInstanceRecId()
          ) {
            //Remplaza y settea el nuevo dato
            data.splice(index, 1, JSON.stringify(infoInstance.toJSONLocal()));
            // data[index] = JSON.stringify(infoInstance.toJSONLocal());
            break;
          }
        }
        await this.storage.set(CHKIDB, data);
        resultado = true;
        this.reloadInf = true;
      }
    } catch (error) {}
    return resultado;
  }

  async deleteInstanceCheckList(instanceRecId: number) {
    let resultado = false;
    try {
      //await this.storage.ready();
      let infoSave = await this.storage.get(CHKIDB);
      if (infoSave !== null && infoSave !== undefined) {
        const data = infoSave as string[];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const jsonInfo = JSON.parse(element);
          if (jsonInfo && jsonInfo.InstanceRecId === instanceRecId) {
            //Remplaza y settea el nuevo dato
            data.splice(index, 1);
            // data[index] = JSON.stringify(infoInstance.toJSONLocal());
            break;
          }
        }
        await this.storage.set(CHKIDB, data);
        resultado = true;
        this.reloadInf = true;
      }
    } catch (error) {}
    return resultado;
  }

  async saveInstanceCheckList(infoInstance: CheckListInstance) {
    let result = false;
    try {
      //await this.storage.ready();
      const listInfoTrip = await this.storage.get(CHKIDB);
      if (listInfoTrip) {
        const info = listInfoTrip as string[];
        info.push(JSON.stringify(infoInstance.toJSONLocal()));
        await this.storage.set(CHKIDB, info); // Almacena un nuevo dato
      } else {
        await this.storage.set(CHKIDB, [
          JSON.stringify(infoInstance.toJSONLocal()),
        ]);
      }
      result = true;
      this.reloadInf = true;
    } catch {
      //console.log('Error local storage');
    }
    return result;
  }

  async saveInfoCheckList(infoCheckList: CheckList) {
    let result = false;
    try {
      //await this.storage.ready();
      const listInfoCheck = await this.storage.get(CHKINFDB);
      if (listInfoCheck) {
        const info = listInfoCheck as string[];
        info.push(JSON.stringify(infoCheckList.toJsonLocalCheckListInfo()));
        await this.storage.set(CHKINFDB, info); // Almacena un nuevo dato
      } else {
        await this.storage.set(CHKINFDB, [
          JSON.stringify(infoCheckList.toJsonLocalCheckListInfo()),
        ]);
      }
      result = true;
      this.reloadInf = true;
    } catch (error) {}
    return result;
  }

  async updateInfoCheckList(infoChecklist: CheckList) {
    let resultado = null;
    try {
      //await this.storage.ready();
      let infoSave = await this.storage.get(CHKINFDB);
      if (infoSave !== null && infoSave !== undefined) {
        const data = infoSave as string[];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const jsonInfo = JSON.parse(element);
          if (jsonInfo && jsonInfo.ID === infoChecklist.getID()) {
            //Remplaza y settea el nuevo dato
            data.splice(
              index,
              1,
              JSON.stringify(infoChecklist.toJsonLocalCheckListInfo())
            );
            // data[index] = JSON.stringify(infoInstance.toJSONLocal());
            break;
          }
        }
        await this.storage.set(CHKINFDB, data);
        resultado = true;
        this.reloadInf = true;
      }
    } catch (error) {}
    return resultado;
  }

  async deleteInfoCheckList(checklistID: string) {
    let resultado = null;
    try {
      //await this.storage.ready();
      let infoSave = await this.storage.get(CHKINFDB);
      if (infoSave !== null && infoSave !== undefined) {
        const data = infoSave as string[];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const jsonInfo = JSON.parse(element);
          if (jsonInfo && jsonInfo.ID === checklistID) {
            //Remplaza y settea el nuevo dato
            data.splice(index, 1);
            // data[index] = JSON.stringify(infoInstance.toJSONLocal());
            break;
          }
        }
        await this.storage.set(CHKINFDB, data);
        resultado = true;
        this.reloadInf = true;
      }
    } catch (error) {}
    return resultado;
  }

  async deleteAllInfo() {
    let result = false;
    try {
      //await this.storage.ready();
      await this.storage.remove(CHKINFDB);
      await this.storage.remove(CHKIDB);
      result = true;
    } catch (error) {}
    return result;
  }

  async getInstanceCheckList() {
    let listInstanceCheck: CheckListInstance[] = [];
    try {
      //await this.storage.ready();
      let infoSave: any;
      infoSave = await this.storage.get(CHKIDB);
      if (infoSave !== null && infoSave !== undefined) {
        //Parsea los datos de las instancias almacenadas
        const data = infoSave as string[];
        for (const iterator of data) {
          listInstanceCheck.push(this.parseInstanceCheckList(iterator));
        }
      }
    } catch (error) {
      //console.log('Error local:  ', error);
    }
    return listInstanceCheck;
  }

  parseInstanceCheckList(objeto: any) {
    let instanceData: CheckListInstance = new CheckListInstance(
      '',
      '',
      '',
      0,
      new Date(),
      new Date()
    );
    const jsonInfo = JSON.parse(objeto);
    /* AssignmentID: "CKA_000000049"
    CheckList: "CHKL_000000002"
    Description: "Alajuela construcción Jose Aguirre"
    ExecutionDate: "2021-04-08T14:00:00.000Z"
    InstanceRecId: 5637170076
    Position: 1
    ShowInstance: 0
    VatNum: "155819600701"*/
    if (jsonInfo) {
      if (jsonInfo.Position) {
        instanceData.position = Number(jsonInfo.Position);
      }

      if (jsonInfo.AssignmentID) {
        instanceData.setAssigmentID(jsonInfo.AssignmentID);
      }

      if (jsonInfo.CheckList) {
        instanceData.setCheckList(jsonInfo.CheckList);
      }

      if (jsonInfo.Description) {
        instanceData.setDescription(jsonInfo.Description);
      }

      if (jsonInfo.ExecutionDate) {
        instanceData.setExecutionDate(new Date(jsonInfo.ExecutionDate));
      }

      if (jsonInfo.InstanceRecId) {
        instanceData.setInstanceRecId(Number(jsonInfo.InstanceRecId));
      }

      if (jsonInfo.ShowInstance) {
        instanceData.showInstance = jsonInfo.ShowInstance === 1;
      }

      if (jsonInfo.VatNum) {
        instanceData.setVatNum(jsonInfo.VatNum);
      }

      if (jsonInfo.Estado) {
        instanceData.setEstado(jsonInfo.Estado);
      }

      if (jsonInfo.LastExecution) {
        instanceData.setLastExecution(new Date(jsonInfo.LastExecution));
      }

      instanceData.setInLocal(true);
    }

    return instanceData;
  }

  async getInfoCheckList(checkListID: string) {
    let infoCheckList: CheckList[] = null;
    try {
      let result = await this.isSavedinLocal(checkListID);
      if (result !== null) {
        infoCheckList = [];
        infoCheckList.push(this.parseCheckListInf(result));
      }
    } catch (error) {}
    return infoCheckList;
  }

  /**
   * Permite saber si tengo datos almacenados de un checklist...
   *
   * @param checkListID
   * @returns null || objeto con la informacion del checklist
   */
  private async isSavedinLocal(checkListID: string) {
    let resultado = null;
    try {
      //await this.storage.ready();
      let infoSave = await this.storage.get(CHKINFDB);
      if (infoSave !== null && infoSave !== undefined) {
        const data = infoSave as string[];
        for (const iterator of data) {
          const jsonInfo = JSON.parse(iterator);
          if (jsonInfo && jsonInfo.ID === checkListID) {
            resultado = iterator;
          }
        }
      }
    } catch (error) {}
    return resultado;
  }

  private parseCheckListInf(objeto: any) {
    const infoCheckList = new CheckList('', '', '');
    const jsonInfo = JSON.parse(objeto);
    if (jsonInfo) {
      if (jsonInfo.TasksList) {
        //Parsea la lista de tareas.
        const taskList = this.parseCheckListTask(jsonInfo.TasksList);
        infoCheckList.setTasksList(taskList);
      }

      if (jsonInfo.TDescription) {
        infoCheckList.setDescriptio(jsonInfo.TDescription);
      }

      if (jsonInfo.ID) {
        infoCheckList.setID(jsonInfo.ID);
      }

      if (jsonInfo.Name) {
        infoCheckList.setName(jsonInfo.Name);
      }
    }
    return infoCheckList;
  }

  /**
   * Devuelve una lista de tareas.
   * @param objeto
   */
  private parseCheckListTask(objeto: any) {
    const infoTaskList: TaskCheck[] = [];
    /**
     * Attached: 0, Description: "Apagadores", ID: "CKT_000000003",
     * Name: "Revisión Eléctrica", OrderTask: 1, Selected: 0, State: 0
     * AnswerTaskList: [], OmissionTaskList: [], Observation: "",
     * ImgData: null || { associatedCompany: "", associatedElementId: 0,
     * associatedVatnum: "", attachmentAction: 3, attachmentExtension: "jpeg",
     * attachmentType: 2, base64: "" }
     */
    for (const iterator of objeto) {
      if (iterator) {
        const taskTmp = new TaskCheck('', '', '');
        if (iterator.AnswerTaskList) {
          taskTmp.setAnswerTaskList(
            this.parseCheckListAnswer(iterator.AnswerTaskList)
          );
        }

        if (iterator.OmissionTaskList) {
          taskTmp.setOmissionTaskList(
            this.parseCheckListOmi(iterator.OmissionTaskList)
          );
        }

        if (iterator.Attached) {
          taskTmp.setAttachecd(iterator.Attached === 1);
        }
        if (iterator.Description) {
          taskTmp.setDescription(iterator.Description);
        }
        if (iterator.ID) {
          taskTmp.setID(iterator.ID);
        }
        if (iterator.Name) {
          taskTmp.setName(iterator.Name);
        }
        if (iterator.OrderTask) {
          taskTmp.setOrderTask(iterator.OrderTask);
        }
        if (iterator.Selected) {
          taskTmp.selected = Number(iterator.Selected) === 1;
        }
        if (iterator.State) {
          taskTmp.state = iterator.State;
        }
        if (iterator.Observation) {
          taskTmp.setObservation(iterator.Observation);
        }

        if (iterator.ImgData) {
          //Extrae los datos de la imagen

          const dataImage: any = iterator.ImgData;
          const dataImageOb: InfoImage = new InfoImage();
          if (dataImage.attachmentExtension) {
            dataImageOb.extension = dataImage.attachmentExtension;
          }
          if (dataImage.attachmentType) {
            dataImageOb.type = Number(dataImage.attachmentType);
          }
          if (dataImage.associatedCompany) {
            dataImageOb.company = dataImage.associatedCompany;
          }
          if (dataImage.attachmentAction) {
            dataImageOb.action = Number(dataImage.attachmentAction);
          }
          if (dataImage.associatedVatnum) {
            dataImageOb.vatnum = dataImage.associatedVatnum;
          }
          if (dataImage.base64) {
            dataImageOb.dataImage = dataImage.base64;
          }
          taskTmp.setImageData(dataImageOb);
        }

        infoTaskList.push(taskTmp);
      }
    }
    return infoTaskList;
  }

  private parseCheckListAnswer(objeto: any) {
    const infoAnsList: AnswerTask[] = [];
    /**
     * Description: "Buen estado", ID: "CKTA_000000001", Observation: "", Position: 1, Selected: 0
     */
    for (const iterator of objeto) {
      if (iterator) {
        const answerTmp = new AnswerTask('', '');
        if (iterator.Description) {
          answerTmp.setDescription(iterator.Description);
        }
        if (iterator.ID) {
          answerTmp.setID(iterator.ID);
        }

        if (iterator.Observation) {
          answerTmp.observation = iterator.Observation;
        }

        if (iterator.Position) {
          answerTmp.position = Number(iterator.Position);
        }

        if (iterator.Selected) {
          answerTmp.selected = Number(iterator.Selected) === 1;
        }
        infoAnsList.push(answerTmp);
      }
    }

    return infoAnsList;
  }

  private parseCheckListOmi(objeto: any) {
    const infoOmiList: OmissionTask[] = [];
    /**
     * Description: "Buen estado", ID: "CKTA_000000001", Observation: "", Position: 1, Selected: 0
     */
    for (const iterator of objeto) {
      if (iterator) {
        const omiTmp = new OmissionTask('', '');
        if (iterator.Description) {
          omiTmp.setDescription(iterator.Description);
        }
        if (iterator.ID) {
          omiTmp.setID(iterator.ID);
        }

        if (iterator.Observation) {
          omiTmp.observation = iterator.Observation;
        }

        if (iterator.Position) {
          omiTmp.position = Number(iterator.Position);
        }

        if (iterator.Selected) {
          omiTmp.selected = Number(iterator.Selected) === 1;
        }
        infoOmiList.push(omiTmp);
      }
    }

    return infoOmiList;
  }
}
