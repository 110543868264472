import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { ItemBatch } from '../OOP/ItemBatch.interface';
import { ItemConversion } from '../OOP/ItemConversion.interface';
import { Param } from '../OOP/Param.interface';
import { PurchLinesInfoParam } from '../OOP/PurchLinesInfoParam.interface';
import { ReceiveItem } from '../OOP/ReceiveItem.interface';
import { SelectFilterOption } from '../OOP/SelectFilterOption.interface';

@Injectable()
export class RemissionService {
  public configLinePRModal: any;

  listDataArea: DataArea[];

  constructor(public _http: HttpClient) {}

  async getDataAreaInfo() {
    const dataAreaInfList: DataArea[] = [];
    const dataAreaInfo = await this.getDataAreaRequest();
    if (dataAreaInfo !== null) {
      for (const areaInf of dataAreaInfo) {
        const tmpDataArea = areaInf as string;
        const information = tmpDataArea.split('_,,,_');
        const nameP = information[1];
        const idP = information[0];
        dataAreaInfList.push(new DataArea(idP, nameP));
      }
    }
    return dataAreaInfList;
  }

  async getDataAreaRequest() {
    let dataAreaList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getDataAreaInfo';
    await this._http
      .get(consulta)
      .toPromise()
      .then((data) => {
        dataAreaList = data;
      })
      .catch((error) => {
        console.log('Error al tratar de traer lista de empresas...', error);
      });
    return dataAreaList;
  }

  async getAllPurchOrders(dataAreaId: string) {
    let response: any[];
    let method: string = 'GetAllPurchOrders';
    let user: string = JSON.parse(localStorage.getItem('WMS')).user;

    if (!user) {
      return [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }

    let params: Param[] = [
      { tag: '_dataAreaId', val: dataAreaId, type: 1 },
      { tag: '_user', val: user, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let collectData: PurchLinesInfoParam[] = [];

        let mainData = res.getElementsByTagName(
          'a:AIF_Aux_ResponseGetPurchInfo_v2'
        );

        for (const PurchOrder of mainData) {
          let data: PurchLinesInfoParam = {
            purchId:
              PurchOrder.getElementsByTagName('a:PurchOrder')[0].textContent,
            objectId: '',
            objectName:
              PurchOrder.getElementsByTagName('a:provider')[0].textContent,
            roleSpecificIds: [],
            containerIds: [],
            id: '',
            containerIdsSelected: [],
            roleSpecificIdsAssistantsSelected: [],
            roleSpecificIdsSelected: [],
            status:
              PurchOrder.getElementsByTagName('a:StatusPO')[0].textContent,
            createdDate:
              PurchOrder.getElementsByTagName(
                'a:CreatedDate'
              )[0].textContent.split('T')[0],
            remissionMode: 'Ordered',
          };

          collectData.push(data);
        }

        return collectData;
      });
    } catch {
      response = [
        {
          status: false,
          message: 'Ocurrió un error al consultar los datos',
          provider: '',
          roleSpecificIds: [],
          containerIds: [],
        },
      ];
    }
    return response;
  }

  // Recibe una lista de parámetros y el nombre de un método.
  // Construye el xml del request
  constructXMLRequest(paramList: Param[], method: string): string {
    return `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
            <s:Body><${method} xmlns="http://tempuri.org/">
            ${this.constructXMLParams(paramList)}</${method}>
            </s:Body></s:Envelope>`;
  }

  // Recibe una lista de parámetros y los convierte en tags de XML
  // Type = 1 : Hoja // Type = 2 : Lista
  constructXMLParams(params: Param[]): string {
    let paramsData = '';
    for (let param of params) {
      let extra = param.extra ? param.extra : '';
      if (param.type == 1) {
        paramsData += `<${param.tag}${extra}>${param.val}</${param.tag}>`;
      } else {
        paramsData += `<${param.tag}${extra}>${this.constructXMLParams(
          param.val
        )}</${param.tag}>`;
      }
    }
    return paramsData;
  }

  // Ejecuta el request hacia el backend
  execRequest(xmlRequest: string, method: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.open('POST', environment.GWMainService_API, true);
      xhr.setRequestHeader('Content-Type', 'text/xml');
      xhr.setRequestHeader(
        'SOAPAction',
        `http://tempuri.org/IGW_MainService/${method}`
      );
      xhr.send(xmlRequest);

      xhr.onreadystatechange = function (aEvt) {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(xhr.responseXML);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
    });
  }

  async getPurchLinesInfo(purchId: string, statusReceipt, company) {
    let response: any;
    let method: string = 'GetPurchLinesInfo';

    let params: Param[] = [
      { tag: 'purchId', val: purchId, type: 1 },
      { tag: 'statusReceipt', val: statusReceipt, type: 1 },
      { tag: 'company', val: company, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);
    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let status =
          res.getElementsByTagName('a:status')[0].textContent === 'true';
        let message = res.getElementsByTagName('a:message')[0].textContent;

        let data = res.getElementsByTagName('a:AIF_Aux_ResponseGetItemInfo');
        let lines = [];

        for (let PurchLine of data) {
          let itemInfo = this.processItemInfo(PurchLine);

          lines.push(itemInfo);
        }

        return { status: status, message: message, purchLines: lines };
      });
    } catch {
      response = {
        status: false,
        message: 'Ocurrió un error al consultar los datos',
        purchLines: [],
      };
    }
    return response;
  }

  processItemInfo(res): ReceiveItem {
    let data: ReceiveItem = {
      status: false,
      batches: [],
      conversions: [],
      description: '',
      units: [],
      hasBatchId: false,
      inventBatchDays: 0,
      tarimaHeight: 0,
      message: '',
      itemId: '',
      unitId: '',
      orderedQty: 0,
      totalQty: 0,
      registeredQty: 0,
      remissionQty: 0,
      roleSpecificIds: [],
      roleSpecificIdsAssistants: [],
      containerIds: [],
      recId: '',
    };

    // Status
    data.status =
      res.getElementsByTagName('a:status')[0].textContent === 'true';

    // Message
    data.message = res.getElementsByTagName('a:message')[0].textContent;

    // ItemId
    data.itemId = res.getElementsByTagName('a:itemId')[0].textContent;

    // Description
    data.description = res.getElementsByTagName('a:description')[0].textContent;

    // HasBatchId
    data.hasBatchId =
      res.getElementsByTagName('a:hasBatchId')[0].textContent === 'true';

    // InventBatchDays
    data.inventBatchDays = parseInt(
      res.getElementsByTagName('a:inventBatchDays')[0].textContent
    );

    // TarimaHeight
    data.tarimaHeight = parseFloat(
      res.getElementsByTagName('a:tarimaHeight')[0].textContent
    );

    // UnitId
    data.unitId = res.getElementsByTagName('a:unitId')[0].textContent;

    // OrderedQty
    data.orderedQty = parseInt(
      res.getElementsByTagName('a:orderedQty')[0].textContent
    );

    // RegisteredQty
    data.registeredQty = parseInt(
      res.getElementsByTagName('a:registeredQty')[0].textContent
    );

    // TotalQty
    data.totalQty = parseInt(
      res.getElementsByTagName('a:totalQty')[0].textContent
    );

    // RemissionQty
    data.remissionQty = parseInt(
      res.getElementsByTagName('a:remissionQty')[0].textContent
    );

    // RecId
    data.recId = res.getElementsByTagName('a:recId')[0].textContent;

    // Batchs
    let batches = res.getElementsByTagName('a:ItemBatch');
    for (let batch of batches) {
      let newBatch: ItemBatch = {
        batchId: batch.getElementsByTagName('a:batchId')[0].textContent,
        batchDate: batch.getElementsByTagName('a:datebatch')[0].textContent,
      };
      data.batches.push(newBatch);
    }

    // Conversions
    let conversions = res.getElementsByTagName('a:ItemConversion');
    for (let conversion of conversions) {
      let newConversion: ItemConversion = {
        fromUnit: conversion.getElementsByTagName('a:fromUnit')[0].textContent,
        toUnit: conversion.getElementsByTagName('a:toUnit')[0].textContent,
        qty: conversion.getElementsByTagName('a:qty')[0].textContent,
      };
      data.conversions.push(newConversion);
    }

    // Units
    let units = res.getElementsByTagName('b:string');
    for (let unit of units) {
      let unitSymbol = unit.textContent;
      data.units.push(unitSymbol);
    }

    return data;
  }

  execRemissionLines(
    purchId: string,
    statusReceipt: number,
    remissionLines: ReceiveItem[],
    company
  ) {
    let remissionLinesParam = [];

    for (let remissionLine of remissionLines) {
      let roleSpecificIdAssistants = '';
      for (let roleSpecificId of remissionLine.roleSpecificIdsAssistants) {
        roleSpecificIdAssistants +=
          (roleSpecificIdAssistants ? ',' : '') + roleSpecificId.id;
      }

      let roleSpecificIds = '';
      for (let roleSpecificId of remissionLine.roleSpecificIds) {
        roleSpecificIds += (roleSpecificIds ? ',' : '') + roleSpecificId.id;
      }

      let containerIds = '';
      for (let containerId of remissionLine.containerIds) {
        containerIds += (containerIds ? ',' : '') + containerId.id;
      }

      let tmp = [
        {
          tag: 'tag:containerId',
          val: containerIds,
          type: 1,
          extra: '',
        },
        {
          tag: 'tag:itemId',
          val: remissionLine.itemId,
          type: 1,
          extra: '',
        },
        {
          tag: 'tag:qty',
          val: remissionLine.remissionQty,
          type: 1,
          extra: '',
        },
        {
          tag: 'tag:recId',
          val: remissionLine.recId,
          type: 1,
          extra: '',
        },
        {
          tag: 'tag:roleSpecificId',
          val: roleSpecificIds,
          type: 1,
          extra: '',
        },
        {
          tag: 'tag:roleSpecificIdAssistant',
          val: roleSpecificIdAssistants,
          type: 1,
          extra: '',
        },
        {
          tag: 'tag:unitId',
          val: remissionLine.unitId,
          type: 1,
          extra: '',
        },
      ];
      remissionLinesParam.push({
        tag: 'tag:AIF_Aux_RemissionItemObj',
        val: [...tmp],
        type: 2,
        extra: '',
      });
    }

    return this.remissionLines_aux(
      purchId,
      statusReceipt,
      [
        {
          tag: 'tag:RemissionItems',
          val: remissionLinesParam,
          type: 2,
          extra: '',
        },
      ],
      company
    );
  }

  async remissionLines_aux(
    purchId: string,
    statusReceipt: number,
    remissionLines: any[],
    company
  ) {
    let response: any;
    let method: string = 'RemissionItems';
    let params: Param[] = [
      {
        tag: 'purchId',
        val: purchId,
        type: 1,
        extra: '',
      },
      {
        tag: 'statusReceipt',
        val: statusReceipt,
        type: 1,
        extra: '',
      },
      {
        tag: 'RemissionItems',
        val: remissionLines,
        type: 2,
        extra:
          ' xmlns:tag="http://schemas.datacontract.org/2004/07/GW_GeneralMovements.Classes" xmlns:i="http://www.w3.org/2001/XMLSchema-instance"',
      },
      {
        tag: 'company',
        val: company,
        type: 1,
        extra: '',
      },
    ];
    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let status =
          res.getElementsByTagName('a:status')[0].textContent === 'true';
        let message = res.getElementsByTagName('a:message')[0].textContent;
        return { status: status, message: message };
      });
    } catch {
      response = {
        status: false,
        message: 'Ocurrió un error al realizar la remisión',
      };
    }
    return response;
  }

  async getPurchInfo(purchId, company) {
    let response: {
      status: boolean;
      message: any;
      provider: any;
      roleSpecificIds: SelectFilterOption[];
      containerIds: SelectFilterOption[];
    };

    let method: string = 'GetPurchInfo';

    let params: Param[] = [
      { tag: 'purchId', val: purchId, type: 1 },
      { tag: 'company', val: company, type: 1 },
    ];

    let xmlRequest = this.constructXMLRequest(params, method);

    try {
      response = await this.execRequest(xmlRequest, method).then((res) => {
        let status =
          res.getElementsByTagName('a:status')[0].textContent === 'true';
        let message = res.getElementsByTagName('a:message')[0].textContent;
        let provider = res.getElementsByTagName('a:provider')[0].textContent;

        let roleSpecificIds: SelectFilterOption[] = [];
        let data = res.getElementsByTagName('a:AIF_Aux_RoleSpecificId');

        for (let roleSpecificId of data) {
          let tempRoleSpecificid =
            roleSpecificId.getElementsByTagName('a:roleSpecificId')[0]
              .textContent;
          let tempDescription =
            roleSpecificId.getElementsByTagName('a:description')[0].textContent;
          let tempRecId =
            roleSpecificId.getElementsByTagName('a:RecId')[0].textContent;

          roleSpecificIds.push({
            label: tempRoleSpecificid,
            description: tempDescription,
            id: tempRecId,
            isChecked: false,
          });
        }

        let containerIds: SelectFilterOption[] = [];
        let containers = res.getElementsByTagName('a:AIF_Aux_ContainerId');

        for (let containerId of containers) {
          let tempContainerId =
            containerId.getElementsByTagName('a:containerId')[0].textContent;
          let tempDescription =
            containerId.getElementsByTagName('a:description')[0].textContent;
          let tempRecId =
            containerId.getElementsByTagName('a:RecId')[0].textContent;

          containerIds.push({
            label: tempContainerId,
            description: tempDescription,
            id: tempRecId,
            isChecked: false,
          });
        }

        return {
          status: status,
          message: message,
          provider: provider,
          roleSpecificIds: roleSpecificIds,
          containerIds: containerIds,
        };
      });
    } catch {
      response = {
        status: false,
        message: 'Ocurrió un error al consultar los datos',
        provider: '',
        roleSpecificIds: [],
        containerIds: [],
      };
    }
    return response;
  }
}
