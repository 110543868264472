<app-nav-bar [title]="'Asociar O.C'"></app-nav-bar>

<ion-content lines="none">
  <div class="box">
    <ion-grid
      style="margin: 0px; background-color: #223e99; color: white"
      lines="none"
      class=""
      (click)="openSearchBarDataAreaId()"
    >
      <ion-row>
        <ion-col size="10">
          <ion-label>
            <h2 class="h2Select">Empresa</h2>
            <h3>
              {{
                empresaSelected ? empresaSelected.getId() : "Sin Seleccionar"
              }}
            </h3>
            <p style="color: white" *ngIf="empresaSelected">
              {{ empresaSelected.getName() }}
            </p>
          </ion-label>
        </ion-col>
        <ion-col
          size="2"
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
          "
        >
          <ion-icon name="caret-down-outline"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Filtros de OC-->
    <ion-searchbar
      type="text"
      placeholder="Buscar"
      (ionChange)="searchOC($event)"
    ></ion-searchbar>

    <div class="flex-container nowrap" style="display: flex">
      <ion-button
        class="filter-btn"
        (click)="filterPopover()"
        style="width: 100%"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        style="width: 100%"
        class="sort-btn"
        (click)="presentSortAlert()"
      >
        Ordenar
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>

    <div class="content">
      <ion-list *ngIf="EInvoicesSearch" lines="none">
        <ion-card *ngFor="let element of EInvoicesSearch">
          <ion-card-content style="padding: 0px">
            <ion-item-sliding (swipe)="swipeEvent($event, element)">
              <ion-item>
                <div class="cardContent">
                  <div class="subCardContent key">
                    <span class="consecutive">{{ element.Consecutive }}</span>
                    <br />
                    {{ element.KeyEInvoice }}
                  </div>
                  <div class="subCardContent basicInfo">
                    <b>ID Receptor:</b> {{ element.ReceiverId }}
                    <br />
                    <span><b>Receptor:</b> {{ element.ReceiverName }}</span>
                    <br />
                    <b>Fecha Emisión:</b>
                    {{ element.EmittedDate | date : "dd/MM/yyyy" }}
                  </div>
                  <div class="subCardContent extraInfo">
                    <div class="item">
                      <b>Total Comprobante:</b>
                      <br />
                      {{
                        element.Total
                          | currency : element.CurrencyCode : "symbol-narrow"
                      }}
                    </div>
                    <div class="item">
                      <b>Orden de Compra:</b>
                      <br />
                      {{ element.PurchId ? element.PurchId : "Sin Asignar" }}
                    </div>
                  </div>
                </div>
              </ion-item>
              <ion-item-options side="end">
                <ion-item-option (click)="swipeEvent($event, element)">
                  <ion-icon name="add"> </ion-icon>
                  <span style="font-size: 8px">Asociar O.C.</span>
                </ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </div>
  </div>
</ion-content>
