import { AssetLocation } from './AssetLocation';
import { AssetRequest } from './AssetRequest';
import { FixedAsset } from './FixedAsset';
import { Worker } from './Worker';

export class FixedAssetTransfer {
  recId: string;
  assetId: string;
  dataAreaId: string;
  emplId: string;
  emplIdNew: string;
  petitionerId: string;
  approverId: string;
  worker: string;
  workerNew: string;
  petitioner: string;
  approver: string;
  locationId: string;
  locationIdNew: string;
  status: string;
  motive: string;
  attachment: string;

  getAssetId() {
    return this.assetId;
  }

  getDataAreaId() {
    return this.dataAreaId;
  }

  getEmplId() {
    return this.emplId;
  }

  getEmplIdNew() {
    return this.emplIdNew;
  }

  getApproverId() {
    return this.approverId;
  }

  getWorker() {
    return this.worker;
  }

  getWorkerNew() {
    return this.workerNew;
  }

  getApprover() {
    return this.approver;
  }

  getLocationId() {
    return this.locationId;
  }

  getLocationIdNew() {
    return this.locationIdNew;
  }

  getStatus() {
    return this.status;
  }

  getMotive() {
    return this.motive;
  }

  getRecId() {
    return this.recId;
  }

  getAttachment() {
    return this.attachment;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo FixedAsset.
   *
   * @param worker Persona encargada del activo.
   * @param workerNew Persona a quien se le solicita aceptar el activo.
   * @param locationNew Nuevo aposento del activo.
   * @param assetsTransList Lista de activos a transferir.
   *
   * @returns Una lista de tipo FixedAssetTransfer con todos los activos.
   */
  sortTransferedAsset(
    logger: Worker,
    worker: Worker,
    workerNew: Worker,
    locationNew: AssetLocation,
    assetsTransList: FixedAsset[]
  ) {
    let assetList: FixedAssetTransfer[] = [];
    let asset: FixedAssetTransfer = new FixedAssetTransfer();
    for (let i = 0; i < assetsTransList.length; i++) {
      asset = new FixedAssetTransfer();
      asset.assetId = assetsTransList[i].getAssetId();
      asset.dataAreaId = assetsTransList[i].getDataAreaId().toLowerCase();
      asset.emplId = worker.getPersonnelNumber();
      asset.emplIdNew = workerNew.getPersonnelNumber();
      asset.petitionerId = logger.getPersonnelNumber();
      asset.worker = worker.getRecId();
      asset.workerNew = workerNew.getRecId();
      asset.petitioner = logger.getRecId();
      asset.locationId = assetsTransList[i].getLocation();
      asset.locationIdNew = locationNew.getLocation();
      if (assetsTransList[i].getApproval()) {
        asset.status = '3';
      } else {
        asset.status = '0';
      }
      assetList.push(asset);
      if (assetsTransList[i].getSubAssets().length) {
        assetList.push(
          ...this.sortTransferedAsset(
            logger,
            worker,
            workerNew,
            locationNew,
            assetsTransList[i].getSubAssets()
          )
        );
      }
    }
    return assetList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo FixedAsset.
   *
   * @param approver Persona encargada del activo.
   * @param pAssetRequestList Persona a quien se le solicita aceptar el activo.
   *
   * @returns Una lista de tipo FixedAssetTransfer con todos los activos.
   */
  sortAssetRequest(
    pApprover: Worker,
    pAssetRequestList: AssetRequest[],
    pMotive: string
  ) {
    let insertDataList: FixedAssetTransfer[] = [];
    let insertData: FixedAssetTransfer = new FixedAssetTransfer();
    for (let i = 0; i < pAssetRequestList.length; i++) {
      insertData = new FixedAssetTransfer();
      insertData.recId = pAssetRequestList[i].getRecId();
      insertData.approverId = pApprover.getPersonnelNumber();
      insertData.approver = pApprover.getRecId();
      insertData.dataAreaId = pAssetRequestList[i]
        .getDataAreaId()
        .toLocaleLowerCase();
      insertData.motive = pMotive;
      insertData.attachment = pAssetRequestList[i].getAttachment();
      insertDataList.push(insertData);
      if (pAssetRequestList[i].getSubRequest().length) {
        insertDataList.push(
          ...this.sortAssetRequest(
            pApprover,
            pAssetRequestList[i].getSubRequest(),
            pMotive
          )
        );
      }
    }
    return insertDataList;
  }
}
