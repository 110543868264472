export class PaymentTerm {
  constructor(private paymTermId: string, private description: string) {}

  getPaymTermId() {
    return this.paymTermId;
  }

  getDescription() {
    return this.description;
  }

  //Those functions are the same, but those names are because SearchProviderComponent works in that way

  getId() {
    return this.paymTermId;
  }

  getName() {
    return this.description;
  }

  setValues(newData: PaymentTerm) {
    this.paymTermId = newData.getPaymTermId();
    this.description = newData.getDescription();
  }
}
