// Native - Plugins
import { Component } from '@angular/core';

// BusinessCore
import { OrderTraceItem } from './../../businessCore/OrderTraceItem';

// Provider
import { NavParams } from '@ionic/angular';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-order-trace-progress',
  templateUrl: './order-trace-progress.component.html',
  styleUrls: [
    './order-trace-progress.component.scss',
    './order-trace-progress.component2.scss',
  ],
})
export class OrderTraceProgressComponent {
  info: {
    groudType: string;
    orderDate: string;
    orderDetail: string;
    orderState: string;
    time: { subtitle: string; title: string };
    icon: string;
  }[] = [];
  parameter: OrderTraceItem;

  /**
   * Class constructor with these injections:
   * @param _apiProvider web service provider
   * @param params object that exists on a page and can contain data for that particular view.
   */
  constructor(_apiProvider: webServiceProvider, private params: NavParams) {
    this.parameter = this.params.get('item');

    console.log(this.parameter);

    _apiProvider
      .getOrderTraceInfo(this.parameter.employeeId, this.parameter.salesId)
      .subscribe((data) => {
        this.info = data as {
          groudType: string;
          orderDate: string;
          orderDetail: string;
          orderState: string;
          time: { subtitle: string; title: string };
          icon: string;
        }[];
        //DEBUG
        /*
        this.info = [
          {
            groudType: '',
            orderDate: '01/01/2023',
            orderDetail: 'Detail',
            orderState: 'Indefinido',
            time: { subtitle: 'Subtitle', title: 'Title' },
            icon: 'fas fa-question',
          },
          {
            groudType: '',
            orderDate: '01/01/2023',
            orderDetail: 'Detail',
            orderState: 'Indefinido',
            time: { subtitle: 'Subtitle', title: 'Title' },
            icon: 'fas fa-question',
          },
        ];
        */
        //DEBUG
        this.info.forEach((item) => {
          let fecha: string = item.orderDate;
          let aux: { subtitle: string; title: string } = {
            subtitle: '',
            title: '',
          };
          aux.subtitle =
            fecha.substring(0, 4) +
            '-' +
            fecha.substring(4, 6) +
            '-' +
            fecha.substring(6, 8);
          aux.title = fecha.substring(8, 10) + ':' + fecha.substring(10, 12);
          item.time = aux;
          item.icon = this.IconOnStatus(item.orderState);
        });
        //
      });
  }

  IconOnStatus(salesStatus: string) {
    if (salesStatus == 'Indefinido') {
      return 'fas fa-question';
    } else if (salesStatus == 'Creada') {
      return 'fas fa-save';
    } else if (salesStatus == 'Recibida') {
      return 'fas fa-receipt';
    } else if (salesStatus == 'PosPuesta') {
      return 'fas fa-question';
    } else if (salesStatus == 'Cancelada') {
      return 'fas fa-ban';
    } else if (salesStatus == 'Aprobada') {
      return 'far fa-handshake';
    } else if (salesStatus == 'Facturada') {
      return 'fas fa-file-invoice';
    } else if (salesStatus == 'Recibida por Entregador') {
      return 'fas fa-truck';
    } else if (salesStatus == 'Entregada Totalmente') {
      return 'fas fa-star';
    } else if (salesStatus == 'Entregada Parcialmente') {
      return 'fas fa-star-half-alt';
    } else if (salesStatus == 'Devuelta Totalmente') {
      return 'fas fa-times-circle';
    } else if (salesStatus == 'Entrega postergada') {
      return 'far fa-clock';
    } else {
      return 'fas fa-question-circle';
    }
  }

  /*
  expandInfo(){
    this.expanded=!this.expanded;
  }
  */
}
