import { Component } from '@angular/core';
import {
  AlertController,
  IonRefresher,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { PurchOrderRemissionFilterComponent } from 'src/app/WMS/components/purch-order-remission-filter/purch-order-remission-filter.component';
import { DataArea } from 'src/app/businessCore/oc-models/DataArea';
import { SearchProviderComponent } from 'src/app/components/oc-components/search-provider/search-provider.component';
import { PurchLinesInfoParamv2 } from '../../OOP/PurchLinesInfoParamv2.interface';
import { PurchLinesSummary } from '../../OOP/PurchLinesSummary.interface';
import { InvoiceMode } from '../../OOP/invoiceMode.enum';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { PurchOrderInvoiceFilterPipe } from '../../pipes/PurchOrderInvoice.pipe';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-page-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListPage {
  loader: HTMLIonLoadingElement;

  empresaSelected: DataArea;
  listPurchOrders: PurchLinesInfoParamv2[] = [];
  listPurchOrdersPivot: PurchLinesInfoParamv2[] = [];
  listPurchOrdersToSearch: PurchLinesInfoParamv2[] = [];
  listVendorsSelected: string[] = [];
  rangeDatesSelected: Date[] = [];

  refresher: boolean = false;

  sortEvents = {
    PurchIdAsc: false,
    PurchIdDesc: true,
    VendorIdAsc: false,
    VendorIdDesc: false,
    VendorNameAsc: false,
    VendorNameDesc: false,
  };

  orderBy: string = '';

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private invServ: InvoiceService,
    private POInvoiceFilter: PurchOrderInvoiceFilterPipe,
    private navService: NavigationService
  ) {
    this.loadCompanies();
  }

  ionViewDidEnter() {
    let StatusOC = localStorage.getItem('StatusOC');

    if (StatusOC) {
      localStorage.removeItem('StatusOC');
      this.loadPurchOrders(this.empresaSelected);
    }
  }

  async loadCompanies() {
    await this.showSpinnerLoading('Cargando Compañías');
    this.invServ.listDataArea = await this.invServ.getDataAreaInfo();
    this.loader.dismiss();
  }

  async showSpinnerLoading(mensaje: string, spinner?: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async openSearchBarDataAreaId() {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: this.invServ.listDataArea,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.loadPurchOrders(data.objSelected);
        }
      }
    });
  }

  searchOC(event) {
    this.listPurchOrdersToSearch = this.POInvoiceFilter.transform(
      this.listPurchOrdersPivot,
      event.detail.value
    );
  }

  async filterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: PurchOrderRemissionFilterComponent,
      componentProps: {
        data: this.listPurchOrders,
        listVendors: this.listVendorsSelected,
        rangeDates: this.rangeDatesSelected,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null && data !== undefined) {
        let listPurchOrdersTmp: PurchLinesInfoParamv2[] = [];

        if (data.listVendors && data.listVendors.length > 0) {
          this.listVendorsSelected = data.listVendors as string[];
        }

        if (data.rangeDates && data.rangeDates.length > 0) {
          this.rangeDatesSelected = data.rangeDates;
        }

        listPurchOrdersTmp = this.listPurchOrders.filter((x) => {
          if (
            this.listVendorsSelected.length > 0 &&
            !this.listVendorsSelected.some((y) => y.includes(x.objectName))
          ) {
            return false;
          }
          let createdDate = new Date(x.createdDate);
          createdDate.setHours(createdDate.getHours() + 6);
          if (
            this.rangeDatesSelected.length > 0 &&
            !(
              createdDate >= new Date(this.rangeDatesSelected[0]) &&
              createdDate <= new Date(this.rangeDatesSelected[1])
            )
          ) {
            return false;
          }

          return true;
        });

        this.listPurchOrdersPivot =
          listPurchOrdersTmp.length > 0
            ? listPurchOrdersTmp
            : this.listPurchOrders;
        this.listPurchOrdersToSearch = this.listPurchOrdersPivot;
      } else {
        this.listVendorsSelected = [];
        this.rangeDatesSelected = [];
        this.listPurchOrdersPivot = this.listPurchOrders;
        this.listPurchOrdersToSearch = this.listPurchOrders;
      }
    });

    this.setListToShow();
  }

  private setSelectedOrder(orderType: string) {
    this.sortEvents.PurchIdAsc = 'PurchIdAsc' === orderType;
    this.sortEvents.PurchIdDesc = 'PurchIdDesc' === orderType;
    this.sortEvents.VendorIdAsc = 'VendorIdAsc' === orderType;
    this.sortEvents.VendorIdDesc = 'VendorIdDesc' === orderType;
    this.sortEvents.VendorNameAsc = 'VendorNameAsc' === orderType;
    this.sortEvents.VendorNameDesc = 'VendorNameDesc' === orderType;
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  async presentSortAlert() {
    let alert = await this.alertCtrl.create({
      header: '¿Cómo ordenar las OCs?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Orden de Compra (A -> Z)',
          'PurchIdAsc',
          this.sortEvents.PurchIdAsc
        ),
        this.getInputAlert(
          'radio',
          'Orden de Compra (Z -> A)',
          'PurchIdDesc',
          this.sortEvents.PurchIdDesc
        ),
        this.getInputAlert(
          'radio',
          'Código de Proveedor (A -> Z)',
          'VendorIdAsc',
          this.sortEvents.VendorIdAsc
        ),
        this.getInputAlert(
          'radio',
          'Código de Proveedor (Z -> A)',
          'VendorIdDesc',
          this.sortEvents.VendorIdDesc
        ),
        this.getInputAlert(
          'radio',
          'Nombre de Proveedor (A -> Z)',
          'VendorNameAsc',
          this.sortEvents.VendorNameAsc
        ),
        this.getInputAlert(
          'radio',
          'Nombre de Proveedor (Z -> A)',
          'VendorNameDesc',
          this.sortEvents.VendorNameDesc
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow();
            }
          },
        },
      ],
    });
    alert.present();
  }

  private setListToShow() {
    Object.keys(this.sortEvents).forEach((key) => {
      if (this.sortEvents[key]) {
        switch (key) {
          case 'PurchIdAsc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) => a.purchId.localeCompare(b.purchId)
            );
            break;

          case 'PurchIdDesc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) => b.purchId.localeCompare(a.purchId)
            );
            break;

          case 'VendorIdAsc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                a.objectName
                  .trim()
                  .split('|')[0]
                  .localeCompare(b.objectName.trim().split('|')[0])
            );
            break;

          case 'VendorIdDesc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                b.objectName
                  .trim()
                  .split('|')[0]
                  .localeCompare(a.objectName.trim().split('|')[0])
            );
            break;

          case 'VendorNameAsc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                a.objectName
                  .trim()
                  .split('|')[1]
                  .localeCompare(b.objectName.trim().split('|')[1])
            );
            break;

          case 'VendorNameDesc':
            this.listPurchOrdersToSearch = this.listPurchOrdersToSearch.sort(
              (a, b) =>
                b.objectName
                  .trim()
                  .split('|')[1]
                  .localeCompare(a.objectName.trim().split('|')[1])
            );
            break;

          default:
            break;
        }
      }
    });
  }

  async loadPurchOrders(company: any, refresher: boolean = false) {
    if (!refresher) {
      await this.showSpinnerLoading('Cargando Órdenes de Compra.');
    }

    this.empresaSelected = company;
    this.listPurchOrders = await this.invServ.getAllPurchOrders(
      this.empresaSelected.getId()
    );

    this.listPurchOrdersToSearch = this.listPurchOrders;
    this.listPurchOrdersPivot = this.listPurchOrders;

    if (!refresher) {
      this.loader.dismiss();
    }
  }

  async makeInvoice(PurchOrder: PurchLinesInfoParamv2) {
    await this.showSpinnerLoading(
      `Facturando ${PurchOrder.purchId}`,
      'bubbles'
    );

    let data: any = await this.invServ.InvoiceSpecificPurchOrder(
      this.empresaSelected.getId(),
      PurchOrder.purchId,
      InvoiceMode[PurchOrder.invoiceMode]
    );

    if (data) {
      let popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: MessageBoxComponent,
        componentProps: {
          MessageBoxParams: {
            header: data.Status ? 'INFO' : 'ERROR',
            message: data.Message,
            noYes: false,
          },
        },
      });
      popover.present();

      popover.onDidDismiss().then((res) => {
        let data = res.data;
        if (data.Status) {
          this.loadPurchOrders(this.empresaSelected);
        }
      });
    } else {
      let popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: MessageBoxComponent,
        componentProps: {
          MessageBoxParams: {
            header: 'ERROR',
            message: 'Ha ocurrido un error al realizar la operación.',
            noYes: false,
          },
        },
      });
      popover.present();
    }

    this.loader.dismiss();
  }

  async alertMakeInvoice(PurchOrder: PurchLinesInfoParamv2) {
    let OkCancel = false;
    let modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: MessageBoxComponent,
      componentProps: {
        MessageBoxParams: {
          header: 'AVISO',
          message: `¿Desea facturar la ${PurchOrder.purchId}?`,
          noYes: true,
        },
      },
      // leaveAnimation: '',
    });
    modal.present();

    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data.result) {
        this.makeInvoice(PurchOrder);
      }
    });

    return OkCancel;
  }

  async goToDetails(PurchOrder: PurchLinesInfoParamv2) {
    let PurchLines: PurchLinesSummary[] = [];
    await this.showSpinnerLoading('Se está cargando las líneas');
    PurchLines = await this.invServ.getAllPurchLines(
      this.empresaSelected.getId(),
      PurchOrder.purchId
    );
    if (PurchLines && PurchLines.length > 0) {
      this.navService.navigateTo(NavigationRoutes.InvoiceLinesPage, {
        Company: this.empresaSelected.getId(),
        PurchOrder: PurchOrder,
        PurchLines: PurchLines,
      });
      this.loader.dismiss();
    } else {
      this.loader.dismiss();
    }
  }

  async doRefresh(event) {
    let refresher: IonRefresher = event.detail;
    this.refresher = true;
    if (this.empresaSelected) {
      await this.loadPurchOrders(this.empresaSelected, this.refresher);
    }
    this.listVendorsSelected = [];
    this.rangeDatesSelected = [];

    this.setListToShow();

    this.refresher = false;
    refresher.complete();
  }
}
