// Native-Plugins
import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

// BusinessCore
import { RegisterData } from './../../businessCore/RegisterData';

@Component({
  selector: 'app-get-map-position',
  templateUrl: './get-map-position.component.html',
  styleUrls: ['./get-map-position.component.scss'],
})
export class GetMapPositionComponent {
  // atributo que contiene toda la informacion de registro de un determinado cliente
  register_data: RegisterData;

  /**
   * Class constructor with this injection:
   * @param navParams object that exists on a page and can contain data for that particular view.
   */
  constructor(public navParams: NavParams) {
    this.register_data = this.navParams.get('register_data') as RegisterData;
  }
}
