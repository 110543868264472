import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
  showHeader: boolean = true;

  constructor(public navParams: NavParams) {
    if (this.navParams.get('showHeader') != null) {
      this.showHeader = this.navParams.get('showHeader') as boolean;
    }
  }
}
