import { Component } from '@angular/core';

@Component({
  selector: 'app-asset-certification-periodicity',
  templateUrl: './asset-certification-periodicity.component.html',
  styleUrls: ['./asset-certification-periodicity.component.scss'],
})
export class AssetCertificationPeriodicityComponent {
  text: string;

  constructor() {
    //console.log('Hello AssetCertificationPeriodicityComponent Component');
    //this.text = 'Hello World';
  }
}
