import { AssetAudit } from './AssetAudit';

export class Audit {
  constructor(
    private auditRecId: number,
    private auditor: number,
    private petitioner: number,
    private auditedWorker: number,
    private auditedWorkerName: string,
    private auditedWorkerCompany: string,
    private auditedWorkerDepartment: string,
    private auditStatus: number,
    private auditDate: string,
    private assetAuditList: AssetAudit[]
  ) {}

  getAuditRecId() {
    return this.auditRecId;
  }

  getAuditor() {
    return this.auditor;
  }

  getPetitioner() {
    return this.petitioner;
  }

  getAuditedWorker() {
    return this.auditedWorker;
  }

  getAuditedWorkerName() {
    return this.auditedWorkerName;
  }

  getAuditStatus() {
    return this.auditStatus;
  }

  getAuditDate() {
    return this.auditDate;
  }

  getAuditedWorkerCompany() {
    return this.auditedWorkerCompany;
  }

  getAuditedWorkerDepartment() {
    return this.auditedWorkerDepartment;
  }

  getAssetAuditList() {
    return this.assetAuditList;
  }
}
