<app-nav-bar [title]="'Confirmar O.C'"></app-nav-bar>

<ion-toolbar class="oc-parameter">
  <ion-segment
    color="black"
    value="toConfirm"
    (ionChange)="changedSegmentValue($event)"
  >
    <ion-segment-button value="toConfirm" layout="icon-start">
      <ion-label class="labelCenter">Por Confirmar</ion-label>
    </ion-segment-button>
    <ion-segment-button value="record" layout="icon-start">
      <ion-label class="labelCenter">Historial</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-toolbar>

<!--Filtros de OC-->
<ion-searchbar
  type="text"
  placeholder="Buscar"
  (ionChange)="searchOC($event)"
  style="background-color: white"
></ion-searchbar>

<div
  class="flex-container nowrap"
  style="background-color: white; display: flex"
>
  <ion-button
    class="filter-btn"
    style="width: 100%"
    (click)="presentFilterPopover()"
  >
    Filtros
    <ion-icon name="options"></ion-icon>
  </ion-button>

  <ion-button class="sort-btn" style="width: 100%" (click)="presentSortAlert()">
    Ordenar
    <ion-icon name="reorder-three-outline"></ion-icon>
  </ion-button>
</div>
<!--//////////////////////////////////////////////////////////////////////////////////////////////-->

<ion-content style="background-color: white">
  <ion-refresher (ionRefresh)="refresOC($event)" slot="fixed">
    <ion-refresher-content
      pullingIcon="arrow-dropdown"
      pullingText="Deslice para actualizar la información de las ordenes"
      refreshingSpinner=""
      refreshingText=""
      slot="fixed"
    >
    </ion-refresher-content>
  </ion-refresher>

  <div class="box">
    <ion-list lines="none" *ngIf="segmentModel === 'toConfirm'">
      <app-to-be-confirmed-oc
        *ngFor="let purch of listPurchShowing"
        [infoPurch]="purch"
      >
      </app-to-be-confirmed-oc>
    </ion-list>

    <ion-list lines="none" *ngIf="segmentModel === 'record'">
      <app-to-be-confirmed-oc
        *ngFor="let purchR of listPurchRecordShowing"
        [infoPurch]="purchR"
        [recordConfirmed]="true"
      >
      </app-to-be-confirmed-oc>
    </ion-list>
  </div>
</ion-content>
