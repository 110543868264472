// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

// BusinessCore
import { Contact } from './../../businessCore/Contact';

// Native - Plugins
import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

//import { environment as ENV } from '../../environments/environment';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss'],
})
export class NewContactComponent {
  contact_data: Contact = new Contact();
  present_alert: boolean = false;

  /**
   * Class constructor with these injections:
   * @param viewController Access various features and information about the current view.
   * @param navParams object that exists on a page and can contain data for that particular view
   * @param _apiProvider web service provider
   */
  constructor(
    private viewController: PopoverController,
    private navParams: NavParams,
    public _apiProvider: webServiceProvider
  ) {
    this.contact_data = this.navParams.get('contact');
  }

  /**
   * Metodo utilizado para añadir un contacto al registro de informacion
   * que esta llevando a cabo el usuario
   */
  addContact() {
    if (
      this.contact_data.contact != null &&
      this.contact_data.contact / 10000000 >= 1 &&
      this.contact_data.contact / 10000000 < 10
    ) {
      this.contact_data.edit = true;
      this.viewController.dismiss(this.contact_data);
    } else {
      this.present_alert = true;
    }
  }

  /**
   * Metodo utilizado para validar el formulario de datos solicitado para
   * añadir nuevos contactos
   */
  validForm() {
    if (
      this.contact_data.contact &&
      this.contact_data.description &&
      this.contact_data.countryCode
    ) {
      if (this.contact_data.type) {
        return true;
      } else if (this.contact_data.contactName) {
        return true;
      }
    }
    return false;
  }
}
