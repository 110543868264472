<app-nav-bar [title]="'Registro'" [start]="'GoBack'"></app-nav-bar>

<ion-content class="ion-padding" center text-center>
  <h3 margin-bottom>Dirección</h3>
  <ion-button expand="block" (click)="presentNewPopover()">
    <ion-icon name="md-pin" class="mr-5"></ion-icon> Agregar Nueva
  </ion-button>

  <form #registerForm="ngForm">
    <ion-list>
      <ion-item
        *ngFor="let direction of register_data.directions"
        class="direction"
        (click)="presentPopover(direction)"
      >
        <ion-label>
          <ion-icon
            name="checkmark-outline"
            *ngIf="direction.is_principal"
            style="z-index: 1; margin-right: 4px"
          ></ion-icon
          >{{ direction.directionName }}</ion-label
        >
        <ion-icon
          (click)="deleteDirection(direction)"
          name="trash"
          style="z-index: 1"
          item-right
        ></ion-icon>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-fab horizontal="end" vertical="bottom" small>
  <ion-fab-button
    color="primary"
    class="submit-btn"
    type="submit"
    [disabled]="!validForm()"
    size="small"
    (click)="registerNextStep()"
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
