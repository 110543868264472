export class GastoModel {
  private dataArea: string;
  private executor: string;
  private FMTrips: string;
  private RecId: number = null;

  constructor(
    private factura: string,
    private amount: number,
    private description: string,
    private vendorDescription: string
  ) {}

  setVendorDescription(newVendorDescription: string) {
    this.vendorDescription = newVendorDescription;
  }

  getVendorDescription() {
    return this.vendorDescription;
  }

  setRecId(newRecId: number) {
    this.RecId = newRecId;
  }

  getRecId() {
    return this.RecId;
  }

  setFMTrips(newFMTrips: string) {
    this.FMTrips = newFMTrips;
  }

  getFMTrips() {
    return this.FMTrips;
  }

  setFactura(newFactura: string) {
    this.factura = newFactura;
  }

  getFactura() {
    return this.factura;
  }

  setAmount(newAmount: number) {
    this.amount = newAmount;
  }

  getAmount() {
    return this.amount;
  }

  setDescription(newDescription: string) {
    this.description = newDescription;
  }

  getDescription() {
    return this.description;
  }

  setDataArea(newDataAreaId: string) {
    this.dataArea = newDataAreaId;
  }

  getDataArea() {
    return this.dataArea;
  }

  setExecutor(newExecutor: string) {
    this.executor = newExecutor;
  }

  getExecutor() {
    return this.executor;
  }

  ToJSON() {
    const objeto = {
      FMTrips: this.FMTrips,
      Description: this.description,
      DataArea: this.dataArea,
      Amount: this.amount,
      Executor: this.executor,
      InvoiceNum: this.factura,
      RecId: this.RecId !== null && this.RecId !== undefined ? this.RecId : 0,
      VendorDescription: this.vendorDescription,
    };

    return objeto;
  }
}
