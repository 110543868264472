import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { FixedAsset } from '../../businessCore/FixedAsset';
import { webServiceProvider } from '../../provider/webServiceProvider';

@Component({
  selector: 'app-asset-audit-unassigned-asset',
  templateUrl: './asset-audit-unassigned-asset.component.html',
  styleUrls: ['./asset-audit-unassigned-asset.component.scss'],
})
export class AssetAuditUnassignedAssetComponent {
  barcode: string;
  assetList: FixedAsset[];
  unassignedAssetList: FixedAsset[];

  constructor(
    private _apiProvider: webServiceProvider,
    public viewCtrl: PopoverController,
    private navParams: NavParams
  ) {
    this.assetList = [];
    this.barcode = '';
    this.unassignedAssetList = this.navParams.get('unassignedAssetList');
  }

  async getAsset() {
    const resp = await this._apiProvider.getAsset(this.barcode);
    this.assetList = resp as FixedAsset[];
  }

  checkAsset(pAssetRecId) {
    if (
      this.unassignedAssetList.find(
        (element) => element.getAssetRecId() === pAssetRecId
      )
    ) {
      return true;
    }
    return false;
  }

  selectAsset(pIndex: number) {
    this.dismiss(this.assetList[pIndex]);
  }

  dismiss(pSelectedAsset: FixedAsset) {
    if (pSelectedAsset.getAssetRecId()) {
      this.viewCtrl.dismiss({ selectedAsset: pSelectedAsset });
    } else {
      this.viewCtrl.dismiss();
    }
  }
}
