export class Bank {
  constructor(private bankGroupID: string, private name: string) {}

  getBankGroupID() {
    return this.bankGroupID;
  }

  getName() {
    return this.name;
  }

  //

  /**
   * This function is the same as getBankGroupID the one above,
   * but SearchProviderComponent needs the function with this name
   * to work properly
   */
  getId() {
    return this.bankGroupID;
  }

  setValues(newData: Bank) {
    this.bankGroupID = newData.getBankGroupID();
    this.name = newData.getName();
  }
}
