<app-nav-bar [title]="'Carrito - Entrega'"></app-nav-bar>

<ion-content class="ion-padding">
  <ion-item>
    <ion-badge
      style="text-align: center; background: blue; margin: auto; padding: 3px"
    >
      <h6 style="color: aliceblue; font-weight: bold; margin: 0px">2/3</h6>
    </ion-badge>
  </ion-item>

  <div style="width: 100%; display: flex; justify-content: center">
    <h5 ion-text text-center text-wrap>
      Total de la orden: {{ setCurrency(totalOrder) }}
    </h5>
  </div>

  <ion-item>
    <ion-select
      label="Método de Entrega"
      label-placement="floating"
      class="floating"
      [(ngModel)]="ordHerder.deliveryMethod"
      (ionChange)="changeMethod()"
      style="width: 100%; border: 0px"
    >
      <ion-select-option value="Inteca">Entrega en dirección</ion-select-option>
      <ion-select-option value="RInteca"
        >Retira en Inteca Cartago</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item>
    <a style="color: brown; margin-right: 2%">*</a>
    <ion-select
      label="{{
        ordHerder.deliveryMethod == 'Inteca'
          ? 'Dirección de Entrega'
          : 'Dirección de Facturación'
      }}"
      label-placement="floating"
      class="floating"
      [(ngModel)]="ordHerder.addressId"
      (ionChange)="selectAddress(ordHerder.addressId)"
      placeholder="Dirección"
      style="width: 100%; border: 0px"
    >
      <ion-select-option
        *ngFor="let item of register_data.directions"
        value="{{ item.recId }}"
        >{{ item.direction }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item>
    <a style="color: brown; margin-right: 2%">*</a>
    <ion-select
      label="Fecha de Entrega:"
      label-placement="floating"
      class="floating"
      [(ngModel)]="ordHerder.deliveryDate"
      [disabled]="disabledDeliveryDate"
      placeholder="DD/MM/YY"
      style="width: 100%; border: 0px"
      *ngIf="ordHerder.deliveryMethod == 'Inteca'"
    >
      <ion-select-option
        *ngFor="let item of addressAvailableDates.Dates"
        value="{{ item.Date.toISOString() }}"
        >{{ item.DateFmt }}</ion-select-option
      >
    </ion-select>
    <ion-select
      label="Fecha de Retiro:"
      label-placement="floating"
      class="floating"
      [(ngModel)]="ordHerder.deliveryDate"
      placeholder="DD/MM/YY"
      style="width: 100%; border: 0px"
      *ngIf="ordHerder.deliveryMethod == 'RInteca'"
    >
      <ion-select-option
        *ngFor="let item of retDeliveryDates"
        value="{{ item.Date.toISOString() }}"
        >{{ item.DateFmt }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item lines="none">
    <h6 ion-text text-wrap>{{ addressNameSelected }}</h6>
  </ion-item>
</ion-content>

<ion-fab vertical="bottom" horizontal="start">
  <ion-fab-button (click)="dismiss()" size="small">
    <ion-icon name="chevron-back"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button (click)="NextStep()" [disabled]="validForm()" size="small">
    <ion-icon name="chevron-forward"></ion-icon>
  </ion-fab-button>
</ion-fab>
