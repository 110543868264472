export class AuditFinding {
  constructor(
    public auditFindingRecId: number,
    public finding: string,
    public description: string,
    public nonConformity: boolean,
    public selected: boolean
  ) {}

  getAuditFindingRecId() {
    return this.auditFindingRecId;
  }

  getFinding() {
    return this.finding;
  }

  getDescription() {
    return this.description;
  }

  getNonConformity() {
    return this.nonConformity;
  }

  getSelected() {
    return this.selected;
  }

  setSelected(pSelected: boolean) {
    this.selected = pSelected;
  }

  deepCopyAuditFinding() {
    let resp = new AuditFinding(
      this.auditFindingRecId,
      this.finding,
      this.description,
      this.nonConformity,
      this.selected
    );
    return resp;
  }
}
