export class CheckListInstance {
  private vatNum: string;
  showInstance: boolean;
  position: number;
  inLocal: boolean;
  state: number;
  private reason: string;

  constructor(
    private assignmentID: string,
    private description: string,
    private checkList: string,
    private instanceRecId: number,
    private executionDate: Date,
    private lastExecution: Date
  ) {
    if (this.executionDate !== null && this.executionDate !== undefined) {
      this.executionDate.setHours(this.executionDate.getHours() + 6);
    }
    if (this.lastExecution !== null && this.lastExecution !== undefined) {
      this.lastExecution.setHours(this.lastExecution.getHours() + 6);
    }
    this.showInstance = true;
    this.inLocal = false;
  }

  setLastExecution(newLastExecution: Date) {
    this.lastExecution = newLastExecution;
  }

  getLastExecution() {
    return this.lastExecution;
  }

  setReason(newReason: string) {
    this.reason = newReason;
  }

  getReason() {
    return this.reason;
  }

  setEstado(newState: number) {
    this.state = newState;
  }

  getEstado() {
    return this.state;
  }

  setInLocal(newInLocal: boolean) {
    this.inLocal = newInLocal;
  }

  getInLocal() {
    return this.inLocal;
  }

  setVatNum(newVatNum: string) {
    this.vatNum = newVatNum;
  }

  getVatNum() {
    return this.vatNum;
  }

  getAssigmentID() {
    return this.assignmentID;
  }

  setAssigmentID(newAssignmentID: string) {
    this.assignmentID = newAssignmentID;
  }

  getDescription() {
    return this.description;
  }

  setDescription(newDescription: string) {
    this.description = newDescription;
  }

  getCheckList() {
    return this.checkList;
  }

  setCheckList(newCheckList: string) {
    this.checkList = newCheckList;
  }

  getInstanceRecId() {
    return this.instanceRecId;
  }

  setInstanceRecId(newInstanceId: number) {
    this.instanceRecId = newInstanceId;
  }

  getExecutionDate() {
    return this.executionDate;
  }

  setExecutionDate(newExecutionDate: Date) {
    this.executionDate = newExecutionDate;
  }

  toJSONLocal() {
    return {
      VatNum: this.vatNum,
      ShowInstance: this.showInstance ? 1 : 0,
      Position: this.position,
      AssignmentID: this.assignmentID,
      Description: this.description,
      CheckList: this.checkList,
      InstanceRecId: this.instanceRecId,
      ExecutionDate: this.executionDate,
      Estado: this.state,
      LastExecution: this.lastExecution,
    };
  }
}
