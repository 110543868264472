<app-nav-bar [title]="'Facturar'"></app-nav-bar>

<ion-content lines="none">
  <div class="box">
    <div lines="none" class="barTitle">
      <ion-label>
        <h2 class="h2Select">{{ PurchOrder.purchId }}</h2>
        <h3>{{ PurchOrder.objectName }}</h3>
      </ion-label>
    </div>

    <div class="content">
      <ion-list lines="none" *ngIf="PurchLines">
        <ion-card *ngFor="let Line of PurchLines">
          <ion-card-content>
            <ion-item>
              <div class="cardContent">
                <div class="subContent title">
                  <h1 style="font-size: medium">
                    <b>Línea {{ Line.LineNumber }}:</b> {{ Line.ItemId }}
                  </h1>
                </div>
                <div class="subContent productInfo">
                  <span class="productName">{{ Line.ProductName }}</span>
                </div>
                <hr class="divider" />
                <div class="subContent productInfo">
                  <div class="contentProductPriceQty">
                    <span class="productPriceQty"
                      ><b>Cant:</b> {{ Line.Qty + " " + Line.UnitId }}</span
                    >
                    <span class="productPriceQty"
                      ><b> Precio:</b>
                      {{
                        Line.Price | currency : "CRC" : "symbol-narrow"
                      }}</span
                    >
                  </div>
                </div>
                <hr class="divider" />
                <div class="subContent totals">
                  <span
                    ><b>Descuento:</b>
                    {{
                      Line.Discount | currency : "CRC" : "symbol-narrow"
                    }}</span
                  >
                  <span
                    ><b>Total:</b>
                    {{
                      Line.LineAmount | currency : "CRC" : "symbol-narrow"
                    }}</span
                  >
                </div>
              </div>
            </ion-item>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </div>
  </div>

  <div class="containerButtonCustom">
    <hr class="dividerFinal" />
    <div class="totalAmountNoLines">
      <span><b>No. Líneas:</b> {{ PurchOrder.qtyLines }}</span>
      <span
        ><b>Total:</b>
        {{ PurchOrder.totalAmount | currency : "CRC" : "symbol-narrow" }}</span
      >
    </div>
    <hr class="dividerFinal" />
    <ion-button
      ion-button
      class="button_custom"
      expand="block"
      style="margin: 15px"
      type="submit"
      (click)="alertMakeInvoice()"
    >
      Facturar
    </ion-button>
  </div>
</ion-content>
