// BusinessCore
import { filteringOptions } from './../../businessCore/filteringOptions';

// Native-Plugins
import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { environment } from 'src/environments/environment';
import { InventoryItem } from '../../businessCore/InventoryItem';

//Environment

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  //Inventario de productos desplegados en Home
  itemInfo: InventoryItem[] = [];

  // Atributo con el nombre del producto que se desea buscar
  productName: string;

  // Objeto con todas las opciones de filtrado disponibles segun categorias
  filter: filteringOptions = new filteringOptions();

  // Objeto que representa el componente de filtrado segun precio (Min-Max)
  structure: any;

  displayPriceSection: boolean = true;

  sitesFMCM: any;

  /**
   * Class constructor with these injections:
   * @param viewCtrl Access various features and information about the current view.
   * @param params Object that exists on a page and can contain data for that particular view.
   */
  constructor(
    public viewCtrl: PopoverController,
    private params: NavParams,
    private storage: StorageService
  ) {
    this.filter = this.params.get('filter') as filteringOptions;

    this.filter.maxPrice = Math.round(this.filter.maxPrice * 100) / 100;
    this.filter.minPrice = Math.round(this.filter.minPrice * 100) / 100;

    this.filter.families = this.filter.families ? this.filter.families : [];

    if (this.filter.minPriceSelected > 0) {
      this.structure = {
        lower: this.filter.minPriceSelected,
        upper: this.filter.maxPriceSelected,
      };
    } else {
      this.structure = {
        lower: this.filter.minPrice,
        upper: this.filter.maxPrice,
      };
    }

    // Si el valor mas alto es menor o igual a cero que se oculte la seccion de precios
    this.displayPriceSection = this.structure.upper <= 0 ? false : true;

    this.setItemsInfo();

    //RQ0397 - Multicompany Multisite
    this.sitesFMCM = environment.FMCM_SITES_NAMES;
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y efectuar
   * los cambios deseados para que el sistema muestre los productos
   * que coincidad con la selccion
   */
  dismiss() {
    this.filter.minPriceSelected = this.structure.lower;
    this.filter.maxPriceSelected = this.structure.upper;

    this.viewCtrl.dismiss(this.filter);
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y sin efectuar
   * ningun cambio en el filtrado de productos
   */
  dismissNull() {
    this.viewCtrl.dismiss(null);
  }

  /**
   * Metodo utilizado para reemplazar el underscore en el title de cada una de las familias
   * @param name string con el nombre de la familia que se le dará formato
   * @returns string without underscore
   */
  replaceUnderScore(name: string) {
    return name.replace(/_/g, ' ');
  }

  /**
   * Metodo utlizado para determinar los datos de productos que se tienen cargados en la aplicación
   * Se realiza un llamado al storage del dispositivo haciendo un get de la llave {productos}
   */
  setItemsInfo() {
    this.storage.get('productos').then((data) => {
      this.itemInfo = JSON.parse(data);
      if (this.itemInfo == null) {
        this.itemInfo = [];
      } else {
        this.getFamilies();
      }
    });
  }

  /**
   * Metodo utilizado para determinar cuales familias son las que estan ligadas a cada uno de los productos de manera tal que se
   * determine un conjunto no repetido de strings con los nombres de dichas familias.
   * Se utiliza para hacer que los filtros sean dinamicos segun las familias que tienen los productos
   */
  getFamilies() {
    //Conjunto de familias desplegadas en los criterios de filtrado
    //let families_info: { title: string, selected: boolean }[] = [];

    this.itemInfo.forEach((item) => {
      if (
        this.filter.families.filter(
          (family) =>
            family.title == item.Hierarchy1.toUpperCase().replace(/ /g, '_')
        ).length <= 0
      ) {
        this.filter.families.push({
          title: item.Hierarchy1.toUpperCase().replace(/ /g, '_'),
          selected: false,
        });
      }
    });
  }

  /**
   * Metodo utilizado para negar el atributo selected del objeto familia
   * @param family objeto con la estructura de familia que se esta trabajando en el componente {filter}
   */
  clickFilterOption(family: { title: string; selected: boolean }) {
    family.selected = !family.selected;
  }

  getSiteName(InventSiteId: string) {
    return environment.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }
}
