<app-nav-bar [title]="title"></app-nav-bar>

<ion-content>
  <div class="imgHeader">
    <img [src]="imgUrl" alt="" onerror="" />
  </div>

  <div style="text-align: center" *ngIf="loadingCustomerInfo">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando información del cliente</h6>
  </div>

  <div *ngIf="customerInfoError && !loadingCustomerInfo">
    No se pudo consultar la información del usuario.
  </div>

  <div *ngIf="!customerInfoError && !loadingCustomerInfo">
    <div class="flexContainer" style="padding: 5px">
      <!-- Edicion del correo asociado a la direccion o informacion general de la persona -->
      <ion-button
        class="submit-btn"
        margin-top
        style="
          margin-right: 5px;
          font-size: 12px;
          font-family: sans-serif;
          width: 100%;
        "
        shape="round"
        (click)="addRestriction()"
      >
        <i class="fas fa-map-marker-alt" style="margin-right: 5px"></i>Agregar
        restricción
      </ion-button>
    </div>

    <h6 margin-bottom style="padding: 5px; text-align: center">
      Seleccione una dirección
    </h6>

    <!--
      <div class="flexContainer" style="padding: 5px;">
        <ion-checkbox class="chkBox" [(ngModel)]="userEmailChecked" (click)="userEmailChecked = !userEmailChecked;"></ion-checkbox>
        <div class="flexBlockContainer infoContainer">
          <div class="addressNameTitle">Correo electrónico asociado al usuario:</div>
          <div class="addressDescription">{{customer_info.email}}</div>
        </div>
      </div>
  
      <div class="flexContainer" style="padding: 5px;">
        <ion-checkbox class="chkBox" [(ngModel)]="userEmail2Checked" (click)="userEmail2Checked = !userEmail2Checked;"></ion-checkbox>
        <div class="flexBlockContainer infoContainer">
          <div class="addressNameTitle">Correo electrónico secundario asociado al usuario: </div>
          <div class="addressDescription">{{customer_info.email_normal}}</div>
        </div>
      </div> -->

    <!-- Ng-For para mostrar las direcciones -->
    <div *ngFor="let addressItem of addressInfo">
      <div
        class="flexContainer"
        lines="none"
        style="padding: 5px"
        (click)="checkItem(addressItem)"
      >
        <ion-checkbox
          class="chkBox"
          [disabled]="true"
          [(ngModel)]="addressItem.checked"
          (click)="checkItem(addressItem)"
        ></ion-checkbox>
        <div class="flexBlockContainer infoContainer">
          <div class="addressNameTitle">
            {{ addressItem.address.AddressName }}
          </div>
          <div class="addressDescription">
            {{ addressItem.address.Address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
