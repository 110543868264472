import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { InfoFileOcPopComponent } from 'src/app/components/oc-components/info-file-oc-pop/info-file-oc-pop.component';
import { DateTimeOperation } from '../../businessCore/oc-models/DateTimeOperations';
import { PurchLine } from '../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { RejectedNoteOCComponent } from '../../components/oc-components/rejected-note-oc/rejected-note-oc.component';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../provider/Services/local-service-oc.service';
import { ServicesOcParameterProvider } from '../../provider/Services/services-oc-parameter';

@Component({
  selector: 'app-purch-order-to-approved',
  templateUrl: './purch-order-to-approved.component.html',
  styleUrls: ['./purch-order-to-approved.component.scss'],
})
export class PurchOrderToApprovedPage {
  purchInfo: PurchOrder;
  total: number;
  cantidadLineas: number;
  impuestos: number;
  // @ViewChild('unlock') input: any;
  setIntID: any;
  presentingMess: boolean = false;
  loader: HTMLIonLoadingElement;
  sendingData: boolean = false;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private extServOC: ExternalServiceOCService,
    private loadingCtrl: LoadingController,
    public alertController: AlertController,
    private ocParameter: ServicesOcParameterProvider,
    private localOcService: LocalServiceOCService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    private navService: NavigationService,
    private platform: Platform
  ) {
    // Extrae la informacion de la lineas de una orden de compra.
    this.purchInfo = this.ocParameter.getPurchOrderParameter();
  }

  async ngOnInit() {
    // if (this.auth == 1) {
    //   if (await this.authGuard.checkToken() && this.authGuard.componentAllow(this.authService.getComponentsInfo(), componentId)) {
    //     this.vatnum = this.authService.getTokenData("refresh");
    //   } else {
    //     window.location.href = "http://giiis01:8025/";
    //   }
    // } else {
    //   if(this.authService.hasCookie()){
    //     window.location.replace(`http://localhost:8100/#/Activos/RecepcionDeTraslado/1/${componentId}`)
    //   }
    //   this.storage.get('register_data').then((data) => {
    //     const register_data = JSON.parse(data) as RegisterData;
    //     this.vatnum = register_data.id_card;
    //   }
    // }

    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
    this.impuestos = this.purchInfo.taxes;
    // this.input.nativeElement.value = 50;
    await this.getDataLines();
  }

  ionViewDidEnter() {
    this.purchInfo = this.ocParameter.getPurchOrderParameter();
    this.total = this.purchInfo.total;
    this.cantidadLineas = this.purchInfo.cantidadLineas;
    this.impuestos = this.purchInfo.taxes;
  }

  async getDataLines() {
    if (this.purchInfo !== null && this.purchInfo !== undefined) {
      await this.showSpinnerLoading('Cargando datos de líneas.');
      const resp = await this.extServOC.getToLinesOC(
        this.purchInfo.getDataArea().getId(),
        this.purchInfo.getIdOC(),
        this.purchInfo.getTaxInclude()
      );
      this.dissmissLoading();
      if (resp !== null) {
        const listTmp = resp as PurchLine[];
        this.purchInfo.setLineList(listTmp);
        if (listTmp.length === 0) {
          this.messageInfo(
            'Alerta',
            'No hay datos de líneas para ' + this.purchInfo.getIdOC() + '.'
          );
        } else {
          this.purchInfo.calcularDatos();
          this.total = this.purchInfo.total;
          this.cantidadLineas = this.purchInfo.cantidadLineas;
          this.impuestos = this.purchInfo.taxes;
        }
      } else {
        this.messageInfo(
          'Alerta',
          'Se produjo un error al consultar la información de las líneas.'
        );
      }
    } else {
      this.messageInfo('Alerta', 'No hay datos de OC para consultar.');
    }
  }

  // Permite regresar al componente de encabezado.
  ionViewDidLoad() {
    this.platform.backButton.subscribe(() => {
      this.goToBack();
    });
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  goToToBeApproved() {
    this.navCtrl.pop();
  }

  /* checkUnlock(evt: Event) {
    let theRange = Number(this.input.nativeElement.value);
    if ((evt.type == 'touchend' || evt.type == 'mouseout') && theRange === 100) {
      this.approvePurchOrder();
    } else if ((evt.type == 'touchend' || evt.type == 'mouseout') && theRange === 0){
      this.rejectPurchOrder();
    }
    this.sendSlideBack(theRange);
  } */

  /* sendSlideBack(theRange: number) {
    this.setIntID = setInterval(() => {
      if (theRange !== 50) {
        this.input.nativeElement.value = 50;
        clearInterval(this.setIntID);
      }
    });
  }*/

  approvePurchOrder() {
    this.messageInfo('Atención', '¿Desea APROBAR la orden de compra?', 1);
  }

  rejectPurchOrder() {
    this.messageInfo('Atención', '¿Desea RECHAZAR la orden de compra?', 2);
  }

  async changeDocumentStatePurchOrder_AUX(
    newState: number,
    notaRechazo?: string
  ) {
    if (!this.sendingData) {
      this.sendingData = true;
      await this.showSpinnerLoading('Enviando cambios...');
      try {
        const notaR: string = notaRechazo ? notaRechazo : '';
        const vatNum = await this.localOcService.getCedulaActual();
        const result = (await this.extServOC.updateDocumentState(
          this.purchInfo.getIdOC(),
          newState,
          this.purchInfo.getDataArea().getId(),
          vatNum,
          notaR
        )) as number;
        if (result === this.purchInfo.getDocumentStateI()) {
          this.messageInfo(
            'Error',
            'No se pudo actualizar el estado de la orden.',
            0
          );
        } else {
          this.purchInfo.setDocumentStateI(result);
          this.messageInfo(
            'Atención',
            'Se ha actualizado el estado de la orden ' +
              this.purchInfo.getIdOC() +
              ' a ' +
              this.purchInfo.getDocumentStateS(),
            -1
          );
        }
      } catch (error) {
        this.messageInfo(
          'Error',
          'No se pudo actualizar el estado de la orden.',
          0
        );
      }
      this.dissmissLoading();
      this.sendingData = false;
    }
  }

  async updatePurchaseOrder() {
    if (!this.sendingData) {
      this.sendingData = true;
      await this.showSpinnerLoading('Enviando cambios de OC.');
      try {
        const header = this.purchInfo.headerToJSON();
        await this.extServOC.updateHeaderPurchase(header);
        this.messageInfo(
          'Atención',
          'Cambios realizados a ' + this.purchInfo.getIdOC(),
          0
        );
      } catch (error) {
        this.messageInfo(
          'Error',
          'No se pudo actualizar la orden de compra.',
          0
        );
      }
      this.dissmissLoading();
      this.sendingData = false;
    }
  }

  async rechazarAux(estado: number) {
    const modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RejectedNoteOCComponent,
      componentProps: {
        cssClass: 'rejected-note-oc',
      },
    });
    await modal.present();
    // Se espera las opciones a actualizar, seleccionada por el usuario
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      this.presentingMess = false;
      if (data !== null && data !== undefined) {
        //no es un dato null, no se cerro por error
        this.validateSendAprobedPurchOrder(estado, data as string);
      }
    });
  }

  executeAction(type: number) {
    switch (type) {
      case 1:
        this.validateSendAprobedPurchOrder(30); // 'APROBADO' === 30
        break;
      case 2:
        this.rechazarAux(20); // 'RECHAZADO' === 20
        break;
      case 3:
        break;
      default:
        break;
    }
  }

  async validateSendAprobedPurchOrder(estado: number, note?: string) {
    // Se muestra el spinner
    await this.showSpinnerLoading('Verficando datos de la OC.');
    try {
      const result = await this.extServOC.getLastEditingDateApp(
        this.purchInfo.getIdOC(),
        this.purchInfo.getDataArea().getId()
      );
      // result = null => la orden no ha sido editada nunca
      if (result === null) {
        // Se debe de validar que no esta siendo editada
        this.isEditing(estado, note);
      } else {
        const comparation = DateTimeOperation.dateAComparatedateBandTime(
          this.purchInfo.getLastEditingAppDate(),
          result as Date
        );
        if (comparation === -1) {
          //La fecha que tenemos es menor a la actual
          // Se traen los datos nuevos.
          this.loader.message = 'Actualizando datos de la orden';
          const newInfo = await this.extServOC.getUpdateDataPO(
            this.purchInfo.getDataArea().getId(),
            -1,
            this.purchInfo.getIdOC(),
            -1,
            '',
            ''
          );
          if (newInfo !== null) {
            this.purchInfo.copyDataFromOtherObjet(newInfo[0] as PurchOrder);
            this.purchInfo.calcularDatos();
            this.total = this.purchInfo.total;
            this.cantidadLineas = this.purchInfo.cantidadLineas;
            this.impuestos = this.purchInfo.taxes;
            this.dissmissLoading();
            this.messageInfo(
              'Atención',
              'Los datos de la orden estaban desactualizados y fueron actualizados.',
              0
            );
          } else {
            this.dissmissLoading();
            this.messageInfo(
              'Error',
              'No se pudo traer la información más actual de la OC.',
              0
            );
          }
        } else {
          // la fecha que tenemos es igual a la actual
          // Se debe de validar si está siendo editada
          this.isEditing(estado, note);
        }
      }
    } catch (error) {
      console.log(error);
      this.dissmissLoading();
      this.messageInfo('Error', 'No se pudo realizar la opción solicitada.', 0);
    }
  }

  async isEditing(estado: number, notaRecha?: string) {
    try {
      this.loader.message = 'Verificando si la orden no está siendo editada.';
      let result = await this.extServOC.isEditing(
        this.purchInfo.getIdOC(),
        this.purchInfo.getDataArea().getId()
      );
      if (result === 'NOTEDITING') {
        // this.sendAprobedPurchOrder(); // como no está siendo editada
        this.dissmissLoading(); // Para evitar conflictos con la siguiente función.
        if (estado === 20) {
          const notaR =
            notaRecha !== null && notaRecha !== undefined ? notaRecha : '';
          this.changeDocumentStatePurchOrder_AUX(estado, notaR);
        } else {
          this.changeDocumentStatePurchOrder_AUX(estado);
        }
      } else {
        this.dissmissLoading();
        this.messageInfo(
          'Error',
          'La orden está siendo editada por un usuario.',
          0
        );
      }
    } catch (error) {
      this.dissmissLoading();
      this.messageInfo('Error', 'No se pudo aprobar de la orden.', 0);
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action > 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.executeAction(action);
          },
        },
        { text: 'NO', handler: () => {} },
      ];
    } else if (action && action === -1) {
      botones = [
        {
          text: 'ACEPTAR',
          handler: () => {
            this.navCtrl.pop();
          },
        },
      ];
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  /**
   * Permite regresar a la pantalla de lineas, si así lo quiso el usuario
   *  de lo contrario realiza un pop
   */
  goToBack() {
    // Mando la ordern actual como parametro
    this.ocParameter.setPurchOrderParameter(this.purchInfo);
    // Seteo los archivos de parametro en []
    this.ocParameter.setListFile([]);
    // Hago el pop de la pagina
    this.navCtrl.pop();
  }

  openHeaderOC() {
    // Mando la ordern actual como parametro
    this.ocParameter.setPurchOrderParameter(this.purchInfo);
    this.ocParameter.setToEdit(true);
    //this.navCtrl.push(PurchOrderPage);
    this.navService.navigateTo(NavigationRoutes.PurchOrderPage);
  }

  openLineOC() {
    // Mando la orden actual como parametro
    this.ocParameter.setPurchOrderParameter(this.purchInfo);
    this.ocParameter.setToEdit(true);
    this.navService.navigateTo(NavigationRoutes.PurchOrderLinePage);
  }

  // Abre el componente de archivos
  async openSendFile() {
    if (this.purchInfo !== null && this.purchInfo !== undefined) {
      const modal = await this.modalCtrl.create({
        component: InfoFileOcPopComponent,
        componentProps: {
          fileList: [],
          cantSendFile: false,
          ocId: this.purchInfo.getIdOC(),
          vatnum: '',
          company: this.purchInfo.getDataArea().getId(),
          type: 3,
        },
        backdropDismiss: false,
      });

      await modal.present();
      modal.onDidDismiss();
    }
  }
}
