import { AnswerTask } from './AnswerTask';
import { InfoImage } from './InfoImage';
import { OmissionTask } from './OmissionTask';

export class TaskCheck {
  private AnswerTaskList: AnswerTask[];
  private OmissionTaskList: OmissionTask[];
  public state: number; // 0 sin ejecutar, 1 ejecutada , 2 omitida
  public selected: boolean; // dado que no tenga configuradas respuestas, puede ser seleccionada con checkbox
  private observation: string; // dado que no tenga configuradas respuestas/omisiones, se setea la observacion en esta variable
  private attached: boolean;
  private imgData: InfoImage;
  private orderTask: number;
  constructor(
    private ID: string,
    private description: string,
    private name: string
  ) {
    this.AnswerTaskList = [];
    this.OmissionTaskList = [];
    this.state = 0;
    this.attached = false;
  }

  setAnswerTaskList(newAnswerTaskList: AnswerTask[]) {
    this.AnswerTaskList = newAnswerTaskList;
  }

  getAnswerTaskList() {
    return this.AnswerTaskList;
  }

  setOmissionTaskList(newOmissionTaskList: OmissionTask[]) {
    this.OmissionTaskList = newOmissionTaskList;
  }

  getOmissionTaskList() {
    return this.OmissionTaskList;
  }

  getID() {
    return this.ID;
  }

  setID(newID: string) {
    this.ID = newID;
  }

  getDescription() {
    return this.description;
  }

  setDescription(newDescription: string) {
    this.description = newDescription;
  }

  getName() {
    return this.name;
  }

  setName(newName: string) {
    this.name = newName;
  }

  getObservation() {
    return this.observation;
  }

  setObservation(newObser: string) {
    this.observation = newObser;
  }

  toJSON() {
    let result: any;
    if (this.state === 1) {
      result = this.getResultByList(this.AnswerTaskList);
    } else if (this.state === 2) {
      result = this.getResultByList(this.OmissionTaskList);
    } else {
      result = this.getAnswerOmission(this.getID(), this.state, '', '', '');
    }
    return result;
  }

  getAnswerOmission(
    idTaskCheckList: string,
    state: number,
    taskAnswerId: string,
    taskOmissionId: string,
    observation: string
  ) {
    const result = {
      TaskCheckList: idTaskCheckList,
      State: state,
      TaskAnswerCheckList: taskAnswerId,
      TaskOmissionCheckList: taskOmissionId,
      Observation: observation,
    };
    return result;
  }

  getResultByList(listInfo: any[]) {
    let info: any;
    if (listInfo.length !== 0) {
      const resplis = listInfo.find((data) => data.selected);
      if (resplis !== null && resplis !== undefined) {
        info =
          this.state === 1
            ? this.getAnswerOmission(
                this.getID(),
                this.state,
                resplis.ID,
                '',
                resplis.observation
              )
            : this.getAnswerOmission(
                this.getID(),
                this.state,
                '',
                resplis.ID,
                resplis.observation
              );
      }
    } else {
      info = this.getAnswerOmission(
        this.getID(),
        this.state,
        '',
        '',
        this.observation
      );
    }
    return info;
  }

  getAttached() {
    return this.attached;
  }

  setAttachecd(newAttached: boolean) {
    this.attached = newAttached;
  }

  getImageData() {
    return this.imgData;
  }

  setImageData(newInfoImage: InfoImage) {
    this.imgData = newInfoImage;
  }

  setOrderTask(newOrderTask: number) {
    this.orderTask = newOrderTask;
  }

  getOrderTask() {
    return this.orderTask;
  }

  savedImage() {
    let result = false;
    if (this.imgData !== undefined && this.imgData !== null) {
      result = true;
    }

    return result;
  }

  private toJSONLocalInfoAnswer() {
    const listInfo: any[] = [];
    for (const answer of this.AnswerTaskList) {
      listInfo.push(answer.toJSONLocal());
    }
    return listInfo;
  }

  private toJSONLocalInfoOmi() {
    const listInfo: any[] = [];
    for (const answer of this.OmissionTaskList) {
      listInfo.push(answer);
    }
    return listInfo;
  }

  private toJSONImage() {
    let obj: any = null;
    if (this.imgData !== undefined && this.imgData !== null) {
      obj = this.imgData.toJSONLocal();
    }
    return obj;
  }

  toJSONLocalInfo() {
    // AnswerTaskList: AnswerTask[];
    // OmissionTaskList: OmissionTask[];
    // ImgData: InfoImage;
    return {
      State: this.state, // 0 sin ejecutar, 1 ejecutada , 2 omitida
      Selected: this.selected ? 1 : 0, // dado que no tenga configuradas respuestas, puede ser seleccionada con checkbox
      Observation: this.observation, // dado que no tenga configuradas respuestas/omisiones, se setea la observacion en esta variable
      Attached: this.attached ? 1 : 0,
      OrderTask: this.orderTask,
      ID: this.ID,
      Description: this.description,
      Name: this.name,
      AnswerTaskList: this.toJSONLocalInfoAnswer(),
      OmissionTaskList: this.toJSONLocalInfoOmi(),
      ImgData: this.toJSONImage(),
    };
  }
}
