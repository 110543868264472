<app-nav-bar [title]="'Devolución - Productos'"></app-nav-bar>

<ion-content>
  <ion-item lines="none" style="margin-top: 20px">
    <ion-badge style="text-align: center; background: blue; margin: auto">
      <h6 style="color: aliceblue; font-weight: bold">1/2</h6>
    </ion-badge>
  </ion-item>

  <ion-list *ngFor="let item of rtnHeader.PSRtnLine; index as i">
    <ion-card>
      <ion-card-content>
        <ion-item-sliding (swipe)="swipeEvent($event, item, i)">
          <ion-item class="borderStyle">
            <ion-grid>
              <ion-row>
                <ion-col col-3>
                  <img
                    src="https://serviceplan-api.mercasacr.com/images/Products/{{
                      item.itmId
                    }}_l_.PNG"
                    onerror="this.src='assets/img/error.png'"
                    alt=""
                  />
                </ion-col>
                <ion-col col-9>
                  <ion-item lines="none">
                    <h6 class="h7">
                      {{ item.baseObject.NAME }}
                    </h6>
                    <ion-grid>
                      <ion-row style="display: flex; justify-content: center">
                        <ion-col col-4>
                          <h6 class="h7">
                            {{ item.slQntty }}
                          </h6>
                          <h6 class="h7">
                            {{ item.untId }}
                          </h6>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item-options side="end">
            <ion-item-option color="danger" (click)="deleteItem(item, i)">
              <ion-icon name="trash"> </ion-icon>
              <span style="font-size: 8px">ELIMINAR</span>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-card-content>
    </ion-card>
  </ion-list>

  <ion-item lines="none">
    <div style="display: flex; width: 100%">
      <h5 style="font-size: 1rem; width: 100%; text-align: center">
        <a style="margin-right: 0.2rem">N° Líneas:</a
        >{{ rtnHeader.PSRtnLine.length }}
      </h5>
      <h5 style="font-size: 1rem; width: 100%; text-align: center">
        <a style="margin-right: 0.2rem">N° Artículos:</a>{{ totalUnits }}
      </h5>
    </div>
  </ion-item>
</ion-content>

<ion-fab horizontal="end" vertical="bottom">
  <ion-fab-button (click)="NextStep()" size="small">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
