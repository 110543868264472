<app-nav-bar [title]="'Agregar al carrito'" [start]="'GoBack'"></app-nav-bar>
<ion-content style="--padding-top: 50px !important">
  <ion-fab vertical="top" horizontal="end">
    <ion-fab-button size="small">
      <ion-icon name="alert"></ion-icon>
    </ion-fab-button>
    <ion-fab-list>
      <ion-fab-button class="blue-fab" (click)="openComments()">
        <i class="fas fa-comments"></i>
      </ion-fab-button>
      <ion-fab-button class="blue-fab" (click)="ShareProduct()">
        <i class="fas fa-share-alt"></i>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>

  <ion-card class="card">
    <img
      class="center"
      src="https://serviceplan-api.mercasacr.com/images/Products/{{ item.ItemId }}_l_.PNG"
      onerror="this.src='assets/img/error.png'"
      alt=""
      style="margin: 10px auto"
    />
    <ion-card-header class="card-header">
      <ion-card-subtitle>{{ item.ItemId }}</ion-card-subtitle>
      <ion-card-title text-wrap>{{ item.ItemName }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div *ngFor="let item of ordLine">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dashed black;
            padding-top: 5px;
          "
        >
          <div style="display: block; width: 25%; text-align: center">
            <h6 ion-text style="margin: 0" class="h7">{{ item.unitId }}</h6>
            <!-- <h6 ion-text color="primary" >{{setSalePrice(item)}}</h6> -->
            <div ion-text color="primary" style="margin-top: 0" text-center>
              {{ setSalePrice(item) }}
            </div>
          </div>
          <ion-button
            style="z-index: 1"
            style="width: 10%"
            (pan)="reduceQuantityOnPressUp()"
            (pressup)="reduceQuantityOnPressUp()"
            (press)="reduceQuantityOnPress($event, item)"
            (click)="reduceQuantity(item)"
            class="button-blue"
          >
            <i style="font-size: large" class="fas fa-minus-circle"></i>
          </ion-button>
          <div style="width: 15%; text-align: center">
            <input
              class="quantityClass"
              type="number"
              name="salesQuantity"
              id="salesQuantity"
              [(ngModel)]="item.salesQuantity"
              (keyup)="changeValueKeyUp($event, item)"
              min="0"
              step="1"
            />
          </div>
          <ion-button
            class="button-blue"
            style="width: 10%"
            (pan)="increaseQuantityOnPressUp()"
            (pressup)="increaseQuantityOnPressUp()"
            (press)="increaseQuantityOnPress($event, item)"
            (click)="increaseQuantity(item)"
            class="button-blue"
          >
            <i style="font-size: large" class="fas fa-plus-circle"></i>
          </ion-button>
          <div style="display: flex; flex-direction: row; width: 25%">
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 75%;
                text-align: center;
              "
            >
              <h6 ion-text style="margin: 0" class="h7">Total</h6>
              <div ion-text color="primary" text-center>
                {{ setCurrency(item.totalSale) }}
              </div>
              <!-- <div ion-text color="secondary" style="font-size: 0.7em;" text-center *ngIf="item.taxAmount>0">{{'+ ' + setCurrency(item.taxAmount) + ' IVA'}}</div> -->
            </div>
            <div style="display: flex; flex-direction: column; width: 25%">
              <ion-button
                style="width: 10%; color: rgb(207, 15, 15); margin-left: auto"
                (click)="openFavorities(item)"
                *ngIf="!isOnList(item)"
                class="button-blue"
              >
                <i color="#ff0000" class="far fa-heart"></i>
              </ion-button>
              <ion-button
                style="width: 10%; color: rgb(207, 15, 15); margin-left: auto"
                (click)="openFavorities(item)"
                *ngIf="isOnList(item)"
                class="button-blue"
              >
                <i class="fas fa-heart"></i>
              </ion-button>
            </div>
          </div>
        </div>

        <div style="text-align: center">
          <ion-spinner
            name="dots"
            duration="1000"
            *ngIf="!complete"
          ></ion-spinner>
        </div>
      </div>

      <div style="margin-top: 2rem; display: flex; justify-content: center">
        <ion-button style="width: 50%" (click)="dismiss()">
          Agregar
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>

  <div class="above-container">
    <img
      class="center"
      src="http://186.176.206.154:8088/images/Products/{{ item.ItemId }}_l_.PNG"
      onerror="this.src='assets/img/error.png'"
      alt=""
      style="margin: 10px auto"
    />
    <p style="text-align: center; margin: 0px 20px" class="h7">
      {{ item.ItemName }}
    </p>
    <!-- <p ion-text text-center class="h7" style="font-size: 8px !important;">Nota: Los precios mostrados a continuación se muestran sin cálculo de decimales.</p> -->
    <p class="h7" style="color: #223e99 !important; text-align: center">
      {{ item.ItemId }}
    </p>

    <div *ngFor="let item of ordLine">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px dashed black;
          padding-top: 5px;
        "
      >
        <div style="display: block; width: 25%; text-align: center">
          <h6 ion-text style="margin: 0" class="h7">{{ item.unitId }}</h6>
          <div ion-text color="primary" style="margin-top: 0" text-center>
            {{ setSalePrice(item) }}
          </div>
        </div>
        <ion-button
          style="z-index: 1"
          style="width: 10%"
          (pan)="reduceQuantityOnPressUp()"
          (pressup)="reduceQuantityOnPressUp()"
          (press)="reduceQuantityOnPress($event, item)"
          (click)="reduceQuantity(item)"
          class="button-blue"
        >
          <i style="font-size: large" class="fas fa-minus-circle"></i>
        </ion-button>
        <div style="width: 15%; text-align: center">
          <input
            class="quantityClass"
            type="number"
            name="salesQuantity"
            id="salesQuantity"
            [(ngModel)]="item.salesQuantity"
            (keyup)="changeValueKeyUp($event, item)"
            min="0"
            step="1"
          />
        </div>
        <ion-button
          style="width: 10%"
          (pan)="increaseQuantityOnPressUp()"
          (pressup)="increaseQuantityOnPressUp()"
          (press)="increaseQuantityOnPress($event, item)"
          (click)="increaseQuantity(item)"
          class="button-blue"
        >
          <i style="font-size: large" class="fas fa-plus-circle"></i>
        </ion-button>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 25%;
            text-align: center;
          "
        >
          <h6 ion-text style="margin: 0" class="h7">Total</h6>
          <div ion-text color="primary" text-center>
            {{ setCurrency(item.totalSale) }}
          </div>
        </div>
        <ion-button
          style="width: 10%; color: rgb(207, 15, 15); margin-left: auto"
          (click)="openFavorities(item)"
          *ngIf="!isOnList(item)"
          class="button-blue"
        >
          <i color="#ff0000" class="far fa-heart"></i>
        </ion-button>
        <ion-button
          style="width: 10%; color: rgb(207, 15, 15); margin-left: auto"
          (click)="openFavorities(item)"
          *ngIf="isOnList(item)"
          class="button-blue"
        >
          <i class="fas fa-heart"></i>
        </ion-button>
      </div>

      <div style="text-align: center">
        <ion-spinner
          name="dots"
          duration="1000"
          *ngIf="!complete"
        ></ion-spinner>
      </div>
    </div>

    <div style="margin-top: 2rem; display: flex; justify-content: center">
      <ion-button (click)="dismiss()">Agregar</ion-button>
    </div>
  </div>
</ion-content>
