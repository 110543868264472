import { AuditFinding } from './AuditFinding';

export class AssetAudit {
  constructor(
    private assetRecId: number,
    private comment: string,
    private auditFindings: AuditFinding[],
    private auditFindingRecIds: number[],
    private assigned: number
  ) {}

  getAssetRecId() {
    return this.assetRecId;
  }

  getComment() {
    return this.comment;
  }

  getAuditFindings() {
    return this.auditFindings;
  }

  getAuditFindingRecIds() {
    return this.auditFindingRecIds;
  }

  getAssigned() {
    return this.assigned;
  }

  setComment(pVar: string) {
    this.comment = pVar;
  }

  setAuditFindings(pVar: AuditFinding[]) {
    this.auditFindings = pVar;
  }

  setAuditFindingRecIds(pVar: number[]) {
    this.auditFindingRecIds = pVar;
  }

  setAssigned(pVar: number) {
    this.assigned = pVar;
  }
}
