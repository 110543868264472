<app-nav-bar [title]="'Confirmar Pedidos'"></app-nav-bar>

<ion-content class="ion-padding">
  <div class="infoContainer">
    <i
      class="fa fa-exclamation-circle"
      style="color: goldenrod; margin-right: 5px"
    ></i
    >A continuación, se muestra el conjunto de pedidos por confirmar que han
    sido generados fuera del app Mercasa VIP y que involucran su usuario.
  </div>

  <ion-searchbar
    type="text"
    class="searchBar"
    [(ngModel)]="searchInput"
    placeholder="Buscar"
    (ionInput)="getFilteredItems($event)"
  ></ion-searchbar>

  <div style="text-align: center" *ngIf="loadingOrders">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 text-center>Cargando Pedidos</h6>
  </div>
  <div
    style="text-align: center; margin-top: 12px; font-weight: bold"
    *ngIf="!validOrderState()"
  >
    <h6>No existen pedidos por confirmar.</h6>
  </div>

  <ion-list>
    <ion-card
      *ngFor="let order of searchOrders"
      style="
        background: transparent;
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      "
    >
      <ion-item-sliding
        style="border-radius: 5px; overflow: hidden"
        (swipe)="swipeEvent($event, order)"
        class="item-wrapper"
      >
        <ion-item class="borderStyle">
          <div class="flexBlockContainer">
            <div class="flexContainer">
              <h6 class="titleContent">ID de la orden:</h6>
              <div class="textContent">{{ order.SALESID }}</div>
            </div>
            <div class="flexContainer">
              <h6 class="titleContent">Fecha de creación:</h6>
              <div class="textContent">{{ order.CREATEDDATETIME }}</div>
            </div>
            <div class="flexContainer">
              <h6 class="titleContent">Total del pedido:</h6>
              <div class="textContent">{{ setCurrency(order.TOTAL) }}</div>
            </div>
            <div class="flexContainer">
              <div
                class="deleteOrder"
                style="margin-right: 2px; text-align: center"
                (click)="presentConfirmPopover(order, false)"
              >
                <i class="fa fa-minus-circle" style="margin-right: 3px"></i>
                Rechazar
              </div>
              <div
                class="confirmOrder"
                style="margin-left: 2px; text-align: center"
                (click)="presentConfirmPopover(order, true)"
              >
                <i class="fa fa-check" style="margin-right: 3px"></i> Confirmar
              </div>
            </div>
          </div>
        </ion-item>

        <ion-item-options side="start">
          <ion-item-option
            style="background-color: var(--ion-color-primary)"
            (click)="presentInfoPopover(order)"
          >
            <i style="width: 35%" class="far fa-eye"></i>
            <span style="font-size: 8px">INFORMACIÓN</span>
          </ion-item-option>
        </ion-item-options>

        <ion-item-options side="end">
          <ion-item-option
            style="background-color: var(--ion-color-secondary)"
            (click)="presentProductsPopover(order)"
          >
            <i style="width: 35%" class="fas fa-clipboard-list"></i>
            <span style="font-size: 8px">PRODUCTOS</span>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-card>
  </ion-list>
</ion-content>
