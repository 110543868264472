<app-nav-bar [title]="'Productos'"
  ><ion-buttons slot="end">
    <ion-button style="background: none" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-button> </ion-buttons
></app-nav-bar>

<ion-content>
  <ion-list *ngFor="let item of order.LineHistory">
    <ion-card>
      <ion-card-content>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col size="3" style="display: flex">
                <img
                  src="https://serviceplan-api.mercasacr.com/images/Products/{{
                    item.ITEMID
                  }}_s_.PNG"
                  alt=""
                  onerror=""
                />
              </ion-col>
              <ion-col size="9">
                <ion-item>
                  <h6 ion-text text-center text-wrap class="h7">
                    {{ item.NAME }}
                  </h6>
                  <ion-grid>
                    <ion-row style="justify-content: center">
                      <ion-col size="6">
                        <h6
                          color="primary"
                          class="h7"
                          style="font-weight: bold"
                        >
                          {{ item.ITEMID }}
                        </h6>
                      </ion-col>
                      <ion-col size="6">
                        <h6 ion-text text-center class="h7">
                          {{ item.SALESQTY }} {{ item.SALESUNIT }}
                        </h6>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-list>
</ion-content>
