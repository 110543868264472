import { InfoImage } from './InfoImage';

export class ParadaModel {
  private dataArea: string;
  private latitud: number;
  private longitud: number;
  private latitudEnd: number;
  private longitudEnd: number;
  private type: number;
  private typeString: string;
  private typeCreation: string;
  private motivo: string;
  private descripcion: string;
  private addressID: number = 0;
  private kilometraje: number = null;
  private FMTrips: string;
  private partyNumber: string;
  private partyName: string;
  private InitDate: Date;
  private EndDate: Date;
  private RecId: number = null;
  private imageData: InfoImage = null;
  private haveImageSaved: boolean = false;

  constructor() {}

  setPartyName(newPartyName: string) {
    this.partyName = newPartyName;
  }

  getPartyName() {
    return this.partyName;
  }

  setImageData(imageData: InfoImage) {
    this.imageData = imageData;
    if (this.imageData !== null && this.imageData !== undefined) {
      this.setHaveImageSaved(true);
    }
  }

  setHaveImageSaved(newHaveImageSaved: boolean) {
    this.haveImageSaved = newHaveImageSaved;
  }

  getHaveImageSaved() {
    return this.haveImageSaved;
  }

  getImageData() {
    return this.imageData;
  }

  setLongitudEnd(newLongitudEnd: number) {
    this.longitudEnd = newLongitudEnd;
  }

  getLongitudEnd() {
    return this.longitudEnd;
  }

  setLatitudEnd(newLatitudEnd: number) {
    this.latitudEnd = newLatitudEnd;
  }

  getLatitudEnd() {
    return this.latitudEnd;
  }

  setRecId(newRecId: number) {
    this.RecId = newRecId;
  }

  getRecId() {
    return this.RecId;
  }

  setType(newType: number) {
    this.type = newType;
    this.setTypeString();
  }

  setAddressID(newAddressID: number) {
    this.addressID = newAddressID;
  }

  getAddressID() {
    return this.addressID;
  }

  private setTypeString() {
    switch (this.type) {
      case 0:
        this.typeString = 'INICIO';
        break;
      case 1:
        this.typeString = 'PARADA';
        break;
      case 2:
        this.typeString = 'FIN';
        break;
      default:
        break;
    }
  }

  setTypeByString(newType: string) {
    switch (newType) {
      case 'INICIO':
        this.setType(0);
        break;
      case 'PARADA':
        this.setType(1);
        break;
      case 'FIN':
        this.setType(2);
        break;
      default:
        break;
    }
  }

  getTypeString() {
    return this.typeString;
  }

  getType() {
    return this.type;
  }

  setLatitud(newLatitud: number) {
    this.latitud = newLatitud;
  }

  getLatitud() {
    return this.latitud;
  }

  setLongitud(newLongitud: number) {
    this.longitud = newLongitud;
  }

  getLongitud() {
    return this.longitud;
  }

  setKilometraje(newKilometraje: number) {
    this.kilometraje = newKilometraje;
  }

  getKilometraje() {
    return this.kilometraje;
  }

  getKilometrajeJSON() {
    return this.kilometraje === null ? -1 : this.kilometraje;
  }

  getDataArea() {
    return this.dataArea;
  }

  setDataArea(newDataArea: string) {
    this.dataArea = newDataArea;
  }

  getFMTrips() {
    return this.FMTrips;
  }

  setFMTrips(newFMTrips: string) {
    this.FMTrips = newFMTrips;
  }

  getPartyNumber() {
    return this.partyNumber;
  }

  setPartyNumber(newPartyNumber: string) {
    this.partyNumber = newPartyNumber;
  }

  getTypeCreation() {
    return this.typeCreation;
  }

  setTypeCreation(newTypeCreation: string) {
    this.typeCreation = newTypeCreation;
  }

  getInitDate() {
    return this.InitDate;
  }

  setInitDate(newInitDate: Date) {
    if (this.validateDate(newInitDate)) {
      this.InitDate = new Date(newInitDate);
    }
  }

  getEndDate() {
    return this.EndDate;
  }

  setEndDate(newEndDate: Date) {
    if (this.validateDate(newEndDate)) {
      this.EndDate = new Date(newEndDate);
    }
  }

  setMotivo(newMotivo: string) {
    this.motivo = newMotivo;
  }

  setDescripcion(newDescripcion: string) {
    this.descripcion = newDescripcion;
  }

  getDescripcion() {
    return this.descripcion;
  }

  getDateToString(infoDate: Date) {
    let result = '';
    if (this.validateDate(infoDate)) {
      result =
        infoDate.getMonth() +
        1 +
        '/' +
        infoDate.getDate() +
        '/' +
        infoDate.getFullYear() +
        ' ' +
        infoDate.getHours() +
        ':' +
        infoDate.getMinutes();
    }
    return result;
  }

  getImageJSON() {
    let result = '';
    if (this.imageData !== null && this.imageData !== undefined) {
      result = JSON.stringify(this.imageData.toJSON());
    }
    return result;
  }

  getImageData64() {
    let result = '';
    if (this.imageData !== null && this.imageData !== undefined) {
      result = this.imageData.dataImage;
    }
    return result;
  }

  validateDate(fecha: Date) {
    return (
      fecha !== null &&
      fecha !== undefined &&
      fecha.getTime() !== new Date(null).getTime()
    );
  }
  //Tipo planeado //No planeado
  //Si es planeado
  setDatesInfo() {
    if (this.typeCreation === 'PLANEADO') {
      if (this.typeString === 'INICIO') {
        if (this.validateDate(this.EndDate)) {
          // Tiene una fecha seteada probablemente de un checkout
          this.EndDate.setHours(this.EndDate.getHours() + 6);
        }
      } else if (this.typeString === 'FIN') {
        if (this.validateDate(this.InitDate)) {
          this.InitDate.setHours(this.InitDate.getHours() + 6);
        }
      }
    } else if (this.typeCreation === 'INESPERADO') {
      // Coloca las mismas horas
      if (this.typeString === 'INICIO') {
        this.EndDate = this.InitDate;
      } else if (this.typeString === 'FIN') {
        this.InitDate = this.EndDate;
      }
    }
  }

  ToJSON() {
    this.setDatesInfo();
    const objeto = {
      DataArea: this.dataArea,
      FMTrips: this.FMTrips,
      PartyNumber: this.partyNumber,
      TypePoint: this.typeString,
      TypeCreationPoint: this.typeCreation,
      InitPointDateTime: this.getDateToString(this.InitDate),
      InitLatitude: this.latitud,
      InitLongitude: this.longitud,
      Mileage: this.getKilometrajeJSON(),
      RecId: this.RecId === null ? -1 : this.RecId,
      EndLatitude: this.latitudEnd,
      EndLongitude: this.longitudEnd,
      EndPointDateTime: this.getDateToString(this.EndDate),
      Description: this.descripcion,
      Motivo: this.motivo,
      AddressID: this.addressID,
      CustomerName: this.partyName,
    };
    return objeto;
  }

  ToJSONLocal() {
    const objeto = {
      DataArea: this.dataArea,
      FMTrips: this.FMTrips,
      PartyNumber: this.partyNumber,
      TypePoint: this.typeString,
      TypeCreationPoint: this.typeCreation,
      InitPointDateTime: this.InitDate,
      InitLatitude: this.latitud,
      InitLongitude: this.longitud,
      Mileage: this.getKilometrajeJSON(),
      RecId: this.RecId === null ? -1 : this.RecId,
      EndLatitude: this.latitudEnd,
      EndLongitude: this.longitudEnd,
      EndPointDateTime: this.EndDate,
      Description: this.descripcion,
      Motivo: this.motivo,
      AddressID: this.addressID,
      CustomerName: this.partyName,
      ImageDate:
        this.imageData !== null && this.imageData !== undefined
          ? this.imageData.toJSONLocal()
          : this.imageData,
    };
    return objeto;
  }

  /**
   * Retorna la copia de su informacion
   */
  copyParadaModel() {
    const tmpParada = new ParadaModel();
    tmpParada.setDataArea(this.dataArea);
    tmpParada.setLatitud(this.latitud);
    tmpParada.setLongitud(this.longitud);
    tmpParada.setLatitudEnd(this.latitudEnd);
    tmpParada.setLongitudEnd(this.longitudEnd);
    tmpParada.setType(this.type);
    tmpParada.setTypeCreation(this.typeCreation);
    tmpParada.setMotivo(this.motivo);
    tmpParada.setDescripcion(this.descripcion);
    tmpParada.setAddressID(this.addressID);
    tmpParada.setKilometraje(this.kilometraje);
    tmpParada.setFMTrips(this.FMTrips);
    tmpParada.setPartyNumber(this.partyNumber);
    tmpParada.setPartyName(this.partyName);
    tmpParada.setInitDate(this.InitDate);
    tmpParada.setEndDate(this.EndDate);
    tmpParada.setRecId(this.RecId);
    tmpParada.setImageData(this.imageData);
    return tmpParada;
  }
}
