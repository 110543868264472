<ion-list linesnone color="secondary">
  <ion-item button *ngIf="!reenviar || reenviar" (click)="saveFilesOC()">
    <ion-label> Documentos </ion-label>
  </ion-item>
  <ion-item button *ngIf="!reenviar" (click)="saveDataOC()">
    <ion-label> Guardar </ion-label>
  </ion-item>
  <ion-item button *ngIf="!reenviar" (click)="deleteDataOC()">
    <ion-label> Eliminar </ion-label>
  </ion-item>
  <ion-item button *ngIf="!reenviar && cantFinalizar()" (click)="sendDataOC()">
    <ion-label> Finalizar </ion-label>
  </ion-item>
  <ion-item button *ngIf="reenviar && cantFinalizar()" (click)="updateDataOC()">
    <ion-label> Finalizar </ion-label>
  </ion-item>
</ion-list>
