// Native - Plugins
import { Component } from '@angular/core';
import { Money } from './../../GeneralUtilis/money';

// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

// BusinessCore
import {
  AlertController,
  ModalController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';
import { OrderLine } from './../../businessCore/OrderLine';
import { OrderTraceItem } from './../../businessCore/OrderTraceItem';

@Component({
  selector: 'app-select-products',
  templateUrl: './select-products.component.html',
  styleUrls: ['./select-products.component.scss'],
})
export class SelectProductsComponent {
  order: OrderTraceItem;
  orderLines: OrderLine[] = [];
  chkSelectAll: boolean = false;
  loadingProducts: boolean = true;
  shoppingCar: OrderLine[] = [];

  /**
   * Class constructor with these injections:
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param _apiProvider web service provider
   * @param storage to store key/value pairs and JSON objects.
   * @param alert dialog that presents users with information
   * or collects information from the user using inputs.
   * @param viewController Access various features and information about the current view.
   * @param modalCtrl content pane that goes over the user's current page.
   */
  constructor(
    public navParams: NavParams,
    public _apiProvider: webServiceProvider,
    private navService: NavigationService,
    public storage: StorageService,
    public alert: AlertController,
    public viewController: PopoverController,
    public modalCtrl: ModalController,
    private globalEventsService: GlobalEventsService
  ) {
    this.order = this.navParams.get('item');
    this.getOrderTraceLines();
  }

  /**
   * Metodo auxiliar utilizado para filtrar aquellos productos que
   * hayan sido seleccionados por el usuario para ser agregados al carrito de compras.
   */
  addItems() {
    let items = [];
    this.orderLines.forEach((element) => {
      if (element.checked) {
        items.push(element);
      }
    });

    this.storage.get('carrito').then((data) => {
      if (data != null) {
        this.shoppingCar = JSON.parse(data) as OrderLine[];
        this.addProducts(items);
      } else {
        this.shoppingCar = [] as OrderLine[];
        this.addProducts(items);
      }
    });
  }

  /**
   * Metodo utilizado para agregar al carrito de compras aquellos productos que
   * hayan sido seleccionados por el usuario por medio del checkbox que se
   * despliega contiguo a cada item.
   * @param items arreglo con todos las lineas de la orden que el usuario
   * desea agregar al carrito de compras
   */
  addProducts(items: OrderLine[]) {
    let inserted: boolean;
    items.forEach((item) => {
      inserted = false;

      const productItem = new OrderLine();
      productItem.fillAtt(
        0,
        0,
        item.dataAreaId,
        item.itemId,
        item.itemName,
        item.unitId,
        item.salesQuantity,
        item.salePrice,
        item.totalSale,
        item.taxAmount,
        item.lineDiscount,
        item.linePercent,
        item.fAmnt,
        item.lnAmnt,
        item.DeliveryDate,
        '0',
        '0',
        'null',
        99,
        item.promoId,
        true
      );

      this.shoppingCar.forEach((data) => {
        if (
          productItem.itemId == data.itemId &&
          productItem.unitId == data.unitId
        ) {
          inserted = true;
          data.salePrice = productItem.salePrice;
          data.salesQuantity += productItem.salesQuantity;
          data.lnAmnt = item.lnAmnt;
          data.taxAmount += item.taxAmount;
          data.totalSale += item.totalSale;
          //data.totalSale = data.salesQuantity * data.salePrice;
        }
      });
      if (!inserted) {
        this.shoppingCar.push(productItem);
      }
    });

    this.storage.set('carrito', JSON.stringify(this.shoppingCar)).then(() => {
      this.globalEventsService.publish(GlobalEvents.UPDATE_CART);
    });

    this.presentAlert(
      'Productos agregados con éxito!',
      '¿Desea ver el carrito de compras?'
    );
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  /**
   * Metodo utilizado para presentar una alerta con un titulo y mensaje
   * @param title string con el titulo del mensaje que se le desea desplegar al usuario
   * @param msg string con el mensaje desplegado al usuario
   */
  async presentAlert(title: string, msg: string) {
    let alert = await this.alert.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Si',
          handler: () => {
            this.storage.get('enable_version').then((versionData) => {
              if (versionData != null && versionData) {
                this.viewController.dismiss().then(() => {
                  // Redirect to shoppingCar
                  //this.navCtrl.push(ShoppingCarComponent, this.shoppingCar);
                  this.navService.navigateTo(
                    NavigationRoutes.ShoppingCartComponent,
                    { shoppingCar: this.shoppingCar }
                  );
                });
              } else {
                this.viewController.dismiss().then(async () => {
                  let alert = await this.alert.create({
                    header: 'Error',
                    subHeader:
                      'Debe iniciar sesión o registrarse antes de ver el carrito de compras',
                    buttons: [
                      {
                        text: 'Ok',
                      },
                    ],
                    backdropDismiss: false,
                  });
                  alert.present();
                });
              }
            });
          },
        },
        {
          text: 'No',
          handler: () => {
            this.viewController.dismiss();
          },
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  /**
   * Metodo utilizado para consultar las lineas de productos que tiene una orden
   * se logra por medio del salesAXId que es el identificador de la orden a consultar
   */
  getOrderTraceLines() {
    this._apiProvider
      .getOrderTraceLines(this.order.salesAxId)
      .subscribe((data) => {
        if (data != null) {
          this.orderLines = data as OrderLine[];
          console.log(this.orderLines);
          this.orderLines.forEach((_, index) => {
            this.orderLines[index] = OrderLine.convertOrderLine(
              this.orderLines[index]
            );
            this.orderLines[index].salePrice +=
              this.orderLines[index].taxProduct;
            this.orderLines[index].totalSale =
              this.orderLines[index].salePrice *
              this.orderLines[index].salesQuantity;
          });

          this.loadingProducts = false;
          this.selectAll();
        }
      });
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo asociado al boton de seleccion de productos que se encarga de marcar
   * todos las lineas que tiene la orden para realizar una seleccion mas sencilla y permitirle
   * al usuario agregar dichas lineas al carrito de compras
   */
  selectAll() {
    if (this.chkSelectAll) {
      this.orderLines.forEach((line) => {
        line.checked = true;
      });
    } else {
      this.orderLines.forEach((line) => {
        line.checked = false;
      });
    }
    this.chkSelectAll = !this.chkSelectAll;
  }

  /**
   * Metodo utilizado para cambiar el valor del check general segun sea la seleccion del usuario
   */
  changeValue() {
    this.chkSelectAll =
      this.orderLines.filter((value) => value.checked).length ==
      this.orderLines.length;
  }

  /**
   * Metodo utilizado para validar el formulario de seleccion para que no existan problemas
   * a la hora de agregar los productos seleccionados al carro de compras
   * @returns boolean con el estado (correcto/incorrecto) del formulario
   */
  validForm() {
    let response = false;
    this.orderLines.forEach((element) => {
      if (element.checked) {
        response = true;
      }
    });

    return response;
  }
}
