// Native - Plugins
import { Component, Input } from '@angular/core';
import { NavController, NavParams, ToastController } from '@ionic/angular';

// BusinessCore
import { InventoryItem } from './../../businessCore/InventoryItem';
import { ProductComment } from './../../businessCore/ProductComment';
import { ProductEvaluation } from './../../businessCore/ProductEvaluation';
import { RegisterData } from './../../businessCore/RegisterData';

// Provider
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-product-comment',
  templateUrl: './product-comment.component.html',
  styleUrls: ['./product-comment.component.scss'],
})
export class ProductCommentComponent {
  // informacion del item sobre el que se esta consultando los comentarios y reacciones
  @Input() item: InventoryItem;

  // informacion de registro del usuario
  public register_data: RegisterData;

  // atributo con el conjunto de comentarios asociados a un producto
  public product_comments: ProductComment[] = [];

  // conjunto de evaluaciones asociadas a un producto
  public product_rate: ProductEvaluation = new ProductEvaluation();

  /**
   * Class constructor with these injections:
   * @param navCtrl base class for navigation controller components like Nav and Tab.
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param _apiProvider web service provider
   * @param storage to store key/value pairs and JSON objects
   */
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public _apiProvider: webServiceProvider,
    public storage: StorageService,
    public toastCtrl: ToastController
  ) {
    this.item = this.navParams.get('item');
    this.loadData();
  }

  /**
   * Metodo utilizado de manera automatica por el sistema
   * Se ejecuta cuando la vista detecta que esta siendo accedida desde cualquier punto
   * de la aplicacion, el proposito principal es llevar a cabo una actualizacion de los comentarios
   * que tiene asociados un producto.
   */
  loadData() {
    this.getProductRate(this.item.ItemId);

    // Verifica si se cuenta con informacion del usuario cargada
    // si se cumple se procede a consultar los comentarios del producto
    if (this.register_data != null) {
      // Cargar comentarios que se han realizado sobre un determinado item
      let itemId = this.item.ItemId;
      let accountNum = this.register_data.customerId;
      this.getProductComments(itemId, accountNum);
    } else {
      this.storage.get('register_data').then((data) => {
        if (data != null) {
          this.register_data = JSON.parse(data) as RegisterData;
          // Cargar comentarios que se han realizado sobre un determinado item
          let itemId = this.item.ItemId;
          if (this.register_data != null) {
            let accountNum = this.register_data.customerId;
            this.getProductComments(itemId, accountNum);
          } else {
            this.getProductComments(itemId, '');
          }
        } else {
          this.getProductComments(this.item.ItemId, '');
        }
      });
    }
  }

  /**
   * Metodo utilizado para consultar los comentarios que tiene asociados un producto
   * proporcionando los datos relacionados a cada uno de los comentarios: autor, contenido,
   * reacciones(likes & dislikes) asociadas, etc.
   * @param itemId identificador del item al que se le estan consultandon los comentarios
   * @param accountNum idetificador del usuario para determinar cuales comentarios le
   * pertenecen.
   * @returns <ProductComment> comentarios asociados al producto
   */
  getProductComments(itemId: string, accountNum: string) {
    this._apiProvider.getCommentsInfo(itemId, accountNum).subscribe((data) => {
      if (data != null) {
        this.product_comments = data as ProductComment[];
      }
    });
  }

  /**
   * Metodo utilizado para consultar las valoraciones que tiene asociadas un producto
   * proporcionando los datos relacionados: cantidad de reacciones segun valoracion
   * (rate1, rate2, ..., rate5), average e identificador del producto
   * @param itemId identificador del producto al que se le esta consultando la valoracion
   * @returns ProductEvaluation con las valoraciones asociadas al producto
   */
  getProductRate(itemId: string) {
    this._apiProvider.getProductRate(itemId).subscribe((data) => {
      console.log('DATA', data);
      if (data != null) {
        this.product_rate = data as ProductEvaluation;
        this.product_rate.AVERAGE = Math.round(this.product_rate.AVERAGE);

        console.log(this.product_rate);
      }
    });
  }

  /**
   * Metodo utilizado para
   * @param event
   */
  onModelChange(event) {
    console.log('EVENT', event);
  }

  /**
   * Metodo utilizado para llevar cabo el seteo de reacciones de un usuario
   * en este caso, se setea un like en caso de no existir uno previo, de lo contrario
   * se setea la reaccion de type=1 que representa un estado de inactividad que sirve para
   * 'eliminar' una reaccion previa
   * @param comment objeto con los datos del comentario al que se le esta seteando la reaccion
   */
  setLike(comment: ProductComment) {
    let type = 2;
    if (comment.USERLIKED) {
      type = 1;
    }
    if (this.register_data != null) {
      this.setCommentReaction(
        type,
        this.register_data.customerId,
        comment.RECID
      );
    } else {
      this.presentToast(
        'Lo sentimos, aún no se pueden registrar sus reacciones.',
        3000,
        'bottom'
      );
    }
  }

  /**
   * Metodo utilizado para llevar cabo el seteo de reacciones de un usuario
   * en este caso, se setea un dislike en caso de no existir uno previo, de lo contrario
   * se setea la reaccion de type=1 que representa un estado de inactividad que sirve para
   * 'eliminar' una reaccion previa
   * @param comment objeto con los datos del comentario al que se le esta seteando la reaccion
   */
  setDislike(comment: ProductComment) {
    let type = 0;
    if (comment.USERDISLIKED) {
      type = 1;
    }
    if (this.register_data != null) {
      this.setCommentReaction(
        type,
        this.register_data.customerId,
        comment.RECID
      );
    } else {
      this.presentToast(
        'Lo sentimos, aún no se pueden registrar sus reacciones.',
        3000,
        'bottom'
      );
    }
  }

  /**
   * Metodo utilizado para crear un toast con un mensaje determinado y desplegarlo en un tiempo y
   * posicion especifica
   * @param msg Texto con el mensaje que se va desplegar en el toast
   * @param time tiempo en milisegundos que durara el toast en pantalla
   * @param pos posicion en que se ubicara el componente, top, bottom, medium
   */
  async presentToast(msg, time, pos) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: time,
      position: pos,
    });

    toast.present();
  }

  /**
   * Metodo utilizado para llevar cabo el seteo de reacciones de un usuario
   * en este caso, es la funcion principal que toma los datos enviados
   * de las funciones 'setlike' & 'setDislike' donde lo que varian es el tipo de reaccion
   * que se desea setear sobre el comentario
   * @param type identificador del tipo de reaccion que se desea asociar al comentario
   * 0 = dislike
   * 1 = neutro/reaccion inactiva
   * 2 = like
   * @param accountNum identificador del usuario que esta llevando a cabo la reaccion
   * @param fkComment identificador del comentario al que se le asociara la reaccion
   */
  setCommentReaction(type: number, accountNum: string, fkComment: string) {
    this._apiProvider
      .setCommentReaction(type, accountNum, fkComment)
      .subscribe((data) => {
        if (data != null) {
          if (data == 'SUCCESS') {
            // Actualizacion de los comentarios para poder visualizar la reacción
            if (this.register_data != null) {
              this.getProductComments(
                this.item.ItemId,
                this.register_data.customerId
              );
            }
          }
        }
      });
  }
}
