export class SelectedStandar {
  constructor(private id: string, private name: string) {}
  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getValue() {
    return this.id;
  }
}
