<ion-content>
  <h4 class="center-element" margin-bottom>{{ title }}</h4>

  <div style="text-align: center" *ngIf="loadingInfo">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando información</h6>
  </div>

  <div style="margin-left: 10px; margin-right: 10x">
    <!-- Actual Data -->
    <div>
      <ion-item style="padding-left: 0px !important">
        <ion-input
          label="Dato Actual"
          label-placement="floating"
          class="floating"
          class="inputField"
          [disabled]="true"
          [(ngModel)]="actualData"
          [ngModelOptions]="{ standalone: true }"
        ></ion-input>
      </ion-item>
    </div>

    <!-- New Data -->
    <div style="margin-top: 10px">
      <ion-item style="padding-left: 0px !important">
        <ion-input
          label="Nuevo Dato"
          label-placement="floating"
          class="floating"
          class="inputField"
          [(ngModel)]="newData"
          [ngModelOptions]="{ standalone: true }"
          required
        ></ion-input>
      </ion-item>
    </div>
  </div>

  <ion-button
    class="submit-btn btnCss"
    type="submit"
    margin-top
    (click)="editData()"
  >
    <i class="fas fa-pencil-alt" style="margin-right: 10px"></i>Editar
  </ion-button>
</ion-content>
