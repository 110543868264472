// Native - Plugins
import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';

// Pages
// import { HomePage } from './../../pages/home/home';

// Components

// BusinessCore
import { RegisterData } from './../../businessCore/RegisterData';

@Component({
  selector: 'app-register-method',
  templateUrl: './register-method.component.html',
  styleUrls: ['./register-method.component.scss'],
})
export class RegisterMethodComponent {
  register_data: RegisterData;
  card_types: any;
  isActive: boolean = false;
  isActive2: boolean = false;

  /**
   * class constructor with these injections:
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param nav base class for navigation controller components like Nav and Tab.
   */
  constructor(
    public navParams: NavParams,
    private navService: NavigationService,
    public navCtrl: NavController
  ) {
    this.register_data = this.navParams.get('registerData');
    this.card_types = this.register_data.get_card_types();

    if (this.register_data != null) {
      let splitValues = this.register_data.user_name.split(' ');
      if (splitValues.length > 2) {
        this.register_data.secondLastName = splitValues.pop();
        this.register_data.firstLastName = splitValues.pop();
        this.register_data.name = splitValues.join(' ');
      }
    }
  }

  /**
   * Redirigir a un determinado componente con ciertos datos
   * @param component objeto con los datos del componente al que se va redirigir la aplicacion
   * @param data objeto con los datos que se envian al componente
   */
  private redirectTo(component: NavigationRoutes, data) {
    //this.nav.push(component, data);
    this.navService.navigateTo(component, data);
  }

  /**
   * Metodo utilizado para redirigir la aplicacion al siguiente paso del registro de usuarios
   * en especifico se realiza al componente de registro de direcciones.
   */
  public registerNextStep() {
    if (this.register_data && this.register_data.c_type === 2) {
      this.setNameJuridic();
    }
    this.redirectTo(NavigationRoutes.RegisterDirectionComponent, {
      registerData: this.register_data,
    });
  }

  /**
   * Metodo utilizado para realizar el cambio en el tipo de tarjeta que ha seleccionado el usuario
   * @param ct identificador del tipo de tarjeta seleccionado por el cliente en el 'select'
   */
  public updateCardType(ct) {
    this.register_data.represent_c_type = ct;
    this.register_data.represent_id_card = '';
  }

  /**
   * Metodo utilizado para borra por completo el campo de identificadion y poder
   * ingresalo nuevamente
   */
  public deleteIdCard() {
    this.register_data.represent_id_card = '';
  }

  /**
   * Metodo utilizado para retornar al componente principal de la aplicacion
   * que es la ventana de despliegue de productos
   */
  goHome() {
    this.navService.navigateTo(NavigationRoutes.ROOT_PAGE);
  }

  /**
   * Metodo utilizado para crear 'nombre' de cedula juridica
   */
  setNameJuridic() {
    if (this.register_data != null) {
      let splitValues = this.register_data.user_name.split(' ');
      if (splitValues.length > 0) {
        if (splitValues.length < 3) {
          this.register_data.secondLastName =
            splitValues[2] !== null && splitValues[2] !== undefined
              ? splitValues[2]
              : '';

          this.register_data.firstLastName =
            splitValues[1] !== null && splitValues[1] !== undefined
              ? splitValues[1]
              : '';

          this.register_data.name =
            splitValues[0] !== null && splitValues[0] !== undefined
              ? splitValues[0]
              : '';
        } else {
          this.register_data.secondLastName = splitValues.pop();
          this.register_data.firstLastName = splitValues.pop();
          this.register_data.name = splitValues.join('');
        }
      }
    }
  }
}
