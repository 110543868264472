<!-- MERCASA VIP STYLE-->
<ion-card *ngIf="infoPurch">
  <ion-card-content>
    <ion-item-sliding (swipe)="swipeEvent($event)">
      <ion-item [ngClass]="pCanEdit ? 'borderStyle' : 'borderStyle2'">
        <ion-grid style="padding: 0px">
          <ion-row>
            <ion-col>
              <h6 *ngIf="infoPurch.getIdOC() !== ''" class="h73">
                {{ infoPurch.getIdOC() }}
              </h6>
              <h6 *ngIf="infoPurch.getIdOC() === ''" class="h73">
                Borrador {{ infoPurch.getLocalId() }}
              </h6>
            </ion-col>
            <ion-col>
              <h6 ion-text class="h72">
                {{ infoPurch.getDataArea().getId() }}
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6 ion-text text-wrap class="h71">
                {{ infoPurch.getVendName() }}
              </h6>
            </ion-col>
            <ion-col>
              <h6 ion-text class="h72">{{ fecha }}</h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6
                class="h71B"
                *ngIf="
                  infoPurch.getNotaRechazo() &&
                  infoPurch.getNotaRechazo() !== ''
                "
              >
                Nota de rechazo: {{ infoPurch.getNotaRechazo() }}
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h6 ion-text text-center class="h7">LINEAS</h6>
              <h6 color="primary" class="h7" style="font-weight: bold">
                {{ infoPurch.cantidadLineas }}
              </h6>
              <h6 color="primary" class="h7" style="font-weight: bold">
                {{ infoPurch.getDocumentStateS() }}
              </h6>
            </ion-col>
            <ion-col col-4>
              <h6 class="h7">TOTAL</h6>
              <h6 color="primary" class="h7" style="font-weight: bold">
                {{ getCurrency(infoPurch.total) }}
              </h6>
              <h6 ion-text color="primary" class="h7" style="font-weight: bold">
                {{ infoPurch.getPurchStatusS() }}
              </h6>
            </ion-col>
            <ion-col col-4>
              <h6 class="h7">&#8205;</h6>
              <h6 class="h7">&#8205;</h6>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item-options side="start">
        <ion-item-option (click)="openEdit()" *ngIf="pCanEdit">
          <i class="fas fa-pencil-alt"></i>
          <span style="font-size: 8px">Actualizar</span>
        </ion-item-option>
      </ion-item-options>

      <ion-item-options side="end">
        <ion-item-option color="danger" (click)="goToConfirmOrder()">
          <ion-icon name="add"> </ion-icon>
          <span style="font-size: 8px">Ver más</span>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-card-content>
</ion-card>
