<app-nav-bar [title]="'Remisión de OC'"></app-nav-bar>

<ion-content>
  <ion-grid style="padding: 0">
    <div lines="none" class="barTitle">
      <div style="display: flex; justify-content: center; width: 100%">
        <h5 style="font-size: 22px; text-align: center">
          <b>{{ data.purchId }}</b>
        </h5>
      </div>
    </div>
    <!--BEGIN OBJECT-->
    <ion-row class="content" size="large">
      <span class="label-style" style="font-size: 18px">{{
        data.objectId
      }}</span>
    </ion-row>
    <ion-row class="content" size="large">
      <span class="label-style">{{ data.objectName }}</span>
    </ion-row>
    <!--END OBJECT-->
    <ion-row class="content">
      <ion-label class="label-style" style="margin-top: 10px"
        >Transportista</ion-label
      >
    </ion-row>
    <ion-row class="content">
      <ion-item class="item-style" (click)="selectRoleSpecificId()"
        >{{ roleSpecificSelected() }}
        <ion-icon
          src="assets\bx-chevron-down.svg"
          role="img"
          class="md hydrated"
          aria-label="star"
          slot="end"
        >
        </ion-icon>
      </ion-item>
    </ion-row>
    <ion-row class="content">
      <ion-label class="label-style" style="margin-top: 10px"
        >Descargador</ion-label
      >
    </ion-row>
    <ion-row class="content">
      <ion-item class="item-style" (click)="selectRoleSpecificIdAssitants()"
        >{{ roleSpecificAssistantSelected() }}
        <ion-icon
          src="assets\bx-chevron-down.svg"
          role="img"
          class="md hydrated"
          aria-label="star"
          slot="end"
        >
        </ion-icon>
      </ion-item>
    </ion-row>
    <ion-row class="content">
      <ion-label class="label-style" style="margin-top: 10px"
        >Contenedor</ion-label
      >
    </ion-row>
    <ion-row class="content">
      <ion-item class="item-style" (click)="selectContainerId()"
        >{{ containerIdsSelected() }}
        <ion-icon
          src="assets\bx-chevron-down.svg"
          role="img"
          class="md hydrated"
          aria-label="star"
          slot="end"
        >
        </ion-icon>
      </ion-item>
    </ion-row>
    <ion-row class="content">
      <ion-col>
        <ion-button
          class="button_custom"
          expand="block"
          style="margin-top: 15px"
          (click)="saveInfo()"
        >
          Aceptar
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button
          class="button_custom"
          expand="block"
          style="margin-top: 15px"
          (click)="close()"
        >
          Cancelar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
