export class FileOCInfo {
  private url: string;
  constructor(
    private fileName: string,
    private fileInf: any,
    private fileExtension: any,
    private fileData: string,
    private send: boolean
  ) {}

  setURL(newURL: string) {
    this.url = newURL;
  }

  getURL() {
    return this.url;
  }

  setSend(newBoolean: boolean) {
    this.send = newBoolean;
  }

  getSend() {
    return this.send;
  }

  setFileData(newFileData: string) {
    this.fileData = newFileData;
  }

  getFileData() {
    return this.fileData;
  }

  setFileExtension(newFileExtension: any) {
    this.fileExtension = newFileExtension;
  }

  getFileExtension() {
    return this.fileExtension;
  }

  setFileName(newFileName: string) {
    this.fileName = newFileName;
  }

  getFileName() {
    return this.fileName;
  }

  setFileInf(fileInfo: any) {
    this.fileInf = fileInfo;
  }

  getFileInfo() {
    return this.fileInf;
  }
}
