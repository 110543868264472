export class AssetRequest {
  //Atributos para recepción de datos
  recId: string;
  assetId: string;
  barcode: string;
  assetName: string;
  assetGroup: string;
  assetGroupName: string;
  dataAreaId: string;
  companyName: string;
  assetType: string;
  locationId: string;
  locationIdNew: string;
  mainAssetId: string;
  worker: string;
  workerNew: string;
  workerName: string;
  workerNameNew: string;
  workerEmail: string;
  workerEmailNew: string;
  transferDate: Date;
  attachment: string; //Es un json que contiene todos los datos que se envían.

  //Utilizados en frontEnd
  selected: boolean;
  expanded: boolean;
  affected: boolean;
  subRequest: AssetRequest[];
  imgName: string = '';

  //Setters y Getters
  getRecId() {
    return this.recId;
  }

  getAssetId() {
    return this.assetId;
  }

  getBarcode() {
    return this.barcode;
  }

  getAssetName() {
    return this.assetName;
  }

  getAssetGroup() {
    return this.assetGroup;
  }

  getAssetGroupName() {
    return this.assetGroupName;
  }

  getDataAreaId() {
    return this.dataAreaId;
  }

  getCompanyName() {
    return this.companyName;
  }

  getLocationId() {
    return this.locationId;
  }

  getLocationIdNew() {
    return this.locationIdNew;
  }

  getMainAssetId() {
    return this.mainAssetId;
  }

  getWorker() {
    return this.worker;
  }

  getWorkerNew() {
    return this.workerNew;
  }

  getWorkerName() {
    return this.workerName;
  }

  getWorkerNameNew() {
    return this.workerNameNew;
  }

  getWorkerEmail() {
    return this.workerEmail;
  }

  getWorkerEmailNew() {
    return this.workerEmailNew;
  }

  getTransferDate() {
    return this.transferDate;
  }

  //Usados en frontend
  getSelected() {
    return this.selected;
  }

  getExpanded() {
    return this.expanded;
  }

  getAffected() {
    return this.affected;
  }

  getSubRequest() {
    return this.subRequest;
  }

  getImgName() {
    return this.imgName;
  }

  getAttachment() {
    return this.attachment;
  }

  getAssetType() {
    return this.assetType;
  }
}

export class FilterRequestData {
  companies: any[];
  categories: any[];
  responsibles: any[];
  newResponsibles: any[];
  dates: any;
}
