<app-nav-bar [title]="'Registrar Gasto'">
  <ion-buttons slot="end">
    <ion-button (click)="closeCancel()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content style="background: white; color: black">
  <div style="display: flex; align-items: center">
    <div style="padding: 10px">
      <ion-label> N° Factura: </ion-label>
    </div>
    <div style="padding: 10px; width: 100%">
      <input
        type="text"
        [(ngModel)]="factura"
        class="quantityClass"
        expand="block"
        style="background: white"
      />
    </div>
  </div>
  <div style="display: flex; align-items: center">
    <div style="padding: 10px">
      <ion-label> Proveedor: </ion-label>
    </div>
    <div style="padding: 10px; width: 100%">
      <input
        type="text"
        [(ngModel)]="proveedorInfo"
        class="quantityClass"
        style="background: white"
      />
    </div>
  </div>
  <div style="display: flex">
    <app-input-number
      [titulo]="'Monto'"
      [cantidadObjeto]="monto ? monto : 0"
      (getNumber)="getmonto($event)"
      [cantEdit]="true"
      style="width: 100%"
    >
    </app-input-number>
  </div>

  <div style="margin: 15px">
    <ion-item>
      <ion-label> Descripción: </ion-label>
    </ion-item>
    <textarea
      rows="10"
      cols="50"
      style="border-style: solid; width: 100%; background: white"
      placeholder="Escriba la descripción"
      maxlength="1000"
      [(ngModel)]="description"
      name="note"
      autocomplete="on"
      autocorrect="on"
    >
    </textarea>
  </div>

  <div style="padding-left: 90%">
    <ion-button (click)="saveSpend()" style="background: white">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-button>
  </div>
</ion-content>
