import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CatalogInfo } from '../../../businessCore/trip-models/CatalogInfo';
import { Vehicle } from '../../../businessCore/trip-models/Vehicle';
import { ExternalServiceTripProvider } from '../../../provider/Services/external-service-trip';
import { SearchProviderComponent } from '../../oc-components/search-provider/search-provider.component';

@Component({
  selector: 'app-info-vehicle-pop',
  templateUrl: './info-vehicle-pop.component.html',
  styleUrls: ['./info-vehicle-pop.component.scss'],
})
export class InfoVehiclePopComponent {
  objInf: Vehicle;
  cantEdit: boolean = false;
  canSelectNumber: boolean = false;
  insertNumberPla: boolean = false;
  canSelectType: boolean = true;
  selectedProp: number = 0;
  numeroVehi: string = '';
  descripVehi: string = '';
  numeroPla: string = '';
  selectedType: number = -1;
  listTypeVehicle: CatalogInfo[] = [];
  listProperty: CatalogInfo[] = [];
  listVehicle: Vehicle[] = [];
  constructor(
    private viewCtrl: ModalController,
    private navParams: NavParams,
    private webService: ExternalServiceTripProvider,
    private modalCtrl: ModalController
  ) {
    this.objInf = this.navParams.get('objInf');
    const tmpCanEdit = this.navParams.get('canEdit');
    if (tmpCanEdit !== null && tmpCanEdit !== undefined) {
      this.cantEdit = tmpCanEdit;
    }
  }

  async ngOnInit() {
    /**Trae la info de tipos de transportes */
    try {
      const listInfo = await this.webService.getTypeVehicle();
      this.listTypeVehicle = listInfo as CatalogInfo[];
      this.listTypeVehicle = this.listTypeVehicle.filter(
        (value, index, array) => {
          return value.getValue() !== 4;
        }
      );
      const listInfo2 = await this.webService.getPropertyVehicle();
      this.listProperty = listInfo2 as CatalogInfo[];
      this.listProperty = this.listProperty.filter((value) => {
        return value.getValue() !== 0;
      });
      const listVehicleTmp = await this.webService.getVehicles('');
      this.listVehicle = listVehicleTmp as Vehicle[];
    } catch {}

    if (this.objInf !== null && this.objInf !== undefined) {
      // ya hay datos seteados...
      this.selectedProp = this.objInf.getTypeProperty();
      this.selectedType = this.objInf.getTypeTransport();
      this.numeroVehi = this.objInf.getNumVehicle();
      this.numeroPla = this.objInf.getPlacVehicle();
      if (this.selectedProp === 1) {
        this.setVehicleDescrp();
      }
    }
  }

  setVehicleDescrp() {
    const data = this.listVehicle.find(
      (vehiTmp) => vehiTmp.getId() === this.numeroVehi
    );
    if (data) {
      this.descripVehi = data.getName();
    }
  }

  closePopOver() {
    this.viewCtrl.dismiss({
      objInf: this.objInf, //Envia los datos sin ser guardados...
    });
  }

  changeSelectedPropiedad() {
    /*
      this.numeroVehi = "";
      this.descripVehi = "";
      this.numeroPla = "";
      this.selectedType = -1;
      console.log("Entro: ", this.canSetDefault, " ", new Date());
    */

    switch (this.selectedProp) {
      case 1:
        this.canSelectNumber = true;
        this.insertNumberPla = false;
        this.canSelectType = true;
        break;
      case 2:
        this.canSelectNumber = false;
        this.insertNumberPla = true;
        this.canSelectType = false;
        break;
      default:
        break;
    }
  }

  emitSelectedBar() {
    if (this.canSelectNumber && this.cantEdit) {
      //Setea la informacion de un vehiculo de la empresa.
      //Pasa la lista de vehiculos cargados
      this.openSearchBarAux(this.listVehicle, false);
    }
  }

  async openSearchBarAux(listInfo: any[], cantEdit: boolean) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
        canEdit: cantEdit,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.setTransportSelect(data.objSelected as Vehicle);
        }
      }
    });
  }

  setTransportSelect(objSelected: Vehicle) {
    this.numeroVehi = objSelected.getNumVehicle();
    this.descripVehi = objSelected.getName();
    this.numeroPla = objSelected.getPlacVehicle();
    this.selectedType = objSelected.getTypeTransport();
  }

  saveTransportInfo() {
    // Setea los datos seleccionados.
    if (this.objInf === null || this.objInf === undefined) {
      // ya hay datos seteados...
      this.objInf = new Vehicle('', '');
    }

    this.objInf.setNumVehicle(this.numeroVehi);
    this.objInf.setPlacVehicle(this.numeroPla);
    if (this.selectedProp !== 0) {
      this.objInf.setTypeProperty(Number(this.selectedProp));
      if (this.selectedProp === 2) {
        this.objInf.setName(this.descripVehi);
      }
      const selectTmp = this.listProperty.find(
        (data) => data.getValue() === this.selectedProp
      );
      if (selectTmp) {
        this.objInf.setTypePropertyStr(selectTmp.getDescription());
      }
    }

    if (this.selectedType !== -1) {
      this.objInf.setTypeTransport(this.selectedType);
    }

    //Agregar estos tipos al transporte y agregar el envio para wcf.
    //Si se puede, agregar campos y enum (Así dormimos xD)
    this.closePopOver();
  }
}
