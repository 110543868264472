<ion-grid class="update-quantity" style="background: white; color: black">
  <ion-row center>
    <ion-col style="display: flex; flex-direction: column; align-items: center">
      <ion-button (click)="reduceQuantity()" fill="clear">
        <ion-icon name="remove-circle"></ion-icon>
      </ion-button>
      <h6 class="h7 primaryColor" style="font-weight: bold; text-align: center">
        {{ purchLine.getPrecio() }}
      </h6>
      <h6 class="ion-text-center h7">{{ purchLine.unitId }}</h6>
    </ion-col>
    <ion-col col-4>
      <h6 class="ion-text-center h7">
        {{ purchLine.cantidad }} {{ purchLine.unitId }}
      </h6>
    </ion-col>
    <ion-col style="display: flex; flex-direction: column; align-items: center">
      <ion-button (click)="increaseQuantity()" fill="clear">
        <ion-icon round name="add-circle"></ion-icon>
      </ion-button>
      <h6 class="h7 primaryColor" style="font-weight: bold; text-align: center">
        {{ this.getCurrency(purchLine.getTotal()) }}
      </h6>
      <!-- <h6 ion-text text-center class="h7 secondaryColor" style="font-weight: bold;" *ngIf="item.lnAmnt>0">{{setCurrency(item.taxAmount) + ' IVA'}}</h6> -->
      <h6 class="ion-text-center h7">TOTAL</h6>
    </ion-col>
  </ion-row>
</ion-grid>
