import { Component } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-get-data2-create',
  templateUrl: './get-data2-create.component.html',
  styleUrls: ['./get-data2-create.component.scss'],
})
export class GetData2CreateComponent {
  title: string = '';
  newData: string = '';
  public loadingInfo: boolean = false;

  constructor(
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public toastCtrl: ToastController
  ) {
    this.title = this.navParams.get('title');
  }

  /**
   *
   */
  async addData() {
    if (this.newData.length > 0 && this.newData != null) {
      this.viewCtrl.dismiss(this.newData);
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Debe ingresar un dato válido!',
        duration: 2000,
        buttons: ['Ok'],
      });
      toast.present();
    }
  }
}
