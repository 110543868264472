// Native - Plugins
import { Component } from '@angular/core';
import {
  LoadingController,
  NavParams,
  PopoverController,
} from '@ionic/angular';

// BusinessCore
import { ConfirmOrderHeader } from './../../businessCore/ConfirmOrderHeader';
import { ConfirmOrderList } from './../../businessCore/ConfirmOrderList';

// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-order-confirmation-info',
  templateUrl: './order-confirmation-info.component.html',
  styleUrls: ['./order-confirmation-info.component.scss'],
})
export class OrderConfirmationInfoComponent {
  order: ConfirmOrderList;
  orderInfo: ConfirmOrderHeader;

  /**
   * Atributo utilizado para almacenar el componente spinner que será desplegado
   * en la aplicación para mostrar que la información está siendo cargada.
   */
  loading: HTMLIonLoadingElement;

  /**
   * Class constructor with these injections:
   * @param _apiProvider web service provider
   * @param params object that exists on a page and can contain data for that particular view.
   * @param viewCtrl features and information about the current view.
   * @param loadingCtrl used to indicate activity while blocking user interaction
   */
  constructor(
    public _apiProvider: webServiceProvider,
    public params: NavParams,
    public viewCtrl: PopoverController,
    public loadingCtrl: LoadingController
  ) {
    this.order = this.params.get('order') as ConfirmOrderList;
    this.orderInfo = new ConfirmOrderHeader();
    this.getOrderHeader();
  }

  /**
   * Metodo utilizado para consultar la informacion que tiene asociada una orden
   * que esta siendo consultada y confirmada por un consumidor
   */
  async getOrderHeader() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Cargando Información',
    });
    this.loading.present();

    this._apiProvider
      .getOrderHeader(this.order.SALESID, this.order.CUSTACCOUNT)
      .subscribe((res) => {
        console.log(res);
        let data = res as Array<any>;
        if (data != null) {
          let headerTmp = data.pop();
          console.log('headerTmp');
          console.log(headerTmp);
          this.orderInfo = headerTmp
            ? (headerTmp as ConfirmOrderHeader)
            : new ConfirmOrderHeader();
          console.log('this.orderInfo');
          console.log(this.orderInfo);
          let CREATEDDATETIME = new Date(
            parseInt(this.orderInfo.CREATEDDATETIME.substr(6))
          );
          let FechaVence = new Date(
            parseInt(this.orderInfo.FechaVence.substr(6))
          );
          let PSCUSTSHIPDATE = new Date(
            parseInt(this.orderInfo.PSCUSTSHIPDATE.substr(6))
          );

          this.orderInfo.CREATEDDATETIME = CREATEDDATETIME.toLocaleDateString();
          this.orderInfo.FechaVence = FechaVence.toLocaleDateString();
          this.orderInfo.PSCUSTSHIPDATE = PSCUSTSHIPDATE.toLocaleDateString();

          this.loading.dismiss();
        }
      });
  }

  /**
   * Metodo utilizado para cerrar el popup que contiene este componente abierto
   * Se emplea cuando se desea cancelar la accion de confirmacion.
   */
  closeWin() {
    this.viewCtrl.dismiss();
  }
}
