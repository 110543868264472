import { Component } from '@angular/core';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { AssetAudit } from '../../businessCore/AssetAudit';
import { Audit } from '../../businessCore/Audit';
import { AuditFinding } from '../../businessCore/AuditFinding';
import { FixedAsset } from '../../businessCore/FixedAsset';
import { Worker } from '../../businessCore/Worker';
import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { AddFindingComponent } from '../add-finding/add-finding.component';
import { AssetAuditUnassignedAssetComponent } from '../asset-audit-unassigned-asset/asset-audit-unassigned-asset.component';
import { SearchableSelectComponent } from '../searchable-select/searchable-select.component';

@Component({
  selector: 'app-asset-audit-add',
  templateUrl: './asset-audit-add.component.html',
  styleUrls: ['./asset-audit-add.component.scss'],
})
export class AssetAuditAddComponent {
  audit: Audit;

  aDepartment: CatalogModel[] = [];
  department: CatalogModel;

  assetResponsibles: Worker[];
  assetsResponsible: Worker;

  assetList: FixedAsset[];
  auditFindingList: AuditFinding[];
  assetAuditList: AssetAudit[];

  savedFlag: boolean;

  errorMsg: string;

  petitioner: Worker;
  auditor: Worker;

  unassignedAssetList: FixedAsset[];

  constructor(
    public viewCtrl: ModalController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public popoverCtrl: PopoverController,
    public loadingCtrl: LoadingController,
    private extServOC: ExternalServiceOCService,
    private _apiProvider: webServiceProvider,
    private iab: InAppBrowser,
    private navParams: NavParams
  ) {
    this.audit = this.navParams.get('audit');
    this.petitioner = this.navParams.get('petitioner');
    this.auditor = this.navParams.get('auditor');
    this.savedFlag = false;
    this.department = new CatalogModel(0, '');
    this.assetsResponsible = new Worker();
    this.errorMsg = '';
    this.assetList = [];
    this.assetAuditList = [];
    this.unassignedAssetList = [];
  }

  async ngOnInit() {
    try {
      const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
      loading.present(); //Muestra pantalla de cargado.
      this.auditFindingList = await this.getAuditFindingList();
      if (this.audit.getAuditRecId()) {
        this.assetsResponsible = await this.getWorker(
          '',
          '',
          this.audit.getAuditedWorker()
        );
        this.assetList = await this.getAssetsList(
          this.assetsResponsible.getPersonnelNumber()
        );
        this.loadAudit();
        this.savedFlag = true;
      } else {
        this.aDepartment = await this.extServOC.getFinancialDimensionByName(
          'A_Departamento'
        );
      }
      await loading.dismiss();
    } catch (error) {
      this.createAlert(
        'Error de conexión',
        'Se ha presentado un error, favor intentar más tarde'
      );
    }
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de trabajadores.
   *
   * @returns Una lista con los trabajadores activos a la fecha.
   */
  async getWorker(
    personnelNumber: string = '',
    vatnum: string = '',
    recId: number = 0
  ) {
    const resp = await this._apiProvider.getWorker(
      personnelNumber,
      vatnum,
      recId
    );
    const list: Worker[] = resp as Worker[];
    return list[0];
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de trabajadores.
   *
   * @returns Una lista con los trabajadores activos a la fecha.
   */
  async getWorkerList(displayValue: string, dimensionName: string) {
    const resp = await this._apiProvider.getWorkerByFinancialDimension(
      displayValue,
      dimensionName
    );
    const list: Worker[] = resp as Worker[];
    return list;
  }

  /**
   *
   * @param personnelNumber
   * @returns
   */
  async getAssetsList(personnelNumber: string) {
    const resp = await this._apiProvider.getAssetByWorker(personnelNumber); //Obtiene los activos por usuario.
    const list: FixedAsset[] = resp as FixedAsset[]; //Identifica el response como una lista de objetos FixedAsset.
    return list;
  }

  /**
   *
   * @param personnelNumber
   * @returns
   */
  async getAuditFindingList() {
    const resp = await this._apiProvider.getAuditFinding(); //Obtiene todos los hallazgos de auditoría.
    const list: AuditFinding[] = resp as AuditFinding[]; //Identifica el response como una lista de objetos AuditFinding.
    return list;
  }

  // /**
  //  *
  //  */
  async selectDepartment() {
    this.assetAuditList = [];
    this.assetList = [];
    let popover = await this.modalCtrl.create({
      component: SearchableSelectComponent,
      componentProps: { objectsList: this.aDepartment, typeAction: 3 },
      cssClass: 'hide-overflow-popover',
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null) {
        this.department = data;
        const loading = await this.loadingCtrl.create({
          message: 'Cargando...',
        }); //Genera pantalla de cargado.
        loading.present();
        this.assetResponsibles = await this.getWorkerList(
          this.department.getId().toString(),
          'A_Departamento'
        );
        await loading.dismiss();
      }
    });
  }

  /**
   *
   */
  async selectAuditedResponsible() {
    this.assetAuditList = [];
    this.assetList = [];
    let popover = await this.modalCtrl.create({
      component: SearchableSelectComponent,
      componentProps: { objectsList: this.assetResponsibles },
      cssClass: 'hide-overflow-popover',
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null) {
        this.assetsResponsible = data;
        const loading = await this.loadingCtrl.create({
          message: 'Cargando...',
        }); //Genera pantalla de cargado.
        loading.present(); //Muestra pantalla de cargado.
        this.assetList = await this.getAssetsList(
          this.assetsResponsible.getPersonnelNumber()
        );
        await loading.dismiss();
      }
    });
  }

  async loadAudit() {
    for (let i = 0; i < this.assetList.length; i++) {
      let assetAuditTemp = this.audit
        .getAssetAuditList()
        .find(
          (element) =>
            element.getAssetRecId() == this.assetList[i].getAssetRecId()
        );
      if (assetAuditTemp) {
        let auditFindingTemp = this.deepAuditFindingCopy(this.auditFindingList);
        assetAuditTemp.getAuditFindingRecIds().forEach((AF) => {
          auditFindingTemp
            .find((AFT) => AFT.getAuditFindingRecId() === AF)
            .setSelected(true);
        });
        let newAudit = new AssetAudit(
          assetAuditTemp.getAssetRecId(),
          assetAuditTemp.getComment(),
          auditFindingTemp,
          assetAuditTemp.getAuditFindingRecIds(),
          assetAuditTemp.getAssigned()
        );
        this.assetAuditList.push(newAudit);
      }
    }

    for (let i = 0; i < this.audit.getAssetAuditList().length; i++) {
      if (this.audit.getAssetAuditList()[i].getAssigned() == 0) {
        const resp = await this._apiProvider.getAsset(
          '',
          this.audit.getAssetAuditList()[i].getAssetRecId()
        );
        let vUnassigendAsset: FixedAsset[] = resp as FixedAsset[];
        this.unassignedAssetList.push(vUnassigendAsset[0]);
      }
    }
  }

  deepAuditFindingCopy(pList: AuditFinding[]) {
    let respList: AuditFinding[] = [];
    pList.forEach((element) => {
      let varia = new AuditFinding(
        element.getAuditFindingRecId(),
        element.getFinding(),
        element.getDescription(),
        element.getNonConformity(),
        element.getSelected()
      );
      respList.push(varia);
    });
    return respList;
  }

  /**
   *
   * @param pAssetRecId:
   */
  async addComment(pAssetRecId: number) {
    let vComment: string;
    let vAuditFindings: AuditFinding[];
    let vAssigned: number = 1;
    if (
      this.assetAuditList.find(
        (element) => element.getAssetRecId() === pAssetRecId
      )
    ) {
      vComment = this.assetAuditList
        .find((element) => element.getAssetRecId() === pAssetRecId)
        .getComment();
      vAuditFindings = this.assetAuditList
        .find((element) => element.getAssetRecId() === pAssetRecId)
        .getAuditFindings();
    } else {
      vComment = '';
      vAuditFindings = this.auditFindingList;
    }
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AddCommentComponent,
      componentProps: {
        assetRecId: pAssetRecId,
        comment: vComment,
        auditFindings: vAuditFindings,
        assigned: vAssigned,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        if (
          this.assetAuditList.find(
            (element) => element.getAssetRecId() === pAssetRecId
          )
        ) {
          this.assetAuditList
            .find((element) => element.getAssetRecId() === pAssetRecId)
            .setComment(data.comment);
        } else {
          let newAssetAudit = new AssetAudit(
            data.assetRecId,
            data.comment,
            data.auditFindings,
            [],
            data.assigned
          );
          this.assetAuditList.push(newAssetAudit);
        }
      }
    });
  }

  /**
   *
   */
  async addFinding(pAssetRecId: number) {
    let vComment: string;
    let vAuditFindings: AuditFinding[];
    let vAssigned: number = 1;
    if (
      this.assetAuditList.find(
        (element) => element.getAssetRecId() === pAssetRecId
      )
    ) {
      vComment = this.assetAuditList
        .find((element) => element.getAssetRecId() === pAssetRecId)
        .getComment();
      vAuditFindings = this.assetAuditList
        .find((element) => element.getAssetRecId() === pAssetRecId)
        .getAuditFindings();
    } else {
      vComment = '';
      vAuditFindings = this.auditFindingList;
    }
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AddFindingComponent,
      componentProps: {
        assetRecId: pAssetRecId,
        comment: vComment,
        auditFindings: vAuditFindings,
        assigned: vAssigned,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        if (
          this.assetAuditList.find(
            (element) => element.getAssetRecId() === pAssetRecId
          )
        ) {
          this.assetAuditList
            .find((element) => element.getAssetRecId() === pAssetRecId)
            .setAuditFindings(data.auditFindings);
        } else {
          let newAssetAudit = new AssetAudit(
            data.assetRecId,
            data.comment,
            data.auditFindings,
            [],
            data.assigned
          );
          this.assetAuditList.push(newAssetAudit);
        }
      }
    });
  }

  /**
   *
   * @param pAssetRecId
   * @returns
   */
  checkComment(pAssetRecId: number) {
    if (
      this.assetAuditList.find(
        (element) => element.getAssetRecId() === pAssetRecId
      ) &&
      this.assetAuditList
        .find((element) => element.getAssetRecId() === pAssetRecId)
        .getComment()
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param pAssetRecId
   * @returns
   */
  checkFinding(pAssetRecId: number) {
    if (
      this.assetAuditList.find(
        (element) => element.getAssetRecId() === pAssetRecId
      ) &&
      this.assetAuditList
        .find((element) => element.getAssetRecId() === pAssetRecId)
        .getAuditFindings()
        .find((subElement) => subElement.getSelected())
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkErrorMsg() {
    if (this.errorMsg.length) {
      return true;
    } else {
      return false;
    }
  }

  parseAudit() {
    let newAuditFindingsList = [];
    for (let i = 0; i < this.assetAuditList.length; i++) {
      newAuditFindingsList = [];
      for (
        let j = 0;
        j < this.assetAuditList[i].getAuditFindings().length;
        j++
      ) {
        const auditFinding = this.assetAuditList[i].getAuditFindings()[j];
        if (auditFinding.getSelected()) {
          newAuditFindingsList.push(auditFinding.getAuditFindingRecId());
        }
      }
      this.assetAuditList[i].setAuditFindings([]);
      this.assetAuditList[i].setAuditFindingRecIds(newAuditFindingsList);
    }
  }

  //Agregar los nuevos audits.

  countCheckedAssets() {
    let assetsCount: number = 0;
    for (let index = 0; index < this.assetAuditList.length; index++) {
      if (this.checkFinding(this.assetAuditList[index].getAssetRecId())) {
        assetsCount += 1;
      }
    }
    return assetsCount;
  }

  async saveData() {
    if (this.assetList.length != 0) {
      const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
      loading.present(); //Muestra pantalla de cargado.
      this.addUnassignedAssets();
      this.parseAudit();
      const resp = await this._apiProvider.saveAudit(
        this.audit.getAuditRecId(),
        this.petitioner,
        this.auditor,
        this.assetsResponsible,
        this.assetAuditList,
        0
      );
      await loading.dismiss();
      if (resp) {
        this.createAlert(
          'Auditoría guardada',
          'La auditoría se ha guardado correctamente'
        );
        this.viewCtrl.dismiss({ reload: true });
      }
    } else {
      this.createAlert(
        'Acción sin efecto',
        'No se poseen activos para guardar'
      );
    }
  }

  async finishAudit() {
    if (
      this.countCheckedAssets() === this.assetList.length &&
      this.assetList.length != 0
    ) {
      const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
      loading.present(); //Muestra pantalla de cargado.
      this.addUnassignedAssets();
      this.parseAudit();
      const resp = await this._apiProvider.saveAudit(
        this.audit.getAuditRecId(),
        this.petitioner,
        this.auditor,
        this.assetsResponsible,
        this.assetAuditList,
        1
      );
      await loading.dismiss();
      if (resp == 1) {
        this.createAlert(
          'Auditoría exitosa',
          'La auditoría ha sido finalizada correctamente'
        );
        this.viewCtrl.dismiss({ reload: true });
      } else {
        this.createAlert(
          'Error',
          'Se ha producido un error cuando se ha intentado guardar la auditoría'
        );
      }
    } else {
      this.errorMsg = 'Uno o más activos pendientes de revisión';
    }
  }

  /**
   *
   * @param title
   * @param subTitle
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      message: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }

  async openFile(fileURL: string) {
    const options: InAppBrowserOptions = {
      location: 'yes', //Or 'no'
      hidden: 'no', //Or  'yes'
      clearcache: 'yes',
      clearsessioncache: 'yes',
      zoom: 'yes', //Android only ,shows browser zoom controls
      hardwareback: 'yes',
      mediaPlaybackRequiresUserAction: 'no',
      shouldPauseOnSuspend: 'no', //Android only
      closebuttoncaption: 'Close', //iOS only
      disallowoverscroll: 'no', //iOS only
      toolbar: 'yes', //iOS only
      enableViewportScale: 'no', //iOS only
      allowInlineMediaPlayback: 'no', //iOS only
      presentationstyle: 'pagesheet', //iOS only
      fullscreen: 'yes', //Windows only
    };
    const browser = this.iab.create(fileURL, '_system', options);
    browser.show();
  }

  async getAssetListByCodebar() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AssetAuditUnassignedAssetComponent,
      componentProps: {
        unassignedAssetList: this.unassignedAssetList,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        this.unassignedAssetList.push(data.selectedAsset);
      }
    });
  }

  async addUnassignedAssets() {
    for (let index = 0; index < this.unassignedAssetList.length; index++) {
      let newAssetAudit = new AssetAudit(
        this.unassignedAssetList[index].getAssetRecId(),
        '',
        [],
        [],
        0
      );
      this.assetAuditList.push(newAssetAudit);
    }
  }

  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
}
