import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { ClientInfo } from '../../businessCore/trip-models/ClientInfo';
import { FormTrip } from '../../businessCore/trip-models/FormTrip';
import { InfoImage } from '../../businessCore/trip-models/InfoImage';
import { Vehicle } from '../../businessCore/trip-models/Vehicle';
import { TripModel } from '../../businessCore/trip-models/trip';

const ITEM_KEY = 'TRIP-DB';
const vehiclesTrip = 'VEHI-TRIP';
const typeVehicle = 'TYPE-VEHI-TRIP';
const propertyVehicle = 'PRO-VEHI-TRIP';

@Injectable()
export class LocalTripStorageProvider {
  private fechaGuardado: Date;
  private actualForm: FormTrip;
  private dataAreaList: DataArea[];
  private vehicleList: Vehicle[];
  private clientList: ClientInfo[];
  private actualTrip: TripModel;
  private imageDataInit: InfoImage;
  private imageDataEnd: InfoImage;

  constructor(private storage: StorageService) {}

  setFormData(newForm: FormTrip) {
    this.actualForm = newForm;
  }

  async saveLastForm() {
    let result = false;
    try {
      //console.log('Entro en save last form');
      //await this.storage.ready();
      const listInfoTrip = await this.storage.get(ITEM_KEY);
      // if (listInfoTrip) {
      // console.log("Guardando...");
      this.actualForm.FechaLocal = new Date(); // this.fechaGuardado;
      //console.log(this.actualForm);
      const data = this.storage.set(ITEM_KEY, [
        JSON.stringify(this.actualForm),
      ]);
      // const data =  this.storage.set(ITEM_KEY, [JSON.stringify({prueb: "hola"})]);
      //console.log('Guardado... ', data);
      // }
      result = true;
    } catch {
      //console.log('Error local storage');
    }
    return result;
  }

  async getLastForm() {
    let infoSave: any;
    try {
      //await this.storage.ready();
      infoSave = await this.storage.get(ITEM_KEY);
      //console.log('Datos de local storage: ', infoSave);
    } catch {
      //console.log('Error local storage 2--');
    }
    return infoSave;
  }

  // Get cedula
  async getCedulaActual(isMVIP: boolean) {
    let cedulaAct = '';
    if (isMVIP) {
      const data = await this.storage.get('register_data');

      if (data) {
        const register_data = JSON.parse(data);
        const { id_card } = register_data;
        cedulaAct = id_card as string;
      }

      const cedulaDist = await this.storage.get('associatedPerson');
      if (cedulaDist) {
        const associatedPerson = JSON.parse(cedulaDist);
        if (associatedPerson) {
          const { vatNum } = associatedPerson;
          cedulaAct = vatNum;
        }
      }
    } else {
      //Si ocupo tambien revisar que sea desde GI_PLUS
      const data = await this.storage.get('register_vatnum');
      if (data) {
        cedulaAct = JSON.parse(data) as string;
      }
    }

    if (cedulaAct === undefined || cedulaAct === null) {
      cedulaAct = '';
    }

    return cedulaAct;
  }

  async getTypeVehicle() {
    let listTmpVehicle: Vehicle[] = [];
    try {
      //await this.storage.ready();
      const infoSaved = await this.storage.get(typeVehicle);
      if (infoSaved !== null && infoSaved !== undefined) {
        // { dateSaved , dataTrip } = infoSaved as TypeVehicleS;
      } else {
        //Se llama al servidor, para traer los datos de los tipos de vehiculos...
      }
    } catch {
      listTmpVehicle = [];
    }
    return listTmpVehicle;
  }
}
