import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
} from '@ionic/angular';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { ParadaTripComponent } from 'src/app/components/trip-components/parada-trip/parada-trip.component';
import { SpendTripComponent } from 'src/app/components/trip-components/spend-trip/spend-trip.component';
import { GastoModel } from '../../businessCore/trip-models/gasto';
import { ParadaModel } from '../../businessCore/trip-models/parada';
import { TripModel } from '../../businessCore/trip-models/trip';
import { TripParameterProvider } from '../../provider/Services/trip-parameter';
import { ExternalServiceTripProvider } from './../../provider/Services/external-service-trip';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';

@Component({
  selector: 'app-trip-register',
  templateUrl: './trip-register.component.html',
  styleUrls: ['./trip-register.component.scss'],
})
export class TripRegisterPage {
  private actualTrip: TripModel;
  presentingMess: boolean;
  loader: HTMLIonLoadingElement;
  hideParadaOP: boolean;
  adjuntoFinal: boolean;
  auth: number;

  constructor(
    public navCtrl: NavController,
    private parameterTrip: TripParameterProvider,
    public modalCtrl: ModalController,
    private alertController: AlertController,
    private webService: ExternalServiceTripProvider,
    private loadingCtrl: LoadingController,
    public navParams: NavParams,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.presentingMess = false;
    this.hideParadaOP = false;
    this.adjuntoFinal = false;
    this.auth = 0;
  }

  async ngOnInit() {
    this.auth = Number(this.navParams.get('auth'));
    // const info = window.location.search;
    // const urlParams = new URLSearchParams(info);
    this.hideParadaOP = !(Number(this.navParams.get('showOp')) === 1);
  }

  ionViewWillUnload() {
    // this.setStateForm();
    // this.localSto.saveLastForm();
  }

  /**async openMenuOC(evento ) {
    const popover = this.popoverCtrl.create(
      InfoTripPopComponent,
      {cssClass: 'info-trip-pop',
      enableBackdropDismiss: true,
      showBackdrop: true}
    );
    await popover.present({
      ev: evento
    });
  }**/

  detenerAbrir() {
    this.actualTrip = this.parameterTrip.getTripParameter();
    if (this.actualTrip !== null && this.actualTrip !== undefined) {
      if (this.actualTrip.getStateTrip() === 'ABIERTO') {
        this.openOption(4); // this.executeAction(4);
      } else {
        this.openOption(7); // this.executeAction(7);
      }
    } else {
      this.messageInfo('Atención', 'No hay datos de viaje iniciado');
    }
  }

  executeAction(action: number) {
    switch (action) {
      case 1:
        this.openGastoScreen();
        break;
      case 2:
        this.openParadaScreen();
        break;
      case 3:
        this.changeStateTrip('CONCLUIDO');
        break;
      case 4:
        this.changeStateTrip('DETENIDO');
        break;
      case 5:
        this.changeStateTrip('INTERRUMPIDO');
        break;
      case 6:
        this.changeStateTrip('CANCELADO');
        break;
      case 7:
        this.changeStateTrip('ABIERTO');
        break;
      default:
        break;
    }
  }

  openOption(option: number) {
    switch (option) {
      case 1:
        this.messageInfo(
          'Atención',
          '¿Quiere agregar un gasto al registro del viaje?',
          false,
          '',
          option
        );
        break;
      case 2:
        this.messageInfo(
          'Atención',
          '¿Quiere agregar una parada al registro del viaje?',
          false,
          '',
          option
        );
        break;
      case 3:
        this.messageInfo(
          'Atención',
          '¿Quiere CONCLUIR el registro del viaje?',
          false,
          '',
          option
        );
        break;
      case 4:
        this.messageInfo(
          'Atención',
          '¿Quiere DETENER el registro del viaje?',
          false,
          '',
          option
        );
        break;
      case 5:
        this.messageInfo(
          'Atención',
          '¿Quiere INTERRUMPIR el registro del viaje?',
          false,
          '',
          option
        );
        break;
      case 6:
        this.messageInfo(
          'Atención',
          '¿Quiere CANCELAR el registro del viaje?',
          false,
          '',
          option
        );
        break;
      case 7:
        this.messageInfo(
          'Atención',
          '¿Quiere ABRIR el registro del viaje?',
          false,
          '',
          option
        );
        break;
      default:
        break;
    }
  }

  async sendChangeStateTrip(
    estado: string,
    mensaje: string,
    mensajeFin: string
  ) {
    let resp = null;
    await this.showSpinnerLoading(mensaje);
    try {
      resp = await this.webService.changeTripState(
        estado,
        this.actualTrip.getFMTrip(),
        this.actualTrip.getDataArea()
      );
    } catch (error) {
      resp = error;
    }
    this.dissmissLoading();

    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puede cambiar el estado del viaje, intentelo de nuevo.'
      );
    } else {
      if (
        typeof resp === 'string' &&
        resp.toUpperCase() === estado.toUpperCase()
      ) {
        this.actualTrip.setStateTrip(resp.toUpperCase());
        this.parameterTrip.setTripParameter(this.actualTrip);
        this.messageInfo('Atención', mensajeFin);

        if (estado === 'CONCLUIDO') {
          this.parameterTrip.setTripParameter(new TripModel());
          this.navService.setRoot(
            this.activatedRoute.component.name as NavigationRoutes
          );
        }
      } else {
        this.messageInfo(
          'Error',
          'No se puede cambiar el estado del viaje, intentelo de nuevo.'
        );
      }
    }
    // 'CONCLUIDO' 'DETENIDO' 'INTERRUMPIDO' 'ABIERTO'
  }

  validateAdjInit() {
    let resultado = true;
    if (this.adjuntoFinal) {
      if (
        (this.actualTrip.getPuntoFinal().getImageData() === null ||
          this.actualTrip.getPuntoFinal().getImageData() === undefined) &&
        this.actualTrip.getPuntoFinal().getRecId() !== null
      ) {
        resultado = false;
      }
    }
    return resultado;
  }

  //Este codigo tiene muchos if, claro, me estara juzgando, pero tengo una molestia en este momento
  //entonces lo dejo asi

  cantDoIt() {
    return (
      this.actualTrip.getStateTrip().toUpperCase() !== 'CONCLUIDO' &&
      this.actualTrip.getStateTrip().toUpperCase() !== 'CANCELADO' &&
      this.actualTrip.getStateTrip().toUpperCase() !== 'INTERRUMPIDO' &&
      this.actualTrip.getStateTrip().toUpperCase() !== '' &&
      this.actualTrip.getStateTrip().toUpperCase() !== 'DETENIDO'
    );
  }

  concluirViaje() {
    if (
      this.actualTrip.getPuntoFinal() !== null &&
      this.actualTrip.getPuntoFinal() !== undefined &&
      this.actualTrip.getPuntoFinal().getRecId() !== null
    ) {
      if (this.actualTrip.getPuntoFinal().getKilometraje() !== null) {
        if (this.validateAdjInit()) {
          if (
            this.actualTrip
              .getPuntoFinal()
              .validateDate(this.actualTrip.getPuntoFinal().getEndDate())
          ) {
            // Setea el kilometraje del punto final, en kilometros dado el caso.
            this.updateTripPoint(
              this.actualTrip.getPuntoFinal(),
              'Actualizando punto final',
              ''
            );
          } else {
            this.messageInfo(
              'Error',
              'Se necesita la fecha final, para concluir el viaje.'
            );
          }
        } else {
          this.messageInfo(
            'Error',
            'Se necesita la foto del kilometraje final, para concluir el viaje.'
          );
        }
      } else {
        this.messageInfo(
          'Atención',
          'No hay información del kilometraje final,  no se puede concluir el viaje.'
        );
      }
    } else {
      this.messageInfo(
        'Atención',
        'No hay punto de fin para el viaje, no se puede concluir.'
      );
    }
  }

  changeStateTrip(estado: string) {
    this.actualTrip = this.parameterTrip.getTripParameter();
    this.adjuntoFinal = this.parameterTrip.getParadaOP();
    if (this.actualTrip !== null && this.actualTrip !== undefined) {
      if (
        this.actualTrip.getPuntoInit() !== null &&
        this.actualTrip.getPuntoInit() !== undefined
      ) {
        if (this.cantDoIt()) {
          if (estado === 'DETENIDO') {
            this.sendChangeStateTrip(
              estado,
              'Deteniendo viaje',
              'El registro del viaje se detuvo de forma correcta.'
            );
          } else if (estado === 'INTERRUMPIDO') {
            this.sendChangeStateTrip(
              estado,
              'Interrumpiendo viaje',
              'El registro del viaje se interrumpió de forma correcta.'
            );
          } else if (estado === 'CANCELADO') {
            this.sendChangeStateTrip(
              estado,
              'Cancelando viaje',
              'El registro del viaje se canceló de forma correcta.'
            );
          } else if (estado === 'CONCLUIDO') {
            this.concluirViaje();
          }
        } else if (
          this.actualTrip.getStateTrip() === 'DETENIDO' &&
          estado === 'ABIERTO'
        ) {
          this.sendChangeStateTrip(
            estado,
            'Abriendo viaje',
            'El registro del viaje está abierto.'
          );
        } else {
          let mensaje =
            'El viaje no ha sido creado, no se puede realizar esta acción.';
          if (this.actualTrip.getStateTrip() !== '') {
            mensaje =
              `El viaje ha sido ` +
              this.actualTrip.getStateTrip().toUpperCase() +
              `, no se puede realizar esta opción.`;
          }
          this.messageInfo('Atención', mensaje);
        }
      } else {
        this.messageInfo('Atención', 'No hay punto de inicio para el viaje');
      }
    } else {
      this.messageInfo('Atención', 'No hay datos de viaje iniciado');
    }
  }

  async updateTripPoint(
    paradaInfo: ParadaModel,
    mensajeSpinner: string,
    mensajeEnd: string
  ) {
    await this.showSpinnerLoading(mensajeSpinner);
    let resp = null;
    try {
      const listInfo: any[] = [];
      listInfo.push(paradaInfo.ToJSON());
      resp = await this.webService.editPointTrip(
        JSON.stringify(listInfo),
        paradaInfo.getImageData64(),
        paradaInfo.getImageJSON()
      );
    } catch (error) {
      resp = error;
      console.log(error);
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puede guardar el punto de ' +
          mensajeEnd +
          ' , intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp.includes('SUCCESS')) {
      console.log('Se envia los datos de cambio de estado');
      this.sendChangeStateTrip(
        'CONCLUIDO',
        'Concluyendo viaje',
        'El registro del viaje se concluyó de forma correcta.'
      );
    }
  }

  async sendTripPoint(
    paradaInfo: ParadaModel,
    mensajeSpinner: string,
    mensajeEnd: string,
    endPoint?: boolean
  ) {
    await this.showSpinnerLoading(mensajeSpinner);
    let resp = null;
    try {
      const listInfo: any[] = [];
      listInfo.push(paradaInfo.ToJSON());
      resp = await this.webService.createTripPoint(
        JSON.stringify(listInfo),
        '',
        ''
      );
    } catch (error) {
      resp = error;
      console.log(error);
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puede guardar el punto de ' +
          mensajeEnd +
          ' , intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp.includes('SUCCESS')) {
      const arraySplit = resp.split('-');
      if (arraySplit[1] !== '') {
        paradaInfo.setRecId(Number(arraySplit[1]));
        /* if (endPoint === null || endPoint === undefined || !endPoint) {
          try {
            const listInfo: any[] = [];
            listInfo.push(paradaInfo.ToJSON());
            this.webService.editPointTrip(JSON.stringify(listInfo), '', '');
          } catch (error) {
            console.log(error);
          }
        }*/
        this.actualTrip.setNewParada(paradaInfo);
      }

      this.messageInfo(
        'Atención',
        'Punto de ' + mensajeEnd + ' almacenado correctamente.'
      );

      this.parameterTrip.setTripParameter(this.actualTrip);
    }
  }

  async sendExpenseTrip(
    gastoInfo: GastoModel,
    mensajeSpinner: string,
    mensajeEnd: string
  ) {
    await this.showSpinnerLoading(mensajeSpinner);
    let resp = null;
    try {
      const listInfo: any[] = [];
      listInfo.push(gastoInfo.ToJSON());
      resp = await this.webService.sendGastoTrip(JSON.stringify(listInfo));
    } catch (error) {
      resp = error;
      console.log(error);
    }

    this.dissmissLoading();
    if (typeof resp === 'number') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'timeOut') {
      this.messageInfo(
        'Error',
        'No se puede comunicar con el servidor, intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp === 'FAILED') {
      this.messageInfo(
        'Error',
        'No se puede guardar el punto de ' +
          mensajeEnd +
          ' , intentelo de nuevo.'
      );
    } else if (typeof resp === 'string' && resp.includes('SUCCESS')) {
      this.messageInfo(
        'Atención',
        'Información de ' + mensajeEnd + ' almacenado correctamente.'
      );
      const arraySplit = resp.split('-');
      if (arraySplit[1] !== '') {
        gastoInfo.setRecId(Number(arraySplit[1]));
        /* if( === null || endPoint === undefined || !endPoint) {*/
        /* try {
          const listInfo: any[] = [];
          listInfo.push( paradaInfo.ToJSON() );
          this.webService.editPointTrip(JSON.stringify(listInfo), '','');
        } catch (error) {
          console.log(error);
        }*/
        /*}*/
        this.actualTrip.setNewGasto(gastoInfo);
      }

      this.messageInfo(
        'Atención',
        'Información de ' + mensajeEnd + ' almacenada correctamente.'
      );
      this.parameterTrip.setTripParameter(this.actualTrip);
    }
  }

  openScreen(option: number) {
    this.actualTrip = this.parameterTrip.getTripParameter();
    if (this.actualTrip !== null && this.actualTrip !== undefined) {
      if (
        this.actualTrip.getPuntoInit() !== null &&
        this.actualTrip.getPuntoInit() !== undefined
      ) {
        if (this.cantDoIt() && this.actualTrip.getStateTrip() !== 'DETENIDO') {
          this.openOption(option);
        } else {
          let mensaje =
            'El viaje no ha sido creado, no se puede realizar esta acción.';
          if (this.actualTrip.getStateTrip() !== '') {
            mensaje =
              `El viaje ha sido ` +
              this.actualTrip.getStateTrip().toUpperCase() +
              `, no se puede realizar esta opción.`;
          }
          this.messageInfo('Atención', mensaje);
        }
      } else {
        this.messageInfo('Atención', 'No hay punto de inicio para el viaje');
      }
    } else {
      this.messageInfo('Atención', 'No hay datos de viaje iniciado');
    }
  }

  //Llama la función de pedir observacion
  async openGastoScreen() {
    if (!this.presentingMess) {
      this.presentingMess = true;
      const modal = await this.modalCtrl.create({
        component: SpendTripComponent,
        componentProps: {
          actualInfo: this.actualTrip,
        },
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((res) => {
        let data = res.data;
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          const newGasto = data.spendCreated as GastoModel;
          if (newGasto !== null && newGasto !== undefined) {
            this.actualTrip.setNewGasto(newGasto);
            newGasto.setDataArea(this.actualTrip.getDataArea());
            newGasto.setFMTrips(this.actualTrip.getFMTrip());
            newGasto.setExecutor(this.actualTrip.getExecutorId());
            this.sendExpenseTrip(newGasto, 'Agregando gasto a viaje', 'gasto');
          }
        }
      });
    }
  }

  async openParadaScreen() {
    if (!this.presentingMess) {
      this.presentingMess = true;
      const modal = await this.modalCtrl.create({
        component: ParadaTripComponent,
        componentProps: {
          actualInfo: this.actualTrip,
        },
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((res) => {
        let data = res.data;
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          const newParada = data.paradaCreated as ParadaModel;
          if (newParada !== null && newParada !== undefined) {
            newParada.setDataArea(this.actualTrip.getDataArea());
            newParada.setFMTrips(this.actualTrip.getFMTrip());
            this.sendTripPoint(newParada, 'Agregando parada a viaje', 'parada'); // , true);
          }
        }
      });
    }
  }

  async messageInfo(
    titulo: string,
    mensaje: string,
    go?: boolean,
    subH?: string,
    action?: number
  ) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action > 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.executeAction(action);
          },
        },
        { text: 'NO', handler: () => {} },
      ];
    } else if (action && action === -1) {
      botones = [
        {
          text: 'ACEPTAR',
          handler: () => {
            this.navCtrl.pop();
          },
        },
      ];
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: subH,
        message: mensaje,
        buttons: botones,
        /*[{ text: 'Aceptar', handler: () => {
          if(go) {
            // this.appCtrl.getRootNav().popToRoot();
            // this.appCtrl.getActiveNavs()[0].popToRoot();
          }
        } }]*/
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }
}
