<div style="display: flex; background: white">
  <div
    *ngFor="let star of stars"
    class="star-container"
    (click)="setRate(star)"
  >
    <!-- Full Star -->
    <ion-icon
      *ngIf="floor(rating) >= star"
      name="star"
      class="full star"
    ></ion-icon>

    <!-- Half Star -->
    <ion-icon
      *ngIf="star - 1 < rating && rating < star"
      name="star-half-outline"
      class="half star"
    ></ion-icon>

    <!-- Empty Star -->
    <ion-icon
      *ngIf="rating <= star - 1"
      name="star-outline"
      class="empty star"
    ></ion-icon>
  </div>
</div>
