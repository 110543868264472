import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EinvoiceFilterComponent } from '../Invoice/components/einvoice-filter/einvoice-filter.component';
import { MessageBoxComponent } from '../Invoice/components/message-box/message-box.component';
import { PurchOrderEinvoiceFilterComponent } from '../Invoice/components/purch-order-einvoice-filter/purch-order-einvoice-filter.component';
import { AssignEinvoicePage } from '../Invoice/pages/assign-einvoice/assign-einvoice.component';
import { AssignPurchOrderPage } from '../Invoice/pages/assign-purch-order/assign-purch-order.component';
import { InvoiceLinesPage } from '../Invoice/pages/invoice-lines/invoice-lines.component';
import { InvoiceListPage } from '../Invoice/pages/invoice-list/invoice-list.component';
import { ConfigLinePurchRemissionComponent } from '../WMS/components/config-line-purch-remission/config-line-purch-remission.component';
import { PurchOrderRemissionFilterComponent } from '../WMS/components/purch-order-remission-filter/purch-order-remission-filter.component';
import { PurchRemissionInfoComponent } from '../WMS/components/purch-remission-info/purch-remission-info.component';
import { SelectFilterComponent } from '../WMS/components/select-filter/select-filter.component';
import { SelectObjectsComponent } from '../WMS/components/select-objects/select-objects.component';
import { ShowConversionItemComponent } from '../WMS/components/show-conversion-item/show-conversion-item.component';
import { RemissionLinesPage } from '../WMS/pages/remission-lines/remission-lines.component';
import { RemissionListPage } from '../WMS/pages/remission-list/remission-list.component';
import { DirectivesModule } from '../directives/directives.module';
import { AssetAuditPage } from '../pages/asset-audit/asset-audit.component';
import { AssetCertificationApprovalPage } from '../pages/asset-certification-approval/asset-certification-approval.component';
import { AssetCertificationPage } from '../pages/asset-certification/asset-certification.component';
import { AuthPage } from '../pages/auth/auth.component';
import { GetPositionVendorPage } from '../pages/get-position-vendor/get-position-vendor.component';
import { GetPositionPage } from '../pages/get-position/get-position.component';
import { HomePage } from '../pages/home/home.component';
import { LoginPage } from '../pages/login/login.page.component';
import { MainPagePage } from '../pages/main-page/main-page.page.component';
import { MapTripPage } from '../pages/map-trip/map-trip.component';
import { PurchOrderConfirmPage } from '../pages/purch-order-confirm/purch-order-confirm.component';
import { PurchOrderLinePage } from '../pages/purch-order-line/purch-order-line.component';
import { PurchOrderRecordPage } from '../pages/purch-order-record/purch-order-record.component';
import { PurchOrderToApprovedPage } from '../pages/purch-order-to-approved/purch-order-to-approved.component';
import { PurchOrderToBeApprovedPage } from '../pages/purch-order-to-be-approved/purch-order-to-be-approved.component';
import { PurchOrderToBeConfirmedPage } from '../pages/purch-order-to-be-confirmed/purch-order-to-be-confirmed.component';
import { PurchOrderPage } from '../pages/purch-order/purch-order.component';
import { TripRegisterPage } from '../pages/trip-register/trip-register.component';
import { OrderRetHistoryComponent } from './ReturnComponents/order-ret-history/order-ret-history.component';
import { RetAddItemComponent } from './ReturnComponents/ret-add-item/ret-add-item.component';
import { RetBoughtItemComponent } from './ReturnComponents/ret-bought-item/ret-bought-item.component';
import { RetCarComponent } from './ReturnComponents/ret-car/ret-car.component';
import { RetDeliveryCarComponent } from './ReturnComponents/ret-delivery-car/ret-delivery-car.component';
import { RetProductHistoryComponent } from './ReturnComponents/ret-product-history/ret-product-history.component';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { AddFindingComponent } from './add-finding/add-finding.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { AddToListComponent } from './add-to-list/add-to-list.component';
import { AssetAcceptanceComponent } from './asset-acceptance/asset-acceptance.component';
import { AssetAuditAddComponent } from './asset-audit-add/asset-audit-add.component';
import { AssetAuditRecordComponent } from './asset-audit-record/asset-audit-record.component';
import { AssetAuditUnassignedAssetComponent } from './asset-audit-unassigned-asset/asset-audit-unassigned-asset.component';
import { AssetCertificationPeriodicityComponent } from './asset-certification-periodicity/asset-certification-periodicity.component';
import { AssetCertificationPopoverComponent } from './asset-certification-popover/asset-certification-popover.component';
import { AssetFilterComponent } from './asset-filter/asset-filter.component';
import { AssetRequestActionComponent } from './asset-request-action/asset-request-action.component';
import { AssetRequestFilterComponent } from './asset-request-filter/asset-request-filter.component';
import { AssetRequestListComponent } from './asset-request-list/asset-request-list.component';
import { AssetsApprovalComponent } from './assets-approval/assets-approval.component';
import { AssetsTransferComponent } from './assets-transfer/assets-transfer.component';
import { CertFilterComponent } from './cert-filter/cert-filter.component';
import { CertListSelectedComponent } from './cert-list-selected/cert-list-selected.component';
import { CheckListAnswerOmiComponent } from './checklist-component/check-list-answer-omi/check-list-answer-omi.component';
import { CheckListInstanceComponent } from './checklist-component/check-list-instance/check-list-instance-component';
import { CheckListTaskComponent } from './checklist-component/check-list-task/check-list-task.component';
import { ChecklistInstancePage } from './checklist-component/checklist-instance/checklist-instance.component';
import { ChecklistTaskPage } from './checklist-component/checklist-task/checklist-task.component';
import { NoteTaskComponent } from './checklist-component/note-task/note-task.component';
import { ContactComponent } from './contact/contact.component';
import { FamiliesHomeComponent } from './families-home/families-home.component';
import { FamiliesSelectHierarchyComponent } from './families-select-hierarchy/families-select-hierarchy.component';
import { FamiliesComponent } from './families/families.component';
import { FilterComponent } from './filter/filter.component';
import { GeneralDividerComponent } from './general-divider/general-divider.component';
import { GetData2CreateComponent } from './get-data2-create/get-data2-create.component';
import { GetData2EditComponent } from './get-data2-edit/get-data2-edit.component';
import { GetMapPositionComponent } from './get-map-position/get-map-position.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { InsertPasswordComponent } from './insert-password/insert-password.component';
import { LogoScreenComponent } from './logo-screen/logo-screen.component';
import { MyCustomListProductsComponent } from './my-custom-list-products/my-custom-list-products.component';
import { MyCustomListComponent } from './my-custom-list/my-custom-list.component';
import { MyPaymentComponent } from './my-payment/my-payment.component';
import { MyProfileAddcaseComponent } from './my-profile-addcase/my-profile-addcase.component';
import { MyProfileCasemanagementComponent } from './my-profile-casemanagement/my-profile-casemanagement.component';
import { MyProfileContactsComponent } from './my-profile-contacts/my-profile-contacts.component';
import { MyProfileCreateRestrictionComponent } from './my-profile-create-restriction/my-profile-create-restriction.component';
import { MyProfileDirectionsComponent } from './my-profile-directions/my-profile-directions.component';
import { MyProfileEditEmailComponent } from './my-profile-edit-email/my-profile-edit-email.component';
import { MyProfileEditLatlngComponent } from './my-profile-edit-latlng/my-profile-edit-latlng.component';
import { MyProfileEditPhoneComponent } from './my-profile-edit-phone/my-profile-edit-phone.component';
import { MyProfileGeneralInfoComponent } from './my-profile-general-info/my-profile-general-info.component';
import { MyProfileRestrictionsComponent } from './my-profile-restrictions/my-profile-restrictions.component';
import { MyProfileUpdatepassComponent } from './my-profile-updatepass/my-profile-updatepass.component';
import { MyPromoListComponent } from './my-promo-list/my-promo-list.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NewContactComponent } from './new-contact/new-contact.component';
import { NewDirectionComponent } from './new-direction/new-direction.component';
import { NewListComponent } from './new-list/new-list.component';
import { NewRestrictionComponent } from './new-restriction/new-restriction.component';
import { EntregaOcComponent } from './oc-components/entrega-oc/entrega-oc.component';
import { GeneralParameterOcComponent } from './oc-components/general-parameter-oc/general-parameter-oc.component';
import { HistorialOcComponent } from './oc-components/historial-oc/historial-oc.component';
import { InfoFileOcPopComponent } from './oc-components/info-file-oc-pop/info-file-oc-pop.component';
import { InfoFilterOcPopComponent } from './oc-components/info-filter-oc-pop/info-filter-oc-pop.component';
import { InfoPlusOcComponent } from './oc-components/info-plus-oc/info-plus-oc.component';
import { InputNumberComponent } from './oc-components/input-number/input-number.component';
import { LineOcComponent } from './oc-components/line-oc/line-oc.component';
import { LineaInfoOcComponent } from './oc-components/linea-info-oc/linea-info-oc.component';
import { NotaOcComponent } from './oc-components/nota-oc/nota-oc.component';
import { PopInfoOcComponent } from './oc-components/pop-info-oc/pop-info-oc.component';
import { RejectedNoteOCComponent } from './oc-components/rejected-note-oc/rejected-note-oc.component';
import { ResumenOcVendorComponent } from './oc-components/resumen-oc-vendor/resumen-oc-vendor.component';
import { SearchProviderComponent } from './oc-components/search-provider/search-provider.component';
import { SendMultipleOCComponent } from './oc-components/send-multiple-oc/send-multiple-oc.component';
import { ToBeApprovedOcComponent } from './oc-components/to-be-approved-oc/to-be-approved-oc.component';
import { ToBeConfirmedOcComponent } from './oc-components/to-be-confirmed-oc/to-be-confirmed-oc.component';
import { TotalesOcComponent } from './oc-components/totales-oc/totales-oc.component';
import { UpdateFinancialDimensionComponent } from './oc-components/update-financial-dimension/update-financial-dimension.component';
import { UpdateQtyOCComponent } from './oc-components/update-qty-oc/update-qty-oc.component';
import { OrderConfirmationInfoComponent } from './order-confirmation-info/order-confirmation-info.component';
import { OrderConfirmationPassComponent } from './order-confirmation-pass/order-confirmation-pass.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderTraceLinesComponent } from './order-trace-lines/order-trace-lines.component';
import { OrderTraceProgressComponent } from './order-trace-progress/order-trace-progress.component';
import { OrderTraceComponent } from './order-trace/order-trace.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PrevShoppingCarComponent } from './prev-shopping-car/prev-shopping-car.component';
import { ProductCommentComponent } from './product-comment/product-comment.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductGeneralCommentComponent } from './product-general-comment/product-general-comment.component';
import { ProductMyCommentsComponent } from './product-my-comments/product-my-comments.component';
import { PromoDetailComponent } from './promo-detail/promo-detail.component';
import { PromoResultComponent } from './promo-result/promo-result.component';
import { RatingComponent } from './rating/rating.component';
import { RegisterAdditionalInfoComponent } from './register-additional-info/register-additional-info.component';
import { RegisterAddressComponent } from './register-address/register-address.component';
import { RegisterContactEmailComponent } from './register-contact-email/register-contact-email.component';
import { RegisterContactNumberComponent } from './register-contact-number/register-contact-number.component';
import { RegisterContactComponent } from './register-contact/register-contact.component';
import { RegisterDirectionComponent } from './register-direction/register-direction.component';
import { RegisterMethodComponent } from './register-method/register-method.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SearchableSelectComponent } from './searchable-select/searchable-select.component';
import { SelectDeliveryAddressComponent } from './select-delivery-address/select-delivery-address.component';
import { SelectDeliveryTimeComponent } from './select-delivery-time/select-delivery-time.component';
import { SelectProductsComponent } from './select-products/select-products.component';
import { ShoppingCarDeliveryComponent } from './shopping-car-delivery/shopping-car-delivery.component';
import { ShoppingCarPaymentComponent } from './shopping-car-payment/shopping-car-payment.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { SubFamiliesSelectHierarchyComponent } from './sub-families-select-hierarchy/sub-families-select-hierarchy.component';
import { TermsComponent } from './terms/terms.component';
import {
  TimelineComponent,
  TimelineItemComponent,
  TimelineTimeComponent,
} from './timeline/timeline.component';
import { InfoTripsPopComponent } from './trip-components/info-trips-pop/info-trips-pop.component';
import { InfoVehiclePopComponent } from './trip-components/info-vehicle-pop/info-vehicle-pop.component';
import { NotaTripComponent } from './trip-components/nota-trip/nota-trip.component';
import { ParadaTripComponent } from './trip-components/parada-trip/parada-trip.component';
import { RegisterTripComponent } from './trip-components/register-trip/register-trip.component';
import { ShowImageComponent } from './trip-components/show-image/show-image.component';
import { SpendTripComponent } from './trip-components/spend-trip/spend-trip.component';
import { TutorialVideoComponent } from './tutorial-video/tutorial-video.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { UpdateQuantityComponent } from './update-quantity/update-quantity.component';
import { UseTermsComponent } from './use-terms/use-terms.component';
import { ValidateClientComponent } from './validate-client/validate-client.component';
import { VendorAlertComponent } from './vendor-alert/vendor-alert.component';
import { VendorRecordComponent } from './vendor-record/vendor-record.component';
import { VendorRegisterAddressComponent } from './vendor-register-address/vendor-register-address.component';
import { VendorRegisterContactComponent } from './vendor-register-contact/vendor-register-contact.component';
import { VendorRegisterDocumentComponent } from './vendor-register-document/vendor-register-document.component';
import { VendorRegisterGeneralComponent } from './vendor-register-general/vendor-register-general.component';
import { VendorRegisterLanguageComponent } from './vendor-register-language/vendor-register-language.component';
import { VendorRegisterPaymentComponent } from './vendor-register-payment/vendor-register-payment.component';
import { VendorRegisterComponent } from './vendor-register/vendor-register.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

const COMPONENTS = [
  AssetCertificationPopoverComponent,
  NavBarComponent,
  FamiliesComponent,
  AssetAcceptanceComponent,
  AssetRequestActionComponent,
  AddCommentComponent,
  AddFindingComponent,
  AddToCartComponent,
  AddToListComponent,
  RegisterContactNumberComponent,
  AssetRequestListComponent,
  AssetRequestFilterComponent,
  AssetAuditAddComponent,
  AssetAuditRecordComponent,
  AssetAuditUnassignedAssetComponent,
  AssetCertificationPeriodicityComponent,
  AssetFilterComponent,
  AssetsApprovalComponent,
  AssetsTransferComponent,
  CertFilterComponent,
  CertListSelectedComponent,
  CheckListAnswerOmiComponent,
  CheckListTaskComponent,
  NoteTaskComponent,
  ContactComponent,
  FamiliesHomeComponent,
  FamiliesSelectHierarchyComponent,
  FilterComponent,
  GeneralDividerComponent,
  GetData2CreateComponent,
  GetData2EditComponent,
  GetMapPositionComponent,
  GoogleMapComponent,
  InsertPasswordComponent,
  LogoScreenComponent,
  MyCustomListComponent,
  MyCustomListProductsComponent,
  MyPaymentComponent,
  MyProfileAddcaseComponent,
  MyProfileCasemanagementComponent,
  MyProfileContactsComponent,
  MyProfileCreateRestrictionComponent,
  MyProfileDirectionsComponent,
  MyProfileEditEmailComponent,
  MyProfileEditLatlngComponent,
  MyProfileEditPhoneComponent,
  MyProfileGeneralInfoComponent,
  MyProfileRestrictionsComponent,
  MyProfileUpdatepassComponent,
  MyPromoListComponent,
  NewContactComponent,
  NewDirectionComponent,
  NewListComponent,
  NewRestrictionComponent,
  EntregaOcComponent,
  GeneralParameterOcComponent,
  HistorialOcComponent,
  InfoFileOcPopComponent,
  InfoFilterOcPopComponent,
  InfoPlusOcComponent,
  InputNumberComponent,
  LineOcComponent,
  LineaInfoOcComponent,
  NotaOcComponent,
  PopInfoOcComponent,
  RejectedNoteOCComponent,
  ResumenOcVendorComponent,
  SearchProviderComponent,
  SendMultipleOCComponent,
  ToBeApprovedOcComponent,
  ToBeConfirmedOcComponent,
  TotalesOcComponent,
  UpdateFinancialDimensionComponent,
  UpdateQtyOCComponent,
  OrderConfirmationComponent,
  OrderConfirmationInfoComponent,
  OrderConfirmationPassComponent,
  OrderHistoryComponent,
  OrderTraceComponent,
  OrderTraceLinesComponent,
  OrderTraceProgressComponent,
  PaymentFormComponent,
  PaymentStatusComponent,
  PrevShoppingCarComponent,
  ProductCommentComponent,
  ProductDetailComponent,
  ProductGeneralCommentComponent,
  ProductMyCommentsComponent,
  PromoDetailComponent,
  PromoResultComponent,
  RegisterAdditionalInfoComponent,
  RegisterAddressComponent,
  RegisterContactComponent,
  RegisterContactEmailComponent,
  RegisterDirectionComponent,
  RegisterMethodComponent,
  ResetPasswordComponent,
  OrderRetHistoryComponent,
  RetAddItemComponent,
  RetBoughtItemComponent,
  RetCarComponent,
  RetDeliveryCarComponent,
  RetProductHistoryComponent,
  SearchableSelectComponent,
  SelectDeliveryAddressComponent,
  SelectDeliveryTimeComponent,
  SelectProductsComponent,
  ShoppingCarDeliveryComponent,
  ShoppingCarPaymentComponent,
  ShoppingCartComponent,
  SubFamiliesSelectHierarchyComponent,
  TermsComponent,
  TimelineComponent,
  TimelineItemComponent,
  TimelineTimeComponent,
  UseTermsComponent,
  InfoTripsPopComponent,
  InfoVehiclePopComponent,
  NotaTripComponent,
  ParadaTripComponent,
  RegisterTripComponent,
  ShowImageComponent,
  SpendTripComponent,
  TutorialVideoComponent,
  TutorialsComponent,
  UpdateQuantityComponent,
  ValidateClientComponent,
  VendorAlertComponent,
  VendorRecordComponent,
  VendorRegisterComponent,
  VendorRegisterAddressComponent,
  VendorRegisterContactComponent,
  VendorRegisterDocumentComponent,
  VendorRegisterGeneralComponent,
  VendorRegisterLanguageComponent,
  VendorRegisterPaymentComponent,
  EinvoiceFilterComponent,
  MessageBoxComponent,
  PurchOrderEinvoiceFilterComponent,
  AssignEinvoicePage,
  AssignPurchOrderPage,
  InvoiceLinesPage,
  InvoiceListPage,
  AssetAuditPage,
  AssetCertificationPage,
  AssetCertificationApprovalPage,
  AuthPage,
  ChecklistInstancePage,
  ChecklistTaskPage,
  GetPositionPage,
  GetPositionVendorPage,
  HomePage,
  LoginPage,
  MainPagePage,
  MapTripPage,
  PurchOrderPage,
  PurchOrderConfirmPage,
  PurchOrderLinePage,
  PurchOrderRecordPage,
  PurchOrderToApprovedPage,
  PurchOrderToBeApprovedPage,
  PurchOrderToBeConfirmedPage,
  TripRegisterPage,
  ConfigLinePurchRemissionComponent,
  PurchOrderRemissionFilterComponent,
  PurchRemissionInfoComponent,
  SelectFilterComponent,
  SelectObjectsComponent,
  ShowConversionItemComponent,
  RemissionLinesPage,
  RemissionListPage,
  CheckListInstanceComponent,
  NavBarComponent,
  RatingComponent,
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    DirectivesModule,
    ScrollingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
