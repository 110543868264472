import { AssetAudit } from '../businessCore/AssetAudit';
import { AssetLocation } from '../businessCore/AssetLocation';
import { AssetRequest } from '../businessCore/AssetRequest';
import { Audit } from '../businessCore/Audit';
import { AuditFinding } from '../businessCore/AuditFinding';
import { Bank } from '../businessCore/Bank';
import { Certification } from '../businessCore/Certification';
import { Currency } from '../businessCore/Currency';
import { EntityName } from '../businessCore/EntityName';
import { FixedAsset } from '../businessCore/FixedAsset';
import { DataArea } from '../businessCore/oc-models/DataArea';
import { PaymentTerm } from '../businessCore/PaymentTerm';
import { Worker } from '../businessCore/Worker';

export class Interpreter {
  /**
   * Reorganiza una lista para ordenarla con assets y subassets
   *
   * @param assetsList Una lista de activos
   * @returns Una lista de tipo FixedAsset con los subassets ordenados en su activo correspondiente.
   */
  sortSubAssets(assetsList: FixedAsset[]) {
    for (let i = 0; i < assetsList.length; i++) {
      if (assetsList[i].getMainAssetId()) {
        if (
          assetsList.find(
            (e) =>
              e.assetId == assetsList[i].getMainAssetId() &&
              e.dataAreaId == assetsList[i].getDataAreaId()
          )
        ) {
          assetsList
            .find(
              (e) =>
                e.assetId == assetsList[i].getMainAssetId() &&
                e.dataAreaId == assetsList[i].getDataAreaId()
            )
            .subAssets.push(assetsList[i]);
          assetsList.splice(i, 1);
          i = 0;
        } else {
          assetsList[i].affected = true;
        }
      }
    }
    return assetsList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo FixedAsset.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo FixedAsset con todos los activos por usuario.
   */
  parseAssetEntity(xml: XMLDocument) {
    const tag: string = 'a:';
    let assets: FixedAsset[] = [];
    let assetsXML = xml.getElementsByTagName(tag + 'Asset');
    for (let i = 0; i < assetsXML.length; i++) {
      let assetTemp: FixedAsset = new FixedAsset();
      let assetXml = assetsXML[i];
      assetTemp.assetRecId = Number(
        assetXml.getElementsByTagName(tag + 'assetRecId')[0].textContent
      );
      assetTemp.assetGroup = assetXml
        .getElementsByTagName(tag + 'assetGroup')[0]
        .textContent.toUpperCase();
      assetTemp.assetGroupName = assetXml.getElementsByTagName(
        tag + 'assetGroupName'
      )[0].textContent;
      assetTemp.assetId = assetXml.getElementsByTagName(
        tag + 'assetId'
      )[0].textContent;
      assetTemp.assetName = assetXml.getElementsByTagName(
        tag + 'assetName'
      )[0].textContent;
      assetTemp.barcode = assetXml.getElementsByTagName(
        tag + 'barCode'
      )[0].textContent;
      assetTemp.companyName = assetXml.getElementsByTagName(
        tag + 'companyName'
      )[0].textContent;
      assetTemp.dataAreaId = assetXml
        .getElementsByTagName(tag + 'dataAreaId')[0]
        .textContent.toUpperCase();
      assetTemp.location = assetXml.getElementsByTagName(
        tag + 'location'
      )[0].textContent;
      assetTemp.workerResponsible = assetXml.getElementsByTagName(
        tag + 'workerResponsible'
      )[0].textContent;
      assetTemp.mainAssetId = assetXml.getElementsByTagName(
        tag + 'mainAssetId'
      )[0].textContent;
      assetTemp.pending = Boolean(
        JSON.parse(
          assetXml.getElementsByTagName(tag + 'pending')[0].textContent
        )
      );
      assetTemp.approval = Boolean(
        JSON.parse(
          assetXml.getElementsByTagName(tag + 'approval')[0].textContent
        )
      );
      assetTemp.acquValue = assetXml.getElementsByTagName(
        tag + 'acquValue'
      )[0].textContent;
      assetTemp.accuDepreciation = assetXml.getElementsByTagName(
        tag + 'accuDepreciation'
      )[0].textContent;
      assetTemp.netBookValue = assetXml.getElementsByTagName(
        tag + 'netBookValue'
      )[0].textContent;
      assetTemp.imgURL = assetXml.getElementsByTagName(
        tag + 'imgURL'
      )[0].textContent;

      assetTemp.selected = false;
      assetTemp.expanded = false;
      assetTemp.affected = false;
      assetTemp.subAssets = [];
      assets.push(assetTemp);
    }
    assets = this.sortSubAssets(assets);
    return assets;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo Worker.
   *
   * @returns Una lista de tipo Worker con todos los trabajadores de activos.
   */
  parseWorkerEntity(xml: XMLDocument) {
    const tag: string = 'a:';
    let worker: Worker[] = [];
    let workersXML = xml.getElementsByTagName(tag + 'Worker');
    for (let i = 0; i < workersXML.length; i++) {
      let workerTemp: Worker = new Worker();
      let workerXml = workersXML[i];
      workerTemp.recId = workerXml
        .getElementsByTagName(tag + 'recId')[0]
        .textContent.toUpperCase();
      workerTemp.workerName = workerXml
        .getElementsByTagName(tag + 'workerName')[0]
        .textContent.toUpperCase();
      workerTemp.vatnum = workerXml.getElementsByTagName(
        tag + 'vatnum'
      )[0].textContent;
      workerTemp.personnelNumber = workerXml.getElementsByTagName(
        tag + 'personnelNumber'
      )[0].textContent;
      workerTemp.email = workerXml.getElementsByTagName(
        tag + 'email'
      )[0].textContent;
      worker.push(workerTemp);
    }
    return worker;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetLocation.
   *
   * @returns Una lista de tipo AssetLocation con todos los aposentos de activos.
   */
  parseAssetLocationEntity(xml: XMLDocument) {
    const tag: string = 'a:';
    let assetLocations: AssetLocation[] = [];
    let assetLocationsXML = xml.getElementsByTagName(tag + 'AssetLocation');
    // console.log(assetLocationsXML);
    for (let i = 0; i < assetLocationsXML.length; i++) {
      let assetLocationTemp: AssetLocation = new AssetLocation();
      let assetXml = assetLocationsXML[i];
      assetLocationTemp.location = assetXml.getElementsByTagName(
        tag + 'location'
      )[0].textContent;
      assetLocationTemp.locationName = assetXml.getElementsByTagName(
        tag + 'locationName'
      )[0].textContent;
      assetLocationTemp.locationCatalogueCode = assetXml.getElementsByTagName(
        tag + 'locationCatalogueCode'
      )[0].textContent;
      assetLocationTemp.locationCatalogueDescription =
        assetXml.getElementsByTagName(
          tag + 'locationCatalogueDescription'
        )[0].textContent;
      assetLocations.push(assetLocationTemp);
    }
    return assetLocations;
  }

  /**
   * Reorganiza una lista para ordenarla con assets y subassets
   *
   * @param assetsRequestList Una lista de activos
   * @returns Una lista de tipo FixedAsset con los subassets ordenados en su activo correspondiente.
   */
  sortSubRequest(assetsRequestList: AssetRequest[]) {
    for (let i = 0; i < assetsRequestList.length; i++) {
      if (assetsRequestList[i].getMainAssetId()) {
        if (
          assetsRequestList.find(
            (e) =>
              e.assetId == assetsRequestList[i].getMainAssetId() &&
              e.dataAreaId == assetsRequestList[i].getDataAreaId()
          )
        ) {
          assetsRequestList
            .find(
              (e) =>
                e.assetId == assetsRequestList[i].getMainAssetId() &&
                e.dataAreaId == assetsRequestList[i].getDataAreaId()
            )
            .subRequest.push(assetsRequestList[i]);
          assetsRequestList.splice(i, 1);
          i = 0;
        } else {
          assetsRequestList[i].affected = true;
        }
      }
    }
    return assetsRequestList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo AssetRequest con todos los activos por usuario.
   */
  parseAssetRequestEntity(xml: XMLDocument) {
    const tag: string = 'a:';
    let assetsRequest: AssetRequest[] = [];
    let assetsRequestXML = xml.getElementsByTagName(tag + 'AssetRequest');
    for (let i = 0; i < assetsRequestXML.length; i++) {
      let assetRequestTemp: AssetRequest = new AssetRequest();
      let assetRequestXML = assetsRequestXML[i];
      assetRequestTemp.recId = assetRequestXML.getElementsByTagName(
        tag + 'recId'
      )[0].textContent;
      assetRequestTemp.assetGroup = assetRequestXML
        .getElementsByTagName(tag + 'assetGroup')[0]
        .textContent.toUpperCase();
      assetRequestTemp.assetGroupName = assetRequestXML.getElementsByTagName(
        tag + 'assetGroupName'
      )[0].textContent;
      assetRequestTemp.assetId = assetRequestXML.getElementsByTagName(
        tag + 'assetId'
      )[0].textContent;
      assetRequestTemp.assetName = assetRequestXML.getElementsByTagName(
        tag + 'assetName'
      )[0].textContent;
      assetRequestTemp.barcode = assetRequestXML.getElementsByTagName(
        tag + 'barCode'
      )[0].textContent;
      assetRequestTemp.companyName = assetRequestXML.getElementsByTagName(
        tag + 'companyName'
      )[0].textContent;
      assetRequestTemp.dataAreaId = assetRequestXML
        .getElementsByTagName(tag + 'dataAreaId')[0]
        .textContent.toUpperCase();
      assetRequestTemp.assetType = assetRequestXML.getElementsByTagName(
        tag + 'assetType'
      )[0].textContent;
      assetRequestTemp.locationId = assetRequestXML.getElementsByTagName(
        tag + 'locationId'
      )[0].textContent;
      assetRequestTemp.locationIdNew = assetRequestXML.getElementsByTagName(
        tag + 'locationIdNew'
      )[0].textContent;
      assetRequestTemp.mainAssetId = assetRequestXML.getElementsByTagName(
        tag + 'mainAssetId'
      )[0].textContent;
      assetRequestTemp.worker = assetRequestXML.getElementsByTagName(
        tag + 'worker'
      )[0].textContent;
      assetRequestTemp.workerNew = assetRequestXML.getElementsByTagName(
        tag + 'workerNew'
      )[0].textContent;
      assetRequestTemp.workerName = assetRequestXML.getElementsByTagName(
        tag + 'workerName'
      )[0].textContent;
      assetRequestTemp.workerNameNew = assetRequestXML.getElementsByTagName(
        tag + 'workerNameNew'
      )[0].textContent;
      assetRequestTemp.workerEmail = assetRequestXML.getElementsByTagName(
        tag + 'workerEmail'
      )[0].textContent;
      assetRequestTemp.workerEmailNew = assetRequestXML.getElementsByTagName(
        tag + 'workerEmailNew'
      )[0].textContent;
      assetRequestTemp.transferDate = new Date(
        assetRequestXML.getElementsByTagName(
          tag + 'transferDate'
        )[0].textContent
      );
      assetRequestTemp.selected = false;
      assetRequestTemp.expanded = false;
      assetRequestTemp.affected = false;
      assetRequestTemp.subRequest = [];
      assetsRequest.push(assetRequestTemp);
    }
    assetsRequest = this.sortSubRequest(assetsRequest);
    return assetsRequest;
  }

  /**
   *
   * @param xml
   */
  parseGetComponentsByUserId(xml: XMLDocument) {
    let result = xml.getElementsByTagName('GetComponentsByUserIdResult')[0]
      .textContent;
    return result;
  }

  /**
   *
   * @param xml
   */
  parseGetAssociatedPersonInfo(xml: XMLDocument) {
    let result = xml.getElementsByTagName('GetAssociatedPersonInfoResult')[0]
      .textContent;
    return result;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo DataArea con todos las compañías por usuario.
   */
  parseDataArea(xml: XMLDocument) {
    const tag: string = 'a:';
    let dataAreaList: DataArea[] = [];
    let dataAreaXML = xml.getElementsByTagName(tag + 'Company');
    for (let i = 0; i < dataAreaXML.length; i++) {
      let dataAreaTempXML = dataAreaXML[i];
      let dataAreaId = dataAreaTempXML.getElementsByTagName(tag + 'id')[0]
        .textContent;
      let dataAreaName = dataAreaTempXML
        .getElementsByTagName(tag + 'name')[0]
        .textContent.toUpperCase();
      let dataAreaTemp: DataArea = new DataArea(dataAreaId, dataAreaName);
      dataAreaList.push(dataAreaTemp);
    }
    return dataAreaList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo EntityName con todos los activos por usuario.
   */
  parseEntityName(xml: XMLDocument) {
    const tag: string = 'a:';
    let entityNameList: EntityName[] = [];
    let entityNameListXML = xml.getElementsByTagName(tag + 'EntityName');
    for (let i = 0; i < entityNameListXML.length; i++) {
      let entityNameTemp: EntityName = new EntityName();
      let assetRequestXML = entityNameListXML[i];
      entityNameTemp.name = assetRequestXML.getElementsByTagName(
        tag + 'name'
      )[0].textContent;
      entityNameTemp.firstLastName = assetRequestXML.getElementsByTagName(
        tag + 'firstLastName'
      )[0].textContent;
      entityNameTemp.secondLastName = assetRequestXML.getElementsByTagName(
        tag + 'secondLastName'
      )[0].textContent;
      entityNameList.push(entityNameTemp);
    }
    return entityNameList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo PaymentTerm con todos las compañías por usuario.
   */
  parsePaymentTerm(xml: XMLDocument) {
    const tag: string = 'a:';
    let paymTermList: PaymentTerm[] = [];
    let paymTermXML = xml.getElementsByTagName(tag + 'PaymentTerm');
    for (let i = 0; i < paymTermXML.length; i++) {
      let paymTermTempXML = paymTermXML[i];
      let paymentTermId = paymTermTempXML.getElementsByTagName(
        tag + 'paymTermId'
      )[0].textContent;
      let description = paymTermTempXML
        .getElementsByTagName(tag + 'description')[0]
        .textContent.toUpperCase();
      let paymTermTemp: PaymentTerm = new PaymentTerm(
        paymentTermId,
        description
      );
      paymTermList.push(paymTermTemp);
    }
    return paymTermList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo Bank con todos las compañías por usuario.
   */
  parseBank(xml: XMLDocument) {
    const tag: string = 'a:';
    let bankList: Bank[] = [];
    let bankXML = xml.getElementsByTagName(tag + 'Bank');
    for (let i = 0; i < bankXML.length; i++) {
      let bankTempXML = bankXML[i];
      let bankGroupID = bankTempXML.getElementsByTagName(tag + 'bankGroupID')[0]
        .textContent;
      let name = bankTempXML
        .getElementsByTagName(tag + 'name')[0]
        .textContent.toUpperCase();
      let bankTemp: Bank = new Bank(bankGroupID, name);
      bankList.push(bankTemp);
    }
    return bankList;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo AssetRequest.
   *
   * @param xml Un xml que debe ser parseado.
   * @returns Una lista de tipo Currency con todos las compañías por usuario.
   */
  parseCurrency(xml: XMLDocument) {
    const tag: string = 'a:';
    let currencyList: Currency[] = [];
    let currencyXML = xml.getElementsByTagName(tag + 'CurrencyCode');
    for (let i = 0; i < currencyXML.length; i++) {
      let currencyTempXML = currencyXML[i];
      let currencyCodeId = currencyTempXML.getElementsByTagName(
        tag + 'CurrencyCodeId'
      )[0].textContent;
      let txt = currencyTempXML
        .getElementsByTagName(tag + 'txt')[0]
        .textContent.toUpperCase();
      let currencyTemp: Currency = new Currency(currencyCodeId, txt);
      currencyList.push(currencyTemp);
    }
    return currencyList;
  }

  /**
   *
   * @param xml
   */
  parseValidationCode(xml: XMLDocument) {
    let resultado = xml.getElementsByTagName('GetValidationCodeResult')[0]
      .textContent;
    return resultado;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo
   *
   * @param xml Un xml que debe ser parseado.
   * @returns
   */
  parseCertificationPeriodicity(xml: XMLDocument) {
    let result = xml.getElementsByTagName(
      'GetCertificationPeriodicityResult'
    )[0].textContent;
    return result;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo
   *
   * @param xml Un xml que debe ser parseado.
   * @returns
   */
  parseCheckPeriod(xml: XMLDocument) {
    let result = xml.getElementsByTagName('CheckPeriodResult')[0].textContent;
    return result;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo
   *
   * @param xml Un xml que debe ser parseado.
   * @returns
   */
  parseCreateAssetCertificationPdf(xml: XMLDocument) {
    let result = xml.getElementsByTagName(
      'CreateAssetCertificationPdfResult'
    )[0].textContent;
    return result;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo
   *
   * @param xml Un xml que debe ser parseado.
   * @returns
   */
  parseIsCertPeriodValid(xml: XMLDocument) {
    let result = xml.getElementsByTagName('IsCertPeriodValidResult')[0]
      .textContent;
    return result;
  }

  /**
   * Realiza un parse de un xml a una lista de tipo
   *
   * @param xml Un xml que debe ser parseado.
   * @returns
   */
  parseCanCertificateOutsidePeriod(xml: XMLDocument) {
    let result = xml.getElementsByTagName(
      'CanCertificateOutsidePeriodResult'
    )[0].textContent;
    return result;
  }

  /**
   *
   * @param xml
   * @returns
   */
  parsePendingCertification(xml: XMLDocument) {
    //console.log(xml);
    const tag: string = 'a:';
    let pendCerts: Certification[] = [];
    let pendCertsXML = xml.getElementsByTagName(tag + 'Certification');
    for (let i = 0; i < pendCertsXML.length; i++) {
      let pendCertTemp: Certification = new Certification();
      let pendCertXML = pendCertsXML[i];
      pendCertTemp.certificatedPeriod = pendCertXML.getElementsByTagName(
        tag + 'certificatedPeriod'
      )[0].textContent;
      pendCertTemp.workerName = pendCertXML.getElementsByTagName(
        tag + 'workerName'
      )[0].textContent;
      pendCertTemp.workerDepartmentName = pendCertXML.getElementsByTagName(
        tag + 'workerDepartment'
      )[0].textContent;
      pendCertTemp.workerEmail = pendCertXML.getElementsByTagName(
        tag + 'workerEmail'
      )[0].textContent;
      pendCertTemp.assetQty = Number(
        pendCertXML.getElementsByTagName(tag + 'assetQty')[0].textContent
      );
      pendCertTemp.rewardAmount = pendCertXML.getElementsByTagName(
        tag + 'rewardAmount'
      )[0].textContent;
      pendCertTemp.documentLink = pendCertXML.getElementsByTagName(
        tag + 'documentLink'
      )[0].textContent;
      pendCertTemp.currency = pendCertXML.getElementsByTagName(
        tag + 'currency'
      )[0].textContent;
      pendCertTemp.petitionDate = new Date(
        pendCertXML.getElementsByTagName(tag + 'petitionDate')[0].textContent
      );
      pendCertTemp.periodStart = new Date(
        pendCertXML.getElementsByTagName(tag + 'periodStart')[0].textContent
      );
      pendCertTemp.recordRecId = pendCertXML.getElementsByTagName(
        tag + 'recordRecId'
      )[0].textContent;
      pendCerts.push(pendCertTemp);
    }
    return pendCerts;
  }

  /**
   *
   * @param xml
   * @returns
   */
  parseAuditFinding(xml: XMLDocument) {
    const tag: string = 'a:';
    let auditFindingList: AuditFinding[] = [];
    let auditFindingXML = xml.getElementsByTagName(tag + 'AuditFinding');
    for (let i = 0; i < auditFindingXML.length; i++) {
      let auditFindingTempXML = auditFindingXML[i];
      let auditFindingRecId = Number(
        auditFindingTempXML.getElementsByTagName(tag + 'auditFindingRecId')[0]
          .textContent
      );
      let description = auditFindingTempXML.getElementsByTagName(
        tag + 'description'
      )[0].textContent;
      let finding = auditFindingTempXML.getElementsByTagName(tag + 'finding')[0]
        .textContent;
      let nonConformity = Boolean(
        auditFindingTempXML.getElementsByTagName(tag + 'nonConformity')[0]
          .textContent
      );
      let auditFindingTemp: AuditFinding = new AuditFinding(
        auditFindingRecId,
        finding,
        description,
        nonConformity,
        false
      );
      auditFindingList.push(auditFindingTemp);
    }
    return auditFindingList;
  }

  /**
   *
   * @param xml
   * @returns
   */
  parseAudit(xml: XMLDocument) {
    const tag: string = 'a:';
    const tagB: string = 'b:';

    let auditList: Audit[] = [];
    let auditXML = xml.getElementsByTagName(tag + 'Audit');
    for (let i = 0; i < auditXML.length; i++) {
      let auditTempXML = auditXML[i];
      let auditRecId = Number(
        auditTempXML.getElementsByTagName(tag + 'auditRecId')[0].textContent
      );
      let auditor = Number(
        auditTempXML.getElementsByTagName(tag + 'auditor')[0].textContent
      );
      let petitioner = Number(
        auditTempXML.getElementsByTagName(tag + 'petitioner')[0].textContent
      );
      let auditDate = auditTempXML.getElementsByTagName(tag + 'auditDate')[0]
        .textContent;
      let auditedWorker = Number(
        auditTempXML.getElementsByTagName(tag + 'auditedWorker')[0].textContent
      );
      let auditedWorkerName = auditTempXML.getElementsByTagName(
        tag + 'auditedWorkerName'
      )[0].textContent;
      let auditedWorkerCompany = auditTempXML.getElementsByTagName(
        tag + 'auditedWorkerCompany'
      )[0].textContent;
      let auditedWorkerDepartment = auditTempXML.getElementsByTagName(
        tag + 'auditedWorkerDepartment'
      )[0].textContent;
      let auditStatus = Number(
        auditTempXML.getElementsByTagName(tag + 'auditStatus')[0].textContent
      );

      let assetAuditList: AssetAudit[] = [];
      let assetAuditXML = auditTempXML.getElementsByTagName(tag + 'AssetAudit');
      for (let j = 0; j < assetAuditXML.length; j++) {
        let assetAuditTempXML = assetAuditXML[j];
        let assetRecId = Number(
          assetAuditTempXML.getElementsByTagName(tag + 'assetRecId')[0]
            .textContent
        );
        let comment = assetAuditTempXML.getElementsByTagName(tag + 'comment')[0]
          .textContent;
        let assigned = Number(
          assetAuditTempXML.getElementsByTagName(tag + 'assigned')[0]
            .textContent
        );

        let auditFindingRecIds: number[] = [];
        let auditFindingRecIdsXML = assetAuditTempXML.getElementsByTagName(
          tagB + 'long'
        );
        for (let k = 0; k < auditFindingRecIdsXML.length; k++) {
          let auditFindingRecId = auditFindingRecIdsXML[k];
          auditFindingRecIds.push(Number(auditFindingRecId.innerHTML));
        }
        let assetAuditTemp: AssetAudit = new AssetAudit(
          assetRecId,
          comment,
          [],
          auditFindingRecIds,
          assigned
        );
        assetAuditList.push(assetAuditTemp);
      }
      let auditTemp: Audit = new Audit(
        auditRecId,
        auditor,
        petitioner,
        auditedWorker,
        auditedWorkerName,
        auditedWorkerCompany,
        auditedWorkerDepartment,
        auditStatus,
        auditDate,
        assetAuditList
      );
      auditList.push(auditTemp);
    }
    return auditList;
  }
}
