import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Money } from '../../GeneralUtilis/money';
import { OrderHeader } from '../../businessCore/OrderHeader';
import { OrderLine } from '../../businessCore/OrderLine';

@Component({
  selector: 'app-prev-shopping-car',
  templateUrl: './prev-shopping-car.component.html',
  styleUrls: ['./prev-shopping-car.component.scss'],
})
export class PrevShoppingCarComponent {
  ordHerder: OrderHeader = new OrderHeader();
  totalOrder: number = 0;
  totalUnits: number = 0;
  showBtn: boolean = false;
  promoId: string = '';
  pSequence: number = -1;

  constructor(
    private params: NavParams,
    public popoverCtrl: PopoverController
  ) {
    this.ordHerder.ordLine = this.params.get('tmpShoppingCar');
    this.showBtn = this.params.get('showBtn');
    this.promoId = this.params.get('promoId');
    this.pSequence = this.params.get('pSequence');

    //DEBUG
    /*let item = new OrderLine();
    item.promoId = '111';
    item.PromoSequence = 1;
    this.ordHerder.ordLine = [item];
    this.showBtn = true;
    this.promoId = '111';
    this.pSequence = 1;
    */
    //DEBUG
    this.reIndexCar();
  }

  reIndexCar() {
    let count: number = 1;
    this.totalOrder = 0;
    this.ordHerder.totalSale = 0;
    this.totalUnits = 0;
    this.ordHerder.ordLine.forEach((item) => {
      item.lineId = count;
      if (item.promoId != '') {
        this.totalOrder += item.lnAmnt;
        this.ordHerder.totalSale += item.lnAmnt;
      } else {
        this.totalOrder = this.totalOrder + item.totalSale;
        this.ordHerder.totalSale = this.ordHerder.totalSale + item.totalSale;
      }
      this.totalUnits += item.salesQuantity;
      count++;
    });
  }

  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para setear el precio unitario de un producto + el impuesto correspondiente
   * @param item Línea de la orden a la que se le calcula el precio con impuesto por cada unidad
   */
  setSalePrice(item: OrderLine) {
    return this.setCurrency(item.salePrice + item.tax);
  }

  dismiss(p: number) {
    if (p == 0) {
      this.popoverCtrl.dismiss();
    } else {
      this.popoverCtrl.dismiss('OK');
    }
  }
}
