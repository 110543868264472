import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import OneSignal from 'onesignal-cordova-plugin';

@Injectable()
export class PushNotificationsProvider {
  public dispositiveID: string = '';

  constructor(private storage: StorageService) {}

  async configuracionInicial() {
    const oneSignal = OneSignal as any; // Cast OneSignal to any to bypass type checking

    oneSignal.setAppId('4b2937e8-f5c2-4821-bdba-c9893e002715');

    // Do something when a notification is opened
    oneSignal.setNotificationOpenedHandler((noti: any) => {
      console.log('Notificación Abierta', noti);
    });

    // Prompts the user for notification permissions
    oneSignal.promptForPushNotificationsWithUserResponse(function (accepted: boolean) {
      console.log('User accepted notifications: ' + accepted);
    });

    // Get the UserId
    oneSignal.getDeviceState((response: any) => {
      this.storage.set('dispositiveId', response.userId);
      this.dispositiveID = response.userId;
    });

    return this.dispositiveID;
  }
}
