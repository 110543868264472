<ion-header>
  <ion-toolbar>
    <!-- <ion-title>search-customer</ion-title> -->
    <ion-searchbar
      placeholder="Código o Nombre"
      [(ngModel)]="claveBusqueda"
      (ionChange)="setFilteredObjects()"
      (keyup)="setFilteredObjects()"
      showCancelButton="focus"
    >
    </ion-searchbar>
    <ion-buttons slot="end">
      <ion-button (click)="closePopOver(null)">
        <!-- <ion-icon name="close"></ion-icon>-->
        <i class="fas fa-times" style="width: 30px"></i>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="!show">
  <ion-list>
    <ion-item *ngFor="let obj of objectFilterdList" (click)="closePopOver(obj)">
      <ion-label>
        <h2>{{ obj.getId() }}</h2>
        <p *ngIf="showId(obj)">{{ obj.getName() }}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-content *ngIf="show">
  <ion-list>
    <ion-item *ngFor="let obj of objectFilterdList" (click)="closePopOver(obj)">
      <ion-label>
        <h2>{{ obj.getName() }}</h2>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
