<ion-content class="ion-padding" style="padding: 5px">
  <h4 class="title">Seleccione la Información Deseada</h4>

  <div style="text-align: center" *ngIf="loadingInfo">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando información</h6>
  </div>

  <!-- PERIODOS DE ENTREGAS -->
  <!-- <h6 margin-bottom class="subTitle">Rango de tiempo</h6>
  <div margin-bottom class="subTitleDesc">Seleccione el rango de tiempo deseado para que se le entregue el pedido.</div>

  <div *ngFor="let time of timeRange">
    <div class="flexContainer" lines='none' style="padding: 5px;" (click)="checkTimeItem(time)">
      <ion-checkbox class="chkBox" [disabled]="true" [(ngModel)]="time.checked" (click)="checkTimeItem(time)"></ion-checkbox>
      <div class="flexBlockContainer infoContainer">
        <div class="contactNameTitle">Período de tiempo:</div>
        <div class="contactDescription">{{time.desc}}</div>
      </div>
    </div>
  </div> -->

  <!-- DIRECCIONES DE ENTERGA -->
  <!-- ngFor entre todas las direcciones -->
  <h6 margin-bottom class="subTitle">Dirección de entrega</h6>
  <div margin-bottom class="subTitleDesc">
    Seleccione la dirección de entrega a la cual deberá llegar el pedido.
  </div>
  <div *ngFor="let addressData of customerAddress">
    <div
      class="flexContainer"
      lines="none"
      style="padding: 5px"
      (click)="checkItem(addressData)"
    >
      <ion-checkbox
        class="chkBox"
        [disabled]="true"
        [(ngModel)]="addressData.checked"
        (click)="checkItem(addressData)"
      ></ion-checkbox>
      <div class="flexBlockContainer infoContainer">
        <div class="contactNameTitle">Dirección de entrega:</div>
        <div class="contactDescription">{{ addressData.address.Address }}</div>
      </div>
    </div>
  </div>

  <div class="flexContainer">
    <ion-button
      expand="block"
      type="submit"
      shape="round"
      (click)="setData()"
      class="submit-btn btnCss"
      style="width: 100%; font-size: 12px; font-family: sans-serif"
    >
      <i class="fas fa-check" style="margin-right: 10px"></i>Finalizar
    </ion-button>
  </div>
</ion-content>
