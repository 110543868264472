import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LoadingController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { InventoryItem } from 'src/app/businessCore/InventoryItem';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { RegisterData } from 'src/app/businessCore/RegisterData';
import { Money } from 'src/app/GeneralUtilis/money';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { webServiceProvider } from 'src/app/provider/webServiceProvider';
//Environment
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { environment as ENV } from '../../../environments/environment';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { RegisterContactNumberComponent } from '../register-contact-number/register-contact-number.component';

import {
  GlobalEventsService,
  GlobalEvents,
} from 'src/app/utils/globalEvents.service';

@Component({
  selector: 'app-families',
  templateUrl: './families.component.html',
  styleUrls: ['./families.component.scss'],
})
export class FamiliesComponent {
  itemInfo: InventoryItem[] = [];
  itemInfoFMCM: InventoryItem[] = [];

  //breadcrumbs: any[]

  public families_info: string[] = [];

  shoppingCar: OrderLine[] = [];
  priceList: string = 'HX-VIP';
  loadingProducts: boolean = true;

  public registerData: RegisterData = new RegisterData();

  constructor(
    private storage: StorageService,
    private toast: ToastController,
    private _apiProvider: webServiceProvider,
    private _loadingCtrl: LoadingController,
    private ShoopingCartUtils: ShoopingCartUtils,
    public popoverCtrl: PopoverController,
    private navElement: NavigationService,
    private events: GlobalEventsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loadData();
    this.events.getGlobalEvent(GlobalEvents.UPDATE_PRODUCTS).subscribe(() => {
      this.loadData();
    });

    // DEBUG //
    //this.addPhoneNumber();
    //DEBUG
  }

  loadData() {
    this.storage.get('register_data').then((register_data) => {
      if (register_data != null) {
        this.registerData = JSON.parse(register_data) as RegisterData;
      }

      this.storage.get('cleanStorage').then((info) => {
        if (info == null) {
          this.storage.set('cleanStorage', JSON.stringify(true));
          this.storage.set('productos', null);
          this.storage.set('ultimaActualizacionProductos', null);
        }

        this.storage.get('price_group_id').then((data) => {
          if (data != null) {
            this.priceList = JSON.parse(data) as string;

            if ((JSON.parse(data) as string) == 'FU') {
              this.priceList = 'HX-VIP';
            }

            this.reloadProducts();
          } else {
            this.priceList = 'HX-VIP';
            this.reloadProducts();
          }
        });
      });
    });
  }

  /**
   *
   */
  async addPhoneNumber() {
    const modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RegisterContactNumberComponent,
      componentProps: {
        test_data_1: 1,
        test_data_2: 2,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      //console.log('RES', res);
      //console.log('DATA', data);
    });
  }

  /**
   * Checks if a FMCM site (Homex) exists.
   * Both the InventSiteId and SiteName have to exists
   * in the ENV array
   * @param InventSiteId InventSiteId to check
   * @param SiteName Site name to check
   */
  isActiveSiteFMCM(InventSiteId: string, SiteName: string) {
    return ENV.FMCM_SITES.find(
      (site) => site.InventSiteId == InventSiteId && site.SiteName == SiteName
    )
      ? true
      : false;
  }

  getRegisterData() {
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        const register_data = JSON.parse(data) as RegisterData;
        this.registerData = register_data;
      }
    });
  }

  displayShoppingCar() {
    this.getRegisterData();
    this.ShoopingCartUtils.displayShoppingCart();
  }

  setTotalSale(Amount: number, Taxes: number) {
    if (Amount && Taxes) {
      let data =
        Number(Amount.toString().replace(',', '.')) +
        Number(Taxes.toString().replace(',', '.'));
      return this.setCurrency(data);
    } else {
      return this.setCurrency(0);
    }
  }

  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  getProductsFMCM(loading: any) {
    //FMCM products
    this._apiProvider
      .getProductsFMCM(
        this.priceList,
        this.registerData ? this.registerData.customerId : ''
      )
      .subscribe((data: any) => {
        this.itemInfoFMCM = data as InventoryItem[];

        this.loadingProducts = false;

        this.itemInfoFMCM.forEach((item) => {
          item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
        });

        this.storage.set('productosFMCM', JSON.stringify(this.itemInfoFMCM));
        this.storage.set('ultimaActualizacionProductos', new Date());
        this.getAvailability();

        this.setItemsInfo();

        // success
        loading.dismiss(null);
      });
  }

  reloadProductsFromLocalStorageFMCM(loading: any) {
    this.storage.get('productosFMCM').then((data) => {
      this.itemInfoFMCM = JSON.parse(data);
      this.loadingProducts = false;

      this.itemInfoFMCM.forEach((item) => {
        item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
      });

      this.storage.set('productosFMCM', JSON.stringify(this.itemInfoFMCM));
      this.storage.set('ultimaActualizacionProductos', new Date());
      this.getAvailability();

      this.setItemsInfo();

      // success
      loading.dismiss(null);
    });
  }

  async reloadProducts() {
    let loading = await this._loadingCtrl.create({
      message: 'Cargando información de productos...',
    });
    loading.present(); //nav instance of NavController

    this.storage.get('ultimaActualizacionProductos').then((fecha) => {
      this.storage.get('productos').then((prods: any) => {
        let prodsList: InventoryItem[] = JSON.parse(prods);
        if (
          prods == null ||
          prodsList.length === 0 ||
          fecha == null ||
          new Date().getTime() - new Date(fecha).getTime() > 10800000
        ) {
          //3525000   => 1 hora

          //CMER products
          this._apiProvider
            .getProducts(this.priceList)
            .subscribe((data: any) => {
              this.itemInfo = data as InventoryItem[];
              if (this.itemInfo.length > 0) {
                this.loadingProducts = false;

                this.itemInfo.forEach((item) => {
                  item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
                });

                this.storage.set(
                  'productosCMER',
                  JSON.stringify(this.itemInfo)
                );
                this.storage.set('ultimaActualizacionProductos', new Date());

                if (this.registerData != null) {
                  this._apiProvider
                    .checkIfCustomerExistsInComp(
                      this.registerData.id_card,
                      'fmcm'
                    )
                    .subscribe((data: any) => {
                      //console.log(data);
                      if (data) {
                        //If client exists in FMCM
                        this.getProductsFMCM(loading);
                      } else {
                        //If the client does not exists in FMCM
                        this.storage.remove('carrito').then((data) => {
                          this.shoppingCar = [];
                          this.itemInfoFMCM = [];

                          this.loadingProducts = false;

                          this.storage.set(
                            'productosFMCM',
                            JSON.stringify(this.itemInfoFMCM)
                          );
                          this.storage.set(
                            'ultimaActualizacionProductos',
                            new Date()
                          );
                          this.getAvailability();

                          this.setItemsInfo();

                          // success
                          loading.dismiss(null);
                        });
                      }
                    });
                } else {
                  this.getProductsFMCM(loading);
                }
              } else {
                loading.dismiss('step2');
              }
            });
        } else {
          //If it is not necessary to get the products again from the db
          this.loadingProducts = false;

          this.storage.get('productosCMER').then((data) => {
            this.itemInfo = JSON.parse(data);
            if (this.itemInfo == null) {
              this.itemInfo = [];
            }
            if (this.itemInfo.length > 0) {
              this.loadingProducts = false;

              this.itemInfo.forEach((item) => {
                item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
              });

              this.storage.set('productosCMER', JSON.stringify(this.itemInfo));
              this.storage.set('ultimaActualizacionProductos', new Date());

              if (this.registerData != null) {
                this._apiProvider
                  .checkIfCustomerExistsInComp(
                    this.registerData.id_card,
                    'fmcm'
                  )
                  .subscribe((data: any) => {
                    //console.log(data);
                    if (data) {
                      //If client exists in FMCM
                      this.reloadProductsFromLocalStorageFMCM(loading);
                    } else {
                      //If the client does not exists in FMCM
                      this.itemInfoFMCM = [];

                      this.loadingProducts = false;

                      this.storage.set(
                        'productosFMCM',
                        JSON.stringify(this.itemInfoFMCM)
                      );
                      this.storage.set(
                        'ultimaActualizacionProductos',
                        new Date()
                      );
                      this.getAvailability();

                      this.setItemsInfo();

                      // success
                      loading.dismiss(null);
                    }
                  });
              } else {
                this.reloadProductsFromLocalStorageFMCM(loading);
              }
            } else {
              loading.dismiss('step2');
            }

            // this.itemInfo.forEach(item => {
            //   item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
            // });
          });

          this.storage.get('carrito').then((data) => {
            this.shoppingCar = JSON.parse(data);
            if (this.shoppingCar == null) {
              this.shoppingCar = [];
            }
          });

          // success
          loading.dismiss(null);
        }
      });
    });

    loading.onDidDismiss().then((res) => {
      let data = res.data;
      if (data && data != null) {
        this.reloadProducts2(2);
      } else {
        this.setItemsInfo();
      }
    });
  }

  getSiteName(InventSiteId: string) {
    let test = ENV.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    });
    return ENV.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }

  getAvailability() {
    let itemInfoAvailability: InventoryItem[] = [...this.itemInfo];
    let itemInfoFMCMCopy: InventoryItem[] = [...this.itemInfoFMCM];

    for (let i = 0, i_len = itemInfoAvailability.length; i < i_len; i++) {
      if (!itemInfoAvailability[i].SiteName) {
        itemInfoAvailability[i].SiteNameList = [];
        itemInfoAvailability[i].InventSiteIdList = [];

        itemInfoAvailability[i].SiteName = 'A domicilio';
        itemInfoAvailability[i].SiteNameList.push(
          itemInfoAvailability[i].SiteName
        );
        itemInfoAvailability[i].InventSiteIdList.push('CMER');
      }
      this.itemInfoFMCM = [...itemInfoFMCMCopy];
      for (let j = 0, j_len = this.itemInfoFMCM.length; j < j_len; j++) {
        if (
          itemInfoAvailability[i].ItemId == this.itemInfoFMCM[j].ItemId &&
          itemInfoAvailability[i].UnitId == this.itemInfoFMCM[j].UnitId &&
          !itemInfoAvailability[i].InventSiteIdList.find(
            (id) => id == this.itemInfoFMCM[j].InventSiteId
          )
        ) {
          if (
            this.isActiveSiteFMCM(
              this.itemInfoFMCM[j].InventSiteId,
              this.itemInfoFMCM[j].SiteName
            )
          ) {
            const index = itemInfoFMCMCopy.indexOf(this.itemInfoFMCM[j]);
            if (index > -1) {
              itemInfoFMCMCopy.splice(index, 1);
            }
            itemInfoAvailability[i].SiteName = itemInfoAvailability[
              i
            ].SiteName.concat(
              ', ' + this.getSiteName(this.itemInfoFMCM[j].InventSiteId)
            );
            itemInfoAvailability[i].InventSiteIdList.push(
              this.itemInfoFMCM[j].InventSiteId
            );
            itemInfoAvailability[i].SiteNameList.push(
              this.itemInfoFMCM[j].SiteName
            );
          }
        }
      }
    }

    this.itemInfoFMCM = [...itemInfoFMCMCopy];
    for (let i2 = 0, len2 = this.itemInfoFMCM.length; i2 < len2; i2++) {
      let flagExists: boolean = false;
      for (let item of itemInfoAvailability) {
        if (
          item.ItemId == this.itemInfoFMCM[i2].ItemId &&
          item.UnitId == this.itemInfoFMCM[i2].UnitId &&
          !item.InventSiteIdList.find(
            (id) => id == this.itemInfoFMCM[i2].InventSiteId
          )
        ) {
          flagExists = true;

          if (
            this.isActiveSiteFMCM(
              this.itemInfoFMCM[i2].InventSiteId,
              this.itemInfoFMCM[i2].SiteName
            )
          ) {
            item.SiteName = item.SiteName.concat(
              ', ' + this.getSiteName(this.itemInfoFMCM[i2].InventSiteId)
            );
            item.InventSiteIdList.push(this.itemInfoFMCM[i2].InventSiteId);
            item.SiteNameList.push(this.itemInfoFMCM[i2].SiteName);

            const index = itemInfoFMCMCopy.indexOf(this.itemInfoFMCM[i2]);
            if (index > -1) {
              itemInfoFMCMCopy.splice(index, 1);
            }
          }
        }
      }

      if (
        !flagExists &&
        this.isActiveSiteFMCM(
          this.itemInfoFMCM[i2].InventSiteId,
          this.itemInfoFMCM[i2].SiteName
        )
      ) {
        this.itemInfoFMCM[i2].SiteName = this.getSiteName(
          this.itemInfoFMCM[i2].InventSiteId
        ); //Change name -> Remove 'Site'
        this.itemInfoFMCM[i2].SiteNameList = [];
        this.itemInfoFMCM[i2].InventSiteIdList = [];
        this.itemInfoFMCM[i2].InventSiteIdList.push(
          this.itemInfoFMCM[i2].InventSiteId
        );
        this.itemInfoFMCM[i2].SiteNameList.push(this.itemInfoFMCM[i2].SiteName);
        itemInfoAvailability.push(this.itemInfoFMCM[i2]);
      }
    }

    this.itemInfo = itemInfoAvailability as InventoryItem[];
    this.storage.set('productos', JSON.stringify(itemInfoAvailability));
    this.storage.set('ultimaActualizacionProductos', new Date());
  }

  async reloadProducts2(counter: number) {
    if (counter > 3) {
      this.loadingProducts = false;
      let toast = await this.toast.create({
        message:
          'Lo sentimos, en este momento no se pudieron consultar los productos.',
        buttons: ['Cerrar'],
        duration: 5000,
        position: 'bottom',
      });

      toast.present();

      this.setItemsInfo();
    } else {
      /*
      let loading = this._loadingCtrl.create({ content: 'Intentando cargar los productos: ' + counter + '...' });
      loading.present(); //nav instance of NavController
      */
      this.storage.get('ultimaActualizacionProductos').then((fecha) => {
        this.storage.get('productos').then((prods) => {
          if (
            prods == null ||
            (JSON.parse(prods) as InventoryItem[]).length == 0 ||
            fecha == null ||
            new Date().getTime() - new Date(fecha).getTime() > 10800000
          ) {
            //3525000   => 1 hora
            this._apiProvider
              .getProducts(this.priceList)
              .subscribe((data: any) => {
                this.itemInfo = data as InventoryItem[];
                if (this.itemInfo.length > 0) {
                  this.loadingProducts = false;

                  this.itemInfo.forEach((item) => {
                    item.FinalPrice = this.setTotalSale(
                      item.Amount,
                      item.taxes
                    );
                  });

                  this.storage.set('productos', JSON.stringify(this.itemInfo));
                  this.storage.set('ultimaActualizacionProductos', new Date());
                  this.setItemsInfo();
                } else {
                  this.reloadProducts2(counter + 1);
                }
              });
          } else {
            this.loadingProducts = false;
            this.storage.get('productos').then((data) => {
              this.itemInfo = JSON.parse(data);
              if (this.itemInfo == null) {
                this.itemInfo = [];
              }
              this.itemInfo.forEach((item) => {
                item.Icon = 'arrow-down';
                item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
              });
            });

            this.storage.get('carrito').then((data) => {
              this.shoppingCar = JSON.parse(data);
              if (this.shoppingCar == null) {
                this.shoppingCar = [];
              }
            });

            this.setItemsInfo();
          }
        });
      });
    }
  }

  replaceUnderScore(name: string) {
    return name.replace(/_/g, ' ');
  }

  setItemsInfo() {
    // this.storage.get('productos').then((data) => {
    // this.itemInfo = JSON.parse(data);
    if (this.itemInfo == null) {
      this.itemInfo = [];
    } else {
      this.getFamilies();
    }
    // });
  }

  getFamilies() {
    this.itemInfo.forEach((item) => {
      item.Icon = 'arrow-down';
      if (
        this.families_info.indexOf(
          item.Hierarchy1.toUpperCase().replace(/ /g, '_')
        ) === -1
      ) {
        this.families_info.push(
          item.Hierarchy1.toUpperCase().replace(/ /g, '_')
        );
      }
    });
  }

  async nextStep(family: string) {
    let imgUrl =
      'https://serviceplan-api.mercasacr.com/images/Products/Families/' +
      family +
      '_BG.JPG';
    family = family.replace(/_/g, ' ');

    this.storage.get('productos').then(async (data) => {
      this.itemInfo = JSON.parse(data);
      if (this.itemInfo == null) {
        this.itemInfo = [];
      }

      // Se filtran los items que cumplen con la Hierarchy1 = family
      this.itemInfo = this.itemInfo.filter(
        (value: InventoryItem) =>
          value.Hierarchy1.toUpperCase() === family.toUpperCase()
      );
      if (this.itemInfo != null && this.itemInfo.length > 0) {
        this.navElement.navigateTo(
          NavigationRoutes.FamiliesSelectHierarchyComponent,
          {
            inventoryItem: this.itemInfo,
            imgUrl: imgUrl,
          }
        );
      } else {
        let toast = await this.toast.create({
          message: 'Actualmente no hay productos en la familia seleccionada',
          duration: 2000,
          buttons: ['Ok'],
        });
        toast.present();
      }
    });
  }
}
