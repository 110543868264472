<app-nav-bar
  *ngIf="showHeader"
  [title]="'Tutorial'"
  [start]="'GoBack'"
></app-nav-bar>

<ion-content center>
  <video #tutorialVideo style="width: 100%" muted>
    <source
      src="https://serviceplan-api.mercasacr.com/videos/tutorial/t3.mp4"
      type="video/mp4"
    />
  </video>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button *ngIf="showPlayButton" expand="block" (click)="playVideo()">
      Visualizar
      <ion-icon name="play"></ion-icon>
    </ion-button>
    <ion-button *ngIf="!showPlayButton" expand="block" (click)="nextStep()">
      Saltar
      <ion-icon name="arrow-forward"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-footer>
