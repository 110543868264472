// export interface AssociatedInterface{
//     codPerson: string;
//     name: string;
//     reference: 0;
//     roleHazId: string;
//     roleHazName: string;
//     vatNum: string;
// }

// export class AssociatedClass implements AssociatedInterface{

//     codPerson: string;
//     name: string;
//     reference: 0;
//     roleHazId: string;
//     roleHazName: string;
//     vatNum: string;
//     roleHazIdList: string[];

//     constructor(pCodPerson, pName, pReference, pRoleHazId, pRoleHazName, pVatnum){
//         this.codPerson = pCodPerson;
//         this.name = pName;
//         this.reference = pReference;
//         this.roleHazId = pRoleHazId;
//         this.roleHazName = pRoleHazName;
//         this.vatNum = pVatnum;
//     }

//     public getRoleHazName() {
//         return this.roleHazName;
//     }

// }

export class AssociatedInstance {
  codPerson: string;
  name: string;
  reference: 0;
  roleHazId: string[];
  roleHazName: string;
  vatNum: string;

  constructor(
    pCodPerson,
    pName,
    pReference,
    pRoleHazId,
    pRoleHazName,
    pVatnum
  ) {
    this.codPerson = pCodPerson;
    this.name = pName;
    this.reference = pReference;
    this.roleHazId = pRoleHazId;
    this.roleHazName = pRoleHazName;
    this.vatNum = pVatnum;
  }

  public getCodPerson() {
    return this.codPerson;
  }

  public getName() {
    return this.name;
  }

  public getVatnum() {
    return this.vatNum;
  }

  public getRoleHazIdList() {
    return this.roleHazId;
  }
}
