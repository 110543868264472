<div class="box">
  <div class="barTitle">
    <h5
      style="
        font-size: 1rem;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
      "
    >
      MÁS INFORMACIÓN
    </h5>
  </div>
  <div class="content">
    <ion-list>
      <ion-item (click)="openCloseDimensionesFinancieras()">
        <ion-label><h1 class="myH1">Dimensiones Financieras</h1></ion-label>
        <ion-icon item-end name="add" *ngIf="!dimensionesFinShow"></ion-icon>
        <ion-icon item-end name="remove" *ngIf="dimensionesFinShow"></ion-icon>
      </ion-item>
      <div class="divCantProd" *ngIf="dimensionesFinShow">
        <ion-item (click)="emitSelectedBar(6)">
          <ion-label>
            <h2>A_Departamento</h2>
            <p>{{ aDepartamentoSelected.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
        <ion-item (click)="emitSelectedBar(7)">
          <ion-label>
            <h2>B_CentroCosto</h2>
            <p>{{ bCentroCostoSelected.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
        <ion-item (click)="emitSelectedBar(8)">
          <ion-label>
            <h2>C_Proposito</h2>
            <p>{{ cPropositoSelected.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
      </div>

      <ion-item (click)="openCloseInternacionales()">
        <ion-label><h1 class="myH1">Internacionales</h1></ion-label>
        <ion-icon item-end name="add" *ngIf="!internacionalesShow"></ion-icon>
        <ion-icon item-end name="remove" *ngIf="internacionalesShow"></ion-icon>
      </ion-item>
      <div class="divCantProd" *ngIf="internacionalesShow">
        <ion-item (click)="openCloseCont()">
          <ion-label><h1 class="myH2">Contenedor</h1></ion-label>
          <ion-icon
            item-end
            name="add"
            *ngIf="!contenedorShow"
            class="ion-icon2"
          ></ion-icon>
          <ion-icon
            item-end
            name="remove"
            *ngIf="contenedorShow"
            class="ion-icon2"
          ></ion-icon>
        </ion-item>
        <div class="divCantProd" *ngIf="contenedorShow">
          <app-input-number
            [titulo]="'Cantidad de Contenedores:'"
            (getNumber)="getNumberContenedor($event)"
            [cantidadObjeto]="cantidadContenedor ? cantidadContenedor : 0"
            [cantEdit]="cantEdit"
          ></app-input-number>

          <app-input-number
            [titulo]="'Cantidad TEUS:   '"
            (getNumber)="getNumberTEUS($event)"
            [cantidadObjeto]="cantidadTEUS ? cantidadTEUS : 0"
            [cantEdit]="cantEdit"
          ></app-input-number>

          <ion-item (click)="emitSelectedBar(5)">
            <ion-label>
              <h2>Tipo de contenedor</h2>
              <p>{{ typeContainer.getName() }}</p>
            </ion-label>
            <ion-icon
              *ngIf="cantEdit"
              name="caret-down-outline"
              item-end
            ></ion-icon>
          </ion-item>
          <app-input-number
            [titulo]="'Días libres:   '"
            (getNumber)="getNumberDLibres($event)"
            [cantidadObjeto]="cantidadLibres ? cantidadLibres : 0"
            [cantEdit]="cantEdit"
          ></app-input-number>
        </div>

        <ion-item (click)="emitSelectedBar(4)">
          <ion-label>
            <h2>Estado Pedido</h2>
            <p>{{ estadoPedido.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>

        <ion-item (click)="openCloseFechas()">
          <ion-label><h1 class="myH2">Fechas</h1></ion-label>
          <ion-icon
            item-end
            name="add"
            *ngIf="!fechasShow"
            class="ion-icon2"
          ></ion-icon>
          <ion-icon
            item-end
            name="remove"
            *ngIf="fechasShow"
            class="ion-icon2"
          ></ion-icon>
        </ion-item>
        <div class="divCantProd" *ngIf="fechasShow">
          <ion-item class="timer" *ngIf="maxDate">
            <ion-label class="colorLabel"><h2>Salida Solicitada</h2></ion-label>

            <ion-datetime-button datetime="datetime"></ion-datetime-button>

            <ion-modal [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  id="datetime"
                  presentation="date"
                  [showDefaultButtons]="true"
                  [disabled]="!cantEdit"
                  displayFormat="DD/MM/YYYY"
                  [(ngModel)]="salidaSolicDayS"
                  placeholder="Seleccione la fecha"
                  doneText="Aceptar"
                  cancelText="Cancelar"
                  (ionChange)="emitDays()"
                  [max]="maxDate"
                ></ion-datetime>
              </ng-template>
            </ion-modal>
          </ion-item>
          <ion-item class="timer" *ngIf="maxDate">
            <ion-label><h2>Salida arribo</h2></ion-label>

            <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>

            <ion-modal [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  id="datetimeEnd"
                  presentation="date"
                  [showDefaultButtons]="true"
                  [disabled]="!cantEdit"
                  displayFormat="DD/MM/YYYY"
                  [(ngModel)]="salidaArriboDayS"
                  placeholder="Seleccione la fecha"
                  doneText="Aceptar"
                  cancelText="Cancelar"
                  (ionChange)="emitDays()"
                  [max]="maxDate"
                ></ion-datetime>
              </ng-template>
            </ion-modal>
          </ion-item>
          <ion-item class="timer" *ngIf="maxDate">
            <ion-label><h2>Solicitada llegada bodega</h2></ion-label>

            <ion-datetime-button
              datetime="datetimeArrive"
            ></ion-datetime-button>

            <ion-modal [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  id="datetimeArrive"
                  presentation="date"
                  [showDefaultButtons]="true"
                  [disabled]="!cantEdit"
                  displayFormat="DD/MM/YYYY"
                  [(ngModel)]="soliLlegadaBodDayS"
                  placeholder="Seleccione la fecha"
                  doneText="Aceptar"
                  cancelText="Cancelar"
                  (ionChange)="emitDays()"
                  [max]="maxDate"
                ></ion-datetime>
              </ng-template>
            </ion-modal>
          </ion-item>
        </div>

        <ion-item (click)="emitSelectedBar(1)">
          <ion-label>
            <h2>Puerto</h2>
            <p>{{ puertoSelected.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
        <ion-item (click)="emitSelectedBar(2)">
          <ion-label>
            <h2>Naviera</h2>
            <p>{{ navieraSelected.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
        <ion-item (click)="emitSelectedBar(3)">
          <ion-label>
            <h2>Incoterm</h2>
            <p>{{ incoterm.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
      </div>

      <ion-item (click)="openCloseProyectos()">
        <ion-label><h1 class="myH1">Proyectos</h1></ion-label>
        <ion-icon item-end name="add" *ngIf="!proyectShow"></ion-icon>
        <ion-icon item-end name="remove" *ngIf="proyectShow"></ion-icon>
      </ion-item>
      <div class="divCantProd" *ngIf="proyectShow">
        <ion-item (click)="emitSelectedBar(9)">
          <ion-label>
            <h2>ID de Proyecto</h2>
            <p>{{ proyectoSelected.getName() }}</p>
          </ion-label>
          <ion-icon
            *ngIf="cantEdit"
            name="caret-down-outline"
            item-end
          ></ion-icon>
        </ion-item>
      </div>
    </ion-list>
  </div>
</div>
