import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-note-task',
  templateUrl: './note-task.component.html',
  styleUrls: ['./note-task.component.scss'],
})
export class NoteTaskComponent implements OnInit {
  note: string;
  constructor(private params: NavParams, public viewCtrl: PopoverController) {}

  ngOnInit() {
    let noteTmp = this.params.get('noteInfo');
    this.note = '';
    if (noteTmp !== undefined && noteTmp !== null) {
      this.note = noteTmp;
    }
  }

  sendNotePurchOrder() {
    this.viewCtrl.dismiss(this.note);
  }

  close() {
    this.viewCtrl.dismiss(null);
  }
}
