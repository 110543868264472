<app-nav-bar [title]="'Ordenes de Compra'"></app-nav-bar>

<ion-content>
  <div class="box">
    <div lines="none" class="barTitle">
      <div style="display: flex; width: 100%">
        <h5 style="width: 100%; font-size: 1rem; text-align: center">
          <a></a>HISTORIAL
        </h5>
      </div>
    </div>

    <!--Filtros de OC-->
    <ion-searchbar
      type="text"
      placeholder="Buscar"
      (ionChange)="searchOC($event)"
    ></ion-searchbar>

    <div class="flex-container nowrap" style="display: flex">
      <ion-button
        class="filter-btn"
        (click)="presentFilterPopover()"
        style="width: 100%"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        (click)="presentSortAlert()"
        style="width: 100%"
      >
        Ordenar
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>
    <!--//////////////////////////////////////////////////////////////////////////////////////////////-->

    <div class="content">
      <ion-list lines="none">
        <app-historial-oc
          *ngFor="let purch of listPurchShowing"
          [infoPurch]="purch"
        >
        </app-historial-oc>
      </ion-list>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <div class="whiteRectangle"></div>
  <ion-fab horizontal="end" vertical="bottom">
    <ion-fab-button (click)="goToOC()" size="small">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</div>
