import { Pipe, PipeTransform } from '@angular/core';
import { Certification } from '../../businessCore/Certification';

@Pipe({
  name: 'certFilter',
})
export class CertFilterPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(array: Certification[], text: string): any[] {
    if (text === '') {
      return array;
    }

    text = text.toLowerCase();

    return array.filter((item) => {
      return (
        item.getWorkerName().toLowerCase().includes(text) ||
        item.getWorkerDepartmentName().toLowerCase().includes(text) ||
        item.getCertificatedPeriod().toLowerCase().includes(text)
      );
    });
  }
}
