import { Component } from '@angular/core';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { GetData2EditComponent } from '../get-data2-edit/get-data2-edit.component';
import { CaseCategoryType } from './../../businessCore/CaseCategoryType';
import { CustomerAddress } from './../../businessCore/CustomerAddress';
import { CustomerData } from './../../businessCore/CustomerData';
import { RegisterData } from './../../businessCore/RegisterData';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-my-profile-edit-email',
  templateUrl: './my-profile-edit-email.component.html',
  styleUrls: ['./my-profile-edit-email.component.scss'],
})
export class MyProfileEditEmailComponent {
  title: string = '';
  imgUrl: string = '';
  customerInfoError: boolean = true;
  userEmailChecked: boolean = true;
  userEmail2Checked: boolean = false;
  loadingCustomerInfo: boolean = true;
  register_data: RegisterData = new RegisterData();
  customer_info: CustomerData = new CustomerData();
  addressInfo: { address: CustomerAddress; checked: boolean }[] = [];
  caseCategoryType: CaseCategoryType = new CaseCategoryType();

  /**
   *
   * @param navParams
   * @param storage
   * @param _apiProvider
   */
  constructor(
    public navParams: NavParams,
    public storage: StorageService,
    public _apiProvider: webServiceProvider,
    public popoverCtrl: PopoverController,
    public toastCtrl: ToastController
  ) {
    this.title = this.navParams.get('title');
    this.imgUrl = this.navParams.get('imgUrl');
    this.customer_info = this.navParams.get('custData') as CustomerData;
    this.register_data = this.navParams.get('registerData') as RegisterData;
    this.caseCategoryType = this.navParams.get('caseCategoryType')
      ? this.navParams.get('caseCategoryType')
      : new CaseCategoryType();

    if (this.register_data != null && this.customer_info) {
      this.customerInfoError = false;

      let userEmail = new CustomerAddress();
      userEmail.AddressName = 'Correo:';
      userEmail.Email = this.customer_info.email;

      this.addressInfo.push({ address: userEmail, checked: true });
    } else {
      this.customerInfoError = true;
    }

    this.loadingCustomerInfo = false;
  }

  /**
   *
   * @param addressItem
   */
  checkItem(addressItem: { address: CustomerAddress; checked: boolean }) {
    let itemIndex = this.addressInfo.indexOf(addressItem);
    this.addressInfo.forEach((element, index) => {
      element.checked = false;
      if (index == itemIndex) {
        element.checked = true;
      }
    });
  }

  /**
   *
   */
  async editEmail() {
    let addressSelected = this.addressInfo.filter(
      (data) => data.checked == true
    );
    if (addressSelected.length == 1) {
      // Hay que cambiar AccountNum por Email, cuando el campo se devuelva en el Web Service
      let popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: GetData2EditComponent,
        componentProps: {
          title: 'Editar Correo',
          actualData: addressSelected[0].address.Email,
        },
      });

      popover.present();

      popover.onDidDismiss().then((params) => {
        let newEmail = params.data;
        if (newEmail != null && newEmail != '') {
          this._apiProvider
            .editEmail(
              addressSelected[0].address.Email,
              newEmail,
              this.register_data.customerId
            )
            .subscribe((data) => {
              if (data) {
                // Update fields
                addressSelected[0].address.Email = newEmail;
                this.customer_info.email = newEmail;

                this.presentToast(
                  'Información de contacto actualizada con éxito!'
                );
              } else {
                this.presentToast(
                  'No se pudo actualizar la información de contacto'
                );
              }
            });
        } else {
          this.presentToast('Edición cancelada');
        }
      });
    } else {
      this.presentToast('Debe seleccionar solamente un correo electrónico.');
    }
  }

  /**
   *
   * @param msg
   */
  async presentToast(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: ['Ok'],
    });
    toast.present();
  }
}
