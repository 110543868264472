<app-nav-bar [title]="'Agregar a devolución'" [start]="'GoBack'"></app-nav-bar>

<!-- INVENTBATCHID: string = '';
  EXPDATE: string = '';
  INVENTDIMID: string = '';
  INVOICEID: string = '';
  INVOICEDATE: string = '';
  SALESID: string = '';
  ITEMID: string = '';
  NAME: string = '';
  TAXGROUP: string = '';
  SALESUNIT: string = '';
  INVOICEACCOUNT: string = '';
  SALESPRICE: number = 0;
  QTY: number = 0; -->

<ion-content class="ion-padding">
  <img
    class="center"
    src="https://serviceplan-api.mercasacr.com/images/Products/{{ item.ITEMID }}_l_.PNG"
    onerror="this.src='assets/img/error.png'"
    alt=""
  />
  <p ion-text text-center class="h7">{{ item.NAME }}</p>
  <p ion-text text-center class="h7" style="color: #223e99 !important">
    {{ item.ITEMID }}
  </p>

  <ion-list>
    <ion-item>
      <a style="color: brown; margin-right: 2%">*</a>
      <ion-select
        label="Unidad"
        label-placement="floating"
        class="floating"
        [(ngModel)]="unitId"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option *ngFor="let u of unitIds" value="{{ u }}">{{
          u
        }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <a class="required"></a>
      <ion-input
        label="Cantidad"
        label-placement="floating"
        class="floating"
        type="number"
        name="quantity"
        [(ngModel)]="qty"
        required
      ></ion-input>
    </ion-item>

    <ion-item *ngIf="batchIds.length > 0">
      <a style="color: brown; margin-right: 2%">*</a>
      <ion-select
        label="N° de lote"
        label-placement="floating"
        class="floating"
        [(ngModel)]="batchId"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option *ngFor="let b of batchIds" value="{{ b }}">{{
          b
        }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="batchIds.length > 0">
      <a style="color: brown; margin-right: 2%">*</a>
      <ion-select
        label="Vencimiento"
        label-placement="floating"
        class="floating"
        [(ngModel)]="expDate"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option *ngFor="let e of expDates" value="{{ e }}">{{
          e
        }}</ion-select-option>
      </ion-select>
    </ion-item>

    <!-- Si el producto no cuenta con lote, entonces le coloca un input para ingresarlo -->
    <ion-item *ngIf="batchIds.length <= 0">
      <ion-input
        label="N° de lote"
        label-placement="floating"
        class="floating"
        type="text"
        name="batchId"
        [(ngModel)]="batchId"
        required
      ></ion-input>
    </ion-item>

    <!-- Si el producto no cuenta con lote, entonces le coloca un input para ingresar la fecha -->
    <ion-item *ngIf="batchIds.length <= 0">
      <ion-label style="font-size: 1rem" color="primary">
        Vencimiento</ion-label
      >
      <ion-datetime-button datetime="datetime"></ion-datetime-button>

      <ion-popover [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetime"
            presentation="date"
            [showDefaultButtons]="true"
            displayFormat="YYYY/MMM/DD"
            [(ngModel)]="expDate"
            max="2050"
            cancelText="Cancelar"
            doneText="Aceptar"
            monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Set, Oct, Nov, Dic"
          ></ion-datetime>
        </ng-template>
      </ion-popover>
    </ion-item>

    <ion-item>
      <a style="color: brown; margin-right: 2%">*</a>
      <ion-select
        label="Razón"
        label-placement="floating"
        class="floating"
        [(ngModel)]="reason"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option
          *ngFor="let r of psRtnReason"
          value="{{ r.REASONCODEID }}"
          >{{ r.DESCRIPTION }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </ion-list>

  <ion-grid>
    <ion-row>
      <ion-col size="6" style="display: flex">
        <ion-button style="margin: auto" color="danger" (click)="dismiss(0)"
          >Cancelar</ion-button
        >
      </ion-col>
      <ion-col size="6" style="display: flex">
        <ion-button style="margin: auto" (click)="dismiss(1)"
          >Agregar</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
