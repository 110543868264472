import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComponentData } from './component.interface';
import { Connection } from './model/connection.interface';
import { ParametersBuilder } from './model/parameter';
import { XMLRequest } from './model/xml.request';

@Injectable()
export class AuthJWTService {
  /**
   * Propiedades de conexión
   */
  private connection: Connection = {
    serviceLink: environment.GIPlus_API,
    IService: 'IService1',
  };
  /**
   * Objeto para hacer peticiones al servidor
   */
  private request = new XMLRequest(this.connection);

  private readonly REFRESH_TOKEN = 'refresh_token';

  private _token: string = undefined;

  constructor(private cookieService: CookieService) {}

  /**
   * Logins into the GI+ api. If the login is successful, a new token is generated and returned.
   * If login fails, an exception is thrown.
   * @param vatNum
   * @param password
   */
  login(vatNum: string, password: string) {
    return new Observable<boolean>((observer) => {
      let params = new ParametersBuilder()
        .addParameter('cedula', vatNum)
        .addParameter('password', password);
      this.request
        .executeRequest('checkLoginData', params)
        .then((res) => {
          let value = res.xml
            .getElementsByTagName('checkLoginDataResult')
            .item(0).textContent;
          if (res.headers['authorization']) {
            let header = res.headers['authorization'];
            this._token = header.replace(/Bearer (.+)/, '$1');
          }
          observer.next(value === 'true');
          observer.complete();
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }
  /**
   * Verifica si el token es vaálido. En caso de que el token sea válido, guarda un `token_refresh`
   * en una cookie. El valor de esta cookie se utilizará para solictar nuevos token válidos. Este método
   * al crear una cookie, puede ser considerado como el inicio de sesión de la aplicación.
   *
   * @param app La aplicación que genera la solicitud de verificación del token
   * @param token El token por validar
   * @returns  Retorna un observable.
   */
  verifyAuthToken(token: string) {
    return new Observable<boolean>((observer) => {
      let params = new ParametersBuilder()
        //.addParameter("app", app)
        .addParameter('token', token);
      this.request
        .executeRequest('VerifyAuthToken', params)
        .then((res) => {
          if (res.headers['authorization']) {
            let header = res.headers['authorization'];
            let token = header.replace(/Bearer (.+)/, '$1');
            this.cookieService.set(
              this.REFRESH_TOKEN,
              token,
              1,
              '/',
              'localhost',
              false,
              'Lax'
            );
            observer.next(true);
            observer.complete();
          } else {
            observer.error('');
          }
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }
  /**
   * Solicita un nuevo token al servidor. Toma el valor almacenado en la cookie `refresh_token`
   * para hacer la solicitud. Si la solicitud es exitosa obtiene un nuevo token. Este nuevo token
   * contiene información relacionada al usuario que está iniciando sesión. La información que se puede
   * obtener del token es la siguiente:
   * - El número de cédula, representado por la la clave `vat-num`
   * - La lista de componentes a los cuales tiene acceso el usuario, representado por la clave `components`
   *
   * Para obtener el token generado, use el método `token`
   *
   * Para obtener los datos del token generado, use el método `getTokenData`
   *
   * @returns  Un observable
   */
  refreshToken() {
    return new Observable<boolean>((observer) => {
      if (!this.cookieService.check(this.REFRESH_TOKEN)) {
        observer.next(false);
        observer.complete();
      } else if (this._token) {
        observer.next(true);
        observer.complete();
      } else {
        let params = new ParametersBuilder().addParameter(
          'token',
          this.cookieService.get(this.REFRESH_TOKEN)
        );
        this.request
          .executeRequest('RefreshToken', params)
          .then((res) => {
            if (res.headers['authorization']) {
              let header = res.headers['authorization'];
              this._token = header.replace(/Bearer (.+)/, '$1');
              observer.next(true);
              observer.complete();
            } else {
              observer.next(false);
              observer.complete();
            }
          })
          .catch((err) => {
            observer.error(err);
          });
      }
    });
  }
  /**
   * Verifica si existe la cookie asociada a `refresh_token`
   * @returns Retorna true si la cookie existe
   */
  hasCookie() {
    return this.cookieService.check(this.REFRESH_TOKEN);
  }
  /**
   * Verifica si existe el token
   * @returns  Retorna true si existe
   */
  hasToken() {
    return this.token !== undefined && this.token !== '';
  }
  /**
   * Obtiene los datos del token.
   * @param type El tipo de token del cual desea obtener los datos
   * @returns  Retorna un objeto JSON
   */
  getTokenData(type: 'refresh' | 'token') {
    // return { "vat-num": "3-0512-0804" };
    if (type == 'refresh')
      
      return jwtDecode(this.cookieService.get(this.REFRESH_TOKEN)) as any;

    else {
      return jwtDecode(this.token) as any;
    }
  }

  getComponentsInfo() {
    return JSON.parse(
      this.getTokenData('token')['components']
    ) as ComponentData[];
  }
  /**
   * Obtiene el valor del token
   */
  public get token() {
    return this._token;
  }
}
