<table *ngIf="data" class="blueTable">
  <thead class="title">
    <tr>
      <th *ngIf="data.description" colspan="3" style="text-align: center">
        {{ data.description }}
      </th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>De</th>
      <th class="arrow">&raquo;</th>
      <th>A</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let conv of data.conversions">
      <td>{{ conv.fromUnit }}</td>
      <td class="arrow">&raquo;</td>
      <td>{{ conv.toUnit }}</td>
    </tr>
  </tbody>
</table>
