import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SelectFilterOption } from '../../OOP/SelectFilterOption.interface';

@Component({
  selector: 'app-select-objects',
  templateUrl: './select-objects.component.html',
  styleUrls: ['./select-objects.component.scss'],
})
export class SelectObjectsComponent {
  options: SelectFilterOption[] = [];
  optionsSelected: SelectFilterOption[] = [];
  optionsSearch: SelectFilterOption[] = [];
  multiple: boolean;
  timer: any;
  input_value: string;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.options = this.navParams.get('options');
    this.optionsSelected = this.navParams.get('optionsSelected');
    this.multiple = this.navParams.get('multiple');

    this.initSelectedOptions();
  }

  initSelectedOptions() {
    this.optionsSelected
      .filter((x) => x.isChecked)
      .forEach((x) => {
        this.options.forEach((y) => {
          if (y.id === x.id) {
            y.isChecked = true;
          }
        });
      });

    this.optionsSearch = this.options.slice();
  }

  evalMultiple(_option) {
    if (!this.multiple) {
      for (let option of this.options) {
        if (option.id != _option.id) {
          option.isChecked = false;
        }
      }

      this.filter();
    }
  }

  filter() {
    clearTimeout(this.timer);

    let ms = 200; // milliseconds

    this.timer = setTimeout(() => {
      this.doFilter();
    }, ms);
  }

  doFilter() {
    if (
      (this.input_value !== null || this.input_value !== undefined) &&
      this.input_value
    ) {
      let temp_input_value = this.input_value.toLowerCase();

      this.optionsSearch = this.options.filter((option) => {
        let id = option.label.toLowerCase() + option.description.toLowerCase();
        return id.includes(temp_input_value);
      });
    } else {
      this.optionsSearch = this.options.slice();
    }
  }

  save() {
    this.optionsSelected = JSON.parse(
      JSON.stringify(this.options.filter((x) => x.isChecked))
    );
    this.modalCtrl.dismiss(this.optionsSelected);
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
