import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-rejected-note-oc',
  templateUrl: './rejected-note-oc.component.html',
  styleUrls: ['./rejected-note-oc.component.scss'],
})
export class RejectedNoteOCComponent implements OnInit {
  note: string;
  constructor(public viewCtrl: PopoverController) {}

  ngOnInit() {
    this.note = '';
  }

  sendNotePurchOrder() {
    this.viewCtrl.dismiss(this.note);
  }

  close() {
    this.viewCtrl.dismiss(null);
  }
}
