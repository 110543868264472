//Native
import { Component, OnInit } from '@angular/core';

//Models-Classes
import { AssetRequest } from '../../businessCore/AssetRequest';
import { RegisterData } from '../../businessCore/RegisterData';

//Provider
import { ActivatedRoute } from '@angular/router';
import {
  AlertController,
  LoadingController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Worker } from '../../businessCore/Worker';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AuthenticationAuthGuardProvider } from '../../providers/authentication-auth-guard/authentication-auth-guard';
import { AssetRequestActionComponent } from '../asset-request-action/asset-request-action.component';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';

@Component({
  selector: 'app-assets-approval',
  templateUrl: './assets-approval.component.html',
  styleUrls: ['./assets-approval.component.scss'],
})
export class AssetsApprovalComponent implements OnInit {
  approver: Worker; //Persona que inicia sesión.
  approverVatnum: string; //Cédula del approver.
  requestsList: AssetRequest[]; //Lista de todas las solicitudes empleado, esta lista no se altera.
  requestListChecked: AssetRequest[]; //Todos los activos que serán pasados.

  rejectMotive: string; //Motivo de rechazo

  auth: number;
  componentId: any;

  constructor(
    private _apiProvider: webServiceProvider,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public popoverCtrl: PopoverController,
    public storage: StorageService,
    private authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService,
    private navParams: NavParams,
    public navCtrl: NavController,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.auth = Number(this.navParams.get('auth'));
    this.approverVatnum = this.navParams.get('code');

    if (this.auth === 0 && this.authService.hasCookie()) {
      this.navService.setRoot(
        this.activatedRoute.component.name as NavigationRoutes,
        {
          auth: 1,
          code: this.componentId,
        }
      );
    } else {
      if (this.auth === 1) {
        let checkedToken = await this.authGuard.checkToken();
        if (
          checkedToken &&
          this.authGuard.componentAllow(
            this.authService.getComponentsInfo(),
            this.componentId
          )
        ) {
          this.approverVatnum =
            this.authService.getTokenData('refresh')['vat-num'];
          console.log(this.approverVatnum);
        } else {
          window.location.href = 'http://giiis01:8025/';
        }
      } else {
        this.storage.get('register_data').then((data) => {
          const register_data = JSON.parse(data) as RegisterData;
          this.approverVatnum = register_data.id_card;
          // DEBUG
          //this.approverVatnum = '1-1236-0499';
          // DEBUG
        });
      }
      this.initialize();
    }
  }

  async initialize() {
    this.requestsList = [];
    this.requestListChecked = [];
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' }); //Genera pantalla de cargado.
    await loading.present(); //Muestra pantalla de cargado.
    try {
      this.approver = await this.getWorker('', this.approverVatnum);
      this.requestsList = await this.getRequestList(3);
    } catch (error) {
      this.createAlert(
        'Error de conexión',
        'Se ha presentado un error, favor intentar más tarde'
      );
    }
    await loading.dismiss();
  }

  /**
   * Permite refrescar el componente.
   * @param e Es el evento en cuestión proporcionado desde el html
   */
  refreshAssetRequest() {
    this.initialize();
    // e.complete();
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de peticiones.
   *
   * @param pStatus Si es 0 tienen estado pendiente por recibir, si es 3 pendiente por aprobar.
   *
   * @returns Una lista de activos por usuario.
   */
  async getRequestList(pStatus: number) {
    const resp = await this._apiProvider.getAssetRequest(pStatus);
    const list: AssetRequest[] = resp as AssetRequest[];
    return list;
  }

  /**
   *
   * Consume el servicio necesario para retornar la lista de trabajadores.
   *
   * @returns Una lista con los trabajadores activos a la fecha.
   */
  async getWorker(personnelNumber: string = '', vatnum: string = '') {
    const resp = await this._apiProvider.getWorker(personnelNumber, vatnum);
    const list: Worker[] = resp as Worker[];
    return list[0];
  }

  /**
   *
   */
  setRequestListChecked(pRequestListChecked: AssetRequest[]) {
    this.requestListChecked = pRequestListChecked;
  }

  /**
   *
   * @param title
   * @param subTitle
   *
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }

  /**
   *
   * Muestra el componente popover para elegir los filtros necesarios.
   *
   */
  async presentAssetReqActionPopover(param: number) {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AssetRequestActionComponent,
      componentProps: {
        requestList: this.requestListChecked,
        typeAction: param,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      const data = res.data;
      if (data != null) {
        this.rejectMotive = data.motive;
        this.approveRequest(
          this.approver,
          this.requestListChecked,
          data.motive,
          data.typeAction
        );
      }
    });
  }

  /**
   *
   * Envía los datos de las solicitudes a editar
   *
   */
  async approveRequest(
    pApprover: Worker,
    requestCheckedList: AssetRequest[],
    pMotive: string,
    pNewStatus: number
  ) {
    try {
      const resp = await this._apiProvider.alterAssetRequest(
        pApprover,
        requestCheckedList,
        pMotive,
        pNewStatus
      );
      let title = '';
      let action = '';
      if (pNewStatus == 4) {
        title = 'Solicitud rechazada';
        action = 'rechazado';
      } else {
        title = 'Solicitud aprobada';
        action = 'aprobado';
      }
      if (resp == '1') {
        this.createAlert(title, 'Se ha realizado la acción con éxito');
        try {
          this.sendEmails(requestCheckedList, this.approver, title, action);
        } catch (error) {
          this.createAlert(
            'Error en correo',
            'No se ha logrado enviar el correo de confirmación a uno de los interesados, favor notificarlos'
          );
        }
        this.initialize();
      } else {
        this.createAlert(
          'Evento sin efecto',
          'No se ha podido realizar su petición, favor intentar más tarde'
        );
      }
    } catch (error) {
      this.createAlert(
        'Error de conexión',
        'Se ha presentado un error, favor intentar más tarde'
      );
    }
  }

  /**
   *
   * @param requestCheckedList
   * @param pNewResponsible
   * @param pTitle
   * @param pAction
   */
  async sendEmails(
    requestCheckedList: AssetRequest[],
    pApprover: Worker,
    pTitle: string,
    pAction: string
  ) {
    let map: Map<string, AssetRequest[]> =
      this.requestsByRecipientsApprover(requestCheckedList);
    let mapIter = map.keys();
    for (let i = 0; i < map.size; i++) {
      let emailRecipient = mapIter.next().value;
      let msgBody: string = this.createEmailBody(
        pApprover,
        map.get(emailRecipient),
        pAction
      );
      if (pApprover.getEmail() != emailRecipient) {
        await this._apiProvider.sendEmail(
          msgBody,
          emailRecipient,
          pTitle + ' del traslado de activos'
        );
      }
    }
    let approverMsgBody = this.createApproverEmailBody(
      pApprover,
      requestCheckedList,
      pAction
    );
    await this._apiProvider.sendEmail(
      approverMsgBody,
      pApprover.getEmail(),
      pTitle + ' del traslado de activos'
    );
  }

  /**
   *
   * @param requestCheckedList
   */
  requestsByRecipientsApprover(requestCheckedList: AssetRequest[]) {
    let map = new Map<string, AssetRequest[]>();
    let requestCheckedL: AssetRequest[] = [];
    requestCheckedList.forEach((request) => {
      if (map.has(request.getWorkerEmail())) {
        requestCheckedL = map.get(request.getWorkerEmail());
        requestCheckedL.push(request);
      } else {
        requestCheckedL = [request];
      }
      map.set(request.getWorkerEmail(), requestCheckedL);
    });
    requestCheckedList.forEach((request) => {
      if (map.has(request.getWorkerEmailNew())) {
        requestCheckedL = map.get(request.getWorkerEmailNew());
        requestCheckedL.push(request);
      } else {
        requestCheckedL = [request];
      }
      map.set(request.getWorkerEmailNew(), requestCheckedL);
    });
    return map;
  }

  /**
   *
   * @param pNewResponsible
   * @param requestCheckedList
   * @param action
   */
  private createEmailBody(
    pApprover: Worker,
    requestCheckedList: AssetRequest[],
    action: string
  ) {
    let responsible = requestCheckedList[0].getWorkerName();
    let responsibleNew = requestCheckedList[0].getWorkerNameNew();

    let newAsstRequest: AssetRequest[] = [];
    let asstRequest: AssetRequest = new AssetRequest();
    for (let i = 0; i < requestCheckedList.length; i++) {
      asstRequest = new AssetRequest();
      asstRequest.assetId = requestCheckedList[i].getAssetId();
      asstRequest.barcode = requestCheckedList[i].getBarcode();
      asstRequest.assetName = requestCheckedList[i].getAssetName();
      asstRequest.mainAssetId = requestCheckedList[i].getMainAssetId();
      newAsstRequest.push(asstRequest);
      if (requestCheckedList[i].getSubRequest().length) {
        for (let j = 0; j < requestCheckedList[i].getSubRequest().length; j++) {
          asstRequest = new AssetRequest();
          asstRequest.assetId = requestCheckedList[i]
            .getSubRequest()
            [j].getAssetId();
          asstRequest.barcode = requestCheckedList[i]
            .getSubRequest()
            [j].getBarcode();
          asstRequest.assetName = requestCheckedList[i]
            .getSubRequest()
            [j].getAssetName();
          asstRequest.mainAssetId = requestCheckedList[i].getMainAssetId();
          newAsstRequest.push(asstRequest);
        }
      }
    }
    let resp =
      pApprover.getName() +
      ' ha ' +
      action +
      ' la solicitud de traslado de ' +
      responsible.toUpperCase() +
      ' para ' +
      responsibleNew.toUpperCase() +
      ' que incluye los activos: &#60;br&#62;';
    for (let assetRequest of newAsstRequest) {
      resp += '&#60;br&#62;─Número de activo: ' + assetRequest.getAssetId();
      if (assetRequest.getBarcode()) {
        resp += ', Código de barras: ' + assetRequest.getBarcode();
      }
      resp +=
        ', Nombre: ' +
        assetRequest.getAssetName() +
        ', compañía: ' +
        assetRequest.getDataAreaId();
    }
    if (this.rejectMotive) {
      resp += '&#60;br&#62;&#60;br&#62; Por motivo de ' + this.rejectMotive;
    }
    resp += '&#60;br&#62;&#60;br&#62;Saludos,';
    return resp;
  }

  /**
   *
   * @param pNewResponsible
   * @param requestCheckedList
   * @param action
   */
  private createApproverEmailBody(
    pApprover: Worker,
    requestCheckedList: AssetRequest[],
    action: string
  ) {
    let newAsstRequest: AssetRequest[] = [];
    let asstRequest: AssetRequest = new AssetRequest();
    for (let i = 0; i < requestCheckedList.length; i++) {
      asstRequest = new AssetRequest();
      asstRequest.assetId = requestCheckedList[i].getAssetId();
      asstRequest.barcode = requestCheckedList[i].getBarcode();
      asstRequest.assetName = requestCheckedList[i].getAssetName();
      asstRequest.dataAreaId = requestCheckedList[i].getDataAreaId();
      asstRequest.workerName = requestCheckedList[i].getWorkerName();
      asstRequest.workerNameNew = requestCheckedList[i].getWorkerNameNew();
      asstRequest.mainAssetId = requestCheckedList[i].getMainAssetId();
      newAsstRequest.push(asstRequest);
      if (requestCheckedList[i].getSubRequest().length) {
        for (let j = 0; j < requestCheckedList[i].getSubRequest().length; j++) {
          asstRequest = new AssetRequest();
          asstRequest.assetId = requestCheckedList[i]
            .getSubRequest()
            [j].getAssetId();
          asstRequest.barcode = requestCheckedList[i]
            .getSubRequest()
            [j].getBarcode();
          asstRequest.assetName = requestCheckedList[i]
            .getSubRequest()
            [j].getAssetName();
          asstRequest.dataAreaId = requestCheckedList[i]
            .getSubRequest()
            [j].getDataAreaId();
          asstRequest.workerName = requestCheckedList[i]
            .getSubRequest()
            [j].getWorkerName();
          asstRequest.workerNameNew = requestCheckedList[i]
            .getSubRequest()
            [j].getWorkerNameNew();
          asstRequest.mainAssetId = requestCheckedList[i].getMainAssetId();
          newAsstRequest.push(asstRequest);
        }
      }
    }

    let resp =
      pApprover.getName() +
      ' has ' +
      action +
      ' el traslado de los activos: &#60;br&#62;';
    for (let assetRequest of newAsstRequest) {
      resp += '&#60;br&#62;─Número de activo: ' + assetRequest.getAssetId();
      if (assetRequest.getBarcode()) {
        resp += ', Código de barras: ' + assetRequest.getBarcode();
      }
      resp +=
        ', Nombre: ' +
        assetRequest.getAssetName() +
        ', compañía: ' +
        assetRequest.getDataAreaId() +
        '; de ' +
        assetRequest.getWorkerName().toUpperCase() +
        ' para ' +
        assetRequest.getWorkerNameNew().toUpperCase();
    }
    //console.log(resp);
    if (this.rejectMotive) {
      resp += '&#60;br&#62;&#60;br&#62; Por motivo de ' + this.rejectMotive;
    }
    resp += '&#60;br&#62;&#60;br&#62;Saludos,';
    //console.log(resp);
    return resp;
  }
}
