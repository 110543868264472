import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent {
  /**
   * Array with the tutorial info, in this case just related with four
   * t1-> search products
   * t2-> Order History
   * t3-> App Register
   * t4-> Create order
   */
  families_info: { familyTitle: string; selected: boolean }[] = [
    { familyTitle: 't1', selected: false },
    { familyTitle: 't2', selected: false },
    { familyTitle: 't3', selected: false },
    { familyTitle: 't4', selected: false },
  ];

  /**
   * Attribute related with the video component that is displayed in the html section
   * with the template reference variable #videos
   */
  @ViewChildren('videos') divs: QueryList<ElementRef>;

  /**
   * Contructor of the class TutorialsComponent
   */
  constructor() {}

  /**
   * Method that bring the option to change de select property for the video component in the array videoComponent
   * @param family item with the information of the family selected/ clicked in the html
   */
  playVideo(family: { familyTitle: string; selected: boolean }) {
    this.families_info.forEach((f) => {
      if (f.familyTitle != family.familyTitle) {
        f.selected = false;
      } else {
        family.selected = !family.selected;
      }
    });

    this.divs.forEach((video: ElementRef) => {
      if (video.nativeElement.id == family.familyTitle) {
        video.nativeElement.paused && family.selected
          ? video.nativeElement.play()
          : video.nativeElement.pause();
      } else if (!video.nativeElement.paused) {
        video.nativeElement.pause();
      }
    });
  }
}
