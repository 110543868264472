// Native-Plugins
import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { FilterRequestData } from '../../businessCore/AssetRequest';

@Component({
  selector: 'app-asset-request-filter',
  templateUrl: './asset-request-filter.component.html',
  styleUrls: ['./asset-request-filter.component.scss'],
})
export class AssetRequestFilterComponent {
  filterData: FilterRequestData = new FilterRequestData();
  companies: any[];
  categories: any[];
  responsibles: any[];
  newResponsibles: any[];
  startDate: string;
  finalDate: string;
  wrongDateFlag: boolean;

  /**
   * Class constructor with these injections:
   * @param viewCtrl Access various features and information about the current view.
   * @param params Object that exists on a page and can contain data for that particular view.
   */

  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.filterData = this.params.get('filterRequestData');
    this.startDate = this.filterData.dates.startDate.toISOString();
    this.finalDate = this.filterData.dates.finalDate.toISOString();
    this.wrongDateFlag = false;
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y sin efectuar
   * ningun cambio en el filtrado de productos
   */
  dismissNull() {
    this.filterData.categories.forEach((element) => {
      element.selected = false;
    });
    this.filterData.companies.forEach((element) => {
      element.selected = false;
    });
    this.filterData.responsibles.forEach((element) => {
      element.selected = false;
    });
    this.filterData.newResponsibles.forEach((element) => {
      element.selected = false;
    });
    this.filterData.dates.startDate = this.filterData.dates.definitiveStartDate;
    this.filterData.dates.finalDate = this.filterData.dates.definitiveFinalDate;
    this.viewCtrl.dismiss(this.filterData);
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y efectuar
   * los cambios deseados para que el sistema muestre los productos
   * que coincidan con la selección
   */
  dismiss() {
    this.wrongDateFlag = false;
    this.filterData.dates.startDate = new Date(this.startDate);
    this.filterData.dates.finalDate = new Date(this.finalDate);
    if (this.filterData.dates.startDate <= this.filterData.dates.finalDate) {
      this.viewCtrl.dismiss(this.filterData);
    } else {
      this.wrongDateFlag = true;
    }
  }

  onChangeCompany(event) {
    this.falseAll(this.filterData.companies);
    for (let i of event.detail.value) {
      this.filterData.companies.find(
        (element) => element.dataAreaId === i.dataAreaId
      ).selected = true;
    }
  }

  onChangeCategory(event) {
    this.falseAll(this.filterData.categories);
    for (let i of event.detail.value) {
      this.filterData.categories.find(
        (element) => element.assetGroup === i.assetGroup
      ).selected = true;
    }
  }

  onChangeResponsible(event) {
    this.falseAll(this.filterData.responsibles);
    for (let i of event.detail.value) {
      this.filterData.responsibles.find(
        (element) => element.recId === i.recId
      ).selected = true;
    }
  }

  onChangeNewResponsible(event) {
    this.falseAll(this.filterData.newResponsibles);
    for (let i of event.detail.value) {
      this.filterData.newResponsibles.find(
        (element) => element.recId === i.recId
      ).selected = true;
    }
  }

  falseAll(array: any[]) {
    for (let i of array) {
      i.selected = false;
    }
  }
}
