// Native - Plugins
import { Component } from '@angular/core';

import { Money } from './../../GeneralUtilis/money';

// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

// BusinessCore
import { OrderTraceItem } from './../../businessCore/OrderTraceItem';
import { RegisterData } from './../../businessCore/RegisterData';

// Components
import {
  AlertController,
  NavController,
  PopoverController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { OrderLine } from '../../businessCore/OrderLine';
import { SelectProductsComponent } from '../select-products/select-products.component';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent {
  orders: OrderTraceItem[] = [];
  searchOrders: OrderTraceItem[] = [];
  loadingOrders: boolean = true;

  shouldAnimate: boolean = true;
  register_data: RegisterData = new RegisterData();
  shoppingCar: OrderLine[] = [];

  searchInput: string;

  /**
   * Class constructor with these injections:
   * @param navCtrl base class for navigation controller components like Nav and Tab.
   * @param popoverCtrl dialog that appears on top of the current page.
   * @param storage to store key/value pairs and JSON objects.
   * @param _apiProvider web service provider.
   */
  constructor(
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    public storage: StorageService,
    public _apiProvider: webServiceProvider,
    private alertCtrl: AlertController,
    private shoopingCartUtils: ShoopingCartUtils
  ) {
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;

        this.getTrace((JSON.parse(data) as RegisterData).customerId);
      }
    });
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  displayShoppingCar() {
    this.shoopingCartUtils.displayShoppingCart();
  }

  async presentAlert(title: string, msg: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  /**
   * Metodo utilizado para consultar el web service getOrderTraceList con todas las
   * ordenes que se encuentra asociadas a un determinado cliente
   * @param customerId string con el identificador del cliente sobre
   * el que se esta consultando el conjunto de ordenes pendientes de confirmacion.
   */
  getTrace(customerId: string) {
    this._apiProvider
      .getOrderTraceList(0, customerId, -1, '', 0, 15, '', '')
      .subscribe((data) => {
        this.orders = data as OrderTraceItem[];

        if (this.orders != null && this.orders.length > 0) {
          this.orders.forEach((item) => {
            let aux: OrderTraceItem = new OrderTraceItem();
            aux.salesStatus = item.salesStatus;
            aux.createdDatetime = item.createdDatetime;
            aux.deliveryDate = item.deliveryDate;
            aux.createdDatetimeString =
              (aux.createdDatetime + '').substring(0, 4) +
              '-' +
              (aux.createdDatetime + '').substring(4, 6) +
              '-' +
              (aux.createdDatetime + '').substring(6, 8);
            aux.deliveryDateString =
              (aux.deliveryDate + '').substring(0, 4) +
              '-' +
              (aux.deliveryDate + '').substring(4, 6) +
              '-' +
              (aux.deliveryDate + '').substring(6, 8);
            item.salesStatusString = aux.statusToText();
            item.createdDatetimeString = aux.createdDatetimeString;
            item.deliveryDateString = aux.deliveryDateString;
          });
        }
        this.initializeItems();
      });
  }

  /**
   * Metodo utilizado para resetear el arreglo de ordenes filtradas y poder
   * reiniciarlo con los campos que coincidan con la busqueda
   */
  initializeItems() {
    this.searchOrders = this.orders;
    this.loadingOrders = false;
  }

  /**
   * Metodo utilizado para llevar a cabo el filtrado de ordenes segun el valor
   * ingresado en el input de busqueda (event.target.value)
   * @param event parametro utilizado para capturar el valor ingresado en el campo de busqueda
   */
  getItems(event) {
    if (this.shouldAnimate) {
      this.shouldAnimate = false;
    }

    this.initializeItems();

    let val = event.target.value;
    if (val && val.trim() != '') {
      this.searchOrders = this.searchOrders.filter((item) => {
        return (
          item.salesAxId.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          item.deliveryDateString.toLowerCase().indexOf(val.toLowerCase()) >
            -1 ||
          item.createdDatetimeString.toLowerCase().indexOf(val.toLowerCase()) >
            -1 ||
          item.totalSales.toString().toLowerCase().indexOf(val.toLowerCase()) >
            -1
        );
      });
    }
  }

  /**
   * Metodo auxiliar utilizado para presentar un popup con los productos que tiene
   * asociados una orden
   * @param order objeto tipo OrderTraceItem sobre la que se presentaran los productos
   */
  async presentPopover(order: OrderTraceItem) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: SelectProductsComponent,
      componentProps: { item: order },
    });
    popover.present();
    popover.onDidDismiss();
  }

  /**
   * Metodo utilizado para presentar un popup con los productos que tiene asociados
   * una orden
   * @param order objeto tipo OrderTraceItem sobre la que se presentaran los productos
   */
  selectProducts(order: OrderTraceItem) {
    this.presentPopover(order);
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   * @returns <number> con el formato money establecido
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para llevar acabo los eventos definidos dentro del
   * sliding de cada item u orden.
   * En este caso solamente se maneja el swipeevent con direccion a la izquierda
   * @param event evento con la direccion de movimiento empleada
   * @param order orden tipo OrderTraceItem a la que se le consultaran
   * los productos asociados
   */
  swipeEvent(event, order) {
    if (event.direction === 2) {
      this.selectProducts(order);
    }
  }
}
