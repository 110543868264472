// Native - Plugins
import { Component } from '@angular/core';

// Pages
// import { HomePage } from './../../pages/home/home';

// BusinessCore
import {
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { NewContactComponent } from '../new-contact/new-contact.component';
import { Contact } from './../../businessCore/Contact';
import { RegisterData } from './../../businessCore/RegisterData';

@Component({
  selector: 'app-register-contact',
  templateUrl: './register-contact.component.html',
  styleUrls: ['./register-contact.component.scss'],
})
export class RegisterContactComponent {
  register_data: RegisterData;

  /**
   * Class constructor with these injections:
   * @param navParams object that exists on a page and can contain data for that particular view.
   * @param nav base class for navigation controller components like Nav and Tab
   * @param popoverCtrl dialog that appears on top of the current page.
   * @param toast subtle notification commonly used in modern applications.
   */
  constructor(
    public navParams: NavParams,
    private nav: NavController,
    private popoverCtrl: PopoverController,
    private toast: ToastController,
    public navCtrl: NavController,
    private navService: NavigationService
  ) {
    this.register_data = this.navParams.get('registerData');
  }

  /**
   * Metodo utilizado para redirigir la aplicacion al siguiente paso del registro de usuarios
   * en especifico se realiza al componente de registro de informacion adicional:
   * Restricciones, sitio web, etc.
   */
  public registerNextStep() {
    if (this.validForm()) {
      this.navService.navigateTo(
        NavigationRoutes.RegisterAdditionalInfoComponent,
        { registerData: this.register_data }
      );
    }
  }

  /**
   * Metodo utilizado para presentar el popup de creacion de un nuevo contacto
   * donde se registra el numero, descripcion y si el telefono es principal o no.
   * @param contact objeto con los campos que deben ser llenados para llevar
   * a cabo el registro de un nuevo contacto
   */
  async presentPopover(contact: Contact) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: NewContactComponent,
      componentProps: { contact: contact },
    });
    popover.present();

    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null && this.register_data.contacts.indexOf(contact) == -1) {
        this.register_data.contacts.push(data as Contact);
      }
    });
  }

  /**
   * Metodo utilizado para presentar el popup de registro de contactos pero de manera tal
   * que no sea para actualizar el contacto, sino para crearlo desde cero = nuevo.
   * @param type <boolean> con el tipo de contacto que se desea crear (Personal/otro)
   */
  presentNewPopover(type: boolean) {
    let contact = new Contact();
    contact.type = type;
    contact.edit = false;

    this.presentPopover(contact);
  }

  /**
   * Metodo utilizado para eliminar un contacto de la lista que el usuario tiene ingresados
   * esto se realiza por medio de un splice que elimina el objeto que se encuentra en la posicion
   * especificada
   * @param con objeto con la informacion del contacto que se desea eliminar
   */
  async deleteContact(con: Contact) {
    let delete_index = this.register_data.contacts.indexOf(con);
    this.register_data.contacts.splice(delete_index, 1);

    let toast = await this.toast.create({
      message: 'Contacto ' + con.contact + ' eliminado.',
      duration: 2000,
    });
    toast.present();
  }

  /**
   * Metodo utilizado para validar el formato con que se ingresa un correo electronico,
   * logrando el objetivo por medio de una expresion regular
   * @param email string con la ruta del correo que se desea validar
   */
  validateEmail(email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * Metodo utilizado para validar el formulario de registro, para que el mismo cumpla con
   * todo lo necesario para llevar a cabo el registro del nuevo cliente y asi le permita
   * continuar con el ingreso de informacion adicional
   */
  validForm() {
    if (
      Object.keys(this.register_data.contacts).length &&
      this.validateEmail(this.register_data.email)
    )
      return true;
    return false;
  }

  /**
   * Metodo utilizado para retornar al componente principal de la aplicacion
   * que es la ventana de despliegue de productos
   */
  goHome() {
    // this.navCtrl.setRoot(HomePage);
    /*this.navCtrl.setRoot(FamiliesComponent);
    this.navCtrl.popToRoot();*/
    this.navService.navigateTo(NavigationRoutes.FamiliesComponent);
  }
}
