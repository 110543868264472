// import { NewDirectionComponent } from './../../components/new-direction/new-direction';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { Address } from '../../businessCore/Address';

declare let L;

@Component({
  selector: 'app-vendor-register-address',
  templateUrl: './vendor-register-address.component.html',
  styleUrls: ['./vendor-register-address.component.scss'],
})
export class VendorRegisterAddressComponent implements OnInit {
  @Input() addressData: Address[];
  @Output() addressDataChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() addressLanguage: { [key: string]: any } = {};
  @Input() changeLanguageTo = '';
  @Output() languageChanger = new EventEmitter<string>();
  @Output() screenChanger = new EventEmitter<number>();

  addressList: Address[] = [];

  lat: number;
  lng: number;

  // direction_data: Direction;
  callback: any;
  mymap;
  marker;
  showActualPosBtn: boolean = false;

  constructor(private navService: NavigationService) {}

  /**
   *
   */
  ngOnInit(): void {
    this.setAddressVariables();
  }

  /**
   *
   */
  setAddressVariables() {
    this.addressList = this.addressData;
  }

  /**
   *
   */
  setGlobalVariables() {
    this.addressData = this.addressList;
  }

  /**
   *
   */
  changeLanguage() {
    switch (this.changeLanguageTo) {
      case 'ES':
        this.languageChanger.emit('EN');
        break;
      case 'EN':
        this.languageChanger.emit('ES');
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param pSecreen
   */
  changeScreen(pSecreen: number) {
    this.setGlobalVariables();
    this.addressDataChange.emit(this.addressData);
    this.screenChanger.emit(pSecreen);
  }

  /**
   *
   * @param pIndex
   */
  deleteElement(pIndex: number) {
    let newMain: any;
    this.addressList.splice(pIndex, 1);
    if (this.addressList.length) {
      newMain = this.addressList[0];
    }
  }

  /*
   *
   */
  presentGetPosition() {
    this.navService.navigateTo(NavigationRoutes.GetPositionVendorPage, {
      callback: this.getData,
    });
  }

  /**
   *
   */
  disableNext() {
    if (this.addressList.length) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @param newAddress
   */
  getData = (newAddress) => {
    this.addressList.push(newAddress);
  };
}
