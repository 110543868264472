//Native
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

//Models-Classes
import {
  AssetRequest,
  FilterRequestData,
} from '../../businessCore/AssetRequest';
import { RegisterData } from '../../businessCore/RegisterData';

//Pipes
import { AssetRequestFilterPipe } from '../../pipes/asset-request-filter/asset-request-filter';
import { FilterOneKeyListPipe } from '../../pipes/filter-one-key-list/filter-one-key-list';

//Components

import { AttachmentPost } from '../../businessCore/AttachmentPost';

//Para comprimir
// import { Ng2ImgMaxService } from 'ng2-img-max';
// import { DomSanitizer } from '@angular/platform-browser';

// import { NgxImageCompressService } from 'ngx-image-compress';

import {
  AlertController,
  AlertOptions,
  LoadingController,
  PopoverController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AssetRequestFilterComponent } from '../asset-request-filter/asset-request-filter.component';

@Component({
  selector: 'app-asset-request-list',
  templateUrl: './asset-request-list.component.html',
  styleUrls: ['./asset-request-list.component.scss'],
})
export class AssetRequestListComponent implements OnInit {
  @Input() requestList: AssetRequest[]; //Lista de todas solicitudes, esta lista no se altera.
  @Input() requestType: number; //Define que tipo de lista de solicitudes son (1=Aprobar, 2=Recibir).

  @Output() requestListCheckedEmit: EventEmitter<any> = new EventEmitter<any>();

  requestListToFilter: AssetRequest[]; //Contiene todos las solicitudes sin alteración, necesarios por las modificaciones sobre la lista previa.
  requestListToSearch: AssetRequest[]; //Contiene todos las solicitudes sin alteración, necesarios por las modificaciones sobre la lista previa.
  requestListChecked: AssetRequest[]; //

  companies: any[]; //Contiene todas las compañías que tienen relación con los activos actuales, para filtrar por ellos.
  categories: any[]; //Contiene todas las categorias que tienen relación con los activos actuales, para filtrar por ellos.
  responsibles: any[]; //Contiene todos los responsables actuales que poseen el activo, para filtrar por ellos.
  newResponsibles: any[]; //Contiene todos los responsables nuevos a quienes buscan traspasar los activos, para filtrar por ellos.
  dates: any; //Contiene todos los responsables nuevos a quienes buscan traspasar los activos, para filtrar por ellos.

  filterData: FilterRequestData; //Una estructura que contiene companies, categories, y responsables para pasar los valores al popover.

  sortByDateAsc: boolean; //Indica que el Radio Button de fecha ascendente está seleccionado o no.
  sortByDateDesc: boolean; //Indica que el Radio Button de fecha descendente está seleccionado o no.
  sortByAssetNumberAsc: boolean; //Indica que el Radio Button de número de activo ascendente está seleccionado o no.
  sortByAssetNumberDesc: boolean; //Indica que el Radio Button de número de activo descendente está seleccionado o no.
  sortByBarcodeAsc: boolean; //Indica que el Radio Button de barcode ascendente está seleccionado o no.
  sortByBarcodeDesc: boolean; //Indica que el Radio Button de barcode descendente está seleccionado o no.
  sortByAssetNameAsc: boolean; //Indica que el Radio Button del nombre del activo ascendente está seleccionado o no.
  sortByAssetNameDesc: boolean; //Indica que el Radio Button del nombre del activo descendente está seleccionado o no.
  sortByDataAreaIdAsc: boolean; //Indica que el Radio Button del código de la empresa es ascendente está seleccionado o no.
  sortByDataAreaIdDesc: boolean; //Indica que el Radio Button del código de la empresa es descendente está seleccionado o no.
  sortByAssetGroupNameAsc: boolean; //Indica que el Radio Button del nombre de la categoría es ascendente está seleccionado o no.
  sortByAssetGroupNameDesc: boolean; //Indica que el Radio Button del nombre de la categoría es descendente está seleccionado o no.

  vatnum: string; //

  constructor(
    private assetRequestFilterPipe: AssetRequestFilterPipe,
    private OneKeyListPipe: FilterOneKeyListPipe,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public storage: StorageService,
    public loadingCtrl: LoadingController,
    private _apiProvider: webServiceProvider
  ) {}

  async ngOnInit() {
    this.storage.get('register_data').then((data) => {
      const register_data = JSON.parse(data) as RegisterData;
      this.vatnum = register_data.id_card;
    });
    this.requestListToFilter = this.requestList;
    this.requestListToSearch = this.requestListToFilter;
    this.sortProducts('sortByDateAsc', this.requestListToSearch);

    this.companies = this.requestList
      .filter(
        (assetRequestData, i, arr) =>
          arr.findIndex(
            (t) => t.getDataAreaId() === assetRequestData.getDataAreaId()
          ) === i
      )
      .map((item) => {
        return {
          dataAreaId: item.getDataAreaId(),
          companyName: item.getCompanyName(),
          selected: false,
        };
      });
    this.categories = this.requestList
      .filter(
        (assetRequestData, i, arr) =>
          arr.findIndex(
            (t) => t.getAssetGroup() === assetRequestData.getAssetGroup()
          ) === i
      )
      .map((item) => {
        return {
          assetGroup: item.getAssetGroup(),
          assetGroupName: item.getAssetGroupName(),
          selected: false,
        };
      });
    this.responsibles = this.requestList
      .filter(
        (assetRequestData, i, arr) =>
          arr.findIndex(
            (t) => t.getWorkerName() === assetRequestData.getWorkerName()
          ) === i
      )
      .map((item) => {
        return {
          responsibleName: item.getWorkerName(),
          recId: item.getWorker(),
          selected: false,
        };
      });
    this.newResponsibles = this.requestList
      .filter(
        (assetRequestData, i, arr) =>
          arr.findIndex(
            (t) => t.getWorkerNameNew() === assetRequestData.getWorkerNameNew()
          ) === i
      )
      .map((item) => {
        return {
          responsibleName: item.getWorkerNameNew(),
          recId: item.getWorkerNew(),
          selected: false,
        };
      });
    this.dates = {
      startDate: this.requestListToSearch[0].getTransferDate(),
      finalDate:
        this.requestListToSearch[
          this.requestListToSearch.length - 1
        ].getTransferDate(),
      definitiveStartDate: this.requestListToSearch[0].getTransferDate(),
      definitiveFinalDate:
        this.requestListToSearch[
          this.requestListToSearch.length - 1
        ].getTransferDate(),
    };
    this.filterData = {
      companies: this.companies,
      categories: this.categories,
      responsibles: this.responsibles,
      newResponsibles: this.newResponsibles,
      dates: this.dates,
    };
  }

  /**
   * Modifica la lista sobre la que se busca según los valores ingresado en la barra de búsqueda.
   */
  searchAssetRequest(event) {
    this.requestListToSearch = this.assetRequestFilterPipe.transform(
      this.requestListToFilter,
      event.detail.value
    );
  }

  /**
   *
   * Muestra el componente popover para elegir los filtros necesarios.
   *
   */
  async presentFilterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AssetRequestFilterComponent,
      componentProps: { filterRequestData: this.filterData },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        this.filterData = data;
        this.filterAssetRequest();
      }
    });
  }

  /**
   *
   * Modifica la lista sobre la que se busca según los valores ingresado en la barra de búsqueda.
   *
   */
  filterAssetRequest() {
    this.requestListToFilter = this.requestList;
    const filtCompanies = this.filterAssetList('Empresa');
    const filtCategories = this.filterAssetList('Categoria');
    const filtResponsibles = this.filterAssetList('Responsable');
    const filtNewResponsibles = this.filterAssetList('ResponsableNuevo');
    this.requestListToFilter = this.OneKeyListPipe.transform(
      this.requestListToFilter,
      filtCompanies,
      2
    );
    this.requestListToFilter = this.OneKeyListPipe.transform(
      this.requestListToFilter,
      filtCategories,
      2
    );
    this.requestListToFilter = this.OneKeyListPipe.transform(
      this.requestListToFilter,
      filtResponsibles,
      2
    );
    this.requestListToFilter = this.OneKeyListPipe.transform(
      this.requestListToFilter,
      filtNewResponsibles,
      2
    );
    this.requestListToFilter = this.requestListToFilter.filter(
      (assetRequest: AssetRequest) => {
        return (
          assetRequest.getTransferDate() >= this.filterData.dates.startDate &&
          assetRequest.getTransferDate() <= this.filterData.dates.finalDate
        );
      }
    );
    this.requestListToSearch = this.requestListToFilter;
  }

  /**
   *
   * Obtiene una lista y la filtra de acuerdo al elemento de filtrado elegido.
   *
   * @param type Indica el tipo de elemento por el que se debe filtrar.
   *
   * @returns Una lista de activos filtrados.
   */
  filterAssetList(type: string) {
    let result: string[] = [];
    switch (type) {
      case 'Empresa':
        for (let i of this.filterData.companies.filter(
          (element) => element.selected === true
        )) {
          result.push(i.dataAreaId);
        }
        break;
      case 'Categoria':
        for (let i of this.filterData.categories.filter(
          (element) => element.selected === true
        )) {
          result.push(i.assetGroup);
        }
        break;
      case 'Responsable':
        for (let i of this.filterData.responsibles.filter(
          (element) => element.selected === true
        )) {
          result.push(i.recId);
        }
        break;
      case 'ResponsableNuevo':
      case 'Fechas':
        for (let i of this.filterData.newResponsibles.filter(
          (element) => element.selected === true
        )) {
          result.push(i.recId);
        }
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Muestra un alert con valores para ordenar las listas.
   */
  async presentSortAlert() {
    let alertOptions: AlertOptions = {
      header: '¿Cómo ordenar los activos?',
      inputs: [
        {
          type: 'radio',
          label: 'Fecha (Ascendente)',
          value: 'sortByDateAsc',
          checked: this.sortByDateAsc,
        },
        {
          type: 'radio',
          label: 'Fecha (Descendiente)',
          value: 'sortByDateDesc',
          checked: this.sortByDateDesc,
        },
        {
          type: 'radio',
          label: 'Número de activo (Menor a Mayor)',
          value: 'sortByAssetNumberAsc',
          checked: this.sortByAssetNumberAsc,
        },
        {
          type: 'radio',
          label: 'Número de activo (Mayor a Menor)',
          value: 'sortByAssetNumberDesc',
          checked: this.sortByAssetNumberDesc,
        },
        {
          type: 'radio',
          label: 'Código de barras (Menor a Mayor)',
          value: 'sortByBarcodeAsc',
          checked: this.sortByBarcodeAsc,
        },
        {
          type: 'radio',
          label: 'Código de barras (Mayor a Menor)',
          value: 'sortByBarcodeDesc',
          checked: this.sortByBarcodeDesc,
        },
        {
          type: 'radio',
          label: 'Nombre del activo (A -> Z)',
          value: 'sortByAssetNameAsc',
          checked: this.sortByAssetNameAsc,
        },
        {
          type: 'radio',
          label: 'Nombre del activo (Z -> A)',
          value: 'sortByAssetNameDesc',
          checked: this.sortByAssetNameDesc,
        },
        {
          type: 'radio',
          label: 'Código de la empresa (A -> Z)',
          value: 'sortByDataAreaIdAsc',
          checked: this.sortByDataAreaIdAsc,
        },
        {
          type: 'radio',
          label: 'Código de la empresa (Z -> A)',
          value: 'sortByDataAreaIdDesc',
          checked: this.sortByDataAreaIdDesc,
        },
        {
          type: 'radio',
          label: 'Categoría (A -> Z)',
          value: 'sortByAssetGroupNameAsc',
          checked: this.sortByAssetGroupNameAsc,
        },
        {
          type: 'radio',
          label: 'Categoría (Z -> A)',
          value: 'sortByAssetGroupNameDesc',
          checked: this.sortByAssetGroupNameDesc,
        },
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            this.sortProducts(data, this.requestListToSearch);
          },
        },
      ],
    };

    let alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  /**
   *
   * Ordena una lista por el elemento que se le indique.
   *
   * @param data El valor por el que se debe ordenar.
   * @param list La lista que se debe ordenar.
   *
   * @returns Una lista ordenada.
   */
  sortProducts(data: string, list: AssetRequest[]) {
    this.sortByDateAsc = false;
    this.sortByDateDesc = false;
    this.sortByAssetNumberAsc = false;
    this.sortByAssetNumberDesc = false;
    this.sortByBarcodeAsc = false;
    this.sortByBarcodeDesc = false;
    this.sortByAssetNameAsc = false;
    this.sortByAssetNameDesc = false;
    this.sortByDataAreaIdAsc = false;
    this.sortByDataAreaIdDesc = false;
    this.sortByAssetGroupNameAsc = false;
    this.sortByAssetGroupNameDesc = false;
    switch (data) {
      case 'sortByDateAsc':
        list.sort((a, b) => (a.transferDate <= b.transferDate ? -1 : 1));
        this.sortByDateAsc = true;
        break;
      case 'sortByDateDesc':
        list.sort((a, b) => (a.transferDate > b.transferDate ? -1 : 1));
        this.sortByDateDesc = true;
        break;
      case 'sortByAssetNumberAsc':
        list.sort((a, b) => (a.assetId <= b.assetId ? -1 : 1));
        this.sortByAssetNumberAsc = true;
        break;
      case 'sortByAssetNumberDesc':
        list.sort((a, b) => (a.assetId > b.assetId ? -1 : 1));
        this.sortByAssetNumberDesc = true;
        break;
      case 'sortByBarcodeAsc':
        list.sort((a, b) => (+a.barcode <= +b.barcode ? -1 : 1));
        this.sortByBarcodeAsc = true;
        break;
      case 'sortByBarcodeDesc':
        list.sort((a, b) => (+a.barcode > +b.barcode ? -1 : 1));
        this.sortByBarcodeDesc = true;
        break;
      case 'sortByAssetNameAsc':
        list.sort((a, b) => (a.assetName < b.assetName ? -1 : 1));
        this.sortByAssetNameAsc = true;
        break;
      case 'sortByAssetNameDesc':
        list.sort((a, b) => (a.assetName > b.assetName ? -1 : 1));
        this.sortByAssetNameDesc = true;
        break;
      case 'sortByDataAreaIdAsc':
        list.sort((a, b) => (a.dataAreaId < b.dataAreaId ? -1 : 1));
        this.sortByDataAreaIdAsc = true;
        break;
      case 'sortByDataAreaIdDesc':
        list.sort((a, b) => (a.dataAreaId > b.dataAreaId ? -1 : 1));
        this.sortByDataAreaIdDesc = true;
        break;
      case 'sortByAssetGroupNameAsc':
        list.sort((a, b) => (a.assetGroupName < b.assetGroupName ? -1 : 1));
        this.sortByAssetGroupNameAsc = true;
        break;
      case 'sortByAssetGroupNameDesc':
        list.sort((a, b) => (a.assetGroupName > b.assetGroupName ? -1 : 1));
        this.sortByAssetGroupNameDesc = true;
        break;
      default:
        break;
    }
    return list;
  }

  /**
   * Obtiene la lista de los elementos seleccionados que se muestran en pantalla.
   */
  async selectAllRequests(event) {
    this.requestListToSearch.forEach((request) => {
      if (event.detail.checked) {
        request.selected = true;
      } else {
        request.selected = false;
      }
      this.getCheckedvalue();
    });
  }

  /**
   *
   * Obtiene la lista de los elementos seleccionados que se muestran en pantalla.
   *
   */
  async getCheckedvalue() {
    this.requestListChecked = await this.requestListToSearch.filter((value) => {
      return value.selected;
    });
    this.requestListCheckedEmit.emit(this.requestListChecked);
  }

  /**
   *
   * Expande una lista de subassets, para los assets que poseen varios.
   *
   * @param asset Indica el asset específico que pide expandirse
   *
   */
  toggleAccordion(AssetRequest: AssetRequest) {
    AssetRequest.expanded = !AssetRequest.getExpanded();
  }

  //FUNCIONES CUANDO EL REQUESTTYPE == 2
  /**
   *
   * @param event Evento, trae la información de la imagen (input img)
   * @param index Es el elemento de la lista seleccionado
   */
  onFileSelected(event, index) {
    let imageData: AttachmentPost = new AttachmentPost();
    let imageTo64: string;
    let imgExtension: string;
    let file = event.target.files[0];
    let reader = new FileReader();
    this.requestListToSearch[index].imgName = file.name;
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      imageTo64 = reader.result.toString();
      imgExtension = imageTo64.substring(
        'data:image/'.length,
        imageTo64.indexOf(';base64')
      );
      imageTo64 = imageTo64.substring(imageTo64.lastIndexOf(',') + 1);
      imageData.base64 = imageTo64;
      imageData.attachmentExtension = imgExtension;
      imageData.attachmentAction = 1;
      imageData.attachmentType = 0;
      imageData.associatedVatnum = this.vatnum;
      imageData.associatedElementId = this.requestListToSearch[index].assetId;
      imageData.associatedCompany = this.requestListToSearch[index].dataAreaId;
      this.requestListToSearch[index].attachment = JSON.stringify(imageData);
      //console.log(imageTo64);
    };
  }

  /**
   * Crea pantalla en caso de error o éxito que lo indican.
   *
   * @param title
   * @param subTitle
   */
  async createAlert(title: string, subTitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      buttons: ['De acuerdo'],
    });
    alert.present();
  }

  // dataURItoBlob(dataURI) {
  //   const byteString = window.atob(dataURI);
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const int8Array = new Uint8Array(arrayBuffer);
  //   for (let i = 0; i < byteString.length; i++) {
  //     int8Array[i] = byteString.charCodeAt(i);
  //   }
  //   const blob = new Blob([int8Array], { type: 'image/jpeg' });
  //   return blob;
  // }

  // getBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });
  // }
}
