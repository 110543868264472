export class AlmacenModel {
  constructor(private inventLocationId: string, private name: string) {}

  getId() {
    return this.inventLocationId;
  }

  getName() {
    return this.name;
  }

  setValues(newId: string, newName: string) {
    this.inventLocationId = newId;
    this.name = newName;
  }
}
