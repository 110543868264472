import { Component } from '@angular/core';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { CustomerAddress } from './../../businessCore/CustomerAddress';
import { CustomerData } from './../../businessCore/CustomerData';

@Component({
  selector: 'app-select-delivery-address',
  templateUrl: './select-delivery-address.component.html',
  styleUrls: ['./select-delivery-address.component.scss'],
})
export class SelectDeliveryAddressComponent {
  customerData: CustomerData = new CustomerData();
  customerAddress: { address: CustomerAddress; checked: boolean }[] = [];
  timeRange: { type: number; desc: string; checked: boolean }[] = [];

  loadingInfo: any;

  constructor(
    public navParams: NavParams,
    public viewCtrl: PopoverController,
    public toastCtrl: ToastController
  ) {
    this.customerData = this.navParams.get('customerData');

    let checkedCtrl = true;
    this.customerData.CustomerAddresses.forEach((data) => {
      this.customerAddress.push({
        address: data,
        checked: checkedCtrl,
      });
      checkedCtrl = false;
    });

    this.timeRange.push({
      type: 0,
      desc: 'Dentro de las próximas 4 horas.',
      checked: true,
    });
    this.timeRange.push({
      type: 1,
      desc: 'Dentro de las próximas 48 horas.',
      checked: false,
    });
  }

  /**
   *
   * @param addressItem
   */
  checkItem(addressItem: { address: CustomerAddress; checked: boolean }) {
    let itemIndex = this.customerAddress.indexOf(addressItem);
    this.customerAddress.forEach((element, index) => {
      element.checked = false;
      if (index == itemIndex) {
        element.checked = true;
      }
    });
  }

  checkTimeItem(time: { type: number; desc: string; checked: boolean }) {
    let itemIndex = this.timeRange.indexOf(time);
    this.timeRange.forEach((element, index) => {
      element.checked = false;
      if (index == itemIndex) {
        element.checked = true;
      }
    });
  }

  /**
   *
   */
  async setData() {
    let addresSelected = this.customerAddress.filter(
      (addresss) => addresss.checked
    );
    let timeRangeSelected = this.timeRange.filter((time) => time.checked);

    if (addresSelected.length == 1 && timeRangeSelected.length == 1) {
      this.viewCtrl.dismiss({
        direction: addresSelected[0],
        timeRange: timeRangeSelected[0],
      });
    } else {
      let msg = '';
      if (addresSelected.length < 1 || timeRangeSelected.length < 1) {
        msg =
          'Lo sentimos, debe seleccionar una dirección y un rango de tiempo.';
      } else {
        msg =
          'Lo sentimos, se debe seleccionar solamente una dirección y un rango de tiempo.';
      }
      let toast = await this.toastCtrl.create({
        message: msg,
        duration: 3000,
        buttons: ['Ok'],
      });
      toast.present();
    }
  }

  /**
   *
   */
  createDirection() {}
}
