import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  AlertOptions,
  IonContent,
  LoadingController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { ShoopingCartUtils } from 'src/app/GeneralUtilis/ShoopingCar.utils';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { TutorialVideoComponent } from 'src/app/components/tutorial-video/tutorial-video.component';
import { formatter } from './../../GeneralUtilis/formatter';
import { Money } from './../../GeneralUtilis/money';
import { InventoryItem } from './../../businessCore/InventoryItem';
import { OrderLine } from './../../businessCore/OrderLine';
import { filteringOptions } from './../../businessCore/filteringOptions';
import { webServiceProvider } from './../../provider/webServiceProvider';
import { Swiper } from 'swiper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomePage implements OnInit {
  @ViewChild('content') content: IonContent;

  itemInfo: InventoryItem[];
  itemInfoBackUp: InventoryItem[] = [];
  shoppingCar: OrderLine[] = [];
  slide_items: number[] = [1, 2, 3];
  filter: filteringOptions = new filteringOptions();

  sortByPriceAsc: boolean = false;
  sortByPriceDesc: boolean = false;
  sortByNameAsc: boolean = false;
  sortByNameDesc: boolean = false;
  showScrollButton: boolean = false;

  priceList: string = 'HX-VIP';

  loadingProducts: boolean = true;

  constructor(
    public navCtrl: NavController,
    private _apiProvider: webServiceProvider,
    private _loadingCtrl: LoadingController,
    private toast: ToastController,
    public popoverCtrl: PopoverController,
    private storage: StorageService,
    private alertCtrl: AlertController,
    public popupCtrl: PopoverController,
    private navService: NavigationService,
    private shoppingCartUtils: ShoopingCartUtils,
    public platform: Platform //public fcm: FCM // Firebase Cloud Messaging
  ) {
    this.storage.get('cleanStorage').then((info) => {
      if (info == null) {
        this.storage.set('cleanStorage', JSON.stringify(true));
        this.storage.set('productos', null);
        this.storage.set('ultimaActualizacionProductos', null);
      }

      this.storage.get('price_group_id').then((data) => {
        if (data != null) {
          this.priceList = JSON.parse(data) as string;

          if ((JSON.parse(data) as string) == 'FU') {
            this.priceList = 'HX-VIP';
          }
        } else {
          this.priceList = 'HX-VIP';
        }
        this.reloadProducts();
      });
    });
  }

  openTutorialVideo() {
    this.storage
      .get('showTutorial')
      .then(async (data) => {
        let showTutorial = data != null ? (data as boolean) : true;

        if (showTutorial) {
          let tutorialPopup = await this.popoverCtrl.create({
            cssClass: 'custom-popover',
            component: TutorialVideoComponent,
            componentProps: { showHeader: false },
            backdropDismiss: false,
          });

          tutorialPopup.present();
        }
        this.storage.set('showTutorial', false);
      })
      .catch(() => {
        this.storage.set('showTutorial', false);
      });
  }

  async logScrollStart() {
    let scroll = await this.content.getScrollElement();
    this.showScrollButton = scroll.scrollTop == 0 ? false : true;
  }

  async logScrollEnd() {
    let scroll = await this.content.getScrollElement();
    this.showScrollButton = scroll.scrollTop == 0 ? false : true;
  }

  scrollToTop() {
    this.content.scrollToTop(1000);
    this.showScrollButton = false;
  }

  /*
  setFCMToken(token, custaccount) {
    this._apiProvider.setFCMToken(token, custaccount).subscribe(() => { });
  }*/

  ngOnInit(): void {
    /*
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.fcm.getToken().then(token => {

          this.storage.get("register_data").then((data) => {
            if (data != null) {
              const register_data = JSON.parse(data) as RegisterData;

              this.setFCMToken(token, register_data.id_card);
            } else {
              this.setFCMToken(token, '1-1656-0987');
            }
          }).catch(() => {
            this.setFCMToken(token, '5-0399-0169');
          });
        });

        this.fcm.onTokenRefresh().subscribe(token => {
          this.storage.get("register_data").then((data) => {
            if (data != null) {
              const register_data = JSON.parse(data) as RegisterData;

              this.setFCMToken(token, register_data.id_card);
            } else {
              this.setFCMToken(token, '1-1656-0987');
            }
          }).catch(() => {
            this.setFCMToken(token, '5-0399-0169');
          });
        });

        this.fcm.onNotification().subscribe(data => {
          if (data.wasTapped) {
            this.toast.create({
              message: 'Received in background',
              showCloseButton: true,
              closeButtonText: 'Ok',
              duration: 5000,
              position: 'top'
            }).present();

          } else {
            this.toast.create({
              message: 'Received in foreground',
              showCloseButton: true,
              closeButtonText: 'Ok',
              duration: 5000,
              position: 'top'
            }).present();
          }
        });
      }
    });*/
  }

  setTotalSale(Amount: Number, Taxes: Number) {
    if (Amount && Taxes) {
      let data =
        Number(Amount.toString().replace(',', '.')) +
        Number(Taxes.toString().replace(',', '.'));
      return this.setCurrency(data);
    } else {
      return this.setCurrency(0);
    }
  }

  async reloadProducts() {
    let loading = await this._loadingCtrl.create({ message: 'Cargando...' });
    loading.present(); //nav instance of NavController
    this.storage
      .get('productos')
      .then((prods) => {
        this.itemInfo = JSON.parse(prods);
        if (this.itemInfo == null) {
          this.itemInfo = [];
        }

        let format: formatter = new formatter();
        this.filter = new filteringOptions();
        // Set min and max price to the filters
        this.filter.minPrice = format.toNumber(this.itemInfo[0].Amount);
        this.filter.maxPrice = format.toNumber(this.itemInfo[0].Amount);
        this.itemInfo.forEach((item) => {
          if (format.toNumber(item.Amount) < this.filter.minPrice) {
            this.filter.minPrice = format.toNumber(item.Amount);
          } else if (format.toNumber(item.Amount) > this.filter.maxPrice) {
            this.filter.maxPrice = format.toNumber(item.Amount);
          }

          item.FinalPrice = this.setTotalSale(item.Amount, item.taxes);
        });

        this.sortHierarchyAsc();

        this.storage.get('carrito').then((data) => {
          this.shoppingCar = JSON.parse(data);
          if (this.shoppingCar == null) {
            this.shoppingCar = [];
          }
        });

        this.loadingProducts = false;
        loading.dismiss();
      })
      .catch(async () => {
        this.loadingProducts = false;
        loading.dismiss();

        let toast = await this.toast.create({
          message:
            'Lo sentimos, en este momento no se pudieron consultar los productos!',
          buttons: ['Ok'],
          duration: 5000,
          position: 'bottom',
        });
        toast.present();
      });
  }

  hasTaxes(item: InventoryItem) {
    let value = Number.parseFloat(item.taxes.toString().replace(',', '.'));
    return value > 0 ? true : false;
  }

  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  openPromos(item: InventoryItem) {
    //console.log('Abriendo popup de promociones para el item -->> ');
    //console.log(item);
  }

  addToCart(item: InventoryItem) {
    this.navService.navigateTo(NavigationRoutes.AddToCartComponent, {
      item: item,
      shoppingCar: this.shoppingCar,
    });
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  displayShoppingCar() {
    this.shoppingCartUtils.displayShoppingCart();
  }

  async presentAlert(title: string, msg: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  applySearchFilter() {
    let filterAux = this.filter;
    filterAux.minPriceSelected = this.filter.minPrice;
    filterAux.maxPriceSelected = this.filter.maxPrice;

    this.applyFilters(this.filter);
  }

  async presentPopoverFilter() {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: FilterComponent,
      componentProps: { filter: this.filter },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      this.applyFilters(data);
    });
  }

  applyFilters(data) {
    if (this.itemInfoBackUp.length > 0) {
      this.itemInfo = this.itemInfoBackUp;
    }
    if (data != null) {
      this.filter = data as filteringOptions;

      this.itemInfoBackUp = this.itemInfo;

      // Variable para determinar si una familia fue seleccionada
      let familySelected = false;

      // Determina si una familia fue seleccionada
      this.filter.families.forEach((familyInfo) => {
        if (familyInfo.selected) {
          familySelected = true;
        }
      });

      // Si una familia fue seleccionada se toma en cuenta este criterio para el filtrado de productos
      if (familySelected) {
        this.itemInfo = [];

        // Check if some family is selected
        this.filter.families.forEach((familyInfo) => {
          this.itemInfoBackUp.forEach((item) => {
            if (
              familyInfo.selected &&
              item.Hierarchy1.toUpperCase() ==
                familyInfo.title.toUpperCase().replace(/_/g, ' ')
            ) {
              this.itemInfo.push(item);
            }
          });
        });
      }

      //  Filtro de productos por precio
      let aux: InventoryItem[] = this.itemInfo;
      this.itemInfo = [];
      let format: formatter = new formatter();
      aux.forEach((item) => {
        if (
          this.filter.minPriceSelected <= format.toNumber(item.Amount) &&
          this.filter.maxPriceSelected >= format.toNumber(item.Amount)
        ) {
          this.itemInfo.push(item);
        }
      });

      // Filtro de productos por nombre e identificacion
      if (this.filter.productName && this.filter.productName.length > 0) {
        aux = this.itemInfo;
        this.itemInfo = [];
        aux.forEach((item) => {
          if (
            item.ItemName.toLowerCase().includes(
              this.filter.productName.toLowerCase()
            ) ||
            item.ItemId.toLocaleLowerCase().includes(
              this.filter.productName.toLocaleLowerCase()
            )
          ) {
            this.itemInfo.push(item);
          }
        });
      }
    } else {
      if (this.filter != null) {
        this.filter.minPriceSelected = 0;
        this.filter.maxPriceSelected = 0;
        this.filter.families = [];
      }
    }
  }

  async presentPopoverSort() {
    let alertOptions: AlertOptions = {
      header: '¿Cómo ordenar los productos?',
      inputs: [
        {
          type: 'radio',
          label: 'Precio (Menor a Mayor)',
          value: 'sortByPriceAsc',
          checked: this.sortByPriceAsc,
        },
        {
          type: 'radio',
          label: 'Precio (Mayor a Menor)',
          value: 'sortByPriceDesc',
          checked: this.sortByPriceDesc,
        },
        {
          type: 'radio',
          label: 'Nombre (A -> Z)',
          value: 'sortByNameAsc',
          checked: this.sortByNameAsc,
        },
        {
          type: 'radio',
          label: 'Nombre (Z -> A)',
          value: 'sortByNameDesc',
          checked: this.sortByNameDesc,
        },
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            this.sortProducts(data);
          },
        },
      ],
    };

    let alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  sortProducts(data: string) {
    this.sortByPriceAsc = false;
    this.sortByPriceDesc = false;
    this.sortByNameAsc = false;
    this.sortByNameDesc = false;
    if (data == 'sortByPriceAsc') {
      this.sortPriceAsc();
      this.sortByPriceAsc = true;
    } else if (data == 'sortByPriceDesc') {
      this.sortPriceDesc();
      this.sortByPriceDesc = true;
    } else if (data == 'sortByNameAsc') {
      this.sortNameAsc();
      this.sortByNameAsc = true;
    } else if (data == 'sortByNameDesc') {
      this.sortNameDesc();
      this.sortByNameDesc = true;
    }
  }

  sortPriceAsc() {
    if (this.itemInfo.length > 1) {
      this.itemInfo = [...this.itemInfo].sort((a, b) => {
        return (
          Number.parseFloat(a.Amount.toString().replace(',', '.')) +
          Number.parseFloat(a.taxes.toString().replace(',', '.')) -
          (Number.parseFloat(b.Amount.toString().replace(',', '.')) +
            Number.parseFloat(b.taxes.toString().replace(',', '.')))
        );
      });
    }
  }

  sortPriceDesc() {
    if (this.itemInfo.length > 1) {
      this.itemInfo = [...this.itemInfo].sort((a, b) => {
        return (
          Number.parseFloat(b.Amount.toString().replace(',', '.')) +
          Number.parseFloat(b.taxes.toString().replace(',', '.')) -
          (Number.parseFloat(a.Amount.toString().replace(',', '.')) +
            Number.parseFloat(a.taxes.toString().replace(',', '.')))
        );
      });
    }
  }

  sortHierarchyAsc() {
    if (this.itemInfo.length > 1) {
      this.itemInfo = [...this.itemInfo].sort((a, b) => {
        if (a.MerchaArea < b.MerchaArea) {
          return -2;
        } else if (a.MerchaArea > b.MerchaArea) {
          return 2;
        } else {
          if (a.ItemId < b.ItemId) {
            return -1;
          } else if (a.ItemId > b.ItemId) {
            return 1;
          }
        }
        return 0;
      });
    }
  }

  sortNameAsc() {
    if (this.itemInfo.length > 1) {
      this.itemInfo = [...this.itemInfo].sort((a, b) => {
        if (a.ItemName < b.ItemName) {
          return -1;
        } else if (a.ItemName > b.ItemName) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  sortNameDesc() {
    if (this.itemInfo.length > 1) {
      this.itemInfo = [...this.itemInfo].sort((a, b) => {
        if (a.ItemName > b.ItemName) {
          return -1;
        } else if (a.ItemName < b.ItemName) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }
}
