import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { CertFilterData } from '../../businessCore/Certification';

@Component({
  selector: 'app-cert-filter',
  templateUrl: './cert-filter.component.html',
  styleUrls: ['./cert-filter.component.scss'],
})
export class CertFilterComponent {
  filterData: CertFilterData = new CertFilterData();
  departments: any[];
  periods: any[];
  startDate: string;
  finalDate: string;
  wrongDateFlag: boolean;

  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.filterData = this.params.get('certFilterData');
    /*this.startDate = this.filterData.dates.startDate.toISOString();
    this.filterData.dates.finalDate = new Date(this.filterData.dates.finalDate);
    this.filterData.dates.finalDate.setHours(17); //Le asigno 17 (23-6), para que no me de 19 en una segunda ocasión cuando se establecen los rangos de fechas en 00:00:00 y 23:59:59. Se puede mejorar agarrando la zona horaria.
    this.finalDate = this.filterData.dates.finalDate.toISOString();*/
    this.wrongDateFlag = false;
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y sin efectuar
   * ningun cambio en el filtrado de certificaciones
   */
  dismissNull() {
    this.filterData.departments.forEach((element) => {
      element.selected = false;
    });
    this.filterData.periods.forEach((element) => {
      element.selected = false;
    });
    this.filterData.dates.startDate = this.filterData.dates.definitiveStartDate;
    this.filterData.dates.finalDate = this.filterData.dates.definitiveFinalDate;
    this.viewCtrl.dismiss(this.filterData);
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y efectuar
   * los cambios deseados para que el sistema muestre las certificaciones
   * que coincidan con la selección
   */
  dismiss() {
    this.wrongDateFlag = false;
    this.filterData.dates.startDate = new Date(this.startDate);
    this.filterData.dates.startDate.setHours(0, 0, 0);
    this.filterData.dates.finalDate = new Date(this.finalDate);
    //console.log(this.finalDate);
    this.filterData.dates.finalDate.setHours(23, 59, 59);
    //console.log(this.filterData.dates);
    if (this.filterData.dates.startDate <= this.filterData.dates.finalDate) {
      //console.log('Yes');
      this.viewCtrl.dismiss(this.filterData);
    } else {
      this.wrongDateFlag = true;
    }
  }

  /**
   *
   * @param event
   */
  onChangeDepartment(event) {
    this.falseAll(this.filterData.departments);
    for (let i of event.detail.value) {
      this.filterData.departments.find(
        (element) => element.workerDepartment === i.workerDepartment
      ).selected = true;
    }
  }

  /**
   *
   * @param event
   */
  onChangePeriod(event) {
    this.falseAll(this.filterData.periods);
    for (let i of event.detail.value) {
      this.filterData.periods.find(
        (element) => element.certificatedPeriod === i.certificatedPeriod
      ).selected = true;
    }
  }

  /**
   *
   * @param array
   */
  falseAll(array: any[]) {
    for (let i of array) {
      i.selected = false;
    }
  }
}
