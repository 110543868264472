import { Component, Input, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { PurchLine } from '../../../businessCore/oc-models/PurchLine';

@Component({
  selector: 'app-update-qty-oc',
  templateUrl: './update-qty-oc.component.html',
  styleUrls: ['./update-qty-oc.component.scss'],
})
export class UpdateQtyOCComponent implements OnInit {
  @Input() purchLine: PurchLine;
  operation: any;
  taxInfo: boolean;
  constructor(private params: NavParams) {
    try {
      this.purchLine = this.params.get('purchLine');
      this.taxInfo = this.params.get('taxInfo');
    } catch (e) {}
    this.operation = null;
  }

  ngOnInit() {}

  reduceQuantity() {
    if (this.purchLine.cantidad > 0) {
      this.purchLine.cantidad--;
      this.purchLine.calculateInfo(this.taxInfo);
      this.operation = 1;
    }
  }

  increaseQuantity() {
    // if (this.purchLine.cantidad > 0) {
    this.purchLine.cantidad++;
    this.purchLine.calculateInfo(this.taxInfo);
    this.operation = 1;
    // }
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }
}
