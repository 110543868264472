export class Phone {
  constructor(
    public extension: string,
    public phoneNumber: string,
    public description: string,
    public main: boolean
  ) {}

  getExtension() {
    return this.extension;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getDescription() {
    return this.description;
  }

  getMainNumber() {
    return this.main;
  }

  setPhoneNumber(pPhoneNumber: string) {
    this.phoneNumber = pPhoneNumber;
  }

  setMainNumber(pMain: boolean) {
    this.main = pMain;
  }

  setValues(newData: Phone) {
    this.extension = newData.getExtension();
    this.phoneNumber = newData.getPhoneNumber();
    this.description = newData.getDescription();
    this.main = newData.getMainNumber();
  }
}
