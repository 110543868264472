export class PromoItemGroup {
  // OrdLine
  itemId: string = '';
  salesQuantity: number = 0;
  totalSale: number = 0;
  ItemGroupId: string = '';

  constructor(
    itemId: string,
    salesQuantity: number,
    totalSale: number,
    ItemGroupId: string
  ) {
    this.itemId = itemId;
    this.salesQuantity = salesQuantity;
    this.totalSale = totalSale;
    this.ItemGroupId = ItemGroupId;
  }
  //
}
