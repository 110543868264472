import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent {
  public orderid: string;
  public custid: string;
  public amount: number;
  public response: string;
  public transactionStatus: string = '';
  public transStatusOk: boolean = false;

  constructor(public navParams: NavParams, public viewCtrl: PopoverController) {
    let amount = this.navParams.get('amount') as number;
    let custid = this.navParams.get('custid');
    let orderid = this.navParams.get('orderid');
    let response = this.navParams.get('response');

    if (amount != null) {
      this.amount = amount;
    }
    if (custid != null) {
      this.custid = custid;
    }
    if (orderid != null) {
      this.orderid = orderid;
    }
    if (response != null) {
      this.response = response;
    }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    switch (this.response) {
      case '1':
        // Approved Transaction
        this.transStatusOk = true;
        this.transactionStatus = 'Su transacción ha sido aprobada.';

        break;
      case '2':
        // Denied Transaction
        this.transStatusOk = false;
        this.transactionStatus = 'Su transacción ha sido denegada.';

        break;
      case '3':
        // Data error || System Error
        this.transStatusOk = false;
        this.transactionStatus =
          'Su transacción NO ha sido procesada por problemas de datos o un error en el sistema.';

        break;
      default:
        // Unknown response
        this.transStatusOk = false;
        this.transactionStatus = 'Su transacción ha sido declinada.';

        break;
    }
  }

  /**
   * Method used to auto dismiss the popup
   */
  autoDismissForm() {
    this.viewCtrl.dismiss();
  }

  /**
   * Metodo utilizado para dar formato tipo moneda a un numero pasado por parametro
   * @param Amount numero con la cantidad a la que se le dara formato de moneda
   */
  setCurrency(Amount) {
    return '₡' + Amount;
  }
}
