export class ItemOCInfoPriceModel {
  public cantidad: number;
  constructor(
    public unitId: string,
    public amount: number,
    public percent: number,
    public taxes: number,
    public typeTaxes: string
  ) {
    this.cantidad = 0;
  }

  copyItem(objeto: ItemOCInfoPriceModel) {
    return new ItemOCInfoPriceModel(
      objeto.getUnitId(),
      objeto.getAmount(),
      objeto.getPercent(),
      objeto.getTaxes(),
      objeto.getTypeTaxes()
    );
  }

  getTypeTaxes() {
    return this.typeTaxes;
  }

  getCantidad() {
    return this.cantidad;
  }

  getUnitId() {
    return this.unitId;
  }

  getId() {
    return this.unitId;
  }

  getName() {
    return this.unitId;
  }

  getAmount() {
    return this.amount;
  }

  getPercent() {
    return this.percent;
  }

  getTaxes() {
    return this.taxes;
  }
}
