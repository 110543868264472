import { Component } from '@angular/core';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { GetData2EditComponent } from '../get-data2-edit/get-data2-edit.component';
import { CustomerData } from './../../businessCore/CustomerData';
import { RegisterData } from './../../businessCore/RegisterData';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-my-profile-edit-phone',
  templateUrl: './my-profile-edit-phone.component.html',
  styleUrls: ['./my-profile-edit-phone.component.scss'],
})
export class MyProfileEditPhoneComponent {
  title: string = '';
  imgUrl: string = '';
  customerInfoError: boolean = true;
  userEmailChecked: boolean = true;
  userEmail2Checked: boolean = false;
  loadingCustomerInfo: boolean = true;
  register_data: RegisterData = new RegisterData();
  customer_info: CustomerData = new CustomerData();
  contactInfo: { phoneNumber: string; checked: boolean }[] = [];

  /**
   *
   * @param navParams
   * @param storage
   * @param _apiProvider
   */
  constructor(
    public navParams: NavParams,
    public storage: StorageService,
    public _apiProvider: webServiceProvider,
    public popoverCtrl: PopoverController,
    public toastCtrl: ToastController
  ) {
    this.title = this.navParams.get('title');
    this.imgUrl = this.navParams.get('imgUrl');
    this.customer_info = this.navParams.get('custData') as CustomerData;
    this.register_data = this.navParams.get('registerData') as RegisterData;

    if (this.register_data != null) {
      if (this.customer_info) {
        this.customerInfoError = false;
        this.formatCustomerInfo();
      } else {
        this.customerInfoError = true;
      }
    } else {
      this.customerInfoError = true;
    }

    this.loadingCustomerInfo = false;
  }

  /**
   *
   */
  formatCustomerInfo() {
    let checked = true;
    let phoneNumbers = this.customer_info.Phone.split('/');
    phoneNumbers.forEach((phone) => {
      this.contactInfo.push({ phoneNumber: phone, checked: checked });
      checked = false;
    });
  }

  /**
   *
   * @param addressItem
   */
  checkItem(addressItem: { phoneNumber: string; checked: boolean }) {
    let itemIndex = this.contactInfo.indexOf(addressItem);
    this.contactInfo.forEach((element, index) => {
      element.checked = false;
      if (index == itemIndex) {
        element.checked = true;
      }
    });
  }

  /**
   *
   */
  async editPhoneNumber() {
    let contactSelected = this.contactInfo.filter(
      (data) => data.checked == true
    );
    if (contactSelected.length == 1) {
      let popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: GetData2EditComponent,
        componentProps: {
          title: 'Editar Número Telefónico',
          actualData: contactSelected[0].phoneNumber,
        },
      });

      popover.present();

      popover.onDidDismiss().then((res) => {
        let newPhoneNumber = res.data;
        if (newPhoneNumber != null && newPhoneNumber != '') {
          // let notes = 'Cambiar número telefónico del cliente ' + this.register_data.customerId + ", Número actual: " + contactSelected[0].phoneNumber + ", Número Nuevo: " + data;
          // let description = "Cambiar tel HE";
          // this.sendCase(notes, description);
          this._apiProvider
            .editPhoneNumber(
              contactSelected[0].phoneNumber,
              newPhoneNumber,
              this.register_data.customerId
            )
            .subscribe((data) => {
              if (data) {
                let phoneNumbers = this.customer_info.Phone.split('/');
                this.customer_info.Phone = '';
                phoneNumbers.forEach((phone, i) => {
                  if (phone == contactSelected[0].phoneNumber) {
                    this.customer_info.Phone += newPhoneNumber;
                  } else {
                    this.customer_info.Phone += phone;
                  }

                  // Si no es el ultimo elemento entonces le concatena un /
                  this.customer_info.Phone +=
                    i == phoneNumbers.length - 1 ? '' : '/';
                });

                this.contactInfo
                  .filter(
                    (contact) =>
                      contact.phoneNumber == contactSelected[0].phoneNumber
                  )
                  .forEach((c) => {
                    c.phoneNumber = newPhoneNumber;
                  });

                this.presentToast(
                  'Información de contacto actualizada con éxito!'
                );
              } else {
                this.presentToast(
                  'No se pudo actualizar la información de contacto'
                );
              }
            });
        } else {
          this.presentToast('Edición cancelada');
        }
      });
    } else {
      this.presentToast('Debe seleccionar solamente un número telefónico.');
    }
  }

  async presentToast(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: ['Ok'],
    });
    toast.present();
  }
}
