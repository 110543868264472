<app-nav-bar [title]="'Registrar Viaje'"></app-nav-bar>

<ion-content>
  <div class="box">
    <div class="content">
      <app-register-trip></app-register-trip>
    </div>
    <div class="infContent">
      <ion-segment style="background-color: #223e99">
        <ion-segment-button
          value="gasto"
          layout="icon-start"
          (click)="openScreen(1)"
        >
          <!--<ion-icon name="cash"></ion-icon>-->
          <i class="fas fa-dollar-sign" style="font-size: large"></i>
          <!--<ion-label class="labelCenter">AGREGAR GASTO</ion-label>-->
        </ion-segment-button>
        <ion-segment-button
          value="parada"
          layout="icon-start"
          (click)="openScreen(2)"
          *ngIf="hideParadaOP"
        >
          <ion-icon name="hand-right-outline"></ion-icon>
          <!--<ion-label class="labelCenter">PARADA</ion-label>-->
        </ion-segment-button>
        <ion-segment-button
          value="concluir"
          layout="icon-start"
          (click)="openOption(3)"
        >
          <ion-icon name="checkmark-circle"></ion-icon>
          <!--<ion-label class="labelCenter">CONCLUIR</ion-label>-->
        </ion-segment-button>
        <ion-segment-button
          value="detener"
          layout="icon-start"
          (click)="detenerAbrir()"
        >
          <!--"openOption(4)">-->
          <!--<ion-icon name="pin"></ion-icon>-->
          <i class="fas fa-ban" style="font-size: large"></i>
          <!--<ion-label class="labelCenter">DETENER</ion-label>-->
        </ion-segment-button>
        <ion-segment-button
          value="interrumpir"
          layout="icon-start"
          (click)="openOption(5)"
        >
          <!--<ion-icon name="pin"></ion-icon>-->
          <i class="fas fa-exclamation-triangle" style="font-size: large"></i>
          <!--<ion-label class="labelCenter">INTERRUMPIR</ion-label>-->
        </ion-segment-button>
        <ion-segment-button
          value="cancelar"
          layout="icon-start"
          (click)="openOption(6)"
        >
          <i class="fas fa-times-circle" style="font-size: large"></i>
          <!--<ion-icon name="trash"></ion-icon>-->
          <!--<ion-label class="labelCenter">ELIMINAR</ion-label>-->
        </ion-segment-button>
      </ion-segment>
    </div>
  </div>
</ion-content>
