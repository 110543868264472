export class Direction {
  country: string = 'CRI';
  province: number = 1;
  canton: number = 101;
  district: number = 10101;
  city: string = 'CI028';
  direction: string = '';
  directionName: string = '';
  addressName: string = '';
  deliveryDays: string = '';
  delivery_date: number = 0;
  is_principal: boolean = false;
  latitude: number = 9.845987756846009;
  longitude: number = -83.95003882233732;
  edit: boolean = true;
  recId: number = 0;
  priceGroupId: string = '';
  principalDeliveryDay: number = 1;
  secondDeliveryDay: number = 0;

  constructor() {}

  toXML(identify: string, prospectId: string) {
    let xml: string = '';
    xml =
      '<PSCustBusRelTableAddress class="entity">' +
      '<prospectId>' +
      prospectId +
      '</prospectId>' +
      '<country>' +
      this.country +
      '</country>' +
      '<province>' +
      this.province +
      '</province>' +
      '<canton>' +
      this.canton +
      '</canton>' +
      '<district>' +
      this.district +
      '</district>' +
      '<city>' +
      this.city +
      '</city>' +
      '<detail>' +
      this.direction +
      '</detail>' +
      '<isPrincipal>' +
      (this.is_principal ? 1 : 0) +
      '</isPrincipal>' +
      '<addressType>0</addressType>' + //0 GPS, 1 MAP
      '<latitude>' +
      this.latitude +
      '</latitude>' +
      '<longitude>' +
      this.longitude +
      '</longitude>' +
      '<visitPlans></visitPlans>' +
      '<deliveryPlans>' +
      this.delivery_date +
      '</deliveryPlans>' +
      '<identify>' +
      identify +
      '</identify>' +
      '<isNew>1</isNew>' + //siempre en 1
      '</PSCustBusRelTableAddress>';

    return xml;
  }
}
