//import { CardIO } from '@ionic-native/card-io';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';
import { environment } from 'src/environments/environment';
import { Money } from '../../GeneralUtilis/money';
import { OrderHeader } from '../../businessCore/OrderHeader';
import { RegisterData } from '../../businessCore/RegisterData';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { PaymentStatusComponent } from '../payment-status/payment-status.component';
import { TermsComponent } from '../terms/terms.component';
import { UseTermsComponent } from '../use-terms/use-terms.component';

@Component({
  selector: 'app-shopping-car-payment',
  templateUrl: './shopping-car-payment.component.html',
  styleUrls: ['./shopping-car-payment.component.scss'],
})
export class ShoppingCarPaymentComponent implements OnInit {
  ordHerder: OrderHeader = new OrderHeader();
  setIntID: any;

  @ViewChild('unlock') input: any;
  CardNumber: string;
  month: string;
  year: string;
  cvv: string;
  CardSrc: string;
  paymentType: string = 'DefaultPaymentType';
  totalOrder: number = 0;
  minimunLoaded: boolean = false;
  terms_conditions: boolean = false;
  credential_terms: boolean = false;
  app: number = 0; // 0 = HOMEX EXPRESS / 1 = MercasaVIP

  public credomaticForm: SafeResourceUrl;
  private minimunAmount: number;
  private orderid: string;
  private custid: string;
  private amount: number;
  register_data: RegisterData = new RegisterData();

  constructor(
    public viewCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public toastCtrl: ToastController,
    private params: NavParams,
    private _loadingCtrl: LoadingController,
    private webService: webServiceProvider,
    public navCtrl: NavController,
    public storage: StorageService,
    private alert: AlertController,
    //private cardIO: CardIO,
    private sanitizer: DomSanitizer,
    private globalEventsService: GlobalEventsService
  ) {
    this.ordHerder = this.params.get('ordHeader');

    this.calculateTotal();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.storage
      .get('register_data')
      .then((data) => {
        this.register_data = JSON.parse(data);
        if (this.register_data == null) {
          this.register_data = new RegisterData();
        }
        this.custid = this.register_data.id_card;
        this.amount = this.ordHerder.totalSale;
        this.orderid = this.ordHerder.salesId;

        this.webService
          .getMinimumAmount(this.register_data.id_card)
          .subscribe((data) => {
            if (data != null) {
              this.minimunAmount = data as number;
              this.minimunLoaded = true;
            }
          });

        if (this.ordHerder.salesId == '') {
          this.webService
            .getMobileSequence(this.ordHerder.employId)
            .subscribe((data) => {
              if (data != null) {
                let orderNumber: number = 0;
                let sequenceSelected: string = '';
                let sequences: {
                  Prefix: string;
                  PrefixDirectSale: string;
                  SequenceId: number;
                  Value: number;
                }[] = data as {
                  Prefix: string;
                  PrefixDirectSale: string;
                  SequenceId: number;
                  Value: number;
                }[];
                sequences.forEach((value) => {
                  if (value.SequenceId == 2) {
                    orderNumber = value.Value + 1;
                    sequenceSelected = value.Prefix;
                  }
                });
                if (orderNumber > 0) {
                  this.webService
                    .setMobileSequence(this.ordHerder.employId, 2, orderNumber)
                    .subscribe((item) => {
                      let orderFinalNumber: string = orderNumber.toString();
                      while (orderFinalNumber.length < 6) {
                        orderFinalNumber = '0' + orderFinalNumber;
                      }
                      orderFinalNumber = sequenceSelected + orderFinalNumber;
                      this.ordHerder.salesId = orderFinalNumber;
                      this.orderid = this.ordHerder.salesId;
                      this.credomaticForm =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                          'https://mercasacr.com:8096/PaymentForm/?amount=' +
                            this.amount +
                            '&orderid=' +
                            this.orderid +
                            '&custid=' +
                            this.custid +
                            '&app=' +
                            this.app
                        );
                      // this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/PaymentForm/?amount=' + this.amount + '&orderid=' + this.orderid + '&custid=' + this.custid + '&app=' + this.app);
                    });
                } else {
                  this.credomaticForm =
                    this.sanitizer.bypassSecurityTrustResourceUrl(
                      'https://mercasacr.com:8096/PaymentForm/?amount=' +
                        this.amount +
                        '&orderid=' +
                        this.orderid +
                        '&custid=' +
                        this.custid +
                        '&app=' +
                        this.app
                    );
                  // this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/PaymentForm/?amount=' + this.amount + '&orderid=' + this.orderid + '&custid=' + this.custid + '&app=' + this.app);
                }
              } else {
                this.credomaticForm =
                  this.sanitizer.bypassSecurityTrustResourceUrl(
                    'https://mercasacr.com:8096/PaymentForm/?amount=' +
                      this.amount +
                      '&orderid=' +
                      this.orderid +
                      '&custid=' +
                      this.custid +
                      '&app=' +
                      this.app
                  );
                // this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/PaymentForm/?amount=' + this.amount + '&orderid=' + this.orderid + '&custid=' + this.custid + '&app=' + this.app);
              }
            });
        } else {
          this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl(
            'https://mercasacr.com:8096/PaymentForm/?amount=' +
              this.amount +
              '&orderid=' +
              this.orderid +
              '&custid=' +
              this.custid +
              '&app=' +
              this.app
          );
          // this.credomaticForm = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/PaymentForm/?amount=' + this.amount + '&orderid=' + this.orderid + '&custid=' + this.custid + '&app=' + this.app);
        }
      })
      .catch((err) => {});
  }

  calculateTotal() {
    this.totalOrder = 0;
    this.ordHerder.totalSale = 0;
    this.ordHerder.ordLine.forEach((item) => {
      if (item.promoId != '') {
        this.totalOrder += item.lnAmnt;
        this.ordHerder.totalSale += item.lnAmnt;
      } else {
        this.totalOrder = this.totalOrder + item.totalSale;
        this.ordHerder.totalSale = this.ordHerder.totalSale + item.totalSale;
      }
      // this.totalOrder += item.totalSale;
      // this.ordHerder.totalSale += item.totalSale;
    });
  }

  getSiteName(InventSiteId: string) {
    return environment.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }

  isTransferSelected() {
    return this.paymentType == 'Transfer';
  }

  isCreditCardSelected() {
    return this.paymentType == 'CreditCard';
  }

  isDefaultPaymentTypeSelected() {
    return this.paymentType == 'DefaultPaymentType';
  }

  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  async showTerms() {
    let popOver = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: TermsComponent,
      componentProps: { showHeader: false },
    });
    popOver.present();
  }

  async showCredentialTerms() {
    let popOver = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: UseTermsComponent,
      componentProps: { showHeader: false },
    });
    popOver.present();
  }

  async purchase() {
    let loading = await this._loadingCtrl.create({ message: 'Cargando...' });
    loading.present();

    this.webService
      .getMobileSequence(this.ordHerder.employId)
      .subscribe((data) => {
        if (data != null) {
          let orderNumber: number = 0;
          let sequenceSelected: string = '';
          let sequences: {
            Prefix: string;
            PrefixDirectSale: string;
            SequenceId: number;
            Value: number;
          }[] = data as {
            Prefix: string;
            PrefixDirectSale: string;
            SequenceId: number;
            Value: number;
          }[];
          sequences.forEach((value) => {
            if (value.SequenceId == 2) {
              orderNumber = value.Value + 1;
              sequenceSelected = value.Prefix;
            }
          });
          if (orderNumber > 0) {
            this.webService
              .setMobileSequence(this.ordHerder.employId, 2, orderNumber)
              .subscribe((item) => {
                let orderFinalNumber: string = orderNumber.toString();
                while (orderFinalNumber.length < 6) {
                  orderFinalNumber = '0' + orderFinalNumber;
                }
                orderFinalNumber = sequenceSelected + orderFinalNumber;
                this.ordHerder.salesId = orderFinalNumber;

                this.webService
                  .sendXML(this.finishAsExecuted())
                  .subscribe((data) => {
                    loading.dismiss();
                    this.presentAlert(
                      'Orden Finalizada',
                      'Su orden fue enviada correctamente, recuerde revisar el estado de su orden para verificar el progreso de la misma.'
                    );
                  });
              });
          }
        }
      });
  }

  VerifyCardType() {
    if (this.CardNumber.startsWith('4')) {
      this.CardSrc = 'assets/img/visa.png';
    } else if (
      this.CardNumber.startsWith('51') ||
      this.CardNumber.startsWith('52') ||
      this.CardNumber.startsWith('53') ||
      this.CardNumber.startsWith('54') ||
      this.CardNumber.startsWith('55')
    ) {
      this.CardSrc = 'assets/img/mastercard.png';
    } else if (
      this.CardNumber.startsWith('34') ||
      this.CardNumber.startsWith('37')
    ) {
      this.CardSrc = 'assets/img/americanexpress.png';
    } else {
      this.CardSrc = '';
    }
  }

  finishAsExecuted() {
    let identify: number = new Date().getTime();
    this.ordHerder.totalSale = this.totalOrder;
    let result: string = '';
    result += '<XmlSend><identify>';
    result += identify;
    result += '</identify><user>';
    result += 'HOEX00';
    result += '</user><businessData><![CDATA[';
    result += this.ordHerder.toXML();
    result += ']]></businessData></XmlSend>';
    return result;
  }

  dismiss() {
    this.navCtrl.pop();
  }

  async openPaymentForm(range: number) {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: PaymentFormComponent,
      componentProps: {
        amount: this.amount,
        orderid: this.orderid,
        custid: this.custid,
        app: this.app,
      },
    });

    popover.present();

    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data) {
        if (data == '1') {
          // Approved Transaction
          this.webService
            .setPaymentReceipt(this.amount, this.custid, this.orderid)
            .subscribe((data) => {
              if (data == 'SUCCESS') {
                this.webService
                  .sendXML(this.finishAsExecuted())
                  .subscribe((data) => {
                    this.presentAlert(
                      'Orden Finalizada',
                      'Su orden fue enviada correctamente, recuerde revisar el estado de su orden para verificar el progreso de la misma.'
                    );
                  });
              }
            });
        }

        let popoverStatus = await this.popoverCtrl.create({
          cssClass: 'custom-popover',
          component: PaymentStatusComponent,
          componentProps: {
            amount: this.amount,
            orderid: this.orderid,
            custid: this.custid,
            response: data,
          },
        });

        popoverStatus.present();

        popoverStatus.onDidDismiss().then(async () => {
          if (data == '1') {
            this.presentAlert(
              'Orden Finalizada',
              'Su orden fue enviada correctamente, recuerde revisar el estado de su orden para verificar el progreso de la misma.'
            );
          } else {
            let toast = await this.toastCtrl.create({
              message:
                'No se ha registrado ningún pago satisfactorio para la órden ' +
                this.ordHerder.salesId,
              buttons: ['Ok'],
              duration: 5000,
            });
            toast.present();
          }
        });
      } else {
        let toast = await this.toastCtrl.create({
          message:
            'Proceso de pago cancelado! No se ha registrado ningún pago para la órden ' +
            this.ordHerder.salesId,
          buttons: ['Ok'],
          duration: 5000,
        });
        toast.present();
      }
    });

    this.sendSlideBack(range);
  }

  async checkUnlock(evt: Event) {
    let theRange = Number(this.input.nativeElement.value);
    if (
      evt.type == 'touchend' &&
      theRange === 100 &&
      !this.isDefaultPaymentTypeSelected()
    ) {
      if (this.paymentType == 'Transfer') {
        this.ordHerder.setAditionalInstruction('transferencia');
      } else {
        this.ordHerder.setAditionalInstruction('tarjeta');
      }

      if (this.totalOrder < this.minimunAmount) {
        let alert = await this.alert.create({
          header: 'Error Monto Mínimo',
          message:
            'El monto mínimo de una compra es de ₡' +
            this.minimunAmount +
            ', debe agregar más artículos al carrito!',
          buttons: [
            {
              text: 'Aceptar',
              role: 'cancel',
              handler: () => {
                this.sendSlideBack(theRange);
              },
            },
          ],
        });
        alert.present();
        return;
      }
      if (!this.isCreditCardSelected()) {
        let alert = await this.alert.create({
          header: 'Finalización de la orden',
          message: 'Desea confirmar la orden y enviarla al sistema?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              handler: () => {
                this.sendSlideBack(theRange);
              },
            },
            {
              text: 'OK',
              handler: () => {
                this.purchase();
              },
            },
          ],
        });

        alert.present();
      } else {
        // credit card is selected
        this.openPaymentForm(theRange);
      }
    } else {
      this.sendSlideBack(theRange);
    }
  }

  sendSlideBack(theRange: number) {
    this.setIntID = setInterval(() => {
      if (this.input.nativeElement.value > 0) {
        theRange--;
        this.input.nativeElement.value = theRange--;
      } else {
        this.input.nativeElement.value = 0;
        clearInterval(this.setIntID);
      }
    });
  }

  /*
  presentCardScanner() {
    this.cardIO.canScan()
      .then(
        (res: boolean) => {
          if (res) {
            let options = {
              requireExpiry: true,
              requireCVV: true
            };

            this.cardIO.scan(options).then(data => {
              this.cvv = data.cvv;
              this.month = data.expiryMonth.toString();
              this.year = data.expiryYear.toString();
              this.CardNumber = data.redactedCardNumber;

            }).catch(err => {
              this.alert.create({
                title: 'Error',
                subTitle: 'Los datos de la tarjeta NO fueron capturados.',
                enableBackdropDismiss: false
              }).present();
            });
          }
        }
      );
  } */

  goHome() {
    this.storage.remove('carrito').then((data) => {
      this.globalEventsService.publish(GlobalEvents.UPDATE_CART);

      this.storage.set('carrito', null).then((st) => {
        this.navCtrl.pop(); // Shopping-Car-Payment
        this.navCtrl.pop(); // Shopping-Car-Delivery
        this.navCtrl.pop(); // Shopping-Car
      });
    });
  }

  async presentAlert(title: string, msg: string) {
    let alert = await this.alert.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            this.goHome();
          },
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }
}
