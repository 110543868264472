<div class="box">
  <div class="barTitle">
    <h5
      style="
        font-size: 1rem;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
      "
    >
      ENTREGAS
    </h5>
  </div>
  <div class="content">
    <ion-item (click)="emitSelectedBar(1)">
      <ion-label>
        <h2>Sitio</h2>
        <p>{{ sitioSelected.getName() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>
    <ion-item (click)="emitSelectedBar(2)">
      <ion-label>
        <h2>Almacén</h2>
        <p>{{ almacenSelected.getId() }}</p>
      </ion-label>
      <ion-icon *ngIf="cantEdit" name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item class="timer" *ngIf="maxDate">
      <ion-label><h2>Fecha de Entrega</h2></ion-label>

      <ion-datetime-button datetime="datetime"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetime"
            presentation="date"
            [showDefaultButtons]="true"
            [disabled]="!cantEdit"
            displayFormat="DD/MM/YYYY"
            [(ngModel)]="entregaDayS"
            placeholder="Seleccione la fecha"
            doneText="Aceptar"
            cancelText="Cancelar"
            (ionChange)="emitDays()"
            [max]="maxDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
  </div>
</div>
