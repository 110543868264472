export class OrderTraceItem {
  salesId: string;
  salesAxId: string;
  accountNum: string;
  customerId: string;
  addressName: string;
  createdDatetime: Date;
  createdDatetimeString: string;
  employeeId: string;
  deliveryDate: Date;
  deliveryDateString: string;
  salesStatus: number;
  salesStatusString: string;
  totalSales: number;
  states: { icon: string; state: string; class: string }[] = [];
  paymentReceiptId: string;

  statusToText() {
    switch (this.salesStatus) {
      case 0:
        return 'Indefinido';
      case 1:
        return 'Creada';
      case 2:
        return 'Recibida';
      case 3:
        return 'PosPuesta';
      case 4:
        return 'Cancelada';
      case 5:
        return 'Aprobada';
      case 6:
        return 'Facturada';
      case 7:
        return 'Recibida por Transportista';
      case 8:
        return 'Entregada totalmente';
      case 9:
        return 'Entregada Parcialmente';
      case 10:
        return 'Devuelta totalmente';
      case 11:
        return 'Entrega Postergada';
      default:
        return 'Desconocido';
    }
  }

  IconOnStatus() {
    switch (this.salesStatus) {
      case 0:
        return 'fas fa-question'; //Indefinido
      case 1:
        return 'fas fa-save'; //Creada
      case 2:
        return 'fas fa-receipt'; //Recibida
      case 3:
        return 'fas fa-question'; //PosPuesta
      case 4:
        return 'fas fa-ban'; //Cancelada
      case 5:
        return 'far fa-handshake'; //Aprobada
      case 6:
        return 'fas fa-file-invoice'; //Facturada
      case 7:
        return 'fas fa-truck'; //recibida por transportista
      case 8:
        return 'fas fa-star'; //entregada totalmente
      case 9:
        return 'fas fa-star-half-alt'; //entregada parcialmente
      case 10:
        return 'fas fa-times-circle'; //devuelta totalmente
      case 11:
        return 'far fa-clock'; //Entrega postergada
      default:
        return 'fas fa-question-circle';
    }
  }
}
