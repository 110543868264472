// Native - Plugins

import { Component } from '@angular/core';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';

// BusinessCore
import { Direction } from '../../businessCore/Direction';
import { Canton } from './../../businessCore/Canton';
import { City } from './../../businessCore/City';
import { District } from './../../businessCore/District';
import { Province } from './../../businessCore/Province';

// Provider
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-new-direction',
  templateUrl: './new-direction.component.html',
  styleUrls: ['./new-direction.component.scss'],
})
export class NewDirectionComponent {
  // Informacion de la direccion que el usuario va registrar
  direction_data: Direction = new Direction();

  // Listas de localizacion utilizadas para realizar el population de los select
  province_list: any = [];
  canton_list: any = [];
  district_list: any = [];
  city_list: any = [];

  // Atributo utilizado para controlar la creación o actualización de una direccion
  // ya que este componente es utilizado como un popup el cual se le carga
  // el formulario de registro de direcciones
  edit_direction: boolean;

  loadedOnce: boolean = false;

  /**
   * Class constructor with these injections:
   * @param _apiProvider web service provider
   * @param viewController Access various features and information about the current view.
   * @param params object that exists on a page and can contain data for that particular view.
   */
  constructor(
    private _apiProvider: webServiceProvider,
    private viewController: PopoverController,
    public params: NavParams,
    public alertCtrl: AlertController
  ) {
    this.direction_data = params.get('address') as Direction;
    console.log(this.direction_data);
    this.edit_direction = this.direction_data.edit;
    this.setProvince();
  }

  /**
   * Metodo utilizado para hacer efectivo el agregado de una direccion
   * Esto se logra por medio del dismiss de este componente que es cargado dentro de
   * un popup, enviando los datos de la direccion que se desea registrar
   */
  addDirection() {
    // this.alertCtrl.create({
    //   title: 'Confirmación de información',
    //   subTitle: 'Su dirección es lo suficientemente específica? Si no lo es por favor Editarla!',
    //   message: 'Ejemplo: 125 mts este del SENARA, frente al restaurante MiSabor, casa #25 con portones verdes.',
    //   buttons: [
    //     {
    //       text: 'Confirmar',
    //       handler: () => {
    //         this.viewController.dismiss(this.direction_data);
    //       }
    //     },
    //     {
    //       text: 'Editar',
    //       role: 'cancel'
    //     }
    //   ]
    // }).present();

    this.viewController.dismiss(this.direction_data);
  }

  /**
   * Metodo utilizado para reaizar un DISTINCT respecto a la lista
   * de distritos consultados desde el API, ya que la consulta retorna
   * distritos repetidos y los mismos deben ser cargados dentro de un select
   */
  get_distinct_districts() {
    const unique_list = [];
    for (let index = 0; index < this.district_list.length; index++) {
      const element = this.district_list[index];
      let current_district_Id = element.DistrictId;
      let is_unique = true;

      unique_list.forEach((data) => {
        if (data.DistrictId == current_district_Id) {
          is_unique = false;
        }
      });

      if (is_unique) {
        unique_list.push(element);
      }
    }
    return unique_list;
  }

  /**
   * Metodo utilizado para consultar todas las provincias asociadas a un pais
   */
  setProvince() {
    this._apiProvider
      .getProvince(this.direction_data.country)
      .subscribe((data) => {
        this.province_list = data as Province[];
        if (Object.keys(this.province_list).length) {
          if (!this.edit_direction) {
            this.direction_data.province = this.province_list[0].ProvinceId;
          }
          this.setCanton();
        }
      });
  }

  /**
   * Metodo utilizado para consultar todos los cantones asociados a una provincia
   */
  setCanton() {
    this._apiProvider
      .getCanton(this.direction_data.country, this.direction_data.province)
      .subscribe((data) => {
        this.canton_list = data as Canton[];
        if (Object.keys(this.canton_list).length) {
          if (!this.edit_direction) {
            this.direction_data.canton = this.canton_list[0].CantonId;
          }
          this.setDistrict();
        }
      });
  }

  /**
   * Metodo utilizado para consultar todos los distritos asociados a un canton
   */
  setDistrict() {
    this._apiProvider
      .getDistrict(
        this.direction_data.country,
        this.direction_data.province,
        this.direction_data.canton
      )
      .subscribe((data) => {
        this.district_list = data as District[];
        if (Object.keys(this.district_list).length) {
          if (!this.edit_direction) {
            this.direction_data.district = this.district_list[0].DistrictId;
          }
          this.district_list = this.get_distinct_districts();
          this.setCity();
        }
      });
  }

  /**
   * Metodo utilizado para consultar todas las ciudades asociadas a un distrito
   */
  setCity() {
    this._apiProvider
      .getCities(
        this.direction_data.country,
        this.direction_data.province,
        this.direction_data.canton,
        this.direction_data.district
      )
      .subscribe((data) => {
        this.city_list = data as City[];
        if (Object.keys(this.city_list).length) {
          if (!this.edit_direction) {
            this.direction_data.city =
              this.city_list[0].CityId != null ? this.city_list[0].CityId : '';
          }
        }
        this.edit_direction = false;

        if (!this.loadedOnce) {
          this.loadedOnce = true;
          this.setProvince();
        }
      });
  }

  /**
   * Metodo utilizado para verificar si un distrito cuenta con ciudades registradas o no
   * @returns boolean, con la confirmacion de que el distrito cuenta con ciudades registradas
   */
  emptyCities() {
    if (Object.keys(this.city_list).length == 1) {
      if (this.city_list[0].CityId == null) {
        return true;
      }
    }
    return false;
  }

  /**
   * Metodo utilizado para validar el formulario para que el mismo cuente con las restricciones
   * de registro solicitadas
   */
  validForm() {
    // if (this.direction_data.direction == "" || this.direction_data.direction.length < 40 || this.direction_data.directionName.length < 1) {
    return true;
  }
}
