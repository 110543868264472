import { Component } from '@angular/core';
import { NavController, NavParams, ToastController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { CaseCategoryType } from './../../businessCore/CaseCategoryType';
import { CustomerAddress } from './../../businessCore/CustomerAddress';
import { CustomerData } from './../../businessCore/CustomerData';
import { Direction } from './../../businessCore/Direction';
import { RegisterData } from './../../businessCore/RegisterData';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-my-profile-edit-latlng',
  templateUrl: './my-profile-edit-latlng.component.html',
  styleUrls: ['./my-profile-edit-latlng.component.scss'],
})
export class MyProfileEditLatlngComponent {
  title: string = '';
  imgUrl: string = '';
  customerInfoError: boolean = true;
  userEmailChecked: boolean = true;
  userEmail2Checked: boolean = false;
  loadingCustomerInfo: boolean = true;
  register_data: RegisterData = new RegisterData();
  customer_info: CustomerData = new CustomerData();
  addressInfo: { address: CustomerAddress; checked: boolean }[] = [];
  caseCategoryType: CaseCategoryType = new CaseCategoryType();

  direction: Direction = new Direction();

  /**
   *
   * @param navParams
   * @param storage
   * @param _apiProvider
   */
  constructor(
    public navParams: NavParams,
    public storage: StorageService,
    public _apiProvider: webServiceProvider,
    public toastCtrl: ToastController,
    public nav: NavController,
    private navService: NavigationService
  ) {
    this.title = this.navParams.get('title');
    this.imgUrl = this.navParams.get('imgUrl');
    this.caseCategoryType = this.navParams.get('caseCategoryType');

    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
        this._apiProvider
          .getCustomer(this.register_data.id_card)
          .subscribe((res) => {
            let data = res as Array<CustomerData>;
            if (data && data.length > 0 && data[0]) {
              this.customerInfoError = false;
              this.customer_info = data[0] as CustomerData;

              this.formatCustomerAddresses(
                this.customer_info.CustomerAddresses
              );
            } else {
              this.customerInfoError = true;
            }
            this.loadingCustomerInfo = false;
          });
      } else {
        this.customerInfoError = true;
        this.loadingCustomerInfo = false;
      }
    });
  }

  /**
   *
   */
  formatCustomerAddresses(customerAddresses: CustomerAddress[]) {
    let checked = true;
    customerAddresses.forEach((address) => {
      this.addressInfo.push({ address: address, checked: checked });
      checked = false;
    });
  }

  /**
   *
   * @param addressItem
   */
  checkItem(addressItem: { address: CustomerAddress; checked: boolean }) {
    let itemIndex = this.addressInfo.indexOf(addressItem);
    this.addressInfo.forEach((element, index) => {
      element.checked = false;
      if (index == itemIndex) {
        element.checked = true;
      }
    });
  }

  /**
   *
   */
  editLatLng() {
    let addressSelected = this.addressInfo.filter(
      (data) => data.checked == true
    );
    if (addressSelected.length == 1) {
      // Hay que cambiar AccountNum por Email, cuando el campo se devuelva en el Web Service

      this.editDirection(addressSelected[0].address);
    } else {
      this.presentToast('Debe seleccionar solamente una dirección.');
    }
  }

  /**
   * Metodo utilizado para presentar el popup de creacion de una nueva direccion
   * donde se registra los datos solicitados en el formulario
   * @param direction objeto con los campos que deben ser llenados para llevar
   * a cabo el registro de una nueva direccion
   */
  editDirection(dir: CustomerAddress) {
    let address = new Direction();
    address.edit = true;
    address.direction = dir.Address;
    address.directionName = dir.AddressName;
    address.latitude = dir.Latitude;
    address.longitude = dir.Longitude;
    address.is_principal = dir.IsPrimary == 1;
    // address.city = dir.City;

    this.navService.navigateTo(NavigationRoutes.GetPositionPage, {
      data: address,
      callback: this.getData,
    });
  }

  /**
   *
   */
  getData = (data) => {
    return new Promise<void>((resolve) => {
      this.direction = data as Direction;

      if (this.direction.directionName == '') {
        if (this.register_data.directions.length > 0) {
          this.direction.directionName =
            this.register_data.name +
            ' - ' +
            this.register_data.commercial_name +
            ' - #' +
            this.register_data.directions.length.toString();
        } else {
          this.direction.directionName =
            this.register_data.name +
            ' - ' +
            this.register_data.commercial_name;
        }
      }

      let notes =
        'GPS= ' +
        this.direction.latitude +
        ',' +
        this.direction.longitude +
        ' | Dir=' +
        this.direction.direction +
        ' | Dir Name=' +
        this.direction.directionName;

      let description = 'Editar dirección HE';

      this.sendCase(notes, description);

      resolve();
    });
  };

  /**
   *
   * @param notes
   * @param description
   */
  sendCase(notes: string, description: string) {
    let caseId = new Date().getTime().toString();
    let categoryId = this.caseCategoryType.RecId;
    let customerId = this.register_data.customerId;
    let ownerWorker = this.caseCategoryType.OwnerWorker;

    this._apiProvider
      .InsertCaseDetail(
        caseId,
        description,
        categoryId,
        customerId,
        ownerWorker
      )
      .subscribe((res) => {
        let data = res as string;
        if (data && data.length > 0) {
          let response = (data as string).split(',');
          if (response.length == 2) {
            this.presentToast('Solicitud creada con éxito!');
            let caseRecId = +response[1]; // revisar si el campo llega en null

            this._apiProvider
              .InsertCaseLog(caseRecId, description, notes)
              .subscribe(() => {});

            // entityType = 3 => cliente
            // entityType = 5 => prospectos
            // entityType = 7 => OVs
            // entityType = 11 => productos
            let entityType = 3;

            this._apiProvider
              .InsertCaseAssociation(caseRecId, entityType, customerId)
              .subscribe(() => {});
          } else {
            this.presentToast('La solicitud no se pudo crear!');
          }
        } else {
          this.presentToast('La solicitud no se pudo crear!');
        }
      });
  }

  /**
   *
   * @param msg
   */
  async presentToast(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: ['Ok'],
    });
    toast.present();
  }
}
