<ion-content>
  <h1 *ngIf="typeAction == 2" class="title">
    ¿Desea rechazar estas certificaciones para activos fijo?
  </h1>
  <h1 *ngIf="typeAction == 1" class="title">
    ¿Desea aprobar estas certificaciones para activos fijo?
  </h1>
  <div class="box">
    <div class="content">
      <ion-card>
        <ion-card-content>
          <ion-list>
            <p *ngFor="let cert of certList" text-wrap>
              - {{ cert.getWorkerName() }}: {{ cert.getCertificatedPeriod() }}
            </p>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </div>
    <div class="flex-container nowrap infContent atRight">
      <ion-button color="grayTwo" (click)="dismiss(0)"> Atrás </ion-button>
      <ion-button *ngIf="typeAction == 2" (click)="dismiss(1)" color="danger">
        Rechazar
      </ion-button>
      <ion-button *ngIf="typeAction == 1" ion-button (click)="dismiss(1)">
        Aprobar
      </ion-button>
    </div>
  </div>
</ion-content>
