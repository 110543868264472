<div *ngIf="!codeByEmail.tries">
  <h3 style="text-align: center">{{ contactEmail?.title }}</h3>

  <ion-item class="madeUp-select">
    <div item-content class="forInputs" style="width: 100%">
      <ion-label style="width: 100%">
        <h2 class="title-info">{{ contactEmail?.email }}</h2>
      </ion-label>
      <ion-input [(ngModel)]="newMail.email" [placeholder]="" required>
      </ion-input>
    </div>
  </ion-item>

  <ion-item lines="none">
    <ion-checkbox
      style="margin-right: 3px; margin-left: 0px"
      [(ngModel)]="newMail.main" labelPlacement="end"
    >Correo para notificación de pago</ion-checkbox>
    <ion-label style="font-size: 14px"
      ></ion-label
    >
    

  </ion-item>

  <div style="text-align: center">
    <ion-button
      style="width: 97%"
      [disabled]="!validateEmail()"
      (click)="confirmEmail()"
    >
      {{ contactEmail?.add }}
    </ion-button>
  </div>
</div>

<div *ngIf="codeByEmail.tries">
  <h3 style="text-align: center">{{ contactEmailConfir.title }}</h3>
  <p style="margin: 14px">{{ contactEmailConfir.text }}</p>

  <ion-item class="madeUp-select">
    <div item-content class="forInputs" style="width: 100%">
      <ion-label style="width: 100%">
        <h2 class="title-info">{{ contactEmailConfir.password }}</h2>
      </ion-label>
      <ion-input [(ngModel)]="code" type="number" [placeholder]=""></ion-input>
    </div>
  </ion-item>
  <p
    style="color: red; margin-left: 16px; margin-right: 16px"
    *ngIf="codeByEmail.tries != 3"
  >
    La contraseña digitada es incorrecta, tiene {{ codeByEmail.tries }} intentos
    más.
  </p>
  <br />
  <div style="text-align: center">
    <ion-button style="width: 97%" ion-button (click)="validateCode()">
      {{ contactEmailConfir.add }}
    </ion-button>
  </div>
</div>
