import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-use-terms',
  templateUrl: './use-terms.component.html',
  styleUrls: ['./use-terms.component.scss'],
})
export class UseTermsComponent {
  showHeader: boolean = true;

  constructor(public navParams: NavParams) {
    if (this.navParams.get('showHeader') != null) {
      this.showHeader = this.navParams.get('showHeader');
    }
  }
}
