<ion-content>
  <div class="box">
    <div class="content">
      <div style="margin-top: 2rem; margin-bottom: 1rem; text-align: center">
        <h3 style="margin-top: 0; margin-bottom: 0; display: inline">
          {{ paymentLanguage["title"] }}
        </h3>
        <div class="languageTag" style="display: inline">
          <p
            style="
              margin-top: 0px;
              margin-bottom: 0px;
              vertical-align: top;
              font-size: 13px;
            "
            (click)="changeLanguage()"
          >
            {{ changeLanguageTo }}
          </p>
        </div>
      </div>

      <!-- Proveedor de -->
      <ion-item class="madeUp-select" (click)="selectMenuOption(0)">
        <ion-label>
          <h2 class="title-info required">
            {{ paymentLanguage["paymentTerms"] }}
          </h2>
          <h3>
            {{ paymentTermSelected["paymTermId"] }}
            {{ paymentTermSelected["description"] }}
          </h3>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>

      <ion-item class="madeUp-select" (click)="selectMenuOption(1)">
        <ion-label>
          <h2 class="title-info required">
            {{ paymentLanguage["bankGroups"] }}
          </h2>
          <h3>{{ bankSelected["id"] }} {{ bankSelected["name"] }}</h3>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>

      <ion-item class="madeUp-select" (click)="selectMenuOption(2)">
        <ion-label>
          <h2 class="title-info required">
            {{ paymentLanguage["currency"] }}
          </h2>
          <h3>
            {{ currencySelected["currencyCodeId"] }}
            {{ currencySelected["txt"] }}
          </h3>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>

      <!-- Cuenta bancaria -->
      <ion-item class="madeUp-select">
        <div item-content class="forInputs" style="width: 100%">
          <ion-label style="width: 100%">
            <h2 class="title-info required">
              {{ paymentLanguage["accountNumber"] }}
            </h2>
          </ion-label>
          <ion-input
            [(ngModel)]="bankAccount"
            [placeholder]="paymentLanguage['placeholder']"
            required
          >
          </ion-input>
        </div>
      </ion-item>

      <!-- Número ABA -->
      <ion-item class="madeUp-select">
        <div item-content class="forInputs" style="width: 100%">
          <ion-label style="width: 100%">
            <h2 class="title-info">{{ paymentLanguage["ABANumber"] }}</h2>
          </ion-label>
          <ion-input
            [(ngModel)]="ABANumber"
            [placeholder]="paymentLanguage['placeholder']"
            type="text"
            minlength="9"
            maxlength="9"
          ></ion-input>
        </div>
      </ion-item>

      <!-- SWIFT -->
      <ion-item class="madeUp-select">
        <div item-content class="forInputs" style="width: 100%">
          <ion-label style="width: 100%">
            <h2 class="title-info">{{ paymentLanguage["SWIFTCode"] }}</h2>
          </ion-label>
          <ion-input
            [(ngModel)]="SWIFTCode"
            [placeholder]="paymentLanguage['placeholder']"
            type="text"
            minlength="8"
            maxlength="11"
          >
          </ion-input>
        </div>
      </ion-item>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <div class="whiteRectangle">
    <ion-fab horizontal="start" vertical="bottom">
      <ion-fab-button size="small" (click)="changeScreen(1)"
        ><ion-icon name="chevron-back-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>

<div class="infContent">
  <div class="whiteRectangle">
    <ion-fab horizontal="end" vertical="bottom">
      <ion-fab-button
        (click)="changeScreen(3)"
        [disabled]="disableNext()"
        size="small"
      >
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>
