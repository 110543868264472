import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-nota-trip',
  templateUrl: './nota-trip.component.html',
  styleUrls: ['./nota-trip.component.scss'],
})
export class NotaTripComponent implements OnInit {
  note: string;
  constructor(private params: NavParams, public viewCtrl: PopoverController) {}

  ngOnInit() {
    const dataMensj = this.params.get('descrip');
    dataMensj !== null && dataMensj !== undefined
      ? (this.note = dataMensj)
      : (this.note = '');
  }

  sendNotePurchOrder() {
    this.viewCtrl.dismiss(this.note);
  }

  close() {
    this.viewCtrl.dismiss(null);
  }
}
