<ion-card *ngIf="checkListIns && checkListIns.showInstance">
  <ion-card-content>
    <ion-item-sliding (swipe)="swipeEvent($event)">
      <ion-item (click)="openInfoCheckList()" [ngClass]="getStyle()">
        <ion-grid>
          <ion-row>
            <ion-col class="noPadding" style="display: flex; align-items: center;">
              <h6 ion-text class="h73">
                {{checkListIns.position}} - Checklist
              </h6>
              <img
                src="assets/img/diskette.svg"
                *ngIf="checkListIns.getInLocal()"
                style="width: 15px"
                alt=""
                onerror=""
              />
            </ion-col>
            <ion-col class="noPadding" style="text-align: right;">
              <label>{{dateExec | date: 'dd/MM/yyyy HH:mm' }}</label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <label text-wrap> {{checkListIns.getDescription()}} </label>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="lastDateExec != ''">
            <ion-col class="noPadding" style="display: flex; align-items: center;">
              <h6 ion-text class="h73">Fecha de expiración:</h6>
            </ion-col>
            <ion-col class="noPadding" style="text-align: right;">
              <label>{{ lastDateExec | date: 'dd/MM/yyyy HH:mm' }}</label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item-options side="end" *ngIf="canOmit()">
        <ion-item-option color="danger" (click)="goToOmit()">
          <ion-icon name="close"></ion-icon>
          <span style="font-size: 8px">Omitir</span>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-card-content>
</ion-card>
