<app-nav-bar [title]="'Mis Solicitudes'"></app-nav-bar>

<ion-content class="ion-padding" text-center>
  <!-- <h3 class="title2" margin-bottom>Historial</h3> -->

  <div style="text-align: center" *ngIf="loadingCases">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando las Solicitudes</h6>
  </div>

  <div
    style="text-align: center; margin-top: 50px"
    *ngIf="!loadingCases && without_Cases"
  >
    <h6
      ion-text
      text-center
      style="font-family: sans-serif; margin-top: 10px; font-size: 14px"
    >
      El usuario aún no cuenta con solicitudes registradas.
    </h6>
  </div>

  <ion-list
    style="display: flex; flex-direction: column; justify-content: center"
  >
    <ion-card
      *ngFor="let case of caseDetail"
      style="background: transparent; width: 95%"
    >
      <ion-item-sliding
        style="border-radius: 5px; overflow: hidden"
        (swipe)="swipeEvent($event, case)"
      >
        <ion-item
          lines="none"
          style="border-right: 2px solid; font-size: 12px; margin: 5px"
          [ngStyle]="{ 'border-right-color': getStatusColor(case.Status) }"
        >
          <div class="flexBlockContainer" style="width: 100%">
            <div class="flexContainer" style="justify-content: center">
              <div style="width: 50%; font-size: 14px">{{ case.CaseId }}</div>
              <div
                style="
                  width: 50%;
                  text-align: right;
                  text-decoration: underline;
                  font-size: 11px;
                "
              >
                {{ getStatus(case.Status) }}
              </div>
            </div>
            <div style="margin-top: 5px; margin-bottom: 5px">
              Fecha de creación: {{ case.CreatedDateTime }}
            </div>
            <div style="text-align: justify">{{ case.Description }}</div>
          </div>
        </ion-item>
      </ion-item-sliding>
    </ion-card>
  </ion-list>
</ion-content>

<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button
    color="primary"
    class="submit-btn"
    type="submit"
    size="small"
    (click)="addCase()"
  >
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>
