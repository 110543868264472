import { Worker } from './Worker';

export class Certification {
  petitionerRecId: string;
  workerRecId: string;
  approverRecId: string;
  hcmPositionRecId: string;
  approvalDate: Date;
  petitionDate: Date;
  certificatedPeriod: string;
  certificationId: string;
  status: number;
  periodType: number;
  strPeriodDate: string;

  workerName: string;
  workerDepartment: string;
  workerDepartmentName: string;
  workerEmail: string;
  assetQty: number;
  rewardAmount: string;
  certificationDate: string;
  documentLink: string;
  currency: string;
  periodStart: Date;
  recordRecId: string;

  //
  selected: boolean;

  getPetitionerRecId() {
    return this.petitionerRecId;
  }

  getWorkerRecId() {
    return this.workerRecId;
  }

  getApproverRecId() {
    return this.approverRecId;
  }

  getHcmPositionRecId() {
    return this.hcmPositionRecId;
  }

  getApprovalDate() {
    return this.approvalDate;
  }

  getPetitionDate() {
    return this.petitionDate;
  }

  getCertificatedPeriod() {
    return this.certificatedPeriod;
  }

  getCertificationId() {
    return this.certificationId;
  }

  getStatus() {
    return this.status;
  }

  getPeriodType() {
    return this.periodType;
  }

  getWorkerName() {
    return this.workerName;
  }

  getWorkerDepartment() {
    return this.workerDepartment;
  }

  getWorkerDepartmentName() {
    return this.workerDepartmentName;
  }

  getAssetQty() {
    return this.assetQty;
  }

  getRewardAmount() {
    return this.rewardAmount;
  }

  getCertificationDate() {
    return this.certificationDate;
  }

  getCurrency() {
    return this.currency;
  }

  getDocumentLink() {
    return this.documentLink;
  }

  getPeriodStart() {
    return this.periodStart;
  }

  getRecordRecId() {
    return this.recordRecId;
  }

  getWorkerEmail() {
    return this.workerEmail;
  }

  /**
   *
   * @param petitioner
   * @param worker
   * @param certificationPeriod
   * @param certificationId
   * @returns
   *
   * Nota: Se hace de esta forma, con una posibilidad de lista en caso de que sea requerido en
   * algún momento, por ejemplo en múltiples certificaciones (caso imaginado para distintos periodos)
   */
  parseCertificate(
    petitioner: Worker,
    worker: Worker,
    certificationPeriod: string,
    certificationId: string,
    strPeriodDate: string,
    assetQty: number
  ) {
    let certificate: Certification = new Certification();
    let certificateList: Certification[] = [];
    // for (let i = 0; i < certificateList.length; i++) { //
    certificate = new Certification();
    certificate.petitionerRecId = petitioner.getRecId();
    certificate.workerRecId = worker.getRecId();
    certificate.certificatedPeriod = certificationPeriod;
    certificate.certificationId = certificationId;
    certificate.strPeriodDate = strPeriodDate;
    certificate.assetQty = assetQty;
    certificateList.push(certificate);
    // }
    console.log(certificateList);
    return certificateList;
  }

  /**
   *
   * @param pApprover
   * @param pRequestCheckedList
   * @param pMotive
   * @returns
   */
  sortCertRecord(pApprover: Worker, pRequestCheckedList: Certification[]) {
    let insertDataList: Certification[] = [];
    let insertData: Certification;
    for (let i = 0; i < pRequestCheckedList.length; i++) {
      insertData = new Certification();
      insertData.recordRecId = pRequestCheckedList[i].getRecordRecId();
      insertData.approverRecId = pApprover.getRecId();
      insertDataList.push(insertData);
    }
    return insertDataList;
  }
}

export class CertFilterData {
  departments: any[];
  periods: any[];
  dates: any;
}
