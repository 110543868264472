<div>
  <ion-searchbar
    type="text"
    [(ngModel)]="filter.productName"
    placeholder="Buscar"
  ></ion-searchbar>
  <ion-list-header style="color: #223e99"> Familias </ion-list-header>
  <div class="flexContainer">
    <div
      class="familyContainer fImg"
      (click)="clickFilterOption(family)"
      *ngFor="let family of filter.families"
    >
      <img
        src="{{
          'https://serviceplan-api.mercasacr.com/images/Products/Families/' +
            family.title +
            '_ico.png'
        }}"
        [ngClass]="{ shadow: family.selected }"
      />
      <div class="h7">{{ replaceUnderScore(family.title) }}</div>
    </div>
  </div>
</div>

<ion-list *ngIf="displayPriceSection">
  <ion-list-header style="color: #223e99"> Rango de Precios </ion-list-header>
  <div
    style="display: flex; justify-content: space-around; align-items: center"
  >
    <div style="width: 50%" style="font-size: 11px" class="h7">
      Desde: ₡{{ structure.lower }}
    </div>
    <div style="width: 50%" style="font-size: 11px" class="h7">
      Hasta: ₡{{ structure.upper }}
    </div>
  </div>
  <ion-grid lines="none">
    <ion-row style="display: flex; align-items: center">
      <ion-col size="1"
        ><img src="assets/img/littleColon.png" alt="" onerror=""
      /></ion-col>
      <ion-col size="10">
        <ion-range
          id="colonRange"
          dualKnobs="true"
          pin="true"
          [(ngModel)]="structure"
          [min]="filter.minPrice"
          [max]="filter.maxPrice"
        >
        </ion-range
      ></ion-col>
      <ion-col size="1"
        ><img src="assets/img/colon.png" alt="" onerror=""
      /></ion-col>
    </ion-row>
  </ion-grid>
</ion-list>
<ion-grid>
  <ion-row>
    <ion-col>
      <div text-center clear>
        <ion-button
          style="width: 100%"
          text-color="#0000ff"
          (click)="dismissNull()"
        >
          <ion-icon style="margin: 0px 5px" name="trash"></ion-icon> Borrar
          Filtros
        </ion-button>
      </div>
    </ion-col>
    <ion-col>
      <div text-center>
        <ion-button
          style="width: 100%"
          text-color="#0000ff"
          (click)="dismiss()"
        >
          <ion-icon
            style="margin: 0px 5px"
            name="checkmark-done-outline"
          ></ion-icon
          >Filtrar
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
