<ion-content>
  <div class="box">
    <div class="content">
      <div style="margin-top: 2rem; margin-bottom: 1rem; text-align: center">
        <h3 style="margin-top: 0; margin-bottom: 0; display: inline">
          General
        </h3>
        <div class="languageTag" style="display: inline">
          <p
            style="
              margin-top: 0px;
              margin-bottom: 0px;
              vertical-align: top;
              font-size: 13px;
            "
            (click)="changeLanguage()"
          >
            {{ changeLanguageTo }}
          </p>
        </div>
      </div>

      <!-- Proveedor de -->
      <ion-item class="madeUp-select" (click)="selectMenuOption(0)">
        <ion-label>
          <h2 class="title-info required">
            {{ generalLenguage["vendorOf"] }}
          </h2>
          <h3>{{ dataAreaSelected["id"] }} {{ dataAreaSelected["name"] }}</h3>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>

      <!-- Tipo de cédula -->
      <ion-item class="madeUp-select" (click)="selectMenuOption(1)">
        <ion-label>
          <h2 class="title-info required">
            {{ generalLenguage["vatnumType"] }}
          </h2>
          <h3>{{ vendorIdType.getName() }}</h3>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>

      <!-- Cédula -->
      <ion-item class="madeUp-select">
        <div
          item-content
          style="width: 100%"
          [ngClass]="{
            forInputs: vendorIdType.getId() != '',
            disabled: vendorIdType.getId() == ''
          }"
        >
          <ion-label>
            <h2 class="title-info required">
              {{ generalLenguage["vatnum"] }}
            </h2>
          </ion-label>
          <ion-input
            *ngIf="vendorIdType.getMask() && vendorIdType.getId() == '0'"
            type="text"
            placeholder="#-####-####"
            mask="*-****-****"
            minlength="11"
            maxlength="11"
            (ionBlur)="setEntityName($event, 0, 0)"
            style="font-size: 1rem"
            [value]="vendorVatnum"
            required
          >
          </ion-input>

          <ion-input
            *ngIf="vendorIdType.getMask() && vendorIdType.getId() == '1'"
            type="text"
            placeholder="###########*"
            mask="********************"
            minlength="1"
            maxlength="20"
            (ionBlur)="setEntityName($event, 1, 0)"
            style="font-size: 1rem"
            [value]="vendorVatnum"
            required
          >
          </ion-input>

          <ion-input
            *ngIf="vendorIdType.getMask() && vendorIdType.getId() == '2'"
            type="text"
            placeholder="#-###-#####"
            mask="*-***-******"
            minlength="12"
            maxlength="12"
            (ionBlur)="setEntityName($event, 2, 0)"
            style="font-size: 1rem"
            [value]="vendorVatnum"
            required
          >
          </ion-input>
          <ion-input
            *ngIf="vendorIdType.getMask() && vendorIdType.getId() == '3'"
            type="text"
            placeholder="############"
            mask="************"
            minlength="12"
            maxlength="12"
            (ionBlur)="setEntityName($event, 3, 0)"
            style="font-size: 1rem"
            [value]="vendorVatnum"
            required
          >
          </ion-input>
          <ion-input
            *ngIf="vendorIdType.getMask() && vendorIdType.getId() == '4'"
            type="text"
            placeholder="#############"
            mask="*************"
            minlength="13"
            maxlength="13"
            (ionBlur)="setEntityName($event, 4, 0)"
            style="font-size: 1rem"
            [value]="vendorVatnum"
            required
          >
          </ion-input>
        </div>
      </ion-item>

      <!-- Nombre -->
      <ion-item class="madeUp-select">
        <div
          item-content
          style="width: 100%"
          [ngClass]="{
            disabled: validateVendorNameDisabled(),
            forInputs:
              (vendorIdType.getId() == '1' ||
                vendorIdType.getId() == '3' ||
                vendorIdType.getId() == '4') &&
              vendorVatnum.length
          }"
        >
          <ion-label style="width: 100%">
            <h2 class="title-info required">
              {{ generalLenguage["name"] }}
            </h2>
            <h3 *ngIf="validateVendorName()">
              {{ vendorName.getName() }}
            </h3>
          </ion-label>
          <ion-input
            *ngIf="
              (vendorIdType.getId() == '1' ||
                vendorIdType.getId() == '3' ||
                vendorIdType.getId() == '4') &&
              vendorVatnum.length
            "
            [placeholder]="generalLenguage['placeholder']"
            [(ngModel)]="vendorName.name"
            required
          >
          </ion-input>
        </div>
      </ion-item>

      <!-- Primer apellido -->
      <ion-item
        class="madeUp-select"
        *ngIf="vendorName.getFirstLastName()"
        [disabled]="vendorVatnum"
      >
        <ion-label style="width: 100%">
          <h3 class="title-info required">
            {{ generalLenguage["firstLastName"] }}
          </h3>
          <h3>{{ vendorName.getFirstLastName() }}</h3>
        </ion-label>
      </ion-item>

      <!-- Segundo apellido -->
      <ion-item
        class="madeUp-select"
        *ngIf="vendorName.getSecondLastName()"
        [disabled]="vendorVatnum"
      >
        <ion-label style="width: 100%">
          <h3 class="title-info required">
            {{ generalLenguage["secondLastName"] }}
          </h3>
          <h3>{{ vendorName.getSecondLastName() }}</h3>
        </ion-label>
      </ion-item>

      <!-- ######################################################################################  -->

      <!-- Tipo de cédula representante -->
      <ion-item class="madeUp-select" (click)="selectMenuOption(2)">
        <ion-label>
          <h2
            class="title-info"
            [ngClass]="{ required: vendorIdType.getId() == '2' }"
          >
            {{ generalLenguage["repVatnumType"] }}
          </h2>
          <h3>{{ representIdType.getName() }}</h3>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>

      <!-- Cédula representante legal -->
      <ion-item *ngIf="representIdType.getId()" class="madeUp-select">
        <div
          item-content
          style="width: 100%"
          [ngClass]="{
            forInputs: representIdType.getId() != '',
            disabled: representIdType.getId() == ''
          }"
        >
          <ion-label>
            <h2 class="title-info required">{{ generalLenguage["vatnum"] }}</h2>
          </ion-label>
          <ion-input
            *ngIf="representIdType.getMask() && representIdType.getId() == '0'"
            type="text"
            placeholder="#-####-####"
            mask="*-****-****"
            minlength="11"
            maxlength="11"
            (ionBlur)="setEntityName($event, 0, 1)"
            style="font-size: 1rem"
            [value]="representVatnum"
            required
          >
          </ion-input>
          <ion-input
            *ngIf="representIdType.getMask() && representIdType.getId() == '1'"
            type="text"
            placeholder="###########*"
            mask="********************"
            minlength="1"
            maxlength="20"
            (ionBlur)="setEntityName($event, 1, 1)"
            style="font-size: 1rem"
            [value]="representVatnum"
            required
          >
          </ion-input>

          <!-- <ion-input *ngIf="representIdType.getMask() && representIdType.getId()==2" type="text"
            placeholder="#-###-#####" mask="*-***-******" minlength="12" maxlength="12"
            (ionBlur)="setEntityName($event.value, 2, 1)" style="font-size: 1rem;" [value]="representVatnum" required>
          </ion-input> -->
          <ion-input
            *ngIf="representIdType.getMask() && representIdType.getId() == '3'"
            type="text"
            placeholder="############"
            mask="************"
            minlength="12"
            maxlength="12"
            (ionBlur)="setEntityName($event, 3, 1)"
            style="font-size: 1rem"
            [value]="representVatnum"
            required
          >
          </ion-input>
          <ion-input
            *ngIf="representIdType.getMask() && representIdType.getId() == '4'"
            type="text"
            placeholder="#############"
            mask="*************"
            minlength="13"
            maxlength="13"
            (ionBlur)="setEntityName($event, 4, 1)"
            style="font-size: 1rem"
            [value]="representVatnum"
            required
          >
          </ion-input>
        </div>
      </ion-item>

      <!-- Nombre representante legal -->
      <ion-item *ngIf="representIdType.getId()" class="madeUp-select">
        <div
          item-content
          style="width: 100%"
          [ngClass]="{
            disabled: validateRepresentNameDisabled(),
            forInputs:
              (representIdType.getId() == '1' ||
                representIdType.getId() == '3' ||
                representIdType.getId() == '4') &&
              representVatnum.length
          }"
        >
          <ion-label style="width: 100%">
            <h2 class="title-info required">{{ generalLenguage["name"] }}</h2>
            <h3 *ngIf="representName.getName() && validateRepresentName()">
              {{ representName.getName() }}
            </h3>
          </ion-label>
          <ion-input
            *ngIf="
              (representIdType.getId() == '1' ||
                representIdType.getId() == '3' ||
                representIdType.getId() == '4') &&
              representVatnum.length
            "
            [placeholder]="generalLenguage['placeholder']"
            [(ngModel)]="representName.name"
            required
          >
          </ion-input>
        </div>
      </ion-item>

      <!-- Primer apellido -->
      <ion-item
        class="madeUp-select"
        *ngIf="representName.getFirstLastName()"
        [disabled]="representVatnum"
      >
        <ion-label style="width: 100%">
          <h3 class="title-info required">
            {{ generalLenguage["firstLastName"] }}
          </h3>
          <h3>{{ representName.getFirstLastName() }}</h3>
        </ion-label>
      </ion-item>

      <!-- Segundo apellido -->
      <ion-item
        class="madeUp-select"
        *ngIf="representName.getSecondLastName()"
        [disabled]="representVatnum"
      >
        <ion-label style="width: 100%">
          <h3 class="title-info required">
            {{ generalLenguage["secondLastName"] }}
          </h3>
          <h3>{{ representName.getSecondLastName() }}</h3>
        </ion-label>
      </ion-item>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-fab horizontal="end" vertical="bottom">
    <ion-fab-button
      size="small"
      (click)="changeScreen()"
      [disabled]="disableNext()"
    >
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-footer>
