import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ManageUserKeysProvider {

  constructor() {}

  private manageUserKeyToXML(
    vatNum: string,
    dispositiveUserId: string,
    appId: string,
    OS: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
      <manageUserKey xmlns="http://tempuri.org/">
        <vatNum>` +
      vatNum +
      `</vatNum>
        <dispositiveUserId>` +
      dispositiveUserId +
      `</dispositiveUserId>
        <appId>` +
      appId +
      `</appId>
        <OS>` +
      OS +
      ` </OS>
      </manageUserKey>
    </s:Body>
    </s:Envelope>`
    );
  }

  async manageUserKey(
    vatNum: string,
    dispositiveUserId: string,
    appId: string,
    OS: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlAnswer = this.manageUserKeyToXML(
        vatNum,
        dispositiveUserId,
        appId,
        OS
      );
      console.log('XML_Ans_ ', xmlAnswer);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/manageUserKey'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'manageUserKeyResult'
            )[0].textContent;
            localStorage.setItem('WMS', JSON.stringify({ user: vatNum }));
            resolve(result);
          } else if (xmlRequest.status === 500) {
            localStorage.removeItem('WMS');
            resolve(500);
          } else if (xmlRequest.status === 0) {
            localStorage.removeItem('WMS');
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlAnswer);
    });
  }
}
