// Native - Plugins
import { Component } from '@angular/core';
import { Money } from './../../GeneralUtilis/money';

// BusinessCore
import { ConfirmOrderList } from './../../businessCore/ConfirmOrderList';
import { OrderTraceItem } from './../../businessCore/OrderTraceItem';
import { RegisterData } from './../../businessCore/RegisterData';

// Components

// Provider
import {
  AlertController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { OrderLine } from '../../businessCore/OrderLine';
import { OrderConfirmationInfoComponent } from '../order-confirmation-info/order-confirmation-info.component';
import { OrderConfirmationPassComponent } from '../order-confirmation-pass/order-confirmation-pass.component';
import { OrderTraceLinesComponent } from '../order-trace-lines/order-trace-lines.component';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent {
  orders: ConfirmOrderList[] = [];
  searchOrders = [];
  loadingOrders: boolean = true;

  shouldAnimate: boolean = false;
  registerData: RegisterData;

  shoppingCar: OrderLine[] = [];

  searchInput: string;

  /**
   * Class constructor with these injections:
   * @param popoverCtrl dialog that appears on top of the current page.
   * @param storage to store values/keys pairs and JSON objects.
   * @param _apiProvider web service provider
   * @param toastCtrl a subtle notification commonly used in modern applications.
   */
  constructor(
    public popoverCtrl: PopoverController,
    public storage: StorageService,
    private alertCtrl: AlertController,
    public _apiProvider: webServiceProvider,
    public toastCtrl: ToastController,
    private shoopingCartUtils: ShoopingCartUtils
  ) {
    // Consulta de la informacion de registro
    this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.registerData = JSON.parse(data) as RegisterData;
        this.getOrders(this.registerData.customerId);
      }
    });
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  displayShoppingCar() {
    this.shoopingCartUtils.displayShoppingCart();
  }

  async presentAlert(title: string, msg: string) {
    let al = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    al.present();
  }

  /**
   * Metodo utilizado para obtener todas las ordenes que se encuentran pendientes de confirmar
   * Es decir, el conjunto de pedidos por confirmar que han sido generados fuera
   * del app y que involucran el codigo del usuario.
   * @param customerId string con el codigo del usuario al que se le consultan las ordenes
   * pendientes de confirmacion
   */
  getOrders(customerId: string) {
    this._apiProvider.getOrdersToConfirm(customerId).subscribe((data) => {
      this.orders = data as ConfirmOrderList[];
      this.orders.forEach((element) => {
        let date = new Date(parseInt(element.CREATEDDATETIME.substr(6)));
        element.CREATEDDATETIME = date.toLocaleDateString();
      });
      this.initializeItems();
    });
  }

  /**
   * Metodo utilizado para inicializar el conjunto de ordenes por confirmar y
   * colocar el atributo de control de carga de ordenes en false.
   */
  initializeItems() {
    this.searchOrders = this.orders;
    this.loadingOrders = false;
  }

  /**
   * Metodo utilizado para filtrar las ordenes segun una barra de busqueda y el
   * valor ingresado en la misma
   * @param event
   */
  getFilteredItems(event) {
    if (this.shouldAnimate) {
      this.shouldAnimate = false;
    }

    this.initializeItems();
    let val = event.target.value;

    if (val && val.trim() != '') {
      this.searchOrders = this.searchOrders.filter((item) => {
        return (
          item.CREATEDDATETIME.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          item.SALESID.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          item.TOTAL.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    }
  }

  /**
   * Metodo utilizado para realizar el sliding hacia la derecha e izquierda
   * ejecutando la muestra de informacion del pedido o el despliegue de productos
   * segun la asociados a la orden direccion
   * @param event evento que contiene la direccion del sliding que se ejecutó
   * @param order objeto tipo ConfirmOrderList con la informacion de la orden a la que se
   * le ejecuta la accion
   */
  swipeEvent(event, order) {
    if (event.direction === 2) {
      // leftDirection - products
      this.presentProductsPopover(order);
    } else if (event.direction === 4) {
      // rightDirection - information
      this.presentInfoPopover(order);
    }
  }

  /**
   * Metodo utilizado para presentar un popup con la informacion de los productos que tiene
   * asociados una orden
   * @param order objeto tipo confirmOrderList con toda la informacion de la orden a la que
   * se le consultarán las líneas de productos asociados
   */
  async presentProductsPopover(order: ConfirmOrderList) {
    let param = new OrderTraceItem();
    param.salesAxId = order.SALESID;
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: OrderTraceLinesComponent,
      componentProps: { item: param },
    });
    popover.present();
  }

  /**
   * Metodo utilizado para presentar un popup con la informacion general
   * que tiene asociada una orden
   * @param order objeto tipo confirmOrderList con toda la informacion de la orden a la que
   * se le consultarán las líneas de productos asociados
   */
  async presentInfoPopover(order) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: OrderConfirmationInfoComponent,
      componentProps: { order: order },
    });
    popover.present();
  }

  /**
   * Metodo utilizado para presentar el popup de confirmacion de la orden
   * con los campos que se requieren para el registro de la confirmacion
   * @param order objeto tipo confirmOrderList con los datos de la orden que se desea confirmar
   * @param orderConfirmed booleano para indicar si la orden va ha ser confirmada o rechazada
   */
  async presentConfirmPopover(order, orderConfirmed: boolean) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: OrderConfirmationPassComponent,
      componentProps: {
        order: order,
        orderConfirmed: orderConfirmed,
      },
    });
    popover.present();

    popover.onDidDismiss().then((res) => {
      let data = res.data;
      let toast;
      if (data == 0) {
        toast = this.toastCtrl.create({
          message: 'Confirmación realizada con éxito',
          duration: 2000,
        });
        this.getOrders(this.registerData.customerId);
      } else if (data == 1) {
        toast = this.toastCtrl.create({
          message: 'Orden Rechaza con éxito',
          duration: 2000,
        });
        this.getOrders(this.registerData.customerId);
      } else {
        toast = this.toastCtrl.create({
          message: 'No se realizó la acción',
          duration: 2000,
        });
      }
      toast.present();
    });
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para validar el formulario de ordenes por confirmar
   * para saber cuando esta vacio o posee ordenes
   * @returns boolean con el estado(correcto/incorrecto) del formulario
   */
  validOrderState() {
    if (!this.loadingOrders && this.orders.length < 1) {
      return false;
    }
    return true;
  }
}
