import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TripModel } from '../../../businessCore/trip-models/trip';

@Component({
  selector: 'app-info-trips-pop',
  templateUrl: './info-trips-pop.component.html',
  styleUrls: ['./info-trips-pop.component.scss'],
})
export class InfoTripsPopComponent {
  titulo: string = '';
  objectList: TripModel[] = [];

  constructor(
    private viewCtrl: PopoverController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    const list = this.navParams.get('objectsList');
    const tit = this.navParams.get('titulo');
    if (list !== undefined && list !== null) {
      this.objectList = list as TripModel[];
    }
    if (tit !== undefined && tit !== null) {
      this.titulo = tit;
    }

    //DEBUG
    /*let x = new TripModel();
    x.setDescription('ajkshkajskdj');
    x.setFMTrip('ASLDKAL');
    x.setFecha(new Date());
    this.objectList = [x];
    this.titulo = 'ABC';*/
    //DEBUG
  }

  async closePopOver(obj: TripModel) {
    const resp = obj !== null && obj !== undefined ? obj.getFMTrip() : '';
    const resp2 = obj !== null && obj !== undefined ? obj.getInstance() : '';
    this.viewCtrl.dismiss({ tripNum: resp, instance: resp2 });
  }
}
