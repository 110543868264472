import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlmacenModel } from '../../businessCore/oc-models/AlmacenModel';
import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { CurrencyInfoModel } from '../../businessCore/oc-models/CurrencyInfoModel';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { ItemOCModel } from '../../businessCore/oc-models/ItemOCModel';
import { ItemOCInfoPriceModel } from '../../businessCore/oc-models/ItemOCPriceInfoModel';
import { LanguageModel } from '../../businessCore/oc-models/LanguageModel';
import { ProveedorModel } from '../../businessCore/oc-models/ProveedorModel';
import { PurchLine } from '../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { PurchPoolModel } from '../../businessCore/oc-models/PurchPoolModel';
import { PurposeAllowedByRole } from '../../businessCore/oc-models/PurposeAllowedByRole';
import { PurposeCategoriesAllowedByRole } from '../../businessCore/oc-models/PurposeCategoriesAllowedByRole';
import { RoleSpecifidInfo } from '../../businessCore/oc-models/RoleSpecifidInfo';
import { SitioModel } from '../../businessCore/oc-models/SitioModel';
import { InfoImage } from '../../businessCore/trip-models/InfoImage';
import { webServiceProvider } from '../webServiceProvider';
import { FileOCInfo } from './../../businessCore/oc-models/FileOCInfo';

@Injectable()
export class ExternalServiceOCService {
  //Axel Vargas
  triggerStopLoadingOC: boolean = false;
  //Axel Vargas

  constructor(
    private dataService: webServiceProvider,
    public _http: HttpClient
  ) {}

  // WebService que trae los datos de un proveedor para una orden de compra
  async getVendInfo(dataArea: string) {
    const vendList: ProveedorModel[] = [];
    const dataVend = await this.dataService.getVendInfo(dataArea);
    if (dataVend != null) {
      for (const vendInf of dataVend) {
        vendList.push(
          new ProveedorModel(
            vendInf.accountNum,
            vendInf.delName,
            vendInf.currency,
            vendInf.languageId,
            vendInf.regimenId
          )
        );
      }
    }
    return vendList;
  }

  // WebService que trae los datos de tipo de compra para una OC
  async getPurchPoolInfo(dataArea: string) {
    const purchPoolList: PurchPoolModel[] = [];
    purchPoolList.push(new PurchPoolModel('', ''));
    const dataPurchPool = await this.dataService.getPurchPoolInfo(dataArea);
    if (dataPurchPool != null) {
      for (const purchPoolInf of dataPurchPool) {
        purchPoolList.push(
          new PurchPoolModel(purchPoolInf.purchPoolId, purchPoolInf.name)
        );
      }
    }
    return purchPoolList;
  }

  // WebService que trae los datos de tipo de compra para una OC
  async getCurrencyInfo() {
    const currencyInfoList: CurrencyInfoModel[] = [];
    currencyInfoList.push(new CurrencyInfoModel('', ''));
    const dataCurrency = await this.dataService.getCurrencyInfo();
    if (dataCurrency != null) {
      for (const currencyInf of dataCurrency) {
        currencyInfoList.push(
          new CurrencyInfoModel(currencyInf.id, currencyInf.name)
        );
      }
    }
    return currencyInfoList;
  }

  async getPuertosInfo(dataArea: string) {
    const dataPuertos = await this.dataService.getIntrastatPort(dataArea);
    return this.processDataInf(dataPuertos);
  }

  async getNavierasInfo(dataArea: string) {
    const dataNavieras = await this.dataService.getIntrastatTransportPortMode(
      dataArea
    );
    return this.processDataInf(dataNavieras);
  }

  async processDataInf(dataInfo: any) {
    const infoList: CatalogModel[] = [];
    infoList.push(new CatalogModel(-1, ''));
    if (dataInfo != null) {
      for (const actInf of dataInfo) {
        infoList.push(new CatalogModel(actInf.id, actInf.name));
      }
    }
    return infoList;
  }

  async getLenguageInfo() {
    const languageInfoList: LanguageModel[] = [];
    languageInfoList.push(new LanguageModel(''));
    const dataLanguage = await this.dataService.getLenguageInfo();
    if (dataLanguage != null) {
      for (const currencyInf of dataLanguage) {
        const langId = currencyInf as string;
        languageInfoList.push(new LanguageModel(langId.toLowerCase()));
      }
    }
    return languageInfoList;
  }

  async getDataAreaInfo() {
    // Se traen los datos de espacios contratados
    const dataAreaInfList: DataArea[] = [];
    const dataAreaInfo = await this.dataService.getDataAreaInfo();
    if (dataAreaInfo !== null) {
      for (const areaInf of dataAreaInfo) {
        const tmpDataArea = areaInf as string; // ID, '_,,,_',NAME,'_,,,_',RECID
        const information = tmpDataArea.split('_,,,_');
        const nameP = information[1];
        const idP = information[0];
        dataAreaInfList.push(new DataArea(idP, nameP));
      }
    }
    return dataAreaInfList;
  }

  async getInventSiteInfo(dataArea: string) {
    // Se traen los datos de espacios contratados
    const dataInventSiteList: SitioModel[] = [];
    dataInventSiteList.push(new SitioModel('', ''));
    const dataInventSiteInfo = await this.dataService.getInventSiteInfo(
      dataArea
    );
    if (dataInventSiteInfo !== null) {
      for (const siteInf of dataInventSiteInfo) {
        const tmpDataSite = siteInf as string; // SiteId, '_,,,_',Name
        const information = tmpDataSite.split('_,,,_');
        const idSite = information[0];
        const nameSite = information[1];
        dataInventSiteList.push(new SitioModel(idSite, nameSite));
      }
    }
    return dataInventSiteList;
  }

  async getInventLocaInfo(inventSiteId: string, dataArea: string) {
    const dataInventLocalList: AlmacenModel[] = [];
    dataInventLocalList.push(new AlmacenModel('', ''));
    if (inventSiteId !== '' && dataArea !== '') {
      const dataInventLocalInfo = await this.dataService.getInventLocaInfo(
        inventSiteId,
        dataArea
      );
      if (dataInventLocalInfo !== null) {
        for (const localInf of dataInventLocalInfo) {
          const tmpDataLocal = localInf as string; // INVENTLOCATIONID,NAME
          const information = tmpDataLocal.split('_,,,_');
          const nameLocation = information[1];
          const idLocation = information[0];
          dataInventLocalList.push(new AlmacenModel(idLocation, nameLocation));
        }
      }
    }
    return dataInventLocalList;
  }

  /**
   * Permite traer la informacion de los datos de los productos de un proveedor
   */
  async getDataItemByVendot(vendAccount: string, dataArea: string) {
    const listAll = await this.getAllItemsByVendor(vendAccount, dataArea);
    const listAcuerd = await this.getItemsByVendor(vendAccount, dataArea);

    // Combine list
    for (const itemA of listAcuerd) {
      const element = listAll.find(
        (itemAll) => itemAll.getId() === itemA.getId()
      );
      if (element !== undefined) {
        for (const unitItem of itemA.getListUnits()) {
          const unidTmp = element
            .getListUnits()
            .find((unidId) => unitItem.getId() === unidId.getId());
          if (unidTmp !== undefined) {
            // editar la unidad por la info del acuerdo
            unidTmp.amount = unitItem.amount;
            unidTmp.taxes = unitItem.taxes;
            unidTmp.percent = unitItem.percent;
          } else {
            // si la unidad no esta
            element.getListUnits().push(unitItem);
          }
        }
      }
    }

    return listAll;
  }

  async getAllItemsByVendor(vendAccount: string, dataArea: string) {
    const dataItemByVendInfo = await this.dataService.getAllItemsByVendor(
      vendAccount,
      dataArea
    );
    return this.processDataListItem(dataItemByVendInfo);
  }

  async getItemsByVendor(vendAccount: string, dataArea: string) {
    const dataItemByVendInfo = await this.dataService.getItemsByVendor(
      vendAccount,
      dataArea
    );
    return this.processDataListItem(dataItemByVendInfo);
  }

  processDataListItem(dataItemByVendInfo: any) {
    const dataItemByVendList: ItemOCModel[] = [];
    if (dataItemByVendInfo !== null) {
      for (const item of dataItemByVendInfo) {
        const tmpInfoItem = new ItemOCModel(item.accountRelation, item.itemId);
        tmpInfoItem.setName(item.itemName);
        for (const unidad of item.infoPrice) {
          tmpInfoItem
            .getListUnits()
            .push(
              new ItemOCInfoPriceModel(
                unidad.unitId,
                unidad.amount,
                unidad.percent,
                unidad.taxes,
                unidad.taxGroup
              )
            );
        }
        dataItemByVendList.push(tmpInfoItem);
      }
    }
    return dataItemByVendList;
  }

  // Crea el xml a mandar de la orden de compra
  toXML_OC(header: string, lines: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <SavePurchaseOrder xmlns="http://tempuri.org/">
        <purchaseHeader> ` +
      header +
      ` </purchaseHeader>
        <listOrder> ` +
      lines +
      ` </listOrder>
        </SavePurchaseOrder>
      </s:Body>
    </s:Envelope>`
    );
  }

  sendOC(header: string, lines: string) {
    // Se envia un XML con los datos de la OC
    return new Promise((resolve, reject) => {
      const xmlOC = this.toXML_OC(header, lines);
      //console.log('XML_OC_ ', xmlOC);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/SavePurchaseOrder'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const ocID = xmlRequest.responseXML.getElementsByTagName(
              'SavePurchaseOrderResult'
            )[0].textContent;
            resolve(ocID);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlOC);
    });
  }

  async getToBeApproved(dataArea: string, state: number) {
    // 1 --> Orden Abierta
    let resp: any;
    try {
      const XMLDocument = (await this.getPurchaseOrder(
        dataArea,
        state,
        '',
        '',
        '',
        1,
        '',
        ''
      )) as Document;
      resp = this.parsePurchaseOrder(XMLDocument);
    } catch (error) {
      resp = null;
    }
    return resp;
  }

  async getToBeConfirmed(vatNumVendor: string, state: number) {
    // 1 --> Orden Abierta
    let resp: any;
    try {
      const XMLDocument = (await this.getPurchaseOrder(
        '',
        state,
        '',
        '',
        vatNumVendor,
        1,
        '',
        ''
      )) as Document;
      resp = this.parsePurchaseOrder(XMLDocument);
    } catch (error) {
      resp = null;
    }
    return resp;
  }

  async getUpdateDataPO(
    dataArea: string,
    state: number,
    purchId: string,
    documentStatus: number,
    vatNumCreator: string,
    vatNumConfirmed: string
  ) {
    let resp: any;
    try {
      const XMLDocument = (await this.getPurchaseOrder(
        dataArea,
        state,
        purchId,
        '',
        '',
        documentStatus,
        vatNumCreator,
        vatNumConfirmed
      )) as Document;
      resp = this.parsePurchaseOrder(XMLDocument);
      for (let oc of resp as PurchOrder[]) {
        // Traer la informacion actual de las lineas
        const lines = await this.getToLinesOC(
          oc.getDataArea().getId(),
          oc.getIdOC(),
          oc.getTaxInclude()
        );
        if (lines) {
          //Si no hay errores
          oc.setLineList(lines);
          oc.calcularDatos(); //calcula los datos con respecto a total, subtotal, impuesto ...
        }

        if (this.triggerStopLoadingOC) {
          break;
        }
      }
    } catch (error) {
      resp = null;
    }
    return resp;
  }

  async getToLinesOC(dataArea: string, purchId: string, taxInclude: boolean) {
    let resp: any;
    try {
      const XMLDocument = (await this.getPurchaseLineOrder(
        dataArea,
        purchId
      )) as Document;
      resp = this.parsePurchaseLine(XMLDocument, taxInclude);
    } catch (error) {
      resp = null;
    }
    return resp;
  }

  toXML2P(
    dataArea: string,
    state: number,
    purchId: string,
    orderAccount: string,
    vatNumVendor: string,
    documentStatus: number,
    vatNumCreator: string,
    vatNumConfirmed: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
       <GetPurchaseOrder xmlns="http://tempuri.org/">
         <purchId>` +
      purchId +
      `</purchId>
         <dataArea>` +
      dataArea +
      `</dataArea>
         <state>` +
      state +
      `</state>
         <orderAccount>` +
      orderAccount +
      `</orderAccount>
         <vatNumVendor>` +
      vatNumVendor +
      `</vatNumVendor>
         <documentStatus>` +
      documentStatus +
      `</documentStatus>
         <vatNumCreator>` +
      vatNumCreator +
      `</vatNumCreator>
         <vatNumConfirmed>` +
      vatNumConfirmed +
      `</vatNumConfirmed>
       </GetPurchaseOrder>
      </s:Body>
    </s:Envelope>`
    );
  }

  getPurchaseOrder(
    dataArea: string,
    state: number,
    purchId: string,
    orderAccount: string,
    vatNumVendor: string,
    documentStatus: number,
    vatNumCreator: string,
    vatNumConfirmed: string
  ) {
    // Se envia un XML con los datos de la OC
    return new Promise((resolve, reject) => {
      const xmlOC = this.toXML2P(
        dataArea,
        state,
        purchId,
        orderAccount,
        vatNumVendor,
        documentStatus,
        vatNumCreator,
        vatNumConfirmed
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPurchaseOrder'
      );
      xmlRequest.send(xmlOC);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(xmlRequest.responseXML);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  getPurchaseLineOrderToXML(dataArea: string, purchId: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
       <GetLinesByPO xmlns="http://tempuri.org/">
         <purchId>` +
      purchId +
      `</purchId>
         <dataArea>` +
      dataArea +
      `</dataArea>
       </GetLinesByPO>
      </s:Body>
    </s:Envelope>`
    );
  }

  getPurchaseLineOrder(dataArea: string, purchId: string) {
    // Se envia un XML con los datos de la OC
    return new Promise((resolve, reject) => {
      const xmlOC = this.getPurchaseLineOrderToXML(dataArea, purchId);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetLinesByPO'
      );
      xmlRequest.send(xmlOC);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(xmlRequest.responseXML);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  /**
   *
   * @param xml
   * @returns retorna la lista de linea con el impuesto incluido en el total
   */
  parsePurchaseLine(xml: XMLDocument, taxInclude: boolean) {
    const listPurchaseLine: PurchLine[] = [];
    const dataInfo = xml
      .getElementsByTagName('GetLinesByPOResult')[0]
      .getElementsByTagName('a:PurchaseOrderLines');
    for (let position = 0; position < dataInfo.length; position++) {
      const entrada = dataInfo[position]
        .getElementsByTagName('a:Entrada')
        .item(0).textContent;
      const itemId = dataInfo[position]
        .getElementsByTagName('a:ItemId')
        .item(0).textContent;
      const name = dataInfo[position]
        .getElementsByTagName('a:Name')
        .item(0).textContent;
      const recId = dataInfo[position]
        .getElementsByTagName('a:RecId')
        .item(0).textContent;
      const unidId = dataInfo[position]
        .getElementsByTagName('a:purchUnit')
        .item(0).textContent;
      const precio = dataInfo[position]
        .getElementsByTagName('a:purchLinePurchPrice')
        .item(0).textContent;
      const porcentaje = dataInfo[position]
        .getElementsByTagName('a:purchLinePercent')
        .item(0).textContent;
      const taxPercent = dataInfo[position]
        .getElementsByTagName('a:TaxPorcent')
        .item(0).textContent;
      const A_Departamento = dataInfo[position]
        .getElementsByTagName('a:A_Departamento')
        .item(0).textContent;
      const B_CentroCosto = dataInfo[position]
        .getElementsByTagName('a:B_CentroCosto')
        .item(0).textContent;
      const C_Proposito = dataInfo[position]
        .getElementsByTagName('a:C_Proposito')
        .item(0).textContent;
      const typeTax = dataInfo[position]
        .getElementsByTagName('a:TaxItemGroup')
        .item(0).textContent;
      const tmpPurchaseLine = new PurchLine();
      if (recId != '') {
        tmpPurchaseLine.setRecId(Number(recId));
      }
      tmpPurchaseLine.taxes = Number(taxPercent);
      tmpPurchaseLine.setName(name);
      tmpPurchaseLine.itemId = itemId;
      tmpPurchaseLine.cantidad = parseInt(entrada);
      tmpPurchaseLine.unitId = unidId;
      tmpPurchaseLine.precio = Number(precio);
      tmpPurchaseLine.porcentDescuent = parseInt(porcentaje);
      tmpPurchaseLine.calculateInfo(taxInclude); // calcula la informacion con respecto al precio, impuesto, total ...

      tmpPurchaseLine.setA_Departamento(
        A_Departamento === ''
          ? null
          : new CatalogModel(A_Departamento as any, '')
      );
      tmpPurchaseLine.setB_CentroCosto(
        B_CentroCosto === '' ? null : new CatalogModel(B_CentroCosto as any, '')
      );
      tmpPurchaseLine.setC_Proposito(
        C_Proposito === '' ? null : new CatalogModel(C_Proposito as any, '')
      );
      tmpPurchaseLine.setTaxItemGroup(typeTax);

      listPurchaseLine.push(tmpPurchaseLine);
    }

    return listPurchaseLine;
  }

  parsePurchaseOrder(xml: XMLDocument) {
    const listPurchaseOrder: PurchOrder[] = [];
    const dataInfo = xml
      .getElementsByTagName('GetPurchaseOrderResult')[0]
      .getElementsByTagName('a:PurchaseOrderHeader');
    for (let position = 0; position < dataInfo.length; position++) {
      const accountNum = dataInfo[position]
        .getElementsByTagName('a:AccountNum')
        .item(0).textContent;
      const language = dataInfo[position]
        .getElementsByTagName('a:Language')
        .item(0).textContent;
      const currency = dataInfo[position]
        .getElementsByTagName('a:CurrencyCode')
        .item(0).textContent;
      const siteId = dataInfo[position]
        .getElementsByTagName('a:SiteId')
        .item(0).textContent;
      const almacenId = dataInfo[position]
        .getElementsByTagName('a:LocationId')
        .item(0).textContent;
      const dataAreaId = dataInfo[position]
        .getElementsByTagName('a:DataArea')
        .item(0).textContent;
      // const estado = dataInfo[position].getElementsByTagName('a:DocumentState').item(0).textContent;
      const estadoI = dataInfo[position]
        .getElementsByTagName('a:DocumentStateI')
        .item(0).textContent;
      const purchStatus = dataInfo[position]
        .getElementsByTagName('a:PurchStatus')
        .item(0).textContent;
      const idOC = dataInfo[position]
        .getElementsByTagName('a:PurchId')
        .item(0).textContent;
      const accountName = dataInfo[position]
        .getElementsByTagName('a:PurchName')
        .item(0).textContent;
      const note = dataInfo[position]
        .getElementsByTagName('a:PurchaseNotes')
        .item(0).textContent;
      const createdBy = dataInfo[position]
        .getElementsByTagName('a:CreatedBy')
        .item(0).textContent;
      const totalLine = dataInfo[position]
        .getElementsByTagName('a:QtyLine')
        .item(0).textContent;
      const total = dataInfo[position]
        .getElementsByTagName('a:SIT_PurchTotal')
        .item(0).textContent;
      const initDay = dataInfo[position]
        .getElementsByTagName('a:InitDateTime')
        .item(0).textContent;
      const endDay = dataInfo[position]
        .getElementsByTagName('a:EndDateTime')
        .item(0).textContent;
      const deliveryDay = dataInfo[position]
        .getElementsByTagName('a:DeliveryDate')
        .item(0).textContent;
      const purchPool = dataInfo[position]
        .getElementsByTagName('a:PurchPoolId')
        .item(0).textContent;
      const purposeName = dataInfo[position]
        .getElementsByTagName('a:PurposeName')
        .item(0).textContent;
      const countainerNum = dataInfo[position]
        .getElementsByTagName('a:ContainerNum')
        .item(0).textContent;
      const teus = dataInfo[position]
        .getElementsByTagName('a:TEUS')
        .item(0).textContent;
      const containerType = dataInfo[position]
        .getElementsByTagName('a:SIT_ContainerType')
        .item(0).textContent;
      const freeDays = dataInfo[position]
        .getElementsByTagName('a:FreeDays')
        .item(0).textContent;
      const port = dataInfo[position]
        .getElementsByTagName('a:Port')
        .item(0).textContent;
      const transport = dataInfo[position]
        .getElementsByTagName('a:Transport')
        .item(0).textContent;
      const salidadSoli = dataInfo[position]
        .getElementsByTagName('a:RequestDeparture')
        .item(0).textContent;
      const salidaArr = dataInfo[position]
        .getElementsByTagName('a:ArrivalReq')
        .item(0).textContent;
      const soliLlegadBod = dataInfo[position]
        .getElementsByTagName('a:SIT_RequestedArrivalWh')
        .item(0).textContent;
      const shipStatus = dataInfo[position]
        .getElementsByTagName('a:ShipStatus')
        .item(0).textContent;
      const lastEditingApp = dataInfo[position]
        .getElementsByTagName('a:LastEditingApp')
        .item(0).textContent;
      const A_Departamento = dataInfo[position]
        .getElementsByTagName('a:A_Departamento')
        .item(0).textContent;
      const B_CentroCosto = dataInfo[position]
        .getElementsByTagName('a:B_CentroCosto')
        .item(0).textContent;
      const C_Proposito = dataInfo[position]
        .getElementsByTagName('a:C_Proposito')
        .item(0).textContent;
      const nameCompany = dataInfo[position]
        .getElementsByTagName('a:DataAreaName')
        .item(0).textContent;
      const emailCompany = dataInfo[position]
        .getElementsByTagName('a:emailCompany')
        .item(0).textContent;
      const faxCompany = dataInfo[position]
        .getElementsByTagName('a:faxCompany')
        .item(0).textContent;
      const telfCompany = dataInfo[position]
        .getElementsByTagName('a:telfCompany')
        .item(0).textContent;
      const paymet = dataInfo[position]
        .getElementsByTagName('a:Payment')
        .item(0).textContent;
      const vatNum = dataInfo[position]
        .getElementsByTagName('a:VatNum')
        .item(0).textContent;
      const notaR = dataInfo[position]
        .getElementsByTagName('a:NotaRechazo')
        .item(0).textContent;
      const taxInfo = dataInfo[position]
        .getElementsByTagName('a:InclTax')
        .item(0).textContent;
      const createDate = dataInfo[position]
        .getElementsByTagName('a:CreatedDateTime')
        .item(0).textContent;
      const projecId = dataInfo[position]
        .getElementsByTagName('a:ProjId')
        .item(0).textContent;
      const typeOC = ''; //dataInfo[position].getElementsByTagName('a:TypePurchaseOrder').item(0).textContent;
      const incoterm = '';
      const tmpPurchaseOrder = new PurchOrder(
        accountNum,
        purchPool === '' ? null : new PurchPoolModel(purchPool as any, ''),

        language,
        currency,
        initDay.includes('1900-01-01T') ? null : new Date(initDay),
        endDay.includes('1900-01-01T') ? null : new Date(endDay),
        siteId === '' ? null : new SitioModel(siteId, ''),
        almacenId === '' ? null : new AlmacenModel(almacenId, ''),
        deliveryDay.includes('1900-01-01T') ? null : new Date(deliveryDay),
        Number(countainerNum),
        Number(teus),
        containerType === '' ? null : new CatalogModel(-1, containerType),
        Number(freeDays),
        salidadSoli.includes('1900-01-01T') ? null : new Date(salidadSoli),
        salidaArr.includes('1900-01-01T') ? null : new Date(salidaArr),
        soliLlegadBod.includes('1900-01-01T') ? null : new Date(soliLlegadBod),
        port === '' ? null : new CatalogModel(port as any, ''),
        transport === '' ? null : new CatalogModel(transport as any, ''),
        incoterm === '' ? null : new CatalogModel(-1, ''),
        '',
        shipStatus === '' ? null : new CatalogModel(Number(shipStatus), ''),
        note,
        new DataArea(dataAreaId, nameCompany),
        vatNum //Cedula de quien lo crea
      );
      // tmpPurchaseOrder.setDocumentState(estado.toUpperCase());
      tmpPurchaseOrder.setDocumentStateI(Number(estadoI));
      tmpPurchaseOrder.setPurchStatus(Number(purchStatus));
      tmpPurchaseOrder.setIdOC(idOC);
      tmpPurchaseOrder.setVendName(accountName);
      tmpPurchaseOrder.setCreatedBy(createdBy);
      tmpPurchaseOrder.cantidadLineas = parseInt(totalLine);
      tmpPurchaseOrder.total = Number(total);
      tmpPurchaseOrder.setTaxInclude(Number(taxInfo) === 1);
      tmpPurchaseOrder.setLastEditingAppDate(
        lastEditingApp.includes('1900-01-01T') ? null : new Date(lastEditingApp)
      );
      tmpPurchaseOrder.setFechaCreacion(
        createDate.includes('1900-01-01T') ? undefined : new Date(createDate)
      );

      tmpPurchaseOrder.setA_Departamento(
        A_Departamento === ''
          ? null
          : new CatalogModel(A_Departamento as any, '')
      );
      tmpPurchaseOrder.setB_CentroCosto(
        B_CentroCosto === '' ? null : new CatalogModel(B_CentroCosto as any, '')
      );
      tmpPurchaseOrder.setC_Proposito(
        C_Proposito === '' ? null : new CatalogModel(C_Proposito as any, '')
      );
      tmpPurchaseOrder.setTypeProject(
        projecId === '' ? null : new CatalogModel(projecId as any, '')
      );
      tmpPurchaseOrder.setTypePurchaseOrder(
        typeOC === '' ? null : new CatalogModel(typeOC as any, '')
      );
      tmpPurchaseOrder.setPurpose(
        purposeName === '' ? null : new CatalogModel(purposeName as any, '')
      );
      tmpPurchaseOrder.setEmailCompany(emailCompany);
      tmpPurchaseOrder.setFaxCompany(faxCompany);
      tmpPurchaseOrder.setTelfCompany(telfCompany);
      tmpPurchaseOrder.setPaymet(paymet);

      tmpPurchaseOrder.setNotaRechazo(notaR);

      listPurchaseOrder.push(tmpPurchaseOrder);
    }

    return listPurchaseOrder;
  }

  updateDocumentStateXML(
    purchId: string,
    newState: number,
    dataArea: string,
    vatNum: string,
    notaRechazo?: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
      <updatedDocumentState xmlns="http://tempuri.org/">
         <newState>` +
      newState +
      `</newState>
         <purchID>` +
      purchId +
      `</purchID>
         <dataArea>` +
      dataArea +
      `</dataArea>
         <nota>` +
      notaRechazo +
      `</nota>
         <VatNum>` +
      vatNum +
      `</VatNum>
       </updatedDocumentState>
      </s:Body>
    </s:Envelope>`
    );
  }

  updateDocumentState(
    purchId: string,
    newState: number,
    dataArea: string,
    vatNum: string,
    notaRechazo?: string
  ) {
    // Se envia un XML con los datos de la OC
    let notaInfo = notaRechazo ? notaRechazo : '';
    return new Promise((resolve, reject) => {
      const xmlOC = this.updateDocumentStateXML(
        purchId,
        newState,
        dataArea,
        vatNum,
        notaInfo
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/updatedDocumentState'
      );
      xmlRequest.send(xmlOC);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const stateAct = xmlRequest.responseXML.getElementsByTagName(
              'updatedDocumentStateResult'
            )[0].textContent;
            resolve(Number(stateAct));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  updateHeaderPurchaseToXML(purchHeader: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <updateHeaderPO xmlns="http://tempuri.org/">
          <purchHeader>` +
      purchHeader +
      `</purchHeader>
        </updateHeaderPO>
      </s:Body>
    </s:Envelope>`
    );
  }

  updateHeaderPurchase(purchaHeader: string) {
    // Se envia un XML con los datos de la OC
    return new Promise((resolve, reject) => {
      const xmlOC = this.updateHeaderPurchaseToXML(purchaHeader);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/updateHeaderPO'
      );
      xmlRequest.send(xmlOC);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const stateAct = xmlRequest.responseXML.getElementsByTagName(
              'updateHeaderPOResult'
            )[0].textContent;
            resolve(stateAct);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private updateLinePurchaseToXML(
    purchId: string,
    dataArea: string,
    purchLines: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <updateLinePO xmlns="http://tempuri.org/">
          <purchID>` +
      purchId +
      `</purchID>
          <dataArea>` +
      dataArea +
      `</dataArea>
          <purchLine>` +
      purchLines +
      `</purchLine>
        </updateLinePO>
      </s:Body>
    </s:Envelope>`
    );
  }

  updateLinePurchaseOrder(
    purchId: string,
    dataArea: string,
    purchLines: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlOC = this.updateLinePurchaseToXML(purchId, dataArea, purchLines);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/updateLinePO'
      );
      xmlRequest.send(xmlOC);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const stateAct =
              xmlRequest.responseXML.getElementsByTagName(
                'updateLinePOResult'
              )[0].textContent;
            resolve(stateAct);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private editingModeToXML(
    newState: number,
    purchID: string,
    dataArea: string,
    vatNum: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <editingMode xmlns="http://tempuri.org/">
          <newState>` +
      newState +
      `</newState>
          <purchID>` +
      purchID +
      `</purchID>
          <dataArea>` +
      dataArea +
      `</dataArea>
          <vatNum>` +
      vatNum +
      `</vatNum>
          <newDocumentState></newDocumentState>
        </editingMode>
      </s:Body>
    </s:Envelope>
    `
    );
  }

  editingMode(
    newState: number,
    purchID: string,
    dataArea: string,
    vatNum: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.editingModeToXML(
        newState,
        purchID,
        dataArea,
        vatNum
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/editingMode'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const stateAct =
              xmlRequest.responseXML.getElementsByTagName(
                'editingModeResult'
              )[0].textContent;
            resolve(stateAct);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private getLastEditingDateAppXML(purchID: string, dataArea: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getLastEditedDateAppPO xmlns="http://tempuri.org/">
          <purchID>` +
      purchID +
      `</purchID>
          <dataArea>` +
      dataArea +
      `</dataArea>
        </getLastEditedDateAppPO>
      </s:Body>
    </s:Envelope>
    `
    );
  }

  getLastEditingDateApp(purchID: string, dataArea: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getLastEditingDateAppXML(purchID, dataArea);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getLastEditedDateAppPO'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const stateAct = xmlRequest.responseXML.getElementsByTagName(
              'getLastEditedDateAppPOResult'
            )[0].textContent;
            const result = stateAct.includes('1900-01-01T')
              ? null
              : new Date(stateAct);
            if (result) {
              result.setHours(result.getHours() + 6);
            }
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private isEditingXML(purchID: string, dataArea: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <isEditing xmlns="http://tempuri.org/">
          <purchID>` +
      purchID +
      `</purchID>
          <dataArea>` +
      dataArea +
      `</dataArea>
        </isEditing>
      </s:Body>
    </s:Envelope>
    `
    );
  }

  isEditing(purchID: string, dataArea: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.isEditingXML(purchID, dataArea);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/isEditing'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const stateAct =
              xmlRequest.responseXML.getElementsByTagName('isEditingResult')[0]
                .textContent;
            resolve(stateAct);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private getFinancialDimensionByNameXML(dimensionName: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetDimensionFinancial xmlns="http://tempuri.org/">
          <dimensionName>` +
      dimensionName +
      `</dimensionName>
          </GetDimensionFinancial>
      </s:Body>
    </s:Envelope>
    `
    );
  }

  private getFinancialDimension(dimensionName: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getFinancialDimensionByNameXML(dimensionName);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetDimensionFinancial'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  processDataInfDimension(xml: XMLDocument) {
    const listFinancialDocument: CatalogModel[] = [];
    listFinancialDocument.push(new CatalogModel('' as any, ''));
    const dataInfo = xml
      .getElementsByTagName('GetDimensionFinancialResult')[0]
      .getElementsByTagName('a:DimensionFinancial');
    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:Description')
        .item(0).textContent;
      const FinancialTagCategory = dataInfo[position]
        .getElementsByTagName('a:FinancialTagCategory')
        .item(0).textContent;
      const value = dataInfo[position]
        .getElementsByTagName('a:Value')
        .item(0).textContent;
      const tmp = new CatalogModel(value as any, descripcion);
      FinancialTagCategory !== ''
        ? tmp.setRecId(Number(FinancialTagCategory))
        : tmp.setRecId(0);
      listFinancialDocument.push(tmp);
    }
    return listFinancialDocument;
  }

  async getFinancialDimensionByName(dimensionName: string) {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getFinancialDimension(
        dimensionName
      )) as Document;
      resp = this.processDataInfDimension(XMLDocument);
    } catch (error) {
      resp.push(new CatalogModel(-1, ''));
    }
    return resp;
  }

  private getRoleEspecifidDataXML(vatNum: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <getRoleEspecificData xmlns="http://tempuri.org/">
          <vatNum>` +
      vatNum +
      `</vatNum>
          </getRoleEspecificData>
      </s:Body>
    </s:Envelope>`
    );
  }

  /**
   * Permite traer la informacion de rol especifico, dado un vatnum.
   * Si es vacio trae la lista de todos los roles especificos.
   * @param vatnum
   */
  private getRoleEspecifiData(vatnum: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getRoleEspecifidDataXML(vatnum);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getRoleEspecificData'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  /**
   * Dado un usuario, trae la información financiera por defecto que posee.
   * @param vatNum
   */
  async getFinancialDimensionByUser(vatNum: string) {
    let resp: RoleSpecifidInfo[] = [];
    try {
      const XMLDocument = (await this.getRoleEspecifiData(vatNum)) as Document;
      resp = this.processRoleEspecifiData(XMLDocument);
    } catch (error) {}
    return resp;
  }

  processRoleEspecifiData(xml: XMLDocument) {
    const listRoleEspecifi: RoleSpecifidInfo[] = [];
    const dataInfo = xml
      .getElementsByTagName('getRoleEspecificDataResult')[0]
      .getElementsByTagName('a:RoleSpecifidInfo');
    for (let position = 0; position < dataInfo.length; position++) {
      const a_departamento = dataInfo[position]
        .getElementsByTagName('a:A_Departamento')
        .item(0).textContent;
      const b_centroCosto = dataInfo[position]
        .getElementsByTagName('a:B_CentroCosto')
        .item(0).textContent;
      const c_proposito = dataInfo[position]
        .getElementsByTagName('a:C_Proposito')
        .item(0).textContent;
      const roleID = dataInfo[position]
        .getElementsByTagName('a:RoleID')
        .item(0).textContent;
      const roleSpecificId = dataInfo[position]
        .getElementsByTagName('a:RoleSpecifid')
        .item(0).textContent;
      listRoleEspecifi.push(
        new RoleSpecifidInfo(
          a_departamento,
          b_centroCosto,
          c_proposito,
          roleID,
          roleSpecificId
        )
      );
    }
    return listRoleEspecifi;
  }

  private createReportXML(
    purchID: string,
    dataArea: string,
    orderAccount: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <createReportPO xmlns="http://tempuri.org/">
          <purchID>` +
      purchID +
      `</purchID>
          <dataArea>` +
      dataArea +
      `</dataArea>
          <orderAccount>` +
      orderAccount +
      `</orderAccount>
          </createReportPO>
      </s:Body>
    </s:Envelope>`
    );
  }

  createReport(purchID: string, dataArea: string, orderAccount: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.createReportXML(
        purchID,
        dataArea,
        orderAccount
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/createReportPO'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML.getElementsByTagName(
              'createReportPOResult'
            )[0].textContent;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private getTypeTaxesInfoXML() {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetTaxes xmlns="http://tempuri.org/">
          </GetTaxes>
      </s:Body>
    </s:Envelope>
    `;
  }

  private getTypeTaxesInfo() {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getTypeTaxesInfoXML();
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetTaxes'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getTypeTaxes() {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getTypeTaxesInfo()) as Document;
      resp = this.processDataInfoT(XMLDocument);
    } catch (error) {
      resp.push(new CatalogModel(-1, ''));
    }
    return resp;
  }

  processDataInfoT(xml: XMLDocument) {
    const listFinancialDocument: CatalogModel[] = [];
    // listFinancialDocument.push(new CatalogModel('' as any , ''));
    const dataInfo = xml
      .getElementsByTagName('GetTaxesResult')[0]
      .getElementsByTagName('a:CatalogInfo');
    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:labelInfo')
        .item(0).textContent;
      const value = dataInfo[position]
        .getElementsByTagName('a:valueInfo')
        .item(0).textContent;
      const tmp = new CatalogModel(descripcion as any, descripcion as any); // value as any, descripcion);
      value !== '' ? tmp.setRecId(Number(value) / 100) : tmp.setRecId(0);
      listFinancialDocument.push(tmp);
    }
    return listFinancialDocument;
  }

  private getProjectInfoXML(status: number, dataArea: string) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetProject xmlns="http://tempuri.org/">
          <status>` +
      status +
      `</status>
          <dataArea>` +
      dataArea +
      `</dataArea>
         </GetProject>
      </s:Body>
    </s:Envelope>
    `
    );
  }

  private getProjectsType(status: number, dataArea: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getProjectInfoXML(status, dataArea);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetProject'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getProjects(status: number, dataArea: string) {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getProjectsType(
        status,
        dataArea
      )) as Document;
      resp = this.processDataInfoProject(XMLDocument);
    } catch (error) {
      resp = [new CatalogModel('' as any, '')];
    }
    return resp;
  }

  private processDataInfoProject(xml: XMLDocument) {
    const listProjects: CatalogModel[] = [];
    listProjects.push(new CatalogModel('' as any, ''));
    const dataInfo = xml
      .getElementsByTagName('GetProjectResult')[0]
      .getElementsByTagName('a:CatalogInfo');
    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:labelInfo')
        .item(0).textContent;
      const valueS = dataInfo[position]
        .getElementsByTagName('a:valueStr')
        .item(0).textContent;
      const tmp = new CatalogModel(valueS as any, descripcion as any);
      listProjects.push(tmp);
    }
    return listProjects;
  }

  private getPurchaseOrderTypeXML() {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetTypePurchaseOrder xmlns="http://tempuri.org/">
         </GetTypePurchaseOrder>
      </s:Body>
    </s:Envelope>
    `;
  }

  private getPurchaseOrderType() {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getPurchaseOrderTypeXML();
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetTypePurchaseOrder'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  private processPurchaseOrderType(xml: XMLDocument) {
    const listOCType: CatalogModel[] = [];
    listOCType.push(new CatalogModel('' as any, ''));
    const dataInfo = xml
      .getElementsByTagName('GetTypePurchaseOrderResult')[0]
      .getElementsByTagName('a:CatalogInfo');

    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:labelInfo')
        .item(0).textContent;
      const valueS = dataInfo[position]
        .getElementsByTagName('a:valueStr')
        .item(0).textContent;
      const tmp = new CatalogModel(valueS as any, descripcion as any);
      listOCType.push(tmp);
    }
    return listOCType;
  }

  async getOCType() {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getPurchaseOrderType()) as Document;
      resp = this.processPurchaseOrderType(XMLDocument);
    } catch (error) {
      resp = [new CatalogModel('' as any, '')];
    }
    return resp;
  }

  //************************DG **************************/
  private getPurposeInfoXML(dataArea: string) {
    return (
      `
  <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
        <GetPurpose xmlns="http://tempuri.org/">
        <dataArea>` +
      dataArea +
      `</dataArea>
       </GetPurpose>
    </s:Body>
  </s:Envelope>
  `
    );
  }

  private getPurposesType(dataArea: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getPurposeInfoXML(dataArea);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPurpose'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getPurposes(dataArea: string) {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getPurposesType(dataArea)) as Document;
      resp = this.processDataInfoPurpose(XMLDocument);
    } catch (error) {
      resp = [new CatalogModel('' as any, '')];
    }
    return resp;
  }

  processDataInfoPurpose(xml: XMLDocument) {
    const listPurposes: CatalogModel[] = [];
    listPurposes.push(new CatalogModel('' as any, ''));
    const dataInfo = xml
      .getElementsByTagName('GetPurposeResult')[0]
      .getElementsByTagName('a:CatalogInfo');
    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:labelInfo')
        .item(0).textContent;
      const valueS = dataInfo[position]
        .getElementsByTagName('a:valueStr')
        .item(0).textContent;
      const salesOrderRelation = dataInfo[position]
        .getElementsByTagName('a:valueInt')
        .item(0).textContent;
      const tmp = new CatalogModel(
        valueS as any,
        descripcion as any,
        salesOrderRelation === '1'
      );
      listPurposes.push(tmp);
    }
    return listPurposes;
  }

  //------------SALE RELATION ------------
  private getSaleInfoXML(dataArea: string, vendorAccountNum: String) {
    return (
      `
  <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
        <GetSales xmlns="http://tempuri.org/">
        <dataArea>` +
      dataArea +
      `</dataArea>
        <vendorAccountNum>` +
      vendorAccountNum +
      `</vendorAccountNum>
       </GetSales>
    </s:Body>
  </s:Envelope>
  `
    );
  }

  private getSalesType(dataArea: string, vendorAccountNum: String) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getSaleInfoXML(dataArea, vendorAccountNum);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetSales'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getSales(dataArea: string, vendorAccountNum: String) {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getSalesType(
        dataArea,
        vendorAccountNum
      )) as Document;
      resp = this.processDataInfoSale(XMLDocument);
    } catch (error) {
      resp = [new CatalogModel('' as any, '')];
    }
    return resp;
  }

  processDataInfoSale(xml: XMLDocument) {
    const listSales: CatalogModel[] = [];
    listSales.push(new CatalogModel('' as any, ''));
    const dataInfo = xml
      .getElementsByTagName('GetSalesResult')[0]
      .getElementsByTagName('a:CatalogInfo');
    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:labelInfo')
        .item(0).textContent;
      const valueS = dataInfo[position]
        .getElementsByTagName('a:valueStr')
        .item(0).textContent;
      const labelS = dataInfo[position]
        .getElementsByTagName('a:labelStr')
        .item(0).textContent;
      const tmp = new CatalogModel(
        valueS as any,
        descripcion as any,
        false,
        labelS
      );
      listSales.push(tmp);
    }
    return listSales;
  }
  //***********************END DG **************************/

  uploadImageToXML(infoData: InfoImage) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <UploadImage xmlns="http://tempuri.org/">
        <base64>` +
      infoData.getDataImage() +
      `</base64>
        <extension>` +
      infoData.extension +
      `</extension>
        <action>` +
      infoData.action +
      `</action>
        <type>` +
      infoData.type +
      `</type>
        <personVatnum>` +
      infoData.vatnum +
      `</personVatnum>
        <elementId>` +
      infoData.recIdAnswer +
      `</elementId>
        <company>` +
      infoData.company +
      `</company>
        </UploadImage>
      </s:Body>
    </s:Envelope>`
    );
  }

  async uploadImge(infoData: InfoImage) {
    return new Promise((resolve, reject) => {
      const xmlImg = this.uploadImageToXML(infoData);

      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/UploadImage'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName(
                'UploadImageResult'
              )[0].textContent;
            resolve(Number(result));
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlImg);
    });
  }

  private getFileInfoToXML(
    type: number,
    action: number,
    elementId: string,
    company: string,
    personVatnum: string
  ) {
    return (
      `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <getFile xmlns="http://tempuri.org/">
          <type>` +
      type +
      `</type>
          <action>` +
      action +
      `</action>
          <elementId>` +
      elementId +
      `</elementId>
          <company>` +
      company +
      `</company>
          <personVatnum>` +
      personVatnum +
      `</personVatnum> 
        </getFile>
      </s:Body>
    </s:Envelope>`
    );
  }

  private async getFileInfo(
    type: number,
    action: number,
    elementId: string,
    company: string,
    personVatnum: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlImg = this.getFileInfoToXML(
        type,
        action,
        elementId,
        company,
        personVatnum
      );

      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/getFile'
      );

      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };

      xmlRequest.send(xmlImg);
    });
  }

  private processDataInfoFile(xml: XMLDocument) {
    const listFilesOC: FileOCInfo[] = [];
    const dataInfo = xml
      .getElementsByTagName('getFileResult')[0]
      .getElementsByTagName('a:Image');
    for (let position = 0; position < dataInfo.length; position++) {
      const url = dataInfo[position].getElementsByTagName('a:URL').item(0)
        .textContent as string;
      const name = url.substr(url.lastIndexOf('/', url.length) + 1);
      const tmpFile = new FileOCInfo(name, '', '', '', false);
      tmpFile.setURL(url);
      listFilesOC.push(tmpFile);
    }
    return listFilesOC;
  }

  async getFileInfoOC(
    type: number,
    action: number,
    elementId: string,
    company: string,
    personVatnum: string
  ) {
    let resp: FileOCInfo[] = [];
    try {
      const XMLDocument = (await this.getFileInfo(
        type,
        action,
        elementId,
        company,
        personVatnum
      )) as Document;
      resp = this.processDataInfoFile(XMLDocument);
    } catch (error) {}
    return resp;
  }

  //Axel Vargas
  /**Variable para conocer si necesita o no un Número de Factura Física.*/
  requireNoPhysicalInvoice: boolean = false;

  /**Variable para conocer cuál agente ejecutó la OC.*/
  roleSpecificId: string = '';

  // --- Consumo de WCF GetPurposeAllowedByRole
  private getPurposeAllowedByRoleInfoXML(dataArea: string, executor: string) {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetPurposeAllowedByRole xmlns="http://tempuri.org/">
          <dataAreaId>${dataArea}</dataAreaId>
          <executor>${executor}</executor>
          </GetPurposeAllowedByRole>
      </s:Body>
    </s:Envelope>
    `;
  }

  private getPurposeAllowedByRoleType(dataArea: string, executor: string) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getPurposeAllowedByRoleInfoXML(
        dataArea,
        executor
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPurposeAllowedByRole'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getPurposeAllowedByRole(dataArea: string, executor: string) {
    let resp: PurposeAllowedByRole[] = [];
    try {
      const XMLDocument = (await this.getPurposeAllowedByRoleType(
        dataArea,
        executor
      )) as Document;
      resp = this.processPurposeAllowedByRole(XMLDocument);
    } catch (error) {
      resp = [
        new PurposeAllowedByRole({
          Purpose: '',
          WeekNumber: 0,
          WeeklyAmountAllowed: 0,
          WeeklyAmountRemaining: 0,
        }),
      ];
    }
    return resp;
  }

  listPurposes: PurposeAllowedByRole[] = [];

  processPurposeAllowedByRole(xml: XMLDocument) {
    const listPurposes: PurposeAllowedByRole[] = [];
    //listPurposes.push(new PurposeAllowedByRole());
    const dataInfo = xml
      .getElementsByTagName('GetPurposeAllowedByRoleResult')[0]
      .getElementsByTagName('a:PurposeAllowed');

    for (let position = 0; position < dataInfo.length; position++) {
      const Purpose = dataInfo[position]
        .getElementsByTagName('a:Purpose')
        .item(0).textContent;
      const WeekNumber = Number(
        dataInfo[position].getElementsByTagName('a:WeekNumber').item(0)
          .textContent
      );
      const WeeklyAmountAllowed = Number(
        dataInfo[position].getElementsByTagName('a:WeeklyAmountAllowed').item(0)
          .textContent
      );
      const WeeklyAmountRemaining = Number(
        dataInfo[position]
          .getElementsByTagName('a:WeeklyAmountRemaining')
          .item(0).textContent
      );

      const element = new PurposeAllowedByRole({
        Purpose: Purpose,
        WeekNumber: WeekNumber,
        WeeklyAmountAllowed: WeeklyAmountAllowed,
        WeeklyAmountRemaining: WeeklyAmountRemaining,
      });

      listPurposes.push(element);
    }

    this.listPurposes = listPurposes.slice();
    return listPurposes;
  }
  // --- Consumo de WCF GetPurposeAllowedByRole

  // --- Consumo de WCF GetPurposeCategoriesAllowedByRole
  private getPurposeCategoriesAllowedByRoleInfoXML(
    dataArea: string,
    executor: string
  ) {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetPurposeCategoriesAllowedByRole xmlns="http://tempuri.org/">
          <dataAreaId>${dataArea}</dataAreaId>
          <executor>${executor}</executor>
          </GetPurposeCategoriesAllowedByRole>
      </s:Body>
    </s:Envelope>
    `;
  }

  private getPurposeCategoriesAllowedByRoleType(
    dataArea: string,
    executor: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getPurposeCategoriesAllowedByRoleInfoXML(
        dataArea,
        executor
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPurposeCategoriesAllowedByRole'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getPurposeCategoriesAllowedByRole(dataArea: string, executor: string) {
    let resp: PurposeCategoriesAllowedByRole[] = [];
    try {
      const XMLDocument = (await this.getPurposeCategoriesAllowedByRoleType(
        dataArea,
        executor
      )) as Document;
      resp = this.processPurposeCategoriesAllowedByRole(XMLDocument);
    } catch (error) {
      resp = [
        new PurposeCategoriesAllowedByRole({
          Purpose: '',
          PurposeCategory: '',
          DiaryAmountAllowed: 0,
          RangeStartTime: '00:00:00',
          RangeEndTime: '00:00:00',
          RegisteredDate: '1900-01-01T:00:00:00',
        }),
      ];
    }
    return resp;
  }

  listPurposesCategories: PurposeCategoriesAllowedByRole[] = [];

  processPurposeCategoriesAllowedByRole(xml: XMLDocument) {
    const listPurposesCategories: PurposeCategoriesAllowedByRole[] = [];
    //listPurposes.push(new PurposeCategoriesAllowedByRole());
    const dataInfo = xml
      .getElementsByTagName('GetPurposeCategoriesAllowedByRoleResult')[0]
      .getElementsByTagName('a:PurposeCategoriesAllowed');

    for (let position = 0; position < dataInfo.length; position++) {
      const Purpose = dataInfo[position]
        .getElementsByTagName('a:Purpose')
        .item(0).textContent;
      const PurposeCategory = dataInfo[position]
        .getElementsByTagName('a:PurposeCategory')
        .item(0).textContent;
      const DiaryAmountAllowed = Number(
        dataInfo[position].getElementsByTagName('a:DiaryAmountAllowed').item(0)
          .textContent
      );
      const RangeStartTime = dataInfo[position]
        .getElementsByTagName('a:RangeStartTime')
        .item(0).textContent;
      const RangeEndTime = dataInfo[position]
        .getElementsByTagName('a:RangeEndTime')
        .item(0).textContent;
      const RegisteredDate = dataInfo[position]
        .getElementsByTagName('a:RegisteredDate')
        .item(0).textContent;

      const element = new PurposeCategoriesAllowedByRole({
        Purpose: Purpose,
        PurposeCategory: PurposeCategory,
        DiaryAmountAllowed: DiaryAmountAllowed,
        RangeStartTime: RangeStartTime,
        RangeEndTime: RangeEndTime,
        RegisteredDate: RegisteredDate,
      });

      listPurposesCategories.push(element);
    }

    this.listPurposesCategories = listPurposesCategories.slice();
    return listPurposesCategories;
  }
  // --- Consumo de WCF GetPurposeCategoriesAllowedByRole

  // --- Consumo de WCF GetPurposeCategoriesAllowedByRoleList
  private getPurposeCategoriesAllowedByRoleListInfoXML(
    dataAreaId: string,
    executor: string
  ) {
    return `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
          <GetPurposeCategoriesAllowedByRoleList xmlns="http://tempuri.org/">
            <dataAreaId>${dataAreaId}</dataAreaId>
            <executor>${executor}</executor>
          </GetPurposeCategoriesAllowedByRoleList>
      </s:Body>
    </s:Envelope>
    `;
  }

  private getPurposeCategoriesAllowedByRoleListType(
    dataAreaId: string,
    executor: string
  ) {
    return new Promise((resolve, reject) => {
      const xmlEditingMode = this.getPurposeCategoriesAllowedByRoleListInfoXML(
        dataAreaId,
        executor
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPurposeCategoriesAllowedByRoleList'
      );
      xmlRequest.send(xmlEditingMode);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const infoData = xmlRequest.responseXML;
            resolve(infoData);
          } else if (xmlRequest.status === 500) {
            reject(500);
          } else if (xmlRequest.status === 0) {
            reject(0);
          }
        }
      };
    });
  }

  async getPurposeCategoriesAllowedByRoleList(
    dataAreaId: string,
    executor: string
  ) {
    let resp: CatalogModel[] = [];
    try {
      const XMLDocument = (await this.getPurposeCategoriesAllowedByRoleListType(
        dataAreaId,
        executor
      )) as Document;
      resp = this.processPurposeCategoriesAllowedByRoleList(XMLDocument);
    } catch (error) {
      resp = [new CatalogModel('' as any, '')];
    }
    return resp;
  }

  processPurposeCategoriesAllowedByRoleList(xml: XMLDocument) {
    const listPurposes: CatalogModel[] = [];
    listPurposes.push(new CatalogModel('' as any, ''));
    const dataInfo = xml
      .getElementsByTagName('GetPurposeCategoriesAllowedByRoleListResult')[0]
      .getElementsByTagName('a:CatalogInfo');
    for (let position = 0; position < dataInfo.length; position++) {
      const descripcion = dataInfo[position]
        .getElementsByTagName('a:labelInfo')
        .item(0).textContent;
      const valueS = dataInfo[position]
        .getElementsByTagName('a:valueStr')
        .item(0).textContent;
      const tmp = new CatalogModel(valueS as any, descripcion as any);
      listPurposes.push(tmp);
    }
    return listPurposes;
  }
  // --- Consumo de WCF GetPurposeCategoriesAllowedByRoleList

  // --- Invalidar la existencia de un mismo Número de Factura Física de un mismo Proveedor
  async getNoPhysicalInvoiceIsValid(
    dataAreaId: string,
    noPhysicalInvoice: string,
    vendor: string
  ) {
    return await this.dataService.getNoPhysicalInvoiceIsValid(
      dataAreaId,
      noPhysicalInvoice,
      vendor
    );
  }
  // --- Invalidar la existencia de un mismo Número de Factura Física de un mismo Proveedor

  // --- Invalidar la existencia de una OC existente con el mismo categoría de propósito.
  async canGenerateNewPurchByPurposeCategory(
    dataAreaId: string,
    purposeCategory: string,
    executor: string
  ) {
    return await this.dataService.canGenerateNewPurchByPurposeCategory(
      dataAreaId,
      purposeCategory,
      executor
    );
  }
  // --- Invalidar la existencia de una OC existente con el mismo categoría de propósito.
  //Axel Vargas
}
