import { Component } from '@angular/core';
import {
  AlertController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { DataArea } from '../../../businessCore/oc-models/DataArea';
import { PurchLinesInfoParamv2 } from '../../OOP/PurchLinesInfoParamv2.interface';
import { Einvoice } from '../../OOP/einvoices.interface';
import { PurchOrderEinvoiceFilterComponent } from '../../components/purch-order-einvoice-filter/purch-order-einvoice-filter.component';
import { PurchOrderInvoiceFilterPipe } from '../../pipes/PurchOrderInvoice.pipe';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-page-assign-purch-order',
  templateUrl: './assign-purch-order.component.html',
  styleUrls: ['./assign-purch-order.component.scss'],
})
export class AssignPurchOrderPage {
  empresaSelected: DataArea;
  EInvoice: Einvoice;
  PurchOrders: PurchLinesInfoParamv2[] = [];
  PurchOrdersSearch: PurchLinesInfoParamv2[] = [];
  PurchOrdersPivot: PurchLinesInfoParamv2[] = [];

  sortEvents = {
    PurchIdAsc: false,
    PurchIdDesc: false,
    CreatedDateAsc: false,
    CreatedDateDesc: false,
  };

  orderBy: string = '';
  rangeDatesSelected: Date[];
  MinMax: { lower: number; upper: number } = { lower: 0, upper: 0 };

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private PurchOrderFilter: PurchOrderInvoiceFilterPipe,
    private InvServ: InvoiceService
  ) {
    this.empresaSelected = this.navParams.get('Company') as DataArea;
    this.EInvoice = this.navParams.get('EInvoice') as Einvoice;
    this.PurchOrders = this.navParams.get(
      'PurchOrders'
    ) as PurchLinesInfoParamv2[];
    this.PurchOrdersSearch = this.PurchOrders;
    this.PurchOrdersPivot = this.PurchOrders;
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad AssignPurchOrderPage');
  }

  verifyCheck(purchId: string) {
    this.PurchOrdersSearch.forEach((x) => {
      if (x.purchId !== purchId) {
        x.checked = false;
      }
    });
  }

  searchOC(event) {
    this.PurchOrdersSearch = this.PurchOrderFilter.transform(
      this.PurchOrders,
      event.detail.value
    );
  }

  async presentSortAlert() {
    let alert = await this.alertCtrl.create({
      header: '¿Cómo ordenar las OCs?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Orden de Compra (A -> Z)',
          'PurchIdAsc',
          this.sortEvents.PurchIdAsc
        ),
        this.getInputAlert(
          'radio',
          'Orden de Compra (Z -> A)',
          'PurchIdDesc',
          this.sortEvents.PurchIdDesc
        ),
        this.getInputAlert(
          'radio',
          'Fecha de Creación (Más Antiguo)',
          'CreatedDateAsc',
          this.sortEvents.CreatedDateAsc
        ),
        this.getInputAlert(
          'radio',
          'Fecha de Creación (Más Actual)',
          'CreatedDateDesc',
          this.sortEvents.CreatedDateDesc
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow();
            }
          },
        },
      ],
    });
    alert.present();
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  private setSelectedOrder(orderType: string) {
    this.sortEvents.PurchIdAsc = 'PurchIdAsc' === orderType;
    this.sortEvents.PurchIdDesc = 'PurchIdDesc' === orderType;
    this.sortEvents.CreatedDateAsc = 'CreatedDateAsc' === orderType;
    this.sortEvents.CreatedDateDesc = 'CreatedDateDesc' === orderType;
  }

  private setListToShow() {
    Object.keys(this.sortEvents).forEach((key) => {
      if (this.sortEvents[key]) {
        switch (key) {
          case 'PurchIdAsc':
            this.PurchOrdersSearch = this.PurchOrdersSearch.sort((a, b) =>
              a.purchId.localeCompare(b.purchId)
            );
            break;

          case 'PurchIdDesc':
            this.PurchOrdersSearch = this.PurchOrdersSearch.sort((a, b) =>
              b.purchId.localeCompare(a.purchId)
            );
            break;

          case 'CreatedDateAsc':
            this.PurchOrdersSearch = this.PurchOrdersSearch.sort(
              (a, b) =>
                new Date(a.createdDate).getTime() -
                new Date(b.createdDate).getTime()
            );
            break;

          case 'CreatedDateDesc':
            this.PurchOrdersSearch = this.PurchOrdersSearch.sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            );
            break;

          default:
            break;
        }
      }
    });
  }

  async filterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: PurchOrderEinvoiceFilterComponent,
      componentProps: {
        data: this.PurchOrders,
        rangeDates: this.rangeDatesSelected,
        MinMax: this.MinMax,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null && data !== undefined) {
        let PurchOrdersTmp: PurchLinesInfoParamv2[] = [];

        if (data.rangeDates && data.rangeDates.length > 0) {
          this.rangeDatesSelected = data.rangeDates;
        }

        if (data.MinMax) {
          this.MinMax = data.MinMax;
        }

        PurchOrdersTmp = this.PurchOrders.filter((x) => {
          let createdDate = new Date(x.createdDate);
          createdDate.setHours(createdDate.getHours() + 6);
          if (
            this.rangeDatesSelected.length > 0 &&
            !(
              createdDate >= new Date(this.rangeDatesSelected[0]) &&
              createdDate <= new Date(this.rangeDatesSelected[1])
            )
          ) {
            return false;
          }

          if (
            this.MinMax.lower > 0 &&
            this.MinMax.upper > 0 &&
            !(
              x.totalAmount >= this.MinMax.lower &&
              x.totalAmount <= this.MinMax.upper
            )
          ) {
            return false;
          }

          return true;
        });

        this.PurchOrdersPivot =
          PurchOrdersTmp.length > 0 ? PurchOrdersTmp : this.PurchOrders;
        this.PurchOrdersSearch = this.PurchOrdersPivot;
      } else {
        this.rangeDatesSelected = [];
        this.PurchOrdersPivot = this.PurchOrders;
        this.PurchOrdersSearch = this.PurchOrders;
      }
    });

    this.setListToShow();
  }

  async alertMakeAssign() {
    let PurchOrder: PurchLinesInfoParamv2 = this.PurchOrdersSearch.find(
      (x) => x.checked
    );

    if (PurchOrder) {
      console.log(PurchOrder);
      let alert = await this.alertCtrl.create({
        header: 'AVISO',
        message: `¿Está seguro que desea asignar la FE ${this.EInvoice.Consecutive} con la Orden de Compra ${PurchOrder.purchId}?`,
        buttons: [
          {
            text: 'Sí',
            handler: () => {
              this.makeAssign(PurchOrder);
            },
          },
          'No',
        ],
      });
      await alert.present();
    } else {
      let alert = await this.alertCtrl.create({
        header: 'ERROR',
        message: `No se ha seleccionado ninguna Orden de Compra para asignar.`,
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }

  async makeAssign(PurchOrder: PurchLinesInfoParamv2) {
    let data: { Status: boolean; Message: string } =
      await this.InvServ.ProcessEInvoiceToAssignPO(
        this.empresaSelected.getId(),
        this.EInvoice.KeyEInvoice,
        this.EInvoice.Consecutive,
        PurchOrder.purchId
      );

    if (data) {
      this.isSuccessful(data);
    } else {
      let alert = await this.alertCtrl.create({
        header: 'ERROR',
        message: `Ocurrió un problema al ejecutar la tarea. Inténtalo nuevamente.`,
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }

  async isSuccessful(is: any) {
    if (is.Status) {
      let alert = await this.alertCtrl.create({
        header: 'INFO',
        message: `${is.Message} Se ha asignado con éxito.`,
        buttons: ['Ok'],
      });
      await alert.present();
      await this.navCtrl.pop();
    } else {
      let alert = await this.alertCtrl.create({
        header: 'ERROR',
        message: `${is.Message}`,
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }
}
