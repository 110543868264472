import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { OrderLine } from '../businessCore/OrderLine';
import { RegisterData } from '../businessCore/RegisterData';
import { NavigationRoutes } from './Navigation/Navigation-routes';
import { NavigationService } from './Navigation/NavigationElement';
import { StorageService } from './Storage';

@Injectable()
export class ShoopingCartUtils {
  public shoppingCar: OrderLine[] = [];

  constructor(
    private storage: StorageService,
    private alertCtrl: AlertController,
    private navService: NavigationService
  ) {
    this.reloadShoppingCar();
  }

  displayShoppingCart() {
    this.reloadShoppingCar().then(() =>
      this.storage.get('enable_version').then((versionData) => {
        if (versionData != null && versionData) {
          this.storage.get('register_data').then((data) => {
            if (data != null) {
              const register_data = JSON.parse(data) as RegisterData;
              if (register_data.customerId != '') {
                // Redirect to shoppingCar
                this.navService.navigateTo(
                  NavigationRoutes.ShoppingCartComponent,
                  {
                    shoppingCar: this.shoppingCar,
                  }
                );
              } else {
                this.presentAlert(
                  'Error',
                  `<p>El usuario que estás utilizando aún no se ha procesado, por favor intente más tarde. Si el problema persiste comuníquese con nosotros.</p>
              <p>Número</p>
              <ul>
                <li>7071-3188</li>
                <li>7071-3153</li>
                <li>7071-3067</li>
                <li>7071-3098</li>
                <li>7071-3089</li>
              </ul>
              `
                );
              }
            } else {
              this.presentAlert(
                'Error',
                'Debe iniciar sesión o registrarse antes de ver el carrito de compras'
              );
            }
          });
        } else {
          this.presentAlert(
            'Error',
            'Debe actualizarse la versión de la aplicación antes de ver el carrito de compras'
          );
        }
      })
    );
  }

  async reloadShoppingCar() {
    const data = await this.storage.get('carrito');
    let shoppingCar = JSON.parse(data);
    this.shoppingCar = shoppingCar ?? [];

    return this.shoppingCar;
  }

  async presentAlert(title: string, msg: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'OK',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }
}
