import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nota-oc',
  templateUrl: './nota-oc.component.html',
  styleUrls: ['./nota-oc.component.scss'],
})
export class NotaOcComponent implements OnInit {
  @Input() note: string;
  @Input() cantEdit: Boolean;
  @Output() getNote = new EventEmitter<string>(); // Evento de la nota que se escribe
  constructor() {}

  ngOnInit() {}

  emitNote() {
    if (this.cantEdit) {
      this.getNote.emit(this.note);
    }
  }
}
