import { Contact } from './Contact';
import { Direction } from './Direction';
import { RestrictionData } from './RestrictionData';
export class RegisterData {
  id_card: string = '';
  c_name: string = '';
  c_type: number = 0;
  user_name: string = '';
  name: string = '';
  firstLastName: string = '';
  secondLastName: string = '';
  email: string = '';
  password: string = '';
  passConfirm: string = '';
  commercial_name: string = '';

  priceList: string = 'HX-VIP';

  represent_c_type: number = 0;
  represent_user_name: string = '';
  represent_id_card: string = '';

  webSite: string = '';
  delivery_restric: string = '';
  additional_info: string = '';

  directions: Direction[] = [];
  contacts: Contact[] = [];
  restrictions: RestrictionData[] = [];

  customerId: string = '';
  latitude: number = 0.0;
  longitude: number = 0.0;

  OriginSite: string = '';
  Hierarchy: string = '';
  DeliveryRoute: string = '';

  constructor() {}

  public get_card_types() {
    return [
      { value: 0, name: 'Persona Física', format: '#-####-####' },
      { value: 1, name: 'Extranjeros', format: '##########*' },
      { value: 2, name: 'Jurídica', format: '#-###-######' },
    ];
  }

  finishAsExecuted() {
    let identify: number = new Date().getTime();
    let result: string = '';
    result += '<XmlSend><identify>';
    result += identify;
    result += '</identify><user>';
    result += 'HOEX00';
    result += '</user><businessData><![CDATA[';
    result += this.toXML(identify);
    result += ']]></businessData></XmlSend>';
    return result;
  }

  toXML(identify: number) {
    let xml: string = '';
    let date = new Date(identify);
    xml +=
      '<PSCustBusRelTable class="entity">' +
      '<PSCustBusRelTable class="entity">' +
      '<entityType>0</entityType>' +
      '<prospectId>' +
      this.id_card +
      '</prospectId>' +
      '<name>' +
      this.name +
      '</name>' +
      '<firstName>' +
      this.firstLastName +
      '</firstName>' +
      '<lastName>' +
      this.secondLastName +
      '</lastName>' +
      '<identifyType>' +
      this.c_type +
      '</identifyType>' +
      '<comercialName>' +
      this.commercial_name +
      '</comercialName>' +
      '<legalIdentify>' +
      this.represent_id_card +
      '</legalIdentify>' +
      '<legalName>' +
      this.represent_user_name +
      '</legalName>' +
      '<dimex>' +
      (this.c_type == 1 ? this.id_card : '') +
      '</dimex>' +
      '<nite>' +
      (this.c_type == 1 ? this.id_card : '') +
      '</nite>' +
      '<customerClass></customerClass>' +
      '<priceGroup>HX-VIP</priceGroup>' +
      '<webSite>' +
      this.webSite +
      '</webSite>' +
      '<dlvrRstrctn>' +
      this.delivery_restric +
      '</dlvrRstrctn>' +
      '<additionalData>' +
      this.additional_info +
      '</additionalData>' +
      '<customerCode>null</customerCode>' +
      '<identify>' +
      identify +
      '</identify>' +
      '<latitude>' +
      this.latitude +
      '</latitude>' +
      '<longitude>' +
      this.longitude +
      '</longitude>' +
      '<createdDate>' +
      date.toISOString() +
      '</createdDate>' + //2018-07-06T13:18:56Z
      '<executorId>HOEX00</executorId>' +
      '<customerType>5637144576@@5637146077@null</customerType>';
    this.directions.forEach((item, index) => {
      xml += item.toXML(identify.toString(), this.id_card);
    });
    this.contacts.forEach((item, index) => {
      xml += item.toXML(identify.toString(), this.id_card);
    });
    xml +=
      `<PSCustBusRelTableContact class="entity">
        <prospectId>` +
      this.id_card +
      `</prospectId>
        <contactType>2</contactType>
        <contact>` +
      this.email +
      `</contact>
        <extension></extension>
        <description></description>
        <isPrincipal>1</isPrincipal>
        <contactName>null</contactName>
        <firstName>null</firstName>
        <lastName>null</lastName>
        <contactIdType>1</contactIdType>
        <contactId>null</contactId>
        <typeRegister>0</typeRegister>
        <type></type>
        <identify>` +
      identify.toString() +
      `</identify>
        <isNew>1</isNew>
    </PSCustBusRelTableContact>`;

    xml += '</PSCustBusRelTable></PSCustBusRelTable>';

    return encodeURI(xml);
  }

  public get_day_value(daysArray) {
    const available_days = [
      { id: 0, value: 'Lunes' },
      { id: 1, value: 'Martes' },
      { id: 2, value: 'Miércoles' },
      { id: 3, value: 'Jueves' },
      { id: 4, value: 'Viernes' },
      { id: 5, value: 'Sábado' },
    ];

    let daysResult = '';

    // Si el usuario marco todos los dias
    if (daysArray.length == 6) {
      daysResult = 'Todos los días';
    } else {
      daysArray.forEach((day) => {
        available_days.forEach((element) => {
          if (element.id == parseInt(day)) {
            if (daysResult.length == 0) {
              daysResult = element.value;
            } else {
              daysResult = daysResult + ', ' + element.value;
            }
          }
        });
      });
    }
    return daysResult;
  }

  restrictionToString() {
    const restric_array = [];
    this.restrictions.forEach((res) => {
      restric_array.push(
        ' ' +
          this.get_day_value(res.day) +
          ' ' +
          res.get_type_value(res.type) +
          this.getRestrictionHours(res)
      );
    });

    return restric_array.toString();
  }

  getRestrictionHours(restriction: RestrictionData) {
    let hours_date1 = restriction.getHours(restriction.date1);
    let hours_date2 = restriction.getHours(restriction.date2);

    if (restriction.type == 0 || restriction.type == 1) {
      return '(' + hours_date1[0] + ':' + hours_date1[1] + ')';
    }
    return (
      '(' +
      hours_date1[0] +
      ':' +
      hours_date1[1] +
      ' - ' +
      hours_date2[0] +
      ':' +
      hours_date2[1] +
      ')'
    );
  }
}
