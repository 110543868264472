<div class="box">
  <ion-searchbar
    type="text"
    placeholder="Buscar"
    (ionChange)="searchAudit($event)"
  ></ion-searchbar>

  <div class="content">
    <ion-list>
      <ion-card
        (click)="loadAudit(audit.getAuditRecId())"
        [ngClass]="{ card: true }"
        [disabled]="audit.getAuditStatus() == 1"
        *ngFor="let audit of auditListToSearch; let i = index"
      >
        <ion-card-header>
          <div class="title">
            <span>{{ audit.getAuditedWorkerName() }}</span>
            <span class="date">{{ parseAuditDate(audit.getAuditDate()) }}</span>
          </div>
        </ion-card-header>

        <ion-card-content>
          {{ audit.getAuditedWorkerCompany() }}<br />
          {{ audit.getAuditedWorkerDepartment() }}
        </ion-card-content>
      </ion-card>
    </ion-list>
  </div>
</div>

<ion-fab horizontal="end" vertical="bottom">
  <ion-fab-button (click)="loadAudit(0)" size="small">
    <ion-icon name="add-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
