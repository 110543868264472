import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { AlmacenModel } from '../../businessCore/oc-models/AlmacenModel';
import { CatalogModel } from '../../businessCore/oc-models/CatalogModel';
import { CurrencyInfoModel } from '../../businessCore/oc-models/CurrencyInfoModel';
import { DataArea } from '../../businessCore/oc-models/DataArea';
import { LanguageModel } from '../../businessCore/oc-models/LanguageModel';
import { ProveedorModel } from '../../businessCore/oc-models/ProveedorModel';
import { PurchOrder } from '../../businessCore/oc-models/PurchOrder';
import { PurchPoolModel } from '../../businessCore/oc-models/PurchPoolModel';
import { PurposeAllowedByRole } from '../../businessCore/oc-models/PurposeAllowedByRole';
import { PurposeCategoriesAllowedByRole } from '../../businessCore/oc-models/PurposeCategoriesAllowedByRole';
import { SitioModel } from '../../businessCore/oc-models/SitioModel';
import { DayIniFin } from '../../businessCore/oc-models/dayIniFin';
import { PopInfoOcComponent } from '../../components/oc-components/pop-info-oc/pop-info-oc.component';
import { ExternalServiceOCService } from '../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../provider/Services/local-service-oc.service';
import { ServicesOcParameterProvider } from '../../provider/Services/services-oc-parameter';
import { SearchProviderComponent } from './../../components/oc-components/search-provider/search-provider.component';

@Component({
  selector: 'app-purch-order',
  templateUrl: './purch-order.component.html',
  styleUrls: ['./purch-order.component.scss'],
})
export class PurchOrderPage {
  //@ViewChild(Navbar) navBar: Navbar;
  presentingMess = false;
  ocInfo: PurchOrder;
  segmentModel: string;
  montoProducto = 0;
  cantidadProducto = 0;
  montoCantidadP = 0;
  montoUser = 0;
  toEdit = false;
  isEditing = false;
  createEditing = false;
  loader: HTMLIonLoadingElement;
  presentData = false;
  sendingData: boolean = false;
  taxInclude: boolean = false;
  saleRequired: boolean = false;

  listEmpresa: DataArea[] = [];
  listProveedor: ProveedorModel[] = [];
  listPurchPool: PurchPoolModel[] = [];
  listPurpose: CatalogModel[] = [];
  listSale: CatalogModel[] = [];
  listCurrency: CurrencyInfoModel[] = [];
  listlanguage: LanguageModel[] = [];

  // Informacion General de Encabezado
  empresaSelected: DataArea;
  proveedorSelected: ProveedorModel;
  purchPoolSelected: PurchPoolModel;
  purposeSelected: CatalogModel;
  saleSelected: CatalogModel;
  currencySelected: CurrencyInfoModel;
  languageSelected: LanguageModel;
  fechaInicio: Date;
  fechaFin: Date;

  // Información de Entrega
  listSitio: SitioModel[] = [];
  listAlmacen: AlmacenModel[] = [];

  sitioSelected: SitioModel;
  almacenSelected: AlmacenModel;
  fechaEntrega: Date;

  // Informacion Adicional
  cantContenedor: number;
  cantTEUS: number;
  cantDLibres: number;
  containerTypeList: CatalogModel[] = [];
  containerTypeSelected: CatalogModel;
  salidaSolicD: Date;
  salidaArriboD: Date;
  soliLlegaBD: Date;
  // Internacionales
  puertoSelected: CatalogModel;
  puertoList: CatalogModel[] = [];
  navieraSelected: CatalogModel;
  navieraList: CatalogModel[] = [];
  incotermSelected: CatalogModel;
  incotermList: CatalogModel[] = [];
  // Estado del pedido
  estadoPedidoSelected: CatalogModel;
  estadoPedido: CatalogModel[] = [];
  // Nota de OC
  noteOC: string;
  // Dimensiones
  aDepartamento: CatalogModel[] = [];
  bCentroCosto: CatalogModel[] = [];
  cProposito: CatalogModel[] = [];
  aDepartamentoSelected: CatalogModel;
  bCentroCostoSelected: CatalogModel;
  cPropositoSelected: CatalogModel;

  // Proyecto
  proyectoSelected: CatalogModel;
  proyectoType: CatalogModel[] = [];

  // TipoOC
  tipoOCSelected: CatalogModel;
  typeOCList: CatalogModel[] = [];

  //cedula
  actualVatNum: string;

  //Axel Vargas
  /**Variable para conocer el agente ligado.*/
  roleSpecificId: string = '';

  /**Lista de Categorías de Propósito de OC */
  PurposeCategoriesAllowedByRoleList: CatalogModel[] = [];

  /**Variable que define cuál es la categoría del Propósito de OC seleccionada.*/
  PurposeCategorySelected: string = '';

  /**Lista de Propósitos validos para el Agente.*/
  PurposeAllowedByRole: PurposeAllowedByRole[] = [];

  /**Lista de Categorías de Propósitos validos para el Agente.*/
  PurposeCategoriesAllowedByRole: PurposeCategoriesAllowedByRole[] = [];

  /**Necesaria para saber si el usuario debe o no seleccionar una categoría.*/
  needToSetCategory: boolean = false;

  /**Variable para conocer si la OC necesita el No. Factura Física.*/
  requireNoPhysicalInvoice: boolean = false;

  /**Variable que obtendrá el número de factura física del formulario.*/
  noPhysicalInvoice: string = '';
  //Axel Vargas

  constructor(
    private extServOC: ExternalServiceOCService,
    private localServOC: LocalServiceOCService,
    public modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    public navCtrl: NavController,
    public navParams: NavParams,
    public alertController: AlertController,
    private ocParameter: ServicesOcParameterProvider,
    private loadingCtrl: LoadingController,
    private navService: NavigationService,
    private platform: Platform
  ) {}

  async ngOnInit() {
    this.ocInfo = this.ocParameter.getPurchOrderParameter();
    this.segmentModel = 'infoGeneral';
    this.toEdit = this.ocParameter.getToEdit();

    await this.showSpinnerLoading('Cargado datos para OC.');
    this.actualVatNum = await this.localServOC.getCedulaActual();
    this.listEmpresa = await this.extServOC.getDataAreaInfo();
    if (this.ocInfo !== null && this.ocInfo !== undefined) {
      this.empresaSelected = this.ocInfo.getDataArea();
    } else {
      this.empresaSelected = this.listEmpresa[0];
    }

    this.listProveedor = await this.extServOC.getVendInfo(
      this.empresaSelected.getId()
    );
    this.listPurchPool = await this.extServOC.getPurchPoolInfo(
      this.empresaSelected.getId()
    );
    //DG
    this.listPurpose = await this.extServOC.getPurposes(
      this.empresaSelected.getId()
    );

    this.listCurrency = await this.extServOC.getCurrencyInfo();
    this.listlanguage = await this.extServOC.getLenguageInfo();

    // Informacion adicional
    // Informacion de entrega
    this.listSitio = await this.extServOC.getInventSiteInfo(
      this.empresaSelected.getId()
    );
    // Internacionales
    this.puertoList = await this.extServOC.getPuertosInfo(
      this.empresaSelected.getId()
    );
    this.navieraList = await this.extServOC.getNavierasInfo(
      this.empresaSelected.getId()
    );
    this.incotermList.push(
      new CatalogModel(0, 'No definido'),
      new CatalogModel(1, 'DDP'),
      new CatalogModel(2, 'FOB'),
      new CatalogModel(3, 'CFR'),
      new CatalogModel(4, 'FAS'),
      new CatalogModel(5, 'CIF'),
      new CatalogModel(6, 'EXW'),
      new CatalogModel(7, 'FCA'),
      new CatalogModel(8, 'CTP'),
      new CatalogModel(9, 'CIP'),
      new CatalogModel(10, 'DAP')
    );
    this.containerTypeList.push(
      new CatalogModel(0, '20 STD'),
      new CatalogModel(1, '40 dry'),
      new CatalogModel(2, '40 HQ dry'),
      new CatalogModel(3, '40 HQ NOR'),
      new CatalogModel(4, '40 NOR'),
      new CatalogModel(5, 'Camion'),
      new CatalogModel(6, 'Furgon')
    );
    // Estado del pedido
    this.estadoPedido.push(
      new CatalogModel(1, 'Propuesta'),
      new CatalogModel(2, 'Bodega'),
      new CatalogModel(3, 'Mar'),
      new CatalogModel(4, 'Tierra D'),
      new CatalogModel(5, 'Tierra_ND'),
      new CatalogModel(6, 'Forecast'),
      new CatalogModel(7, 'Contrato')
    );
    // Nota

    // Dimensiones Financieras
    this.aDepartamento = await this.extServOC.getFinancialDimensionByName(
      'A_Departamento'
    );
    this.bCentroCosto = await this.extServOC.getFinancialDimensionByName(
      'B_CentroCosto'
    );
    this.cProposito = await this.extServOC.getFinancialDimensionByName(
      'C_Proposito'
    );

    // Projectos
    this.proyectoType = await this.extServOC.getProjects(
      3,
      this.empresaSelected.getId()
    );

    // Tipo de OC
    this.typeOCList = await this.extServOC.getOCType();

    await this.setDataFromOrder();
    this.dissmissLoading();
    this.presentData = true;

    //Si la orden es borrador o rechazada para editar
    this.setEditingModeByState();
  }

  // Permite regresar al componente de encabezado.
  ionViewDidLoad() {
    this.platform.backButton.subscribe(() => {
      if (this.toEdit) {
        this.goToLBackEditApproved();
      } else {
        this.goToBack();
      }
    });
  }

  async ngOnDestroy() {
    if (this.isEditing) {
      if (this.ocInfo) {
        const cedula = await this.localServOC.getCedulaActual();
        const recha = 20;
        const revi = 10;
        if (this.ocInfo.getDocumentStateI() === recha) {
          //Si la orden tiene ciertos estado es necesario hacer
          this.extServOC.editingMode(
            recha,
            this.ocInfo.getIdOC(),
            this.ocInfo.getDataArea().getId(),
            cedula
          );
        } else if (this.ocInfo.getDocumentStateI() === revi) {
          if (this.ocParameter.getFrom() == 'RECORD') {
            this.extServOC.editingMode(
              revi,
              this.ocInfo.getIdOC(),
              this.ocInfo.getDataArea().getId(),
              cedula
            );
          }
        } else if (
          this.ocInfo.getDocumentStateI() === 0 &&
          this.ocInfo.getIdOC() !== ''
        ) {
          this.extServOC.editingMode(
            revi,
            this.ocInfo.getIdOC(),
            this.ocInfo.getDataArea().getId(),
            cedula
          );
        }
      }
    }

    this.extServOC.triggerStopLoadingOC = false;
  }

  async changeEdit() {
    if (this.isEditing) {
      const tmpPurchase = this.setDataInfoOC();
      // Se manda los cambios si la persona quiere
      this.sendDataOCToUpdate(
        'Atención',
        '¿Cambios realizados a la orden, desea enviar los cambios?',
        tmpPurchase
      );
    } else {
      // Se trata de entrar a modo de edición
      await this.modoEditing('Entrando a modo edición', 0); // 'BORRADOR' === 0
    }
  }

  ionViewDidEnter() {
    this.ocInfo = this.ocParameter.getPurchOrderParameter();
  }

  async setEditingModeByState() {
    if (this.ocInfo !== undefined && this.ocInfo !== null) {
      // manda a "bloquear" la orden, para no ser editada por otro usuario desde la app
      this.createEditing = false;
      const borra = 0;
      if (this.ocInfo.getDocumentStateI() === 20) {
        // 'RECHAZADO' == 20 Si la orden tiene ciertos estado es necesario hacer
        await this.modoEditing('Entrando a modo edición', borra);
      } else if (this.ocInfo.getDocumentStateI() === 10) {
        // 'EN REVISIÓN'
        if (this.ocParameter.getFrom() == 'RECORD') {
          await this.modoEditing('Entrando a modo edición', borra);
        }
      } else if (
        this.ocInfo.getDocumentStateI() === borra &&
        this.ocInfo.getIdOC() !== ''
      ) {
        await this.modoEditing('Entrando a modo edición', borra);
      } else if (
        this.ocInfo.getDocumentStateI() === borra &&
        this.ocInfo.getLocalId() !== '' &&
        this.ocInfo.getIdOC() === ''
      ) {
        this.createEditing = true;
        this.isEditing = true;
      }
      // Ya que si esta en borrador, la orden no es vista por otros usuarios.
    } else {
      if (!this.toEdit) {
        // no es para editar, es para crear, entonces se deja seleccionar
        this.createEditing = true; // puede crear
        this.isEditing = true;
      }
    }
  }

  setDataByPO(dataSearch: any, list: any[]) {
    let result: any;
    if (dataSearch !== null && dataSearch !== undefined) {
      result = list.find(
        (data) =>
          data.getId().toString().toLowerCase() ===
          dataSearch.getId().toString().toLowerCase()
      );
      if (result === undefined) {
        result = list[0];
      }
    } else {
      result = list[0];
    }
    return result;
  }

  async setDataFromOrder() {
    if (this.ocInfo !== null && this.ocInfo !== undefined) {
      this.empresaSelected = this.listEmpresa.find(
        (data) =>
          data.getId().toLowerCase() ===
          this.ocInfo.getDataArea().getId().toLowerCase()
      );
      this.proveedorSelected = new ProveedorModel(
        this.ocInfo.getAccountNum(),
        this.ocInfo.getVendName(),
        this.ocInfo.getCurrency(),
        this.ocInfo.getLanguage(),
        this.listProveedor
          .find((x) => x.getAccountNum() === this.ocInfo.getAccountNum())
          .getRegimenId()
      );

      this.proveedorSelected =
        this.proveedorSelected !== null && this.proveedorSelected !== undefined
          ? this.proveedorSelected
          : new ProveedorModel('', '', '', '', '');

      //Axel Vargas
      if (this.proveedorSelected.getRegimenId() == '02') {
        this.requireNoPhysicalInvoice = true;
        this.extServOC.requireNoPhysicalInvoice = true;
      } else {
        this.requireNoPhysicalInvoice = false;
        this.extServOC.requireNoPhysicalInvoice = false;
      }

      const dataDimUser = await this.extServOC.getFinancialDimensionByUser(
        this.actualVatNum
      );
      if (dataDimUser.length > 0) {
        this.roleSpecificId = dataDimUser[0]['roleSpecificId'];
        this.extServOC.roleSpecificId = this.roleSpecificId;

        this.PurposeAllowedByRole =
          await this.extServOC.getPurposeAllowedByRole(
            this.empresaSelected.getId(),
            this.roleSpecificId
          );
        this.extServOC.listPurposes = this.PurposeAllowedByRole;
        this.PurposeCategoriesAllowedByRole =
          await this.extServOC.getPurposeCategoriesAllowedByRole(
            this.empresaSelected.getId(),
            this.roleSpecificId
          );
        this.extServOC.listPurposesCategories =
          this.PurposeCategoriesAllowedByRole;
        this.PurposeCategoriesAllowedByRoleList =
          await this.extServOC.getPurposeCategoriesAllowedByRoleList(
            this.empresaSelected.getId(),
            this.roleSpecificId
          );

        this.mergePurposes();
      }

      this.PurposeCategorySelected = this.ocInfo.getPurposeCategory();

      this.noPhysicalInvoice = this.ocInfo.getNoPhysicalInvoice();
      //Axel Vargas

      this.listSale = await this.extServOC.getSales(
        this.empresaSelected.getId(),
        this.proveedorSelected.getAccountNum()
      );

      this.purchPoolSelected = this.setDataByPO(
        this.ocInfo.getPurchPool(),
        this.listPurchPool
      ) as PurchPoolModel;
      //DG
      this.purposeSelected = this.setDataByPO(
        this.ocInfo.getPurpose(),
        this.listPurpose
      ) as CatalogModel;

      //Axel Vargas
      if (this.purposeSelected !== null && this.purposeSelected !== undefined) {
        this.needToSetCategory = this.PurposeAllowedByRole.some(
          (x) => x.Purpose === this.purposeSelected.getId().toString()
        );
      }
      //Axel Vargas

      this.saleRequired = this.purposeSelected.getSalesOrderRelation();

      this.saleSelected = this.setDataByPO(
        this.ocInfo.getSale(),
        this.listSale
      ) as CatalogModel;

      this.currencySelected = new CurrencyInfoModel(
        this.proveedorSelected.getCurrency(),
        ''
      );
      this.languageSelected = new LanguageModel(
        this.proveedorSelected.getLanguageId().toLowerCase()
      );
      this.fechaFin = this.ocInfo.getFechaFin();
      this.fechaInicio = this.ocInfo.getFechaIni();

      this.sitioSelected = this.setDataByPO(
        this.ocInfo.getSite(),
        this.listSitio
      ) as SitioModel;
      this.fechaEntrega = this.ocInfo.getFechaEntrega();

      this.cantContenedor = this.ocInfo.getContainerNum();
      this.cantTEUS = this.ocInfo.getTEUS();
      this.containerTypeSelected = this.setDataByPO(
        this.ocInfo.getContainerType(),
        this.containerTypeList
      ) as CatalogModel;

      this.cantDLibres = this.ocInfo.getFreeDays();
      this.salidaSolicD = this.ocInfo.getSalidaSolicD();
      this.salidaArriboD = this.ocInfo.getSalidadArriboD();
      this.soliLlegaBD = this.ocInfo.getSoliLlegaBod();

      this.puertoSelected = this.setDataByPO(
        this.ocInfo.getPuerto(),
        this.puertoList
      ) as CatalogModel;
      this.navieraSelected = this.setDataByPO(
        this.ocInfo.getNaviera(),
        this.navieraList
      ) as CatalogModel;

      this.incotermSelected = this.setDataByPO(
        this.ocInfo.getIncoterm(),
        this.incotermList
      ) as CatalogModel;
      this.estadoPedidoSelected = this.setDataByPO(
        this.ocInfo.getStatus(),
        this.estadoPedido
      ) as CatalogModel;

      this.noteOC = this.ocInfo.getNote();
      this.aDepartamentoSelected = this.setDataByPO(
        this.ocInfo.getA_Departamento(),
        this.aDepartamento
      ) as CatalogModel;

      this.bCentroCostoSelected = this.setDataByPO(
        this.ocInfo.getB_CentroCosto(),
        this.bCentroCosto
      ) as CatalogModel;

      this.cPropositoSelected = this.setDataByPO(
        this.ocInfo.getC_Proposito(),
        this.cProposito
      ) as CatalogModel;
      this.taxInclude = this.ocInfo.getTaxInclude();

      this.proyectoSelected = this.setDataByPO(
        this.ocInfo.getTypeProject(),
        this.proyectoType
      ) as CatalogModel;

      this.tipoOCSelected = this.setDataByPO(
        this.ocInfo.getTypePurchaseOrder(),
        this.typeOCList
      ) as CatalogModel;

      this.ocParameter.setListFile(this.ocInfo.getListInfoFile());
    } else {
      this.setDataVendor();
      this.purchPoolSelected = this.listPurchPool[0];
      this.purposeSelected = this.listPurpose[0];
      this.saleSelected = new CatalogModel('' as any, '', false, '' as String);
      this.fechaFin = null;
      this.fechaInicio = null;
      this.sitioSelected = this.listSitio[0];
      this.fechaEntrega = null;
      this.cantContenedor = 0;
      this.cantTEUS = 0;
      this.cantDLibres = 0;
      this.containerTypeSelected = this.containerTypeList[0];
      this.salidaSolicD = null;
      this.salidaArriboD = null;
      this.soliLlegaBD = null;
      this.puertoSelected = this.puertoList[0];
      this.navieraSelected = this.navieraList[0];
      this.incotermSelected = this.incotermList[0];
      this.estadoPedidoSelected = this.estadoPedido[5];
      this.noteOC = '';
      this.taxInclude = false;
      this.proyectoSelected = this.proyectoType[0];
      this.tipoOCSelected = this.typeOCList[0];
      this.ocParameter.setListFile([]); // Es una orden nueva, se seta vacio los datos de los archivos
      await this.setDataDimensionByUser();
    }

    this.listAlmacen = await this.extServOC.getInventLocaInfo(
      this.sitioSelected.getId(),
      this.empresaSelected.getId()
    );
    if (this.ocInfo === null || this.ocInfo === undefined) {
      this.listAlmacen.length > 1
        ? (this.almacenSelected = this.listAlmacen[1])
        : (this.almacenSelected = this.listAlmacen[0]);
    } else {
      this.almacenSelected = this.setDataByPO(
        this.ocInfo.getLocation(),
        this.listAlmacen
      ) as AlmacenModel;
    }
  }

  async setDataDimensionByUser() {
    const vatNum = await this.localServOC.getCedulaActual();
    const dataDimUser = await this.extServOC.getFinancialDimensionByUser(
      vatNum
    );
    // Se cargan los datos.
    if (dataDimUser.length > 0) {
      // tiene rol, se extre la información
      //console.log('Este es el agente: ' + dataDimUser[0]['roleSpecificId']);
      //console.log(
      //);

      //Axel Vargas
      this.roleSpecificId = dataDimUser[0]['roleSpecificId'];
      this.extServOC.roleSpecificId = this.roleSpecificId;

      this.PurposeAllowedByRole = await this.extServOC.getPurposeAllowedByRole(
        this.empresaSelected.getId(),
        this.roleSpecificId
      );
      this.PurposeCategoriesAllowedByRole =
        await this.extServOC.getPurposeCategoriesAllowedByRole(
          this.empresaSelected.getId(),
          this.roleSpecificId
        );
      this.PurposeCategoriesAllowedByRoleList =
        await this.extServOC.getPurposeCategoriesAllowedByRoleList(
          this.empresaSelected.getId(),
          this.roleSpecificId
        );

      this.mergePurposes();
      //Axel Vargas

      this.aDepartamentoSelected = this.setDataByPO(
        new CatalogModel(dataDimUser[0].getA_Departamento() as any, ''),
        this.aDepartamento
      ) as CatalogModel;
      this.bCentroCostoSelected = this.setDataByPO(
        new CatalogModel(dataDimUser[0].getB_CentroCosto() as any, ''),
        this.bCentroCosto
      ) as CatalogModel;
      this.cPropositoSelected = this.setDataByPO(
        new CatalogModel(dataDimUser[0].getC_Proposito() as any, ''),
        this.cProposito
      ) as CatalogModel;
    } else {
      //no tiene ningun role asignado
      this.aDepartamentoSelected = this.aDepartamento[0];
      this.bCentroCostoSelected = this.bCentroCosto[0];
      this.cPropositoSelected = this.cProposito[0];
    }
  }

  setDataVendor() {
    if (this.listProveedor.length > 0) {
      this.proveedorSelected = this.listProveedor[0];
      //Axel Vargas
      if (this.proveedorSelected.getRegimenId() == '02') {
        this.requireNoPhysicalInvoice = true;
        this.extServOC.requireNoPhysicalInvoice = true;
      } else {
        this.requireNoPhysicalInvoice = false;
        this.extServOC.requireNoPhysicalInvoice = false;
      }
      //Axel Vargas
      this.currencySelected = new CurrencyInfoModel(
        this.proveedorSelected.getCurrency(),
        ''
      );
      this.languageSelected = new LanguageModel(
        this.proveedorSelected.getLanguageId().toLowerCase()
      );
    } else {
      this.proveedorSelected = new ProveedorModel('', '', '', '', '');
      this.currencySelected = new CurrencyInfoModel('', '');
      this.languageSelected = new LanguageModel('');
    }
  }

  actualMonto() {
    this.montoCantidadP = this.montoUser * this.cantidadProducto;
  }

  async onSelectChangeSitio(sitioSelected: SitioModel, dataArea: string) {
    if (sitioSelected.getId() !== '') {
      this.listAlmacen = await this.extServOC.getInventLocaInfo(
        sitioSelected.getId(),
        dataArea
      );
      this.listAlmacen.length > 1
        ? (this.almacenSelected = this.listAlmacen[1])
        : (this.almacenSelected = this.listAlmacen[0]);
    } else {
      this.listAlmacen = [];
      this.listAlmacen.push(new AlmacenModel('', ''));
      this.almacenSelected = this.listAlmacen[0];
    }
  }

  async onSelectChangePurpose(
    purposeSelected: CatalogModel,
    dataArea: string,
    vendorAccountNum: string
  ) {
    this.PurposeCategorySelected = '';
    this.needToSetCategory = this.PurposeAllowedByRole.some(
      (x) => x.Purpose === this.purposeSelected.getId().toString()
    );
    if (
      (purposeSelected.getId() as any) !== '' &&
      purposeSelected.getSalesOrderRelation()
    ) {
      this.listSale = await this.extServOC.getSales(dataArea, vendorAccountNum);
      this.listSale.length > 1
        ? (this.saleSelected = this.listSale[1])
        : (this.saleSelected = this.listSale[0]);
      this.saleRequired = purposeSelected.getSalesOrderRelation();
    } else {
      this.listSale = [];
      this.listSale.push(new CatalogModel('' as any, '', false, '' as String));
      this.saleSelected = this.listSale[0];
      this.saleRequired = false;
    }
  }

  async onSelectChangeDataArea(dataAreaId: string) {
    this.listProveedor = await this.extServOC.getVendInfo(
      this.empresaSelected.getId()
    );
    this.listSitio = await this.extServOC.getInventSiteInfo(
      this.empresaSelected.getId()
    );
    this.sitioSelected = this.setDataByPO(null, this.listSitio) as SitioModel;
    this.onSelectChangeSitio(this.sitioSelected, this.empresaSelected.getId());
    this.setDataVendor(); // Setea los datos por proveedor
    this.listPurchPool = await this.extServOC.getPurchPoolInfo(
      this.empresaSelected.getId()
    );
    this.purchPoolSelected = this.setDataByPO(
      null,
      this.listPurchPool
    ) as PurchPoolModel;
    this.puertoList = await this.extServOC.getPuertosInfo(
      this.empresaSelected.getId()
    );
    this.puertoSelected = this.setDataByPO(
      null,
      this.puertoList
    ) as CatalogModel;
    this.navieraList = await this.extServOC.getNavierasInfo(
      this.empresaSelected.getId()
    );
    this.navieraSelected = this.setDataByPO(
      null,
      this.navieraList
    ) as CatalogModel;
    this.proyectoType = await this.extServOC.getProjects(
      3,
      this.empresaSelected.getId()
    );
    this.proyectoSelected = this.setDataByPO(
      null,
      this.proyectoType
    ) as CatalogModel;
    this.typeOCList = await this.extServOC.getOCType();
    this.tipoOCSelected = this.setDataByPO(
      null,
      this.typeOCList
    ) as CatalogModel;
    this.listPurpose = await this.extServOC.getPurposes(
      this.empresaSelected.getId()
    );
    this.purposeSelected = this.setDataByPO(
      null,
      this.listPurpose
    ) as CatalogModel;
    // this.saleSelected = this.setDataByPO(null,this.listSale) as CatalogModel;
    this.onSelectChangePurpose(
      this.purposeSelected,
      this.empresaSelected.getId(),
      this.proveedorSelected.getAccountNum()
    );

    //Axel Vargas
    this.PurposeCategorySelected = '';
    this.PurposeAllowedByRole = await this.extServOC.getPurposeAllowedByRole(
      this.empresaSelected.getId(),
      this.roleSpecificId
    );
    this.PurposeCategoriesAllowedByRole =
      await this.extServOC.getPurposeCategoriesAllowedByRole(
        this.empresaSelected.getId(),
        this.roleSpecificId
      );
    this.PurposeCategoriesAllowedByRoleList =
      await this.extServOC.getPurposeCategoriesAllowedByRoleList(
        this.empresaSelected.getId(),
        this.roleSpecificId
      );

    this.mergePurposes();

    this.noPhysicalInvoice = '';
    //Axel Vargas
  }

  getTaxesInfo(newTaxesInfo: boolean) {
    this.taxInclude = newTaxesInfo;
    if (this.ocInfo && this.ocInfo.getLineList().length > 0) {
      this.ocInfo.setTaxInclude(this.taxInclude);
      this.ocInfo.calcularDatosLines();
      this.ocInfo.calcularDatos();
    }
  }

  /**
   * Funcion que abre la pantalla de busqueda con una lista en especifico
   * retorna el dato seleccionado, que luego sera procesado.
   */
  async openSearchBar(typeSearch: string) {
    switch (typeSearch) {
      case 'search-pro':
        if (!this.toEdit) {
          this.openSearchBarAux(this.listProveedor, typeSearch);
        }
        break;
      case 'search-pool':
        this.openSearchBarAux(this.listPurchPool, typeSearch);
        break;
      case 'search-purpose':
        this.openSearchBarAux(this.listPurpose, typeSearch);
        break;
      case 'search-saleOrder':
        this.openSearchBarAux(this.listSale, typeSearch);
        break;
      case 'language':
        this.openSearchBarAux(this.listlanguage, typeSearch);
        break;
      case 'currency':
        this.openSearchBarAux(this.listCurrency, typeSearch);
        break;
      case 'search-sitio':
        this.openSearchBarAux(this.listSitio, typeSearch);
        break;
      case 'search-almacen':
        this.openSearchBarAux(this.listAlmacen, typeSearch);
        break;
      case 'search-puerto':
        this.openSearchBarAux(this.puertoList, typeSearch);
        break;
      case 'search-naviera':
        this.openSearchBarAux(this.navieraList, typeSearch);
        break;
      case 'search-incoterm':
        this.openSearchBarAux(this.incotermList, typeSearch);
        break;
      case 'search-pedido-state':
        this.openSearchBarAux(this.estadoPedido, typeSearch);
        break;
      case 'search-type-container':
        this.openSearchBarAux(this.containerTypeList, typeSearch);
        break;
      case 'search-data-area':
        if (!this.toEdit) {
          this.openSearchBarAux(this.listEmpresa, typeSearch);
        }
        break;
      case 'search-A_Departamento':
        this.changeFinancialDimension(this.aDepartamento, typeSearch);
        break;
      case 'search-B_CentroCosto':
        this.changeFinancialDimension(this.bCentroCosto, typeSearch);
        break;
      case 'search-C_Proposito':
        this.changeFinancialDimension(this.cProposito, typeSearch);
        break;
      case 'search-projectType':
        this.openSearchBarAux(this.proyectoType, typeSearch);
        break;
      case 'search-type-oc':
        this.openSearchBarAux(this.typeOCList, typeSearch);
        break;
      case 'categoriesPurpose':
        this.openSearchBarAux(
          this.changeTheCategoriesByPurposeValidated(),
          typeSearch
        );
        break;
      default:
        break;
    }
  }

  /**
   * Para los parametros de dimensiones financieras, en el encabezado
   * se debe contemplar si tiene lineas para caerles
   */
  changeFinancialDimension(listInfo: any[], typeSearch: string) {
    if (this.ocInfo && this.ocInfo.getLineList().length > 0) {
      this.messageInfoDimensiones(listInfo, typeSearch);
    } else {
      //Al no tener lineas
      this.openSearchBarAux(listInfo, typeSearch);
    }
  }

  /**
   *
   * @param listInfo
   * @param typeSearch
   * Funcion para abrir una pantalla y retornar el elemento de busqueda
   * recibe la lista a filtrar
   */
  async openSearchBarAux(listInfo: any[], typeSearch: string) {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: listInfo,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.setDataSelected(data.objSelected, typeSearch);
        }
      }
    });
  }

  // Asigna el dato seleccionado a la variable
  setDataSelected(objectSelected: any, type: string) {
    // Si es el proveedor se cambia otras cosas tambien
    switch (type) {
      case 'search-pro':
        this.proveedorSelected = objectSelected as ProveedorModel;
        //Axel Vargas
        if (this.proveedorSelected.getRegimenId() == '02') {
          this.requireNoPhysicalInvoice = true;
          this.extServOC.requireNoPhysicalInvoice = true;
        } else {
          this.requireNoPhysicalInvoice = false;
          this.extServOC.requireNoPhysicalInvoice = false;
        }

        this.noPhysicalInvoice = '';
        //Axel Vargas
        this.currencySelected = new CurrencyInfoModel(
          this.proveedorSelected.getCurrency(),
          ''
        );
        this.languageSelected = new LanguageModel(
          this.proveedorSelected.getLanguageId().toLowerCase()
        );
        this.onSelectChangePurpose(
          this.purposeSelected,
          this.empresaSelected.getId(),
          this.proveedorSelected.getAccountNum()
        );
        break;
      case 'search-pool':
        this.purchPoolSelected = objectSelected as PurchPoolModel;
        break;
      case 'search-purpose':
        const tempPurposeSelected = objectSelected as CatalogModel;
        if ((tempPurposeSelected.getId() as any) !== '') {
          this.purposeSelected = objectSelected as CatalogModel;
          this.onSelectChangePurpose(
            this.purposeSelected,
            this.empresaSelected.getId(),
            this.proveedorSelected.getAccountNum()
          );
        } else {
          this.messageInfo('Atención', 'Debe seleccionar el propósito de OC.');
        }
        break;
      case 'search-saleOrder':
        const tempSaleSelected = objectSelected as CatalogModel;
        if ((tempSaleSelected.getId() as any) !== '') {
          this.saleSelected = objectSelected as CatalogModel;
        } else {
          this.messageInfo('Atención', 'Debe seleccionar un OV.');
        }
        break;
      case 'language':
        this.languageSelected = objectSelected as LanguageModel;
        break;
      case 'currency':
        this.currencySelected = objectSelected as CurrencyInfoModel;
        break;
      case 'search-sitio':
        this.sitioSelected = objectSelected as SitioModel;
        this.onSelectChangeSitio(
          this.sitioSelected,
          this.empresaSelected.getId()
        );
        break;
      case 'search-almacen':
        this.almacenSelected = objectSelected as AlmacenModel;
        break;
      case 'search-puerto':
        this.puertoSelected = objectSelected as CatalogModel;
        break;
      case 'search-naviera':
        this.navieraSelected = objectSelected as CatalogModel;
        break;
      case 'search-incoterm':
        this.incotermSelected = objectSelected as CatalogModel;
        break;
      case 'search-pedido-state':
        this.estadoPedidoSelected = objectSelected as CatalogModel;
        break;
      case 'search-type-container':
        this.containerTypeSelected = objectSelected as CatalogModel;
        break;
      case 'search-data-area':
        this.empresaSelected = objectSelected as DataArea;
        this.onSelectChangeDataArea(this.empresaSelected.getId());
        break;
      case 'search-A_Departamento':
        this.aDepartamentoSelected = objectSelected as CatalogModel;
        this.updateLineFinancialDimension();
        break;
      case 'search-B_CentroCosto':
        this.bCentroCostoSelected = objectSelected as CatalogModel;
        this.updateLineFinancialDimension();
        break;
      case 'search-C_Proposito':
        this.cPropositoSelected = objectSelected as CatalogModel;
        this.updateLineFinancialDimension();
        break;
      case 'search-projectType':
        this.proyectoSelected = objectSelected as CatalogModel;
        break;
      case 'search-type-oc':
        this.tipoOCSelected = objectSelected as CatalogModel;
        break;
      case 'categoriesPurpose':
        this.PurposeCategorySelected = (
          objectSelected as CatalogModel
        ).getName();
        break;
      default:
        break;
    }
  }

  // Asigna las fechas de inicio y fin para la OC
  getDatesIniFin(datesInfo: DayIniFin) {
    this.fechaInicio = datesInfo.dayIni;
    this.fechaFin = datesInfo.dayFin;
  }

  // Asigna la fecha de entrega
  getDatesEntrega(dayInfo: Date) {
    this.fechaEntrega = dayInfo;
  }

  // Asigna la informacion de contenedor de OC
  getInfoPlusNumber(dataInfo: { value: number; type: number }) {
    switch (dataInfo.type) {
      case 1:
        this.cantContenedor = dataInfo.value;
        break;
      case 2:
        this.cantTEUS = dataInfo.value;
        break;
      case 3:
        this.cantDLibres = dataInfo.value;
        break;
      default:
        break;
    }
  }
  // Asigna la informacion de Fechas Plus
  getDatesPlus(dataInfo: { sSoli: Date; sArribo: Date; sLlegB: Date }) {
    this.salidaArriboD = dataInfo.sArribo;
    this.salidaSolicD = dataInfo.sSoli;
    this.soliLlegaBD = dataInfo.sLlegB;
  }
  // Asigna la nota del header de la OC
  getNote(note: string) {
    this.noteOC = note;
  }

  setDataInfoOC() {
    let purchHeader: PurchOrder;
    purchHeader = new PurchOrder(
      this.proveedorSelected.getAccountNum(),
      this.purchPoolSelected,
      this.languageSelected.getId(),
      this.currencySelected.getId(),
      this.fechaInicio,
      this.fechaFin,
      this.sitioSelected,
      this.almacenSelected,
      this.fechaEntrega,
      this.cantContenedor,
      this.cantTEUS,
      this.containerTypeSelected,
      this.cantDLibres,
      this.salidaSolicD,
      this.salidaArriboD,
      this.soliLlegaBD,
      this.puertoSelected,
      this.navieraSelected,
      this.incotermSelected,
      '',
      this.estadoPedidoSelected,
      this.noteOC,
      this.empresaSelected,
      this.actualVatNum
    );
    purchHeader.setVendName(this.proveedorSelected.getName());
    purchHeader.setA_Departamento(this.aDepartamentoSelected);
    purchHeader.setB_CentroCosto(this.bCentroCostoSelected);
    purchHeader.setC_Proposito(this.cPropositoSelected);
    purchHeader.setTaxInclude(this.taxInclude);
    purchHeader.setTypeProject(this.proyectoSelected);
    purchHeader.setPurpose(this.purposeSelected);
    //Axel Vargas
    purchHeader.setPurposeCategory(this.PurposeCategorySelected);
    purchHeader.setNoPhysicalInvoice(this.noPhysicalInvoice);
    //Axel Vargas
    purchHeader.setSale(this.saleSelected);
    purchHeader.setTypePurchaseOrder(this.tipoOCSelected);
    // Si no es una orden nueva debemos de setear los datos relacionados a la linea y los id
    if (this.ocInfo !== null && this.ocInfo !== undefined) {
      purchHeader.subTotal = this.ocInfo.subTotal;
      purchHeader.taxes = this.ocInfo.taxes;
      purchHeader.total = this.ocInfo.total;
      purchHeader.totalDiscount = this.ocInfo.totalDiscount;
      purchHeader.cantidadLineas = this.ocInfo.cantidadLineas;
      purchHeader.setIdOC(this.ocInfo.getIdOC());
      purchHeader.setLocalId(this.ocInfo.getLocalId());
      purchHeader.setLineList(this.ocInfo.getLineList());
      purchHeader.setFechaCreacion(this.ocInfo.getFechaCreacion());
      purchHeader.setCreatedBy(this.ocInfo.getCreatedBy());
      purchHeader.setDocumentStateI(this.ocInfo.getDocumentStateI());
      purchHeader.setPurchStatus(this.ocInfo.getPurchStatus());
    }
    return purchHeader;
  }

  async updatePurchaseOrder(toSendData: PurchOrder) {
    if (!this.sendingData) {
      this.sendingData = true;
      let titulo = '';
      let mensaje = '';
      let resp: any;
      await this.showSpinnerLoading('Enviando cambios de OC.');
      try {
        const header = toSendData.headerToJSON();
        resp = await this.extServOC.updateHeaderPurchase(header);
        if (resp === 'SUCCESS') {
          // Si sale todo bien se cambia de modo edicion
          this.ocParameter.setPurchOrderParameter(toSendData);
          titulo = 'Atención';
          mensaje =
            'La orden de compra ha sido actualizada ' + toSendData.getIdOC();
        } else {
          titulo = 'Error';
          mensaje = 'No se pudo actualizar la orden de compra.';
        }
      } catch (error) {
        // Hubo un error, la orden sigue siendo la misma.
        titulo = 'Error';
        mensaje = 'No se comunicar con el servidor.';
      }
      this.dissmissLoading();
      this.messageInfo(titulo, mensaje);
      this.sendingData = false;
      if (resp === 'SUCCESS') {
        await this.modoEditing('Saliendo de modo edición', 10); // 'EN REVISIÓN' === 10
      }
    }
  }

  async sendDataOCToUpdate(
    titulo: string,
    mensaje: string,
    purchInfo: PurchOrder
  ) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [
          {
            text: 'ACEPTAR',
            handler: () => {
              this.updatePurchaseOrder(purchInfo);
            },
          },
          {
            text: 'CANCELAR',
            handler: async () => {
              // Como no se quiere guardar los cambios no se setea el parametro, deja igual
              await this.modoEditing('Saliendo de modo edición', 10); // 'EN REVISIÓN' === 10
              // Se setea la orden anterior
              this.goToLBackEditApproved();
            },
          },
        ],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async goToLBackEditApproved() {
    // this.ocParameter.setToEdit(false);
    if (this.isEditing) {
      const tmpPurchase = this.setDataInfoOC();
      // Se manda los cambios si la persona quiere
      await this.sendDataOCToUpdate(
        'Atención',
        '¿Cambios realizados a la orden, desea enviar los cambios?',
        tmpPurchase
      );
    } else {
      if (!this.ocParameter.getEditated()) {
        // Si no fue editado.
        this.ocParameter.setPurchOrderParameter(
          this.ocParameter.getPurchOrderParameter()
        );
        console.log('No fue editada la orden');
      } else {
        console.log('Fue editada la orden');
      }
      this.goToBack();
    }
  }

  goToBack() {
    this.navCtrl.pop();
  }
  // Ir a la pantalla de Linea
  goToLines() {
    const purchHeader = this.setDataInfoOC();
    this.goToLinesAux(purchHeader);
  }

  goToLinesAux(purchHeader: PurchOrder) {
    if (purchHeader.getAccountNum() !== '') {
      if (
        purchHeader.getSite().getId() !== '' ||
        purchHeader.getLocation().getId() !== ''
      ) {
        /*if (purchHeader.getA_Departamento().getId() as any !== ''
          && purchHeader.getB_CentroCosto().getId() as any !== ''
          && purchHeader.getC_Proposito().getId() as any !== '') {*/
        this.ocParameter.setPurchOrderParameter(purchHeader);
        this.ocParameter.setToEdit(false);
        this.navService.navigateTo(NavigationRoutes.PurchOrderLinePage);
        /*} else {
            this.messageInfo('Error', 'No hay datos de dimensiones financieras.');
          }*/
      } else {
        this.messageInfo('Error', 'No hay datos del sitio o almacén.');
      }
    } else {
      this.messageInfo('Error', 'No hay datos del proveedor para la OC.');
    }
  }

  openMenuOC(evento) {
    const purchHeader = this.setDataInfoOC();
    this.openMenuAux(purchHeader, evento);
  }

  async openMenuAux(purchHeader: PurchOrder, evento) {
    const popover = await this.popoverCtrl.create({
      component: PopInfoOcComponent,
      componentProps: { infoOC: purchHeader },
      cssClass: ['custom-popover', 'pop-info-oc'],
      backdropDismiss: true,
      showBackdrop: true,
    });
    await popover.present(evento);
  }

  async messageInfo(titulo: string, mensaje: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: [{ text: 'Aceptar', handler: () => {} }],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  updateLineFinancialDimension() {
    if (this.ocInfo && this.ocInfo.getLineList().length > 0) {
      this.ocInfo.getLineList().forEach((linea) => {
        linea
          .getA_Departamento()
          .setValues(
            this.aDepartamentoSelected.getId(),
            this.aDepartamentoSelected.getName()
          );
        linea
          .getB_CentroCosto()
          .setValues(
            this.bCentroCostoSelected.getId(),
            this.bCentroCostoSelected.getName()
          );
        linea
          .getC_Proposito()
          .setValues(
            this.cPropositoSelected.getId(),
            this.cPropositoSelected.getName()
          );
      });
    }
  }

  async messageInfoDimensiones(listInfo: any[], typeSearch: string) {
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: 'Atención',
        subHeader: 'Cambio de dimensiones financieras',
        message:
          'Al cambiar una dimensión financiera se actualizan las dimensiones financieras de las líneas de la orden.',
        buttons: [
          { text: 'Cancelar', handler: () => {} },
          {
            text: 'Aceptar',
            handler: () => {
              this.openSearchBarAux(listInfo, typeSearch);
            },
          },
        ],
        backdropDismiss: true,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async modoEditing(mensaje: string, estado: number) {
    if (!this.sendingData) {
      this.sendingData = true;
      await this.showSpinnerLoading(mensaje);
      //Hay que sacar la cedula
      const cedula = await this.localServOC.getCedulaActual();
      const resp = await this.extServOC.editingMode(
        estado,
        this.ocInfo.getIdOC(),
        this.ocInfo.getDataArea().getId(),
        cedula
      );
      this.dissmissLoading();
      this.sendingData = false;
      this.resolveEditingMode(resp);
    }
  }

  async resolveEditingMode(respuesta: any) {
    if (typeof respuesta === 'string') {
      switch (respuesta) {
        case 'CANNOTEDIT':
          this.messageInfo(
            'Error',
            'La orden está siendo editada por otro usuario.'
          );
          this.isEditing = false;
          break;
        case 'EDITING':
          this.messageInfo('Atención', 'Puede editar la orden.');
          this.isEditing = true; //Se espera que para este momento todo este cargado
          break;
        case 'SUCCESS':
          this.isEditing = false;
          this.ocParameter.setEditated(true);
          break;
        default:
          break;
      }
    } else {
      this.messageInfo(
        'Error',
        'No se puede editar la orden en este momento, no se pudo comunicar con el servidor.'
      );
    }
  }

  //Axel Vargas
  /**Método que se encarga de retornar la lista de Categorías válidas.*/
  changeTheCategoriesByPurposeValidated(): CatalogModel[] {
    let returnData: CatalogModel[] = [];
    if (
      this.purposeSelected !== undefined &&
      this.purposeSelected.getName() !== ''
    ) {
      if (
        this.PurposeAllowedByRole.some(
          (x) => x.Purpose === this.purposeSelected.getId().toString()
        )
      ) {
        this.PurposeCategoriesAllowedByRole.filter(
          (x) => x.Purpose === this.purposeSelected.getId().toString()
        ).forEach((x) => {
          if (
            !returnData.some(
              (y) => x.PurposeCategory === y.getId().toString()
            ) &&
            this.PurposeCategoriesAllowedByRoleList.some(
              (y) => x.PurposeCategory === y.getId().toString()
            )
          ) {
            returnData.push(
              this.PurposeCategoriesAllowedByRoleList.find(
                (y) => x.PurposeCategory === y.getId().toString()
              )
            );
          }
        });
      }
    }
    return returnData;
  }

  /**Método para conseguir el Número de Factura Física.*/
  async noPhysicalInvoiceEmit(noPhysicalInvoice: string) {
    const isValid: Number = await this.extServOC.getNoPhysicalInvoiceIsValid(
      this.empresaSelected.getId(),
      noPhysicalInvoice,
      this.proveedorSelected.getAccountNum()
    );
    //console.log(isValid);
    switch (isValid) {
      case 0:
        this.noPhysicalInvoice = '';
        this.messageInfo(
          'ERROR',
          'Número Factura ha sido registrado previamente.'
        );
        break;

      case 1:
        this.noPhysicalInvoice = noPhysicalInvoice;
        break;

      default:
        this.noPhysicalInvoice = '';
        this.messageInfo(
          'ERROR',
          'Problema de comunicación con el servidor, por favor, inténtelo de nuevo.'
        );
        break;
    }
  }

  /** Método para combinar los propósitos comunes con los propósitos permitidos al usuario. */
  mergePurposes() {
    const listPurposes: CatalogModel[] = [];
    this.PurposeAllowedByRole.forEach((x) => {
      const descripcion = x.Purpose;
      const valueS = x.Purpose;
      const tmp = new CatalogModel(valueS as any, descripcion as any);
      listPurposes.push(tmp);
    });

    if (listPurposes.length > 0) {
      this.listPurpose = this.listPurpose
        .concat(listPurposes)
        .sort((a, b) =>
          a.getId().toString().localeCompare(b.getId().toString())
        );
      //console.log('Merge Purposes', this.listPurpose);
    }
  }
  //Axel Vargas
}
