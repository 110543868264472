<app-nav-bar [title]="'Registro'" [start]="'GoBack'"></app-nav-bar>

<ion-content class="ion-padding" center text-center>
  <h3 class="title2">Info Adicional</h3>
  <form #registerForm="ngForm">
    <ion-list>
      <ion-item>
        <ion-input
          label="Sitio Web"
          label-placement="floating"
          class="floating"
          type="text"
          [(ngModel)]="register_data.webSite"
          [ngModelOptions]="{ standalone: true }"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-textarea
          label="Información Adicional"
          label-placement="floating"
          class="floating"
          type="text"
          rows="2"
          [(ngModel)]="register_data.additional_info"
          [ngModelOptions]="{ standalone: true }"
        ></ion-textarea>
      </ion-item>

      <ion-item style="text-align: center">
        <ion-button class="submit-btn" (click)="addNewRestriction()">
          <ion-icon name="add-outline"></ion-icon> Restricción de Entrega
        </ion-button>
      </ion-item>

      <ion-list class="list-height">
        <ion-item
          class="restriction"
          *ngFor="let restriction of register_data.restrictions"
          (click)="addRestriction(restriction)"
        >
          <span style="white-space: normal">{{
            getRestrictionInfo(restriction)
          }}</span>
          <br />
          <span>{{ getRestrictionHours(restriction) }}</span>
          <ion-icon
            name="trash"
            style="z-index: 1"
            item-right
            (click)="deleteRestriction(restriction)"
          ></ion-icon>
        </ion-item>
      </ion-list>
    </ion-list>

    <ion-button
      class="submit-btn"
      type="submit"
      (click)="registerFinalStep()"
      margin-top
      [disabled]="!registerForm.form.valid"
      expand="block"
    >
      <ion-icon name="person-add-outline"></ion-icon> Registrarse
    </ion-button>
  </form>
</ion-content>
