import { Component } from '@angular/core';
import {
  LoadingController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { PurchLinesInfoParamv2 } from '../../OOP/PurchLinesInfoParamv2.interface';
import { PurchLinesSummary } from '../../OOP/PurchLinesSummary.interface';
import { InvoiceMode } from '../../OOP/invoiceMode.enum';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-invoice-lines',
  templateUrl: './invoice-lines.component.html',
  styleUrls: ['./invoice-lines.component.scss'],
})
export class InvoiceLinesPage {
  Company: string = '';
  PurchOrder: PurchLinesInfoParamv2;
  PurchLines: PurchLinesSummary[] = [];

  loader: HTMLIonLoadingElement;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    public loadingCtrl: LoadingController,
    private invServ: InvoiceService
  ) {
    this.Company = this.navParams.get('Company');
    this.PurchOrder = this.navParams.get('PurchOrder') as PurchLinesInfoParamv2;
    this.PurchLines = this.navParams.get('PurchLines') as PurchLinesSummary[];
  }

  async alertMakeInvoice() {
    let OkCancel = false;
    let modal = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: MessageBoxComponent,
      componentProps: {
        MessageBoxParams: {
          header: 'AVISO',
          message: `Desea hacer la facturación de ${this.PurchOrder.purchId}?`,
          noYes: true,
        },
      },
    });
    modal.present();

    modal.onDidDismiss().then((res) => {
      let data = res.data;
      if (data.result) {
        this.makeInvoice();
      }
    });

    return OkCancel;
  }

  async makeInvoice() {
    await this.showSpinnerLoading(
      `Facturando ${this.PurchOrder.purchId}`,
      'bubbles'
    );

    let data: any = await this.invServ.InvoiceSpecificPurchOrder(
      this.Company,
      this.PurchOrder.purchId,
      InvoiceMode[this.PurchOrder.invoiceMode]
    );

    if (data) {
      let modal = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: MessageBoxComponent,
        componentProps: {
          MessageBoxParams: {
            header: data.Status ? 'INFO' : 'ERROR',
            message: data.Message,
            noYes: false,
          },
        },
      });
      modal.present();

      modal.onDidDismiss().then(() => {
        if (data.Status) {
          localStorage.setItem('StatusOC', 'true');
          this.closePage();
        }
      });
    } else {
      let modal = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: MessageBoxComponent,
        componentProps: {
          MessageBoxParams: {
            header: 'ERROR',
            message: 'Ha ocurrido un error al realizar la operación.',
            noYes: false,
          },
        },
      });
      modal.present();
    }

    this.loader.dismiss();
  }

  async showSpinnerLoading(mensaje: string, spinner?: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async closePage() {
    this.navCtrl.pop();
  }
}
