import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { PSRtnHeaderHistory } from '../../../businessCore/OrderRet/PSRtnHeaderHistory';

@Component({
  selector: 'app-ret-product-history',
  templateUrl: './ret-product-history.component.html',
  styleUrls: ['./ret-product-history.component.scss'],
})
export class RetProductHistoryComponent {
  order: PSRtnHeaderHistory;

  /**
   * Class constructor with these injections:
   * @param params object that exists on a page and can contain data for that particular view.
   */
  constructor(private params: NavParams, public viewCtrl: PopoverController) {
    this.order = this.params.get('PSRtnHeaderHistory');

    //DEBUG
    /*this.order = new PSRtnHeaderHistory();
    let x = new PSRtnLineHistory();

    x.SALESID = 'OVA5S4D5';
    x.ITEMID = 'MTIDHI01';
    x.NAME = '1ASD';
    x.TAXGROUP = '1ASD';
    x.SALESPRICE = 14;
    x.SALESUNIT = '1A4SD5';
    x.CUSTACCOUNT = '1A4SD';
    x.SALESQTY = 12;
    this.order.LineHistory = [x];
    */
    //DEBUG
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
