<app-nav-bar [title]="'Resultados'"></app-nav-bar>

<ion-fab vertical="top" horizontal="end" (click)="displayInfoDialog()">
  <ion-fab-button
    color="gray"
    class="submit-btn"
    size="small"
    style="margin: 0px"
  >
    <i class="fas fa-question"></i>
  </ion-fab-button>
</ion-fab>

<ion-content
  class="ion-paddding"
  style="--border-color: var(--ion-color-primary); --border-radius: 100%"
>
  <ion-item lines="none" text-center no-padding>
    <ion-badge style="text-align: center; background: blue; margin: auto">
      <h6 style="color: aliceblue; font-weight: bold">2/2</h6>
    </ion-badge>
  </ion-item>

  <div class="flexColumn">
    <div
      style="font-size: 1rem; text-transform: lowercase; text-align: center"
      padding-bottom
      (click)="previewShoppingCar()"
    >
      <ion-icon color="primary" name="eye" style="font-size: 1rem"></ion-icon>
      <a style="text-transform: capitalize; text-decoration: underline"
        >VER CARRITO DE COMPRAS (SIN PROMOCIÓN)</a
      >
    </div>
    <div
      style="font-size: 1rem; text-transform: lowercase; text-align: justify"
      padding-bottom
    >
      <a style="text-transform: capitalize">PROMOCIÓN: </a> {{ promo.PromoId }}
    </div>
    <div
      style="font-size: 1rem; text-transform: lowercase; text-align: justify"
      padding-bottom
    >
      <a style="text-transform: capitalize">DESCRIPCIÓN: </a>
      {{ promo.Description }}
    </div>
  </div>

  <!-- Mandatory Results -->
  <fieldset class="fieldsetContainer" *ngIf="validMandatoryResult">
    <legend style="font-size: 1rem">
      <a>Resultados Mandatorios</a>
    </legend>
    <div style="font-size: 1rem">
      <i class="fas fa-info" style="margin-right: 0.5rem; color: orange"></i
      >Estos resultados son de uso obligatorio en la promoción y no se podrán
      editar.
    </div>
    <div *ngFor="let pr of mandatoryResult">
      <!-- HEADER -->
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid black;
          padding-top: 5px;
        "
      >
        <!-- <ion-checkbox class="chkBox" [(ngModel)]="pr.Selected" name="lineCheck" disabled></ion-checkbox> -->
        <div style="width: 100%; margin-left: 0.2rem">
          <div style="font-size: 1rem; color: green">
            {{ pr.Desc }}
          </div>
          <div style="font-size: 1rem">
            {{ pr.SubDesc }}
          </div>
        </div>
        <div style="width: 10%" (click)="pr.Showed = !pr.Showed">
          <h6 ion-text text-wrap color="secondary">
            <ion-icon *ngIf="!pr.Showed" ios="ios-add" md="md-add"></ion-icon>
            <ion-icon
              *ngIf="pr.Showed"
              ios="ios-remove"
              md="md-remove"
            ></ion-icon>
            Ver
          </h6>
        </div>
      </div>
      <!-- END HEADER -->

      <!-- BODY -->
      <div *ngIf="pr.Showed">
        <div
          *ngFor="let res of pr.ItemByResult"
          style="
            display: block;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dashed black;
            padding-top: 5px;
          "
        >
          <!-- Top Section -->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 0.5rem;
              font-size: 1rem;
            "
          >
            {{ res.ItemName }}
          </div>

          <!-- Bottom Section -->
          <div
            style="display: flex; align-items: center; justify-content: center"
            [ngClass]="{ itemDisabled: res.Amount <= 0 }"
          >
            <div (click)="displayItemDetails(res.ItemId)">
              <ion-icon
                color="primary"
                name="eye"
                style="font-size: 20px"
              ></ion-icon>
            </div>
            <div
              style="text-align: center; width: 25%"
              (click)="displayItemDetails(res.ItemId)"
            >
              <img
                class="image-container"
                alt="Imagen No Disponible"
                src="https://serviceplan-api.mercasacr.com/images/Products/{{
                  res.ItemId
                }}_l_.PNG"
                onerror="this.onerror=null;this.src='assets/img/error.png'"
              />
            </div>

            <div style="display: block; width: 25%">
              <h6 ion-text color="primary" style="text-align: center">
                Producto
              </h6>
              <h6 ion-text style="text-align: center">{{ res.ItemId }}</h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 0"
                style="font-size: 1rem; text-align: center"
              >
                En Condiciones
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 1"
                style="font-size: 1rem; text-align: center"
              >
                En Carrito
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 2"
                style="font-size: 1rem; text-align: center"
              >
                Agregar Nuevo
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 3"
                style="font-size: 1rem; text-align: center"
              >
                Regalía
              </h6>
            </div>

            <!-- Amount/Quantity section -->
            <div
              style="
                display: block;
                justify-content: center;
                align-items: center;
                width: 35%;
              "
            >
              <!-- UNIT Description Section and UNIT price -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 0.5rem">{{ res.UnitId }}</a
                  >₡{{ res.Amount_Taxes }}
                </h6>
                <h6 ion-text *ngIf="res.Amount <= 0">
                  <a style="margin-right: 0.5rem; color: brown"
                    >No Disponible</a
                  >
                </h6>
              </div>
              <!-- Quantity Section EDITABLE-->
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 0.8rem;
                "
                *ngIf="res.Origin == 2"
              >
                <ion-button
                  ion-button
                  icon-only
                  clear
                  style="z-index: 1"
                  style="width: 30%"
                  (click)="reduceQuantityItemResult(res, 0)"
                >
                  <i class="fas fa-minus-circle"></i>
                </ion-button>
                <div style="width: 40%; text-align: center">
                  <input
                    class="quantityClass"
                    type="number"
                    name="SaleQtyItemList"
                    id="SaleQtyItemList"
                    (keyup)="changeValueKeyUpItemResult($event, res, 0)"
                    [(ngModel)]="res.SalesQuantity"
                    min="0"
                    step="1"
                  />
                </div>
                <ion-button
                  ion-button
                  icon-only
                  clear
                  style="width: 30%"
                  (click)="increaseQuantityItemResult(res, 0)"
                >
                  <i class="fas fa-plus-circle"></i>
                </ion-button>
              </div>
              <!-- Quantity Section NO-EDITABLE-->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
                *ngIf="res.Origin != 2"
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Cantidad:</a
                  >{{ res.SalesQuantity }}
                </h6>
              </div>
              <!-- Total Amount Section -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Sub Total:</a
                  >
                  ₡{{ res.TotalSale }}
                </h6>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                  padding-top: 0.5rem;
                  border-top: 1px solid;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >% Desc:</a
                  >
                  {{ res.linePercent }}%
                </h6>
              </div>
              <!-- <div style="display: flex; align-items: center; margin-bottom: 0.5rem;">
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase;">Monto Desc`:</a> ₡{{res.lineDiscount}}</h6>
              </div> -->
              <div style="display: flex; align-items: center">
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Total:</a
                  >
                  ₡{{ res.TotalWithDisc }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END BODY -->
    </div>
  </fieldset>

  <!-- Optional Results -->
  <fieldset class="fieldsetContainer" *ngIf="validOptionalResult">
    <legend style="font-size: 1rem">
      <a>Opcionales</a>
    </legend>
    <div style="font-size: 1rem">
      <i class="fas fa-info" style="margin-right: 0.5rem; color: orange"></i
      >Estos resultados son de uso opcional, usted podrá seleccionarlos todos si
      así lo desea.
    </div>
    <div *ngFor="let pr of optionalResult">
      <!-- HEADER -->
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid black;
          padding-top: 5px;
        "
      >
        <ion-checkbox
          class="chkBox"
          [(ngModel)]="pr.Selected"
          name="lineCheck"
        ></ion-checkbox>
        <div style="width: 100%; margin-left: 0.2rem">
          <div style="font-size: 1rem; color: green">
            {{ pr.Desc }}
          </div>
          <div style="font-size: 1rem">
            {{ pr.SubDesc }}
          </div>
        </div>
        <div style="width: 10%" (click)="pr.Showed = !pr.Showed">
          <h6 ion-text text-wrap color="secondary">
            <ion-icon *ngIf="!pr.Showed" ios="ios-add" md="md-add"></ion-icon>
            <ion-icon
              *ngIf="pr.Showed"
              ios="ios-remove"
              md="md-remove"
            ></ion-icon>
            Ver
          </h6>
        </div>
      </div>
      <!-- END HEADER -->

      <!-- BODY -->
      <div *ngIf="pr.Showed">
        <div
          *ngFor="let res of pr.ItemByResult"
          style="
            display: block;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dashed black;
            padding-top: 5px;
          "
        >
          <!-- Top Section -->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 0.5rem;
              font-size: 1rem;
            "
          >
            {{ res.ItemName }}
          </div>

          <!-- Bottom Section -->
          <div
            style="display: flex; align-items: center; justify-content: center"
            [ngClass]="{ itemDisabled: res.Amount <= 0 }"
          >
            <div (click)="displayItemDetails(res.ItemId)">
              <ion-icon
                color="primary"
                name="eye"
                style="font-size: 20px"
              ></ion-icon>
            </div>
            <div
              style="text-align: center; width: 25%"
              (click)="displayItemDetails(res.ItemId)"
            >
              <img
                class="image-container"
                alt="Imagen No Disponible"
                src="https://serviceplan-api.mercasacr.com/images/Products/{{
                  res.ItemId
                }}_l_.PNG"
                onerror="this.onerror=null;this.src='assets/img/error.png'"
              />
            </div>

            <div style="display: block; width: 25%">
              <h6 ion-text color="primary" style="text-align: center">
                Producto
              </h6>
              <h6 ion-text style="text-align: center">{{ res.ItemId }}</h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 0"
                style="font-size: 1rem; text-align: center"
              >
                En Condiciones
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 1"
                style="font-size: 1rem; text-align: center"
              >
                En Carrito
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 2"
                style="font-size: 1rem; text-align: center"
              >
                Agregar Nuevo
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 3"
                style="font-size: 1rem; text-align: center"
              >
                Regalía
              </h6>
            </div>

            <!-- Amount/Quantity section -->
            <div
              style="
                display: block;
                justify-content: center;
                align-items: center;
                width: 35%;
              "
            >
              <!-- UNIT Description Section and UNIT price -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 0.5rem">{{ res.UnitId }}</a
                  >₡{{ res.Amount_Taxes }}
                </h6>
                <h6 ion-text *ngIf="res.Amount <= 0">
                  <a style="margin-right: 0.5rem; color: brown"
                    >No Disponible</a
                  >
                </h6>
              </div>
              <!-- Quantity Section EDITABLE-->
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 0.8rem;
                "
                *ngIf="res.Origin == 2"
              >
                <ion-button
                  ion-button
                  icon-only
                  clear
                  style="z-index: 1"
                  style="width: 30%"
                  (click)="reduceQuantityItemResult(res, 1)"
                >
                  <i class="fas fa-minus-circle"></i>
                </ion-button>
                <div style="width: 40%; text-align: center">
                  <input
                    class="quantityClass"
                    type="number"
                    name="SaleQtyItemList"
                    id="SaleQtyItemList"
                    (keyup)="changeValueKeyUpItemResult($event, res, 1)"
                    [(ngModel)]="res.SalesQuantity"
                    min="0"
                    step="1"
                  />
                </div>
                <ion-button
                  ion-button
                  icon-only
                  clear
                  style="width: 30%"
                  (click)="increaseQuantityItemResult(res, 1)"
                >
                  <i class="fas fa-plus-circle"></i>
                </ion-button>
              </div>
              <!-- Quantity Section NO-EDITABLE-->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
                *ngIf="res.Origin != 2"
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Cantidad:</a
                  >{{ res.SalesQuantity }}
                </h6>
              </div>
              <!-- Total Amount Section -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Sub Total:</a
                  >
                  ₡{{ res.TotalSale }}
                </h6>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                  padding-top: 0.5rem;
                  border-top: 1px solid;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >% Desc:</a
                  >
                  {{ res.linePercent }}%
                </h6>
              </div>
              <!-- <div style="display: flex; align-items: center; margin-bottom: 0.5rem;">
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase;">Monto Desc`:</a> ₡{{res.lineDiscount}}</h6>
              </div> -->
              <div style="display: flex; align-items: center">
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Total:</a
                  >
                  ₡{{ res.TotalWithDisc }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END BODY -->
    </div>
  </fieldset>

  <!-- Optional Exclusive Results -->
  <fieldset class="fieldsetContainer" *ngIf="validOptionalExclResult">
    <legend style="font-size: 1rem">
      <a>Opcionales Exclusivos</a>
    </legend>
    <div style="font-size: 1rem">
      <i class="fas fa-info" style="margin-right: 0.5rem; color: orange"></i
      >Estos resultados son de uso opcional, pero
      <a style="color: brown">solamente podrá seleccionar uno</a> para aplicar.
    </div>
    <div *ngFor="let pr of optionalExclResult">
      <!-- HEADER -->
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid black;
          padding-top: 5px;
        "
      >
        <ion-checkbox
          class="chkBox"
          [(ngModel)]="pr.Selected"
          name="lineCheck"
          (ionChange)="selectOptExclResult(pr)"
        ></ion-checkbox>
        <div style="width: 100%; margin-left: 0.2rem">
          <div style="font-size: 1rem; color: green">
            {{ pr.Desc }}
          </div>
          <div style="font-size: 1rem">
            {{ pr.SubDesc }}
          </div>
        </div>
        <div style="width: 10%" (click)="pr.Showed = !pr.Showed">
          <h6 ion-text text-wrap color="secondary">
            <ion-icon *ngIf="!pr.Showed" ios="ios-add" md="md-add"></ion-icon>
            <ion-icon
              *ngIf="pr.Showed"
              ios="ios-remove"
              md="md-remove"
            ></ion-icon>
            Ver
          </h6>
        </div>
      </div>
      <!-- HEADER END -->

      <!-- BODY -->
      <div *ngIf="pr.Showed">
        <div
          *ngFor="let res of pr.ItemByResult"
          style="
            display: block;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dashed black;
            padding-top: 5px;
          "
        >
          <!-- Top Section -->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 0.5rem;
              font-size: 1rem;
            "
          >
            {{ res.ItemName }}
          </div>

          <!-- Bottom Section -->
          <div
            style="display: flex; align-items: center; justify-content: center"
            [ngClass]="{ itemDisabled: res.Amount <= 0 }"
          >
            <div (click)="displayItemDetails(res.ItemId)">
              <ion-icon
                color="primary"
                name="eye"
                style="font-size: 20px"
              ></ion-icon>
            </div>
            <div
              style="text-align: center; width: 25%"
              (click)="displayItemDetails(res.ItemId)"
            >
              <img
                class="image-container"
                alt="Imagen No Disponible"
                src="https://serviceplan-api.mercasacr.com/images/Products/{{
                  res.ItemId
                }}_l_.PNG"
                onerror="this.onerror=null;this.src='assets/img/error.png'"
              />
            </div>

            <div style="display: block; width: 25%">
              <h6 ion-text color="primary" style="text-align: center">
                Producto
              </h6>
              <h6 ion-text style="text-align: center">{{ res.ItemId }}</h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 0"
                style="font-size: 1rem; text-align: center"
              >
                En Condiciones
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 1"
                style="font-size: 1rem; text-align: center"
              >
                En Carrito
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 2"
                style="font-size: 1rem; text-align: center"
              >
                Agregar Nuevo
              </h6>
              <h6
                ion-text
                color="danger"
                *ngIf="res.Origin == 3"
                style="font-size: 1rem; text-align: center"
              >
                Regalía
              </h6>
            </div>

            <!-- Amount/Quantity section -->
            <div
              style="
                display: block;
                justify-content: center;
                align-items: center;
                width: 35%;
              "
            >
              <!-- UNIT Description Section and UNIT price -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 0.5rem">{{ res.UnitId }}</a
                  >₡{{ res.Amount_Taxes }}
                </h6>
                <h6 ion-text *ngIf="res.Amount <= 0">
                  <a style="margin-right: 0.5rem; color: brown"
                    >No Disponible</a
                  >
                </h6>
              </div>
              <!-- Quantity Section EDITABLE-->
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 0.8rem;
                "
                *ngIf="res.Origin == 2"
              >
                <ion-button
                  ion-button
                  icon-only
                  clear
                  style="z-index: 1"
                  style="width: 30%"
                  (click)="reduceQuantityItemResult(res, 2)"
                >
                  <i class="fas fa-minus-circle"></i>
                </ion-button>
                <div style="width: 40%; text-align: center">
                  <input
                    class="quantityClass"
                    type="number"
                    name="SaleQtyItemList"
                    id="SaleQtyItemList"
                    (keyup)="changeValueKeyUpItemResult($event, res, 2)"
                    [(ngModel)]="res.SalesQuantity"
                    min="0"
                    step="1"
                  />
                </div>
                <ion-button
                  ion-button
                  icon-only
                  clear
                  style="width: 30%"
                  (click)="increaseQuantityItemResult(res, 2)"
                >
                  <i class="fas fa-plus-circle"></i>
                </ion-button>
              </div>
              <!-- Quantity Section NO-EDITABLE-->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
                *ngIf="res.Origin != 2"
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Cantidad:</a
                  >{{ res.SalesQuantity }}
                </h6>
              </div>
              <!-- Total Amount Section -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Sub Total:</a
                  >
                  ₡{{ res.TotalSale }}
                </h6>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                  padding-top: 0.5rem;
                  border-top: 1px solid;
                "
              >
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >% Desc:</a
                  >
                  {{ res.linePercent }}%
                </h6>
              </div>
              <!-- <div style="display: flex; align-items: center; margin-bottom: 0.5rem;">
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase;">Monto Desc`:</a> ₡{{res.lineDiscount}}</h6>
              </div> -->
              <div style="display: flex; align-items: center">
                <h6 ion-text *ngIf="res.Amount > 0">
                  <a style="margin-right: 1rem; text-transform: uppercase"
                    >Total:</a
                  >
                  ₡{{ res.TotalWithDisc }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END BODY -->
    </div>
  </fieldset>
</ion-content>

<ion-footer class="ion-no-border">
  <div class="flex-container">
    <ion-button
      class="borderRadius"
      style="margin-right: 3px; background-color: brown"
      (click)="undo()"
    >
      <ion-icon name="arrow-back"></ion-icon>Atrás
    </ion-button>

    <ion-button
      class="borderRadius"
      style="margin-left: 3px"
      (click)="applyPromo()"
    >
      Vista Previa
      <ion-icon name="eye"></ion-icon>
    </ion-button>
  </div>
</ion-footer>
