<app-nav-bar [title]="'Crear Devolución'" [start]="'None'">
  <ion-buttons slot="start">
    <ion-button (click)="viewCtrl.dismiss()">
      <ion-icon style="font-size: 25px" name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<!-- <ion-content #content (ionScrollStart)="logScrollStart()" (ionScrollEnd)="logScrollEnd()"> -->
<ion-content #content>
  <div style="text-align: center; margin-top: 12px" *ngIf="loadingBoughtItems">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center style="font-size: 1rem">
      Cargando productos comprados
    </h6>
  </div>

  <div
    style="text-align: center; margin-top: 12px"
    *ngIf="BoughtItems.length <= 0 && !loadingBoughtItems"
  >
    <h6 ion-text text-center style="font-size: 1rem; margin: 15px">
      No existen productos comprados en un periodo aceptable para realizar una
      devolución.
    </h6>
  </div>

  <!-- *********** FILTROS *********** -->
  <div *ngIf="BoughtItems.length > 0 && !loadingBoughtItems">
    <div>
      <ion-searchbar
        type="text"
        [(ngModel)]="filter.productName"
        placeholder="Buscar"
        (keyup)="applySearchFilter()"
      ></ion-searchbar>
      <!-- <ion-searchbar type="text" placeholder='Buscar'></ion-searchbar> -->
    </div>

    <div class="flex-container nowrap">
      <ion-button
        class="filter-btn"
        (click)="presentPopoverFilter()"
        [disabled]="loadingBoughtItems"
      >
        Filtros
        <!-- <ion-button class="filter-btn" ion-button block icon-end>Filtros -->
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        (click)="presentPopoverSort()"
        [disabled]="loadingBoughtItems"
      >
        Ordenar
        <!-- <ion-button class="sort-btn" ion-button block icon-end>Ordenar -->
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>
  </div>

  <ion-list
    #virtualScroll
    *ngIf="BoughtItems.length > 0 && !loadingBoughtItems"
  >
    <!-- // INVENTBATCHID: string = '';
      // EXPDATE: string = '';
      // INVENTDIMID: string = '';
      // INVOICEID: string = '';
      // INVOICEDATE: string = '';
      // SALESID: string = '';
      // ITEMID: string = '';
      // NAME: string = '';
      // TAXGROUP: string = '';
      // SALESUNIT: string = '';
      // INVOICEACCOUNT: string = '';
      // SALESPRICE: number = 0;
      // QTY: number = 0; -->
    <ion-item *ngFor="let item of BoughtItemsByGroup">
      <ion-grid>
        <ion-row>
          <ion-col
            col-4
            style="align-items: center; justify-content: center; display: flex"
            (click)="addToCart(item)"
          >
            <img
              class="image-container"
              alt="Imagen No Disponible"
              src="https://serviceplan-api.mercasacr.com/images/Products/{{
                item.ITEMID
              }}_l_.PNG"
              onerror="this.onerror=null;this.src='assets/img/error.png'"
            />
          </ion-col>
          <ion-col col-8>
            <h4>{{ item.ITEMID }}</h4>
            <ion-item text-wrap lines="none" style="padding-right: 10px">
              <span class="h7">{{ item.NAME }}</span>
            </ion-item>
            <div style="display: flex">
              <!-- <div style="display: flex; align-items: baseline;">
                <h5 style="font-weight: bold; margin-right: 2px;">{{item.FinalPrice}}</h5>
              </div> -->
              <!-- <ion-button ion-button clear large (click)="openPromos(item)" style="margin-left: auto;" *ngIf="item.InPromo > 0">
                <i class="fas fa-cart-plus"></i>
              </ion-button> -->
              <ion-button (click)="addToCart(item)" style="margin-left: auto">
                <i class="fas fa-cart-plus"></i>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
</ion-content>

<!-- Footer without a border -->
<ion-footer class="ion-no-border" style="background-color: white">
  <div class="flex-container">
    <ion-button
      slot="end"
      class="borderRadius"
      style="margin-left: 3px"
      (click)="applyRtn()"
    >
      Ver Devolución
      <ion-icon name="eye"></ion-icon>
    </ion-button>
    <ion-button
      slot="start"
      class="borderRadius"
      style="margin-right: 3px; background-color: brown"
      color="brown"
      (click)="cancelRtn()"
    >
      <ion-icon name="close-circle"></ion-icon>Cancelar
    </ion-button>
    <ion-badge class="badge2" *ngIf="rtnLines.length > 0">{{
      rtnLines.length
    }}</ion-badge>
  </div>
</ion-footer>
