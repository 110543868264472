// Components

// BusinessCore
import { OrderLine } from '../../businessCore/OrderLine';
import { productList } from './../../businessCore/productList';

// Native - Plugin
import { Component } from '@angular/core';

import {
  AlertController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { MyCustomListProductsComponent } from '../my-custom-list-products/my-custom-list-products.component';
import { NewListComponent } from '../new-list/new-list.component';

@Component({
  selector: 'app-my-custom-list',
  templateUrl: './my-custom-list.component.html',
  styleUrls: ['./my-custom-list.component.scss'],
})
export class MyCustomListComponent {
  // Arreglo con el conjunto de listas que el usuario tiene creadas
  listas: productList[] = [];

  // Arreglo con el conjunto de listas de manera filtrada
  // Es el arreglo de listas que se despliega en la ventana
  listasFilter: productList[] = [];

  // Mostrar la animacion del sliding en cada uno de los componentes
  // que contienen el nombre de las listas
  shouldAnimate: boolean = true;

  shoppingCar: OrderLine[] = [];

  searchInput: string;

  /**
   * Class constructor with these injections:
   * @param storage store key/value pairs and JSON objects.
   * @param popoverCtrl dialog that appears on top of the current page.
   * @param alert dialog that presents users with information or collects
   * information from the user using inputs.
   * @param toastCtrl a subtle notification commonly used in modern applications.
   */
  constructor(
    private storage: StorageService,
    public popoverCtrl: PopoverController,
    private nav: NavController,
    public alert: AlertController,
    public toastCtrl: ToastController,
    private ShoopingCartUtils: ShoopingCartUtils
  ) {
    this.getProductList();
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  displayShoppingCar() {
    this.ShoopingCartUtils.displayShoppingCart();
  }

  async presentAlert(title: string, msg: string) {
    let alert = await this.alert.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  /**
   * Metodo utilizado para consultar la lista de productos que tiene un cliente
   * tomando la informacion por medio del storage en la variable 'listas_productos'
   */
  getProductList() {
    this.storage.get('listas_productos').then((value) => {
      if (value != null) {
        this.listas = JSON.parse(value) as productList[];
        this.listas.forEach((value, index) => {
          value.id = index;
        });
        this.listasFilter = this.listas;
      }
    });
  }

  /**
   * Metodo utilizado para filtrar las listas segun el nombre que tienen asocidado
   * @param event parametro utilizado para tomar el valor del target que quiere filtrar el usuario
   */
  getItems(event) {
    if (this.shouldAnimate) {
      this.shouldAnimate = false;
    }

    let val = event.target.value;
    if (val && val.trim() != '') {
      this.listasFilter = this.listas;
      this.listasFilter = this.listasFilter.filter((value) =>
        value.name.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      this.listasFilter = this.listas;
    }
  }

  /**
   * Metodo utilizado para eliminar una lista de productos desde el storage 'listas_productos'
   * mostrando un mensaje de alerta para confirmar la accion
   * @param lista objeto tipo productList que se eliminara del arreglo de listas
   */
  async deleteList(lista: productList) {
    let alert = await this.alert.create({
      header: 'Atención',
      subHeader: '¿Desea eliminar esta lista? ',
      message: lista.name,
      buttons: [
        {
          text: 'Eliminar',
          handler: () => {
            this.listas = this.listas.filter((value) => value.id != lista.id);
            this.listasFilter = this.listas;
            this.storage.set('listas_productos', JSON.stringify(this.listas));
          },
        },
        {
          text: 'Cancelar',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  /**
   * Metodo utilizado para mostrar los productos que tienen asociados una lista de productos
   * @param productos arreglo con los productos e informacion de los mismos para mostrar en el popup
   */
  async showProducts(productos: OrderLine[]) {
    if (productos.length > 0) {
      const popover = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: MyCustomListProductsComponent,
        componentProps: {
          products: productos,
        },
      });
      popover.present();
    }
  }

  /**
   * Metodo utilizado para realizar el sliding hacia la derecha e izquierda
   * ejecutando la eliminacion de la lista o el despliegue de productos segun la
   * direccion
   * @param event evento que contiene la direccion del sliding que se ejecutó
   * @param lista objeto tipo productList al que se le está ejecutando la acción
   */
  swipeEvent(event, lista: productList) {
    if (event.direction === 2) {
      this.deleteList(lista);
    } else if (event.direction === 4) {
      this.showProducts(lista.items);
    }
  }

  /**
   * Metodo utilizado para validar el estado del arreglo de listas
   * que se va desplegar para que el mismo no este vacio
   */
  validListState() {
    if (this.listasFilter.length < 1) {
      return false;
    }
    return true;
  }

  /**
   * Metodo utilizado para crear una nueva lista desplegando un popup
   * con el componente NewListComponent, que porporciona un input para el nombre y
   * los botones de cancelacion y confirmacion para la creacion
   */
  async createList() {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: NewListComponent,
      componentProps: this.listas,
    });
    popover.present();

    // Evento que se ejecuta una vez que el popup culmina su ejecucion
    popover.onDidDismiss().then(async (res) => {
      let toast: HTMLIonToastElement;
      let data = res.data;
      if (data) {
        toast = await this.toastCtrl.create({
          message: 'Lista creada con éxito',
          duration: 2000,
        });
        this.getProductList();
      } else {
        toast = await this.toastCtrl.create({
          message: 'No se pudo crear la lista',
          duration: 2000,
        });
      }

      toast.present();
    });
  }
}
