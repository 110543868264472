import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Einvoice } from '../../OOP/einvoices.interface';

@Component({
  selector: 'app-einvoice-filter',
  templateUrl: './einvoice-filter.component.html',
  styleUrls: ['./einvoice-filter.component.scss'],
})
export class EinvoiceFilterComponent {
  EInvoices: Einvoice[] = [];

  wrongDateFlag: boolean = false;
  startDate: Date;
  finalDate: Date;
  preLoadDates: boolean = false;

  MinMax = { lower: 0, upper: 0 };
  Min = 0;
  Max = 0;

  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.EInvoices = this.params.get('data');
    console.log(this.params.get('MinMax'));
    this.MinMax = this.params.get('MinMax')
      ? this.params.get('MinMax')
      : this.MinMax;
    let rangeDates = this.params.get('rangeDates');

    if (rangeDates) {
      this.preLoadDates = !this.preLoadDates;
      this.startDate = rangeDates[0];
      this.finalDate = rangeDates[1];
    }

    this.extractData();
  }

  extractData() {
    this.EInvoices.forEach((x) => {
      let total = Number(x.Total);
      let emittedDate = x.EmittedDate;
      if (this.Min === 0 || Number(this.Min) > total) {
        this.Min = total;
      }

      if (this.Max === 0 || Number(this.Max) < total) {
        this.Max = total;
      }

      if (
        !this.preLoadDates &&
        (!this.startDate ||
          new Date(this.startDate).getTime() > new Date(emittedDate).getTime())
      ) {
        this.startDate = emittedDate;
      }

      if (
        !this.preLoadDates &&
        (!this.finalDate ||
          new Date(this.finalDate).getTime() < new Date(emittedDate).getTime())
      ) {
        this.finalDate = emittedDate;
      }
    });

    if (this.MinMax.lower === 0 && this.MinMax.upper === 0) {
      this.MinMax.lower = this.Min;
      this.MinMax.upper = this.Max;
    }
  }

  dismiss() {
    this.viewCtrl.dismiss({
      rangeDates: this.isValidFormat()
        ? this.startDate && this.finalDate
          ? [this.startDate, this.finalDate]
          : null
        : null,
      MinMax: this.MinMax,
    });
  }

  dismissNull() {
    this.viewCtrl.dismiss();
  }

  isValidFormat(): boolean {
    if (
      (this.startDate && this.finalDate && this.startDate <= this.finalDate) ||
      (!this.startDate && !this.finalDate)
    ) {
      return true;
    }
    return false;
  }
}
