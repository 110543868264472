<app-nav-bar
  [title]="'Iniciar sesión'"
  *ngIf="!validationInProgress"
></app-nav-bar>

<ion-content class="ion-padding login-content">
  <ion-grid>
    <div class="img-container">
      <img
        src="/assets/img/logo_homex.png"
        style="height: 50%; width: 25%"
        alt=""
        onerror=""
      />
    </div>
    <ion-card class="login-card" *ngIf="!login_status">
      <ion-card-header text-center>
        <div class="login-title">Login</div>
      </ion-card-header>
      <ion-card-content>
        <ion-item>
          <ion-select
            label="Tipo de cédula"
            label-placement="floating"
            class="floating"
            [(ngModel)]="c_type"
            [ngModelOptions]="{ standalone: true }"
            interface="popover"
          >
            <ion-select-option
              *ngFor="let ct of card_types"
              (ionSelect)="updateCardType(ct.value)"
              value="{{ ct.value }}"
            >
              {{ ct.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-label>
            <a style="color: brown">*</a>
          </ion-label>
          <ion-input
            type="text"
            placeholder="Seleccione Tipo de cédula"
            #username
            mask=""
            *ngIf="c_type == -1"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>
          <ion-input
            type="text"
            placeholder="{{ card_types[c_type].format }}"
            #username
            mask="*-****-****"
            minlength="11"
            maxlength="11"
            *ngIf="c_type == 0"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>
          <ion-input
            type="text"
            placeholder="{{ card_types[c_type].format }}"
            #username
            mask="********************"
            minlength="1"
            maxlength="20"
            *ngIf="c_type == 1"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>

          <ion-input
            type="text"
            placeholder="{{ card_types[c_type].format }}"
            #username
            mask="*-***-******"
            minlength="12"
            maxlength="12"
            *ngIf="c_type == 2"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>
          <ion-button
            type="button"
            (click)="deleteIdCard()"
            style="height: 40px; text-align: center; margin-right: 0px"
            class="login-button"
          >
            <ion-icon name="close" style="font-size: 20px"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item>
          <ion-input
            [type]="isActive ? 'text' : 'password'"
            [(ngModel)]="pass"
            #password
            placeholder="Contraseña"
          ></ion-input>

          <ion-button
            type="button"
            (click)="isActive = !isActive"
            style="height: 40px; text-align: center; margin-right: 0px"
            class="login-button"
          >
            <ion-icon
              [name]="isActive ? 'eye' : 'eye-off'"
              style="font-size: 20px"
            ></ion-icon>
          </ion-button>
        </ion-item>

        <ion-grid style="padding: 5px 0px !important; display: flex">
          <ion-button
            style="margin: 0px !important; width: 100%"
            (click)="login(id_card, pass)"
            [disabled]="validationInProgress"
          >
            Iniciar Sesión
          </ion-button>
        </ion-grid>

        <div
          class="forgotPassword"
          (click)="resetPassword()"
          [hidden]="validationInProgress"
        >
          ¿Olvidó su contraseña?
        </div>
      </ion-card-content>
    </ion-card>

    <div style="text-align: center; margin-top: 5px" *ngIf="load_info">
      <ion-spinner name="dots" duration="1000"></ion-spinner>
      <h6>
        Cargando la información del usuario, por favor espere unos segundos
      </h6>
    </div>
  </ion-grid>
</ion-content>
