import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { AssetAudit } from '../businessCore/AssetAudit';
import { AssetLocation } from '../businessCore/AssetLocation';
import { AssetRequest } from '../businessCore/AssetRequest';
import { Certification } from '../businessCore/Certification';
import { FixedAsset } from '../businessCore/FixedAsset';
import { FixedAssetTransfer } from '../businessCore/FixedAssetTransfer';
import { Worker } from '../businessCore/Worker';
import { Vendor } from '../businessCore/vendor';
import { Interpreter } from './interpreter';
import { Request } from './request';

@Injectable()
export class webServiceProvider {
  request: Request = new Request();
  interpreter: Interpreter = new Interpreter();

  // APP-ID
  AppId: number = 1; // Mercasa VIP
  // AppId: number = 2; // FMCM (Homex Express)

  /**
   * Constructor que recibe un injection de http para poder ejecutar los get y post
   * que comunican la aplicación con el web service
   * http is available as an injectable class, with methods to perform http requests
   * @param _http injection de la clase http
   */
  constructor(public _http: HttpClient) {}

  /*
  ===================================================GET=======================================================
  */

  /**
   * Web service tipo get, encargado de consultar todos los productos de la
   * lista de precios asociada al cliente
   * @returns List<HE_inventItem>, contiene toda la info asociada a un producto
   */
  getProducts(priceList: string, addressId: number = -1) {
    if (priceList == 'FU') {
      priceList = 'HX-VIP';
    }

    return this._http.get(
      environment.SP_API +
        '/business/HE_GetInventoryItemByAddress?PriceList=' +
        priceList +
        '&AddressId=' +
        addressId
    );
  }

  /**
   * Web service tipo get, encargado de consultar todos los productos de la
   * lista de precios asociada al cliente
   * @returns List<HE_inventItem>, contiene toda la info asociada a un producto
   */
  getProductsTop(priceList: string) {
    if (priceList == 'FU') {
      priceList = 'HX-VIP';
    }

    return this._http.get(
      environment.SP_API +
        '/business/HE_GetInventoryItemTop10?PriceList=' +
        priceList
    );
  }

  getProductsFMCM(
    priceList: string,
    accountNum: string,
    addressId: number = -1
  ) {
    if (priceList == 'FU') {
      priceList = 'VIP HOMEX';
    }

    if (priceList == 'HX-VIP') {
      priceList = 'VIP HOMEX';
    }

    return this._http.get(
      environment.SP_API +
        '/business/HE_GetInventoryItemsFMCM?PriceGroup=' +
        priceList +
        '&AccountNum=' +
        accountNum +
        '&AddressId=' +
        addressId
    );
  }

  checkIfCustomerExistsInComp(id: string, company: string) {
    return this._http.get(
      environment.SP_API +
        '/business/HE_CheckClientExistsInComp?VATNUM=' +
        id +
        '&DATAAREAID=' +
        company
    );
  }

  /**
   * Web Service tipo get, encargado de consultar todas las categorías que se encuentran asociados a un item
   * @param ItemId identificador del item al que se le consultaran todos los productos que tiene asociados
   * @returns List<HE_inventItem>
   */
  getProductsByItem(ItemId: String, priceList: string) {
    if (priceList == 'FU') {
      priceList = 'HX-VIP';
    }
    return this._http.get(
      environment.SP_API +
        '/business/HE_GetInventoryItems?PriceList=' +
        priceList +
        '&ItemId=' +
        ItemId
    );
  }

  /**
   * Web service tipo get, encargado de consultar el estado de una cuenta de usuario para validarlo en la ventana
   * validate-client.ts
   * @param userId identificador del usuario
   * @param type es el tipo de usuario, ya sea Persona física, jurídica o extranjera.
   * @returns IdState, contiene un estado y el nombre del usuario
   */
  getCustomerName(userId: string, type: number) {
    return this._http.get(
      environment.SP_API +
        '/business/GetProspectNameAndState?Id=' +
        userId +
        '&type=' +
        type
    );
  }

  /**
   * Web service tipo get, encargado de consultar todos los países que
   * se encuentran asociados a PSLogisticAddresses
   * @returns List<Country>
   */
  getCountry() {
    return this._http.get(environment.SP_API + '/business/GetCountries');
  }

  /**
   * Web service tipo get, encargado de consultar todas las provincias asociadas
   * a un determinado país
   * @param countryId codigo del país para el que se consultan las provincias
   * @returns List<Province>
   */
  getProvince(countryId: string) {
    return this._http.get(
      environment.SP_API + '/business/GetProvince?countryId=' + countryId
    );
  }

  /**
   * Web service tipo get, encargado de consultar todos los cantones asociados
   * a una determinada provincia
   * @param countryId codigo del pais al que pertenece la provincia
   * @param provinceId codigo de la provincia a la que se le consultaran los cantones
   * @returns List<Canton>
   */
  getCanton(countryId: string, provinceId: number) {
    return this._http.get(
      environment.SP_API +
        '/business/GetCanton?countryId=' +
        countryId +
        '&provinceId=' +
        provinceId
    );
  }

  /**
   * Web service tipo get, encargado de consultar todos los distritos asociados
   * a una determinado canton
   * @param countryId codigo del pais al que pertenece la provincia
   * @param provinceId codigo de la provincia a la que pertenece el canton
   * @param cantonId  codigo del canton al que se le consultaran los distritos
   * @returns List<District>
   */
  getDistrict(countryId: string, provinceId: number, cantonId: number) {
    return this._http.get(
      environment.SP_API +
        '/business/GetDistrict?countryId=' +
        countryId +
        '&provinceId=' +
        provinceId +
        '&cantonId=' +
        cantonId
    );
  }

  /**
   * Web service tipo get, encargado de consultar todas las ciudades asociadas
   * a un determinado distrito
   * @param countryId codigo del pais al que pertenece la provincia
   * @param provinceId codigo de la provincia a la que pertenece el canton
   * @param cantonId  codigo del canton al que pertenece el distrito
   * @param districtId codigo del distrito al que se le consultaran las ciudades
   * @returns List<City>
   */
  getCities(
    countryId: string,
    provinceId: number,
    cantonId: number,
    districtId: number
  ) {
    return this._http.get(
      environment.SP_API +
        '/business/GetCities?countryId=' +
        countryId +
        '&provinceId=' +
        provinceId +
        '&cantonId=' +
        cantonId +
        '&districtId=' +
        districtId
    );
  }

  /**
   * Web service tipo get, encargado de consultar la información principal de las ordenes asociadas
   * a un cliente y las categoriza segun localizaciones
   * @param reportId identificador del tipo de reporte que se desea consultar, por defecto es 0
   * y se utiliza para consultar el reporte general.
   * @param accountNum id del cliente
   * @param state Es el tipo de filtrado que desea que tenga las ordenes
   * Ejemplo: 1 filtra los que estén en estado 1, 0 en estado 0, y -1 para que muestre todos.
   * @param salesId id del vendedor
   * @param positionIni posicion para calcular el inicio del reporte
   * @param positionEnd posicion para calcular el fin del reporte
   * @param dateIni Date with format YYYYMMDDHHmm
   * @param dateEnd Date with format YYYYMMDDHHmm
   * @returns List<TOrderHeader>
   */
  getOrderTraceList(
    reportId: number,
    accountNum: string,
    state: number,
    salesId: string,
    positionIni: number,
    positionEnd: number,
    dateIni: string,
    dateEnd: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/business/HE_GetOTOrderHeader?reportId=' +
        reportId +
        '&accountNum=' +
        accountNum +
        '&dateIni=' +
        dateIni +
        '&dateEnd=' +
        dateEnd +
        '&state=' +
        state +
        '&salesId=' +
        salesId +
        '&positionIni=' +
        positionIni +
        '&positionEnd=' +
        positionEnd
    );
  }

  /**
   * Web service tipo get, encargado de consultar información detallada de una orden de venta,
   * mostrando la trazabilidad de esa orden
   * @param executor identificador del ejecutor que se asocia a una determinada compañía
   * @param salesId identificador de la orden a la cual se le están consultando los detalles
   * @returns List<TOrderDetail>
   */
  getOrderTraceInfo(executor: string, salesId: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetOTOrderDetail?executor=' +
        executor +
        '&salesId=' +
        salesId
    ); //
  }

  /**
   * Web service tipo get, encargado de consultar todas las lineas que están asociadas a una orden,
   * además de sus respectivos detalles.
   * @param orderAXId identificador en AX de la orden que se está consultando
   * @returns List<OrderLine>
   */
  getOrderTraceLines(orderAXId: string) {
    return this._http.get(
      environment.SP_API + '/business/GetOrderDetails?orderId=' + orderAXId
    ); //
  }

  /**
   * Web service tipo get, encargado de verificar el inicio de sesión dentro de la aplicación
   * El usuario deberá estar registrado en AX como cliente, de lo contrario no bastaría con
   * el registro que se hace desde la opción Registrarse, pues el xml que se envía debe procesarse
   * y crearle un perfil al usuario.
   * @param Id identificador o cédula de la persona
   * @param password contrasenha que tiene asociada la cuenta del cliente
   * @returns string con el estado success o error
   */
  login(Id: string, password: string) {
    return this._http.get(
      environment.SP_API +
        '/business/login_HE?Id=' +
        Id +
        '&password=' +
        password
    ); //
  }

  /**
   * Web service tipo get, encargado de registrar una contraseña para un determinado usuario
   * que ya se encuentra registrado en el sistema de la empresa, pero no en la aplicacion
   * @param Id identificador del usuario
   * @param password contrasenha nueva que registrará el usuario para su cuenta
   * @returns string con el estado success o error
   */
  registerPassword(Id: string, password: string) {
    return this._http.get(
      environment.SP_API +
        '/business/userRegisterHE?Id=' +
        Id +
        '&password=' +
        password
    ); //
  }

  /**
   * Web service tipo get, encargado de consultar la información de un cliente por medio de
   * su idenficador
   * @param Id string con el identificador del cliente, separado por @
   * @returns List<Customer>
   */
  getCustomer(Id: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetCustomersHE?customers=' +
        Id +
        '&addresses=&executor=HOEX00&version=2'
    );
  }

  /**
   * Web service tipo get, encargado de
   * @param user
   * @returns
   */
  getMobileSequence(user: string) {
    return this._http.get(
      environment.SP_API + '/GetMobileSequences?user=' + user
    );
  }

  /**
   * Web service tipo get, encargado de
   * @param id
   * @returns
   */
  getMinimumAmount(id: string) {
    return this._http.get(
      environment.SP_API + '/business/getMinimumAmount2?Id=' + id
    );
  }

  /**
   * Web service tipo get, encargado de
   * @returns
   */
  getRestrictedDate() {
    return this._http.get(environment.SP_API + '/business/getRestrictedDate');
  }

  /**
   * Web service tipo get, encargado de
   * @param user
   * @param sequenceId
   * @param value
   * @returns
   */
  setMobileSequence(user: string, sequenceId: number, value: number) {
    return this._http.get(
      environment.SP_API +
        '/UpdateSequenceValue?user=' +
        user +
        '&sequenceId=' +
        sequenceId +
        '&value=' +
        value
    );
  }

  /**
   * Web service tipo get, encargado de consultar y verificar la existencia de productos para una orden
   * que esté generando un cliente
   * @param priceList identificador de la lista de precios del cliente
   * @param itemIds conjunto de identificadores para cada item seteados en un string y separados por @
   * @param unitId conjunto de identificadores de unidades seteados en un string y separados por @
   * @param Availphysical cantidad de producto solicitado para cada item seteado en un string y separados por @
   * @returns List<HE_itemStatus>
   */
  getRealProductsAvailable(
    priceList: string,
    itemIds: string,
    unitId: string,
    Availphysical: string
  ) {
    if (priceList == 'FU') {
      priceList = 'HX-VIP';
    }

    return this._http.get(
      environment.SP_API +
        '/business/HE_GetItemStatus?PriceList=' +
        priceList +
        '&ItemIds=' +
        encodeURI(itemIds) +
        '&UnitIds=' +
        encodeURI(unitId) +
        '&Availphysical=' +
        encodeURI(Availphysical)
    );
  }

  /**
   * Web service externo, encargado de validar si un número telefónico cuenta
   * con el formato esperado o no, este web service es invocado desde la ventana
   * de registro, donde el usuario agrega contactos.
   * @param number Es el número telefónico que se debe validar
   * @returns arreglo con los datos de validacion del numero telefonico
   */
  validatePhoneNumber(number: string) {
    return this._http.get(
      'http://apilayer.net/api/validate?access_key=3a5865e5e3266f680f005e3c6e4b84d5&number=' +
        number +
        '&country_code=& format=1'
    );
  }

  /**
   * Web service tipo get, encargado de llevar a cabo el cambio de contraseña de un usuario
   * donde el mismo debe indicar la contraseña actual y la nueva credencial
   * @param user_id identificador del usuario
   * @param newPassword nueva contraseña que se estaría seteando
   * @param actualPassword contraseña actual del usuario (La que está seteando el usuario)
   * @returns string con el estado de finalizacion success o error
   */
  changePassword(user_id: string, newPassword: string, actualPassword: string) {
    return this._http.get(
      environment.SP_API +
        '/HE_ChangePassword?UserId=' +
        user_id +
        '&NewPassword=' +
        newPassword +
        '&ActualPassword=' +
        actualPassword
    );
  }

  /*
  ================================= Control de versionamiento en la aplicación ===============================
  */

  /**
   * Metodo utilizado para consultar la version de la aplicacion, y retornar una respuesta de si la version consultada
   * se encuentra actualizada o no
   * @param versionCode string con el numero de version a consultar
   */
  getVersionControl(versionCode: string) {
    return this._http.get(
      environment.SP_API +
        '/GetVersionControl2?versionCode=' +
        versionCode +
        '&App=' +
        this.AppId
    );
  }

  /*
  ================================= Confirmación de órdenes por medio de otras vías ===============================
  */

  /**
   * Web service tipo get, encargado de consultar todas las ordenes que se encuentran
   * pendientes de confirmar, es decir, aquellas que son generadas a nombre de un usuario del app
   * fuera de la aplicación por parte un tercero (vendedor...)
   * @param user_ID identificador del usuario sobre el que se consultarán todas las ordenes pendientes de confirmar
   * @returns List<confirm_orderList>
   */
  getOrdersToConfirm(user_ID: string) {
    // let PORT_NUMBER = "8094"
    // let IP_ADDRESS = "172.30.1.83"
    // Lista de pedidos pendientes de confirmar
    return this._http.get(
      environment.SP_API +
        '/business/Confirm_GetOrderList?clientCode=' +
        user_ID
    );
  }

  /**
   * Web service tipo get, encargado de consultar la información de una orden que se encuentra
   * pendiente de confirmar
   * @param Order_ID identificador de la orden sobre la que se hará la consulta de información
   * @returns List<confirm_order_header>
   */
  getOrderHeader(Order_ID: string, AccountNum: string) {
    // let PORT_NUMBER = "8094"
    // let IP_ADDRESS = "172.30.1.83"
    // Encabezado de la orden
    return this._http.get(
      environment.SP_API +
        '/business/Confirm_GetOrderHeader?OV_CODE=' +
        Order_ID +
        '&ACCOUNTNUM=' +
        AccountNum
    );
  }

  /**
   * Web service tipo get, encargado de confirmar o rechazar una determinada orden que estaba
   * pendiente de confirmar
   * @param Order_ID identificador de la orden que se aceptará o rechazará
   * @param user_ID identificador del cliente o usuario de la aplicación
   * @param mensaje string utilizado para justificar el rechazo de una orden
   * @param RoleSpecificID identificador del ejecutor, es decir, el que ejecutó la orden
   * @param ECOCONFIRMED valor para indicar si la orden será confirmada o no
   * @param ECOREJECTED valor para indicar si la orden será rechazada o no
   * @returns string con el estado de la confirmación o rechazo de la orden
   */
  confirmOrder(
    Order_ID: string,
    user_ID: string,
    mensaje: string,
    RoleSpecificID: string,
    ECOCONFIRMED: number,
    ECOREJECTED: number
  ) {
    // let PORT_NUMBER = "8094"
    // let IP_ADDRESS = "172.30.1.83"
    // Confirmar la orden
    return this._http.get(
      environment.SP_API +
        '/business/Confirm_Reject_Order?EVENTID=' +
        Order_ID +
        '&CUSTOMERID=' +
        user_ID +
        '&NOTES=' +
        mensaje +
        '&ROLESPECIFICID=' +
        RoleSpecificID +
        '&DOCUMENT=' +
        Order_ID +
        '&ECOCONFIRMED=' +
        ECOCONFIRMED +
        '&ECOREJECTED=' +
        ECOREJECTED
    );
  }

  /*
  ================================= Manejo de comentarios y reacciones sobre los productos ===============================
  */

  /**
   * Web service tipo get, encargado de consultar todos los comentarios que tiene asociado
   * un determinado item y retorna todos los datos que son relevantes para cada comentario
   * @param itemId identificador del producto al que se le consultarán los comentarios asociados
   * @param accountNum identificador del usuario que está consultando los comentarios
   * @returns List<HE_ProductComment>
   */
  getCommentsInfo(itemId: string, accountNum: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/HE_GetProductComments?itemId=' +
        itemId +
        '&accountNum=' +
        accountNum
    );
  }

  /**
   * Web service tipo get, encargado de setear reacciones (likes & dislikes) sobre  los comentarios
   * de un determinado producto, esto por parte de un cliente que lleva a cabo la acción
   * @param type identifica el tipo de reacción que está seteando el usuario,
   * Tipos de reacciones:
   * 0 -> Dislike
   * 1 -> Neutro, es cuando un usuario quiere deshacer la reacción
   * 2 -> Like
   * @param accountNum identificador del usuario que está seteando la reacción
   * @param fkComment identificador del comentario al que se le está seteando la reacción
   * @returns string con el estado de finalización success o error
   */
  setCommentReaction(type: number, accountNum: string, fkComment: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/HE_InsertCommentReaction?type=' +
        type +
        '&accountNum=' +
        accountNum +
        '&fkComment=' +
        fkComment
    );
  }

  /**
   * Web service tipo get, encargado de setear un comentario sobre un determinado producto
   * @param content string con el contenido del comentario que se está añadiendo
   * @param accountNum identificador del usuario que está registrando el comentario
   * @param itemId identificador del item al que se le asociará el comentario
   * @returns string con el estado de finalización success o error
   */
  setProductComment(content: string, accountNum: string, itemId: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/HE_InsertComment?content=' +
        content +
        '&accountNum=' +
        accountNum +
        '&itemId=' +
        itemId
    );
  }

  /**
   * Web service tipo get encargado de registrar una valoración por parte de un usuario, para un
   * determinado producto
   * @param rate valoración en estrellas (1-5) que el usuario desea registrar para el producto
   * @param accountNum identificador del usuario que está seteando la reacción
   * @param itemId identificador del item al que se le está añadiendo la calificación
   * @returns string con el estado de finalización success o error
   */
  setProductEvaluation(rate: number, accountNum: string, itemId: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/HE_InsertProductEvaluation?rate=' +
        rate +
        '&accountNum=' +
        accountNum +
        '&itemId=' +
        itemId
    );
  }

  /**
   * Web service tipo get, encargado de obtener la valoración general que tiene un producto
   * @param itemId identificador del producto al que se le consultará la valoración
   * @returns List<HE_ProductEvaluation>
   */
  getProductRate(itemId: string) {
    return this._http.get(
      environment.SP_API + '/BUSINESS/HE_GetProductRate?itemId=' + itemId
    );
  }

  /**
   * Web service tipo get, encargado de obtener la valoración que un determinado usuario
   * a registrado sobre un producto
   * @param accountNum identificador del usuario que está consultando la valoración
   * @param itemId identificador del item que está siendo consultado
   * @returns List<HE_PersonalProductRate>
   */
  getPersonalProductRate(accountNum: string, itemId: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/HE_GetPersonalProductRate?itemId=' +
        itemId +
        '&accountNum=' +
        accountNum
    );
  }

  /*
  ============================================= CaseManagement =================================================
  */

  public HE_GetCaseDetail(AccountNum: string) {
    return this._http.get(
      environment.SP_API + '/BUSINESS/HE_GetCaseDetail?AccountNum=' + AccountNum
    );
  }

  /**
   * Web service utilizado para insertar un nuevo caso para la edicion de datos.
   * @param caseId numero con el identificador del caso, se obtiene a partir de la conversion de la fecha.
   * @param description string con el texto que se asocia al caso.
   * @param categoryId identificador (RecId) de la catagoria que se asocia a un propietario.
   * @param customer identificador del cliente sobre el que se ejecutara el caso.
   * @param ownerWorker identificador del propietario que se encargara del caso.
   * @param createdBy HOEX00, identificador del creador del caso.
   * @param executor HOEX00.
   * @returns RecId y el numero de caso en AX
   */
  public InsertCaseDetail(
    caseId: string,
    description: string,
    categoryId: number,
    customer: string,
    ownerWorker: number
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/InsertCaseDetail?caseId=' +
        caseId +
        '&description=' +
        encodeURI(description) +
        '&categoryId=' +
        categoryId +
        '&customer=' +
        customer +
        '&ownerWorker=' +
        ownerWorker +
        '&createdBy=HOEX00&executor=HOEX00'
    );
  }

  /**
   * Web Service tipo get utilizado para insertar en la tabla los estados que va teniendo un determinado caso.
   * @param caseRecId identificador del caso sobre el que se registrara la descripcion del avance.
   * @param description descripcion del avance que se va a realizar sobre un determinado caso.
   * @param notes string con los apuntes del log que se va ha registrar.
   * @param createdBy identificador del creador del log para el caso, en este caso sera HOEX00.
   * @param executor identificador del ejecutor del log para el caso, en este caso sera HOEX00.
   */
  public InsertCaseLog(caseRecId: number, description: string, notes: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/InsertCaseLog?caseRecId=' +
        caseRecId +
        '&description=' +
        encodeURI(description) +
        '&notes=' +
        encodeURI(notes) +
        '&createdBy=HOEX00&executor=HOEX00'
    );
  }

  /**
   * Web service tipo get encargado de asociarle alguna informacion al caso por medio de la referencia.
   * @param caseRecId identificador del caso al que se le va asociar la referencia
   * @param entityType es una especie de categorizacion para la asociacion que se esta llevando a cabo .
   * @param reference string con la referencia que se le asociara al caso.
   */
  public InsertCaseAssociation(
    caseRecId: number,
    entityType: number,
    reference: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/InsertCaseAssociation?caseRecId=' +
        caseRecId +
        '&entityType=' +
        entityType +
        '&reference=' +
        reference
    );
  }

  /**
   * Web service para evaluar la atención/resolución que se le dio al caso.
   * @param caseId identificador del caso que se esta evaluando.
   * @param caseAxId identificador en ax del caso.
   * @param comments string con los comentarios que se van a realizar sobre un determinado caso.
   * @param evaluation string con la evaluacion que se realizara sobre el caso.
   * @param createdBy identificador del usuario que creo el caso, en este caso seria HOEX00
   */
  public InsertCaseEvaluation(
    caseId: string,
    caseAxId: string,
    comments: string,
    evaluation: string,
    createdBy: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/InsertCaseEvaluation?caseId=' +
        caseId +
        '&caseAxId=' +
        caseAxId +
        '&comments=' +
        encodeURI(comments) +
        '&evaluation=' +
        evaluation +
        '&createdBy=HOEX00'
    );
  }

  /**
   * Web service tipo get encargado de consultar el estado del caso.
   * @param caseRecId identificador (RecId) del caso que se esta consultando.
   */
  public getCaseStatus(caseRecId: number) {
    return this._http.get(
      environment.SP_API + '/BUSINESS/getCaseStatus?caseRecId=' + caseRecId
    );
  }

  /**
   * Web service tipo get encargado de actualizar el estado del caso.
   * @param caseRecId identificador (RecId) del caso que se esta consultando.
   * @param status string con el estado al que se actualizara el caso.
   */
  public UpdateCaseStatus(caseRecId: string, status: number) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/UpdateCaseStatus?caseRecId=' +
        caseRecId +
        '&status=' +
        status
    );
  }

  /**
   * Web service para obtener las actualizaciones que ha tenido el log de casos sobre un determinado id de caso.
   * @param CaseId identificador del caso que se consultara.
   */
  public GetCaseLogUpdate(CaseId: string) {
    return this._http.get(
      environment.SP_API + '/BUSINESS/GetCaseLogUpdate?CaseId=' + CaseId
    );
  }

  /**
   * Web service utilizado para consultar la información de los tipos de categoria que seran enviadas en los casos.
   * @param recId identificador del caso que se está consultando
   */
  public GetCaseCategoryType(recId: number) {
    return this._http.get(
      environment.SP_API + '/BUSINESS/GetCaseCategoryHE?recId=' + recId
    );
  }

  /**
   * Web service utilizado para consultar las actualizaciones de un caso
   * @param CaseId identificador del caso
   * @param recid identificador RecId del caso que se va a consultar
   */
  public GetCaseChange(CaseId: string, recid: number) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/GetCaseChance?CaseId=' +
        CaseId +
        '&recid=' +
        recid
    );
  }

  /**
   * Web service utilizado para realizar una actualizacion de la contraseña
   * @param email correo asociado a la cuenta del cliente
   * @param id identificacion del cliente
   */
  public ResetPassword(email: string, id: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/ResetPassword?Id=' +
        id +
        '&Email=' +
        email
    );
  }

  /**
   * Web service utilizado para realizar una actualizacion de la contraseña
   * @param newPassword nueva contraseña asociada a la cuenta del cliente
   * @param id identificacion del cliente
   * @param verificationCode codigo de verificacion obtenido en el paso anterior
   */
  public SetNewPassword(
    id: string,
    newPassword: string,
    verificationCode: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/SetNewPassword?Id=' +
        id +
        '&NewPassword=' +
        newPassword +
        '&Code=' +
        verificationCode
    );
  }

  /*
   ==========================================================================================================
   =========================================== Shopping Car =================================================
   ==========================================================================================================
 */

  /**
   * Método utilizado para mantener sincronizado el carro de compras con el sistema central de la empresa
   * Por cada nueva linea en el carro de compras, el sistema registra dicha linea en el sistema
   * @param HomexApp Enum con el tipo de aplicación desde la que se esta registrando
   * @param ItemId codigo del producto que se está registrando
   * @param Quantity cantidad del producto en cuestión
   * @param UnitId unidad del producto
   * @param VATNum cedula del cliente
   */
  createShoppingCarLine(
    HomexApp: number,
    ItemId: string,
    Quantity: number,
    UnitId: string,
    VATNum: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/createShoppingCarLine?HomexApp=' +
        HomexApp +
        '&ItemId=' +
        ItemId +
        '&Quantity=' +
        Quantity +
        '&UnitId=' +
        UnitId +
        '&VATNum=' +
        VATNum
    );
  }

  /**
   * Método utilizado para mantener sincronizado el carro de compras con el sistema central de la empresa
   * Por cada nueva linea en el carro de compras, el sistema actualiza dicha linea en el sistema en caso de ya existir
   * @param HomexApp Enum con el tipo de aplicación desde la que se esta actualizando
   * @param ItemId codigo del producto que se está actualizando
   * @param Quantity cantidad del producto en cuestión
   * @param UnitId unidad del producto
   * @param VATNum cedula del cliente
   */
  updateShoppingCarLine(
    HomexApp: number,
    ItemId: string,
    Quantity: number,
    UnitId: string,
    VATNum: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/updateShoppingCarLine?HomexApp=' +
        HomexApp +
        '&ItemId=' +
        ItemId +
        '&Quantity=' +
        Quantity +
        '&UnitId=' +
        UnitId +
        '&VATNum=' +
        VATNum
    );
  }

  /**
   * Método utilizado para mantener sincronizado el carro de compras con el sistema central de la empresa
   * Por cada linea elimnada en el carro de compras, el sistema borra dicha linea en el sistema
   * @param HomexApp Enum con el tipo de aplicación desde la que se esta eliminando
   * @param ItemId codigo del producto que se está eliminando
   * @param Quantity cantidad del producto en cuestión
   * @param UnitId unidad del producto
   * @param VATNum cedula del cliente
   */
  deleteShoppingCarLine(
    HomexApp: number,
    ItemId: string,
    Quantity: number,
    UnitId: string,
    VATNum: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/deleteShoppingCarLine?HomexApp=' +
        HomexApp +
        '&ItemId=' +
        ItemId +
        '&Quantity=' +
        Quantity +
        '&UnitId=' +
        UnitId +
        '&VATNum=' +
        VATNum
    );
  }

  /**
   * http://' + environment.IP +   '/business/SP_FCM_SetToken?TOKEN=asdf1234&CUSTACCOUNT=1-1656-0987
   */
  setFCMToken(TOKEN: string, CUSTACCOUNT: string) {
    return this._http.get(
      environment.SP_API +
        '/BUSINESS/SP_FCM_SetToken?TOKEN=' +
        TOKEN +
        '&CUSTACCOUNT=' +
        CUSTACCOUNT
    );
  }

  /**
   * Method used to get the status of the payment for a specific OrderID
   * @param orderID string with the unique ID of the order
   */
  getPaymentStatus(orderID: string) {
    return this._http.get(
      environment.SP_API + '/BUSINESS/GetPaymentStatus?ORDERID=' + orderID
    );
  }

  /**
   * Method used to set the payment receipt
   * @param AMOUNT number with the amount of the order
   * @param CUSTID string with the unique ID of customer
   * @param TRANSACTIONID string with the unique transaction ID
   */
  setPaymentReceipt(AMOUNT: number, CUSTID: string, TRANSACTIONID: string) {
    return this._http.get(
      environment.SP_API +
        '/business/SP_PaymentReceipt?AMOUNT=' +
        AMOUNT +
        '&CUSTID=' +
        CUSTID +
        '&TRANSACTIONID=' +
        TRANSACTIONID
    );
  }

  /**
   * Method used to obtain all the information of the orders paied by credit card
   * @param id_card string with the unique ID (IDCARD)
   */
  getCreditCardPayment(id_card: string) {
    return this._http.get(
      environment.SP_API + '/business/SP_GetPaymentReceipt?Custid=' + id_card
    );
  }

  /**
   * Method used to set all the information of the device
   * SP_updateCustInfo(string userid, string VersionName, string OS_type, string OS_Version, string Phone_Version)
   * @param userid
   * @param VersionName
   * @param OS_type
   * @param OS_Version
   * @param Phone_Version
   */
  updateCustAccountInfo(
    userid: string,
    VersionName: string,
    OS_type: string,
    OS_Version: string,
    Phone_Version: string
  ) {
    return this._http.get(
      environment.SP_API +
        '/business/SP_updateCustInfo?userid=' +
        userid +
        '&VersionName=' +
        VersionName +
        '&OS_type=' +
        OS_type +
        '&OS_Version=' +
        OS_Version +
        '&Phone_Version=' +
        Phone_Version
    );
  }

  // *************************************************** EDITAR CORREO / EMAIL ***************************************************

  editPhoneNumber(actualPhone: string, newPhone: string, accountNum: string) {
    return this._http.get(
      environment.SP_API +
        '/business/HE_EditPhoneNumber?ACTUALPHONE=' +
        actualPhone +
        '&ACCOUNTNUM=' +
        accountNum +
        '&NEWPHONE=' +
        newPhone
    );
  }

  editEmail(actualEmail: string, newEmail: string, accountNum: string) {
    return this._http.get(
      environment.SP_API +
        '/business/HE_EditEmail?ACTUALEMAIL=' +
        actualEmail +
        '&ACCOUNTNUM=' +
        accountNum +
        '&NEWEMAIL=' +
        newEmail
    );
  }

  // *************************************************** PROMOCIONES ***************************************************
  /**
   * Method used to get all the promotions assigned to a specific location - customer
   * @param AccountNum string with unique ID of the customer
   * @param AddressId string with unique ID of the location related with the customer
   * @param PriceList string with the PriceList of the location related with the customer
   */
  getPromosByAddress(AccountNum: string, AddressId: string, PriceList: string) {
    return this._http.get(
      environment.SP_API +
        '/business/HE_GetPromosByAddress?ADDRESSID=' +
        AddressId +
        '&ACCOUNTNUM=' +
        AccountNum +
        '&PRICELIST=' +
        PriceList
    );
  }

  getIpPonderationByAddress(AddressId: string) {
    return this._http.get(
      environment.SP_API + '/business/GETIPPONDERATION?ADDRESSID=' + AddressId
    );
  }

  // *************************************************** DEVOLUCIONES ***************************************************
  /**
   * Method used to get all the products bought 6 weeks ago
   * @param CUSTACCOUNT string with unique ID of the customer
   */
  GetPSRtnBoughtItem(CUSTACCOUNT: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetPSRtnBoughtItem?CUSTACCOUNT=' +
        CUSTACCOUNT
    );
  }

  /**
   * Method used to get all the return orders for a specific custaccount
   * @param CUSTACCOUNT string with unique ID of the customer
   */
  GetPSRtnHistory(CUSTACCOUNT: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetPSRtnHistory?CUSTACCOUNT=' +
        CUSTACCOUNT
    );
  }

  /**
   * Method used to get all availables reasons for return orders
   */
  GetPSRtnReason() {
    return this._http.get(environment.SP_API + '/business/GetPSRtnReason');
  }

  /**
   * Method used to get the itemConvertions for a specific itemid
   */
  GetItemConvertion(ITEMID: string, PRICELIST: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetItemConversion?ITEMID=' +
        ITEMID +
        '&PRICELIST=' +
        PRICELIST
    );
  }

  /**
   * Method used to get all the item info
   */
  GetPSRtnItemInfo(ITEMID: string, CUSTACCOUNT: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetPSRtnItemInfo?CUSTACCOUNT=' +
        CUSTACCOUNT +
        '&ITEMID=' +
        ITEMID
    );
  }

  /**
   * Method used to get if a request is in process for this client (CUSTACCOUNT)
   */
  GetPSRtnInProcess(CUSTACCOUNT: string) {
    return this._http.get(
      environment.SP_API +
        '/business/GetPSRtnInProcess?CUSTACCOUNT=' +
        CUSTACCOUNT
    );
  }

  registerNewCustomer() {
    return this._http.get(environment.SP_API + '/RegisterNewCustomer');
  }

  /*
  ===================================================POST=======================================================
  */

  /**
   * Web service tipo post, encargado de enviar un XML con todos lo datos del registro
   * que lleva a cabo un usuario.
   * @param stream string con todos los datos que son necesarios para el registro
   * @returns estado de culminación por parte del web service.
   */
  sendXML(stream: string) {
    let headers: HttpHeaders = this._getHeaders(); //cambiar por XmlSend2
    stream = `?xml${stream}?`;
    return this._http.post(
      environment.SP_API + '/XmlSend2',
      { businessData: stream },
      { headers }
    );
  }

  /**
   * Método utilizado para obtener los encabezados que son necesarios para el envío de información
   * desde los métodos post
   * @returns RequestOptions, con los encabezados necesarios para la implementación del post
   */
  private _getHeaders(): HttpHeaders {
    let header = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return header;
  }

  /**
   * Web service tipo get, encargado de consultar los diferentes roles de un cliente por medio de
   * su idenficador
   * @param Id string con el identificador del cliente, separado por @
   * @returns List<RoleByProcess>
   */

  getCustomerRoles(Id: string) {
    return this._http.get(
      environment.SP_API + '/business/GetRoleByProcess?id_number=' + Id
    );
  }

  /**
   * Obtiene los componentes (definidos por negocios) a los cuales
   * tiene acceso el usuario
   *
   * @param VatNum Número de cédula por el que se incia sesión.
   */
  getComponentsByUserId(VatNum: string) {
    return new Promise((resolve, reject) => {
      const xmlRequest = this.request.XMLGetComponentsByUserId(VatNum);
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', environment.GIPlus_API, true);
      xhr.setRequestHeader('Content-Type', 'text/xml');
      xhr.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetComponentsByUserId'
      );
      xhr.send(xmlRequest);
      xhr.onreadystatechange = (aEvt) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(
              this.interpreter.parseGetComponentsByUserId(xhr.responseXML)
            );
          } else if (xhr.status === 500) {
            resolve(500);
          } else if (xhr.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Obtiene acceso a los componentes (definidos por negocios) de sus instancias.
   *
   * @param VatNum Número de cédula por el que se incia sesión.
   */
  getAssociatedPersonInfo(pVatnum: string) {
    return new Promise((resolve, reject) => {
      const xmlRequest = this.request.XMLGetAssociatedPersonInfo(pVatnum);
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', environment.GIPlus_API, true);
      xhr.setRequestHeader('Content-Type', 'text/xml');
      xhr.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAssociatedPersonInfo'
      );
      xhr.send(xmlRequest);
      xhr.onreadystatechange = (aEvt) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(
              this.interpreter.parseGetAssociatedPersonInfo(xhr.responseXML)
            );
          } else if (xhr.status === 500) {
            resolve(500);
          } else if (xhr.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Metodos utilizados para componete de Solicitud de traslado de activos
   */
  // ---------------------------------------------------------------------------------------
  // WebService para Traslado de Activos
  // ---------------------------------------------------------------------------------------

  /**
   * Webservice que trae todos los activos pertenecientes basados en el barcode
   *
   * @param barcode
   */
  getAsset(pBarcode: string = '', pAssetRecId: number = 0) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetAsset(pBarcode, pAssetRecId);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAsset'
      );
      xmlRequest.send(xml);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.interpreter.parseAssetEntity(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que trae todos los activos pertenecientes a un trabajador
   *
   * @param personnelNumber
   */
  getAssetByWorker(personnelNumber: string) {
    return new Promise((resolve, reject) => {
      const xmlAbW = this.request.XMLGetAssetsByWorker(personnelNumber);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAssetsByWorker'
      );
      xmlRequest.send(xmlAbW);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.interpreter.parseAssetEntity(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que solicita la información de 1 trabajador guardado en HcmWorker si se le indica parámetros
   * o todos los trabajadores si no se le pasan parámetros.
   *
   * @param personnelNumber
   * @param vatnum
   */
  getWorker(
    personnelNumber: string = '',
    vatnum: string = '',
    recId: number = 0
  ) {
    return new Promise((resolve, reject) => {
      const toXML_GW = this.request.XMLGetWorker(
        personnelNumber,
        vatnum,
        recId
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetWorker'
      );
      xmlRequest.send(toXML_GW);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.interpreter.parseWorkerEntity(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que solicita todos los workers por departamento parámetros
   *
   * @param displayValue
   * @param dimensionName
   */
  getWorkerByFinancialDimension(displayValue: string, dimensionName: string) {
    return new Promise((resolve, reject) => {
      const toXML_GW = this.request.XMLGetWorkerByFinancialDimension(
        displayValue,
        dimensionName
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetWorkerByFinancialDimension'
      );
      xmlRequest.send(toXML_GW);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(this.interpreter.parseWorkerEntity(xmlRequest.responseXML));
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que solicita todas los aposentos de los activos fijos.
   */
  getAssetLocation(workerResponsible: number = 0, location: string = '') {
    return new Promise((resolve, reject) => {
      const xmlGAL = this.request.XMLGetAssetLocation(
        workerResponsible,
        location
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAssetLocation'
      );
      xmlRequest.send(xmlGAL);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(
              this.interpreter.parseAssetLocationEntity(xmlRequest.responseXML)
            );
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que trae todos los activos pertenecientes a un trabajador
   *
   * @param pStatus
   */
  getAssetRequest(pStatus: number, pRecId: string = '') {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetAssetRequest(pStatus, pRecId);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAssetRequest'
      );
      xmlRequest.send(xml);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(
              this.interpreter.parseAssetRequestEntity(xmlRequest.responseXML)
            );
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que trae todos los activos pertenecientes a un trabajador
   *
   * @param pStatus
   */
  getSendAssetRequest(pStatus: number, pRecId: string = '') {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetSendAssetRequest(pStatus, pRecId);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetSendAssetRequest'
      );
      xmlRequest.send(xml);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(
              this.interpreter.parseAssetRequestEntity(xmlRequest.responseXML)
            );
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice tipo post encargado de enviar un XML con todos lo datos necesarios para
   * realizar una solicitud de transferencia de activos.
   *
   * @param responsible Trabajador que realiza la solicitud.
   * @param newResponsible Trabajador que recibirá el o los activos.
   * @param newAssetLocation Nuevo aposento de los activos.
   * @param assetsList Lista de los activos a transferir.
   */
  transferAsset(
    logger: Worker,
    responsible: Worker,
    newResponsible: Worker,
    newAssetLocation: AssetLocation,
    assetsList: FixedAsset[]
  ) {
    const fixedAssetT: FixedAssetTransfer = new FixedAssetTransfer();
    const fixedAssetList = fixedAssetT.sortTransferedAsset(
      logger,
      responsible,
      newResponsible,
      newAssetLocation,
      assetsList
    );
    return new Promise((resolve, reject) => {
      const xmlTFA = this.request.XMLTransferAsset(fixedAssetList);
      //console.log(xmlTFA);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/TransferAsset'
      );
      xmlRequest.send(xmlTFA);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'TransferAssetResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice tipo post encargado de enviar un XML con todos lo datos necesarios para
   * realizar la aprobración o rechazo de solicitud de transferencia de activos.
   *
   * @param pApprover Trabajador que realiza la solicitud.
   * @param requestCheckedList Lista de las solicitudes a aceptar o rechazar.
   * @param pMotive Trabajador que recibirá el o los activos.
   * @param pStatus Nuevo aposento de los activos.
   *
   *
   * IMPORTANTE: La clase FixedAssetTransfer contiene todos los datos que contiene la tabla en Base de datos
   * AssetTransferS, es por esto que la lista envía los datos en este tipo de tabla, pero únicamente los valores
   * acordes.
   */
  alterAssetRequest(
    pApprover: Worker,
    requestCheckedList: AssetRequest[],
    pMotive: string,
    pStatus: number
  ) {
    const fixedAssetT: FixedAssetTransfer = new FixedAssetTransfer();
    const fixedAssetList = fixedAssetT.sortAssetRequest(
      pApprover,
      requestCheckedList,
      pMotive
    );
    return new Promise((resolve, reject) => {
      const apprAssetReq = this.request.XMLAlterAssetRequest(
        fixedAssetList,
        pStatus
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/AlterAssetRequest'
      );
      xmlRequest.send(apprAssetReq);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'AlterAssetRequestResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Metodos utilizados para componete de creación de orden de compra
   */
  // ---------------------------------------------------------------------------------------
  // WebService para OC
  // ---------------------------------------------------------------------------------------
  // Trae la lista de proovedor, para realizar una orden de compra
  async getVendInfo(dataArea: string) {
    let vendInfoList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getVendInfo?dataArea=' + dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        vendInfoList = data;
      })
      .catch((error: any) => {
        
        console.log('Error al tratar de traer lista de proovedores...', error);
      });
    return vendInfoList;
  }

  /**
   * Trae la informacion de los tipos de compra, dada una compannia, para realizar una OC.
   */
  async getPurchPoolInfo(dataArea: string) {
    let purchPoolInfoList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getPurchpoolInfo?dataArea=' + dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        purchPoolInfoList = data;
      })
      .catch((error: any) => {
        console.log(
          'Error al tratar de traer lista de tipos de compra...',
          error
        );
      });
    return purchPoolInfoList;
  }

  /**
   * Trae la informacion de las monedas disponibles
   */
  async getCurrencyInfo() {
    let currencyInfoList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getCurrencyInfo';
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        currencyInfoList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de currency...', error);
      });
    return currencyInfoList;
  }

  /**
   * Funcion que trae la informacion de los lenguajes disponibles
   */
  async getLenguageInfo() {
    let languageInfoList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getLenguageInfo';
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        languageInfoList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de language...', error);
      });
    return languageInfoList;
  }

  /**
   * Funcion que trae la informacion de las compannias
   */
  async getDataAreaInfo() {
    let dataAreaList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getDataAreaInfo';
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        dataAreaList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de empresas...', error);
      });
    return dataAreaList;
  }

  /**
   * Funcion que trae la informacion de los sitios, dado una compannia
   */
  async getInventSiteInfo(dataArea: string) {
    let dataInvetnSiteList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getInventSiteInfo?dataArea=' + dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        dataInvetnSiteList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de sitios...', error);
      });
    return dataInvetnSiteList;
  }

  /**
   * Funcion que trae la informacion de los almacenes, dado un sitio y compannia
   */
  async getInventLocaInfo(inventSiteId: string, dataArea: string) {
    let dataInvetnSiteList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta =
      basePath +
      'getInventLocaInfo?inventSiteId=' +
      inventSiteId +
      '&dataArea=' +
      dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        dataInvetnSiteList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de locations...', error);
      });
    return dataInvetnSiteList;
  }

  /**
   * Funcion que trae la informacion de los puertos por compannia
   */
  async getIntrastatPort(dataArea: string) {
    let puertoInfoList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta = basePath + 'getIntrastatPort?dataArea=' + dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        puertoInfoList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de puertos...', error);
      });
    return puertoInfoList;
  }

  /**
   * Funcion que trae la información de las navieras
   */
  async getIntrastatTransportPortMode(dataArea: string) {
    let navieraInfoList = null;
    let basePath = environment.SP_API + '/business/';
    const consulta =
      basePath + 'getIntrastatTransportPortMode?dataArea=' + dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        navieraInfoList = data;
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer lista de navieras...', error);
      });
    return navieraInfoList;
  }

  /**
   * Funcion que trae la informacion de los productos que un proveedor posee
   * con acuerdo comercial
   */
  async getItemsByVendor(vendAccount: string, dataArea: string) {
    let dataItemByVend = null;
    let basePath = environment.SP_API + '/business/';
    const consulta =
      basePath +
      'GetItemsByVendor?vendaccount=' +
      vendAccount +
      '&dataArea=' +
      dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        dataItemByVend = data;
      })
      .catch((error: any) => {
        console.log(
          'Error al tratar de traer lista de items by Vendor...',
          error
        );
      });
    return dataItemByVend;
  }

  /**
   * Función que trae la informacion de los productos que un proveedor puede vender
   */
  async getAllItemsByVendor(vendAccount: string, dataArea: string) {
    let dataItemByVend = null;
    let basePath = environment.SP_API + '/business/';
    const consulta =
      basePath +
      'GetAllItemsByVendor?vendaccount=' +
      vendAccount +
      '&dataArea=' +
      dataArea;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        dataItemByVend = data;
      })
      .catch((error: any) => {
        console.log(
          'Error al tratar de traer lista all items by Vendor...',
          error
        );
      });
    return dataItemByVend;
  }

  /**
   *
   * @param pBase64
   * @param pImgExtension
   * @param pAction
   * @param pType
   * @param pWorkerVatnum
   * @param pElementId
   * @param pCompany
   */
  uploadImage(
    pBase64: string,
    pImgExtension: string,
    pAction: number,
    pType: number,
    pPersonVatnum: string,
    pElementId: string,
    pCompany: string
  ) {
    return new Promise((resolve, reject) => {
      const imageXML = this.request.XMLUploadImage(
        pBase64,
        pImgExtension,
        pAction,
        pType,
        pPersonVatnum,
        pElementId,
        pCompany
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/UploadImage'
      );
      xmlRequest.send(imageXML);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName(
                'UploadImageResult'
              )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param msg_body
   * @param to
   * @param bcc
   * @param subject
   */
  public sendEmail(msg_body: string, to: string, subject: string) {
    return new Promise((resolve, reject) => {
      const xmlSE = this.request.toXMLSendEmail(msg_body, to, subject);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/SendEmail'
      );
      xmlRequest.send(xmlSE);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName('SendEmailResult')[0]
                .textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   * Webservice que trae todos los activos pertenecientes a un trabajador
   *
   * @param pStatus
   */
  getDataAreaByComponent(pComponentId: number) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetDataAreaByComponent(pComponentId);
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetDataAreaByComponent'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            // console.log(this.interpreter.parseDataArea(xmlHttpRequest.responseXML));
            resolve(this.interpreter.parseDataArea(xmlHttpRequest.responseXML));
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param pVatnum
   * @param pIdentifierType
   */
  getEntityName(pVatnum: string, pIdentifierType: number) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetEntityName(pVatnum, pIdentifierType);
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetEntityName'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseEntityName(xmlHttpRequest.responseXML)
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  getPaymentTerm() {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetPaymentTerm();
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPaymentTerm'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parsePaymentTerm(xmlHttpRequest.responseXML)
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  getBank() {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetBank();
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetBank'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(this.interpreter.parseBank(xmlHttpRequest.responseXML));
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  getCurrency() {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetCurrency();
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAllCurrencyData'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(this.interpreter.parseCurrency(xmlHttpRequest.responseXML));
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param pEmail
   * @returns
   */
  getValidationCode(pEmail: string) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetValidationCode(pEmail);
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetValidationCode'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseValidationCode(xmlHttpRequest.responseXML)
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  registerVendorAux(pVendor: Vendor) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLRegisterVendor(pVendor);
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/RegisterVendorAux'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            const result = xmlHttpRequest.responseXML.getElementsByTagName(
              'RegisterVendorAuxResult'
            )[0].textContent;
            resolve(result);
            // console.log(xmlHttpRequest.responseXML.getElementsByTagName('registerVendorAuxResult'));
            // resolve(1);
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param pVendor
   * @param pVendTableAuxRecId
   * @returns
   */
  registerVendContactAux(pVendor: Vendor, pVendTableAuxRecId: string) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLRegisterVendContactAux(
        pVendor.phoneList,
        pVendor.emailList,
        pVendor.vendorVatnum,
        pVendTableAuxRecId
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/RegVendContact'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            const result = xmlHttpRequest.responseXML.getElementsByTagName(
              'RegVendContactResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param pVendor
   * @param pVendTableAuxRecId
   * @returns
   */
  registerVendAddressAux(pVendor: Vendor, pVendTableAuxRecId: string) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLRegisterVendAddressAux(
        pVendor.AddressList,
        pVendor.vendorVatnum,
        pVendTableAuxRecId
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/RegVendAddress'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            const result = xmlHttpRequest.responseXML.getElementsByTagName(
              'RegVendAddressResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param petitioner
   * @param worker
   * @param certificationPeriod
   * @param certificationId
   * @returns
   */
  certificate(
    petitioner: Worker,
    worker: Worker,
    certificationPeriod: string,
    certificationId: string,
    strPeriodDate: string,
    assetQty: number
  ) {
    const certificateT: Certification = new Certification();
    const certificateList = certificateT.parseCertificate(
      petitioner,
      worker,
      certificationPeriod,
      certificationId,
      strPeriodDate,
      assetQty
    );
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLCertificate(
        certificateList,
        worker.getVatnum()
      );
      console.log(xml);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/Certificate'
      );
      xmlRequest.send(xml);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName(
                'CertificateResult'
              )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  getCertificationPeriodicity(pWorkerRecId: string, pCertificationId: string) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetCertificationPeriodicity(
        pWorkerRecId,
        pCertificationId
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetCertificationPeriodicity'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseCertificationPeriodicity(
                xmlHttpRequest.responseXML
              )
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  checkPeriod(
    pWorkerRecId: string,
    pCertificationId: string,
    pStrDate: string,
    pPeriodType: number
  ) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLCheckPeriod(
        pWorkerRecId,
        pCertificationId,
        pStrDate,
        pPeriodType
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/CheckPeriod'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseCheckPeriod(xmlHttpRequest.responseXML)
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  getPendingCertification(pCertificationId: string, pApproverRecId: string) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetPendingCertification(
        pCertificationId,
        pApproverRecId
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetPendingCertification'
      );
      xmlRequest.send(xml);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            resolve(
              this.interpreter.parsePendingCertification(xmlRequest.responseXML)
            );
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   * @param pWorkerFullName
   * @param pWorkerVatnum
   * @returns
   */
  createAssetCertificationPdf(
    pWorkerFullName: string,
    pWorkerVatnum: string,
    pPeriod: string,
    pAssetList: any[]
  ) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLCreateAssetCertificationPdf(
        pWorkerFullName,
        pWorkerVatnum,
        pPeriod,
        pAssetList
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/CreateAssetCertificationPdf'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseCreateAssetCertificationPdf(
                xmlHttpRequest.responseXML
              )
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   */
  alterCertRecord(
    pApprover: Worker,
    requestCheckedList: Certification[],
    pStatus: number
  ) {
    const cert: Certification = new Certification();
    const CertList = cert.sortCertRecord(pApprover, requestCheckedList);
    return new Promise((resolve, reject) => {
      const alterRecordReq = this.request.XMLAlterCertRecord(CertList, pStatus);
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/AlterCertRecord'
      );
      xmlRequest.send(alterRecordReq);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result = xmlRequest.responseXML.getElementsByTagName(
              'AlterCertRecordResult'
            )[0].textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  isCertPeriodValid(
    pWorkerRecId: string,
    pCertificationId: string,
    pStrDate: string
  ) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLIsCertPeriodValid(
        pWorkerRecId,
        pCertificationId,
        pStrDate
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/IsCertPeriodValid'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseIsCertPeriodValid(
                xmlHttpRequest.responseXML
              )
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  canCertificateOutsidePeriod(pWorkerRecId: string, pCertificationId: string) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLCanCertificateOutsidePeriod(
        pWorkerRecId,
        pCertificationId
      );
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/CanCertificateOutsidePeriod'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseCanCertificateOutsidePeriod(
                xmlHttpRequest.responseXML
              )
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  getAuditFinding() {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetAuditFinding();
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAuditFinding'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(
              this.interpreter.parseAuditFinding(xmlHttpRequest.responseXML)
            );
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   */
  saveAudit(
    auditRecId: number,
    petitioner: Worker,
    auditor: Worker,
    auditedWorker: Worker,
    pAuditList: AssetAudit[],
    auditStatus: number
  ) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLAudit(
        auditRecId,
        petitioner.getRecId(),
        auditor.getRecId(),
        auditedWorker.getRecId(),
        pAuditList,
        auditStatus
      );
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.open('POST', environment.GIPlus_API, true);
      xmlRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/SaveAudit'
      );
      xmlRequest.send(xml);
      xmlRequest.onreadystatechange = (aEvt) => {
        if (xmlRequest.readyState === 4) {
          if (xmlRequest.status === 200) {
            const result =
              xmlRequest.responseXML.getElementsByTagName('SaveAuditResult')[0]
                .textContent;
            resolve(result);
          } else if (xmlRequest.status === 500) {
            resolve(500);
          } else if (xmlRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  /**
   *
   *
   */
  getAudit(pAuditor: number, pStatus: number) {
    return new Promise((resolve, reject) => {
      const xml = this.request.XMLGetAudit(pAuditor, pStatus);
      const xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', environment.GIPlus_API, true);
      xmlHttpRequest.setRequestHeader('Content-Type', 'text/xml');
      xmlHttpRequest.setRequestHeader(
        'SOAPAction',
        'http://tempuri.org/IService1/GetAudit'
      );
      xmlHttpRequest.send(xml);
      xmlHttpRequest.onreadystatechange = (aEvt) => {
        if (xmlHttpRequest.readyState === 4) {
          if (xmlHttpRequest.status === 200) {
            resolve(this.interpreter.parseAudit(xmlHttpRequest.responseXML));
          } else if (xmlHttpRequest.status === 500) {
            resolve(500);
          } else if (xmlHttpRequest.status === 0) {
            reject('timeOut');
          }
        }
      };
    });
  }

  //Axel Vargas
  // --- Invalidar la existencia de un mismo Número de Factura Física de un mismo Proveedor
  async getNoPhysicalInvoiceIsValid(
    dataArea: string,
    noPhysicalInvoice: string,
    vendor: string
  ) {
    let isValid: number = -1;
    let basePath = environment.SP_API + '/business/';
    const consulta = `${basePath}getNoPhysicalInvoiceIsValid?dataAreaId=${dataArea}&noPhysicalInvoice=${noPhysicalInvoice}&vendor=${vendor}`;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        isValid = Number(data);
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer la información...', error);
      });

    console.log(isValid);

    return isValid;
  }
  // --- Invalidar la existencia de un mismo Número de Factura Física de un mismo Proveedor

  // --- Invalidar la existencia de una OC existente con el mismo categoría de propósito.
  async canGenerateNewPurchByPurposeCategory(
    dataArea: string,
    purposeCategory: string,
    executor: string
  ) {
    let isValid: number = -1;
    let basePath = environment.SP_API + '/business/';
    const consulta = `${basePath}canGenerateNewPurchByPurposeCategory?dataAreaId=${dataArea}&purposeCategory=${purposeCategory}&executor=${executor}`;
    await this._http
      .get(consulta)
      .toPromise()
      .then((data: { json: () => any }) => {
        isValid = Number(data);
      })
      .catch((error: any) => {
        console.log('Error al tratar de traer la información...', error);
      });

    console.log(isValid);

    return isValid;
  }
  // --- Invalidar la existencia de una OC existente con el mismo categoría de propósito.
  //Axel Vargas
}
