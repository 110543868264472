<ion-content>
  <h4
    class="title2"
    style="text-align: center; font-weight: bold"
    *ngIf="direction_data.edit"
  >
    Editar Dirección
  </h4>
  <h4
    class="title2"
    style="text-align: center; font-weight: bold"
    *ngIf="!direction_data.edit"
  >
    Nueva Dirección
  </h4>

  <form (ngSubmit)="addDirection()" #registerForm="ngForm" style="margin: 10px">
    <ion-grid>
      <ion-select
        label="Provincia"
        label-placement="floating"
        class="floating"
        name="province"
        (ionChange)="setCanton()"
        [(ngModel)]="direction_data.province"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option
          *ngFor="let province of province_list"
          value="{{ province.ProvinceId }}"
          >{{ province.provinceDesc }}</ion-select-option
        >
      </ion-select>
    </ion-grid>

    <ion-grid>
      <ion-select
        label="Cantón"
        label-placement="floating"
        class="floating"
        name="canton"
        (ionChange)="setDistrict()"
        [(ngModel)]="direction_data.canton"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option
          *ngFor="let canton of canton_list"
          value="{{ canton.CantonId }}"
          >{{ canton.CantonDesc }}</ion-select-option
        >
      </ion-select>
    </ion-grid>

    <ion-grid>
      <ion-select
        label="Distrito"
        label-placement="floating"
        class="floating"
        name="district"
        (ionChange)="setCity()"
        [(ngModel)]="direction_data.district"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option
          *ngFor="let district of district_list"
          value="{{ district.DistrictId }}"
          >{{ district.DistrcitDesc }}</ion-select-option
        >
      </ion-select>
    </ion-grid>

    <ion-grid *ngIf="!emptyCities()">
      <ion-select
        label="Ciudad"
        label-placement="floating"
        class="floating"
        name="city"
        [(ngModel)]="direction_data.city"
        [ngModelOptions]="{ standalone: true }"
        interface="popover"
      >
        <ion-select-option
          *ngFor="let city of city_list"
          value="{{ city.CityId }}"
          >{{ city.CityDesc }}</ion-select-option
        >
      </ion-select>
    </ion-grid>

    <ion-grid>
      <ion-row>
        <a class="required">Dirección</a>
      </ion-row>
      <ion-item>
        <ion-textarea
          label="Dirección (Otras Señas)"
          label-placement="floating"
          class="floating"
          type="text"
          name="direction"
          rows="3"
          [(ngModel)]="direction_data.direction"
          [ngModelOptions]="{ standalone: true }"
          required
        ></ion-textarea
      ></ion-item>
    </ion-grid>

    <ion-grid>
      <a class="required">Ej: Mi casa</a>
      <ion-item>
        <ion-input
          label="Nombre dirección"
          label-placement="floating"
          class="floating"
          type="text"
          name="directionName"
          [(ngModel)]="direction_data.directionName"
          [ngModelOptions]="{ standalone: true }"
          required
        ></ion-input
      ></ion-item>
    </ion-grid>

    <ion-grid>
      <div><ion-label color="primary">¿Principal?</ion-label></div>
      <ion-toggle
        name="is_principal"
        [(ngModel)]="direction_data.is_principal"
        [ngModelOptions]="{ standalone: true }"
      ></ion-toggle>
    </ion-grid>

    <div style="display: flex; justify-content: center">
      <ion-button
        shape="round"
        class="submit-btn"
        type="submit"
        *ngIf="direction_data.edit"
        style="width: 90%"
      >
        <ion-icon
          name="checkmark-circle-outline"
          class="mr-5"
          style="margin: 5px"
        ></ion-icon>
        Editar
      </ion-button>
    </div>
    <div style="display: flex; justify-content: center">
      <ion-button
        shape="round"
        class="submit-btn"
        type="submit"
        *ngIf="!direction_data.edit"
        style="width: 90%"
      >
        <ion-icon
          name="checkmark-circle-outline"
          class="mr-5"
          style="margin: 5px"
        ></ion-icon>
        Agregar
      </ion-button>
    </div>
  </form>
</ion-content>
