<app-nav-bar [title]="'Remisión'"></app-nav-bar>

<ion-content>
  <ion-grid style="padding: 0px 30px">
    <ion-row style="margin: 20px 0px">
      <ion-col class="ion-text-center">
        <ion-title>Remisión de OC</ion-title>
      </ion-col>
    </ion-row>
    <div class="main-div-pick">
      <ion-row size="large">
        <span class="label-style" style="font-size: 18px">{{ purchId }}</span>
      </ion-row>
      <!--BEGIN OBJECT-->
      <ion-row size="large">
        <span class="label-style" style="font-size: 18px">{{ objectId }}</span>
      </ion-row>
      <ion-row size="large">
        <span class="label-style">{{ objectName }}</span>
      </ion-row>
      <!--END OBJECT-->

      <ion-row>
        <ion-label class="label-style" style="margin-top: 10px"
          >Transportista</ion-label
        >
      </ion-row>
      <ion-row>
        <ion-item class="item-style" (click)="selectRoleSpecificId()"
          >{{ roleSpecificIdsLabel }}
          <ion-icon
            src="assets\bx-chevron-down.svg"
            role="img"
            class="md hydrated"
            aria-label="star"
            slot="end"
          >
          </ion-icon>
        </ion-item>
      </ion-row>
      <ion-row>
        <ion-label class="label-style" style="margin-top: 10px"
          >Descargador</ion-label
        >
      </ion-row>
      <ion-row>
        <ion-item class="item-style" (click)="selectRoleSpecificIdAssitants()"
          >{{ roleSpecificIdsAssistantsLabel }}
          <ion-icon
            src="assets\bx-chevron-down.svg"
            role="img"
            class="md hydrated"
            aria-label="star"
            slot="end"
          >
          </ion-icon>
        </ion-item>
      </ion-row>

      <ion-row>
        <ion-label class="label-style" style="margin-top: 10px"
          >Contenedor</ion-label
        >
      </ion-row>
      <ion-row>
        <ion-item class="item-style" (click)="selectContainerId()"
          >{{ containerIdsLabel }}
          <ion-icon
            src="assets\bx-chevron-down.svg"
            role="img"
            class="md hydrated"
            aria-label="star"
            slot="end"
          >
          </ion-icon>
        </ion-item>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-button
            class="button_custom"
            expand="block"
            style="margin-top: 15px"
            (click)="saveInfo()"
          >
            Aceptar
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button
            class="button_custom"
            expand="block"
            style="margin-top: 15px"
            (click)="close()"
          >
            Cancelar
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
