// Native - Plugins
import { Component } from '@angular/core';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';

// BusinessCore
import { ConfirmOrderHeader } from './../../businessCore/ConfirmOrderHeader';
import { ConfirmOrderList } from './../../businessCore/ConfirmOrderList';

// Provider
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from './../../provider/webServiceProvider';

@Component({
  selector: 'app-order-confirmation-pass',
  templateUrl: './order-confirmation-pass.component.html',
  styleUrls: ['./order-confirmation-pass.component.scss'],
})
export class OrderConfirmationPassComponent {
  // atributo de control para saber si el usuario esta confirmando o cancelando la ordern
  orderConfirmed: boolean = false;

  // atributos con la informacion de la orden
  order: ConfirmOrderList;
  orderInfo: ConfirmOrderHeader;

  // string con el motivo por el cual el usuario esta cancelando una orden
  reason: string = '';

  // string con el paramentro password ingresado por el usuario para llevar a cabo la confirmacion
  // o rechazo de una ordern
  password: string = '';

  // string con la contrasenha actual del usuario, consultada desde el storage con el key = 'password'
  actualPassword: string = '';

  /**
   * Class constructor with these injections:
   * @param params  object that exists on a page and can contain data for that particular view.
   * @param _apiProvider web service provider
   * @param viewCtrl eatures and information about the current view.
   * @param storage to store key/value pairs and JSON objects.
   * @param alertCtrl dialog that presents users with information
   * or collects information from the user using inputs.
   */
  constructor(
    public params: NavParams,
    public _apiProvider: webServiceProvider,
    public viewCtrl: PopoverController,
    public storage: StorageService,
    public alertCtrl: AlertController
  ) {
    this.orderConfirmed = params.get('orderConfirmed');
    this.order = params.get('order');

    console.log('this.orderConfirmed');
    console.log(this.orderConfirmed);
    console.log('this.order');
    console.log(this.order);

    // Obtiene la info de la orden
    this.getOrderHeader();

    // Toma la contrasenha que se tiene almacenada en storage
    this.getPassword();
  }

  /**
   * Metodo utilizado para consultar la contrasenha actual del consumidor
   * la cual se encuentra almacenada con el nombre 'password' en el storage
   * del dispositivo
   */
  getPassword() {
    this.storage.get('password').then((data) => {
      if (data != null) {
        this.actualPassword = JSON.parse(data) as string;
      }
    });
  }

  /**
   * Metodo utilizado para consultar la informacion que tiene asociada una orden
   * que esta siendo consultada y confirmada por un consumidor
   */
  getOrderHeader() {
    this._apiProvider
      .getOrderHeader(this.order.SALESID, this.order.CUSTACCOUNT)
      .subscribe((res) => {
        let data = res as Array<any>;
        if (data != null) {
          let headerTmp = data.pop();
          console.log('headerTmp');
          console.log(headerTmp);
          this.orderInfo = headerTmp
            ? (headerTmp as ConfirmOrderHeader)
            : new ConfirmOrderHeader();
          console.log('this.orderInfo');
          console.log(this.orderInfo);
          let CREATEDDATETIME = new Date(
            parseInt(this.orderInfo.CREATEDDATETIME.substr(6))
          );
          let FechaVence = new Date(
            parseInt(this.orderInfo.FechaVence.substr(6))
          );
          let PSCUSTSHIPDATE = new Date(
            parseInt(this.orderInfo.PSCUSTSHIPDATE.substr(6))
          );

          this.orderInfo.CREATEDDATETIME = CREATEDDATETIME.toLocaleDateString();
          this.orderInfo.FechaVence = FechaVence.toLocaleDateString();
          this.orderInfo.PSCUSTSHIPDATE = PSCUSTSHIPDATE.toLocaleDateString();
        }
      });
  }

  /**
   * Metodo utilizado para llevar a cabo el cierre de una orden
   * validando si la contrasenha ingresada coincide con la actual,
   * de cumplirse se procede a verificar cual metodo llevar a cabo,
   * si el cierre de la orden o la confirmacion de la misma, esto segun
   * los datos ingresados por el usuario.
   */
  async closeOrder() {
    if (this.actualPassword == this.password) {
      if (this.orderConfirmed && this.validForm()) {
        // Order confirmation
        this._apiProvider
          .confirmOrder(
            this.order.SALESID,
            this.orderInfo.CUSTACCOUNT,
            this.reason,
            this.orderInfo.IdV,
            1,
            0
          )
          .subscribe((data) => {
            this.viewCtrl.dismiss(0);
          });
      } else if (this.validForm()) {
        // Close order
        this._apiProvider
          .confirmOrder(
            this.order.SALESID,
            this.orderInfo.CUSTACCOUNT,
            this.reason,
            this.orderInfo.IdV,
            0,
            1
          )
          .subscribe((data) => {
            this.viewCtrl.dismiss(1);
          });
      } else {
        // Error
        this.viewCtrl.dismiss(-1);
      }
    } else {
      this.password = '';
      let alert = await this.alertCtrl.create({
        message: 'La contraseña no coincide',
        subHeader: 'Por favor inténtelo nuevamente.',
        buttons: ['Ok'],
        backdropDismiss: false,
      });
      alert.present();
    }
  }

  /**
   * Valida que se ingresó una contraseña y un motivo en caso de aplicarse
   * de lo contrario devuleve un false para indicar lo contrario
   * @returns boolean con la confirmacion de estado correco/incorrecto
   */
  validForm() {
    if (
      (this.orderConfirmed && this.password.length > 0) ||
      (!this.orderConfirmed &&
        this.password.length > 0 &&
        this.reason.length > 0)
    ) {
      return true;
    }
    return false;
  }
}
