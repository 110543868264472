<app-nav-bar [title]="'Registro de proveedores'"></app-nav-bar>

<ion-content>
  <app-vendor-register-general
    *ngIf="actualScreen == 1"
    [generalLenguage]="languageData"
    [changeLanguageTo]="changeLanguageTo"
    (languageChanger)="translate($event)"
    (screenChanger)="changeScreen($event)"
    [(generalData)]="generalData"
  >
  </app-vendor-register-general>
  <app-vendor-register-payment
    *ngIf="actualScreen == 2"
    [paymentLanguage]="languageData"
    [changeLanguageTo]="changeLanguageTo"
    (languageChanger)="translate($event)"
    (screenChanger)="changeScreen($event)"
    [(paymentData)]="paymentData"
  >
  </app-vendor-register-payment>
  <app-vendor-register-contact
    *ngIf="actualScreen == 3"
    [contactLanguage]="languageData"
    [changeLanguageTo]="changeLanguageTo"
    (languageChanger)="translate($event)"
    (screenChanger)="changeScreen($event)"
    [(contactData)]="contactData"
  >
  </app-vendor-register-contact>
  <app-vendor-register-address
    *ngIf="actualScreen == 4"
    [addressLanguage]="languageData"
    [changeLanguageTo]="changeLanguageTo"
    (languageChanger)="translate($event)"
    (screenChanger)="changeScreen($event)"
    [(addressData)]="addressData"
  >
  </app-vendor-register-address>
  <app-vendor-register-document
    *ngIf="actualScreen == 5"
    [documentLanguage]="languageData"
    [changeLanguageTo]="changeLanguageTo"
    (languageChanger)="translate($event)"
    (screenChanger)="changeScreen($event)"
    [(documentData)]="documentData"
    [reqRepr]="generalData.representVatnum"
    (registerVendor)="registerVendor()"
  >
  </app-vendor-register-document>
</ion-content>
