import { Component } from '@angular/core';
import {
  AlertController,
  IonRefresher,
  LoadingController,
  NavController,
  NavParams,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CheckListInstance } from '../../../businessCore/check-models/CheckInstance';
import { ExternalServiceChecklistProvider } from '../../../provider/Services/external-service-checklist';
import { ServicesLocalCheckProvider } from '../../../provider/Services/services-local-check';
import { AuthJWTService } from '../../../provider/auth-jwt/auth.jwt.service';
import { AuthenticationAuthGuardProvider } from '../../../providers/authentication-auth-guard/authentication-auth-guard';

@Component({
  selector: 'app-checklist-instance',
  templateUrl: './checklist-instance.component.html',
  styleUrls: ['./checklist-instance.component.scss'],
})
export class ChecklistInstancePage {
  checkLitsPending: CheckListInstance[];
  vatNum: string;
  loader: HTMLIonLoadingElement;
  private presentingMess: boolean;
  private pruSubs: Subscription;
  auth: number;
  constructor(
    public navCtrl: NavController,
    private service: ExternalServiceChecklistProvider,
    private loadingCtrl: LoadingController,
    public alertController: AlertController,
    private localService: ServicesLocalCheckProvider,
    public navParams: NavParams,
    public authGuard: AuthenticationAuthGuardProvider,
    private authService: AuthJWTService
  ) {
    this.auth = 0;
    this.presentingMess = false;
  }

  async getVatNum() {
    // const info = window.location.search;
    // const urlParams = new URLSearchParams(info);
    this.vatNum = this.navParams.get('userID'); // urlParams.get('userID');
    if (
      this.vatNum === null ||
      this.vatNum === undefined ||
      this.vatNum === ''
    ) {
      this.vatNum = await this.localService.getCedulaActual();
    }
  }

  async ngOnInit() {
    this.auth = Number(this.navParams.get('auth'));
    let componentId = this.navParams.get('code');
    if (this.auth === 1) {
      if (
        (await this.authGuard.checkToken()) &&
        this.authGuard.componentAllow(
          this.authService.getComponentsInfo(),
          componentId
        )
      ) {
        this.vatNum = this.authService.getTokenData('refresh');
      } else {
        window.location.href = 'http://giiis01:8025/';
      }
    } else {
      // if(this.authService.hasCookie()){
      //   window.location.replace(`http://localhost:8100/#/ChecklistPendientes/1/${componentId}`)
      // }
      await this.getVatNum();
    }

    if (
      this.vatNum !== null &&
      this.vatNum !== undefined &&
      this.vatNum !== ''
    ) {
      await this.loadAllData();
      /* await this.showSpinnerLoading('Cargando datos de checklits pendientes');
      this.checkLitsPending = await this.service.getMyPendingCheckList(this.vatNum);

      //Esto se debe de hacer siempre cuando se regresa a la página...
      //Se llaman los datos de checklist locales...
      const dataFromLocal = await this.getDataFromLocal();

      this.checkLitsPending =  this.concatenarInfoInstance(this.checkLitsPending, dataFromLocal);
      //Se debe de validar cuales mostrar y cuales no.
      ///////////////////////////////////////////////////////////////////////

      this.dissmissLoading();
      if(this.checkLitsPending) {
        if(this.checkLitsPending.length === 0) {
          this.messageInfo('Atención', 'No hay checklist pendientes.');
        }
      } else {
        this.messageInfo('Atención', 'No se pudo comunicar con el servidor.');
      }*/

      ////////////////////////////////////////////
      this.pruSubs = this.localService
        .getSubjectReload()
        .subscribe((reload) => {
          if (reload) {
            this.loadAllData();
            this.localService.setNextSubjetReload(false);
          }
        });

      this.localService.setNextSubjetReload(false);
      ////////////////////////////////////////////
    } else {
      this.messageInfo('Atención', 'No se encuentran datos de usuario.');
    }
  }

  ionViewDidEnter() {
    if (this.localService.getReloadInfo()) {
      this.loadAllData();
      this.localService.setReloadInfo(false);
    }
  }

  ngOnDestroy() {
    this.pruSubs.unsubscribe();
  }

  async loadAllData() {
    if (!this.presentingMess) {
      this.presentingMess = true;
      await this.showSpinnerLoading('Cargando datos de checklits pendientes');
      this.checkLitsPending = await this.service.getMyPendingCheckList(
        this.vatNum
      );

      //Esto se debe de hacer siempre cuando se regresa a la página...
      //Se llaman los datos de checklist locales...
      const dataFromLocal = await this.getDataFromLocal();

      this.checkLitsPending = this.concatenarInfoInstance(
        this.checkLitsPending,
        dataFromLocal
      );
      //Se debe de validar cuales mostrar y cuales no.
      ///////////////////////////////////////////////////////////////////////
      this.dissmissLoading();
      this.presentingMess = false;

      if (this.checkLitsPending) {
        if (this.checkLitsPending.length === 0) {
          this.messageInfo('Atención', 'No hay checklist pendientes.');
        }
      } else {
        this.messageInfo('Atención', 'No se pudo comunicar con el servidor.');
      }
    }
  }

  async getDataFromLocal() {
    // DEBUG //
    /*let res: CheckListInstance[] = [
      new CheckListInstance('123', '', '123', 0, new Date(), new Date()),
    ];
    return res;*/
    //DEBUG
    return await this.localService.getInstanceCheckList();
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    /* if(action && action > 0) {
      botones = [{ text: 'SI', handler: () => {
        this.executeAction(action);
      } }, { text: 'NO' , handler: () => {}}];
    }  else if (action && action === -1) {
      // botones = [{ text: 'ACEPTAR', handler: () => {this.navCtrl.pop()}}];
    // }*/
    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  concatenarInfoInstance(
    dataFromService: CheckListInstance[],
    dataFromLocal: CheckListInstance[]
  ) {
    let infoList: CheckListInstance[] = [];
    if (
      dataFromService !== null &&
      dataFromService !== undefined &&
      dataFromService.length > 0
    ) {
      for (let index = 0; index < dataFromService.length; index++) {
        const infoInstaS = dataFromService[index];
        let encontrado: boolean = false;
        for (let j = 0; j < dataFromLocal.length; j++) {
          const infoInstaL = dataFromLocal[j];
          if (infoInstaS.getInstanceRecId() === infoInstaL.getInstanceRecId()) {
            //Remplaza y settea el nuevo dato
            infoInstaL.position = infoInstaS.position;
            infoInstaL.setEstado(infoInstaS.getEstado());
            infoInstaL.setLastExecution(infoInstaS.getLastExecution());
            infoInstaL.setExecutionDate(infoInstaS.getExecutionDate());
            infoList.push(infoInstaL);
            dataFromLocal.splice(j, 1); //saco esa info
            encontrado = true;
            break;
          }
        }
        if (!encontrado) {
          infoList.push(infoInstaS);
        }
      }
    } else if (
      dataFromLocal !== null &&
      dataFromLocal !== undefined &&
      dataFromLocal.length > 0
    ) {
      infoList = dataFromLocal;
    } else if (
      dataFromService !== null &&
      dataFromService !== undefined &&
      dataFromService.length === 0
    ) {
      infoList = [];
    } else {
      infoList = null;
    }

    if (infoList) {
      for (let index = 0; index < infoList.length; index++) {
        infoList[index].position = index + 1;
      }
    }

    return infoList;
  }

  refresChecklist(event) {
    let refresher: IonRefresher = event.detail;
    refresher.complete();
    this.loadAllData();
  }
}
