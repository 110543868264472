<app-nav-bar [title]="'Tutoriales de Uso'" [start]="'GoBack'"></app-nav-bar>

<ion-content>
  <div class="flexCont">
    <div
      class="vCont familyCont relative flexCont"
      *ngFor="let family of families_info"
    >
      <div
        class="familyCont relative"
        [ngClass]="{ opacity: !family.selected }"
        (click)="playVideo(family)"
      >
        <video
          #videos
          class="videoContainer"
          [id]="family.familyTitle"
          [hidden]="!family.selected"
        >
          <source
            src="https://serviceplan-api.mercasacr.com/videos/tutorial/{{
              family.familyTitle
            }}.mp4"
            type="video/mp4"
          />
        </video>
        <div
          class="familyCont infoContainer fImg marginTB"
          *ngIf="!family.selected"
          [ngStyle]="{
            'background-image':
              'url(https://serviceplan-api.mercasacr.com/videos/tutorial/' +
              family.familyTitle +
              '_bg.PNG' +
              ')'
          }"
        >
          <i class="far fa-play-circle f45 infoi"></i>
        </div>
      </div>
    </div>
  </div>
</ion-content>
