export class PurchPoolModel {
  constructor(private purchPoolId: string, private name: string) {}

  getId() {
    return this.purchPoolId;
  }

  getName() {
    return this.name;
  }

  setId(newId: string) {
    this.purchPoolId = newId;
  }

  setName(newName: string) {
    this.name = newName;
  }

  setDataValue(newId: string, newName: string) {
    this.purchPoolId = newId;
    this.name = newName;
  }
}
