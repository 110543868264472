import { Connection } from './connection.interface';
import { XMLResponse } from './header.response';
import { Header } from './headers.interface';
import { ParametersBuilder } from './parameter';

export class XMLRequest {
  constructor(private connection: Connection) {}

  executeRequest(
    method: string,
    parameters: ParametersBuilder,
    headers: Header[] = []
  ) {
    let xmlRequest = this._constructXMLRequest(parameters, method);
    return new Promise<XMLResponse>((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', this.connection.serviceLink, true);

      xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
      xhr.setRequestHeader(
        'SOAPAction',
        `http://tempuri.org/${this.connection.IService}/${method}`
      );
      headers.forEach((header) => {
        xhr.setRequestHeader(header.key, header.value);
      });
      xhr.send(xmlRequest.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

      xhr.onreadystatechange = function (aEvt) {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            let response: XMLResponse = {
              xml: xhr.responseXML,
              headers: {},
            };

            let headers = xhr.getAllResponseHeaders();
            let arr = headers.trim().split(/[\r\n]+/);
            (arr || []).forEach(function (line) {
              let parts = line.split(': ');
              let header = parts.shift();
              let value = parts.join(': ');
              response.headers[header.toLowerCase()] = value;
            });

            resolve(response);
          } else {
            reject(new Error(xhr.statusText));
          }
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
    });
  }

  private _constructXMLRequest(
    parameters: ParametersBuilder,
    method: string
  ): string {
    return `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
         <s:Body>
            <${method} xmlns="http://tempuri.org/">
                ${parameters.buildParameters()}
            </${method}>
            </s:Body>
        </s:Envelope>`;
  }
}
