<app-nav-bar [title]="'Líneas'">
  <ion-buttons slot="end">
    <ion-button
      style="background: none"
      (click)="openMenuOC($event)"
      *ngIf="!toEdit"
    >
      <img
        src="assets/img/menuPoint2.svg"
        style="width: 25px"
        alt=""
        onerror=""
      />
    </ion-button>
    <ion-button style="background: none" *ngIf="toEdit" (click)="changeEdit()">
      <i class="fas fa-pencil-alt" style="width: 30px"></i>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-toolbar class="oc-parameter" *ngIf="(toEdit && !isEditing) || !toEdit">
  <ion-segment color="black" value="infoLineas" [(ngModel)]="segmentModel">
    <ion-segment-button value="infoLineas" layout="icon-start">
      <!--<ion-icon name="person"></ion-icon>-->
      <ion-label class="labelCenter">Líneas</ion-label>
    </ion-segment-button>
    <ion-segment-button value="infoTotales" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <ion-label class="labelCenter">Totales</ion-label>
    </ion-segment-button>
    <ion-segment-button value="infoNota" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <ion-label class="labelCenter">Notas</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-toolbar>

<ion-toolbar class="oc-parameter" *ngIf="toEdit && isEditing">
  <ion-segment color="black" value="infoLineas" [(ngModel)]="segmentModel">
    <ion-segment-button value="infoLineas" layout="icon-start">
      <!--<ion-icon name="person"></ion-icon>-->
      <ion-label class="labelCenter">Agregar Líneas</ion-label>
    </ion-segment-button>
    <ion-segment-button value="infoEditingAdd" layout="icon-start">
      <ion-label class="labelCenter">Editar Líneas</ion-label>
    </ion-segment-button>
    <ion-segment-button value="infoTotales" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <ion-label class="labelCenter">Totales</ion-label>
    </ion-segment-button>
    <ion-segment-button value="infoNota" layout="icon-start">
      <!--<ion-icon name="pin"></ion-icon>-->
      <ion-label class="labelCenter">Notas</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-toolbar>

<ion-content>
  <div class="box">
    <div class="content">
      <app-line-oc
        [cantidadLineas]="cantidadLineas"
        [total]="total"
        [purhInfoOC]="purchInfo"
        [lineaActual]="actualLine"
        [productActual]="productoSelected"
        [unidadSelected]="unidadSelected"
        [impuestos]="impuestos"
        [aDepartamentoSelected]="aDepartamentoSelected"
        [bCentroCostoSelected]="bCentroCostoSelected"
        [cPropositoSelected]="cPropositoSelected"
        [tipoImpuesto]="tipoImpuesto"
        (getPurchInfoOC)="getPurchInfoOC($event)"
        (openSearchBar)="openSearchBar($event)"
        *ngIf="
          (segmentModel === 'infoLineas' &&
            !toEdit &&
            purchInfo &&
            productoSelected &&
            unidadSelected) ||
          (segmentModel === 'infoLineas' &&
            toEdit &&
            isEditing &&
            purchInfo &&
            productoSelected &&
            unidadSelected)
        "
      >
      </app-line-oc>

      <div
        *ngIf="
          (segmentModel === 'infoEditingAdd' && toEdit && isEditing) ||
          (segmentModel === 'infoLineas' && toEdit && !isEditing)
        "
        class="box"
      >
        <div lines="none" class="barTitle">
          <div style="display: flex; width: 100%">
            <h5 style="width: 100%; font-size: 1rem; text-align: center">
              N° Líneas: {{ cantidadLineas }}
            </h5>
            <h5 style="width: 100%; font-size: 1rem; text-align: center">
              Imp: {{ getCurrency(impuestos) }}
            </h5>
            <h5 style="width: 100%; font-size: 1rem; text-align: center">
              Total: {{ getCurrency(total) }}
            </h5>
          </div>
        </div>
        <ion-content class="content2">
          <ion-list>
            <app-linea-info-oc
              *ngFor="let purchLineInf of purchInfo.getLineList()"
              [purchLineInf]="purchLineInf"
              [cantEdit]="isEditing"
              (deleteItem)="deleteItem($event)"
              (updateInfPurch)="updateInfPurch($event)"
              [taxInfo]="purchInfo.getTaxInclude()"
            >
            </app-linea-info-oc>
          </ion-list>
        </ion-content>
      </div>

      <app-totales-oc
        *ngIf="segmentModel === 'infoTotales'"
        [purhInfoOC]="purchInfo"
      ></app-totales-oc>

      <app-nota-oc
        *ngIf="segmentModel === 'infoNota'"
        [note]="notaLineaAct"
        (getNote)="getNoteLine($event)"
        [cantEdit]="!toEdit"
      ></app-nota-oc>

      <ion-label
        *ngIf="
          ((notItem || !listItem) && !toEdit) ||
          ((notItem || !listItem) &&
            toEdit &&
            isEditing &&
            segmentModel === 'infoLineas')
        "
      >
        <h2 class="noItemC">Atención!!!</h2>
        <p class="noItemM">No hay productos para este provedor.</p>
      </ion-label>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <ion-fab horizontal="start" vertical="bottom" *ngIf="!toEdit">
    <ion-fab-button (click)="goToBack()" size="small"
      ><ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab horizontal="start" vertical="bottom" *ngIf="toEdit">
    <ion-fab-button (click)="goToBackEdit()" size="small"
      ><ion-icon name="chevron-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab horizontal="end" vertical="bottom" *ngIf="!toEdit">
    <ion-fab-button (click)="goToConfirmOrder()" size="small">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="whiteRectangle"></div>
</div>
