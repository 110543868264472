<div class="box">
  <div lines="none" class="barTitle">
    <div style="display: flex; width: 100%">
      <h5 class="centerText">N° Líneas: {{ cantidadLineas }}</h5>
      <h5 class="centerText">Imp: {{ getCurrency(impuestos) }}</h5>
      <h5 class="centerText">Total: {{ getCurrency(total) }}</h5>
    </div>
  </div>

  <div class="content">
    <ion-item (click)="emitSelectedBar(1)">
      <ion-label ion-text-wrap>
        <h2 class="h2Select">Producto</h2>
        <h3>{{ productActual.getId() }}</h3>
        <p>{{ productActual.getName() }}</p>
      </ion-label>
      <ion-icon name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item (click)="emitSelectedBar(2)">
      <ion-label>
        <h2 class="h2Select">Unidad</h2>
        <p>{{ unidadSelected.unitId }}</p>
      </ion-label>
      <ion-icon name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item (click)="emitSelectedBar(9)">
      <ion-label>
        <h2 class="h2Select">Impuesto</h2>
        <p>{{ tipoImpuesto.getId() }}</p>
      </ion-label>
      <ion-icon name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <app-input-number
      [titulo]="'Cantidad'"
      [cantidadObjeto]="unidadSelected.cantidad ? unidadSelected.cantidad : 0"
      (getNumber)="getNumberCantidad($event)"
      [cantEdit]="true"
    >
    </app-input-number>

    <div>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="width: 80%">
          <ion-item>
            <ion-label>
              Precio unitario ({{ this.purhInfoOC.getCurrency() }}):
            </ion-label>
          </ion-item>
        </div>
        <div slot="end" style="display: contents">
          <div class="divInputLine">
            <input
              type="number"
              [(ngModel)]="unidadSelected.amount"
              min="0"
              step="1"
              class="inputNumberT"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="width: 80%">
          <ion-item>
            <ion-label> Porcentaje de Descuento: </ion-label>
          </ion-item>
        </div>
        <div slot="end" style="display: contents">
          <div class="divInputLine">
            <input
              type="number"
              [(ngModel)]="unidadSelected.percent"
              min="0"
              step="1"
              max="100"
              class="inputNumberT"
            />
          </div>
        </div>
      </div>
    </div>

    <ion-item (click)="openCloseDimensionesFinancieras()">
      <ion-label><h2>Dimensiones Financieras</h2></ion-label>
      <ion-icon item-end name="add" *ngIf="!dimensionesFinShow"></ion-icon>
      <ion-icon item-end name="remove" *ngIf="dimensionesFinShow"></ion-icon>
    </ion-item>

    <div class="divCantProd" *ngIf="dimensionesFinShow">
      <ion-item (click)="emitSelectedBar(6)">
        <ion-label>
          <h2>A_Departamento</h2>
          <p>{{ aDepartamentoSelected.getName() }}</p>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>
      <ion-item (click)="emitSelectedBar(7)">
        <ion-label>
          <h2>B_CentroCosto</h2>
          <p>{{ bCentroCostoSelected.getName() }}</p>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>
      <ion-item (click)="emitSelectedBar(8)">
        <ion-label>
          <h2>C_Proposito</h2>
          <p>{{ cPropositoSelected.getName() }}</p>
        </ion-label>
        <ion-icon name="caret-down-outline" item-end></ion-icon>
      </ion-item>
    </div>
  </div>

  <div class="infoContent">
    <div class="addLineBtn">
      <ion-button
        fill="clear"
        (click)="addProducto()"
        icon-start
        color="primary"
      >
        Agregar línea
        <img
          src="assets/img/addLine.svg"
          style="width: 25px"
          alt=""
          onerror=""
        />
      </ion-button>
    </div>
  </div>
</div>
