import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from '../../provider/webServiceProvider';

export interface ContactEmail {
  title: string;
  email: string;
  main: string;
  add: string;
  password?: string;
  enterBtn?: string;
  text?: string;
}

export interface ContactEmailConfirm {
  title: string;
  password: string;
  enterBtn: string;
  text: string;
  add: string;
}

@Component({
  selector: 'app-register-contact-email',
  templateUrl: './register-contact-email.component.html',
  styleUrls: ['./register-contact-email.component.scss'],
})
export class RegisterContactEmailComponent implements OnInit {
  contactEmailScreen = 1;

  newMail: any = { email: '', main: false };
  codeByEmail: any = { code: '', tries: 0 };

  code: any;

  contactEmail: ContactEmail;
  contactEmailConfir: ContactEmailConfirm;

  contactEmailES: ContactEmail = {
    title: 'Nuevo contacto',
    email: 'Correo eletrónico',
    main: 'Correo para notificaciones de pago',
    add: 'Agregar',
  };

  contactEmailEN: ContactEmail = {
    title: 'New Contact',
    email: 'Email',
    main: 'Email direction for payment notifications',
    add: 'Add',
  };

  contactEmailConfirES: ContactEmailConfirm = {
    title: 'Confirmación de correo',
    password: 'Contraseña',
    enterBtn: 'Cofirmar',
    text: 'Se ha enviado una contraseña al correo electrónico registrado, favor ingresar la contraseña',
    add: 'Agregar',
  };

  contactEmailConfirEN: ContactEmailConfirm = {
    title: 'Email Confirmation',
    password: 'Correo eletrónico',
    enterBtn: 'Enter',
    text: 'A password has been sent to the registered email, please enter the password',
    add: 'Add',
  };

  constructor(
    public storage: StorageService,
    private _apiProvider: webServiceProvider,
    public viewCtrl: PopoverController
  ) {}

  async ngOnInit() {
    await this.storage.get('vendor_language').then((data) => {
      const language = data as string;

      if (language.toString() == 'ES') {
        this.contactEmail = this.contactEmailEN;
        this.contactEmailConfir = this.contactEmailConfirEN;
      } else {
        this.contactEmail = this.contactEmailES;
        this.contactEmailConfir = this.contactEmailConfirES;
      }
    });
  }

  /**
   *
   */
  validateEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.newMail.email).toLowerCase());
  }

  /**
   *
   */
  async confirmEmail() {
    this.codeByEmail.code = await this.getValidationCode(this.newMail.email);
    this.codeByEmail.tries = 3;
    this.contactEmailScreen = 2;
    // console.log(this.codeByEmail);
  }

  /**
   *
   */
  async getValidationCode(pEmail: string) {
    let resp: any = await this._apiProvider.getValidationCode(pEmail);
    resp = resp as string[];
    return resp;
  }

  /**
   *
   * @param pCode
   */
  async validateCode() {
    if (this.code == this.codeByEmail.code) {
      this.viewCtrl.dismiss(this.newMail);
    } else {
      this.codeByEmail.tries = this.codeByEmail.tries - 1;
    }
  }
}
