export class PurposeCategoriesAllowedByRole {
  Purpose: string = '';
  PurposeCategory: string = '';
  DiaryAmountAllowed: number = 0;
  RangeStartTime: string = '00:00:00';
  RangeEndTime: string = '00:00:00';
  RegisteredDate: string = 'RegisteredDate';

  constructor(
    newPurposeCategoriesAllowedByRole?: PurposeCategoriesAllowedByRole
  ) {
    this.Purpose = newPurposeCategoriesAllowedByRole.Purpose;
    this.PurposeCategory = newPurposeCategoriesAllowedByRole.PurposeCategory;
    this.DiaryAmountAllowed =
      newPurposeCategoriesAllowedByRole.DiaryAmountAllowed;
    this.RangeStartTime = newPurposeCategoriesAllowedByRole.RangeStartTime;
    this.RangeEndTime = newPurposeCategoriesAllowedByRole.RangeEndTime;
    this.RegisteredDate = newPurposeCategoriesAllowedByRole.RegisteredDate;
  }
}
