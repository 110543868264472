<ion-content>
  <ion-input
    placeholder="Filtrar"
    class="input-style"
    (keyup)="filter()"
    [(ngModel)]="input_value"
  ></ion-input>

  <ion-list>
    <ion-item *ngFor="let option of options" (click)="evalMultiple(option)">
      <ion-label>{{ option.label }}-{{ option.description }}</ion-label>
      <ion-checkbox slot="end" [(ngModel)]="option.isChecked"></ion-checkbox>
    </ion-item>
  </ion-list>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="Cargando datos..."
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer>
  <ion-row>
    <ion-col>
      <ion-button
        class="button-style"
        expand="block"
        style="margin-top: 15px"
        (click)="save()"
      >
        Aceptar
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button
        class="button-style"
        expand="block"
        style="margin-top: 15px"
        (click)="close()"
      >
        Cancelar
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
