<ion-content center text-center>
  <h4 class="title2" style="text-align: center" *ngIf="orderConfirmed">
    Confirmación del Pedido
  </h4>
  <h4 class="title2" style="text-align: center" *ngIf="!orderConfirmed">
    Rechazo del Pedido
  </h4>

  <ion-item *ngIf="!orderConfirmed">
    <a class="required"></a>
    <ion-input
      label="Motivo"
      label-placement="floating"
      class="floating"
      type="text"
      name="reason"
      [(ngModel)]="reason"
      [ngModelOptions]="{ standalone: true }"
      required
    ></ion-input>
  </ion-item>

  <ion-item>
    <a class="required"></a>
    <ion-input
      label="Contraseña"
      label-placement="floating"
      class="floating"
      type="password"
      name="password"
      [(ngModel)]="password"
      [ngModelOptions]="{ standalone: true }"
      required
    ></ion-input>
  </ion-item>

  <ion-button
    class="submit-btn"
    type="submit"
    *ngIf="orderConfirmed"
    (click)="closeOrder()"
    [disabled]="!validForm()"
    style="width: 98%"
    shape="round"
  >
    <i class="fa fa-check" style="margin-right: 5px"></i>Confirmar
  </ion-button>

  <ion-button
    class="submit-btn"
    type="submit"
    *ngIf="!orderConfirmed"
    (click)="closeOrder()"
    [disabled]="!validForm()"
    style="width: 98%"
    shape="round"
  >
    <i class="fa fa-minus-circle" style="margin-right: 5px"></i>Rechazar
  </ion-button>
</ion-content>
