<app-nav-bar [title]="'Inicio'" [start]="'Menu'" [end]="'Cart'"></app-nav-bar>

<ion-content>
  <div class="flexContainer">
    <div
      class="familyContainer fImg"
      [ngStyle]="{
        'background-image':
          'url(https://serviceplan-api.mercasacr.com/images/Products/Families/' +
          familyTitle +
          '_BG.JPG' +
          ')'
      }"
      (click)="nextStep(familyTitle)"
      *ngFor="let familyTitle of families_info"
    >
      <img
        src="{{
          'https://serviceplan-api.mercasacr.com/images/Products/Families/' +
            familyTitle +
            '_ico.png'
        }}"
        alt=""
      />
      <div class="h7">{{ replaceUnderScore(familyTitle) }}</div>
    </div>
  </div>
</ion-content>
