import { PromoConditionsView } from './PromoConditionsView';

export class PromoConditionGroup {
  GroupID: number = 0;
  PromoCondition: PromoConditionsView[] = [];
  GroupRequired: number = -1;

  constructor(GroupID: number, PromoCondition: PromoConditionsView) {
    this.GroupID = GroupID;
    this.PromoCondition = [PromoCondition];
  }
}
