<app-nav-bar [title]="'Aprobar O.C'"
  ><ion-buttons slot="end">
    <ion-button style="background: none" (click)="openSendFile()">
      <ion-icon name="document"></ion-icon>
    </ion-button> </ion-buttons
></app-nav-bar>

<ion-content>
  <div class="box">
    <ion-item lines="none" *ngIf="purchInfo.getIdOC() !== ''">
      <ion-label style="font-size: 1rem; text-align: center">
        <b>{{ purchInfo.getIdOC() }}</b>
      </ion-label>
    </ion-item>
    <div style="display: flex">
      <ion-item lines="none" style="width: 30%">
        <ion-label style="font-size: 1rem">
          {{ purchInfo.getAccountNum() }}
        </ion-label>
      </ion-item>
      <ion-item
        lines="none"
        style="width: 70%"
        *ngIf="purchInfo.getVendName() !== ''"
      >
        <ion-label text-wrap style="font-size: 1rem">
          {{ purchInfo.getVendName() }}
        </ion-label>
      </ion-item>
    </div>
    <ion-item
      lines="none"
      style="width: 50%"
      *ngIf="purchInfo.getVendName() !== ''"
    >
      <ion-label text-wrap style="font-size: 1rem">
        {{ purchInfo.getCurrency() }}
      </ion-label>
    </ion-item>

    <div lines="none" class="barTitle">
      <div style="display: flex; width: 100%">
        <h5 class="btnTitle" (click)="openHeaderOC()">Encabezado</h5>
        <h5 class="btnTitle" (click)="openLineOC()">Líneas</h5>
      </div>
    </div>

    <ion-content class="content">
      <ion-list>
        <app-linea-info-oc
          *ngFor="let purchLineInf of purchInfo.getLineList()"
          [purchLineInf]="purchLineInf"
          [cantEdit]="false"
          [taxInfo]="purchInfo.getTaxInclude()"
        >
        </app-linea-info-oc>
      </ion-list>
    </ion-content>

    <div class="infContent">
      <ion-item lines="none" color="primary">
        <div style="display: flex; width: 100%">
          <h5 style="width: 100%; font-size: 1rem; text-align: center">
            N° Líneas: {{ cantidadLineas }}
          </h5>
          <h5 style="width: 100%; font-size: 1rem; text-align: center">
            Imp: {{ getCurrency(impuestos) }}
          </h5>
          <h5 style="width: 100%; font-size: 1rem; text-align: center">
            Total: {{ getCurrency(total) }}
          </h5>
        </div>
      </ion-item>
      <div style="display: flex; margin-top: 2%; margin-bottom: 2%">
        <ion-button
          ion-button
          color="danger"
          class="btnPosition"
          (click)="rejectPurchOrder()"
        >
          Rechazar
        </ion-button>
        <ion-button
          ion-button
          color="secondary"
          class="btnPosition"
          (click)="approvePurchOrder()"
        >
          Aprobar
        </ion-button>
      </div>
    </div>
  </div>
  <!-- Slide to unlock -->
  <!-- <div style="text-align: center; margin-top: 5px;">
      <input #unlock type="range" class="slideToUnlock" value="0" max="100" (touchend)="checkUnlock($event)" (mouseout)="checkUnlock($event)">
    </div> -->
</ion-content>
