<app-nav-bar
  [title]="'Terminos y condiciones'"
  [start]="'GoBack'"
  *ngIf="showHeader"
></app-nav-bar>

<ion-content class="ion-padding">
  <div
    id="page1-div"
    style="position: relative; text-align: justify; font-family: arial narrow"
  >
    Legal
    <br />
    <br />
    TÉRMINOS Y CONDICIONES
    <br />
    <br />
    Última actualización: 03 de octubre de 2018
    <br />
    <br />
    <div class="title1">1. Definiciones:</div>

    a. Club: Personas afiliadas a la Plataforma Tecnológica, con el fin de
    adquirir bienes comercializados por empresas que formen parte del GRUPO
    INTECA.
    <br /><br />
    b. Código promocional: Composición de elementos numéricos, alfabéticos o
    alfanuméricos mediante la cual el cliente al ingresar dicha composición a la
    Plataforma Tecnológica optará por crédito de Cuenta, descuento, u otros
    elementos o beneficios relacionados con la obtención de bienes por parte del
    GRUPO INTECA.
    <br /><br />
    c. Condiciones: Los términos y condiciones que contiene el presente
    documento.
    <br /><br />
    d. Contenido de cliente: Corresponde a la información que el cliente envíe,
    cargue, publique o de otro modo ponga a disposición del GRUPO INTECA a
    través de la Plataforma Tecnológica, contenido que incluye: información de
    texto, audio y/o visual (incluidos comentarios y opiniones relativos a la
    Plataforma Tecnológica, iniciación de peticiones de apoyo, así como
    presentación de admisiones para competiciones y promociones.
    <br /><br />
    e. Costos: Importe a cubrir por el cliente, por la prestación del servicio
    de venta y movilidad de los bienes comercializados por el GRUPO INTECA. El
    costo incluye el monto del bien vendido por empresas que formen parte del
    GRUPO INTECA, el traslado de bien del punto en que se encuentre a la
    localidad señalada por el cliente, y los gastos administrativos y logísticos
    que se generen propios de dichas funciones.
    <br /><br />
    f. Cuenta: Perfil personal único e individualizado de una persona que
    utilizó, utiliza o utilizará la Plataforma Tecnológica, el cual contiene
    información personal y podrá contener información sensible del cliente, la
    cual se compone por un usuario, en la mayoría de los casos asociado a una
    contraseña.
    <br /><br />
    g. GRUPO INTECA: Conjunto de empresas que se encuentra conformado por la
    sociedad Mercadeo de Consumo MERCONSA, Sociedad Anónima cédula jurídica
    número 3-101-302123, la sociedad Mercadeo de Artículos de Consumo, Sociedad
    Anónima cédula jurídica número 3-101-137584, entre otras sociedades
    vinculadas con esta dirigidas a prestar servicios conexos, complementarios y
    afines a la comercialización y distribución de consumo masivo.
    <br /><br />
    h. Plataforma Tecnológica: Sistema compuesto de aplicaciones móviles,
    páginas web, contenido, productos y servicios elaborado con tipos de
    arquitectura, sistema operativo, lenguaje de programación o interfaz de
    usuario compatibles, que sirve como base para hacer funcionar determinados
    módulos de hardware o de software que permite que los clientes del Club
    puedan realizar la selección y compra de los productos comercializados por
    empresas que formen parte del GRUPO INTECA, y que el GRUPO INTECA pueda
    realizar la facturación y entrega de los productos seleccionados a los
    clientes del Club. Dicho sistema está definido por un estándar alrededor del
    cual se determina una arquitectura de hardware y una plataforma de software
    (incluyendo entornos de aplicaciones).

    <div class="title1">
      2. Aceptación de Afiliación al Club de Colaboración para la
      Autosatisfacción de una Necesidad de Consumo (“Club”)
    </div>

    Usted acepta formar parte del Club con la aceptación de las Condiciones, y,
    usted manifiesta en este acto haberlos leído y los acepta expresamente.
    Estos términos y condiciones podrán ser modificados en cualquier momento,
    situación que usted expresamente conoce y acepta.
    <br /><br />
    La afiliación al Club es condición esencial para que pueda suscribir los
    presentes términos y condiciones con
    <b>Mercadeo de Consumo MERCONSA, Sociedad Anónima</b>, y con Mercadeo de
    Artículos de Consumo, Sociedad Anónima, que son sociedades anónimas
    constituidas bajo las leyes de la República de Costa Rica, con domicilio
    social en la provincia de San José, 200 metros sur del Motel Paraíso en San
    Francisco de Dos Ríos, inscrita en el Registro de Personas Jurídicas del
    Registro Nacional bajo el número de cédula jurídica 3-101- 302123, y cédula
    jurídica número 3-101-137584, respectivamente, las cuales serán denominadas
    en lo sucesivo como “GRUPO INTECA”. El uso de la Plataforma Tecnológica que
    facilita la comunicación entre los clientes del Club presupone su aceptación
    de (i) la presente afiliación al Club (ii) así como los presentes Términos y
    Condiciones con GRUPO INTECA que se detallan a continuación. <br /><br />
    Una vez que su solicitud de afiliación haya sido aprobada, el acceso y uso
    que su persona haga de la Plataforma Tecnológica constituye su
    consentimiento expreso de apegarse a estas Condiciones.

    <div class="title1">3. Relación</div>

    Las presentes Condiciones regulan el acceso o uso que usted haga, como
    persona, desde Costa Rica de aplicaciones, páginas web, contenido, productos
    y servicios parte de la Plataforma Tecnológica puestos a disposición por el
    GRUPO INTECA.
    <br /><br />
    LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LA PLATAFORMA
    TECNOLOGICA.
    <br /><br />
    Mediante su acceso y uso de la Plataforma Tecnológica usted acuerda
    vincularse jurídicamente por estas Condiciones, que establecen una relación
    entre usted y el GRUPO INTECA exclusivamente para efectos del uso de la
    Plataforma Tecnológica.
    <br /><br />
    Si usted no acepta estas Condiciones, no podrá acceder o usar la Plataforma
    Tecnológica. Estas Condiciones sustituyen expresamente los acuerdos o
    compromisos previos con usted. El GRUPO INTECA podrá poner fin de inmediato
    a estas Condiciones o cualquiera de los clientes asociados a la Plataforma
    Tecnológica respecto de usted o, en general, dejar de ofrecer o denegar el
    acceso a la Plataforma Tecnológica o cualquier parte de ella, en cualquier
    momento y por cualquier motivo. Se podrán aplicar condiciones especiales o
    suplementarias a determinadas facilidades suministradas por la Plataforma
    Tecnológica, como políticas para un evento, una actividad o una promoción
    particular, y dichas condiciones especiales o suplementarias se le
    comunicarán en relación con las facilidades aplicables a la Plataforma
    Tecnológica. Las condiciones suplementarias y/o especiales se establecen
    además de las Condiciones, y se considerarán una parte de éstas, para los
    fines de la Plataforma Tecnológica. Las condiciones suplementarias y/o
    especiales prevalecerán sobre las Condiciones en el caso de conflicto con
    respecto a la Plataforma Tecnológica aplicables.
    <br /><br /><br />
    GRUPO INTECA podrá modificar las Condiciones relativas a la Plataforma
    Tecnológica cuando lo considere oportuno. Las modificaciones serán efectivas
    después de la publicación por parte de GRUPO INTECA de dichas Condiciones
    actualizadas o las políticas modificadas o condiciones suplementarias
    aplicables, publicación que se realizará por medio de una ventana emergente
    o “pop up” que contendrá un hipervínculo que lo redirigirá al enlace que
    contiene el documento con las Condiciones actualizadas, y, a su vez una
    casilla en la cual se indicará que en caso de marcarse, se entiende que el
    cliente leyó las Condiciones actualizadas, las entendió y las acepta.
    <br /><br />
    Su acceso o uso continuado de la Plataforma Tecnológica después de dicha
    publicación constituye su consentimiento expreso a vincularse por las
    Condiciones y sus modificaciones. La recopilación y el uso que hacemos de la
    información personal y sensible que llegue a integrar la base de datos del
    GRUPO INTECA en relación con la Plataforma Tecnológica tendrá como único fin
    la prestación del servicio objeto de las presente Condiciones, por lo que el
    GRUPO INTECA garantizará que el uso de ésta se realizará para tales fines.
    <br /><br />
    El GRUPO INTECA podrá facilitar a una autoridad judicial o administrativa,
    tramitador de reclamaciones, tercero autorizado o a una aseguradora
    cualquier información necesaria (incluida su información sensible) si
    hubiera quejas, disputas o conflictos, que pudieran incluir un evento,
    implicándole a usted y a un tercero y dicha información o dichos datos
    fueran necesarios para resolver la queja, la disputa o conflicto.

    <div class="title1">4. La Plataforma Tecnológica</div>

    La Plataforma Tecnológica constituye un desarrollo de tecnología que permite
    a quienes formen parte del Club y acepten estas Condiciones, beneficiarse
    del uso de ésta a través de teléfonos móviles inteligentes o páginas web
    proporcionadas como parte de la Plataforma Tecnológica para organizar y
    planear la satisfacción de una necesidad de consumo bienes y logística entre
    individuos clientes del Club y el GRUPO INTECA.
    <br /><br />USTED RECONOCE QUE EL GRUPO INTECA NO PRESTA SERVICIOS DE
    TRANSPORTE O DE LOGÍSTICA NI FUNCIONA COMO UNA EMPRESA DE TRANSPORTES.
    <br /><br />4.1 Licencia <br /><br />Sujeto al cumplimiento de estas
    Condiciones, el GRUPO INTECA le otorga una licencia limitada, no exclusiva,
    no sub licenciable, revocable, no transferible para: <br /><br />(i) el
    acceso y uso de la Plataforma Tecnológica en su dispositivo personal sólo en
    relación con su uso; y, (ii) el acceso y uso de cualquier contenido,
    información y material relacionado que pueda ponerse a disposición a través
    de la Plataforma Tecnológica, en cada caso sólo para su uso personal, no
    comercial. <br /><br />El GRUPO INTECA y los otorgantes de licencia del
    GRUPO INTECA se reservan cualquier derecho que no haya sido expresamente
    otorgado por las presentes Condiciones. <br /><br />4.2 Restricciones
    <br /><br />Usted no podrá: <br /><br />(i) retirar cualquier nota de
    derechos de autor, marca registrada u otra nota de propiedad de cualquier
    parte de la Plataforma Tecnológica; (ii) reproducir, modificar, preparar
    obras derivadas sobre la Plataforma Tecnológica, o distribuir, licenciar,
    arrendar, revender, transferir, exhibir públicamente, presentar
    públicamente, transmitir, retransmitir o explotar de otra forma la
    Plataforma Tecnológica , excepto como se permita expresamente por parte del
    GRUPO INTECA; (iii) descompilar, realizar ingeniería inversa o desmontar la
    Plataforma Tecnológica; (iv) enlazar, reflejar o enmarcar cualquier parte de
    la Plataforma Tecnológica; (v) causar o lanzar cualquier programa o script
    con el objeto de extraer, indexar, analizar o de otro modo realizar
    prospección de datos de cualquier parte de la Plataforma Tecnológica o
    sobrecargar o bloquear indebidamente la operación y/o funcionalidad de
    cualquier aspecto de la Plataforma Tecnológica; o (vi) intentar obtener un
    acceso no autorizado o dañar cualquier aspecto de la Plataforma Tecnológica
    o sus sistemas o redes relacionados. <br /><br />4.3 Prestación de la
    Plataforma Tecnológica <br /><br />Usted reconoce que partes de la
    Plataforma Tecnológica podrán ponerse a disposición bajo varias marcas u
    opciones de las empresas que conforman el GRUPO INTECA asociadas con
    colaboración para venta de bienes o servicios logísticos. Asimismo, usted
    reconoce que la Plataforma Tecnológica podrá ponerse a disposición bajo
    diversas marcas u opciones de pedidos por o en relación con: (i) ciertas
    subsidiarias o afiliados del GRUPO INTECA; o (ii) Terceros proveedores
    independientes, incluidos Colaboradores de empresas de red de transportes,
    titulares del permiso de carta de transporte o titulares de permisos,
    autorizaciones o licencias de transporte similares. <br /><br />4.4
    Contenido de Terceros <br /><br />La Plataforma Tecnológica podrá ponerse a
    disposición o ser accesible en relación con servicios, bienes y contenido de
    terceros (incluida la publicidad) que el GRUPO INTECA no controle.
    <br /><br />Usted reconoce que podrán ser de aplicación diferentes
    condiciones y políticas de privacidad al uso que haga de dichos servicios,
    bienes y contenido de terceros. El GRUPO INTECA no respalda dichos
    servicios, bienes y contenido de terceros y en ningún caso las empresas que
    formen parte del GRUPO INTECA serán responsables de cualquier producto o
    servicio de dichos terceros proveedores. <br /><br />Adicionalmente, Apple
    Inc., Google, Inc., Microsoft Corporation o BlackBerry Limited y/o sus
    correspondientes subsidiarias o afiliados internacionales serán terceros
    beneficiarios en este contrato si usted accede a la Plataforma Tecnológica
    utilizando aplicaciones desarrolladas para dispositivos móviles con sistema
    iOS, Android, Microsoft Windows, respectivamente. Estos terceros
    beneficiarios no son partes de este contrato y no son responsables de la
    prestación o apoyo de la Plataforma Tecnológica de ninguna manera.
    <br /><br />Su acceso a la Plataforma Tecnológica utilizando estos
    dispositivos se sujeta a las condiciones establecidas en las condiciones de
    servicio de terceros beneficiarios aplicables. <br /><br />4.5 Titularidad
    <br /><br />La titularidad de la Plataforma Tecnológica y todos los derechos
    relativos a ésta son y permanecerán en propiedad del GRUPO INTECA o de los
    otorgantes de licencia del GRUPO INTECA. <br /><br />Ninguna de estas
    Condiciones ni el uso de la Plataforma Tecnológica le transfieren u otorgan
    ningún derecho: <br /><br />(i) sobre o en relación con la Plataforma
    Tecnológica; o bien (ii) a utilizar o mencionar en cualquier modo a los
    nombres de las empresas que conforman el GRUPO INTECA, logotipos, nombres de
    producto y facilidades, marcas comerciales o marcas de servicio del GRUPO
    INTECA o de los otorgantes de licencia del GRUPO INTECA.

    <div class="title1">5. Su uso de la Plataforma Tecnológica</div>

    5.1 Cuentas de usuario
    <br /><br />Con el fin de usar la mayor parte de los aspectos de la
    Plataforma Tecnológica usted debe registrarse y mantener activa una cuenta
    personal de asociado a la Plataforma Tecnológica (“Cuenta”). Para obtener
    una Cuenta debe tener como mínimo 15 años, salvo que el cliente pretenda la
    adquisición de bebidas con contenido alcohólico y/o cigarrillos para lo cual
    deberá ser mayor de 18 años, condición que deberá ser validada por el
    cliente al momento de seleccionar tales productos, al marcar en la ventana
    emergente o “pop up” que es mayor de 18 años, y que ulteriormente será
    verificada al momento del recibo por parte del cliente de los productos
    según el tipo de bien a ser entregado por el personal enviado por el GRUPO
    INTECA. <br /><br />El registro de la Cuenta requiere que comunique al GRUPO
    INTECA determinada información personal y en algunos casos sensible, como su
    nombre, dirección, número de teléfono móvil, así como por lo menos un método
    de pago válido (bien una tarjeta de crédito o bien un medio de pago
    aceptado). Usted se compromete a mantener la información en su Cuenta de
    forma exacta, veraz, completa y actualizada. <br /><br />Si no mantiene la
    información de su Cuenta de forma exacta, veraz, completa y actualizada, o
    el hecho de mantener registrado un método de pago inválido o vencido, podría
    resultar en su imposibilidad de beneficiarse de la Plataforma Tecnológica
    sin responsabilidad alguna por parte de GRUPO INTECA. Adicionalmente, si el
    cliente realiza un uso y/o goce abusivo de la Plataforma Tecnológica, por lo
    que el GRUPO INTECA se reserva el derecho de retener o deducir el crédito u
    otros elementos o beneficios obtenidos a través de la utilización de la
    Plataforma Tecnológica, dar de baja al cliente, suspender o restringir el
    acceso o uso de la Plataforma Tecnológica por usted o cualquier otro
    afiliado al Club en el caso de que GRUPO INTECA determine o crea que el uso
    o goce de la Plataforma Tecnológica, o de los beneficios, prerrogativas,
    elementos, o derechos fue empleado de modo erróneo, fraudulento, ilegal o
    infringiendo las presentes Condiciones, o la legislación aplicable a la
    materia. <br /><br />Cada cliente utilizará de forma personal su Cuenta,
    siendo responsable de toda la actividad que ocurre en su Cuenta y se obliga
    a mantener en todo momento de forma segura y secreta el nombre de usuario y
    la contraseña de su Cuenta. A menos que el GRUPO INTECA permita otra cosa
    por escrito, usted solo puede poseer una Cuenta. <br /><br />5.2 Requisitos
    y conducta del usuario <br /><br />La Plataforma Tecnológica no está
    disponible para el uso de personas menores de 15 años. Usted no podrá
    autorizar a terceros a utilizar su Cuenta, asimismo no podrá permitir a
    personas menores de 15 años se beneficien de la Plataforma Tecnológica para
    satisfacer de una necesidad de consumo o logística, a menos que aquellos
    sean acompañados por usted, salvo en el caso de bebidas alcohólicas o
    cigarrillos, en cuyo caso las personas menores de 18 no podrán tener acceso
    a dichos bienes bajo ningún supuesto o modalidad de prestación del servicio.
    El cliente no podrá ceder o transferir de otro modo su Cuenta a cualquier
    otra persona o entidad. <br /><br />Usted se obliga a cumplir con todas las
    leyes aplicables al utilizar la Plataforma Tecnológica y solo podrá utilizar
    la Plataforma Tecnológica con fines legítimos (p. ej., no para el transporte
    de materiales ilegales o peligrosos). En el uso de la Plataforma Tecnológica
    procurará no causar estorbos, molestias, incomodidades o daños a la
    propiedad, tanto a otro individuo afiliado al Club como a cualquier otra
    parte. <br /><br />En algunos casos, se le podrá requerir que facilite un
    documento de identidad u otro elemento de verificación de identidad para el
    acceso o uso de la Plataforma Tecnológica, y usted acepta que se le podrá
    denegar el acceso o uso de la Plataforma Tecnológica si se niega a facilitar
    el documento de identidad o el elemento de verificación de identidad.
    <br /><br />5.3 Mensajes de texto <br /><br />Al crear una Cuenta, usted
    acepta que información relacionada con la Plataforma Tecnológica le sea
    enviada a través de mensajes de texto informativos (SMS) como parte de la
    actividad normal de su uso de la Plataforma Tecnológica. Usted podrá
    solicitar la no recepción de mensajes de texto informativos (SMS) del GRUPO
    INTECA en cualquier momento enviando un correo electrónico a
    “servicio_al_cliente&#64;grupointeca.com” indicando que no desea recibir más
    dichos mensajes, junto con el número de teléfono del dispositivo móvil que
    recibe los mensajes. <br /><br />Usted reconoce que solicitar la no
    recepción de mensajes de texto informativos (SMS) podrá afectar la calidad y
    alcances de uso que usted haga de la Plataforma Tecnológica.

    <br /><br />5.4 Códigos promocionales <br /><br />El GRUPO INTECA podrá, a
    su sola discreción, crear códigos promocionales que podrán ser canjeados por
    crédito de Cuenta u otros elementos o beneficios relacionados con la
    Plataforma Tecnológica, con sujeción a cualquier condición adicional que
    GRUPO INTECA establezca sobre la base de cada código promocional.
    <br /><br />Usted acuerda que los Códigos promocionales: <br /><br />(i)
    deben usarse para la audiencia y el propósito deseado, y de manera lícita;
    (ii) no podrán duplicarse, venderse o transferirse de ninguna manera, o
    ponerse a disposición del público general (tanto si se publican en un foro
    público como de otra forma), a menos que sea con el permiso del GRUPO
    INTECA; (iii) podrán ser invalidados por el GRUPO INTECA en cualquier
    momento por cualquier motivo sin responsabilidad para el GRUPO INTECA; (iv)
    podrán usarse solo conforme a las condiciones específicas que el GRUPO
    INTECA establezca para dicho Código promocional; (v) no son válidos como
    efectivo; y (vi) podrán caducar antes de que usted los utilice.
    <br /><br />El GRUPO INTECA se reserva el derecho de retener o deducir el
    crédito u otros elementos o beneficios obtenidos a través de la utilización
    de los Códigos promocionales por usted o cualquier otro afiliado al Club en
    el caso de que el GRUPO INTECA determine o crea que el uso o el canje de los
    Códigos promocionales fue de modo erróneo, fraudulento, ilegal o
    infringiendo las condiciones del Código promocional o las presentes
    Condiciones. <br /><br />5.5 Contenido proporcionado por el cliente
    <br /><br />Todo Contenido de Cliente facilitado por usted seguirá siendo de
    su propiedad. No obstante, al proporcionar Contenido de Cliente, usted
    otorga una licencia mundial, perpetua, irrevocable, transferible, libre de
    regalías, con derecho a sub-licenciar, usar, copiar, modificar, crear obras
    derivadas, distribuir, exhibir públicamente, presentar públicamente o de
    otro modo explotar de cualquier manera dicho Contenido de Cliente en todos
    los formatos y canales de distribución, conocidos ahora o ideados en un
    futuro (incluidos en relación con la Plataforma Tecnológica y el negocio del
    GRUPO INTECA y en sitios y servicios de terceros), sin más aviso o
    consentimiento de usted y sin requerirse el pago a usted o a cualquier otra
    persona o entidad. <br /><br />Usted declara y garantiza que:
    <br /><br />(i) es el único y exclusivo propietario de todo el Contenido de
    Cliente o que tiene todos los derechos, licencias, consentimientos y
    permisos necesarios para otorgar al GRUPO INTECA la licencia al Contenido de
    Cliente como establecido anteriormente; y (ii) ni el Contenido de Cliente ni
    su presentación, carga, publicación o puesta a disposición de otro modo de
    dicho Contenido de Cliente, ni el uso por parte del GRUPO INTECA del
    Contenido de Cliente como está aquí permitido, infringirán, malversarán o
    violarán la propiedad intelectual o los derechos de propiedad de un tercero
    o los derechos de publicidad o privacidad o resultarán en la violación de
    cualquier ley o reglamento aplicable; y (iii) es el único y exclusivo
    responsable de todo el Contenido de Cliente que presente, cargue, publique o
    ponga a disposición del GRUPO INTECA, por lo que se obliga a indemnizar al
    GRUPO INTECA bajo cualquier supuesto, por los daños y perjuicios que ésta
    pueda llegar a erogar a causa del uso del Contenido de Cliente suministrado
    por usted. <br /><br />Usted acuerda no proporcionar Contenido que sea
    difamatorio, calumnioso, odioso, violento, obsceno, pornográfico, ilícito o
    de otro modo ofensivo, como determine el GRUPO INTECA, a su sola discreción,
    tanto si dicho material pueda estar protegido o no por la ley.
    <br /><br />El GRUPO INTECA podrá, a su sola discreción y en cualquier
    momento y por cualquier motivo, sin avisarle previamente, revisar, controlar
    o eliminar Contenido de usuario, pero sin estar obligada a ello.
    <br /><br />5.6 Acceso a la red y dispositivos <br /><br />Usted es
    responsable de obtener el acceso a la red de datos necesario para la
    Plataforma Tecnológica. Podrán aplicarse las tarifas y tasas de datos y
    mensajes de la red móvil si usted accede o utiliza la Plataforma Tecnológica
    desde un dispositivo inalámbrico y usted será responsable de dichas tarifas
    y tasas. Usted es responsable de adquirir y actualizar el hardware
    compatible o los dispositivos necesarios para acceder y utilizar la
    Plataforma Tecnológica y aplicaciones y cualquier actualización de estos. El
    GRUPO INTECA no garantiza que la Plataforma Tecnológica, o cualquier parte
    de estos, funcionen en cualquier hardware o dispositivo particular.
    <br /><br />Además, los la Plataforma Tecnológica podrán ser objeto de
    disfunciones o retrasos inherentes al uso de Internet y de las
    comunicaciones electrónicas.

    <div class="title1">
      6. Costos de colaboración, y cancelación de orden y devoluciones
    </div>

    6.1. Costos de Colaboración
    <br /><br />Usted entiende que el uso de la Plataforma Tecnológica puede
    derivar en costos por concepto de la colaboración en la autosatisfacción de
    una necesidad de consumo en la que participe con el GRUPO INTECA. Después de
    que haya satisfecho su necesidad de consumo a través del GRUPO INTECA, ésta
    facilitará la recolección de los Costos. <br /><br />La recolección de los
    Costos de dicha manera se considerará como el pago efectuado directamente
    por usted a GRUPO INTECA. Los Costos incluirán los impuestos aplicables
    cuando se requiera por ley. Los Costos pagados por usted son definitivos y
    no reembolsables, a menos que GRUPO INTECA determine lo contrario.
    <br /><br />Los Costos pueden incluir otras cuotas, peajes y/o recargos
    aplicables, incluyendo cuotas por solicitud, cuotas o cargos municipales,
    estatales o nacionales, cargos por demoras, tarifas de procesamiento por
    pagos fraccionados, entre otros que sean aplicables. <br /><br />Todos los
    Costos son pagaderos inmediatamente y el pago se facilitará por el GRUPO
    INTECA utilizando al método de pago preferido indicado en su Cuenta, y
    después de ello GRUPO INTECA le enviará un recibo por correo electrónico.
    <br /><br />Si se determina que el método de pago de su Cuenta principal ha
    caducado, es inválido o de otro modo no sirve para rebajar la compensación
    por los Costos, usted acepta que el GRUPO INTECA, como agente de cobro
    utilice un método de pago secundario en su Cuenta, si estuviera disponible.
    <br /><br />El GRUPO INTECA, en cualquier momento y a su sola discreción, se
    reserva el derecho de establecer, eliminar y/o revisar los Costos para
    alguno o todos los componentes de la Plataforma Tecnológica obtenidos a
    través del uso de esta. <br /><br />Además, usted reconoce y acepta que los
    Costos aplicables en determinadas zonas geográficas y horarios de entrega,
    podrán incrementar sustancialmente durante los periodos de alta demanda o
    por encontrarse el cliente en una zona alejada. <br /><br />El GRUPO INTECA
    usará los esfuerzos razonables para informarle de los Costos de Colaboración
    que podrán aplicarse, siempre y cuando usted sea responsable de los Costos
    incurridos en su Cuenta, independientemente de que usted conozca dichos
    Costos o los importes de estos. <br /><br />El GRUPO INTECA podrá, cuando lo
    considere oportuno, proporcionar a determinados miembros del Club ofertas
    promocionales y descuentos que pueden resultar en el cobro de diferentes
    importes por beneficios obtenidos a través del uso de la Plataforma
    Tecnológica, y usted acepta que dichas ofertas promocionales y descuentos, a
    menos que también se pongan a su disposición, no se tendrán en cuenta en el
    uso por su parte de la Plataforma Tecnológica o los Costos de Colaboración
    aplicados a usted. <br /><br />Esta estructura de compensación está
    destinada para compensar plenamente al GRUPO INTECA por los costos generados
    por la prestación del servicio dirigido a la satisfacción de una necesidad
    de consumo de un cliente del Club. El GRUPO INTECA no proporciona importes
    adicionales al cliente, aparte de los costos descritos anteriormente
    <br /><br />Las gratificaciones son voluntarias. <br /><br />Después de que
    haya satisfecho su necesidad de consumo, a través de la colaboración por
    parte de GRUPO INTECA, tendrá la oportunidad de calificar su experiencia y
    dejar comentarios adicionales sobre el servicio prestado. <br /><br />6.2.
    Costos por cancelación de orden <br /><br />Usted es responsable de cubrir
    con su propio peculio los costos correspondientes que sean determinados por
    el GRUPO INTECA, en caso de que proceda a cancelar una orden que haya
    colocado. En caso que la cancelación de la orden se haga en un periodo
    inferior a 60 minutos desde el momento en que la Plataforma Tecnológica
    procesa la orden, el GRUPO INTECA cancelará la misma sin ningún costo para
    el cliente, vencido ese plazo el GRUPO INTECA si la cancelación se da
    después de sesenta minutos, se le cargará un monto de un dólar moneda de los
    Estados Unidos de América por dicha cancelación; monto que podrá sufrir
    modificaciones determinado los siguientes elementos para el cálculo: i)
    monto de la orden cancelada, ii) cantidad de producto que contiene la orden
    cancelada, iii) ubicación del cliente establecida como punto de entrega de
    la orden cancelada, y, iv) estado de proceso interno en que se encuentre la
    orden cancelada. En caso de cancelarse la orden donde se utilice como medio
    de pago una tarjeta de crédito o débito, los costos de cancelación serán
    cargados a la tarjeta indicada como medio de pago; y, para el caso en que se
    señale como medio de pago la cancelación en efectivo contra entrega, se
    generará una cuenta por pagar a cargo del cliente y en beneficio del GRUPO
    INTECA, que será sumada a la siguiente facturación que se genere por la
    colocación de una nueva orden por parte del cliente. En caso de que el
    cliente no tenga fondos suficientes, o se niegue a cancelar el monto
    correspondiente a la cancelación de una orden, dará pie para que el GRUPO
    INTECA a su entera disposición pueda dar de baja al cliente, suspender o
    restringir el uso de la Plataforma Tecnológica, sin detrimento ni renuncia
    del ejercicio de las acciones correspondientes por la vía legal
    correspondiente para reclamar el pago de los montos no cubiertos por el
    cliente. Restricciones <br /><br />Usted manifiesta conocer y aceptar que la
    autosatisfacción de su necesidad de consumo se encuentra limitada por la
    posibilidad del GRUPO INTECA de satisfacer dicha necesidad la cual se
    encuentra restringida por tres factores, siendo estos: <br /><br />a) Zona
    geográfica: El GRUPO INTECA prestará el servicio objeto que se contempla en
    la Plataforma Tecnológica en las zonas contempladas dentro del perímetro de
    bolsas establecidas en el Plan de Servicios del GRUPO INTECA. <br /><br />b)
    Horario: El GRUPO INTECA mantendrá activo el servicio de entrega que se
    contempla en la Plataforma Tecnológica, en un horario de lunes a sábado de
    las 06:30 horas a las 17:00 horas. <br /><br />c) Cantidad de producto: Para
    los casos de pedidos que excedan la cantidad del producto que hay en
    inventario, o la capacidad de carga de la unidad de entrega, el GRUPO INTECA
    coordinará con el cliente el tiempo de entrega de la mercadería.
    <br /><br />6.3. Devoluciones <br /><br />Usted manifiesta conocer y aceptar
    que los productos que adquiera para la autosatisfacción de su necesidad de
    consumo pueden ser devueltos en caso de que el producto presente problemas
    de calidad, cantidad, empacado, inocuidad, sensibilidad u organolepsia.
    Usted únicamente podrá realizar devoluciones por problemas de calidad,
    cantidad o empacado al momento de recibo de la orden colocada en la
    Plataforma Tecnológica, por lo cual manifiesta conocer, entender y aceptar
    que no podrá devolver producto por dichos problemas luego de recibida la
    orden. En caso de que la devolución sea por problemas de inocuidad,
    sensibilidad u organolepsia, podrá solicitar la devolución del producto que
    forme parte de una orden de compra colocada a través de la Plataforma
    Tecnológica durante un periodo de ocho días naturales máximo, por lo cual
    manifiesta conocer, entender y aceptar que no podrá devolver producto por
    dichos problemas una vez superado dicho plazo. <br /><br />Las solicitudes
    de devolución deberá realizarlas dentro de los plazos indicados en el
    párrafo anterior al correo electrónico servicio_al_cliente&#64;grupointeca.com,
    las cuales serán tramitadas de conformidad con el procedimiento interno del
    GRUPO INTECA, y en cumplimiento a la normativa de protección al consumidor
    que contempla el ordenamiento jurídico costarricense.

    <div class="title1">
      7. Renuncias; Limitación de responsabilidad; Indemnidad.
    </div>

    RENUNCIA.
    <br /><br />LOS PLATAFORMA TECNOLOGICA SE PROPORCIONA “TAL CUAL” Y “COMO
    DISPONIBLES”. EL GRUPO INTECA RENUNCIA A TODA DECLARACIÓN Y GARANTÍA,
    EXPRESA, IMPLÍCITA O ESTATUTARIA, NO EXPRESAMENTE ESTABLECIDA EN ESTAS
    CONDICIONES, INCLUIDAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD,
    IDONEIDAD PARA UN FIN PARTICULAR Y NO VIOLACIÓN. <br /><br />ADEMÁS, EL
    GRUPO INTECA NO HACE DECLARACIÓN NI PRESTA GARANTÍA ALGUNA RELATIVA A LA
    FIABILIDAD, PUNTUALIDAD, CALIDAD, IDONEIDAD O DISPONIBILIDAD DE LA
    PRESTACIÓN DEL SERVICIO A TRAVÉS DEL USO DE LA PLATAFORMA TECNOLÓGICA, O QUE
    LA FUNCIONALIDAD DE LA PLATAFORMA TECNOLÓGICA NO SERÁ INTERRUMPIDAS O SEA
    LIBRES DE ERRORES. <br /><br />EL GRUPO INTECA NO GARANTIZA LA CALIDAD,
    IDONEIDAD, SEGURIDAD O HABILIDAD DE LOS TRANSPORTISTAS. <br /><br />USTED
    ACUERDA QUE TODO RIESGO DERIVADO DEL USO QUE HAGA DE LA PLATAFORMA
    TECNOLÓGICA Y CUALQUIER BIEN O SERVICIO SOLICITADO EN RELACIÓN CON AQUELLA
    SERÁ ÚNICAMENTE SUYO, EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY APLICABLE.
    <br /><br />LIMITACIÓN DE RESPONSABILIDAD. <br /><br />EL GRUPO INTECA NO
    SERÁ RESPONSABLE DE DAÑOS INDIRECTOS, INCIDENTALES, ESPECIALES, EJEMPLARES,
    PUNITIVOS O EMERGENTES, INCLUIDOS EL LUCRO CESANTE, LA PÉRDIDA DE DATOS, LA
    LESIÓN PERSONAL O EL DAÑO A LA PROPIEDAD RELATIVOS, O EN RELACIÓN CON, O DE
    OTRO MODO DERIVADOS DE CUALQUIER USO DE LA PLATAFORMA TECNOLÓGICA, INCLUSO
    AUNQUE EL GRUPO INTECA HAYA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS
    DAÑOS. <br /><br />EL GRUPO INTECA NO SERÁ RESPONSABLE DE CUALQUIER DAÑO,
    RESPONSABILIDAD O PÉRDIDA QUE DERIVEN DE: <br /><br />(I) EL USO O
    DEPENDENCIA DE LA PLATAFORMA TECNOLÓGICA O SU INCAPACIDAD PARA ACCEDER O
    UTILIZAR LA PLATAFORMA TECNOLÓGICA; O (ii) CUALQUIER TRANSACCIÓN O RELACIÓN
    ENTRE USTED Y CUALQUIER CLIENTE COLABORADOR, AUNQUE EL GRUPO INTECA HUBIERA
    SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS. <br /><br />EL GRUPO
    INTECA NO SERÁ RESPONSABLE DEL RETRASO O DE LA FALTA DE EJECUCIÓN RESULTANTE
    DE CAUSAS QUE VAYAN MÁS ALLÁ DEL CONTROL RAZONABLE DE EL GRUPO INTECA.USTED
    RECONOCE QUE LOS TRANSPORTISTAS Y PERSONAL DE APOYO QUE EJECUTEN LABORES EN
    LA SATISFACCIÓN DE LA NECESIDAD DE CONSUMO DE USTED COMO CLIENTE EN
    PARTICULAR LO HACEN EN CUMPLIMIENTO DE SUS LABORES PROPIAS. <br /><br />EN
    NINGÚN CASO LA RESPONSABILIDAD TOTAL DE EL GRUPO INTECA HACIA USTED EN
    RELACIÓN CON LA PLATAFORMA TECNOLÓGICA POR TODOS LOS DAÑOS, LAS PÉRDIDAS Y
    LOS JUICIOS PODRÁ EXCEDER DE QUINIENTOS DÓLARES (USD$ 500).

    <br /><br /><br />USTED PODRÁ UTILIZAR LA PLATAFORMA TECNOLÓGICA DEL CLUB
    PARA SOLICITAR UNA COLABORACIÓN PARA SATISFACER SUS NECESIDADES DE CONSUMO,
    PERO ACEPTA QUE EL GRUPO INTECA NO TIENE RESPONSABILIDAD ALGUNA HACIA USTED,
    SALVO LA QUE LEGALMENTE LE CORRESPONDE.

    <br /><br /><br />LAS LIMITACIONES Y LA RENUNCIA EN ESTE DOCUMENTO NO
    PRETENDEN LIMITAR LA RESPONSABILIDAD O ALTERAR SUS DERECHOS COMO CONSUMIDOR
    QUE NO PUEDAN EXCLUIRSE SEGÚN LA LEY APLICABLE.

    <div class="title1">8. Indemnidad.</div>
    Usted acuerda mantener indemnes y responder frente al GRUPO INTECA y sus
    consejeros, directores, empleados y agentes, empresas y sociedades afiliadas
    o relacionadas, por cualquier reclamación, demanda, pérdida, responsabilidad
    y gasto (incluidos los honorarios de abogados) que deriven de: (i) su uso de
    la Plataforma Tecnológica ; (ii) su incumplimiento o violación de cualquiera
    de estas Condiciones; (iii) el uso por parte del GRUPO INTECA de su
    Contenido de usuario; o (iv) su infracción de los derechos de cualquier otro
    individuo, incluido el personal que ejecutará las labores administrativas,
    logísticas y de transporte para la satisfacción de su necesidad de consumo.

    <div class="title1">9. Notificaciones.</div>
    El GRUPO INTECA podrá notificar por medio de una notificación o publicación
    en general en la Plataforma Tecnológica, mediante un correo electrónico
    enviado a su dirección electrónica en su Cuenta o por comunicación escrita
    enviada a su dirección, según lo dispuesto en su Cuenta. Usted podrá
    notificar a el GRUPO INTECA por comunicación escrita a su domicilio social.

    <div class="title1">10. Disposiciones generales.</div>
    No podrá ceder ni transferir estas Condiciones, en todo o en parte, sin el
    consentimiento previo por escrito del GRUPO INTECA.
    <br /><br />Usted da su aprobación al GRUPO INTECA para ceder o transferir
    estas Condiciones, en todo o en parte, sin necesidad de autorización previa,
    incluido a: <br /><br />(i) una subsidiaria o un afiliado; (ii) un
    adquirente del capital, del negocio o de los activos del GRUPO INTECA; o
    (iii) un sucesor por fusión. <br /><br />No existe entre usted, el GRUPO
    INTECA o una empresa conjunta o relación de clientes, empleo o agencia como
    resultado del contrato entre usted y el GRUPO INTECA o del uso de la
    Plataforma Tecnológica. <br /><br />Si cualquier disposición de estas
    Condiciones se considerara ilegal, nula o inexigible, ya sea en su totalidad
    o en parte, de conformidad con cualquier legislación, dicha disposición o
    parte de esta se considerará que no forma parte de estas Condiciones, aunque
    la legalidad, validez y exigibilidad del resto de las disposiciones de estas
    Condiciones no se verá afectada. <br /><br />En ese caso, el GRUPO INTECA
    buscará reemplazar dicha disposición ilegal, nula o inexigible, en todo o en
    parte por una disposición legal, válida y exigible que tenga, en la medida
    de lo posible, un efecto similar al que tenía la disposición ilegal, nula o
    inexigible, dados los contenidos y el propósito de estas Condiciones.
    <br /><br />Estas Condiciones constituyen el contrato íntegro y el
    entendimiento entre las partes en relación con el objeto y sustituye y
    reemplaza a todos los contratos o acuerdos anteriores o contemporáneos en
    relación con dicho objeto. En estas Condiciones, las palabras
    “incluido/a/os/as” e “incluye/n” significan “incluido, de forma meramente
    enunciativa”.
  </div>
</ion-content>
