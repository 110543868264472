import { Component } from '@angular/core';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import { NewDirectionComponent } from 'src/app/components/new-direction/new-direction.component';
import { Direction } from './../../businessCore/Direction';

@Component({
  selector: 'app-get-position',
  templateUrl: './get-position.component.html',
  styleUrls: ['./get-position.component.scss'],
})
export class GetPositionPage {
  lat: number;
  lng: number;

  direction_data: Direction;
  callback: any;
  mymap: Leaflet.Map;
  marker: Leaflet.Marker;
  showActualPosBtn: boolean = false;

  constructor(
    public nav: NavController,
    public navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {
    this.direction_data = new Direction();
    this.direction_data = this.navParams.get('data');

    this.lat = this.direction_data.latitude;
    this.lng = this.direction_data.longitude;

    this.callback = this.navParams.get('callback');

    setTimeout(() => {
      this.showActualPosBtn = true;
    }, 5000);
  }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((location) => {
      //console.log(location);
      let latlng =
        this.direction_data.edit &&
        this.direction_data.latitude &&
        this.direction_data.longitude
          ? new Leaflet.LatLng(
              this.direction_data.latitude,
              this.direction_data.longitude
            )
          : new Leaflet.LatLng(
              location.coords.latitude,
              location.coords.longitude
            );

      this.mymap = Leaflet.map('mapid').setView(latlng, 15);
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://mapbox.com">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox.streets',
      }).addTo(this.mymap);

      this.marker = Leaflet.marker(latlng)
        .setIcon(
          new Leaflet.Icon({
            iconUrl: './assets/leafletImg/marker-icon-2x.png',
          })
        )
        .addTo(this.mymap);

      this.mymap.on('move', (e) => {
        this.moveEvent();
      });
    });
  }

  moveEvent() {
    let latlng = this.mymap.getCenter();
    this.lat = latlng.lat;
    this.lng = latlng.lng;
    this.marker.setLatLng(latlng);
  }

  getPosition() {
    this.direction_data.latitude = this.lat;
    this.direction_data.longitude = this.lng;

    this.presentPopover(this.direction_data);
  }

  async presentPopover(direction: Direction) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: NewDirectionComponent,
      componentProps: { address: direction },
    });
    popover.present();

    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data != null) {
        this.callback(this.direction_data).then(() => {
          this.nav.pop();
        });
      }
    });
  }

  setActualPosition() {
    navigator.geolocation.getCurrentPosition((location) => {
      let latlng = new Leaflet.LatLng(
        location.coords.latitude,
        location.coords.longitude
      );

      this.marker.setLatLng(latlng);

      this.mymap.setView(latlng, 18);
    });
  }
}
