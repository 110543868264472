import { Component } from '@angular/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import {
  AlertController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { AssociatedInstance } from 'src/app/businessCore/AssociatedInstance';
import { CustomerAddress } from 'src/app/businessCore/CustomerAddress';
import { CustomerData } from 'src/app/businessCore/CustomerData';
import { Direction } from 'src/app/businessCore/Direction';
import { GI_Component } from 'src/app/businessCore/GI_Component';
import { RegisterData } from 'src/app/businessCore/RegisterData';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { SelectDeliveryAddressComponent } from 'src/app/components/select-delivery-address/select-delivery-address.component';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { webServiceProvider } from 'src/app/provider/webServiceProvider';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';

@Component({
  selector: 'app-login.page',
  templateUrl: './login.page.component.html',
  styleUrls: ['./login.page.component.scss'],
})
export class LoginPage {
  isActive: any;
  pass: string;
  card_types: any;
  id_card: string;
  c_type: number;
  register_data: RegisterData;
  login_status: boolean = false;
  load_info: boolean = false;
  load_role: boolean = false;
  toUpdateComponents: Array<number> = [3, 2, 9, 6, 7, 12, 4]; //Oculta: 1 y 3; Muestra: 9, 6, 7, 12, 4
  ClienteSeleccionado: string = '';
  ActividadesSeleccionadas: Array<number>;
  clientesSeleccionados: Array<{ Accountnum: string; VatNum: string }>;
  seleccionarCliente: boolean = false;
  // roles: Array<{ Haz: string, clients: Array<{ Accountnum: string, VatNum: string }>, Actividades: Array<number> }> = [];

  validationInProgress: boolean = false; // Control if the login event is in progress

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private _apiProvider: webServiceProvider,
    private toast: ToastController,
    private alert: AlertController,
    public popoverCtrl: PopoverController,
    private storage: StorageService,
    public modalCtrl: ModalController,
    public callNumber: CallNumber,
    private events: GlobalEventsService,
    private navService: NavigationService
  ) {
    this.register_data = new RegisterData();
    this.card_types = this.register_data.get_card_types();

    this.c_type = -1;
  }

  async resetPassword() {
    let modal = await this.modalCtrl.create({
      component: ResetPasswordComponent,
    });
    modal.present();
  }

  /**
   *
   * @param message
   * @param duration
   */
  async presentToast(message, duration) {
    let toast = await this.toast.create({
      message: message,
      duration: duration,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }

  updateCardType(ct) {
    this.c_type = ct;
    this.id_card = '';
  }

  deleteIdCard() {
    this.id_card = '';
  }

  /**
   *
   * @param id_card
   * @param password
   */
  async login(id_card, password) {
    this.storage.set('register_data', null);
    if (password && id_card) {
      this.validationInProgress = true;
      this._apiProvider.login(id_card, password).subscribe(async (data) => {
        if (data && data == 'SUCCESS') {
          // Valida si el usuario se encuentra registrado pero la apertura en proceso
          // sino es asi lo deja continuar con el login
          this._apiProvider
            .getCustomerName(id_card, this.c_type)
            .subscribe(async (data) => {
              if (data) {
                let response = data as { name: string; state: number };

                // El usuario NO cuenta con un registro en proceso
                if (response.state != 3) {
                  this.login_status = true;
                  this.load_info = true;

                  await this.getCustomerInfo(id_card);
                  this.storage.set('password', JSON.stringify(password));
                } else {
                  this.login_status = false;
                  let alert = await this.alert.create({
                    header: 'Registro de cuenta en proceso!',
                    subHeader:
                      'El registro de su cuenta se está realizando en este momento',
                    message: `<p>Para conocer el estado de su solicitud, comuníquese con nosotros.</p>
                    <p>Números</p>
                    <ul>
                      <li>7071-3153.</li>
                      <li>7071-3067</li>
                      <li>7071-3098</li>
                      <li>7071-3089</li>
                    </ul>
                    `,
                    buttons: [
                      {
                        text: 'Ok',
                        role: 'cancel',
                      },
                    ],
                    backdropDismiss: true,
                  });

                  alert.present();
                }
              }
              this.validationInProgress = false;
            });
        } else if (data == 'CREDIT_USER') {
          this.validationInProgress = false;
          this.login_status = false;
          let toast = await this.toast.create({
            message:
              'Esta aplicación por seguridad se encuentra bloqueada para clientes de crédito! En versiones posteriores se habilitará su uso.',
            duration: 3500,
            buttons: ['Ok'],
          });

          toast.present();
        } else {
          this.validationInProgress = false;
          this.login_status = false;
          let toast = await this.toast.create({
            message: 'Login incorrecto',
            duration: 3000,
            buttons: ['Ok'],
          });

          toast.present();
        }
      });
    } else {
      let toast = await this.toast.create({
        message: '¡Debe ingresar ambos campos!',
        duration: 2000,
        buttons: ['Ok'],
      });
      toast.present();
    }
  }

  /**
   * Metodo utilizado para realizar una llamada al agente que tiene asociado el usuario que se encuentra registrado
   * De no existir una sesion activa se llamara a un numero por defecto en la empresa.
   */
  callAgent(PhoneNumber: string) {
    let phoneNumber: string = '+506' + PhoneNumber.replace('-', '');

    this.callNumber.isCallSupported().then((data) => {
      this.callNumber
        .callNumber(phoneNumber, true)
        .then(() => {})
        .catch(() => {});
    });
  }

  /**
   * Gets all the GI_Components(Business definition of Component) allowed to the user.
   *
   * @param id_card Identification number
   */
  async getGIComponents(id_card) {
    let giComponents: any[];
    await this._apiProvider
      .getComponentsByUserId(id_card)
      .then((response: string) => {
        giComponents = JSON.parse(response);
      });
    //const result1 = giComponents.filter((v, i, a) => a.findIndex(t => (t.componentId === v.componentId)) === i);
    //console.log("GIComponents:", result1);
    const result = giComponents.filter(
      (v, i, a) => a.findIndex((t) => t.componentName === v.componentName) === i
    );
    //console.log('GIComponents:', result);
    return result;
  }

  /**
   * Gets all the people(Client, Worker or supplier) related to the user
   *
   * @param id_card Identification number
   */
  async getAssociatedPeople(id_card) {
    //***********************OBTIENE LOS DATOS***********************
    let associatedPeopleTemp: any[];
    await this._apiProvider
      .getAssociatedPersonInfo(id_card)
      .then((response: string) => {
        associatedPeopleTemp = JSON.parse(response);
      });
    //console.log("associatedPeople_1:", associatedPeopleTemp);
    //*********Modifica el valor para que tenga sean listas en lugar de string*********
    associatedPeopleTemp = associatedPeopleTemp.map((e) => {
      e.roleHazId = [e.roleHazId];
      return e;
    });

    //***********************Unifica***********************
    let foundIndex: number; //Esto es para hacer un index.
    const associatedPeople: AssociatedInstance[] = associatedPeopleTemp.reduce(
      (completeAsso, associated) => {
        if (
          completeAsso.some((value, resIdx) => {
            // Se asegura si en el acumulador (completeAsso) ya existe ese asociado
            foundIndex = resIdx;
            return value.vatNum == associated.vatNum;
          })
        ) {
          //Se busca en el acumulador el asociado correspondiente e incorpora el valor de los que son iguales.
          completeAsso[foundIndex].roleHazId.push(...associated.roleHazId);
        } else {
          //Incorpora el valor al final del acumulador, porque es un valor nuevo.
          completeAsso.push(associated);
        }
        return completeAsso;
      },
      []
    );
    //***********************PRUEBA***********************
    //console.log("associatedPeople:", associatedPeople);
    return associatedPeople;
  }

  /**
   *
   * @param id_card Identification number
   */
  async getCustomerInfo(id_card) {
    const componentsByUser: GI_Component[] = await this.getGIComponents(
      id_card
    );
    //console.log("componentsByUser: ", componentsByUser);
    let associatedPeople: AssociatedInstance[] = await this.getAssociatedPeople(
      id_card
    );
    associatedPeople.unshift(
      new AssociatedInstance('', 'Default', 0, [], '', id_card)
    );
    this.load_role = false;
    this._apiProvider.getCustomer(id_card).subscribe((data) => {
      this.load_info = false;
      if (data && data[0]) {
        const customer_data = data[0] as CustomerData;

        this.setRegisterData(customer_data, id_card);
        this.presentAlert(
          'Bienvenid@',
          '¡Su información ha sido consultada correctamente!',
          true,
          customer_data
        );

        this.toUpdateComponents.forEach((value) => {
          //Oculta: 1 y 3; Muestra: 9, 6, 7, 12, 4
          this.events.publish(GlobalEvents.UPDATE_PAGES, {
            param: value,
            args: [],
          });
        });

        componentsByUser.forEach((value) => {
          //Hace visible los componentes permitidos al momento de iniciar sesión.
          if (value.type == 0) {
            this.events.publish(GlobalEvents.SHOW_PAGES, {
              param: value.componentId,
            });
          } else if (value.type == 3 && value.ListAppsComponent.length) {
            this.events.publish(GlobalEvents.SHOW_PAGES, {
              param: value.componentName,
              args: value.ListAppsComponent,
            });
          }
        });

        this.events.publish(GlobalEvents.SET_COMPONENTS_BY_USER, {
          pComponentsByUser: componentsByUser,
        });

        this.events.publish(GlobalEvents.SET_ASSOCIATED_PEOPLE, {
          pAssociatedPeople: associatedPeople,
        });

        // Delete all data of the products
        this.storage.set('componentsByUser', JSON.stringify(componentsByUser));
        this.storage.set('associatedPeople', JSON.stringify(associatedPeople));
        this.storage.remove('productos');
        this.storage.remove('filtros');
        this.storage.remove('ultimaActualizacionProductos');
      } else {
        this.storage.get('register_data').then((data) => {
          if (data == null) {
            const register_data = new RegisterData();
            register_data.id_card = id_card;

            this.storage.set('register_data', JSON.stringify(register_data));
          }
        });
        this.presentAlert(
          'Lo sentimos',
          'Su cuenta aún no ha sido procesada en nuestro sistema, por favor espere unos minutos para poder acceder a la funcionalidad de compras.',
          false,
          data as CustomerData
        );
      }
    });
  }

  /**
   *
   * @param customerData
   */
  async presentPopupSelectDir(customerData: CustomerData) {
    if (
      customerData.CustomerAddresses &&
      customerData.CustomerAddresses.length > 0
    ) {
      let popup = await this.popoverCtrl.create({
        cssClass: 'custom-popover',
        component: SelectDeliveryAddressComponent,
        componentProps: {
          customerData: customerData,
        },
        backdropDismiss: false,
      });

      popup.present();

      popup.onDidDismiss().then((res) => {
        let data = res.data;
        let priceGroupId = 'HX-VIP';

        if (data) {
          let directionInfo = data as {
            direction: { address: CustomerAddress; checked: boolean };
            timeRange: { type: number; desc: string; checked: boolean };
          };

          if (directionInfo) {
            priceGroupId = directionInfo.direction.address.PriceGroupId;
            if (priceGroupId == '') {
              priceGroupId = customerData.PriceGroup;
            }

            if (priceGroupId == 'FU') {
              priceGroupId = 'HX-VIP';
            }
          }
        }

        this.storage.set('price_group_id', JSON.stringify(priceGroupId));

        this.navService.navigateTo(
          NavigationRoutes.FamiliesComponent,
          {},
          { replaceUrl: true }
        );
      });
    } else {
      this.storage.set('price_group_id', JSON.stringify('HX-VIP'));

      this.navService.navigateTo(
        NavigationRoutes.ROOT_PAGE,
        {},
        { replaceUrl: true }
      );
    }
  }

  /**
   *
   * @param customer_data
   * @param id_card
   */
  setRegisterData(customer_data: CustomerData, id_card: string) {
    const register_data = new RegisterData();
    register_data.customerId = customer_data.AccountNum;
    register_data.user_name = customer_data.CustomerName;

    register_data.OriginSite = customer_data.OriginSite;
    register_data.Hierarchy = customer_data.Hierarchy;
    register_data.DeliveryRoute = customer_data.DeliveryRoute;
    register_data.priceList = customer_data.PriceGroup;

    // Validate if customer has addresses
    if (customer_data.CustomerAddresses) {
      customer_data.CustomerAddresses.forEach((address) => {
        const direction = new Direction();

        direction.recId = address.RecId;
        direction.latitude = address.Latitude;
        direction.longitude = address.Longitude;
        direction.direction = address.Address;
        direction.addressName = address.AddressName;
        direction.deliveryDays = address.DeliveryDays;
        direction.priceGroupId = address.PriceGroupId;
        direction.principalDeliveryDay = address.PrincipalDeliveryDay;
        direction.secondDeliveryDay = address.SecondDeliveryDay;
        direction.city = address.City;

        register_data.directions.push(direction);
      });
    }

    register_data.id_card = id_card;
    this.storage.set('register_data', JSON.stringify(register_data));
    this.events.publish(GlobalEvents.SET_LOGIN_DATA, {
      userName: register_data.user_name,
      userId: register_data.id_card,
      loginStatus: true,
    });
  }

  /**
   *
   * @param title
   * @param msg
   * @param success
   * @param data
   */
  async presentAlert(
    title: string,
    msg: string,
    success: boolean,
    data: CustomerData
  ) {
    let alert = await this.alert.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            if (success) {
              this.presentPopupSelectDir(data);
            } else {
              this.navService.navigateTo(
                NavigationRoutes.ROOT_PAGE,
                {},
                { replaceUrl: true }
              );
            }
          },
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }
}
