import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from './../../../provider/Services/local-service-oc.service';

@Component({
  selector: 'app-send-multiple-oc',
  templateUrl: './send-multiple-oc.component.html',
  styleUrls: ['./send-multiple-oc.component.scss'],
})
export class SendMultipleOCComponent implements OnInit {
  objectsList: PurchOrder[];

  constructor(
    private viewCtrl: ModalController,
    private navParams: NavParams,
    private externalService: ExternalServiceOCService,
    private localOcService: LocalServiceOCService
  ) {}

  ngOnInit() {
    this.objectsList = this.navParams.get('objectsList');
  }

  async closePopOver() {
    /*this.viewCtrl.dismiss ({
      objSelected: null
    });*/
    let result = this.objectsList.filter((data) => data.getSending() === 2);

    if (result.length === 0) {
      //puedo salir
      this.objectsList.map((data) => data.setSending(0));
      this.viewCtrl.dismiss({ objSelected: null });
    } else {
      //
    }
  }

  approvePurchOrder() {
    //se mandan las OC que el usuario eligió.
    for (const orden of this.objectsList) {
      this.sendAprobedPurchOrder(orden);
    }
  }

  async sendAprobedPurchOrder(infoPurch: PurchOrder) {
    try {
      const estadoNum: number = 30;
      if (infoPurch.getDocumentStateI() !== estadoNum) {
        // 'APROBADO' === 30
        infoPurch.setSending(2); // muestra el espiner
        const vatNum = await this.localOcService.getCedulaActual();
        const result = (await this.externalService.updateDocumentState(
          infoPurch.getIdOC(),
          estadoNum,
          infoPurch.getDataArea().getId(),
          vatNum
        )) as number;
        if (result !== estadoNum) {
          infoPurch.setSending(-1);
          console.log('No se pudo actualizar el estado de la orden');
        } else {
          infoPurch.setDocumentStateI(result);
          infoPurch.setSending(1);
        }
      }
    } catch (error) {
      infoPurch.setSending(-1);
      console.log('No se pudo actualizar el estado de la orden');
    }
  }

  getStyle(state: number) {
    let result = 'normal';
    switch (state) {
      case 1:
        result = 'good';
        break;
      case -1:
        result = 'bad';
        break;
      default:
        break;
    }
    return result;
  }
}
