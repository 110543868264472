// Native-Plugins
import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { FilterData } from '../../businessCore/FixedAsset';

@Component({
  selector: 'app-asset-filter',
  templateUrl: './asset-filter.component.html',
  styleUrls: ['./asset-filter.component.scss'],
})
export class AssetFilterComponent {
  filterData: FilterData = new FilterData();
  companies: any[];
  categories: any[];
  statusList: any[];

  /**
   * Class constructor with these injections:
   * @param viewCtrl Access various features and information about the current view.
   * @param params Object that exists on a page and can contain data for that particular view.
   */

  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.filterData = this.params.get('filterData');
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y sin efectuar
   * ningun cambio en el filtrado de productos
   */
  dismissNull() {
    this.filterData.categories.forEach((element) => {
      element.selected = false;
    });
    this.filterData.companies.forEach((element) => {
      element.selected = false;
    });
    this.filterData.statusList.forEach((element) => {
      element.selected = false;
    });
    this.viewCtrl.dismiss(this.filterData);
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y efectuar
   * los cambios deseados para que el sistema muestre los productos
   * que coincidan con la selección
   */
  dismiss() {
    this.viewCtrl.dismiss(this.filterData);
  }

  onChangeCompany(event) {
    this.falseAll(this.filterData.companies);
    for (let i of event.detail.value) {
      this.filterData.companies.find(
        (element) => element.dataAreaId === i.dataAreaId
      ).selected = true;
    }
  }

  onChangeCategory(event) {
    this.falseAll(this.filterData.categories);
    for (let i of event.detail.value) {
      this.filterData.categories.find(
        (element) => element.assetGroup === i.assetGroup
      ).selected = true;
    }
  }

  onChangeStatus(event) {
    this.falseAll(this.filterData.statusList);
    for (let i of event.detail.value) {
      this.filterData.statusList.find(
        (element) => element.statusName === i.statusName
      ).selected = true;
    }
  }

  falseAll(array: any[]) {
    for (let i of array) {
      i.selected = false;
    }
  }
}
