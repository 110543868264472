import { ChangeDetectorRef, Component } from '@angular/core';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { Money } from 'src/app/GeneralUtilis/money';
import { InventoryItem } from 'src/app/businessCore/InventoryItem';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';

@Component({
  selector: 'app-families-home',
  templateUrl: './families-home.component.html',
  styleUrls: ['./families-home.component.scss'],
})
export class FamiliesHomeComponent {
  itemInfo: InventoryItem[];
  shoppingCar: OrderLine[] = [];
  titulo: string = '';
  public hasProducts: boolean = false;
 

  sliderOpts = {
    zoom: {
      maxRatio: 2,
    },
  };

  constructor(
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    private storage: StorageService,
    public navParams: NavParams,
    private changeRef: ChangeDetectorRef,
    private ShoopingCartUtils: ShoopingCartUtils,
    private navService: NavigationService
  ) {
    this.itemInfo = this.navParams.get('inventoryItem') as InventoryItem[];

    this.reloadProducts();
  }

  reloadProducts() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }

      if (this.itemInfo && this.itemInfo.length > 0) {
        this.titulo = this.itemInfo[0].Hierarchy3;
        this.hasProducts = true;
      } else {
        this.hasProducts = false;
        this.navService.navigateTo(NavigationRoutes.FamiliesComponent, {});
      }

      //console.log('Item Info');
      //console.log(this.itemInfo);
    });

    //console.log(this.itemInfo);
  }

  setCurrency(item) {
    if (item && item.taxes && item.Amount) {
      let tax = Number.parseFloat(item.taxes.toString().replace(',', '.'));
      let salePrice = Number.parseFloat(
        item.Amount.toString().replace(',', '.')
      );
      let formatter = new Money(tax + salePrice);

      return formatter.getCurrency();
    }
    return '';
  }

  async addToCart(item: InventoryItem) {
    this.navService.navigateTo(NavigationRoutes.AddToCartComponent, {
      item: item,
      shoppingCar: this.shoppingCar,
    });
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  toggleDetails(data: InventoryItem) {
    if (data.IsExpanded) {
      data.IsExpanded = false;
      data.Icon = 'arrow-down';
      this.changeRef.detectChanges();
    } else {
      data.IsExpanded = true;
      data.Icon = 'arrow-up';
      this.changeRef.detectChanges();
    }
  }

  getSlicedSites(siteName: string) {
    const maxStringSize: number = 50;
    if (siteName.length < maxStringSize) {
      return siteName;
    }
    return siteName.slice(0, 50) + '...';
  }

  displayShoppingCar() {
    this.ShoopingCartUtils.displayShoppingCart();
  }

  goHome() {
    this.navService.navigateTo(
      NavigationRoutes.FamiliesComponent,
      {},
      { replaceUrl: true }
    );
  }
}
