<ion-content class="ion-padding">
  <div class="blockContainer centerItems" style="justify-content: center">
    <!-- Imagen y nombre del producto -->
    <img
      src="https://serviceplan-api.mercasacr.com/images/Products/{{ item.ItemId }}_l_.PNG"
      class="center"
      alt=""
      onerror=""
    />
    <p class="h7" style="display: flex; justify-content: center">
      {{ item.ItemName }}
    </p>

    <!-- Titulo para valorar el comentario -->
    <h6 style="text-align: center; font-weight: bold" *ngIf="rate">
      Valoración
    </h6>
    <h6 style="text-align: center; font-weight: bold" *ngIf="!rate">
      Valorar Producto
    </h6>

    <!-- Encabezado con la valoración -->
    <div
      class="flexContainer centerItems marginAll"
      *ngIf="register_data != null"
    >
      <div style="padding: 5px 10px" *ngIf="rate">
        {{ rate }}
      </div>
      <div class="flexContainer">
        <app-rating
          (ratingChange)="onModelChange($event)"
          [(rating)]="rate"
          [readonly]="ratingReadOnly"
        ></app-rating>
      </div>
    </div>

    <!-- Titulo del comentario -->
    <h6
      style="text-align: center; font-weight: bold"
      *ngIf="register_data != null"
    >
      Mi Comentario
    </h6>

    <!-- Conjunto de comentarios con el contenido, likes y dislikes -->
    <div
      class="flexContainer centerItems marginAll commentContainer"
      *ngIf="product_comment.RECID != '' && register_data != null"
    >
      <div style="margin: auto">
        <i class="far fa-user" style="font-size: 25px"></i>
      </div>
      <div class="vl"></div>
      <div class="blockContainer marginAll" style="width: 75%">
        <div style="text-align: justify; font-size: 12px">
          {{ product_comment.CONTENT }}
        </div>
        <div class="flexContainer marginAll" style="align-items: center">
          <div style="margin-right: 8px; font-size: 12px">
            {{ product_comment.LIKES }}
          </div>
          <div style="margin-right: 6px">
            <i class="far fa-thumbs-up" style="font-size: 16px"></i>
          </div>
          <div style="margin-left: 6px; margin-right: 8px; font-size: 12px">
            {{ product_comment.DISLIKES }}
          </div>
          <div>
            <i class="far fa-thumbs-down" style="font-size: 16px"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Input para crear un nuevo comentario -->
    <div
      style="text-align: center"
      *ngIf="product_comment.RECID == '' && register_data != null"
    >
      <ion-item>
        <ion-textarea
          label="Nuevo comentario"
          label-placement="floating"
          class="floating"
          type="text"
          rows="2"
          [(ngModel)]="newComment"
        ></ion-textarea>
      </ion-item>

      <ion-button ion-button round class="submit-btn" (click)="createComment()">
        Agregar Comentario
      </ion-button>
    </div>

    <div
      style="padding: 5px"
      *ngIf="product_comment.RECID == '' && register_data == null"
    >
      <div style="font-size: 11px">
        Para llevar a cabo la valoración de un producto, es necesario:
      </div>
      <div style="margin-left: 10px; font-size: 10px; margin-top: 8px">
        <i class="fas fa-user-check" style="color: #444444"></i> Registrarse en
        el sistema.
      </div>
      <div style="margin-left: 10px; font-size: 10px; margin-top: 5px">
        <i class="fas fa-sign-in-alt" style="color: #444444"></i> Tener una
        sesión activa.
      </div>
      <div style="margin-left: 10px; font-size: 10px; margin-top: 5px">
        <i class="fas fa-shopping-cart" style="color: #444444"></i> Haber
        comprado este producto en el último mes.
      </div>
    </div>
  </div>
</ion-content>
