import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuditFinding } from '../../businessCore/AuditFinding';

@Component({
  selector: 'app-add-finding',
  templateUrl: './add-finding.component.html',
  styleUrls: ['./add-finding.component.scss'],
})
export class AddFindingComponent {
  assetRecId: number;
  comment: string;
  assigned: number;
  auditFindings: AuditFinding[];
  auditFindingsCopy: AuditFinding[];
  canceledAuditFindings: AuditFinding[];

  constructor(public viewCtrl: ModalController, private navParams: NavParams) {
    this.assetRecId = this.navParams.get('assetRecId');
    this.comment = this.navParams.get('comment');
    this.auditFindings = this.navParams.get('auditFindings');
    this.assigned = this.navParams.get('assigned');

    this.auditFindingsCopy = [];

    for (let element of this.auditFindings) {
      this.auditFindingsCopy.push(element.deepCopyAuditFinding());
    }

    this.canceledAuditFindings = this.navParams.get('auditFindings');
  }

  dismiss(pAction: number) {
    if (pAction === 1) {
      this.viewCtrl.dismiss({
        assetRecId: this.assetRecId,
        comment: this.comment,
        auditFindings: this.auditFindingsCopy,
        assigned: this.assigned,
      });
    } else {
      this.viewCtrl.dismiss({
        assetRecId: this.assetRecId,
        comment: this.comment,
        auditFindings: this.auditFindings,
        assigned: this.assigned,
      });
    }
  }
}
