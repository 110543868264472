import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-check-list-answer-omi',
  templateUrl: './check-list-answer-omi.component.html',
  styleUrls: ['./check-list-answer-omi.component.scss'],
})
export class CheckListAnswerOmiComponent {
  listInfo: any[] = [];
  posSelected: string = null;
  addNote: boolean = false;
  note: string = '';
  type: number = 0;
  titulo: string = '';
  successful: boolean = false;
  constructor(public navParams: NavParams, private viewCtrl: ModalController) {
    this.type = this.navParams.get('type');
    this.titulo = this.navParams.get('titulo');
    this.listInfo = this.navParams.get('listInfo') as any[];
  }

  ngOnInit() {
    // Busca si hay una respuesta seleccionada para setear la nota
    this.setSelecctionByInfo();
    // Si hay una seleccionada y la nota es no vacia, addNote = true
    if (this.posSelected === null) {
      //No habia ninguna tarea seleccionada
      //Se setea por default lo primero de la lista.
      this.posSelected = this.listInfo[0].getID();
      this.note = this.listInfo[0].observation;
    }
  }

  async closePopOver(objetSelected: any) {
    this.setSelecctionEnd();
    this.viewCtrl.dismiss({
      successful: this.successful,
    });
  }

  setSelecctionEnd() {
    for (const option of this.listInfo) {
      //Pone en falso las respuestas no seleccionadas y el mensaje
      if (option.getID() === this.posSelected) {
        option.selected = true;
        if (this.addNote) {
          option.observation = this.note;
        } else {
          option.observation = '';
        }
        this.successful = true; // se escoge una opcion y el estado de la tarea se cambia.
      } else {
        option.selected = false;
        option.observation = '';
      }
    }
  }

  setSelecctionByInfo() {
    for (const option of this.listInfo) {
      //Pone en falso las respuestas no seleccionadas y el mensaje
      if (option.selected) {
        this.posSelected = option.getID();
        if (option.observation.length > 0) {
          //Hay una nota registrada
          this.note = option.observation;
          this.addNote = true;
        }
      }
    }
  }
}
