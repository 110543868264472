<ion-content>
  <div class="box">
    <div class="content">
      <div style="margin-top: 2rem; margin-bottom: 1rem; text-align: center">
        <h3 style="margin-top: 0; margin-bottom: 0; display: inline">
          {{ documentLanguage.title }}
        </h3>
        <div class="languageTag" style="display: inline">
          <p
            style="
              margin-top: 0px;
              margin-bottom: 0px;
              vertical-align: top;
              font-size: 13px;
            "
            (click)="changeLanguage()"
          >
            {{ changeLanguageTo }}
          </p>
        </div>
      </div>

      <div style="margin-left: 15px; margin-right: 15px; font-size: 16px">
        <P>{{ documentLanguage.infoText }}</P>
      </div>

      <ul style="font-size: 16px">
        <li [ngClass]="{ hideListBullet: document.vatNumVendor.length > 0 }">
          <ion-icon
            *ngIf="document.vatNumVendor.length"
            name="checkmark-outline"
            class="checkIcon"
          ></ion-icon>
          <div style="display: inline">
            {{ documentLanguage.b1 }}
            <label style="display: inline-block" class="btn-attach"
              >{{ documentLanguage.uploadImage }}
              <input
                type="file"
                accept="application/pdf, image/*"
                class="upload-photo"
                (change)="onFileSelected($event, 1)"
                multiple
              />
            </label>
          </div>
        </li>

        <li
          *ngIf="this.reqRepr"
          [ngClass]="{ hideListBullet: document.vatNumRepres.length > 0 }"
        >
          <ion-icon
            *ngIf="document.vatNumRepres.length"
            name="checkmark-outline"
            class="checkIcon"
          ></ion-icon>
          <div style="display: inline">
            {{ documentLanguage.b2 }}
            <label style="display: inline-block" class="btn-attach"
              >{{ documentLanguage.uploadImage }}
              <input
                type="file"
                accept="application/pdf, image/*"
                class="upload-photo"
                (change)="onFileSelected($event, 2)"
                multiple
              />
            </label>
          </div>
        </li>

        <li
          [ngClass]="{ hideListBullet: document.vatNumReprInvoice.length > 0 }"
        >
          <ion-icon
            *ngIf="document.vatNumReprInvoice.length"
            name="checkmark-outline"
            class="checkIcon"
          ></ion-icon>
          <div style="display: inline">
            {{ documentLanguage.b3 }}
            <label style="display: inline-block" class="btn-attach"
              >{{ documentLanguage.uploadImage }}
              <input
                type="file"
                accept="application/pdf, image/*"
                class="upload-photo"
                (change)="onFileSelected($event, 3)"
                multiple
              />
            </label>
          </div>
        </li>

        <li
          [ngClass]="{ hideListBullet: document.vatNumReprService.length > 0 }"
        >
          <ion-icon
            *ngIf="document.vatNumReprService.length"
            name="checkmark-outline"
            class="checkIcon"
          ></ion-icon>
          <div style="display: inline">
            {{ documentLanguage.b4 }}
            <label style="display: inline-block" class="btn-attach"
              >{{ documentLanguage.uploadImage }}
              <input
                type="file"
                accept="application/pdf, image/*"
                class="upload-photo"
                (change)="onFileSelected($event, 4)"
                multiple
              />
            </label>
          </div>
        </li>

        <li [ngClass]="{ hideListBullet: document.vendorAgreement.length > 0 }">
          <ion-icon
            *ngIf="document.vendorAgreement.length"
            name="checkmark-outline"
            class="checkIcon"
          ></ion-icon>
          <div style="display: inline">
            <a
              #vendorAgreement
              (click)="
                showAlert(
                  documentLanguage.alertTitle,
                  documentLanguage.alertSubTitle,
                  documentLanguage.alertBtnText,
                  documentLanguage.alertRejectBtnText,
                  1
                )
              "
            >
              <u>{{ documentLanguage.b5 }}</u></a
            >
            <label style="display: inline-block" class="btn-attach"
              >{{ documentLanguage.uploadImage }}
              <input
                type="file"
                accept="application/pdf, image/*"
                class="upload-photo"
                (change)="onFileSelected($event, 5)"
                multiple
              />
            </label>
          </div>
        </li>

        <!-- <li [ngClass]="{'hideListBullet':document.transferAuthorization.length}">
          <ion-icon *ngIf="document.transferAuthorization.length" name="checkmark-outline" class="checkIcon"></ion-icon>
          <div style="display: inline;">{{documentLanguage.b6}}
            <label style="display: inline-block;" class="btn-attach">{{documentLanguage.uploadImage}}
              <input type="file" accept="application/pdf,image/*" class="upload-photo"
                (change)="onFileSelected($event, 6)" multiple>
            </label>
          </div>
        </li> -->

        <li
          [ngClass]="{ hideListBullet: document.guaranteePolicies.length > 0 }"
        >
          <ion-icon
            *ngIf="document.guaranteePolicies.length"
            name="checkmark-outline"
            class="checkIcon"
          ></ion-icon>
          <div style="display: inline">
            {{ documentLanguage.b7 }}
            <label style="display: inline-block" class="btn-attach"
              >{{ documentLanguage.uploadImage }}
              <input
                type="file"
                accept="application/pdf, image/*"
                class="upload-photo"
                (change)="onFileSelected($event, 7)"
                multiple
              />
            </label>
          </div>
        </li>
      </ul>

      <br />

      <ion-item lines="none">
        <ion-checkbox
          [(ngModel)]="document.acceptVendorAgreement"
          style="margin-right: 6px"
        ></ion-checkbox>
        <ion-label style="font-size: 14px; text-decoration: underline">{{
          documentLanguage.terms
        }}</ion-label>
      </ion-item>

      <div class="center">
        <ion-button (click)="register()" class="centerBtn" expand="block">
          Registrar
        </ion-button>
      </div>

      <!-- <div style="text-align: center; margin-top: 5px;">
        <input #unlock type="range" value="0" max="100" (touchend)="checkUnlock($event)"
          (mouseout)="checkUnlock($event)" id="{{documentLanguage.register}}"
          style="text-align: center;">
      </div> -->

      <p
        *ngIf="flagErrorMsg"
        style="color: red; margin-left: 15px; font-size: 14px"
      >
        {{ documentLanguage.errorMsg }}
      </p>
    </div>
  </div>
</ion-content>

<div class="infContent">
  <div class="whiteRectangle">
    <ion-fab horizontal="start" vertical="bottom">
      <ion-fab-button size="small" (click)="changeScreen(4)"
        ><ion-icon name="chevron-back-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>
