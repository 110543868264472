export class RestrictionData {
  day: string[] = [];
  type: number = 0;
  edit: boolean = true;
  date1: string = new Date().toISOString();
  date2: string = new Date().toISOString();

  constructor() {}

  public get_restriction_type() {
    return [
      { id: 0, value: 'Antes De' },
      { id: 1, value: 'Después De' },
      { id: 2, value: 'Dentro del Rango' },
      { id: 3, value: 'Fuera del Rango' },
    ];
  }

  public get_type_value(day: number) {
    const array = this.get_restriction_type();
    for (let element of array) {
      if (element.id == day) {
        return element.value;
      }
    }
    return { id: -1, value: 'ERROR' };
  }

  public getHours(s) {
    let b = s.split(/\D+/);
    return [b[3], b[4]];
  }
}
