<app-nav-bar [title]="'Mis Listas'"></app-nav-bar>

<ion-content class="ion-padding" text-center>
  <ion-searchbar
    type="text"
    class="searchBar"
    [(ngModel)]="searchInput"
    placeholder="Buscar"
    (ionInput)="getItems($event)"
  ></ion-searchbar>

  <div style="text-align: center; margin-top: 12px" *ngIf="!validListState()">
    <h6 ion-text text-center>No existen listas creadas.</h6>
  </div>

  <ion-list>
    <ion-card
      *ngFor="let lista of listasFilter"
      style="
        background: transparent;
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      "
    >
      <ion-item-sliding
        (swipe)="swipeEvent($event, lista)"
        style="border-radius: 5px; overflow: hidden"
      >
        <ion-item lines="none" class="borderStyle">
          <div style="display: flex; align-items: center; flex-wrap: nowrap">
            <div style="margin-right: 10px; font-size: 18px">
              <i class="fas fa-align-left blueColor"></i>
            </div>
            <div>
              <h6 style="font-weight: bold">{{ lista.name }}</h6>
              <h6>Artículos ({{ lista.items.length }})</h6>
            </div>
          </div>
        </ion-item>

        <ion-item-options side="end">
          <ion-item-option color="danger" (click)="deleteList(lista)">
            <i class="fas fa-trash-alt"></i>
            <span style="max-width: 70px" class="wrapword">Eliminar</span>
          </ion-item-option>
        </ion-item-options>

        <ion-item-options side="start">
          <ion-item-option (click)="showProducts(lista.items)">
            <i class="fas fa-tasks"></i>
            <span style="max-width: 70px" class="wrapword">Ver Productos</span>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-card>
  </ion-list>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" (click)="createList()">
  <ion-fab-button color="primary" class="submit-btn" size="small">
    <i class="fas fa-plus"></i>
  </ion-fab-button>
</ion-fab>
