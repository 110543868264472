import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Money } from 'src/app/GeneralUtilis/money';
import { OrderLine } from 'src/app/businessCore/OrderLine';
import { InventoryItem } from '../../businessCore/InventoryItem';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent {
  item: InventoryItem;
  ordLine: OrderLine[] = [];
  constructor(private params: NavParams, private viewCtrl: PopoverController) {
    this.item = this.params.get('inventoryItem');
  }

  /**
   * Metodo utilizado para dar formato tipo Money al Amount
   * que se le pasa como paramentro
   * @param Amount numero que representa el precio y al que se le
   * dara formato tipo Money
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para setear el precio unitario de un producto + el impuesto correspondiente
   * @param item Línea de la orden a la que se le calcula el precio con impuesto por cada unidad
   */
  setSalePrice(item: OrderLine) {
    return this.setCurrency(item.salePrice + item.tax);
  }

  closePopup() {
    this.viewCtrl.dismiss();
  }
}
