import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {
  @Input() public rating: number;
  @Input() public readonly: boolean;
  @Output() public ratingChange: EventEmitter<number> =
    new EventEmitter<number>();

  public stars: number[] = [1, 2, 3, 4, 5];

  floor(number: number) {
    return Math.floor(number);
  }

  ceil(number: number) {
    return Math.ceil(number);
  }

  setRate(rate: number) {
    if (!this.readonly) {
      console.log(rate);
      this.ratingChange.emit(rate);
    }
  }
}
