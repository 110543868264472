<app-nav-bar [title]="'Registro'"></app-nav-bar>

<ion-content class="ion-padding" center text-center>
  <h3 class="title2" margin-bottom>Agregar Contacto</h3>

  <ion-row style="justify-content: center">
    <ion-col col-6>
      <ion-button expand="block" (click)="presentNewPopover(true)">
        <ion-icon name="call" class="mr-5"></ion-icon> Personal
      </ion-button>
    </ion-col>
  </ion-row>

  <form (ngSubmit)="registerNextStep()" #registerForm="ngForm">
    <ion-list>
      <ion-item
        lines="none"
        [ngClass]="{
          contact: validForm(),
          contactError: !validForm()
        }"
      >
        <a class="required"></a>
        <ion-input
          label="Correo Electrónico"
          label-placement="floating"
          class="floating"
          type="email"
          name="email"
          [(ngModel)]="register_data.email"
          [ngModelOptions]="{ standalone: true }"
          required
        ></ion-input>
      </ion-item>

      <ion-item
        *ngFor="let contact of register_data.contacts"
        class="contact"
        (click)="presentPopover(contact)"
      >
        <ion-label>
          <ion-icon
            name="call"
            *ngIf="contact.type"
            style="z-index: 1; margin-right: 4px"
          ></ion-icon>
          <ion-icon
            name="contacts"
            *ngIf="!contact.type"
            style="z-index: 1; margin-right: 4px"
          ></ion-icon>
          {{ contact.contact }}</ion-label
        >
        <ion-icon
          (click)="deleteContact(contact)"
          name="trash"
          style="z-index: 1"
          item-right
        ></ion-icon>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-fab horizontal="end" vertical="bottom">
  <ion-fab-button
    color="primary"
    class="submit-btn"
    type="submit"
    [disabled]="!validForm()"
    size="small"
    (click)="registerNextStep()"
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
