import { Component, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { AuthJWTService } from '../../provider/auth-jwt/auth.jwt.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthPage implements OnInit {
  pages: Array<{ id: number; title: string; component: any }>;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private authService: AuthJWTService,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    this.pages = [
      {
        id: 14,
        title: 'Crear orden de compra',
        component: 'PurchOrderRecordPage',
      },
      {
        id: 15,
        title: 'Aprobar orden de compra',
        component: 'PurchOrderToBeApprovedPage',
      },
      {
        id: 16,
        title: 'Confirmación de orden de compra',
        component: 'PurchOrderToBeConfirmedPage',
      },
      {
        id: 22,
        title: 'Solicitud de traslado',
        component: 'AssetsTransferComponent',
      },
      {
        id: 24,
        title: 'Aprobación de traslado',
        component: 'AssetsApprovalComponent',
      },
      {
        id: 25,
        title: 'Recepción de activo',
        component: 'AssetAcceptanceComponent',
      },
      { id: 26, title: 'Check List', component: 'ChecklistInstancePage' },
      { id: 27, title: 'Viajes', component: 'TripRegisterPage' },
      {
        id: 28,
        title: 'Registro de proveedores',
        component: 'VendorRegisterComponent',
      },
      {
        id: 29,
        title: 'Certificación de AF',
        component: 'AssetCertificationPage',
      },
      {
        id: 30,
        title: 'Aprobar certificación AF',
        component: 'AssetCertificationApprovalPage',
      },
      {
        id: 31,
        title: 'Auditoría de activos fijos',
        component: 'AssetAuditPage',
      },
    ];

    let token = this.navParams.get('token');
    let componentId: any = this.navParams.get('componentId');
    if (!componentId) {
      window.location.href = 'http://giiis01:8025/';
    }
    componentId = Number(componentId);
    if (token) {
      this.authService.verifyAuthToken(token).subscribe(
        (res) => {
          let index = this.pages.findIndex((x) => x.id === componentId);

          this.navService.navigateTo(
            this.pages[index].component,
            {
              auth: 1,
              code: componentId,
            },
            { replaceUrl: true }
          );
          // redirect to main page
        },
        (err) => {
          // login failed
          // redirect to login page
        }
      );
    } else {
      // redirect to login page
    }
  }
}
