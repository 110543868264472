import { Component } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { PSRtnHeader } from '../../../businessCore/OrderRet/PSRtnHeader';
import { PSRtnLine } from '../../../businessCore/OrderRet/PSRtnLine';
import { RegisterData } from '../../../businessCore/RegisterData';
import { webServiceProvider } from '../../../provider/webServiceProvider';
import { RetDeliveryCarComponent } from '../ret-delivery-car/ret-delivery-car.component';

@Component({
  selector: 'app-ret-car',
  templateUrl: './ret-car.component.html',
  styleUrls: ['./ret-car.component.scss'],
})
export class RetCarComponent {
  rtnHeader: PSRtnHeader;
  totalUnits: number = 0;
  register_data: RegisterData;

  constructor(
    private viewCtrl: ModalController,
    private alert: AlertController,
    private params: NavParams,
    private navService: NavigationService,
    private webService: webServiceProvider
  ) {
    this.rtnHeader = new PSRtnHeader();

    this.rtnHeader.PSRtnLine = this.params.get('rtnLines');
    this.register_data = this.params.get('registerData');

    //DEBUG
    /*this.register_data = new RegisterData();
    this.rtnHeader = new PSRtnHeader();
    let p = new PSRtnLine();
    p.baseObject = new PSRtnBoughtItem('NAME', 'ITEMID');
    this.rtnHeader.PSRtnLine = [p];*/
    //DEBUG
    this.rtnHeader.dlvryRte = this.register_data.DeliveryRoute;
    this.rtnHeader.orgnSte = this.register_data.OriginSite;
    this.rtnHeader.cstmrAccnt = this.register_data.customerId;

    this.updateTotalUnits();

    this.updateLnId();

    this.setMobileSeq();
  }

  setMobileSeq() {
    this.webService
      .getMobileSequence(this.rtnHeader.emplyId)
      .subscribe((data) => {
        if (data != null) {
          let orderNumber: number = 0;
          let sequenceSelected: string = '';
          let sequences: {
            Prefix: string;
            PrefixDirectSale: string;
            SequenceId: number;
            Value: number;
          }[] = data as {
            Prefix: string;
            PrefixDirectSale: string;
            SequenceId: number;
            Value: number;
          }[];
          sequences.forEach((value) => {
            if (value.SequenceId == 3) {
              orderNumber = value.Value + 1;
              sequenceSelected = value.Prefix;
            }
          });
          if (orderNumber > 0) {
            this.webService
              .setMobileSequence(this.rtnHeader.emplyId, 3, orderNumber)
              .subscribe((item) => {
                let orderFinalNumber: string = orderNumber.toString();
                while (orderFinalNumber.length < 6) {
                  orderFinalNumber = '0' + orderFinalNumber;
                }
                orderFinalNumber = sequenceSelected + orderFinalNumber;
                this.rtnHeader.rtnId = orderFinalNumber;
                this.rtnHeader.dbtNbr = orderFinalNumber;
              });
          }
        }
      });
  }

  updateLnId() {
    this.rtnHeader.PSRtnLine.forEach((line, index) => {
      line.lnId = index + 1;
    });
  }

  updateTotalUnits() {
    this.totalUnits = 0;
    this.rtnHeader.PSRtnLine.forEach((line) => {
      this.totalUnits = this.totalUnits + line.slQntty;
    });
  }

  async deleteItem(item: PSRtnLine, index: number) {
    console.log('deleteItem(item: PSRtnLine) {');
    console.log(item);

    let alert = await this.alert.create({
      header: 'Atención!',
      subHeader: '¿Está seguro que desea eliminar esta línea de la devolución?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Si',
          handler: () => {
            console.log('deleted');
            console.log('index');
            console.log(index);
            this.rtnHeader.PSRtnLine.splice(index, 1);
            this.updateTotalUnits();
            this.updateLnId();
          },
        },
      ],
      backdropDismiss: true,
    });
    alert.present();
  }

  swipeEvent(event, item: PSRtnLine, index: number) {
    // DELETE LINE
    if (event.direction === 2) {
      console.log('if (event.direction === 2) {');
      this.deleteItem(item, index);
    }
    // UPDATE LINE QUANTITY
    else if (event.direction === 4) {
      console.log('else if (event.direction === 4) {');
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  async NextStep() {
    console.log('nextStep');
    let modal = await this.viewCtrl.create({
      component: RetDeliveryCarComponent,
      componentProps: {
        PSRtnHeader: this.rtnHeader,
        registerData: this.register_data,
      },
    });
    modal.present();
  }
}
