import { CaseDetail } from './../../businessCore/CaseDetail';
import { webServiceProvider } from './../../provider/webServiceProvider';

import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { RegisterData } from '../../businessCore/RegisterData';

@Component({
  selector: 'app-my-profile-casemanagement',
  templateUrl: './my-profile-casemanagement.component.html',
  styleUrls: ['./my-profile-casemanagement.component.scss'],
})
export class MyProfileCasemanagementComponent {
  loadingCases: boolean = true;
  without_Cases: boolean = false;
  caseDetail: CaseDetail[] = [];
  register_data: RegisterData = new RegisterData();
  statusArray = [
    { id: 0, status: 'Ninguno', color: '#222' },
    { id: 1, status: 'Abierto', color: '#223e99' },
    { id: 2, status: 'En Proceso', color: 'chocolate' },
    { id: 3, status: 'Cerrado', color: '#32db64' },
    { id: 4, status: 'Cancelado', color: '#f53d3d' },
  ];

  /**
   *
   * @param navCtrl
   * @param apiProvider
   * @param storage
   */
  constructor(
    public navCtrl: NavController,
    public apiProvider: webServiceProvider,
    public storage: StorageService,
    private navService: NavigationService
  ) {
    this.storage.get('register_data').then((data) => {
      if (data && data != null) {
        this.register_data = JSON.parse(data) as RegisterData;
        this.apiProvider
          .HE_GetCaseDetail(this.register_data.customerId)
          .subscribe((data) => {
            if (data && data != null) {
              this.caseDetail = data as CaseDetail[];
              if (this.caseDetail.length < 1) {
                this.without_Cases = true;
              }
              this.loadingCases = false;
            } else {
              this.loadingCases = false;
            }
          });
      }
    });
  }

  swipeEvent($event, order) {}

  addCase() {
    //this.navCtrl.push(MyProfileAddcaseComponent);
    this.navService.navigateTo(NavigationRoutes.MyProfileAddcaseComponent);
  }

  /**
   *
   * @param statusId
   */
  getStatus(statusId: number) {
    return this.statusArray.filter((element) => element.id == statusId).pop()
      .status;
  }

  /**
   *
   * @param statusId
   */
  getStatusColor(statusId: number) {
    return this.statusArray.filter((element) => element.id == statusId).pop()
      .color;
  }
}
