// BusinessCore
import { InventoryItem } from '../../businessCore/InventoryItem';
import { OrderLine } from './../../businessCore/OrderLine';
import { productList } from './../../businessCore/productList';

// Native-Plugins
import { Component } from '@angular/core';
import { Screenshot } from 'capacitor-screenshot';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Money } from '../../GeneralUtilis/money';

// WebService
import {
  AlertController,
  NavController,
  NavParams,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import {
  GlobalEvents,
  GlobalEventsService,
} from 'src/app/utils/globalEvents.service';
import { environment } from 'src/environments/environment';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { AddToListComponent } from '../add-to-list/add-to-list.component';
import { ProductGeneralCommentComponent } from '../product-general-comment/product-general-comment.component';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
})
export class AddToCartComponent {
  public item: InventoryItem;
  private itemInfo: InventoryItem[] = [];
  public ordLine: OrderLine[] = [];
  public complete: boolean = false;
  // private firstCall: boolean = false;
  private listas: productList[] = [];
  priceList: string = 'HX-VIP';

  incrQtyOnPressUp: boolean = false;
  reducQtyOnPressUp: boolean = false;

  shoppingCar: OrderLine[] = [];

  constructor(
    //public viewCtrl: ModalController,
    private webService: webServiceProvider,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public storage: StorageService,
    private socialSharing: SocialSharing,
    private toastCtrl: ToastController,
    private navParams: NavParams,
    private navController: NavController,
    private globalEventsService: GlobalEventsService
  ) {
    this.item = this.navParams.get('item') as InventoryItem;
    this.shoppingCar = this.navParams.get('shoppingCar') as OrderLine[];

    this.storage.get('price_group_id').then((data) => {
      if (data != null) {
        this.priceList = JSON.parse(data) as string;
      }
      this.initData();
    });

    // this.platform.backButton.subscribe(() => {
    //   console.log('Handler was called!');
    //   this.alertCtrl.create({
    //     title: 'Alerta!',
    //     message: 'Devolverse con backButton',
    //     enableBackdropDismiss: true,
    //     buttons: [{
    //       text: 'OK',
    //       role: 'cancel'
    //     }]
    //   }).present();
    //   // this.volver();
    // });
  }

  initData() {
    this.itemInfo.push(this.item);
    // this.firstCall = true;
    this.convertItemstoShoppingCar();
    // this.firstCall = false;
    this.reloadData(this.item.ItemId);
    this.storage.get('listas_productos').then((value) => {
      if (value != null) {
        this.listas = JSON.parse(value) as productList[];
      }
    });
  }

  async updateShoppingCarParam(response: OrderLine[]) {
    if (response != null) {
      let inserted: boolean;
      response.forEach((item) => {
        inserted = false;
        this.shoppingCar.forEach((data) => {
          if (item.itemId == data.itemId && item.unitId == data.unitId) {
            inserted = true;
            data.salePrice = item.salePrice;
            data.salesQuantity = data.salesQuantity + item.salesQuantity;
            data.totalSale = data.salesQuantity * data.salePrice;
          }
        });
        if (!inserted) {
          this.shoppingCar.push(item);
        }
      });

      this.storage.set('carrito', JSON.stringify(this.shoppingCar)).then(() => {
        this.globalEventsService.publish(GlobalEvents.UPDATE_CART);
      });
      let toast = await this.toastCtrl.create({
        message: 'Artículo agregado ' + (response as OrderLine[])[0].itemId,
        duration: 1500,
      });
      toast.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Artículo NO agregado',
        duration: 1500,
      });
      toast.present();
    }

    this.navController.pop();
  }

  async dismiss() {
    let lineas: OrderLine[] = [];
    let showErrorMsg: boolean = false;
    this.ordLine.forEach((item) => {
      if (item.salesQuantity != 0) {
        lineas.push(item);
      }
      if (!this.validQuantity(item.salesQuantity.toString())) {
        showErrorMsg = true;
      }
    });

    if (lineas.length == 0) {
      this.navController.pop();
    } else {
      if (showErrorMsg) {
        let alert = await this.alertCtrl.create({
          header: 'Error en la cantidad',
          message: 'Solamente son válidos números enteros.',
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
            },
          ],
        });
        alert.present();
      } else {
        this.updateShoppingCarParam(lineas);
      }
    }
  }

  reloadData(ItemId: String) {
    this.webService
      .getProductsByItem(ItemId, this.priceList)
      .subscribe((data) => {
        this.complete = true;
        if (
          data != null &&
          (data as InventoryItem[]).length > 0 &&
          (data as InventoryItem[])[0].AccountRelation != null
        ) {
          this.itemInfo = data as InventoryItem[];
          this.convertItemstoShoppingCar();
        }
      });
  }

  convertItemstoShoppingCar() {
    this.itemInfo.forEach((item) => {
      let found: boolean = false;
      this.ordLine.forEach((item2) => {
        if (item.ItemId == item2.itemId && item.UnitId == item2.unitId) {
          found = true;
        }
      });
      if (!found) {
        let line: OrderLine = new OrderLine();
        line.lineId = this.ordLine.length;
        line.itemId = item.ItemId;
        line.unitId = item.UnitId;
        line.tax = Number.parseFloat(item.taxes.toString().replace(',', '.'));
        line.salePrice = Number.parseFloat(
          item.Amount.toString().replace(',', '.')
        );

        //if (!this.firstCall) {
        line.totalSale = 0;
        line.salesQuantity = 0;
        line.taxAmount = 0;
        /*} else {
          line.salesQuantity = 1;
          line.lnAmnt = line.salePrice;
          line.taxAmount = line.tax; // IVA
          line.totalSale = Number(line.salePrice * line.salesQuantity) + line.taxAmount;
        }
        */

        line.itemName = item.ItemName;

        this.ordLine.push(line);
      }
    });
  }

  /**
   * Metodo utilizado para setear el precio unitario de un producto + el impuesto correspondiente
   * @param item Línea de la orden a la que se le calcula el precio con impuesto por cada unidad
   */
  setSalePrice(item: OrderLine) {
    return this.setCurrency(item.salePrice + item.tax);
  }

  /**
   * Metodo utilizado para incrementar la cantidad de producto de manera tal que al presionar el boton de + se
   * incremente una vez la cantidad.
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va incrementar
   */
  changeValueKeyUp(event: any, item: OrderLine) {
    // Validate if the input is a number, if not just prevent the input value
    // if (event.which != 8 && isNaN(Number(String.fromCharCode(event.which)))) {
    //   event.preventDefault(); //stop character from entering input
    // }
    // else {
    let index: number = item.lineId;

    this.ordLine[index].salesQuantity =
      this.ordLine[index].salesQuantity &&
      this.validQuantity(this.ordLine[index].salesQuantity.toString())
        ? this.ordLine[index].salesQuantity
        : 0;
    let quantity =
      this.ordLine[index].salesQuantity > 0
        ? this.ordLine[index].salesQuantity
        : 0;

    this.ordLine[index].lnAmnt = Number(
      quantity * this.ordLine[index].salePrice
    );
    this.ordLine[index].taxAmount = this.ordLine[index].tax * quantity;
    this.ordLine[index].totalSale =
      this.ordLine[index].lnAmnt + this.ordLine[index].taxAmount;
  }

  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que al presionar el boton de - se
   * decremente una vez la cantidad.
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va decrementar
   */
  reduceQuantity(item: OrderLine) {
    let index: number = item.lineId;
    if (this.ordLine[index].salesQuantity > 0) {
      let quantity = this.ordLine[index].salesQuantity - 1;
      this.ordLine[index].salesQuantity--;

      this.ordLine[index].lnAmnt = Number(
        quantity * this.ordLine[index].salePrice
      );
      this.ordLine[index].taxAmount = this.ordLine[index].tax * quantity;
      this.ordLine[index].totalSale =
        this.ordLine[index].lnAmnt + this.ordLine[index].taxAmount;
    }
  }

  /**
   * Metodo utilizado para incrementar la cantidad de producto de manera tal que al presionar el boton de + se
   * incremente una vez la cantidad.
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va incrementar
   */
  increaseQuantity(item: OrderLine) {
    let index: number = item.lineId;
    let quantity = this.ordLine[index].salesQuantity + 1;
    this.ordLine[index].salesQuantity++;

    this.ordLine[index].lnAmnt = Number(
      quantity * this.ordLine[index].salePrice
    );
    this.ordLine[index].taxAmount = Number(this.ordLine[index].tax * quantity);
    this.ordLine[index].totalSale =
      this.ordLine[index].lnAmnt + this.ordLine[index].taxAmount;
  }

  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que al presionar el boton de - se
   * disminuya multiples veces la cantidad.
   * Esta funcion es la encargada de poner en true la variable de control reducQtyOnPressUp y ejecutar el metodo
   * encargado de hacer el ciclo de reducción de cantidad
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va decrementar
   */
  reduceQuantityOnPress(e, item: OrderLine) {
    this.reducQtyOnPressUp = true;

    this.reduceQuantityOnPress_Aux(item);
  }

  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que al presionar el boton de - se
   * disminuya multiples veces la cantidad.
   * Metodo encargado de hacer el ciclo de reducción de cantidad
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va decrementar
   */
  reduceQuantityOnPress_Aux(item: OrderLine) {
    this.reduceQuantity(item);
    if (this.reducQtyOnPressUp) {
      setTimeout(() => {
        this.reduceQuantityOnPress_Aux(item);
      }, 200);
    }
  }

  /**
   * Metodo utilizado para cancelar el metodo reduce del quantity
   */
  reduceQuantityOnPressUp() {
    this.reducQtyOnPressUp = false;
  }

  /**
   * Metodo utilizado para incrementar la cantidad de producto de manera tal que al presionar el boton de + se
   * incremente multiples veces la cantidad.
   * Esta funcion es la encargada de poner en true la variable de control incrQtyOnPressUp y ejecutar el metodo
   * encargado de hacer el ciclo de incremento de cantidad
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va incrementar
   */
  increaseQuantityOnPress(e, item: OrderLine) {
    this.incrQtyOnPressUp = true;

    this.increaseQuantityOnPress_Aux(item);
  }

  /**
   * Metodo utilizado para reducir la cantidad de producto de manera tal que al presionar el boton de - se
   * disminuya multiples veces la cantidad.
   * Metodo encargado de hacer el ciclo de incremento de cantidad
   * @param item objeto de tipo OrderLine, utilizado para identificar el item que se va decrementar
   */
  increaseQuantityOnPress_Aux(item: OrderLine) {
    this.increaseQuantity(item);
    if (this.incrQtyOnPressUp) {
      setTimeout(() => {
        this.increaseQuantityOnPress_Aux(item);
      }, 200);
    }
  }

  /**
   * Metodo utilizado para cancelar el metodo increase del quantity
   */
  increaseQuantityOnPressUp() {
    this.incrQtyOnPressUp = false;
  }

  /**
   * Metodo utilizado para dar formato tipo moneda a un numero pasado por parametro
   * @param Amount numero con la cantidad a la que se le dara formato de moneda
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  ShareProduct() {
    // Take a screenshot and save to file
    Screenshot.take().then((value) => {
      this.socialSharing
        .shareWithOptions({
          message:
            'Mira este y más productos solamente en Mercasa VIP: • Play Store: https://play.google.com/store/apps/details?id=senseIT.cs.MercasaVIP • App Store: https://apps.apple.com/cr/app/mercasa-vip/id1554351581',
          subject: 'Atención!',
          files: ['data:image/png;base64,' + value.base64],
          url: '',
          chooserTitle: 'Compartir con una aplicación',
        })
        .then(() => {});
    });
  }

  async openFavorities(item: OrderLine) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: AddToListComponent,
      componentProps: { item: item },
      side: 'top',
    });
    popover.present();
    popover.onDidDismiss().then(() => {
      this.storage.get('listas_productos').then((value) => {
        if (value != null) {
          this.listas = JSON.parse(value) as productList[];
        }
      });
    });
  }

  isOnList(item: OrderLine) {
    let response: boolean = false;
    this.listas.forEach((value) => {
      value.items.forEach((value2) => {
        if (value2.itemId == item.itemId && value2.unitId == item.unitId) {
          response = true;
        }
      });
    });
    return response;
  }

  async openComments() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: ProductGeneralCommentComponent,
      componentProps: {
        item: this.item,
      },
      //cssClass: 'product-general-comment',
      size: 'auto',
      side: 'top',
      alignment: 'center',
    });
    popover.present();
  }

  validQuantity(quantity: string) {
    return /^\d+$/.test(quantity);
  }

  getSiteName(InventSiteId: string) {
    return environment.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }

  getOriginalSiteName(InventSiteId: string) {
    return environment.FMCM_SITES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }
}
