export class CurrencyInfoModel {
  constructor(private currencyCode: string, private txt: string) {}

  getCurrencyCode() {
    return this.currencyCode;
  }

  getTxt() {
    return this.txt;
  }

  getName() {
    return this.txt;
  }

  getId() {
    return this.currencyCode;
  }
}
