import { Pipe, PipeTransform } from '@angular/core';
import { PurchLinesInfoParam } from '../OOP/PurchLinesInfoParam.interface';

@Pipe({
  name: 'PORemissionFilter',
})
export class PurchOrderRemissionFilterPipe implements PipeTransform {
  transform(array: PurchLinesInfoParam[], text: string): any[] {
    if (text === '') {
      return array;
    }

    text = text.toLowerCase();

    return array.filter((item) => {
      return (
        item.purchId.toLowerCase().includes(text) ||
        item.objectName.toLowerCase().includes(text)
      );
    });
  }
}
