// Native - Plugins
import { Component } from '@angular/core';

@Component({
  selector: 'app-my-profile-restrictions',
  templateUrl: './my-profile-restrictions.component.html',
  styleUrls: ['./my-profile-restrictions.component.scss'],
})
export class MyProfileRestrictionsComponent {
  constructor() {}
}
