<app-nav-bar [title]="'Asociar O.C'" [start]="'GoBack'"></app-nav-bar>

<ion-content lines="none">
  <div class="box">
    <div lines="none" class="barTitle" style="padding: 10px">
      <ion-label>
        <h2 class="h2Select">Empresa</h2>
        <h3>
          {{ empresaSelected ? empresaSelected.getId() : "Sin Seleccionar" }}
        </h3>
        <p>{{ empresaSelected ? empresaSelected.getName() : "Sin Datos" }}</p>
      </ion-label>
    </div>

    <!--Filtros de OC-->
    <ion-searchbar
      type="text"
      placeholder="Buscar"
      (ionChange)="searchOC($event)"
    ></ion-searchbar>

    <div class="flex-container nowrap" style="display: flex">
      <ion-button
        class="filter-btn"
        (click)="filterPopover()"
        style="width: 100%"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        (click)="presentSortAlert()"
        style="width: 100%"
      >
        Ordenar
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>

    <div class="content">
      <ion-list *ngIf="PurchOrdersSearch" lines="none">
        <ion-card *ngFor="let element of PurchOrdersSearch">
          <ion-card-content style="padding: 0px">
            <ion-item>
              <ion-checkbox
                [(ngModel)]="element.checked"
                (click)="verifyCheck(element.purchId)"
              ></ion-checkbox>
              <ion-label style="padding: 10px">
                <div class="subContent">
                  <p class="purchOrder">{{ element.purchId }}</p>
                  <p class="createdDate">
                    <b>Fecha Creación:</b>
                    {{ element.createdDate | date : "dd/MM/yyyy" }}
                  </p>
                  <p class="amount">
                    <b>Total:</b>
                    {{
                      element.totalAmount | currency : "CRC" : "symbol-narrow"
                    }}
                  </p>
                </div>
              </ion-label>
            </ion-item>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </div>
  </div>

  <div class="containerButtonCustom">
    <hr class="dividerFinal" />
    <ion-button
      class="button_custom"
      expand="block"
      style="margin: 15px"
      type="submit"
      (click)="alertMakeAssign()"
    >
      Asociar OC
    </ion-button>
  </div>
</ion-content>
