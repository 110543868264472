<ion-grid>
  <ion-row center>
    <ion-col col-4 text-center style="display: flex; flex-direction: column">
      <ion-button
        (click)="reduceQuantity()"
        [disabled]="InPromo"
        class="button-blue"
      >
        <ion-icon
          style="width: 25px; height: 25px"
          name="remove-circle"
        ></ion-icon>
      </ion-button>
      <h6
        ion-text
        text-center
        class="h7 primaryColor"
        style="font-weight: bold"
      >
        {{ setSalePrice(item) }}
      </h6>
      <h6 ion-text text-center class="h7">{{ item.unitId }}</h6>
    </ion-col>
    <ion-col col-4>
      <h6 ion-text text-center class="h7">
        {{ item.salesQuantity }} {{ item.unitId }}
      </h6>
    </ion-col>
    <ion-col col-4 text-center style="display: flex; flex-direction: column">
      <ion-button class="button-blue" (click)="increaseQuantity()">
        <ion-icon
          style="width: 25px; height: 25px"
          name="add-circle"
        ></ion-icon>
      </ion-button>
      <h6
        ion-text
        text-center
        class="h7 primaryColor"
        style="font-weight: bold"
      >
        {{ setCurrency(item.totalSale) }}
      </h6>
      <!-- <h6 ion-text text-center class="h7 secondaryColor" style="font-weight: bold;" *ngIf="item.lnAmnt>0">{{setCurrency(item.taxAmount) + ' IVA'}}</h6> -->
      <h6 ion-text text-center class="h7">TOTAL</h6>
    </ion-col>
  </ion-row>
</ion-grid>
