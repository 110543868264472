import { Component } from '@angular/core';
import { ModalController, NavParams, PopoverController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-get-data2-edit',
  templateUrl: './get-data2-edit.component.html',
  styleUrls: ['./get-data2-edit.component.scss'],
})
export class GetData2EditComponent {
  title: string = '';
  actualData: string = '';
  newData: string = '';
  public loadingInfo: boolean = false;

  constructor(
    public navParams: NavParams,
    public viewCtrl: PopoverController,
    public toastCtrl: ToastController
  ) {
    this.title = this.navParams.get('title') as string;
    this.actualData = this.navParams.get('actualData') as string;
  }

  /**
   *
   */
  async editData() {
    if (this.newData.length > 0 && this.newData != null) {
      this.viewCtrl.dismiss(this.newData);
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Debe ingresar un dato válido!',
        duration: 2000,
        buttons: ['Ok'],
      });
      toast.present();
    }
  }
}
