import { Component, OnInit } from '@angular/core';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  Platform,
  PopoverController,
} from '@ionic/angular';

import { PurchLine } from '../../../businessCore/oc-models/PurchLine';
import { PurchOrder } from '../../../businessCore/oc-models/PurchOrder';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { LocalServiceOCService } from '../../../provider/Services/local-service-oc.service';
import { InfoFileOcPopComponent } from '../info-file-oc-pop/info-file-oc-pop.component';

import { environment } from 'src/environments/environment';
import { RejectedNoteOCComponent } from '../rejected-note-oc/rejected-note-oc.component';
import { DateTimeOperation } from './../../../businessCore/oc-models/DateTimeOperations';

@Component({
  selector: 'app-resumen-oc-vendor',
  templateUrl: './resumen-oc-vendor.component.html',
  styleUrls: ['./resumen-oc-vendor.component.scss'],
})
export class ResumenOcVendorComponent implements OnInit {
  infoPurch: PurchOrder;
  presentingMess = false;
  loader: HTMLIonLoadingElement;
  total: number;
  cantidadLineas: number;
  impuestos: number;
  fechaIni: string;
  fechaFin: string;
  containerType: string;
  puerto: string;
  naviera: string;
  sitio: string;
  almacen: string;
  src: string;
  showPdf = false;
  recordConfirmed: boolean = false;
  constructor(
    public navCtrl: NavController,
    public alertController: AlertController,
    private externalService: ExternalServiceOCService,
    private loadingCtrl: LoadingController,
    public popoverCtrl: PopoverController,
    private navParams: NavParams,
    private viewCtrl: ModalController,
    public plt: Platform,
    private localOcService: LocalServiceOCService,
    public modalCtrl: ModalController
  ) {
    this.infoPurch = this.navParams.get('purchOrd');
    this.recordConfirmed = this.navParams.get('recordConfirmed');
    if (this.recordConfirmed === undefined || this.recordConfirmed === null) {
      this.recordConfirmed = false;
    }
  }

  async ngOnInit() {
    // Trae los datos de las lineas de OC.
    await this.getDataLines();
  }

  ionViewDidEnter() {
    this.setData();
  }

  setData() {
    if (this.infoPurch !== null && this.infoPurch !== undefined) {
      this.total = this.infoPurch.total;
      this.cantidadLineas = this.infoPurch.cantidadLineas;
      this.impuestos = this.infoPurch.taxes;
      this.fechaIni = this.infoPurch.getFechaIni()
        ? DateTimeOperation.dateToStringFormart(this.infoPurch.getFechaIni())
        : '';
      this.fechaFin = this.infoPurch.getFechaFin()
        ? DateTimeOperation.dateToStringFormart(this.infoPurch.getFechaFin())
        : '';
      this.containerType =
        this.infoPurch.getContainerType() &&
        this.infoPurch.getContainerType() !== undefined
          ? this.infoPurch.getContainerType().getName()
          : '';

      this.puerto = this.infoPurch.getPuerto()
        ? (this.infoPurch.getPuerto().getId() as any)
        : '';

      this.naviera = this.infoPurch.getNaviera()
        ? (this.infoPurch.getNaviera().getId() as any)
        : '';

      this.sitio = this.infoPurch.getSite()
        ? (this.infoPurch.getSite().getId() as any)
        : '';

      this.almacen = this.infoPurch.getLocation()
        ? (this.infoPurch.getLocation().getId() as any)
        : '';
    }
  }

  swipeEvent(event) {
    if (event.direction === 2) {
      // Rechazar
      this.rejectPurchOrder();
    } else if (event.direction === 4) {
      // Confirmar
      this.confirmPurchOrder();
    }
  }

  confirmPurchOrder() {
    this.messageInfo('Atención', '¿Desea CONFIRMAR la orden de compra?', 1);
  }

  rejectPurchOrder() {
    this.messageInfo('Atención', '¿Desea RECHAZAR la orden de compra?', 2);
  }

  async sendNewStatePurchOrder(estado: number, type: number, nota?: string) {
    const typeSend = type === 1 ? 'confirmación' : 'rechazo';
    await this.showSpinnerLoading(
      'Enviando ' + typeSend + ' de orden de compra.'
    );
    try {
      // this.loader.setContent('Enviando ' + typeSend + ' de orden de compra.');
      const vatNum = await this.localOcService.getCedulaActual();
      const result = (await this.externalService.updateDocumentState(
        this.infoPurch.getIdOC(),
        estado,
        this.infoPurch.getDataArea().getId(),
        vatNum,
        nota
      )) as number;
      // if (result.toUpperCase() === this.infoPurch.getDocumentState().toUpperCase()
      if (result !== estado) {
        // No se pudo actualizar
        this.messageInfo(
          'Error',
          'No se pudo actualizar el estado de la orden',
          0
        );
      } else {
        this.infoPurch.setDocumentStateI(result);
        if (estado === 40) {
          // 40 === 'CONFIRMADO'
          this.messageInfo(
            'Atención',
            'Se ha actualizado el estado de la orden ' +
              this.infoPurch.getIdOC() +
              ' \n a ' +
              this.infoPurch.getDocumentStateS() +
              ' \n ¿Desea descargar el reporte de OC?',
            3
          );
        } else {
          this.messageInfo(
            'Atención',
            'Se ha actualizado el estado de la orden ' +
              this.infoPurch.getIdOC() +
              ' \n a ' +
              this.infoPurch.getDocumentStateS(),
            4
          );
        }
      }
    } catch (error) {
      this.messageInfo(
        'Error',
        'No se pudo actualizar el estado de la orden',
        0
      );
    }
    this.dissmissLoading();
  }

  // Verificar si el valor de actualización es 0
  executeAction(type: number) {
    switch (type) {
      case 1:
        this.sendNewStatePurchOrder(40, 1); // 40 ===  'CONFIRMADO'
        break;
      case 2:
        // Muestra el mensaje de rechazo y pide la razón.
        this.messageInfoReject();
        break;
      case 3:
        break;
      default:
        break;
    }
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action !== 0) {
      if (action === 3) {
        botones = [
          {
            text: 'SI',
            handler: () => {
              // Se llama la funcion que realiza el pdf.
              this.createReport();
            },
          },
          {
            text: 'NO',
            handler: () => {
              this.closePopOver(null);
            },
          },
        ];
      } else if (action === 4) {
        // me saca de la pantalla
        botones = [
          {
            text: 'ACEPTAR',
            handler: () => {
              this.closePopOver(null);
            },
          },
        ];
      } else {
        botones = [
          {
            text: 'SI',
            handler: () => {
              this.executeAction(action);
            },
          },
          { text: 'NO', handler: () => {} },
        ];
      }
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }

  async messageInfoReject() {
    if (!this.presentingMess) {
      this.presentingMess = true;

      const modal = await this.popoverCtrl.create({
        component: RejectedNoteOCComponent,
        cssClass: ['custom-popover', 'rejected-note-oc'],
      });
      await modal.present();
      // Se espera las opciones a actualizar, seleccionada por el usuario
      modal.onDidDismiss().then((res) => {
        let data = res.data as string;
        console.log('modal se cerró, mensaje: ', data);
        this.presentingMess = false;
        if (data !== null && data !== undefined) {
          //no es un dato null, no se cerro por error
          this.sendNewStatePurchOrder(20, 2, data); // 'RECHAZADO' === 20
        }
      });
    }
  }

  getCurrency(amount: number) {
    if (amount) {
      let decimal: number = Number.parseInt((amount + '').split('.', 2)[1]);

      if (decimal && decimal > 0) {
        const nt = Number(amount).toFixed(2);
        const deci = nt.toString();
        const deci2 = deci.substring(deci.length - 3, deci.length);
        const deci3 = deci.substring(0, deci.length - 3);
        const result = this.getFormat3Money(deci3);
        return result + deci2;
      } else {
        const nt = Number.parseInt((amount + '').split('.', 2)[0]);
        const nt2 = nt.toString();
        const result = this.getFormat3Money(nt2);
        return result;
      }
    } else {
      return 0;
    }
  }

  getFormat3Money(moneyInfo: string) {
    let result = '';
    if (moneyInfo !== null && moneyInfo !== undefined) {
      for (let i = 0; i < moneyInfo.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          result = moneyInfo[moneyInfo.length - 1 - i] + ' ' + result;
        } else {
          result = moneyInfo[moneyInfo.length - 1 - i] + result;
        }
      }
    }
    return result;
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  async createReport() {
    await this.showSpinnerLoading('Creando reporte de OC.');
    try {
      // const result = await
      if (this.infoPurch !== null && this.infoPurch !== undefined) {
        const result = await this.externalService.createReport(
          this.infoPurch.getIdOC(),
          this.infoPurch.getDataArea().getId(),
          ''
        );
        if (result !== 'error') {
          this.openReport(result as string);
        } else {
          this.messageInfo('Error', 'No se pudo generar el documento. ', 0);
        }
      }
    } catch (error) {
      this.messageInfo('Error', 'No se pudo generar el documento. ', 0);
    }
    this.dissmissLoading();
  }

  async getDataLines() {
    if (this.infoPurch !== null && this.infoPurch !== undefined) {
      await this.showSpinnerLoading('Cargando datos de líneas.');
      const resp = await this.externalService.getToLinesOC(
        this.infoPurch.getDataArea().getId(),
        this.infoPurch.getIdOC(),
        this.infoPurch.getTaxInclude()
      );
      this.dissmissLoading();
      if (resp !== null) {
        const listTmp = resp as PurchLine[];
        this.infoPurch.setLineList(listTmp);
        if (listTmp.length === 0) {
          this.messageInfo(
            'Alerta',
            'No hay datos de líneas para ' + this.infoPurch.getIdOC() + '.'
          );
        } else {
          this.infoPurch.calcularDatos();
          this.total = this.infoPurch.total;
          this.cantidadLineas = this.infoPurch.cantidadLineas;
          this.impuestos = this.infoPurch.taxes;
        }
      } else {
        this.messageInfo(
          'Alerta',
          'Se produjo un error al consultar la información de las líneas.'
        );
      }
    } else {
      this.messageInfo('Alerta', 'No hay datos de OC para consultar.');
    }
  }

  async closePopOver(objetSelected: any) {
    this.viewCtrl.dismiss({
      objSelected: objetSelected,
    });
  }

  async openReport(nameFile: string) {
    // Trae los datos de las lineas de OC;
    const src = `${environment.SP_API}/pdfReports/` + nameFile;
    // Dependiendo de la plataforma actuara distinto la app
    if (this.plt.is('mobileweb')) {
      // WEB
      // console.log('Open web', this.plt.is('mobileweb'), this.plt.platforms());
      window.open(
        src,
        '_blank',
        'Content-Type: application/pdf' +
          ', width=' +
          screen.availWidth +
          ',height=' +
          screen.availHeight
      );
      this.closePopOver(null); // cierra el modal
    } else {
      /// MOBILE*/
      // console.log('Open mobile', this.plt.is('mobileweb'), this.plt.platforms());
      const options: InAppBrowserOptions = {
        location: 'yes', //Or 'no'
        hidden: 'no', //Or  'yes'
        clearcache: 'yes',
        clearsessioncache: 'yes',
        zoom: 'yes', //Android only ,shows browser zoom controls
        hardwareback: 'yes',
        mediaPlaybackRequiresUserAction: 'no',
        shouldPauseOnSuspend: 'no', //Android only
        closebuttoncaption: 'Close', //iOS only
        disallowoverscroll: 'no', //iOS only
        toolbar: 'yes', //iOS only
        enableViewportScale: 'no', //iOS only
        allowInlineMediaPlayback: 'no', //iOS only
        presentationstyle: 'pagesheet', //iOS only
        fullscreen: 'yes', //Windows only
      };
      let browserInst = new InAppBrowser();
      let browser = browserInst.create(src, '_system', options);
      browser.show();
      this.closePopOver(null);
    }
  }

  // Abre el componente de archivos
  async openSendFile() {
    if (this.infoPurch !== null && this.infoPurch !== undefined) {
      const vatNumAct = await this.localOcService.getCedulaActual();
      const modal = await this.modalCtrl.create({
        component: InfoFileOcPopComponent,
        componentProps: {
          fileList: [],
          cantSendFile: !this.recordConfirmed,
          ocId: this.infoPurch.getIdOC(),
          vatnum: vatNumAct,
          company: this.infoPurch.getDataArea().getId(),
          type: 2,
        },
        backdropDismiss: false,
      });

      await modal.present();
    }
  }
}
