export class SitioModel {
  constructor(private siteId: string, private name: string) {}

  getId() {
    return this.siteId;
  }

  getName() {
    return this.name;
  }

  setValues(newId: string, newName: string) {
    this.siteId = newId;
    this.name = newName;
  }
}
