<app-product-comment
  *ngIf="tab == 'ProductCommentComponent'"
></app-product-comment>

<app-product-my-comments
  *ngIf="tab == 'ProductMyCommentsComponent'"
></app-product-my-comments>

<ion-item
  style="--background: #f1f1f1 !important; border-top: 1px solid #e7e7e7"
>
  <ion-button
    class="tab-button"
    (click)="setTab('ProductCommentComponent')"
    [ngStyle]="{
      color: tab == 'ProductCommentComponent' ? '#223e99' : 'black',
      fontSize: tab == 'ProductCommentComponent' ? '13px' : '11px'
    }"
  >
    <div class="custom-button-content">
      <ion-icon
        slot="start"
        name="people-outline"
        class="icon-style"
      ></ion-icon>
      <ion-label>Comentarios</ion-label>
    </div>
  </ion-button>

  <ion-button
    class="tab-button"
    (click)="setTab('ProductMyCommentsComponent')"
    [ngStyle]="{
      color: tab == 'ProductMyCommentsComponent' ? '#223e99' : 'black',
      fontSize: tab == 'ProductMyCommentsComponent' ? '13px' : '11px'
    }"
  >
    <div class="custom-button-content">
      <ion-icon
        slot="start"
        name="star-half-outline"
        class="icon-style"
      ></ion-icon>
      <ion-label>Mi Valoración</ion-label>
    </div>
  </ion-button>
</ion-item>
