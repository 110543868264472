<div style="display: flex; flex-direction: column; padding: 2%">
  <div style="display: flex; align-items: center">
    <label style="width: 100%" class="h7"><b>Observaciones</b></label>
    <ion-button ion-button icon-only (click)="close()" clear>
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </div>
  <textarea
    rows="10"
    cols="50"
    style="margin: 0; border-style: solid; background: white"
    placeholder="Escriba la nota"
    maxlength="1000"
    [(ngModel)]="note"
    name="note"
    autocomplete="on"
    autocorrect="on"
  >
  </textarea>
  <div style="margin-top: 2%; margin-bottom: 2%; width: 100%">
    <ion-button
      color="secondary"
      class="btnPosition"
      (click)="sendNotePurchOrder()"
    >
      Aceptar
    </ion-button>
  </div>
</div>
