<div>
  <ion-item *ngIf="actualTrip && actualTrip.getFMTrip().length > 0">
    <ion-label>
      <b>
        <h2 class="h2Select" style="font-weight: 900">
          Viaje {{ actualTrip.getFMTrip() }}
        </h2>
      </b>
    </ion-label>
  </ion-item>
  <ion-list>
    <ion-item (click)="emitSelectedBar(1)" *ngIf="actualTrip">
      <ion-label>
        <h2 class="h2Select">Compañia</h2>
        <h3>{{ actualTrip.getDataArea() }}</h3>
        <!--<p>{{dataAreaSelected.getName()}}</p>-->
      </ion-label>
      <ion-icon name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item (click)="getInfoVehicule()" *ngIf="actualTrip">
      <ion-label>
        <h2 class="h2Select">Vehículo</h2>
        <div
          style="display: flex"
          *ngIf="actualTrip.getVehicule().getPlacVehicle() !== ''"
        >
          <h3 style="width: 100%">
            {{ actualTrip.getVehicule().getPlacVehicle() }} -
            {{ actualTrip.getVehicule().getTypePropertyStr() }}
          </h3>
          <!-- <h3 style="width: 100%;">{{actualTrip.getVehicule().getTypePropertyStr()}}</h3>-->
        </div>
      </ion-label>

      <ion-icon name="caret-down-outline" item-end></ion-icon>
    </ion-item>

    <ion-item class="timer" *ngIf="maxDate">
      <ion-label><h2 class="h2Select">Fecha:</h2></ion-label>

      <ion-datetime-button datetime="datetime"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetime"
            presentation="date"
            [showDefaultButtons]="true"
            [disabled]="!cantEdit"
            displayFormat="DD/MM/YYYY"
            [(ngModel)]="todayS"
            placeholder="Seleccione la fecha"
            doneText="Aceptar"
            cancelText="Cancelar"
            (ionChange)="emitDays()"
            [max]="maxDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>

    <ion-item class="timer" *ngIf="maxDate">
      <ion-label><h2 class="h2Select">Hora inicio:</h2></ion-label>

      <ion-datetime-button datetime="datetimeStart"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            id="datetimeStart"
            presentation="time"
            [showDefaultButtons]="true"
            [disabled]="!cantEdit"
            displayFormat="HH:mm"
            [(ngModel)]="todayS"
            placeholder="Seleccione la hora"
            doneText="Aceptar"
            cancelText="Cancelar"
            (ionChange)="emitDays()"
            [max]="maxDate"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>

    <!--true-->
    <div style="display: flex">
      <app-input-number
        [titulo]="'Kilometraje Inicial'"
        [cantidadObjeto]="kiloInit ? kiloInit : 0"
        (getNumber)="getNumberCantidad($event)"
        [cantEdit]="setInitKilo"
        style="width: 100%"
      >
      </app-input-number>
      <ion-button
        style="width: 10%"
        (click)="openCamera(1)"
        *ngIf="showOptionAdj && setInitKilo"
        fill="clear"
      >
        <i style="font-size: large" [ngClass]="getClickImage(1)"></i>
      </ion-button>
    </div>
    <ion-item>
      <ion-select
        label="Tipo"
        label-placement="floating"
        class="floating"
        [(ngModel)]="optionSelectedK"
        okText="Aceptar"
        cancelText="Cancelar"
        placeholder="Seleccione el tipo"
        disabled="{{ !setInitKilo }}"
        (ionChange)="setDataService()"
      >
        <ion-select-option [value]="0">Kilómetro</ion-select-option>
        <ion-select-option [value]="1">Milla</ion-select-option>
      </ion-select>
    </ion-item>

    <div>
      <ion-item (click)="openCloseInitRegister()">
        <ion-label><h1 class="myH1">Registrar Origen Viaje</h1></ion-label>
        <ion-icon item-end name="add" *ngIf="!initRegisterShow"></ion-icon>
        <ion-icon item-end name="remove" *ngIf="initRegisterShow"></ion-icon>
      </ion-item>
      <div class="divCantProd" *ngIf="initRegisterShow">
        <ion-radio-group
          value="planeado"
          [(ngModel)]="posSelected"
          [disabled]="!canOriginB"
        >
          <ion-list>
            <div class="option" *ngIf="showOptionClient">
              <ion-item>
                <ion-label>
                  <h2 class="h2Select">Origen del viaje</h2>
                  <p *ngIf="!showNameOrigen()">Nombre del punto de partida</p>
                  <p *ngIf="showNameOrigen()" text-wrap>
                    {{ actualTrip.getPuntoInit().getPartyNumber() }}
                  </p>
                  <p *ngIf="showNameOrigen()" text-wrap>
                    {{ actualTrip.getPuntoInit().getPartyName() }}
                  </p>
                </ion-label>
                <ion-radio value="planeado"></ion-radio>
              </ion-item>
              <div *ngIf="posSelected === 'planeado' && canOriginB">
                <ion-item (click)="emitSelectedBar(3)">
                  <ion-icon name="caret-down-outline" item-end></ion-icon>
                </ion-item>
              </div>
            </div>

            <div class="option" *ngIf="showOptionAdj">
              <ion-item>
                <ion-label>
                  <h2 class="h2Select">Otro origen</h2>
                  <p *ngIf="showDescripOrig()" text-wrap>
                    {{ actualTrip.getPuntoInit().getDescripcion() }}
                  </p>
                </ion-label>

                <ion-radio value="noPlaneado" item-left></ion-radio>
              </ion-item>
              <div
                *ngIf="posSelected === 'noPlaneado' && canOriginB"
                class="divGPS"
              >
                <ion-button
                  color="gray2"
                  style="
                    color: white;
                    background-color: var(--ion-color-primary);
                  "
                  (click)="openGPSMap(0)"
                >
                  Punto GPS
                </ion-button>
              </div>
            </div>
          </ion-list>
        </ion-radio-group>
      </div>

      <div style="text-align: center">
        <ion-button
          style="width: 75%"
          [disabled]="!initButtonTrip"
          [ngClass]="getStyle()"
          (click)="openCreateEditTrip()"
        >
          Iniciar Viaje
        </ion-button>
      </div>
    </div>

    <div>
      <ion-item (click)="openCloseDest()">
        <ion-label><h1 class="myH1">Registrar Destino Viaje</h1></ion-label>
        <ion-icon item-end name="add" *ngIf="!endRegisterShow"></ion-icon>
        <ion-icon item-end name="remove" *ngIf="endRegisterShow"></ion-icon>
      </ion-item>
      <div class="divCantProd" *ngIf="endRegisterShow">
        <ion-radio-group
          value="planeado"
          [(ngModel)]="posSelectedEnd"
          [disabled]="!canDestB"
        >
          <ion-list>
            <div class="option" *ngIf="showOptionClient">
              <ion-item>
                <ion-label>
                  <h2 class="h2Select">Destino del viaje</h2>
                  <p *ngIf="!showNameFin()">Nombre del destino de viaje</p>
                  <p *ngIf="showNameFin()" text-wrap>
                    {{ actualTrip.getPuntoFinal().getPartyNumber() }}
                  </p>
                  <p *ngIf="showNameFin()" text-wrap>
                    {{ actualTrip.getPuntoFinal().getPartyName() }}
                  </p>
                </ion-label>
                <ion-radio value="planeado"></ion-radio>
              </ion-item>
              <div *ngIf="posSelectedEnd === 'planeado' && canDestB">
                <ion-item (click)="emitSelectedBar(4)">
                  <ion-icon
                    name="caret-down-outline"
                    item-end
                    *ngIf="canDestB"
                  ></ion-icon>
                </ion-item>
              </div>
            </div>

            <div class="option" *ngIf="showOptionAdj">
              <ion-item>
                <ion-label>
                  <h2 class="h2Select">Otro destino</h2>
                  <p *ngIf="showDescripFin()" text-wrap>
                    {{ actualTrip.getPuntoFinal().getDescripcion() }}
                  </p>
                </ion-label>
                <ion-radio value="noPlaneado"></ion-radio>
              </ion-item>
              <div
                *ngIf="posSelectedEnd === 'noPlaneado' && canDestB"
                class="divGPS"
              >
                <ion-button
                  style="
                    color: white;
                    background-color: var(--ion-color-primary);
                  "
                  color="gray2"
                  (click)="openGPSMap(2)"
                >
                  Punto GPS
                </ion-button>
              </div>
            </div>
          </ion-list>
        </ion-radio-group>
      </div>

      <div style="text-align: center">
        <ion-button
          ion-button
          style="width: 75%"
          [disabled]="!endButtonTrip"
          [ngClass]="getStyle2()"
          (click)="endTripSave()"
        >
          Completar Viaje
        </ion-button>
      </div>

      <div *ngIf="endTrip">
        <div style="display: flex">
          <app-input-number
            [titulo]="'Kilometraje Final'"
            [cantidadObjeto]="kiloEnd ? kiloEnd : 0"
            (getNumber)="getNumberCantidad2($event)"
            [cantEdit]="setEndKilo"
            style="width: 100%"
          >
          </app-input-number>
          <ion-button
            style="width: 10%"
            (click)="openCamera(2)"
            *ngIf="showOptionAdj && setEndKilo"
            fill="clear"
          >
            <i style="font-size: large" [ngClass]="getClickImage(2)"> </i>
            <!-- class="fas fa-paperclip"></i> -->
          </ion-button>
        </div>

        <ion-item class="timer" *ngIf="maxDate">
          <ion-label><h2 class="h2Select">Hora final:</h2></ion-label>

          <ion-datetime-button datetime="datetimeEnd"></ion-datetime-button>

          <ion-modal [keepContentsMounted]="true">
            <ng-template>
              <ion-datetime
                id="datetimeEnd"
                presentation="time"
                [showDefaultButtons]="true"
                [disabled]="!cantEdit"
                displayFormat="HH:mm"
                [(ngModel)]="todayEndS"
                placeholder="Seleccione la fecha"
                doneText="Aceptar"
                cancelText="Cancelar"
                (ionChange)="emitDays()"
                [max]="maxDate"
              ></ion-datetime>
            </ng-template>
          </ion-modal>
        </ion-item>
      </div>
    </div>
  </ion-list>
</div>
