import { Component } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { AssetRequest } from '../../businessCore/AssetRequest';

@Component({
  selector: 'app-asset-request-action',
  templateUrl: './asset-request-action.component.html',
  styleUrls: ['./asset-request-action.component.scss'],
})
export class AssetRequestActionComponent {
  requestList: AssetRequest[]; // Lista a filtrar
  typeAction: number;
  rejectMotive: string;

  /**
   * Class constructor with these injections:
   * @param viewCtrl Access various features and information about the current view.
   * @param params Object that exists on a page and can contain data for that particular view.
   */
  constructor(public viewCtrl: PopoverController, private params: NavParams) {
    this.requestList = this.params.get('requestList');
    this.typeAction = this.params.get('typeAction');
    this.rejectMotive = '';

    //console.log(this.requestList)
  }

  /**
   * Metodo utilizado para cerrar el popup de filtrado y efectuar
   * los cambios deseados para que el sistema muestre los productos
   * que coincidan con la selección
   */
  dismiss(param: number) {
    if (param) {
      this.viewCtrl.dismiss();
    } else {
      this.viewCtrl.dismiss({
        typeAction: this.typeAction,
        motive: this.rejectMotive,
      });
    }
  }
}
