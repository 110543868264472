import { Component } from '@angular/core';
import { AlertController, NavController, NavParams } from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { environment } from 'src/environments/environment';
import { Money } from '../../GeneralUtilis/money';
import { Direction } from '../../businessCore/Direction';
import { RegisterData } from '../../businessCore/RegisterData';
import { RestrictedDate } from '../../businessCore/RestrictedDate';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { OrderHeader } from './../../businessCore/OrderHeader';

@Component({
  selector: 'app-shopping-car-delivery',
  templateUrl: './shopping-car-delivery.component.html',
  styleUrls: ['./shopping-car-delivery.component.scss'],
})
export class ShoppingCarDeliveryComponent {
  // atributo con el conjunto de datos de la orden sobre la que se esta trabajando
  ordHerder: OrderHeader = new OrderHeader();
  // atributo con el total de la orden, utilizado para desplegarlo en la parte inicial de la ventana
  totalOrder: number = 0;
  // nombre de la direccion seleccionada
  addressNameSelected: string = '';
  // arreglo con el conjunto de datos del cliente
  register_data: RegisterData = new RegisterData();
  // arreglo base con todas las posibles fechas de las diferentes direcciones
  availableDates: {
    RecID: number;
    Dates: { Date: Date; DateFmt: string }[];
  }[] = [];
  // conjunto de fechas filtradas segun la direccion que se tiene seleccionada
  addressAvailableDates: {
    RecID: number;
    Dates: { Date: Date; DateFmt: string }[];
  } = { RecID: -1, Dates: [] };
  // boolean utilizado para indicar si el item de fecha esta habilitado o no
  disabledDeliveryDate: boolean = true;
  // Fechas invalidas para meter un pedido
  invalidDates: RestrictedDate[] = [];
  // Fechas de retira
  retDeliveryDates: { Date: Date; DateFmt: string }[] = [];

  /**
   * Class constructor with these injections:=
   * @param params object that exists on a page and can contain data for that particular view.
   * @param storage to store key/value pairs and JSON objects.
   * @param alertCtrl dialog that presents users with information or collects information from the user using inputs.
   */
  constructor(
    private params: NavParams,
    private storage: StorageService,
    private alertCtrl: AlertController,
    public apiProvider: webServiceProvider,
    private navService: NavigationService,
    private navController: NavController
  ) {
    this.ordHerder = this.params.get('ordHeader') as OrderHeader;

    this.storage.get('register_data').then((data) => {
      this.register_data = JSON.parse(data);
      if (this.register_data == null) {
        this.register_data = new RegisterData();
      }

      this.setAvailableDates();

      this.ordHerder.deliveryDate = '';
      this.ordHerder.addressId = 0;
    });
    this.calculateTotal();
  }

  setRetDeliveryDates() {
    for (let index = 0; index < 5; index++) {
      let tmpDate = new Date(new Date().getTime() + (2 + index) * 86400000);

      // Si es Domingo, coloca el sexto dia en vez del domingo
      if (tmpDate.getDay() == 0) {
        tmpDate = new Date(new Date().getTime() + 7 * 86400000);
      }

      this.retDeliveryDates.push({
        Date: tmpDate,
        DateFmt: this.getDateFmt(tmpDate),
      });
    }

    this.retDeliveryDates.sort(
      (d1, d2) => d1.Date.getTime() - d2.Date.getTime()
    );

    // Quitar fechas según fin de semana
    // days[0] = "Domingo";
    // days[1] = "Lunes";
    // days[2] = "Martes";
    // days[3] = "Miércoles";
    // days[4] = "Jueves";
    // days[5] = "Viernes";
    // days[6] = "Sábado";
    this.retDeliveryDates.forEach((d, index) => {
      // Igual a Lunes
      if (d.Date.getDay() == 1) {
        let actualD = new Date();
        // Si fecha actual Igual a Sabado o domingo, quitar fecha de entrega
        if (actualD.getDay() == 6 || actualD.getDay() == 0) {
          this.retDeliveryDates.splice(index, 1);
        }
      }
    });

    // Validar hora de solicitud maximo a las 5pm de cada día
    this.retDeliveryDates.forEach((d, index) => {
      let actualD = new Date(new Date().getTime() + 2 * 86400000);
      let dAux = new Date(d.Date);

      if (actualD.getHours() >= 17) {
        actualD.setHours(0, 0, 0, 0);
        dAux.setHours(0, 0, 0, 0);

        if (Math.abs(dAux.getTime() - actualD.getTime()) <= 172800000) {
          this.retDeliveryDates.splice(index, 1);
        }
      }
    });

    // Validar fechas inválidas por restricciones de feriados o lo que se configure a nivel del WS
    this.invalidDates.forEach((invalidDate) => {
      let date = new Date(
        +invalidDate.fecha.slice(0, 4),
        +invalidDate.fecha.slice(4, 6) - 1,
        +invalidDate.fecha.slice(6, 8)
      );
      this.retDeliveryDates.forEach((result, index) => {
        // Si la fecha es igual a una invalida, eliminela de las posibles
        if (
          date.getDate() == result.Date.getDate() &&
          date.getMonth() == result.Date.getMonth() &&
          date.getFullYear() == result.Date.getFullYear()
        ) {
          if (index > -1) {
            this.retDeliveryDates.splice(index, 1);
          }
        }
      });
    });
  }

  /**
   * Metodo utilizado para determinar todas las fechas disponibles
   * para entrega segun las direcciones que tiene registradas el cliente
   */
  setAvailableDates() {
    // Get restricted dates/ holidays
    this.apiProvider.getRestrictedDate().subscribe((data) => {
      this.invalidDates = data as RestrictedDate[];

      this.setRetDeliveryDates();

      // Get delivery dates (+3 weeks)
      this.register_data.directions.forEach((element) => {
        let tmpAvailableDates: {
          RecID: number;
          Dates: { Date: Date; DateFmt: string }[];
        } = { RecID: -1, Dates: [] };
        tmpAvailableDates.RecID = element.recId;

        let PrincipalDeliveryDay = element.principalDeliveryDay;

        let tmpDate = new Date(new Date().getTime() + 2 * 86400000);
        let tmpPDD = new Date(
          tmpDate.getTime() +
            this.getDayDistance(PrincipalDeliveryDay, tmpDate.getDay()) *
              86400000
        );
        let tmpPDD2 = new Date(tmpPDD.getTime() + 7 * 86400000);
        let tmpPDD3 = new Date(tmpPDD2.getTime() + 7 * 86400000);

        let datesResult = this.setPlanDeliveryDates(element, tmpAvailableDates);

        if (datesResult.Dates.length <= 0) {
          datesResult.Dates.push({
            Date: tmpPDD,
            DateFmt: this.getDateFmt(tmpPDD),
          });
          datesResult.Dates.push({
            Date: tmpPDD2,
            DateFmt: this.getDateFmt(tmpPDD2),
          });
          datesResult.Dates.push({
            Date: tmpPDD3,
            DateFmt: this.getDateFmt(tmpPDD3),
          });
        }

        // Quitar fechas según fin de semana
        // days[0] = "Domingo";
        // days[1] = "Lunes";
        // days[2] = "Martes";
        // days[3] = "Miércoles";
        // days[4] = "Jueves";
        // days[5] = "Viernes";
        // days[6] = "Sábado";
        datesResult.Dates.forEach((d, index) => {
          // Igual a Lunes
          if (d.Date.getDay() == 1) {
            let actualD = new Date();
            // Si fecha actual Igual a Sabado o domingo, quitar fecha de entrega
            if (actualD.getDay() == 6 || actualD.getDay() == 0) {
              datesResult.Dates.splice(index, 1);
            }
          }
        });

        // Validar hora de solicitud maximo a las 5pm de cada día
        datesResult.Dates.forEach((d, index) => {
          let actualD = new Date(new Date().getTime() + 2 * 86400000);
          let dAux = new Date(d.Date);

          if (actualD.getHours() >= 17) {
            actualD.setHours(0, 0, 0, 0);
            dAux.setHours(0, 0, 0, 0);

            if (Math.abs(dAux.getTime() - actualD.getTime()) <= 172800000) {
              datesResult.Dates.splice(index, 1);
            }
          }
        });

        let datesResultAux = datesResult;

        this.invalidDates.forEach((invalidDate) => {
          let date = new Date(
            +invalidDate.fecha.slice(0, 4),
            +invalidDate.fecha.slice(4, 6) - 1,
            +invalidDate.fecha.slice(6, 8)
          );
          console.log(date);

          datesResultAux.Dates.forEach((result) => {
            // Si la fecha es igual a una invalida, eliminela de las posibles
            if (
              date.getDate() == result.Date.getDate() &&
              date.getMonth() == result.Date.getMonth() &&
              date.getFullYear() == result.Date.getFullYear()
            ) {
              const index = datesResult.Dates.indexOf(result, 0);
              if (index > -1) {
                datesResult.Dates.splice(index, 1);
              }
            }
          });
        });

        this.availableDates.push(datesResult);
      });
    });
  }

  /**
   * Metodo utilizado para dar formato a una fecha en especifico
   * @param date fecha con todos los datos asociados (dia, mes, año, etc...)
   * @returns string con el formato de la fecha "<DayName> <Day> de <Mes> del <Año>"
   */
  getDateFmt(date: Date) {
    return (
      this.getWeekDay(date.getDay()) +
      ' ' +
      date.getDate() +
      ' de ' +
      this.getMonthName(date.getMonth()) +
      ' del ' +
      date.getFullYear()
    );
  }

  /**
   * Metodo utilizado para obtener el dia de la semana segun el numero
   * @param day numero con el numero de dia al que se le quiere consultar el nombre
   */
  getWeekDay(day: number) {
    let days = new Array(7);
    days[0] = 'Domingo';
    days[1] = 'Lunes';
    days[2] = 'Martes';
    days[3] = 'Miércoles';
    days[4] = 'Jueves';
    days[5] = 'Viernes';
    days[6] = 'Sábado';

    return days[day];
  }

  /**
   * Metodo utilizado para consultar el nombre de un mes segun un numero de identificacion
   * @param month numero con el mes que se esta consultando
   */
  getMonthName(month: number) {
    let days = new Array(12);
    days[0] = 'Enero';
    days[1] = 'Febrero';
    days[2] = 'Marzo';
    days[3] = 'Abril';
    days[4] = 'Mayo';
    days[5] = 'Junio';
    days[6] = 'Julio';
    days[7] = 'Agosto';
    days[8] = 'Setiembre';
    days[9] = 'Octubre';
    days[10] = 'Noviembre';
    days[11] = 'Diciembre';

    return days[month];
  }

  /**
   * Metodo utilizado para obtener la distancia entre un dia y otro,
   * esto se utiliza para poder determinar el 'salto' en dias que debe
   * agregarse a la fecha actual con el fin de obtener las siguientes tres fechas
   * de entrega para el cliente en cuestion
   * @param deliveryDay dia de entrega programado para el cliente
   * @param limitDay dia limite (fecha actual +2) sobre el que se deben construir las siguientes
   * fechas de entrega para el cliente.
   */
  getDayDistance(deliveryDay, limitDay) {
    if (deliveryDay == limitDay) {
      return 0;
    } else {
      if (deliveryDay > limitDay) {
        return deliveryDay - limitDay;
      } else {
        return 7 - (limitDay - deliveryDay);
      }
    }
  }

  /**
   * Metodo utilizado para consultar el total de la orden
   * segun los montos de los diferentes productos
   */
  calculateTotal() {
    this.totalOrder = 0;
    this.ordHerder.totalSale = 0;
    this.ordHerder.ordLine.forEach((item) => {
      // this.totalOrder += item.totalSale;
      // this.ordHerder.totalSale += item.totalSale;
      if (item.promoId != '') {
        this.totalOrder += item.lnAmnt;
        this.ordHerder.totalSale += item.lnAmnt;
      } else {
        this.totalOrder = this.totalOrder + item.totalSale;
        this.ordHerder.totalSale = this.ordHerder.totalSale + item.totalSale;
      }
    });
  }

  /**
   * Metodo utilizado para obtener el formato de moneda
   * de una determinada cantidad (numero)
   * @param Amount
   */
  setCurrency(Amount: number) {
    let formatter = new Money(Amount);
    return formatter.getCurrency();
  }

  /**
   * Metodo utilizado para presentar una alerta con un mensaje y titulo determinado
   * @param title string con el titulo que se va presentar en la alerta
   * @param msg string con el mensaje que se va presentar en la alerta
   */
  async presentAlert(title: string, msg: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }

  /**
   *
   */
  getAddressName() {
    if (this.ordHerder.deliveryMethod == 'Inteca') {
      let result = '';
      this.register_data.directions.forEach((data) => {
        if (this.ordHerder.addressId == data.recId) {
          result = data.direction;
        }
      });
      return result;
    } else {
      return 'Retira Mercaderia en Grupo Inteca Cartago';
    }
  }

  /**
   * Reinicia la fecha de entrega cuando el método de entrega se cambia
   */
  changeMethod() {
    this.ordHerder.deliveryDate = '';
  }

  /**
   *
   * @param directionId
   */
  selectAddress(directionId: number) {
    this.ordHerder.deliveryDate = '';

    let datesAux = this.availableDates
      .filter((address) => address.RecID == directionId)
      .pop();
    if (datesAux) {
      this.addressAvailableDates = datesAux;

      // Habilitar el campo de fecha
      this.disabledDeliveryDate = false;
    }

    let address = this.register_data.directions
      .filter((dir) => dir.recId == directionId)
      .pop();
    if (address) {
      this.addressNameSelected = address.direction;
    }
  }

  getDateFromStr(date: string) {
    // Expected Date Format -->> 25/10/2019 12:00:00 p.m.
    try {
      if (date) {
        let splitDate = date.split(' ');
        if (splitDate.length >= 3) {
          let datePart = splitDate[0];
          let hourPart = splitDate[1];
          let periodPart = splitDate[2];

          let dateStr = datePart.split('/');
          let hourStr = hourPart.split(':');
          let periodStr = periodPart.split('.');

          if (periodStr[0] == 'p') {
            // PM
            // month is 0-based, that's why we need dateStr[1] - 1
            if (+hourStr[0] == 12 && +hourStr[1] == 0 && +hourStr[2] == 0) {
              return new Date(
                +dateStr[2],
                +dateStr[1] - 1,
                +dateStr[0],
                12,
                0,
                0
              );
            } else {
              return new Date(
                +dateStr[2],
                +dateStr[1] - 1,
                +dateStr[0],
                +hourStr[0] + 12,
                +hourStr[1],
                +hourStr[2]
              );
            }
          } else {
            //AM
            // month is 0-based, that's why we need dateStr[1] - 1
            return new Date(
              +dateStr[2],
              +dateStr[1] - 1,
              +dateStr[0],
              +hourStr[0],
              +hourStr[1],
              +hourStr[2]
            );
          }
        }
      }
    } catch (error) {}

    return null;
  }

  /**
   *
   * @param addressInfo
   * @param addressID
   */
  setPlanDeliveryDates(
    addressInfo: Direction,
    tmpAvailableDates: {
      RecID: number;
      Dates: { Date: Date; DateFmt: string }[];
    }
  ) {
    if (addressInfo != null) {
      addressInfo.deliveryDays.split('#').forEach((element) => {
        let datesAux = element.split('@');
        let custDelDate = this.getDateFromStr(datesAux[2]); //0 - orderDate / 1 - planStartDate / 2 - custDelDate
        let orderDate = this.getDateFromStr(datesAux[0]); //0 - orderDate / 1 - planStartDate / 2 - custDelDate

        if (custDelDate && orderDate) {
          try {
            // get the current date
            let actualDate = new Date();

            // validate that current <orderDate> is greater than the <actualDate>
            if (orderDate.getTime() > actualDate.getTime()) {
              let isNewDate = true;

              tmpAvailableDates.Dates.forEach((tmpDate) => {
                if (
                  tmpDate.Date.getDate() == custDelDate.getDate() &&
                  tmpDate.Date.getMonth() == custDelDate.getMonth() &&
                  tmpDate.Date.getFullYear() == custDelDate.getFullYear()
                ) {
                  isNewDate = false;
                }
              });

              if (isNewDate) {
                tmpAvailableDates.Dates.push({
                  Date: custDelDate,
                  DateFmt: this.getDateFmt(custDelDate),
                });
              }
            }
          } catch (error) {}
        } // end null dates
      });
    }

    tmpAvailableDates.Dates = tmpAvailableDates.Dates.sort((a, b) => {
      if (
        a.Date.getUTCDate() == b.Date.getUTCDate() &&
        a.Date.getUTCMonth() == b.Date.getUTCMonth() &&
        a.Date.getUTCFullYear() == b.Date.getUTCFullYear()
      ) {
        return 0;
      } else if (
        a.Date.getUTCFullYear() > b.Date.getUTCFullYear() ||
        (a.Date.getUTCFullYear() == b.Date.getUTCFullYear() &&
          a.Date.getUTCMonth() > b.Date.getUTCMonth()) ||
        (a.Date.getUTCFullYear() == b.Date.getUTCFullYear() &&
          a.Date.getUTCMonth() == b.Date.getUTCMonth() &&
          a.Date.getUTCDate() > b.Date.getUTCDate())
      ) {
        return 1;
      } else {
        return -1;
      }
    });

    return tmpAvailableDates;
  }

  validMidday(actualDate: Date) {
    return actualDate.getUTCHours() <= 12 ? true : false;
  }

  /**
   * Method to get the days diff between two dates
   * @param date1 attribute with the first date
   * @param date2 attribute with the second date
   */
  getDateDiff(date1: Date, date2: Date) {
    let diff = Math.abs(date1.getTime() - date2.getTime());
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  }

  /**
   * Method to get the service plan type, where 1=24hrs and 2=48hrs
   * @param orderDate Attribute with the date to get the order of the client
   * @param custDelDate Attribute with the delivery date of the orders
   */
  getServicePlanType(orderDate: Date, custDelDate: Date) {
    orderDate.setUTCHours(0, 0, 0, 0);
    custDelDate.setUTCHours(0, 0, 0, 0);

    let spType = this.getDateDiff(orderDate, custDelDate);

    // 1=24hrs plan | 2=48hrs plan
    return spType <= 1 ? 1 : 2;
  }

  /**
   *
   */
  NextStep() {
    let day: number = new Date(this.ordHerder.deliveryDate).getDay();
    if (day != 0) {
      this.navService.navigateTo(NavigationRoutes.ShoppingCarPaymentComponent, {
        ordHeader: this.ordHerder,
      });
    } else {
      this.presentAlert('Error', 'El domingo no es un día de entrega valido');
    }
  }

  /**
   * Metodo utilizado para retornar la aplicacion hacia la ventana
   * anterior (Carrito de compras) retornando el estado actual de la orden
   */
  dismiss() {
    this.navController.pop();
  }

  /**
   * Metodo utilizado para validar que el formulario
   * se encuentra completado correctamente
   */
  validForm() {
    return this.ordHerder.addressId == 0 || this.ordHerder.deliveryDate == '';
  }

  getSiteName(InventSiteId: string) {
    return environment.FMCM_SITES_NAMES.filter(function (site) {
      return site.InventSiteId == InventSiteId;
    })[0].SiteName;
  }
}
