import { Component } from '@angular/core';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  Platform,
} from '@ionic/angular';
import { InfoImage } from '../../../businessCore/trip-models/InfoImage';
import { ExternalServiceOCService } from '../../../provider/Services/external-service-oc.service';
import { FileOCInfo } from './../../../businessCore/oc-models/FileOCInfo';

@Component({
  selector: 'app-info-file-oc-pop',
  templateUrl: './info-file-oc-pop.component.html',
  styleUrls: ['./info-file-oc-pop.component.scss'],
})
export class InfoFileOcPopComponent {
  segmentModel: string;
  text: string;
  loader: HTMLIonLoadingElement;
  listOfFileToSend: FileOCInfo[] = [];
  listOfFileSend: FileOCInfo[] = [];
  listOfFileReceived: FileOCInfo[] = [];

  canSend: boolean = false;

  cedulaAct: string = '';
  companyAct: string = '';
  ocIdAct: string = '';
  typeView: number = 0; // 1 CREADOR, 2 CONFIRMADOR, 3 aprobador
  presentingMess: boolean = false;

  constructor(
    private viewCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private navParams: NavParams,
    private extOCServ: ExternalServiceOCService,
    public plt: Platform,
    public alertController: AlertController
  ) {
    this.segmentModel = 'reci';
  }

  ngOnInit() {
    // Saca la OC que se manda como parametro
    const type = this.navParams.get('type');
    const listFileTmp = this.navParams.get('fileList');
    const canSendD = this.navParams.get('cantSendFile');
    const cedulaActTmp = this.navParams.get('vatnum');
    const companyTmp = this.navParams.get('company');
    const ocIdTmp = this.navParams.get('ocId');

    if (type !== null && type !== undefined) {
      this.typeView = type;
    }

    if (cedulaActTmp !== null && cedulaActTmp !== undefined) {
      this.cedulaAct = cedulaActTmp;
    }

    if (companyTmp !== null && companyTmp !== undefined) {
      this.companyAct = companyTmp;
    }

    if (ocIdTmp !== null && ocIdTmp !== undefined) {
      this.ocIdAct = ocIdTmp;
    }

    if (listFileTmp !== null && listFileTmp !== undefined) {
      this.listOfFileToSend = listFileTmp;
    }

    if (canSendD !== null && canSendD !== undefined) {
      this.canSend = canSendD;
    }

    console.log('');

    this.setViewByType();
  }

  setViewByType() {
    switch (this.typeView) {
      case 1:
        if (
          this.ocIdAct.length > 0 &&
          this.companyAct.length > 0 &&
          this.cedulaAct.length > 0
        ) {
          this.getFileInfoOC(
            1,
            15,
            this.ocIdAct,
            this.companyAct,
            this.cedulaAct
          ).then((data) => (this.listOfFileSend = data as FileOCInfo[]));
          this.getFileInfoOC(1, 16, this.ocIdAct, this.companyAct, '').then(
            (data) => (this.listOfFileReceived = data as FileOCInfo[])
          );
        }
        break;

      case 2:
        if (
          this.ocIdAct.length > 0 &&
          this.companyAct.length > 0 &&
          this.cedulaAct.length > 0
        ) {
          this.getFileInfoOC(
            1,
            16,
            this.ocIdAct,
            this.companyAct,
            this.cedulaAct
          ).then((data) => (this.listOfFileSend = data as FileOCInfo[]));
          this.getFileInfoOC(1, 15, this.ocIdAct, this.companyAct, '').then(
            (data) => (this.listOfFileReceived = data as FileOCInfo[])
          );
        }
        break;

      case 3:
        if (this.ocIdAct.length > 0 && this.companyAct.length) {
          this.getFileInfoOC(1, 15, this.ocIdAct, this.companyAct, '').then(
            (data) =>
              (this.listOfFileReceived = this.listOfFileReceived.concat(
                data as FileOCInfo[]
              ))
          );
          this.getFileInfoOC(1, 16, this.ocIdAct, this.companyAct, '').then(
            (data) =>
              (this.listOfFileReceived = this.listOfFileReceived.concat(
                data as FileOCInfo[]
              ))
          );
        }
        break;

      default:
        break;
    }
    // DEBUG //
    //this.listOfFileSend = [new FileOCInfo('file1', '', 'pdf', '', true)];
    //this.listOfFileReceived = [new FileOCInfo('file1', '', 'pdf', '', true)];
    //DEBUG
  }

  async getFileInfoOC(
    type: number,
    action: number,
    ocId: string,
    company: string,
    personVatnum: string
  ) {
    return await this.extOCServ.getFileInfoOC(
      type,
      action,
      ocId,
      company,
      personVatnum
    );
  }

  saveFile() {
    this.closePopOver(true);
  }

  closePopOver(list: boolean) {
    if (list) {
      this.viewCtrl.dismiss({ listFile: this.listOfFileToSend });
    } else {
      if (this.canSend) {
        if (this.listOfFileToSend.length > 0) {
          this.viewCtrl.dismiss({ listFile: this.listOfFileToSend });
        } else {
          this.viewCtrl.dismiss({ listFile: [] });
        }
      } else {
        this.viewCtrl.dismiss(null);
      }
    }
  }

  async onFileSelected(event, option: number) {
    await this.showSpinnerLoading('Cargando archivo');
    try {
      for (let index = 0; index < event.target.files.length; index++) {
        let fileI = event.target.files[index];
        let fileExtension = event.target.files[index].type;
        this.saveFileToSend(fileI, fileExtension);
      }
    } catch (error) {
      console.log(error);
    }
    this.dissmissLoading();
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  saveFileToSend(fileI: any, fileExtension: any) {
    let fileTo64: string;
    let reader = new FileReader();
    reader.readAsDataURL(fileI);
    reader.onload = (e) => {
      fileTo64 = reader.result.toString();
      fileExtension = fileTo64.substring(
        fileTo64.indexOf('/') + 1,
        fileTo64.indexOf(';base64')
      );
      fileTo64 = fileTo64.substring(fileTo64.lastIndexOf(',') + 1);
      this.listOfFileToSend.push(
        new FileOCInfo(fileI.name, fileI, fileExtension, fileTo64, false)
      );
    };
  }

  deleteFile(pos: number) {
    this.listOfFileToSend.splice(pos, 1);
  }

  async openReport(urlFile: string) {
    // Dependiendo de la plataforma actuara distinto la app
    //if(this.plt.is('mobileweb') || this.plt.is('core')) { // WEB
    // console.log('Open web', this.plt.is('mobileweb'), this.plt.platforms());
    //  window.open(urlFile,'_blank', 'Content-Type: application/pdf' + ', width='+screen.availWidth+',height='+screen.availHeight);
    // } else { /// MOBILE
    // console.log('Open mobile', this.plt.is('mobileweb'), this.plt.platforms());
    const options: InAppBrowserOptions = {
      location: 'yes', //Or 'no'
      hidden: 'no', //Or  'yes'
      clearcache: 'yes',
      clearsessioncache: 'yes',
      zoom: 'yes', //Android only ,shows browser zoom controls
      hardwareback: 'yes',
      mediaPlaybackRequiresUserAction: 'no',
      shouldPauseOnSuspend: 'no', //Android only
      closebuttoncaption: 'Close', //iOS only
      disallowoverscroll: 'no', //iOS only
      toolbar: 'yes', //iOS only
      enableViewportScale: 'no', //iOS only
      allowInlineMediaPlayback: 'no', //iOS only
      presentationstyle: 'pagesheet', //iOS only
      fullscreen: 'yes', //Windows only
    };
    let browserInst = new InAppBrowser();
    let browser = browserInst.create(urlFile, '_system', options);
    browser.show();
    // }
  }

  saveFile2(retryM: boolean) {
    // Ask to send file
    const mensajeM = retryM
      ? '¿Desea enviar los documentos restantes no enviados?'
      : '¿Desea enviar los archivos seleccionados?';
    this.messageInfo('Atención', mensajeM, 1);
  }

  async sendFile2Aux() {
    await this.showSpinnerLoading('Enviando datos de archivos');
    await this.setImageAux(
      this.ocIdAct,
      this.companyAct,
      16,
      this.cedulaAct,
      this.listOfFileToSend
    );
    this.dissmissLoading();

    const restantes = this.listOfFileToSend.find((fileD) => !fileD.getSend());
    if (restantes) {
      // Aun quedan restantes
      this.saveFile2(true);
    } else {
      this.listOfFileSend = [];
      this.closePopOver(false);
    }
  }

  /**
   *
   * @param ocId
   * @param company
   * @param typeAction Action tipo 15 Creador OC | 16 Confirmador OC
   */
  async setImageAux(
    ocId: string,
    company: string,
    typeAction: number,
    vatNum: string,
    litsFile: FileOCInfo[]
  ) {
    if (litsFile.length > 0) {
      for (let i = 0; i < litsFile.length; i++) {
        // Archivos tipo 1 Orden de Compra
        // Action tipo 15 Creador OC | 16 Confirmador OC
        let infoFile = litsFile[i];
        if (!infoFile.getSend()) {
          const result = await this.loadImage(
            infoFile,
            typeAction,
            company,
            vatNum,
            ocId
          );
          if (result === 1) {
            infoFile.setSend(true);
          }
        }
      }
    }
  }

  async loadImage(
    fileInfo: FileOCInfo,
    typeAction: number,
    company: string,
    vatnumUser: string,
    ocId: string
  ) {
    let result = 0;
    try {
      const infoData = new InfoImage();
      infoData.setDataImage(fileInfo.getFileData());
      infoData.extension = fileInfo.getFileExtension() as string;
      infoData.action = typeAction;
      infoData.type = 1; //OC
      infoData.vatnum = vatnumUser;
      infoData.recIdAnswer = ocId as any; //ElementeId
      infoData.company = company;
      result = (await this.extOCServ.uploadImge(infoData)) as number;
    } catch (error) {}
    return result;
  }

  async messageInfo(titulo: string, mensaje: string, action?: number) {
    let botones = [{ text: 'ACEPTAR', handler: () => {} }];
    if (action && action > 0) {
      botones = [
        {
          text: 'SI',
          handler: () => {
            this.sendFile2Aux();
          },
        },
        {
          text: 'NO',
          handler: () => {
            this.listOfFileSend = [];
            this.closePopOver(false);
          },
        },
      ];
    }

    if (!this.presentingMess) {
      const alert = await this.alertController.create({
        header: titulo,
        subHeader: '',
        message: mensaje,
        buttons: botones,
        backdropDismiss: false,
      });
      this.presentingMess = true;
      await alert.present();
      this.presentingMess = false;
    }
  }
}
