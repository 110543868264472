import { ItemOCInfoPriceModel } from './ItemOCPriceInfoModel';

export class ItemOCModel {
  private nameItem: string;
  private listUnits: ItemOCInfoPriceModel[];
  constructor(private accountRelation: string, private itemId: string) {
    this.listUnits = [];
  }

  getId() {
    return this.itemId;
  }

  getName() {
    return this.nameItem;
  }

  getAccountRelation() {
    return this.accountRelation;
  }

  getListUnits() {
    return this.listUnits;
  }

  setName(newName: string) {
    this.nameItem = newName;
  }
}
