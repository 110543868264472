<app-nav-bar [title]="'Contacto'"></app-nav-bar>

<ion-content>
  <div style="text-align: center" *ngIf="loadingDirections">
    <ion-spinner name="dots" duration="1000"></ion-spinner>
    <h6 ion-text text-center>Cargando Información</h6>
  </div>
  <div *ngIf="!loadingDirections && directionsError" style="text-align: center">
    No se pudo consultar la información de contacto!
  </div>
  <div
    class="sectionContainer"
    *ngIf="!loadingDirections && !directionsError"
    style="margin: 15px"
  >
    <h4
      class="title2"
      style="
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid #d6d6d6;
        padding: 0px 10px 10px;
      "
    >
      Información de Contacto
    </h4>
    <div
      class="flexBlockContainer"
      *ngFor="let item of customer_info.CustomerAddresses"
      style="border-bottom: 1px solid #d6d6d6"
    >
      <hr />
      <div class="flexContainer">
        <h6 class="titleContent">Correo de servicio al cliente:</h6>
        <div class="textContent">servicio_al_cliente&#64;grupointeca.com</div>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Teléfono de servicio al cliente:</h6>
        <div class="textContent">(+506) 7071-1313</div>
      </div>
      <div
        class="flexContainer"
        *ngIf="item.SalesAgent && item.SalesAgent != ''"
      >
        <h6 class="titleContent">Código del Agente:</h6>
        <div class="textContent">{{ item.SalesAgent }}</div>
      </div>
      <div
        class="flexContainer"
        *ngIf="item.NombreVendedor && item.NombreVendedor != ''"
      >
        <h6 class="titleContent">Nombre del Agente:</h6>
        <div class="textContent">{{ item.NombreVendedor }}</div>
      </div>
      <div
        class="flexContainer"
        *ngIf="item.NumeroTelVendedor && item.NumeroTelVendedor != ''"
      >
        <h6 class="titleContent">Número del Agente:</h6>
        <div
          class="textContent"
          style="color: #223e99; text-decoration: underline"
          (click)="callSeller(item.NumeroTelVendedor)"
        >
          {{ "+506 " + item.NumeroTelVendedor }}
        </div>
        <i
          style="color: #333333; font-size: 18px; margin-left: 3%"
          class="fas fa-phone-square"
          (click)="callSeller(item.NumeroTelVendedor)"
        ></i>
      </div>
    </div>
  </div>
</ion-content>
