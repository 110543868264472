<ion-app>
  <ion-menu contentId="main-content">
    <ion-header>
      <ion-toolbar style="text-align: center">
        <img src="assets/img/logo_homex.png" alt="" onerror="" />
      </ion-toolbar>
    </ion-header>
    <ion-content style="display: block; justify-content: center">
      <div style="max-height: 35vh">
        <div class="userIcon">
          <i class="fas fa-user-circle"></i>
        </div>
        <div>
          <div class="userTitle">{{ userName }}</div>
          <div *ngIf="loginStatus" class="userSubtitle">{{ userId }}</div>
        </div>
        <div *ngIf="associatedPeople.length > 1">
          <ion-item (click)="selectAssociated()">
            <!-- <ion-label *ngIf="associatedPerson.name.toLowerCase() === 'default'" class="userTitle">Instancia:</ion-label> -->
            <ion-label
              class="userSubtitle capitalize"
              style="display: inline-block"
            >
              {{ associatedPerson.name }}
            </ion-label>
            <ion-label
              *ngIf="associatedPerson.name.toLowerCase() !== 'default'"
              class="userSubtitle"
              style="display: inline-block"
              >{{ associatedPerson.vatNum }}</ion-label
            >
            <ion-icon name="caret-down-outline" item-right></ion-icon>
          </ion-item>
        </div>
      </div>

      <div *ngFor="let p of pages">
        <div *ngIf="p.show" [ngClass]="{ loginDiv: p.id == 3 }">
          <ion-menu-toggle *ngIf="p.id == 3" style="width: 100%">
            <ion-item lines="none">
              <ion-button
                class="loginBtn"
                style="font-size: 14px"
                (click)="openPage(p, false)"
                shape="round"
              >
                <i
                  class="{{ p.icon }}"
                  class="side-menu-icon"
                  style="padding: 2px"
                  color="dark"
                ></i>
                <label style="padding: 5px">{{ p.title }}</label>
              </ion-button>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="!p.childs && p.id != 3">
            <ion-item (click)="openPage(p, false)" style="font-size: 14px">
              <i
                class="{{ p.icon }}"
                class="side-menu-icon"
                style="padding: 2px"
                color="dark"
              ></i>
              <label style="padding: 5px">{{ p.title }}</label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item
            *ngIf="p.childs && p.id != 3"
            (click)="openPage(p, true)"
            style="font-size: 14px"
          >
            <i
              class="{{ p.icon }}"
              class="side-menu-icon"
              style="padding: 2px"
            ></i>
            <label style="padding: 5px">{{ p.title }}</label>
            <i
              class="fas fa-angle-right"
              style="padding: 2px; position: absolute; right: 16px"
              *ngIf="!p.expanded"
            ></i>
            <i
              class="fas fa-angle-down"
              style="padding: 2px; position: absolute; right: 16px"
              *ngIf="p.expanded"
            ></i>
          </ion-item>
          <ion-list style="padding: 0px" *ngIf="p.childs">
            <ion-menu-toggle
              *ngFor="let c of p.childs"
              style="margin: 0px; padding: 0px"
            >
              <ion-item
                *ngIf="p.expanded && c.show"
                (click)="openPage(c, false)"
                style="font-size: 13px; margin: 0px; padding-left: 20px"
              >
                <i class="{{ c.icon }}" style="padding: 2px"></i>
                <label style="padding: 5px">{{ c.title }}</label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </div>
      </div>
    </ion-content>
    <div
      style="
        left: 0px;
        margin-bottom: 1%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        bottom: 0;
      "
    >
      <div style="display: flex">
        <i
          style="color: #3b5998; font-size: 30px; margin: 5px"
          class="fab fa-facebook-square"
          (click)="openFacebook()"
        ></i>
        <i
          style="color: #c71610; font-size: 30px; margin: 5px"
          class="fas fa-envelope-square"
          (click)="openMail()"
        ></i>
        <i
          style="color: #25d366; font-size: 30px; margin: 5px"
          class="fab fa-whatsapp"
          (click)="sendWhatsappMsg()"
        ></i>
        <i
          style="color: #0000ff; font-size: 30px; margin: 5px"
          class="fas fa-share-alt"
          (click)="shareApp()"
        ></i>
      </div>
      <div style="padding-left: 1%" *ngIf="appVersionNumber">
        Versión: {{ appVersionNumber }}
      </div>
    </div>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
