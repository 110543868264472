import { Component } from '@angular/core';

@Component({
  selector: 'app-select-delivery-time',
  templateUrl: './select-delivery-time.component.html',
  styleUrls: ['./select-delivery-time.component.scss'],
})
export class SelectDeliveryTimeComponent {
  text: string;

  constructor() {
    this.text = 'Hello World';
  }
}
