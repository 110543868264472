import { Component } from '@angular/core';
import {
  AlertController,
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';

import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { PSRtnHeaderHistory } from '../../../businessCore/OrderRet/PSRtnHeaderHistory';
import { RegisterData } from '../../../businessCore/RegisterData';
import { webServiceProvider } from '../../../provider/webServiceProvider';
import { RetBoughtItemComponent } from '../ret-bought-item/ret-bought-item.component';
import { RetProductHistoryComponent } from '../ret-product-history/ret-product-history.component';

@Component({
  selector: 'app-order-ret-history',
  templateUrl: './order-ret-history.component.html',
  styleUrls: ['./order-ret-history.component.scss'],
})
export class OrderRetHistoryComponent {
  AddressId: number = -1;
  AccountNum: string = '';
  PriceList: string = '';

  searchInput: string = '';

  // Mostrar la animacion del sliding en cada uno de los componentes
  // que contienen el nombre de las listas
  shouldAnimate: boolean = true;

  register_data: RegisterData = new RegisterData();

  orderHistory: PSRtnHeaderHistory[] = [];

  loadingRtnHistory: boolean = true;

  constructor(
    public apiProvider: webServiceProvider,
    public alertCtrl: AlertController,
    public toast: ToastController,
    private storage: StorageService,
    private popoverCtrl: PopoverController,
    private navService: NavigationService,
    private modalController: ModalController
  ) {
    this.setUserInfo();
  }

  async setUserInfo() {
    await this.storage
      .get('register_data')
      .then((data) => {
        if (data != null) {
          this.register_data = JSON.parse(data) as RegisterData;

          this.AccountNum = this.register_data.customerId;
          this.PriceList = this.register_data.priceList;
          if (this.register_data.directions.length > 0) {
            this.AddressId = this.register_data.directions[0].recId;
          }

          this.getPSRtnHeaderHistory();
        } else {
          this.loadingRtnHistory = false;
        }
      })
      .catch(() => {
        this.loadingRtnHistory = false;
      });
  }

  async getPSRtnHeaderHistory() {
    //console.log(this.AccountNum);
    if (this.AccountNum != '') {
      this.apiProvider
        .GetPSRtnHistory(this.AccountNum)
        .subscribe(async (data) => {
          if (data != null) {
            //console.log(data);
            this.orderHistory = data as PSRtnHeaderHistory[];
            //console.log(this.orderHistory);
          } else {
            let toast = await this.toast.create({
              message:
                'No se pudo consultar la información del cliente, vuelva a intentarlo luego!',
              duration: 3000,
            });
            toast.present();
          }
          this.loadingRtnHistory = false;
        });
    } else {
      this.loadingRtnHistory = false;
      let toast = await this.toast.create({
        message: 'No se cuenta con toda la información del cliente requerida!',
        duration: 3000,
      });
      toast.present();
    }
  }

  /**
   * Metodo utilizado para realizar el sliding hacia la derecha e izquierda
   * ejecutando la eliminacion de la lista o el despliegue de productos segun la
   * direccion
   * @param event evento que contiene la direccion del sliding que se ejecutó
   * @param order objeto tipo PSRtnHeaderHistory al que se le está ejecutando la acción
   */
  swipeEvent(event, order: PSRtnHeaderHistory) {
    // Left
    if (event.direction === 2) {
      this.displayItems(order);
    }
    // Right
    else if (event.direction === 4) {
    }
  }

  /**
   * Metodo auxiliar utilizado para presentar un popup con los productos que tiene
   * asociados una orden
   * @param order objeto tipo OrderTraceItem sobre la que se presentaran los productos
   */
  async displayItems(order: PSRtnHeaderHistory) {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: RetProductHistoryComponent,
      componentProps: { PSRtnHeaderHistory: order },
    });
    popover.present();

    popover.onDidDismiss();
  }

  async newReturnOrder() {
    if (this.AccountNum != '') {
      let modalCtrl = await this.modalController.create({
        component: RetBoughtItemComponent,
        componentProps: {
          accountNum: this.AccountNum,
          priceList: this.PriceList,
          registerData: this.register_data,
        },
      });
      modalCtrl.present();
    } else {
      let toast = await this.toast.create({
        message:
          'No se pudo consultar la información del cliente, vuelva a intentarlo luego!',
        duration: 3000,
      });
      toast.present();
    }
  }
}
