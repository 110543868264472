<app-nav-bar [title]="'Remisión'"></app-nav-bar>

<ion-content>
  <div class="box">
    <ion-item lines="none" class="title">
      <div
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
        "
      >
        <h5
          style="
            font-size: 1rem;
            margin-bottom: 5px;
            color: dimgrey;
            text-align: center;
          "
        >
          <b>{{
            StatusReceipt === 5
              ? "Recibir Cantidad Pedida"
              : "Recibir
            Cantidad Registrada"
          }}</b>
        </h5>
        <h5 ion-text text-center style="font-size: 1rem; color: dimgrey">
          {{ PurchOrder.objectName }}
        </h5>
      </div>
    </ion-item>

    <div lines="none" class="barTitle">
      <div style="display: flex; justify-content: center; width: 100%">
        <h5 style="font-size: 1rem; width: 100%; text-align: center">
          <b>Líneas {{ PurchId }}</b>
        </h5>
      </div>
    </div>

    <div class="div-table">
      <ngx-datatable
        #myTable
        style="font-size: min(3vw, 12px)"
        [ngClass]="tableStyle"
        [rows]="PurchLinesInfo"
        [columnMode]="'force'"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [sorts]="[{ prop: 'orderedQty', dir: 'desc' }]"
        [selected]="selected"
        [selectionType]="selection"
        [selectAllRowsOnPage]="false"
        (select)="onSelect($event)"
      >
        <!-- Row Column Template -->
        <ngx-datatable-column
          name=""
          prop="selected"
          [headerCheckboxable]="true"
          [checkboxable]="true"
          [minWidth]="10"
          [width]="10"
          cellClass=""
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Artículo"
          prop="itemId"
          [width]="40"
          [minWidth]="40"
          (press)="askPurchInfo($event)"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
            style="min-height: 100px"
          >
            <div (press)="askPurchInfo(row)" (click)="showItemInfo(row)">
              <span>{{ value }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Unidad"
          prop="unitId"
          [width]="40"
          [minWidth]="40"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ totalQtyLabel }}"
          prop="totalQty"
          [width]="StatusReceipt == 3 ? 68 : 80"
          [minWidth]="40"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="StatusReceipt == 3"
          name="{{ orderedQtyLabel }}"
          prop="orderedQty"
          [width]="68"
          [minWidth]="40"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="StatusReceipt == 3"
          name="{{ remissionQtyLabel }}"
          prop="remissionQty"
          [width]="68"
          [minWidth]="40"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="StatusReceipt == 5"
          name="{{ remissionQtyLabel }}"
          prop="remissionQty"
          [minWidth]="50"
        >
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <div>
              <ion-input
                class="remission-input"
                value="{{ value }}"
                type="number"
                min="0"
                (ionBlur)="updateCantRemission(row, $event)"
              ></ion-input>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <div class="containerButtonCustom">
      <hr class="divider" />
      <ion-button
        class="button_custom"
        expand="block"
        style="margin: 15px"
        type="submit"
        (click)="remissionLines()"
        [disabled]="!selected.length"
      >
        Aceptar Remisión
      </ion-button>
    </div>
  </div>
</ion-content>
