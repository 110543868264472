<ion-content center text-center>
  <h4 class="title2" *ngIf="contact_data.edit">Editar Contacto</h4>
  <h4 class="title2" *ngIf="!contact_data.edit">Nuevo Contacto</h4>

  <form (ngSubmit)="addContact()" #registerForm="ngForm">
    <ion-item *ngIf="!contact_data.type">
      <a class="required"></a>
      <ion-input
        label="Nombre"
        label-placement="floating"
        class="floating"
        type="text"
        name="contactName"
        [(ngModel)]="contact_data.contactName"
        [ngModelOptions]="{ standalone: true }"
        required
      ></ion-input>
    </ion-item>

    <ion-label color="primary" style="text-align: left; padding-left: 16px">
      <a style="color: brown">*</a> Teléfono</ion-label
    >

    <ion-item>
      <ion-input
        type="number"
        name="contact"
        placeholder="XXXX XXXX"
        [(ngModel)]="contact_data.contact"
        [ngModelOptions]="{ standalone: true }"
        required
      ></ion-input>
    </ion-item>

    <ion-item>
      <a class="required"></a>
      <ion-input
        label="Descripción"
        label-placement="floating"
        class="floating"
        type="text"
        name="description"
        [(ngModel)]="contact_data.description"
        [ngModelOptions]="{ standalone: true }"
        required
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label color="primary">¿Principal?</ion-label>
      <ion-toggle
        [(ngModel)]="contact_data.is_principal"
        [ngModelOptions]="{ standalone: true }"
      ></ion-toggle>
    </ion-item>

    <div *ngIf="present_alert" style="text-align: left">
      <span class="span_alert">* Número telefónico inválido.</span>
    </div>

    <ion-button
      expand="block"
      class="submit-btn"
      type="submit"
      [disabled]="!validForm()"
      *ngIf="contact_data.edit"
    >
      <ion-icon name="md-checkmark-circle-outline" class="mr-5"></ion-icon>
      Editar
    </ion-button>

    <ion-button
      expand="block"
      class="submit-btn"
      type="submit"
      [disabled]="!validForm()"
      *ngIf="!contact_data.edit"
    >
      <ion-icon name="md-checkmark-circle-outline" class="mr-5"></ion-icon>
      Agregar
    </ion-button>
  </form>
</ion-content>
