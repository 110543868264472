import { Component } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';
import { ShoopingCartUtils } from '../../GeneralUtilis/ShoopingCar.utils';
import { IpPonderation } from '../../businessCore/IPPonderation';
import { OrderLine } from '../../businessCore/OrderLine';
import { PromoTableView } from '../../businessCore/PromoTableView';
import { RegisterData } from '../../businessCore/RegisterData';
import { webServiceProvider } from '../../provider/webServiceProvider';
import { PromoDetailComponent } from '../promo-detail/promo-detail.component';

@Component({
  selector: 'app-my-promo-list',
  templateUrl: './my-promo-list.component.html',
  styleUrls: ['./my-promo-list.component.scss'],
})
export class MyPromoListComponent {
  AddressId: number = -1;
  AccountNum: string = '';
  PriceList: string = '';

  // IpPonderation, productos con poderacion de la direccion consultada
  ipPonderation: IpPonderation[] = [];

  ipPercent: number = 0;

  // Arreglo con el conjunto de promociones que el usuario tiene creadas
  promoHeaders: PromoTableView[] = [];

  // Arreglo con el conjunto de listas de manera filtrada
  // Es el arreglo de listas que se despliega en la ventana
  promoFiltered: PromoTableView[] = [];

  validationInProgress: boolean = false;

  searchInput: string = '';

  // Shopping Car Array
  shoppingCar: OrderLine[] = [];

  // Mostrar la animacion del sliding en cada uno de los componentes
  // que contienen el nombre de las listas
  shouldAnimate: boolean = true;

  loading: HTMLIonLoadingElement;

  register_data: RegisterData = new RegisterData();

  constructor(
    public apiProvider: webServiceProvider,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public toast: ToastController,
    private storage: StorageService,
    private loadingCtrl: LoadingController,
    private shoopingCartUtils: ShoopingCartUtils
  ) {
    this.setUserInfo();

    //DEBUG
    //let promo = new PromoTableView();
    //this.promoFiltered = [promo];
    //DEBUG
  }

  async setUserInfo() {
    // loadingController
    this.loading = await this.loadingCtrl.create({
      message: 'Cargando Promociones...',
    });
    await this.storage.get('register_data').then((data) => {
      if (data != null) {
        this.register_data = JSON.parse(data) as RegisterData;

        this.AccountNum = this.register_data.customerId;
        this.PriceList = this.register_data.priceList;
        if (this.register_data.directions.length > 0) {
          this.AddressId = this.register_data.directions[0].recId;
        }
        this.getPromosByAddress();
      }

      this.getShoppingCar();
    });
  }

  getShoppingCar() {
    this.storage
      .get('carrito')
      .then((data) => {
        this.shoppingCar =
          data && data != null ? (JSON.parse(data) as OrderLine[]) : [];
        // console.log(this.shoppingCar);

        this.validateHowManyTimesApply();
      })
      .catch(() => {});
  }

  countPromo(promoId: string) {
    let sequences: number[] = [];
    // console.log('this.shoppingCar.filter(l => l.promoId == promoId)');
    // console.log(this.shoppingCar.filter(l => l.promoId == promoId));
    this.shoppingCar
      .filter((l) => l.promoId == promoId)
      .forEach((cl) => {
        // console.log('sequences.indexOf(cl.PromoSequence)');
        // console.log(sequences.indexOf(cl.PromoSequence));
        if (sequences.indexOf(cl.PromoSequence) == -1) {
          sequences.push(cl.PromoSequence);
        }
        // else {
        // console.log('Not Exist');
        // }
      });
    // console.log('sequences.length');
    // console.log(sequences.length);
    return sequences.length;
  }

  validateHowManyTimesApply() {
    // console.log('validateHowManyTimesApply');
    this.promoHeaders.forEach((ph) => {
      // console.log('*********** validateHowManyTimesApply PROMOID***********');
      // console.log(ph);
      // Si es menor o igual la bloquea y tiene resultados de tipo regalia
      if (
        ph.Times != 0 &&
        ph.Times <= this.countPromo(ph.PromoId) &&
        ph.PromoResults.filter((pr) => pr.SubType == 108).length > 0
      ) {
        ph.lockedByIpPonderation = true;
        // console.log('PromoBloqueada por Times');
        // console.log(ph);

        this.promoFiltered
          .filter((pf) => pf.PromoId == ph.PromoId)
          .forEach((promoFiltered) => {
            promoFiltered.lockedByIpPonderation = true;
          });
      }
      //  else {
      // console.log('CORRECT');
      // console.log('ph.Times');
      // console.log(ph.Times);
      // console.log('this.countPromo(ph.PromoId)');
      // console.log(this.countPromo(ph.PromoId));
      // }
    });

    // Limpia las promos que ya se aplicaron mas de las veces permitidas
    this.promoHeaders = this.promoHeaders
      .filter((p) => !p.lockedByIpPonderation && p.ValidProductConditions)
      .sort((a, b) => {
        return a.PromoId.toUpperCase() > b.PromoId.toUpperCase() ? 1 : -1;
      });

    // Limpia el campo de filtrado
    this.searchInput = '';

    // iguala las promos filtradas a los encabezados
    this.promoFiltered = this.promoHeaders;
  }

  async getPromosByAddress() {
    if (this.PriceList != '' && this.AccountNum != '' && this.AddressId != -1) {
      // Obtener ponderacion IP de la direccion consultada
      this.getIpPonderationByAddress();
    } else {
      console.log('this.AddressId');
      console.log(this.AddressId);
      console.log('this.AccountNum');
      console.log(this.AccountNum);
      console.log('this.PriceList');
      console.log(this.PriceList);
      let toast = await this.toast.create({
        message: 'No se pudieron consultar las promociones!',
        duration: 3000,
      });
      toast.present();
    }
  }

  async getIpPonderationByAddress() {
    this.validationInProgress = true;

    this.loading = await this.loadingCtrl.create({
      message: 'Cargando Promociones...',
    });

    this.loading.present();

    this.ipPercent = 0;
    this.apiProvider
      .getIpPonderationByAddress(this.AddressId.toString())
      .subscribe((data) => {
        this.ipPonderation = data as IpPonderation[];

        // console.log(this.ipPonderation);

        this.ipPonderation
          .filter((p) => p.NetSale > 0)
          .forEach((elementPonderation) => {
            // console.log(elementPonderation);
            // console.log(elementPonderation.Ponderation);
            let amount = Number.parseFloat(
              Number.parseFloat(
                elementPonderation.Ponderation.toString().replace(',', '.')
              ).toFixed(2)
            );
            this.ipPercent = Number.parseFloat(
              (this.ipPercent + amount).toFixed(2)
            );
          });

        // console.log(this.ipPercent);
        this.getData();
      });
  }

  getData() {
    this.storage.get('lastUpdatePromo').then((fecha) => {
      this.storage.get('promos').then((promos) => {
        if (
          promos == null ||
          (JSON.parse(promos) as PromoTableView[]).length == 0 ||
          fecha == null ||
          new Date().getTime() - new Date(fecha).getTime() > 3525000
        ) {
          //3525000   => 1 hora

          this.apiProvider
            .getPromosByAddress(
              this.AccountNum,
              this.AddressId.toString(),
              this.PriceList
            )
            .subscribe((data) => {
              this.getPromoByAddress_Aux(data as PromoTableView[]);

              this.storage.set('lastUpdatePromo', new Date());
              this.storage.set('promos', JSON.stringify(data));
            });
        } else {
          this.getPromoByAddress_Aux(JSON.parse(promos) as PromoTableView[]);
        }
      });
    });
  }

  async getPromoByAddress_Aux(data) {
    // this.apiProvider.getPromosByAddress(this.AccountNum, this.AddressId).subscribe(data => {
    if (data != null) {
      //console.log(data);

      let promoResults = data as PromoTableView[];

      promoResults.forEach((promo) => {
        promo.lockedByIpPonderation = false;
        promo.expression = '';
        let condition: boolean = false;

        promo.PromoFeaturesHistoryViews.sort(function (a, b) {
          return a.Group - b.Group || a.LineNum - b.LineNum;
        });

        // console.log('******************************************************');
        // console.log('*************************** promo.PromoId ***************************');
        // console.log('******************************************************');
        // console.log(promo.PromoId);
        // console.log(promo.PromoFeaturesHistoryViews);

        if (promo.PromoFeaturesHistoryViews.length < 1) {
          // console.log('if(promo.PromoFeaturesHistoryViews.length <=1) FALSE');
          promo.lockedByIpPonderation = false;
        } else {
          promo.PromoFeaturesHistoryViews.forEach((feature, i) => {
            // console.log('Actual promo.expression');
            // console.log(promo.expression);
            switch (feature.Transaction) {
              case 3:
                // console.log('feature.Transaction = 3...');
                switch (feature.Operator) {
                  case 1:
                    // console.log('feature.Operator = 1...');
                    condition = feature.ValueTransaction == this.ipPercent;
                    promo.expression = new PromoTableView().buildExpression(
                      promo.expression,
                      i == 0
                        ? feature.Group - 1
                        : promo.PromoFeaturesHistoryViews[i - 1].Group,
                      feature.Group,
                      i == promo.PromoFeaturesHistoryViews.length - 1
                        ? feature.Group + 1
                        : promo.PromoFeaturesHistoryViews[i + 1].Group,
                      feature.ConditionOr == 0 ? ' & ' : ' | ',
                      condition ? ' 1 ' : ' 0 ',
                      i == promo.PromoFeaturesHistoryViews.length - 1
                    );

                    // console.log('promo.expression durante Iteracion # ' + i);
                    // console.log(promo.expression);
                    break;
                  case 2:
                    // console.log('feature.Operator = 2...');
                    condition = feature.ValueTransaction <= this.ipPercent;
                    promo.expression = new PromoTableView().buildExpression(
                      promo.expression,
                      i == 0
                        ? feature.Group - 1
                        : promo.PromoFeaturesHistoryViews[i - 1].Group,
                      feature.Group,
                      i == promo.PromoFeaturesHistoryViews.length - 1
                        ? feature.Group + 1
                        : promo.PromoFeaturesHistoryViews[i + 1].Group,
                      feature.ConditionOr == 0 ? ' & ' : ' | ',
                      condition ? ' 1 ' : ' 0 ',
                      i == promo.PromoFeaturesHistoryViews.length - 1
                    );
                    // console.log('promo.expression durante Iteracion # ' + i);
                    // console.log(promo.expression);
                    break;
                  case 3:
                    // console.log('feature.Operator = 3...');
                    condition = feature.ValueTransaction >= this.ipPercent;
                    promo.expression = new PromoTableView().buildExpression(
                      promo.expression,
                      i == 0
                        ? feature.Group - 1
                        : promo.PromoFeaturesHistoryViews[i - 1].Group,
                      feature.Group,
                      i == promo.PromoFeaturesHistoryViews.length - 1
                        ? feature.Group + 1
                        : promo.PromoFeaturesHistoryViews[i + 1].Group,
                      feature.ConditionOr == 0 ? ' & ' : ' | ',
                      condition ? ' 1 ' : ' 0 ',
                      i == promo.PromoFeaturesHistoryViews.length - 1
                    );
                    // console.log('promo.expression durante Iteracion # ' + i);
                    // console.log(promo.expression);
                    break;
                  case 4:
                    // console.log('feature.Operator = 4...');
                    condition =
                      feature.ToValueTransaction >= this.ipPercent &&
                      this.ipPercent >= feature.ValueTransaction;
                    promo.expression = new PromoTableView().buildExpression(
                      promo.expression,
                      i == 0
                        ? feature.Group - 1
                        : promo.PromoFeaturesHistoryViews[i - 1].Group,
                      feature.Group,
                      i == promo.PromoFeaturesHistoryViews.length - 1
                        ? feature.Group + 1
                        : promo.PromoFeaturesHistoryViews[i + 1].Group,
                      feature.ConditionOr == 0 ? ' & ' : ' | ',
                      condition ? ' 1 ' : ' 0 ',
                      i == promo.PromoFeaturesHistoryViews.length - 1
                    );
                    // console.log('promo.expression durante Iteracion # ' + i);
                    // console.log(promo.expression);
                    break;
                  default:
                    // console.log('Operador sin definir para el tipo de transaction 3');
                    break;
                }
                break;
              case 1:
                //console.log('No ha facturado en...');
                break;
              case 99:
                //console.log('El IP numerico es ...');
                break;
              default:
                //console.log('Transaction PromoFeatures desconocido');
                break;
            }
          });
          //console.log(promo.expression);
          promo.expression = promo.expression.replace(/ /g, '');
          // console.log(promo.expression);
          // console.log(new PromoTableView().eval(promo.expression));
          promo.lockedByIpPonderation = !new PromoTableView().eval(
            promo.expression
          );
        }
      });

      this.promoHeaders = promoResults
        .filter((p) => !p.lockedByIpPonderation)
        .sort((a, b) => {
          return a.PromoId.toUpperCase() > b.PromoId.toUpperCase() ? 1 : -1;
        });

      // this.promoFiltered = this.promoHeaders;

      this.filterByValidProducts();

      this.validationInProgress = false;
      this.loading.dismiss();

      console.log(this.promoHeaders);

      // this.validateHowManyTimesApply();
      this.getShoppingCar();
    } else {
      let alert = await this.alertCtrl.create({
        header: 'Error',
        message: 'No existen datos asociados.',
      });
      alert.present();

      this.validationInProgress = false;
      this.loading.dismiss();
    }
  }

  filterByValidProducts() {
    this.promoHeaders.forEach((ph) => {
      ph.ValidProductConditions = true;
      ph.PromoConditions.filter((pc) => pc.TypeCondition == 1).forEach(
        (cond) => {
          switch (cond.OperatorValue) {
            // LISTA
            case 1:
              if (
                cond.PromoConditionsList.length == 0 ||
                cond.PromoConditionsList.filter(
                  (pcl) => pcl.Amount <= 0 || pcl.UnitId == ''
                ).length > 0
              ) {
                ph.ValidProductConditions = false;
              }
              break;
            //IGUAl ARTICULO
            case 2:
              if (cond.Amount <= 0 || cond.UnitIdValue == '') {
                ph.ValidProductConditions = false;
              }
              break;
          }
        }
      );
    });

    console.log('PROMO INVALIDAS POR PRODUCTOS INCOMPLETOS');
    console.log(this.promoHeaders.filter((ph) => !ph.ValidProductConditions));
    this.promoFiltered = this.promoHeaders.filter(
      (ph) => ph.ValidProductConditions
    );
  }

  /**
   * Metodo utilizado para filtrar las listas segun el nombre que tienen asocidado
   * @param event parametro utilizado para tomar el valor del target que quiere filtrar el usuario
   */
  getItems(event) {
    let val = event.target.value;
    if (val && val.trim() != '') {
      this.promoFiltered = this.promoHeaders;
      this.promoFiltered = this.promoFiltered.filter(
        (value) =>
          value.Name.toLowerCase().includes(val.toLowerCase()) ||
          value.Description.toLowerCase().includes(val.toLowerCase()) ||
          value.PromoId.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      this.promoFiltered = this.promoHeaders;
    }
  }

  promoExist() {
    return this.promoHeaders.length > 0 ? true : false;
  }

  /**
   * Metodo utilizado para realizar el sliding hacia la derecha e izquierda
   * ejecutando la eliminacion de la lista o el despliegue de productos segun la
   * direccion
   * @param event evento que contiene la direccion del sliding que se ejecutó
   * @param promo objeto tipo productList al que se le está ejecutando la acción
   */
  swipeEvent(event, promo: PromoTableView) {
    if (event.direction === 2) {
      this.displayPromo(promo);
    } else if (event.direction === 4) {
    }
  }

  /**
   * Metodo utilizado para desplegar las promociones dependiendo el tipo
   * @param promo
   */
  async displayPromo(promo: PromoTableView) {
    console.log('Desplegando Promocion...');
    console.log(promo);

    let resetModal = await this.modalCtrl.create({
      component: PromoDetailComponent,
      componentProps: { promoTableView: promo },
    });
    resetModal.present();
    resetModal.onDidDismiss().then((res) => {
      let data = res.data as string;
      if (data && data == 'OK') {
        console.log('Promoción agregada con éxito');
        this.getShoppingCar();
        // this.validateHowManyTimesApply();
      } else if (data && data == 'CANCEL') {
        console.log('Promo Cancelada');
      }
    });
  }

  async reloadPromos() {
    if (!this.validationInProgress) {
      this.validationInProgress = true;
      this.loading = await this.loadingCtrl.create({
        message: 'Cargando Promociones...',
      });
      this.loading.present();
      this.apiProvider
        .getPromosByAddress(
          this.AccountNum,
          this.AddressId.toString(),
          this.PriceList
        )
        .subscribe((data) => {
          this.getPromoByAddress_Aux(data as PromoTableView[]);

          this.storage.set('lastUpdatePromo', new Date());
          this.storage.set('promos', JSON.stringify(data));

          this.validationInProgress = false;
          // this.loading.dismiss();
        });
    } else {
      let toast = await this.toast.create({
        message: 'Ya se encuentra en curso la consulta de promociones!',
        duration: 2500,
      });
      toast.present();
    }
  }

  ionViewDidEnter() {
    this.reloadShoppingCar();
  }

  reloadShoppingCar() {
    this.storage.get('carrito').then((data) => {
      this.shoppingCar = JSON.parse(data);
      if (this.shoppingCar == null) {
        this.shoppingCar = [];
      }
    });
  }

  /**
   * Metodo utlizado para desplegar el carro de compras al usuario
   */
  displayShoppingCar() {
    this.shoopingCartUtils.displayShoppingCart();
  }

  /**
   * Metodo utilizado para desplegar una alerta al usuario
   * @param title string con el titulo de la alerta
   * @param msg string con el mensaje de la alerta
   */
  async presentAlert(title: string, msg: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      backdropDismiss: false,
    });
    alert.present();
  }
}
