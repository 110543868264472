import { Component, ViewEncapsulation } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-show-conversion-item',
  templateUrl: './show-conversion-item.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./show-conversion-item.component.scss'],
})
export class ShowConversionItemComponent {
  data: {
    description: string;
    conversions: { fromUnit: string; toUnit: string }[];
  };

  constructor(private navParams: NavParams) {
    this.data = this.navParams.get('data');
  }
}
