import { Component, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { DataArea } from '../../../businessCore/oc-models/DataArea';
import { SearchProviderComponent } from '../../../components/oc-components/search-provider/search-provider.component';
import { PurchLinesInfoParamv2 } from '../../OOP/PurchLinesInfoParamv2.interface';
import { Einvoice } from '../../OOP/einvoices.interface';
import { EinvoiceFilterComponent } from '../../components/einvoice-filter/einvoice-filter.component';
import { EInvoiceFilterPipe } from '../../pipes/EInvoice.pipe';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-assign-einvoice',
  templateUrl: './assign-einvoice.component.html',
  styleUrls: ['./assign-einvoice.component.scss'],
})
export class AssignEinvoicePage implements OnInit {
  empresaSelected: DataArea;
  EInvoices: Einvoice[] = [];
  EInvoicesSearch: Einvoice[] = [];
  EInvoicesPivot: Einvoice[] = [];
  loader: HTMLIonLoadingElement;

  sortEvents = {
    KeyEInvoiceAsc: false,
    KeyEInvoiceDesc: false,
    PurchIdAsc: false,
    PurchIdDesc: false,
    ReceiverIdAsc: false,
    ReceiverIdDesc: false,
    ReceiverNameAsc: false,
    ReceiverNameDesc: false,
    EmittedDateAsc: false,
    EmittedDateDesc: true,
  };

  orderBy: string = '';
  rangeDatesSelected: Date[];
  MinMax: { lower: number; upper: number } = { lower: 0, upper: 0 };

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private invServ: InvoiceService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private EInvoiceFilter: EInvoiceFilterPipe,
    private navService: NavigationService
  ) {}

  ngOnInit(): void {
    this.loadCompanies();
  }

  ionViewDidEnter() {
    if (this.EInvoices.length > 0 && this.empresaSelected.getId()) {
      this.EInvoices = [];
      this.EInvoicesSearch = [];
      this.EInvoicesPivot = [];
      this.orderBy = '';
      this.rangeDatesSelected = [];
      this.MinMax = { lower: 0, upper: 0 };
      this.loadEInvoices(this.empresaSelected);
    }
  }

  async loadCompanies() {
    await this.showSpinnerLoading('Cargando Compañías');
    this.invServ.listDataArea = await this.invServ.getDataAreaInfo();
    this.loader.dismiss();
  }

  async loadEInvoices(company: any) {
    await this.showSpinnerLoading('Cargando Facturas Electrónicas');
    this.empresaSelected = company;

    this.EInvoices = await this.invServ.getAllEInvoicesByVendor(
      this.empresaSelected.getId()
    );
    this.EInvoicesSearch = this.EInvoices;
    this.EInvoicesPivot = this.EInvoices;

    this.loader.dismiss();
  }

  async openSearchBarDataAreaId() {
    const modal = await this.modalCtrl.create({
      component: SearchProviderComponent,
      componentProps: {
        objectsList: this.invServ.listDataArea,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data !== null) {
        if (data.objSelected) {
          this.loadEInvoices(data.objSelected);
        }
      }
    });
  }

  async showSpinnerLoading(mensaje: string, spinner?: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  searchOC(event) {
    this.EInvoicesSearch = this.EInvoiceFilter.transform(
      this.EInvoicesPivot,
      event.detail.value
    );
  }

  async presentSortAlert() {
    let alert = await this.alertCtrl.create({
      header: '¿Cómo ordenar las FEs?',
      inputs: [
        this.getInputAlert(
          'radio',
          'Clave (A -> Z)',
          'KeyEInvoiceAsc',
          this.sortEvents.KeyEInvoiceAsc
        ),

        this.getInputAlert(
          'radio',
          'Clave (Z -> A)',
          'KeyEInvoiceDesc',
          this.sortEvents.KeyEInvoiceDesc
        ),
        this.getInputAlert(
          'radio',
          'Orden de Compra (A -> Z)',
          'PurchIdAsc',
          this.sortEvents.PurchIdAsc
        ),
        this.getInputAlert(
          'radio',
          'Orden de Compra (Z -> A)',
          'PurchIdDesc',
          this.sortEvents.PurchIdDesc
        ),
        this.getInputAlert(
          'radio',
          'ID Receptor (A -> Z)',
          'ReceiverIdAsc',
          this.sortEvents.ReceiverIdAsc
        ),
        this.getInputAlert(
          'radio',
          'ID Receptor (Z -> A)',
          'ReceiverIdDesc',
          this.sortEvents.ReceiverIdDesc
        ),
        this.getInputAlert(
          'radio',
          'Nombre Receptor (A -> Z)',
          'ReceiverNameAsc',
          this.sortEvents.ReceiverNameAsc
        ),
        this.getInputAlert(
          'radio',
          'Nombre Receptor (Z -> A)',
          'ReceiverNameDesc',
          this.sortEvents.ReceiverNameDesc
        ),
        this.getInputAlert(
          'radio',
          'Fecha Emisión (Más Antiguo)',
          'EmittedDateAsc',
          this.sortEvents.EmittedDateAsc
        ),
        this.getInputAlert(
          'radio',
          'Fecha Emisión (Más Actual)',
          'EmittedDateDesc',
          this.sortEvents.EmittedDateDesc
        ),
      ],
      buttons: [
        'Cancelar',
        {
          text: 'Ordenar',
          handler: (data) => {
            if (data !== null && data !== undefined) {
              this.orderBy = data as string;
              this.setSelectedOrder(this.orderBy);
              this.setListToShow();
            }
          },
        },
      ],
    });
    alert.present();
  }

  private getInputAlert(
    type: 'radio',
    label: string,
    value: string,
    checked: boolean
  ) {
    return {
      type: type,
      label: label,
      value: value,
      checked: checked,
    };
  }

  private setSelectedOrder(orderType: string) {
    this.sortEvents.KeyEInvoiceAsc = 'KeyEInvoiceAsc' === orderType;
    this.sortEvents.KeyEInvoiceDesc = 'KeyEInvoiceDesc' === orderType;
    this.sortEvents.PurchIdAsc = 'PurchIdAsc' === orderType;
    this.sortEvents.PurchIdDesc = 'PurchIdDesc' === orderType;
    this.sortEvents.ReceiverIdAsc = 'ReceiverIdAsc' === orderType;
    this.sortEvents.ReceiverIdDesc = 'ReceiverIdDesc' === orderType;
    this.sortEvents.ReceiverNameAsc = 'ReceiverNameAsc' === orderType;
    this.sortEvents.ReceiverNameDesc = 'ReceiverNameDesc' === orderType;
    this.sortEvents.EmittedDateAsc = 'EmittedDateAsc' === orderType;
    this.sortEvents.EmittedDateDesc = 'EmittedDateDesc' === orderType;
  }

  private setListToShow() {
    Object.keys(this.sortEvents).forEach((key) => {
      if (this.sortEvents[key]) {
        switch (key) {
          case 'KeyEInvoiceAsc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              a.KeyEInvoice.localeCompare(b.KeyEInvoice)
            );
            break;

          case 'KeyEInvoiceDesc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              b.KeyEInvoice.localeCompare(a.KeyEInvoice)
            );
            break;

          case 'PurchIdAsc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              a.PurchId.localeCompare(b.PurchId)
            );
            break;

          case 'PurchIdDesc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              b.PurchId.localeCompare(a.PurchId)
            );
            break;

          case 'ReceiverIdAsc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              a.ReceiverId.localeCompare(b.ReceiverId)
            );
            break;

          case 'ReceiverIdDesc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              b.ReceiverId.localeCompare(a.ReceiverId)
            );
            break;

          case 'ReceiverNameAsc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              a.ReceiverName.localeCompare(b.ReceiverName)
            );
            break;

          case 'ReceiverNameDesc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort((a, b) =>
              b.ReceiverName.localeCompare(a.ReceiverName)
            );
            break;

          case 'EmittedDateAsc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort(
              (a, b) =>
                new Date(a.EmittedDate).getTime() -
                new Date(b.EmittedDate).getTime()
            );
            break;

          case 'EmittedDateDesc':
            this.EInvoicesSearch = this.EInvoicesSearch.sort(
              (a, b) =>
                new Date(b.EmittedDate).getTime() -
                new Date(a.EmittedDate).getTime()
            );
            break;

          default:
            break;
        }
      }
    });
  }

  async filterPopover() {
    let popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: EinvoiceFilterComponent,
      componentProps: {
        data: this.EInvoices,
        rangeDates: this.rangeDatesSelected,
        MinMax: this.MinMax,
      },
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      let data = res.data;
      if (data !== null && data !== undefined) {
        let EinvoicesTmp: Einvoice[] = [];

        if (data.rangeDates && data.rangeDates.length > 0) {
          this.rangeDatesSelected = data.rangeDates;
        }

        if (data.MinMax) {
          this.MinMax = data.MinMax;
        }

        EinvoicesTmp = this.EInvoices.filter((x) => {
          let createdDate = new Date(x.EmittedDate);
          createdDate.setHours(createdDate.getHours() + 6);
          if (
            this.rangeDatesSelected.length > 0 &&
            !(
              createdDate >= new Date(this.rangeDatesSelected[0]) &&
              createdDate <= new Date(this.rangeDatesSelected[1])
            )
          ) {
            return false;
          }

          if (
            this.MinMax.lower > 0 &&
            this.MinMax.upper > 0 &&
            !(x.Total >= this.MinMax.lower && x.Total <= this.MinMax.upper)
          ) {
            return false;
          }

          return true;
        });

        this.EInvoicesPivot =
          EinvoicesTmp.length > 0 ? EinvoicesTmp : this.EInvoices;
        this.EInvoicesSearch = this.EInvoicesPivot;
      } else {
        this.rangeDatesSelected = [];
        this.EInvoicesPivot = this.EInvoices;
        this.EInvoicesSearch = this.EInvoices;
      }
    });

    this.setListToShow();
  }

  async swipeEvent(event, EInvoice: Einvoice) {
    this.goToDetails(EInvoice);
  }

  async goToDetails(EInvoice: Einvoice) {
    let PurchOrders: PurchLinesInfoParamv2[] = [];
    await this.showSpinnerLoading('Se está cargando las órdenes de compra');
    PurchOrders = await this.invServ.getAllPurchOrdersToAssignEInvoice(
      this.empresaSelected.getId()
    );
    if (PurchOrders && PurchOrders.length > 0) {
      this.navService.navigateTo(NavigationRoutes.AssignPurchOrderPage, {
        Company: this.empresaSelected,
        EInvoice: EInvoice,
        PurchOrders: PurchOrders,
      });
      this.loader.dismiss();
    } else {
      this.loader.dismiss();
    }
  }
}
