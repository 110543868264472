import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, LoadingController } from '@ionic/angular';
import { StorageService } from 'src/app/GeneralUtilis/Storage';

@Component({
  selector: 'app-vendor-register-document',
  templateUrl: './vendor-register-document.component.html',
  styleUrls: ['./vendor-register-document.component.scss'],
})
export class VendorRegisterDocumentComponent implements OnInit {
  @Input() documentData: any = {};
  @Output() documentDataChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() registerVendor: EventEmitter<any> = new EventEmitter<any>();

  @Input() reqRepr: string;
  @Input() documentLanguage: any = {};
  @Input() changeLanguageTo = '';
  @Output() languageChanger = new EventEmitter<string>();
  @Output() screenChanger = new EventEmitter<number>();

  @ViewChild('unlock') input: any;
  @ViewChild('vendorAgreement') vendorAgreement: any;

  loader: HTMLIonLoadingElement;

  setIntID;
  flagErrorMsg = false;

  document = {
    vatNumVendor: [],
    vatNumRepres: [],
    vatNumReprInvoice: [],
    vatNumReprService: [],
    vendorAgreement: [],
    transferAuthorization: [],
    guaranteePolicies: [],
    acceptVendorAgreement: false,
  };

  constructor(
    public storage: StorageService,
    public alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private iab: InAppBrowser
  ) {}

  async ngOnInit() {
    this.setDocumentVariables();
  }

  setDocumentVariables() {
    this.document = this.documentData;
  }

  setGlobalVariables() {
    this.documentData = this.document;
  }

  /**
   *
   */
  changeLanguage() {
    switch (this.changeLanguageTo) {
      case 'ES':
        this.languageChanger.emit('EN');
        break;
      case 'EN':
        this.languageChanger.emit('ES');
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param pSecreen
   */
  changeScreen(pSecreen: number) {
    this.setGlobalVariables();
    this.documentDataChange.emit(this.documentData);
    this.screenChanger.emit(pSecreen);
  }

  /**
   *
   * @param event Evento, trae la información de la imagen (input img)
   * @param index Es el elemento de la lista seleccionado
   */
  async onFileSelected(event, i) {
    this.clearDocVar(i);
    await this.showSpinnerLoading('Subiendo archivo...');
    try {
      for (let index = 0; index < event.target.files.length; index++) {
        let file = event.target.files[index];
        let fileExtension = event.target.files[index].type;
        await this.addToDocument(file, fileExtension, i);
      }
    } catch (error) {
      console.log(error);
    }
    this.dissmissLoading();
  }

  /**
   *
   * @param file
   * @param fileExtension
   * @param i
   */
  clearDocVar(i) {
    switch (i) {
      case 1:
        this.document.vatNumVendor = [];
        break;
      case 2:
        this.document.vatNumRepres = [];
        break;
      case 3:
        this.document.vatNumReprInvoice = [];
        break;
      case 4:
        this.document.vatNumReprService = [];
        break;
      case 5:
        this.document.vendorAgreement = [];
        break;
      case 6:
        this.document.transferAuthorization = [];
        break;
      case 7:
        this.document.guaranteePolicies = [];
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param file
   * @param fileExtension
   * @param i
   */
  async addToDocument(file, fileExtension, i) {
    let fileTo64: string;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      fileTo64 = reader.result.toString();
      fileExtension = fileTo64.substring(
        fileTo64.indexOf('/') + 1,
        fileTo64.indexOf(';base64')
      );
      fileTo64 = fileTo64.substring(fileTo64.lastIndexOf(',') + 1);
      switch (i) {
        case 1:
          this.document.vatNumVendor.push({ fileTo64, fileExtension });
          break;
        case 2:
          this.document.vatNumRepres.push({ fileTo64, fileExtension });
          break;
        case 3:
          this.document.vatNumReprInvoice.push({ fileTo64, fileExtension });
          break;
        case 4:
          this.document.vatNumReprService.push({ fileTo64, fileExtension });
          break;
        case 5:
          this.document.vendorAgreement.push({ fileTo64, fileExtension });
          break;
        case 6:
          this.document.transferAuthorization.push({ fileTo64, fileExtension });
          break;
        case 7:
          this.document.guaranteePolicies.push({ fileTo64, fileExtension });
          break;
        default:
          break;
      }
    };
    this.setGlobalVariables();
  }

  async showSpinnerLoading(mensaje: string) {
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      spinner: 'dots',
    });
    this.loader.present();
  }

  async dissmissLoading() {
    this.loader.dismiss();
  }

  /**
   *
   * @param evt
   */
  // checkUnlock(evt: Event) {
  //   let theRange = Number(this.input.nativeElement.value);
  //   if (evt.type == 'touchend' && theRange === 100) {
  //     if (!this.document.vendorAgreement.length
  //       // || !this.document.guaranteePolicies.length
  //       || !this.document.vatNumReprInvoice.length
  //       || !this.document.vatNumVendor.length
  //       || (this.reqRepr && !this.document.vatNumRepres.length)
  //       || !this.document.acceptVendorAgreement
  //     ) {
  //       this.flagErrorMsg = true;
  //       this.sendSlideBack(theRange);
  //     } else {
  //       this.setGlobalVariables();
  //       this.showAlert(
  //         this.documentLanguage.confirmTitle,
  //         this.documentLanguage.confirmSubTitle,
  //         this.documentLanguage.confirmBtnText,
  //         this.documentLanguage.rejectBtnText,
  //         2
  //       );
  //     }
  //   }
  // }

  register() {
    if (
      !this.document.vendorAgreement.length ||
      // || !this.document.guaranteePolicies.length
      !this.document.vatNumReprInvoice.length ||
      !this.document.vatNumVendor.length ||
      (this.reqRepr && !this.document.vatNumRepres.length) ||
      !this.document.acceptVendorAgreement
    ) {
      this.flagErrorMsg = true;
      // this.sendSlideBack(theRange);
    } else {
      this.setGlobalVariables();
      this.showAlert(
        this.documentLanguage.confirmTitle,
        this.documentLanguage.confirmSubTitle,
        this.documentLanguage.confirmBtnText,
        this.documentLanguage.rejectBtnText,
        2
      );
    }
  }

  // /**
  //  *
  //  * @param theRange:
  //  */
  // sendSlideBack(theRange: number) {
  //   this.setIntID = setInterval(() => {
  //     if (this.input.nativeElement.value > 0) {
  //       theRange--;
  //       this.input.nativeElement.value = theRange--;
  //     } else {
  //       this.input.nativeElement.value = 0;
  //       clearInterval(this.setIntID);
  //     }
  //   });
  // }

  async showAlert(pTitle, pSubTitle, pText, pBackText, pCase) {
    const docURL =
      'https://serviceplan-api.mercasacr.com/images/Agreements/GFI-130  Acuerdo de compra y venta repetitiva.pdf';
    let alert = await this.alertCtrl.create({
      header: pTitle,
      subHeader: pSubTitle,
      buttons: [
        { text: pBackText },
        {
          text: pText,
          handler: () => {
            if (pCase == 1) {
              const browser = this.iab.create(
                docURL,
                '_system',
                'location=yes'
              );
              browser.show();
            } else if (pCase == 2) {
              this.documentDataChange.emit(this.documentData);
              this.registerVendor.emit(true);
              this.flagErrorMsg = false;
            }
          },
        },
      ],
    });
    await alert.present();
    // this.vendorAgreement.target = "_blank";
  }
}
