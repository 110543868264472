// Native - Plugins
import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

// BusinessCore
import { RestrictionData } from './../../businessCore/RestrictionData';

@Component({
  selector: 'app-new-restriction',
  templateUrl: './new-restriction.component.html',
  styleUrls: ['./new-restriction.component.scss'],
})
export class NewRestrictionComponent {
  restriction_data: RestrictionData = new RestrictionData();
  available_days: any;
  type_list: any;
  restrictionDays: string[] = [];
  restrictionType: number = 0;
  restrictionDate1: string = new Date().toISOString();
  restrictionDate2: string = new Date().toISOString();

  /**
   * Class constructor with these injections:
   * @param viewController features and information about the current view.
   * @param navParams object that exists on a page and can contain data for that particular view.
   */
  constructor(
    private viewController: PopoverController,
    private navParams: NavParams
  ) {
    this.restriction_data = this.navParams.get('restriction');

    this.restrictionDays = this.restriction_data.day;
    this.restrictionType = this.restriction_data.type;
    this.restrictionDate1 = this.restriction_data.date1;
    this.restrictionDate2 = this.restriction_data.date2;

    this.available_days = [
      { id: 0, value: 'Lunes' },
      { id: 1, value: 'Martes' },
      { id: 2, value: 'Miércoles' },
      { id: 3, value: 'Jueves' },
      { id: 4, value: 'Viernes' },
      { id: 5, value: 'Sábado' },
    ];

    this.type_list = this.restriction_data.get_restriction_type();
  }

  /**
   * Metodo utilizado para agregar una nueva restriccion, cerrando el popup (con el dismiss) donde
   * se encuentra cargado el presente componente (new-restriction) y enviando la informacion
   * que se encuentra registrada en 'restriction_data'
   */
  addRestriction() {
    this.restriction_data.edit = true;
    this.restriction_data.day = this.restrictionDays;
    this.restriction_data.type = this.restrictionType;
    this.restriction_data.date1 = this.restrictionDate1;
    this.restriction_data.date2 = this.restrictionDate2;
    this.viewController.dismiss(this.restriction_data);
  }

  /**
   * Metodo utilizado para validar el formulario y los datos ingresados para la restriccion
   */
  validForm() {
    if (this.restrictionDays.length > 0) {
      if (this.restrictionType == 2 || this.restrictionType == 3) {
        let hours_date1 = this.restriction_data.getHours(this.restrictionDate1);
        let hours_date2 = this.restriction_data.getHours(this.restrictionDate2);

        if (
          this.restrictionDate1 &&
          this.restrictionDate2 &&
          ((+hours_date1[0] == +hours_date2[0] &&
            +hours_date1[1] <= +hours_date2[1]) ||
            +hours_date1[0] < +hours_date2[0])
        ) {
          return true;
        }
      } else if (this.restrictionDate1 != null) {
        return true;
      }
    }
    return false;
  }
}
