import { CustomerAddress } from './CustomerAddress';

export class CustomerData {
  AccountNum: string = '';
  AvailableBalance: number = 0;
  Blocked: number = 0;
  CreditMax: number = 0;
  CreditType: number = 0;
  CurrentBalance: number = 0;
  CustomerGroup: string = '';
  CustomerName: string = '';
  DebitNoteRequired: number = 0;
  DeliveryMethod: string = '';
  DeliveryRoute: string = '';
  HasDueInvoices: number = 0;
  Hierarchy: string = '';
  IndReqPaymAuth: number = 0;
  IsCustomer: number = 1;
  LineDisc: string = '';
  OrigOrdNbrInd: number = 0;
  OriginSite: string = '';
  PartialReturn: number = 0;
  PaymTermId: string = '';
  Phone: string = '';
  PriceGroup: string = '';
  RequirementAuthorized: number = 0;
  TaxFlag: number = 0;
  TotalReturn: number = 0;
  email: string = '';
  email_normal: string = '';

  CustomerAddresses: CustomerAddress[] = [];

  AuthorizedBankAccounts: any[] = [];
  AuthorizedCustomers: any[] = [];
  BillsOfExchange: any[] = [];
  CustomerCompanies: any[] = [];
  CustomerPaymentConditions: any[] = [];
  DeliveryReturnRequirements: any[] = [];

  constructor() {}
}
