<app-nav-bar [title]="'Registrar Parada'">
  <ion-buttons slot="end">
    <ion-button (click)="closeCancel()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div>
    <div style="display: flex; align-items: center" (click)="openMap(3)">
      <ion-item>
        <ion-label> Inicio de Parada: </ion-label>
      </ion-item>
      <input
        type="time"
        [(ngModel)]="horaIni"
        min="00:00"
        max="24:00"
        disabled="true"
        class="time-h"
      />
    </div>
    <div style="display: flex; align-items: center" (click)="openMap(4)">
      <ion-item>
        <ion-label> Fin de Parada: </ion-label>
      </ion-item>
      <input
        type="time"
        [(ngModel)]="horaFin"
        min="00:00"
        max="24:00"
        disabled="true"
        class="time-h"
      />
    </div>

    <div style="padding-right: 5%">
      <ion-item>
        <ion-label> Motivo de parada: </ion-label>
      </ion-item>
      <textarea
        rows="10"
        cols="50"
        style="
          margin: 0;
          border-style: solid;
          margin-right: 2.5%;
          margin-left: 2.5%;
          width: 100%;
          background: white;
        "
        placeholder="Escriba la descripción"
        maxlength="1000"
        [(ngModel)]="motivo"
        name="note"
        autocomplete="on"
        autocorrect="on"
      >
      </textarea>
    </div>

    <div style="display: flex; justify-content: center">
      <ion-button (click)="saveCheckPoint()" style="width: 10%">
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-content>
