export class DateTimeOperation {
  constructor() {}

  // Esta funcion permite saber, dada una fecha, si es el dia actual
  static isToday(dateParameter: Date) {
    let result = false;
    if (dateParameter !== null) {
      const today = new Date();
      result =
        dateParameter.getFullYear() === today.getFullYear() &&
        dateParameter.getMonth() === today.getMonth() &&
        dateParameter.getDate() === today.getDate();
    }
    return result;
  }

  static dateToNumberYYYYMMDD(dayToConvert: Date) {
    if (dayToConvert !== null) {
      return (
        dayToConvert.getFullYear() * 10000 +
        (dayToConvert.getMonth() + 1) * 100 +
        dayToConvert.getDate() * 1
      );
    } else {
      return null;
    }
  }

  static getDateString(date: string) {
    if (date !== null) {
      return new Date(
        parseInt(date.toString().substring(0, 4), 10),
        parseInt(date.toString().substring(4, 6), 10) - 1,
        parseInt(date.toString().substring(6, 8), 10)
      );
    } else {
      return null;
    }
  }

  static getDateStringYYYYMMDDHHSS(date: string) {
    if (date !== null) {
      return new Date(
        parseInt(date.toString().substring(0, 4), 10),
        parseInt(date.toString().substring(4, 6), 10) - 1,
        parseInt(date.toString().substring(6, 8), 10),
        parseInt(date.toString().substring(8, 10), 10),
        parseInt(date.toString().substring(10, 12), 10)
      );
    } else {
      return null;
    }
  }

  static dateToNumberYYYYMMMDDMMSS(dayToConvert: Date) {
    if (dayToConvert !== null) {
      return (
        dayToConvert.getFullYear() * 100000000 +
        (dayToConvert.getMonth() + 1) * 1000000 +
        dayToConvert.getDate() * 10000 +
        dayToConvert.getHours() * 100 +
        dayToConvert.getMinutes() * 1
      );
    } else {
      return null;
    }
  }

  static getRecentDate(dateA: Date, dateB: Date) {
    return dateA > dateB ? dateA : dateB;
  }

  static dateAComparatedateB(dateA: Date, dateB: Date) {
    let result = -1; // -1 A menor a B
    if (dateA.getFullYear() > dateB.getFullYear()) {
      // la fecha A es mayor a B
      result = 1;
    } else if (dateA.getMonth() > dateB.getMonth()) {
      // tienen el mismo año (se compara el mes)
      result = 1;
    } else if (dateA.getDate() > dateB.getDate()) {
      // tienen el mismo mes y años ( se compara los días)
      result = 1;
    } else if (
      dateA.getFullYear() === dateB.getFullYear() && // En caso de ser iguales.
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate()
    ) {
      result = 0; // Son el mismo dia
    }

    return result;
  }

  static dateAComparatedateBandTime(dateA: Date, dateB: Date) {
    const compareDate = this.dateAComparatedateB(dateA, dateB);
    let result = compareDate; // A < B = -1  || A > B = 1 || A == B = 0
    if (compareDate === 0) {
      // son el mismo día, hay que comparar las Horas minutos y seguntos.
      result = -1; // A < B = -1
      if (dateA.getHours() > dateB.getHours()) {
        result = 1;
      } else if (
        dateA.getMinutes() > dateB.getMinutes() &&
        dateA.getHours() === dateB.getHours()
      ) {
        result = 1;
      } else if (
        dateA.getSeconds() > dateB.getSeconds() &&
        dateA.getMinutes() === dateB.getMinutes()
      ) {
        result = 1;
      } else if (
        dateA.getHours() === dateB.getHours() &&
        dateA.getMinutes() === dateB.getMinutes() &&
        dateA.getSeconds() === dateB.getSeconds()
      ) {
        result = 0;
      }
    }
    return result;
  }

  static getDaysDiference(dateA: Date, dateB: Date) {
    const diff = Math.abs(dateA.getTime() - dateB.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  static dateToStringFormart(dayToConvert: Date) {
    return (
      dayToConvert.getDate() +
      '/' +
      (dayToConvert.getMonth() + 1).toString() +
      '/' +
      dayToConvert.getFullYear().toString()
    );
  }
}
