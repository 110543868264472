<!-- MERCASA VIP STYLE-->
<ion-card
  *ngIf="infoPurch && (infoPurch.getDocumentStateI() === 30 || recordConfirmed)"
>
  <ion-card-content>
    <ion-item-sliding (swipe)="swipeEvent($event)">
      <ion-item class="borderStyle">
        <ion-grid
          style="padding: 0px; --padding-start: 5px; --inner-padding-end: 5px"
        >
          <ion-row>
            <ion-col size="6">
              <h6 *ngIf="infoPurch.getIdOC() !== ''" class="h74">
                {{ infoPurch.getIdOC() }}
              </h6>
            </ion-col>
            <ion-col
              size="4"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h6 class="h73" style="text-align: right !important">
                {{ infoPurch.getDataArea().getId().toUpperCase() }}
              </h6>
            </ion-col>
            <ion-col size="2">
              <ion-button
                (click)="openResumen()"
                style="padding: 0"
                fill="clear"
              >
                <ion-icon name="document"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <h6 ion-text text-wrap class="h72">
                {{ infoPurch.getAccountNum() }}
              </h6>
            </ion-col>
            <ion-col size="6">
              <h6 ion-text text-wrap class="h73">
                {{ infoPurch.getVendName() }}
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <h6 ion-text class="h7">Creado por:</h6>
              <h6 ion-text class="h7">Notas:</h6>
            </ion-col>
            <ion-col size="6">
              <h6 ion-text class="h7">
                {{ this.infoPurch.getCreatedBy() }}
              </h6>
              <h6 ion-text text-wrap class="h7">
                {{ this.infoPurch.getNote() }}
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4">
              <h6 ion-text text-center class="h7">LINEAS</h6>
              <h6 color="primary" class="h7" style="font-weight: bold">
                {{ infoPurch.cantidadLineas }}
              </h6>
            </ion-col>
            <ion-col size="4">
              <h6 ion-text text-center class="h7">TOTAL</h6>
              <h6 color="primary" class="h7" style="font-weight: bold">
                {{ getCurrency(infoPurch.total) }}
              </h6>
            </ion-col>
            <ion-col size="4">
              <h6 ion-text text-center class="h7">&#8205;</h6>
              <h6 color="primary" class="h7" style="font-weight: bold">
                {{ infoPurch.getDocumentStateS() }}
              </h6>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item-options side="start">
        <ion-item-option (click)="confirmPurchOrder()" *ngIf="!recordConfirmed">
          <ion-icon name="checkmark-outline"></ion-icon>
          <span style="font-size: 8px">Confirmar</span>
        </ion-item-option>
      </ion-item-options>

      <ion-item-options side="end">
        <ion-item-option
          color="danger"
          (click)="rejectPurchOrder()"
          *ngIf="!recordConfirmed"
        >
          <ion-icon name="close"></ion-icon>
          <span style="font-size: 8px">Rechazar</span>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-card-content>
</ion-card>
