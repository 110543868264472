export class Address {
  latitude: number;
  longitude: number;
  addressName: string; //Nombre de la dirección: E.j: Oficina
  addressType: number;

  countryCode: string; //País
  region: string; //Provincia o estado
  city: string; //Distrito o ciudad
  subregion: string; //Canton, colonia o municipio
  address: string; //Nombre de la calle-Otras señas
  addNum: string;
  addr_type: string;
  block: string;
  district: string; //
  longLabel: string;
  match_addr: string;
  metroArea: string;
  neighborhood: string;
  placeName: string;
  postal: string;
  postalExt: string;

  sector: string;
  shortLabel: string; //"Avenida 8";

  territory: string;
  type: string;
}

export class AddressFeature {
  latitude: number;
  longitude: number;
  addressName: string; //Nombre de la dirección: E.j: Oficina
  street: string; //Otras señas

  bbox: number[]; //[-87.2016188991497, 5.40010640012229, -82.5325839553876, 11.2196609999159]
  center: number[]; //[-84.2009147613538, 10.2577944410312]
  geometry: { type: ''; coordinates: [0, 0] }; //{type: Point, coordinates: [-84.2009147613538, 10.2577944410312]};
  id: string; //"country.9179111388611280"
  place_name: string; //"Costa Rica"
  place_type: string[]; //["country"]
  properties: { wikidata: ''; short_code: '' }; //{wikidata: "Q800", short_code: "cr"}
  relevance: number; //1
  text: string; //"Costa Rica"
  type: string; //"Feature"
}
