import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOneKeyList',
})
export class FilterOneKeyListPipe implements PipeTransform {
  transform(
    FilteredArray: any[],
    filterArray: any[],
    actionType: number
  ): any[] {
    if (filterArray.length === 0) {
      return FilteredArray;
    }

    switch (actionType) {
      case 1:
        return FilteredArray.filter(
          (element) =>
            filterArray.indexOf(element.getDataAreaId()) > -1 ||
            filterArray.indexOf(element.getAssetGroup()) > -1 ||
            filterArray.indexOf(element.getPending()) > -1
        );
      case 2:
        return FilteredArray.filter(
          (element) =>
            filterArray.indexOf(element.getDataAreaId()) > -1 ||
            filterArray.indexOf(element.getAssetGroup()) > -1 ||
            filterArray.indexOf(element.getWorker()) > -1 ||
            filterArray.indexOf(element.getWorkerNew()) > -1
        );
      case 3:
        return FilteredArray.filter(
          (element) =>
            filterArray.indexOf(element.getWorkerDepartmentName()) > -1 ||
            filterArray.indexOf(element.getCertificatedPeriod()) > -1
          // || filterArray.indexOf(element.getWorker()) > -1
          // || filterArray.indexOf(element.getWorkerNew()) > -1
        );
      default:
        return [];
        break;
    }
  }
}
