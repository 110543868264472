<div *ngIf="purhInfoOC">
  <div lines="none" class="barTitle">
    <div style="display: flex; width: 100%">
      <h5 style="text-align: center; font-size: 1rem; width: 100%">
        <a></a>TOTALES ({{ this.purhInfoOC.getCurrency() }})
      </h5>
    </div>
  </div>
  <!-- <ion-item>
    <ion-label>
      SubTotal
    </ion-label>
    <ion-input
    [(ngModel)]="subTotalS" readonly></ion-input>
  </ion-item> -->
  <div class="lineT">
    <ion-item>
      <ion-label> SubTotal </ion-label>
    </ion-item>
    <input [(ngModel)]="subTotalS" readonly />
  </div>

  <div class="lineT">
    <ion-item>
      <ion-label> Descuento </ion-label>
    </ion-item>
    <input [(ngModel)]="descuentoS" readonly />
  </div>

  <div class="lineT">
    <ion-item>
      <ion-label> Imp venta: </ion-label>
    </ion-item>
    <input [(ngModel)]="impuestoVenS" readonly />
  </div>

  <div class="lineT">
    <ion-item>
      <ion-label> Monto Total: </ion-label>
    </ion-item>
    <input [(ngModel)]="totalS" readonly />
  </div>
</div>
