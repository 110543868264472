<app-nav-bar [title]="'Credenciales'" [start]="'GoBack'"></app-nav-bar>

<ion-content class="ion-padding" center text-center>
  <div class="sectionContainer">
    <h4 class="title2" style="text-align: center">Cambiar Contraseña</h4>
    <div class="flexBlockContainer">
      <div class="flexContainer">
        <h6 class="titleContent">Contraseña actual:</h6>
        <ion-input
          class="input"
          type="password"
          name="actualPass"
          [(ngModel)]="actualPass"
          required
        ></ion-input>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Nueva contraseña:</h6>
        <ion-input
          class="input"
          type="password"
          name="newPass"
          [(ngModel)]="newPass"
          required
        ></ion-input>
      </div>
      <div class="flexContainer">
        <h6 class="titleContent">Confirmar contraseña:</h6>
        <ion-input
          class="input"
          type="password"
          name="confirmPass"
          [(ngModel)]="confirmPass"
          required
        ></ion-input>
      </div>

      <span class="span_alert"
        >* Deben tener un largo mínimo de 8 caracteres.</span
      >
      <br />
      <span *ngIf="newPass != confirmPass" class="span_alert"
        >"Las contraseñas no coinciden."</span
      >
      <div style="display: flex; justify-content: center">
        <ion-button (click)="updatePassword()" style="width: 80%; margin: 10px">
          Actualizar contraseña
        </ion-button>
      </div>

      <div class="msgContainer" style="margin: 5px 10px">
        <span style="color: brown" *ngIf="setWarning">Error: </span
        >{{ warningMsg }}
      </div>
    </div>
  </div>
</ion-content>
